// @flow
import type { DataProps as CampaignDescriptionData } from '../../components/campaignCreation/CampaignDescription';
import type { DataProps as CampaignDetailsData } from '../../components/campaignCreation/CampaignDetails';
import type { IntlShape } from 'react-intl';
import type { DataProps as ProductSelectionData } from '../../components/campaignCreation/ProductSelection';
import type { RootState } from '../../reducers';
import type { SetAttributeForCampaignDetailsStep } from '../../actions/campaign';
import type { Step } from '../../components/campaignCreation/CampaignCreationWizard';

import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { buildStepsFromState } from './buildStepsFromState';
import { getUser } from '../../helpers/user';
import compose from '../../hoc/compose';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';

import { StepName, StepUrl } from './constants';

import CampaignDetails from '../../components/campaignCreation/CampaignDetails';
import FaceWorried from '../../images/icons/FaceWorried';
import FinalStep from './FinalStep';

import { clearCreatedCampaign, setAttributeForCampaignDetailsStep } from '../../actions/campaign';

type Actions = {|
  clearCreatedCampaign: () => void,
  setAttributeForCampaignDetailsStep: SetAttributeForCampaignDetailsStep
|};

type DataProps = {|
  data: CampaignDetailsData,
  campaignDescription: CampaignDescriptionData,
  productSelection: ProductSelectionData,
  createdByUserId?: number,
  steps: Step[]
|};

type Props = {|
  ...Actions,
  ...DataProps,

  intl: IntlShape
|};

class CampaignDetailsStep extends React.PureComponent<Props, {}> {
  static layoutClassName = 'Layout--create-campaign';
  static disableHeadroom = true;
  static url = StepUrl[StepName.CAMPAIGN_DETAILS];

  componentDidMount() {
    if (this.props.createdByUserId !== getUser().id) {
      this.props.clearCreatedCampaign();
    }
  }

  onChange = (attribute: string, value: any) => {
    this.props.setAttributeForCampaignDetailsStep({ attribute, value });
  };

  renderNotification = () => {
    const { budget } = this.props.data;

    if (budget > 0 && budget < 1000000) {
      return {
        icon: <FaceWorried />,
        content: <FormattedMessage id="campaignCreation.campaignDetails.notification.lowBudget" />
      };
    }

    return null;
  };

  render() {
    const { data, steps, productSelection, campaignDescription } = this.props;

    const { selectedPlatforms = [] } = productSelection || {};
    const { contentPlatforms = [] } = campaignDescription || {};

    const content = (
      <CampaignDetails
        {...data}
        isCreatingNewCampaign
        contentPlatform={contentPlatforms[0]}
        platforms={selectedPlatforms}
        onChange={this.onChange}
      />
    );

    // we are using changeStyleWhenScrolling HOC in CampaignCreationWizard which automatically
    // injects `style` object to props. We haven't had a way to tell flow that yet
    return (
      // $FlowFixMe
      <FinalStep steps={steps} notification={this.renderNotification()}>
        {content}
      </FinalStep>
    );
  }
}

const mapDispatchToProps = generateDispatchToProps({
  clearCreatedCampaign,
  setAttributeForCampaignDetailsStep
});

export default compose(
  connect(function (state: RootState, ownProps): DataProps {
    const ownState = state.campaignCreation;

    return {
      data: ownState.campaignDetails,
      campaignDescription: ownState.campaignDescription,
      productSelection: ownState.productSelection,

      steps: buildStepsFromState(ownState, StepName.CAMPAIGN_DETAILS),
      createdByUserId: ownState.createdByUserId
    };
  }, mapDispatchToProps)
)(CampaignDetailsStep);
