// @flow
import * as React from 'react';

import classNames from 'classnames';

import './Spinner.scss';

function sizeToFontAwesomeIconScale(size) {
  // http://bulma.io/documentation/elements/icon/#sizes
  switch (size) {
    case 'small':
      return '';
    case 'medium':
      return 'fa-2x';
    case 'large':
      return 'fa-3x';
    default:
      return 'fa-lg';
  }
}

export type SpinnerConfig = {
  mode?: 'cover' | 'fullWidth' | 'overlay' | 'inline',
  // '' and null mean default size
  size?: 'small' | 'medium' | 'large',
  centered?: boolean
};

const Spinner = ({
  children,
  mode = 'cover',
  size = '',
  centered = false
}: {
  children?: any
} & SpinnerConfig) => {
  const className = classNames(`Spinner Spinner--${mode}`, {
    [`Spinner--${size}`]: !!size,
    'has-text-centered': centered
  });
  // TODO instead of FA icon maybe use https://material-ui.com/api/circular-progress/
  const iconClassName = classNames('icon', {
    [`is-${size}`]: !!size
  });
  const faClassName = classNames(
    `fa fa-circle-o-notch fa-spin ${sizeToFontAwesomeIconScale(size)}`
  );

  const bg = mode === 'cover' ? <div className="Spinner__background" /> : null;

  return (
    <div className={className}>
      {bg}
      <div className="Spinner__content">
        <span className={iconClassName}>
          <i className={faClassName} />
        </span>
        {children ? <div className="Spinner__children">{children}</div> : null}
      </div>
    </div>
  );
};

export default Spinner;
