import React from 'react';

const InstallsIcon = props => {
  const { primaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 46 49" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fillRule="nonzero" stroke={primaryColor} strokeWidth="4" fill="none" strokeLinecap="round">
        <path d="M2 37v10h42V37" />
        <path d="M32 30l-9 9-9-9M27 8a6 6 0 0 1-6 6H11a6 6 0 1 1 0-12h4" />
        <path d="M19 8a6 6 0 0 1 6-6h10a6 6 0 1 1 0 12h-4M23 38V22" />
      </g>
    </svg>
  );
};

export default InstallsIcon;
