// @flow
import { connect, useDispatch } from 'react-redux';
import React from 'react';

import {
  deleteTeamInvite,
  deleteTeamMember,
  fetchTeam,
  inviteTeamMember
} from '../actions/account';
import Error from '../components/common/Error';
import NoData from '../components/common/NoData';
import Spinner from '../components/common/Spinner';
import TeamManage from '../components/team/TeamManage';
import generateStateToProps from '../helpers/generateStateToProps';
import type { ManagerTeam } from '../types/team.flow';

type Props = {
  user: Object,
  params: { teamId: string },
  team: ManagerTeam,
  isFetchingTeam: boolean,
  isInvitingTeamMember: boolean,
  isDeletingTeamMember: boolean,
  isDeletingTeamInvite: boolean,
  inviteTeamMember: Function,
  deleteTeamMember: Function,
  deleteTeamInvite: Function,
  error: any
};

let PublisherTeamManagePage = ({
  user,
  team,
  params,
  isFetchingTeam,
  isDeletingTeamInvite,
  isDeletingTeamMember,
  isInvitingTeamMember,
  error
}: Props) => {
  const dispatch = useDispatch();
  const accountId = user.id;
  const teamId = params.teamId;

  React.useEffect(() => {
    if (user) {
      dispatch(fetchTeam.run(user.id, teamId));
    }
  }, [user, teamId, dispatch]);

  const onInviteTeamMember = email => {
    dispatch(inviteTeamMember.run(accountId, teamId, email));
  };

  const onDeleteTeamMember = member => {
    dispatch(deleteTeamMember.run(accountId, teamId, member.id));
  };

  const onDeleteTeamInvite = invite => {
    dispatch(deleteTeamInvite.run(accountId, teamId, invite.id));
  };

  const isTeamOwner = () => {
    if (!team) return false;
    const members = team.members || [];
    if (!members.length) return false;
    const me = members.filter(member => {
      return member.account.id === user.id;
    });

    if (!me.length) return false;
    return me[0].role === 'owner';
  };

  if (isFetchingTeam) return <Spinner size="large" />;
  if (!team) return <NoData className="container" />;

  return (
    <div className="container PublisherTeamManagePage">
      <Error error={error} />
      <div className="PublisherTeamManage">
        <TeamManage
          team={team}
          isOwner={isTeamOwner()}
          onInviteTeamMember={onInviteTeamMember}
          onDeleteTeamMember={onDeleteTeamMember}
          onDeleteTeamInvite={onDeleteTeamInvite}
          isLoading={isInvitingTeamMember || isDeletingTeamMember || isDeletingTeamInvite}
          error={error}
        />
      </div>
    </div>
  );
};

const mapStateToProps = generateStateToProps('publisherTeamManagePage');

// $FlowFixMe
export default connect(mapStateToProps)(PublisherTeamManagePage);
