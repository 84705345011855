// @flow
// $FlowFixMe
import { useAsync } from 'react-async';
// $FlowFixMe
import { useFieldArray, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import classNames from 'classnames';

import { ImagePreview } from '../admin/CreateCampaignForm';
import callApi from '../../helpers/api';

import EditContentSubmissionTaskTemplateSection from './EditContentSubmissionTaskTemplateSection';

async function getContentSubmissionTaskTemplate({ contentSubmissionTaskTemplateId }) {
  if (!contentSubmissionTaskTemplateId) {
    return null;
  }
  const r = await callApi(
    `/admin/content-submission-tasks/v1/content-submission-task-template/${contentSubmissionTaskTemplateId}`
  );
  return r.data;
}

async function updateContentSubmissionTaskTemplate(contentSubmissionTaskTemplate) {
  if (
    !contentSubmissionTaskTemplate ||
    !contentSubmissionTaskTemplate.briefing ||
    !contentSubmissionTaskTemplate.content_submission ||
    !contentSubmissionTaskTemplate.cover_image_url ||
    !contentSubmissionTaskTemplate.id ||
    !contentSubmissionTaskTemplate.user_inputs
  ) {
    throw new Error(
      `Incomplete content submission task template: ${JSON.stringify(
        contentSubmissionTaskTemplate
      )}. This is a technical error, please contact the product team`
    );
  }
  const r = await callApi(
    `/admin/content-submission-tasks/v1/content-submission-task-template/${contentSubmissionTaskTemplate.id}`,
    {
      method: 'PUT',
      body: {
        briefing: contentSubmissionTaskTemplate.briefing,
        content_submission: contentSubmissionTaskTemplate.content_submission,
        cover_image_url: contentSubmissionTaskTemplate.cover_image_url,
        user_inputs: contentSubmissionTaskTemplate.user_inputs
      }
    }
  );
  return r.data;
}

export function ContentSubmissionTaskTemplateForm({
  register,
  errors,
  watchedCoverImageUrl,
  setError,
  fieldArray
}: {
  register: (name: string, options: Object) => Object,
  errors: Object,
  watchedCoverImageUrl: string,
  setError: (name: string, error: Object, ?{ shouldFocus?: boolean }) => void,
  fieldArray: Object
}) {
  const { remove: removeUserInput, append: appendUserInput, fields: fieldsUserInput } = fieldArray;
  return (
    <>
      <div className="columns mt-3">
        <div className="column">
          <div className="field">
            <label className="label">Briefing</label>
            <div className="control">
              <textarea
                {...register('contentSubmissionTaskTemplate.briefing', {
                  required: 'Briefing is required'
                })}
                className="textarea"
              />
              <p className="help">Explain to the creator what this campaign is about.</p>
            </div>
            {errors &&
              errors.contentSubmissionTaskTemplate &&
              errors.contentSubmissionTaskTemplate.briefing && (
                <p className="help is-danger">
                  {errors.contentSubmissionTaskTemplate.briefing.message}
                </p>
              )}
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Campaign cover image URL</label>
            <div className="control">
              <input
                {...register('contentSubmissionTaskTemplate.cover_image_url', {
                  required: 'Cover image URL is required'
                })}
                className="input"
                type="text"
              />
              <p className="help">
                The image should be in landscape format (recommendend ratio around 4:1). It will be
                shown to the creator.
              </p>
            </div>
          </div>
          {errors &&
            errors.contentSubmissionTaskTemplate &&
            errors.contentSubmissionTaskTemplate.cover_image_url && (
              <p className="help is-danger">
                {errors.contentSubmissionTaskTemplate.cover_image_url.message}
              </p>
            )}
          <div>
            <ImagePreview
              url={watchedCoverImageUrl}
              onError={() => {
                setError('contentSubmissionTaskTemplate.cover_image_url', {
                  type: 'string',
                  message: 'Invalid image URL'
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">Integration checklist for creators</label>
        {fieldsUserInput.map((field, index) => {
          return (
            <div className="columns is-vcentered my-0" key={field.id || index}>
              <div className="column py-1 is-half">
                <div className="control">
                  <input
                    {...register(`contentSubmissionTaskTemplate.user_inputs.${index}.description`, {
                      required: 'Description is required'
                    })}
                    className="input"
                    placeholder="Description"
                  />
                </div>
                {errors &&
                  errors.contentSubmissionTaskTemplate &&
                  errors.contentSubmissionTaskTemplate.user_inputs &&
                  errors.contentSubmissionTaskTemplate.user_inputs[index] &&
                  errors.contentSubmissionTaskTemplate.user_inputs[index].description && (
                    <p className="help is-danger">
                      {errors.contentSubmissionTaskTemplate.user_inputs[index].description.message}
                    </p>
                  )}
              </div>
              <div className="column py-1 is-one-fifth">
                <div className="control">
                  <label className="checkbox">
                    <input
                      {...register(
                        `contentSubmissionTaskTemplate.user_inputs.${index}.is_required`
                      )}
                      type="checkbox"
                    />{' '}
                    Required
                  </label>
                </div>
              </div>
              <div className="column py-1">
                <button
                  className="button"
                  onClick={e => {
                    removeUserInput(index);
                  }}>
                  Remove
                </button>
              </div>
            </div>
          );
        })}
        <p className="help">
          These are displayed as checkboxes to the creator. Mark them as required if the creator is
          not allowed to submit the content without the item checked.
        </p>
      </div>
      <button
        className="button"
        onClick={e => {
          e.preventDefault();
          appendUserInput({
            description: '',
            is_required: false,
            user_input_type: 'boolean'
          });
        }}>
        Add item
      </button>
    </>
  );
}

function ContentSubmissionTaskTemplate({
  contentSubmissionTaskTemplateId
}: {
  contentSubmissionTaskTemplateId: string
}) {
  const { data: contentSubmissionTaskTemplate, error, isPending } = useAsync(
    getContentSubmissionTaskTemplate,
    {
      contentSubmissionTaskTemplateId
    }
  );

  const {
    register,
    getValues,
    setValue,
    setError,
    watch,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      contentSubmissionTaskTemplate: {
        cover_image_url: null,
        user_inputs: []
      }
    },
    mode: 'onBlur'
  });

  const fieldArray = useFieldArray({ control, name: 'contentSubmissionTaskTemplate.user_inputs' });

  const [useContentSubmissionTaskTemplate, setUseContentSubmissionTaskTemplate] = useState(false);

  const watchedCoverImageUrl = watch('contentSubmissionTaskTemplate.cover_image_url');

  const {
    isResolved: contentSubmissionTaskTemplateUpdatedSuccessfully,
    errors: contentSubmissionTaskTemplateUpdateErrors,
    run: editContentSubmissionTaskTemplate,
    isLoading: isSavingContentSubmissionTaskTemplate
  } = useAsync({
    deferFn: async () => {
      const data = getValues();

      const taskTemplate = {
        ...contentSubmissionTaskTemplate,
        ...data.contentSubmissionTaskTemplate
      };

      updateContentSubmissionTaskTemplate(taskTemplate);
    }
  });

  React.useEffect(() => {
    if (!contentSubmissionTaskTemplate) {
      return;
    }
    setValue(
      'contentSubmissionTaskTemplate.cover_image_url',
      contentSubmissionTaskTemplate.cover_image_url
    );
    setValue('contentSubmissionTaskTemplate.briefing', contentSubmissionTaskTemplate.briefing);
    for (const input of contentSubmissionTaskTemplate.user_inputs) {
      fieldArray.append({
        description: input.description,
        is_required: input.is_required,
        user_input_type: input.user_input_type
      });
    }
  }, [contentSubmissionTaskTemplate, setValue]);

  return !isPending && contentSubmissionTaskTemplateId && !error ? (
    <form onSubmit={handleSubmit(editContentSubmissionTaskTemplate)}>
      <div className="section mb-5">
        <h2 className="subtitle">Creator content submission</h2>
        {!useContentSubmissionTaskTemplate && (
          <EditContentSubmissionTaskTemplateSection
            contentSubmissionTaskTemplateId={contentSubmissionTaskTemplateId}
            onClick={() => setUseContentSubmissionTaskTemplate(true)}
          />
        )}
        {useContentSubmissionTaskTemplate && (
          <>
            <ContentSubmissionTaskTemplateForm
              register={register}
              errors={errors}
              setError={setError}
              fieldArray={fieldArray}
              watchedCoverImageUrl={watchedCoverImageUrl}
            />
            <div className="buttons mt-5">
              <button
                className={classNames('button is-primary', {
                  'is-disabled': contentSubmissionTaskTemplateUpdatedSuccessfully,
                  'is-loading': isSavingContentSubmissionTaskTemplate
                })}
                type="submit">
                <span>Save</span>
              </button>
              <button
                className={classNames('button', {
                  'is-disabled': contentSubmissionTaskTemplateUpdatedSuccessfully,
                  'is-loading': isSavingContentSubmissionTaskTemplate
                })}
                onClick={() => setUseContentSubmissionTaskTemplate(false)}>
                <span>Cancel</span>
              </button>
              {contentSubmissionTaskTemplateUpdateErrors ? (
                <div className="mt-5">
                  Failed to update content submission task template: {JSON.stringify(errors)}
                </div>
              ) : (
                contentSubmissionTaskTemplateUpdatedSuccessfully && (
                  <div className="mt-5">Successfully updated content submission task template</div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </form>
  ) : error ? (
    <div className="section mb-5">
      <p className="has-text-red">
        Error loading content submission task template: {JSON.stringify(error)}
      </p>
    </div>
  ) : (
    <div className="section mb-5">
      <p className="has-text-grey">No content submission task template in this campaign</p>
    </div>
  );
}

export default ContentSubmissionTaskTemplate;
