// @flow
import type { ApiResponse } from '../../../types/api/response.flow';
import type { GetPublishedContentData } from '../../../types/api/campaigns.flow';

import { ContentPlatform } from '../../../constants';
// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
//$FlowFixMe
import { useAsync } from 'react-async';
import FormattedNumber from '../../common/FormattedNumber';
import NoData from '../../common/NoData';
import React from 'react';
import Spinner from '../../common/Spinner';
import callApi from '../../../helpers/api';
import moment from 'moment';

import './CampaignReportVideos.scss';

async function getPublishedContent({ campaignId }) {
  return callApi(`/campaigns/${campaignId}/published-content`, {
    method: 'GET'
  });
}

function Content(props: { publishedContent: GetPublishedContentData | null }) {
  const { publishedContent } = props;
  if (!publishedContent || !publishedContent.length) {
    return <NoData />;
  }

  return (
    <div className="CampaignReportVideos__container">
      {publishedContent
        .map((content, index) => {
          switch (content.contentPlatform) {
            case ContentPlatform.YOUTUBE:
              return <YoutubeVideoBlock key={index} video={content} />;
            default:
              return null;
          }
        })
        .filter(Boolean)}
    </div>
  );
}

function YoutubeVideoBlock(props) {
  const intl = useIntl();
  const { video } = props;
  return (
    <div key={video.contentId} className="CampaignReportVideos__video-block">
      <a
        className="CampaignReportVideos__thumbnail"
        target="_blank"
        rel="noopener noreferrer"
        href={video.contentUrl}>
        <img
          referrerPolicy="no-referrer"
          alt={intl.formatMessage(
            { id: 'campaign.report.videos.thumbnail' },
            { channelName: video.channelName }
          )}
          src={video.contentImageUrl}
        />
      </a>
      <span className="CampaignReportVideos__video-texts">
        <i className="material-icons">ondemand_video</i>
        <a target="_blank" rel="noopener noreferrer" href={video.contentUrl}>
          <strong>{video.contentText}</strong>
        </a>
      </span>
      <span className="CampaignReportVideos__video-texts">
        <i className="material-icons">person</i>
        <a target="_blank" rel="noopener noreferrer" href={video.channelUrl}>
          {video.channelName}
        </a>
      </span>
      <span className="CampaignReportVideos__video-texts">
        <i className="material-icons">calendar_today</i>
        {moment(video.published).format('Do MMM YYYY')}
      </span>
      {video.stats && video.stats.viewCount && (
        <span className="CampaignReportVideos__video-texts">
          <i className="material-icons">remove_red_eye</i>
          <FormattedNumber value={video.stats.viewCount} type="number" />
        </span>
      )}
      <span className="CampaignReportVideos__video-texts">
        <i className="material-icons">loyalty</i>
        <p>{video.promotionType.charAt(0).toUpperCase() + video.promotionType.slice(1)}</p>
      </span>
    </div>
  );
}

const CampaignReportVideos = ({ campaignId }: { campaignId: number }) => {
  const {
    data,
    isPending
  }: { data: ApiResponse<GetPublishedContentData>, isPending: boolean } = useAsync({
    promiseFn: getPublishedContent,
    campaignId
  });

  if (isPending) return <Spinner mode="fullWidth" size="large" />;

  return (
    <div className="CampaignReportVideos">
      <h2>
        <FormattedMessage id="campaign.report.videos.title" tagName="strong" />
      </h2>
      <Content publishedContent={data.data} />
    </div>
  );
};

export default CampaignReportVideos;
