// @flow
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import './MarkdownTextArea.scss';

function MarkdownTextArea(props: {
  getContent: () => string | null,
  textareaComponent: React.Element<'textarea'>
}) {
  const [showPreview, setShowPreview] = React.useState(false);

  return (
    <>
      <div className="tabs is-boxed is-small">
        <ul>
          <li
            className={classNames({ 'is-active': !showPreview })}
            onClick={() => {
              setShowPreview(false);
            }}>
            {/* Bulma's tabs styles work only for anchor :/ */
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Text</a>
          </li>
          <li
            className={classNames({ 'is-active': showPreview })}
            onClick={() => {
              setShowPreview(true);
            }}>
            {/* Bulma's tabs styles work only for anchor :/ */
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>Preview</a>
          </li>
        </ul>
      </div>
      {showPreview ? (
        <div>
          <label className="help">
            Note that depending on where the user is viewing the text (e.g. creator app, email,
            etc), fonts, spacing etc might look slightly different
          </label>
          <div
            style={{
              listStylePosition: 'inside',
              padding: '1rem',
              background: '#fafafa',
              marginTop: '0.5rem'
            }}
            className="MarkdownTextArea">
            <ReactMarkdown source={props.getContent()} />
          </div>
        </div>
      ) : (
        props.textareaComponent
      )}
    </>
  );
}

export default MarkdownTextArea;
