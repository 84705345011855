// @flow

import * as React from 'react';
import classNames from 'classnames';

import { renderInputField } from '../../../helpers/formHelper';

import './Input.scss';

type Props = {|
  className?: string,
  onBlur?: (event: any) => void,
  onChange?: (event: any) => void,
  onClick?: (event: any) => void,
  onFocus?: (event: any) => void,
  onKeyDown?: (event: any) => void,
  onKeyUp?: (event: any) => void,
  placeholder?: string,
  size?: 'large' | 'small' | 'medium' | null,
  type?: 'text' | 'number' | 'email' | 'url' | 'button',
  label?: string | React.Node | null,
  name?: string,
  value?: string | number,
  disabled?: boolean,
  min?: number,
  max?: number,
  step?: number,
  autoFocus?: boolean,
  iconLeft?: React.Node,
  iconRight?: React.Node,
  addonLeft?: React.Node,
  addonRight?: React.Node,
  isExpanded?: boolean
|};

class Input extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    size: null,
    disabled: false
  };

  focus() {
    this.refs.input.focus();
  }

  render() {
    const className = classNames(`field ${this.props.className || ''} Input`, {
      'has-addons': this.props.addonLeft || this.props.addonRight
    });

    const params = {
      attribute: this.props.name,
      props: this.props,
      state: this.state,
      ref: 'input',
      ...this.props
    };

    const addonLeft = this.props.addonLeft ? (
      <div className="control">{this.props.addonLeft}</div>
    ) : null;
    const addonRight = this.props.addonRight ? (
      <div className="control">{this.props.addonRight}</div>
    ) : null;

    return (
      <div className={className}>
        {addonLeft}
        {renderInputField(params)}
        {addonRight}
      </div>
    );
  }
}

export default Input;
