// @flow
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
// $FlowFixMe
import { useIntl } from 'react-intl';
import React from 'react';

import { DEFAULT_SEARCH_QUERY, queryFromURLFriendlyFormat } from '../helpers/searchTwitch';
import { getCollection, getDynamicCollections } from '../actions/collection';
import { resetSearch, searchInfluencers } from '../actions/influencerTwitchSearch';
import TwitchSearch from '../components/twitch/search/TwitchSearch';
import usePrevious from '../hooks/usePrevious';
import type { State as PublisherYoutubeSearchPageState } from '../reducers/containers/publisherYoutubeSearchPage';
import type { SearchParams } from '../types/search.flow';

type QueryParams = SearchParams & {
  collectionId?: number,
  linkToPreviousPage?: string
};

type Props = {
  location: { query: QueryParams, search: string },
  router: { setRouteLeaveHook: Function },
  route: string
};

const combineCollectionSearchAndQuery = (collection?: Object, query: Object) => ({
  ...(collection && collection.search),
  ...query
});

let PublisherTwitchSearchPage = (props: Props) => {
  const {
    router,
    route,
    location: { search, query }
  } = props;
  const intl = useIntl();
  const previousSearch = usePrevious(search);
  const dispatch = useDispatch();
  const { collectionId, linkToPreviousPage } = query;

  const reducerState: PublisherYoutubeSearchPageState = useSelector(
    state => state.publisherYoutubeSearchPage
  );
  const {
    collection,
    isFetchingCollection,
    collections,
    influencers,
    isSearchingInfluencers,
    errorSearchingInfluencers,
    isSavingSearch,
    updatedCollection,
    hasSearchedOnce,
    errorSavingSearch,
    isLoadingCollections,
    totalCount,
    isFetchingCountries,
    isFetchingSuggestedGames,
    isFetchingSuggestedTags,
    suggestedGames,
    suggestedTags
  } = reducerState;

  const previousIsFetchingCollection = usePrevious(isFetchingCollection);

  React.useEffect(() => {
    collectionId && dispatch(getCollection.run(collectionId, ['search']));
  }, [collectionId, dispatch]);

  React.useEffect(() => {
    dispatch(getDynamicCollections.run());
  }, [dispatch]);

  // However we leave this page, always reset
  React.useEffect(() => {
    router.setRouteLeaveHook(route, () => {
      dispatch(resetSearch.run());
    });
  }, [dispatch, route, router]);

  React.useEffect(() => {
    if (previousSearch && !search) {
      dispatch(resetSearch.run());
    }
  }, [previousSearch, search, dispatch]);

  React.useEffect(() => {
    // don't do anything if we are fetching a collection
    if (isFetchingCollection) {
      return;
    }

    // don't do anything if we don't have the collection data
    if (collectionId && !collection) {
      return;
    }

    // when we have the collection ready for the first time
    if (previousIsFetchingCollection && !isFetchingCollection) {
      dispatch(
        searchInfluencers.run(
          combineCollectionSearchAndQuery(collection, query),
          DEFAULT_SEARCH_QUERY
        )
      );
      return;
    }

    // trigger a search if the search params change
    if (search && search !== previousSearch) {
      dispatch(searchInfluencers.run(query, DEFAULT_SEARCH_QUERY));
      return;
    }
  }, [
    dispatch,
    isFetchingCollection,
    collection,
    search,
    query,
    collectionId,
    previousSearch,
    previousIsFetchingCollection
  ]);

  const title = intl.formatMessage({ id: 'search.title' });
  // Our querystring is constructed with queryToURLFriendlyFormat, so use
  // its counter-part here to process query back to correct object
  const constructedQuery = queryFromURLFriendlyFormat(
    { ...DEFAULT_SEARCH_QUERY, ...combineCollectionSearchAndQuery(collection, query) },
    DEFAULT_SEARCH_QUERY
  );

  return (
    <div className="PublisherYoutubeSearchPage container">
      <Helmet title={title} />
      <TwitchSearch
        linkToPreviousPage={linkToPreviousPage}
        isFetchingSuggestedGames={isFetchingSuggestedGames}
        isFetchingSuggestedTags={isFetchingSuggestedTags}
        suggestedGames={suggestedGames}
        suggestedTags={suggestedTags}
        collection={collection}
        collections={collections}
        influencers={influencers}
        query={constructedQuery}
        isSearchingInfluencers={isSearchingInfluencers}
        errorSearchingInfluencers={errorSearchingInfluencers}
        isSavingSearch={isSavingSearch}
        updatedCollection={updatedCollection}
        hasSearchedOnce={hasSearchedOnce}
        errorSavingSearch={errorSavingSearch}
        isLoadingCollections={isLoadingCollections}
        totalCount={totalCount}
        isFetchingCountries={isFetchingCountries}
      />
    </div>
  );
};

export default PublisherTwitchSearchPage;
