// @flow
import type { CampaignType, GamePlatform } from 'matchmade-types';
import type { GameToImport } from '../../../types/game.flow';

import * as React from 'react';
import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import { Platform } from '@sharkpunch/matchmade-common/game';

import Games from './Games';
import SelectGame from './SelectGame';
import SelectPlatform from './SelectPlatform';
import SelectSoftLaunchCountry from './SelectSoftLaunchCountry';
import SelectVertical from './SelectVertical';

import './index.scss';

type CommonProps = {
  games: GameToImport[] | null,
  isLoading: boolean,
  isDisabled: boolean
};

export type DataProps = {
  selectedGames: GameToImport[],
  selectedPlatforms: GamePlatform[],
  campaignType: CampaignType | null
};

export type ListSortingSettings = {
  showSelectedIosGameFirst: boolean,
  showSelectedAndroidGameFirst: boolean,
  showSelectedSteamGameFirst: boolean
};

type StatelessProps = CommonProps &
  DataProps &
  ListSortingSettings & {
    onSearchForGame: (title: string) => void,
    onSelectGame: (game: GameToImport) => void,
    onChangeSearchQuery: (title: string) => void,

    onSelectPlatform: (platform: GamePlatform) => void,

    isSoftLaunch: boolean,
    onToggleSoftLaunch: () => void,

    softLaunchCountry: string | null, // alpha-2
    onSelectSoftLaunchCountry: (country: string) => void, // alpha-2

    searchQuery: string,

    onChangeVertical: (value: CampaignType) => void
  };

export type SearchSettings = {
  isSoftLaunch: boolean,
  softLaunchCountry: string | null,
  searchQuery: string
};

type StatefulProps = CommonProps & {
  onSearchForGame: (title: string, softLaunchCountry?: ?string) => void,

  data: DataProps,
  onChange: (data: DataProps) => void,

  searchSettings: SearchSettings,
  onChangeSearchSettings: (settings: SearchSettings) => void
};

type State = DataProps & SearchSettings & ListSortingSettings;

function isTheSameGame(id1, id2) {
  return id1 + '' === id2 + '';
}

function getSelectedGameByPlatform(platform: GamePlatform) {
  switch (platform) {
    case Platform.IOS:
      return 'showSelectedIosGameFirst';
    case Platform.ANDROID:
      return 'showSelectedAndroidGameFirst';
    case Platform.STEAM:
    case Platform.HOMEPAGE:
    default:
      return 'showSelectedSteamGameFirst';
  }
}

function stateful(
  Component: React.ComponentType<StatelessProps>
): React.ComponentType<StatefulProps> {
  class StatefulProductSelection extends React.Component<StatefulProps, State> {
    static defaultProps = {
      searchSettings: {
        isSoftLaunch: false,
        softLaunchCountry: null,
        showSelectedGamesFirst: true,
        searchQuery: ''
      },
      onChangeSearchSettings() {}
    };

    constructor(props) {
      super(props);

      this.state = {
        ...props.searchSettings,
        ...props.data,
        showSelectedIosGameFirst: true,
        showSelectedAndroidGameFirst: true,
        showSelectedSteamGameFirst: true
      };
    }

    onChangeSearchSettings = () => {
      this.props.onChangeSearchSettings({
        isSoftLaunch: this.state.isSoftLaunch,
        softLaunchCountry: this.state.softLaunchCountry,
        searchQuery: this.state.searchQuery
      });
    };

    onSearchForGameAfterTogglingSoftLaunch = () => {
      const { isSoftLaunch, softLaunchCountry } = this.state;

      if (!isSoftLaunch && !softLaunchCountry) return;
      this.onSearchForCurrentGameTitle();
    };

    onToggleSoftLaunch = () => {
      const { isSoftLaunch } = this.state;

      this.setState(
        {
          isSoftLaunch: !isSoftLaunch
        },
        () => {
          this.onSearchForGameAfterTogglingSoftLaunch();
          this.onChangeSearchSettings();
        }
      );
    };

    onSearchForCurrentGameTitle = () => {
      const { searchQuery } = this.state;
      this.onSearchForGame(searchQuery);
    };

    onSelectSoftLaunchCountry = softLaunchCountry => {
      this.setState({ softLaunchCountry }, () => {
        this.onSearchForCurrentGameTitle();
        this.onChangeSearchSettings();
      });
    };

    // don't show steam games to android/ios and vice versa
    onSelectGame = game => {
      // This functions is called to either add or remove game from selected games.
      let wasAlreadySelected = false;

      const currentlySelectedGames = (this.state.selectedGames || []).filter(sg => {
        if (isTheSameGame(sg.id, game.id)) wasAlreadySelected = true;
        return sg.platform !== game.platform && !isTheSameGame(sg.id, game.id);
      });

      if (!wasAlreadySelected) currentlySelectedGames.push(game);
      // Find games with matching title from another platform so we can auto-select them
      // Use "dumb" normalizedTitle comparison and also use first match only
      const gamesWithMatchingTitle = (this.props.games || []).find(searchResultGame => {
        return (
          searchResultGame.normalizedTitle === game.normalizedTitle &&
          searchResultGame.platform !== game.platform
        );
      });

      // Finally, combine all found games, preferring the ones that we already had in selection
      const newSelectedGames = (gamesWithMatchingTitle
        ? currentlySelectedGames.concat(gamesWithMatchingTitle)
        : currentlySelectedGames
      ).filter((game, i, arr) => arr.findIndex(g => g.platform === game.platform) === i);

      const selectedGameByPlatform: string = getSelectedGameByPlatform(game.platform);

      this.setState(
        {
          selectedGames: newSelectedGames,
          [selectedGameByPlatform]: false
        },
        this.onChange
      );
    };

    onChangeSearchQuery = searchQuery => {
      this.setState({ searchQuery }, () => {
        this.onChangeSearchSettings();
      });
    };

    onSearchForGame = title => {
      const cleanTitle = title.trim();

      if (!cleanTitle.length) return;

      // Note that we DON'T reset game selections here
      // since games might have similar (but different >_<)
      // titles in different stores, so we want selection
      // to persist between the searches
      const { isSoftLaunch, softLaunchCountry } = this.state;
      this.onChange();
      if (isSoftLaunch && !softLaunchCountry) return;

      this.props.onSearchForGame(title, isSoftLaunch ? softLaunchCountry : null);
    };

    onSelectPlatform = platform => {
      const hasPlatform = this.state.selectedPlatforms.indexOf(platform) !== -1;
      const newSelectedPlatforms = (hasPlatform
        ? this.state.selectedPlatforms.filter(p => {
            return p !== platform;
          })
        : this.state.selectedPlatforms.concat([platform])
      ).filter(p => {
        return platform === Platform.STEAM ? p === Platform.STEAM : p !== Platform.STEAM;
      });

      this.setState(
        {
          selectedPlatforms: newSelectedPlatforms
        },
        this.onChange
      );
    };

    onChangeVertical = campaignType => {
      this.setState(
        {
          campaignType
        },
        this.onChange
      );
    };

    onChange = () => {
      // We keep our internal state unchanged, so game selection
      // persists. But for external component we tell
      // that we selected only games that match currently selected
      // platforms
      const selectedGames = this.state.selectedGames.filter(({ platform }) => {
        return this.state.selectedPlatforms.indexOf(platform) !== -1;
      });
      this.props.onChange({
        selectedGames,
        selectedPlatforms: this.state.selectedPlatforms,
        campaignType: this.state.campaignType
      });
    };

    render() {
      const props = {
        isLoading: this.props.isLoading,
        isDisabled: this.props.isDisabled,
        games: this.props.games,

        campaignType: this.props.data.campaignType,
        selectedGames: this.state.selectedGames,
        onSelectGame: this.onSelectGame,
        onSearchForGame: this.onSearchForGame,
        onChangeSearchQuery: this.onChangeSearchQuery,

        // might need to use getDerivedStateFromProps to update state
        // when props are changing
        // This breaks when we update the component without re-creating it
        isSoftLaunch: this.state.isSoftLaunch,
        softLaunchCountry: this.state.softLaunchCountry,
        searchQuery: this.state.searchQuery,

        onToggleSoftLaunch: this.onToggleSoftLaunch,
        onSelectSoftLaunchCountry: this.onSelectSoftLaunchCountry,

        selectedPlatforms: this.state.selectedPlatforms,
        onSelectPlatform: this.onSelectPlatform,

        showSelectedIosGameFirst: this.state.showSelectedIosGameFirst,
        showSelectedAndroidGameFirst: this.state.showSelectedAndroidGameFirst,
        showSelectedSteamGameFirst: this.state.showSelectedSteamGameFirst,

        onChangeVertical: this.onChangeVertical
      };
      return <Component {...props} />;
    }
  }

  return StatefulProductSelection;
}

function renderCountrySelection(props: StatelessProps) {
  if (!props.isSoftLaunch) return null;

  return (
    <SelectSoftLaunchCountry
      value={props.softLaunchCountry}
      onChange={props.onSelectSoftLaunchCountry}
      disabled={props.isLoading || props.isDisabled}
    />
  );
}

function renderSelectVertical(props: StatelessProps) {
  return <SelectVertical value={props.campaignType} onChange={props.onChangeVertical} />;
}

// TODO: rename SelectGame to something more generic like SelectProduct
// also do the same for all the props that involve "game"
function renderSelectGame(props: StatelessProps) {
  if (!props.campaignType) {
    return null;
  }

  return (
    <SelectGame
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      searchQuery={props.searchQuery}
      onChangeSearchQuery={props.onChangeSearchQuery}
      onSearchForGame={props.onSearchForGame}
      onToggleSoftLaunch={props.onToggleSoftLaunch}
      isSoftLaunch={props.isSoftLaunch}
      campaignType={props.campaignType}
    />
  );
}

function renderPlatforms(props: StatelessProps) {
  if (!props.campaignType) {
    return null;
  }

  const supportedPlatforms =
    props.campaignType === CampaignTypeEnum.APP
      ? [Platform.ANDROID, Platform.IOS]
      : [Platform.ANDROID, Platform.IOS, Platform.STEAM];
  return (
    <SelectPlatform
      selectedPlatforms={props.selectedPlatforms}
      onSelectPlatform={props.isDisabled || props.isLoading ? () => {} : props.onSelectPlatform}
      supportedPlatforms={supportedPlatforms}
    />
  );
}

function renderGames(props: StatelessProps) {
  return (
    <Games
      games={props.games}
      isLoading={props.isLoading}
      selectedGames={props.selectedGames}
      showSelectedIosGameFirst={props.showSelectedIosGameFirst}
      showSelectedAndroidGameFirst={props.showSelectedAndroidGameFirst}
      showSelectedSteamGameFirst={props.showSelectedSteamGameFirst}
      selectedPlatforms={props.selectedPlatforms}
      onSelectGame={props.onSelectGame}
    />
  );
}

function ProductSelection(props: StatelessProps) {
  return (
    <div className="ProductSelection CreateCampaignFields">
      {renderSelectVertical(props)}
      {renderSelectGame(props)}
      {renderCountrySelection(props)}
      {renderPlatforms(props)}

      {renderGames(props)}
    </div>
  );
}

export default stateful(ProductSelection);
