import React from 'react';

import Icon from '../../../images/icons/TabsIconDemographics';

import './Icons.scss';

const DemographicsIcon = ({ isActive }) => {
  const iconStyles = isActive ? 'icon TabIcon mdTabIcon activeTabIcon' : 'icon TabIcon mdTabIcon';
  return (
    <span className={iconStyles}>
      <Icon />
    </span>
  );
};

export default DemographicsIcon;
