// @flow
import * as React from 'react';
import type { GameToImport } from '../../../types/game.flow';

import classNames from 'classnames';

import './Game.scss';
import './SteamGame.scss';

type Props = GameToImport & {
  isSelected: boolean,
  onSelect: () => void
};

export default function SteamGame(props: Props) {
  const className = classNames('SteamGame Game', {
    'SteamGame--selected': props.isSelected
  });
  return (
    <div onClick={props.onSelect} className={className}>
      <div className="SteamGame__logo">
        <img referrerPolicy="no-referrer" alt={props.title} src={props.logoUrl} />
      </div>
      <div className="SteamGame__data">
        <div className="SteamGame__title">
          <strong>{props.title}</strong>
        </div>
        <div className="SteamGame__publisher">{props.publisherName}</div>
      </div>
    </div>
  );
}
