// The cpi key tells whether the service supports CPI or not
import { AttributionPartner } from '@sharkpunch/matchmade-common/campaign';

export const services = [
  { value: AttributionPartner.NONE, label: 'No attribution', cpi: false },
  { value: AttributionPartner.ADJUST, label: 'Adjust', cpi: true },
  { value: AttributionPartner.APPSFLYER, label: 'AppsFlyer', cpi: true },
  { value: AttributionPartner.BUFFPANEL, label: 'BuffPanel', cpi: true },
  { value: AttributionPartner.KOCHAVA, label: 'Kochava', cpi: true },
  { value: AttributionPartner.MATCHMADE_LINK, label: 'Simple redirect', cpi: false },
  { value: AttributionPartner.MATCHMADE_PIXEL, label: 'Pixel tracking', cpi: true },
  { value: AttributionPartner.TENJIN, label: 'Tenjin', cpi: true },
  { value: AttributionPartner.TUNE, label: 'Branch', cpi: true },
  { value: AttributionPartner.SINGULAR, label: 'Singular', cpi: true },
  { value: AttributionPartner.OTHER, label: 'Other', cpi: false }
];

export function getService(name) {
  return services.find(({ value }) => value === name);
}

export const canDoCPI = attributionPartner => {
  const partner = services.find(service => {
    return service.value === attributionPartner;
  });
  return partner && partner.cpi;
};
