// @flow
import type { ReducerCreator } from '../../types/action.flow';

import Immutable from 'immutable';

import { fetchInfluencers, mock, resetMock } from '../../actions/influencer';

export default {
  fetchInfluencers(state: any, action: any) {
    switch (action.type) {
      case fetchInfluencers.REQUEST:
        console.warn('DEPRECATED! Use handleFetchInfluencersActions instead');
        return state.set('isLoadingInfluencers', true);
      case fetchInfluencers.SUCCESS:
        console.warn('DEPRECATED! Use handleFetchInfluencersActions instead');
        return state
          .set('isLoadingInfluencers', false)
          .set('influencers', Immutable.fromJS(action.payload));
      case fetchInfluencers.FAILURE:
        console.warn('DEPRECATED! Use handleFetchInfluencersActions instead');
        return state
          .set('isLoadingInfluencers', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  mock(state: any, action: any) {
    switch (action.type) {
      case mock.REQUEST:
        return state.set('mockInfluencer', null).set('isImportingMockInfluencer', true);
      case mock.SUCCESS:
        return state
          .set('isImportingMockInfluencer', false)
          .set('mockInfluencer', Immutable.fromJS(action.payload));
      case mock.FAILURE:
        return state
          .set('isImportingMockInfluencer', false)
          .set('mockInfluencer', null)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  resetMock(state: any, action: any) {
    switch (action.type) {
      case resetMock.SUCCESS:
        return state.set('mockInfluencer', null);

      default:
        return state;
    }
  }
};

export type FetchInfluencersAttributes = {
  isLoadingInfluencers: boolean,
  influencers: Object[] | null,
  error: Object | null
};
export const handleFetchInfluencersActions: ReducerCreator<
  void,
  FetchInfluencersAttributes
> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchInfluencers.REQUEST:
        return {
          ...state,
          isLoadingInfluencers: true,
          error: null
        };
      case fetchInfluencers.SUCCESS:
        return {
          ...state,
          isLoadingInfluencers: false,
          influencers: action.payload,
          error: null
        };
      case fetchInfluencers.FAILURE:
        return {
          ...state,
          isLoadingInfluencers: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};
