// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import type { IntlShape } from 'react-intl';

import { InvitedChannels, ManagedChannels } from '../../components/managerTeam/Channels';
import { TeamMembers } from '../../components/managerTeam/TeamMembers';
import {
  fetchManagerTeamById,
  fetchManagerTeamChannelInvites,
  fetchManagerTeamChannels,
  fetchManagerTeamMembers,
  removeManagedChannel,
  updateManagerTeam
} from '../../actions/managerTeam';
import EditableHeader from '../../components/common/EditableHeader';
import Error from '../../components/common/Error';
import compose from '../../hoc/compose';
import generateStateToProps from '../../helpers/generateStateToProps';
import type { AdminManagerTeamPage as AdminManagerTeamPageType } from '../../reducers/containers/admin/adminManagerTeamPage';
import type { Team } from '../../types/team.flow';

import './AdminManagerTeamPage.scss';

type Props = AdminManagerTeamPageType & {|
  params: {
    teamId: string
  },
  team: Team,
  intl: IntlShape
|};

const AdminManagerTeamPage = (props: Props) => {
  const { params, team } = props;
  const dispatch = useDispatch();
  const teamName = team ? team.name : null;

  React.useEffect(() => {
    dispatch(fetchManagerTeamById.run(params.teamId));
    dispatch(fetchManagerTeamMembers.run(params.teamId));
    dispatch(fetchManagerTeamChannels.run(params.teamId));
    dispatch(fetchManagerTeamChannelInvites.run(params.teamId));
  }, [dispatch, params.teamId]);

  return (
    <div className="AdminManagerTeamPage container">
      <Error error={props.error} />
      <div className="TeamInfo box">
        <EditableHeader
          text={teamName || ''}
          suffix={
            <FormattedMessage
              id="admin.managerTeam.members.title"
              values={{
                membersCount: props.members ? props.members.length : 0
              }}
            />
          }
          onEdit={name => {
            dispatch(updateManagerTeam.run(params.teamId, { name }));
          }}
        />
        <TeamMembers
          isLoading={props.isLoadingTeam || props.isLoadingMembers}
          members={props.members || []}
        />
      </div>
      <div className="ManagedChannels box">
        <ManagedChannels
          onRemoveManagedChannel={channelId => {
            dispatch(removeManagedChannel.run(channelId, team.id));
          }}
          channels={props.channels}
          isLoading={props.isLoadingChannels}
        />
      </div>
      <div className="InvitedChannels box">
        <InvitedChannels invites={props.invites} isLoading={props.isLoadingInvites} />
      </div>
    </div>
  );
};

const mapStateToProps = generateStateToProps('adminManagerTeamPage');

export default compose(connect(mapStateToProps))(AdminManagerTeamPage);
