import { Bar as BarChart } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import React from 'react';

import { getColorVariables } from '../../../config';
import { getInstallsAndClicksByPlatformGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 455; // 32.5rem;

const InstallsAndClicksByPlatformGraph = ({ clicksAndInstallsByPlatform, intl }) => {
  const variables = getColorVariables();
  const labels = clicksAndInstallsByPlatform.map(({ platform }) => platform);
  const installsByPlatform = clicksAndInstallsByPlatform.map(({ installs }) => installs);
  const clicksByPlatform = clicksAndInstallsByPlatform.map(({ clicks }) => clicks);
  const { chart } = getGraphConfig(labels, installsByPlatform, clicksByPlatform, variables);

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default injectIntl(InstallsAndClicksByPlatformGraph);
