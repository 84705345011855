// @flow
import { getChats } from '../../actions/chat';

import type { Action } from '../../types/action.flow';

export type State = {|
  chats: any[],
  isChatsLoading: boolean
|};

export const initialState: State = Object.freeze({
  chats: [],
  isChatsLoading: false
});

export default function chatReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case getChats.REQUEST:
      return {
        ...state,
        isChatsLoading: true
      };

    case getChats.SUCCESS:
      return {
        ...state,
        isChatsLoading: false,
        chats: action.payload
      };

    case getChats.FAILURE:
      return {
        ...state,
        isChatsLoading: false
      };

    default:
      return state;
  }
}
