// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import AttributionInfoTooltip from './AttributionInfoTooltip';
import ServiceWithOneTrackingUrlAndInstruction from './ServiceWithOneTrackingUrlAndInstruction';

import type { Props } from './ServiceWithOneTrackingUrlAndInstruction';

import getConfig from '../../../../config';
const trackingUrl = getConfig('tracking.url');

const iconTooltipData = [
  { weSend: 'the campaign name', as: 'utm_campaign' },
  { weSend: 'YouTube channel name', as: 'utm_content' },
  { weSendHighlighted: getConfig('appName'), as: 'utm_source' },
  { weSendHighlighted: 'YouTube', as: 'utm_medium' }
];

export default function MatchmadePixel(props: Props) {
  return (
    <ServiceWithOneTrackingUrlAndInstruction {...props} name="matchmadePixel">
      <FormattedMessage
        tagName="p"
        id="campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.instruction"
      />
      <div className="CampaignAttributionPixelPromptBlock__code-block">
        <FormattedMessage
          tagName="p"
          id="campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.link"
          values={{
            trackingUrl,
            campaignId: 'put-actual-campaign-id-here'
          }}
        />
      </div>
      <AttributionInfoTooltip list={iconTooltipData} />
    </ServiceWithOneTrackingUrlAndInstruction>
  );
}
