import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  avatarUrl: PropTypes.string,
  videoCount: PropTypes.number,
  subscriberCount: PropTypes.number,
  commentCount: PropTypes.number,
  last30dStats: PropTypes.object,
  backgroundUrl: PropTypes.string,
  demographics: PropTypes.object,
  published: PropTypes.string,
  country: PropTypes.string,
  estimatedCountry: PropTypes.string,
  contactInfo: PropTypes.object
});
