// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import AttributionInfoTooltip from './AttributionInfoTooltip';

import type { Props } from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import ServiceWithOneTrackingUrlOrPlatformSpecific from './ServiceWithOneTrackingUrlOrPlatformSpecific';
import getConfig from '../../../../config';

import './AttributionInfo.scss';

const iconTooltipData = [
  { weSend: 'the campaign name', as: 'utm_campaign' },
  { weSendHighlighted: getConfig('appName'), as: 'utm_source' },
  { weSendHighlighted: 'YouTube', as: 'utm_medium' },
  { weSend: 'YouTube channel name', as: 'utm_content' }
];

export default function BuffPanel(props: Props) {
  return (
    <div className="AttributionInfo">
      <ServiceWithOneTrackingUrlOrPlatformSpecific {...props} name="buffpanel" />
      <div className="AttributionInfo__description">
        <AttributionInfoTooltip list={iconTooltipData} />
        <FormattedMessage
          id="publisher.campaign.attribution.buffpanelDescription"
          values={{
            link: (...chunks) => {
              return (
                <a href="http://buffpanel.com/help/generating_links_for_your_campaigns">{chunks}</a>
              );
            }
          }}
        />
      </div>
    </div>
  );
}
