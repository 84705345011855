import React from 'react';

const TabsIconDemographics = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      d="M21.818 15c2.597 0 4.582-1.95 4.582-4.5S24.415 6 21.818 6c-2.596 0-4.582 1.95-4.582 4.5s1.986 4.5 4.582 4.5zm-11.636 0c2.596 0 4.582-1.95 4.582-4.5S12.778 6 10.182 6C7.585 6 5.6 7.95 5.6 10.5S7.585 15 10.182 15zm.318 2C7.05 17 0 18.8 0 22.25V26h21v-3.75C21 18.8 13.95 17 10.5 17zm9.61.222c1.805.696 3.785 2.702 3.785 4.802V26H32v-3.75C32 18.8 25.164 17 21.818 17c-.436 0-1.258.067-1.708.222z"
      fill="#2D3767"
      fillRule="evenodd"
    />
  </svg>
);

export default TabsIconDemographics;
