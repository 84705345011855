import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchEmailTemplates, previewEmailTemplate, sendEmailTemplate } from '../../actions/email';
import EmailTemplateTester from '../../components/admin/EmailTemplateTester';
import React, { Component } from 'react';

import './AdminDashboardCommon.scss';

import { Helmet } from 'react-helmet';
import Spinner from '../../components/common/Spinner';
import pickState from '../pickState';
import size from 'lodash/size';

class AdminDashboardEmail extends Component {
  componentWillMount() {
    this.props.fetchEmailTemplates();
  }

  render() {
    const { emailTemplates, isLoadingEmailTemplates } = this.props;
    let content = null;
    let rootClasses = ['AdminDashboardEmail'];

    if (isLoadingEmailTemplates) {
      content = <Spinner mode="fullWidth" size="large" />;
    } else if (!size(emailTemplates)) {
      content = (
        <div className="hero">
          <div className="hero-body">
            <h2 className="title">
              <FormattedMessage id="error.nodata" />
            </h2>
          </div>
        </div>
      );
    } else {
      rootClasses.push('container');
      content = <EmailTemplateTester {...this.props} />;
    }

    return (
      <div className={rootClasses.join(' ')}>
        <Helmet title="Email" />
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return pickState(state, 'layout', 'adminDashboardEmail');
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchEmailTemplates() {
      return dispatch(fetchEmailTemplates.run());
    },
    previewEmailTemplate(templateName, recipient, variables) {
      return dispatch(previewEmailTemplate.run(templateName, recipient, variables));
    },
    sendEmailTemplate(templateName, recipient, variables) {
      return dispatch(sendEmailTemplate.run(templateName, recipient, variables));
    }
  };
};

AdminDashboardEmail = connect(mapStateToProps, mapDispatchToProps)(AdminDashboardEmail);

export default AdminDashboardEmail;
