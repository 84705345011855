// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import InfoTooltip from '../../common/InfoTooltip';
import type { IntlShape } from 'react-intl';

type Props = {
  value: string,
  onChange: (name: string) => void,

  isDisabled: boolean,

  intl: IntlShape
};

Name.defaultProps = {
  isDisabled: false
};

function Name(props: Props) {
  return (
    <div className="field is-horizontal">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.campaignDescription.name" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.campaignDescription.name.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              value={props.value}
              placeholder={props.intl.formatMessage({
                id: 'campaignCreation.campaignDescription.name.placeholder'
              })}
              onChange={e => {
                props.onChange(e.target.value);
              }}
              disabled={props.isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Name);
