// @flow
import React from 'react';

import Avatar from '../../messages/Avatar';
import Form from '../../forms/Form';
import Input from '../../forms/controls/Input';
import Spinner from '../../common/Spinner';

import './BasicChannelInfo.scss';

type Props = {
  error?: Object,
  isLoading?: boolean,
  onChange: Function,
  searchChannel: Function,
  channel?: {
    email: string,
    avatarUrl: string,
    displayName: string,
    contactEmail: string,
    contactPersonName: string,
    channelUrl: string
  }
};

const BasicChannelInfo = (props: Props) => {
  const { error, onChange, searchChannel, isLoading = false, channel = {} } = props;

  return (
    <Form
      className="BasicChannelInfo section is-flex"
      onChange={onChange}
      error={error}
      values={channel}>
      <Avatar url={channel.avatarUrl} size={128} shape={channel.avatarUrl ? 'shield' : null} />
      {isLoading && <Spinner size="large" mode="overlay" />}
      <div className="BasicChannelInfo__fields">
        <div className="field">
          <Input
            name="channelUrl"
            type="text"
            onBlur={searchChannel}
            placeholder="Channel url or name"
            label="Channel url"
            size="large"
          />
        </div>
        <div className="field is-horizontal">
          <div className="control">
            <Input name="name" type="text" placeholder="Influencer name" label="Influencer name" />
          </div>
          <div className="control">
            <Input
              name="contactPersonName"
              type="text"
              placeholder="Contact person name"
              label="Contact person name"
            />
          </div>
          <div className="control">
            <Input
              name="contactEmail"
              type="email"
              placeholder="Contact person email"
              label="Contact person email"
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default BasicChannelInfo;
