import PropTypes from 'prop-types';
import React from 'react';

const getStarIcon = (roundedRating, i) => {
  if (roundedRating > i && roundedRating < i + 1) {
    return 'star_half';
  } else if (roundedRating > i) {
    return 'star';
  } else {
    return 'star_border';
  }
};

const StarsRating = ({ size, value }) => {
  const roundedRating = Math.round(value * 2) / 2 || 0;
  const starsArray = Array.from(Array(5), () => null);
  return starsArray.map((x, i) => {
    return (
      <i key={`${value}-${i}`} className="material-icons">
        {getStarIcon(roundedRating, i)}
      </i>
    );
  });
};

StarsRating.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number
};

StarsRating.defaultProps = {
  value: 0,
  size: 20
};

export default StarsRating;
