// @flow
import * as React from 'react';
import type { GameToImport } from '../../../types/game.flow';

import classNames from 'classnames';

import './Game.scss';
import './MobileGame.scss';
import CloseIcon from '../../common/Icons/CloseIcon';

type Props = GameToImport & {
  isSelected: boolean,
  onSelect: () => void
};

export default function MobileGame(props: Props) {
  const className = classNames('MobileGame Game', {
    'MobileGame--selected': props.isSelected
  });
  return (
    <div onClick={props.onSelect} className={className}>
      <img referrerPolicy="no-referrer" alt={props.title} src={props.logoUrl} />
      <div className="MobileGame__data">
        {props.isSelected && (
          <button onClick={props.onSelect} className="link-button MobileGame__data-close">
            <CloseIcon />
          </button>
        )}
        <div className="MobileGame__title">
          <strong>{props.title}</strong>
        </div>
        <div className="MobileGame__publisher">{props.publisherName}</div>
      </div>
    </div>
  );
}
