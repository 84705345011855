// @flow
import * as React from 'react';
import classNames from 'classnames';

import './CentToDollarInput.scss';

type CommonProps = {
  className?: string,
  allowDecimal?: boolean,
  isDisabled?: boolean,
  placeholder?: string,
  key?: string
};

type StatelessProps = CommonProps & {
  value: number | string, // cents
  onBlur?: (value: number | string) => void, // cents
  onChange: (value: number | string) => void, // cents
  onEnter?: (value: number | string) => void // cents
};

function cleanupUserInput(value: string) {
  return value.replace(/ /g, '').replace(',', '.');
}

// User typed something like `7.` or `.42` or `4.0`
function looksLikeIncompleteNumber(value) {
  return value.startsWith('.') || value.endsWith('.') || value.endsWith('.0');
}

function CentToDollarInput({
  value,
  onChange = (value: number | string) => {},
  onBlur = (value: number | string) => {},
  onEnter = () => {},
  className,
  allowDecimal = true,
  isDisabled = false,
  placeholder = '',
  key = 'centToDollarInput'
}: StatelessProps) {
  let formattedValue = value;
  if (typeof value === 'number') {
    const parsedValue = parseFloat(value / 100);
    formattedValue = !isNaN(parsedValue)
      ? // toLocaleString formats thousands with comma delimiters,
        // but we want it to be padded with ' ' instead
        // We also force locale here to be en-GB, so it works the same way
        // regardless of OS/browser locale settings
        parsedValue.toLocaleString('en-GB').replace(/,/g, ' ')
      : value;
  }

  const inputPattern = allowDecimal ? '[\\d ]*[,|.]?\\d{0,2}' : '[\\d ]*';
  return (
    <input
      key={key}
      value={formattedValue}
      className={classNames('input CentToDollarInput', className && { [className]: !!className })}
      type="text"
      // Can't use number type, since we want to display spaces. Still, limit user inputs only to numbers and ,.
      pattern={inputPattern}
      disabled={isDisabled}
      placeholder={placeholder}
      onBlur={e => {
        // when user enters it's in dollars, we need to convert it to cents
        const userInputValue = cleanupUserInput(e.target.value);
        const value = parseFloat(userInputValue) * 100;
        onBlur(isNaN(value) ? userInputValue : value);
      }}
      onChange={e => {
        if (!e.target.checkValidity()) return;
        const currentValue = cleanupUserInput(e.target.value);
        const currentValueAsFloat = parseFloat(currentValue);
        if (isNaN(currentValueAsFloat) || looksLikeIncompleteNumber(currentValue)) {
          return onChange(currentValue);
        }

        // remove the leading 0 when we don't have any decimals
        if (
          currentValue.length > 1 &&
          currentValue.indexOf('.') === -1 &&
          currentValue.indexOf(',') === -1 &&
          currentValue.charAt(0) === '0'
        ) {
          e.target.value = currentValueAsFloat + '';
        }

        // when user enters it's in dollars, we need to convert it to cents
        onChange(currentValueAsFloat * 100);
      }}
      onKeyPress={e => {
        if (e.key !== 'Enter') {
          return;
        }
        onEnter(e.target.value);
      }}
    />
  );
}

export type CurrencyOnChange = (value: number | '') => void; // cents or empty string (to clear input)
type StatefulProps = CommonProps & {
  value: number | '', // allow to pass empty string
  onChange: CurrencyOnChange,
  onEnter?: Function
};

type State = {
  tempValue: number | string, // cents
  value: number | string // cents
};

function stateful(
  Component: React.ComponentType<StatelessProps>
): React.ComponentType<StatefulProps> {
  class StatefulCentToDollarInput extends React.Component<StatefulProps, State> {
    constructor(props) {
      super(props);

      this.state = {
        value: props.value,
        tempValue: props.value
      };
    }

    static getDerivedStateFromProps(props, state) {
      const { value } = state;

      if (value !== props.value) {
        return {
          value: props.value,
          tempValue: props.value
        };
      }

      return null;
    }

    triggerOnChangeWithCurrentValueOrZero = value => {
      // only trigger onChange when we have a number or an empty string
      // ignore all other cases
      if (typeof value === 'number') {
        this.props.onChange(value);
      } else if (typeof value === 'string' && !value.trim().length) {
        // Explicitly rigger onChange with empty string, so we can clear numeric field
        this.props.onChange('');
      }
    };

    onChange = value => {
      this.setState(
        {
          tempValue: value
        },
        () => {
          this.triggerOnChangeWithCurrentValueOrZero(value);
        }
      );
    };

    onBlur = value => {
      this.setState(
        {
          tempValue: value,
          value
        },
        () => {
          this.triggerOnChangeWithCurrentValueOrZero(value);
        }
      );
    };

    onEnter = () => {
      this.onBlur(this.state.tempValue);
      this.props.onEnter && this.props.onEnter(this.state.tempValue);
    };

    render() {
      // Explicitly exclude onChange, otherwise flow
      // gets confused with which onChange ends up in Component
      const { onChange, ...rest } = this.props;
      const props = {
        ...rest,
        onChange: this.onChange,
        onEnter: this.onEnter,
        onBlur: this.onBlur,
        value: this.state.tempValue
      };

      return <Component {...props} />;
    }
  }

  return StatefulCentToDollarInput;
}

export default stateful(CentToDollarInput);
