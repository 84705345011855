/**
 * Floating footer component, stuck to the bottom.
 *
 * Children are rendered twice:
 *  - once in the visible "floating" div
 *  - and once in the "spacer" element, to make sure
 *    that floating footer doesn't overlay on top of
 *    whatever happens to be at the bottom of the page
 * Keep that in mind
 *
 * It would be great to fix it in some purely css way :/
 */
import React from 'react';

import './FloatingFooter.scss';

const FloatingFooter = ({ children }) => {
  if (!children) return null;

  return (
    <div className="FloatingFooter">
      <div className="FloatingFooter__spacer">{children}</div>
      <div className="FloatingFooter__content">{children}</div>
    </div>
  );
};

export default FloatingFooter;
