import PropTypes from 'prop-types';
import React, { Component } from 'react';

import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';

import { renderValidationError } from '../../helpers/formHelper';

import teamShape from '../../shapes/team';

import { FormattedMessage, injectIntl } from 'react-intl';

class TeamForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getTeamFromProps(props);

    this.onChangeTeamName = this.onChangeTeamName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getTeamFromProps(props) {
    const team = props.team ? clone(props.team) : {};

    return {
      team
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.team, nextProps.team)) {
      this.setState(this.getTeamFromProps(nextProps));
    }
  }

  renderTitle() {
    const title = this.state.team.id ? (
      <FormattedMessage id="publisher.team.updateTeam" />
    ) : (
      <FormattedMessage id="publisher.team.createTeam" />
    );
    return <div className="subtitle">{title}</div>;
  }

  onChangeTeamName(e) {
    this.setState({
      team: Object.assign(this.state.team, { name: e.target.value })
    });
  }

  renderNameInput() {
    const nameError = renderValidationError(this.props.error, 'name');

    return [
      <label key="team-name-label" className="label">
        <FormattedMessage id="publisher.team.name" />
      </label>,
      <div key="team-name-input" className="control has-icons has-icon-right">
        <input
          className={`input ${nameError ? 'is-danger' : ''}`}
          type="text"
          placeholder={this.props.intl.formatMessage({
            id: 'publisher.team.name.placeholder'
          })}
          value={this.state.team.name}
          disabled={this.props.isLoading}
          onChange={this.onChangeTeamName}
        />
        {nameError}
      </div>
    ];
  }

  onSubmit(e) {
    e.preventDefault();
    const { team } = this.state;
    this.props.onSubmit(team);
  }

  render() {
    const btnText = this.state.team.id ? (
      <FormattedMessage id="form.save" />
    ) : (
      <FormattedMessage id="form.create" />
    );

    return (
      <div className="TeamForm">
        <form onSubmit={this.onSubmit}>
          {this.renderTitle()}
          <div className="field">{this.renderNameInput()}</div>

          <div className="field is-grouped">
            <p className="control">
              <button className="button is-primary" disabled={this.props.isLoading}>
                {btnText}
              </button>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

TeamForm.propTypes = {
  team: teamShape,
  displayMode: PropTypes.oneOf(['full', 'minimal']),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

TeamForm.defaultProps = {
  displayMode: 'full',
  onSubmit: team => {}
};

export default injectIntl(TeamForm);
