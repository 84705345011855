// @flow
import callApi from '../../helpers/api';
// $FlowFixMe
import { useAsync } from 'react-async';
import Button, { Color } from '../common/Button';
import React from 'react';

const toggleChat = (agreementId: number, payload: { chatClosed: boolean }) => {
  return callApi(`/agreements/${agreementId}`, { method: 'PUT', body: payload });
};

type Props = {
  agreementId: number,
  closed: boolean,
  onChatClosed: Function
};

export const ChatToggler = (props: Props) => {
  const { agreementId, closed, onChatClosed } = props;
  const { run, isPending } = useAsync(
    {
      deferFn: () => toggleChat(agreementId, { chatClosed: !closed }),
      onResolve: res => {
        const { chatClosed } = res.data;
        onChatClosed(chatClosed);
      }
    },
    { agreementId }
  );
  return (
    <div style={{ width: '10rem', marginBottom: '2rem' }}>
      <Button
        color={Color.PRIMARY}
        className="Button__main-action"
        loading={isPending}
        onClick={() => {
          run();
        }}>
        {closed ? <p>Enable chat</p> : <p>Disable chat</p>}
      </Button>
    </div>
  );
};
