//@flow

import React, { useEffect, useState } from 'react';

import callApi from '../../helpers/api';

//$FlowFixMe
import { useAsync } from 'react-async';
import useError from '../../hooks/useError';

import './CampaignBudgetLock.scss';
import CampaignListModal from './CampaignListModal';
import CentToDollarInput from '../campaignCreation/common/CentToDollarInput';
import FormattedNumber from '../common/FormattedNumber';

type CampaignBudgetLockProps = {
  campaignId: number
};

type BudgetStats = {
  allocated: number,
  unallocated: number,
  acceptedOffers: number,
  pendingOffers: number,
  campaigns?: number[]
};

type Budget = {
  id: string,
  maximum: number,
  name?: string
};

type CampaignBudgetLockStatelessProps = {
  budget: ?Budget,
  isBudgetLoading: boolean,
  stats: ?BudgetStats,
  isStatsLoading: boolean,
  saveBudget: Function,
  isSavingBudget: boolean
};

type BudgetLockAmountProps = Budget & {
  isLoading: boolean,
  onSave: Function
};

type BudgetLockStatsProps = BudgetStats & {
  isLoading: boolean
};

const getBudgetLock = ({ campaignId }) => {
  return callApi(`/campaigns/${campaignId}/budget-lock`);
};

const setBudgetLock = params => {
  return callApi(`/campaigns/${params.campaignId}/budget-lock`, {
    method: 'PUT',
    body: { amount: params.amount }
  });
};

const getBudgetLockStats = budgetId => {
  return callApi(`/budgets/${budgetId}/stats`);
};

const BudgetLockAmount = (props: BudgetLockAmountProps) => {
  const { id, maximum, isLoading } = props;
  const [amount, setAmount] = useState(maximum);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => setAmount(maximum), [maximum]);

  const onSave = () => {
    props.onSave(amount);
    setShowEdit(false);
  };

  return (
    <div className="CampaignBudgetLockAmount">
      {!showEdit ? (
        <React.Fragment>
          <h2 className="CampaignBudgetLockAmount__readonly-value">
            {id ? <FormattedNumber type="cost" value={amount || 0} /> : 'not in use'}
          </h2>
          <button className="button is-secondary" onClick={() => setShowEdit(true)}>
            {id ? 'Edit' : 'Add budget lock'}
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="CampaignBudgetLockAmount__editable-value field no-margin-bottom">
            <p className="FullDollarControlInput control has-icons-left">
              <CentToDollarInput
                value={amount || 0}
                isDisabled={isLoading}
                onChange={setAmount}
                onEnter={onSave}
                allowDecimal={false}
              />
              <span className="icon is-small is-left">
                <i className="material-icons">attach_money</i>
              </span>
            </p>
          </div>
          <div className="field no-margin-bottom">
            <button
              className="button is-primary"
              type="submit"
              onClick={onSave}
              style={{ marginRight: '0.25rem' }}>
              Save
            </button>
            <button className="button is-secondary" onClick={() => setShowEdit(false)}>
              Cancel
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const BudgetLockStats = (props: BudgetLockStatsProps) => {
  const {
    allocated,
    unallocated,
    acceptedOffers,
    pendingOffers,
    isLoading,
    campaigns = []
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  if (isLoading) {
    return <div>Loading stats...</div>;
  } else {
    return (
      <React.Fragment>
        <div className="stats-elem">
          <span className="num">
            <FormattedNumber type="cost" value={allocated} />
          </span>
          <span className="label">Allocated</span>
        </div>
        <div className="stats-elem">
          <span className="num">
            <FormattedNumber type="cost" value={unallocated} />
          </span>
          <span className="label">Unallocated</span>
        </div>
        <div className="stats-elem">
          <span className="num">
            <FormattedNumber type="number" value={acceptedOffers} />
          </span>
          <span className="label">Accepted offers</span>
        </div>
        <div className="stats-elem">
          <span className="num">
            <FormattedNumber type="number" value={pendingOffers} />
          </span>
          <span className="label">Pending offers</span>
        </div>
        <div className="stats-elem">
          <span className="num">
            <FormattedNumber type="number" value={campaigns.length || 1} />
          </span>
          <button
            className="is-block button is-white has-text-primary"
            onClick={() => setIsOpen(true)}>
            Campaigns
          </button>
        </div>
        <CampaignListModal isOpen={isOpen} setIsOpen={setIsOpen} campaignIds={campaigns} />
      </React.Fragment>
    );
  }
};

const CampaignBudgetLock = (props: CampaignBudgetLockStatelessProps) => {
  const { budget, isBudgetLoading, stats, isStatsLoading, saveBudget } = props;

  return (
    <div className="box CampaignBudgetLock">
      <div className="CampaignBudgetLock__amount-block">
        <div className="CampaignBudgetLock__amount-block-wrapper">
          <h2 className="CampaignBudgetLock__amount-block-label">Budget lock</h2>
          <BudgetLockAmount
            id={budget ? budget.id : ''}
            maximum={budget ? budget.maximum : 0}
            isLoading={isBudgetLoading || isStatsLoading}
            onSave={amount => saveBudget(amount)}
          />
        </div>
        <div className="CampaignBudgetLock__stats-block">
          {budget && stats && (
            <BudgetLockStats
              allocated={stats.allocated}
              unallocated={stats.unallocated}
              acceptedOffers={stats.acceptedOffers}
              pendingOffers={stats.pendingOffers}
              campaigns={stats.campaigns}
              isLoading={isStatsLoading}
            />
          )}
        </div>
      </div>
      <div className="CampaignBudgetLock__warning-block">
        <span className="tag is-danger">Warning</span> These numbers are{' '}
        <strong>without commission</strong>. The budget lock is only applied to Offers sent via bulk
        tools.{' '}
        {budget && (
          <div className="mt-2">
            <p>
              Budget ID <span className="tag is-light">{budget.id}</span>
            </p>
            <p>
              Budget name <span className="tag is-light">{budget.name}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default function (props: CampaignBudgetLockProps) {
  const { campaignId } = props;
  const { showError } = useError();
  const [budget, setBudget] = useState(null);
  const [stats, setStats] = useState(null);
  const setBudgetAndGetStats = budget => {
    if (!budget) {
      return;
    }

    setBudget(budget);
    getStats(budget.id);
  };

  const { isLoading: isBudgetLoading } = useAsync({
    promiseFn: getBudgetLock,
    onResolve: response => setBudgetAndGetStats(response.data),
    onReject: error => showError(error),
    campaignId: campaignId
  });

  const { run: getStats, isLoading: isStatsLoading } = useAsync({
    deferFn: ([budgetId]) => getBudgetLockStats(budgetId),
    onResolve: response => setStats(response.data),
    onReject: error => showError(error)
  });

  const { run: saveBudget, isLoading: isSavingBudget } = useAsync({
    deferFn: ([amount]) => setBudgetLock({ campaignId, amount }),
    onResolve: response => setBudgetAndGetStats(response.data),
    onReject: error => showError(error)
  });

  return (
    <CampaignBudgetLock
      campaignId={props.campaignId}
      budget={budget}
      isBudgetLoading={isBudgetLoading}
      stats={stats}
      isStatsLoading={isStatsLoading}
      saveBudget={saveBudget}
      isSavingBudget={isSavingBudget}
    />
  );
}
