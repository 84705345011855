// @flow
import * as React from 'react';
import classNames from 'classnames';

import Tooltip from './Tooltip';

import './Button.scss';

export const Color = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  WHITE: 'white',
  LIGHT: 'light',
  TEXT: 'text',
  INFO: 'info'
};

export type Props = {
  className?: string,
  buttonClassName?: string,
  background?: React.Element<*> | React.Element<*>[],
  color?: $Values<typeof Color>,
  wrapLabel?: boolean,
  round?: boolean,
  disabled?: boolean,
  loading?: boolean,
  transparent?: boolean,
  size?: 'small' | 'medium' | 'large',
  children: React.Element<*> | React.Element<*>[],
  onClick?: () => void,
  type?: string,
  tooltip?: React.Node
};

const Button = (props: Props) => {
  const {
    className,
    buttonClassName,
    color,
    background = null,
    wrapLabel = false,
    round = false,
    disabled = false,
    loading = false,
    transparent = false,
    size = null,
    children,
    onClick,
    type = 'button',
    tooltip
  } = props;
  const finalClassName = classNames(className, 'control Button', {
    'Button--wrapped': wrapLabel,
    'Button--round': round,
    'Button--transparent': transparent,
    [`Button--${color || ''}`]: color
  });

  const finalButtonClassName = classNames('button', buttonClassName, {
    'is-loading': loading,
    [`is-${color || ''}`]: color,
    [`is-${size || ''}`]: size
  });

  const clickHandler = disabled ? () => {} : onClick;

  const btn = (
    <p className={finalClassName} onClick={clickHandler}>
      {background}
      <button type={type} className={finalButtonClassName} disabled={disabled || loading}>
        {children}
      </button>
    </p>
  );

  if (!tooltip) {
    return btn;
  }

  return <Tooltip tooltip={tooltip}>{btn}</Tooltip>;
};

export default Button;
