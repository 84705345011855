// @flow
import Icon from '../Icon';
import React from 'react';
import type { IconProps } from '../Icon';

import './Icons.scss';

const FolderIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      {() => (
        <i className="material-icons" style={{ transform: 'scale(-1, 1)' }}>
          folder
        </i>
      )}
    </Icon>
  );
};

export default FolderIcon;
