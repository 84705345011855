// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import EmailCopyIcon from '../common/EmailCopyIcon';
import Link from 'react-router/lib/Link';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import get from 'lodash/get';
import moment from 'moment';
import type { ManagerTeam } from '../../types/team.flow';

import './TeamsTable.scss';

type Props = {
  managerTeams: ManagerTeam[]
};

const ManagerTeamsTable = ({ managerTeams }: Props) => {
  return (
    <Table
      data={managerTeams}
      initialSort="id"
      initialSortDirection="desc"
      className="TeamsTable is-fullwidth"
      pagination={managerTeams.length > pageLimitOptions[1]}
      searchBy={[
        'name',
        ({ members }, searchInput) => {
          const searchInputRegex = new RegExp(searchInput, 'gi');
          return (members || []).some(({ displayName, email }) => {
            return searchInputRegex.test(displayName) || searchInputRegex.test(email);
          });
        }
      ]}>
      <Column name="id">
        <FormattedMessage id="admin.managerTeams.table.header.id" />
      </Column>
      <Column
        name="teamName"
        component={props => (
          <Link to={`/admin/manager-teams/${props.rowData.id}`}>{props.rowData.name}</Link>
        )}>
        <FormattedMessage id="admin.managerTeams.table.header.name" />
      </Column>
      <Column
        name="members"
        sortBy={(a, b) => {
          a = a || [];
          b = b || [];

          // desc so that we see publisher belonging to the most teams
          if (a.length !== b.length) return a.length - b.length;

          const aName = get(a[0], 'account.displayName', '');
          const bName = get(b[0], 'account.displayName', '');

          // asc to sort A-Z
          return bName.localeCompare(aName);
        }}
        component={props => {
          const team = props.rowData;

          const members = team.members.map((member, index) => {
            const email = member.contactEmail || member.email;
            return (
              <li key={index}>
                <EmailCopyIcon value={email} /> {member.displayName}
              </li>
            );
          });
          return <ul>{members}</ul>;
        }}>
        <FormattedMessage id="admin.managerTeams.table.header.members" />
      </Column>
      <Column
        name="created"
        sortBy={created => new Date(created).getTime()}
        component={props => <span>{moment.utc(props.rowData.created).format('MMM Do YYYY')}</span>}>
        <FormattedMessage id="admin.managerTeams.table.header.created" />
      </Column>
    </Table>
  );
};

export default ManagerTeamsTable;
