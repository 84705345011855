// @flow
// $FlowFixMe
import { useAsync } from 'react-async';
import ErrorFooterNotification from '../components/campaignCreation/common/ErrorFooterNotification';
import SingleValueSelection from '../components/campaignCreation/common/SingleValueSelection';
import type { ClearError } from '../actions/common';
import type { DashboardUser } from '../types/account.flow';
import type {
  EditCampaign as EditCampaignAction,
  FetchCampaign,
  UpdateCampaign
} from '../actions/campaign';
import type { IntlShape } from 'react-intl';
import type { Option } from '../components/campaignCreation/common/SingleValueSelection';
import type { State as ReducerState } from '../reducers/containers/editCampaign';
import type { ExtraProps as RoleCheckingProps } from '../hoc/injectRoleChecking';

import injectRoleChecking from '../hoc/injectRoleChecking';

import * as React from 'react';
import { connect } from 'react-redux';

// $FlowFixMe
import ReactSelect from 'react-select';

import './EditCampaign.scss';

import callApi from '../helpers/api';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';

import { clearError } from '../actions/common';
import { editCampaign, fetchCampaign, updateCampaign } from '../actions/campaign';

import { FormattedMessage, injectIntl } from 'react-intl';
import compose from '../hoc/compose';

import Spinner from '../components/common/Spinner';

import CampaignDescription from '../components/campaignCreation/CampaignDescription';
import CampaignDetails from '../components/campaignCreation/CampaignDetails';
import FooterActions from '../components/common/FooterActions';

import MarkdownTextArea from '../components/common/MarkdownTextArea';

import browserHistory from 'react-router/lib/browserHistory';

import { DEFAULT_CAMPAIGN_COMMISSION_RATE } from '@sharkpunch/matchmade-common/campaign';

import { isWhitelabelAdmin } from '@sharkpunch/matchmade-common/user';

type Actions = {|
  editCampaign: EditCampaignAction,
  fetchCampaign: FetchCampaign,
  updateCampaign: UpdateCampaign,
  clearError: ClearError
|};

type InjectedProps = {|
  ...Actions,
  ...RoleCheckingProps,
  intl: IntlShape,
  error: Object | null,
  // From router
  user: DashboardUser,
  params: {
    id: string
  }
|};

type Props = {
  ...ReducerState,
  ...InjectedProps
};

const Header = (props: { logoUrl: string, gameTitle: string }) => {
  const style = {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("${props.logoUrl}")`
  };

  return (
    <React.Fragment>
      <div className="EditCampaign__header-background svgBlur" style={style} />
      <div className="EditCampaign__header">
        <div className="container">
          <h2>Edit campaign</h2>
          <h1>{props.gameTitle}</h1>
        </div>
      </div>
    </React.Fragment>
  );
};

const fetchAdmins = async () => {
  return await callApi('/accounts/list-admins');
};

export function AutomatedWelcomeMessageSenderSelector({
  onChangeSelection,
  selected,
  useSingleValueSelection
}: any) {
  const noWelcomeMessage = { value: null, label: `Don't send a welcome message` };
  const initialOptions = [
    { value: selected, label: selected ? selected.toString() : 'No sender selected' },
    noWelcomeMessage
  ];
  const { data } = useAsync({ promiseFn: fetchAdmins });

  const selectedOption = data ? data.data.find(d => d.id === selected) : null;

  const dataToLabel = (d: { displayName: string, email: string }) =>
    `${d.displayName} (${d.email})`;

  const props = {
    options:
      data && data.data && Array.isArray(data.data)
        ? data.data
            .map(d => {
              return { value: d.id, label: dataToLabel(d) };
            })
            .concat(noWelcomeMessage)
        : initialOptions,
    onChange: option => onChangeSelection(option),
    value: {
      value: selected,
      label:
        (selectedOption && dataToLabel(selectedOption)) ||
        (selected && selected.toString()) ||
        'No sender selected'
    },
    clearable: false,
    removeSelected: true
  };

  return useSingleValueSelection ? <SingleValueSelection {...props} /> : <ReactSelect {...props} />;
}

class EditCampaign extends React.PureComponent<Props> {
  static layoutClassName: string;

  static defaultProps = {
    isCampaignChanged: false
  };

  componentDidMount() {
    this.props.fetchCampaign(parseInt(this.props.params.id, 10));
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !this.props.isUpdatingCampaign &&
      prevProps.isUpdatingCampaign &&
      !this.props.error &&
      this.props.campaign
    ) {
      this.goToCampaignPage();
    }
  }

  goToCampaignPage = () => {
    if (!this.props.campaign) {
      return;
    }

    const campaignId = this.props.campaign.id;
    if (isWhitelabelAdmin(this.props.user.token)) {
      browserHistory.push({
        pathname: `/admin/campaigns/${campaignId}`
      });
      return;
    }

    browserHistory.push({
      pathname: `/dashboard/publisher/campaigns/${campaignId}`
    });
  };

  onChange = (attribute: string, value: any) => {
    this.props.editCampaign({
      attribute,
      value
    });
  };

  renderCampaignDescriptionSection() {
    const { campaign, isUpdatingCampaign } = this.props;

    if (!campaign) {
      return null;
    }

    return (
      <div className="EditCampaign__campaign-description">
        <FormattedMessage tagName="h2" id="campaignCreation.step.campaignDescription" />
        <CampaignDescription
          onChange={this.onChange}
          isDisabled={isUpdatingCampaign}
          isContentPlatformDisabled={campaign.visible || !!campaign.agreements.length}
          name={campaign.name}
          contentPlatforms={campaign.contentPlatforms}
          promotionTypes={campaign.promotionTypes}
          defaultPromotionType={campaign.defaultPromotionType}
          talkingPoints={campaign.talkingPoints}
          videoReview={campaign.videoReview}
          restrictions={campaign.description}
          isAutopilot={campaign.isAutopilot}
          campaignType={campaign.campaignType}
        />
      </div>
    );
  }

  renderCampaignDetailsSection() {
    const { campaign, isUpdatingCampaign } = this.props;

    if (!campaign) {
      return null;
    }

    // TODO we totally ignore defaultContentPlatform (it's set to the first contentPlatforms by the backend)
    // here because we are not allowing multiple content platforms at the moment.
    // The variable is named `contentPlatforms` because of historical reasons
    const contentPlatform = campaign.contentPlatforms[0];

    return (
      <div className="EditCampaign__campaign-details">
        <FormattedMessage tagName="h2" id="campaignCreation.step.campaignDetails" />
        <CampaignDetails
          {...campaign}
          platforms={campaign.game.platforms}
          contentPlatform={contentPlatform}
          onChange={this.onChange}
          isDisabled={isUpdatingCampaign}
        />
      </div>
    );
  }

  onChangeCommissionRate = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.checkValidity()) {
      return;
    }

    const newCommissionRateInPercentage = parseInt(e.target.value, 10) || 0;

    if (newCommissionRateInPercentage < 0 || newCommissionRateInPercentage > 100) {
      // back to the original campaign commission or default when having invalid data
      return this.onChange(
        'commissionRate',
        (this.props.originalCampaign && this.props.originalCampaign.commissionRate) ||
          DEFAULT_CAMPAIGN_COMMISSION_RATE
      );
    }

    this.onChange('commissionRate', newCommissionRateInPercentage / 100);
  };

  onChangeAutomatedWelcomeMessage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.checkValidity()) {
      return;
    }

    this.onChange('automatedWelcomeMessage', e.target.value.length > 0 ? e.target.value : null);
  };

  onChangeAutomatedWelcomeMessageFollowUp = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.checkValidity()) {
      return;
    }

    this.onChange(
      'automatedWelcomeMessageFollowUp',
      e.target.value.length > 0 ? e.target.value : null
    );
  };

  onChangeAutomatedWelcomeMessageSenderId = (option?: Option) => {
    if (!option || (typeof option.value !== 'number' && option.value !== null)) {
      return;
    }
    this.onChange('automatedWelcomeMessageSenderId', option.value);
  };

  onChangeAutomatedApprovalMessage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.checkValidity()) {
      return;
    }

    this.onChange('automatedApprovalMessage', e.target.value || null);
  };

  renderAdminOnlyFields() {
    const { campaign } = this.props;

    if (!campaign || !this.props.isWhitelabelAdmin) {
      return null;
    }

    return (
      <div className="EditCampaign__campaign-details">
        <FormattedMessage tagName="h2" id="campaignCreation.step.adminOnly" />
        <div className="EditCampaign__admin-only CreateCampaignFields">
          <div className="field is-horizontal EditCampaign__commission-rate-field">
            <div className="field-label">
              <label className="label">
                <FormattedMessage id="campaignCreation.adminOnly.commissionRate" />
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-left">
                  <input
                    className="input"
                    pattern="\d{0,3}"
                    value={campaign.commissionRate * 100}
                    type="text"
                    onChange={this.onChangeCommissionRate}
                  />
                  <span className="icon is-small is-left">%</span>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <FormattedMessage id="campaignCreation.adminOnly.commissionRate.notice" />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">
                <FormattedMessage id="campaignCreation.adminOnly.automatedWelcomeMessage" />
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-left">
                  <MarkdownTextArea
                    textareaComponent={
                      <textarea
                        maxLength={1500}
                        className="textarea"
                        value={campaign.automatedWelcomeMessage}
                        type="text"
                        onChange={this.onChangeAutomatedWelcomeMessage}
                      />
                    }
                    getContent={() => {
                      return campaign.automatedWelcomeMessage;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">
                <FormattedMessage id="campaignCreation.adminOnly.automatedWelcomeMessageFollowUp" />
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-left">
                  <MarkdownTextArea
                    textareaComponent={
                      <textarea
                        maxLength={1500}
                        className="textarea"
                        value={campaign.automatedWelcomeMessageFollowUp}
                        type="text"
                        onChange={this.onChangeAutomatedWelcomeMessageFollowUp}
                      />
                    }
                    getContent={() => {
                      return campaign.automatedWelcomeMessageFollowUp;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <FormattedMessage id="campaignCreation.adminOnly.automatedWelcomeMessageFollowUp.notice" />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">
                <FormattedMessage id="campaignCreation.adminOnly.automatedWelcomeMessageSenderId" />
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-left">
                  <AutomatedWelcomeMessageSenderSelector
                    onChangeSelection={e => this.onChangeAutomatedWelcomeMessageSenderId(e)}
                    selected={campaign.automatedWelcomeMessageSenderId}
                    useSingleValueSelection
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">
                <FormattedMessage id="campaignCreation.adminOnly.automatedApprovalMessage" />
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control has-icons-left">
                  <MarkdownTextArea
                    textareaComponent={
                      <textarea
                        maxLength={1500}
                        className="textarea"
                        value={campaign.automatedApprovalMessage}
                        type="text"
                        onChange={this.onChangeAutomatedApprovalMessage}
                      />
                    }
                    getContent={() => {
                      return campaign.automatedApprovalMessage;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label"></div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <FormattedMessage id="campaignCreation.adminOnly.automatedApprovalMessage.notice" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="EditCampaign__content container">
        {this.renderCampaignDescriptionSection()}
        <hr />
        {this.renderCampaignDetailsSection()}
        {this.props.isWhitelabelAdmin && <hr />}
        {this.renderAdminOnlyFields()}
      </div>
    );
  }

  onClickSave = () => {
    if (!this.props.campaign) {
      return;
    }
    this.props.updateCampaign(this.props.campaign);
  };

  onClickBack = () => {
    this.goToCampaignPage();
  };

  renderFooter() {
    if (this.props.error) {
      return <ErrorFooterNotification clearError={this.props.clearError} />;
    }

    const { isUpdatingCampaign, isCampaignChanged, isCampaignValid } = this.props;

    return (
      <div className="EditCampaign__footer container">
        <FooterActions
          showBackButton
          disabled={!isCampaignChanged || !isCampaignValid}
          isLoading={isUpdatingCampaign}
          onNext={this.onClickSave}
          onBack={this.onClickBack}
          nextButtonText="form.save"
        />
      </div>
    );
  }

  render() {
    if (this.props.isLoadingCampaign) {
      return <Spinner mode="fullWidth" size="large" />;
    }

    const campaign = this.props.campaign;

    // TODO need to show an error here
    if (!campaign) {
      return null;
    }

    return (
      <div className="EditCampaign section">
        <Header gameTitle={campaign.game.title} logoUrl={campaign.game.logoUrl} />
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('editCampaign');

const mapDispatchToProps = generateDispatchToProps({
  fetchCampaign,
  editCampaign,
  updateCampaign,
  clearError
});

EditCampaign = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  injectRoleChecking()
)(EditCampaign);

EditCampaign.layoutClassName = 'Layout--edit-campaign';

export default EditCampaign;
