import React, { Component } from 'react';

import { connect } from 'react-redux';

import { fetchManagerTeams } from '../../actions/managerTeam';

import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import Spinner from '../../components/common/Spinner';

import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';
import ManagerTeamsTable from '../../components/admin/ManagerTeamsTable';

class AdminDashboardManagerTeams extends Component {
  componentWillMount() {
    this.props.fetchManagerTeams();
  }

  render() {
    const { managerTeams, isLoadingManagerTeams } = this.props;
    let content = null;

    if (isLoadingManagerTeams) {
      content = <Spinner mode="fullWidth" size="large" />;
    } else if (!managerTeams || !managerTeams.length) {
      content = (
        <h2 className="title">
          <FormattedMessage id="error.nodata" />
        </h2>
      );
    } else {
      content = <ManagerTeamsTable {...this.props} />;
    }

    return (
      <div className="AdminDashboardManagerTeams container">
        <Helmet title="Teams" />
        {content}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardManagerTeams');

const mapDispatchToProps = generateDispatchToProps({ fetchManagerTeams });

AdminDashboardManagerTeams = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardManagerTeams);

export default AdminDashboardManagerTeams;
