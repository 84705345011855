// @flow
import find from 'lodash/find';
import get from 'lodash/get';
import moment from 'moment';
import pull from 'lodash/pull';
import remove from 'lodash/remove';

import {
  CANCELLED,
  INFLUENCER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_DECLINED,
  PUBLISHER_WITHDREW
} from '@sharkpunch/matchmade-common/campaignAgreement';

import { MixpanelEvent, sendMixpanelEvent } from '../../helpers/mixpanelEvents';
import { OUT_OF_DATE_NEGOTIATION } from '../../helpers/errorCode';
import {
  acceptCampaign,
  declineCampaign,
  deleteCampaignInvite,
  fetchAllInNetworkInfluencers,
  fetchCampaign,
  fetchCampaignAnalytics,
  fetchCampaignInfluencerStats,
  fetchCampaignOverallStats,
  negotiateCampaign,
  updateCampaign
} from '../../actions/campaign';
import {
  addPublishedContent,
  cancelSettledDeal,
  setAgreementDeadline
} from '../../actions/campaignAgreement';
import {
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection,
  linkCollectionToCampaign,
  removeCollectionFromCampaign,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection
} from '../../actions/collection';
import { checkIfPublisherOrWhitelabelAdmin } from '../../helpers/user';
import { getAllocatedBudgetForCampaign } from '../../actions/admin';
import { getSelectedOptionForAttributionPartner } from '../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';
import {
  handleAcceptCampaignActions,
  handleDeclineCampaignActions,
  handleNegotiateCampaignActions,
  handleOutOfDateNegotiationMoves
} from '../common/negotiation';
import { handleCancelDealActions } from '../common/campaignAgreement';
import { handleUserSettingActions } from '../common/userSetting';
import collectionsReducer from '../common/collection';
import runMultipleReducers from '../helpers/runMultipleReducers';
import type { Action, ReducerCreator, ReducerReturnType } from '../../types/action.flow';
import type { Collection, CollectionWithPaginatedInfluencers } from '../../types/collection.flow';

// TODO replace any with proper type
type State = {|
  ...$Exact<ReducerReturnType<typeof handleAcceptCampaignActions>>,
  ...$Exact<ReducerReturnType<typeof handleDeclineCampaignActions>>,
  ...$Exact<ReducerReturnType<typeof handleNegotiateCampaignActions>>,

  campaign: any,
  isLoadingCampaign: boolean,

  isFetchingAllInNetworkInfluencers: boolean,
  allInNetworkInfluencers: any,

  isFetchingCampaignAnalytics: boolean,
  campaignAnalytics: any,

  isFetchingCampaignOverallStats: boolean,
  campaignOverallStats: any,

  isFetchingCampaignInfluencerStats: boolean,
  campaignInfluencerStats: any,

  isDeletingCampaignInvite: boolean,
  deletingCampaignInvite: any,
  deletedCampaignInvite: any,

  isUpdatingAccountSettings: boolean,
  isCancelingDeal: boolean,
  error: any,

  isFetchingCurrentCollection: boolean,
  currentCollection: ?CollectionWithPaginatedInfluencers,

  collections: Collection[],
  isFetchingCollections: boolean,

  isLoadingBudget: boolean,
  budget: any
|};

const initialState: State = Object.freeze({
  campaign: null,
  isLoadingCampaign: true,

  collections: [],
  isFetchingCollections: false,

  isNegotiatingCampaign: false,
  createdCampaignAgreement: null,
  creatingCampaignAgreement: null,

  isAcceptingCampaign: false,
  acceptedCampaignAgreement: null,
  acceptingCampaignAgreement: null,

  isDecliningCampaign: false,
  decliningCampaignAgreement: null,

  isFetchingAllInNetworkInfluencers: true,
  allInNetworkInfluencers: null,

  isFetchingCampaignAnalytics: true,
  campaignAnalytics: null,

  isFetchingCampaignOverallStats: true,
  campaignOverallStats: null,

  isFetchingCampaignInfluencerStats: true,
  campaignInfluencerStats: null,

  isDeletingCampaignInvite: false,
  deletingCampaignInvite: null,
  deletedCampaignInvite: null,

  isUpdatingAccountSettings: false,
  isCancelingDeal: false,
  error: null,

  isFetchingCurrentCollection: false,
  currentCollection: null,

  isLoadingBudget: false,
  budget: null
});

function generateFillerData(startTimestamp, endTimestamp) {
  let result = [];
  const startDate = moment.utc(startTimestamp);
  const endDate = moment.utc(endTimestamp);
  while (startDate.isBefore(endDate, 'hour')) {
    result.push({
      timestamp: startDate.format(),
      viewerCount: 0,
      gameId: ''
    });
    startDate.add(1, 'hour');
  }
  return result;
}

const optimisticallyUpdateStateWhenDeletingInvite: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      // optimistically delete it
      // TODO handle error and revert the action
      case deleteCampaignInvite.SUCCESS:
      case deleteCampaignInvite.REQUEST:
        const { campaign } = state;
        return {
          ...state,
          campaign: {
            ...campaign,
            outOfNetworkInfluencers: (campaign.outOfNetworkInfluencers || []).filter(influencer => {
              return influencer.invite.id !== action.payload.id;
            })
          }
        };
      default:
        return state;
    }
  };
};

const updateInfluencerListAfterEachNegotiationMove: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case cancelSettledDeal.SUCCESS:
      case acceptCampaign.SUCCESS:
      case declineCampaign.SUCCESS:
      case negotiateCampaign.SUCCESS:
        // Everything below happens because we store participating and available influencers
        // as part of the campaign object; would rather store them separately
        const { campaign } = state;
        if (!campaign) return state;

        let influencers = campaign.influencers || [];

        let allInNetworkInfluencers = [...(state.allInNetworkInfluencers || [])];
        let allInfluencers = allInNetworkInfluencers || [];

        if (!allInfluencers) return state;

        const updatedAgreement = action.payload;
        const { influencerId } = updatedAgreement;

        const newInfluencer = find(allInfluencers, { id: influencerId });
        const negotiatingInfluencer = find(influencers, { id: influencerId });

        if (!newInfluencer && negotiatingInfluencer) {
          if (
            [
              INFLUENCER_DECLINED,
              PUBLISHER_DECLINED,
              INFLUENCER_WITHDREW,
              PUBLISHER_WITHDREW,
              CANCELLED
            ].indexOf(updatedAgreement.status) !== -1
          ) {
            // Remove influencer card from negotiations and put it back to participants
            influencers = pull(influencers, negotiatingInfluencer);
            negotiatingInfluencer.agreementLogId = updatedAgreement.logId;
            // If campaign has collections, then we put the influencers back to all in network tab
            if (allInNetworkInfluencers && campaign.collections.length) {
              allInNetworkInfluencers = allInNetworkInfluencers.concat([negotiatingInfluencer]);
            }
          }
        } else if (newInfluencer && !negotiatingInfluencer) {
          influencers = influencers.concat([newInfluencer]);
          allInNetworkInfluencers = allInNetworkInfluencers.filter(i => i.id !== newInfluencer.id);
        }

        const agreements = campaign.agreements || [];
        if (
          [
            INFLUENCER_DECLINED,
            PUBLISHER_DECLINED,
            INFLUENCER_WITHDREW,
            PUBLISHER_WITHDREW,
            CANCELLED
          ].indexOf(updatedAgreement.status) !== -1
        ) {
          remove(agreements, a => updatedAgreement.id === a.id);
        } else {
          let existingAgreement = find(agreements, { id: updatedAgreement.id });
          if (existingAgreement) {
            existingAgreement = Object.assign(existingAgreement, updatedAgreement);
          } else {
            agreements.push(updatedAgreement);
          }
        }

        return {
          ...state,
          allInNetworkInfluencers,
          campaign: {
            ...campaign,
            agreements,
            influencers
          }
        };
      default:
        return state;
    }
  };
};

const handleCustomAgreementDeadlineActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case setAgreementDeadline.ERROR:
        return {
          ...state,
          error: action.payload
        };
      case setAgreementDeadline.SUCCESS:
        const { deadline } = action.payload;
        const { campaign } = state;
        if (!campaign) return state;
        const { agreements } = campaign;

        return {
          ...state,
          campaign: {
            ...campaign,
            agreements: agreements.map(agreement => {
              if (agreement.id !== action.payload.id) return agreement;

              return {
                ...agreement,
                deadline
              };
            })
          }
        };
      default:
        return state;
    }
  };
};

const handleCampaignDataActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchCampaign.REQUEST:
        return {
          ...state,
          isLoadingCampaign: true,
          campaign: null
        };
      case fetchCampaign.SUCCESS:
        const campaign = action.payload;

        if (!campaign) {
          return {
            ...state,
            isLoadingCampaign: false
          };
        }

        return {
          ...state,
          isLoadingCampaign: false,
          campaign: {
            ...campaign,
            attributionPartnerSelectedOption: getSelectedOptionForAttributionPartner(campaign)
          },
          error: null
        };
      case fetchCampaign.FAILURE:
        return {
          ...state,
          isLoadingCampaign: false,
          error: action.payload
        };

      case fetchCampaignAnalytics.REQUEST:
        return {
          ...state,
          isFetchingCampaignAnalytics: true
        };
      case fetchCampaignAnalytics.SUCCESS:
        const hasPoints = action.payload && action.payload.points;
        if (!hasPoints) {
          return {
            ...state,
            isFetchingCampaignAnalytics: false,
            campaignAnalytics: action.payload
          };
        }

        const { startDate, endDate } = action.meta.loadingPayload;

        const data = action.payload.points;
        // We need to generate some placeholder data for graph
        // We do it here, since backend provides only ES entries
        // that matched our query, but we need to display graph
        // from startDate to endDate
        const dataStartTimestamp = (data[0] || {}).timestamp || endDate;
        const dataEndTimestamp = (data[data.length - 1] || {}).timestamp || endDate;

        const startDateFillerData = generateFillerData(startDate, dataStartTimestamp);
        const endDateFillerData = generateFillerData(dataEndTimestamp, endDate);

        return {
          ...state,
          isFetchingCampaignAnalytics: false,
          campaignAnalytics: {
            points: startDateFillerData.concat(data).concat(endDateFillerData),
            aggs: action.payload.aggs
          }
        };

      case fetchCampaignAnalytics.FAILURE:
        return {
          ...state,
          isFetchingCampaignAnalytics: false,
          error: action.payload
        };

      case fetchCampaignOverallStats.REQUEST:
        return {
          ...state,
          isFetchingCampaignOverallStats: true
        };
      case fetchCampaignOverallStats.SUCCESS:
        return {
          ...state,
          isFetchingCampaignOverallStats: false,
          campaignOverallStats: action.payload
        };
      case fetchCampaignOverallStats.FAILURE:
        return {
          ...state,
          isFetchingCampaignOverallStats: false,
          error: action.payload
        };

      case fetchCampaignInfluencerStats.REQUEST:
        return {
          ...state,
          isFetchingCampaignInfluencerStats: true
        };
      case fetchCampaignInfluencerStats.SUCCESS:
        return {
          ...state,
          isFetchingCampaignInfluencerStats: false,
          campaignInfluencerStats: action.payload
        };
      case fetchCampaignInfluencerStats.FAILURE:
        return {
          ...state,
          isFetchingCampaignInfluencerStats: false,
          error: action.payload
        };
      case getAllocatedBudgetForCampaign.REQUEST:
        return {
          ...state,
          isLoadingBudget: true
        };
      case getAllocatedBudgetForCampaign.SUCCESS:
        return {
          ...state,
          budget: action.payload,
          isLoadingBudget: false
        };
      case getAllocatedBudgetForCampaign.FAILURE:
        return {
          ...state,
          isLoadingBudget: false
        };

      default:
        return state;
    }
  };
};

const handleOutOfNetworkInfluencerActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case deleteCampaignInvite.REQUEST:
        return {
          ...state,
          isDeletingCampaignInvite: true,
          deletingCampaignInvite: action.payload
        };
      case deleteCampaignInvite.SUCCESS:
        return {
          ...state,
          isDeletingCampaignInvite: false,
          deletedCampaignInvite: action.payload
        };
      case deleteCampaignInvite.FAILURE:
        return {
          ...state,
          isDeletingCampaignInvite: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleCollectionActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    state = collectionsReducer.fetchAllCollections(state, action);

    switch (action.type) {
      case addYoutubeChannelToCollection.SUCCESS:
        return handleYoutubeChannelCollectionUpdate(state, action);
      case removeYoutubeChannelFromCollection.SUCCESS:
        return handleYoutubeChannelCollectionUpdate(state, action);
      case addTwitchChannelToCollection.SUCCESS:
        return handleTwitchChannelCollectionUpdate(state, action);
      case removeTwitchChannelFromCollection.SUCCESS:
        return handleTwitchChannelCollectionUpdate(state, action);
      case linkCollectionToCampaign.SUCCESS:
        return {
          ...state,
          campaign:
            (state.campaign && {
              ...state.campaign,
              collections: state.campaign.collections.concat(action.payload)
            }) ||
            null
        };
      case removeCollectionFromCampaign.SUCCESS:
        return {
          ...state,
          campaign:
            (state.campaign && {
              ...state.campaign,
              collections: state.campaign.collections.filter(c => c.id !== action.payload.id)
            }) ||
            null
        };

      default:
        return state;
    }
  };
};

const handleAllInNetworkInfluencerActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchAllInNetworkInfluencers.REQUEST:
        return {
          ...state,
          isFetchingAllInNetworkInfluencers: true
        };
      case fetchAllInNetworkInfluencers.SUCCESS:
        return {
          ...state,
          isFetchingAllInNetworkInfluencers: false,
          allInNetworkInfluencers: action.payload
        };
      case fetchAllInNetworkInfluencers.FAILURE:
        return {
          ...state,
          isFetchingAllInNetworkInfluencers: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleYoutubeChannelCollectionUpdate = (state, action) => {
  const isCurrentCollectionChange =
    state.currentCollection && state.currentCollection.id === parseInt(action.payload.id, 10);
  return {
    ...state,
    // Update campaign collection channels
    campaign:
      (state.campaign && {
        ...state.campaign,
        collections: state.campaign.collections.map(col => {
          return col.id === parseInt(action.payload.id, 10)
            ? { ...col, channels: action.payload.channels }
            : col;
        })
      }) ||
      null,
    // Update current collection if a tab has been selected
    currentCollection:
      (isCurrentCollectionChange && {
        ...state.currentCollection,
        influencers: state.currentCollection.influencers.data.filter(i => {
          return action.payload.channels.includes(i.youtubeChannels[0].id);
        }),
        channels: action.payload.channels
      }) ||
      state.currentCollection
  };
};

const handleTwitchChannelCollectionUpdate = (state, action) => {
  const isCurrentCollectionChange =
    state.currentCollection && state.currentCollection.id === parseInt(action.payload.id, 10);
  return {
    ...state,
    // Update campaign collection twitchChannels
    campaign:
      (state.campaign && {
        ...state.campaign,
        collections: state.campaign.collections.map(col => {
          return col.id === parseInt(action.payload.id, 10)
            ? { ...col, twitchChannels: action.payload.channels }
            : col;
        })
      }) ||
      null,
    // Update current collection if a tab has been selected
    currentCollection:
      (state.currentCollection &&
        isCurrentCollectionChange && {
          ...state.currentCollection,
          influencers: state.currentCollection.influencers.filter(i => {
            return action.payload.channels.includes(i.twitchChannels[0].id);
          }),
          twitchChannels: action.payload.channels
        }) ||
      state.currentCollection
  };
};

const handleFailedNegotiation: ReducerCreator<void, State> = () => {
  return (state, action) => {
    if (
      ![negotiateCampaign.FAILURE, acceptCampaign.FAILURE, declineCampaign.FAILURE].includes(
        action.type
      )
    ) {
      return state;
    }

    const code = (action.payload || {}).code;
    if (code !== OUT_OF_DATE_NEGOTIATION) {
      return state;
    }

    const { campaign } = state;

    const { agreements, influencers } = campaign;

    const newAgreement = action.payload.params;

    return {
      ...state,
      campaign: {
        ...campaign,
        agreements: (agreements || []).map(a => {
          if (a.id !== newAgreement.id) {
            return a;
          }
          return { ...a, ...newAgreement };
        }),
        influencers: influencers
      }
    };
  };
};

const handleUpdateCampaignActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case updateCampaign.SUCCESS:
        return {
          ...state,
          campaign: {
            ...state.campaign,
            collections: action.payload.collections
          }
        };
      default:
        return state;
    }
  };
};

const handleInfluencerContentUpdateActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case addPublishedContent.SUCCESS:
        if (!action.payload || !state.campaign) {
          return state;
        }

        const agreements = (state.campaign.agreements || []).map(agreement => {
          const { id, dealItems } = agreement;
          if (id !== action.payload.campaignAgreementId) {
            return agreement;
          }

          return {
            ...agreement,
            dealItems: (dealItems || []).concat(action.payload).reduce((all, dealItem) => {
              const existing = all.find(({ id }) => {
                return id === dealItem.id;
              });

              if (!existing) {
                return all.concat(dealItem);
              }

              // we only need to update these 2 attributes for now
              existing.status = dealItem.status;
              existing.contentUrl = dealItem.contentUrl;

              return all;
            }, [])
          };
        });

        return {
          ...state,
          campaign: {
            ...state.campaign,
            agreements
          }
        };
      default:
        return state;
    }
  };
};

const sendMixpanelEventWhenAdvertiserSendsAnOffer: ReducerCreator<void, State> = () => {
  return (state, action) => {
    const loadingPayload = (action.meta && action.meta.loadingPayload) || {};
    const { influencerId, pageInfo } = loadingPayload;
    // NOTE! pageInfo should only be sent for first event!

    const shouldSendEvent =
      action.type === negotiateCampaign.SUCCESS && checkIfPublisherOrWhitelabelAdmin() && pageInfo;

    if (shouldSendEvent) {
      sendMixpanelEvent(MixpanelEvent.ADVERTISER_PRESSED_SEND_OFFER_BUTTON, {
        position_on_page: pageInfo.positionOnPage,
        position_on_list: pageInfo.positionOnList,
        total_list_size: pageInfo.totalListSize,
        sort_direction: pageInfo.sortDirection,
        sort_by: pageInfo.sortBy,
        page_limit: pageInfo.pageLimit,
        influencer_id: influencerId
      });
    }

    return state;
  };
};

const handleStateTransition = runMultipleReducers([
  handleAcceptCampaignActions(),
  handleNegotiateCampaignActions(),
  handleDeclineCampaignActions(),
  handleOutOfDateNegotiationMoves({
    getAgreementsFromState(state: State) {
      return get(state, 'campaign.agreements', []);
    },
    setAgreementsInState(state: State, agreements) {
      const { campaign } = state;
      return {
        ...state,
        campaign: {
          ...campaign,
          agreements
        }
      };
    }
  }),
  handleUserSettingActions(),
  updateInfluencerListAfterEachNegotiationMove(),
  optimisticallyUpdateStateWhenDeletingInvite(),
  handleCollectionActions(),
  handleCustomAgreementDeadlineActions(),
  handleCampaignDataActions(),
  handleOutOfNetworkInfluencerActions(),
  handleAllInNetworkInfluencerActions(),
  handleFailedNegotiation(),
  handleUpdateCampaignActions(),
  handleInfluencerContentUpdateActions(),
  handleCancelDealActions(),
  // side effects
  sendMixpanelEventWhenAdvertiserSendsAnOffer()
]);

export default function publisherCampaignPageReducer(
  state: State = initialState,
  action: Action
): State {
  return handleStateTransition(
    {
      ...state,
      // always reset the state
      createdCampaignAgreement: null,
      acceptedCampaignAgreement: null,
      error: null
    },
    action
  );
}
