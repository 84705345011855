import React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

const ProTipIcon = (props: IconProps) => {
  const iconStyles = 'icon Icon';
  return (
    <Icon className={iconStyles} {...props}>
      {() => <i className="material-icons">emoji_objects</i>}
    </Icon>
  );
};

export default ProTipIcon;
