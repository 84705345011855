// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';

import InstagramIcon from '../../images/icons/InstagramIcon';
import TwitchIcon from '../../images/icons/TwitchIcon';
import YoutubeIcon from '../../images/icons/YoutubeIcon';
import type { ContentPlatform } from '../../types/campaign.flow';

import './ContentPlatform.scss';

type Props = {
  platform: ContentPlatform,
  isIcon?: boolean,
  children?: React.Node
};

const Wrapper = ({ children, platform, isIcon = false }: Props) => {
  const className = classNames('ContentPlatformWrapper tag', {
    'ContentPlatformWrapper--instagram': platform === ContentPlatformEnum.INSTAGRAM,
    'ContentPlatformWrapper--youtube': platform === ContentPlatformEnum.YOUTUBE,
    'ContentPlatformWrapper--twitch': platform === ContentPlatformEnum.TWITCH,
    'ContentPlatformWrapper--icon': isIcon
  });
  return <div className={className}>{children}</div>;
};

export const ContentPlatformLabel = ({ platform }: { platform: ContentPlatform }) => (
  <Wrapper platform={platform}>
    <FormattedMessage id={`contentPlatforms.${platform}`} />
  </Wrapper>
);

export const ContentPlatformIcon = ({ platform }: { platform: ContentPlatform }) => (
  <Wrapper platform={platform} isIcon>
    {platform === ContentPlatformEnum.INSTAGRAM && <InstagramIcon isGlyph />}
    {platform === ContentPlatformEnum.YOUTUBE && <YoutubeIcon />}
    {platform === ContentPlatformEnum.TWITCH && <TwitchIcon />}
  </Wrapper>
);

export default Wrapper;
