// @flow
import { STEAM } from './game';
import type { ContentPlatform, DealType } from 'matchmade-types';

import {
  AttributionPartner,
  ContentPlatform as ContentPlatformEnum,
  DealType as DealTypeEnum
} from '@sharkpunch/matchmade-common/campaign';

export function shouldHideCpi(attributionPartner: string, gameType: string) {
  if (gameType === STEAM) {
    return true;
  } else if (
    attributionPartner === AttributionPartner.NONE ||
    attributionPartner === AttributionPartner.MATCHMADE_LINK ||
    attributionPartner === AttributionPartner.OTHER
  ) {
    return true;
  }
  return false;
}

export function getAvailableDealTypes(contentPlatform: ContentPlatform): DealType[] {
  switch (contentPlatform) {
    case ContentPlatformEnum.INSTAGRAM:
      return [DealTypeEnum.FIXED_FEE];
    default:
      return [DealTypeEnum.FIXED_FEE, DealTypeEnum.CPM, DealTypeEnum.CPI];
  }
}
