import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';

import Link from 'react-router/lib/Link';

import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import get from 'lodash/get';

import { FormattedMessage, injectIntl } from 'react-intl';

import {
  fetchMessages,
  optimisticallyCreateNegotiationMessage,
  sendMessage
} from '../actions/messages';
import { markChatAsRead } from '../actions/chat';

import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';

import {
  fetchCampaignAgreementInfo,
  fetchInfluencerFromCampaignAgreement
} from '../actions/campaignAgreement';

import {
  acceptCampaign,
  declineCampaign,
  fetchCampaignEstimates,
  negotiateCampaign
} from '../actions/campaign';

import { resetProps } from '../actions/common';

import { acceptTOS } from '../actions/user';

import { calculateYoutubePrices } from '@sharkpunch/matchmade-common/money';
import { getEstimatedInstallsFromEstimates } from '../helpers/estimates';
import {
  getFirstAvailableContentPlatform,
  getOneChannelPerContentPlatform
} from '../helpers/influencer';
import { getGameTypeForEstimates } from '../helpers/game';
import { getViewsFromEstimates } from '../helpers/youtube';
import { shouldHideCpi } from '../helpers/campaign';
import Error from '../components/common/Error';

import {
  checkIfInfluencerOrManager,
  checkIfPublisher,
  checkIfWhitelabelAdmin,
  checkIfInfluencer,
  getRole
} from '../helpers/user';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';

import NotFound from '../components/auth/NotFound';

import FormattedNumber from '../components/common/FormattedNumber';
import Spinner from '../components/common/Spinner';

import AdminDealTagInput from '../components/common/TabComponents/AdminDealTagInput';

import MessagesList from '../components/messages/MessagesList';
import NewChatMessage from '../components/messages/NewChatMessage';

import DeclineReasonMessage from '../components/messages/DeclineReasonMessage';

import CampaignInfoFooter from '../components/messages/CampaignInfoFooter';

import CampaignAvailableCard from '../components/campaign/CampaignAvailableCard';
import CampaignParticipatingCard from '../components/campaign/CampaignParticipatingCard';

import InfluencerAvailableCard from '../components/influencer/InfluencerAvailableCard';
import InfluencerNegotiationCard from '../components/influencer/InfluencerNegotiationCard';
import InfluencerParticipatingCard from '../components/influencer/InfluencerParticipatingCard';

import CampaignPerformance from '../components/messages/CampaignPerformance';
import CampaignTotalCost from '../components/messages/CampaignTotalCost';

import { ChatToggler } from '../components/messages/ChatToggler';

import compose from '../hoc/compose';
import injectRoleChecking from '../hoc/injectRoleChecking';

import { MessageType, supportedMessageTypes } from '../constants';

import '../components/messages/NegotiationMessage.scss';
import './MessagesPage.scss';

import {
  CANCELLED,
  INFLUENCER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_DECLINED,
  PUBLISHER_WITHDREW,
  SETTLED,
  getCpiFromAgreement,
  getCpmFromAgreement,
  getDealType,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement
} from '@sharkpunch/matchmade-common/campaignAgreement';

import { Role } from '@sharkpunch/matchmade-common/user';
import { validate as isNjordOffer } from '../helpers/uuid';
import DeclineOfferModal from '../components/modals/DeclineOfferModal';
import injectPageVisibilityChecking from '../hoc/injectPageVisibilityChecking';
import config from '../config';
import Modal from '../components/common/Modal';

const fetchMessagesInterval = 5000; // 5s
const agreementPollingInterval = 5000; // 5s
const markChatAsReadInterval = 5000;

const declinedOrWithdrawnStatuses = [
  INFLUENCER_DECLINED,
  PUBLISHER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_WITHDREW
];

const declinedOrWithdrawnOrCancelledStatuses = declinedOrWithdrawnStatuses.concat(CANCELLED);

function isMessageInputHidden({ campaignAgreement, status, chatClosed }) {
  switch (true) {
    // disable for Njord offers
    case isNjordOffer(campaignAgreement.id):
      return true;

    // No messaging for agreements where chatClosed flag is set to true
    case chatClosed:
      return true;

    case checkIfWhitelabelAdmin(): {
      return false;
    }

    default: {
      return (
        [
          INFLUENCER_WITHDREW,
          PUBLISHER_WITHDREW,
          INFLUENCER_DECLINED,
          PUBLISHER_DECLINED,
          CANCELLED
        ].indexOf(status) !== -1
      );
    }
  }
}

class MessagesPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { chatClosed: null };
  }

  componentDidMount() {
    if (!this.props.router) return;
    // However we leave this page, always reset
    this.props.router.setRouteLeaveHook(this.props.route, this.props.resetProps);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.campaignAgreement &&
        this.props.campaignAgreement &&
        prevProps.campaignAgreement.chatClosed !== this.props.campaignAgreement.chatClosed) ||
      (!prevProps.campaignAgreement && this.props.campaignAgreement)
    ) {
      this.setState({
        chatClosed: this.props.campaignAgreement && this.props.campaignAgreement.chatClosed
      });
    }
  }

  onSendMessage = content => {
    this.props.sendMessage(this.props.params.id, content);
  };

  onNegotiationAction(type) {
    const props = this.props;
    return function (params) {
      // Call negotiation api
      if (type === 'accept') {
        props.acceptCampaign(params);
      } else if (type === 'negotiate') {
        props.negotiateCampaign(params);
      }
      // default action for settled and influencer_declined;
      // it will be replaced with proper status by Object.assign for negotiations
      let action;
      if (type === 'accept') action = 'settled';
      else {
        const role = getRole();
        const type = props.agreement && props.agreement.status ? 'set_cpi' : 'changed_cpi';
        action = `${role}_${type}`;
        params = Object.assign({}, { status: action }, params);
        props.optimisticallyCreateNegotiationMessage(params);
      }
    };
  }

  processMessages = messages => {
    const data = messages || [];

    return data.filter(Boolean).map((message, i, messages) => {
      if (supportedMessageTypes.indexOf(message.type) === -1) return message; // Let list decide what to do with this

      // Show messages belonging to current role on the right
      const reverse =
        ([Role.INFLUENCER, Role.INFLUENCER_MANAGER].includes(message.role) &&
          checkIfInfluencerOrManager()) ||
        ([message.role === Role.WHITELABEL_ADMIN, message.role === Role.PUBLISHER].includes(
          message.role
        ) &&
          (checkIfPublisher() || checkIfWhitelabelAdmin()));

      const created = message.created;

      const baseMessage = {
        id: message.id,
        reverse,
        type: message.type,
        created
      };

      if (message.type === MessageType.MESSAGE) {
        return Object.assign(baseMessage, {
          avatarUrl: get(message, 'author.avatarUrl'),
          displayName: get(message, 'author.displayName'),
          isAdmin: get(message, 'author.isAdmin'),
          children: message.content
        });
      }

      if (message.type === MessageType.CHANGED_DEADLINE) {
        return Object.assign(baseMessage, {
          role: message.role,
          displayName: get(message, 'author.displayName'),
          deadline: get(message, 'dealValues.deadline')
        });
      }

      if (message.type === MessageType.PUBLISHED_CONTENT) {
        return Object.assign(baseMessage, {
          channelName: get(message, 'author.displayName'),
          contentText: get(message, 'dealItem.contentText'),
          contentUrl: get(message, 'dealItem.contentUrl'),
          contentType: get(message, 'dealItem.contentType'),
          status: get(message, 'dealItem.status')
        });
      }

      // For 'negotiation' messages
      const nextNegotiationMessage = find(messages.slice(i + 1), { type: 'negotiation' }) || {};
      const updated = nextNegotiationMessage.content === message.content;
      const role = message.role;
      const actionMessages = [
        <FormattedMessage
          key="negotiationMessageActor"
          id={`chat.negotiation.${camelCase(message.content)}`}
          values={{
            actor: get(message, 'author.displayName')
          }}
        />
      ];

      let offer;
      if (declinedOrWithdrawnStatuses.indexOf(message.content) !== -1) {
        actionMessages.push(
          <FormattedMessage key="negotiationMessageOffer" id="chat.messagesDisabled" />
        );
        // There is special text for any declined/withdrawn negotiations
        offer = <DeclineReasonMessage declineReason={message.dealValues.declineReason} />;
      } else if (message.content !== 'cancelled') {
        const cpi =
          message.content === 'settled' ? message.dealValues.cpi : message.dealValues[`${role}Cpi`];
        const cpm =
          message.content === 'settled' ? message.dealValues.cpm : message.dealValues[`${role}Cpm`];
        const minGuarantee =
          message.content === 'settled'
            ? message.dealValues.minGuarantee
            : message.dealValues[`${role}MinGuarantee`];
        const maxPayment =
          message.content === 'settled'
            ? message.dealValues.maxPayment
            : message.dealValues[`${role}MaxPayment`];
        const promotionType = this.props.intl.formatMessage({
          id: `promotionTypes.${message.dealValues.promotionType}`
        });

        offer = message.content !== 'cancelled' && (
          <span>
            <strong>{promotionType}</strong>
            {cpi > 0 && (
              <FormattedMessage
                id="chat.negotiation.offer.cpi"
                values={{
                  value: (
                    <strong>
                      <FormattedNumber type="cost" value={cpi} />
                    </strong>
                  )
                }}
              />
            )}
            {cpm > 0 && (
              <FormattedMessage
                id="chat.negotiation.offer.cpm"
                values={{
                  value: (
                    <strong>
                      <FormattedNumber type="cost" value={cpm} />
                    </strong>
                  )
                }}
              />
            )}
            {cpi === 0 && cpm === 0 && (
              <FormattedMessage
                id="chat.negotiation.offer.fixedFee"
                values={{
                  value: (
                    <strong>
                      <FormattedNumber type="cost" value={minGuarantee} />
                    </strong>
                  )
                }}
              />
            )}
            {(cpi > 0 || cpm > 0) && (
              <FormattedMessage
                id="chat.negotiation.offer.minMaxPayment"
                values={{
                  minGuarantee: minGuarantee ? (
                    <strong>
                      <FormattedNumber type="cost" value={minGuarantee} />
                    </strong>
                  ) : (
                    0
                  ),
                  maxPayment: maxPayment ? (
                    <strong>
                      <FormattedNumber type="cost" value={maxPayment} />
                    </strong>
                  ) : (
                    0
                  )
                }}
              />
            )}
          </span>
        );
      }

      const action = <span>{actionMessages.reduce((prev, curr) => [prev, ', ', curr])}</span>;
      return Object.assign(baseMessage, {
        status: message.content,
        updated,
        action,
        offer
      });
    });
  };

  onInviteInNetworkInfluencer = (
    campaign,
    influencer,
    cpi,
    minGuarantee,
    deadline,
    promotionType
  ) => {
    const params = {
      campaignId: campaign.id,
      influencerId: influencer.id,
      influencerCpi: 0,
      publisherCpi: cpi,
      publisherMinGuarantee: minGuarantee,
      promotionType,
      // this is only available when we reopen a negotiation that was previously declined or withdrawn
      logId: influencer.agreementLogId,
      deadline,
      freeOfCharge: campaign.campaignType === CampaignTypeEnum.CHARITY
    };
    this.props.negotiateCampaign(params);
    this.props.optimisticallyCreateNegotiationMessage(params);
  };

  renderCampaignCardForSidebar() {
    const { campaignAgreement, influencer } = this.props;
    const { campaign, game } = campaignAgreement || {};
    const gameType = getGameTypeForEstimates(game);
    const estimates = campaignAgreement.estimates;
    const estimatedInstalls = getEstimatedInstallsFromEstimates(
      estimates,
      campaignAgreement.promotionType,
      gameType
    );
    const estimateProps = {
      estimates,
      estimatedInstalls
    };

    const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);

    return [
      declinedOrWithdrawnOrCancelledStatuses.indexOf(campaignAgreement.status) === -1 ? (
        <CampaignParticipatingCard
          defaultTab={campaignAgreement.status === SETTLED ? 3 : 2}
          campaign={{
            ...campaign
          }}
          agreement={{
            ...campaignAgreement,
            estimatedInstalls
          }}
          game={game}
          hideCpi={hideCpi}
          onNegotiateCampaign={this.onNegotiationAction('negotiate')}
          onShowDeclineOfferModal={this.props.onShowDeclineOfferModal}
          onAcceptCampaign={this.onNegotiationAction('accept')}
          isLoading={
            this.props.isAcceptingCampaign ||
            this.props.isNegotiatingCampaign ||
            this.props.isDecliningCampaign
          }
          influencer={influencer}
          user={this.props.user}
          key={`campaign-card-${campaign.id}`}
          error={this.props.error}
          acceptTOS={this.props.acceptTOS}
          {...estimateProps}
          showMessagesTab={false}
          showVideoTab={true}
          gameType={gameType}
        />
      ) : (
        <CampaignAvailableCard
          {...estimateProps}
          key={`campaign-card-${campaignAgreement.id}`}
          influencer={influencer}
          campaign={{
            ...campaign,
            estimatedInstalls
          }}
          agreement={{
            ...campaignAgreement,
            estimatedInstalls
          }}
          game={game}
          hideCpi={hideCpi}
          user={this.props.user}
          onNegotiateCampaign={this.onNegotiationAction('negotiate')}
          isLoading={this.props.isAcceptingCampaign || this.props.isNegotiatingCampaign}
          acceptTOS={this.props.acceptTOS}
          gameType={gameType}
        />
      ),
      <CampaignInfoFooter key="footer" />
    ];
  }

  renderInfluencerCardForSidebar() {
    const { campaignAgreement, influencer } = this.props;
    const { campaign, game } = campaignAgreement || {};

    const { youtubeChannel } = getOneChannelPerContentPlatform(influencer);

    const gameType = getGameTypeForEstimates(game);
    const sidebarContent = [];
    const estimates = youtubeChannel && youtubeChannel.estimates;

    const estimatedInstalls = getEstimatedInstallsFromEstimates(
      estimates,
      campaignAgreement.promotionType,
      gameType
    );
    const estimateProps = {
      estimates,
      estimatedInstalls
    };

    const estimatedViews = getViewsFromEstimates(estimates);

    const cpi = getCpiFromAgreement(campaignAgreement);
    const cpm = getCpmFromAgreement(campaignAgreement);
    const minGuarantee = getMinGuaranteeFromAgreement(campaignAgreement);
    const maxPayment = getMaxPaymentFromAgreement(campaignAgreement);

    const estimatedCost = calculateYoutubePrices({
      promotionType: campaignAgreement.promotionType,
      dealType: getDealType({ cpi, cpm, freeOfCharge: campaignAgreement.freeOfCharge }),
      minGuarantee,
      cpi,
      cpm,
      maxPayment,
      estimatedInstalls,
      estimatedViews
    }).currentPrice;

    const { channel } =
      getFirstAvailableContentPlatform(influencer, campaignAgreement.contentPlatform) || {};

    const channelName = (channel || {}).name || (influencer.account || {}).displayName || '';

    const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);

    // 2020-08-11 @Eder
    // It seems we don't get cost directly in the agreement... but under `stats` so checking both options for now
    const cost = campaignAgreement.cost || (campaignAgreement.stats || {}).cost;

    if (campaignAgreement.status === SETTLED) {
      sidebarContent.push(
        <InfluencerParticipatingCard
          error={this.props.error}
          agreement={campaignAgreement}
          campaign={campaign}
          agreementDeadline={campaignAgreement.deadline}
          agreementStatus={campaignAgreement.status}
          promotionType={campaignAgreement.promotionType || campaign.defaultPromotionType}
          contentPlatform={campaignAgreement.contentPlaform || campaign.defaultContentPlatform}
          {...estimateProps}
          influencer={influencer}
          isLoading={this.props.isCancelingDeal}
          showMessagesTab={false}
          showLatestDealsTab={false}
          key={`influencer-card-${campaign.id}`}
          channelName={channelName}
          hideCpi={hideCpi}
          gameType={gameType}
          estimatedCost={estimatedCost}
          cost={cost}
        />
      );
    } else if (declinedOrWithdrawnOrCancelledStatuses.indexOf(campaignAgreement.status) === -1) {
      const isLoading =
        this.props.isAcceptingCampaign ||
        this.props.isNegotiatingCampaign ||
        this.props.isDecliningCampaign;

      sidebarContent.push(
        <InfluencerNegotiationCard
          error={this.props.error}
          agreement={campaignAgreement}
          promotionType={campaignAgreement.promotionType || campaign.defaultPromotionType}
          contentPlatform={campaignAgreement.contentPlaform || campaign.defaultContentPlatform}
          {...estimateProps}
          onNegotiateCampaign={this.onNegotiationAction('negotiate')}
          onShowDeclineOfferModal={this.props.onShowDeclineOfferModal}
          onAcceptCampaign={this.onNegotiationAction('accept')}
          disabled={!campaign.visible || isLoading}
          isLoading={isLoading}
          campaign={{
            ...campaign,
            game
          }}
          influencer={{
            ...influencer
          }}
          channelName={channelName}
          showMessagesTab={false}
          showLatestDealsTab={false}
          showCommissionTab={false}
          key={`influencer-card-${campaign.id}`}
          hideCpi={hideCpi}
          gameType={gameType}
          agreementStatus={campaignAgreement.status}
          agreementDeadline={campaignAgreement.deadline}
          estimatedCost={estimatedCost}
          cost={cost}
        />
      );
    } else if (declinedOrWithdrawnOrCancelledStatuses.indexOf(campaignAgreement.status) !== -1) {
      const isLoading = this.props.isNegotiatingCampaign;

      sidebarContent.push(
        <InfluencerAvailableCard
          campaign={{
            ...campaign,
            game
          }}
          influencer={{
            ...influencer,
            youtubeChannels: influencer.youtubeChannels.map(channel => {
              return {
                ...channel,
                estimatedInstalls
              };
            }),
            twitchChannels: influencer.twitchChannels.map(channel => {
              return {
                ...channel,
                estimatedInstalls
              };
            })
          }}
          channelName={channelName}
          onInviteInNetworkInfluencer={this.onInviteInNetworkInfluencer}
          disabled={!campaign.visible || isLoading}
          isLoading={isLoading}
          key={`influencer-card-${campaign.id}`}
          gameType={gameType}
        />
      );
    }

    if (!isNjordOffer(campaignAgreement.id)) {
      sidebarContent.push(
        <div style={{ marginTop: '1rem', background: '#fff' }} key="deal-tags">
          <AdminDealTagInput agreementId={campaignAgreement.id} />
        </div>
      );
    }

    return sidebarContent.concat([<CampaignInfoFooter key="footer" />]);
  }

  renderHeader() {
    const campaignPerformance = this.renderCampaignPerformance();
    const totalCost = this.renderTotalCost();

    if (!totalCost && !campaignPerformance) return null;

    return (
      <div className="MessagesPage__header">
        {totalCost}
        {campaignPerformance}
      </div>
    );
  }

  renderCampaignPerformance() {
    const { campaignAgreement } = this.props;

    return campaignAgreement && campaignAgreement.status === SETTLED ? (
      <CampaignPerformance agreement={campaignAgreement} />
    ) : null;
  }

  renderTotalCost() {
    const { campaignAgreement } = this.props;
    if (checkIfInfluencerOrManager() || !campaignAgreement) return null;

    const campaignId = campaignAgreement.campaignId;
    const titleText = get(this.props.campaignAgreement, 'game.title');
    let campaignLinkRoot;

    if (checkIfPublisher()) campaignLinkRoot = '/dashboard/publisher/campaigns';
    else if (checkIfWhitelabelAdmin()) campaignLinkRoot = '/admin/campaigns';

    const title = (
      <Link to={`${campaignLinkRoot}/${campaignId}`}>
        <strong>{titleText}</strong>
      </Link>
    );

    return (
      <CampaignTotalCost
        isLoading={this.props.isLoadingCampaignEstimates || !this.props.campaignEstimates}
        cost={get(this.props, 'campaignEstimates.totalCost')}
        title={title}
      />
    );
  }

  render() {
    if (
      !this.props.isFetchingCampaignAgreementInfo &&
      !this.props.campaignAgreement &&
      this.props.error
    ) {
      // We can't fetch campaign agreement -- probably no rights to it, show "not found"
      return <NotFound />;
    }

    const {
      campaignAgreement,
      influencer,
      isFetchingCampaignAgreementInfo,
      isLoadingInfluencer
    } = this.props;

    const loading =
      this.props.isFetchingMessages || isFetchingCampaignAgreementInfo || isLoadingInfluencer;

    let sidebarContent = null;

    if (campaignAgreement && influencer) {
      if (checkIfInfluencerOrManager()) {
        sidebarContent = this.renderCampaignCardForSidebar();
      } else {
        sidebarContent = this.renderInfluencerCardForSidebar();
      }
    } else {
      sidebarContent = (
        <Spinner size="large" mode="overlay">
          <FormattedMessage id="chat.loadingCampaign" />
        </Spinner>
      );
    }

    const status = campaignAgreement && campaignAgreement.status;
    const avatarUrl = checkIfWhitelabelAdmin()
      ? this.props.user.avatarUrl
      : get(this.props.campaignAgreement, 'author.avatarUrl');
    const newMessageBox =
      this.state.chatClosed === null ? (
        <Spinner size="large" mode="overlay">
          <FormattedMessage id="chat.loadingChats" />
        </Spinner>
      ) : this.state.chatClosed ||
        isMessageInputHidden({
          campaignAgreement,
          status,
          chatClosed: this.state.chatClosed
        }) ? (
        <>
          <div className="NegotiationMessage__negotiation">
            <FormattedMessage
              id="chat.currentlyDisabled"
              values={{
                faq: (
                  <a
                    href="https://matchmade.tv/content-creator-faq/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <FormattedMessage id="auth.faq" />
                  </a>
                )
              }}
            />
          </div>
          <div className="NegotiationMessage">
            <div className="NegotiationMessage__content">
              <div className="NegotiationMessage__split">
                <div className="NegotiationMessage__icon">
                  <span className="icon is-medium">
                    <i className="material-icons">check</i>
                  </span>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </>
      ) : (
        <NewChatMessage
          avatarUrl={avatarUrl}
          loading={loading}
          disabled={this.props.initialFetch}
          sendingMessage={this.props.isSendingMessage}
          errorMessage={this.props.sendMessageError && this.props.sendMessageError.message}
          sendMessage={this.onSendMessage}
          campaignAgreement={campaignAgreement}
          isWhitelabelAdmin={checkIfWhitelabelAdmin()}
        />
      );

    return (
      <div className="MessagesPage container">
        <div className="section">
          <div className="MessagesPage__grid">
            <div className="MessagesPage__content">
              {this.renderHeader()}
              {checkIfWhitelabelAdmin() &&
                campaignAgreement &&
                !isNjordOffer(campaignAgreement.id) && (
                  <ChatToggler
                    agreementId={campaignAgreement.id}
                    closed={this.state.chatClosed}
                    onChatClosed={closingState => {
                      this.setState({ chatClosed: closingState });
                    }}
                  />
                )}
              {newMessageBox}
              <MessagesList
                loading={loading}
                messages={this.processMessages(this.props.messages)}
              />
            </div>
            <div className="MessagesPage__sidebar">{sidebarContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const getAlphaRedirectUrl = (url, isNew) => {
  const searchParams = new URLSearchParams(window.location.search);
  if (isNew) searchParams.append('isNew', 1);
  return `${url}${window.location.search && '?'}${searchParams}`;
};

MessagesPage = compose(injectIntl, injectRoleChecking())(MessagesPage);

// Show alpha banner to any user that passes modulo check,
// to Alex's account
// and to our test influencer accounts
const isAlphaUser = id =>
  id % 3 === 0 || id % 2 === 0 || id % 7 === 0 || [2520, 3627, 21748, 23018].includes(id);

class MessagesPageContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialFetch: true,
      isDeclineOfferModalOpen: false,
      isNewUIModalOpen: checkIfWhitelabelAdmin() ? true : false
    };

    this._fetchMessagesTimeout = null;
    this._fetchAgreementInfoTimeout = null;
    this._markChatAsReadTimeout = null;
  }

  componentDidMount() {
    const {
      fetchMessages,
      fetchCampaignAgreementInfo,
      fetchInfluencerFromCampaignAgreement
    } = this.props;
    const agreementId = this.props.params.id;

    fetchMessages(agreementId);
    fetchCampaignAgreementInfo(agreementId);
    fetchInfluencerFromCampaignAgreement(agreementId);

    this.scheduleFetchMessages();
    this.scheduleFetchCampaignAgreementInfo();
    this.scheduleMarkChatAsRead();
  }

  componentWillUnmount() {
    this.stopFetchMessages();
    this.stopFetchAgreementInfo();
    this.stopMarkMessagesAsRead();
  }

  componentWillReceiveProps(nextProps) {
    const { fetchCampaignEstimates } = this.props;

    // Once we fetched agreement, we can fetch the rest
    if (!this.props.campaignAgreement && nextProps.campaignAgreement) {
      if (checkIfPublisher() || checkIfWhitelabelAdmin()) {
        fetchCampaignEstimates(nextProps.campaignAgreement.campaignId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isPageVisible } = this.props;

    if (prevProps.isPageVisible !== isPageVisible) {
      if (isPageVisible) {
        this.scheduleFetchMessages();
        this.scheduleFetchCampaignAgreementInfo();
        this.scheduleMarkChatAsRead();
      } else {
        this.stopFetchMessages();
        this.stopFetchAgreementInfo();
        this.stopMarkMessagesAsRead();
      }
    }
  }

  scheduleFetchMessages = () => {
    const { error, fetchMessages, params } = this.props;

    if (error || this.props.isFetchingMessages) {
      this.setState({ initialFetch: false });
      this.stopFetchMessages();

      return;
    }

    if (!this._fetchMessagesTimeout) {
      this._fetchMessagesTimeout = setInterval(
        fetchMessages.bind(this, params.id),
        fetchMessagesInterval
      );
    }
  };

  scheduleFetchCampaignAgreementInfo = () => {
    const { error, fetchCampaignAgreementInfo, params } = this.props;

    if (error || this.props.isFetchingCampaignAgreementInfo) {
      this.stopFetchAgreementInfo();

      return;
    }

    if (!this._fetchAgreementInfoTimeout) {
      this._fetchAgreementInfoTimeout = setInterval(
        fetchCampaignAgreementInfo.bind(this, params.id),
        agreementPollingInterval
      );
    }
  };

  scheduleMarkChatAsRead = () => {
    const { error, markChatAsRead, params } = this.props;

    if (error) {
      this.stopMarkMessagesAsRead();
      return;
    }

    if (!this._markChatAsReadTimeout) {
      this._markChatAsReadTimeout = setInterval(
        markChatAsRead.bind(this, params.id),
        markChatAsReadInterval
      );
    }
  };

  stopFetchMessages = () => {
    clearTimeout(this._fetchMessagesTimeout);
    this._fetchMessagesTimeout = null;
  };

  stopFetchAgreementInfo = () => {
    clearTimeout(this._fetchAgreementInfoTimeout);
    this._fetchAgreementInfoTimeout = null;
  };

  stopMarkMessagesAsRead = () => {
    clearTimeout(this._markChatAsReadTimeout);
    this._markChatAsReadTimeout = null;
  };

  toggleDeclineOfferModal = params => {
    this.setState(prevState => ({
      isDeclineOfferModalOpen: !prevState.isDeclineOfferModalOpen,
      declineOfferInfluencerId: params ? params.influencerId : null,
      declineOfferCampaignId: params ? params.campaignId : null,
      declineOfferLogId: params ? params.logId : null
    }));
  };

  toggleUseNewUIModal = params => {
    this.setState(prevState => ({
      isNewUIModalOpen: !prevState.isNewUIModalOpen
    }));
  };

  onDeclineCampaign = async ({ reason }) => {
    const { declineOfferInfluencerId, declineOfferCampaignId, declineOfferLogId } = this.state;
    const { declineCampaign } = this.props;

    await declineCampaign({
      campaignId: declineOfferCampaignId,
      influencerId: declineOfferInfluencerId,
      logId: declineOfferLogId,
      reason
    });
  };

  render() {
    const props = Object.assign(
      {
        onShowDeclineOfferModal: this.toggleDeclineOfferModal
      },
      this.state,
      this.props
    );
    const { isDeclineOfferModalOpen, isNewUIModalOpen } = this.state;

    const showNewUiAlert =
      this.props.user &&
      checkIfInfluencer() &&
      isAlphaUser(this.props.user.id) &&
      this.props.campaignAgreement &&
      this.props.campaignAgreement.status === SETTLED;

    // force some influencers to the new app!!
    if (
      this.props.user &&
      checkIfInfluencer() &&
      this.props.campaignAgreement &&
      this.props.campaignAgreement.id
    ) {
      const alphaRedirectUrl = getAlphaRedirectUrl(
        `${config('creatorApp.url')}/deal/${this.props.campaignAgreement.id}`,
        this.props.accountContext &&
          this.props.accountContext.data &&
          this.props.accountContext.data.isNew
      );
      window.location = alphaRedirectUrl;
    }

    return (
      <React.Fragment>
        <Error error={this.props.error} />
        {showNewUiAlert && (
          <Alert severity="info" className="is-flex is-justify-content-center">
            <AlertTitle>Try out the new dashboard</AlertTitle>
            <p>
              You've been selected to our early access program. We'll soon launch a completely
              revamped version of the Matchmade app.
            </p>
            <p>You can come back to this page at any time.</p>
            <a
              className="button is-primary is-normal mt-4"
              href={`${config('creatorApp.url')}/deal/${this.props.params.id}`}>
              Try it out!
            </a>
          </Alert>
        )}
        <MessagesPage {...props} />
        {checkIfWhitelabelAdmin() && (
          <Modal isOpen={isNewUIModalOpen} isBoxed={true} isClosable={false}>
            <section className="modal-card-body">
              <p className="modal-card-title">Hello there. Why not use our shiny new UI?</p>
              <button
                className="button is-light is-medium mt-4 mr-4"
                onClick={this.toggleUseNewUIModal}>
                No, I still need to access the old view
              </button>
              <a
                class="button"
                className="button is-primary is-medium mt-4"
                href={`${config('bulkOfferTool.url')}/deals/${this.props.params.id}`}>
                Open this in the new tools
              </a>
            </section>
          </Modal>
        )}
        <DeclineOfferModal
          onClose={this.toggleDeclineOfferModal}
          onDeclineCampaign={this.onDeclineCampaign}
          isOpen={isDeclineOfferModalOpen}
          isLoading={this.props.isDecliningCampaign}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = generateStateToProps('messagesPage');

const mapDispatchToProps = generateDispatchToProps({
  fetchCampaignAgreementInfo,
  fetchCampaignEstimates,
  fetchMessages,
  markChatAsRead,
  sendMessage,
  negotiateCampaign,
  acceptCampaign,
  declineCampaign,
  optimisticallyCreateNegotiationMessage,
  resetProps,
  fetchInfluencerFromCampaignAgreement,
  acceptTOS
});

MessagesPageContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectPageVisibilityChecking
)(MessagesPageContainer);

export default MessagesPageContainer;
export { MessagesPage }; // for test
