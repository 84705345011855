import React from 'react';

const PromotionTypesIcon = () => (
  <svg width="61px" height="54px" viewBox="0 0 61 54" version="1.1">
    <g
      id="Illustration_Promotion-type"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <path
        d="M43.466061,32.3610846 C43.8082644,31.992782 44,31.5142881 44,31 C44,29.8945695 43.1054305,29 42,29 C41,29 40.5,29.5632787 40,30.0316394 C39.6666667,30.3438798 39.1683962,30.8333333 38.5051886,31.5 C37.8350629,30.8333333 37.3333333,30.3438798 37,30.0316394 C36.5,29.5632787 36.1054305,29 35,29 C33.8945695,29 33,29.8945695 33,31 C33,31.6311936 33.2910634,32.2094889 33.7821552,32.5872545 C33.8245712,32.6198824 35.3989157,34.1583609 38.5051886,37.2026899 C41.7993924,33.9889923 43.4530166,32.3751239 43.466061,32.3610846 Z M29,31 C29,27.6854305 31.6854305,25 35,25 C36.2868489,25 37.5,25.5 38.5,26.5 C39.5,25.5 40.7131511,25 42,25 C45.3145695,25 48,27.6854305 48,31 C48,32.4540901 47.4786858,33.8310036 46.5530545,34.9084646 C46.4883374,34.9965292 46.415358,35.0806808 46.3340321,35.1599129 L39.8956528,41.4325339 C39.116972,42.1911672 37.8749037,42.1888634 37.0990426,41.4273466 L31.2391497,35.6757931 C29.8364315,34.5469915 29,32.8410964 29,31 Z"
        id="Combined-Shape"
        fill="#2D3767"
        fillRule="nonzero"
      />
      <circle id="Oval-4" fill="#2D3767" fillRule="nonzero" cx="18" cy="11" r="4" />
      <polygon
        id="Rectangle"
        stroke="#2D3767"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="nonzero"
        transform="translate(28.844931, 21.844931) scale(-1, 1) rotate(45.000000) translate(-28.844931, -21.844931) "
        points="16.0554577 6.60442509 28.8449314 -6.15506858 42.3449314 7.31328585 42.3449314 49.8449314 15.3449314 49.8449314"
      />
      <path
        d="M20,11 L2,11"
        id="Line-8"
        stroke="#2D3767"
        strokeWidth="4"
        strokeLinecap="round"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default PromotionTypesIcon;
