// @flow

import React, { useCallback, useEffect, useState } from 'react';

import Modal from '../Modal';

import '../../modals/BasicModal.scss';

type ConfirmProps = {|
  title?: string,
  description?: string,
  actionText?: string,
  closeText?: string,
  actionCategory?: 'primary' | 'danger'
|};

function confirmServiceFactory() {
  let resolver, rejector;

  const defaultContentSettings = {
    title: 'Are you sure?',
    description: 'You need to confirm this action',
    closeText: 'Cancel',
    actionText: 'Confirm',
    actionCategory: 'primary'
  };

  return function () {
    const [isOpen, setOpen] = useState(false);
    const [contentProps, setContentProps] = useState(defaultContentSettings);

    const reset = useCallback(() => {
      resolver = undefined;
      rejector = undefined;
      setContentProps(defaultContentSettings);
      setOpen(false);
    }, []);

    useEffect(() => {
      return function cleanup() {
        reset();
      };
    }, [reset]);

    const doCancel = () => {
      if (rejector) {
        rejector();
      }
      reset();
    };

    const doConfirm = () => {
      if (resolver) {
        resolver();
      }
      reset();
    };

    const ModalComponent = () => (
      <Modal isOpen={isOpen} onClose={() => doCancel()} isBoxed={false}>
        <header className="modal-card-head">
          <p className="modal-card-title">{contentProps.title}</p>
        </header>
        {contentProps.description && (
          <section className="modal-card-body">
            <p>{contentProps.description}</p>
          </section>
        )}
        <footer className="modal-card-foot">
          <button
            className="button"
            onClick={() => {
              doCancel();
            }}>
            {contentProps.closeText}
          </button>
          <button
            className={'button is-' + contentProps.actionCategory}
            onClick={() => {
              doConfirm();
            }}>
            {contentProps.actionText}
          </button>
        </footer>
      </Modal>
    );

    const confirm = (confirmProps?: ConfirmProps): Promise<void> => {
      setContentProps({ ...defaultContentSettings, ...confirmProps });
      setOpen(true);
      const promise = new Promise((resolve, reject) => {
        resolver = resolve;
        rejector = reject;
      });
      return promise;
    };

    return {
      Modal: ModalComponent,
      confirm
    };
  };
}

const useConfirmService = confirmServiceFactory();

export { confirmServiceFactory };
export default useConfirmService;
