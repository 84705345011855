import React from 'react';

const ECPIIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 192 69" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fillRule="nonzero" strokeWidth="4" fill="none" strokeLinecap="round">
        <g stroke={primaryColor}>
          <path d="M138.5 44l11.5 4 3-10M155.5 24l5.5 10 9.5-6M157.5 45.5l5 10.5 10-3.5" />
        </g>
        <path
          d="M162.5 54.5c6-11 11-20.5 27-29.5M148 45c-4-8.5-14.704-21-25-25M176.995 2c-6.189 7.12-13.93 19.019-15.495 31"
          stroke={primaryColor}
        />
        <path stroke={primaryColor} d="M138 53v10h42V53" />
        <path d="M112 51V27M100 39h24" stroke={secondaryColor} />
        <path stroke={primaryColor} d="M50 54v10h42V54" />
        <path stroke={primaryColor} d="M80 47l-9 9-9-9M75 25a6 6 0 0 1-6 6H59a6 6 0 1 1 0-12h4" />
        <path d="M67 25a6 6 0 0 1 6-6h10a6 6 0 1 1 0 12h-4M71 55V39" stroke={primaryColor} />
        <path
          d="M19 32c0-4.647-3.5-8-8-8s-8.5 2.853-8.5 7.5 3 7.5 8.5 9.486c5.5 1.985 9 4.514 9 9.104 0 4.59-4.03 8.455-9 8.455s-9-3.786-9-8.455"
          stroke={primaryColor}
          strokeLinejoin="round"
        />
        <path d="M11 24v-4M11 62v-3" stroke={primaryColor} />
        <path d="M27 67l12-48" stroke={secondaryColor} />
      </g>
    </svg>
  );
};

export default ECPIIcon;
