// @flow
import * as React from 'react';
import classNames from 'classnames';

import WithTimestampUpdate from './common/WithTimestampUpdate';

import './NegotiationMessage.scss';

const messageStatusToIcon = {
  invited_to_join: 'star',
  waiting_for_approval: 'star',
  influencer_set_cpi: 'gavel',
  publisher_set_cpi: 'gavel',
  influencer_declined: 'times',
  publisher_declined: 'times',
  influencer_withdrew: 'times',
  publisher_withdrew: 'times',
  settled: 'handshake-o',
  cancelled: 'times'
};

type Props = {
  created?: string,
  timestamp?: string,
  updated?: boolean,
  reverse?: boolean,
  action: React.Element<*>,
  offer: React.Element<*>,
  status: $Keys<typeof messageStatusToIcon>
};

const NegotiationMessage = (props: Props) => {
  const {
    action,
    offer,
    created = 'a few seconds ago',
    reverse,
    updated,
    timestamp,
    status
  } = props;

  if (!action) return null;

  // timestamp comes from WithTimestampUpdate
  const time = timestamp || created;
  const icon = updated ? 'rotate-left' : messageStatusToIcon[status] || 'check';

  return (
    <div className="NegotiationMessage">
      <div className="NegotiationMessage__content">
        <div className="NegotiationMessage__negotiation">
          {action}
          {offer}
        </div>
        <div
          className={classNames('NegotiationMessage__split', {
            'NegotiationMessage__split--reversed': reverse
          })}>
          <div className="NegotiationMessage__icon">
            <span className="icon is-medium">
              <i className={`fa fa-${icon}`} />
            </span>
          </div>
          <hr />
        </div>
        <div className="NegotiationMessage__created">
          <time>{time}</time>
        </div>
      </div>
    </div>
  );
};

export default WithTimestampUpdate(NegotiationMessage, 'created');

export { messageStatusToIcon }; // for tests
