// @flow
import './index.scss';
import * as React from 'react';
import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import type { ContentPlatform as ContentPlatformType } from 'matchmade-types';

import type {
  CampaignType,
  GamePlatform,
  PlatformSpecificTrackingCodes,
  CampaignGoal as TCampaignGoal
} from 'matchmade-types';

import { getUser } from '../../../helpers/user';

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';

import Budget from './Budget';
import BudgetLock from './BudgetLock';

import InstallAttributionSetting from './InstallAttributionSetting';

import type { AttributionPartnerOption } from './attributionPartners/AttributionPartnerOption';

export type DataProps = {
  goal: TCampaignGoal,
  budget: number, // cents
  njordBudget?: number,

  attributionPartner: string,
  attributionPartnerTrackingCode: string | null,
  attributionPartnerPlatformSpecificTrackingCodes: PlatformSpecificTrackingCodes | null,

  // this is generated in the UI, preferably from redux
  attributionPartnerSelectedOption: AttributionPartnerOption,

  campaignType: CampaignType
};

type Props = DataProps & {
  // current game's platforms
  platforms: GamePlatform[],
  isCreatingNewCampaign?: boolean,

  isDisabled: boolean,

  showAdvancedSettings?: boolean,
  contentPlatform: ContentPlatformType,

  onChange: (attribute: string, data: any) => void
};

function generateOnChange(attribute: string, onChange: (attribute: string, data: any) => void) {
  return data => {
    onChange(attribute, data);
  };
}

function renderBudget(props: Props) {
  if (props.campaignType === CampaignTypeEnum.CHARITY) {
    return null;
  }
  return (
    <Budget
      isDisabled={props.isDisabled}
      value={props.budget}
      onChange={generateOnChange('budget', props.onChange)}
    />
  );
}

function renderBudgetLock(props: Props) {
  const user = getUser();
  if (
    !!user.token.adminAccountId &&
    props.isCreatingNewCampaign &&
    props.njordBudget !== undefined
  ) {
    return (
      <BudgetLock
        isDisabled={props.isDisabled}
        value={props.njordBudget}
        onChange={generateOnChange('njordBudget', props.onChange)}
      />
    );
  }
  return null;
}

function renderAdvancedSettingInstallAttribution(props: Props) {
  return (
    <InstallAttributionSetting
      attributionPartner={props.attributionPartner}
      trackingCode={props.attributionPartnerTrackingCode}
      platformSpecificTrackingCodes={props.attributionPartnerPlatformSpecificTrackingCodes}
      selectedOption={props.attributionPartnerSelectedOption}
      platforms={props.platforms}
      onChangeAttributionPartner={generateOnChange('attributionPartner', props.onChange)}
      onChangeTrackingCode={generateOnChange('attributionPartnerTrackingCode', props.onChange)}
      onChangePlatformSpecificTrackingCodes={generateOnChange(
        'attributionPartnerPlatformSpecificTrackingCodes',
        props.onChange
      )}
      onChangeOption={generateOnChange('attributionPartnerSelectedOption', props.onChange)}
      isDisabled={props.isDisabled}
    />
  );
}

class CampaignDetails extends React.PureComponent<Props, {}> {
  static defaultProps = {
    isDisabled: false
  };
  render() {
    if (this.props.contentPlatform === ContentPlatform.TWITCH) {
      return (
        <div className="CampaignDetails CreateCampaignFields CreateCampaignFields--twitch">
          {renderBudget(this.props)}

          <hr />

          {renderAdvancedSettingInstallAttribution(this.props)}
        </div>
      );
    }

    return (
      <div className="CampaignDetails CreateCampaignFields">
        {renderBudget(this.props)}
        {renderBudgetLock(this.props)}
        {renderAdvancedSettingInstallAttribution(this.props)}
      </div>
    );
  }
}

export default CampaignDetails;
