// @flow
import * as React from 'react';
import chunk from 'lodash/chunk';

import classnames from 'classnames';

import { CsvUpload } from '../../helpers/CsvUpload';
import callApi from '../../helpers/api';
// $FlowFixMe
import { useAsync } from 'react-async';
import Table, { Column } from '../../components/tables/Table';

import './AdminPayoutsPage.scss';
import Button, { Color } from '../../components/common/Button';
// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';
import CheckIcon from '../../components/common/Icons/CheckIcon';
import InfoIcon from '../../components/common/Icons/InfoIcon';
import ListIcon from '../../components/common/Icons/ListIcon';
import Spinner from '../../components/common/Spinner';

// $FlowFixMe
import Accordion from '@material-ui/core/Accordion';
// $FlowFixMe
import AccordionDetails from '@material-ui/core/AccordionDetails';
// $FlowFixMe
import AccordionSummary from '@material-ui/core/AccordionSummary';

const UNCHANGED_MESSAGE = 'Data is already up-to-date';

// https://github.com/SharkPunch/freyja-api/blob/main/src/entities/PayoutTask.ts#L4-L11
// Would be great to define Enum [0], but we're way behind
// current flow version :|
// [0] https://flow.org/en/docs/enums/
export const PayoutTaskStatusEnum = Object.freeze({
  PAYMENT_INFO_PENDING: 'payment_info_pending',
  PAYMENT_INFO_ADDED: 'payment_info_added',
  PAYMENT_INFO_CONFIRMED: 'payment_info_confirmed',
  PAYMENT_INFO_INVALID: 'payment_info_invalid',
  PAID: 'paid',
  PAYMENT_FAILED: 'payment_failed',
  UNKNOWN: 'unknown'
});
export type PayoutTaskStatus = $Values<typeof PayoutTaskStatusEnum>;

type TransactionType = 'not_interesting' | null;

type PayoutTaskPayloadRow = {
  payoutTaskId: string,
  updateParams: {
    status?: PayoutTaskStatus,
    transaction_date?: string,
    expected_payout_date?: string,
    payout_date?: string,
    revenue_recognition_date?: string,
    meta?: Object
  },
  tableData: {
    status?: PayoutTaskStatus,
    reference_id: string,
    timestampDisplay: string | null,
    amount: number,
    transactionType: TransactionType,
    updateMessage?: string,
    payoutCount?: number,
    updateSuccess?: boolean,
    unchanged?: boolean,
    expected_payout_date?: string,
    payout_date?: string,
    revenue_recognition_date?: string
  }
};

const handleUpdatePayoutTask = async (i: PayoutTaskPayloadRow): Promise<PayoutTaskPayloadRow> => {
  try {
    // No need to update unchanged payout tasks
    if (i.tableData.unchanged || isIgnoredTransaction(i.tableData.transactionType)) {
      return i;
    }

    const res = await callApi(`/admin/payout-tasks/${i.payoutTaskId}`, {
      method: 'PATCH',
      body: i.updateParams
    });
    const payoutTaskResponse = res.data;
    if (!payoutTaskResponse || !payoutTaskResponse.success) {
      return {
        ...i,
        tableData: {
          ...i.tableData,
          updateSuccess: false,
          updateMessage: payoutTaskResponse.errorMessage
        }
      };
    }
    return {
      ...i,
      tableData: {
        ...i.tableData,
        updateSuccess: true,
        updateMessage: 'Successfully updated payout task'
      }
    };
  } catch (e) {
    return { ...i, tableData: { ...i.tableData, updateSuccess: false, updateMessage: e.message } };
  }
};

const updatePayoutTasks = async (args: [PayoutTaskPayloadRow[], Function]) => {
  const payoutData = args[0];
  const setPayoutData = args[1];

  const updateResults = await Promise.all(payoutData.map(async t => handleUpdatePayoutTask(t)));
  setPayoutData(updateResults);

  return true;
};

type PayoutTaskData = {
  id: string,
  amount: number,
  currency: string,
  status: PayoutTaskStatus,
  display_info: string | null,
  idempotency_key: string,
  reference_id: string,
  meta: Object | null,
  transaction_date: string | null,
  expected_payout_date: string | null,
  payout_date: string | null,
  revenue_recognition_date: string | null
};

type SuccessfulPayoutTaskDataPromise = $ReadOnly<{
  status: 'fulfilled',
  value: PayoutTaskData
}>;

type FailedPayoutTaskDataPromise = $ReadOnly<{ status: 'rejected', reason: string }>;

type AllSettledPayoutTaskData = SuccessfulPayoutTaskDataPromise | FailedPayoutTaskDataPromise;

const getCurrentPayoutTaskDataInDatabase = async (
  payoutTasks
): Promise<AllSettledPayoutTaskData[]> => {
  const batchSize = 10;
  const batches = chunk(payoutTasks, batchSize);
  let payoutTaskResults = [];
  const getPayoutTasksInBatches = async () => {
    for (const batch of batches) {
      const results = await Promise.allSettled(
        batch.map(async i => {
          const { data } = await callApi(
            `/admin/payout-tasks?reference_id=${i.tableData.reference_id}`
          );
          return data;
        })
      );
      payoutTaskResults = payoutTaskResults.concat(results);
    }
  };

  await getPayoutTasksInBatches();

  return payoutTaskResults;
};

const transactionsToIgnore = ['not_interesting'];
const isIgnoredTransaction = (t: TransactionType) => transactionsToIgnore.includes(t);

const getMixedStringValueOrNull = (row: mixed, name: string): string | null => {
  let value = '';
  if (row && typeof row === 'object') {
    value = row[name];
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return value + '';
    } else if (!value) {
      return null;
    }
  }
  throw new Error(`Badly formatted value [${String(value)}] for [${name}], expecting string`);
};

const getMixedNumberValue = (row: mixed, name: string): number => {
  let value = '';
  if (row && typeof row === 'object') {
    value = row[name];
    if (typeof value === 'number') {
      return value;
    }
  }
  throw new Error(`Badly formatted value [${String(value)}] for [${name}], expecting number`);
};

const toDate = (dateStr: string): Date => {
  // we are expecting date in CSV to be in YYYY-MM-DD format
  const [year, month, day] = dateStr.split('-');
  // Also use Date.UTC, since hours are in local timezone by default.
  // If we don't do this, we can get 2021-05-31T21:00:00.000Z for '01-06-2021'.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
  // We also set time to "last second" of the day, so that we can update
  // payout that happened at the same day the creator filled their payment details
  return new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 23, 59, 59));
};

const parseStatus = (status: string | null): PayoutTaskStatus => {
  if (
    // I wish flow could do something like return status as PayoutTask :/
    status === 'payment_info_pending' ||
    status === 'payment_info_added' ||
    status === 'payment_info_confirmed' ||
    status === 'payment_info_invalid' ||
    status === 'paid' ||
    status === 'payment_failed' ||
    status === 'unknown'
  ) {
    return status;
  } else {
    return 'unknown';
  }
};

const processRow = async (row: Object): Promise<PayoutTaskPayloadRow> => {
  // Get the payout task id first
  const reference_id = getMixedStringValueOrNull(row, 'Payment Reference');
  if (!reference_id) {
    throw new Error(`Missing or invalid Payment Reference in CSV at row number ${row.id}.`);
  }
  const {
    data: { id: payoutTaskId, meta: currentMeta }
  } = await callApi(`/admin/payout-tasks?reference_id=${reference_id}`, {
    method: 'GET'
  });
  if (!payoutTaskId) {
    throw new Error(
      `Could not find a payout task matching the following reference id: ${reference_id}`
    );
  }

  // Next, parse values from CSV
  const status = getMixedStringValueOrNull(row, 'Status');
  const parsedStatus = status ? parseStatus(status) : null;
  const transaction_date = getMixedStringValueOrNull(row, 'Date');
  const transactionType: TransactionType = !reference_id ? 'not_interesting' : null;
  const amount = getMixedNumberValue(row, 'Amount');
  const expected_payout_date = getMixedStringValueOrNull(row, 'Expected Payout Date');
  const payout_date = getMixedStringValueOrNull(row, 'Payout Date');
  const revenue_recognition_date = getMixedStringValueOrNull(row, 'Revenue Recognition Date');

  // Remove keys we explicitly use from row, so we can dump rest into `meta`
  delete row.status;
  delete row.transaction_date;
  delete row.amount;
  delete row['Expected Payout Date'];
  delete row['Payout Date'];
  delete row['Revenue Recognition Date'];
  delete row['Payment Reference'];
  const meta =
    Object.keys(row).length > 0 || currentMeta ? { ...(currentMeta || {}), ...(row || {}) } : null;

  // Build the object to for updateParams into API and tableData for UI
  const updateParams = {};
  const tableData = {};
  tableData.reference_id = reference_id;
  tableData.amount = amount;
  tableData.transactionType = transactionType;
  if (parsedStatus) {
    updateParams.status = parsedStatus;
  }
  if (transaction_date) {
    updateParams.transaction_date = transaction_date;
  }
  if (expected_payout_date) {
    updateParams.expected_payout_date = expected_payout_date;
    tableData.expected_payout_date = expected_payout_date;
  }
  if (payout_date) {
    updateParams.payout_date = payout_date;
    tableData.payout_date = payout_date;
  }
  if (revenue_recognition_date) {
    updateParams.revenue_recognition_date = revenue_recognition_date;
    tableData.revenue_recognition_date = revenue_recognition_date;
  }
  if (meta) {
    updateParams.meta = meta;
  }
  tableData.timestampDisplay = transaction_date
    ? toDate(transaction_date).toISOString().split('T')[0]
    : null;

  const processedRow = {
    updateParams,
    payoutTaskId,
    reference_id,
    tableData
  };

  if (transactionType === 'not_interesting') {
    processedRow.tableData = {
      ...processedRow.tableData,
      updateMessage: 'Row is not a bank or paypal transaction'
    };
  }
  if (parsedStatus) {
    processedRow.tableData = {
      ...processedRow.tableData,
      status: parsedStatus
    };
  }
  return processedRow;
};

const parseCsvData = async (
  csvData: Object,
  setError: Function
): Promise<PayoutTaskPayloadRow[]> => {
  setError(null); // clean up old errors
  if (!Array.isArray(csvData)) {
    setError('Invalid CSV formatting!');
    return [];
  }
  try {
    let payoutCount = 0;

    const results = await Promise.all(
      csvData.map(async (row: Object, index: number) => {
        if (!row || typeof row !== 'object') {
          setError('Malformed CSV data!');
          throw new Error();
        }

        // Always increase payout count, so these match row number in imported csv
        payoutCount++;
        try {
          const processedRow = await processRow(row);
          processedRow.tableData.payoutCount = payoutCount;

          return processedRow;
        } catch (e) {
          setError(`Failed to process row: ${index + 1}, error message: [${e.message}]`);
          throw e;
        }
      })
    );

    const duplicateRefIds = results
      .map(r => r.tableData.reference_id)
      .filter((r, i, arr) => arr.indexOf(r) !== i);

    if (duplicateRefIds.length) {
      setError(
        `Duplicate reference ids found: [${duplicateRefIds.join(
          ', '
        )}]. Leave only the row referencing latest state.`
      );
      throw new Error();
    }

    return results;
  } catch (e) {
    return [];
  }
};

const renderSuccess = data => {
  const validTransactions = data.filter(d => !isIgnoredTransaction(d.transactionType));

  const countSuccess = validTransactions.filter(d => d.updateSuccess && !d.unchanged).length;
  const countUnchanged = validTransactions.filter(d => d.updateSuccess && d.unchanged).length;
  const failed = validTransactions.filter(d => !d.updateSuccess && !d.unchanged);
  const countFail = failed.length;

  const color =
    countSuccess + countUnchanged === 0 ? 'error' : countFail === 0 ? 'success' : 'warning';

  const referenceIds = failed.map(i => i.reference_id).join(', ');

  return (
    <Alert severity={color} className="my-5" variant="filled">
      <AlertTitle>
        Successfully updated [{countSuccess}] out of [{countFail + countSuccess + countUnchanged}]
        payout tasks. [{countUnchanged}] payout tasks were unchanged, because the data in CSV
        matched what is in database already.
      </AlertTitle>
      {countFail > 0 &&
        `Failed to update statuses of payout tasks with reference_id: ${referenceIds}`}
    </Alert>
  );
};

const handleOnData = async (data, setError, setIsLoading, setPayoutData) => {
  try {
    if (!data.every(d => typeof d === 'object')) {
      throw new Error('Invalid data in CSV, must be parsed into an object!');
    }
    setIsLoading(true);
    const csvData = await parseCsvData(data, setError);
    const payoutTasksInDatabase = await getCurrentPayoutTaskDataInDatabase(
      csvData.filter(
        c => !c.tableData.unchanged && !isIgnoredTransaction(c.tableData.transactionType)
      )
    );

    for (const row of csvData) {
      // We already classified this row during parsing process -- skip
      // checking it's status in Freyja db, most likely this transaction
      // isn't there (or otherwise irrelevant to this process)
      if (isIgnoredTransaction(row.tableData.transactionType)) {
        continue;
      }

      const current = payoutTasksInDatabase.find(i => {
        return (
          i.status === 'fulfilled' && i.value && i.value.reference_id === row.tableData.reference_id
        );
      });

      if (!current) {
        row.tableData.updateSuccess = false;
        row.tableData.unchanged = true;
        row.tableData.updateMessage = 'Missing from database';
      } else if (
        current.value &&
        current.value.status === row.updateParams.status &&
        current.value.revenue_recognition_date === row.updateParams.revenue_recognition_date &&
        current.value.payout_date === row.updateParams.payout_date &&
        current.value.expected_payout_date === row.updateParams.expected_payout_date
      ) {
        row.tableData.updateSuccess = true;
        row.tableData.unchanged = true;
        row.tableData.updateMessage = UNCHANGED_MESSAGE;
      }
    }

    setPayoutData(csvData);
  } catch (e) {
    setError(e.message);
  }
  setIsLoading(false);
};

const AdminPayoutsPage = () => {
  const [payoutData, setPayoutData] = React.useState<PayoutTaskPayloadRow[]>([]);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const { data: dataWasUpdated, run: runUpdate, isPending: isPendingUpdate } = useAsync({
    deferFn: updatePayoutTasks,
    onResolve: res => {
      setError(null);
    },
    onReject: (error: Error) => {
      setError(error.message);
    }
  });

  return (
    <div className="AdminPayoutsPage container">
      <div className="AdminPayoutsPage__top">
        <div className="AdminPayoutsPage__item">
          <CsvUpload
            onData={data => {
              handleOnData(data, setError, setIsLoading, setPayoutData);
            }}
          />
        </div>
        <div className="AdminPayoutsPage__item">
          <Button
            onClick={() => {
              runUpdate(payoutData, setPayoutData);
            }}
            disabled={!payoutData || !payoutData.length || isPendingUpdate || isLoading}
            color={Color.PRIMARY}>
            <p>Update payout tasks</p>
          </Button>
        </div>
      </div>
      <div className="mb-6">
        This takes payouts CSV with at least the following columns:
        <pre>
          Payment Reference{'\n'}Status{'\n'}Date{'\n'}Reason{'\n'}Payee{'\n'}Amount{'\n'}
          Expected Payout Date{'\n'}Payout Date{'\n'}Revenue Recognition Date
        </pre>
        {'\n'}
        Any additional columns will be stored as payout metadata during update.
      </div>
      <div className="mb-6">
        <Accordion>
          <AccordionSummary
            expandIcon={<i className="material-icons">chevron_left</i>}
            aria-controls="csv-format-content"
            id="csv-format-header">
            Click here to view accepted formatting for the CSV file
          </AccordionSummary>
          <AccordionDetails>
            <table role="table" className="table is-fullwidth">
              <thead>
                <tr role="row">
                  <th>Column</th>
                  <th>Format</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td role="gridcell">
                    <p>Payment Reference</p>
                  </td>
                  <td role="gridcell">
                    <p>text</p>
                  </td>
                  <td role="gridcell">
                    <code>ID12345</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Status</p>
                  </td>
                  <td role="gridcell">
                    <p>
                      <code>paid</code> or <code>payment_failed</code>
                    </p>
                  </td>
                  <td role="gridcell">
                    <code>paid</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Date in YYYY-MM-DD format</p>
                  </td>
                  <td role="gridcell">
                    <p>text</p>
                  </td>
                  <td role="gridcell">
                    <code>31-03-2021</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Reason</p>
                  </td>
                  <td role="gridcell">
                    <p>Optional free-form reason for payment failure</p>
                  </td>
                  <td role="gridcell" />
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Amount</p>
                  </td>
                  <td role="gridcell">
                    <p>Payout amount (used only for display purposes in this table).</p>
                  </td>
                  <td role="gridcell">
                    <code>-532.82</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Expected Payout Date</p>
                  </td>
                  <td role="gridcell">
                    <p>
                      Timestamp for when we expect the payout to be made, not necessarily the same
                      timestamp when the payout actually happens.
                    </p>
                  </td>
                  <td role="gridcell">
                    <code>31-03-2021</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Payout Date</p>
                  </td>
                  <td role="gridcell">
                    <p>Timestamp for when the payout was sent.</p>
                  </td>
                  <td role="gridcell">
                    <code>31-03-2021</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>Revenue Recognition Date</p>
                  </td>
                  <td role="gridcell">
                    <p>For which date / month is the revenue recognised for this payout.</p>
                  </td>
                  <td role="gridcell">
                    <code>31-03-2021</code>
                  </td>
                </tr>
              </tbody>
            </table>
            Example CSV:
            <pre>
              Payment
              Reference,Status,Date,Reason,Payee,Amount,paypalTransactionId,wiseTransactionId,westernUnionTransactionId,Expected
              Payout Date,Payout Date,Revenue Recognition Date
              {'\n'}
              RIDmefsr,payment_failed,2022-01-19,No money left,Josh,55.00,,wise-44a,,,,{'\n'}
              RIDomizp,paid,2022-01-19,,,-64.02,,,
              RIDticpy,payment_info_added,2022-01-22,,,50,bb233_PAL_05x,,,,,{'\n'}
              RIDticpy,paid,2022-01-23,,,-50.30,,FF4-322ax,31-03-2021,31-03-2021,31-03-2021{'\n'}
            </pre>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="mb-2">
        The CSV is then showed in the table below, with information on whether we managed to find
        payout with payment reference, and whether it's status matches the one in CSV. Then one can
        update payouts by pressing "Update payout tasks" button. See{' '}
        <a
          href="https://paper.dropbox.com/doc/How-to-pay-individual-creator--BaNua3S9PA8Gu5QWB7EWhXLZAg-yyyDz8Wi3lPJk0ALAPoXI"
          target="_blank"
          rel="noopener noreferrer">
          How to pay individual creator
        </a>{' '}
        for more information on how to generate CSV.
      </div>
      <div className="AdminPayoutsPage__success-container">
        {dataWasUpdated ? renderSuccess(payoutData.map(p => p.tableData)) : null}
      </div>
      {error && (
        <Alert severity="error" className="my-5" variant="filled">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
      {isLoading || isPendingUpdate ? (
        <Spinner size="large" mode="fullWidth" centered>
          {isLoading ? 'Fetching payouts current status...' : 'Updating payouts, please wait...'}
        </Spinner>
      ) : (
        <Table
          className="is-fullwidth is-hoverable"
          data={(payoutData || []).map(p => p.tableData)}
          initialSort="timestamp"
          showRowCount
          searchBy={[
            'reference_id',
            'status',
            'timestamp',
            'expected_payout_date',
            'payout_date',
            'revenue_recognition_date'
          ]}>
          <Column name="payoutCount">#</Column>
          <Column
            name="timestampDisplay"
            component={props => <time dateTime={props.data}>{props.data}</time>}>
            Date
          </Column>
          <Column name="reference_id">Payment reference</Column>
          <Column name="amount">Payout amount</Column>
          <Column name="status">Status to set</Column>
          <Column name="reason">Reason</Column>
          <Column name="expected_payout_date">Expected Payout Date</Column>
          <Column name="payout_date">Payout Date</Column>
          <Column name="revenue_recognition_date">Revenue Recognition Date</Column>
          <Column
            name="update_result"
            component={props => {
              const rootClasses = classnames('is-flex is-align-items-center', {
                'has-text-danger': props.rowData.updateSuccess === false
              });

              let icon;
              switch (true) {
                case isIgnoredTransaction(props.rowData.transactionType) ||
                  (props.rowData.updateSuccess && props.rowData.unchanged): {
                  icon = <ListIcon className="has-text-info mr-2" />;
                  break;
                }
                case props.rowData.updateSuccess && !props.rowData.unchanged: {
                  icon = <CheckIcon className="has-text-success mr-2" />;
                  break;
                }
                case props.rowData.updateSuccess: {
                  icon = <InfoIcon className=" mr-2" />;
                  break;
                }
                default:
                  icon = null;
              }

              return (
                <div className={rootClasses}>
                  {icon}
                  {props.rowData.updateMessage}
                </div>
              );
            }}>
            Result of update
          </Column>
        </Table>
      )}
    </div>
  );
};

export default AdminPayoutsPage;
