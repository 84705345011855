import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';

import ANALYTICS from '../../helpers/analytics';
import GAOutboundLink from '../common/GAOutboundLink';

import { getAuthUrlWithNextUrl } from '../../helpers/auth';

import './AuthLanding.scss';

import logo from '../../images/themes/matchmade/logo-large.svg';

class AuthLanding extends React.PureComponent {
  getAuthUrl(name) {
    return getAuthUrlWithNextUrl(name, this.props.location);
  }

  render() {
    return (
      <div className="AuthLanding">
        <section className="hero is-main-brand is-fullheight">
          <div className="mm-hero">
            <div className="tile is-ancestor is-marginless">
              <div className="tile is-parent is-6">
                <div className="tile is-child mm-hero-left">
                  <img src={logo} alt={this.props.intl.formatMessage({ id: 'brand' })} />
                </div>
              </div>
              <div className="tile is-parent is-vertical is-6">
                <div className="tile is-child mm-hero-top">
                  <div className="content">
                    <h1 className="title">
                      <FormattedMessage id="brand" />
                    </h1>
                  </div>
                </div>
                <div className="tile is-child mm-hero-bottom">
                  <div className="content">
                    <p className="subtitle" id="influencerLogin">
                      <GAOutboundLink
                        href={this.getAuthUrl('urls.creatorAuth')}
                        eventLabel={ANALYTICS.LABEL.INFLUENCER_CLICKED_SIGNUP}>
                        <FormattedMessage id="auth.loginInfluencer" />
                      </GAOutboundLink>
                    </p>
                    <p className="subtitle" id="managerLogin">
                      <GAOutboundLink
                        href={this.getAuthUrl('urls.influencerManagerAuth')}
                        eventLabel={ANALYTICS.LABEL.INFLUENCER_MANAGER_CLICKED_SIGNUP}>
                        <FormattedMessage id="auth.loginInfluencerManager" />
                      </GAOutboundLink>
                    </p>
                    <p className="subtitle" id="advertiserLogin">
                      <a href="https://express.matchmade.tv">
                        <FormattedMessage id="auth.loginAdvertiser" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

AuthLanding = injectIntl(AuthLanding);

AuthLanding.topNavigationBar = false;

export default AuthLanding;
