// @flow
import type { AttributionPartnerOption } from '../../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';
import type {
  ContentPlatform,
  GamePlatform,
  PlatformSpecificTrackingCodes,
  PromotionType
} from 'matchmade-types';
import type { GameToImport } from '../../../types/game.flow';
import type { State } from '.';

import overEvery from 'lodash/overEvery';

import {
  AttributionPartner,
  isAttributionPartnerSettingValid
} from '@sharkpunch/matchmade-common/campaign';
import { isAttributionPartnerSettingValidForSelectedOption } from '../../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';

export function validateName(data: { name: string }): boolean {
  return !!data.name;
}

export function validateContentPlatforms(data: { contentPlatforms: ContentPlatform[] }) {
  return !!(data.contentPlatforms || []).length;
}

export function validatePromotionTypes(data: {
  promotionTypes: PromotionType[],
  defaultPromotionType: PromotionType
}): boolean {
  const { promotionTypes, defaultPromotionType } = data;

  return !(promotionTypes.length === 2 && !defaultPromotionType);
}

export function validateProductSelectionAgainstSelectedPlatforms(data: {
  selectedGames: GameToImport[],
  selectedPlatforms: GamePlatform[]
}): boolean {
  const { selectedGames = [], selectedPlatforms = [] } = data;

  if (!selectedGames.length || !selectedPlatforms.length) return false;
  return selectedGames.length === selectedPlatforms.length;
}

export function validateAttributionPartnerSelection(data: {
  attributionPartner: string,
  attributionPartnerPlatformSpecificTrackingCodes: PlatformSpecificTrackingCodes | null,
  attributionPartnerSelectedOption?: AttributionPartnerOption,
  attributionPartnerTrackingCode?: string | null,
  gamePlatforms: Array<GamePlatform>
}): boolean {
  const {
    attributionPartner,
    attributionPartnerTrackingCode = null,
    attributionPartnerSelectedOption = 'none',
    attributionPartnerPlatformSpecificTrackingCodes = null,
    gamePlatforms
  } = data;
  return (
    // isAttributionPartnerSettingValid is broken for Matchmade-link (at least),
    // so blindly just bypass it in that case. For future generations: avoid
    // these "common libraries" unless it's for extremely generic stuff that is not
    // in any way domain-specific
    (attributionPartner === AttributionPartner.MATCHMADE_LINK ||
      isAttributionPartnerSettingValid({
        attributionPartner,
        attributionPartnerTrackingCode,
        attributionPartnerPlatformSpecificTrackingCodes
      })) &&
    isAttributionPartnerSettingValidForSelectedOption({
      attributionPartner,
      attributionPartnerTrackingCode,
      attributionPartnerSelectedOption,
      attributionPartnerPlatformSpecificTrackingCodes,
      gamePlatforms
    })
  );
}

export function validateCampaignDescriptionData(state: State) {
  const { campaignDescription } = state;

  return overEvery([validateName, validateContentPlatforms, validatePromotionTypes])(
    campaignDescription
  );
}

export function validateProductSelectionData(state: State) {
  const { selectedGames, selectedPlatforms } = state.productSelection;
  return validateProductSelectionAgainstSelectedPlatforms({ selectedGames, selectedPlatforms });
}

export function validateCampaignDetailsData(state: State) {
  const { importedGame, campaignDetails, productSelection, campaignDescription } = state;

  if (!productSelection || !campaignDescription) {
    return false;
  }

  return (
    !!importedGame &&
    validateAttributionPartnerSelection({
      ...campaignDetails,
      gamePlatforms: importedGame.platforms
    })
  );
}
