import React from 'react';

const EngagementIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 222 53" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fillRule="nonzero" strokeWidth="4" fill="none" strokeLinecap="round">
        <path
          d="M195 14.5c0-1.097-.854-2-2-2h-11l1.5-6.47.042-.458c0-.619-.125-1.153-.542-1.572l-1.5-2L171 12c-.563.539-1 1.551-1 2.369V27.5c0 1.656 1.27 3 3 3h14c1.292 0 2.52-.943 3-2l4.77-10.54c.147-.34.23-1.131.23-1.51V14.5zm22 7.973l-14 .027c-1.292 0-2.52.943-3 2l-4.77 10.34c-.147.34-.23.719-.23 1.098v2.493c0 1.097.854 2.115 2 2.115h11L206.5 47v.5c0 .618.083 1.081.5 1.5l2 2 10-10.454c.563-.539 1-1.228 1-2.046v-13c0-1.656-1.27-3.027-3-3.027z"
          stroke={primaryColor}
          strokeLinejoin="round"
        />
        <path d="M147 37V13M135 25h24" stroke={secondaryColor} />
        <path
          d="M120 9H87c-2.255 0-4 1.7-4 3.9V49l8-8h29c2.255 0 4-1.8 4-4V12.9c0-2.2-1.745-3.9-4-3.9z"
          stroke={primaryColor}
          strokeLinejoin="round"
        />
        <path d="M59 37V13M47 25h24" stroke={secondaryColor} />
        <path
          d="M20.5 29C14.326 29 2 31.161 2 37v4.9h37V37c0-5.839-12.326-8-18.5-8zM20 23c4.973 0 9-4.027 9-9s-4.027-9-9-9-9 4.027-9 9 4.027 9 9 9z"
          stroke={primaryColor}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default EngagementIcon;
