// @flow
// TODO Split this to two / three / n components!
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';

// $FlowFixMe
import { InfluencerSearchTypeEnum } from '../../../types/search.flow';
import type { InfluencerSearchType } from '../../../types/search.flow';
import type { IntlShape } from 'react-intl';
import OnOffToggle from '../../../components/common/OnOffToggle';

type Props = {
  intl: IntlShape,
  disabled: boolean,
  channelName: ?string,
  playedGames: string[],
  notPlayedGames: string[],
  matchingContentMinVideoCount: number,
  onSelectPlayedGames: (string[]) => void,
  onSelectNotPlayedGames: (string[]) => void,
  onChangeMatchingContentMinVideoCount: (SyntheticInputEvent<*>) => void,
  onBlurMatchingContentMinVideoCount: (SyntheticInputEvent<*>) => void,
  influencerType: InfluencerSearchType,
  onSelectInfluencerType: (influencerType: InfluencerSearchType) => void,
  onChangeChannelName: string => void
};

class GamesAndChannelName extends Component<Props> {
  static defaultProps = { disabled: false };
  onChannelNameChange = event => {
    if (!event) {
      return;
    }
    this.props.onChangeChannelName(event.target.value);
  };
  renderChannelNameSearch = () => {
    return (
      <input
        className="input YoutubeSearchSettings__channel-name-input"
        type="text"
        name="generic-link"
        placeholder={this.props.intl.formatMessage({
          id: 'youtube.search.setting.searchChannelName'
        })}
        disabled={this.props.disabled}
        value={this.props.channelName}
        onChange={this.onChannelNameChange}
      />
    );
  };

  render() {
    return (
      <div className="YoutubeSearchSettings">
        <div className="is-flex-tablet">{this.renderChannelNameSearch()}</div>
        <div className="is-flex-tablet">
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.ALL}
            isRadio={true}
            onClick={() => this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.ALL)}
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.allChannels" />
          </OnOffToggle>
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.IN_NETWORK}
            isRadio={true}
            onClick={() => this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.IN_NETWORK)}
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.onlyInNetwork" />
          </OnOffToggle>
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.OUT_OF_NETWORK}
            isRadio={true}
            onClick={() =>
              this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.OUT_OF_NETWORK)
            }
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.onlyOutOfNetwork" />
          </OnOffToggle>
        </div>
      </div>
    );
  }
}

GamesAndChannelName = injectIntl(GamesAndChannelName);

export default GamesAndChannelName;
