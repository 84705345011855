// @flow
import type { Action } from '../../../types/action.flow';

import {
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection
} from '../../../actions/collection';
import { importYoutubeInfluencersByChannel } from '../../../actions/influencer';
import collectionsReducer from '../../common/collection';
import type { Collection } from '../../../types/collection.flow';

type State = {|
  isImportingChannels: boolean,
  isAddingChannelsToCollection: boolean,
  influencers: Array<any>,
  staticCollections: Array<Collection>,
  addedChannels: Array<string>,
  notFoundChannels: Array<string>
|};

const initialState: State = Object.freeze({
  isImportingChannels: false,
  isAddingChannelsToCollection: false,
  influencers: [],
  staticCollections: [],
  addedChannels: [],
  notFoundChannels: []
});

export default function createAdminDashboardImportInfluencerReducer(
  state: State = initialState,
  action: Action
): State {
  state = collectionsReducer.fetchStaticCollections(state, action);

  switch (action.type) {
    case importYoutubeInfluencersByChannel.REQUEST:
      return {
        ...state,
        influencers: [],
        isImportingChannels: true
      };
    case importYoutubeInfluencersByChannel.SUCCESS:
      return {
        ...state,
        isImportingChannels: false,
        influencers: action.payload
      };
    case importYoutubeInfluencersByChannel.FAILURE:
      return {
        ...state,
        influencers: [],
        isImportingChannels: false
      };
    case addYoutubeChannelToCollection.REQUEST:
    case addTwitchChannelToCollection.REQUEST:
      return {
        ...state,
        isAddingChannelsToCollection: true,
        addedChannels: [],
        notFoundChannels: []
      };
    case addYoutubeChannelToCollection.SUCCESS:
    case addTwitchChannelToCollection.SUCCESS:
      return {
        ...state,
        isAddingChannelsToCollection: false,
        addedChannels: action.payload.channels,
        notFoundChannels: action.payload.notFoundChannels || []
      };
    case addYoutubeChannelToCollection.FAILURE:
    case addTwitchChannelToCollection.FAILURE:
      return {
        ...state,
        isAddingChannelsToCollection: false,
        addedChannels: [],
        notFoundChannels: []
      };
    default:
      return state;
  }
}
