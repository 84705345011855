import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LazyLoad from 'react-lazyload';

import ANALYTICS from '../../helpers/analytics';
import AccountIcon from '../common/Icons/AccountIcon';
import GAOutboundLink from '../common/GAOutboundLink';

import { getFirstAvailableContentPlatform } from '../../helpers/influencer';

import './InfluencerCardHeader.scss';

const LazyLoadImage = ({ avatarUrl, alt }) => {
  const [showFallbackAvatar, setShowFallbackAvatar] = React.useState(false);

  const fallbackAvatar = <AccountIcon className="InfluencerCardHeader__fallback-avatar" />;
  const handleError = () => {
    setShowFallbackAvatar(true);
  };
  const avatar = (
    <img referrerPolicy="no-referrer" src={avatarUrl} alt={alt} onError={handleError} />
  );

  // LazyLoad doesn't work with our testing environment
  // perhaps there should be a <LazyLoadImage> component
  if (process.env.NODE_ENV !== 'test') {
    return <LazyLoad height="100%">{showFallbackAvatar ? fallbackAvatar : avatar}</LazyLoad>;
  }
  return avatar;
};

class InfluencerCardHeader extends Component {
  render() {
    // TODO: use LinkToInfluencer component from InfluencerAvailableCard?
    const influencer = this.props.influencer;
    const isOutOfNetworkInfluencer = influencer.isOutOfNetworkInfluencer;

    const name = influencer.account ? influencer.account.displayName : null;
    const { channel, url } = getFirstAvailableContentPlatform(influencer) || {};

    let avatarUrl = channel
      ? channel.avatarUrl
      : influencer.account
      ? influencer.account.avatarUrl
      : null;
    let channelName = channel ? channel.name : null;

    const avatar = <LazyLoadImage alt={name || channelName} avatarUrl={avatarUrl} />;

    let right = null;
    if (this.props.right) {
      right = <div className="media-right">{this.props.right}</div>;
    }

    // TODO: allow using custom eventLabel for GAOutboundLink
    return (
      <header className="InfluencerCardHeader card-header">
        <div className="media">
          <div className="media-left">
            {isOutOfNetworkInfluencer ? (
              <GAOutboundLink
                href={url}
                eventLabel={ANALYTICS.LABEL.PUBLISHER_CLICKED_CHANNEL_LINK_IN_CAMPAIGN_CARD}
                target="_blank"
                rel="noopener noreferrer">
                <figure className="image is-64x64">{avatar}</figure>
              </GAOutboundLink>
            ) : (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <figure className="image is-64x64">{avatar}</figure>
              </a>
            )}
          </div>
          <div className="media-content media-content--flex">
            <div className="media-content__title">
              <div className="is-truncated">
                <strong>
                  {isOutOfNetworkInfluencer ? (
                    <GAOutboundLink
                      href={url}
                      eventLabel={ANALYTICS.LABEL.PUBLISHER_CLICKED_CHANNEL_LINK_IN_CAMPAIGN_CARD}>
                      {name}
                    </GAOutboundLink>
                  ) : (
                    <Link to={url}>{name}</Link>
                  )}
                </strong>
              </div>
              <div className="is-truncated">
                {isOutOfNetworkInfluencer ? (
                  <GAOutboundLink
                    href={url}
                    eventLabel={ANALYTICS.LABEL.PUBLISHER_CLICKED_CHANNEL_LINK_IN_CAMPAIGN_CARD}>
                    {channelName}
                  </GAOutboundLink>
                ) : (
                  <Link to={url}>{channelName}</Link>
                )}
              </div>
            </div>
          </div>
          {right}
        </div>
      </header>
    );
  }
}

InfluencerCardHeader.propTypes = {
  influencer: PropTypes.object.isRequired,
  // the right side of the header
  // the left side is fixed and can't be changed
  right: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default InfluencerCardHeader;
