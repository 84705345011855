import React from 'react';

const StoreIconSteam = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs />
    <g id="Icon-/-Steam" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M2.02870238,13.2533569 L6.45112096,15.4645662 C6.45943022,14.7466232 6.71993107,14.089294 7.14817638,13.577026 L2,11.0029378 C2.50038539,5.9481866 6.7645765,2 11.950916,2 C17.4737635,2 21.950916,6.4771525 21.950916,12 C21.950916,17.5228475 17.4737635,22 11.950916,22 C6.85257524,22 2.64535541,18.1846577 2.02871125,13.2534279 L2.02870238,13.2533569 Z M14.950916,6 C13.4237321,6 12.1630747,7.14113546 11.950916,8.60417086 C11.4942751,10.571698 11.2654463,11.2948828 9.99194928,13.5740396 C9.81986795,13.5257986 9.63840477,13.5 9.450916,13.5 C8.3463465,13.5 7.450916,14.3954305 7.450916,15.5 C7.450916,16.6045695 8.3463465,17.5 9.450916,17.5 C10.5554855,17.5 11.450916,16.6045695 11.450916,15.5 C11.450916,15.2343522 11.3991245,14.980801 11.3050882,14.7488929 C12.6844486,13.4242076 14.5086984,12.3228078 16.1507013,11.7504676 C17.2103588,11.2875827 17.950916,10.2302745 17.950916,9 C17.950916,7.34314575 16.6077703,6 14.950916,6 Z M14.950916,10 C14.3986313,10 13.950916,9.55228475 13.950916,9 C13.950916,8.44771525 14.3986313,8 14.950916,8 C15.5032008,8 15.950916,8.44771525 15.950916,9 C15.950916,9.55228475 15.5032008,10 14.950916,10 Z"
        id="Combined-Shape"
        fill="#2D3767"
      />
    </g>
  </svg>
);

export default StoreIconSteam;
