import { InfluencerSettings, InfluencerYoutubePreferences } from '../types/influencerSettings.flow';
import { createApiAction } from './helper';

export type GetInfluencerSettings = (accountId: number) => Promise<InfluencerSettings>;
export const getInfluencerSettings = createApiAction('GET_INFLUENCER_SETTINGS', id => {
  return {
    path: `/influencers/${id}/settings`,
    options: {
      method: 'GET'
    }
  };
});

export type SaveInfluencerSettings = (
  accountId: number,
  payload: InfluencerSettings
) => Promise<InfluencerSettings>;
export const saveInfluencerSettings = createApiAction(
  'SAVE_INFLUENCER_SETTINGS',
  (accountId, payload) => {
    return {
      path: `/influencers/${accountId}/settings`,
      options: {
        method: 'PUT',
        body: payload
      }
    };
  }
);

export type GetInfluencerYoutubePreferences = (
  accountId: number
) => Promise<InfluencerYoutubePreferences>;
export const getInfluencerYoutubePreferences = createApiAction(
  'GET_INFLUENCER_YOUTUBE_PREFERENCES',
  id => {
    return {
      path: `/influencers/${id}/youtube-preferences`,
      options: {
        method: 'GET'
      }
    };
  }
);

export type SaveInfluencerYoutubePreferences = (
  accountId: number,
  payload: InfluencerYoutubePreferences
) => Promise<InfluencerYoutubePreferences>;
export const saveInfluencerYoutubePreferences = createApiAction(
  'SAVE_INFLUENCER_YOUTUBE_PREFERENCES',
  (id, payload) => {
    return {
      path: `/influencers/${id}/youtube-preferences`,
      options: {
        method: 'PUT',
        body: payload
      }
    };
  }
);
