import React from 'react';

const Bullhorn = props => (
  <svg
    width={230}
    height={190}
    viewBox="0 0 230 190"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs>
      <rect id="a" width={28} height={78} rx={14} />
      <ellipse id="c" cx={26.5} cy={56} rx={26.5} ry={56} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(41 77)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <rect stroke="#EE322B" strokeWidth={6} x={-3} y={-3} width={34} height={84} rx={17} />
        <path stroke="#EE322B" strokeWidth={6} fill="#F3DCDC" mask="url(#b)" d="M13 15h37v72H13z" />
        <path stroke="#EE322B" strokeWidth={6} fill="#F3C4C4" mask="url(#b)" d="M13 39h39v18H13z" />
      </g>
      <path
        d="M119 6.306v119.388l-4.26-1.971c-2.225-1.03-13.024-6.213-14.756-7.035-7.476-3.547-14.055-6.553-20.76-9.436-10.473-4.502-20.3-8.277-29.583-11.256-14.574-4.678-27.227-7.203-37.578-7.203h-1.52l-.9-1.226C5.994 82.588 3 72.387 3 66c0-7.188 2.425-16.09 6.686-21.624l.9-1.17h1.477c10.351 0 23.004-2.524 37.578-7.202 9.282-2.98 19.11-6.754 29.583-11.256 6.705-2.883 13.284-5.889 20.76-9.436 1.732-.822 12.531-6.005 14.756-7.035L119 6.306z"
        stroke="#EE322B"
        strokeWidth={6}
        fill="#F3C4C4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(94 10)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <ellipse stroke="#EE322B" strokeWidth={6} cx={26.5} cy={56} rx={29.5} ry={59} />
        <rect
          stroke="#EE322B"
          strokeWidth={6}
          fill="#F3C4C4"
          mask="url(#d)"
          x={-24}
          y={39}
          width={47}
          height={34}
          rx={17}
        />
      </g>
      <path
        d="M39 39c-4.5 10-6 18.621-6 27 0 8.379 1.5 18 6 27M130 66.816h20.445c4.153 0 9.72 4.503 9.72 12.184v27.711c0 5.12 2.139 9.789 7.335 9.789s8-3.497 8-9-.17-76-.17-82.422c0-6.422 2.728-10.649 8.67-10.649s8.734 4.7 8.734 10.649V59c0 4.818 3.53 8 8.266 8h6.5"
        stroke="#EE322B"
        strokeWidth={6}
      />
      <path fill="#EE322B" d="M212 64h6v6h-6zM222 64h6v6h-6z" />
    </g>
  </svg>
);

export default Bullhorn;
