import { createApiAction } from './helper';

export const getChats = createApiAction('GET_CHATS', () => {
  return {
    path: '/chats'
  };
});

export const markChatAsRead = createApiAction('MARK_CHAT_AS_READ', campaignAgreementId => {
  return {
    path: `/chats/${campaignAgreementId}/mark-as-read`,
    options: {
      method: 'PUT'
    }
  };
});
