// @flow

import moment from 'moment';

const userDateFormat = 'Do MMM YYYY';
const apiDateFormat = 'YYYY-MM-DD';

function initMomentShortFormat() {
  const defaultLocale = moment.locale();

  moment.defineLocale('short-format', {
    parentLocale: defaultLocale,
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few s',
      ss: '%ds',
      m: 'a m',
      mm: '%dm',
      h: 'an hr',
      hh: '%dhr',
      d: 'a d',
      dd: '%dd',
      M: 'a mo',
      MM: '%dmo',
      y: 'a yr',
      yy: '%dyr'
    }
  });

  moment.locale(defaultLocale);
}

export { userDateFormat, apiDateFormat, initMomentShortFormat };
