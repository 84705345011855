// @flow

import * as React from 'react';
import SearchInput from '../components/common/SearchInput';
import get from 'lodash/get';
import getDisplayName from './getDisplayName';

type State = {
  value: string
};

export type Props = {|
  renderSearchInput: () => void,
  getDataMatchedSearchInput: (data: any, searchBy: any) => any,
  onChangeSearchInput: (value: string) => void,
  searchInput: string
|};

// https://stackoverflow.com/a/6969486
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// TODO switch to useState hook so that we can use HOC type
export default function injectSearchInputController<WrappedComponent: React.ComponentType<*>>(): (
  c: WrappedComponent
) => React.ComponentType<{
  ...$Diff<React.ElementConfig<WrappedComponent>, Props>
}> {
  return (C: WrappedComponent) => {
    class SearchInputController extends React.Component<
      $Diff<React.ElementConfig<WrappedComponent>, Props>,
      State
    > {
      static displayName = getDisplayName('injectSearchInputController', C);

      constructor(props) {
        super(props);

        this.state = {
          value: props.value || ''
        };
      }

      onChangeSearchInput = (value: string) => {
        this.setState({ value });
      };

      renderSearchInput = () => {
        return (
          <SearchInput value={this.state.value} onChangeSearchInput={this.onChangeSearchInput} />
        );
      };

      getDataMatchedSearchInput = (data, searchBy) => {
        const searchInput = (this.state.value || '').trim();
        const searchInputRegex = searchInput ? new RegExp(escapeRegExp(searchInput), 'i') : null;

        return searchBy && searchBy.length && searchInputRegex
          ? data.filter(d => {
              return searchBy.some(attr => {
                if (typeof attr === 'string') {
                  return searchInputRegex.test(get(d, attr));
                }
                if (typeof attr === 'function') {
                  return attr(d, searchInput, attr);
                }
                return false;
              });
            })
          : data;
      };

      render() {
        const props = {
          renderSearchInput: this.renderSearchInput,
          getDataMatchedSearchInput: this.getDataMatchedSearchInput,
          onChangeSearchInput: this.onChangeSearchInput,
          searchInput: this.state.value,
          ...this.props
        };

        return <C {...props} />;
      }
    }

    return SearchInputController;
  };
}
