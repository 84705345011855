/*
 * Control the visibility of CampaignDescriptionModal
 *
 * Usage:
 * ```
 * class SomeCardComponent extends Component { ... }
 *
 * SomeCardComponent = injectCampaignDescriptionModalController(MyComponent);
 * // if you need to inject intl, do it AFTER
 * SomeCardComponent = injectIntl(MyComponent);
 *
 * SomeCardComponent should call onShowCampaignDescriptionModal or onHideCampaignDescriptionModal to change the visibility of CampaignDescriptionModal
 * SomeCardComponent will receive showCampaignDescriptionModal: boolean accordingly
 * SomeCardComponent should call renderCampaignDescriptionModal to render the modal
 *
 * export default SomeCardComponent
 * ```
 */
import React, { Component } from 'react';

import CampaignDescriptionModal from '../../modals/CampaignDescriptionModal';

import PropTypes from 'prop-types';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default function injectCampaignDescriptionModalController(WrappedComponent) {
  class InjectCampaignDescriptionModalController extends Component {
    static displayName = `InjectCampaignDescriptionModalController(${getDisplayName(
      WrappedComponent
    )})`;

    constructor(props) {
      super(props);

      this.state = {
        showCampaignDescriptionModal: false,
        modalShouldSendOffer: false
      };
    }

    onShowCampaignDescriptionModal = (modalShouldSendOffer = true) => {
      this.setState({
        showCampaignDescriptionModal: true,
        modalShouldSendOffer: !!modalShouldSendOffer
      });
    };

    onHideCampaignDescriptionModal = () => {
      this.setState({
        showCampaignDescriptionModal: false,
        modalShouldSendOffer: false
      });
    };

    render() {
      const renderCampaignDescriptionModal = props => {
        if (!this.state.showCampaignDescriptionModal) return null;
        const campaign = this.props.campaign;

        const finalProps = {
          className: 'CampaignCard__modal',
          isOpen: this.state.showCampaignDescriptionModal,
          isSendingOffer: this.state.modalShouldSendOffer,
          isLoading: this.props.isNegotiatingCampaign || this.props.isLoading,
          user: this.props.user,
          onClose: this.onHideCampaignDescriptionModal,

          key: `modal-${campaign.id}`,
          description: campaign.description,
          talkingPoints: campaign.talkingPoints,
          promotionTypes: campaign.promotionTypes,
          contentPlatforms: campaign.contentPlatforms,
          campaignType: campaign.campaignType,
          ...props
        };

        return <CampaignDescriptionModal {...finalProps} />;
      };

      return (
        <WrappedComponent
          {...this.props}
          onShowCampaignDescriptionModal={this.onShowCampaignDescriptionModal}
          onHideCampaignDescriptionModal={this.onHideCampaignDescriptionModal}
          showCampaignDescriptionModal={this.state.showCampaignDescriptionModal}
          modalShouldSendOffer={this.state.modalShouldSendOffer}
          renderCampaignDescriptionModal={renderCampaignDescriptionModal}
        />
      );
    }
  }

  InjectCampaignDescriptionModalController.propTypes = {
    isNegotiatingCampaign: PropTypes.bool,
    isLoading: PropTypes.bool,

    // TODO get rid of this object and use simple props
    campaign: PropTypes.object.isRequired,

    user: PropTypes.object
  };

  return InjectCampaignDescriptionModalController;
}
