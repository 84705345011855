// @flow
import type { ApiResponse } from '../../../types/api/response.flow';
import type { ContentPlatform } from '../../../types/campaign.flow';
import type { GetChannelsReportData } from '../../../types/api/campaigns.flow';
// $FlowFixMe
import { FormattedMessage } from 'react-intl';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import React from 'react';
// $FlowFixMe
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
//$FlowFixMe
import { useAsync } from 'react-async';
import ChannelInfo from './ChannelInfo';
import FormattedNumber from '../../common/FormattedNumber';
import InstallsAndClicksByChannelGraph from '../graphs/InstallsAndClicksByChannelGraph';
import InstallsConversionByChannelGraph from '../graphs/InstallsConversionByChannelGraph';
import NoData from '../../common/NoData';
import Spinner from '../../common/Spinner';
import Table, { Column } from '../../tables/Table';
import ViewsByChannelGraph from '../graphs/ViewsByChannelGraph';
import callApi from '../../../helpers/api';

import './Channels.scss';

configureAnchors({ offset: -60 });

type RowData = $ElementType<GetChannelsReportData, 0>;

async function getChannelsReport({ campaignId }) {
  return callApi(`/campaigns/${campaignId}/channels-report`, {
    method: 'GET'
  });
}

type Props = {
  campaignId: number,
  contentPlatform: ContentPlatform
};

function Graphs(props: { entries: GetChannelsReportData }) {
  const { entries } = props;

  const labels = entries.map(({ influencer }) =>
    influencer ? influencer.account.displayName : 'N/A'
  );
  const installs = entries.map(({ installCount }) => installCount);
  const clicks = entries.map(({ clickCount }) => clickCount);
  const conversionRates = entries.map(
    ({ installCount, clickCount }) => (installCount / clickCount) * 100
  );
  const views = entries.map(({ viewCount }) => viewCount);

  return (
    <React.Fragment>
      <div className="ChannelsTab__graph">
        <InstallsAndClicksByChannelGraph installs={installs} clicks={clicks} labels={labels} />
      </div>
      <div className="ChannelsTab__graph">
        <InstallsConversionByChannelGraph labels={labels} conversionRates={conversionRates} />
      </div>
      <div className="ChannelsTab__graph">
        <ViewsByChannelGraph labels={labels} views={views} />
      </div>
    </React.Fragment>
  );
}

function ChannelsTab(props: Props) {
  const { campaignId, contentPlatform } = props;

  const {
    data,
    isPending
  }: { data: ApiResponse<GetChannelsReportData>, isPending: boolean } = useAsync({
    promiseFn: getChannelsReport,
    campaignId
  });

  if (isPending) {
    return <Spinner mode="fullWidth" size="large" />;
  }

  const campaignChannelsReport = data.data;

  if (!campaignChannelsReport || !campaignChannelsReport.length) {
    return <NoData />;
  }

  return (
    <div className="ChannelsTab">
      <ScrollableAnchor id="participating">
        <div className="ChannelsTab__section">
          <div className="ChannelsTab__title">
            <h2>
              <FormattedMessage id="campaign.report.channels.title" tagName="strong" />
            </h2>
            <h1>
              <a href="#participating">
                <FormattedMessage id="campaign.report.channels.participating" tagName="strong" />
              </a>
            </h1>
          </div>
          <Table
            data={campaignChannelsReport}
            className="is-fullwidth"
            initialSort="channelName"
            initialSortDirection="asc"
            searchBy={[]}>
            <Column
              name="account.displayName"
              component={({ rowData }: { rowData: RowData }) => {
                const { influencer } = rowData;

                if (!influencer) {
                  return null;
                }

                const { url, channel } = influencer
                  ? getFirstAvailableContentPlatform(influencer, contentPlatform) || {}
                  : {};

                if (!channel) {
                  return null;
                }

                return (
                  <a href={url} rel="noopener noreferrer">
                    <strong key={influencer.id} className="ChannelsTab__channel-name">
                      {channel.name}
                    </strong>
                    <div className="ChannelsTab__thumbnail image is-circle is-64x64">
                      <img
                        referrerPolicy="no-referrer"
                        src={channel.avatarUrl}
                        alt={channel.name}
                      />
                    </div>
                  </a>
                );
              }}>
              <FormattedMessage id="campaign.report.channels.channel" />
            </Column>
            <Column
              name="videoStats.channel.subscriberCount"
              component={({ rowData }: { rowData: RowData }) => {
                const { channel } = rowData.influencer
                  ? getFirstAvailableContentPlatform(rowData.influencer, contentPlatform) || {}
                  : {};

                if (!channel || typeof channel.subscriberCount != 'number') {
                  return null;
                }
                return <FormattedNumber value={channel.subscriberCount} type="number" />;
              }}>
              <FormattedMessage id="campaign.report.channels.subscribers" />
            </Column>
            <Column
              name="promotionType"
              component={props => (
                <FormattedMessage id={`campaign.promotionTypes.${props.rowData.promotionType}`} />
              )}>
              <FormattedMessage id="campaign.report.channels.promotionType" />
            </Column>
            <Column
              name="cost"
              component={props => <FormattedNumber value={props.rowData.cost} type="cost" />}>
              <FormattedMessage id="campaign.report.channels.cost" />
            </Column>
          </Table>
        </div>
      </ScrollableAnchor>
      <ScrollableAnchor id="channel-comparison">
        <div className="ChannelsTab__section">
          <div className="ChannelsTab__title">
            <h2>
              <FormattedMessage id="campaign.report.channels.title" tagName="strong" />
            </h2>
            <h1>
              <a href="#channel-comparison">
                <FormattedMessage
                  id="campaign.report.channels.channelComparison"
                  tagName="strong"
                />
              </a>
            </h1>
          </div>
          <Graphs entries={campaignChannelsReport} />
        </div>
      </ScrollableAnchor>
      {campaignChannelsReport
        .map(({ influencer }) => {
          const { channel } = influencer
            ? getFirstAvailableContentPlatform(influencer, contentPlatform) || {}
            : {};

          if (!channel) {
            return null;
          }
          return (
            <ScrollableAnchor key={channel.id} id={`channel-${channel.id}`}>
              <div className="ChannelsTab__section">
                <div className="ChannelsTab__title">
                  <h2>
                    <FormattedMessage id="campaign.report.channels.title" tagName="strong" />
                  </h2>
                  <h1>
                    <a href={`#channel-${channel.id}`}>
                      <FormattedMessage
                        id="campaign.report.channels.channelInformation"
                        tagName="strong"
                      />
                    </a>
                  </h1>
                </div>
                <ChannelInfo channel={channel} />
              </div>
            </ScrollableAnchor>
          );
        })
        .filter(Boolean)}
    </div>
  );
}

export default ChannelsTab;
