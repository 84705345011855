// @flow
import { FormattedMessage } from 'react-intl';
import { OfferType as OfferTypeEnum } from '../../../constants';
import { useDispatch } from 'react-redux';
import React from 'react';
import classNames from 'classnames';

import {
  CampaignType as CampaignTypeEnum,
  ContentPlatform as ContentPlatformEnum
} from '@sharkpunch/matchmade-common/campaign';
import {
  INVITED_TO_JOIN,
  PUBLISHER_SET_CPI,
  SETTLED,
  getCpiFromAgreement,
  getCpmFromAgreement,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement,
  influencerActions,
  publisherActions
} from '@sharkpunch/matchmade-common/campaignAgreement';
import type { ContentPlatform } from 'matchmade-types';

import { acceptCampaign, negotiateCampaign } from '../../../actions/campaign';
import { checkIfWhitelabelAdmin } from '../../../helpers/user';
import { getAvailableDealTypes } from '../../../helpers/campaign';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { validate as isNjordOffer } from '../../../helpers/uuid';
import { setAgreementDeadline } from '../../../actions/campaignAgreement';
import { useModifyOfferOverlay } from '../../common/ModifyOfferOverlay';
import AdminTab, { useAdminTab } from '../InfluencerCard/AdminTab';
import Avatar from '../../messages/Avatar';
import Button, { Color } from '../../common/Button';
import Card from '../../common/Card';
import ChannelHeader from '../../common/ChannelHeader';
import CheckForLatestAgreement from '../../campaignAgreement/CheckForLatestAgreement';
import EditCommissionIcon from '../../common/Icons/EditCommissionIcon';
import EditIcon from '../../common/Icons/EditIcon';
import HistoryIcon from '../../common/Icons/HistoryIcon';
import LastDealsTab from '../../common/TabComponents/LastDealsTab';
import LatestOfferTab from '../../common/TabComponents/LatestOfferTab';
import LinkToInfluencer from '../InfluencerCard/LinkToInfluencer';
import MessagesIcon from '../../common/Icons/MessagesIcon';
import MessagesTab from '../../common/TabComponents/MessagesTab';
import NegotiationIcon from '../../common/Icons/NegotiationIcon';
import SendIcon from '../../common/Icons/SendIcon';
import TabsWithContent from '../../common/TabsWithContent';
import getConfig from '../../../config';

import { getAnalyticsTab, getDemographicsTab } from '../InfluencerCard/common';

import type { CampaignForPublisher } from '../../../types/campaign.flow';
import type { Estimates } from '../../../types/estimates.flow';
import type { EstimatesGameType } from '../../../types/game.flow';

const onAcceptInfluencerOffer = (agreement, attributionPartner, onAcceptCampaign) => {
  onAcceptCampaign(agreement);
};

const getActionButton = (
  influencer,
  agreement,
  isLoading,
  disabled,
  onAcceptCampaign,
  onEdit,
  attributionPartner,
  isCharityCampaign
) => {
  const isOfferFromInfluencer = influencerActions.indexOf(agreement.status) !== -1;
  const isOfferFromPublisher = publisherActions.indexOf(agreement.status) !== -1;

  let acceptMessageId = 'influencer.campaign.accept';
  if (isOfferFromInfluencer) {
    return (
      <Button
        key="accept"
        color={Color.PRIMARY}
        disabled={disabled}
        className="Button__main-action"
        onClick={() => onAcceptInfluencerOffer(agreement, attributionPartner, onAcceptCampaign)}
        loading={isLoading}>
        <FormattedMessage tagName="strong" id={acceptMessageId} />
      </Button>
    );
  }
  if (!agreement.status && !influencer.invite) {
    return (
      <Button
        key="invite"
        color={Color.PRIMARY}
        className="Button__main-action"
        disabled={true}
        onClick={onEdit}
        loading={isLoading}>
        <FormattedMessage tagName="strong" id="campaign.influencer.outOfNetworkNoInvite" />
      </Button>
    );
  }
  if (isOfferFromPublisher || agreement.status === SETTLED) {
    return (
      <Button key="all-done" className="Button__main-action" disabled onClick={() => {}}>
        <FormattedMessage tagName="strong" id="influencer.card.actions.offerSent" />
        <SendIcon />
      </Button>
    );
  }
  return null;
};

type Props = {
  defaultTab: number,
  influencer: any,
  campaign: CampaignForPublisher,
  agreement: any,
  agreementStatus: string,
  agreementDeadline: string,
  isLoading: boolean,
  hideCpi: boolean,
  disabled: boolean,
  estimatedInstalls: number,
  showMessagesTab: boolean,
  showLatestDealsTab: boolean,
  showCommissionTab: boolean,
  estimates: ?Estimates,
  gameType: EstimatesGameType,
  promotionType: string,
  contentPlatform: ContentPlatform,
  adminActions: Array<any>,
  cost: number,
  estimatedCost: number,
  channelName: string,
  error: Object,
  onShowDeclineOfferModal: Function
};

const InfluencerNegotiationCard = (props: Props) => {
  const {
    agreement = {},
    campaign,
    disabled,
    isLoading = false,
    showMessagesTab = true,
    showLatestDealsTab = true,
    cost,
    estimatedCost,
    estimates,
    channelName,
    promotionType,
    contentPlatform,
    agreementStatus,
    agreementDeadline,
    gameType,
    onShowDeclineOfferModal = (params: any) => {},
    adminActions,
    influencer,
    error,
    defaultTab = 1,
    hideCpi,
    showCommissionTab = true
  } = props;

  const dispatch = useDispatch();
  const [seenRefreshWarning, setSeenRefreshWarning] = React.useState(false);
  const [hasMinimalChannelHeader, setHasMinimalChannelHeader] = React.useState(false);

  const { channel, contentPlatform: channelContentPlatform, url: channelUrl } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};
  const dealTypes = getAvailableDealTypes(
    agreement.contentPlatform || campaign.defaultContentPlatform
  );

  const cpi = getCpiFromAgreement(agreement);
  const cpm = getCpmFromAgreement(agreement);
  const minGuarantee = getMinGuaranteeFromAgreement(agreement);
  const maxPayment = getMaxPaymentFromAgreement(agreement);

  const handleDeclineOffer = () => {
    const { channel } = getFirstAvailableContentPlatform(influencer) || {};
    onShowDeclineOfferModal({
      influencerId: influencer.id,
      channelId: channel.id,
      campaignId: agreement.campaignId,
      logId: agreement.logId
    });
  };

  const [modifyOfferOverlay, showModifyOfferOverlay] = useModifyOfferOverlay({
    cpi,
    cpm,
    averageViewCount:
      channelContentPlatform === ContentPlatformEnum.YOUTUBE
        ? channel.last30dStats.averageViewCount
        : 0,
    minGuarantee,
    maxPayment,
    deadline: agreement.deadline,

    isLoading,

    promotionType: agreement.promotionType,
    promotionTypes: campaign.promotionTypes,
    defaultPromotionType: campaign.defaultPromotionType,

    contentPlatform: agreement.contentPlatform,
    contentPlatforms: campaign.contentPlatforms,
    defaultContentPlatform: campaign.defaultContentPlatform,

    campaignType: campaign.campaignType,

    onShowDeclineOfferModal: handleDeclineOffer,
    onMakeOffer: ({
      cpi,
      cpm,
      minGuarantee,
      maxPayment,
      deadline,
      promotionType,
      contentPlatform,
      freeOfCharge,
      offerType
    }) => {
      dispatch(
        negotiateCampaign.run({
          campaignId: agreement.campaignId,
          promotionType,
          contentPlatform,
          influencerId: agreement.influencerId,
          logId: agreement.logId,
          id: agreement.id,
          publisherCpi: cpi,
          publisherCpm: cpm,
          publisherMinGuarantee: minGuarantee,
          publisherMaxPayment: maxPayment,
          deadline,
          freeOfCharge,
          takeItOrLeaveIt: offerType === OfferTypeEnum.EXPRESS
        })
      );
      showModifyOfferOverlay(false);
    },

    estimates,

    gameType,

    hideCpi,
    avatarUrl: channel && channel.avatarUrl,
    displayName: channelName,
    dealTypes,

    hidePromotionType: channelContentPlatform === ContentPlatformEnum.INSTAGRAM,

    checkLatestLog: true,
    campaignId: campaign.id,
    influencerId: influencer.id
  });

  const onShowOverlay = () => {
    showModifyOfferOverlay(true);
  };

  const [adminTab] = useAdminTab({
    cpi,
    cpm,
    minGuarantee,
    maxPayment,
    estimates: agreement.estimates,
    promotionType: agreement.promotionType,
    agreementStatus: agreement.status,
    agreementId: agreement.id,
    contentPlatform: agreement.contentPlatform,
    deadline: agreement.deadline,
    isDisabled: isLoading || disabled,
    commissionRate: Number.isNaN(agreement.commissionRate)
      ? campaign.commissionRate
      : agreement.commissionRate,
    onSubmitDeadline: deadline => {
      dispatch(setAgreementDeadline.run(agreement.id, deadline));
    }
  });

  if (!channel) return null;

  const lastMessage = agreement.lastMessage || {};

  function onAcceptCampaign(agreement) {
    const { channel } = getFirstAvailableContentPlatform(influencer) || {};
    dispatch(acceptCampaign.run({ ...agreement, channelId: channel && channel.id }));
  }

  const addDefaultOnSelectTabHandler = tab => {
    return {
      onSelect: () => {
        setHasMinimalChannelHeader(false);
      },
      ...tab
    };
  };

  const renderActions = (tabIndex, tab) => {
    const isAdminTab = tab.content.type === AdminTab;
    const isEditable = !isNjordOffer(agreement.id);

    const commonButtonProps = {
      color: Color.PRIMARY,
      disabled: disabled,
      loading: isLoading
    };

    return [
      // this is one side only
      // if we have a mock influencer invited by either us or the actual publisher,
      // we can accept the deal as the influencer
      // if we somehow logged in as the mock influencer and make another deal,
      // it follows the normal flow using the edit offer modal
      isAdminTab && [INVITED_TO_JOIN, PUBLISHER_SET_CPI].indexOf(agreement.status) !== -1 ? (
        <Button
          key="set-deal"
          className="Button__main-action AdminTab__accept-as"
          onClick={() => {
            onAcceptCampaign(agreement);
          }}
          {...commonButtonProps}
          color={Color.DANGER}>
          <FormattedMessage id="influencer.card.admin.acceptAsInfluencer" />
          <Avatar url={channel.avatarUrl} size={32} />
        </Button>
      ) : (
        getActionButton(
          influencer,
          agreement,
          isLoading,
          commonButtonProps.disabled,
          onAcceptCampaign,
          onShowOverlay,
          campaign.attributionPartner,
          campaign.campaignType === CampaignTypeEnum.CHARITY
        )
      ),
      <Button
        key="modify"
        buttonClassName="InfluencerNegotiationCard__modify"
        onClick={onShowOverlay}
        loading={isLoading}
        color={isEditable ? Color.PRIMARY : Color.LIGHT}
        tooltip={
          isEditable ? null : (
            <span>This offer is part of bulk offers, and unfortunately can't be modified</span>
          )
        }
        disabled={commonButtonProps.disabled || !isEditable}>
        <EditIcon />
      </Button>,
      <Button
        buttonClassName="InfluencerNegotiationCard__decline"
        color={Color.DANGER}
        key="decline"
        onClick={handleDeclineOffer}
        disabled={disabled}
        loading={isLoading}>
        <FormattedMessage id="actions.decline" tagName="strong" />
      </Button>
    ];
  };

  const publisher = campaign.game.publisherName;
  const actor = influencerActions.indexOf(agreement.status) !== -1 ? channelName : publisher;
  const className = classNames('InfluencerCard', {
    'InfluencerCard--minimal': hasMinimalChannelHeader,
    'InfluencerCard--has-modify-offer-overlay': !!modifyOfferOverlay
  });

  return (
    <Card className={className}>
      <ChannelHeader
        backgroundImage={channel.avatarUrl}
        isVertical
        isMinimal={hasMinimalChannelHeader}
        channelType={contentPlatform}
        adminActions={adminActions}>
        <LinkToInfluencer
          id={influencer.id}
          isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
          channelUrl={channelUrl}
          avatarUrl={channel.avatarUrl}
          channelName={channelName}
          isMock={channel.mock}
        />
      </ChannelHeader>
      <TabsWithContent
        defaultTab={defaultTab}
        isMinimal={hasMinimalChannelHeader}
        actions={renderActions}
        data={[
          getAnalyticsTab({
            influencer,
            defaultContentPlatform: agreement.contentPlatform
          }),
          {
            icon: <NegotiationIcon />,
            content: (
              <LatestOfferTab
                isVertical
                cpi={cpi}
                cpm={cpm}
                minGuarantee={minGuarantee}
                maxPayment={maxPayment}
                freeOfCharge={agreement.freeOfCharge || false}
                cost={cost}
                estimatedCost={estimatedCost}
                promotionType={promotionType}
                contentPlatform={contentPlatform}
                agreementStatus={agreementStatus}
                agreementDeadline={agreementDeadline}
                averageViewCount={
                  channelContentPlatform === ContentPlatformEnum.YOUTUBE
                    ? channel.last30dStats.averageViewCount
                    : 0
                }
                showEffectiveCpm={channelContentPlatform !== ContentPlatformEnum.INSTAGRAM}
                showPromotionType={channelContentPlatform !== ContentPlatformEnum.INSTAGRAM}
                isTakeItOrLeaveIt={agreement.takeItOrLeaveIt}
                publishingWindow={agreement.publishingWindow}
              />
            )
          },
          getDemographicsTab(influencer, agreement.contentPlatform),
          showMessagesTab &&
            agreement.id && {
              icon: <MessagesIcon />,
              content: (
                <MessagesTab
                  content={lastMessage.content}
                  lastMessageRole={lastMessage.role}
                  senderName={lastMessage.senderName}
                  senderAvatarUrl={lastMessage.senderAvatarUrl}
                  created={lastMessage.created}
                  agreementId={agreement.id}
                  noMessageAvatar={channel.avatarUrl}
                  chatClosed={agreement.chatClosed}
                />
              )
            },
          agreement.id &&
            getConfig('features.showAdminCommissionTool') &&
            showCommissionTab &&
            checkIfWhitelabelAdmin() && {
              icon: <EditCommissionIcon />,
              isAdminTab: true,
              content: adminTab
            },
          showLatestDealsTab &&
            channelContentPlatform === ContentPlatformEnum.YOUTUBE &&
            checkIfWhitelabelAdmin() && {
              icon: <HistoryIcon />,
              isAdminTab: true,
              content: (
                <LastDealsTab deals={agreement.lastDeals} campaignId={agreement.campaignId} />
              )
            }
        ]
          .filter(Boolean)
          .map(addDefaultOnSelectTabHandler)}
      />
      {!disabled && (
        <CheckForLatestAgreement
          className="ModifyOfferOverlay"
          error={!seenRefreshWarning ? error : null}
          actor={actor}
          onRefresh={() => setSeenRefreshWarning(true)}
          agreementId={agreement.id}>
          {modifyOfferOverlay}
        </CheckForLatestAgreement>
      )}
    </Card>
  );
};

export default InfluencerNegotiationCard;
