import * as React from 'react';

import { IntlProvider as NativeIntlProvider } from 'react-intl';
import enUS from '../locales/en-US';

export const locales = {
  'en-US': enUS
};

export const defaultLocale = 'en-US';

export function IntlProvider(props) {
  const message = locales[defaultLocale];
  return (
    <NativeIntlProvider textComponent="span" locale="en-US" messages={message}>
      {props.children}
    </NativeIntlProvider>
  );
}
