// @flow
import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import moment from 'moment';
import type { CollectionId, Team, TeamId } from 'matchmade-types/schema';
// $FlowFixMe
import { useIntl } from 'react-intl';

import { InlineTeamManagement, useInlineTeamEntryBeingEdited } from './InlineTeamManagement';
import { getSearchURL } from '../../helpers/searchYoutube';
import Button, { Color } from '../common/Button';
import CopyIcon from '../common/Icons/CopyIcon';
import DeleteIcon from '../common/Icons/DeleteIcon';
import DynamicCollectionIcon from '../../images/icons/DynamicCollectionIcon';
import EditIcon from '../common/Icons/EditIcon';
import FolderIcon from '../common/Icons/FolderIcon';
import Form from '../forms/Form';
import Input from '../forms/controls/Input';
import MTurkEmailIcon from '../common/Icons/MTurkEmailIcon';
import Modal from '../common/Modal';
import OnOffToggle from '../common/OnOffToggle';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import Tooltip from '../common/Tooltip';

import './CollectionsTable.scss';

type DuplicateCollectionModalProps = {
  closeModal: Function,
  onDuplicateAsStaticCollection: Function,
  onDuplicateAsDynamicCollection: Function,
  currentCollection: any,
  isOpen: boolean
};

const DuplicateCollectionModal = ({
  closeModal,
  currentCollection,
  onDuplicateAsStaticCollection,
  onDuplicateAsDynamicCollection,
  isOpen
}: DuplicateCollectionModalProps) => {
  const [asStatic, setAsStatic] = React.useState(true);
  const intl = useIntl();

  if (!currentCollection) {
    return null;
  }
  const isDynamicCollection = !!currentCollection.searchId;

  return (
    <Modal className="CollectionsTable__modal" isOpen={isOpen} onClose={() => closeModal()}>
      <header className="modal-card-head">
        <FormattedMessage
          id="admin.collections.table.duplicate.collectionName"
          values={{ value: (currentCollection || {}).name || '' }}
          tagName="h3"
        />
      </header>
      <Form
        onSubmit={params => {
          if (asStatic) {
            onDuplicateAsStaticCollection(params);
          } else {
            onDuplicateAsDynamicCollection(params);
          }
        }}
        key="form">
        <section className="modal-card-body">
          <Input
            name="newCollectionName"
            type="text"
            placeholder={intl.formatMessage({
              id: 'admin.collections.table.duplicate.placeholder'
            })}
            size="large"
          />
          {isDynamicCollection && (
            <React.Fragment>
              <label className="CollectionsTable__modal-duplicate-label">
                <OnOffToggle
                  isLoading={false}
                  isDisabled={false}
                  isOn={asStatic}
                  onClick={() => setAsStatic(!asStatic)}></OnOffToggle>
                <FormattedMessage id="admin.collections.table.duplicate.asStatic" />
              </label>
            </React.Fragment>
          )}
        </section>
        <footer className="modal-card-foot">
          <Button color={Color.PRIMARY} type="submit" className="action_button" onClick={() => {}}>
            <FormattedMessage id="form.save" tagName="strong" />
          </Button>
          <Button onClick={() => closeModal()} className="action_button">
            <FormattedMessage id="form.cancel" tagName="strong" />
          </Button>
        </footer>
      </Form>
    </Modal>
  );
};

type DeleteCollectionModalPropsp = {
  onDeleteCollection: Function,
  closeModal: Function,
  isOpen: boolean
};
const DeleteCollectionModal = ({
  isOpen,
  onDeleteCollection,
  closeModal
}: DeleteCollectionModalPropsp) => (
  <Modal
    isOpen={isOpen}
    className="CollectionsTable__modal"
    isBoxed={false}
    onClose={() => closeModal()}>
    <header className="modal-card-head">
      <FormattedMessage id="areYouSure" tagName="h3" />
    </header>
    <section className="modal-card-body">
      <FormattedMessage id="influencer.collection.deleteMessage" />
    </section>
    <footer className="modal-card-foot">
      <Button color={Color.DANGER} onClick={() => onDeleteCollection()} className="action_button">
        <FormattedMessage id="form.delete" tagName="strong" />
      </Button>
      <Button onClick={() => closeModal()} className="action_button">
        <FormattedMessage id="form.cancel" tagName="strong" />
      </Button>
    </footer>
  </Modal>
);

type Collections = {|
  id: number,
  creatorId: number,
  teamId: number,
  name: string,
  created: string,
  modified: string,
  team: {
    id: number,
    name: string,
    created: string,
    modified: string
  },
  channels: string[],
  twitchChannels: string[]
|}[];

type Props = {
  collections: ?Collections,
  teams: null | Team[],
  isChangingTeam: boolean,
  onChangeTeam(params: {|
    collectionId: CollectionId,
    teamId: ?TeamId,
    teamName: ?string
  |}): Function,
  onDelete(collectionId: CollectionId): Function,
  onDuplicateAsStaticCollection(params: {|
    name: string,
    id: CollectionId
  |}): Function,
  onDuplicateAsDynamicCollection(params: {|
    name: string,
    id: CollectionId
  |}): Function
};

function CollectionsTable(props: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);

  const {
    teams,
    isChangingTeam,
    onDelete,
    onDuplicateAsStaticCollection,
    onDuplicateAsDynamicCollection,
    onChangeTeam = params => {}
  } = props;

  const [collectionToChangeTeam, setCollectionToChangeTeam] = useInlineTeamEntryBeingEdited(
    null,
    isChangingTeam
  );

  const getChannelsCountFromCollection = collection => {
    const channelsCount =
      (collection.youtubeChannelsCount || 0) +
      (collection.twitchChannelsCount || 0) +
      (collection.instagramChannelsCount || 0);
    return channelsCount;
  };

  const closeModal = () => {
    // Let's close both, they won't be open at the same time
    setShowDuplicateModal(false);
    setShowDeleteModal(false);
  };

  const openModal = (collection, setModalAction) => {
    setModalAction(true);
    setCurrentCollection(collection);
  };

  const duplicateAsStaticCollection = ({ newCollectionName }) => {
    if (currentCollection) {
      onDuplicateAsStaticCollection({
        name: newCollectionName,
        id: currentCollection.id
      });
    }
    setShowDuplicateModal(false);
  };

  const duplicateAsDynamicCollection = ({ newCollectionName }) => {
    if (currentCollection) {
      onDuplicateAsDynamicCollection({
        name: newCollectionName,
        id: currentCollection.id
      });
    }
    setShowDuplicateModal(false);
  };

  const deleteCollection = () => {
    if (currentCollection) {
      onDelete(currentCollection.id);
    }
    setShowDeleteModal(false);
  };

  const modals = [
    <DuplicateCollectionModal
      key="collections-table-duplicate-modal"
      isOpen={showDuplicateModal}
      currentCollection={currentCollection}
      onDuplicateAsStaticCollection={duplicateAsStaticCollection}
      onDuplicateAsDynamicCollection={duplicateAsDynamicCollection}
      closeModal={closeModal}
    />,
    <DeleteCollectionModal
      key="collections-table-delete-modal"
      isOpen={showDeleteModal}
      onDeleteCollection={deleteCollection}
      closeModal={closeModal}
    />
  ].filter(Boolean);

  if (!props.collections) {
    return null;
  }

  return [
    <Table
      key="collections-table"
      data={props.collections}
      initialSort="id"
      initialSortDirection="desc"
      className="is-fullwidth CollectionsTable"
      pagination={props.collections.length > pageLimitOptions[1]}
      searchBy={['id', 'name', 'team.name']}>
      <Column name="id">
        <FormattedMessage id="admin.collections.table.header.id" />
      </Column>
      <Column
        name="name"
        component={props => {
          const collection = props.rowData;
          return (
            <div className="CollectionsTable__name">
              {collection.searchId ? (
                <Tooltip tooltip={<FormattedMessage id={'influencer.collection.dynamic'} />}>
                  <DynamicCollectionIcon />
                </Tooltip>
              ) : (
                <FolderIcon tooltipI18nString="influencer.collection.static" withTooltip />
              )}
              <Link to={`/collections/${collection.id}`}>{collection.name}</Link>
            </div>
          );
        }}>
        <FormattedMessage id="admin.collections.table.header.name" />
      </Column>
      <Column
        name="team.name"
        sortBy={name => name.toLowerCase()}
        component={props => {
          const collection = props.rowData;
          if (!collection.team) {
            return null;
          }

          return (
            <InlineTeamManagement
              allTeams={teams}
              currentTeam={collection.team}
              isEditing={collectionToChangeTeam === collection.id}
              isLoading={isChangingTeam}
              onChangeTeam={(teamId, teamName) => {
                if (!collectionToChangeTeam) {
                  return;
                }
                onChangeTeam({
                  collectionId: collectionToChangeTeam,
                  teamId,
                  teamName
                });
              }}
              onCreateTeam={teamName => {
                if (!collectionToChangeTeam) {
                  return;
                }
                onChangeTeam({
                  collectionId: collectionToChangeTeam,
                  teamId: null,
                  teamName
                });
              }}
              onToggleEdit={() => {
                setCollectionToChangeTeam(
                  collection.id === collectionToChangeTeam ? null : collection.id
                );
              }}
            />
          );
        }}>
        <FormattedMessage id="admin.campaigns.table.header.team" />
      </Column>
      <Column
        name="influencerCount"
        sortBy={collection => getChannelsCountFromCollection(collection)}
        component={props => <span>{getChannelsCountFromCollection(props.rowData)}</span>}>
        <FormattedMessage id="admin.collections.table.header.influencers" />
      </Column>
      <Column
        name="created"
        sortBy={created => new Date(created).getTime()}
        component={props => <span>{moment(props.rowData.created).format('MMM Do YYYY')}</span>}>
        <FormattedMessage id="admin.collections.table.header.created" />
      </Column>
      <Column
        name="lastRelatedChannelsUpdated"
        sortBy={lastRelatedChannelsUpdated => new Date(lastRelatedChannelsUpdated).getTime()}
        component={props => {
          const collection = props.rowData;
          if (!collection.lastRelatedChannelsUpdated) {
            return null;
          }
          return <span>{moment(collection.lastRelatedChannelsUpdated).format('MMM Do YYYY')}</span>;
        }}>
        <FormattedMessage id="admin.collections.table.header.lastRelatedChannelsUpdated" />
      </Column>
      <Column
        name="actions"
        sortable={false}
        component={props => {
          const collection = props.rowData;
          const channelsCount = getChannelsCountFromCollection(collection);
          return (
            <div className="CollectionsTable__actions">
              <Link to={`/admin/collections/${collection.id}/fetch-emails`}>
                <MTurkEmailIcon withTooltip tooltipI18nString="tooltips.mturk" />
              </Link>
              {!!channelsCount && (
                <button
                  className="link-button"
                  onClick={() => openModal(collection, setShowDuplicateModal)}>
                  <CopyIcon tooltipI18nString="tooltips.duplicate" withTooltip />
                </button>
              )}
              <button
                className="link-button"
                onClick={() => openModal(collection, setShowDeleteModal)}>
                <DeleteIcon />
              </button>
              {collection.searchId && (
                <Link
                  to={getSearchURL({
                    collectionId: collection.id,
                    linkToPreviousPage: '/admin/collections'
                  })}
                  className="SearchLink">
                  <EditIcon tooltipI18nString="tooltips.editSearch" withTooltip />
                </Link>
              )}
            </div>
          );
        }}></Column>
    </Table>,
    ...modals
  ];
}

export default CollectionsTable;
