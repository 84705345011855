// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Link from 'react-router/lib/Link';
// $FlowFixMe
import Select from 'react-select';
import type { IntlShape } from 'react-intl';

import {
  addTwitchChannelToCollection,
  createCollection,
  getStaticCollections
} from '../../actions/collection';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';
import type { Collection } from '../../types/collection.flow';

import './AdminDashboardImportInfluencer.scss';

type Props = {
  isAddingChannelsToCollection: boolean,
  addedChannels: string[],
  notFoundChannels: string[],
  influencers: any[],
  staticCollections: Collection[],
  fetchCollections: () => void,
  createCollection: (channelIds: string[], collectionId: number) => void,
  addTwitchChannelToCollection: (channelIds: string[], collectionId: number) => void,
  intl: IntlShape
};

type State = {
  submitted: boolean,
  channelIds: string,
  selectedCollection: ?Collection
};

class AdminDashboardAddTwitchChannelsToCollection extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = { channelIds: '', selectedCollection: null, submitted: false };
  }
  componentDidMount() {
    this.props.fetchCollections();
  }

  onAddToCollectionClick = () => {
    const { addTwitchChannelToCollection } = this.props;
    const { selectedCollection, channelIds } = this.state;
    const ids = channelIds
      .split(/[\s,]+/)
      .filter(Boolean)
      .filter((item, index, arr) => arr.indexOf(item) === index);

    if (selectedCollection && ids && ids.length) {
      addTwitchChannelToCollection(ids, selectedCollection.id);
      this.setState({
        submitted: true
      });
    }
  };

  onChannelsChange = e => {
    this.setState({ channelIds: e.target.value, submitted: false });
  };

  onSelectCollection = option => {
    this.setState({ selectedCollection: option, submitted: false });
  };

  renderAddToCollection() {
    const { selectedCollection } = this.state;

    return (
      <div className="field is-horizontal AdminDashboardImportInfluencer__add-to-collection-section">
        <div className="field-label" />
        <div className="field-body">
          <div className="field">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-fullwidth">{this.renderCollectionDropDown()}</div>
              </div>
              <div className="control">
                <button
                  className="button is-primary"
                  disabled={this.props.isAddingChannelsToCollection || !selectedCollection}
                  onClick={this.onAddToCollectionClick}>
                  <FormattedMessage id="admin.collections.addChannels.addToCollectionButton" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCollectionDropDown = () => {
    const { staticCollections, intl } = this.props;
    const { selectedCollection } = this.state;

    return (
      <Select
        onChange={this.onSelectCollection}
        placeholder={intl.formatMessage({
          id: 'admin.collections.addChannels.selectCollectionPlaceholder'
        })}
        options={staticCollections}
        value={selectedCollection}
        multi={false}
        labelKey="name"
        valueKey="id"
      />
    );
  };

  renderChannelsAddedComparison() {
    const { selectedCollection, channelIds, submitted } = this.state;
    const { isAddingChannelsToCollection, notFoundChannels } = this.props;
    if (!channelIds || !submitted || isAddingChannelsToCollection) {
      return null;
    }
    return (
      <div className="field is-horizontal AdminDashboardImportInfluencer__result-section">
        <div className="field-label" />
        <div className="field-body">
          <div className="field">
            {/* If any channel wasn't added */}
            {!!notFoundChannels.length && (
              <div>
                <label>
                  <FormattedMessage
                    id="admin.collections.addTwitchChannels.nonAddedChannels"
                    tagName="p"
                  />
                </label>
                <ul className="not-found-container">
                  {notFoundChannels.map(channel => (
                    <li>{channel}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* All good! */}
            {!notFoundChannels.length && (
              <div className="label success">
                <FormattedMessage id="admin.collections.addTwitchChannels.success" />
              </div>
            )}
            {/* Link to collection */}
            {selectedCollection && (
              <Link to={`/collections/${selectedCollection.id}`}>
                <FormattedMessage id="admin.collections.addTwitchChannels.goToCollection" />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { channelIds } = this.state;
    return (
      <div className="container AdminDashboardImportInfluencer">
        <Helmet title="Add twitch channels to collection" />
        <h1 className="title">
          <FormattedMessage id="admin.collections.addTwitchChannels.title" />
        </h1>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
              <FormattedMessage id="admin.collections.addChannels.channelsLabel" />
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <textarea
                  value={channelIds}
                  onChange={this.onChannelsChange}
                  className="textarea"
                  placeholder={this.props.intl.formatMessage({
                    id: 'admin.collections.addTwitchChannels.channelListPlaceholder'
                  })}
                  rows="10"
                />
              </div>
              <p className="help">
                <FormattedMessage id="admin.collections.addChannels.helpInfo" />
              </p>
            </div>
          </div>
        </div>
        {this.renderAddToCollection()}
        {this.renderChannelsAddedComparison()}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardImportInfluencer');
const mapDispatchToProps = generateDispatchToProps({
  addTwitchChannelToCollection,
  createCollection,
  fetchCollections: getStaticCollections
});

AdminDashboardAddTwitchChannelsToCollection = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardAddTwitchChannelsToCollection);

export default injectIntl(AdminDashboardAddTwitchChannelsToCollection);
