// @flow
import * as React from 'react';
import chunk from 'lodash/chunk';

import { CsvUpload } from '../../helpers/CsvUpload';
import callApi from '../../helpers/api';
// $FlowFixMe
import { useAsync } from 'react-async';
import Table, { Column } from '../../components/tables/Table';

import './AdminInvoicesPage.scss';
import Button, { Color } from '../../components/common/Button';
// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import Accordion from '@material-ui/core/Accordion';
// $FlowFixMe
import AccordionDetails from '@material-ui/core/AccordionDetails';
// $FlowFixMe
import AccordionSummary from '@material-ui/core/AccordionSummary';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';
import CheckIcon from '../../components/common/Icons/CheckIcon';
import InfoIcon from '../../components/common/Icons/InfoIcon';
import ListIcon from '../../components/common/Icons/ListIcon';
import Spinner from '../../components/common/Spinner';

const UNCHANGED_MESSAGE = 'Data is already up-to-date';

type InvoiceItemPayloadRow = {
  id: string,
  invoiceId?: string,
  amount?: string,
  status: string,
  updateSuccess?: boolean,
  unchanged?: boolean,
  updateMessage?: string
};

type InvoiceItem = {
  amount: string,
  campaign_id: string,
  category: string,
  currency: string,
  customer_id: string,
  deal_id: string,
  description: string,
  id: string,
  idempotency_key: string,
  invoice_id: string | null,
  invoice_item_id: string | null,
  meta: { channel_id: string, content_id: string, campaign_id: string },
  payout_id: string,
  status: string,
  user_name: string
};

const handleUpdateInvoiceItem = async (
  i: InvoiceItemPayloadRow
): Promise<InvoiceItemPayloadRow> => {
  try {
    // No need to update unchanged invoice items
    if (i.unchanged) {
      return i;
    }

    await callApi(`/admin/invoice-items/update`, {
      method: 'PUT',
      body: { ...i, invoice_id: i.invoiceId }
    });
    return { ...i, updateSuccess: true, updateMessage: 'Successfully updated invoice item' };
  } catch (e) {
    return { ...i, updateSuccess: false, updateMessage: e.message };
  }
};

const updateItems = async (args: [InvoiceItemPayloadRow[], Function]) => {
  const tableData = args[0];
  const setTableData = args[1];

  const batchSize = 10;
  const batches = chunk(tableData, batchSize);
  let updateResults = [];

  const updateItemsInBatches = async () => {
    for (const batch of batches) {
      const results = await Promise.all(batch.map(handleUpdateInvoiceItem));
      updateResults = updateResults.concat(results);
    }
  };

  await updateItemsInBatches();
  setTableData(updateResults);

  return true;
};

const getCurrentInvoiceItemDataInDatabase = async (invoiceItems): Promise<InvoiceItem[]> => {
  const batchSize = 10;
  const batches = chunk(invoiceItems, batchSize);
  let invoiceItemResults = [];
  const getInvoiceItemsInBatches = async () => {
    for (const batch of batches) {
      const results = await Promise.all(
        batch.map(async i => {
          const { data } = await callApi(`/admin/invoice-items/${i.id}`);
          return data;
        })
      );
      invoiceItemResults = invoiceItemResults.concat(results);
    }
  };

  await getInvoiceItemsInBatches();

  return invoiceItemResults;
};

const parseCsvData = (csvData: mixed, setError: Function): InvoiceItemPayloadRow[] => {
  setError(null); // clean up old errors
  if (!Array.isArray(csvData)) {
    setError('Invalid CSV formatting!');
    return [];
  }
  try {
    return csvData.map((row: mixed, index: number) => {
      if (!row || typeof row !== 'object') {
        setError('Malformed CSV data!');
        throw new Error();
      }
      const { id, status, invoice_id, amount } = row;
      const payload = {};

      if (!id || typeof id !== 'string') {
        setError(`Missing or invalid id from CSV data on row ${index + 1}`);
        throw new Error();
      }
      payload.id = id;

      if (!status || typeof status !== 'string') {
        setError(`Missing or invalid status from CSV data on row ${index + 1}`);
        throw new Error();
      }
      payload.status = status;

      if (invoice_id && (typeof invoice_id === 'string' || typeof invoice_id === 'number')) {
        payload.invoiceId = invoice_id.toString();
      }

      if (amount && (typeof amount === 'string' || typeof amount === 'number')) {
        payload.amount = amount.toString();
      }

      return payload;
    });
  } catch (e) {
    return [];
  }
};

const renderSuccess = data => {
  if (!data || !data.length) {
    return <p>Will update the invoice items with the values visible in the table below</p>;
  }

  const countSuccess = data.filter(d => d.updateSuccess && !d.unchanged).length;
  const countUnchanged = data.filter(d => d.unchanged).length;
  const failed = data.filter(d => !d.updateSuccess);
  const countFail = failed.length;

  const color =
    countSuccess + countUnchanged === 0 ? 'error' : countFail === 0 ? 'success' : 'warning';

  const invoiceIds = failed.map(i => i.id).join(', ');

  return (
    <Alert severity={color} className="my-5" variant="filled">
      <AlertTitle>
        Successfully updated [{countSuccess}] out of [{countFail + countSuccess + countUnchanged}]
        invoice items. [{countUnchanged}] items were unchanged, because the data in CSV matched what
        is in database already.
      </AlertTitle>
      {countFail > 0 && `Failed to update statuses of invoices with id: ${invoiceIds}`}
    </Alert>
  );
};

const isEmpty = invoiceId => !invoiceId || invoiceId === '';

const handleOnData = async (data, setError, setIsLoading, setTableData) => {
  try {
    setIsLoading(true);
    const csvData = parseCsvData(data, setError);
    const invoiceItemsInDatabase = await getCurrentInvoiceItemDataInDatabase(csvData);
    for (const row of csvData) {
      // Match the CSV invoice item into item from database
      const current = invoiceItemsInDatabase.find(i => i.id === row.id);

      if (!current) {
        setError(`Failed to find invoice item in database for id: [${row.id}]`);
        return;
      }

      // Mark "updateSuccess" key as truthy for items that need not be updated, i.e. the items
      // which already have the same status in database as the parsed CSV.

      if (
        ((isEmpty(current.invoice_id) && isEmpty(row.invoiceId)) ||
          current.invoice_id === row.invoiceId) &&
        current.status === row.status
      ) {
        row.updateSuccess = true;
        row.unchanged = true;
        row.updateMessage = UNCHANGED_MESSAGE;
      }
      setTableData(csvData);
    }
  } catch (e) {
    setError(e.message);
  }
  setIsLoading(false);
};

const AdminInvoicesPage = () => {
  const [tableData, setTableData] = React.useState<InvoiceItemPayloadRow[]>([]);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  // This is the function that updates the invoice items
  const { data: dataWasUpdated, run: runUpdate, isPending: isPendingUpdate } = useAsync({
    deferFn: updateItems,
    onResolve: res => {
      setError(null);
    },
    onReject: (error: Error) => {
      setError(error.message);
    }
  });

  const amountSum = React.useMemo(() => {
    return (
      tableData &&
      tableData.length &&
      tableData.reduce((acc, cur) => {
        const amount = parseFloat(cur.amount);
        return acc + (isNaN(amount) ? 0 : amount);
      }, 0)
    );
  }, [tableData]);

  return (
    <div className="container AdminInvoicesPage">
      <div className="AdminInvoicesPage__top">
        <div className="AdminInvoicesPage__item">
          <CsvUpload
            onData={data => {
              handleOnData(data, setError, setIsLoading, setTableData);
            }}
          />
        </div>
        <div className="AdminInvoicesPage__item">
          <Button
            onClick={() => runUpdate(tableData, setTableData)}
            disabled={!tableData || !tableData.length || isLoading || isPendingUpdate}
            color={Color.PRIMARY}>
            <p>Update invoice statuses</p>
          </Button>
        </div>
      </div>
      <div>
        This takes invoices CSV with the following columns:
        <pre>
          id{'\n'}status{'\n'}invoice_id{'\n'}amount
        </pre>
      </div>
      <div className="mb-6">
        <Accordion>
          <AccordionSummary
            expandIcon={<i className="material-icons">chevron_left</i>}
            aria-controls="csv-format-content"
            id="csv-format-header">
            Click here to view accepted formatting for the CSV file
          </AccordionSummary>
          <AccordionDetails>
            <table role="table" className="table is-fullwidth">
              <thead>
                <tr role="row">
                  <th>Column</th>
                  <th>Format</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td role="gridcell">
                    <p>id</p>
                  </td>
                  <td role="gridcell">
                    <p>text</p>
                  </td>
                  <td role="gridcell">
                    <code>123e4567-e89b-12d3-a456-426652340000</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>status</p>
                  </td>
                  <td role="gridcell">
                    <p>
                      <code>pending</code>, <code>invoiced</code> or <code>paid</code>
                    </p>
                  </td>
                  <td role="gridcell">
                    <code>paid</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>invoice_id</p>
                  </td>
                  <td role="gridcell">
                    <p>
                      text, referencing external payments system invoice (which can be anything)
                    </p>
                  </td>
                  <td role="gridcell">
                    <code>1221</code>
                  </td>
                </tr>
                <tr role="row">
                  <td role="gridcell">
                    <p>amount</p>
                  </td>
                  <td role="gridcell">
                    <p>Invoice amount (used only for display purposes in this table).</p>
                  </td>
                  <td role="gridcell">
                    <code>-532.82</code>
                  </td>
                </tr>
              </tbody>
            </table>
            Example CSV:
            <pre>
              id,status,invoice_id,amount{'\n'}
              8448f13c-a340-4415-b169-219adf5de6ae,paid,RIDmefsr,55.00 {'\n'}
              7723c107-b857-4f9a-8cab-af38f4fb8220,pending,RIDikjhm,918.20{'\n'}
              fcdd6d2e-6244-4db1-ac2d-1d7426d20ce8,invoiced,invoice 115,593.26{'\n'}
              1e3b818f-78a8-4021-8117-8ccac4d08bbc,paid,42,1000.00{'\n'}
            </pre>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="mb-2">
        The CSV is then showed in the table below, with information on whether we managed to find
        payout with payment reference, and whether it's status matches the one in CSV. Then one can
        update payout statuses by pressing "Update payout statuses" button. See{' '}
        <a
          href="https://paper.dropbox.com/doc/How-to-pay-individual-creator--BaNua3S9PA8Gu5QWB7EWhXLZAg-yyyDz8Wi3lPJk0ALAPoXI"
          target="_blank"
          rel="noopener noreferrer">
          How to pay individual creator
        </a>{' '}
        for more information on how to generate CSV.
      </div>
      {amountSum > 0 && <div>Total sum of invoice items: ${amountSum}</div>}
      {dataWasUpdated && (
        <div className="AdminInvoicesPage__success-container">{renderSuccess(tableData)}</div>
      )}
      {error && (
        <Alert severity="error" className="my-5" variant="filled">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
      {isLoading || isPendingUpdate ? (
        <Spinner size="large" mode="fullWidth" centered>
          {isLoading
            ? 'Fetching current status of invoices...'
            : 'Updating invoices, please wait...'}
        </Spinner>
      ) : (
        <Table
          className="is-fullwidth is-hoverable"
          data={tableData}
          initialSort="id"
          searchBy={['id', 'status', 'invoiceId']}
          showRowCount>
          <Column name="id">Id</Column>
          <Column name="status">Status</Column>
          <Column name="invoiceId">Invoice id</Column>
          <Column name="amount">Amount</Column>
          <Column
            name="id"
            component={props => {
              if (props.rowData.updateSuccess) {
                return props.rowData.unchanged ? (
                  <div className="is-flex is-align-items-center">
                    <ListIcon className="has-text-info mr-2" />
                    {props.rowData.updateMessage}
                  </div>
                ) : (
                  <div className="is-flex is-align-items-center">
                    <CheckIcon className="has-text-success mr-2" />
                    {props.rowData.updateMessage}
                  </div>
                );
              }
              if (props.rowData.updateSuccess === undefined) {
                return null;
              }
              if (!props.rowData.updateSuccess) {
                return (
                  <div className="is-flex is-align-items-center has-text-danger">
                    <InfoIcon className=" mr-2" />
                    {props.rowData.updateMessage || 'Failed to update status'}
                  </div>
                );
              }
              return null;
            }}>
            Update result
          </Column>
        </Table>
      )}
    </div>
  );
};

export default AdminInvoicesPage;
