// @flow

import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getUser } from '../../helpers/user';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Spinner from '../common/Spinner';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import type { IntlShape } from 'react-intl';

import './ChatList.scss';

type Props = {
  isLoading: boolean,
  chats: Array<{
    campaignAgreementId: number,
    title: string,
    content: string,
    senderId: number,
    senderName: string,
    logo: string,
    date: Date,
    isNew: boolean
  }>,
  intl: IntlShape,
  onChatClick: () => void
};

class ChatList extends React.PureComponent<Props> {
  static defaultProps = {
    chats: []
  };

  render() {
    const { chats, onChatClick, intl, isLoading } = this.props;
    const noMessage = intl.formatMessage({
      id: 'chat.noMessagesInChat'
    });
    const fromYou = intl.formatMessage({ id: 'chat.fromYou' });
    const user = getUser();

    return (
      <List className="ChatList">
        {chats.map((chat, index) => (
          <React.Fragment key={chat.campaignAgreementId}>
            <ListItem
              href={`/messages/${chat.campaignAgreementId}`}
              className={`ChatList__item ${chat.isNew ? ' new-message' : ''}`}
              target="_blank"
              component="a"
              alignItems="flex-start"
              onClick={onChatClick}>
              <ListItemAvatar>
                <Avatar className="ChatList__item-avatar" alt={chat.title} src={chat.logo} />
              </ListItemAvatar>
              <ListItemText
                className="ChatList__item-content-wrapper"
                primary={
                  <div className="ChatList__item-title">
                    <strong className="ChatList__item-name">{chat.title}</strong>
                    {chat.date && (
                      <span className="ChatList__item-time">{moment(chat.date).fromNow()}</span>
                    )}
                  </div>
                }
                secondary={
                  <span className="ChatList__item-content">
                    {chat.senderId && (
                      <Typography
                        component="span"
                        variant="body2"
                        className="ChatList__item-sender"
                        color="textPrimary">
                        {chat.senderId === user.token.accountId ? fromYou : `${chat.senderName}:`}
                      </Typography>
                    )}
                    <Typography
                      className="ChatList__item-message"
                      color="textSecondary"
                      component="span">
                      {!!chat.content && ` "${chat.content}"`}
                      {!chat.content && noMessage}
                    </Typography>
                  </span>
                }
              />
            </ListItem>
            {index + 1 !== chats.length && !chat.isNew && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        ))}
        {!chats.length && (
          <div className="ChatList__item-no-chats">
            <FormattedMessage id="chat.noAvailableChats" />
          </div>
        )}
        {isLoading && <Spinner size="medium" mode="inline" centered={true} />}
      </List>
    );
  }
}

export default injectIntl(ChatList);
