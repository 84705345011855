// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';

import './InfluencerYoutubePreferencesForm.scss';
import Form from '../forms/Form';
import OnOffToggle from '../common/OnOffToggle';
import type { InfluencerYoutubePreferences } from '../../types/influencerSettings.flow';

const promotionTypes = {
  DEDICATED: 'dedicated',
  INTEGRATED_SHORT: 'integratedShort',
  INTEGRATED_LONG: 'integratedLong'
};

type Props = {
  youtubePreferences: InfluencerYoutubePreferences | null,
  onChange?: (form: InfluencerYoutubePreferences) => void,
  editMode?: boolean
};

export default function InfluencerYoutubePreferencesForm({
  onChange,
  youtubePreferences,
  editMode = false
}: Props) {
  const newYoutubePreferences = youtubePreferences;
  const [form, setForm] = React.useState(newYoutubePreferences);

  let selectedPromotionTypes =
    (form && form.preferredPromotionType && form.preferredPromotionType.split(',')) || [];
  const changePromotionType = option => {
    if (selectedPromotionTypes.includes(option)) {
      selectedPromotionTypes = selectedPromotionTypes.filter(p => p !== option);
    } else {
      selectedPromotionTypes.push(option);
    }
    onChange &&
      onChange({
        ...form,
        preferredPromotionType: selectedPromotionTypes.join(',')
      });
  };
  const isPromotionTypeSelected = option => {
    return selectedPromotionTypes.includes(option);
  };

  const ReadOnlySelectedPromotionTypes = ({ selected }: { selected: string[] }) => {
    return selected.map((p, index) => {
      const last = index === selected.length - 1;
      return (
        <span key={`preferredPromotionType-${p}-${index}`}>
          <FormattedMessage id={`influencer.profile.preferredPromotionType.${p}`} tagName="span" />
          {!last && <span>, </span>}
        </span>
      );
    });
  };

  React.useEffect(() => {
    setForm(newYoutubePreferences);
  }, [newYoutubePreferences]);

  return (
    <Form className="InfluencerYoutubePreferencesForm">
      <div className="columns">
        <div className="column">
          <div className="control">
            <label className="label">
              <FormattedMessage id="influencer.profile.preferredPromotionType" />
            </label>
            {editMode ? (
              <div className="is-flex is-flex-direction-column">
                <label
                  className="checkbox"
                  onClick={() => changePromotionType(promotionTypes.DEDICATED)}>
                  <OnOffToggle
                    isOn={isPromotionTypeSelected(promotionTypes.DEDICATED)}
                    onClick={() => changePromotionType(promotionTypes.DEDICATED)}>
                    <FormattedMessage id="influencer.profile.preferredPromotionType.dedicated" />
                  </OnOffToggle>
                </label>
                <label
                  className="checkbox"
                  onClick={() => changePromotionType(promotionTypes.INTEGRATED_SHORT)}>
                  <OnOffToggle
                    isOn={isPromotionTypeSelected(promotionTypes.INTEGRATED_SHORT)}
                    onClick={() => changePromotionType(promotionTypes.INTEGRATED_SHORT)}>
                    <FormattedMessage id="influencer.profile.preferredPromotionType.integratedShort" />
                  </OnOffToggle>
                </label>
                <label
                  className="checkbox"
                  onClick={() => changePromotionType(promotionTypes.INTEGRATED_LONG)}>
                  <OnOffToggle
                    isOn={isPromotionTypeSelected(promotionTypes.INTEGRATED_LONG)}
                    onClick={() => changePromotionType(promotionTypes.INTEGRATED_LONG)}>
                    <FormattedMessage id="influencer.profile.preferredPromotionType.integratedLong" />
                  </OnOffToggle>
                </label>
              </div>
            ) : (
              <ReadOnlySelectedPromotionTypes selected={selectedPromotionTypes} />
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
