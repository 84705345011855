// @flow
import type { ReducerCreator } from '../../../types/action.flow';

import {
  setAttributeForCampaignDescriptionStep,
  setAttributeForCampaignDetailsStep,
  setAttributesForProductSelectionStep
} from '../../../actions/campaign';

import {
  getSelectedOptionForAttributionPartner,
  resetAttributionPartnerSettingAfterChangingOption
} from '../../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';

export const handleCampaignDescriptionChanges: ReducerCreator<
  {
    stateAttribute?: string
  },
  *
> = (params = {}) => {
  const { stateAttribute = 'campaignDescription' } = params;

  return (state, action) => {
    switch (action.type) {
      case setAttributeForCampaignDescriptionStep.SUCCESS: {
        return {
          ...state,
          [stateAttribute]: {
            ...(state[stateAttribute] || {}),
            [action.payload.attribute]: action.payload.value
          }
        };
      }
      default:
        return state;
    }
  };
};

export const handleProductSelectionChanges: ReducerCreator<
  {
    stateAttribute?: string
  },
  *
> = (params = {}) => {
  const { stateAttribute = 'productSelection' } = params;

  return (state, action) => {
    switch (action.type) {
      case setAttributesForProductSelectionStep.SUCCESS: {
        return {
          ...state,
          [stateAttribute]: action.payload
        };
      }
      default:
        return state;
    }
  };
};

export const handleCampaignDetailsChanges: ReducerCreator<
  {
    stateAttribute?: string
  },
  *
> = (params = {}) => {
  const { stateAttribute = 'campaignDetails' } = params;

  return (state, action) => {
    switch (action.type) {
      case setAttributeForCampaignDetailsStep.SUCCESS: {
        return {
          ...state,
          [stateAttribute]: {
            ...(state[stateAttribute] || {}),
            [action.payload.attribute]: action.payload.value
          }
        };
      }
      default:
        return state;
    }
  };
};

export const handleResetState: ReducerCreator<
  {
    events: string[],
    initialState: any
  },
  *
> = (params = {}) => {
  const { events, initialState } = params;
  return (state, action) => {
    if (events.indexOf(action.type) !== -1) {
      return {
        ...(initialState || state)
      };
    }

    return state;
  };
};

export const handleAttributionPartnerChanges: ReducerCreator<{}, *> = () => {
  return (state, action) => {
    switch (action.type) {
      case setAttributeForCampaignDetailsStep.SUCCESS: {
        switch (action.payload.attribute) {
          case 'attributionPartnerSelectedOption':
            return {
              ...state,
              campaignDetails: resetAttributionPartnerSettingAfterChangingOption(
                state.campaignDetails
              )
            };
          case 'attributionPartner':
            return {
              ...state,
              campaignDetails: {
                ...state.campaignDetails,
                attributionPartnerSelectedOption: getSelectedOptionForAttributionPartner(
                  state.campaignDetails
                )
              }
            };
          default:
            return state;
        }
      }
      default:
        return state;
    }
  };
};
