import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useAsync } from 'react-async';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../Spinner';
import callApi from '../../../helpers/api';
import classNames from 'classnames';

import './AdminApproveDealTab.scss';
import { useIntl } from 'react-intl';

const isValidAmount = amount => {
  return /^\d+(\.\d{1,2})?$/.test(amount);
};

const normalizeDecimalMarker = (value: string) => (value ? value.replace(',', '.') : value);

async function getPayoutsTaskApiCall(options) {
  return callApi(`/agreements/${options.agreementId}/payout-task`);
}

async function getInvoiceItemsApiCall(options) {
  return callApi(`/agreements/${options.agreementId}/invoice-items`);
}

async function approveDealApiCall(
  agreementId,
  payoutAmount,
  invoiceItemAmount,
  setPayoutTask,
  setInvoiceItems
) {
  if (!isValidAmount(payoutAmount)) {
    throw new Error(`${payoutAmount} is not valid, can't create payout`);
  }
  if (!isValidAmount(invoiceItemAmount)) {
    throw new Error(`${invoiceItemAmount} is not valid, can't create invoice item`);
  }
  const response = await callApi(`/agreements/${agreementId}/approve-deal`, {
    method: 'POST',
    body: { payoutAmount, invoiceItemAmount }
  });

  const { payout, invoiceItem, payoutError = null, invoiceItemError = null } = response.data;

  if (payout) {
    setPayoutTask({ data: payout });
  }
  if (invoiceItem) {
    setInvoiceItems({ data: [invoiceItem] });
  }

  if (payoutError) {
    throw new Error(payoutError);
  }

  if (invoiceItemError) {
    throw new Error(invoiceItemError);
  }
}

async function resetPaymentMethodApiCall(agreementId, setData) {
  await callApi(`/agreements/${agreementId}/payout-task/payment-method`, { method: 'DELETE' });
  const updatedPayoutTask = await getPayoutsTaskApiCall({ agreementId });
  setData(updatedPayoutTask);
  return updatedPayoutTask;
}

const LoadingState = () => (
  <div>
    <Spinner mode="fullWidth" size="medium" />
  </div>
);

const EditableCurrencyText = ({ isEditMode, isValid, isLoading, amount, setAmount }) => {
  if (!isEditMode) {
    return <AmountCurrency amount={amount} />;
  }

  return (
    <div className="control has-icons-left is-small">
      <input
        className={classNames('input', { 'is-danger': !isValid })}
        disabled={isLoading}
        type="text"
        value={amount}
        onChange={e => setAmount(normalizeDecimalMarker(e.target.value))}
      />
      <span className="icon is-left">
        <i className="material-icons">attach_money</i>
      </span>
    </div>
  );
};

const ApproveDeal = props => {
  const [error, setError] = useState(null);
  const [payoutAmount, setPayoutAmount] = useState(props.payoutAmount);
  const [invoiceItemAmount, setInvoiceItemAmount] = useState(props.invoiceItemAmount);
  const [isEditMode, setEditMode] = useState(false);

  const isPayoutValid = isValidAmount(payoutAmount) && parseFloat(payoutAmount) > 0;
  const isInvoiceItemValid = isValidAmount(invoiceItemAmount);

  const onApproveDeal = () =>
    props.confirmContext
      .confirm({
        title: 'Are you sure?',
        description: `By confirming that the deal done, we invoice $${invoiceItemAmount} the advertiser and pay $${payoutAmount} the creator or agency. This can't be changed later on!`,
        actionText: 'Confirm deal done'
      })
      .then(() => props.onApproveDeal(payoutAmount, invoiceItemAmount))
      .catch(() => {
        // no need to do anything if the user cancels
      });

  const { isLoading, run } = useAsync({
    deferFn: onApproveDeal,
    onResolve: () => setError(null),
    onReject: e => setError(e.message || 'An error')
  });

  return (
    <div className="ApproveDeal">
      <p style={{ marginBottom: '1rem' }}>
        Confirm that the deal has been executed, and we can invoice the advertiser and pay the
        creator or agency the sums below.
      </p>
      <Alert severity="info" style={{ marginBottom: '1rem' }}>
        Make sure the amounts are correct. This can't be changed later on! If you need to change
        numbers,{' '}
        <button className="link-button" onClick={() => setEditMode(true)}>
          click here
        </button>
      </Alert>
      <div className="ApproveDeal__prices">
        <div className="ApproveDeal__prices-item" style={{ marginRight: '1rem' }}>
          <div className="ApproveDeal__prices-item-label">Invoice to advertiser:</div>
          <EditableCurrencyText
            isEditMode={isEditMode}
            isValid={isInvoiceItemValid}
            isLoading={isLoading}
            amount={invoiceItemAmount}
            setAmount={setInvoiceItemAmount}
          />
        </div>
        <div className="ApproveDeal__prices-item" style={{ marginLeft: '1rem' }}>
          <div className="ApproveDeal__prices-item-label">Payout to creator:</div>
          <EditableCurrencyText
            isEditMode={isEditMode}
            isValid={isPayoutValid}
            isLoading={isLoading}
            amount={payoutAmount}
            setAmount={setPayoutAmount}
          />
        </div>
      </div>
      {isEditMode ? (
        <button
          disabled={!isPayoutValid || !isInvoiceItemValid}
          className={`button is-success is-medium`}
          onClick={() => setEditMode(false)}>
          Save amounts
        </button>
      ) : (
        <button
          disabled={isLoading}
          className={classNames('button is-primary is-medium', { 'is-loading': isLoading })}
          onClick={run}>
          Deal done
        </button>
      )}

      {error && (
        <Alert severity="error" style={{ marginTop: '1rem' }}>
          There was an error when approving the deal: {error}
        </Alert>
      )}
    </div>
  );
};

const statusToText = {
  payment_info_pending: 'Waiting for payment info',
  payment_info_added: 'Payment info added',
  payment_info_confirmed: 'Payment info confirmed',
  payment_info_invalid: 'Incorrect payment info',
  paid: 'Payment done',
  payment_failed: 'Payment failed'
};

// Statuses that we allow to reset payment for
// https://github.com/SharkPunch/freyja-api/blob/main/src/api/v1/payoutTask.ts#L589-L602
const resettableStatuses = ['payment_info_added', 'payment_info_invalid', 'payment_failed'];

const PayoutTaskDetails = ({ amount, url, status, onResetPaymentMethod }) => {
  const [error, setError] = useState(null);
  const resetPaymentMethodService = useAsync({
    deferFn: onResetPaymentMethod,
    onResolve: () => setError(null),
    onReject: e => setError(e.message || 'An error')
  });

  return (
    <div className="Payout">
      <div style={{ marginBottom: '1rem' }}>
        Payout <AmountCurrency amount={amount} /> has been created
      </div>
      <div style={{ marginBottom: '1rem' }}>
        Status: <i>{statusToText[status] || 'Unknown state'}</i>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          Check details
        </a>
      </div>
      {resettableStatuses.includes(status) && (
        <div style={{ marginBottom: '1rem' }}>
          <button className="button Button is-danger" onClick={resetPaymentMethodService.run}>
            Reset payment method
          </button>
        </div>
      )}
      {error && (
        <Alert severity="error" style={{ marginTop: '1rem' }}>
          There was an error when resetting the payment method: {error}
        </Alert>
      )}
    </div>
  );
};

const AmountCurrency = ({ amount }) => {
  const intl = useIntl();

  return <strong>{intl.formatNumber(amount, { style: 'currency', currency: 'USD' })}</strong>;
};

const InvoiceItemsDetails = ({ items = [] }) => {
  const statusToText = {
    pending: 'Invoice item has been created',
    invoiced: 'Invoice item has been sent',
    paid: 'Invoice item has been paid'
  };

  return (
    <div className="InvoiceItems">
      <div style={{ marginBottom: '0.5rem' }}>Invoice items:</div>
      {items.map((invoiceItem, index) => {
        return (
          <div
            key={`${invoiceItem.id}_${index}`}
            style={{ marginBottom: '0.5rem', textAlign: 'left' }}>
            <div>
              Item#{index + 1}: <AmountCurrency amount={invoiceItem.amount} />
            </div>
            <div>
              Status: <i>{statusToText[invoiceItem.status] || 'Unknown status'}</i>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ErrorState = () => (
  <div>
    <p>Could not load Payout or Invoice Item information</p>
  </div>
);

const MissingAmount = () => (
  <div className="MissingAmount">
    <Alert severity="info">
      Can't create payout or invoice item yet because of a missing amount. Has the content been
      fully triggered yet?
    </Alert>
  </div>
);

const DealView = ({
  existingInvoiceItems,
  invoiceItemAmount,
  existingPayout,
  payoutAmount,
  onApproveDeal,
  onResetPaymentMethod,
  confirmContext
}) => {
  const invoiceItemExists = existingInvoiceItems && existingInvoiceItems.length > 0;
  if (invoiceItemExists || existingPayout) {
    return (
      <div className="DealView">
        {existingPayout && (
          <PayoutTaskDetails
            amount={existingPayout.amount}
            url={existingPayout.url}
            status={existingPayout.status}
            onResetPaymentMethod={onResetPaymentMethod}
          />
        )}
        {invoiceItemExists && <InvoiceItemsDetails items={existingInvoiceItems} />}
      </div>
    );
  } else if (!payoutAmount || !invoiceItemAmount) {
    return <MissingAmount />;
  } else {
    return (
      <ApproveDeal
        payoutAmount={payoutAmount}
        invoiceItemAmount={invoiceItemAmount}
        onApproveDeal={onApproveDeal}
        confirmContext={confirmContext}
      />
    );
  }
};

const AdminApproveDealTab = ({ agreementId, payoutAmount, invoiceItemAmount, confirmContext }) => {
  const {
    data: invoiceItemsResponse,
    isPending: isInvoiceItemsLoading,
    setData: setInvoiceItems
  } = useAsync(getInvoiceItemsApiCall, {
    agreementId
  });
  const {
    data: payoutTaskResponse,
    isPending: isPayoutTaskLoading,
    setData: setPayoutTask
  } = useAsync(getPayoutsTaskApiCall, {
    agreementId
  });
  const onResetPaymentMethod = () =>
    confirmContext
      .confirm({
        title: 'Are you sure?',
        description:
          'Resetting payment method causes issues if we have already made the payment. Before resetting, make sure it is ok to do so.',
        actionText: 'Reset payment info'
      })
      .then(() => resetPaymentMethodApiCall(agreementId, setPayoutTask))
      .catch(() => {
        // no need to do anything if the user cancels
      });
  const existingInvoiceItems = invoiceItemsResponse ? invoiceItemsResponse.data : null;
  const existingPayout = payoutTaskResponse ? payoutTaskResponse.data : null;

  const renderContent = () => {
    if (isPayoutTaskLoading || isInvoiceItemsLoading) {
      return <LoadingState />;
    } else if (invoiceItemsResponse || payoutTaskResponse) {
      return (
        <DealView
          existingPayout={existingPayout}
          existingInvoiceItems={existingInvoiceItems}
          payoutAmount={payoutAmount}
          invoiceItemAmount={invoiceItemAmount}
          onApproveDeal={(payout, invoiceItem) =>
            approveDealApiCall(agreementId, payout, invoiceItem, setPayoutTask, setInvoiceItems)
          }
          onResetPaymentMethod={onResetPaymentMethod}
          confirmContext={confirmContext}
        />
      );
    } else {
      return <ErrorState />;
    }
  };
  return <div className="AdminApproveDealTab">{renderContent()}</div>;
};

AdminApproveDealTab.propTypes = {
  agreementId: PropTypes.number.isRequired,
  payoutAmount: PropTypes.string.isRequired,
  invoiceItemAmount: PropTypes.string.isRequired
};

// @lauri: somewhere upper in the call tree we do dumb stuff with
// reducers and updating _all of the tree_ with 2s interval
// without React.memo we'd trigger `useAsync` with that interval
export default React.memo(AdminApproveDealTab);
