import React from 'react';

const CPMIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg height="100%" viewBox="0 0 119 52" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g strokeWidth="4" fill="none" fillRule="evenodd">
        <path
          d="M98 24.5C98 33.613 90.389 41 81 41s-17-7.387-17-16.5S71.611 8 81 8s17 7.387 17 16.5"
          stroke={primaryColor}
        />
        <path
          d="M80 7c-14.575 0-27.56 6.838-36 17.5C52.44 35.162 65.425 42 80 42s27.56-6.838 36-17.5C107.56 13.838 94.575 7 80 7z"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path stroke={primaryColor} strokeLinecap="round" d="M81 14v10.5l5.5 4.5" />
        <path
          d="M19 15c0-4.647-3.5-8-8-8s-8.5 2.853-8.5 7.5 3 7.5 8.5 9.486c5.5 1.985 9 4.514 9 9.104 0 4.59-4.03 8.455-9 8.455s-9-3.786-9-8.455"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M11 7V3M11 45v-3" stroke={primaryColor} strokeLinecap="round" />
        <path d="M27 50L39 2" stroke={secondaryColor} strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default CPMIcon;
