// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { getUser } from '../../helpers/user';
import { updateSettings } from '../../actions/account';

import './SortingSelector.scss';

const { useRef } = React;

type Props = {
  options: {
    value: any,
    label: React.Node
  }[],

  label?: React.Node,
  ascending?: boolean,
  settingsPrefix?: string,

  selectedValue: any,

  onSelect?: (params: { sortProp: string, ascending: boolean }) => void
};

export default function SortingSelector(props: Props) {
  const {
    options = [],
    label = 'Sort by',
    ascending = false,
    settingsPrefix,
    selectedValue,
    onSelect
  } = props;

  const dispatch = useDispatch();

  const selectEl = useRef(null);

  if (!options.length) return null;

  const optionNodes = options.map(opt => {
    return (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    );
  });

  function onUpdateSettings(settings) {
    const user = getUser();

    if (!user) return;

    dispatch(updateSettings.run(user.id, settings));
  }

  function onChangeSortingOption(changeDirection) {
    if (!selectEl.current) {
      return;
    }
    const isAscending = changeDirection ? !ascending : ascending;
    const kwObj = { sortProp: selectEl.current.value, ascending: isAscending };
    onSelect && onSelect(kwObj);

    if (!settingsPrefix) {
      console.warn(
        'No settings prefix passed to SortingSelector -- cannot persist sorting options!'
      );
      return;
    }
    onUpdateSettings({ [settingsPrefix]: kwObj });
  }

  const iconClassName = `fa fa-angle-double-${ascending ? 'down' : 'up'}`;

  return (
    <div className="SortingSelector is-flex-tablet">
      <label className="label">{label}</label>
      <div className="field has-addons">
        <div className="control">
          <div className="select">
            <select
              onChange={() => {
                onChangeSortingOption(false);
              }}
              ref={selectEl}
              value={selectedValue}>
              {optionNodes}
            </select>
          </div>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            onClick={() => {
              onChangeSortingOption(true);
            }}>
            <span className="icon">
              <i className={iconClassName} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
