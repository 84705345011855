// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';
const GavelIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="GavelIcon">
      {() => <i className="material-icons">gavel</i>}
    </Icon>
  );
};

export default GavelIcon;
