// @flow
import type { ContentPlatform } from 'matchmade-types';
import type { ContentType } from '../../../../types/campaignAgreement.flow';

import React from 'react';
import classNames from 'classnames';
// $FlowFixMe
import * as Sentry from '@sentry/browser';
//$FlowFixMe
import { useAsync } from 'react-async';
import useError from '../../../../hooks/useError';

import Button, { Color } from '../../../common/Button';
import CheckIcon from '../../../common/Icons/CheckIcon';

import {
  ContentPlatform as ContentPlatformEnum,
  ContentType as ContentTypeEnum,
  Regex
} from '../../../../constants';

import { isRuntimeError } from '../../../../helpers/errorCode.js';
import callApi from '../../../../helpers/api.js';

import './LinkInfluencerContent.scss';

const supportedUrls = [
  {
    regex: Regex.INSTAGRAM_POST_ID,
    contentPlatform: ContentPlatformEnum.INSTAGRAM,
    contentType: ContentTypeEnum.INSTAGRAM_POST,
    group: 'shortcode'
  },
  {
    regex: Regex.YOUTUBE_VIDEO_ID,
    contentPlatform: ContentPlatformEnum.YOUTUBE,
    contentType: ContentTypeEnum.YOUTUBE_VIDEO,
    group: 'videoId'
  }
];

function extractContentIdAndTypeFromUrlOrThrow(url) {
  for (const supported of supportedUrls) {
    const { regex, contentPlatform, contentType, group } = supported;
    const matches = url.match(regex);

    if (matches && matches.groups && matches.groups[group]) {
      return {
        contentType,
        contentPlatform,
        contentUrl: url
      };
    }
  }
  throw new Error(`URL is not supported`);
}

type Notification = {
  type: 'danger' | 'warn' | 'info' | 'success',
  message: string
} | null;

function useTriggerPublishedContent() {
  const { showError } = useError();

  const [notification, setNotification] = React.useState<Notification>(null);

  const submit = useAsync({
    deferFn: ([agreementId, contentType, contentUrl]) => {
      return callApiForTriggeringContent(agreementId, contentType, contentUrl);
    },
    onResolve: data => {
      setNotification({
        type: 'success',
        message: 'The content has been linked to this agreement'
      });
    },
    onReject: e => {
      showError(e);
      let errorMessage = e.message || 'Internal error';
      if (isRuntimeError(e)) {
        Sentry.captureException(e);
        console.error(e);
        errorMessage = 'Internal error';
      }

      setNotification({
        type: 'danger',
        message: errorMessage
      });
    }
  });

  return {
    run: submit.run,
    isLoading: submit.isPending,
    notification,
    clearNotification() {
      setNotification(null);
    }
  };
}

async function callApiForTriggeringContent(agreementId, contentType, contentUrl) {
  return callApi(`/agreements/${agreementId}/${contentType}/publish`, {
    method: 'POST',
    body: { contentUrl }
  });
}

export function AddPublishedContent(props: {
  agreementId: number | null,
  contentPlatform: ContentPlatform,
  agreementId: number | null,
  isDisabled?: boolean,
  className?: string,
  label?: any
}) {
  const { run, isLoading, notification, clearNotification } = useTriggerPublishedContent();

  return (
    <LinkInfluencerContent
      {...props}
      notification={notification}
      isLoading={isLoading}
      onSubmit={({ contentType, contentUrl }) => {
        run(props.agreementId, contentType, contentUrl);
      }}
      onClearNotification={clearNotification}
    />
  );
}

// internal component for now until we have the need to export it
// this component can be used to handle 2 cases
// - add published content
// - submit content for review
// This component just renders the input control and handles input validation
function LinkInfluencerContent({
  contentPlatform,
  isDisabled = false,
  className = '',
  isLoading = false,
  notification = null,
  onSubmit,
  onClearNotification = () => {}
}: {
  contentPlatform: ContentPlatform,
  isDisabled?: boolean,
  isLoading?: boolean,
  className?: string,
  notification?: Notification,
  onSubmit: (obj: {
    contentType: ContentType,
    contentPlatform: ContentPlatform,
    contentUrl: string
  }) => void,
  onClearNotification?: () => void
}) {
  const [value, setValue] = React.useState<string>('');
  const [isInputInvalid, setIsInputInvalid] = React.useState(!!value);
  const [interalErrorMessage, setInternalErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!isInputInvalid) {
      setInternalErrorMessage(null);
    }
  }, [isInputInvalid]);

  const cls = classNames(`input ${className}`, {
    'is-warning': isInputInvalid
  });

  function getNotifcation(): Notification {
    // internal error message has higher priority
    if (!!interalErrorMessage) {
      return {
        type: 'danger',
        message: interalErrorMessage
      };
    }

    return notification;
  }

  const internalNotification = getNotifcation();

  return (
    <div className="LinkInfluencerContent">
      <div className="field has-addons">
        <div className="control LinkInfluencerContent__url-input">
          <input
            id="published-content"
            type="text"
            className={cls}
            disabled={isDisabled || isLoading}
            value={value}
            placeholder="http(s)://"
            onFocus={e => {
              onClearNotification();
            }}
            onChange={e => {
              setValue(e.target.value);
              setIsInputInvalid(!Regex.URL.test(e.target.value));
            }}
            onBlur={e => {
              setIsInputInvalid(!Regex.URL.test(e.target.value));
            }}
          />
        </div>
        <Button
          size="small"
          key="submit-content"
          className="Button__main-action AdminTab__submit-content-url"
          onClick={() => {
            onClearNotification();

            if (!value) {
              return;
            }

            try {
              const {
                contentType,
                contentUrl,
                contentPlatform: _platform
              } = extractContentIdAndTypeFromUrlOrThrow(value);

              if (contentPlatform !== _platform) {
                throw new Error(`This agreement only supports ${contentPlatform}`);
              }

              onSubmit({
                contentType,
                contentPlatform: _platform,
                contentUrl
              });
            } catch (e) {
              setIsInputInvalid(true);
              setInternalErrorMessage(e.message);
            }
          }}
          disabled={isInputInvalid || isDisabled || isLoading}
          loading={isLoading}
          color={Color.DANGER}>
          <CheckIcon />
        </Button>
      </div>

      {internalNotification && (
        <p className={`help is-${internalNotification.type}`}>{internalNotification.message}</p>
      )}
    </div>
  );
}
