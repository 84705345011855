import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';

import pick from 'lodash/pick';

function hitCallback() {}

class GAOutboundLink extends React.Component {
  static propTypes = {
    eventLabel: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick();
    ReactGA.outboundLink(
      {
        label: this.props.eventLabel
      },
      hitCallback
    );
  }

  render() {
    const props = Object.assign(
      {
        onClick: this.onClick
      },
      pick(this.props, ['id', 'className', 'title', 'href', 'target', 'rel'])
    );

    return <a {...props}>{this.props.children}</a>;
  }
}

GAOutboundLink.defaultProps = {
  onClick: () => {}
};

export default GAOutboundLink;
