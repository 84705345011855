import { useFieldArray, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import browserHistory from 'react-router/lib/browserHistory';
//$FlowFixMe
import { useAsync } from 'react-async';

// $FlowFixMe
import Select from 'react-select';

import { AutomatedWelcomeMessageSenderSelector } from '../../containers/EditCampaign';
import { Campaign } from '../../types/campaign.flow';
import {
  CampaignGoal,
  CampaignType,
  ContentPlatform,
  PromotionType
} from '@sharkpunch/matchmade-common/campaign';
import ActionableNotification from '../../components/common/ActionableNotification';

import MarkdownTextArea from '../../components/common/MarkdownTextArea';

import callApi from '../../helpers/api';

import Spinner from '../common/Spinner';

import './CreateCampaignForm.scss';

import { ContentSubmissionTaskTemplateForm } from '../campaign/ContentSubmissionTaskTemplate';

import { FormattedMessage } from 'react-intl';

export const ImagePreview = ({ url, onError }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div style={{ width: '100px', height: '100px' }}>
      {loading && url && <Spinner mode="inline" size="small" />}
      <img
        src={url}
        alt={`Preview for ${url}`}
        onError={() => {
          setError(true);
          setLoading(false);
          onError();
        }}
        onLoad={() => {
          setError(false);
          setLoading(false);
        }}
        style={{
          visibility: loading || error ? 'hidden' : 'visible',
          maxWidth: '100%',
          maxHeight: '100%'
        }}
      />
    </div>
  );
};

const ImagePreviewGrid = ({ urls, onError }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '0.25rem' }}>
      {urls.map((url, index) => {
        return <ImagePreview key={index} url={url} onError={onError} />;
      })}
    </div>
  );
};

const screenshotsToArray = screenshots => {
  return screenshots
    .split(',')
    .map(s => {
      return s.trim();
    })
    .filter(s => {
      return !!s;
    });
};

export async function fetchCampaigns() {
  const path = '/admin/dashboard/campaigns';
  const res = await callApi(path, {
    method: 'GET'
  });
  return res.data;
}

async function fetchFormData(campaignId) {
  const formData = {};
  const path = `/campaigns/${campaignId}`;
  const res = await callApi(path, {
    method: 'GET'
  });
  formData.campaign = res.data;
  if (formData.campaign.contentSubmissionTaskTemplateId) {
    const r = await callApi(
      `/admin/content-submission-tasks/v1/content-submission-task-template/${res.data.contentSubmissionTaskTemplateId}`
    );
    if (r.data) {
      formData.contentSubmissionTaskTemplate = r.data;
    }
  }
  return formData;
}

function OptionRenderer(option) {
  return (
    <div className="Option">
      <label className="Option__label">{option.label}</label>
    </div>
  );
}

function campaignToOption(campaign: Campaign) {
  return {
    value: campaign.id,
    label: `${campaign.id} - ${campaign.name}`
  };
}

type CampaignListProps = {
  campaigns: Campaign[],
  value: ?number,
  onSelect: Function
};

function CampaignsList({ campaigns, value, isLoading, onSelect }: CampaignListProps) {
  const options = (campaigns || [])
    .filter(campaign => !campaign.archived)
    .filter(
      campaign =>
        (campaign.contentPlatforms.length === 1 &&
          campaign.contentPlatforms[0] === ContentPlatform.YOUTUBE) ||
        campaign.contentPlatforms[0] === ContentPlatform.TWITCH
    )
    .sort((a, b) => (a.created > b.created ? -1 : a.created < b.created ? 1 : 0))
    .slice(0, 500)
    .map(campaignToOption);

  return (
    <Select
      clearable={false}
      disabled={false}
      isLoading={isLoading}
      onChange={onSelect}
      placeholder="Select campaign you would like to use as a template"
      options={options}
      optionRenderer={OptionRenderer}
      multi={false}
      value={options.find(option => option.value === value)}
    />
  );
}

type CloneCampaignProps = {
  campaigns: Campaign[],
  selectedCampaignId: ?number,
  isCampaignsLoading: boolean,
  isCampaignLoading: boolean,
  onSelect: Function,
  onCloneCampaign: Function
};

function CloneCampaign({
  campaigns,
  selectedCampaignId,
  isCampaignsLoading,
  isCampaignLoading,
  onSelect,
  onCloneCampaign
}: CloneCampaignProps) {
  return (
    <div className="field has-addons">
      <div className="control is-expanded">
        <div className="select is-fullwidth">
          <CampaignsList
            campaigns={campaigns}
            value={selectedCampaignId}
            isLoading={isCampaignsLoading}
            onSelect={onSelect}
          />
        </div>
      </div>
      <div className="control">
        <button
          className={`button is-primary ${isCampaignLoading && 'is-loading'}`}
          disabled={!selectedCampaignId || isCampaignLoading}
          onClick={onCloneCampaign}>
          Select
        </button>
      </div>
    </div>
  );
}

const CreateCampaignForm = () => {
  const [message, setMessage] = useState({});
  const [campaignListLoadingFailed, setCampaignListLoadingFailed] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [useContentSubmissionTaskTemplate, setUseContentSubmissionTaskTemplate] = useState(true);

  const {
    register,
    getValues,
    setValue,
    setError,
    watch,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: null,
      budget: null,
      contentPlatforms: [ContentPlatform.YOUTUBE],
      defaultContentPlatform: ContentPlatform.YOUTUBE,
      promotionTypes: [PromotionType.INTEGRATION],
      defaultPromotionType: PromotionType.INTEGRATION,
      isAutopilot: true,
      videoReview: true,
      goal: CampaignGoal.INSTALLS,
      campaignType: CampaignType.APP,
      automatedWelcomeMessageSenderId: null,
      screenshots: '',
      logoUrl: null,
      publisherName: null,
      teamId: null,
      productTitle: null,
      attributionPartner: 'none',
      contentSubmissionTaskTemplate: {
        cover_image_url: null,
        user_inputs: [{ description: '', is_required: false, user_input_type: 'boolean' }]
      }
    },
    mode: 'onBlur'
  });
  const watchedPromotionTypes = watch('promotionTypes');
  const watchedContentPlatforms = watch('contentPlatforms');
  const watchedAutomatedWelcomeMessageSenderId = watch('automatedWelcomeMessageSenderId');
  const watchedLogoUrl = watch('logoUrl');
  const watchedScreenshots = watch('screenshots');

  const fieldArray = useFieldArray({ control, name: 'contentSubmissionTaskTemplate.user_inputs' });

  const {
    data: campaigns = [],
    isLoading: isCampaignsLoading = true
  }: {
    data: Campaign[]
  } = useAsync({
    promiseFn: fetchCampaigns,
    onReject: () => setCampaignListLoadingFailed(false)
  });

  const { run: loadCampaign, isLoading: isCampaignLoading } = useAsync({
    deferFn: campaignId => fetchFormData(campaignId),
    onReject: e =>
      setMessage({
        type: 'danger',
        text: 'Can not load selected campaign'
      }),
    onResolve: setFormValues
  });

  const { run: createCampaign, isLoading: isSavingCampaign } = useAsync({
    deferFn: async () => {
      setMessage({});
      const data = getValues();
      data.screenshots = screenshotsToArray(data.screenshots);
      data.contentPlatforms = [data.defaultContentPlatform]; // Make only one platform campaign for now
      if (data.budget) {
        // budget should be saved in cents
        data.budget = data.budget * 100;
      }
      // seperate content submission task template from campaign values and add default content_submission
      const taskTemplate = {
        ...data.contentSubmissionTaskTemplate,
        content_submission: {
          content_platform: 'youtube',
          content_format: 'url'
        }
      };
      delete data.contentSubmissionTaskTemplate;

      if (useContentSubmissionTaskTemplate) {
        try {
          const createdTask = await callApi(
            '/admin/content-submission-tasks/v1/content-submission-task-template/',
            {
              method: 'POST',
              body: taskTemplate
            }
          );

          // link the created content submission task to the new campaign
          if (createdTask.data.id) {
            data.contentSubmissionTaskTemplateId = createdTask.data.id;
          }

          return callApi('/admin/campaigns', {
            method: 'POST',
            body: data
          });
        } catch (e) {
          setMessage({
            type: 'danger',
            text: `Error when saving content submission task template: ${
              e.message || 'Unknown error'
            }`
          });
        }
      } else {
        return callApi('/admin/campaigns', {
          method: 'POST',
          body: data
        });
      }
    },
    onResolve: response => {
      browserHistory.push(`/admin/campaigns/${response.data.id}`);
    },
    onReject: e =>
      setMessage({
        type: 'danger',
        text: `Error when saving campagin: ${e.message || 'Unknown error'}`
      })
  });

  const onCloneCampaign = () => loadCampaign(selectedCampaignId);

  function setFormValues(formData) {
    const { campaign, contentSubmissionTaskTemplate } = formData;
    if (!campaign) {
      return;
    }

    setValue('name', campaign.name, { shouldValidate: true });
    setValue('teamId', campaign.team.id, { shouldValidate: true });
    setValue('budget', (campaign.budget || 0) / 100, { shouldValidate: true });
    setValue('goal', campaign.goal, { shouldValidate: true });
    setValue('defaultContentPlatform', campaign.defaultContentPlatform, { shouldValidate: true });
    setValue('promotionTypes', campaign.promotionTypes, { shouldValidate: true });
    setValue('defaultPromotionType', campaign.defaultPromotionType, { shouldValidate: true });
    setValue('description', campaign.description, { shouldValidate: true });
    setValue('talkingPoints', campaign.talkingPoints, { shouldValidate: true });

    setValue('automatedWelcomeMessage', campaign.automatedWelcomeMessage, { shouldValidate: true });
    setValue('automatedWelcomeMessageFollowUp', campaign.automatedWelcomeMessageFollowUp, {
      shouldValidate: true
    });
    setValue('automatedWelcomeMessageSenderId', campaign.automatedWelcomeMessageSenderId, {
      shouldValidate: true
    });
    setValue('automatedApprovalMessage', campaign.automatedApprovalMessage, {
      shouldValidate: true
    });

    if (campaign.game) {
      setValue('productTitle', campaign.game.title, { shouldValidate: true });
      setValue('publisherName', campaign.game.publisherName, { shouldValidate: true });
      setValue('gameDescription', campaign.game.description, { shouldValidate: true });
      setValue('logoUrl', campaign.game.logoUrl, { shouldValidate: true });
      setValue('screenshots', (campaign.game.screenshots || []).join(','), {
        shouldValidate: true
      });

      setValue('playStoreUrl', campaign.game.storeUrls['android'], { shouldValidate: true });
      setValue('appStoreUrl', campaign.game.storeUrls['ios'], { shouldValidate: true });
      setValue('steamStoreUrl', campaign.game.storeUrls['steam'], { shouldValidate: true });
      setValue('webUrl', campaign.game.storeUrls['homepage'], { shouldValidate: true });
    }

    // @peltonpa 2022-04-14 as of today, content submission tasks are not mandatory for campaigns.
    // Before a month ago they weren't part of any campaign.
    // Therefore do nothing if it wasn't part of a campaign to be cloned (which might not have
    // a content submission task template associated with it).
    if (contentSubmissionTaskTemplate) {
      setValue(
        'contentSubmissionTaskTemplate.cover_image_url',
        contentSubmissionTaskTemplate.cover_image_url
      );
      setValue('contentSubmissionTaskTemplate.briefing', contentSubmissionTaskTemplate.briefing);

      // Remove the empty input field (that we render by default)
      // before appending the user inputs
      fieldArray.remove(0);
      for (const input of contentSubmissionTaskTemplate.user_inputs) {
        fieldArray.append({
          description: input.description,
          is_required: input.is_required,
          user_input_type: input.user_input_type
        });
      }
    }
  }

  return (
    <form className="CreateCampaignForm" onSubmit={handleSubmit(createCampaign)}>
      <div className="section mb-5 CreateCampaignForm__CloneCampaign">
        <h2 className="subtitle">Use previous campaign as a template</h2>
        <div className="columns">
          <div className="column">
            <CloneCampaign
              campaigns={campaigns}
              selectedCampaignId={selectedCampaignId}
              isCampaignsLoading={isCampaignsLoading}
              isCampaignLoading={isCampaignLoading}
              onSelect={option => setSelectedCampaignId(option ? option.value : null)}
              onCloneCampaign={onCloneCampaign}
            />
            {campaignListLoadingFailed && (
              <p className="help is-danger">
                Sorry campaigns can not be loaded, please try to refresh the page
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="section mb-5">
        <h2 className="subtitle">Campaign</h2>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="name" className="label">
                Campaign name (required)
              </label>
              <div className="control">
                <input
                  {...register('name', { required: 'Campaign name is required' })}
                  className="input"
                  name="name"
                  type="text"
                />
              </div>
              {errors.name && <p className="help is-danger">{errors.name.message}</p>}
              <p className="help">Creators can see the name of the campaign</p>
            </div>

            <div className="columns">
              <div className="column is-one-quarter">
                <fieldset className="field" disabled={isCampaignLoading}>
                  <legend className="label">Platform</legend>
                  <div className="control">
                    <label className="radio">
                      <input
                        {...register('defaultContentPlatform')}
                        type="radio"
                        name="defaultContentPlatform"
                        value="youtube"
                      />{' '}
                      Youtube
                    </label>
                  </div>
                  <div className="control">
                    <label className="radio">
                      <input
                        {...register('defaultContentPlatform')}
                        type="radio"
                        name="defaultContentPlatform"
                        value="twitch"
                      />{' '}
                      Twitch
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="field">
              <label htmlFor="teamId" className="label">
                Team id (required)
              </label>
              <div className="control">
                <input
                  {...register('teamId', { required: 'Team id is required' })}
                  className="input"
                  type="number"
                />
              </div>
              {errors.teamId && <p className="help is-danger">{errors.teamId.message}</p>}
            </div>

            <div className="field">
              <label htmlFor="budget" className="label">
                Budget
              </label>
              <div className="control">
                <input
                  {...register('budget', {
                    min: { value: 0, message: 'Budget can not be less than 0' }
                  })}
                  className="input"
                  type="number"
                />
              </div>
              {errors.budget && <p className="help is-danger">{errors.budget.message}</p>}
              <p className="help">Budget of the campaign in USD. Not the same as budget lock!</p>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-quarter">
            <fieldset className="field" disabled={isCampaignLoading}>
              <legend className="label">Campaign goal</legend>
              {Object.entries(CampaignGoal).map(([key, value]) => (
                <div className="control" key={key}>
                  <label className="radio">
                    <input {...register('goal')} type="radio" name="goal" value={value} /> {value}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>

          <div className="column is-one-quarter">
            {watchedContentPlatforms.length > 1 && (
              <fieldset className="field" disabled={isCampaignLoading}>
                <legend className="label">Default content platform</legend>
                {Object.entries(ContentPlatform).map(([key, value]) => (
                  <div className="control" key={key}>
                    <label className="radio">
                      <input
                        {...register('defaultContentPlatform')}
                        type="radio"
                        name="defaultContentPlatform"
                        value={value}
                      />{' '}
                      {value}
                    </label>
                  </div>
                ))}
              </fieldset>
            )}
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-quarter">
            <fieldset className="field" disabled={isCampaignLoading}>
              <legend className="label">Promotion type</legend>
              {Object.entries(PromotionType).map(([key, value]) => (
                <div className="control" key={key}>
                  <label className="checkbox">
                    <input
                      {...register('promotionTypes')}
                      type="checkbox"
                      name="promotionTypes"
                      value={value}
                    />{' '}
                    {value}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
          <div className="column is-one-quarter">
            {watchedPromotionTypes.length > 1 && (
              <fieldset className="field">
                <legend className="label">Default promotion type</legend>
                {Object.entries(PromotionType).map(([key, value]) => (
                  <div className="control" key={key}>
                    <label className="radio">
                      <input
                        {...register('defaultPromotionType')}
                        type="radio"
                        name="defaultPromotionType"
                        value={value}
                      />{' '}
                      {value}
                    </label>
                  </div>
                ))}
              </fieldset>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">Description, requirements and restrictions</label>
          <div className="control">
            <MarkdownTextArea
              textareaComponent={<textarea {...register('description')} className="textarea" />}
              getContent={() => {
                return getValues('description');
              }}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Talking points</label>
          <div className="control">
            <MarkdownTextArea
              textareaComponent={<textarea {...register('talkingPoints')} className="textarea" />}
              getContent={() => {
                return getValues('talkingPoints');
              }}
            />
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Product</h2>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="productTitle" className="label">
                Product title (required)
              </label>
              <div className="control">
                <input
                  {...register('productTitle', { required: 'Product title is required' })}
                  className="input"
                  type="text"
                />
              </div>
              {errors.productTitle && (
                <p className="help is-danger">{errors.productTitle.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="publisherName" className="label">
                Publisher name (required)
              </label>
              <div className="control">
                <input
                  {...register('publisherName', { required: 'Publisher name is required' })}
                  className="input"
                  type="text"
                />
              </div>
              {errors.publisherName && (
                <p className="help is-danger">{errors.publisherName.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Product description</label>
          <div className="control">
            <MarkdownTextArea
              textareaComponent={<textarea {...register('gameDescription')} className="textarea" />}
              getContent={() => {
                return getValues('gameDescription');
              }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label htmlFor="appStoreUrl" className="label">
                App Store URL
              </label>
              <div className="control">
                <input
                  {...register('appStoreUrl')}
                  className="input"
                  type="text"
                  placeholder="http://apps.apple.com/<country>/app/<app–name>/id<store-ID>"
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label htmlFor="playStoreUrl" className="label">
                Play Store URL
              </label>
              <div className="control">
                <input
                  {...register('playStoreUrl')}
                  className="input"
                  type="text"
                  placeholder="https://play.google.com/store/apps/details?id=<package_name>"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label htmlFor="steamStoreUrl" className="label">
                Steam Store URL
              </label>
              <div className="control">
                <input
                  {...register('steamStoreUrl')}
                  className="input"
                  type="text"
                  placeholder="https://store.steampowered.com/app/<store-ID>"
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="webUrl" className="label">
                Web URL
              </label>
              <div className="control">
                <input {...register('webUrl')} className="input" type="text" />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label htmlFor="logoUrl" className="label">
                Logo URL (required)
              </label>
              <div className="control">
                <input
                  {...register('logoUrl', { required: 'Logo url is required' })}
                  className="input"
                  type="text"
                />
              </div>
            </div>
            {errors.logoUrl && <p className="help is-danger">{errors.logoUrl.message}</p>}
            <div>
              <ImagePreview
                url={watchedLogoUrl}
                onError={() => {
                  setError('logoUrl', { type: 'string', message: 'Invalid image link' });
                }}
              />
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label htmlFor="screenshots" className="label">
                Product visuals and/or screenshot URLS (comma seperated)
              </label>
              <div className="control">
                <textarea {...register('screenshots')} className="textarea" />
              </div>
              <p className="help">
                These images will be shown to the creator to give a better idea about what the
                product is. We do not ask the creators to use these images in their content.
              </p>
              {errors.screenshots && <p className="help is-danger">{errors.screenshots.message}</p>}
              <div>
                <ImagePreviewGrid
                  urls={screenshotsToArray(watchedScreenshots)}
                  onError={() => {
                    setError('screenshots', {
                      type: 'string',
                      message: 'Some of the links are not valid images'
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Creator content submission</h2>
        <div className="control">
          <label className="checkbox">
            <input
              checked={useContentSubmissionTaskTemplate}
              type="checkbox"
              onChange={e => setUseContentSubmissionTaskTemplate(e.target.checked)}
            />{' '}
            Use a content submission task template for this campaign (creators will be directed to
            the content submission app to submit their content)
          </label>
        </div>
        {useContentSubmissionTaskTemplate && (
          <ContentSubmissionTaskTemplateForm
            register={register}
            errors={errors}
            setError={setError}
            fieldArray={fieldArray}
          />
        )}
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Miscellaneous</h2>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Automated welcome message</label>
              <div className="control">
                <MarkdownTextArea
                  textareaComponent={
                    <textarea {...register('automatedWelcomeMessage')} className="textarea" />
                  }
                  getContent={() => {
                    return getValues('automatedWelcomeMessage');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Automated welcome message follow up</label>
              <div className="control">
                <MarkdownTextArea
                  textareaComponent={
                    <textarea
                      {...register('automatedWelcomeMessageFollowUp')}
                      className="textarea"
                    />
                  }
                  getContent={() => {
                    return getValues('automatedWelcomeMessageFollowUp');
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Automated welcome message sender</label>
              <div className="control has-icons-left">
                <AutomatedWelcomeMessageSenderSelector
                  onChangeSelection={e => setValue('automatedWelcomeMessageSenderId', e.value)}
                  selected={watchedAutomatedWelcomeMessageSenderId}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Automated approval message</label>
              <div className="control">
                <MarkdownTextArea
                  textareaComponent={
                    <textarea {...register('automatedApprovalMessage')} className="textarea" />
                  }
                  getContent={() => {
                    return getValues('automatedApprovalMessage');
                  }}
                />
                <label className="help">
                  <FormattedMessage id="campaignCreation.adminOnly.automatedApprovalMessage.notice" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="field mt-5">
        <div className="control">
          <button
            className={`button is-primary is-medium ${isSavingCampaign && 'is-loading'}`}
            type="submit">
            Save
          </button>
        </div>
      </div>
      {message && (
        <div className="field">
          <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
        </div>
      )}
    </form>
  );
};

export default CreateCampaignForm;
