// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// $FlowFixMe
import { StaticDatePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { apiDateFormat, userDateFormat } from '../../helpers/formats';
import Button, { Color } from '../common/Button';
import CloseIcon from '../common/Icons/CloseIcon';
import Modal from '../common/Modal';
import moment from 'moment';

import './CampaignDescriptionModal.scss';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: (chosenPublishingDate: string) => void,
  publishingWindow: {
    start: string,
    end: string
  }
};

const PublishingWindowModal = ({ isOpen, onConfirm, onClose, publishingWindow }: Props) => {
  const startDate = moment.utc(publishingWindow && publishingWindow.start);
  const endDate = moment.utc(publishingWindow && publishingWindow.end);
  const [chosenDate, setChosenDate] = React.useState(endDate);

  if (!startDate.isValid() || !endDate.isValid()) return null;

  return (
    <Modal
      className="CampaignDescriptionModal"
      isBoxed={false}
      onClose={onClose}
      isOpen={isOpen}
      isClosable={false}>
      <header className="modal-card-head">
        <h3>
          <FormattedMessage id="influencer.campaign.publishingWindowModal.title" tagName="strong" />
        </h3>
      </header>
      <section className="modal-card-body">
        <div className="columns">
          <div className="column is-half-desktop">
            <StaticDatePicker
              minDate={startDate}
              maxDate={endDate}
              displayStaticWrapperAs="desktop"
              value={chosenDate}
              onChange={setChosenDate}
              renderInput={params => <TextField {...params} />}
            />
          </div>
          <div className="column is-half-desktop">
            <div className="p-4">
              <p>
                Please select a publishing date for your sponsored video on the calendar. The
                available dates for this campaign are between {startDate.format(userDateFormat)} and{' '}
                {endDate.format(userDateFormat)}.
              </p>
              <p>
                Kindly remember that you must submit the draft at least seven (7) days before the
                chosen publishing date.
              </p>
            </div>
            <div className="has-background-light p-4">
              <p>
                <strong>Chosen publishing date: {chosenDate.format(userDateFormat)}</strong>
              </p>
              <p>
                Submit draft by:{' '}
                {
                  // create new date from `chosenDate` so that orignal `chosenDate` isn't changed
                  // $FlowFixMe
                  moment(new Date(chosenDate)).subtract(7, 'days').format(userDateFormat)
                }
              </p>
            </div>
            <div className="p-4">
              <p>Failing to comply with the deadline might result in deal cancellation.</p>
            </div>
          </div>
        </div>
      </section>
      <footer className="modal-card-foot">
        <Button
          color={Color.PRIMARY}
          onClick={() => {
            onConfirm(chosenDate.format(apiDateFormat));
          }}
          className="CampaignDescriptionModal__action_button">
          <FormattedMessage
            id="influencer.campaign.publishingWindowModal.confirm"
            tagName="strong"
          />
        </Button>
        <Button
          color={Color.PRIMARY}
          size="large"
          onClick={onClose}
          className="CampaignDescriptionModal__close_button">
          <CloseIcon />
        </Button>
      </footer>
    </Modal>
  );
};

export default PublishingWindowModal;
