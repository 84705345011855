// @flow

import getConfig from '../config';

// $FlowFixMe
import mixpanel from 'mixpanel-browser';

export type MixpanelClient = {|
  sendEvent: (name: string, payload?: { [key: string]: any }) => void
|};

export type ExtraProps = {
  mixpanel: MixpanelClient
};

// TODO is there a way to not share the same mixpanel client?
mixpanel.init(getConfig('mixpanelToken'));

export const mixpanelClient = mixpanel;

function getInitializedClient(accountId) {
  if (accountId) {
    mixpanel.identify(accountId);
  }

  return mixpanel;
}

export default function setupMixpanelClient(accountId: number | null): MixpanelClient {
  const mixpanelClient = getInitializedClient(accountId || null);

  return {
    sendEvent(name, payload) {
      return mixpanelClient.track(name, payload);
    }
  };
}
