import React from 'react';

const FaceWorried = props => (
  <svg width="1em" height="1em" viewBox="0 0 86 77" {...props}>
    <g fill="#FFF" fillRule="nonzero">
      <path d="M52.874 42H64v10.76C63.587 66.216 52.553 77 39 77S14.413 66.216 14.011 52.76L14 42h12.126a4 4 0 1 0 7.748 0h11.252a4 4 0 1 0 7.748 0zM39.15 59c4.05 0 7.421 1.108 9.013 2.41l1.998-3.451C47.575 56.093 43.545 55 39.111 55c-4.468 0-8.528 1.11-11.111 3.003l2.111 3.497v-.07C31.693 60.12 35.078 59 39.15 59z" />
      <path d="M68 34v-1C68 17.147 54.933 4 39 4S10 17.147 10 33v22H4a4 4 0 0 1-4-4V39a4 4 0 0 1 4-4h2v-2C6 14.865 20.775 0 39 0s33 14.865 33 33v1h14v4h-7.126c.082.32.126.655.126 1v12a4 4 0 0 1-4 4h-2c-.345 0-.68-.044-1-.126V55h-4V38H14v-5C14 19.193 25.193 8 39 8s25 11.193 25 25v1h4z" />
    </g>
  </svg>
);

export default FaceWorried;
