// @flow
import type { Action, Reducer } from '../../types/action.flow';

export default function runMultipleReducers<T: Object>(
  // in an ideal world, instead of using any one can combine all the types of all reducers
  // and produce a final type for the return state :(
  // TODO: figure out how to reach there
  reducers: Reducer<any>[]
): (state: T, action: Action) => T {
  return (state, action) => {
    let tempState = state;
    for (const reduce of reducers) {
      tempState = reduce(tempState, action);
    }

    return tempState;
  };
}
