// @flow
import * as React from 'react';
import TermsNavigation from './TermsNavigation';

import './TermsContent.scss';

const navItems = [
  { id: 'definitions', value: 'Definitions' },
  { id: 'use', value: 'Use of Matchmade Platform' },
  { id: 'fees', value: 'Fees and Payment' },
  { id: 'availability', value: 'Availability' },
  { id: 'restrictions', value: 'Restrictions of use' },
  { id: 'modifications', value: 'Modifications to the Matchmade Platform' },
  { id: 'obligations', value: 'General Obligations of Advertiser' },
  { id: 'specificTerms', value: 'Specific terms relating to Campaigns and Content' },
  { id: 'defaults', value: 'Defaults' },
  { id: 'tracking', value: 'Tracking and deal types' },
  { id: 'intellectualProperty', value: 'Intellectual Property Rights' },
  { id: 'license', value: 'License to use the Content in Advertiser’s social media channels' },
  { id: 'matchmadeRight', value: 'Matchmade’s right to set restrictions' },
  { id: 'indemnification', value: 'Indemnification' },
  { id: 'confidentiality', value: 'Confidentiality' },
  { id: 'interfaces', value: 'Interfaces and integration tools' },
  { id: 'liability', value: 'Limitation of liability' },
  { id: 'term', value: 'Term and Termination' },
  { id: 'governingLaw', value: 'Governing Law and Dispute Resolution' },
  { id: 'forceMajeure', value: 'Force Majeure' },
  { id: 'subcontractors', value: 'Subcontractors' },
  { id: 'notices', value: 'Notices' },
  { id: 'agreement', value: 'Entire Agreement' },
  { id: 'headings', value: 'Headings' },
  { id: 'reference', value: 'Reference Use' },
  { id: 'severability', value: 'Severability' },
  { id: 'assignment', value: 'Assignment' }
];

const PublisherTermsContent = () => {
  return (
    <div className="columns TermsContent">
      <div className="column TermsContent__navigation-column">
        <TermsNavigation items={navItems} />
      </div>
      <div className={`column TermsContent__content-column`}>
        <div className="TermsContent__content content">
          <h1>Matchmade terms of service for advertisers</h1>
          <p>
            These Terms of Service, together with any Orders when applicable, govern the provision
            of Campaigns by Influencers to Advertisers and to any use of the Matchmade Platform or
            any other services provided by Matchmade under the Agreement.
          </p>
          <p>
            By accepting these Terms of Service as part of the sign-up procedure or login to the
            Matchmade Platform (e.g. by clicking “I agree” or a similar box on Matchmade Platform),
            or by accessing or using the Matchmade Platform in any manner, a binding contract is
            formed between Matchmade and the Advertiser. When agreeing to these Terms of Service,
            you hereby warrant to have the required authority to conclude the Agreement on behalf of
            the Advertiser you are representing.
          </p>
          <h2 id="definitions">Definitions</h2>
          <p>
            <strong>“Advertiser”</strong> shall mean the company, or agency representing such
            company, having signed the Order and/or accepted these terms as a part of the order
            process described herein, and looking to promote products or services by working with
            Influencers. The identity and details of Advertiser are specified in the Order or on the
            Matchmade Platform.
          </p>
          <p>
            <strong>“Agreement”</strong> shall mean these Matchmade Terms of Service between
            Matchmade and Advertiser and any Orders and any appendices attached thereto.
          </p>
          <p>
            <strong>“Campaign”</strong> shall mean any marketing or other public Campaign which
            Advertiser purchases by using the Matchmade Platform.
          </p>
          <p>
            <strong>“Content”</strong> shall mean photos, videos, audiovisual material and any other
            form or type of deliverable, material or content uploaded by Influencer to the relevant
            and agreed channel(s) under this Agreement, or otherwise provided to Advertiser by
            Matchmade or Influencer in relation to the Campaign under this Agreement.
          </p>
          <p>
            <strong>“Default”</strong> shall mean any material failure in the Content to conform
            substantially to the specifications set out in the Agreement or otherwise agreed between
            the Parties in writing.
          </p>
          <p>
            <strong>“Influencer”</strong> shall mean an influencer, e.g. a content creator, who is
            advertising the Advertiser’s product(s), service(s) or brand(s).
          </p>
          <p>
            <strong>“Intellectual Property Rights”</strong> shall mean copyrights and related rights
            (including database and catalogue rights and photography rights), patents, utility
            models, design rights, trademarks, tradenames and any other form of registered or
            unregistered intellectual property rights or statutory protection of any kind and
            applications for any of the foregoing.
          </p>
          <p>
            <strong>“Matchmade”</strong> shall mean Shark Punch Oy (business ID: 2618428-2), address
            Urho Kekkosen katu 5&nbsp;B, 00100 Helsinki, Finland.
          </p>
          <p>
            <strong>“Matchmade Platform”</strong> means Matchmade’s online service for connecting
            Advertisers with Influencers, including the online platform on which the Advertiser and
            the Influencer can conclude and administer agreements regarding Campaigns.
          </p>
          <p>
            <strong>“Order(s)”</strong> shall mean the Order Form when applicable, or any other
            written or electronic order or agreement executed between the Parties for the purchase
            of and/or access to the Services.
          </p>
          <p>
            <strong>“Party”</strong> shall mean Advertiser or Matchmade (jointly the{' '}
            <strong>“Parties”</strong>).
          </p>
          <p>
            <strong>“Service Fee(s)”</strong> shall mean the fees payable by Advertiser to Matchmade
            for the services under this Agreement, as defined on the Matchmade Platform.
          </p>
          <p>
            <strong>“User(s)”</strong> shall mean any authorized individual that has been granted
            the right to use and access the Matchmade Platform on behalf of Advertiser hereunder.
          </p>
          <h2 id="use">Use of Matchmade Platform</h2>
          <p>
            Subject to due payment of the applicable Service Fees, as well as compliance with the
            terms of the Agreement, Matchmade grants to Advertiser and any Advertiser’s User a
            limited, non-exclusive, non-transferable, and non-sublicensable right to use the
            Matchmade Platform during the term of this Agreement.
          </p>
          <p>
            When registering to the Matchmade Platform, Advertiser shall provide true, accurate and
            complete information as prompted by the registration process and update such information
            when required.
          </p>
          <p>
            Unauthorized use is strictly prohibited and Advertiser shall be responsible for any
            unauthorized use of the Matchmade Platform conducted under the user names and/or
            passwords of Advertiser or any Advertiser’s User.
          </p>
          <p>
            Advertiser shall use all reasonable endeavors to prevent unauthorized access to, or use
            of, the Matchmade Platform. All passwords and other identifiers relating to the User’s
            use of the Matchmade Platform shall be kept confidential. If Advertiser or a User has
            reason to suspect any unauthorized access or use of the Matchmade Platform, or if any
            password has been revealed to a third party, Advertiser shall promptly notify Matchmade
            thereof.
          </p>
          <h2 id="fees">Fees and Payment</h2>
          <p>
            The Service Fee(s) applicable to the use of the Services are set out on the Matchmade
            Platform.
          </p>
          <p>
            Unless otherwise indicated, all prices are exclusive of VAT and any other applicable
            taxes or fees/payment charges imposed by public authorities or financial institutions
            from time to time. Such taxes and fees shall be added to the prices and be borne by
            Advertiser.
          </p>
          <p>
            The Services purchased by Advertiser are set out in the Order or on the Matchmade
            Platform or otherwise in writing in connection with the order process, wherein Parties
            agree on a Campaign target budget. Payment of the Service Fees shall be made in
            accordance with the pricing set out on the Matchmade Platform or as agreed in writing.
            Unless otherwise agreed in writing between the Parties, payment of Service Fees shall be
            made against invoices issued by Matchmade to Advertiser with a payment term of 30 days
            net from the date of invoice. Matchmade shall invoice Advertiser in two instalments.
            Matchmade shall invoice a deposit of 75% of Campaign target budget after Order has been
            signed or otherwise agreed upon split into no longer than 30 day periods. Once the
            agreed Campaign, or portion split into no longer than 30 day periods, has been
            completed, Matchmade shall invoice the Advertiser based on the realised Service Fee(s)
            deducted with the previously invoiced deposit.
          </p>
          <p>
            Any notices by Advertiser relating to invoices or payments hereunder shall be given in
            writing to Matchmade within seven (7) days from the date of receipt of the relevant
            invoice or payment.
          </p>
          <p>
            Interest on overdue payments shall accrue in accordance with the Finnish Interest Act
            (633/1982).
          </p>
          <p>
            Without prejudice to its other rights, Matchmade may temporarily disable the
            Advertiser’s access to the Services or terminate the Agreement with immediate effect in
            the event Advertiser has overdue payments in excess of thirty (30) days.
          </p>
          <p>
            All Service Fees and payments made to Matchmade under this Agreement are non-refundable.
            For clarity, in the event of any termination or expiry of the Agreement, Advertiser
            shall be liable for all Service Fee(s) realised and committed to before termination and
            shall not be entitled to a refund of any prepaid fees.
          </p>
          <h2 id="availability">Availability</h2>
          <p>
            Matchmade will make reasonable efforts to keep the Services operational. Matchmade shall
            have the right to suspend the availability of the Services due to installation, change
            or maintenance work or due to severe data security risk to the Services or if required
            by law or public authorities. If Matchmade suspends the Services for this reason, it
            shall inform Advertiser of the suspension and the duration of the estimated suspension
            in advance or, if this is not reasonably possible, without undue delay after Matchmade
            has become aware of such occurrence.
          </p>
          <p>
            Matchmade shall have the right to deny Advertiser's or a User’s access to the Services
            without any prior notice to Advertiser, if Matchmade suspects that Advertiser breaches
            the Agreement or burdens or uses the Services in a manner which may jeopardize the
            availability of the Services to other users. Matchmade shall without undue delay inform
            Advertiser of the reasons for such denial.
          </p>
          <p>
            Advertiser acknowledges that interruptions to the availability of the Services may also
            occur due to no fault of Matchmade, for example, in the event of data connection
            disruptions or interruptions to the availability of systems or components delivered by
            third parties.
          </p>
          <p>
            Except as specifically provided under this Agreement, the Services are provided "as is"
            and with the functionalities available at each time without warranty of any kind, either
            express or implied, including but not limited to the warranties of merchantability and
            fitness for a particular purpose.
          </p>
          <h2 id="restrictions">Restrictions of Use</h2>
          <p>
            Advertiser may use the Matchmade Platform only and strictly in accordance with the terms
            of this Agreement.
          </p>
          <p>Unless otherwise permitted in this Agreement, Advertiser may not:</p>
          <ul>
            <li>
              circumvent or attempt to circumvent any usage control or anti-copy features of the
              Matchmade Platform;
            </li>
            <li>probe, scan or test the vulnerability of the Matchmade Platform;</li>
            <li>
              use the Matchmade Platform or the Content in any manner that could damage, disable,
              overburden or impair the Services;
            </li>
            <li>
              use any data mining, robots, scraping, or similar data gathering or extraction
              methods;
            </li>
            <li>
              use, copy, sell, resell, rent, transfer, license or otherwise provide anybody with the
              Services or the Content available through the Services, except as provided herein;
            </li>
            <li>
              reverse engineer or decompile the Services or access the source code thereof, except
              as permitted by law;
            </li>
            <li>
              use the Services for transmitting any unauthorized advertising, promotional materials,
              junk mail, spam, chain letters, contests, pyramid schemes, or any other form of
              solicitation or mass messaging;
            </li>
            <li>use the Services in violation of applicable law;</li>
            <li>
              use the Services in ways that violate Intellectual Property Rights or privacy of third
              parties or users; and/or
            </li>
            <li>
              use the Services to transmit any material that contains adware, malware, spyware,
              software viruses, worms or any other computer code designed to interrupt, destroy, or
              limit the functionality of computer software or equipment.
            </li>
          </ul>
          <h2 id="modifications">Modifications to the Matchmade Platform</h2>
          <p>
            Matchmade may make modifications or changes to the Matchmade Platform at any time at its
            sole discretion and without notifying the Advertiser thereof, provided that such changes
            do not materially affect the Services. If Matchmade introduces changes materially
            affecting the Services, Matchmade will notify Advertiser thereof in advance and if
            Advertiser does not accept such material change made by Matchmade, Advertiser has the
            right to terminate the Agreement by notifying Matchmade thereof prior to the effective
            date of such change.
          </p>
          <h2 id="obligations">General Obligations of Advertiser</h2>
          <p>
            Advertiser is responsible for acquiring and maintaining any and all network connections,
            integrations, hardware and software required for using the Services and is liable for
            maintaining any firewall and any data security and virus protection systems (including
            any costs thereof) and for ensuring that the Services fulfil Advertiser's intended
            purpose of use.
          </p>
          <p>
            Advertiser is responsible for setting up, testing and maintaining any and all
            integrations needed for the provision of Services.
          </p>
          <p>
            The individual accepting this Agreement represents and warrants that such individual has
            the full legal authority to enter into this Agreement, under all applicable laws, on
            behalf of the Advertiser in its capacity as an employee or representative of Advertiser.
          </p>
          <p>
            Advertiser shall ensure that all information and data, such as but not limited to
            attribution data, necessary for the provision of the Services, where applicable, are
            correct and undertakes to update such information as soon as possible in case such
            information has changed. Such information required for the provision of the Services
            shall be provided by Advertiser to Matchmade in a timely manner in order for Matchmade
            to be able to provide the ordered Services during normal office hours and as instructed
            by Matchmade from time to time. Further, Advertiser shall ensure that all details
            provided regarding Advertiser’s contact information and billing information are correct
            and up to date.
          </p>
          <p>
            Advertiser shall be liable for any use of the Services with the user names and/or
            passwords of the Advertiser and the Advertiser’s Users.
          </p>
          <p>
            When Advertiser purchases sponsored Content from a certain Influencer using the
            Matchmade Platform under this Agreement, or otherwise collaborates with certain
            Influencer via the Matchmade Platform, Advertiser commits not to purchase any content or
            agree on any other type or form of collaboration, sponsorship or campaign participation
            directly with such Influencer, or otherwise outside the Matchmade Platform, during a
            period of ninety (90) days after the relevant Content purchased by the Advertiser under
            this Agreement has been published in accordance with the terms of this Agreement. In
            case Advertiser breaches the foregoing exclusivity condition, Advertiser shall pay
            Matchmade EUR 20000 as liquidated damages.
          </p>
          <h2 id="specificTerms">Specific terms relating to Campaigns and Content</h2>
          <p>
            The Services purchased by Advertiser are set out in the Order or on the Matchmade
            Platform or otherwise in writing in connection with the order process.
          </p>
          <p>
            The scope and content of the Campaign and the agreed specifications for the Content to
            be created in connection with the Campaign, as well as possible guidelines, milestones
            and time schedules, are described in the Order, on the Matchmade Platform or otherwise
            in writing (including e-mail) by Parties.
          </p>
          <p>
            Advertiser shall provide guidelines of the Campaign on Matchmade Platform. Such
            guidelines shall outline the specifics and details of the Campaign.
          </p>
          <p>
            Influencers and their Content will comply with Advertiser’s guidelines (as specified in
            the Campaign description in the Matchmade Platform), YouTube and other platform and
            channel terms and conditions, and applicable laws and regulations. In case an Influencer
            or the Content does not comply with the foregoing, Advertiser may, as its sole remedy,
            receive a refund for the Service Fees paid by Advertiser.
          </p>
          <p>
            Matchmade can suggest an Influencer to the Advertiser’s Campaign at its own discretion,
            as long as the Content produced by Influencer matches with guidelines provided by
            Advertiser on the Matchmade Platform or otherwise in writing.
          </p>
          <p>
            Matchmade shall ensure that each Influencer agrees to include in connection with the
            relevant Content created by the Influencer, in the relevant description and in the
            customary place for such information specific to that channel (e.g. in YouTube in the
            description of the Content and in Twitch in the chat): (i) the links via which the
            promoted product can be purchased or downloaded, such as a link to websites and/or
            mobile stores, and (ii) the words referring to paid promotion, partnership, sponsorship
            or advertising or other similar words, as specified by Advertiser.
          </p>
          <p>
            The attribution periods for cost-per-install (CPI) and cost-per-mille (CPM) deal types
            are 180 days for CPI deals and 30 days for CPM deals from the publishing of Content.
          </p>
          <h2 id="defaults">Defaults</h2>
          <p>
            Advertiser shall review the Content delivered in connection with the Campaign for any
            Defaults and other non-conformities within two (2) working days from the delivery of
            such Content. Advertiser shall be liable for Advertiser’s costs of any such review.
          </p>
          <p>
            Advertiser shall within such period of two working days notify Influencer and Matchmade
            in writing of all Defaults detected in the Content and shall specify the Defaults in
            sufficient detail to enable Influencer to identify the Defaults. Upon such notice by
            Advertiser, Influencer shall correct the Defaults, whereupon one (1) additional
            acceptance period commences. The additional acceptance period shall be one (1) working
            day. The Content shall be deemed accepted and delivered (i) when Influencer has
            corrected Defaults that have been notified by Advertiser in accordance with this
            Section; or (ii) if Advertiser has not provided a notice of Defaults within the periods
            set out above.
          </p>
          <p>
            Upon Advertiser approving an Influencer to participate in the Campaign (on the Matchmade
            Platform or in writing) and provided that the Influencer and their Content materially
            comply with Advertiser’s guidelines and other terms mentioned above, Advertiser will be
            billed for the Content published by the Influencer.
          </p>
          <p>
            Matchmade shall not be liable for any Defaults or liabilities occurring as a result of
            Defaults, delays or deficiencies in Advertiser’s materials or other information provided
            by Advertiser to Matchmade or otherwise due the negligence of Advertiser or a third
            party.
          </p>
          <p>
            This Section sets out Matchmade’s entire liability for Defaults or other
            non-conformities in and relating to the Content or the Campaign.
          </p>
          <h2 id="tracking">Tracking and deal types</h2>
          <p>
            If requested by Advertiser, or in case Advertiser has set a certain object for the
            Campaign (such as, but not limited to, an object in Apple Store, Google Play, Steam or
            on a website or platform) the Content will include a Matchmade-provided tracking link in
            the video description or in another place typical for the said platform, in accordance
            with the practices generally in use on such platform, to enable tracking by Advertiser’s
            tracking system, which will be used to determine all qualified actions, e.g. installs,
            purchases or registrations for the Campaign.
          </p>
          <p>
            In case the sponsorship is priced per install and Advertiser reasonably suspects that
            the installs delivered via Influencer’s Content are fraudulent or the result of bots,
            scripts, artificial methods or otherwise unqualified, Advertiser shall notify Matchmade
            within 30 days after any given install, of its good faith belief that the install is
            fraudulent and share the basis for its belief.
          </p>
          <p>
            There are different deal types available for Advertiser to purchase on the Matchmade
            Platform. The specifics of such deal types as well as the pricing thereof and the
            Service Fees connected to each deal type are set out on the Matchmade Platform.
          </p>
          <h2 id="intellectualProperty">Intellectual Property Rights</h2>
          <p>
            All right, title and interest in and to all Intellectual Property Rights in or related
            to the Services and the results thereof as well as in or related to the Matchmade
            Platform and thereto related documentation (including modifications to any of the
            foregoing, if any) and all parts and copies thereof shall remain exclusively vested with
            and be the sole and exclusive property of Matchmade and/or its subcontractors or
            licensors.
          </p>
          <p>
            Except as expressly stated herein, this Agreement does not grant Advertiser or the Users
            any Intellectual Property Rights in the Services and all rights not expressly granted
            hereunder are reserved by Matchmade and its subcontractors or licensors, as the case may
            be.
          </p>
          <p>
            The Intellectual Property Rights and title to Advertiser’s data and materials shall
            belong to Advertiser and shall remain the exclusive property of Advertiser. The
            Advertiser hereby grants to Matchmade and the relevant Influencer the right to use and
            utilize the Advertiser’s material, content and products to the extent necessary in order
            to execute the Campaign, create and utilize the Content and to fulfill the Influencer’s
            obligations in relation to the Campaign.
          </p>
          <p>
            Advertiser may choose to, but is not required to, provide suggestions and feedback and
            other information to Matchmade regarding possible improvements of the Services
            (“Feedback”). All right, title and interest in and to all Intellectual Property Rights
            in or related to the Feedback, and all parts and copies thereof, shall vest in and be
            the sole and exclusive property of Matchmade.
          </p>
          <p>
            Neither Party receives any rights to the Intellectual Property Rights of any third
            party. The Parties acknowledge that any third-party material is made available for
            Advertiser’s use in accordance with the third-party license terms applicable to that
            third-party material and the use of such third-party material by Advertiser may take
            place only in accordance with said applicable third-party license terms.
          </p>
          <h2 id="license">License to use the Content in Advertiser’s social media channels</h2>
          <p>
            Subject to due payment of the relevant Service Fees as well as Advertiser’s compliance
            with this Agreement, Matchmade grants to Advertiser, and shall ensure that the relevant
            Influencer has granted to Matchmade, the right to use and share, by way of publishing
            and making available the link to the Content, for a period of 6 months, beginning on the
            date hereof, the Content in Advertiser’s social media channels. Unless otherwise agreed
            between the Parties in writing, Advertiser shall not receive any other rights, except
            for the restricted right to publish the link in Advertiser’s social media channels, to
            the Content, and title to, and all Intellectual Property Rights (if any) relating to the
            Content shall remain with the relevant Influencer who has created the Content.
          </p>
          <h2 id="matchmadeRight">Matchmade’s right to set restrictions</h2>
          <p>
            Matchmade may set certain restrictions on the foregoing license as well as on
            Advertiser’s use of the Content. Such restrictions shall be communicated to Advertiser
            in writing prior to the delivery of the Content. Advertiser hereby commits to comply
            with all such restrictions.
          </p>
          <h2 id="indemnification">Indemnification</h2>
          <p>
            Advertiser will indemnify, defend, and hold harmless Matchmade from and against all
            liabilities, damages, and costs (including settlement costs and reasonable attorneys'
            fees) arising out of a third-party claim: (i) regarding Advertiser’s materials or
            information; or (ii) regarding Advertiser's use of the Services or Content in violation
            of this Agreement.
          </p>
          <p>
            Matchmade will defend Advertiser against any claim that the Services or the Content
            infringes the Intellectual Property Rights of a third party and pay any damages finally
            settled or awarded in a trial to the third party with respect to any such claim,
            provided that Matchmade is notified promptly in writing of the claim and given sole
            control of the defense and all related settlement negotiations in relation to the claim
            as well reasonable assistance and necessary authorizations from Advertiser to defend or
            settle the claims on behalf of Advertiser.
          </p>
          <p>
            At any time, if Matchmade reasonably deems that any part of the Services or the Content
            infringes the Intellectual Property Rights of any third party, Matchmade has the right
            at its own expense to (i) modify/replace the Services or Content to eliminate the
            infringement in such a manner that the modified Services or Content complies with this
            Agreement; or (ii) procure to Advertiser a right to use the Services or Content. If none
            of the aforementioned alternatives are reasonable possible, Matchmade shall have the
            right to terminate the Agreement.
          </p>
          <p>
            Notwithstanding the foregoing, Matchmade shall not be liable for any infringement or
            claim thereof in the event the claim (i) is made by any affiliates of Advertiser; or
            (ii) has resulted from Advertiser’s or Advertiser’s supplier’s or User’s use or
            modification of or addition to the Services or Content.
          </p>
          <p>
            This Section contains Matchmade’s entire liability and Advertiser’s sole and exclusive
            remedy in case of Intellectual Property Rights infringements.
          </p>
          <h2 id="confidentiality">Confidentiality</h2>
          <p>
            Neither Party shall disclose to third parties any material or information received from
            the other Party and marked as confidential or which should be understood to be
            confidential, and shall not use such material or information for any other purposes than
            those stated in this Agreement.
          </p>
          <p>
            The Party shall return all records or copies of the confidential information of the
            other Party at the request of the other Party and at the latest upon the expiry or
            termination of the Agreement. This shall not apply to confidential information or copies
            thereof which must be stored by the receiving Party according applicable law.
          </p>
          <p>
            The obligation of confidentiality is applied during the term of this Agreement and for
            five (5) years after the termination of this Agreement.
          </p>
          <p>
            The confidentiality obligation shall, however, not be applied to material and
            information, (a) which is generally available or otherwise public; or (b) which the
            Party has lawfully received from a third party without any obligation of
            confidentiality; or (c) which was lawfully in the possession of the receiving Party
            prior to receipt of the same from the other Party without any obligation of
            confidentiality related thereto; or (d) which a Party has independently developed
            without using material or information received from the other Party as verified by the
            written records of the receiving Party; or (e) which a Party is obligated to disclose
            due to applicable mandatory laws, public authority regulations or court orders. In case
            of disclosure due to (e), the Party must promptly inform the other Party of such
            disclosure.
          </p>
          <h2 id="interfaces">Interfaces and integration tools</h2>
          <p>
            For the avoidance of doubt, all interfaces and integration tools, if any, relating to
            the Services are provided on an “as is” basis. Matchmade may provide Advertiser with
            such interfaces and integration tools as developed and implemented by Matchmade from
            time to time. Advertiser acknowledges that some interfaces or integration tools may be
            provided by third parties and/or may have connections or links to third-party service
            providers’ software or systems. Matchmade shall not, under any circumstances, be liable
            for the actions of such third parties or the parts of the interfaces or integration
            tools which are delivered, maintained or owned by third parties.
          </p>
          <h2 id="liability">Limitation of liability</h2>
          <p>
            The Parties shall not be liable for any indirect or consequential damages caused to the
            other Party. The foregoing shall not apply to damages resulting from the breach of the
            Sections “Restrictions of Use”, “Intellectual Property Rights” or “Confidentiality”.
          </p>
          <p>
            Matchmade's total aggregate liability under or in connection with this Agreement shall
            be limited to the aggregate Service Fees paid by the Advertiser for the Services during
            a period of six (6) months preceding the occurrence for which damages are claimed.
          </p>
          <p>
            Nothing contained herein shall be deemed to limit each Party’s liability towards the
            other Party in the event of and to the extent that the damages are caused by willful
            misconduct or gross negligence of the breaching Party.
          </p>
          <p>
            Matchmade has no other obligations or liabilities than those that have expressly been
            agreed upon in this Agreement.
          </p>
          <p>
            The Services or the Content do not constitute a legal opinion or advice of any kind.
            Advertiser is responsible for the use and utilization of the Services and the Content
            and for the assessment of the impact of the Services and the Content on Advertiser’s
            operations.
          </p>
          <h2 id="term">Term and Termination</h2>
          <p>
            This Agreement shall become effective when Advertiser accepts the terms of the Agreement
            in accordance with the Order or as a part of the sign-up process to the Matchmade
            Platform or in any other way, as set out in the Agreement.
          </p>
          <p>
            This Agreement shall continue to be in force until further notice. Each Party may
            terminate this Agreement by a written notice to the other Party. The termination period
            shall be one (1) month.
          </p>
          <p>
            Matchmade may terminate this Agreement with immediate effect by written notice, if (i)
            Advertiser is dissolved or liquidated, is declared bankrupt or otherwise becomes the
            subject to other insolvency proceedings; or (ii) Matchmade ceases its business
            operations or the provision of the Services.
          </p>
          <p>
            Both Parties may terminate this Agreement with immediate effect by giving a written
            notice thereof to the other Party, if the other Party materially breaches its
            obligations under this Agreement and does not remedy the breach within seven (7) days of
            a written notice by the other Party.
          </p>
          <p>
            Advertiser shall continue to have access to the Services and Matchmade is entitled to
            charge the Service Fees and other applicable fees until the end of the notice period.
            Upon expiry or termination of the Agreement, Advertiser shall cease to use the Services.
          </p>
          <p>
            All provisions of this Agreement that are intended to survive the termination or expiry
            of this Agreement shall do so, including without limitation Sections “Intellectual
            Property Rights”, “Confidentiality”, “Limitation of Liability” and “Governing Law and
            Dispute Resolution”.
          </p>
          <h2 id="governingLaw">Governing Law and Dispute Resolution</h2>
          <p>
            This Agreement shall be exclusively governed by and construed in accordance with the
            laws of Finland without regard to its choice of law provisions.
          </p>
          <p>
            Any dispute, controversy or claim arising out of or relating to this Agreement or a
            breach, termination or validity thereof shall be settled primarily by amicable
            negotiations between the Parties.
          </p>
          <p>
            Should the negotiations not lead to a settlement between the Parties within 90 days, any
            dispute, controversy or claim arising out of or relating to this Agreement, or the
            breach, termination or validity thereof, shall be finally settled by arbitration in
            accordance with the Arbitration Rules of the Finland Chamber of Commerce. The number of
            arbitrators shall be one. The seat of arbitration shall be Helsinki, Finland. The
            language of the arbitration shall be English. The arbitral proceedings and award shall
            be confidential.
          </p>
          <p>
            Nothing in this Agreement shall be deemed to limit Matchmade’s rights to seek interim
            injunctive relief or to enforce an arbitration award in any court of law. With respect
            to any violation by Advertiser of any Intellectual Property Rights and/or confidential
            information of Matchmade and/or payment obligations under this Agreement, Matchmade
            shall have the right, at its sole discretion, to seek remedies in public courts within
            any applicable territory.
          </p>
          <h2 id="forceMajeure">Force Majeure</h2>
          <p>
            Neither Party shall be liable for delay and damage caused by an impediment beyond the
            Party’s control and which the Party could not have reasonably taken into account at the
            time of conclusion of this Agreement and the consequences of which the Party could not
            reasonably have avoided or overcome. Such force majeure events shall include, if not
            proven otherwise, inter alia, war or insurrection, earthquake, flood or other similar
            natural catastrophe, interruptions in general traffic, data communication or supply of
            electricity, import or export embargo, strike, lockout, boycott or other similar
            industrial action.
          </p>
          <p>
            Each Party shall without delay inform the other Party in writing of a force majeure
            event and the ceasing of such event.
          </p>
          <p>
            Where a Party’s performance is prevented for a period in excess of one (1) month due to
            an event as stated above, either Party shall be entitled to terminate the Agreement.
          </p>
          <h2 id="subcontractors">Subcontractors</h2>
          <p>
            Matchmade shall be entitled to use subcontractors for the provision of the Services.
            Matchmade shall be liable for the subcontractors’ work and services in the same manner
            as for its own work and services.
          </p>
          <h2 id="notices">Notices</h2>
          <p>
            Any notice or other written communication to be given by the Parties under this
            Agreement shall be sent by email to the email address indicated in the Order or on the
            Matchmade Platform.
          </p>
          <p>
            If either Party is to change their respective contact details, the other Party shall be
            informed thereof in advance.
          </p>
          <h2 id="agreement">Entire Agreement</h2>
          <p>
            This Agreement supersedes all prior agreements, arrangements, and understandings between
            the Parties relating to the subject matter hereof and constitutes the entire agreement
            between the Parties relating to the subject matter hereof.
          </p>
          <h2 id="headings">Headings</h2>
          <p>
            Headings used in this Agreement are for convenience only and do not alter the meaning or
            interpretation of any provision herein, unless otherwise stated.
          </p>
          <h2 id="reference">Reference Use</h2>
          <p>
            Advertiser agrees that Matchmade may use Advertiser’s name and logo to identify
            Advertiser as a customer of Matchmade or as part of a general list of Matchmade’s
            customers for use and reference in Matchmade’s promotional and marketing materials,
            including on Matchmade’s website.
          </p>
          <h2 id="severability">Severability</h2>
          <p>
            If any part of this Agreement is held to be invalid or unenforceable by any court,
            tribunal or other authority having jurisdiction, this shall not affect the validity or
            enforceability of the rest of this Agreement. Instead, this Agreement shall be construed
            and interpreted so that its effect shall remain as close as legally possible to the
            effect it would have had without such invalidity or unenforceability.
          </p>
          <h2 id="assignment">Assignment</h2>
          <p>
            Neither Party may assign this Agreement or any rights or obligations hereunder without
            the prior consent of the other Party. Matchmade may, however, assign all or any of its
            rights or obligations hereunder in whole or part to an affiliate or successor or to a
            purchaser or acquirer of its business assets without Advertiser’s prior consent. This
            Agreement shall be binding upon, and inure to the benefit of, the successors,
            representatives and permitted assigns of the Parties hereto.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublisherTermsContent;
