// @flow
// TODO Split this to two / three / n components!
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';

// $FlowFixMe
import Select from 'react-select';

import { InfluencerSearchTypeEnum } from '../../../types/search.flow';
import type { InfluencerSearchType } from '../../../types/search.flow';
import type { IntlShape } from 'react-intl';

import Input from '../../../components/forms/controls/Input';
import OnOffToggle from '../../../components/common/OnOffToggle';

import { GamesSelector, TagsSelector } from './DynamicSelector';

const CATEGORIES = [
  'Electronic_music',
  'Music_of_Asia',
  'Sports_game',
  'Puzzle_video_game',
  'Physical_attractiveness',
  'Music',
  'Video_game_culture',
  'Music_video_game',
  'Tourism',
  'Military',
  'Boxing',
  'Racing_video_game',
  'Reggae',
  'Rhythm_and_blues',
  'Baseball',
  'American_football',
  'Basketball',
  'Tennis',
  'Independent_music',
  'Hobby',
  'Action-adventure_game',
  'Country_music',
  'Action_game',
  'Cricket',
  'Mixed_martial_arts',
  'Fashion',
  'Casual_game',
  'Strategy_video_game',
  'Volleyball',
  'Classical_music',
  'Sport',
  'Vehicle',
  'Knowledge',
  'Politics',
  'Performing_arts',
  'Technology',
  'Health',
  'Society',
  'Television_program',
  'Role-playing_video_game',
  'Music_of_Latin_America',
  'Pop_music',
  'Business',
  'Pet',
  'Physical_fitness',
  'Food',
  'Humour',
  'Golf',
  'Professional_wrestling',
  'Film',
  'Entertainment',
  'Ice_hockey',
  'Soul_music',
  'Christian_music',
  'Lifestyle_(sociology)',
  'Jazz',
  'Motorsport',
  'Association_football',
  'Hip_hop_music',
  'Religion',
  'Rock_music',
  'Simulation_video_game'
];

const CATEGORY_OPTIONS = CATEGORIES.map(cat => {
  return { label: cat, value: cat };
});

const CategoryRenderer = option => {
  return (
    <div className="Option">
      <label className="Option__label">{option.label}</label>
    </div>
  );
};

type Props = {
  intl: IntlShape,
  disabled: boolean,
  channelName: ?string,
  playedGames: string[],
  notPlayedGames: string[],
  tags: string[],
  excludedTags: string[],
  categoriesAny: string[],
  categoriesAll: string[],
  matchingContentMinVideoCount: number,
  onSelectPlayedGames: (string[]) => void,
  onSelectNotPlayedGames: (string[]) => void,
  onSelectTags: (string[]) => void,
  onExcludeTags: (string[]) => void,
  onCategoriesAny: (string[]) => void,
  onCategoriesAll: (string[]) => void,
  onChangeChannelName: string => void,
  onChangeMatchingContentMinVideoCount: (SyntheticInputEvent<*>) => void,
  onBlurMatchingContentMinVideoCount: (SyntheticInputEvent<*>) => void,
  influencerType: InfluencerSearchType,
  onSelectInfluencerType: (influencerType: InfluencerSearchType) => void
};

const uniq = (v, i, arr) => arr.findIndex(a => a === v) === i;

class GamesAndChannelName extends Component<Props> {
  static defaultProps = { disabled: false };
  onSelectPlayedGames = game => {
    this.props.onSelectPlayedGames(this.props.playedGames.concat(game.title).filter(uniq));
  };
  onSelectNotPlayedGames = game => {
    this.props.onSelectNotPlayedGames(this.props.notPlayedGames.concat(game.title).filter(uniq));
  };
  onChannelNameChange = event => {
    if (!event) {
      return;
    }
    this.props.onChangeChannelName(event.target.value);
  };
  renderChannelNameSearch = () => {
    return (
      <input
        className="input YoutubeSearchSettings__channel-name-input"
        type="text"
        name="generic-link"
        placeholder={this.props.intl.formatMessage({
          id: 'youtube.search.setting.searchChannelName'
        })}
        disabled={this.props.disabled}
        value={this.props.channelName}
        onChange={this.onChannelNameChange}
      />
    );
  };

  renderIncludedTagsSelector() {
    const { disabled, tags, onSelectTags } = this.props;

    return (
      <TagsSelector
        disabled={disabled}
        placeholder="Select tags influencer uses"
        onChange={tag => onSelectTags(tags.concat(tag.label).filter(uniq))}
      />
    );
  }

  renderExludedTagsSelector() {
    const { disabled, excludedTags, onExcludeTags } = this.props;

    return (
      <TagsSelector
        disabled={disabled}
        placeholder="Exclude tags influencer uses"
        onChange={tag => onExcludeTags(excludedTags.concat(tag.label).filter(uniq))}
      />
    );
  }

  renderCategoriesAny() {
    const { disabled, categoriesAny, onCategoriesAny } = this.props;

    return (
      <Select
        className="DynamicSelector"
        name="categories-any"
        clearable={false}
        disabled={disabled}
        onChange={category => onCategoriesAny(categoriesAny.concat(category.label).filter(uniq))}
        placeholder="Select topic categories (match any)"
        options={CATEGORY_OPTIONS}
        optionRenderer={CategoryRenderer}
        multi={false}
      />
    );
  }

  renderCategoriesAll() {
    const { disabled, categoriesAll, onCategoriesAll } = this.props;
    return (
      <Select
        className="DynamicSelector"
        name="categories-all"
        clearable={false}
        disabled={disabled}
        onChange={category => onCategoriesAll(categoriesAll.concat(category.label).filter(uniq))}
        placeholder="Select topic categories (match all)"
        options={CATEGORY_OPTIONS}
        optionRenderer={CategoryRenderer}
        multi={false}
      />
    );
  }

  renderPlayedGameSelector() {
    return (
      <GamesSelector
        filterFunc={game => {
          if (this.props.notPlayedGames.indexOf(game.title) !== -1) return false;
          if (this.props.playedGames.indexOf(game.title) !== -1) return false;
          return true;
        }}
        disabled={this.props.disabled}
        placeholder="Select games influencer has played"
        onChange={this.onSelectPlayedGames}
      />
    );
  }
  renderNotPlayedGameSelector() {
    return (
      <GamesSelector
        filterFunc={game => {
          if (this.props.notPlayedGames.indexOf(game.title) !== -1) return false;
          if (this.props.playedGames.indexOf(game.title) !== -1) return false;
          return true;
        }}
        disabled={this.props.disabled}
        placeholder="Select games influencer has not played"
        onChange={this.onSelectNotPlayedGames}
      />
    );
  }
  render() {
    return (
      <div className="YoutubeSearchSettings">
        <div className="is-flex-tablet">{this.renderChannelNameSearch()}</div>
        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">{this.renderIncludedTagsSelector()}</div>
          <div className="YoutubeSearchSettings__group">{this.renderExludedTagsSelector()}</div>
        </div>
        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">{this.renderPlayedGameSelector()}</div>
          <div className="YoutubeSearchSettings__group">{this.renderNotPlayedGameSelector()}</div>
        </div>
        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">{this.renderCategoriesAny()}</div>
          <div className="YoutubeSearchSettings__group">{this.renderCategoriesAll()}</div>
        </div>
        <div className="is-flex-tablet">
          <Input
            min={0}
            label={<FormattedMessage id="youtube.search.setting.matchingContentMinVideoCount" />}
            value={this.props.matchingContentMinVideoCount}
            onChange={this.props.onChangeMatchingContentMinVideoCount}
            onBlur={this.props.onBlurMatchingContentMinVideoCount}
            disabled={this.props.disabled}
          />
        </div>
        <div className="is-flex-tablet">
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.ALL}
            isRadio={true}
            onClick={() => this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.ALL)}
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.allChannels" />
          </OnOffToggle>
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.IN_NETWORK}
            isRadio={true}
            onClick={() => this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.IN_NETWORK)}
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.onlyInNetwork" />
          </OnOffToggle>
          <OnOffToggle
            isOn={this.props.influencerType === InfluencerSearchTypeEnum.OUT_OF_NETWORK}
            isRadio={true}
            onClick={() =>
              this.props.onSelectInfluencerType(InfluencerSearchTypeEnum.OUT_OF_NETWORK)
            }
            isDisabled={this.props.disabled}>
            <FormattedMessage id="youtube.search.setting.onlyOutOfNetwork" />
          </OnOffToggle>
        </div>
      </div>
    );
  }
}

GamesAndChannelName = injectIntl(GamesAndChannelName);

export default GamesAndChannelName;
