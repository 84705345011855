import Immutable from 'immutable';

import buildContainerReducer from '../helpers/buildContainerReducer';

import { OUT_OF_DATE_NEGOTIATION } from '../../helpers/errorCode';
import { negotiateCampaign } from '../../actions/campaign';

export default buildContainerReducer(
  {
    campaign: null,
    isLoadingCampaign: true,
    error: null,
    isNegotiatingCampaign: false,
    createdCampaignAgreement: null,
    creatingCampaignAgreement: null,
    isFetchingCampaignAnalytics: true,
    campaignAnalytics: null,
    isFetchingCampaignOverallStats: true,
    campaignOverallStats: null
  },
  {
    error: null,
    createdCampaignAgreement: null,
    creatingCampaignAgreement: null
  },
  [
    'campaign.fetchCampaign',
    'campaign.negotiateCampaign',
    'campaign.fetchCampaignAnalytics',
    'campaign.fetchCampaignOverallStats'
  ],
  (state, action) => {
    switch (action.type) {
      case negotiateCampaign.SUCCESS:
      case negotiateCampaign.FAILURE:
        const code = (action.payload || {}).code;
        if (!code || code === OUT_OF_DATE_NEGOTIATION) {
          let campaign = state.get('campaign');
          if (!campaign) return state;

          campaign = campaign.set('agreement', Immutable.fromJS(action.payload));

          state = state.set('campaign', campaign);
        }
        return state;
      default:
        break;
    }

    return state;
  }
);
