export default {
  loading: 'Loading...',
  back: 'Back to {title}',
  beta: 'BETA',
  contactEmail: 'hello@matchmade.tv',
  contactUs: 'If you have questions, please chat with us or contact {contactEmail}',
  genericErrorMessage: 'We’re sorry but something went wrong. Please try again.',
  areYouSure: 'Are you sure?',
  'error.pageNotFound': 'Page not found',
  'error.pageNotFoundContact': 'Email to {email} if you think this is an error.',
  'error.nodata': 'No data :(',
  'error.generic': 'Seems like an error occurred.',
  'platform.ios': 'iOS',
  'platform.android': 'Android',
  'platform.pc': 'Desktop',
  'platform.steam': 'Steam',
  'actions.accept': 'Accept',
  'actions.decline': 'Decline',
  'device.iphone': 'iPhone',
  'device.ipad': 'iPad',
  'takeItOrLeaveIt.description':
    'This is our best offer, and it can only be accepted or declined. The publishing date can be adjusted even after this offer is accepted',
  'offerType.negotiation': 'Negotiation',
  'offerType.express': 'Express',
  'modal.expiredSession.confirmation.admin.continue': 'Continue as an admin',
  'modal.expiredSession.confirmation.header': 'Session temporary suspended',
  'modal.expiredSession.confirmation.body': `You are temporarily logged in as a different user in a separate window. Continuing this session will log you out of that session.`,
  'influencerManager.dashboard.verificationPending.title': 'Welcome to creator manager dashboard',
  'influencerManager.dashboard.verificationPending':
    'We will verify your account shortly, but you can check out available campaigns already now. If you have any questions, please email {email}.',
  'influencerManager.dashboard.searchChannelsPlaceholder':
    'Type the ID or URL of a YouTube channel that you manage',
  'influencerManager.dashboard.searchChannels': 'Search channels',
  'influencerManager.actingOnBehalf': "You're now acting on behalf of {channelName}",
  'influencerManager.searchResults.emailPlaceholder': 'Enter contact email',
  'influencerManager.dashboard.noManagedInfluencers':
    "Seems like you haven't added any managed creators yet.",
  'influencerManager.dashboard.noInvitedInfluencers': "You don't have any pending invitations",
  'influencerManager.influencerStatus.sent': 'Invitation sent!',
  'influencerManager.influencerStatus.pending': 'Invitation sent to {email}',
  'influencerManager.influencerStatus.declined': 'Invitation declined',
  'influencerManager.inviteStatus.heading': 'Invitation status',
  'influencerManager.influencerInvite.cancel': 'Cancel invitation',
  'influencerManager.influencer.profileLink': 'See available campaigns',
  'influencerManager.influencer.installs': 'Installs',
  'influencerManager.influencer.earnings': 'Earnings',
  'influencerManager.influencer.campaigns': 'Campaigns joined',
  'influencerManager.influencer.pendingOffers': 'Pending offers',
  'influencerManager.inviteInfluencers.title': "Let's get started",
  'influencerManager.inviteInfluencers.subtitle': 'by finding the influencer you want to manage',
  'influencer.banner.missingInfo':
    'We are still missing a few bits of contact information from you!',
  'influencer.verificationPending.managedAccount':
    'This is a managed account. Please contact us at {email} to claim back your account.',
  'influencer.verificationPending.default':
    'Unfortunately, your account is not verified yet. Please try with a different account, or contact {email}.',
  'influencer.fillInformation': `Please fill in your profile information, so we can contact
you faster and optimize the matching results. `,
  'influencer.fillInformation.doItHere': `Do it here.`,
  'influencer.verificationPendingLinkText': 'on our blog',
  'influencer.banner.fillItHere': 'Fill it here',
  'influencer.missingContactInfo': 'Your contact info',
  'influencer.missingContactInfo.description': `We'd love to send you campaign offers.`,
  'influencer.managementInvite.title':
    '{managerName} wants to manage your {channelNameAsLink} channel.',
  'influencer.managementInvite.subtitle': 'The manager of your channel',
  'influencer.managementInvite.managerCanJoinCampaigns':
    'will be able to join campaigns on your behalf',
  'influencer.managementInvite.managerCanHandleFinance':
    'will handle all financial negotiations with advertisers',
  'influencer.managementInvite.managerCanHandlePayments': 'will handle your payments',
  'influencer.managementInvitesTitle': 'Management invitations',
  'influencer.acceptInvitationTos': 'By accepting the invitation, you agree to our {tosLink}.',
  'influencer.managedAccount': 'Your account is managed by {teamName}',
  'influencer.videoSince':
    '{videoCount, number} {videoCount, plural, one {videos} other {videos}} since',
  'influencer.comments': '{commentCount, plural, one {comment} other {comments}}',
  'influencer.card.deals.noDeals': 'No deals yet',
  'influencer.card.subscriberCount': 'Subscribers',
  'influencer.card.deals.cost': 'Cost',
  'influencer.card.deals.payout': 'Payout',
  'influencer.card.deals.installs': 'Installs',
  'influencer.card.deals.views': 'Views',
  'influencer.card.deals.clicks': 'Clicks',
  'influencer.card.deals.promotionType': 'Promo',
  'influencer.card.deals.eCpm': 'eCPM',
  'influencer.card.deals.admin.eCpm.tooltip':
    'Effective Cost Per Mille for Advertiser (1000 views)',
  'influencer.card.deals.eCpm.tooltip':
    'Effective Cost Per Mille (1000 views). Calculated based on the 30day average views and fixed fee price.',
  'influencer.card.deals.ctr': 'CTR%',
  'influencer.card.deals.ctr.tooltip': 'Click through rate (Views to click)',
  'influencer.card.deals.cr': 'CR%',
  'influencer.card.deals.cr.tooltip': 'Conversion rate (Clicks to install)',
  'influencer.card.deals.viewsToInstalls': 'VTI%',
  'influencer.card.deals.viewsToInstalls.tooltip': 'View through rate (Views to install)',
  'influencer.card.deals.campaign': 'Campaign',
  'influencer.card.cpi': 'CPI',
  'influencer.card.cpm': 'CPM',
  'influencer.card.monthlyViews': 'Last 30d Views',
  'influencer.card.monthlyViews.avg': 'Average',
  'influencer.card.monthlyViews.med': 'Median',
  'influencer.card.monthlyViews.est': 'Estimated 30d views',
  'influencer.card.admin.estimates': 'Estimates',
  'influencer.card.admin.estimates.views': '30d views',
  'influencer.card.price': 'Price',
  'influencer.card.followers': 'Followers',
  'influencer.card.adminCpiTooltip': 'For influencer: {cpi}',
  'influencer.card.estimatedInstalls': 'Installs',
  'influencer.card.mrs': 'MRS',
  'influencer.card.mrs.description1':
    'How well does sponsored content do on the channel vs. non-sponsored (organic) interms of video views.',
  'influencer.card.mrs.description2':
    'A score of 100 would mean that sponsored and organic content do equally well so whether the channel does sponsored content or not should not affect the views that the videos get. A score over 100 would indicate that sponsored content gets on average more views than organic and a score less than 100 that the sponsored content gets on average fewer views than the organic content.',
  'influencer.card.estimatedCost': 'Estimated cost',
  'influencer.card.estimatedPayout': 'Est. payout',
  'influencer.card.cost': 'Cost',
  'influencer.card.payout': 'Payout',
  'influencer.card.stats': 'Stats',
  'influencer.card.totalChannelSubs': 'Total subscribers',
  'influencer.card.totalChannelViews': 'Total views',
  'influencer.card.likesInPastMonth': 'Likes in past month',
  'influencer.card.commentsInPastMonth': 'Comments in past month',
  'influencer.card.avgViewsInPastMonth': 'Monthly average views',
  'influencer.card.estimatedViews': 'Views',
  'influencer.card.engagement': '30d avg. engagement',
  'influencer.card.videos': 'videos',
  'influencer.card.subscribers': 'subscribers',
  'influencer.card.videoEta': 'Video ETA: {deadline}',
  'influencer.card.trendChange': '{value} 30d',
  'influencer.card.trendNoChange': 'No change',
  'influencer.card.tabs.overview': 'Overview',
  'influencer.card.tabs.details': 'Details',
  'influencer.card.tabs.contacts': 'Contacts',
  'influencer.card.tabs.video': 'Video',
  'influencer.card.toggleOverview': 'Overview',
  'influencer.card.toggleDetails': 'Details',
  'influencer.card.toggleVideo': 'Video',
  'influencer.card.noVideo': 'No video available',
  'influencer.card.stats.nodata': '(not enough data for audience analysis)',
  'influencer.card.stats.age': 'Age',
  'influencer.card.stats.country': 'Country',
  'influencer.card.stats.male': 'Male',
  'influencer.card.stats.female': 'Female',
  'influencer.card.stats.estimatedCountryTooltip': 'Estimated based on video title and description',
  'influencer.card.stats.averageEngagementTooltip':
    'Average engagement ratio is calculated by dividing the likes and comments of last 30 days of YouTube videos by their view count.',
  'influencer.card.minGuarantee': 'Min guarantee',
  'influencer.card.maxPayment': 'Max payment',
  'influencer.card.fixedFee': 'Fixed fee',
  'influencer.card.freeOfCharge': 'Free of charge',
  'influencer.card.resonanceScore': 'Resonance score',
  'influencer.card.countryFilterInfo': `Might not fit this campaign's target {countryCount, plural, one {country} other {countries}}.`,
  'influencer.card.estimatedEarningsNotice':
    'This number is calculated based on public data, your actual earnings might be different.',
  'influencer.card.demographicsTab.recentVideos': 'Recent videos',
  'influencer.card.demographicsTab.game': 'Game',
  'influencer.card.demographicsTab.numberOfVideo': '# videos',
  'influencer.card.demographicsTab.avgViews': 'Avg. views',
  'influencer.card.actions.offerSent': 'Offer sent',
  'influencer.card.actions.wait': 'Wait for response',
  'influencer.card.actions.dealMade': 'Deal made',
  'influencer.card.admin.influencerHeader': 'Creator',
  'influencer.card.admin.publisherHeader': 'Advertiser',
  'influencer.card.admin.influencerHeaderTooltip': 'What the influencer sees',
  'influencer.card.admin.publisherHeaderTooltip': 'What the advertiser sees',
  'influencer.card.admin.currentDealHeader': 'Current deal',
  'influencer.card.admin.suggestionHeader': 'Suggestion',
  'influencer.card.admin.commissionRate': 'Commission rate',
  'influencer.card.admin.publishingDate': 'Publishing date',
  'influencer.card.admin.publishedContent': 'Published content',
  'influencer.card.admin.minGuaranteeWithoutCommission': 'Minimum guarantee without commission',
  'influencer.card.admin.maxPaymentWithoutCommission': 'Maximum payment without commission',
  'influencer.card.admin.cpiWithoutCommission': 'CPI without commission',
  'influencer.card.admin.cpmWithoutCommission': 'CPM without commission',
  'influencer.card.admin.fixedFeeWithoutCommission': 'Payout',
  'influencer.card.admin.cancelDeal': 'Cancel deal',
  'influencer.card.admin.acceptAsInfluencer': 'Accept as creator',
  'influencer.card.admin.acceptAsPublisher': 'Accept as advertiser',
  'influencer.cards.keyboardTip': 'Press key 1, 2, 3... to switch influencer cards tabs',
  'influencer.searchV1Card.videos.30d':
    '{count} {count, plural, one {video} other {videos}} published in the past 30 days',
  'influencer.searchV1Card.video': 'This video',
  'influencer.searchV1Card.video.views': 'Views',
  'influencer.searchV1Card.video.comments': 'Comments',
  'influencer.searchV1Card.video.likes': 'Likes',
  'influencer.searchV1Card.video.engagement': 'Engagement',
  'influencer.campaign.publishingWindowModal.title': 'When will you publish the sponsored video?',
  'influencer.campaign.publishingWindowModal.confirm': 'Confirm publishing date',
  'influencer.campaign.info': 'See campaign description',
  'influencer.campaign.available': 'Available campaigns',
  'influencer.campaign.joined': "{my, select, true {My} other {{username}'s}} campaigns",
  'influencer.campaign.accept': 'Accept',
  'influencer.campaign.decline': 'Decline',
  'influencer.campaign.withdraw': 'Withdraw',
  'influencer.campaign.declineOffer': 'Decline offer',
  'influencer.campaign.withdrawOffer': 'Withdraw offer',
  'influencer.campaign.change': 'Change',
  'influencer.campaign.join': 'Join',
  'influencer.campaign.offerInNegotiation': 'Deal in negotiation',
  'influencer.campaign.takeItOrLeaveItDeal': 'Deal available',
  'influencer.campaign.offerAccepted': 'Offer accepted',
  'influencer.campaign.unknownDeadline': 'Not specified',
  'influencer.campaign.releaseDate': 'Publishing date',
  'influencer.campaign.releaseDateRange': 'Publishing date between',
  'influencer.campaign.sendOffer': 'Send offer',
  'influencer.campaign.sendOffer.charity': 'Ask to join the cause!',
  'influencer.campaign.makeOffer': 'Make offer',
  'influencer.campaign.makeOffer.charity': 'Join the cause!',
  'influencer.campaign.campaignInfo': 'Campaign info',
  'influencer.campaign.availablePromotionTypes': 'Available promotion types',
  'influencer.campaign.availableContentPlatforms': 'Available content platforms',
  'influencer.campaign.integrationExplanation': 'Video includes short promotional clip of the game',
  'influencer.campaign.dedicatedExplanation': 'Video features only this game',
  'influencer.campaign.youtubeExplanation': 'Video published on YouTube',
  'influencer.campaign.twitchExplanation': 'Live stream on Twitch',
  'influencer.campaign.readThis': 'I have read this',
  'influencer.campaign.acceptAndProceed': 'Accept & proceed',
  'influencer.campaign.dedicated': 'Available promotion types',
  'influencer.campaign.videoEta.default': 'Publishing date',
  'influencer.campaign.videoEta.youtube': 'Publishing date',
  'influencer.campaign.videoEta.twitch': 'Streaming date',
  'influencer.campaign.videoEta.instagram': 'Publishing date',
  'influencer.campaign.datePublished': 'Date published',
  'influencer.campaign.dateSubmitted': 'Date submitted',
  'influencer.campaign.agreedDate': 'Agreed date',
  'influencer.campaign.installPageLink': 'Install page link',
  'influencer.campaign.waitForVideo.youtube': 'Wait until you have received the video for review',
  'influencer.campaign.waitForVideo.twitch': 'Wait for stream to go live',
  'influencer.campaign.waitForVideo.instagram': 'Wait for content to go live',
  'influencer.campaign.waitForVideo.default': 'Wait for content to go live',
  'influencer.campaign.approveUnlistedVideo': 'Approve the video through {link}',
  'influencer.campaign.videoUnlisted': 'Review the video',
  'influencer.campaign.videoReleased': 'Video published',
  'influencer.campaign.contentPublished': 'Content published',
  'influencer.campaign.contentInReview': 'Ready to review',
  'influencer.campaign.sendUnlistedVideo.instagram':
    'Submit your content for review based on campaign instructions. Do not publish until it has been approved',
  'influencer.campaign.sendUnlistedVideo.youtube':
    'Submit your unlisted video for review based on campaign instructions. Do not publish until it has been approved',
  'influencer.campaign.sendUnlistedVideo.twitch':
    'Send highlighted campaign clip from your stream based on campaign instructions.',
  'influencer.campaign.sendUnlistedVideo.default':
    'Submit your content for review based on campaign instructions. Do not publish until it has been approved',
  'influencer.campaign.messages': 'messages',
  'influencer.campaign.videoIsLate.youtube': 'Video is late',
  'influencer.campaign.videoIsLate.twitch': 'Stream is late',
  'influencer.campaign.videoIsLate.default': 'Video is late',
  'influencer.campaign.days': '{days} {days, plural, one {day} other {days}}',
  'influencer.campaign.copyURL': 'Copy URL to clipboard',

  'influencer.campaign.influencerSetCpi':
    'You have offered to join this campaign with the CPI rate of {influencerCpi} and {influencerMinGuarantee, select, 0 {no minimum guarantee} other {minimum guarantee of {influencerMinGuarantee}}}{isValidDate, select, true {. Video deadline is {date}} other {}}. Please wait for the response from the advertiser.',
  'influencer.campaign.waitingForApproval':
    'You have offered to join this campaign with the CPI rate of {influencerCpi} and {influencerMinGuarantee, select, 0 {no minimum guarantee} other {minimum guarantee of {influencerMinGuarantee}}}{isValidDate, select, true {. Video deadline is {date}} other {}}. Please wait for the response from the advertiser.',
  'influencer.campaign.invitedToJoin':
    'You have been invited to join this campaign with a CPI rate of {publisherCpi} and {publisherMinGuarantee, select, 0 {no minimum guarantee} other {a minimum guarantee of {publisherMinGuarantee}}}.',

  'influencer.campaign.minGuaranteeTooltip': 'You are guaranteed to earn at least {value}',

  'influencer.campaign.yourTrackingUrl': 'Your install page link: ',
  'influencer.campaign.estimatedEarnings': 'Estimated earnings',
  'influencer.campaign.yourCpiRate': 'Your CPI rate is {cpi}',
  'influencer.campaign.yourMinGuarantee': 'Your minimum guarantee is {minGuarantee}',
  'influencer.campaign.videoDeadline': 'Video should be published on {date}',
  'influencer.campaign.deadlineTooltipForInfluencer':
    'You promise to publish the video on this day',
  'influencer.campaign.deadlineTooltipForPublisher': 'Suggest a desired publish date for the video',
  'influencer.campaign.viewDetails': 'View details',
  'influencer.campaign.viewGame': 'View game',
  'influencer.campaign.countryFilterInfo': 'Target countries: {countries}.',
  'influencer.campaign.kpi.chart.title': 'Performance overview',
  'influencer.campaign.kpi.chart.install.label': 'Installs',
  'influencer.campaign.kpi.chart.view.label': 'Views',
  'influencer.campaign.kpi.chart.install.summary':
    '{installs, plural, one {install} other {installs}}',
  'influencer.campaign.kpi.chart.cost.label': 'Earnings',
  'influencer.campaign.kpi.chart.cost.summary': 'earnings',
  'influencer.campaign.kpi.chart.conversion.summary': 'conversion rate',
  'influencer.campaign.kpi.chart.cumulative': 'Cumulative',
  'influencer.campaign.kpi.chart.hourly': 'Hourly',
  'influencer.campaign.kpi.chart.daily': 'Daily',
  'influencer.campaign.kpi.chart.monthly': 'Monthly',
  'influencer.campaign.relevancy.popularCampaign': 'Popular campaign',
  'influencer.dashboard.chart.title.age': 'Age',
  'influencer.dashboard.chart.title.country': 'Country',
  'influencer.dashboard.twitch.statsTitle': 'Last 30d streams concurrent views',
  'influencer.dashboard.twitch.avgCcv': 'Average',
  'influencer.dashboard.twitch.medianCcv': 'Median',
  'influencer.dashboard.twitch.peakCcv': 'Peak',
  'influencer.dashboard.noCampaigns.title': `Welcome to Matchmade!`,
  'influencer.dashboard.noCampaigns.email':
    'Questions? Please check our {faq} or reach out to us by clicking on the Support button in the bottom right corner.',
  'influencer.dashboard.noCampaigns.profile': `Meanwhile you can help us in matching you with suitable advertisers and campaigns by adding your {link} in your profile.`,
  'influencer.dashboard.noCampaigns':
    'Sorry! Currently we don’t have any campaigns that match your channel metrics. We’ll send you notifications by email when there’s a potential campaign.',
  'influencer.dashboard.noCampaigns.chatWithUs': 'chat with us',
  'influencer.collection.manage': 'Manage tabs',
  'influencer.collection.linkedToCampaigns':
    'Linked to  {count, plural, =0 {0 campaigns} one {1 campaign} other {{count} campaigns}}',
  'influencer.collection.dynamic.description':
    'Automatically updated collection of suggested channels',
  'influencer.collection.static.description': 'Hand-picked collection of suggested channels',
  'influencer.collection.dynamic': 'Dynamic collection',
  'influencer.collection.static': 'Static Collection',
  'influencer.collection.unlink': 'Unlink',
  'influencer.collection.unlink.tooltip': 'Unlink this collection from the campaign',
  'influencer.collection.deleteMessage':
    'Campaigns using this collection will no longer show data associated to this collection.',
  'influencer.collection.removeChannel': 'Remove from this collection',
  'influencer.collection.inCollection':
    'In {count, plural, =0 {0 collections} one {1 collection} other {{count} collections}}',
  'influencer.collection.add': 'Add to collection',
  'influencer.collection.createCollection': 'Create a new collection',
  'influencer.collection.influencerCount':
    '{count, plural, =0 {No creators found} one {1 creator} other {{count} creators}}',
  'influencer.collection.loading': 'Loading creators...',
  'influencer.collection.viewEmailAddress': 'View email address',
  'influencer.collection.display.cards': 'Cards',
  'influencer.collection.display.list': 'List',
  'influencer.collection.display.table.name': 'Name',
  'influencer.collection.display.table.platform': 'Platform',
  'influencer.collection.display.table.videos': 'Videos',
  'influencer.collection.display.table.subscribers': 'Subscribers',
  'influencer.collection.display.table.viewsInPastMonth': 'Views',
  'influencer.collection.display.table.engagement': 'Engagement',
  'influencer.collection.display.table.views': 'Views',
  'influencer.collection.display.table.30dVideos': 'Videos',
  'influencer.collection.display.table.30dLikes': 'Likes',
  'influencer.collection.display.table.30dComments': 'Comments',
  'influencer.collection.display.table.last30dAvg': 'Last 30d average',
  'influencer.collection.display.table.last30dTotal': 'Last 30d total',
  'influencer.collection.display.table.country': 'Country',
  'influencer.collection.display.table.contacts': 'Contacts',
  'influencer.collection.sort.last30dAvgViews': '30d avg. views',
  'influencer.collection.sort.estimatedViews': 'Estimated views',
  'influencer.collection.sort.last30dAvgLikes': '30d avg. likes',
  'influencer.collection.sort.last30dAvgComments': '30d avg. comments',
  'influencer.collection.sort.last30dAvgEngagement': '30d avg. engagement',
  'influencer.collection.sort.last30dTotalVideos': '30d total videos',
  'influencer.collection.sort.eCpm': 'eCPM',
  'influencer.collection.sort.dealDone': 'Deal created',
  'influencer.collection.sort.publishDate': 'Publish Date',
  'influencer.collection.sort.subscribers': 'Subscribers',
  'influencer.collection.sort.similarity': 'Similarity',
  'influencer.collection.sort.videos': 'Videos',
  'influencer.collection.sort.views': 'Views',
  'influencer.collection.sort.name': 'Channel name',
  'influencer.collection.sort.country': 'Country',
  'influencer.collection.sort.language': 'Language',
  'influencer.collection.sort.matchingVideos': 'Matching videos',
  'influencer.collection.sort.estimatedInstalls': 'Estimated installs',
  'influencer.collection.sort.estimatedCost': 'Estimated cost',
  'influencer.collection.sort.contentPlatform': 'Content platform',
  'influencer.collection.sort.thisMonthActiveCampaigns': 'Campaigns joined (this month)',
  'influencer.collection.sort.thisMonthPendingCampaigns': 'Pending offers (this month)',
  'influencer.collection.sortBy': 'sort by',
  'influencer.collection.sortIn': 'in',
  'influencer.collection.sortDirection.asc': 'Ascending order',
  'influencer.collection.sortDirection.desc': 'Descending order',
  'influencer.collection.linkToCampaign.label': 'Link collection to a campaign ',
  'influencer.collection.linkToCampaign.tooltip':
    'This function adds the collection to the campaign page as a new tab',
  'influencer.profile.modal.youtubePreferences.title': 'Prices',
  'influencer.profile.title': 'My profile',
  'influencer.profile.description': `We are thrilled to cooperate with you and hope you’ll find campaigns from Matchmade that fit your channel!`,
  'influencer.profile.agencyInfo': 'Agency information',
  'influencer.profile.agencyName': 'Name',
  'influencer.profile.contactInformation': 'Contact information',
  'influencer.profile.youtubePreferences.title': 'Promotion types',
  'influencer.profile.youtubePreferences.tooltip':
    'Adding available promotion types helps us in matching you with suitable advertisers and campaigns.',
  'influencer.profile.contactInformation.contactEmail': 'Contact email',
  'influencer.profile.contactInformation.email': 'Backup email',
  'influencer.profile.contactInformation.backupChannel': 'Backup channel for communication',
  'influencer.profile.contactInformation.skype': 'Skype ID',
  'influencer.profile.contactInformation.whatsapp': 'Phone number',
  'influencer.profile.contactInformation.phone': 'Phone number',
  'influencer.profile.contactInformation.other': 'Please specify',
  'influencer.profile.preferredPromotionType': 'My available YouTube promotion types',
  'influencer.profile.preferredPromotionType.dedicated': 'Dedicated',
  'influencer.profile.estimatedPrice': 'Your Matchmade estimated price:',
  'influencer.profile.preferredPromotionType.dedicated.tooltip':
    'A dedicated video is only about the product you promote. You make a video that focuses on the promoted product only.',
  'influencer.profile.preferredPromotionType.integratedShort': 'Integrated 35-60 sec',
  'influencer.profile.preferredPromotionType.integratedShort.tooltip':
    'An integrated video means that the promoted product is mentioned/shown in one of your regular videos. Approximately 35-60 seconds.',
  'influencer.profile.preferredPromotionType.integratedLong': 'Integrated 60-120 sec',
  'influencer.profile.preferredPromotionType.integratedLong.tooltip':
    'An integrated video means that the promoted product is mentioned/shown in one of your regular videos. Approximately 60-120 seconds.',
  'search.collection.title': 'Edit dynamic collection parameters',
  'search.youtube.title': 'Youtube creators search',
  'search.twitch.title': 'Twitch creators search',
  'search.backToCollection': ' Back to collection {name}',
  'search.linkToPreviousPage': 'Go back to {section} page',
  'search.game.placeholder': 'Select game to search for creators',
  'search.editCollection.button': 'Save dynamic collection parameters',
  'search.saveToCollection.button': 'Save search parameters as dynamic collection',
  'search.saveToCollection.description':
    'This action will store your defined search parameters and create a new collection with the results',
  'search.selectedCollections': 'Selected collections',
  'search.saveSearchToCollection': 'Save search to collection',
  'nav.socialMediaAccounts': 'Social media accounts',
  'nav.myProfile': 'My profile',
  'nav.myDashboard': 'My dashboard',
  'nav.myTeam': 'My team',
  'nav.createTeam': 'Create a team',
  'nav.logout': 'Log out',
  'nav.backToAdmin': 'Admin dashboard',
  'nav.paymentInfo': 'Payment Info',
  'nav.tos': 'Terms of service',
  'nav.faq': 'Knowledge Base',
  'nav.faqInHeader': 'FAQ',
  'nav.search': 'Search',
  'nav.search.youtube': 'Youtube',
  'nav.search.twitch': 'Twitch',
  'nav.collections': 'Collections',
  'nav.joinCommunity': 'Join the Matchmade community',
  'nav.joinCommunity.discord': 'Discord',
  'nav.invites': 'Pending invitations',
  'nav.admin.installs': 'Installs',
  'nav.admin.users': 'Users',
  'nav.admin.influencers': 'Creators',
  'nav.admin.managers': 'Managers',
  'nav.admin.publishers': 'Advertisers',
  'nav.admin.campaigns': 'Campaigns',
  'nav.admin.allocatedBudget': 'Allocated budget',
  'nav.admin.newCampaign': 'New campaign',
  'nav.admin.email': 'Email',
  'nav.admin.email.v2': 'Email 2.0',
  'nav.admin.teams': 'Teams',
  'nav.admin.redirectLinks': 'Redirect Links',
  'nav.admin.budgets': 'Budgets',
  'nav.admin.apiExplorer': 'API Explorer',
  'nav.admin.apiExplorer.youtube': 'YouTube',
  'nav.admin.apiExplorer.facebook': 'Facebook',
  'nav.admin.apiExplorer.IGPostData': 'IG Posts',
  'nav.admin.finance': 'Finance',
  'nav.admin.newPayout': 'New payout',
  'nav.admin.newInvoiceItem': 'New invoice item',
  'nav.admin.payoutStatusUpdate': 'Update payout statuses',
  'nav.admin.payoutUpdate': 'Update payout tasks',
  'nav.admin.invoiceStatusUpdate': 'Update invoice statuses',
  'nav.influencerManager.influencers': 'Managed creators',
  'nav.influencerManager.inviteInfluencers': 'Invite creators',
  'nav.operations': 'Operations',
  'nav.collections.addChannels': 'Add Youtube channels',
  'nav.collections.combineYoutubeCollection': 'Combine Youtube collections',
  'nav.collections.combineInstagramCollection': 'Combine Instagram collections',
  'nav.collections.addTwitchChannels': 'Add Twitch channels',
  'nav.collections.addInstagramChannels': 'Add Instagram channels',
  'influencerManager.importInfluencers.searchResults': 'Search results',
  'auth.welcome':
    'Welcome! Please register for the invite-only beta by clicking one of the buttons below.',
  'auth.login.facebook': 'Log in with Facebook',
  'auth.login.google': 'Log in with Google',
  'auth.login': 'Log in',
  'auth.faq': 'FAQ',
  'auth.login.help': 'Use same social media account as you used originally to log in.',
  'auth.signup': 'Sign up',
  'auth.trustUs': 'Trusted by',
  'auth.trustUsAndOthers': '...and thousands of others',
  'auth.signup.titleExplanation':
    'Work on exciting sponsorships with well known brands and grow your business. We’ll automatically match you with brand deals that are relevant to you and your audience. Join Matchmade today and apply with one-step application process through our platform. Without agencies and exclusivities. Simple, honest and straightforward.',
  'auth.signup.subtitleExplanation':
    'Create your account using your existing social media account. Your channels can be connected to the platform later on.',
  'auth.loginAdvertiser': 'I am an advertiser',
  'auth.loginInfluencerManager': 'I manage creators',
  'auth.loginInfluencer': 'I am a creator',
  'auth.loginFailedNoDuplicateRoles': 'There was a problem logging in.',
  'auth.loginFailedAuthServerResponseError': 'There was an internal error trying to log you in.',
  'auth.error.noChannels':
    "We can't find any channels linked to your account. Please try with a different account, or contact {contactEmail}. Sorry for the hassle.",
  'auth.createAccount.title': 'The only sponsorship marketplace you’ll need as a creator',
  'auth.createAccount.subtitle': 'Sign up',
  'auth.login.title': 'Log in',
  'auth.selectRole.title': 'Who are you?',
  'auth.accountDetails.title': 'Account details',
  'auth.connectAccount.title': 'Connect your channels',
  'auth.connectAccount.explanation':
    'We only fetch some basic data listed below. We have no access to any of your monetization or ad revenue information.',
  'auth.connectAccount.connected': 'Connected',
  'auth.connectAccount.selectChannelToConnect':
    'Which one of your channels would you like to connect?',
  'auth.connectAccount.scope': 'Data we get',
  'auth.connectAccount.requirements': 'Requirements',
  'auth.provider.youtube': 'Continue with Youtube',
  'auth.provider.google': 'Continue with Google',
  'auth.provider.facebook': 'Continue with Facebook',
  'chat.noMessagesInChat': 'There are no messages in this chat yet',
  'chat.currentlyDisabled':
    'This chat has been closed. If you have any questions please check out the {faq} or reach out to us via the support button in your bottom right corner',
  'chat.noAvailableChats': 'There are no ongoing chats',
  'chat.fromYou': 'From you:',
  'chat.loadingChats': 'Loading chats...',
  'publisher.dashboard.campaignTable.product': 'Product',
  'publisher.dashboard.campaignTable.campaignName': 'Name',
  'publisher.dashboard.campaignTable.active': 'Active',
  'publisher.dashboard.myCampaigns': 'My campaigns',
  'publisher.game.overallRating': 'overall rating',
  'publisher.game.reviews': 'reviews',
  'publisher.game.positive': 'positive',
  'publisher.game.ratings': 'ratings',
  'publisher.game.inCountries':
    'in {countries, number} {countries, plural, one {country} other {countries}}',
  'publisher.game.importGameFromSearch': 'Continue',
  'publisher.game.pasteLink':
    'Paste your App Store, Google Play or Steam link or type the game title here',
  'publisher.game.selectGameFromTheOtherStore': "You haven't selected the game on the {store}",
  'publisher.game.selectGames':
    'Please select the correct game on the App Store and / or Google Play or Steam',
  'publisher.game.selectGameOnlyFromMobileOrDesktop':
    'Campaigns can only target mobile or desktop. If you want to target both, please add two separate games and create two campaigns.',
  'publisher.game.processing': 'Processing {text}',
  'publisher.game.myGames': 'My games',
  'publisher.campaign.collection.tabs': 'Collection tabs',
  'publisher.campaign.collection.addAsTab':
    'Add collection as a tab. This makes the the campaign visible for influencers in the collection, and sends a notification for in-network ones.',
  'publisher.game.verified':
    '{title} - {verified, select, verified {Verified!} other {Unverified}}',
  'publisher.campaign.invitedToJoinOutOfNetwork':
    'You have invited this influencer to join your campaign with an estimated CPI rate of {cpi}',
  'publisher.campaign.inviteToJoinOutOfNetwork': 'Estimated CPI {cpi}',
  'publisher.campaign.cancelInvite': 'Cancel invite',
  'publisher.campaign.overallRating': 'Overall rating',
  'publisher.campaign.positive': 'Positive',

  'publisher.campaign.minGuaranteeTooltip':
    'You agree to either the minimum guarantee or the CPI rate times the number of installs, whichever is bigger',

  'publisher.campaign.settled.youtube': 'Install page link',
  'publisher.campaign.settled.twitch': 'Tracking link',
  'publisher.campaign.settled.instagram': 'Tracking link',
  'publisher.campaign.settled.default': 'Tracking link',
  'publisher.campaign.kpi.chart.title': 'Campaign performance',
  'publisher.campaign.allInfluencers': 'All in network',
  'publisher.campaign.suggestedInfluencers': 'Suggested influencers',
  'publisher.campaign.defaultInfluencers': 'Default',
  'publisher.campaign.est': 'Est. ',
  'publisher.campaign.attribution.specialUrls': 'I have different URLs for iOS and Android',
  'publisher.campaign.attribution.invalidUrl': 'Invalid URL',
  'publisher.campaign.attribution.singleLabel': 'Attribution URL link',
  'publisher.campaign.attribution.singleLabelOther': 'Name of attribution provider',
  'publisher.campaign.attribution.input.url': 'Enter url',
  'publisher.campaign.attribution.input.code': 'Enter code',
  'publisher.campaign.attribution.input.typeName': 'Type name here',
  'publisher.campaign.attribution.choose': 'Choose',
  'publisher.campaign.attribution.save': 'Save',
  'publisher.campaign.attribution.noPartnerHeader':
    'In order to start your campaign, please set up install attribution',
  'publisher.campaign.attribution.hasPartnerHeader': 'Tracking',
  'publisher.campaign.attribution.pixelDescription':
    'You have set up attribution for your campaign with tracking pixel. Please add our tracking pixel to your sign up / registration confirmation page:',
  'publisher.campaign.attribution.adjustDescription1':
    '{term} as the attribution method for your Adjust tracker URL(s).',
  'publisher.campaign.attribution.adjustTerm': 'Select Click-based probabilistic matching',
  'publisher.campaign.attribution.adjustDescription2': 'Read more in {link}.',
  'publisher.campaign.attribution.adjustDocumentation': 'the Adjust Documentation',
  'publisher.campaign.attribution.appsflyerDocumentation': 'the Appsflyer documentation',
  'publisher.campaign.attribution.appsflyerDescription1':
    'Provide access as per {link} for the "{descriptionTerm}" ad network.',
  'publisher.campaign.attribution.appsflyerDescription2':
    'Grant us at least the "Integration Parameter Configurations" and "Retention Reports" permissions.',
  'publisher.campaign.attribution.appsflyerDescription3':
    'Enable Matchmade as Active Partner on AppsFlyer',
  'publisher.campaign.attribution.appsflyerDescription4':
    '1. Open your advertiser dashboard on AppsFlyer',
  'publisher.campaign.attribution.appsflyerDescription5': '2. Go to the Integrated Partner section',
  'publisher.campaign.attribution.appsflyerDescription6':
    '3. Enable Active Partner status for Matchmade',
  'publisher.campaign.attribution.tenjinDescription':
    'Add {descriptionTerm} as a Marketing channel in the <channelsLink>Channels dashboard</channelsLink>.<p>Create a new campaign using {descriptionTerm} in the <campaignsLink>Campaigns dashboard</campaignsLink>.</p><p>Set “Marketing callback” to “Ping on every attribution” for {descriptionTerm}.</p><p>Enter the tracking link(s) here.</p>',
  'publisher.campaign.attribution.tuneDescription':
    'Enable Matchmade as your <link>Universal Ads Partner</link>. Enter the ad link(s) that you have created here.',
  'publisher.campaign.attribution.buffpanelDescription':
    'Create a tracking link as per the <link>Buffpanel Documentation</link> and enter the tracking link here.',
  'publisher.campaign.attribution.kochavaDescription':
    'Create an install campaign as per <link>the Kochava Documentation</link>.<p>Enter the Click URL(s) here.</p><p> Setup a Kochava-Certified postback to network partner {descriptionTerm} as per <link>the Kochava Documentation</link>.</p>',
  'publisher.campaign.attribution.singularDescription':
    'Add "Matchmade" <initialSetupLink>attribution partner</initialSetupLink>.<p>Generate tracking link(s) as per <link>the Singular Documentation</link>.</p><p>Enter the tracking link(s) here.</p>',
  'publisher.campaign.attribution.listItem': 'We send {name} as {param}',
  'publisher.team.changeName': 'Change name',
  'publisher.team.addTeamMember': 'Add a team member',
  'publisher.team.createTeam': 'Create a team',
  'publisher.team.updateTeam': 'Update team',
  'publisher.team.name': 'Name',
  'publisher.team.name.placeholder': 'Name',
  'publisher.team.currentMembers': '{count} {count, plural, one {member} other {members}}',
  'publisher.team.deleteMember.title': 'Remove "<strong>{name}</strong>"?',
  'publisher.team.deleteMember.warning':
    'This member will no longer have the access to your team. Do you want to continue?',
  'publisher.team.inviteMember': 'Invite a new team member by entering their email',
  'team.member.displayName': 'Name',
  'team.member.displayName.notSet': 'Not set',
  'team.member.avatar': 'Avatar',
  'team.member.role': 'Role',
  'team.member.role.owner': 'Owner',
  'team.member.role.member': 'Member',
  'team.member.status': 'Status',
  'team.member.status.joined': 'Joined',
  'team.member.status.pending': 'Invited',
  'campaign.trackingUrl': 'Install page link',
  'campaign.no.games': 'No games to create campaigns for :(',
  'campaign.availableInfluencers': 'Creators available for this campaign',
  'campaign.availableInfluencers.verifyFirst':
    'You can run this campaign on Matchmade as soon as we set up a contract with you. Our sales team will email you shortly. If you have questions, please contact {email}',
  'campaign.participatingInfluencers': 'Creators participating in this campaign',
  'campaign.create': 'Create a new campaign',
  'campaign.oldDraftFound':
    'Looks like you have an unfinished campaign draft{name}: would you like to continue where you left off, or start a new one?',
  'campaign.restartCreate': 'Create from scratch',
  'campaign.create.continue': 'Continue draft',
  'campaign.edit': 'Edit campaign',
  'campaign.print': 'Print',
  'campaign.report': 'Advanced analytics',
  'campaign.details': 'Campaign details',
  'campaign.promotionTypes.title': 'Promotion type',
  'campaign.promotionTypes.defaultPromoTypeTitle': 'Default promotion type',
  'campaign.promotionTypes.dedicated': 'Dedicated',
  'campaign.promotionTypes.youtube.dedicated.help': 'Video features only your game',
  'campaign.promotionTypes.twitch.dedicated.help': 'Stream features only your game',
  'campaign.promotionTypes.integration': 'Integration',
  'campaign.promotionTypes.youtube.integration.help':
    'Video includes short promotional clip of your game',
  'campaign.promotionTypes.twitch.integration.help':
    'Stream includes a promotional part dedicated to your game',
  'campaign.sections.cpi': 'CPI',
  'campaign.sections.all': 'All In-Network',
  'campaign.sections.emptyCollection': 'This collection is currently empty. ',
  'campaign.sections.emptyCollection.dynamic': `We couldn't find any channels with the current search criteria. Please try with a different one. `,
  'campaign.sections.emptyCollection.dynamic.refresh':
    'Please hold tight, matching in progress. Come back soon!',
  'campaign.sections.participants': 'Participating',
  'campaign.sections.negotiations': 'Negotiating',
  'campaign.sections.description': 'Description',
  'campaign.game.placeholder': 'Select game to create campaign for...',
  'campaign.name': 'Name',
  'campaign.name.placeholder': '{title}',
  'campaign.description.title': 'Campaign description',
  'campaign.description.contentPlatform': 'Additional information',
  'campaign.description.requirements.title': 'Requirements and restrictions',
  'campaign.description.requirements.subtitle':
    'List of all the requirements and restrictions for the content',
  'campaign.description.talkingPoints.title': 'Talking points',
  'campaign.description.talkingPoints.subtitle':
    'All the things that the creators should mention about your product',
  'campaign.description.charity.title': 'For a good cause',
  'campaign.description.charity.subtitle':
    'You agree to participate in this charity campaign free of charge.',
  'campaign.description.public': 'Description',
  'campaign.description.placeholder':
    'Campaign description and instructions to creators for making videos. Supports Markdown.',
  'campaign.description.youtube': `- Provide a short text with the tracking link (in the description) to encourage your audience to download. Anything you can do to highlight this link will help - even mention it in your video or add a graphic that points to it.
- Do not post the tracking link anywhere else than your YouTube channel.`,
  'campaign.description.twitch': `- You must turn on the “Store Past Broadcasts” option in your Twitch channel settings, or locally record your broadcast. After your stream, highlight your sponsored content in your VOD for submission to Matchmade.
- When your scheduled campaign is starting, please change your stream title to include #sponsored or #ad tag within it in accordance with FTC guidelines. This tag must remain in your title during the entire duration of the campaign.
- You must verbally state that you’re initiating a block of sponsored content and in turn, let people know when the campaign is ending.
- Mention the advertiser in both the beginning and ending of the campaign.
- Please set your stream up with a “mature warning” filter (this is in your Twitch settings) if need be for a campaign.
- Do not post the tracking link anywhere else than your Twitch channel.`,
  'campaign.description.default.youtube': `- Send your unlisted video for review via chat and wait for the approval before publishing it. You need to do this at least 7 days before the agreed publishing date.
- Mention the name of the product at the beginning and at the end of your video.
- Include the provided tracking URL in the top part of the video description.
- Provide some context to the tracking URL in the description.
- Please refrain from needless bad-mouthing of the content and advertiser but be honest and constructive with any criticism you may have.`,
  'campaign.description.default.instagram': `- Send your unlisted Instagram content for review via chat and wait for the approval before publishing it. You need to do this at least 7 days before the agreed publishing date.
- Mention the name of the product at the beginning and at the end of your Instagram content.
- Include the provided tracking URL within your story to enable swipe up.
- Provide some context to the tracking URL in the description.
- Please refrain from needless bad-mouthing of the content and advertiser but be honest and constructive with any criticism you may have.
- Add the swipe-up link in every stories (even if the call to action is only in the last story)
- Highlight the stories for 1 week or more if possible
- Add the tracking link in the IG profile description (for few days / weeks / more)
- Tag the advertiser on both Stories and Post.`,
  'campaign.description.default.twitch': `- Do not post the tracking link anywhere else than on your channel (eg. social media).  You need written approval from the sponsor to post the tracking link on any other channel than agreed.
- After a sponsored stream/video, you are not allowed to rebroadcast your campaign on another platform.
- We encourage you to promote the sponsored content on your social media channels content once your broadcast/video is live (social posts also require an #sponsored or #ad tag), remember to include a link to your channel within the post. But do not post the tracking link mentioned above. Link to your channel = yes! Tracking link to download = NO!
- Please refrain from needless bad-mouthing of the content and advertiser but be honest and constructive with any criticism you may have.`,
  'form.import': 'Import',
  'form.change': 'Change',
  'campaign.influencer.add': 'Add to campaign',
  'campaign.influencer.remove': 'Remove from campaign',
  'campaign.influencer.outOfNetworkNoInvite': "Out of network, can't invite",
  'campaign.influencer.resendInvite': 'Resend',
  'campaign.influencer.adding': 'Adding creator to campaign',
  'campaign.influencer.participating': 'Participating',
  'campaign.influencer.negotiating': 'Negotiating',
  'campaign.influencer.invite': 'Invite',
  'campaign.influencer.invited': 'Invited',
  'campaign.influencer.trackingUrl': 'Install page URL',
  'campaign.influencer.setCommissionRate': 'Set commission rate',
  'campaign.input.pleaseCheckCpi': 'Your CPI has changed, please check it again',
  'campaign.guidelines.title': 'Tips & suggestions',
  'campaign.guidelines': `
Creators are the experts of their own audience and know best what their subscribers will want to watch. You'll run the most successful influencer campaigns if you allow the influencer creative freedom. Do give some guidance, but keep it to a minimum. Here are a few points to consider:

- Timing - when do you want the game mentioned?
- How do you want the influencer to present your game? (Gameplay, icon, promotional asset, just a callout.)
- Anything they should not mention? (eg. a competitors' games)
- Are the influencers allowed to use mature language?
- Any terminology you want them to use? (MOBA, FPS, RPG, AR…)
- What’s the call-to-action?

  `,
  'campaign.kpi.chart.install.label': 'Installs',
  'campaign.kpi.chart.view.label': 'Views',
  'campaign.kpi.chart.install.summary': '{installs, plural, one {install} other {installs}}',
  'campaign.kpi.chart.view.summary': '{views, plural, one {view} other {views}}',
  'campaign.kpi.chart.cost.label': 'Cost',
  'campaign.kpi.chart.cost.summary': 'total cost',
  'campaign.kpi.chart.payout.summary': 'total payout',
  'campaign.kpi.chart.conversion.summary': 'conversion rate',
  'campaign.kpi.chart.loading': 'Fetching campaign performance stats...',
  'campaign.kpi.table.loading': 'Fetching creators breakdown stats...',
  'campaign.kpi.table.header.agreementId': '#',
  'campaign.kpi.table.header.channel': 'Channel',
  'campaign.kpi.table.header.promotionType': 'Promotion type',
  'campaign.kpi.table.header.contentPlatform': 'Content platform',
  'campaign.kpi.table.header.install': 'Installs',
  'campaign.kpi.table.header.avgCcv': 'Avg. ccv',
  'campaign.kpi.table.header.peakCcv': 'Peak ccv',
  'campaign.kpi.table.header.click': 'Clicks',
  'campaign.kpi.table.header.view': 'Views',
  'campaign.kpi.table.header.cost': 'Cost',
  'campaign.kpi.table.header.payout': 'Payout',
  'campaign.kpi.table.header.conversionRate': 'Conversion',
  'campaign.offer.platform': 'Platform',
  'campaign.offer.promotion': 'Promotion',
  'campaign.offer.promotion.dedicated': 'Dedicated',
  'campaign.offer.promotion.integrated': 'Integrated',
  'campaign.offer.type': 'Offer type',
  'campaign.offer.deal': 'Deal',
  'campaign.offer.deal.fixedFee': 'Fixed fee',
  'campaign.offer.deal.freeOfCharge': 'Free of charge',
  'campaign.offer.deal.cpm': 'CPM',
  'campaign.offer.deal.cpi': 'CPI',
  'youtube.search.keywords.addKeywordPlaceholder': 'Custom keywords',
  'youtube.search.keywords.removeAll': 'Remove all',
  'youtube.search.setting.allChannels': 'All channels',
  'youtube.search.setting.onlyInNetwork': 'Only in-network',
  'youtube.search.setting.onlyOutOfNetwork': 'Only out-of-network',
  'youtube.search.setting.videoFiltersTitle': 'Video filters',
  'youtube.search.setting.matchingContentMinVideoCount':
    'Minimum videos matching selected games, tags and topic categories',
  'youtube.search.setting.channelFiltersTitle': 'Channel filters',
  'youtube.search.setting.minViews': 'Minimum views',
  'youtube.search.setting.minSubscribers': 'Minimum subscribers',
  'youtube.search.setting.maxSubscribers': 'Maximum subscribers',
  'youtube.search.setting.minEngagementRatio': 'Minimum engagement',
  'youtube.search.setting.channel30dMinAvgViews': 'Min. average views',
  'youtube.search.setting.channel30dMaxAvgViews': 'Max. average views',
  'youtube.search.setting.channel30dMinAvgEngagementRatio': 'Min. average engagement',
  'youtube.search.setting.channel30dMaxAvgEngagementRatio': 'Max. average engagement',
  'youtube.search.setting.publishedAfter': 'Videos newer than',
  'youtube.search.setting.publishedBefore': 'Videos older than',
  'youtube.search.setting.searchChannelName': 'Search by channel name',
  'youtube.search.setting.demographicsTargetCountriesMinThreshold':
    'Min. threshold for audience from target countries',
  'youtube.search.setting.demographicsGenderTypeMinThreshold': 'Min. threshold for audience gender',
  'youtube.search.setting.demographicsAgeGroupsMinThreshold':
    'Min. threshold for audience from target age groups',
  'youtube.search.setting.demographicsOperatingSystemMinThreshold':
    'Min. threshold for audience from target operating systems',
  'youtube.search.error.timeout': 'Timeout error, please retry the search',
  'youtube.search.error.retry': 'Retry',
  'campaign.agreement.hasMadeANewOffer': '{actor} has made a new offer',
  'campaign.agreement.hasDeclinedYourOffer': '{actor} has declined your offer',
  'campaign.agreement.hasWithdrawnTheirOffer': '{actor} has withdrawn their offer',
  'campaign.agreement.refreshData': 'Refresh',
  'campaign.agreement.decline.reason': 'Reason',
  'campaign.agreement.decline.popupHeader': 'Are you sure you want to decline the offer?',
  'campaign.agreement.decline.infoMessageForPublisher': 'This feedback will be seen by the creator',
  'campaign.agreement.decline.infoMessageForInfluencer':
    'This feedback will be seen by the advertiser. Please note that if you decline you won’t be able to rejoin this campaign.',
  'campaign.agreement.decline.influencerAudienceInappropriate':
    "Creator's audience doesn't match our target audience",
  'campaign.agreement.decline.priceHigh': 'The price is too high',
  'campaign.agreement.decline.channelSmall': 'The channel is too small',
  'campaign.agreement.decline.channelBig': 'The channel is too big',
  'campaign.agreement.decline.influencerContentInappropriate':
    'The content of the channel is not adequate',
  'campaign.agreement.decline.publisherContentInappropriate':
    "The brand/product doesn't match the content on my channel",
  'campaign.agreement.decline.priceLow': 'The price is too low',
  'campaign.agreement.decline.publisherDealInappropriate': 'The deal type is not adequate',
  'campaign.agreement.decline.campaignNotAvailable': 'Campaign is no longer available',
  'campaign.agreement.decline.otherReasons': 'Other reasons',
  'campaign.agreement.decline.enterText': 'Enter text',
  'campaign.card.info.by': 'by {publisherName}',
  'campaign.card.estimatedEarnings': 'Estimated earnings',
  'campaign.card.earnings': 'Earnings',
  'campaign.card.noScreenshots': 'No screenshots available :(',
  'campaign.card.fillContactToJoin': 'Fill your contact info to join this campaign',
  'form.create': 'Create',
  'form.edit': 'Edit',
  'form.preview': 'Preview',
  'form.cancel': 'Cancel',
  'form.save': 'Save',
  'form.add': 'Add',
  'form.search': 'Search',
  'form.clear': 'Clear',
  'form.delete': 'Delete',
  'form.invite': 'Invite',
  'form.close': 'Close',
  'form.send': 'Send',
  'form.remove': 'Remove',
  'form.verify': 'Verify',
  'title.influencer.dashboard': 'My dashboard',
  'title.influencer.register': 'Connect your channel',
  'title.publisher.dashboard': 'My dashboard',
  'title.publisher.register': 'Connect your account',
  'title.publisher.createCampaign': 'New campaign',
  'tos.title': 'Terms of Service',
  'tos.acceptTerms': 'Accept terms',
  'tos.agree': 'Agree',
  'tos.informationText1':
    'Please take a moment to read through the terms of service. Agreeing to the terms of service is required to use Matchmade.',
  'tos.informationText2': 'I accept and agree to the {link}.',
  'tos.intro':
    'By joining a campaign, you agree to all of the following. We’ve provided easy-to-understand summaries for each section, but you need to read and agree to the full agreement. We may modify any of the terms and conditions of this agreement at any time by providing you with a notification by email.',
  'tos.allGood': 'You already accepted terms of service',
  'tos.participation.header': 'Campaign participation',
  'tos.participation.description':
    'You agree to the campaign-specific pricing and terms when you accept to participate in a campaign.',
  'tos.participation.explanation':
    'Participation in campaigns requires both you and the advertiser to expressly agree to your participation. When you propose participating or agree to participate in a campaign, you expressly agree to these terms as well as any other terms and / or pricing specific to that campaign.',
  'tos.installs.header': 'Qualified installs',
  'tos.installs.description':
    'If you have agreed to a CPI deal, you will only be paid for real installs by real people',
  'tos.installs.explanation':
    'Payments are based on qualified installs. A qualified install is defined as an individual person who (i) accesses the application distribution service (Google Play or Apple App Store) through the partner’s campaign tracking link, (ii) installs and launches the promoted application directly as a result of following the partner’s campaign tracking link while connected to the Internet, (iii) is not a robot, script, or otherwise computer generated, (iv) is not determined by {whitelabel} or the advertiser later to be fraudulent, unqualified or a duplicate.',
  'tos.payments.header': 'Payments',
  'tos.payments.description':
    'You will be paid on the first working day of each month if your balance is over $100.',
  'tos.payments.explanation':
    'You will be paid the previous month’s account balance on the first business day of each month, provided that your account balance is over $100. If your balance is less than this, it will roll over to the next month. If any previously paid qualified installs are later determined to not meet the criteria for qualified installs, these will be deducted from your account.\n\nPayments for campaigns you’ve participated in will be added to your account once they have been paid by the advertiser and received by {whitelabel}. You release {whitelabel} from any claims to these payments if {whitelabel} hasn’t received the funds from the advertiser.',
  'tos.content.header': 'Content',
  'tos.content.description':
    'The content remains yours, and is your responsibility. No nasty or illegal stuff.',
  'tos.content.explanation':
    'You are responsible for the development, delivery and maintenance of all of your content connected to a campaign. This includes ensuring that all materials connected to the content or the campaign are legal, do not infringe on any intellectual property or other rights, do not contain or link to any material which is harmful, threatening, defamatory, obscene, sexually explicit, harassing, promoting violence, promoting discrimination, promoting illegal activities, or otherwise considered objectionable by {whitelabel} or the advertiser. You also agree to abide by any other content requirements as set by the advertiser.\n\nYou maintain the ownership and copyright of all the content you produce. Furthermore, you guarantee that all the content produced in connection to a campaign is your original work and has not previously been published or licensed and that you own or control all the rights to it. You are responsible for any permissions or payments related to the content you produce.',
  'tos.fraud.header': 'Fraud',
  'tos.fraud.description': 'Fraud is grounds for immediate account termination with no payment.',
  'tos.fraud.explanation':
    'Any fraudulent means of obtaining installs are grounds for immediate termination of your account and participation in campaigns. If your account is terminated on the basis of fraud, you will not receive any of the unpaid on your account.',
  'tos.disclosure.header': 'Disclosure',
  'tos.disclosure.description':
    'You will clearly disclose promotional content to your audience per FTC guidelines.',
  'tos.disclosure.explanation':
    'You agree to follow FTC and other guidelines and laws relevant to promotional content. You will clearly disclose any material connection between you and the advertiser.\n\nFailure to abide by the FTC endorsement disclosure guidelines can result in immediate termination of your account.',
  'tos.publicity.header': 'Publicity rights',
  'tos.publicity.description': 'We can mention we work with you.',
  'tos.publicity.explanation':
    'You agree that we may use your artist name, likeness, voice and biography to promote {whitelabel}.',
  'tos.liability.header': 'Limitation of Liability',
  'tos.liability.description': "We are not responsible for things we can't control.",
  'tos.liability.explanation':
    'In no event shall {whitelabel} be liable for any unavailability or inoperability of the links, program web sites, technical malfunction, computer error, corruption or loss of information, or other injury, damage or disruption of any kind beyond the reasonable control of {whitelabel}. In no event will {whitelabel} be liable for any indirect, incidental, consequential, personal injury/wrongful death, special or exemplary damages, including but not limited to, loss of profits or loss of business opportunity, even if such damages are foreseeable and whether or not {whitelabel} has been advised of the possibility thereof.',
  'tos.dataUse.header': 'Data use',
  'tos.dataUse.description': 'We use data about your channel to find the best campaigns for you.',
  'tos.dataUse.explanation':
    'We offer campaigns to you based on the content that you produce and the kind of audience you have. Campaign participation requires the advertiser to get to know you, your channel (YouTube, Twitch, Instagram, etc.) and your audience.',
  'tos.dataOwnership.header': 'Data ownership',
  'tos.dataOwnership.description': 'Your data is yours.',
  'tos.dataOwnership.explanation':
    'We only collect and use data that is necessary for us to find relevant campaigns for you. We keep this data up-to-date and store it in a secure way. Data is shared only with advertisers who we think are a good match for your channel. We never sell your data to anyone.',
  'tos.dataRetention.header': 'Data retention',
  'tos.dataRetention.description': 'We’ll remove data when it’s no longer needed.',
  'tos.dataRetention.explanation':
    'We only store data about your channel for as long as you use {whitelabel}. You can contact us if you’d like to stop using {whitelabel}. We only store data about you until we’re no longer obliged to do so for payment, tax or other legal reasons.',
  'tos.age.header': 'Age',
  'tos.age.description': 'You need to be over 16.',
  'tos.age.explanation':
    'If you are under 16, you need to ask your parent or guardian for permission to use {whitelabel}.',
  'tos.other.header': 'Other terms',
  'tos.other.description': 'We use YouTube API Services to find sponsorships for you',
  'tos.other.explanation':
    'We use YouTube API Services to access video performance data and audience information so we can find relevant campaigns for you. By agreeing to our terms of service you are also bound by the YouTube Terms of Service (https://www.youtube.com/t/terms) and Google Privacy Policy (http://www.google.com/policies/privacy).',
  brand: 'Matchmade',
  'daterangepicker.start': 'Start',
  'daterangepicker.end': 'End',
  'daterangepicker.to': 'to',
  'admin.mturk.collection': 'Collection',
  'admin.mturk.sendRequest': 'Send email requests to MTurk',
  'admin.mturk.sentRequestWithDate': 'Email requests sent to MTurk {date}',
  'admin.mturk.description':
    'This page lists all the creators in this collection. You can request MTurk to search for their email addresses',
  'admin.mturk.noEmail': 'No email',
  'admin.mturk.status.finished': 'Finished',
  'admin.mturk.status.pending': 'Pending',
  'admin.mturk.table.header.channel': 'Channel',
  'admin.mturk.table.header.channelId': 'Channel id',
  'admin.mturk.table.header.task': 'MTurk task',
  'admin.mturk.table.header.30dAvgViews': '30d Avg Views',
  'admin.mturk.table.header.emailAddress': 'Email address',
  'admin.collections.table.header.id': 'Id',
  'admin.collections.table.header.name': 'Collection name',
  'admin.collections.table.header.team': 'Team',
  'admin.collections.table.header.influencers': '# of Creators',
  'admin.collections.table.header.created': 'Created',
  'admin.collections.table.header.lastRelatedChannelsUpdated': 'Last channels update',
  'admin.collections.table.duplicate.collectionName': 'Duplicate collection {value}',
  'admin.collections.table.duplicate.placeholder': 'Enter the new name of the collection',
  'admin.collections.table.duplicate.asStatic':
    'Would you like to duplicate this dynamic collection as a static?',
  'admin.accounts.table.header.id': 'Id',
  'admin.accounts.table.header.name': 'Name',
  'admin.accounts.table.header.teams': 'Teams',
  'admin.accounts.table.header.games': 'Games',
  'admin.accounts.table.header.email': 'Email',
  'admin.accounts.table.header.contactInfo': 'Contact info',
  'admin.accounts.table.header.verified': 'Verified',
  'admin.accounts.table.header.created': 'Joined',
  'admin.accounts.table.header.lastLogin': 'Login',
  'admin.accounts.table.header.tosAccepted': 'TOS',
  'admin.accounts.table.header.language': 'Language',
  'admin.accounts.table.header.country': 'Country',
  'admin.accounts.table.header.delete': 'Delete',
  'admin.accounts.table.header.agency': 'Agency',
  'admin.accounts.table.automaticallyVerified': 'Automatically verified:\n{reason}',
  'admin.accounts.table.manuallyVerified': 'Manually verified',
  'admin.influencers.table.includeYoutube': 'YouTube channels',
  'admin.influencers.table.includeTwitch': 'Twitch channels',
  'admin.influencers.table.includeInstagram': 'Instagram channels',
  'admin.influencers.table.includeBilibili': 'Bilibili channels',
  'admin.influencers.table.includeYoutubeCampaign': 'YouTube campaigns',
  'admin.influencers.table.includeTwitchCampaign': 'Twitch campaigns',
  'admin.influencers.table.includeMock': 'Manually imported creators',
  'admin.accounts.verify.selectOrCreateTeam': 'Select an existing team or create a new one',
  'admin.accounts.verify.createTeam': 'Create a new team "{name}"',
  'admin.accounts.noInfluencers': 'There are no creators yet',
  'admin.accounts.noPublishers': 'There are no advertisers yet',
  'admin.accounts.noCampaigns': 'There are no campaigns yet',
  'admin.campaigns.table.header.id': 'Id',
  'admin.campaigns.table.header.name': 'Name',
  'admin.campaigns.table.header.game': 'Game',
  'admin.campaigns.table.header.team': 'Team',
  'admin.campaigns.table.header.created': 'Created',
  'admin.campaigns.table.header.installs': 'Installs',
  'admin.campaigns.table.header.clicks': 'Clicks',
  'admin.campaigns.table.header.views': 'Views',
  'admin.campaigns.table.header.cost': 'Cost to adv.',
  'admin.campaigns.table.header.payout': 'Payout to inf.',
  'admin.campaigns.table.header.platforms': 'Platforms',
  'admin.campaigns.table.header.attribution': 'Attr. partner',
  'admin.campaigns.table.header.visibility': 'Visible',
  'admin.campaigns.table.header.commission': '%',
  'admin.campaign.modal.sendEmailToInfluencers.button': "Yes, I'm sure",
  'admin.allocatedBudget.table.header.id': 'Id',
  'admin.allocatedBudget.table.header.name': 'Name',
  'admin.allocatedBudget.table.header.team': 'Team',
  'admin.allocatedBudget.table.header.daysCreated': 'Days created',
  'admin.allocatedBudget.table.header.totalBudget': 'Total budget',
  'admin.allocatedBudget.table.header.allocatedBudget': 'Allocated budget',
  'admin.allocatedBudget.table.header.unallocatedBudget': 'Unallocated budget',
  'admin.allocatedBudget.table.header.allocatedOfTotal': 'Allocated of total',
  'admin.allocatedBudget.table.header.inNegotiationOfTotal': 'Negotiated of total',
  'admin.allocatedBudget.table.header.numOfConfirmedDeals': 'Confirmed deals',
  'admin.allocatedBudget.table.header.numOfNegotiatedDeals': 'Negotiated deals',
  'admin.allocatedBudget.table.header.budgetInNegotiation': 'Negotiated budget',
  'admin.allocatedBudget.table.header.pendingOfPublisherApproval': 'Pending on publisher',
  'admin.allocatedBudget.table.header.pendingOfInfluencerApproval': 'Pending on creator',
  'admin.allocatedBudget.table.header.numOfPendingConditionalOffers': 'Pending conditional offers',
  'admin.collections.addTwitchChannels.channelListPlaceholder':
    'Please provide id list of channels you want to add to',
  'admin.collections.addTwitchChannels.nonAddedChannels': `The following channels were not added because they don't exist in our system: `,
  'admin.collections.addTwitchChannels.success': 'All good!',
  'admin.collections.addTwitchChannels.title': 'Add Twitch channels to collection',
  'admin.collections.addTwitchChannels.goToCollection': 'Go to collection',
  'admin.collections.addInstagramChannels.channelListPlaceholder':
    'Please provide a list of channel URLs you want to add',
  'admin.collections.addInstagramChannels.nonAddedChannels': `The following channels were not added because we could not find them: `,
  'admin.collections.addInstagramChannels.success': 'All good!',
  'admin.collections.addInstagramChannels.title': 'Add Instagram channels to collection',
  'admin.collections.addInstagramChannels.goToCollection': 'Go to collection',
  'admin.collections.addInstagramChannels.channelsLabel': 'Channels',
  'admin.collections.addInstagramChannels.searchButton': 'Search',
  'admin.collections.addInstagramChannels.importButton': 'Import',
  'admin.collections.addInstagramChannels.addToCollectionButton': 'Add to collection',
  'admin.collections.addInstagramChannels.selectCollectionPlaceholder': 'Select collection',
  'admin.collections.addInstagramChannels.helpInfo': 'Please provide list of IG channel URLs',
  'admin.collections.addInstagramChannels.noChannels': 'No channels',
  'admin.collections.addChannels.channelListPlaceholder':
    'Please provide id list of channels you want to search for',
  'admin.collections.addChannels.title': 'Add Youtube channels to collection',
  'admin.collections.addChannels.noChannels': 'No channels found',
  'admin.collections.addChannels.importButton': 'Search',
  'admin.collections.addChannels.addToCollectionButton': 'Add to collection',
  'admin.collections.addChannels.selectCollectionPlaceholder': 'Select collection',
  'admin.collections.addChannels.helpInfo':
    'Please provide list of channel ids in comma-separated or space separated way. The search is limited to 300 channels.',
  'admin.collections.addChannels.channelsLabel': 'Channels',
  'admin.collections.create.youtubeTitle': 'Combine Youtube collections',
  'admin.collections.create.instagramTitle': 'Combine Instagram collections',
  'admin.collections.create.newCollectionName': 'New collection name',
  'admin.collections.create.includedCollections': 'Included collections',
  'admin.collections.create.excludedCollections': 'Excluded collections',
  'admin.collections.create.channelsPreview': 'Channels preview',
  'admin.collections.create.noChannels': 'No channels',
  'admin.collections.create.createCollection': 'Create collection',
  'admin.collections.create.successfully': 'Collection {link} [{id}] successfully created',
  'admin.teams.table.header.id': 'Id',
  'admin.teams.table.header.name': 'Name',
  'admin.teams.table.header.members': 'Members',
  'admin.teams.table.header.invites': 'Invites',
  'admin.teams.table.header.created': 'Created',
  'admin.managerTeams.table.header.id': 'Id',
  'admin.managerTeams.table.header.name': 'Name',
  'admin.managerTeams.table.header.members': 'Members',
  'admin.managerTeams.table.header.created': 'Created',
  'admin.managerTeams.table.removeManagedChannel': 'Remove managed channel from the team',
  'admin.teams.table.header.trialEnded': 'Trial',
  'pagination.nextPage': 'Next page',
  'pagination.prevPage': 'Previous',
  'pagination.pageLimit': '{count} per page',
  'chat.loading': 'Loading messages...',
  'chat.loadingCampaign': 'Fetching campaign data...',
  'chat.send': 'Send a message',
  'chat.failedToSend': 'Failed to send message, please try again.',
  'chat.messagesDisabled': 'Messages are disabled',
  'chat.negotiation.offer.cpi': ' · CPI {value}',
  'chat.negotiation.offer.cpm': ' · CPM {value}',
  'chat.negotiation.offer.fixedFee': ' · Fixed fee {value}',
  'chat.negotiation.offer.freeOfCharge': ' · Free of charge',
  'chat.negotiation.offer.minMaxPayment':
    ' · {minGuarantee, select, 0 {No minimum guarantee} other {Minimum guarantee {minGuarantee}}} · {maxPayment, select, 0 {No max payment} other {Max payment {maxPayment}}}',
  'chat.negotiation.invitedToJoin': '{actor} invited creator with the offer:',
  'chat.negotiation.changedDeadline': '{actor} changed the publishing date to: ',
  'chat.negotiation.waitingForApproval': '{actor} wants to join campaign and made a new offer:',
  // Negotiation, Declined & withdrew messages are the same now, but duplicated for the sake of simplicity of picking the message by role
  'chat.negotiation.influencerManagerSetCpi': '{actor} made a new offer:',
  'chat.negotiation.influencerSetCpi': '{actor} made a new offer:',
  'chat.negotiation.publisherSetCpi': '{actor} made a new offer:',
  'chat.negotiation.influencerManagerChangedCpi': '{actor} changed the offer:',
  'chat.negotiation.influencerChangedCpi': '{actor} changed the offer:',
  'chat.negotiation.publisherChangedCpi': '{actor} changed the offer:',
  'chat.negotiation.influencerDeclined': '{actor} declined',
  'chat.negotiation.influencerWithdrew': '{actor} withdrew the offer',
  'chat.negotiation.publisherDeclined': '{actor} declined',
  'chat.negotiation.publisherWithdrew': '{actor} withdrew the offer',
  'chat.negotiation.settled': '{actor} accepted the offer:',
  'chat.negotiation.cancelled': 'Deal has been cancelled. Sorry.',
  'chat.negotiation.updatedOffer': 'The offer has been updated',
  'chat.publishedVideo.videoIsPublic': '{actor} published video',
  'chat.publishedVideo.videoIsReadyForReview': '{actor} created video for review',
  'chat.campaignInfo.footerTitle': 'Always work through us. ',
  'chat.campaignInfo.footerTextForPublisher':
    'To ensure your safety, manage all your finances and communications inside the Matchmade app. Please note that creators will see different prices than you. Our app automatically deducts the commission from their prices.',
  'chat.campaignInfo.footerTextForInfluencer':
    'To ensure your safety, manage all your finances and communications inside the Matchmade app. Please note that advertisers will see different prices than you. Our app automatically adds our commission on top of your earnings.',
  'chat.campaignInfo.needHelp': 'Need help?',
  'chat.actionBox.inviteInfluencer': 'Invite {influencer} to join the campaign',
  'chat.actionBox.joinCampaign': 'Join the campaign by making an offer',
  'chat.actionBox.latestOfferBy': 'Latest offer by {name}',
  'chat.actionBox.estimatedSpend': 'Estimated spend',
  'chat.actionBox.estimatedEarnings': 'Estimated earnings',
  'chat.actionBox.nextStep': 'Next step',
  'chat.actionBox.waitForInfluencerToPostVideo': 'Wait for {influencer} to publish a video',
  'chat.actionBox.deal': 'Deal {check}',
  'chat.actionBox.video': 'Video',
  'chat.actionBox.cpi': 'CPI {cpi}',
  'chat.actionBox.minGuaranteeHeader': 'Minimum guarantee',
  'chat.actionBox.minGuarantee': 'Minimum guarantee {value}',
  'chat.actionBox.fixedFeeHeader': 'Fixed fee',
  'chat.actionBox.fixedFee': 'Fixed fee {value}',
  'chat.actionBox.freeOfCharge': 'Free of charge',
  'chat.actionBox.releaseDate': 'Publishing date',
  /* TODO replace this with ^ once we have video reviews <FormattedMessage id="chat.actionBox.postYourVideo" />*/
  'chat.actionBox.postYourVideo':
    'Send the unlisted video link for review using message box below.', // 'Post your video',
  'chat.actionBox.useThisLinkInDescription': 'Use this link in video description',
  'chat.actionBox.campaignPerformance': 'Campaign performance',
  'chat.actionBox.campaignPerformance.cost': 'cost',
  'chat.actionBox.campaignPerformance.cost.amount': '{amount} cost',
  'chat.actionBox.campaignPerformance.payout': 'earnings',
  'chat.actionBox.campaignPerformance.payout.amount': '{amount} earnings',
  'chat.actionBox.campaignPerformance.views': '{views, plural, one {View} other {Views}}',
  'chat.actionBox.campaignPerformance.installs':
    '{installs, plural, one {Install} other {Installs}}',
  'chat.actionBox.campaignPerformance.conversionRate': 'conversion rate',
  'chat.messagesIconTextToOpenChatPage': 'Messages',
  'chat.campaignInfo.totalCost': 'Total cost up to date {cost}',
  'promotionTypes.title': 'Promotion type',
  'promotionTypes.dedicated': 'Dedicated',
  'promotionTypes.integration': 'Integration',
  'contentPlatforms.title': 'Content platform',
  'contentPlatforms.instagram': 'Instagram',
  'contentPlatforms.youtube': 'YouTube',
  'contentPlatforms.twitch': 'Twitch',
  'dealTypes.title': 'Deal type',
  'card.messagesTab.sendMessage': 'Send message',
  'card.messagesTab.reply': 'Reply',
  'card.messagesTab.latestMessage': 'Latest Message',
  'card.messagesTab.viewEarlierMessages': 'View earlier messages',
  'card.messagesTab.you': 'You',
  'card.messagesTab.footerText': '{senderName} · {created}',

  'tooltips.editSearch': 'Edit dynamic collection parameters',
  'tooltips.duplicate': 'Duplicate',
  'tooltips.delete': 'Delete',
  'tooltips.remove': 'Remove',
  'tooltips.add': 'Add',
  'tooltips.login': 'Login',
  'tooltips.edit': 'Edit',
  'tooltips.changeTeam': 'Change team',
  'tooltips.mturk': 'MTurk email requests',

  'tooltips.collectionTrigger.addChannel': 'Add {platform} channel to collection',

  'paymentInfo.freyja':
    "This page has been removed, you don't need to add your payment details upfront. After the deal has been done Matchmade will ask for those separately.",
  'paymentInfo.faq.link': 'please visit our FAQ section.',
  'paymentInfo.faq.description':
    'If you have any questions about payments and the information required from you, ',
  'paymentInfo.success': 'Saved successfully',
  'paymentInfo.publisher.title': 'Please fill in your company details',
  'paymentInfo.publisher.description':
    'We are thrilled to cooperate with you and hope you find or have already found creators from Matchmade that fit your campaign!',
  'paymentInfo.publisher.description2':
    'Please fill the form below with your company details so you can start working with creators. Invoices are sent once a month for the executed deals from the previous month.',
  'paymentInfo.publisher.label.companyName': 'Company name *',
  'paymentInfo.publisher.label.companyNumber': 'Identification Number',
  'paymentInfo.publisher.label.companyVat': 'VAT',
  'paymentInfo.publisher.label.companyEin': 'EIN',
  'paymentInfo.publisher.label.companyCountry': 'Country *',
  'paymentInfo.publisher.label.companyCity': 'City *',
  'paymentInfo.publisher.label.companyPostalCode': 'Postal code *',
  'paymentInfo.publisher.label.companyStreetAddress': 'Street address *',
  'paymentInfo.publisher.label.contactName': 'Contact name *',
  'paymentInfo.publisher.label.contactEmail': 'Contact email *',
  'paymentInfo.publisher.label.contactPhoneNumber': 'Contact phone number *',
  'paymentInfo.publisher.label.invoiceEmail': 'Email for invoices *',
  'paymentInfo.privacyPolicy': 'Privacy policy',
  'paymentInfo.policy.intro':
    'Matchmade is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested.',
  'paymentInfo.policy.aware':
    'I am aware that the information that I provided will be used and kept for payment purposes only by Matchmade.',
  'paymentInfo.policy.consent':
    'By clicking submit below, you consent to allow Matchmade to store and process the personal information submitted above.',
  'user.settings.revokeAccessTooltip.youtube':
    '⚠️ If you revoke YouTube access, we will lose access to your YouTube channel data. If you revoke access on all services, you will no longer be able to use Matchmade.',
  'user.settings.revokeAccessTooltip.instagram':
    '⚠️ If you revoke Instagram access, we will lose access to your Instagram channel data. If you revoke access on all services, you will no longer be able to use Matchmade.',
  'user.settings.revokeAccessTooltip.twitch':
    '⚠️ If you revoke Twitch access, we will lose access to your Twitch channel data. If you revoke access on all services, you will no longer be able to use Matchmade.',
  'user.settings.revokeAccessTooltip.default':
    '⚠️ If you revoke access, we will lose access to your channel data. If you revoke access on all services, you will no longer be able to use Matchmade.',
  'user.settings.revokeAccessModalTextBody.youtube':
    'You can revoke access to your YouTube account data in your Google Account settings.',
  'user.settings.revokeAccessModalTextBody.instagram':
    'You can revoke access to your Instagram account data in your Facebook Business Integrations settings.',
  'user.settings.revokeAccessModalTextBody.twitch':
    'You can revoke access to your Twitch account data in your Twitch settings.',
  'user.settings.revokeAccessModalTextBody.default':
    "You can revoke access to your account data in your platform's account settings.",
  'user.settings.revokeAccessModalText.youtube': 'Revoke YouTube access',
  'user.settings.revokeAccessModalText.instagram': 'Revoke Instagram access',
  'user.settings.revokeAccessModalText.twitch': 'Revoke Twitch access',
  'user.settings.revokeAccessModalText.default': 'Revoke access',
  'user.settings.revokeAccessModalButton': 'Open settings',

  'campaign.allocatedBudget.title': 'Campaign budget {value}',
  'campaign.allocatedBudget.allocatedBudget': 'Allocated {value}',
  'campaign.allocatedBudget.unallocatedBudget': 'Unallocated {value}',
  'campaign.allocatedBudget.negotiatedBudget': 'In negotiation {value}',
  'campaign.allocatedBudget.spentBudget': 'Spent {value}',
  'campaign.allocatedBudget.numOfPendingConditionalOffers': 'Number of conditional offers {value}',
  'campaign.report.tabs.campaignInfo': 'Campaign\ninformation',
  'campaign.report.tabs.statistics': 'Statistics',
  'campaign.report.tabs.channels': 'Channels',
  'campaign.report.tabs.videos': 'Videos',
  'campaign.report.tabs.glossary': 'Glossary',
  'campaign.report.info.title': 'Campaign information',
  'campaign.report.info.name': 'Campaign name',
  'campaign.report.info.platforms': 'Platforms',
  'campaign.report.info.tags': 'Tags',
  'campaign.report.info.dealType': 'Deal Type',
  'campaign.report.info.promotionType': 'Promotion type',
  'campaign.report.info.contentPlatform': 'Content platform',
  'campaign.report.info.attribution': 'Attribution',
  'campaign.report.info.campaignDescription': 'Campaign description',
  'campaign.report.info.restOfCountries': 'Rest of the countries',
  'campaign.report.info.defaultPromotionType': ' (Default)',
  'campaign.report.info.dedicatedDescription': 'The videos feature only your product',
  'campaign.report.info.integrationDescription':
    'The videos include a short promotional clip of your product',
  'campaign.report.info.defaultContentPlatform': ' (Default)',
  'campaign.report.info.youtubeDescription': 'Video published on YouTube',
  'campaign.report.info.twitchDescription': 'Live stream on Twitch',
  'campaign.report.info.noAttributionWarning':
    'The campaign has no install attribution configured, so install data is not available for this report.',
  'campaign.report.stats.title': 'Statistics',
  'campaign.report.stats.costs': 'Costs',
  'campaign.report.stats.totalCost': 'Total cost',
  'campaign.report.stats.cpi': 'Average CPI',
  'campaign.report.stats.ecpi': 'Average eCPI',
  'campaign.report.stats.cpm': 'Average CPM',
  'campaign.report.stats.installs': 'Installs',
  'campaign.report.stats.totalInstalls': 'Total installs',
  'campaign.report.stats.totalUnattributedInstalls': 'Estimated unattributed installs',
  'campaign.report.stats.totalAttributedInstalls': 'Attributed installs',
  'campaign.report.stats.totalClicks': 'Total clicks',
  'campaign.report.stats.engagement': 'All time views & engagement',
  'campaign.report.stats.totalEngagement': 'Total engagement',
  'campaign.report.stats.totalViews': 'Total views',
  'campaign.report.stats.likes': 'Likes',
  'campaign.report.stats.dislikes': 'Dislikes',
  'campaign.report.stats.comments': 'Comments',
  'campaign.report.stats.installsDemographics': 'Installs & demographics',
  'campaign.report.stats.android': 'Android',
  'campaign.report.stats.ios': 'iOS',
  'campaign.report.stats.others': 'Others',
  'campaign.report.stats.notSpecified': 'Not specified',

  'campaign.report.stats.byCountry': 'By top 5 countries',
  'campaign.report.stats.byPlatform': 'By platform',

  'campaign.report.videos.title': 'Videos',
  'campaign.report.videos.thumbnail': 'Thumbnail for YouTube channel {channelName}',

  'campaign.report.glossary.title': 'Glossary',
  'campaign.report.glossary.cpaTitle': 'CPA',
  'campaign.report.glossary.cpaMeaning': 'Cost per action',
  'campaign.report.glossary.cpaDescription': `‘A’ for action. Cost-per-action can mean many things and it's up to you to define the ‘A’. Common actions include an install + open, completion of the tutorial, completion of an in-game survey or in-app purchase.`,

  'campaign.report.glossary.cpiTitle': 'CPI',
  'campaign.report.glossary.cpiMeaning': 'Cost per install',
  'campaign.report.glossary.cpiDescription': `This is a metric to determine the all important cost of an attributed install.`,

  'campaign.report.glossary.ecpiTitle': 'eCPI',
  'campaign.report.glossary.ecpiMeaning': 'Effective cost-per-install',
  'campaign.report.glossary.ecpiDescription': `The effective cost-per-install determines the more accurate cost of your downloads, taking intro consideration both attributed and unattributed installs. It’s the total cost, divided by, the total amount of installs.`,

  'campaign.report.glossary.cpmTitle': 'CPM',
  'campaign.report.glossary.cpmMeaning': 'Cost per mille',
  'campaign.report.glossary.cpmDescription': `Cost per mille stands for the price for 1000 views within an agreed time window - Usually limited to 7 or 30 days. Tells you how many thousand people saw your sponsored video(s).`,

  'campaign.report.glossary.installTitle': 'Install',
  'campaign.report.glossary.installDescription': `Congrats! A player has downloaded your app using the provided link. The install is tracked and shown in both your attribution platform, and on Matchmade. Perfect!`,

  'campaign.report.glossary.unattributedInstallTitle': 'Unattributed install',
  'campaign.report.glossary.unattributedInstallDescription': `Matchmade campaigns also drive a boost in unattributed installs. Typically, many viewers of the sponsored video don't click on the provided install link, but instead, go directly to the app store, causing a spike in unattributed installs. They should always be taken into account when calculating your eCPI which is necessary to understand the true value of your campaign.`,

  'campaign.report.glossary.effectiveReachTitle': 'Effective reach',
  'campaign.report.glossary.effectiveReachDescription': `The total amount of views for the sponsored video(s).`,

  'campaign.report.glossary.engagement': 'Engagement',
  'campaign.report.glossary.engagementDescription': `Engagement - where the magic happens! This is typically used as a way of measuring the quality of a channel. Matchmade takes into account subscribers, comments, likes, dislikes and more over time. A channel might have many subs, but low engagement - or vice versa.`,

  'campaign.report.glossary.promotionTypes': 'Promotion types',
  'campaign.report.glossary.promotionTypesDescription':
    'There are two promotion types: dedicated and integrated.',
  'campaign.report.glossary.dedicated':
    'A dedicated video is only about your product. The sponsored creator makes a video that focuses on your product only.',
  'campaign.report.glossary.integrated':
    'An integrated video means that your product is mentioned/shown in a video while other content/products are being played. This is a great way to reach the creator’s core audience.',

  'campaign.report.channels.title': 'Channels',
  'campaign.report.channels.participating': 'Participating channels and costs',
  'campaign.report.channels.channel': 'Channel',
  'campaign.report.channels.subscribers': 'Subscribers',
  'campaign.report.channels.promotionType': 'Promotion type',
  'campaign.report.channels.cpi': 'CPI',
  'campaign.report.channels.cpm': 'CPM',
  'campaign.report.channels.minGuarantee': 'Minimum guarantee or fixed fee',
  'campaign.report.channels.cost': 'Cost',

  'campaign.report.channels.channelComparison': 'Channel comparison',
  'campaign.report.channels.channelInformation': 'Channel information',

  'campaign.report.channels.channelUrl': 'Channel URL',
  'campaign.report.channels.youtubeUrl': 'https://youtube.com/channel/{channelId}',
  'campaign.report.channels.age': 'Age',
  'campaign.report.channels.country': 'Country',
  'campaign.report.channels.gender': 'Gender',
  'campaign.report.channels.platform': 'Platform',
  'campaign.report.channels.subscriber': 'Subscribers',
  'campaign.report.channels.totalViews': 'Total views',

  'table.searchPlaceholder': 'Search...',
  'lists.other': 'Other',
  success: 'Success!',

  'mockInfluencerPage.title': 'Import out-of-network creator',
  'mockInfluencerPage.demographicsInfoRequired': 'Please try to fill all demographics',
  'twitch.broadcasterType.partner': 'Partner',
  'twitch.broadcasterType.affiliate': 'Affiliate',
  'twitch.broadcasterType.mature': 'Mature content',
  'twitch.30dAvgCcv': '30d avg. concurrent views',
  'twitch.30dAvgCcv.short': 'Avg. CCV',

  'campaignCreation.title': 'Create campaign',
  'campaignCreation.step.campaignDescription': 'Campaign description',
  'campaignCreation.step.productSelection': 'Product selection',
  'campaignCreation.step.campaignDetails': 'Campaign details',
  'campaignCreation.step.next': 'Next',
  'campaignCreation.step.finish': 'Finish',
  'campaignCreation.step.back': 'Back',
  'campaignCreation.step.adminOnly': 'Admin only',

  'campaignCreation.selectCountry': 'Select country',

  'campaignCreation.adminOnly.commissionRate': 'Commission rate',
  'campaignCreation.adminOnly.commissionRate.notice':
    'The new commission rate will only affect future deals. If you want to change the commission rate for ongoing deals, you can do that in the admin tab on each creator card.',
  'campaignCreation.adminOnly.automatedWelcomeMessage': 'Automated welcome message',
  'campaignCreation.adminOnly.automatedWelcomeMessageFollowUp':
    'Automated welcome message follow up',
  'campaignCreation.adminOnly.automatedWelcomeMessageFollowUp.notice':
    "This message will be sent to the chat if after 24h there's no replies from the creator or the admin (other than the automated welcome message).",
  'campaignCreation.adminOnly.automatedWelcomeMessageSenderId': 'Automated welcome message sender',
  'campaignCreation.adminOnly.automatedApprovalMessage': 'Automated approval message',
  'campaignCreation.adminOnly.automatedApprovalMessage.notice':
    'You can include placeholder values for influencer name, publishing date (automatically formatted to month and day), and tracking link as {{account_name}}, {{publishing_date}}, {{promo_code}} and {{tracking_link}} in all automated messages.',

  'campaignCreation.campaignDescription.name': 'Public name',
  'campaignCreation.campaignDescription.name.tooltip':
    'Creators see the name of the campaign, so keep it short and descriptive.',
  'campaignCreation.campaignDescription.name.placeholder': 'Campaign name',

  'campaignCreation.campaignDescription.platform': 'Content platform',
  'campaignCreation.campaignDescription.platform.tooltip':
    'You can’t currently select creators on both YouTube and Twitch. Please set up separate campaigns for each platform.',
  'campaignCreation.campaignDescription.platform.notSupported': 'Not supported yet',

  'campaignCreation.campaignDescription.promotionTypes': 'Promotion',
  'campaignCreation.campaignDescription.promotionTypes.tooltip':
    'A dedicated video is only about your product, whereas an integration is a short promotion within a video that includes other content. Please select both if you’re uncertain.',
  'campaignCreation.campaignDescription.promotionTypes.disabled.tooltip':
    'Only applicable for YouTube campaigns',
  'campaignCreation.campaignDescription.promotionTypes.dedicated': 'Dedicated',
  'campaignCreation.campaignDescription.promotionTypes.integration': 'Integrated',

  'campaignCreation.campaignDescription.autopilot': 'Autopilot',
  'campaignCreation.campaignDescription.autopilot.tooltip':
    "Put your campaign on autopilot, and leave the time consuming parts to our platform. We will automatically allocate your budget to the best creators based on parameters you've defined. You will get to review all the content before it is published and you will always have a real-time view on campaign progress and performance.",
  'campaignCreation.campaignDescription.autopilot.on': 'On',
  'campaignCreation.campaignDescription.autopilot.off': 'Off',
  'campaignCreation.campaignDescription.autopilot.description': 'This is the description',

  'campaignCreation.advancedSettings': 'Advanced settings',

  'campaignCreation.campaignDescription.advancedSettings.intro':
    'Video review, talking points, requirements & restrictions',
  'campaignCreation.campaignDescription.advancedSettings.tooltip':
    "Creators are the experts of their own audience and you'll run the most successful campaigns if you allow them creative freedom. Give some guidance, but keep it to a minimum.",

  'campaignCreation.campaignDescription.advancedSetting.callToAction': 'Call-to-action',
  'campaignCreation.campaignDescription.advancedSetting.callToAction.tooltip':
    'What is the intended action for the viewers? Eg. download now, click the link, visit your website.',
  'campaignCreation.campaignDescription.advancedSetting.callToAction.placeholder': 'Enter text',

  'campaignCreation.campaignDescription.advancedSetting.talkingPoints': 'Talking points',
  'campaignCreation.campaignDescription.advancedSetting.talkingPoints.tooltip':
    'Anything the creator should mention about your product? Eg. genre, where to download, the best product on mobile etc. ',
  'campaignCreation.campaignDescription.advancedSetting.talkingPoints.placeholder':
    'What should the creator mention about your product?',

  'campaignCreation.campaignDescription.advancedSetting.videoReview': 'Video review',
  'campaignCreation.campaignDescription.advancedSetting.videoReview.tooltip':
    'Do you want to review the content before it’s published?',
  'campaignCreation.campaignDescription.advancedSetting.videoReview.tooltip.disabled':
    'Only applicable for YouTube campaigns',
  'campaignCreation.campaignDescription.advancedSetting.videoReview.yes': 'Yes',
  'campaignCreation.campaignDescription.advancedSetting.videoReview.no': 'No',

  'campaignCreation.campaignDescription.advancedSetting.restrictions':
    'Requirements and restrictions',
  'campaignCreation.campaignDescription.advancedSetting.restrictions.tooltip':
    'Any strict requirements or restrictions for the content?',
  'campaignCreation.campaignDescription.advancedSetting.restrictions.placeholder': 'Enter text',

  'campaignCreation.productSelection.vertical': 'Product',
  'campaignCreation.productSelection.vertical.game': 'Game',
  'campaignCreation.productSelection.vertical.app': 'App',
  'campaignCreation.productSelection.selectApp': 'Select app',
  'campaignCreation.productSelection.selectApp.placeholder': 'Enter app name',
  'campaignCreation.productSelection.selectGame': 'Select game',
  'campaignCreation.productSelection.selectGame.placeholder': 'Enter game name',
  'campaignCreation.productSelection.platform': 'Platform',
  'campaignCreation.productSelection.platform.tooltip':
    "You can't select both mobile and Steam due to their different tracking methods. Please set up separate campaigns for each platform.",
  'campaignCreation.productSelection.softLaunch': 'Soft launch',
  'campaignCreation.productSelection.softLaunch.tooltip':
    'If your product is in soft launch, please select a country where it is available.',
  'campaignCreation.productSelection.selectCountry': 'Select country',
  'campaignCreation.productSelection.noResults':
    'The search produced no results. Please review the product name and the platform selection.',

  'campaignCreation.productSelection.notification.missingGameSelection':
    'Please make a selection in {platform} platform',
  'campaignCreation.productSelection.notification.missingVertical':
    'Please select either Game or App',

  'campaignCreation.campaignDetails.goals': 'Deal type',
  'campaignCreation.campaignDetails.goals.tooltip':
    'Is your goal installs (CPI) or views (CPM)? Please select both if you’re uncertain.',
  'campaignCreation.campaignDetails.goals.twitch.tooltip':
    'This is only for YouTube campaigns. Only fixed fee deals are available for Twitch at the moment.',
  'campaignCreation.campaignDetails.goals.steam.tooltip':
    'Only CPM deals are available for Steam campaigns at the moment.',
  'campaignCreation.campaignDetails.goals.views': 'CPM',
  'campaignCreation.campaignDetails.goals.installs': 'CPI',
  'campaignCreation.campaignDetails.budget': 'Budget',
  'campaignCreation.campaignDetails.budgetLock': 'Budget lock (experimental)',
  'campaignCreation.campaignDetails.budgetLockWarning':
    "The budget lock currently only works for some very few and specific use cases. Budget lock is not automatically applied for most scenarios. If you do not know exactly why you are adding this budget lock, you should assume it currently doesn't work!",
  'campaignCreation.campaignDetails.budget.placeholder': 'Enter your budget or leave this blank',
  'campaignCreation.campaignDetails.budgetLock.placeholder':
    'Enter the budget lock or leave this blank',
  'campaignCreation.campaignDetails.budget.flexible': 'Flexible budget',
  'campaignCreation.campaignDetails.budget.definite': 'Definite limit',

  'campaignCreation.campaignDetails.advancedSettingsIntro.twitch': 'Tracking methods',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution': 'Tracking',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.tooltip':
    'Select your data partner to run CPI/CPA-based deals.',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.codeOrUrl':
    'I have an Adjust code or URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.codeOrUrl.placeholder':
    'Enter code or URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.urlError':
    'If you use an Adjust URL, it must start with https://app.adjust.com/',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.appsflyer.none':
    'No need for URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.appsflyer.url':
    'I have one URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.appsflyer.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.buffpanel.url':
    'I have a Buffpanel URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.buffpanel.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.kochava.url':
    'I have a Kochava URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.kochava.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.tenjin.url':
    'I have a Tenjin URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.tenjin.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.tune.url': 'I have one URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.tune.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.singular.url':
    'I have a Singular URL',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.singular.platformSpecific':
    'I have different URLs for iOS and Android',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadeLink.label':
    'Enter the URL of your landing page or registration',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadeLink.instruction':
    'Warning. We redirect users to the above URL - no installs/purchases/registrations will be recorded on Matchmade.',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.label':
    'Enter the URL of your landing page or registration',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.instruction':
    'Add our tracking pixel to your sign up / registration confirmation page:',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.link':
    '<img height="1" width="1" style="display:none;" alt="" src="{trackingUrl}/t/{campaignId}/a.gif" />',

  'campaignCreation.campaignDetails.advancedSetting.installAttribution.other.label':
    'Name of attribution provider',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.other.instruction':
    ' We will check if it’s technically possible to use this platform and will get back to you soon. Email {email} for more.',
  'campaignCreation.campaignDetails.advancedSetting.installAttribution.none.instruction':
    'Without adding your data partner, you can only run fixed-fee deals.',
  'campaignCreation.campaignDetails.notification.lowBudget':
    'Uh-oh! We cannot do much with this low of a budget. Consider increasing your budget, or contact our customer support.',
  'multiCountrySelection.restOfTheWorld': 'Rest of the world',
  'urlControlInput.enterUrl': 'Enter URL',
  'urlControlInput.invalidUrl': 'Invalid URL',
  'urlControlInput.required': 'Required',
  'multiSelectButtonWithDefault.default': 'Default',
  'multiCountrySelector.add': 'Add',
  'priceSuggestions.priceSuggestion': 'Price suggestion {price}',
  'priceSuggestions.currentPrice': 'Your price {price}',
  'priceSuggestions.currentEstimatedPrice': 'Your estimated price {price}',
  'influencerStats.estimates.views': 'Estimated views {views}',
  'influencerStats.estimates.installs': 'Estimated installs {installs}',
  'influencerStats.estimates.eCpm': 'eCPM {ecpm}',
  'modifyOfferOverlay.cpi': 'CPI',
  'modifyOfferOverlay.cpm': 'CPM',
  'modifyOfferOverlay.maxPayment': 'Max payment',
  'modifyOfferOverlay.minGuarantee': 'Min. guarantee',
  'modifyOfferOverlay.fixedFee': 'Fixed fee',
  'modifyOfferOverlay.freeOfCharge': 'Free of charge',
  'modifyOfferOverlay.publishingDatePlaceholder': 'Select publishing date',
  optional: 'Optional',
  'admin.managerTeam.team.loading': 'Loading team...',
  'admin.managerTeam.members.loading': 'Loading team members...',
  'admin.managerTeam.channels.loading': 'Loading managed channels...',
  'admin.managerTeam.invites.loading': 'Loading channel invites...',
  'admin.managerTeam.members.title':
    ' ({membersCount, plural, =0 {no members} one {# member} other {# members}})',
  'admin.managerTeam.channels.title':
    'Managed channels ({channelsCount, plural, =0 {no channels} one {# channel} other {# channels}})',
  'admin.managerTeam.invites.title':
    'Invited channels ({channelsCount, plural, =0 {no channels} one {# channel} other {# channels}})',
  'table.headers.email': 'Email',
  'export.csv': 'Export all as csv',
  'operator.more-than': 'More than',
  'operator.less-than': 'Less than',
  'operator.between': 'Between',

  'instagram.channelName': 'Channel name',
  'instagram.followers': 'Followers',
  'instagram.posts': 'Posts',
  'instagram.stats': 'Stats',
  'instagram.engagement': 'Engagement',

  'contentType.bilibiliVideo': 'Bilibili Video',

  'contentType.instagramPost': 'Instagram Post',
  'contentType.instagramStory': 'Instagram Story',

  'contentType.instagramPost.contextualHeader':
    '{count, plural, =0 {Posts} one {Post} other {Posts}}',
  'contentType.instagramStory.contextualHeader':
    '{count, plural, =0 {Story sequences} one {Story sequence} other {Story sequences}} (3-5 frames)',

  'datePicker.publishingDate': 'Publishing date',

  'socialMediaAccount.connect': 'Connect',
  'socialMediaAccount.reconnect': 'Reconnect',
  'socialMediaAccount.missingScopeTooltip':
    'You have not allowed Matchmade to get this data from your account. Please reconnect and turn this option on.'
};
