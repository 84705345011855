// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';
import {
  SETTLED,
  calculateValueWithoutCommission,
  getCpiFromAgreement,
  getCpmFromAgreement,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement
} from '@sharkpunch/matchmade-common/campaignAgreement';
import type { ContentPlatform, PromotionType } from 'matchmade-types';

import {
  InReviewDealItemTab,
  PublishedDealItemTab,
  UnpublishedDealItemTab
} from '../tabs/DealItemTab';
import { cancelSettledDeal, setAgreementDeadline } from '../../../actions/campaignAgreement';
import { checkIfWhitelabelAdmin } from '../../../helpers/user';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { useAdminTab } from '../InfluencerCard/AdminTab';
import AdminApproveDealTab from '../../common/TabComponents/AdminApproveDealTab';
import Button, { Color } from '../../common/Button';
import Card from '../../common/Card';
import ChannelHeader from '../../common/ChannelHeader';
import CheckIcon from '../../common/Icons/CheckIcon';
import EditCommissionIcon from '../../common/Icons/EditCommissionIcon';
import HistoryIcon from '../../common/Icons/HistoryIcon';
import LastDealsTab from '../../common/TabComponents/LastDealsTab';
import LatestOfferTab from '../../common/TabComponents/LatestOfferTab';
import LinkToInfluencer from '../InfluencerCard/LinkToInfluencer';
import MessagesIcon from '../../common/Icons/MessagesIcon';
import MessagesTab from '../../common/TabComponents/MessagesTab';
import NegotiationIcon from '../../common/Icons/NegotiationIcon';
import TabsWithContent from '../../common/TabsWithContent';
import VideoIcon from '../../common/Icons/VideoIcon';
import getConfig from '../../../config';
import useConfirmService from '../../common/ConfirmDialog/ConfirmDialog';

import { DealItemStatus } from '../../../constants';

import { getAnalyticsTab, getDemographicsTab } from '../InfluencerCard/common';

function getFirstDealItem(agreement) {
  return (agreement.dealItems || [])[0] || null;
}

function getPublishedContentTab(props: Props) {
  const { agreement } = props;
  const dealItem = getFirstDealItem(agreement);

  const dealItemProps = {
    contentPlatform: agreement.contentPlatform,
    ...(dealItem || {}),
    deadline: agreement.deadline,
    trackingCode: agreement.trackingCode
  };

  const content = !dealItem ? (
    <UnpublishedDealItemTab {...dealItemProps} />
  ) : dealItem.status === DealItemStatus.PUBLISHED ? (
    <PublishedDealItemTab {...dealItemProps} />
  ) : dealItem.status === DealItemStatus.IN_REVIEW ? (
    <InReviewDealItemTab {...dealItemProps} />
  ) : null;

  return content
    ? {
        icon: <VideoIcon />,
        content
      }
    : null;
}

type Props = {
  influencer: any,
  agreement: any,
  isLoading: boolean,
  showMessagesTab: boolean,
  showLatestDealsTab: boolean,
  promotionType: PromotionType,
  contentPlatform: ContentPlatform,
  channelName: string,
  agreementDeadline: string,
  agreementStatus: string,
  cost: number,
  estimatedCost: number,
  adminActions: any,
  defaultTab: number,
  hideCpi: boolean,
  estimatedInstalls: number
};

function InfluencerParticipatingCard(props: Props) {
  const {
    influencer,
    agreement,
    showMessagesTab = true,
    showLatestDealsTab = true,
    isLoading = false,
    promotionType,
    contentPlatform,
    channelName,
    agreementDeadline,
    agreementStatus,
    cost,
    estimatedCost,
    adminActions
  } = props;

  const confirmService = useConfirmService();
  const dispatch = useDispatch();
  const { channel, contentPlatform: channelContentPlatform, url: channelUrl } =
    getFirstAvailableContentPlatform(influencer, agreement.contentPlatform) || {};
  const lastMessage = agreement.lastMessage || {};
  const defaultTab = props.defaultTab !== undefined ? props.defaultTab : showMessagesTab ? 4 : 3;
  const cpi = getCpiFromAgreement(agreement);
  const cpm = getCpmFromAgreement(agreement);
  const minGuarantee = getMinGuaranteeFromAgreement(agreement);
  const maxPayment = getMaxPaymentFromAgreement(agreement);

  const [adminTab] = useAdminTab({
    cpi,
    cpm,
    minGuarantee,
    maxPayment,
    estimates: agreement.estimates,
    promotionType: agreement.promotionType,
    agreementStatus: agreement.status,
    agreementId: agreement.id,
    contentPlatform: agreement.contentPlatform,
    deadline: agreement.deadline,
    isDisabled: false,
    allowToEditDealPrice: true,
    commissionRate: agreement.commissionRate || 0,
    showPublishContent: true,
    onSubmitDeadline: deadline => {
      dispatch(setAgreementDeadline.run(agreement.id, deadline));
    }
  });

  if (!channel) return null;

  const renderActions = (tabIndex, tab) =>
    tab.isAdminTab && agreementStatus === SETTLED ? (
      <Button
        key="set-deal"
        className="Button__main-action"
        onClick={() => {
          (async () => {
            try {
              await confirmService.confirm({
                closeText: `Don't cancel`,
                actionText: 'Yes, cancel deal',
                actionCategory: 'danger',
                description: `
                    All parties involved in this deal won't get notified, so make sure they're aware.
                    Unless there is a good reason, canceling a settled deal should be your last option.
                  `
              });
              await confirmService.confirm({
                title: "⚠ DON'T DO THIS! ⚠",
                closeText: `Don't cancel`,
                actionText: 'Yes, cancel deal',
                actionCategory: 'danger',
                description: `
                    This is something that's very hard to reverse, so please make sure that this is
                    the only possible solution, and that you know what you're doing.
                  `
              });
              dispatch(cancelSettledDeal.run(agreement));
            } catch {
              // Do nothing
            }
          })();
        }}
        loading={isLoading}
        color={Color.DANGER}>
        <FormattedMessage id="influencer.card.admin.cancelDeal" />
      </Button>
    ) : (
      <Button key="all-done" className="Button__main-action" disabled onClick={() => {}}>
        <FormattedMessage tagName="strong" id="influencer.card.actions.dealMade" />
        <CheckIcon />
      </Button>
    );

  // Be careful when changing this or moving this around. It can have an impact on how much creators get paid.
  let payoutAmount = null;
  let invoiceItemAmount = null;
  if (cost) {
    invoiceItemAmount = (cost / 100).toFixed(2);

    payoutAmount = (
      calculateValueWithoutCommission(cost, agreement.commissionRate || 0) / 100
    ).toFixed(2);
  }

  return [
    <Card className="InfluencerCard" key="card">
      <ChannelHeader
        backgroundImage={channel.avatarUrl}
        isVertical
        channelType={channelContentPlatform}
        adminActions={adminActions}>
        <LinkToInfluencer
          id={influencer.id}
          isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
          channelUrl={channelUrl}
          avatarUrl={channel.avatarUrl}
          channelName={channelName}
          isMock={channel.mock}
        />
      </ChannelHeader>
      <TabsWithContent
        defaultTab={defaultTab}
        actions={renderActions}
        data={[
          getAnalyticsTab({
            influencer,
            defaultContentPlatform: contentPlatform
          }),
          {
            icon: <NegotiationIcon />,
            content: (
              <LatestOfferTab
                cpi={cpi}
                cpm={cpm}
                minGuarantee={minGuarantee}
                maxPayment={maxPayment}
                freeOfCharge={agreement.freeOfCharge || false}
                isTakeItOrLeaveIt={agreement.takeItOrLeaveIt}
                cost={cost}
                estimatedCost={estimatedCost}
                agreementDeadline={agreementDeadline}
                agreementStatus={agreementStatus}
                promotionType={promotionType}
                contentPlatform={contentPlatform}
                isVertical
                averageViewCount={
                  channelContentPlatform === ContentPlatformEnum.YOUTUBE
                    ? channel.last30dStats.averageViewCount
                    : 0
                }
                showEffectiveCpm={channelContentPlatform !== ContentPlatformEnum.INSTAGRAM}
                showPromotionType={channelContentPlatform !== ContentPlatformEnum.INSTAGRAM}
                takeItOrLeaveIt={agreement.takeItOrLeaveIt}
                publishingWindow={agreement.publishingWindow}
              />
            )
          },
          getDemographicsTab(influencer, contentPlatform),
          showMessagesTab && {
            icon: <MessagesIcon />,
            content: (
              <MessagesTab
                content={lastMessage.content}
                lastMessageRole={lastMessage.role}
                senderName={lastMessage.senderName}
                senderAvatarUrl={lastMessage.senderAvatarUrl}
                created={lastMessage.created}
                noMessageAvatar={channel.avatarUrl}
                agreementId={agreement.id}
                chatClosed={agreement.chatClosed}
              />
            )
          },
          getPublishedContentTab(props),
          getConfig('features.showAdminCommissionTool') &&
            checkIfWhitelabelAdmin() && {
              icon: <EditCommissionIcon />,
              isAdminTab: true,
              content: adminTab
            },
          checkIfWhitelabelAdmin() && {
            icon: <span className="material-icons">monetization_on</span>,
            isAdminTab: true,
            content: (
              <AdminApproveDealTab
                agreementId={agreement.id}
                payoutAmount={payoutAmount}
                invoiceItemAmount={invoiceItemAmount}
                confirmContext={confirmService}
              />
            )
          },
          showLatestDealsTab &&
            channelContentPlatform === ContentPlatformEnum.YOUTUBE &&
            checkIfWhitelabelAdmin() && {
              icon: <HistoryIcon />,
              isAdminTab: true,
              content: (
                <LastDealsTab deals={agreement.lastDeals} campaignId={agreement.campaignId} />
              )
            }
        ].filter(Boolean)}
      />
    </Card>,
    <confirmService.Modal key="confirm-modal" />
  ];
}

export default InfluencerParticipatingCard;
