import { connect } from 'react-redux';
import React, { Component } from 'react';

import {
  fetchCampaign,
  fetchCampaignAnalytics,
  fetchCampaignOverallStats
} from '../actions/campaign';

import CampaignViewForInfluencer from '../components/campaign/CampaignViewForInfluencer';
import Spinner from '../components/common/Spinner';

import { FormattedMessage } from 'react-intl';

import get from 'lodash/get';

import browserHistory from 'react-router/lib/browserHistory';

import CampaignKpiChart from '../components/campaign/CampaignKpiChart';
import moment from 'moment';

import { SETTLED } from '@sharkpunch/matchmade-common/campaignAgreement';

import { checkIfInfluencer, checkIfInfluencerManager } from '../helpers/user';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';

import './InfluencerCampaignPage.scss';
import CampaignTwitchKpiChart from '../components/campaign/CampaignTwitchKpiChart';

class InfluencerCampaignPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment.utc().subtract(6, 'days'),
      endDate: moment.utc()
    };

    this.onChangeCampaignAnalyticsDate = this.onChangeCampaignAnalyticsDate.bind(this);
    this.getInfluencerId = this.getInfluencerId.bind(this);
  }

  getInfluencerId() {
    if (checkIfInfluencer()) return this.props.user.id;
    else if (checkIfInfluencerManager()) return this.props.params.influencerId;
    else return null;
  }

  componentWillMount() {
    if (checkIfInfluencer() && !(this.props.user || {}).verified) {
      return browserHistory.replace('/dashboard/influencer');
    }

    const influencerId = this.getInfluencerId();
    if (!influencerId) {
      console.error('No influencer id provided');
      return browserHistory.replace('/404');
    }
    this.props.fetchCampaign(this.props.params.id, influencerId);
  }

  componentWillUpdate(nextProps) {
    const campaign = this.props.campaign;
    const nextCampaign = nextProps.campaign;
    if (
      !campaign &&
      nextCampaign &&
      nextCampaign.agreement &&
      nextCampaign.agreement.status === SETTLED
    ) {
      const influencerId = this.getInfluencerId();

      this.onChangeCampaignAnalyticsDate(this.state.startDate, this.state.endDate);
      this.props.fetchCampaignOverallStats(nextCampaign.id, influencerId);
    }
  }

  onChangeCampaignAnalyticsDate(startDate, endDate) {
    let dimension = 'day';

    if (endDate.diff(startDate, 'days') <= 2) dimension = 'hour';

    startDate = startDate.utc().startOf('date');
    endDate = endDate.utc().endOf('date');

    const campaignId = this.props.params.id;

    this.props.fetchCampaignAnalytics({
      campaignId,
      influencerId: this.getInfluencerId(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      dimension
    });
    this.setState({ startDate, endDate });
  }

  doesInfluencerJoinCampaign() {
    const role = get(this.props, 'user.token.role');
    const campaign = this.props.campaign;

    return (
      (role === 'influencer' || role === 'influencer_manager') &&
      campaign &&
      campaign.agreement &&
      campaign.agreement.status === SETTLED
    );
  }

  renderKpiChart() {
    if (!this.doesInfluencerJoinCampaign()) return null;

    const { startDate, endDate } = this.state;
    const { campaign } = this.props;
    const isYoutube = campaign.defaultContentPlatform === 'youtube';
    const campaignAnalytics = this.props.campaignAnalytics || [];
    const analytics = isYoutube ? campaignAnalytics : campaignAnalytics.points;
    const isLoading = this.props.isFetchingCampaignAnalytics; // We show big overlay on top of both chart and table
    const onChangeDate = this.onChangeCampaignAnalyticsDate;

    const props = {
      startDate,
      endDate,
      analytics,
      isLoading,
      onChangeDate
    };

    const chart = isYoutube ? (
      <CampaignKpiChart {...props} campaignCreatedAt={campaign.created} />
    ) : (
      <CampaignTwitchKpiChart {...props} />
    );
    return (
      <section className="section InfluencerCampaignPage__kpi-chart">
        <div className="subtitle">
          <FormattedMessage id="influencer.campaign.kpi.chart.title" />
        </div>
        {chart}
      </section>
    );
  }

  render() {
    let isFetching = this.props.isLoadingCampaign;
    const campaign = this.props.campaign;

    if (this.doesInfluencerJoinCampaign()) {
      isFetching = isFetching || this.props.isFetchingCampaignOverallStats;
    }

    if (isFetching) return <Spinner mode="cover" size="large" />;

    if (!campaign) {
      return (
        <div className="container">
          <FormattedMessage id="error.nodata" tagName="h2" />
        </div>
      );
    }

    return (
      <div className="container InfluencerCampaignPage">
        <CampaignViewForInfluencer
          campaign={campaign}
          error={this.props.error}
          user={this.props.user}
          sections={{
            afterGameHeader: this.renderKpiChart()
          }}
          campaignOverallStats={this.props.campaignOverallStats}
        />
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('influencerCampaignPage');

const mapDispatchToProps = generateDispatchToProps({
  fetchCampaign,
  fetchCampaignAnalytics,
  fetchCampaignOverallStats
});

InfluencerCampaignPage = connect(mapStateToProps, mapDispatchToProps)(InfluencerCampaignPage);

export default InfluencerCampaignPage;
