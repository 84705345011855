// @flow

import * as React from 'react';
import { Link } from 'react-router';
import { fromGriddleWithInjectedUrlParams, pageLimitOptions } from '../tables/Table';
import FormattedNumber from '../common/FormattedNumber';
import moment from 'moment';
// $FlowFixMe
import { useIntl } from 'react-intl';

type Props = {
  campaigns: any[]
};

function AllocatedBudgetTable(props: Props) {
  const intl = useIntl();

  const { campaigns } = props;

  const columnMetadata = [
    {
      columnName: 'id',
      sortDirectionCycle: ['asc', 'desc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.id'
      })
    },
    {
      columnName: 'name',
      sortDirectionCycle: ['asc', 'desc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.name'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <span className="AdminDashboardAllocatedBudget__campaign-name">
            <Link to={`/admin/campaigns/${campaign.id}`}>
              {campaign.name || 'Hypothetical campaign'}
            </Link>
          </span>
        );
      }
    },
    {
      columnName: 'teamName',
      sortDirectionCycle: ['asc', 'desc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.team'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <span className="AdminDashboardAllocatedBudget__campaign-name">
            <Link to={`/admin/teams/${campaign.teamId}`}>
              {campaign.teamName || 'Hypothetical team'}
            </Link>
          </span>
        );
      }
    },
    {
      columnName: 'created',
      sortDirectionCycle: ['asc', 'desc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.daysCreated'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return <span>{moment().diff(campaign.created, 'days')}</span>;
      }
    },
    {
      columnName: 'totalBudget',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.totalBudget'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return <FormattedNumber type="cost" value={campaign.totalBudget} defaultToZero />;
      }
    },
    {
      columnName: 'allocatedBudget',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.allocatedBudget'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return <FormattedNumber type="cost" value={campaign.allocatedBudget} defaultToZero />;
      }
    },
    {
      columnName: 'unallocatedBudget',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.unallocatedBudget'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return <FormattedNumber type="cost" value={campaign.unallocatedBudget} defaultToZero />;
      }
    },
    {
      columnName: 'allocatedOfTotal',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.allocatedOfTotal'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <FormattedNumber type="percentage" value={campaign.allocatedOfTotal} defaultToZero />
        );
      }
    },
    {
      columnName: 'inNegotiationOfTotal',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.inNegotiationOfTotal'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <FormattedNumber type="percentage" value={campaign.inNegotiationOfTotal} defaultToZero />
        );
      }
    },
    {
      columnName: 'numOfConfirmedDeals',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.numOfConfirmedDeals'
      })
    },
    {
      columnName: 'numOfNegotiatedDeals',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.numOfNegotiatedDeals'
      })
    },
    {
      columnName: 'budgetInNegotiation',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.budgetInNegotiation'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return <FormattedNumber type="cost" value={campaign.budgetInNegotiation} defaultToZero />;
      }
    },
    {
      columnName: 'pendingOfPublisherApproval',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.pendingOfPublisherApproval'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <FormattedNumber type="cost" value={campaign.pendingOfPublisherApproval} defaultToZero />
        );
      }
    },
    {
      columnName: 'pendingOfInfluencerApproval',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.pendingOfInfluencerApproval'
      }),
      customComponent: props => {
        const campaign = props.rowData;
        return (
          <FormattedNumber type="cost" value={campaign.pendingOfInfluencerApproval} defaultToZero />
        );
      }
    },
    {
      columnName: 'numOfPendingConditionalOffers',
      sortDirectionCycle: ['desc', 'asc'],
      displayName: intl.formatMessage({
        id: 'admin.allocatedBudget.table.header.numOfPendingConditionalOffers'
      })
    }
  ];

  return (
    <div className="CampaignsTable">
      {fromGriddleWithInjectedUrlParams(columnMetadata, {
        data: campaigns,
        initialSort: 'created',
        initialSortDirection: 'desc',
        className: 'is-fullwidth is-hoverable',
        pagination: campaigns.length > pageLimitOptions[1],
        searchBy: ['id', 'name']
      })}
    </div>
  );
}

export default AllocatedBudgetTable;
