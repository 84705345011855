// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const SaveIcon = (props: IconProps) => {
  return (
    <Icon className="SaveIcon" {...props}>
      {() => <i className="material-icons">save</i>}
    </Icon>
  );
};

export default SaveIcon;
