// @flow
import React from 'react';
import type { State as ReducerState } from '../../reducers/containers/campaignCreation';
import type { Step } from '../../components/campaignCreation/CampaignCreationWizard';

import { FormattedMessage } from 'react-intl';

import { StepI18nLabel, StepName, StepUrl } from './constants';

const COMMON_STEPS = [
  StepName.CAMPAIGN_DESCRIPTION,
  StepName.PRODUCT_SELECTION,
  StepName.CAMPAIGN_DETAILS
];

export function buildStepsFromState(ownState: ReducerState, activeStepName: string): Step[] {
  // This will have a different step later depending on what the advertiser has selected
  return COMMON_STEPS.map(key => ({
    url: StepUrl[key],
    isValid: ownState.validity[key],
    label: <FormattedMessage id={StepI18nLabel[key]} />,
    isActive: activeStepName === key
  }));
}
