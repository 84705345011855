// @flow

/**
 * This is our own generic table component with support for
 * - pagination
 * - sorting by column
 * - grouping columns (with the help of GroupedColumn component)
 *
 * Data structure can be pretty much anything, since each Column
 * or GroupedColumn can be customized to return different component
 * or have different behavior for sorting.
 *
 * You can find relatively simple example of this component
 * in influencer manager's team page,
 * or a more complicated one in managed influencers table:
 * components/influencer/tables/ManagedInfluencersTable.js
 *
 */

import * as React from 'react';

import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import Column from './Column';
import GroupedColumn from './GroupedColumn';

import DataExporter from '../common/DataExporter';
import Pagination, { pageLimitOptions, sliceData } from '../common/Pagination';

import injectSearchInputController from '../../hoc/injectSearchInputController';
import injectURLQueryParameter from '../../hoc/injectURLQueryParameter';
import type { Props as SearchInputProps } from '../../hoc/injectSearchInputController';
import type { WrappedComponent } from '../../hoc/compose';

import { MixpanelEvent, sendMixpanelEvent } from '../../helpers/mixpanelEvents';

import './Table.scss';

export type Props = {
  ...SearchInputProps,
  urlParams?: any,
  initialSort?: string | null,
  initialSortDirection?: string | null,
  // If true, then this component doesn't sort anything by itself
  // but instead relies on data being sorted outside of this component.
  // If this is the case then initialSort and initialSortDirection are treated
  // as the current sort and current sortDirection.
  externalSorting?: boolean,
  data: Object[],
  className?: string,

  pagination?: boolean,
  pageLimit?: ?number,

  searchBy: ?Array<string | Function>,
  searchInputPlaceholder?: string,

  children?: any,
  headerControls?: any | any[],
  showRowCount?: boolean
};

const defaultProps = {
  pageLimit: pageLimitOptions[1]
};

const rowCountColumn = <Column name="rowCount">Row nr.</Column>;

type State = {
  sort: ?string,
  sortDirection: ?string,
  currentPage: number,
  pageLimit: number,
  searchTerm: string
};

class Table extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      sort: props.initialSort || null,
      sortDirection: props.initialSortDirection || null,
      currentPage: 1,
      pageLimit: props.pageLimit || defaultProps.pageLimit,
      searchTerm: props.searchInput
    };

    const { urlParams } = this.props;

    if (urlParams) {
      this.state.sort = urlParams.sortBy || this.state.sort;
      this.state.sortDirection = urlParams.sortDirection || this.state.sortDirection;
      this.state.currentPage = urlParams.page || this.state.currentPage;
      this.state.pageLimit = urlParams.pageSize || this.state.pageLimit;
      this.state.searchTerm = urlParams.searchTerm || this.state.searchTerm;
      urlParams.searchTerm &&
        this.props.onChangeSearchInput &&
        this.props.onChangeSearchInput(urlParams.searchTerm);
    }
  }

  getDefaultState = () => {
    return {
      sort: this.props.initialSort || null,
      sortDirection: this.props.initialSortDirection || null,
      currentPage: 1,
      pageLimit: defaultProps.pageLimit,
      searchTerm: ''
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      externalSorting,
      initialSort,
      initialSortDirection,
      searchInput = '',
      urlParams = null,
      onChangeSearchInput = null
    } = this.props;

    const { sort, sortDirection } = this.state;

    if (externalSorting && (initialSort !== sort || initialSortDirection !== sortDirection)) {
      this.setState({
        sort: initialSort,
        sortDirection: initialSortDirection
      });
    }
    if (urlParams) {
      const defaultState = this.getDefaultState();
      if (
        !document.location.search &&
        !isEqual(this.state, defaultState) &&
        !isEqual(prevState, defaultState)
      ) {
        // when home logo clicked
        this.setState(this.getDefaultState(), () => onChangeSearchInput && onChangeSearchInput(''));
      }
      if (searchInput !== prevProps.searchInput) {
        // when type new search refresh url accordingly
        this.setState(
          {
            searchTerm: searchInput
          },
          this.trackUrlAndMixpanel
        );
      }
    }
  }

  convertChildrenToArray(children: any) {
    const childrenArray = React.Children.toArray(children).filter(Boolean);
    if (this.props.showRowCount) {
      childrenArray.unshift(rowCountColumn);
    }
    return childrenArray;
  }

  onSort = (params: { name: string, sortDirectionCycle: string }) => {
    const { name, sortDirectionCycle } = params;
    const { sortDirection } = this.state;

    const state = {
      sort: name,
      sortDirection
    };

    if (!sortDirection) {
      state.sortDirection = sortDirectionCycle[0];
    } else if (sortDirection === sortDirectionCycle[0]) {
      state.sortDirection = sortDirectionCycle[1];
    } else if (sortDirection === sortDirectionCycle[1]) {
      state.sortDirection = sortDirectionCycle[0];
    }

    this.setState(state, this.trackUrlAndMixpanel);
  };

  refreshUrl = () => {
    const { urlParams = null } = this.props;

    if (urlParams) {
      const params = {
        sortBy: this.state.sort,
        sortDirection: this.state.sortDirection,
        page: this.state.currentPage,
        pageSize: this.state.pageLimit,
        searchTerm: this.state.searchTerm
      };
      urlParams.updateURLParameters(params);
    }
  };

  trackMixpanel = () => {
    const params = {
      sortBy: this.state.sort,
      sortDirection: this.state.sortDirection,
      page: this.state.currentPage,
      pageSize: this.state.pageLimit,
      searchTerm: this.state.searchTerm
    };

    sendMixpanelEvent(MixpanelEvent.USE_TABLE_FILTERS, params);
  };

  trackUrlAndMixpanel = () => {
    this.refreshUrl();
    this.trackMixpanel();
  };

  getColumns(children: any): any[] {
    children = this.convertChildrenToArray(children || this.props.children);

    return flatten(
      children
        .map(child => {
          if (!child) {
            return null;
          }

          if (child.type === GroupedColumn) {
            return this.getColumns(child.props.children);
          }

          if (child.type !== Column) {
            return null;
          }

          return child;
        })
        .filter(Boolean)
    );
  }

  renderHeaders() {
    const { sort, sortDirection } = this.state;
    const children = this.convertChildrenToArray(this.props.children);
    const hasGroupedColumn = children.some(child => child.type === GroupedColumn);

    const rows = [
      <tr key="0">
        {children.map((child, index) => {
          if (!child || !child.props || !child.type) {
            console.warn('Invalid table child!');
            return child;
          }
          return React.cloneElement(child, {
            key: `header-0-${index}`,
            sort,
            sortDirection,
            // Sometimes we don't want the Table to handle sorting
            // but it's handled by the parent component.
            // Maybe there's server side sorting & paging, then
            // we can't sort locally.
            onClick: child.props.onClick || this.onSort,
            rowSpan: child.type === Column && hasGroupedColumn ? 2 : 0
          });
        })}
      </tr>
    ];

    // This supports only one level of nested menus
    if (hasGroupedColumn) {
      const sub = flatten(
        children
          .filter(({ type }) => type === GroupedColumn)
          .map(({ props }) => this.convertChildrenToArray(props.children))
      ).map((child, index) => {
        return React.cloneElement(child, {
          key: `header-1-${index}`,
          sort,
          sortDirection,
          // Sometimes we don't want the Table to handle sorting
          // but it's handled by the parent component.
          // Maybe there's server side sorting & paging, then
          // we can't sort locally.
          onClick: child.props.onClick || this.onSort,
          grouped: true
        });
      });

      rows.push(<tr key="1">{sub}</tr>);
    }

    return rows;
  }

  onChangePageLimit = (pageLimit: number) => {
    this.setState({ pageLimit }, this.trackUrlAndMixpanel);
  };

  onChangePage = (currentPage: number) => {
    this.setState({ currentPage }, this.trackUrlAndMixpanel);
  };

  renderPagination(data: Object[]) {
    if (!this.props.pagination || !data.length) {
      return null;
    }

    const pageCount = Math.ceil((data || []).length / this.state.pageLimit);

    return (
      <Pagination
        pageCount={pageCount}
        currentPage={this.state.currentPage}
        pageLimit={this.state.pageLimit}
        pageRange={4}
        onChangePage={this.onChangePage}
        onChangePageLimit={this.onChangePageLimit}
      />
    );
  }

  renderSearchInput() {
    const { searchBy } = this.props;

    if (!searchBy || !searchBy.length) {
      return null;
    }

    return <div className="Table__search">{this.props.renderSearchInput()}</div>;
  }

  render() {
    const { data, searchBy, getDataMatchedSearchInput = null } = this.props;
    const { sort, sortDirection } = this.state;

    const columns = this.getColumns();
    const headers = this.renderHeaders();
    const dataMatchedSearchInput = getDataMatchedSearchInput
      ? getDataMatchedSearchInput(data, searchBy)
      : [];

    const sortByColumn = sort ? find(columns, ({ props }) => props.name === sort) : null;
    const sortedData =
      sortByColumn && !this.props.externalSorting
        ? sortBy(dataMatchedSearchInput, d => {
            const value = get(d, sort, d);
            const sortBy = sortByColumn.props.sortBy;

            return typeof sortBy === 'function' ? sortBy(value, d) : value;
          })
        : dataMatchedSearchInput;

    if (!this.props.externalSorting && sort && sortDirection === 'desc') {
      sortedData.reverse();
    }

    if (this.props.showRowCount) {
      // if we want to show row count, add number to sorted data but ignore pagination slice
      sortedData.map((d, index) => {
        d.rowCount = index + 1;
        return d;
      });
    }

    const paginatedData = this.props.pagination ? sliceData(sortedData, this.state) : sortedData;

    const rows = paginatedData.map((d, index) => {
      const row = columns.map(({ props }, idx) => {
        let value = get(d, props.name);

        if (props.component) {
          const CustomComponent = props.component;
          value = <CustomComponent {...props.extraProps} rowData={d} data={value} />;
        }

        return (
          <td key={`column-${idx}-row-${index}`} className={props.className}>
            {value}
          </td>
        );
      });

      return <tr key={`row-${index}`}>{row}</tr>;
    });

    const footer = paginatedData.length > pageLimitOptions[0] ? <tfoot>{headers}</tfoot> : null;

    const pagination = this.renderPagination(sortedData);

    const topPagination =
      this.props.pagination || (searchBy && searchBy.length) ? (
        <div className="Table__pagination Table__pagination--top">
          <div className="is-flex">
            {this.renderSearchInput()}
            {this.props.headerControls}
          </div>
          <DataExporter data={sortedData} />
          {pagination}
        </div>
      ) : null;

    const bottomPagination =
      pagination && paginatedData.length > pageLimitOptions[0] ? (
        <div className="Table__pagination Table__pagination--bottom">{pagination}</div>
      ) : null;

    const className = `Table table rtable ${this.props.className || ''}`;

    return (
      <div className="Table__container">
        {topPagination}
        <table className={className}>
          <thead>{headers}</thead>
          <tbody>{rows}</tbody>
          {footer}
        </table>
        {bottomPagination}
      </div>
    );
  }
}

const WrappedTable: WrappedComponent<Props, SearchInputProps> = injectSearchInputController()(
  Table
);
const WrappedTableWithInjectedUrlParams: WrappedComponent<
  Props,
  SearchInputProps
> = injectURLQueryParameter(WrappedTable);

export default WrappedTable;

function fromGriddleWithInjectedUrlParams(
  columnMetadata: Object[],
  tableProps: $Diff<Props, SearchInputProps>
) {
  tableProps = tableProps || {};

  const columns = columnMetadata
    .map(column => {
      if (column.visible === false) {
        return null;
      }

      const props = {
        name: column.columnName,
        key: column.columnName,
        sortDirectionCycle: column.sortDirectionCycle,
        sortable: column.sortable,
        component: column.customComponent,
        className: column.className,
        sortBy: column.customCompareFn
      };
      return <Column {...props}>{column.displayName}</Column>;
    })
    .filter(Boolean);

  return (
    <WrappedTableWithInjectedUrlParams {...tableProps}>{columns}</WrappedTableWithInjectedUrlParams>
  );
}

export { Column, GroupedColumn, fromGriddleWithInjectedUrlParams, pageLimitOptions };
