// @flow
import * as React from 'react';
// $FlowFixMe
import Linkify from 'linkifyjs/react';
// $FlowFixMe
import ReactHtmlParser from 'react-html-parser';

import Avatar from './Avatar';
import WithTimestampUpdate from './common/WithTimestampUpdate';

import './ChatMessage.scss';

type Props = {
  displayName: string,
  avatarUrl: string,
  created?: string,
  timestamp?: string,
  reverse?: boolean,
  isAdmin: boolean,
  children: string
};

const ChatMessage = ({
  created = 'a few seconds ago',
  reverse,
  avatarUrl,
  displayName,
  timestamp,
  isAdmin,
  children
}: Props) => {
  if (!children) {
    return null;
  }

  // timestamp comes from WithTimestampUpdate
  const time = timestamp || created;

  return (
    <div className={`ChatMessage${reverse ? ' is-reverse' : ''}`}>
      <Avatar url={avatarUrl} isAdmin={isAdmin} />
      <div className="ChatMessage__content-box box">
        <div className="ChatMessage__content">
          <Linkify
            options={{
              attributes: {
                rel: 'noopener noreferrer'
              }
            }}>
            {ReactHtmlParser(children)}
          </Linkify>
        </div>
        <div className="ChatMessage__name">
          {displayName} &middot; <time>{time}</time>
        </div>
      </div>
    </div>
  );
};

export default WithTimestampUpdate(ChatMessage, 'created');
