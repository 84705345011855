import {
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection,
  createCollection,
  getAll,
  getCollection,
  getDynamicCollections,
  getStaticCollections,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection,
  getCollectionPublic,
  hideChannelFromCollection,
  showChannelFromCollection
} from '../../actions/collection';

export default {
  createCollection(state, action) {
    switch (action.type) {
      case createCollection.REQUEST:
        return {
          ...state,
          isCreatingCollection: true
        };
      case createCollection.SUCCESS:
        return {
          ...state,
          isCreatingCollection: false
        };
      case createCollection.FAILURE:
        return {
          ...state,
          isCreatingCollection: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  getCollection(state, action) {
    switch (action.type) {
      case getCollection.REQUEST:
        return {
          ...state,
          isFetchingCollection: true
        };
      case getCollection.SUCCESS:
        return {
          ...state,
          isFetchingCollection: false,
          collection: action.payload
        };
      case getCollection.FAILURE:
        return {
          ...state,
          isFetchingCollection: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  getCollectionPublic(state, action) {
    switch (action.type) {
      case getCollectionPublic.REQUEST:
        return {
          ...state,
          isFetchingCollection: true
        };
      case getCollectionPublic.SUCCESS:
        return {
          ...state,
          isFetchingCollection: false,
          collection: action.payload
        };
      case getCollectionPublic.FAILURE:
        return {
          ...state,
          isFetchingCollection: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  fetchAllCollections(state, action) {
    switch (action.type) {
      case getAll.REQUEST:
        return {
          ...state,
          isFetchingCollections: true
        };
      case getAll.SUCCESS:
        return {
          ...state,
          isFetchingCollections: false,
          collections: action.payload
        };
      case getAll.FAILURE:
        return {
          ...state,
          isFetchingCollections: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  fetchDynamicCollections(state, action) {
    switch (action.type) {
      case getDynamicCollections.REQUEST:
        return {
          ...state,
          isFetchingCollections: true
        };
      case getDynamicCollections.SUCCESS:
        return {
          ...state,
          isFetchingCollections: false,
          dynamicCollections: action.payload
        };
      case getDynamicCollections.FAILURE:
        return {
          ...state,
          isFetchingCollections: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  fetchStaticCollections(state, action) {
    switch (action.type) {
      case getStaticCollections.REQUEST:
        return {
          ...state,
          isFetchingCollections: true
        };
      case getStaticCollections.SUCCESS:
        return {
          ...state,
          isFetchingCollections: false,
          staticCollections: action.payload
        };
      case getStaticCollections.FAILURE:
        return {
          ...state,
          isFetchingCollections: false,
          error: action.payload
        };
      default:
        return state;
    }
  },

  addYoutubeChannelToCollection(state, action) {
    switch (action.type) {
      case addYoutubeChannelToCollection.REQUEST:
        return {
          ...state,
          isAddingChannelToCollection: true
        };
      case addYoutubeChannelToCollection.SUCCESS:
        return {
          ...state,
          isAddingChannelToCollection: false
        };
      case addYoutubeChannelToCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isAddingChannelToCollection: false
        };
      default:
        return state;
    }
  },

  removeYoutubeChannelFromCollection(state, action) {
    switch (action.type) {
      case removeYoutubeChannelFromCollection.REQUEST:
        return {
          ...state,
          isRemovingChannelFromCollection: true
        };
      case removeYoutubeChannelFromCollection.SUCCESS:
        return {
          ...state,
          isRemovingChannelFromCollection: false
        };
      case removeYoutubeChannelFromCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isRemovingChannelFromCollection: false
        };
      default:
        return state;
    }
  },

  hideChannelFromCollection(state, action) {
    switch (action.type) {
      case hideChannelFromCollection.REQUEST:
        return {
          ...state,
          isHidingChannelFromCollection: true
        };
      case hideChannelFromCollection.SUCCESS:
        return {
          ...state,
          isHidingChannelFromCollection: false
        };
      case hideChannelFromCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isHidingChannelFromCollection: false
        };
      default:
        return state;
    }
  },

  showChannelFromCollection(state, action) {
    switch (action.type) {
      case showChannelFromCollection.REQUEST:
        return {
          ...state,
          isShowingChannelFromCollection: true
        };
      case showChannelFromCollection.SUCCESS:
        return {
          ...state,
          isShowingChannelFromCollection: false
        };
      case showChannelFromCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isShowingChannelFromCollection: false
        };
      default:
        return state;
    }
  },

  addTwitchChannelToCollection(state, action) {
    switch (action.type) {
      case addTwitchChannelToCollection.REQUEST:
        return {
          ...state
        };
      case addTwitchChannelToCollection.SUCCESS:
        return {
          ...state,
          isAddingChannelToCollection: false
        };
      case addTwitchChannelToCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isAddingChannelToCollection: false
        };
      default:
        return state;
    }
  },

  removeTwitchChannelFromCollection(state, action) {
    switch (action.type) {
      case removeTwitchChannelFromCollection.REQUEST:
        return {
          ...state,
          isRemovingChannelFromCollection: true
        };
      case removeTwitchChannelFromCollection.SUCCESS:
        return {
          ...state,
          isRemovingChannelFromCollection: false
        };
      case removeTwitchChannelFromCollection.FAILURE:
        return {
          ...state,
          error: action.payload,
          isRemovingChannelFromCollection: false
        };
      default:
        return state;
    }
  }
};
