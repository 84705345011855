// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { useEffect, useState } from 'react';
import type { Team } from 'matchmade-types/schema';

import CloseIcon from '../common/Icons/CloseIcon';
import CreatableTeamSelector from './CreatableTeamSelector';
import TeamIcon from '../common/Icons/TeamIcon';
import usePrevious from '../../hooks/usePrevious';

import './InlineTeamManagement.scss';

type Props = {
  currentTeam: ?Team,
  allTeams: null | Team[],
  isLoading: boolean,
  isEditing: boolean,
  link?: string,
  onCreateTeam: (name: string) => void,
  onChangeTeam: (id: number, name: string) => void,
  onToggleEdit: () => void
};

export function useInlineTeamEntryBeingEdited<T>(
  entryIdBeingEdited: ?T,
  isChangingTeam: boolean
): [?T, (value: ?T) => void] {
  const [entryId, setEntryId] = useState(null);

  const previousIsChangingTeam = usePrevious(isChangingTeam);
  useEffect(() => {
    if (previousIsChangingTeam && !isChangingTeam) {
      setEntryId(null);
    }
  }, [isChangingTeam, previousIsChangingTeam]);

  return [entryId, setEntryId];
}

export function InlineTeamManagement(props: Props) {
  const {
    isEditing,
    allTeams,
    isLoading,
    currentTeam,
    link,
    onCreateTeam = name => {},
    onChangeTeam = (id, name) => {},
    onToggleEdit = () => {}
  } = props;

  if (!allTeams) {
    return null;
  }

  const icon = isEditing ? (
    <CloseIcon />
  ) : (
    <TeamIcon withTooltip tooltipI18nString="tooltips.changeTeam" />
  );

  const currentTeamId = (currentTeam && currentTeam.id) || ''; // oh flow...
  const teamLink = link || currentTeamId ? `/admin/teams/${currentTeamId}` : null;

  const changeTeamName = isEditing ? (
    <div className="InlineTeamManagement__change-team-name">
      <Link to={teamLink}>
        <FormattedMessage id="publisher.team.changeName" />
      </Link>
    </div>
  ) : null;

  return (
    <div className="InlineTeamManagement">
      <div className="InlineTeamManagement__team-selector">
        <CreatableTeamSelector
          allTeams={allTeams}
          currentTeam={currentTeam}
          isEditing={isEditing}
          isLoading={isLoading}
          link={link}
          onChange={team => {
            if (!team) {
              return;
            }
            const isCreatingNewTeam = typeof team.id === 'string' && team.id === team.name;
            if (isCreatingNewTeam) {
              onCreateTeam(team.name);
            } else {
              onChangeTeam(team.id, team.name);
            }
          }}
        />
        <button
          className="InlineTeamManagement__team-edit-icon link-button"
          onClick={() => {
            if (isLoading) return;
            onToggleEdit();
          }}>
          {icon}
        </button>
      </div>
      {changeTeamName}
    </div>
  );
}
