import React from 'react';

const ExternalLink = ({ isActive }) => {
  const iconStyles = isActive
    ? 'icon Icon mdExternalLink activeTabIcon'
    : 'icon Icon mdExternalLink';
  return (
    <span className={iconStyles}>
      <i className="material-icons">exit_to_app</i>
    </span>
  );
};

export default ExternalLink;
