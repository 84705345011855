// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'react-router/lib/Link';
import browserHistory from 'react-router/lib/browserHistory';

import { SubStateName } from '../../../reducers/constants';
import { clearCreatedCampaign } from '../../../actions/campaign';
import { getUser } from '../../../helpers/user';
import CheckIcon from '../../common/Icons/CheckIcon';
import Modal from '../../common/Modal';
import compose from '../../../hoc/compose';
import generateDispatchToProps from '../../../helpers/generateDispatchToProps';
import type { DataProps as CampaignDescriptionDataProps } from '../../campaignCreation/CampaignDescription';
import type { CampaignForPublisherDashboard } from '../../../types/campaign.flow';

import './PublisherCampaigns.scss';

const CampaignsTable = ({ campaigns = [] }: { campaigns: CampaignForPublisherDashboard[] }) => {
  if (!campaigns.length) return null;

  const rows = campaigns
    .concat()
    .sort((a, b) => {
      if (a.visible && b.visible) {
        return b.id - a.id;
      }

      if (a.visible !== b.visible) {
        return Number(b.visible) - Number(a.visible);
      }

      return b.id - a.id;
    })
    .map(campaign => {
      return (
        <tr key={`campaign-${campaign.id}`}>
          <td>
            <Link
              className="PublisherCampaigns__campaign-link"
              to={`/dashboard/publisher/campaigns/${campaign.id}`}>
              {campaign.name}
            </Link>
          </td>
          <td>{campaign.visible && <CheckIcon />}</td>
          <td className="PublisherCampaigns__game-title">{campaign.gameTitle}</td>
          <td>
            <Link to={`/dashboard/publisher/campaigns/${campaign.id}/edit`}>
              <i className="fa fa-pencil" />
            </Link>
          </td>
        </tr>
      );
    });

  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          <FormattedMessage tagName="th" id="publisher.dashboard.campaignTable.campaignName" />
          <FormattedMessage tagName="th" id="publisher.dashboard.campaignTable.active" />
          <FormattedMessage tagName="th" id="publisher.dashboard.campaignTable.product" />
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

function redirectToCreateCampaignPage(url) {
  browserHistory.push(url);
}

const CreateCampaignButton = ({
  campaignName,
  shouldShowModal,
  createCampaignUrl,
  clearCreatedCampaign
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const maybeShowModalOrRedirect = () => {
    if (!shouldShowModal) {
      return redirectToCreateCampaignPage(createCampaignUrl);
    }
    setIsOpen(true);
  };

  // i18n spaces are hard :/
  const name = campaignName ? (
    <span>
      {' '}
      <strong>{campaignName}</strong>
    </span>
  ) : (
    ''
  );
  return (
    <>
      <button
        className="button is-primary PublisherCampaigns__create-new-campaign-button"
        onClick={maybeShowModalOrRedirect}>
        <FormattedMessage id="campaign.create" />
      </button>
      <Modal
        key="modal"
        className="PublisherCampaigns__modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}>
        <div className="buttons">
          <FormattedMessage id="campaign.oldDraftFound" values={{ name }} />
          <span
            className="button is-primary"
            onClick={() => {
              clearCreatedCampaign();
              redirectToCreateCampaignPage(createCampaignUrl);
            }}>
            <FormattedMessage id="campaign.restartCreate" />
          </span>
          <span
            className="button is-success"
            onClick={() => {
              redirectToCreateCampaignPage(createCampaignUrl);
            }}>
            <FormattedMessage id="campaign.create.continue" />
          </span>
        </div>
      </Modal>
    </>
  );
};

type Props = {
  campaigns: CampaignForPublisherDashboard[],
  createCampaignData?: { campaignDescription: CampaignDescriptionDataProps },
  // flow cannot distinguish between props we pass from above and props we inject with redux :/
  hasCreatedCampaignDraft?: boolean,
  clearCreatedCampaign?: () => void
};

const PublisherCampaigns = (props: Props) => {
  const {
    hasCreatedCampaignDraft = false,
    clearCreatedCampaign = () => {
      // eslint-disable-next-line
      console.log('Missing clearCreatedCampaignFunction!');
    }
  } = props;
  return (
    <div className="PublisherCampaigns">
      <div className="PublisherCampaigns__create-campaign">
        <CreateCampaignButton
          shouldShowModal={hasCreatedCampaignDraft}
          clearCreatedCampaign={clearCreatedCampaign}
          campaignName={
            props.createCampaignData && props.createCampaignData.campaignDescription.name
          }
          createCampaignUrl={'/dashboard/publisher/campaigns/create/campaign-description'}
        />
      </div>
      <CampaignsTable campaigns={props.campaigns} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignCreation = state[SubStateName.CAMPAIGN_CREATION];
  const campaignDescription = campaignCreation.campaignDescription;
  const currentUser = getUser();

  if (campaignDescription && campaignDescription.name) {
    return {
      createCampaignData: {
        campaignDescription
      },
      hasCreatedCampaignDraft: currentUser.id === campaignCreation.createdByUserId
    };
  }

  return {
    createCampaignData: null,
    hasCreatedCampaignDraft: false
  };
};

const mapDispatchToProps = generateDispatchToProps({ clearCreatedCampaign });

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PublisherCampaigns);
