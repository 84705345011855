import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';
import defaultArrowRenderer from '../../helpers/arrowRenderer';

import './CountrySelection.scss';

const countryOptions = [
  {
    code: '',
    name: '---'
  }
].concat(
  Object.entries(countryCodes).map(([code, name]) => {
    return { code, name };
  })
);

function getCountryOptions(countries) {
  return countries.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
}

const CountrySelection = ({
  selected = '',
  disabled = false,
  clearable = true,
  backspaceRemoves = true,
  closeOnSelect = true,
  autoBlur = false,
  multi = false,
  joinValues = true,
  countries = countryOptions,
  placeholder = '',
  onChange = option => {},
  onOpen = () => {},
  onClose = () => {},
  arrowRenderer,
  valueComponent,
  valueRenderer,
  menuRenderer,
  size = null,
  removeSelected = true
}) => {
  return (
    <Select
      className={`CountrySelection ${size === 'small' ? 'CountrySelection--small' : ''}`}
      value={selected}
      multi={multi}
      clearable={clearable}
      joinValues={joinValues}
      options={getCountryOptions(countries)}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      disabled={disabled}
      valueKey="code"
      labelKey="name"
      arrowRenderer={props => {
        if (disabled) return null;
        return (arrowRenderer || defaultArrowRenderer)(props);
      }}
      valueComponent={valueComponent}
      valueRenderer={valueRenderer}
      menuRenderer={menuRenderer}
      placeholder={placeholder}
      backspaceRemoves={backspaceRemoves}
      closeOnSelect={closeOnSelect}
      onSelectResetsInput={closeOnSelect}
      autoBlur={autoBlur}
      removeSelected={removeSelected}
    />
  );
};

CountrySelection.propTypes = {
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  // We can't really know what selected should be, cause it depends on whether this is multi-select or not
  // selected: PropTypes.oneOfType([CountryShape, PropTypes.arrayOf(CountryShape), PropTypes.array]), // alpha2
  className: PropTypes.string,
  disabled: PropTypes.bool,
  backspaceRemoves: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  clearable: PropTypes.bool,
  joinValues: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  // custom country list
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string // alpha2
    })
  ),
  arrowRenderer: PropTypes.func,
  valueComponent: PropTypes.func,
  valueRenderer: PropTypes.func,
  menuRenderer: PropTypes.func,
  size: PropTypes.string,
  autoBlur: PropTypes.bool,
  removeSelected: PropTypes.bool
};

CountrySelection.defaultOptions = countryOptions;

export default CountrySelection;
