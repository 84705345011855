// @flow
import * as React from 'react';

import { IntlProvider } from '../../locales';

import classNames from 'classnames';

import './Modal.scss';

function resetScroll() {
  const el = document.querySelector('html');
  if (!el) {
    return;
  }
  // Reset scroll to where we were
  el.classList.remove('modal-open');
}

function lockScroll() {
  const el = document.querySelector('html');
  if (!el) {
    return;
  }
  el.classList.add('modal-open');
}

type Props = {
  isOpen: boolean,
  isBoxed?: boolean,
  onClose?: () => void,
  className?: string,
  children: React.Node,
  isClosable?: boolean
};

function Modal(props: Props) {
  const {
    isOpen = true,
    isBoxed = true,
    className = '',
    onClose = () => {},
    children,
    isClosable = true
  } = props;

  React.useEffect(() => {
    if (isOpen) {
      lockScroll();
    }

    return function cleanup() {
      resetScroll();
    };
  }, [isOpen]);

  return (
    <div
      className={classNames(`Modal modal`, className, {
        'is-active': isOpen
      })}>
      {onClose && <div className="modal-background" onClick={onClose} />}
      <div
        className={classNames('modal-card content', {
          box: isBoxed
        })}>
        {children}
      </div>
      {isClosable && <button className="modal-close" onClick={onClose} />}
    </div>
  );
}

export default Modal;

export function ModalWithIntlProvider(props: Props) {
  const { children, ...rest } = props;
  return (
    <IntlProvider>
      <Modal {...rest}>{children}</Modal>
    </IntlProvider>
  );
}
