import React from 'react';

import Icon from '../../../images/icons/TabsIconVideo';

import './Icons.scss';

const VideoIcon = ({ isActive, showAlert = false }) => {
  const iconStyles = isActive ? 'Icon VideoIcon TabIcon activeTabIcon' : 'Icon VideoIcon TabIcon';
  return (
    <span className={iconStyles}>
      <Icon />
      {showAlert && (
        <div className="spotWrapper">
          <div className="spot" />
        </div>
      )}
    </span>
  );
};

export default VideoIcon;
