import React from 'react';

import Icon from '../../../images/icons/DemographicsIconFemale';

import './Icons.scss';

const FemaleIcon = ({ isActive }) => {
  const iconStyles = isActive ? 'TabIcon mdTabIcon activeTabIcon' : 'TabIcon mdTabIcon';
  return (
    <span className={iconStyles}>
      <Icon />
    </span>
  );
};

export default FemaleIcon;
