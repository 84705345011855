import buildContainerReducer from '../../helpers/buildContainerReducer';

export default buildContainerReducer(
  {
    teams: null,
    isFetchingTeams: false,
    isUpdatingTeam: false,
    error: null
  },
  {
    error: null
  },
  ['team.fetchTeams', 'team.updateTeam']
);
