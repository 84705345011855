import React from 'react';

const TabsIconGame = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      d="M8 17v3h2v-3h3v-2h-3v-3H8v3H5v2h3zm1-9h14a8 8 0 1 1-5.292 14h-3.416A8 8 0 1 1 9 8zm16 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-4 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
      fill="#2D3767"
      fillRule="evenodd"
    />
  </svg>
);

export default TabsIconGame;
