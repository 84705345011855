// @flow
import * as React from 'react';
import type { ContentPlatform } from 'matchmade-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import classNames from 'classnames';
import moment from 'moment';

import CopyIcon from '../Icons/CopyIcon';
import VideoIcon from '../Icons/VideoIcon';
import SendIcon from '../Icons/SendIcon';

import { copyTextToClipboard } from '../../../helpers/browser';
import { generateApiPath, getTrackingUrl } from '../../../config';
import { userDateFormat } from '../../../helpers/formats';

import './VideoTab.scss';

import { ContentSubmissionStatusEnum } from '../../../constants';

type ContentSubmission = {
  url: string,
  status: string,
  deadline: string | null
};

type Props = {
  contentPlatform: ContentPlatform,
  videoId: string,
  trackingCode: string,
  agreementId: number,
  deadline: string,
  isVertical?: boolean,
  lastVideoPublishedAt: string,

  isPublic?: boolean,
  contentSubmission: ContentSubmission,

  title?: ?React.Element<*>,
  videoDue?: ?React.Element<*>,
  publishedMetrics?: ?React.Element<*>
};

const VideoTabTitleForCampaignCard = injectIntl(
  ({
    agreementId,
    contentPlatform,
    contentSubmission,
    intl,
    isPublic,
    videoId,
    deadline
  }: {
    agreementId: number,
    contentPlatform: ContentPlatform,
    contentSubmission: ContentSubmission,
    intl: IntlShape,
    isPublic?: boolean,
    videoId: string,
    deadline: string
  }) => {
    const deadlineFormatted = moment(deadline).format(userDateFormat);
    if (videoId && isPublic) {
      return <FormattedMessage id="influencer.campaign.videoReleased" tagName="p" />;
    }

    if (!contentSubmission) {
      return (
        <FormattedMessage
          id={`influencer.campaign.sendUnlistedVideo.${contentPlatform}`}
          defaultMessage={intl.formatMessage({
            id: 'influencer.campaign.sendUnlistedVideo.default'
          })}
          tagName="p"
        />
      );
    }

    const { url, status, deadline: cstDeadline } = contentSubmission;

    if (
      [ContentSubmissionStatusEnum.IN_REVIEW, ContentSubmissionStatusEnum.SUBMITTED].includes(
        status
      )
    ) {
      return <p>Please wait for your content to be approved, do not publish it until then</p>;
    } else if (status === ContentSubmissionStatusEnum.APPROVED) {
      return (
        <p>
          Content has been approved, please publish it on the agreed publishing date:{' '}
          {deadlineFormatted}
        </p>
      );
    }

    let linkText;
    if (status === ContentSubmissionStatusEnum.REQUEST_FOR_CHANGES) {
      linkText = 'Please re-submit draft';
    } else if (status === ContentSubmissionStatusEnum.PENDING) {
      // We need some changes from you, how to phrase?
      linkText = cstDeadline
        ? `Submit draft latest by ${moment(cstDeadline).format(userDateFormat)}`
        : 'Submit draft for review';
    }

    const className = classNames(
      'is-size-6 is-flex is-justify-content-center is-align-items-center',
      { 'has-text-warning': status === ContentSubmissionStatusEnum.REQUEST_FOR_CHANGES }
    );
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className={className}>
        <span className="mr-2">{linkText}</span> <SendIcon />
      </a>
    );
  }
);

// For campaign card
const VideoDue = injectIntl(
  ({
    deadline,
    intl,
    contentPlatform
  }: {
    contentPlatform: ContentPlatform,
    deadline: string,
    intl: IntlShape
  }) => {
    const deadlineMoment = moment.utc(deadline);
    const daysDue = moment().diff(deadlineMoment, 'day');

    const defaultMessageLate = intl.formatMessage({
      id: 'influencer.campaign.videoIsLate.default'
    });
    return (
      <div className="VideoTab__center VideoTab__column VideoTab__is-late has-text-danger">
        <div className="tag is-danger VideoTab__is-late-tag">
          <FormattedMessage
            id={`influencer.campaign.videoIsLate.${contentPlatform}`}
            defaultMessage={defaultMessageLate}
            tagName="p"
          />
        </div>
        <FormattedMessage id="influencer.campaign.days" values={{ days: daysDue }} tagName="p" />
      </div>
    );
  }
);

export function VideoTabForCampaignCard(props: Props) {
  const deadlineMoment = moment.utc(props.deadline);
  const isVideoDue = deadlineMoment.isBefore(moment(), 'day');

  return (
    <VideoTab
      {...props}
      title={<VideoTabTitleForCampaignCard {...props} />}
      videoDue={!props.videoId && isVideoDue ? <VideoDue {...props} /> : null}
    />
  );
}

const VideoTab = injectIntl(
  ({
    contentPlatform,
    videoId,
    trackingCode,
    deadline,
    isVertical = false,
    intl,
    title,
    videoDue,
    publishedMetrics
  }: {
    ...Props,
    intl: IntlShape
  }) => {
    const videoLink =
      contentPlatform === 'youtube'
        ? `https://www.youtube.com/watch?v=${videoId}`
        : `https://www.twitch.tv/videos/${videoId}`;

    const deadlineMoment = moment.utc(deadline);

    const columnStyle = classNames('VideoTab__center VideoTab__column p-2', {
      'VideoTab__column__is-vertical': isVertical
    });

    const defaultVideoEtaMessage = intl.formatMessage({
      id: 'influencer.campaign.videoEta.default'
    });

    const videoLinkOrDeadlineRow = videoId ? (
      <div className={columnStyle}>
        <FormattedMessage id="influencer.card.toggleVideo" tagName="p" />
        <div className="VideoTab__video-separator">
          <a className="button" href={videoLink} target="_blank" rel="noopener noreferrer">
            <VideoIcon />
          </a>
          <button className="button" onClick={() => copyTextToClipboard(trackingUrl)}>
            <CopyIcon tooltipI18nString="influencer.campaign.copyURL" withTooltip />
          </button>
        </div>
      </div>
    ) : deadlineMoment.isValid() ? (
      <div className={columnStyle}>
        <FormattedMessage
          id={`influencer.campaign.videoEta.${contentPlatform}`}
          tagName="p"
          defaultMessage={defaultVideoEtaMessage}
        />
        <h3>{deadlineMoment.format(userDateFormat)}</h3>
      </div>
    ) : null;

    const contentStyle = isVertical ? 'VideoTab__content__is-vertical' : 'VideoTab__content';

    const trackingUrl = getTrackingUrl(trackingCode);
    const downloadUrl = generateApiPath(`/agreements/${trackingCode}/download/qr.png`);

    return (
      <div className="VideoTab">
        <div className="VideoTab__title">
          <h2 className="has-text-weight-bold">{title}</h2>
        </div>
        <div className={contentStyle}>
          {publishedMetrics}
          {videoLinkOrDeadlineRow}
          {videoDue}
          <div className={columnStyle}>
            <p className="pb-2">
              Tracking link: <CopyableTrackingLink url={trackingUrl} />
            </p>
            <a
              className="VideoTab__download-qr-code"
              href={downloadUrl}
              target="_blank"
              rel="noopener noreferrer">
              Download QR code
            </a>
            <QrCode
              imgUrl={`${generateApiPath(`/agreements/${trackingCode}/qr.png`, { scale: 2 })}`}
              href={`${downloadUrl}`}
            />
          </div>
        </div>
      </div>
    );
  }
);

export const CopyableTrackingLink = ({ url }: { url: string }) => (
  <span className="is-flex is-align-items-center">
    <code className="has-text-info">{url}</code>
    <button
      className="button VideoTab__copy-tracking-url is-ghost"
      onClick={() => copyTextToClipboard(url)}>
      <CopyIcon withTooltip tooltipI18nString="influencer.campaign.copyURL" />
    </button>
  </span>
);

export const QrCode = ({ href, imgUrl }: { href: string, imgUrl: string }) => (
  <a className="VideoTab__qr-code-link" href={href} target="_blank" rel="noreferrer">
    <img src={imgUrl} alt="qr code" />
  </a>
);
