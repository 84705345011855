import { createApiAction } from './helper';

export const getCampaignsByName = createApiAction('GET_CAMPAIGNS_BY_NAME', (name = '') => {
  return {
    path: `/campaigns/related?name=${name}`,
    options: {
      method: 'GET'
    }
  };
});
