// @flow
import type { Estimates } from '../types/estimates.flow';

export function getViewsFromEstimates(estimates: ?Estimates): number {
  return (
    (estimates &&
      estimates.freeToPlayMobile &&
      estimates.freeToPlayMobile.views &&
      estimates.freeToPlayMobile.views.youtube) ||
    0
  );
}
