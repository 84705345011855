import { FormattedMessage } from 'react-intl';
import Avatar from './Avatar';
import React, { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import useInterval from '../../hooks/useInterval';

import './ChatMessage.scss';
import './NewChatMessage.scss';

import Button, { Color } from '../common/Button';

import { getContentSubmission } from '../campaign/CampaignParticipatingCard';
import { ContentSubmissionStatusEnum } from '../../constants';
import { NotificationAlert } from '../common/NotificationBanner';
import isValidURL from '../../helpers/urlValidator';
import SendIcon from '../common/Icons/SendIcon';

const MAX_LENGTH = 1500;
const DELAY_SECONDS_FOR_UNDO = 5;

type UndoBlockProps = {
  onUndo: Function,
  onApply: Function,
  delaySeconds: number
};

function UndoBlock({ onUndo, onApply, delaySeconds }: UndoBlockProps) {
  const [count, setCount] = useState(0);
  const [delay, setDelay] = useState(1000);
  const stopInterval = () => setDelay(null);
  const apply = () => {
    stopInterval();
    onApply();
  };
  const undo = () => {
    stopInterval();
    onUndo();
  };

  useInterval(() => setCount(count + 1), delay);
  useEffect(() => {
    if (count === delaySeconds) {
      apply();
    }
  }, [count]);

  return (
    <div className="NewChatMessage__undo-block">
      Sending message in {delaySeconds - count} sec...{' '}
      <button className="link-button" onClick={undo}>
        Undo
      </button>
    </div>
  );
}

type ChatMessageProps = {
  avatarUrl: string,
  errorMessage: string,
  disabled: boolean,
  sendingMessage: boolean,
  sendMessage: Function,
  loading: boolean,
  campaignAgreement: any,
  isWhitelabelAdmin: boolean
};
function NewChatMessage({
  avatarUrl = '',
  errorMessage = '',
  disabled = false,
  sendingMessage = false,
  loading = false,
  isWhitelabelAdmin = false,
  sendMessage,
  campaignAgreement
}: ChatMessageProps) {
  const [value, setValue] = useState('');
  const [waiting, setWaiting] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (!disabled || !sendingMessage) {
      focusTextArea();
    }

    if (!errorMessage && sendingMessage) {
      setValue('');
    }
  }, [disabled, sendingMessage, errorMessage]);

  const focusTextArea = () => textareaRef?.current?.focus();
  const onChange = event => setValue(event.target.value);
  const startWaiting = () => setWaiting(true);
  const stopWaiting = () => setWaiting(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    stopWaiting();
    sendMessage(value);
  };

  const { data: contentSubmission } = useAsync({
    promiseFn: getContentSubmission,
    agreementId: campaignAgreement.id,
    agreementStatus: campaignAgreement.status
  });

  const isInputDisabled = disabled || sendingMessage || waiting;
  const isButtonDisabled = !value || !value.trim() || disabled || waiting;
  const controlClass = ['control', isInputDisabled && 'is-disabled', sendingMessage && 'is-loading']
    .filter(Boolean)
    .join(' ');
  const error = errorMessage && (
    <small className="NewChatMessage__failed">
      <FormattedMessage id="chat.failedToSend" />
    </small>
  );
  const sendBlock = isWhitelabelAdmin ? (
    <>
      {waiting && (
        <UndoBlock onUndo={stopWaiting} onApply={onSubmit} delaySeconds={DELAY_SECONDS_FOR_UNDO} />
      )}
      <Button color={Color.PRIMARY} disabled={isButtonDisabled} onClick={startWaiting}>
        <FormattedMessage id="chat.send" />
      </Button>
    </>
  ) : (
    <>
      <Button color={Color.PRIMARY} disabled={isButtonDisabled} onClick={onSubmit}>
        <FormattedMessage id="chat.send" />
      </Button>
    </>
  );

  return (
    <div className="ChatMessage NewChatMessage is-reverse" onClick={focusTextArea}>
      <Avatar url={avatarUrl} isAdmin={isWhitelabelAdmin} />
      <form className="ChatMessage__content-box box" onSubmit={onSubmit}>
        <div className="NewChatMessage__input">
          <div className="field">
            <div className={controlClass}>
              <textarea
                className="textarea"
                ref={textareaRef}
                value={value}
                onChange={onChange}
                maxLength={MAX_LENGTH}
                disabled={disabled}
              />
              <div className="NewChatMessage__characters-counter">
                {value.length}/{MAX_LENGTH}
              </div>
            </div>
          </div>
        </div>
        <div className="NewChatMessage__button">
          {error}
          {sendBlock}
        </div>
        {!isWhitelabelAdmin &&
          contentSubmission &&
          [
            ContentSubmissionStatusEnum.PENDING,
            ContentSubmissionStatusEnum.REQUEST_FOR_CHANGES
          ].includes(contentSubmission.status) &&
          isValidURL(value) && (
            <NotificationAlert
              link={contentSubmission.url}
              openInNewTab
              title={'Do you have content to submit for review?'}
              actionText={
                <>
                  <span className="mr-2">
                    {contentSubmission.status === ContentSubmissionStatusEnum.PENDING
                      ? 'Submit draft'
                      : 'Re-submit draft'}
                  </span>
                  <SendIcon />
                </>
              }
              buttonColor={
                contentSubmission.status === ContentSubmissionStatusEnum.PENDING
                  ? Color.PRIMARY
                  : Color.WARNING
              }>
              Please use our form instead by clicking this button, it will help with getting your
              content reviewed faster.
            </NotificationAlert>
          )}
      </form>
    </div>
  );
}

export default NewChatMessage;
