import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import uniqueId from 'lodash/uniqueId';

import Tooltip from './Tooltip';

import './EmailCopyIcon.scss';

class EmailCopyIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false
    };

    this._timeout = null;

    this.id = 'tooltip-' + uniqueId();
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  onCopy = () => {
    this.setState({ copied: true });
    // We don't want to show tooltip forever, so reset
    // state after 3 seconds or so.
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  // Clicking copies email address as is
  // Cmd-Clicking or Ctrl-Clicking opens "mailto:" link
  openMailtoLink = event => {
    if (event.metaKey || event.ctrlKey) event.stopPropagation();
    else if (event.type === 'click') event.preventDefault();
  };

  render() {
    const copiedText = this.state.copied ? 'Copied!' : 'Click to copy';

    return (
      <CopyToClipboard text={this.props.value} onCopy={this.onCopy}>
        <span className="EmailCopyIcon">
          <Tooltip
            hideOnClickOutside={false}
            tooltip={
              <span className="EmailCopyIcon__tooltip">{`${this.props.value}\n${copiedText}`}</span>
            }>
            <a id={this.id} onClick={this.openMailtoLink} href={`mailto:${this.props.value}`}>
              <i className="material-icons">email</i>
            </a>
          </Tooltip>
        </span>
      </CopyToClipboard>
    );
  }
}

EmailCopyIcon.propTypes = {
  value: PropTypes.string.isRequired
};

export default EmailCopyIcon;
