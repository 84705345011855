// @flow
import type { ContentType } from '../../types/campaignAgreement.flow';
import type { Status } from '../../types/dealItem.flow';

import { FormattedMessage } from 'react-intl';
import React from 'react';

import { checkIfInfluencerOrManager } from '../../helpers/user';
import ANALYTICS from '../../helpers/analytics';
import GAOutboundLink from '../common/GAOutboundLink';
import PhotoIcon from '../common/Icons/PhotoIcon';
import PhotoLibraryIcon from '../common/Icons/PhotoLibraryIcon';
import VideoIcon from '../common/Icons/VideoIcon';
import WithTimestampUpdate from './common/WithTimestampUpdate';

import { ContentType as ContentTypeEnum, DealItemStatus } from '../../constants';

import './PublishedContentMessage.scss';

type Props = {
  channelName: string,
  contentText: string,
  contentUrl: string,
  contentType: ContentType,
  created?: string,
  timestamp?: string,
  updated?: boolean,
  reverse?: boolean,
  status: Status
};

function getPublishedContentIcon(contentType: string) {
  switch (contentType) {
    case ContentTypeEnum.INSTAGRAM_POST:
      return <PhotoIcon />;
    case ContentTypeEnum.INSTAGRAM_STORY:
      return <PhotoLibraryIcon />;
    default:
      return <VideoIcon />;
  }
}

const PublishedContentMessage = ({
  created = 'a few seconds ago',
  reverse = false,
  updated,
  channelName,
  contentText,
  contentUrl,
  contentType,
  timestamp,
  status
}: Props) => {
  // timestamp comes from WithTimestampUpdate
  const time = timestamp || created;

  const icon = updated ? <i className="fa fa-rotate-left" /> : getPublishedContentIcon(contentType);

  const eventLabel = checkIfInfluencerOrManager()
    ? ANALYTICS.LABEL.INFLUENCER_CLICKED_VIDEO_LINK_IN_PUBLISHED_VIDEO_MESSAGE
    : ANALYTICS.LABEL.PUBLISHER_CLICKED_VIDEO_LINK_IN_PUBLISHED_VIDEO_MESSAGE;

  let message = null;
  switch (status) {
    case DealItemStatus.PUBLISHED:
      message = (
        <FormattedMessage id="chat.publishedVideo.videoIsPublic" values={{ actor: channelName }} />
      );
      break;
    case DealItemStatus.IN_REVIEW:
      message = (
        <FormattedMessage
          id="chat.publishedVideo.videoIsReadyForReview"
          values={{ actor: channelName }}
        />
      );
      break;
    default:
      break;
  }

  if (!message) {
    return null;
  }

  return (
    <div className={`PublishedContentMessage${reverse ? ' is-reverse' : ''}`}>
      <div className="PublishedContentMessage__icon">
        <span className="icon is-medium">{icon}</span>
      </div>
      <div className="PublishedContentMessage__content">
        <div className="PublishedContentMessage__negotiation">
          <p>{message}</p>
          <p className="is-truncated">
            <GAOutboundLink
              href={contentUrl}
              eventLabel={eventLabel}
              target="_blank"
              rel="noopener noreferrer">
              {contentText}
            </GAOutboundLink>
          </p>
        </div>
        <hr />
        <div className="PublishedContentMessage__created">
          <time>{time}</time>
        </div>
      </div>
    </div>
  );
};

export default WithTimestampUpdate(PublishedContentMessage, 'created');
