// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { Color } from '../common/Button';
import Modal from '../common/Modal';
import OnOffToggle from '../common/OnOffToggle';

import './TosConfirmationModal.scss';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onClick: () => void
};

function TosConfirmationModal(props: Props) {
  const { isOpen, onClose, onClick } = props;
  const [agreed, setAgreed] = React.useState(false);

  return (
    <Modal isOpen={isOpen} className="TosConfirmationModal" isClosable={false} isBoxed={false}>
      <header className="modal-card-head">
        <h3>
          <FormattedMessage id="tos.title" />
        </h3>
      </header>
      <section className="modal-card-body">
        <div>
          <FormattedMessage id="tos.informationText1" />
        </div>
        <div className="control">
          <div className="checkbox" onClick={() => setAgreed(!agreed)}>
            <OnOffToggle isOn={agreed} isDisabled={false} onClick={() => setAgreed(!agreed)} />
            <FormattedMessage
              id="tos.informationText2"
              values={{
                link: (
                  <button className="link-button" rel="noopener noreferrer" onClick={onClose}>
                    <FormattedMessage id="tos.title" />
                  </button>
                )
              }}
            />
          </div>
        </div>
      </section>
      <footer className="modal-card-foot">
        <Button
          color={Color.PRIMARY}
          onClick={onClick}
          className="action_button"
          disabled={!agreed}>
          <FormattedMessage id="tos.agree" tagName="strong" />
        </Button>
      </footer>
    </Modal>
  );
}

export default TosConfirmationModal;
