// @flow
import type { AppError } from '../actions/error';

import { addError, clearErrors } from '../actions/error';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function useError(): {
  showError: (error: AppError) => void,
  clearErrors: () => void
} {
  const dispatch = useDispatch();

  const showErrorFn = useCallback(
    error => {
      dispatch(addError.run(error));
    },
    [dispatch]
  );

  const clearErrorsFn = useCallback(() => {
    dispatch(clearErrors.run());
  }, [dispatch]);

  return {
    showError: showErrorFn,
    clearErrors: clearErrorsFn
  };
}
