import React from 'react';

const VisibilityIcon = ({ off }) => {
  return (
    <span className="icon Icon mdVisibilityIcon">
      <i className="material-icons">visibility{off ? '_off' : ''}</i>
    </span>
  );
};

export default VisibilityIcon;
