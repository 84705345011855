import React from 'react';

const CPAIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 119 55" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g transform="translate(2 -11)" fill="none" fillRule="evenodd">
        <g stroke={primaryColor} strokeLinecap="round" strokeWidth="4">
          <path
            d="M84.31 27.38l20.438 20.438c1.518 1.518 1.416 4.08-.228 5.724-1.643 1.644-4.206 1.746-5.724.228l-10.13-10.13C88.643 44.868 88.08 46.19 87 47c-1.346 1.01-2.425.47-4 0-.45 2.876-2.686 4.008-6.5 2.5-.5 2-1 3.5-3 3.5-1 0-2.5 0-3.5-1L47 29l15-14.896c4.098 4.173 6.785 6.896 8.06 8.17a3.243 3.243 0 0 1 1.44-.792c2.862-.71 5.736-1.378 8.615-2.02 1.285-.286 2.373-.825 3.247-1.83 1.05-1.206 2.201-2.322 3.252-3.528.6-.687 1.15-.729 1.918-.237 1.718 1.101 2.546 3.723 1.868 6.089-.765 2.668-2.561 4.562-4.622 6.258-.464.382-.939.75-1.468 1.166z"
            strokeLinejoin="round"
          />
          <path d="M70 22L56 36" />
        </g>
        <ellipse fill={primaryColor} cx="105" cy="62" rx="12" ry="4" />
        <path
          d="M17 29c0-4.647-3.5-8-8-8S.5 23.853.5 28.5s3 7.5 8.5 9.486c5.5 1.985 9 4.514 9 9.104 0 4.59-4.03 8.455-9 8.455s-9-3.786-9-8.455"
          stroke={props.primaryColor}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 21v-4M9 59v-3" stroke={primaryColor} strokeWidth="4" strokeLinecap="round" />
        <path d="M25 64l12-48" stroke={secondaryColor} strokeWidth="4" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default CPAIcon;
