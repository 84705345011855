// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { Color } from '../Button';

import './Toggles.scss';

export type Props<ValueType> = {
  labelIntl: string,
  value: ValueType,
  availableValues: { value: ValueType, labelIntl: string }[],
  isDisabled: boolean,
  onChange: (value: ValueType) => void
};

function Toggles<ValueType>({
  value,
  isDisabled,
  onChange,
  availableValues,
  labelIntl
}: Props<ValueType>) {
  // Show just a tag if we have only one available value instead of clickable buttons.
  const opts =
    availableValues.length > 1 ? (
      availableValues.map(availableValue => (
        <Button
          key={String(availableValue.value)}
          size="small"
          buttonClassName="Toggles__button is-rounded"
          color={value === availableValue.value ? Color.PRIMARY : Color.LIGHT}
          disabled={isDisabled}
          onClick={() => onChange(availableValue.value)}>
          <FormattedMessage id={availableValue.labelIntl} />
        </Button>
      ))
    ) : (
      <span className="tag Toggles__tag">
        <FormattedMessage id={availableValues[0].labelIntl} />
      </span>
    );

  return (
    <div className="field is-horizontal Toggles">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id={labelIntl} />
        </label>
      </div>
      <div className="field-body">
        <div className="field is-horizontal">{opts}</div>
      </div>
    </div>
  );
}

export default Toggles;
