import * as React from 'react';

function DynamicCollectionIcon(props) {
  return (
    <svg className="DynamicCollectionIcon" width={24} height={24} {...props}>
      <path
        d="M10 4l2 2h8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h6zm2 4l-4 6h4v4l4-6h-4V8z"
        fill="#2D3767"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default DynamicCollectionIcon;
