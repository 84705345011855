// @flow
import * as React from 'react';
import throttle from 'lodash/throttle';

export type ExtraProps = {|
  onScroll: (e: any) => void,
  style: {
    title: {
      opacity: number,
      display?: 'none' | 'block'
    },
    header: {
      height?: string, // rem
      top?: string,
      transform?: string,
      transition?: string
    },
    notification: {
      transform?: string,
      transition?: string
    },
    body: {
      transform?: string
    }
  }
|};

type State = {
  headerTitleOpacity: number,
  headerHeight: number,
  notificationScale: number,
  scrollTop: number,
  scrollingUp: boolean
};

const MAX_HEADER_HEIGHT = 11; // rem
const MIN_HEADER_HEIGHT = 6; // rem

const HEADER_HEIGHT_THRESHOLD = 46; // px
const SHRINK_HEADER_THRESHOLD = 150; // px

// only for CreateCampaign
// TODO switch to useState hook so that we can use HOC type
export default function changeStyleWhenScrolling<
  WrappedComponent: React.ComponentType<*>
>(options?: {
  injectedProp: string
}): (
  c: WrappedComponent
) => React.ComponentType<{
  ...$Diff<React.ElementConfig<WrappedComponent>, ExtraProps>,
  ...ExtraProps
}> {
  return Component => {
    class StyleInjectedComponent extends React.Component<
      $Diff<React.ElementConfig<WrappedComponent>, ExtraProps>,
      State
    > {
      constructor(props) {
        super(props);

        this.state = {
          headerTitleOpacity: 1,
          headerHeight: MAX_HEADER_HEIGHT,
          notificationScale: 1,
          scrollTop: 0,
          scrollingUp: true
        };
      }

      componentDidMount() {
        window.addEventListener('scroll', this.onScroll, { passive: true });
      }

      componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
      }

      // If throttling causes performance issues, try requestAnimationFrame approach instead:
      // https://css-tricks.com/debouncing-throttling-explained-examples/#article-header-id-7
      // This tries not to call setState unnecessarily, since we call this
      // function very-very-very often when user scrolls
      onScroll = throttle(e => {
        // a list of scrollable elements to ignore
        if (['Select-menu'].indexOf(e.target.className) !== -1) {
          return;
        }
        const scrollTop =
          e.target && e.target.scrollingElement && e.target.scrollingElement.scrollTop;
        const oldScrollTop = this.state.scrollTop;

        if (
          scrollTop < HEADER_HEIGHT_THRESHOLD ||
          (scrollTop > HEADER_HEIGHT_THRESHOLD && oldScrollTop <= HEADER_HEIGHT_THRESHOLD) ||
          (scrollTop < SHRINK_HEADER_THRESHOLD && oldScrollTop >= SHRINK_HEADER_THRESHOLD) ||
          (scrollTop > SHRINK_HEADER_THRESHOLD && oldScrollTop <= SHRINK_HEADER_THRESHOLD)
        ) {
          this.setState({ scrollTop });
        }
        if (
          (scrollTop >= SHRINK_HEADER_THRESHOLD && this.state.headerHeight === MAX_HEADER_HEIGHT) ||
          (scrollTop < SHRINK_HEADER_THRESHOLD && this.state.headerHeight === MIN_HEADER_HEIGHT)
        ) {
          this.setState({
            headerTitleOpacity: scrollTop < SHRINK_HEADER_THRESHOLD ? 1 : 0,
            headerHeight:
              scrollTop < SHRINK_HEADER_THRESHOLD ? MAX_HEADER_HEIGHT : MIN_HEADER_HEIGHT,
            notificationScale: scrollTop < SHRINK_HEADER_THRESHOLD ? 1 : 0.7
          });
        }
        if (
          (scrollTop >= HEADER_HEIGHT_THRESHOLD && oldScrollTop >= HEADER_HEIGHT_THRESHOLD) ||
          (scrollTop <= HEADER_HEIGHT_THRESHOLD && oldScrollTop <= HEADER_HEIGHT_THRESHOLD)
        ) {
          return;
        }

        const scrollingUp = scrollTop < oldScrollTop;
        if (scrollingUp === this.state.scrollingUp) {
          return;
        }

        this.setState({
          scrollingUp
        });
      }, 16);

      render() {
        const { headerTitleOpacity, headerHeight, notificationScale } = this.state;

        const { injectedProp = 'style' } = options || {};

        const style = {
          title: {
            opacity: headerTitleOpacity,
            display: headerTitleOpacity === 0 ? 'none' : 'block'
          },
          header: {
            height: `${headerHeight}rem`,
            transform: `translate3d(0, ${Math.max(46 - this.state.scrollTop, 0)}px, 0)` // otherwise background magically spreads to the whole body :/
          },
          notification: {
            transform: `scale3d(${notificationScale}, ${notificationScale}, 1.0)`,
            transition: 'transform 0.2s ease-in-out'
          }
        };

        const stylePropName: string = injectedProp || 'style';

        const props = {
          ...this.props,
          [stylePropName]: style,
          onScroll: this.onScroll
        };

        return <Component {...props} />;
      }
    }

    return StyleInjectedComponent;
  };
}
