// @flow
import Button, { Color } from '../components/common/Button';
import Papa from 'papaparse';
import React from 'react';

class InvalidCsvDataError extends Error {}

export function handleFileRead(e: SyntheticInputEvent<HTMLInputElement>): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!e || !e.target || !e.target.files) {
      return reject();
    }
    const file = e.target.files[0];
    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string') {
        resolve(reader.result);
      }
    };
    reader.readAsText(file);
  });
}

// This is exported for tests
export function handleCsvParse(s: string) {
  return Papa.parse(s, { skipEmptyLines: true, header: true, dynamicTyping: true }).data;
}

export function CsvUpload({ onData }: { onData: (d: any) => void }) {
  return (
    <FileUpload
      onData={s => {
        try {
          onData(handleCsvParse(s));
        } catch (e) {
          // Throw only in case something unexpected happened,
          // invalid csv data is handled and logged in
          // handleCsvParse already
          if (!(e instanceof InvalidCsvDataError)) {
            throw e;
          }
        }
      }}
    />
  );
}

const FileUpload = (props: { onData: (d: string) => void }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { onData } = props;
  return (
    <div>
      <input
        type="file"
        multiple={false}
        hidden
        ref={e => {
          inputRef.current = e;
        }}
        onChange={async e => {
          const res = await handleFileRead(e);
          onData(res);
        }}
      />
      <Button
        onClick={() => (inputRef && inputRef.current && inputRef.current.click()) || undefined}
        color={Color.PRIMARY}>
        <p>Select CSV</p>
      </Button>
    </div>
  );
};
