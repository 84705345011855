import PropTypes from 'prop-types';
import React from 'react';

import './GameCategories.scss';

const GameCategories = props => {
  const categories = (props.categories || []).map(category => {
    return (
      <span key={category} className="tag is-rounded is-info">
        {category}
      </span>
    );
  });

  return <div className="GameCategories">{categories}</div>;
};

GameCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default GameCategories;
