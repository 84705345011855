// @flow
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import injectURLQueryParameter from '../hoc/injectURLQueryParameter';

import { fetchCampaign, fetchCampaignStatistics } from '../actions/campaign';
import { resetProps } from '../actions/common';
import { trackOpenAdvancedAnalytics } from '../helpers/mixpanelEvents';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';

import CampaignReport from '../components/report/CampaignReport';

type Props = {
  params: any,
  location: any,
  route: any,
  router: any,
  resetProps: any,
  campaign: any,
  campaignAnalytics: any,
  fetchCampaign(campaignId: number): any,
  fetchCampaignStatistics(campaignId: number, startDate: string, endDate: string): any
};

class PublisherCampaignReportPage extends Component<Props, {}> {
  componentDidMount() {
    const campaignId = this.props.params.id ? parseInt(this.props.params.id, 10) : null;
    if (campaignId && !this.props.campaign) {
      this.props.fetchCampaign(campaignId);
    }
    this.props.router.setRouteLeaveHook(this.props.route, this.props.resetProps);
    trackOpenAdvancedAnalytics();
  }

  render() {
    return <CampaignReport {...this.props} />;
  }
}

const mapStateToProps = generateStateToProps('publisherCampaignReportPage');

const mapDispatchToProps = generateDispatchToProps({
  fetchCampaign,
  fetchCampaignStatistics,
  resetProps
});

PublisherCampaignReportPage = injectURLQueryParameter(PublisherCampaignReportPage, { tab: 0 });
PublisherCampaignReportPage = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PublisherCampaignReportPage)
);

export default PublisherCampaignReportPage;
