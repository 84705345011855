// @flow
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import React from 'react';

import type { IntlShape } from 'react-intl';

import { checkIfInfluencerOrManager } from '../../helpers/user';
import Button, { Color } from '../common/Button';
import CloseIcon from '../common/Icons/CloseIcon';
import Modal from '../common/Modal';

import './DeclineOfferModal.scss';
import {
  DeclineInfluencerReasons,
  DeclinePublisherReasons
} from '@sharkpunch/matchmade-common/campaignAgreement';
import InfoIcon from '../common/Icons/InfoIcon';
import OnOffToggle from '../common/OnOffToggle';

import camelCase from 'lodash/camelCase';

type Props = {
  isOpen: boolean,
  isLoading: boolean,
  onDeclineCampaign: ({ reason: any }) => void,
  onClose: () => void,
  intl: IntlShape
};

const defaultState = {
  predefined: [],
  other: '',
  isOtherInputActive: false
};
type State = {
  predefined: string[],
  other: string,
  isOtherInputActive: boolean
};

class DeclineOfferModal extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  onSubmit = async e => {
    e.preventDefault();

    const { onDeclineCampaign } = this.props;
    const { predefined, other } = this.state;
    const reason = {};

    if (other) {
      reason.other = other;
    }
    if (predefined.length) {
      reason.predefined = predefined;
    }

    await onDeclineCampaign({ reason });

    this.onClose();
  };

  onClose = () => {
    this.setState(defaultState, () => this.props.onClose());
  };

  onChangeOther = e => {
    this.setState({
      other: e.target.value
    });
  };

  onChangePredefined = reason => {
    const { predefined } = this.state;
    const filteredReason = predefined.filter(x => x !== reason);

    if (predefined.length === filteredReason.length) {
      filteredReason.push(reason);
    }

    this.setState({
      predefined: filteredReason
    });
  };

  onToggleOtherInput = () => {
    this.setState(prevState => ({
      isOtherInputActive: !prevState.isOtherInputActive
    }));
  };

  isValid = () => {
    const { predefined, other, isOtherInputActive } = this.state;

    return isOtherInputActive ? !!other : predefined.length;
  };

  render() {
    const { predefined, other, isOtherInputActive } = this.state;
    const { isOpen, isLoading } = this.props;
    const reasons = checkIfInfluencerOrManager()
      ? DeclineInfluencerReasons
      : DeclinePublisherReasons;
    const message = checkIfInfluencerOrManager() ? (
      <FormattedMessage id="campaign.agreement.decline.infoMessageForInfluencer" />
    ) : (
      <FormattedMessage id="campaign.agreement.decline.infoMessageForPublisher" />
    );

    return (
      <Modal isOpen={isOpen} className="DeclineOfferModal" onClose={this.onClose}>
        <header className="modal-card-head">
          <h3>
            <FormattedMessage id="campaign.agreement.decline.popupHeader" tagName="strong" />
          </h3>
        </header>
        <form onSubmit={this.onSubmit}>
          {reasons.map(reason => {
            const checked = predefined.indexOf(reason) !== -1;

            return (
              <div className="field" key={reason}>
                <div className="control">
                  <label className="checkbox">
                    <OnOffToggle onClick={() => this.onChangePredefined(reason)} isOn={checked} />
                    <FormattedMessage id={`campaign.agreement.decline.${camelCase(reason)}`} />
                  </label>
                </div>
              </div>
            );
          })}
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <OnOffToggle onClick={this.onToggleOtherInput} isOn={isOtherInputActive} />
                <FormattedMessage id={`campaign.agreement.decline.otherReasons`} />
              </label>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <textarea
                value={other}
                disabled={!isOtherInputActive}
                className="textarea is-inverted"
                placeholder={this.props.intl.formatMessage({
                  id: 'campaign.agreement.decline.enterText'
                })}
                onChange={this.onChangeOther}
              />
            </div>
          </div>
          <div className="DeclineOfferModal__info">
            <InfoIcon />
            {message}
          </div>
          <footer className="modal-card-foot">
            <Button
              // We don't care about onclick, since this is a form
              onClick={() => {}}
              type="submit"
              color={Color.PRIMARY}
              className="action_button"
              disabled={!this.isValid()}
              loading={isLoading}>
              <FormattedMessage
                id="admin.campaign.modal.sendEmailToInfluencers.button"
                tagName="strong"
              />
            </Button>
            <Button
              color={Color.PRIMARY}
              size="large"
              onClick={this.onClose}
              className="close_button">
              <CloseIcon />
            </Button>
          </footer>
        </form>
      </Modal>
    );
  }
}

export default injectIntl(DeclineOfferModal);
