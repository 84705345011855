// @flow
import * as React from 'react';
import TermsNavigation from './TermsNavigation';

import './TermsContent.scss';

const navItems = [
  { id: 'introduction', value: 'Introduccion' },
  { id: 'definitions', value: 'Definitions' },
  { id: 'background', value: 'Background and purpose' },
  { id: 'rights', value: 'Rights and obligations of parties' },
  { id: 'payment', value: 'Payment to manager' },
  { id: 'confidentiality', value: 'Confidentiality' },
  { id: 'intellectualProperty', value: 'Intellectual property rights' },
  { id: 'term', value: 'Term and termination' },
  { id: 'warranties', value: 'Warranties' },
  { id: 'liability', value: 'Limitation of liability' },
  { id: 'governingLaw', value: 'Governing law and dispute resolution' },
  { id: 'miscellaneous', value: 'Miscellaneous' }
];

const InfluencerManagerTermsContent = () => {
  return (
    <div className="columns TermsContent">
      <div className="column TermsContent__navigation-column">
        <TermsNavigation items={navItems} />
      </div>
      <div className={`column TermsContent__content-column`}>
        <div className="TermsContent__content content">
          <h1>Matchmade terms of service for managers and agencies</h1>
          <h2 id="introduction">Introduction</h2>
          <p>
            These Terms of Service apply to any use of the Matchmade Platform by the Manager or any
            other services provided by Matchmade under the Agreement. The purpose of this Agreement
            is to agree on the terms and conditions pursuant to which Manager receives user rights
            to Matchmade Platform and may act as a representative and account manager of
            Influencer(s) by negotiating and closing Independent Representation Agreements and
            Matchmade Representation Agreements with Advertisers on behalf of Influencer(s) by using
            Matchmade Platform.
          </p>
          <p>
            By accepting these Terms of Service as part of the sign-up procedure or login to the
            Matchmade Platform (e.g. by clicking “I agree” or a similar box in Matchmade Platform),
            or by accessing or using the Matchmade Platform in any manner, a binding contract is
            formed between Matchmade and the Manager. When agreeing to these Terms of Service, you
            hereby warrant to have the required authority to conclude the Agreement on behalf of
            Manager you are representing.
          </p>
          <p>
            These Terms of Service shall form and constitute a binding agreement (hereinafter the
            <strong>“Agreement”</strong>), independently or jointly with a Service Agreement
            concluded between the Parties, if any.
          </p>
          <h2 id="definitions">Definitions</h2>
          <p>
            “Advertiser” means a company looking to promote their products or services by working
            with Influencers.
          </p>
          <p>
            “Influencer” means an influencer, e.g. a video content creator who is advertising the
            Advertiser’s product(s), service(s) or brand(s) and who Manager represents.
          </p>
          <p>
            “Manager” means the authorized manager or agency, representing the Influencer(s), as
            specified in connection with Manager’s registration to Matchmade Platform pursuant to
            Section 3.2 of this Agreement.
          </p>
          <p>“Party” shall mean Influencer or Matchmade (jointly the “Parties”).</p>
          <p>
            “Influencer Accounts” means the Matchmade customer accounts of Influencer(s) Manager is
            entitled to represent and manage on Matchmade Platform under this Agreement.
          </p>
          <p>
            “Campaign Fee(s)” means the remuneration Advertiser and Manager have agreed on for a
            campaign or other co-operation conducted between Advertiser and Manager, and which the
            Advertiser pays to Matchmade and Matchmade pays further to Manager, as set out in this
            Agreement.
          </p>
          <p>
            “Matchmade Platform” means Matchmade’s online service for connecting video content
            creators with Advertisers, including the online platform on which Influencer Accounts
            can be managed and Independent Representation Agreements and Matchmade Representation
            Agreements concluded and administered.
          </p>
          <p>
            “Individual Manager” shall mean Manager that has registered to Matchmade platform as an
            individual, ie. files the revenue generated under this Agreement as personal income to
            the relevant authorities, receives the payment for Campaign Fee(s) directly to a
            personal account or to a service that is in the individual’s personal use, and has not
            engaged in an agreement with a third party to perform Campaign(s) under this Agreement.
          </p>
          <p>
            “Manager Payment” shall mean payment option(s) available on Matchmade Platform indicated
            to be for the use of Individual Manager(s).
          </p>
          <p>
            “Management Company” shall mean Manager that has registered to Matchmade platform as a
            business entity, ie. files the revenue generated under this Agreement as business income
            to the relevant authorities, receives the payment for Campaign Fee(s) directly to a
            business account or to a service that is in business use, or has engaged in an agreement
            with a third party to perform Campaign(s) under this Agreement.
          </p>
          <p>
            “Manager Invoicing” shall mean payment option(s) available on Matchmade Platform
            indicated to be for the use of Management Company.
          </p>
          <p>
            “Independent Representation Agreement” means a final binding commercial agreement or an
            otherwise commercially beneficial arrangement concluded by Manager on behalf of an
            Influencer for a campaign which the Manager has independently created with an
            Advertiser. Manager has discovered the campaign independently without using Matchmade
            Platform and without other contributions by Matchmade.
          </p>
          <p>
            “Matchmade Representation Agreement” means a final binding commercial agreement or an
            otherwise commercially beneficial arrangement Manager concludes on Matchmade Platform on
            behalf of an Influencer with an Advertiser discovered by using Matchmade Platform. A
            Matchmade Representation Agreement is formed on Matchmade Platform by clicking “Agree”
            or similar or otherwise expressing consent in connection to Manager receiving an offer
            or discovering an offer for an Advertiser campaign on Matchmade Platform thus forming a
            binding agreement.
          </p>
          <h2 id="background">Background and purpose</h2>
          <p>
            The purpose of this Agreement is to agree on the terms and conditions pursuant to which
            Manager receives user rights to Matchmade Platform and may act as a representative and
            account manager of Influencer(s) by negotiating and closing Independent Representation
            Agreements and Matchmade Representation Agreements with Advertisers on behalf of
            Influencer(s) by using Matchmade Platform.
          </p>
          <p>
            For clarity, Parties shall be deemed independent contractors and under no circumstances
            shall Manager be deemed an employee or agent of Matchmade. Manager shall be solely
            responsible for its tax obligations.
          </p>
          <p>
            Parties shall co-operate in good faith in respect to activities governed by this
            Agreement.
          </p>
          <h2 id="rights">Rights and obligations of parties</h2>
          <p>
            Manager shall have the right to access Influencer Account(s) in order to close
            Independent Representation Agreements and negotiate and close Matchmade Representation
            Agreements with Advertisers. Manager shall not, however, be able to access Influencer
            Account(s) prior to a confirmation made by Influencer(s) on Influencer Account(s)
            stating that Manager is entitled to act as the representative of Influencer.
          </p>
          <p>
            When registering to the Matchmade Platform, Manager shall provide true, accurate and
            complete information as prompted by the registration process and update such information
            when required. The individual signing or otherwise accepting the Agreement and the Terms
            of Service on behalf of Manager warrants to have the requisite authority to represent
            the Manager registering to the Matchmade Platform.
          </p>
          <p>
            After Manager has received access to the Matchmade Platform and Influencer(s) has/have
            confirmed that Manager is entitled to act as a representative as set out in section 3.1
            above, Manager may manage Influencer Accounts and add marketing campaigns and conclude
            Independent Representation Agreements and Matchmade Representation Agreements via
            Matchmade Platform. Manager may manage the marketing campaigns and the requirements and
            possibilities thereof, e.g. by restricting or enabling Influencer’s possibilities to
            participate in campaigns not managed by Manager, by using the settings of the Matchmade
            Platform. Manager shall remain fully liable for receiving the necessary authorizations
            from Influencer for the management of such settings and possibilities. Manager shall be
            fully liable towards the Influencer and the Advertiser for all actions Manager engages
            in or commits to on behalf of any Influencer on or via Matchmade Platform.
          </p>
          <p>
            Influencer or Manager may at any time during the validity of this Agreement notify
            Matchmade in writing (including e-mail) that Manager no longer represents the
            Influencer. After such notification, Manager may not manage the Influencer Accounts
            and/or otherwise represent the Influencer.
          </p>
          <p>
            Manager’s rights under this Agreement shall be non-exclusive and nothing set out in this
            Agreement prevents Matchmade or its affiliates from conducting same or similar
            co-operation with any other party or selling and advertising Matchmade Platforms in any
            territory.
          </p>
          <p>
            To the extent Manager has been granted any access to Matchmade Platform by Matchmade,
            Manager shall comply with all use restrictions set out in Matchmade’s end-user license
            agreement and/or general terms of service in force from time to time.
          </p>
          <p>
            Manager agrees to indemnify and hold Matchmade harmless from any direct or indirect
            liability, claim or damage arising from Independent Representation Agreements and
            Matchmade Representation Agreements or any other actions Manager engages in or commits
            on behalf of any Influencer whose Influencer Account Manager manages and administrates.
            Manager shall conclude the Matchmade Representation Agreement and Independent
            Representation Agreements independently and although such agreements would be concluded
            on or via Matchmade Platform, Manager shall carry all liabilities and obligations
            arising out of such agreements. For clarity, Matchmade acts as a platform provider and
            the agreements Manager concludes with Advertisers shall be made by and between the
            Manager and the relevant Advertiser.
          </p>
          <p>
            For clarity, Manager shall have no authority to conclude customer agreements or any
            other co-operation agreements on behalf of Matchmade, or in any way bind Matchmade
            towards third parties.
          </p>
          <p>
            When exercising its rights and fulfilling its obligations hereunder, Manager shall
            comply with all laws, including without limitation all regulations relating to
            anti-bribery and shall not engage in any illegal activity.
          </p>
          <p>
            Matchmade retains the sole discretion to modify, update, or change any or all parts of
            Matchmade Platform as well as its customer terms and conditions at any time. Matchmade
            shall have the right to suspend or terminate Manager’s access to Matchmade Platform as
            well as the right to terminate this Agreement in case of nonpayment or payment delays by
            Manager to any Influencer(s), when Influencer(s) notify Matchmade of such nonpayment or
            payment delays.
          </p>
          <p>
            Neither Party shall issue any press release or make any other written public statement
            with respect to this Agreement without a written consent from the other Party.
          </p>
          <p>
            For clarity, Matchmade shall have the sole authority to decide on pricing, fees and
            terms and conditions for Matchmade Platforms.
          </p>
          <p>
            If Manager forms a Matchmade Representation Agreement in a CPI-based Campaign model, the
            following shall apply: A qualified install is defined as an individual person who (i)
            accesses the application distribution service (Google Play or Apple App Store) through
            the partner’s Campaign tracking link; (ii) installs and launches the promoted
            application directly as a result of following the partner’s Campaign tracking link while
            connected to the Internet; (iii) is not a robot, script, or otherwise computer
            generated; (iv) is not determined by Matchmade or the Advertiser later to be fraudulent,
            unqualified or a duplicate. Qualified installs will be attributed to Influencer's
            Content for 180 days following Content's publishing date.
          </p>
          <p>
            If Manager forms a Matchmade Representation Agreement in a CPM-based Campaign model, the
            following shall apply: Campaign Fee will be formed of the agreed CPM (cost-per-mille)
            and every thousand views the Content generates on its content distribution platform.
            Matchmade shall track and pay to Manager, views generated during a period of 30 days
            following the Content's publishing.
          </p>
          <h2 id="payment">Payment to manager</h2>
          <p>
            With respect to Matchmade Representation Agreements concluded by Manager via Matchmade
            Platform, Matchmade is entitled to Campaign Fee(s). Matchmade shall invoice the Campaign
            Fee from the Advertiser. Matchmade shall pay the Manager the Campaign Fee. Manager shall
            pay Influencer the remuneration agreed upon between the Manager and the Influencer.
          </p>
          <p>
            Matchmade shall provide or otherwise make available to Manager reports of Campaign Fees
            actually accrued and received from campaigns concluded with Influencers under this
            Agreement. Payment shall only be made to Manager after the Advertiser has paid the
            Campaign Fee(s) Matchmade whereupon the payment is payable against using one of the
            options available on Matchmade Platform.
          </p>
          <p>
            Manager acknowledges that the provision of correct payment details and adherence to
            Matchmade’s instructions (such as but not limited to instructions regarding Manager
            Payment and Manager Invoicing) for receiving payment is Influencer’s sole
            responsibility. Manager further acknowledges that failure to provide correct payment
            details or follow Matchmade’s instructions may result in a delay in receiving payment,
            for which Matchmade bears no responsibility. Matchmade shall ask for correction from
            Manager, and re-attempt payment of Campaign Fee(s) within 30 days of receiving such
            correction from Manager. In case the Influencer fails to provide correct payment details
            or other information requested by Matchmade for the duration of 180 after a notice given
            by Matchmade, the Campaign Fee(s) will be deemed as forfeited.
          </p>
          <p>
            Further, Manager agrees that Matchmade will not provide additional documentation for
            fulfilling tax and other governmental and statutory obligations, other than what is made
            readily available on Matchmade Platform, for the work (eg. created Content) Influencer
            has performed or for the Campaign Fee(s) Influencer has received under this Agreement.
          </p>
          <p>
            For the avoidance of doubt, Matchmade shall only pay Manager the Campaign Fees which the
            Advertiser has paid to Matchmade. In case the Advertiser refuses to pay the Campaign Fee
            due to Influencer’s or Manager’s breach of Matchmade Representation Agreement or any
            fraudulent or inappropriate conduct, including fraudulent actions aiming to increase any
            installations or downloads, Advertiser’s payment issues or defaults (bankruptcy etc.) or
            any other reason, Matchmade shall have no obligation to pay Manager or the Influencer
            the Campaign Fee or any other remuneration or sums. Matchmade shall not have the
            obligation to pay Manager or the Influencer in case the Influencer has not followed the
            video guidelines or any other reasonable instructions issued by the Advertiser or
            Matchmade.
          </p>
          <p>
            With respect to Independent Representation Agreements, Matchmade shall not be entitled
            to a separate service fee.
          </p>
          <p>
            Manager shall be liable for its own costs and expenses arising out of the performance of
            Manager’s obligations under this Agreement.
          </p>
          <h2 id="confidentiality">Confidentiality</h2>
          <p>
            Neither Party shall disclose to any third parties any material or information received
            from the other Party and marked as confidential or which should be understood to be
            confidential (“Confidential Information”), and shall not use such Confidential
            Information for any other purposes than for the purposes of fulfilling its obligations
            under this Agreement. Both Parties shall limit disclosure of Confidential Information to
            their employees, consultants, officers or agents on a need to know basis only, provided
            that all such persons receiving Confidential Information shall be made aware of its
            confidential nature and the restrictions and obligations set out herein and shall be
            under similar restrictions and obligations no less stringent as those set forth herein.
            Upon termination of this Agreement, both Parties will at other Party’s request return
            (or destroy) any other Party’s Confidential Information in its possession.
          </p>
          <h2 id="intellectualProperty">Intellectual Property Rights</h2>
          <p>
            Matchmade is granting, and Manager is receiving, only those rights expressly set forth
            in this Agreement. Nothing in this Agreement shall be construed to grant Manager any
            other license or right to use Matchmade’s copyrights, drawings, know-how, technology,
            data, trade secrets, domain names, or Trademarks used by Matchmade in relation to
            Matchmade Platforms or otherwise (“Matchmade IPR”). All Matchmade IPR shall at all times
            remain the sole property of Matchmade.
          </p>
          <h2 id="term">Term and termination</h2>
          <p>
            This Agreement shall enter into force when duly signed by both Parties or when the
            Manager accepts these Terms of Service on the Matchmade Platform and continue in force
            until terminated by either Party with written notice to the other Party. The termination
            shall come into force when Manager has fulfilled all its obligations relating to the
            Independent Representation Agreements and/or Matchmade Representation Agreement. For
            clarity, Manager shall be liable for executing and finalizing all campaigns and actions
            agreed upon between Manager and the Advertiser at the time of the termination by either
            Party. The termination of this Agreement will not release either Party from any
            liability arising under this Agreement prior to termination.
          </p>
          <p>
            Either Party may, by written notice to the other, terminate this Agreement if the other
            Party materially breaches any provision of this Agreement and does not cure the breach
            within 14 days after receiving written notice thereof from the non-breaching Party.
          </p>
          <p>
            Notwithstanding the foregoing, Matchmade may terminate the Agreement with immediate
            effect in case of the bankruptcy, insolvency, liquidation or any kind of arrangement
            which is likely to affect substantially Manager’s ability to carry out its obligations
            under this Agreement.
          </p>
          <p>
            Upon termination or expiry of this Agreement, all rights and licenses granted to Manager
            shall terminate immediately.
          </p>
          <p>
            Any provisions of this Agreement intended to survive the expiry or termination of this
            Agreement shall do so, including sections 5 (Confidentiality), 6 (Intellectual Property
            Rights), 7 (Term and Termination), 9 (Limitation of Liability) and 10 (Governing Law and
            Dispute Resolution).
          </p>
          <h2 id="warranties">Warranties</h2>
          <p>
            As between Matchmade and Manager, Matchmade Platforms shall be provided on an “as is”
            basis. Matchmade makes no warranties or representations of any kind regarding the
            Matchmade Platforms and especially regarding any revenues or commercial benefits
            generated by the co-operation set out in this Agreement.
          </p>
          <h2 id="liability">Limitation of liability</h2>
          <p>
            Neither Party shall be liable to each other, whatever the cause thereof, for any loss of
            profit, business or goodwill or loss caused as a result of interruptions in business or
            any other indirect damages arising under this Agreement. This limitation shall not apply
            in cases of intentional misconduct or gross negligence and breaches of sections 6
            (Confidentiality) and 7 (Intellectual Property Rights).
          </p>
          <p>
            The Parties do not restrict their liability for any matter in respect of which, by
            mandatory law, it is not permitted to restrict its liability.
          </p>
          <p>
            Matchmade shall not be held liable for any damages or costs Manager or any Influencer
            has incurred due to non-payment of agreed fees by Advertiser.
          </p>
          <p>
            Manager agrees to indemnify and hold Matchmade harmless from and against any damages or
            costs Matchmade has incurred due to Manager’s or Influencer’s fraudulent conduct.
          </p>
          <h2 id="governingLaw">Governing law and dispute resolution</h2>
          <p>
            This Agreement shall be governed by and construed in accordance with the laws of
            Finland, except for its provisions on choice of law.
          </p>
          <p>
            Any dispute, controversy or claim arising out of or relating to this Agreement, or the
            breach, termination or validity thereof, shall be subject to amicable negotiations
            between the Parties. Should the negotiations not lead to a settlement between the
            Parties within 90 days, any dispute, controversy or claim arising out of or relating to
            this Agreement, or the breach, termination or validity thereof, shall be finally settled
            by arbitration in accordance with the Arbitration Rules of the Finland Chamber of
            Commerce. The number of arbitrators shall be one. The seat of arbitration shall be
            Helsinki, Finland. The language of the arbitration shall be English. The arbitral
            proceedings and award shall be confidential.
          </p>
          <h2 id="miscellaneous">Miscellaneous</h2>
          <p>
            Manager undertakes not to enter into an employment agreement or other agreement
            regarding regular provision of services with any employee of Matchmade. This no
            recruitment obligation shall remain in force during the validity of this Agreement as
            well as for a period of six (6) months after the termination or expiry of this
            Agreement. Should Manager enter into an employment or similar agreement with an employee
            of Matchmade, Manager shall pay Matchmade a compensation equal to six (6) times the
            monthly gross salary of such employee of Matchmade. The foregoing shall not apply to
            Matchmade’s employees employed by Manager as a result of generalized searches for
            employees by use of advertisements in the media which are not targeted to the employees
            of Matchmade, unless such searches are undertaken to circumvent the restrictions
            contained in or to conceal a violation of this Section 11.1.
          </p>
          <p>
            A Party not exercising its right under this Agreement shall not operate as a future
            waiver of such rights.
          </p>
          <p>
            All notices required or permitted under this Agreement will be in writing or made per
            mail or email to the addresses indicated on the contact information of the Parties
            stated in this Agreement or to another address notified by a Party.
          </p>
          <p>
            This Agreement represents the entire agreement between the Parties with respect to the
            subject matter hereof and supersedes all prior negotiations, understandings and
            agreements relating to the subject matter hereof.
          </p>
          <p>
            Any amendment to this Agreement shall be made in writing and be signed by the Parties.
          </p>
          <p>
            Manager may not assign this Agreement without the prior written consent of Matchmade.
            Matchmade may assign this Agreement to an affiliate or to a third party as part of a
            sale or transfer of its business operations pertaining to this Agreement.
          </p>
          <p>
            If any provision contained in this Agreement is deemed invalid or unenforceable in any
            respect under any applicable law, the validity and enforceability of the remaining
            provisions contained herein or therein shall not in any way be affected or impaired. The
            Parties hereby agree to make all reasonable efforts and take all necessary action to
            replace such provision by valid provisions which reflect, as far as possible, the
            intention of the provision so replaced.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfluencerManagerTermsContent;
