// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  INFLUENCER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_DECLINED,
  PUBLISHER_WITHDREW
} from '@sharkpunch/matchmade-common/campaignAgreement';

import { OUT_OF_DATE_NEGOTIATION } from '../../helpers/errorCode';
import RefreshAgreementNotification from './RefreshAgreementNotification';

const { MADE_NEW_OFFER, DECLINED, WITHDREW } = RefreshAgreementNotification.actions;

function getActionFromError(error, agreementId) {
  if (!error || error.code !== OUT_OF_DATE_NEGOTIATION || error.params.id !== agreementId) {
    return null;
  }

  let action = null;
  switch (error.params.status) {
    case PUBLISHER_DECLINED:
    case INFLUENCER_DECLINED:
      action = DECLINED;
      break;
    case PUBLISHER_WITHDREW:
    case INFLUENCER_WITHDREW:
      action = WITHDREW;
      break;
    default:
      action = MADE_NEW_OFFER;
      break;
  }
  return action;
}

type Props = {
  agreementId: number,
  actor: string,
  className?: string,
  onRefresh?: () => void,
  children: React.Node
};

const CheckForLatestAgreement = (props: Props) => {
  const { agreementId, className = '', actor, onRefresh = () => {} } = props;
  const { errors } = useSelector(state => state.layout);
  const [action, setAction] = React.useState(null);
  const [needToRefresh, setNeedToRefresh] = React.useState(false);

  React.useEffect(() => {
    for (const error of errors) {
      setAction(getActionFromError(error, agreementId));
    }
  }, [errors, agreementId]);

  React.useEffect(() => {
    setNeedToRefresh(!!action);
  }, [action]);

  // TODO Get rid of this and just use React.Fragments when we are at React 16
  // https://reactjs.org/blog/2017/11/28/react-v16.2.0-fragment-support.html
  const children = React.Children.toArray(props.children);
  if (children.length > 1)
    console.warn(
      'When having multiple elements inside CheckForLatestAgreement, only the first element is rendered, maybe wrap them inside a div'
    );
  const child = children[0];

  if (needToRefresh && action) {
    return (
      <RefreshAgreementNotification
        className={className}
        actor={actor}
        action={action}
        onClick={() => {
          onRefresh();
          setAction(null);
          setNeedToRefresh(false);
        }}
      />
    );
  }

  // sometimes we don't have any children inside this component
  if (!child) return null;

  const childProps = (child || {}).props || {};

  // pass some common props to child
  // those are usually automatically passed by another wrapper component
  return React.cloneElement(child, {
    className: [childProps.className, className].filter(Boolean).join(' ')
  });
};

export default CheckForLatestAgreement;
