// @flow
import * as React from 'react';
import { ContentSubmissionStatusEnum, ContentSubmissionStatusLabel } from '../../../../constants';
import { DealType } from '@sharkpunch/matchmade-common/campaign';
import { FormattedMessage } from 'react-intl';
import { calculateValueWithoutCommission } from '@sharkpunch/matchmade-common/campaignAgreement';
import { getDealType } from '@sharkpunch/matchmade-common/campaignAgreement';

import type { ContentPlatform } from 'matchmade-types';

import { AddPublishedContent } from './LinkInfluencerContent';
import { centsToDollars } from '../../../../helpers/currencyFormat';
import ChangeDeadline from './ChangeDeadline';
import type { ChangeDeadlineInputProps } from './types.flow';

type CurrentDealTableFooterProps = {
  ...$Exact<ChangeDeadlineInputProps>,
  showPublishContent?: boolean,
  agreementId: number | null,
  contentPlatform: ContentPlatform,
  cpi?: number,
  cpm?: number,
  minGuarantee?: number,
  maxPayment?: number,
  commissionRate: number,
  contentSubmission?: { status: string, url: string }
};

const CpiWithoutCommissionTable = ({
  cpiWithoutCommission,
  minGuaranteeWithoutCommission,
  maxPaymentWithoutCommission
}: {
  cpiWithoutCommission: number,
  minGuaranteeWithoutCommission?: number | null,
  maxPaymentWithoutCommission?: number | null
}) => {
  return (
    <>
      <tr>
        <th>
          <label className="label">
            <FormattedMessage id="influencer.card.admin.cpiWithoutCommission" />
          </label>
        </th>
        <td colSpan="3">{'$' + centsToDollars(cpiWithoutCommission)}</td>
      </tr>
      {!!minGuaranteeWithoutCommission && (
        <tr>
          <th>
            <label className="label">
              <FormattedMessage id="influencer.card.admin.minGuaranteeWithoutCommission" />
            </label>
          </th>
          <td colSpan="3">{'$' + centsToDollars(minGuaranteeWithoutCommission)}</td>
        </tr>
      )}
      {!!maxPaymentWithoutCommission && (
        <tr>
          <th>
            <label className="label">
              <FormattedMessage id="influencer.card.admin.maxPaymentWithoutCommission" />
            </label>
          </th>
          <td colSpan="3">{'$' + centsToDollars(maxPaymentWithoutCommission)}</td>
        </tr>
      )}
    </>
  );
};

const CpmWithoutCommissionTable = ({
  cpmWithoutCommission,
  minGuaranteeWithoutCommission,
  maxPaymentWithoutCommission
}: {
  cpmWithoutCommission: number,
  minGuaranteeWithoutCommission: number | null,
  maxPaymentWithoutCommission?: number | null
}) => {
  return (
    <>
      <tr>
        <th>
          <label className="label">
            <FormattedMessage id="influencer.card.admin.cpmWithoutCommission" />
          </label>
        </th>
        <td colSpan="3">{'$' + centsToDollars(cpmWithoutCommission)}</td>
      </tr>
      {!!minGuaranteeWithoutCommission && (
        <tr>
          <th>
            <label className="label">
              <FormattedMessage id="influencer.card.admin.minGuaranteeWithoutCommission" />
            </label>
          </th>
          <td colSpan="3">{'$' + centsToDollars(minGuaranteeWithoutCommission)}</td>
        </tr>
      )}
      {!!maxPaymentWithoutCommission && (
        <tr>
          <th>
            <label className="label">
              <FormattedMessage id="influencer.card.admin.maxPaymentWithoutCommission" />
            </label>
          </th>
          <td colSpan="3">{'$' + centsToDollars(maxPaymentWithoutCommission)}</td>
        </tr>
      )}
    </>
  );
};

const FixedFeeWithoutCommissionTable = ({
  minGuaranteeWithoutCommission
}: {
  minGuaranteeWithoutCommission: number
}) => {
  return (
    <tr>
      <th>
        <label className="label">
          <FormattedMessage id="influencer.card.admin.fixedFeeWithoutCommission" />
        </label>
      </th>
      <td colSpan="3">{'$' + centsToDollars(minGuaranteeWithoutCommission)}</td>
    </tr>
  );
};

// Somewhere in the influencer cards we want to render non-commission costs for campaign managers to see.
// That place is CurrentDealTable in admin tab, this function returns tables with that info.
const renderCostsWithoutCommission = ({
  cpi,
  cpm,
  minGuarantee,
  maxPayment,
  commissionRate
}: {
  cpi?: number,
  cpm?: number,
  minGuarantee?: number,
  maxPayment?: number,
  commissionRate: number
}) => {
  if (!cpm && !cpi && !minGuarantee && !maxPayment) {
    return null;
  }

  const cpiWithoutCommission = cpi ? calculateValueWithoutCommission(cpi, commissionRate) : null;
  const cpmWithoutCommission = cpm ? calculateValueWithoutCommission(cpm, commissionRate) : null;
  const minGuaranteeWithoutCommission = minGuarantee
    ? calculateValueWithoutCommission(minGuarantee, commissionRate)
    : null;
  const maxPaymentWithoutCommission = maxPayment
    ? calculateValueWithoutCommission(maxPayment, commissionRate)
    : null;

  const dealType = getDealType({ cpi: cpi || 0, cpm: cpm || 0, freeOfCharge: false });

  switch (dealType) {
    case DealType.CPI:
      if (!cpiWithoutCommission) return null;
      return (
        <CpiWithoutCommissionTable
          cpiWithoutCommission={cpiWithoutCommission}
          minGuaranteeWithoutCommission={minGuaranteeWithoutCommission}
          maxPaymentWithoutCommission={maxPaymentWithoutCommission}
        />
      );
    case DealType.CPM:
      if (!cpmWithoutCommission) return null;
      return (
        <CpmWithoutCommissionTable
          cpmWithoutCommission={cpmWithoutCommission}
          minGuaranteeWithoutCommission={minGuaranteeWithoutCommission}
          maxPaymentWithoutCommission={maxPaymentWithoutCommission}
        />
      );
    case DealType.FIXED_FEE:
      if (!minGuaranteeWithoutCommission) return null;
      return (
        <FixedFeeWithoutCommissionTable
          minGuaranteeWithoutCommission={minGuaranteeWithoutCommission}
        />
      );
    default:
      return null;
  }
};

export const CurrentDealTableFooter = (props: CurrentDealTableFooterProps) => {
  const { cpi, cpm, minGuarantee, maxPayment, commissionRate, contentSubmission } = props;

  return (
    <React.Fragment>
      {renderCostsWithoutCommission({ cpi, cpm, minGuarantee, maxPayment, commissionRate })}
      {!!props.deadline && (
        <tr>
          <th>
            <label className="label">
              <FormattedMessage id="influencer.card.admin.publishingDate" />
            </label>
          </th>
          <td colSpan="3">
            <ChangeDeadline {...props} />
          </td>
        </tr>
      )}
      {!!props.agreementId && !!props.showPublishContent && (
        <tr>
          <th>
            <label className="label" htmlFor="published-content">
              <FormattedMessage id="influencer.card.admin.publishedContent" />
            </label>
          </th>
          <td colSpan="3">
            <AddPublishedContent
              agreementId={props.agreementId}
              contentPlatform={props.contentPlatform}
            />
          </td>
        </tr>
      )}
      {!!contentSubmission && (
        <tr>
          <th>
            <label className="label" htmlFor="published-content">
              Content submission
            </label>
          </th>
          <td colSpan="3">
            <p>Status: {ContentSubmissionStatusLabel[contentSubmission.status]}</p>
            <a
              className={`button ${
                contentSubmission.status === ContentSubmissionStatusEnum.SUBMITTED
                  ? 'is-primary'
                  : ''
              }`}
              href={contentSubmission.url}
              target="_blank"
              rel="noreferrer">
              {contentSubmission.status === ContentSubmissionStatusEnum.SUBMITTED
                ? 'Review'
                : 'View'}
            </a>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

type Props = { footer: React.Element<*> };

const CurrentDealTable = ({ footer }: Props) => {
  return (
    <table className="table is-fullwidth AdminTab__current-deal-table">
      <tbody>{footer}</tbody>
    </table>
  );
};

export default CurrentDealTable;
