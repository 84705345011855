const publisherPaymentInfo = {
  companyName: {
    presence: { allowEmpty: false }
  },
  companyCountry: {
    presence: { allowEmpty: false }
  },
  companyCity: {
    presence: { allowEmpty: false }
  },
  companyPostalCode: {
    presence: { allowEmpty: false }
  },
  companyStreetAddress: {
    presence: { allowEmpty: false }
  },
  contactName: {
    presence: { allowEmpty: false }
  },
  contactPhoneNumber: {
    presence: { allowEmpty: false }
  },
  contactEmail: {
    presence: { allowEmpty: false },
    email: {
      message: `invalid format`
    }
  },
  invoiceEmail: {
    presence: { allowEmpty: false },
    email: {
      message: `invalid format`
    }
  }
};

export default {
  publisherPaymentInfo
};
