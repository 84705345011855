import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Highlighter from 'react-highlight-words';
import Select from 'react-select';

import { injectIntl } from 'react-intl';

import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';

import arrowRenderer from '../../helpers/arrowRenderer';

import './EmailTemplateTester.scss';
import getConfig from '../../config';

const HERMOD_URL = `${getConfig('hermod.url')}`;

class EmailTemplateTester extends Component {
  static propTypes = {
    emailTemplates: PropTypes.object.isRequired,
    emailTemplatePreview: PropTypes.object,
    error: PropTypes.object,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTemplateName: null,
      variables: null,
      recipient: JSON.stringify(
        {
          email: this.props.user.email,
          displayName: this.props.user.displayName,
          id: this.props.user.id
        },
        null,
        '  '
      )
    };

    this.debouncedPreview = debounce(this.preview, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedTemplateName !== prevState.selectedTemplateName ||
      this.state.variables !== prevState.variables ||
      this.state.recipient !== prevState.recipient
    ) {
      this.debouncedPreview();
    }
  }

  preview = () => {
    if (this.state.selectedTemplateName && this.state.recipient && this.state.variables) {
      this.props.previewEmailTemplate(
        this.state.selectedTemplateName,
        this.state.recipient,
        this.state.variables
      );
    }
  };

  send = () => {
    if (this.state.selectedTemplateName && this.state.recipient && this.state.variables) {
      this.props.sendEmailTemplate(
        this.state.selectedTemplateName,
        this.state.recipient,
        this.state.variables
      );
    }
  };

  onVariablesChange = value => {
    this.setState({ variables: value.target.value });
  };

  onRecipientChange = value => {
    this.setState({ recipient: value.target.value });
  };

  selectTemplate = option => {
    const newState = {
      selectedTemplateName: option ? option.value : null
    };
    if (newState.selectedTemplateName !== this.state.selectedTemplateName) {
      newState.variables = {};
      if (newState.selectedTemplateName) {
        const template = this.props.emailTemplates[newState.selectedTemplateName];
        const variables = template.variables || [];
        let variableObject = variables.reduce((prev, cur) => {
          prev[cur] = null;
          return prev;
        }, {});
        // Exists but does not have a value
        if (variableObject.displayName === null) {
          variableObject.displayName = this.props.user.displayName;
        }
        if (variableObject.frontendUrl === null) {
          variableObject.frontendUrl = window.location.protocol + '//' + window.location.host;
        }
        if (template.exampleValues) {
          variableObject = Object.assign(variableObject, template.exampleValues);
        }
        newState.variables = JSON.stringify(variableObject, null, '  ');
      }
    }
    this.setState(newState);
  };

  renderTemplateSelectValue = option => {
    return <strong>{option.label}</strong>;
  };

  renderTemplateSelectOption = option => {
    return (
      <div>
        <Highlighter
          highlightTag="strong"
          searchWords={[this._inputValue]}
          textToHighlight={option.label}
        />
      </div>
    );
  };

  renderTemplateSelect = () => {
    const options = [];
    forEach(this.props.emailTemplates || {}, (template, name) => {
      options.push({
        label: name,
        value: name
      });
    });

    return (
      <Select
        name="game"
        placeholder="Select template to test..."
        options={options}
        onChange={this.selectTemplate}
        optionRenderer={this.renderTemplateSelectOption}
        valueRenderer={this.renderTemplateSelectValue}
        onInputChange={inputValue => (this._inputValue = inputValue)}
        value={this.state.selectedTemplateName}
        arrowRenderer={arrowRenderer}
      />
    );
  };

  renderTestForm = () => {
    if (!this.state.selectedTemplateName) {
      return null;
    }

    const template = this.props.emailTemplates[this.state.selectedTemplateName];
    if (!template) {
      return null;
    }

    return (
      <div className="email-variables">
        <form name="email">
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label" htmlFor="email.description">
                  Description <small>What's this email about</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    id="email.description"
                    type="text"
                    name="subject"
                    value={template.description}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label" htmlFor="email.tags">
                  Tags <small>More metadata to Mandrill</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    id="email.tags"
                    type="text"
                    name="subject"
                    value={(template.tags || []).join(', ')}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label" htmlFor="email.recipient">
                  Recipient <small>Who to email & metadata to Mandrill</small>
                </label>
                <div className="control">
                  <textarea value={this.state.recipient || ''} onChange={this.onRecipientChange} />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label" htmlFor="email.variables">
                  Variables <small>Valid JSON fed to EJS templates</small>
                </label>
                <div className="control">
                  <textarea value={this.state.variables || ''} onChange={this.onVariablesChange} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderResult = () => {
    if (!this.state.selectedTemplateName) {
      return null;
    }

    const template = this.props.emailTemplates[this.state.selectedTemplateName];
    if (!template || !template.html) {
      return null;
    }

    const error = this.props.error;
    const preview = this.props.emailTemplatePreview;

    const subject = !error && preview && preview.subject ? preview.subject : template.subject;
    let html = !error && preview && preview.html ? preview.html : null;
    if (html) {
      html = html.replace('<head>', '<head><base key="base" href="https://app.matchmade.tv" />');
    }

    // Don't render iframe if we have only template -- template might use variables in
    // image source, which browser will try to load
    let content =
      !preview || !preview.html ? (
        <textarea value={template.html} readOnly />
      ) : (
        <iframe title="email-template-tester" ref="preview" srcDoc={html} />
      );

    // Give a bogus user_id because Hermod expects it
    if (template.hermodTemplate) {
      template.hermodTemplate.user_id = 1;
    }

    return (
      <div className="email-template-preview field">
        <label className="label" htmlFor="email.recipient">
          Preview
        </label>

        {template.hermodTemplate && (
          <a
            href={`${HERMOD_URL}/preview?template=${encodeURI(
              JSON.stringify(template.hermodTemplate)
            )}`}>
            See version in notification service (aka Email 2.0)
          </a>
        )}

        <div className="control">
          <input
            className="input"
            id="email.subject"
            type="text"
            name="subject"
            value={subject}
            disabled
          />
        </div>
        {content}
        <div className="help is-danger">
          <pre>{this.props.error ? this.props.error.message : null}</pre>
        </div>
      </div>
    );
  };

  renderSend() {
    if (!this.state.selectedTemplateName) {
      return null;
    }

    const template = this.props.emailTemplates[this.state.selectedTemplateName];
    if (!template || !template.html) {
      return null;
    }

    return (
      <div className="is-clearfix">
        <div className="is-pulled-right">
          <div className="field is-grouped">
            <p className="control">
              <button
                disabled={!!this.props.error || this.props.isSendingEmailTemplate}
                className="button is-primary"
                onClick={this.send}>
                Send
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="EmailTester">
        <h1 className="title is-1">Template tester</h1>
        {this.renderTemplateSelect()}
        {this.renderTestForm()}
        {this.renderResult()}
        {this.renderSend()}
      </div>
    );
  }
}

export default injectIntl(EmailTemplateTester);
