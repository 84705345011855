// @flow
import { disableCampaign, enableCampaign, fetchCampaigns } from '../../../actions/campaign';

import type { Action } from '../../../types/action.flow';
import type { CampaignForAdminDashboardTable } from '../../../types/campaign.flow';

import { handleGetAllActions, handleNewTeamCreatedWhenChangingTeam } from '../../common/team';
import runMultipleReducers from '../../helpers/runMultipleReducers';

type State = {|
  campaigns: CampaignForAdminDashboardTable[] | null,
  isLoadingCampaigns: boolean,
  isUpdatingCampaign: boolean,
  updatedCampaign: CampaignForAdminDashboardTable | null,

  updatingCampaignId: number | null,

  error: Object | null
|};

const initialState: State = Object.freeze({
  campaigns: null,
  isLoadingCampaigns: true,
  isUpdatingCampaign: false,
  updatingCampaignId: null,
  updatedCampaign: null,
  error: null
});

export default function adminDashboardCampaignsReducer(
  state: State = initialState,
  action: Action
): State {
  // always reset the state
  const newState = runMultipleReducers([
    handleNewTeamCreatedWhenChangingTeam(),
    handleGetAllActions()
  ])(
    {
      ...state,
      error: null
    },
    action
  );

  switch (action.type) {
    case fetchCampaigns.REQUEST:
      return {
        ...newState,
        isLoadingCampaigns: true
      };
    case fetchCampaigns.SUCCESS:
      return {
        ...newState,
        isLoadingCampaigns: false,
        campaigns: action.payload
      };
    case fetchCampaigns.FAILURE:
      return {
        ...newState,
        isLoadingCampaigns: false,
        error: action.payload
      };
    case enableCampaign.REQUEST:
    case disableCampaign.REQUEST:
      return {
        ...newState,
        updatingCampaignId: action.payload.id
      };
    case enableCampaign.SUCCESS:
    case disableCampaign.SUCCESS:
      const { id, visible } = action.payload;

      return {
        ...newState,
        updatedCampaign: action.payload,
        campaigns: (state.campaigns || []).map(campaign => {
          if (campaign.id === id) {
            return {
              ...campaign,
              visible
            };
          }

          return campaign;
        }),
        updatingCampaignId: null
      };
    case enableCampaign.FAILURE:
    case disableCampaign.FAILURE:
      return {
        ...newState,
        error: action.payload,
        updatingCampaignId: null
      };
    default:
      return newState;
  }
}
