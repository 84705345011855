// @flow
import * as React from 'react';
import { Platform } from '@sharkpunch/matchmade-common/game';
import classNames from 'classnames';

import type { GamePlatform } from 'matchmade-types';

import { FormattedMessage } from 'react-intl';

import StoreIconAndroid from '../../../images/icons/StoreIconAndroid';
import StoreIconApple from '../../../images/icons/StoreIconApple';
import StoreIconSteam from '../../../images/icons/StoreIconSteam';

import InfoTooltip from '../../common/InfoTooltip';

import './SelectPlatform.scss';

type Props = {
  onSelectPlatform: (platform: GamePlatform) => void,
  selectedPlatforms: GamePlatform[],
  supportedPlatforms?: GamePlatform[]
};

const allPlatforms = [
  {
    name: Platform.ANDROID,
    disabled: false,
    icon: [<StoreIconAndroid key="icon" />, <span key="text">Android</span>]
  },
  {
    name: Platform.IOS,
    disabled: false,
    icon: [<StoreIconApple key="icon" />, <span key="text">iOS</span>]
  },
  {
    name: Platform.STEAM,
    disabled: true,
    icon: [<StoreIconSteam key="icon" />, <span key="text">Steam</span>]
  }
];

export default function SelectPlatform(props: Props) {
  const providedPlatforms = props.supportedPlatforms || allPlatforms.map(({ name }) => name);
  const platforms = allPlatforms.filter(({ name }) => {
    return providedPlatforms.indexOf(name) !== -1;
  });

  const platformElements = platforms.map(({ name, icon }) => {
    const selected = props.selectedPlatforms.indexOf(name) !== -1;

    const className = classNames('SelectPlatform SelectPlatform__platform button', {
      'is-primary': selected,
      'SelectPlatform__platform--selected': selected
    });

    return (
      <button
        key={name}
        onClick={() => {
          props.onSelectPlatform(name);
        }}
        className={className}>
        {icon}
      </button>
    );
  });

  return (
    <div className="field is-horizontal">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.productSelection.platform" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.productSelection.platform.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <div className="field is-horizontal">
          <div className="control SelectPlatform__platforms">{platformElements}</div>
        </div>
      </div>
    </div>
  );
}
