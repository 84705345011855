// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import Dotdotdot from 'react-dotdotdot';

import { Role } from '@sharkpunch/matchmade-common/user';
import Avatar from '../../messages/Avatar';

import WithTimestampUpdate from '../../messages/common/WithTimestampUpdate';
import injectRoleChecking from '../../../hoc/injectRoleChecking';

import SendIcon from '../Icons/SendIcon';
import compose from '../../../hoc/compose';

import './MessagesTab.scss';

type Props = {
  agreementId: number | string, // can be uuid for Njord offers
  // this is shown when this is no lastMessage
  noMessageAvatar: string,
  content?: string,
  role: string,
  lastMessageRole?: string,
  senderName?: string,
  senderAvatarUrl?: string,
  created?: string,
  timestamp?: string, // This is generated from created prop by WithTimestampUpdate
  chatClosed: boolean
};

const MessagesTab = ({
  lastMessageRole,
  senderName,
  senderAvatarUrl,
  noMessageAvatar,
  content,
  timestamp,
  agreementId,
  role,
  chatClosed
}: Props) => {
  const buttonLabel = chatClosed
    ? 'card.messagesTab.viewEarlierMessages'
    : content && lastMessageRole !== role
    ? 'card.messagesTab.reply'
    : 'card.messagesTab.sendMessage';

  const goToMessagePageLink = (
    <a
      href={`/messages/${agreementId}`}
      className="MessagesTab__messages-link"
      target="_blank"
      rel="noopener noreferrer">
      <FormattedMessage id={buttonLabel} tagName="strong" />
      <SendIcon />
    </a>
  );

  const logoUrl = senderAvatarUrl || noMessageAvatar;
  // Steam icons shouldn't be rounded
  const isSteam = logoUrl && logoUrl.indexOf('steamcdn') !== -1;
  const isMessageSentByAdmin =
    lastMessageRole === Role.WHITELABEL_ADMIN || lastMessageRole === Role.ADMIN;

  if (chatClosed) {
    return (
      <div className="MessagesTab MessagesTab__chat-closed">
        <FormattedMessage
          id="chat.currentlyDisabled"
          values={{
            faq: (
              <a
                href="https://matchmade.tv/content-creator-faq/"
                target="_blank"
                rel="noopener noreferrer">
                <FormattedMessage id="auth.faq" />
              </a>
            )
          }}
        />
        <div className="actionButton">{goToMessagePageLink}</div>
      </div>
    );
  }

  if (!content) {
    return (
      <div className="MessagesTab MessagesTab__empty">
        <div className="messageBubble">
          <Avatar size={88} url={logoUrl} shape={isSteam ? 'rectangle' : null} />
          <div className="actionButton">{goToMessagePageLink}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="MessagesTab">
      <h2>
        <FormattedMessage id="card.messagesTab.latestMessage" tagName="strong" />
      </h2>
      <div className="messageBubble">
        <Avatar
          size={48}
          url={logoUrl}
          shape={isSteam ? 'rectangle' : null}
          isAdmin={isMessageSentByAdmin}
        />
        <div className="messageText">
          <Dotdotdot clamp={5}>{content}</Dotdotdot>
        </div>
        <span className="textFooter">
          <FormattedMessage
            id="card.messagesTab.footerText"
            values={{
              senderName:
                lastMessageRole === role ? (
                  <FormattedMessage id="card.messagesTab.you" />
                ) : (
                  senderName
                ),
              created: timestamp
            }}
          />
        </span>
      </div>
      <div className="actionButton">{goToMessagePageLink}</div>
    </div>
  );
};
export default compose(injectRoleChecking(), WithTimestampUpdate)(MessagesTab);
