import React from 'react';

const DemographicsIconMale = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      d="M16.5 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM15 11h3a2 2 0 0 1 2 2v5.5h-1.5V26h-4v-7.5H13V13a2 2 0 0 1 2-2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default DemographicsIconMale;
