// @flow
import type { Action, ReducerCreator } from '../../../types/action.flow';

import type { DataProps as CampaignDescription } from '../../../components/campaignCreation/CampaignDescription';
import type { DataProps as CampaignDetails } from '../../../components/campaignCreation/CampaignDetails';
import type { CreateCampaignActionAtributes } from '../../common/campaign';
import type {
  ImportGameActionAttributes,
  SearchGameActionAttributes
} from '../../common/publisher';
import type {
  DataProps as ProductSelection,
  SearchSettings
} from '../../../components/campaignCreation/ProductSelection';

import { Platform } from '@sharkpunch/matchmade-common/game';

import {
  clearCreatedCampaign,
  createCampaign,
  setAttributeForCampaignDescriptionStep,
  setAttributeForCampaignDetailsStep,
  setAttributesForProductSelectionStep,
  setSearchSettingsInProductSelectionStep
} from '../../../actions/campaign';
import { clearError } from '../../../actions/common';

import {
  handleAttributionPartnerChanges,
  handleCampaignDescriptionChanges,
  handleCampaignDetailsChanges,
  handleProductSelectionChanges,
  handleResetState
} from './common';
import { handleCreateCampaignActions } from '../../common/campaign';
import { handleImportGameActions, handleSearchGameActions } from '../../common/publisher';
import runMultipleReducers from '../../helpers/runMultipleReducers';

import {
  getCampaignDescriptionDefaultValues,
  getCampaignDetailsDefaultValues,
  getProductSelectionDefaultValues,
  getSearchGameToImportDefaultSettings
} from './defaultValues';
import {
  validateCampaignDescriptionData,
  validateCampaignDetailsData,
  validateProductSelectionData
} from './validators';

import { StepName } from '../../../containers/campaignCreation/constants';
import { getUser } from '../../../helpers/user';

export type State = {|
  ...$Exact<ImportGameActionAttributes>,
  ...$Exact<SearchGameActionAttributes>,
  ...$Exact<CreateCampaignActionAtributes>,

  newCampaignId: number | null,

  validity: {
    campaignDescription: boolean,
    productSelection: boolean,
    campaignDetails: boolean
  },

  error: Object | null,

  router?: any,
  route?: any,

  campaignDescription: $Exact<CampaignDescription>,
  productSelection: $Exact<ProductSelection>,
  searchSettings: $Exact<SearchSettings>,
  campaignDetails: $Exact<CampaignDetails>,

  createdByUserId?: number
|};

const initialState: State = Object.freeze({
  error: null,

  validity: {
    [StepName.CAMPAIGN_DESCRIPTION]: false,
    [StepName.PRODUCT_SELECTION]: false,
    [StepName.CAMPAIGN_DETAILS]: false
  },

  campaignDescription: getCampaignDescriptionDefaultValues(),
  productSelection: getProductSelectionDefaultValues(),
  searchSettings: getSearchGameToImportDefaultSettings(),
  campaignDetails: getCampaignDetailsDefaultValues(),

  importingGameTitle: null,
  isImportingGame: false,
  importedGame: null,

  isSearchingGame: false,
  searchResults: null,

  createdCampaign: null,
  isCreatingNewCampaign: false,
  newCampaignId: null
});

const handleResetCampaignDetailsWhenSomethingElseChanges: ReducerCreator<{}, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case setAttributeForCampaignDescriptionStep.SUCCESS: {
        if (action.payload.attribute === 'contentPlatforms') {
          // when we change the content platform
          // reset the data in CampaignDetails section, because it's different from platform to platform
          return {
            ...state,
            campaignDetails: getCampaignDetailsDefaultValues()
          };
        }
        return state;
      }
      case setAttributesForProductSelectionStep.SUCCESS: {
        const newPlatforms = state.productSelection.selectedPlatforms;
        const currentPlatforms = action.payload.selectedPlatforms;
        // reset everything in step 3
        // if we changed from steam to mobile or vice versa
        if (
          (newPlatforms.includes(Platform.STEAM) && currentPlatforms.includes(Platform.STEAM)) ||
          (!newPlatforms.includes(Platform.STEAM) && !currentPlatforms.includes(Platform.STEAM))
        ) {
          return state;
        }
        return {
          ...state,
          campaignDetails: getCampaignDetailsDefaultValues()
        };
      }
      default:
        return state;
    }
  };
};

const handleCreatedCampaign: ReducerCreator<{}, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case createCampaign.SUCCESS:
        return {
          ...state,
          newCampaignId: action.payload.id
        };
      default:
        return state;
    }
  };
};

const handleCommonActions: ReducerCreator<{}, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case setAttributeForCampaignDescriptionStep.SUCCESS:
      case setAttributesForProductSelectionStep.SUCCESS:
      case setAttributeForCampaignDetailsStep.SUCCESS:
        return {
          ...state,
          createdByUserId: getUser().id
        };

      case setSearchSettingsInProductSelectionStep.SUCCESS: {
        return {
          ...state,
          searchSettings: action.payload
        };
      }
      case clearError.SUCCESS: {
        return {
          ...state,
          error: null
        };
      }
      default:
        return state;
    }
  };
};

export default function campaignCreation(state: State = initialState, action: Action) {
  const newState: State = runMultipleReducers([
    handleImportGameActions(),
    // this needs to happen before any of the step specific change handler happens
    handleResetCampaignDetailsWhenSomethingElseChanges(),
    handleCampaignDescriptionChanges(),
    handleProductSelectionChanges(),
    handleCampaignDetailsChanges(),
    handleResetState({
      events: [clearCreatedCampaign.SUCCESS, createCampaign.SUCCESS],
      initialState
    }),
    handleCreatedCampaign(),
    handleSearchGameActions(),
    handleAttributionPartnerChanges(),

    handleCreateCampaignActions(),

    handleCommonActions()
  ])(
    {
      ...state,
      error: null
    },
    action
  );

  return {
    ...newState,
    validity: {
      [StepName.CAMPAIGN_DESCRIPTION]: validateCampaignDescriptionData(newState),
      [StepName.PRODUCT_SELECTION]: validateProductSelectionData(newState),
      [StepName.CAMPAIGN_DETAILS]: validateCampaignDetailsData(newState)
    }
  };
}
