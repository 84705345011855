// @flow
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import { SETTLED } from '@sharkpunch/matchmade-common/campaignAgreement';

import { OUT_OF_DATE_NEGOTIATION } from '../../helpers/errorCode';
import FormattedNumber from '../common/FormattedNumber';
import GameHeader from '../game/GameHeader';
import Spinner from '../common/Spinner';

import './CampaignViewForInfluencer.scss';
import './CampaignViewForPublisher.scss';

type Props = {
  campaign: any, // We need proper type for CampaignForInfluencer
  error: any,
  isLoadingCampaign: boolean,
  campaignOverallStats: any,
  sections: { [name: string]: any }
};

const CampaignViewForInfluencer = ({
  campaign,
  error,
  isLoadingCampaign,
  campaignOverallStats = {},
  sections = {}
}: Props) => {
  const agreement = campaign.agreement || {};
  let gameHeaderStats;

  const renderSection = name => {
    const section = sections[name];
    if (!section) return null;
    return <section className="section margin-bottom">{section}</section>;
  };

  let spinner = null;
  if (isLoadingCampaign) {
    spinner = <Spinner mode="cover" size="large" />;
  }

  let errorMessage = null;
  if (error && error.code !== OUT_OF_DATE_NEGOTIATION && error.message) {
    errorMessage = <div className="notification is-danger">{error.message}</div>;
  }

  if (campaignOverallStats && agreement && agreement.status === SETTLED) {
    const installs = campaignOverallStats.installs || 0;
    const cost = campaignOverallStats.cost || 0;
    const clicks = campaignOverallStats.clicks || 0;
    let conversion = installs / clicks;
    if (isNaN(conversion)) conversion = 0;

    gameHeaderStats = {
      left: {
        value: <FormattedNumber type="number" value={installs} />,
        key: (
          <FormattedMessage
            id="influencer.campaign.kpi.chart.install.summary"
            values={{ installs: installs }}
          />
        )
      },
      right: {
        value: <FormattedNumber type="percentage" value={conversion} />,
        key: <FormattedMessage id="influencer.campaign.kpi.chart.conversion.summary" />
      },
      center: {}
    };
    if (campaign.campaignType !== CampaignTypeEnum.CHARITY) {
      gameHeaderStats.center = {
        value: <FormattedNumber value={cost} type="cost" />,
        key: <FormattedMessage id="influencer.campaign.kpi.chart.cost.summary" />
      };
    }
  }

  return (
    <div className="CampaignViewForPublisher CampaignViewForInfluencer">
      <Helmet title={campaign.name} />
      {spinner}
      {errorMessage}

      <section className="section no-padding-bottom">
        <h1 className="title is-1">
          <FormattedMessage id="campaign.name.placeholder" values={{ title: campaign.name }} />
        </h1>
      </section>

      {renderSection('afterTitle')}

      <section className="section margin-bottom">
        <GameHeader game={campaign.game} stats={gameHeaderStats} />
      </section>

      {renderSection('afterGameHeader')}

      <section className="section margin-bottom campaign__description">
        <div className="content">
          <h1 className="subtitle is-pulled-left">
            <FormattedMessage id="campaign.description.public" />
          </h1>
          <div className="is-clearfix" />
          <ReactMarkdown source={campaign.description} />
        </div>
      </section>

      {campaign.talkingPoints ? (
        <section className="section margin-bottom campaign__talking-points">
          <div className="content">
            <h1 className="subtitle is-pulled-left">
              <FormattedMessage id="campaign.description.talkingPoints.title" />
            </h1>
            <div className="is-clearfix" />
            <ReactMarkdown source={campaign.talkingPoints} />
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default CampaignViewForInfluencer;
