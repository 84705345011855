// @flow
import React from 'react';
import config from '../../config';

function EditContentSubmissionTaskTemplateSection({
  contentSubmissionTaskTemplateId,
  onClick
}: {
  contentSubmissionTaskTemplateId: string,
  onClick: Function
}) {
  return (
    <div className="field">
      <div className="control">
        <a
          className="button mb-5"
          rel="noreferrer"
          target="_blank"
          href={`${config(
            'creatorApp.url'
          )}/content-submission-preview/${contentSubmissionTaskTemplateId}`}>
          Open preview of current template
        </a>
      </div>
      <div className="control">
        <button className="button is-primary" onClick={onClick}>
          Edit content submission task template
        </button>
      </div>
    </div>
  );
}

export default EditContentSubmissionTaskTemplateSection;
