import Link from 'react-router/lib/Link';
import React from 'react';
import moment from 'moment';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import Avatar from '../messages/Avatar';

import EmailCopyIcon from '../common/EmailCopyIcon';
import Table, { Column, pageLimitOptions } from '../tables/Table';

import CheckIcon from '../common/Icons/CheckIcon';
import OnOffToggle from '../common/OnOffToggle';

import './PublisherTable.scss';
import DeleteIcon from '../common/Icons/DeleteIcon';
import PersonIcon from '../common/Icons/PersonIcon';

import { InlineTeamManagement } from './InlineTeamManagement';
import Tooltip from '../common/Tooltip';

import type { IntlShape } from 'react-intl';

type Props = {
  publishers: {
    id: number,
    created: string,
    lastLogin: string,
    email: string,
    contactEmail: string,
    displayName: string,
    avatarUrl: string,
    verified: boolean,
    automaticVerificationReason: string,
    teams: Array<{
      id: number,
      name: string,
      hasAcceptedTos: boolean
    }>
  },
  verifyingAccountId: number,
  isDeletingAccount: boolean,
  isChangingTeam: boolean,
  verifyAccount: Function,
  deleteAccount: Function,
  onClickVerifyAccount: Function,
  onChangeTeam: Function,
  intl: IntlShape
};

class PublisherTable extends React.PureComponent<Props, {}> {
  constructor(props) {
    super(props);

    this.state = {
      accountToDelete: null,
      accountToChangeTeam: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isChangingTeam && !this.props.isChangingTeam) {
      this.setState({
        accountToChangeTeam: null
      });
    }
  }

  verifyAccount = publisher => {
    return () => this.props.onClickVerifyAccount(publisher);
  };

  deleteAccount = () => {
    if (!this.state.accountToDelete) {
      return;
    }

    const accountToDelete = this.state.accountToDelete;
    return this.setState({ accountToDelete: null }, () => {
      this.props.deleteAccount(accountToDelete, 'publisher');
    });
  };

  showAccountDeleteModal = id => {
    return this.setState({ accountToDelete: id });
  };

  hideAccountDeleteModal = () => {
    return this.setState({ accountToDelete: null });
  };

  renderConfirmAccountDeleteModal(id) {
    if (!this.state.accountToDelete) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Delete account?</p>
            <button className="delete" onClick={this.hideAccountDeleteModal} />
          </header>
          <section className="modal-card-body">
            <p>
              Removing this account will remove all data associated with this account. Are you sure
              you want to delete it?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger" onClick={this.deleteAccount}>
              Delete
            </button>
            <button className="button" onClick={this.hideAccountDeleteModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }

  isProcessing() {
    return this.props.isDeletingAccount || !!this.props.verifyingAccountId;
  }

  onClickEditTeam = id => {
    this.setState({ accountToChangeTeam: id === this.state.accountToChangeTeam ? null : id });
  };

  render() {
    const disabled = this.isProcessing();
    const { teams: allTeams, isChangingTeam } = this.props;
    const { accountToChangeTeam } = this.state;

    return (
      <div className="PublisherTable">
        {this.renderConfirmAccountDeleteModal()}
        <Table
          data={this.props.publishers}
          initialSort="id"
          initialSortDirection="desc"
          className="is-fullwidth is-hoverable"
          pagination={this.props.publishers.length > pageLimitOptions[1]}
          searchBy={[
            'id',
            'email',
            'contactEmail',
            'displayName',
            function (publisher, searchInput) {
              const searchInputRegex = new RegExp(searchInput, 'gi');
              return (publisher.teams || []).some(({ name }) => {
                return searchInputRegex.test(name);
              });
            }
          ]}>
          <Column name="id">
            <FormattedMessage id="admin.accounts.table.header.id" />
          </Column>
          <Column
            name="displayName"
            component={props => {
              const rowData = props.rowData;

              return (
                <span className="AdminDashboardPublishers__display-name">
                  <Avatar size={24} url={rowData.avatarUrl} alt={rowData.email} />
                  <EmailCopyIcon value={rowData.contactEmail || rowData.email} />
                  {rowData.displayName}
                </span>
              );
            }}>
            <FormattedMessage id="admin.accounts.table.header.name" />
          </Column>
          <Column
            name="teams"
            component={props => {
              const publisher = props.rowData;
              const isEditing = accountToChangeTeam && accountToChangeTeam === publisher.id;

              return (
                <InlineTeamManagement
                  allTeams={allTeams}
                  currentTeam={publisher.teams[0]}
                  isEditing={isEditing}
                  isLoading={isChangingTeam}
                  onChangeTeam={(teamId, teamName) => {
                    this.props.onChangeTeam(accountToChangeTeam, teamId, teamName);
                  }}
                  onCreateTeam={teamName => {
                    this.props.onChangeTeam(accountToChangeTeam, null, teamName);
                  }}
                  onToggleEdit={() => {
                    this.onClickEditTeam(publisher.id);
                  }}
                />
              );
            }}
            sortBy={(a, b) => {
              a = a || [];
              b = b || [];

              // desc so that we see publisher belonging to the most teams
              if (a.length !== b.length) {
                return a.length - b.length;
              }

              const aName = (a[0] || {}).name || '';
              const bName = (b[0] || {}).name || '';

              // asc to sort A-Z
              return bName.localeCompare(aName);
            }}>
            <FormattedMessage id="admin.accounts.table.header.teams" />
          </Column>
          <Column
            name="verified"
            component={props => {
              const publisher = props.rowData;

              if (publisher.verified) {
                const tooltip = publisher.automaticVerificationReason ? (
                  <FormattedMessage
                    id="admin.accounts.table.automaticallyVerified"
                    values={{
                      reason: publisher.automaticVerificationReason
                    }}
                  />
                ) : (
                  <FormattedMessage id="admin.accounts.table.manuallyVerified" />
                );
                return (
                  <Tooltip tooltip={tooltip}>
                    <span>
                      <OnOffToggle isDisabled onClick={() => {}} isOn />
                    </span>
                  </Tooltip>
                );
              }

              return (
                <OnOffToggle
                  onClick={this.verifyAccount(props.rowData)}
                  isDisabled={disabled}
                  isLoading={this.props.verifyingAccountId === props.rowData.id}
                />
              );
            }}>
            <FormattedMessage id="admin.accounts.table.header.verified" />
          </Column>
          <Column
            name="created"
            className="AdminDashboardPublishers__date-field"
            component={props => <span>{moment(props.rowData.created).format('MMM Do YYYY')}</span>}
            sortBy={created => new Date(created).getTime()}>
            <FormattedMessage id="admin.accounts.table.header.created" />
          </Column>
          <Column
            name="lastLogin"
            className="AdminDashboardPublishers__date-field"
            sortBy={lastLogin => new Date(lastLogin).getTime()}
            component={props => (
              <span>{moment(props.rowData.lastLogin).format('MMM Do YYYY')}</span>
            )}>
            <FormattedMessage id="admin.accounts.table.header.lastLogin" />
          </Column>
          <Column
            name="hasAcceptedTos"
            component={props => {
              const { rowData } = props;
              if (rowData && rowData.teams[0] && rowData.teams[0].hasAcceptedTos) {
                return <CheckIcon />;
              }

              return <span>NO</span>;
            }}>
            <FormattedMessage id="admin.accounts.table.header.tosAccepted" />
          </Column>
          <Column
            name="loginAs"
            sortable={false}
            component={props => {
              const rowData = props.rowData;

              return (
                <div className="PublisherTable__actions">
                  <Link
                    to={`/admin/login-as/${RoleEnum.PUBLISHER}/${rowData.id}`}
                    key="admin-login-as-publisher"
                    activeClassName="is-active">
                    <PersonIcon />
                  </Link>
                  <button
                    className="link-button"
                    disabled={disabled}
                    onClick={() => this.showAccountDeleteModal(rowData.id)}>
                    <DeleteIcon />
                  </button>
                </div>
              );
            }}></Column>
        </Table>
      </div>
    );
  }
}

export default injectIntl(PublisherTable);
