// @flow
import { FormattedMessage } from 'react-intl';
import { numberToFormattedValue } from '../../helpers/percentageFormat';
import FormattedNumber from '../common/FormattedNumber';
import React from 'react';
import classNames from 'classnames';
import type { CampaignBudget } from '../../types/campaign.flow';

import './CampaignAllocatedBudget.scss';

type Props = CampaignBudget;

const CampaignAllocatedBudget = ({
  totalBudget = 0,
  allocatedBudget = 0,
  unallocatedBudget = 0,
  budgetInNegotiation = 0,
  spentBudget = 0,
  numOfPendingConditionalOffers = 0
}: Props) => {
  const allocatedBudgetPercentage =
    numberToFormattedValue(allocatedBudget / totalBudget, true) || 0;
  const unallocatedBudgetPercentage =
    numberToFormattedValue(unallocatedBudget / totalBudget, true) || 0;
  const budgetInNegotiationPercentage =
    numberToFormattedValue(budgetInNegotiation / totalBudget, true) || 0;
  const spentBudgetPercentage = numberToFormattedValue(spentBudget / totalBudget, true) || 0;
  const showPercentage = totalBudget > 0;

  return (
    <div id="campaign-allocated-budget" className="CampaignAllocatedBudget box">
      <div className="CampaignAllocatedBudget__title">
        <FormattedMessage
          tagName="strong"
          id="campaign.allocatedBudget.title"
          values={{
            value: <FormattedNumber type="cost" value={totalBudget} defaultToZero />
          }}
        />
      </div>
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">
            <FormattedMessage
              id="campaign.allocatedBudget.allocatedBudget"
              values={{
                value: <FormattedNumber type="cost" value={allocatedBudget} defaultToZero />
              }}
            />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <progress
              className={classNames('progress is-success', { 'show-percentage': showPercentage })}
              value={totalBudget ? allocatedBudget : 0}
              max={totalBudget}
              data-percentage={allocatedBudgetPercentage}
            />
          </div>
        </div>
      </div>
      {unallocatedBudget >= 0 && (
        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">
              <FormattedMessage
                id="campaign.allocatedBudget.unallocatedBudget"
                values={{
                  value: <FormattedNumber type="cost" value={unallocatedBudget} defaultToZero />
                }}
              />
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <progress
                className={classNames('progress is-danger rotated', {
                  'show-percentage': showPercentage
                })}
                value={totalBudget ? unallocatedBudget : 0}
                max={totalBudget}
                data-percentage={unallocatedBudgetPercentage}
              />
            </div>
          </div>
        </div>
      )}
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">
            <FormattedMessage
              id="campaign.allocatedBudget.negotiatedBudget"
              values={{
                value: <FormattedNumber type="cost" value={budgetInNegotiation} defaultToZero />
              }}
            />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <progress
              className={classNames('progress is-warning', { 'show-percentage': showPercentage })}
              value={totalBudget ? budgetInNegotiation : 0}
              max={totalBudget}
              data-percentage={budgetInNegotiationPercentage}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">
            <FormattedMessage
              id="campaign.allocatedBudget.spentBudget"
              values={{
                value: <FormattedNumber type="cost" value={spentBudget} defaultToZero />
              }}
            />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <progress
              className={classNames('progress is-primary', { 'show-percentage': showPercentage })}
              value={totalBudget ? spentBudget : 0}
              max={totalBudget}
              data-percentage={spentBudgetPercentage}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">
            <FormattedMessage
              id="campaign.allocatedBudget.numOfPendingConditionalOffers"
              values={{ value: numOfPendingConditionalOffers }}
            />
          </label>
        </div>
        <div className="field-body">
          <div className="field"></div>
        </div>
      </div>
    </div>
  );
};

export default CampaignAllocatedBudget;
