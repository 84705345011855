import { Bar as BarChart } from 'react-chartjs-2';
import React from 'react';
import moment from 'moment';

import { getColorVariables } from '../../../config';
import { getInstallsAndClicksGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 588; // 42rem;

const InstallsAndClicksGraph = ({ clicksAndInstallsAnalytics }) => {
  const variables = getColorVariables();
  const labels = clicksAndInstallsAnalytics.map(({ date }) => moment(date).format('Do MMM YYYY'));
  const installsByDay = clicksAndInstallsAnalytics.map(({ installs }) => installs);
  const unattributedInstallsByDay = clicksAndInstallsAnalytics.map(
    ({ unattributedInstalls }) => unattributedInstalls
  );
  const clicksByDay = clicksAndInstallsAnalytics.map(({ clicks }) => clicks);
  const { chart } = getGraphConfig(
    labels,
    installsByDay,
    unattributedInstallsByDay,
    clicksByDay,
    variables
  );

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default InstallsAndClicksGraph;
