// @flow
// The idea of this component is to contain all special cases
// of the same DynamicSelector (which is basically ReactSelect.Async)
// So any exported component here should use DynamicSelector as a basis

import * as React from 'react';
// $FlowFixMe
import ReactSelect from 'react-select';

import { toHumanReadableNumber } from '@sharkpunch/matchmade-common/formatters';

import { constructSearchURL } from '../../../helpers/searchYoutube';
import callApi from '../../../helpers/api';

import './DynamicSelector.scss';

type SearchGame = { title: string, platforms: string[] };
type SearchTag = { label: string, channelCount: number };

type LoadOptions<OptionType> = (input: string) => Promise<{ options: OptionType }>;

type FilterGames = SearchGame => boolean;

// We need to combine these two, since react-select doesn't have a separate filtering
// for fetched data
const fetchAndFilterGames: FilterGames => LoadOptions<SearchGame[]> = function (filterFunc) {
  return async function (input) {
    const constructedGamesSearchUrl = constructSearchURL({ query: input });
    const res = await callApi('/search/v2/games?' + constructedGamesSearchUrl);
    return { options: (res.data || []).filter(filterFunc) };
  };
};

const fetchAndFilterTags = async function (input) {
  const tagSearchQuery = constructSearchURL({ query: input });
  const res = await callApi('/search/v2/tags?' + tagSearchQuery);

  return {
    options: res.data.map(d => ({
      label: d.tag,
      channelCount: d.channelCount
    }))
  };
};

const SearchGameRenderer = option => (
  <div className="Option is-flex">
    <label className="Option__label">{option.title}</label>
    <div className="Option__aside responsive-truncate-contents">{option.platforms.join(', ')}</div>
  </div>
);

const SearchTagRenderer = option => (
  <div className="Option is-flex">
    <label className="Option__label">{option.label}</label>
    <label className="Option__aside responsive-truncate-contents">
      ~{toHumanReadableNumber(option.channelCount)} total channels
    </label>
  </div>
);

type GameProps = {
  placeholder: string,
  filterFunc: SearchGame => boolean,
  disabled: boolean,
  onChange: SearchGame => void
};

const GamesSelector = ({ placeholder, filterFunc, disabled, onChange }: GameProps) => (
  <DynamicSelector
    className="GamesSelector"
    name="game"
    disabled={disabled}
    placeholder={placeholder}
    loadOptions={fetchAndFilterGames(filterFunc)}
    onChange={onChange}
    optionRenderer={SearchGameRenderer}
    labelKey="title"
    valueKey="title"
  />
);

type TagsProps = {
  placeholder: string,
  disabled: boolean,
  onChange: SearchTag => void
};

const TagsSelector = ({ placeholder, disabled, onChange }: TagsProps) => {
  return (
    <DynamicSelector
      className="TagsSelector"
      name="tags"
      disabled={disabled}
      placeholder={placeholder}
      loadOptions={fetchAndFilterTags}
      onChange={onChange}
      optionRenderer={SearchTagRenderer}
      labelKey="label"
      valueKey="label"
    />
  );
};

type DynamicSelectorProps<OptionType> = {
  className: string,
  name: string,
  optionRenderer: React.StatelessFunctionalComponent<OptionType>,
  disabled: boolean,
  placeholder: string,
  labelKey: string,
  valueKey: string,
  onChange: OptionType => void,
  loadOptions: LoadOptions<OptionType[]>
};

class DynamicSelector<T> extends React.PureComponent<DynamicSelectorProps<T>, {}> {
  render() {
    const {
      className,
      name,
      optionRenderer,
      placeholder,
      disabled,
      labelKey,
      valueKey,
      onChange,
      loadOptions
    } = this.props;
    return (
      <ReactSelect.Async
        className={`${className} DynamicSelector`}
        name={name}
        clearable={false}
        disabled={disabled}
        placeholder={placeholder}
        loadOptions={loadOptions}
        onChange={onChange}
        multi={false}
        optionRenderer={optionRenderer}
        menuStyle={{ overflow: 'hidden' }}
        labelKey={labelKey}
        valueKey={valueKey}
      />
    );
  }
}

export { GamesSelector, TagsSelector };
