import { HorizontalBar as HorizontalBarChart } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import React from 'react';

import { getColorVariables } from '../../../config';
import { getEngagementGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 588; // 42rem;

const EngagementGraph = ({ likeCount, dislikeCount, commentCount, viewCount, intl }) => {
  const variables = getColorVariables();
  const labels = [
    intl.formatMessage({ id: 'campaign.report.stats.likes' }),
    intl.formatMessage({ id: 'campaign.report.stats.dislikes' }),
    intl.formatMessage({ id: 'campaign.report.stats.comments' })
  ];
  const engagmentData = [likeCount, dislikeCount, commentCount];
  const { chart } = getGraphConfig(labels, engagmentData, viewCount, variables);

  return <HorizontalBarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default injectIntl(EngagementGraph);
