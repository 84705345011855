import React from 'react';

import get from 'lodash/get';
import map from 'lodash/map';

// expect
// {
//   attribute: ['error message 1', 'error message 2']
// }
export function renderValidationError(error, attribute) {
  const errorParams = get(error, 'params', {}) || {};
  if (!errorParams[attribute]) return null;

  return map(errorParams[attribute], msg => {
    return (
      <span key={msg} styles={{ display: 'block' }} className="help is-danger">
        {msg}
      </span>
    );
  });
}

// Select input value when we focus in it
function handleFocus(e) {
  e.target.select();
}

// this function assumes that input is stored in state
// TODO: move this to Input and refactor other components that are using this
// to use Input instead
export function renderInputField({
  props,
  state,
  onBlur,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  placeholder,
  type = 'text',
  label,
  attribute,
  value,
  disabled,
  autoFocus,
  size = null,
  // number
  min,
  max,
  step,
  ref,
  iconLeft,
  iconRight,
  isExpanded = false
}) {
  const error = renderValidationError(props.error, attribute);
  let invalidInput = false;
  value = value !== undefined ? value : get(state, attribute);
  const extraProps = {};
  if (type === 'number') {
    extraProps.min = min;
    extraProps.max = max;
    extraProps.step = step;
    invalidInput = !!error || value < min || value > max;
  }

  if (size) {
    size = `is-${size}`;
  } else {
    size = '';
  }

  if (ref) extraProps.ref = ref;

  const inputWrapperClassName = [
    'control',
    iconLeft && 'has-icons-left',
    iconRight && 'has-icons-right',
    isExpanded && 'is-expanded'
  ]
    .filter(Boolean)
    .join(' ');

  const inputClassName = ['input', invalidInput && 'is-danger', size].filter(Boolean).join(' ');

  const iconLeftClassName = ['icon', 'is-left', size].filter(Boolean).join(' ');
  const iconRightClassName = ['icon', 'is-right', size].filter(Boolean).join(' ');

  return [
    label ? (
      <label key={`${attribute}-label`} className="label">
        {label}
      </label>
    ) : null,
    <p key={`${attribute}-input`} className={inputWrapperClassName}>
      <input
        className={inputClassName}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        {...extraProps}
      />
      {iconLeft && <span className={iconLeftClassName}>{iconLeft}</span>}
      {iconRight && <span className={iconRightClassName}>{iconRight}</span>}
      {error}
    </p>
  ];
}
