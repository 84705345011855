import buildContainerReducer from '../../helpers/buildContainerReducer';

export default buildContainerReducer(
  {
    campaign: null,
    isLoadingCampaign: true,
    updatedCampaign: null,
    isUpdatingCampaign: false,
    error: null
  },
  {
    error: null,
    updatedCampaign: null
  },
  ['campaign.fetchCampaign', 'campaign.updateCampaign']
);
