// @flow
import { FormattedMessage } from 'react-intl';
import { Role } from '@sharkpunch/matchmade-common/user';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import WithTimestampUpdate from './common/WithTimestampUpdate';

import './NegotiationMessage.scss';

type Props = {
  displayName: string, // This is the user displayName
  deadline: string,
  created?: string,
  role?: string,
  timestamp?: string,
  reverse?: boolean
};

// This is suppossed to be an admin message, but can be used for more user types
const ChangedDeadlineMessage = ({
  created = 'a few seconds ago',
  reverse,
  role,
  deadline,
  displayName,
  timestamp
}: Props) => {
  // timestamp comes from WithTimestampUpdate
  const time = timestamp || created;

  return (
    <div className="NegotiationMessage">
      <div className="NegotiationMessage__content">
        <div className="NegotiationMessage__negotiation">
          <FormattedMessage
            key="negotiationMessageActor"
            id={`chat.negotiation.changedDeadline`}
            values={{
              actor: role === Role.ADMIN ? 'Matchmade' : displayName
            }}
          />
          <strong>{moment.utc(deadline).format('MMM Do YYYY')}</strong>
        </div>
        <div
          className={classNames('NegotiationMessage__split', {
            'NegotiationMessage__split--reversed': reverse
          })}>
          <div className="NegotiationMessage__icon">
            <span className="icon is-medium">
              <i className={`fa fa-rotate-left`} />
            </span>
          </div>
          <hr />
        </div>
        <div className="NegotiationMessage__created">
          <time>
            {role === Role.ADMIN ? `${displayName} (Matchmade Admin) - ` : ''}
            {time}
          </time>
        </div>
      </div>
    </div>
  );
};

export default WithTimestampUpdate(ChangedDeadlineMessage, 'created');
