import { createApiAction } from './helper';
import type { UpdateManagerTeam } from '../types/team.flow';

export const fetchManagerTeams = createApiAction('FETCH_MANAGER_TEAMS', () => {
  return {
    path: '/manager-teams',
    options: {
      method: 'GET'
    }
  };
});

export const fetchManagerTeamById = createApiAction('FETCH_MANAGER_TEAM_BY_ID', id => {
  return {
    path: `/manager-teams/${id}`,
    options: {
      method: 'GET'
    }
  };
});

export const updateManagerTeam = createApiAction(
  'UPDATE_MANAGER_TEAM',
  (id: string, team: UpdateManagerTeam) => {
    return {
      path: `/manager-teams/${id}`,
      options: {
        method: 'PUT',
        body: team
      },
      loadingPayload: team
    };
  }
);

export const fetchManagerTeamMembers = createApiAction('FETCH_MANAGER_TEAM_MEMBERS', id => {
  return {
    path: `/manager-teams/${id}/members`,
    options: {
      method: 'GET'
    }
  };
});

export const fetchManagerTeamChannels = createApiAction('FETCH_MANAGER_TEAM_CHANNELS', id => {
  return {
    path: `/manager-teams/${id}/channels`,
    options: {
      method: 'GET'
    }
  };
});

export const fetchManagerTeamChannelInvites = createApiAction(
  'FETCH_MANAGER_TEAM_CHANNEL_INVITES',
  id => {
    return {
      path: `/manager-teams/${id}/channel-invites`,
      options: {
        method: 'GET'
      }
    };
  }
);

export const removeManagedChannel = createApiAction('REMOVE_MANAGED_CHANEL', (channelId, id) => {
  return {
    path: `/manager-teams/${id}/channels/${channelId}`,
    options: {
      method: 'DELETE'
    }
  };
});
