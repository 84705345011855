import PropTypes from 'prop-types';

import accountShape from './account';

export default PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string // when we create a new team it's id is string
  ]),
  name: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      role: PropTypes.string,
      verified: PropTypes.bool,
      isCurrentUser: PropTypes.bool,
      account: accountShape
    })
  ),
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      account: accountShape
    })
  )
});
