import React from 'react';
import sortBy from 'lodash/sortBy';
import userAgent from '../../../helpers/userAgent';

import { Pie as PieChart } from 'react-chartjs-2';

import { getColorVariables } from '../../../config';
import {
  getConstants,
  getAgeGroupChartConfig as getGraphConfig
} from '@sharkpunch/matchmade-common/graphs';
import { injectIntl } from 'react-intl';

const variables = getColorVariables();
const { AGE_GROUP_LABELS } = getConstants(variables);

const AgeGroupChart = ({ ageGroup, height, intl }) => {
  if (!ageGroup) return null;

  ageGroup = sortBy(ageGroup, entry => {
    return entry.name;
  });

  const labels = ageGroup.map(entry => {
    return AGE_GROUP_LABELS[entry.name];
  });

  const values = ageGroup.map(entry => {
    return entry.value;
  });

  const title = intl.formatMessage({
    id: 'influencer.dashboard.chart.title.age'
  });

  const config = getGraphConfig(labels, values, title, variables, {
    isMobile: userAgent.isMobile()
  });

  return <PieChart height={height || (userAgent.isMobile() ? 250 : 150)} {...config} />;
};

export default injectIntl(AgeGroupChart);
