// @flow
import React from 'react';

import { Helmet } from 'react-helmet';

//$FlowFixMe
import { useAsync } from 'react-async';

import Table, { Column, pageLimitOptions } from '../../components/tables/Table';

import ActionableNotification from '../../components/common/ActionableNotification';
import BudgetCreateModal from '../../components/admin/BudgetCreateModal';
import callApi from '../../helpers/api';
import DeleteIcon from '../../components/common/Icons/DeleteIcon';
import EditIcon from '../../components/common/Icons/EditIcon';
import FormattedNumber from '../../components/common/FormattedNumber';
import moment from 'moment';
import Spinner from '../../components/common/Spinner';
import useConfirmService from '../../components/common/ConfirmDialog/ConfirmDialog';

import './AdminDashboardCommon.scss';

//$FlowFixMe
import { Alert } from '@material-ui/lab';
import { chunk } from 'lodash';
import { Snackbar } from '@material-ui/core';

interface Budget {
  created: string;
  currency: string;
  id: string;
  maximum: string;
  name: string;
  updated: string;
}

interface BudgetListItem extends Budget {
  acceptedOffers?: number;
  allocated?: number;
  campaigns?: number[];
  pendingOffers?: number;
  unallocated?: number;
}

function getBudgets() {
  return callApi('/budgets');
}

function doDelete(id) {
  return callApi(`/budgets/${id}`, { method: 'DELETE' });
}

async function addStatsToBudget(budget: Budget): Promise<BudgetListItem> {
  try {
    const stats = await callApi(`/budgets/${budget.id}/stats`);
    return { ...budget, ...stats.data };
  } catch {
    // if stats api fails, still just return budget so that not everything falls apart
    return budget;
  }
}

export function BudgetsTable({
  budgets,
  onNewBudgetSaved,
  onBudgetDeleted
}: {
  budgets: Budget[],
  onNewBudgetSaved: () => {},
  onBudgetDeleted: () => {}
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentBudget, setCurrentBudget] = React.useState(null);
  const [deleteFailedError, setDeleteFailedError] = React.useState('');
  const confirmService = useConfirmService();

  const deleteBudgetResponse = useAsync({
    deferFn: doDelete,
    onResolve: () => {
      onBudgetDeleted();
    },
    onReject: e => {
      setDeleteFailedError(
        e.message ? `Failed to delete budget: ${e.message}` : 'Failed to delete budget'
      );
      // hide error after 10 seconds
      setTimeout(() => {
        setDeleteFailedError('');
      }, 10000);
    }
  });

  React.useEffect(() => {
    if (!isOpen) {
      setCurrentBudget(null);
    }
  }, [isOpen]);

  const headerControls = [
    <button
      key="create-budget"
      className="button is-primary ml-4"
      onClick={() => {
        setCurrentBudget(null);
        setIsOpen(true);
      }}>
      New budget
    </button>
  ];

  const columnDisplayAmount = props => {
    return props.data ? <FormattedNumber type="cost" value={props.data} defaultToZero /> : '0';
  };

  const deleteFailed = deleteFailedError ? (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert elevation={6} variant="filled" severity="error" icon={false}>
        {deleteFailedError}
      </Alert>
    </Snackbar>
  ) : null;

  return (
    <>
      <Table
        className="is-fullwidth is-hoverable"
        headerControls={headerControls}
        data={budgets}
        initialSort="created"
        initialSortDirection="desc"
        pagination={budgets.length > pageLimitOptions[1]}
        searchBy={['name']}>
        <Column name="name">Budget name</Column>
        <Column
          name="created"
          sortBy={created => new Date(created).getTime()}
          component={props => {
            const date = props.data;
            return <span>{moment(date).format('MMM Do YYYY')}</span>;
          }}>
          Created
        </Column>
        <Column name="maximum" component={columnDisplayAmount}>
          Amount
        </Column>
        <Column
          name="allocated"
          component={props => (!props.rowData.campaigns ? '...' : columnDisplayAmount(props))}>
          Allocated
        </Column>
        <Column
          name="unallocated"
          component={props => (!props.rowData.campaigns ? '...' : columnDisplayAmount(props))}>
          Unallocated
        </Column>
        <Column
          name="acceptedOffers"
          component={props => <span>{!props.rowData.campaigns ? '...' : props.data || '0'}</span>}>
          Accepted offers
        </Column>
        <Column
          name="pendingOffers"
          component={props => <span>{!props.rowData.campaigns ? '...' : props.data || '0'}</span>}>
          Pending offers
        </Column>
        <Column
          name="campaigns"
          component={props => (
            <span>{!props.data ? '...' : (props.data && props.data.length) || '0'}</span>
          )}>
          Campaigns
        </Column>
        <Column
          name="actions"
          component={props => (
            <>
              <button
                className="link-button"
                onClick={() => {
                  setCurrentBudget(props.rowData);
                  setIsOpen(true);
                }}>
                <EditIcon />
              </button>

              <button
                className="link-button"
                onClick={() => {
                  confirmService
                    .confirm({
                      description: `
                        Are you sure you want to delete the "${props.rowData.name}" budget?
                        Budget can be deleted only if priced or conditional offers are not linked to the budget.
                        Also it will unlink the budget from all campaigns.
                      `,
                      actionText: 'Delete budget',
                      actionCategory: 'danger'
                    })
                    .then(() => {
                      deleteBudgetResponse.run(props.rowData.id);
                    })
                    .catch();
                }}>
                <DeleteIcon />
              </button>
              <confirmService.Modal />
            </>
          )}>
          Actions
        </Column>
      </Table>
      {deleteFailed}
      <BudgetCreateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentBudget={currentBudget}
        onSuccess={() => {
          onNewBudgetSaved();
          setIsOpen(false);
        }}
      />
    </>
  );
}

function AdminBudgets() {
  const [budgets, setBudgets] = React.useState([]);

  const budgetsWithStatsResponse = useAsync({
    deferFn: async budgetsRes => {
      // make copy to not alter original
      const budgetResArray = [...budgetsRes[0]];
      const batches = chunk(budgetResArray, 5);
      // new array to collect budgets with added stats in
      let budgetsWithStats: BudgetListItem[] = [];

      for (const batch of batches) {
        const res = await Promise.all(batch.map(async budget => addStatsToBudget(budget)));
        budgetsWithStats = budgetsWithStats.concat(res);
        // take the budgets that don't have stats yet...
        const end = [...budgetResArray].splice(budgetsWithStats.length, budgetResArray.length);
        // and paste them after the budgets that now have stats, so that table still has all budgets
        setBudgets([...budgetsWithStats, ...end]);
      }
    }
  });

  const budgetsResponse = useAsync({
    promiseFn: getBudgets,
    onResolve: res => {
      setBudgets(res.data);
      budgetsWithStatsResponse.run(res.data);
    }
  });

  return (
    <div className="AdminDashboardGeneric container">
      <Helmet title="Budgets" />
      {budgetsResponse.isLoading ? (
        <Spinner mode="fullWidth" size="large" />
      ) : budgetsResponse.error ? (
        <ActionableNotification type="danger">
          An error occured: {budgetsResponse.error.message}
        </ActionableNotification>
      ) : (
        <BudgetsTable
          budgets={budgets}
          onNewBudgetSaved={budgetsResponse.reload}
          onBudgetDeleted={budgetsResponse.reload}
        />
      )}
    </div>
  );
}

export default AdminBudgets;
