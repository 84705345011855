import React from 'react';

const OrganicInstallsIcon = props => {
  const { primaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 71 65" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fillRule="nonzero" stroke={primaryColor} strokeWidth="4" fill="none" strokeLinecap="round">
        <path d="M17.5 44L29 48l3-10M34.5 24L40 34l9.5-6M36.5 45.5l5 10.5 10-3.5M41.5 54.5c6-11 11-20.5 27-29.5M27 45c-4-8.5-14.704-21-25-25M55.995 2C49.806 9.12 42.065 21.019 40.5 33" />
        <path d="M17 53v10h42V53" />
      </g>
    </svg>
  );
};

export default OrganicInstallsIcon;
