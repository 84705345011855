// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { userDateFormat } from '../../../../helpers/formats';
import Button, { Color } from '../../../common/Button';
import CheckIcon from '../../../common/Icons/CheckIcon';
import DateOffer from '../../../common/ModifyOfferOverlay/DateOffer';
import type { ChangeDeadlineInputProps } from './types.flow';

import './ChangeDeadline.scss';

const ChangeDeadline = (props: ChangeDeadlineInputProps) => {
  const { deadline: agreementDeadline } = props;

  if (!props.isDeadlineEditable) {
    let deadline = moment.utc(agreementDeadline);
    if (!deadline.isValid()) {
      deadline = <FormattedMessage id="influencer.campaign.unknownDeadline" />;
    } else {
      deadline = deadline.format(userDateFormat);
    }
    return (
      <div className="field">
        <div className="control">{deadline}</div>
      </div>
    );
  }

  return (
    <div className="ChangeDeadline field has-addons">
      <DateOffer
        date={agreementDeadline}
        onChange={deadline => props.onChangedDeadline && props.onChangedDeadline(deadline)}
        disablePast={false}
      />
      <Button
        size="small"
        key="update-deadline"
        className="Button__main-action AdminTab__update-deadline"
        onClick={() => props.onSubmitDeadline && props.onSubmitDeadline(props.deadline)}
        disabled={props.isSubmitDeadlineDisabled}
        color={Color.DANGER}>
        <CheckIcon />
      </Button>
    </div>
  );
};

export default ChangeDeadline;
