// @flow
// This doesn't use FormattedMessages, since we wrap everything with ErrorBoundary (including the `IntlProvider`),
// to also catch potential errors that might happen within intl provider.

import * as React from 'react';

import './FallbackErrorPage.scss';

const FallbackErrorPage = () => (
  <section className="FallbackErrorPage hero is-info is-fullheight">
    <div className="hero-body">
      <div className="has-text-centered">
        <p className="has-text-primary">
          <i className="material-icons">sick</i>
        </p>
        <h1>Oops! Something went wrong... </h1>
        <p>
          We track these errors automatically, but if the problem persists feel free to{' '}
          <a
            className="has-text-primary"
            href="mailto:info@matchmade.tv"
            target="_blank"
            rel="noopener noreferrer">
            contact us
          </a>
          . In the meantime, try refreshing.
        </p>
      </div>
    </div>
  </section>
);
export default FallbackErrorPage;
