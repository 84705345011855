import './SearchInfluencersTable.scss';

import React from 'react';

import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import ChannelCountry from '../ChannelCountry';
import InfluencerContactInfo from '../InfluencerContactInfo';

import FormattedNumber from '../../common/FormattedNumber';

import ANALYTICS from '../../../helpers/analytics';
import GAOutboundLink from '../../common/GAOutboundLink';

import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';

import Table, { Column, GroupedColumn } from '../../tables/Table';

import { getBiggestCountryGroup, getInfluencerByChannelId } from '../../../helpers/influencer';

const getChannels = flow([map('youtubeChannels'), flatten]);

class ChannelName extends React.PureComponent {
  render() {
    const { name, id } = this.props.rowData;

    const url = `https://www.youtube.com/channel/${id}`;

    // TODO: use internal link to influencer profile page
    // if the channel belongs to in-network influencer
    return (
      <div key={`collection-channel-${id}-name`}>
        <div className="SearchInfluencersTable__channel-name">
          <GAOutboundLink
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            eventLabel={ANALYTICS.LABEL.PUBLISHER_CLICKED_CHANNEL_LINK_IN_FOLLOWED_INFLUENCER_CARD}
            className="SearchInfluencersTable__channel-link responsive-truncate-contents"
            title={name}>
            {name}
          </GAOutboundLink>
        </div>
        <div className="SearchInfluencersTable__contact-info responsive-truncate-contents">
          <InfluencerContactInfo youtubeChannel={this.props.rowData} />
        </div>
      </div>
    );
  }
}

class SearchInfluencersTable extends React.PureComponent {
  // See comments in component's propTypes
  isColumnSortable(name) {
    if (!this.props.sortOptions || !this.props.sortOptions.length) {
      return true;
    }
    if (this.props.sortOptions.indexOf(name) !== -1) {
      return true;
    }
    return false;
  }

  render() {
    const channels = getChannels(this.props.influencers);

    return (
      <Table
        data={channels}
        initialSort={this.props.initialSort}
        initialSortDirection={this.props.initialSortDirection}
        externalSorting={!!this.props.onChangeSort}
        className="SearchInfluencersTable is-fullwidth is-bordered">
        <Column
          name="name"
          sortDirectionCycle={['asc', 'desc']}
          onClick={this.props.onChangeSort}
          sortable={this.isColumnSortable('name')}
          component={ChannelName}
          extraProps={this.props}>
          <FormattedMessage id="influencer.collection.display.table.name" />
        </Column>

        <Column
          name="videoCount"
          onClick={this.props.onChangeSort}
          sortable={this.isColumnSortable('videoCount')}
          component={({ rowData }) => {
            const videoCount = rowData.videoCount;

            return <FormattedNumber type="number" value={videoCount} />;
          }}>
          <FormattedMessage id="influencer.collection.display.table.videos" />
        </Column>
        <Column
          name="subscriberCount"
          onClick={this.props.onChangeSort}
          sortable={this.isColumnSortable('subscriberCount')}
          component={({ rowData }) => {
            const subscriberCount = rowData.subscriberCount;

            return <FormattedNumber type="number" value={subscriberCount} />;
          }}>
          <FormattedMessage id="influencer.collection.display.table.subscribers" />
        </Column>

        <GroupedColumn
          title={<FormattedMessage id="influencer.collection.display.table.last30dAvg" />}>
          <Column
            name="last30dStats.averageViewCount"
            onClick={this.props.onChangeSort}
            sortable={this.isColumnSortable('last30dStats.averageViewCount')}
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageViewCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.viewsInPastMonth" />
          </Column>
          <Column
            name="last30dStats.averageLikeCount"
            onClick={this.props.onChangeSort}
            sortable={this.isColumnSortable('last30dStats.averageLikeCount')}
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageLikeCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dLikes" />
          </Column>
          <Column
            name="last30dStats.averageCommentCount"
            onClick={this.props.onChangeSort}
            sortable={this.isColumnSortable('last30dStats.averageCommentCount')}
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageCommentCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dComments" />
          </Column>
          <Column
            name="last30dStats.averageEngagementRatio"
            onClick={this.props.onChangeSort}
            sortable={this.isColumnSortable('last30dStats.averageEngagementRatio')}
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageEngagementRatio;

              return <FormattedNumber type="percentage" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.engagement" />
          </Column>
        </GroupedColumn>
        <GroupedColumn
          title={<FormattedMessage id="influencer.collection.display.table.last30dTotal" />}>
          <Column
            name="last30dStats.totalVideoCount"
            onClick={this.props.onChangeSort}
            sortable={this.isColumnSortable('last30dStats.totalVideoCount')}
            component={({ rowData }) => {
              const value = rowData.last30dStats.totalVideoCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dVideos" />
          </Column>
        </GroupedColumn>

        <Column
          name="country"
          onClick={this.props.onChangeSort}
          sortable={this.isColumnSortable('country')}
          component={({ rowData }) => {
            const influencer = getInfluencerByChannelId(this.props.influencers, rowData.id);

            const { name: countryCode, isEstimated = false } =
              getBiggestCountryGroup(influencer) || {};

            return (
              <ChannelCountry
                countryCode={countryCode}
                isEstimated={isEstimated}
                onlyIcon
                textCentered={false}
              />
            );
          }}
          sortBy={(value, channel) => {
            const influencer = getInfluencerByChannelId(this.props.influencers, channel.id);
            const countryGroup = getBiggestCountryGroup(influencer);
            if (!countryGroup) return null;
            return countryGroup.name;
          }}>
          <FormattedMessage id="influencer.collection.display.table.country" />
        </Column>
      </Table>
    );
  }
}

SearchInfluencersTable = injectIntl(SearchInfluencersTable);

SearchInfluencersTable.propTypes = Object.assign({
  // TODO add influencer shape
  influencers: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialSort: PropTypes.string,
  initialSortDirection: PropTypes.string,
  // If onChangeSort is provided then this component and
  // the child table / columns don't manage sorting themselves at all
  // then initialSort and initialSortDirection are treated always
  // as the "current" sort and sortDirection and data is assumed to be
  // sorted already
  onChangeSort: PropTypes.func,
  // If sortOptions are not provided, then all columns are sortable
  // otherwise only the columns in this array are sortable
  sortOptions: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  commissionRate: PropTypes.number
});

SearchInfluencersTable.defaultProps = {
  initialSort: 'subscriberCount',
  initialSortDirection: 'desc',
  isLoading: false,
  commissionRate: 0.3
};

export default SearchInfluencersTable;
