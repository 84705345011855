// @flow
import type { ReducerCreator } from '../../types/action.flow';

import Immutable from 'immutable';

import {
  deleteAccount,
  deleteTeamInvite,
  deleteTeamMember,
  fetchTeam,
  inviteTeamMember,
  unverifyAccount,
  updateSettings,
  verifyAccount
} from '../../actions/account';

export default {
  fetchTeam(state: any, action: any) {
    switch (action.type) {
      case fetchTeam.REQUEST:
        return state.set('isFetchingTeam', true);
      case fetchTeam.SUCCESS:
        return state.set('isFetchingTeam', false).set('team', Immutable.fromJS(action.payload));
      case fetchTeam.FAILURE:
        return state.set('isFetchingTeam', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  inviteTeamMember(state: any, action: any) {
    switch (action.type) {
      case inviteTeamMember.REQUEST:
        return state.set('isInvitingTeamMember', true);
      case inviteTeamMember.SUCCESS:
        return state.set('isInvitingTeamMember', false);
      case inviteTeamMember.FAILURE:
        return state
          .set('isInvitingTeamMember', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  deleteTeamMember(state: any, action: any) {
    switch (action.type) {
      case deleteTeamMember.REQUEST:
        return state.set('isDeletingTeamMember', true);
      case deleteTeamMember.SUCCESS:
        return state.set('isDeletingTeamMember', false);
      case deleteTeamMember.FAILURE:
        return state
          .set('isDeletingTeamMember', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  deleteTeamInvite(state: any, action: any) {
    switch (action.type) {
      case deleteTeamInvite.REQUEST:
        return state.set('isDeletingTeamInvite', true);
      case deleteTeamInvite.SUCCESS:
        return state.set('isDeletingTeamInvite', false);
      case deleteTeamInvite.FAILURE:
        return state
          .set('isDeletingTeamInvite', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  updateSettings(state: any, action: any) {
    switch (action.type) {
      case updateSettings.REQUEST:
        console.warn('DEPRECATED! Use handleUpdateSettingsActions instead');
        return state.set('isUpdatingAccountSettings', true);
      case updateSettings.SUCCESS:
        console.warn('DEPRECATED! Use handleUpdateSettingsActions instead');
        const user = state.get('user');

        if (user) {
          state = state.set(
            'user',
            user.set('settings', Immutable.fromJS(action.payload.settings))
          );
        }

        return state.set('isUpdatingAccountSettings', false);
      case updateSettings.FAILURE:
        console.warn('DEPRECATED! Use handleUpdateSettingsActions instead');
        return state
          .set('isUpdatingAccountSettings', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};

export type AccountVerificationAttributes = {
  verifyingAccountId: number | null,
  verifiedAccount: Object | null,
  error: Object | null
};
export const handleAccountVerificationActions: ReducerCreator<
  void,
  AccountVerificationAttributes
> = () => {
  return (state, action) => {
    switch (action.type) {
      case verifyAccount.REQUEST:
      case unverifyAccount.REQUEST:
        return {
          ...state,
          verifyingAccountId: action.payload.id,
          verifiedAccount: null,
          error: null
        };
      case verifyAccount.SUCCESS:
      case unverifyAccount.SUCCESS:
        return {
          ...state,
          verifyingAccountId: null,
          verifiedAccount: action.payload,
          error: null
        };
      case verifyAccount.FAILURE:
      case unverifyAccount.FAILURE:
        return {
          ...state,
          verifyingAccountId: null,
          verifiedAccount: null,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

export type DeleteAccountAttributes = {
  isDeletingAccount: boolean,
  error: Object | null
};
export const handleDeleteAccountActions: ReducerCreator<void, DeleteAccountAttributes> = () => {
  return (state, action) => {
    switch (action.type) {
      case deleteAccount.REQUEST:
        return {
          ...state,
          isDeletingAccount: true,
          error: null
        };
      case deleteAccount.SUCCESS:
        return {
          ...state,
          isDeletingAccount: false,
          error: null
        };
      case deleteAccount.FAILURE:
        return {
          ...state,
          isDeletingAccount: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

export type UpdateSettingsAttributes = {
  isUpdatingAccountSettings: boolean,
  user?: Object,
  error: Object | null
};
export const handleUpdateSettingsActions: ReducerCreator<void, UpdateSettingsAttributes> = () => {
  return (state, action) => {
    switch (action.type) {
      case updateSettings.REQUEST:
        return {
          ...state,
          isUpdatingAccountSettings: true
        };
      case updateSettings.SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            settings: action.payload.settings
          },
          isUpdatingAccountSettings: false
        };
      case updateSettings.FAILURE:
        return {
          ...state,
          error: action.payload.settings,
          isUpdatingAccountSettings: false
        };
      default:
        return state;
    }
  };
};
