import { createApiAction } from './helper';

export const fetchEmailTemplates = createApiAction('FETCH_EMAIL_TEMPLATES', () => {
  return {
    path: '/admin/email/templates'
  };
});

export const previewEmailTemplate = createApiAction(
  'PREVIEW_EMAIL_TEMPLATE',
  (templateName, recipient, variables) => {
    return {
      path: `/admin/email/templates/${templateName}/preview`,
      options: {
        method: 'POST',
        body: {
          recipient,
          variables
        }
      }
    };
  }
);

export const sendEmailTemplate = createApiAction(
  'SEND_EMAIL_TEMPLATE',
  (templateName, recipient, variables) => {
    return {
      path: `/admin/email/templates/${templateName}/send`,
      options: {
        method: 'POST',
        body: {
          recipient,
          variables
        }
      }
    };
  }
);
