// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';
import classNames from 'classnames';

import FormattedNumber from '../common/FormattedNumber';
import Tooltip from '../common/Tooltip';

import './ChannelCountry.scss';

type Props = {
  // Alpha-2 format, null means we don't know the country
  countryCode: string | null,
  // null means we don't know the audience country percentage. This is from 1->100
  percentage?: number | null,
  // is this country provided by the api/influencer or our guess?
  isEstimated?: boolean,
  countryAsLabel?: boolean,
  textCentered?: boolean,
  onlyIcon?: boolean
};

export default function ChannelCountry(props: Props) {
  const {
    percentage = null,
    isEstimated = false,
    countryAsLabel = true,
    onlyIcon = false,
    textCentered = true
  } = props;

  const countryCode = (props.countryCode || '').toUpperCase();

  let countryName = countryCodes[countryCode] || 'N/A';

  const hasCountryFlag = countryCode && countryCodes[countryCode];
  let countryFlag = hasCountryFlag ? (
    <span
      title={countryName}
      className={`ChannelCountry__flag flag-icon flag-icon-${(countryCode || '').toLowerCase()}`}
    />
  ) : (
    <FormattedMessage id="influencer.card.stats.country" />
  );

  if (isEstimated) {
    countryName = countryName + '*';
  }

  let countryShare = percentage ? (
    <FormattedNumber type="percentage" value={percentage / 100} />
  ) : null;

  if (onlyIcon) {
    countryName = null;
    countryShare = null;
    if (!hasCountryFlag) {
      countryFlag = 'N/A';
    }
  }

  const className = classNames('ChannelCountry', { 'has-text-centered': textCentered });

  let value = countryShare,
    label = countryName;
  if (countryAsLabel) {
    value = countryName;
    label = countryShare;
  }

  return (
    <div className={className}>
      <Tooltip
        tooltip={
          isEstimated ? (
            <FormattedMessage id="influencer.card.stats.estimatedCountryTooltip" />
          ) : null
        }
        position="bottom"
        group="estimatedCountryTooltip">
        <span>
          <p className="ChannelCountry__stat-val">{value}</p>
          <p className="ChannelCountry__stat-label">
            {countryFlag}
            {label}
          </p>
        </span>
      </Tooltip>
    </div>
  );
}
