// @flow
import * as React from 'react';

import type { HOC } from '../types/hoc.flow';

import {
  checkIfAnonymous,
  checkIfInfluencer,
  checkIfInfluencerManager,
  checkIfInfluencerOrManager,
  checkIfManagedInfluencer,
  checkIfPublisher,
  checkIfPublisherOrWhitelabelAdmin,
  checkIfWhitelabelAdmin,
  getRole
} from '../helpers/user';

export type ExtraProps = {|
  isInfluencer: boolean,
  isManagedInfluencer: boolean,
  isInfluencerManager: boolean,
  isInfluencerOrManager: boolean,
  isPublisher: boolean,
  isPublisherOrWhitelabelAdmin: boolean,
  isWhitelabelAdmin: boolean,
  isAnonymous: boolean,
  role: string
|};

const injectRoleChecking: HOC<ExtraProps> = () => {
  return C => {
    return function InjectRoleChecking(props) {
      const extraProps: ExtraProps = {
        isInfluencer: checkIfInfluencer(),
        isManagedInfluencer: checkIfManagedInfluencer(),
        isInfluencerManager: checkIfInfluencerManager(),
        isInfluencerOrManager: checkIfInfluencerOrManager(),
        isPublisher: checkIfPublisher(),
        isPublisherOrWhitelabelAdmin: checkIfPublisherOrWhitelabelAdmin(),
        isWhitelabelAdmin: checkIfWhitelabelAdmin(),
        isAnonymous: checkIfAnonymous(),
        role: getRole()
      };

      const finalProps = {
        ...extraProps,
        ...props
      };

      return <C {...finalProps} />;
    };
  };
};

export default injectRoleChecking;
