// @flow
import type { ContentPlatform } from 'matchmade-types';

import * as React from 'react';
import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { ContentType } from '../../../constants';

import CopyIcon from '../../common/Icons/CopyIcon';
import PhotoIcon from '../../common/Icons/PhotoIcon';
import PhotoLibraryIcon from '../../common/Icons/PhotoLibraryIcon';
import VideoIcon from '../../common/Icons/VideoIcon';

import { CopyableTrackingLink, QrCode } from '../../common/TabComponents/VideoTab';

import { generateApiPath, getTrackingUrl } from '../../../config';

import { copyTextToClipboard } from '../../../helpers/browser';
import { userDateFormat } from '../../../helpers/formats';

import './DealItemTab.scss';

type Props = {
  contentId: string,
  contentType: string,
  contentPlatform: ContentPlatform,
  contentUrl: string | null, // null means it doesn't have a permanent URL (for example Instagram stories are live for 24h from the channel main page)
  deadline: string,
  trackingCode: string
};

function TwoColumnRow({
  label,
  content
}: {
  label: React.Node | React.Node[] | string,
  content: React.Node | React.Node[] | string
}) {
  return (
    <div className="DealItemTab__two-column-row">
      <div className="DealItemTab__two-column-row-label">
        <strong>{label}</strong>
      </div>
      <div className="DealItemTab__two-column-row-content">{content}</div>
    </div>
  );
}

function DealItemTitle({ i18nId }: { i18nId: string }) {
  return (
    <div className="DealItemTab__title">
      <FormattedMessage id={i18nId} tagName="h2" />
    </div>
  );
}

function getUnpublishedTabI18nTitle(contentPlatform: ContentPlatform) {
  switch (contentPlatform) {
    case ContentPlatformEnum.INSTAGRAM:
      return 'influencer.campaign.waitForVideo.instagram';
    case ContentPlatformEnum.YOUTUBE:
      return 'influencer.campaign.waitForVideo.youtube';
    case ContentPlatformEnum.TWITCH:
      return 'influencer.campaign.waitForVideo.twitch';
    default:
      return 'influencer.campaign.waitForVideo.default';
  }
}

function getPublishedTabI18nTitle(contentPlatform: ContentPlatform) {
  switch (contentPlatform) {
    case ContentPlatformEnum.INSTAGRAM:
      return 'influencer.campaign.contentPublished';
    case ContentPlatformEnum.YOUTUBE:
      return 'influencer.campaign.videoReleased';
    case ContentPlatformEnum.TWITCH:
      return 'influencer.campaign.videoReleased';
    default:
      return 'influencer.campaign.videoReleased';
  }
}

function getPublishedContentI18nTitle(contentType: string) {
  switch (contentType) {
    case ContentType.INSTAGRAM_POST:
      return 'contentType.instagramPost';
    case ContentType.INSTAGRAM_STORY:
      return 'contentType.instagramStory';
    default:
      return 'influencer.card.toggleVideo';
  }
}

function getPublishedContentIcon(contentType: string) {
  switch (contentType) {
    case ContentType.INSTAGRAM_POST:
      return <PhotoIcon />;
    case ContentType.INSTAGRAM_STORY:
      return <PhotoLibraryIcon />;
    default:
      return <VideoIcon />;
  }
}

function PublishedContent(props: { contentUrl: string | null, contentType: string }) {
  const { contentUrl, contentType } = props;

  return (
    <div className="DealItemTab__published-content">
      <FormattedMessage id={getPublishedContentI18nTitle(contentType)} tagName="strong" />
      {contentUrl && (
        <div className="DealItemTab__content-url">
          <div className="DealItemTab__link-to-content">
            <a className="button " href={contentUrl} target="_blank" rel="noopener noreferrer">
              {getPublishedContentIcon(contentType)}
            </a>
          </div>

          <div className="DealItemTab__copy-content-url">
            <button
              className="button"
              onClick={() => {
                copyTextToClipboard(contentUrl);
              }}>
              <CopyIcon tooltipI18nString="influencer.campaign.copyURL" withTooltip />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const TrackingUrl = ({ trackingCode }: { trackingCode: string }) => (
  <div className="DealItemTab__tracking-url">
    <CopyableTrackingLink url={getTrackingUrl(trackingCode)} />
    <QrCode
      imgUrl={`${generateApiPath(`/agreements/${trackingCode}/qr.png`, { scale: 2 })}`}
      href={`${generateApiPath(`/agreements/${trackingCode}/download/qr.png`)}`}
    />
  </div>
);

export function PublishedDealItemTab(
  props: Props & {
    published: string | null // null means it's not published yet
  }
) {
  return (
    <div className="DealItemTab DealItemTab--published">
      <DealItemTitle i18nId={getPublishedTabI18nTitle(props.contentPlatform)} />
      <TwoColumnRow
        label={<FormattedMessage id="influencer.campaign.datePublished" />}
        content={moment(props.published).format(userDateFormat)}
      />
      <TwoColumnRow
        label={<FormattedMessage id="influencer.campaign.agreedDate" />}
        content={moment(props.deadline).format(userDateFormat)}
      />
      <PublishedContent {...props} />
      <TrackingUrl trackingCode={props.trackingCode} />
    </div>
  );
}

function PublishingDate({ deadline }: { deadline: string }) {
  return (
    <div className="DealItemTab__publishing-date">
      <FormattedMessage id="influencer.campaign.releaseDate" tagName="strong" />
      <h3>{moment(deadline).format(userDateFormat)}</h3>
    </div>
  );
}

export function UnpublishedDealItemTab(props: {
  contentPlatform: ContentPlatform,
  deadline: string,
  trackingCode: string
}) {
  return (
    <div className="DealItemTab DealItemTab--unpublished">
      <DealItemTitle i18nId={getUnpublishedTabI18nTitle(props.contentPlatform)} />
      <PublishingDate deadline={props.deadline} />
      <TrackingUrl trackingCode={props.trackingCode} />
    </div>
  );
}

export function InReviewDealItemTab(
  props: Props & {
    submitted: string | null // null means it's not submitted yet
  }
) {
  return (
    <div className="DealItemTab DealItemTab--in-review">
      <DealItemTitle i18nId="influencer.campaign.contentInReview" />
      <TwoColumnRow
        label={<FormattedMessage id="influencer.campaign.dateSubmitted" />}
        content={moment(props.submitted).format(userDateFormat)}
      />
      <TwoColumnRow
        label={<FormattedMessage id="influencer.campaign.agreedDate" />}
        content={moment(props.deadline).format(userDateFormat)}
      />
      <PublishedContent {...props} />
      <TrackingUrl trackingCode={props.trackingCode} />
    </div>
  );
}
