import React from 'react';

import { useAsync } from 'react-async';
import ReactTags from 'react-tag-autocomplete';

import { fetchCampaigns } from '../admin/CreateCampaignForm';

import './CampaignTagsInput.scss';

import { matchSorter } from 'match-sorter';

function campaignToInputFormat(campaign, i) {
  return {
    id: campaign.id,
    name: `[${campaign.id}] ${campaign.name ? campaign.name : ''}`,
    order: i || 0
  };
}

const CampaignTagsInput = ({ tags = [], setData, isDirty }) => {
  const [inputTags, setInputTags] = React.useState(tags.map(campaignToInputFormat));
  const [suggestions, setSuggestions] = React.useState([]);
  const [campaignListLoadingFailed, setCampaignListLoadingFailed] = React.useState(false);

  const {
    isLoading: isCampaignsLoading = true
  }: {
    data: Campaign[]
  } = useAsync({
    promiseFn: fetchCampaigns,
    onResolve: res => {
      const formatted = res.map(campaignToInputFormat);
      setSuggestions(formatted);
    },
    onReject: () => setCampaignListLoadingFailed(false)
  });

  React.useEffect(() => {
    if (!isDirty) {
      if (tags.length) {
        const formatted = tags.map(campaignToInputFormat);
        setInputTags(formatted);
      } else {
        setInputTags([]);
      }
    }
  }, [tags, isDirty, setInputTags]);

  return (
    <>
      {campaignListLoadingFailed ? (
        'Failed to fetch campaigns'
      ) : (
        <ReactTags
          placeholderText={isCampaignsLoading ? 'Loading...' : 'Select campaigns'}
          tags={inputTags}
          suggestions={suggestions}
          allowNew={false}
          suggestionsTransform={(query, suggestions) => {
            const inputtedTags = inputTags.map(t => t.id);
            const unusedSuggestions = suggestions.filter(s => {
              return inputtedTags.indexOf(s.id) === -1;
            });
            return matchSorter(unusedSuggestions, query, {
              keys: ['name'],
              baseSort: (a, b) => (a.order < b.order ? -1 : 1)
            });
          }}
          addOnBlur={true}
          minQueryLength={0}
          onDelete={tagIndex => {
            const tags = inputTags.filter((_, i) => i !== tagIndex);
            setInputTags(tags);
            setData(tags);
          }}
          onAddition={newTag => {
            const tags = [...inputTags, newTag];
            setInputTags(tags);
            setData(tags);
          }}
          onValidate={newTag => {
            // tags should be unique and non-empty
            const inputtedTags = inputTags.map(t => t.name);
            return inputtedTags.indexOf(newTag.name) === -1 && newTag.name.trim() !== '';
          }}
        />
      )}
    </>
  );
};

export default CampaignTagsInput;
