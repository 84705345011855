import 'intl-pluralrules';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// Setup Sentry
import * as Sentry from '@sentry/browser';
import { defaults } from 'react-chartjs-2';

import { initMomentShortFormat } from './helpers/formats';
import Root from './components/Root';
import getConfig from './config';

// TODO get rid of theme thing in webapp altogether
import './css/themes/matchmade/index.scss';

Sentry.init({ dsn: getConfig('sentryDsn', null) });

// set up default configurations for chartjs
defaults.global.tooltips.cornerRadius = 0;
defaults.global.defaultFontFamily = 'GTWalsheim';
defaults.global.layout = {
  padding: {
    left: 15,
    right: 15,
    top: 0,
    bottom: 0
  }
};

initMomentShortFormat();
// Set theme class to html tag, since we might have
// styles that want to style that
document.getElementsByTagName('html')[0].className = 'matchmade';

ReactDOM.render(<Root />, document.getElementById('root'));
