import * as React from 'react';

function DiscordIcon(props) {
  return (
    <svg
      width="3rem"
      height="3rem"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M51.032 7h-38.19C9.623 7 7 9.622 7 12.871v38.532c0 3.25 2.622 5.871 5.843 5.871h32.319l-1.511-5.273 3.648 3.392 3.448 3.192L56.875 64V12.87c0-3.25-2.622-5.871-5.843-5.871z"
          fill="#7289DA"
        />
        <path
          d="M40.031 44.221s-1.025-1.225-1.88-2.308c3.733-1.055 5.158-3.392 5.158-3.392a16.289 16.289 0 01-3.278 1.681 18.756 18.756 0 01-4.132 1.226c-2.736.513-5.244.37-7.381-.028a23.941 23.941 0 01-4.19-1.226 16.722 16.722 0 01-2.08-.969c-.086-.057-.172-.085-.257-.143a.394.394 0 01-.114-.085c-.513-.285-.798-.484-.798-.484s1.368 2.28 4.987 3.362c-.854 1.084-1.909 2.366-1.909 2.366-6.299-.2-8.692-4.332-8.692-4.332 0-9.177 4.104-16.616 4.104-16.616 4.103-3.078 8.008-2.993 8.008-2.993l.285.343c-5.13 1.482-7.495 3.733-7.495 3.733s.626-.341 1.68-.826c3.05-1.34 5.473-1.71 6.47-1.795.171-.03.314-.058.485-.058a24.115 24.115 0 015.757-.056 23.232 23.232 0 018.578 2.735s-2.25-2.137-7.096-3.619l.399-.456s3.904-.086 8.008 2.993c0 0 4.105 7.438 4.105 16.615 0 0-2.423 4.132-8.722 4.332z"
          fill="#FFF"
        />
        <path
          d="M26.779 30.912c-1.625 0-2.907 1.425-2.907 3.163 0 1.739 1.31 3.163 2.907 3.163 1.625 0 2.907-1.424 2.907-3.163.029-1.739-1.282-3.163-2.907-3.163M37.182 30.912c-1.625 0-2.908 1.425-2.908 3.163 0 1.739 1.312 3.163 2.908 3.163 1.624 0 2.906-1.424 2.906-3.163 0-1.739-1.282-3.163-2.906-3.163"
          fill="#7289DA"
        />
      </g>
    </svg>
  );
}

export default DiscordIcon;
