// @flow
import type { DataProps as CampaignDescription } from '../../../components/campaignCreation/CampaignDescription';
import type { DataProps as CampaignDetails } from '../../../components/campaignCreation/CampaignDetails';
import type { CampaignType } from 'matchmade-types';
import type {
  DataProps as ProductSelection,
  SearchSettings
} from '../../../components/campaignCreation/ProductSelection';

import {
  AttributionPartner,
  CampaignGoal,
  CampaignType as CampaignTypeEnum,
  ContentPlatform,
  PromotionType
} from '@sharkpunch/matchmade-common/campaign';
import { Platform } from '@sharkpunch/matchmade-common/game';

import { AttributionPartnerOptionEnum as AttributionPartnerOption } from '../../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';
import enUS from '../../../locales/en-US';

export function getCampaignDescriptionDefaultValues(): $Exact<CampaignDescription> {
  const defaultContentPlatform = ContentPlatform.YOUTUBE;
  return {
    name: '',
    contentPlatforms: [defaultContentPlatform],
    promotionTypes: [PromotionType.DEDICATED],
    defaultPromotionType: PromotionType.DEDICATED,
    talkingPoints: '',
    isAutopilot: true,
    videoReview: true,
    // TODO:
    // how about whitelabel?
    // how to actually support i18n at redux level?
    restrictions: enUS[`campaign.description.default.${defaultContentPlatform}`],

    // temporary data, not sure where to put them
    previousRestrictions: ''
  };
}

export function getProductSelectionDefaultValues(): $Exact<ProductSelection> {
  return {
    selectedGames: [],
    selectedPlatforms: [Platform.ANDROID, Platform.IOS],
    campaignType: null
  };
}

export function getSearchGameToImportDefaultSettings(): $Exact<SearchSettings> {
  return {
    isSoftLaunch: false,
    softLaunchCountry: null,
    searchQuery: ''
  };
}

// default to game
export function getCampaignDetailsDefaultValues(
  campaignType?: CampaignType = CampaignTypeEnum.GAME
): $Exact<CampaignDetails> {
  return {
    goal: CampaignGoal.INSTALLS,
    budget: 0,
    njordBudget: 0,
    attributionPartner: AttributionPartner.NONE,
    attributionPartnerTrackingCode: null,
    attributionPartnerPlatformSpecificTrackingCodes: {},
    attributionPartnerSelectedOption: AttributionPartnerOption.URL,
    campaignType: CampaignTypeEnum.GAME
  };
}
