import getConfig from '../config';

/**
 * Collection of helper methods related to user.
 *
 */
const validUserRoles = [
  'influencer',
  'influencer_manager',
  'publisher',
  'admin',
  'whitelabel_admin',
  'anonymous'
];
const defaultRole = 'anonymous';

let currentUser = null;
let role = defaultRole;

// All of the features enabled for the currently logged in user
// You can't "disable" features it only keeps track of "enabled" features
let features = [];

function isRole(targetRole) {
  if (validUserRoles.indexOf(targetRole) === -1) {
    console.error(`Tried to perform check for invalid user role [${targetRole}].`);
    return false;
  }
  return role === targetRole;
}

// http://stackoverflow.com/a/46181
const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email) {
  return re.test(email);
}

const checkIfWhitelabelAdmin = () => isRole('whitelabel_admin'),
  checkIfInfluencer = () => isRole('influencer'),
  checkIfManagedInfluencer = () =>
    isRole('influencer') && currentUser && currentUser.teams && currentUser.teams.length,
  checkIfInfluencerManager = () => isRole('influencer_manager'),
  checkIfPublisher = () => isRole('publisher'),
  checkIfInfluencerOrManager = () => checkIfInfluencer() || checkIfInfluencerManager(),
  checkIfPublisherOrWhitelabelAdmin = () => checkIfPublisher() || checkIfWhitelabelAdmin(),
  checkIfAnonymous = () => isRole('anonymous'),
  setUser = user => {
    currentUser = user;
  },
  getUser = () => currentUser,
  isEmailUsable = email => {
    if (!email) {
      return false;
    }
    if (email.split('@')[1] === 'pages.plusgoogle.com') {
      return false;
    }
    return validateEmail(email);
  },
  setRole = targetRole => {
    if (role !== defaultRole) {
      console.error(
        `Attempted to set role to [${targetRole}], but role is already set to [${role}].`
      );
      return;
    }
    if (validUserRoles.indexOf(targetRole) === -1) {
      console.error(`Tried to set role to invalid value= [${targetRole}].`);
      return;
    }
    role = targetRole;
  },
  getRole = () => role,
  setFeatures = (f = []) => {
    if (Array.isArray(f)) {
      features = f;
    }
  },
  isFeatureEnabled = feature => {
    return features.indexOf(feature) !== -1;
  },
  getLandingUrl = () => {
    if (
      checkIfInfluencer() &&
      currentUser.hasInvites &&
      !(currentUser.teams && currentUser.teams.length)
    ) {
      return '/dashboard/influencer/invites';
    }

    return getConfig(`features.landingUrls.${role}`, '/404');
  },
  ___reset = () => {
    // This method is intended to be used only in tests or storybook
    if (!['test', 'development'].includes(process.env.NODE_ENV)) {
      return;
    }

    role = defaultRole; // only for testing OR very special cases
    currentUser = null;
    features = [];
  };

export {
  getLandingUrl,
  checkIfWhitelabelAdmin,
  checkIfInfluencer,
  checkIfManagedInfluencer,
  checkIfInfluencerManager,
  checkIfInfluencerOrManager,
  checkIfPublisherOrWhitelabelAdmin,
  checkIfPublisher,
  checkIfAnonymous,
  getUser,
  setUser,
  isEmailUsable,
  setRole,
  getRole,
  setFeatures,
  isFeatureEnabled,
  ___reset,
  defaultRole
};
