import { BroadcastChannel } from 'broadcast-channel';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Role } from '@sharkpunch/matchmade-common/user';
import MomentUtils from '@date-io/moment';
import React from 'react';

import { LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from '../css/materialUITheme';

import IndexRedirect from 'react-router/lib/IndexRedirect';
import IndexRoute from 'react-router/lib/IndexRoute';
import Redirect from 'react-router/lib/Redirect';
import Route from 'react-router/lib/Route';
import Router from 'react-router/lib/Router';
import browserHistory from 'react-router/lib/browserHistory';

import Layout from '../containers/Layout';
import AuthLayout from '../containers/AuthLayout';

import { AccountContextProvider, useAccountContext } from '../helpers/account';

import AdminDashboardEmail from '../containers/admin/AdminDashboardEmail';
import AdminDashboardInfluencers from '../containers/admin/AdminDashboardInfluencers';
import AdminDashboardPublishers from '../containers/admin/AdminDashboardPublishers';

import AdminDashboardAllocatedBudget from '../containers/admin/AdminDashboardAllocatedBudget';
import AdminDashboardCampaigns from '../containers/admin/AdminDashboardCampaigns';
import AdminDashboardCollections from '../containers/admin/AdminDashboardCollections';

import {
  CombineInstagramCollections,
  CombineYoutubeCollections
} from '../containers/admin/AdminDashboardCombineCollection';
import AdminDashboardAddChannelsToCollection from '../containers/admin/AdminDashboardAddChannelsToCollection';
import AdminDashboardAddInstagramChannelsToCollection from '../containers/admin/AdminDashboardAddInstagramChannelsToCollection';
import AdminDashboardAddTwitchChannelsToCollection from '../containers/admin/AdminDashboardAddTwitchChannelsToCollection';
import AdminDashboardTeam from '../containers/admin/AdminDashboardTeam';
import AdminDashboardTeams from '../containers/admin/AdminDashboardTeams';

import { FacebookApiExplorer, YoutubeApiExplorer } from '../containers/admin/ApiExplorer';

import { AdminLoginAs } from '../containers/admin/AdminLoginAs';
import AdminDashboardRedirectLinks from '../containers/admin/AdminDashboardRedirectLinks';
import AdminInvoicesPage from '../containers/admin/AdminInvoicesPage';
import AdminLogin from '../containers/admin/AdminLogin';
import AdminManagerTeamPage from '../containers/admin/AdminManagerTeamPage';

import AuthLandingV2 from '../containers/auth/AuthLanding';

import ReactPixel from 'react-facebook-pixel';

import PublisherCampaignPage from '../containers/PublisherCampaignPage';
import PublisherCampaignReportPage from '../containers/PublisherCampaignReportPage';
import PublisherCollectionPage from '../containers/PublisherCollectionPage';
import PublisherTeamManagePage from '../containers/PublisherTeamManagePage';
import PublisherYoutubeSearchPage from '../containers/PublisherYoutubeSearchPage';
import PublisherTwitchSearchPage from '../containers/PublisherTwitchSearchPage';
import PublicCollectionPage from '../containers/PublicCollectionPage';

import EditCampaign from '../containers/EditCampaign';
import PublisherDashboard from '../containers/PublisherDashboard';

import CampaignDescriptionStep from '../containers/campaignCreation/CampaignDescriptionStep';
import CampaignDetailsStep from '../containers/campaignCreation/CampaignDetailsStep';
import ProductSelectionStep from '../containers/campaignCreation/ProductSelectionStep';

import InfluencerProfilePage from '../containers/InfluencerProfilePage';

import InfluencerCampaignPage from '../containers/InfluencerCampaignPage';

import { InstagramChannelDataFetcher } from '../containers/admin/InstagramChannelDataFetcher';

import PaymentInfo from '../containers/PaymentInfo';
import PublicTermsPage from '../containers/PublicTermsPage';
import SocialMediaAccounts from '../containers/SocialMediaAccounts';

import AuthLanding from './auth/AuthLanding';
import Logout from './auth/Logout';
import NotFound from './auth/NotFound';

import FallbackErrorPage from './FallbackErrorPage';
import Maintenance from './maintenance';

import CreateMockInfluencerPage from '../containers/CreateMockInfluencerPage';

import * as Sentry from '@sentry/browser';
import { checkIfWhitelabelAdmin, getLandingUrl, getRole, isFeatureEnabled } from '../helpers/user';
import ReactGA from 'react-ga';
import getConfig from '../config';

import { IntlProvider } from '../locales';
import { fetchMe } from '../actions/user';
import { trackPageview } from '../helpers/mixpanelEvents';
import AdminDashboardManagers from '../containers/admin/AdminDashboardManagers';
import MessagesPage from '../containers/MessagesPage';
import configureStore from '../configureStore';

import AdminDashboardManagerTeams from '../containers/admin/AdminDashboardManagerTeams';
import TermsPage from '../containers/TermsPage';

import AdminBudgets from '../containers/admin/AdminBudgets';
import AdminFetchYoutubeContactEmailsPage from '../containers/admin/AdminFetchYoutubeContactEmailsPage';
import AdminPayoutsPage from '../containers/admin/AdminPayoutsPage';
import CreateCampaign from './admin/CreateCampaign';
import CreateInvoiceItem from './admin/CreateInvoiceItem';
import CreatePayout from './admin/CreatePayout';

function redirectTo(path) {
  class RedirectTo extends React.Component {
    static displayName = `RedirectTo(${path})`;

    componentDidMount() {
      browserHistory.push({
        pathname: path
      });
    }

    render() {
      return null;
    }
  }

  return RedirectTo;
}

if (process.env.NODE_ENV !== 'test') {
  // Don't initialize this when JEST etc. tests are running as initialization fails
  // and it doesn't really make sense to send these from React component unit tests.
  ReactGA.initialize(getConfig('googleAnalytics.trackingId'), getConfig('googleAnalytics.options'));
  const defaultFields = getConfig('googleAnalytics.defaultFields');
  if (defaultFields) {
    ReactGA.set(defaultFields);
  }

  ReactPixel.init(getConfig('facebookPixelId'));
}

const { store, persistor } = configureStore();

function getUserFromState() {
  const state = store.getState();
  const layout = state.layout.toJS ? state.layout.toJS() : state.layout;
  const user = layout.user || {};

  return user.toJS ? user.toJS() : user;
}

const redirectToSpecificUrlIfNeeded = function (pathname, replace, user) {
  if (
    !user ||
    !user.token ||
    (user.token.role !== Role.ADMIN && user.token.role !== Role.WHITELABEL_ADMIN)
  ) {
    window.location = `${getConfig('creatorApp.url')}?utm_source=old-app`;
  }
};

// https://github.com/ReactTraining/react-router/blob/master/docs/API.md#onenternextstate-replace-callback
// Redirect user to auth page if not logged in!
// Currently checks state on every transition
const canEnterPlatform = async (nextState, replace, callback) => {
  // If user is logged in, then continue to the page where we were transitioning to
  const user = getUserFromState();
  if (user && user.id) {
    redirectToSpecificUrlIfNeeded(nextState.location.pathname, replace, user);
    return callback();
  }

  try {
    const user = await store.dispatch(fetchMe.run());
    if (
      !user ||
      !user.token ||
      (user.token.role !== Role.ADMIN && user.token.role !== Role.WHITELABEL_ADMIN)
    ) {
      // redirect to admin login if this is admin route
      if (nextState.location.pathname.startsWith('/admin')) {
        replace('/auth/admin');
      } else {
        window.location = `${getConfig('creatorApp.url')}?utm_source=old-app`;
      }
      return callback();
    }
    new BroadcastChannel('user-channel').postMessage({
      user,
      payload: {
        type: 'SIGN_IN'
      }
    });
    ReactGA.set({ userId: user.id });

    const email = user.contactEmail || user.email;

    Sentry.configureScope(scope => {
      scope.setUser({ email, id: user.id });
    });
    if (user && window.location.pathname === '/') {
      replace(getLandingUrl());
    }
  } catch (error) {
    window.location = `${getConfig('creatorApp.url')}?utm_source=old-app`;
  }
  return callback();
};

const checkRoles = roles => {
  return (nextState, replace, callback) => {
    if (checkIfWhitelabelAdmin()) {
      // Admin can do anything
      return callback();
    }

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    const role = getRole();
    if (roles.indexOf(role) === -1) {
      // If the roles in this check are only Role.WHITELABEL_ADMIN
      // and Role.ADMIN, then the route is an admin-only route.
      // Since we ended up here the user was not logged in as an admin.
      // Thus redirect to /auth/admin.
      // Else, redirect to /auth.
      replace(
        roles.length === 2 && roles.indexOf(Role.WHITELABEL_ADMIN) && roles.indexOf(Role.ADMIN)
          ? '/auth/admin'
          : '/auth'
      );
    }

    return callback();
  };
};

const checkIfFeatureEnabled = feature => {
  return (nextState, replace, callback) => {
    if (isFeatureEnabled(feature)) {
      return callback();
    }
    // there is no route named not-found, so it defaults to route "*"
    replace('/not-found');
    return callback();
  };
};

const logPageView = () => {
  // https://github.com/react-ga/react-ga#usage
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  // Refreshes Help Scout articles that are based on
  // current route location
  if (window.Beacon) {
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title
    });
    window.Beacon('suggest');
  }

  trackPageview(window.location.pathname);
  ReactPixel.pageView();
};

function TestFeature() {
  return (
    <div>
      <p>Congrats! Your user account has the 'test-feature-1' feature flag set!</p>
      {isFeatureEnabled('test-feature-2') ? (
        <p>Congrats! Your user account has also the 'test-feature-2' feature flag set!</p>
      ) : null}
    </div>
  );
}

// This is straight from https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Caught error in error boundary', error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FallbackErrorPage />;
    }

    return this.props.children;
  }
}

// A collection of common wrappers to reduce the indentation level
function CommonWrappers(props) {
  const formats = {
    normalDate: 'YYYY MMM Do',
    keyboardDate: 'YYYY MMM Do'
  };
  return (
    <ErrorBoundary>
      <IntlProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={muiTheme}>
              <LocalizationProvider dateAdapter={MomentUtils} dateFormats={formats}>
                {props.children}
              </LocalizationProvider>
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

function Routes() {
  return (
    <Router history={browserHistory} onUpdate={logPageView}>
      <Route path="terms" component={Layout}>
        <IndexRoute component={redirectTo('/terms/influencer')} />
        <Route path={Role.INFLUENCER} component={PublicTermsPage} />
        <Route path={Role.INFLUENCER_MANAGER} component={PublicTermsPage} />
        <Route path={Role.PUBLISHER} component={PublicTermsPage} />
        <Route path="advertiser" component={PublicTermsPage} />
      </Route>
      <Route path="tos" component={TermsPage} />
      <Route path="auth/admin" component={AdminLogin} />
      <Route path="auth/failed" component={AuthLanding} />
      <Route path="auth/logout" component={Logout} />
      <Route path="/authenticate/v2" component={AuthLayout}>
        <Route path="landing" component={AuthLandingV2} />
      </Route>
      <Route path="collections/public/:cryptedId" component={PublicCollectionPage} />

      <Route path="/" component={Layout} onEnter={canEnterPlatform}>
        <Route path="payment-info" component={PaymentInfo} />
        <Route path="settings">
          <Route path="social-media-accounts" component={SocialMediaAccounts} />
        </Route>
        <Route
          path="my-profile"
          component={InfluencerProfilePage}
          onEnter={checkRoles([Role.INFLUENCER])}
        />
        {/* The following is an example on how to use feature flags on a route level and on a component level */}
        <Route
          path="test-feature-1"
          onEnter={checkIfFeatureEnabled('test-feature-1')}
          component={TestFeature}
        />

        <Route path="dashboard">
          <Route path="publisher" onEnter={checkRoles(Role.PUBLISHER)}>
            <IndexRoute component={PublisherDashboard} />

            <Route path="teams">
              <Route path=":teamId" component={PublisherTeamManagePage} />
            </Route>

            <Route path="campaigns">
              <Route path="create" onEnter={checkRoles(Role.PUBLISHER)}>
                <IndexRoute
                  component={redirectTo(
                    `/dashboard/publisher/campaigns/create/${CampaignDescriptionStep.url}`
                  )}
                />
                {[CampaignDescriptionStep, ProductSelectionStep, CampaignDetailsStep].map(
                  (Component, index) => {
                    return (
                      <Route
                        key={`gaming-campaign-creation-step-${index}`}
                        path={`${Component.url}`}
                        component={Component}
                      />
                    );
                  }
                )}
              </Route>

              <Route path=":id/edit" onEnter={checkRoles(Role.PUBLISHER)} component={EditCampaign}>
                <IndexRoute component={EditCampaign} />
              </Route>
              <Route path=":id/report" component={PublisherCampaignReportPage} />
              <Route path=":id/collection/:collectionId" component={PublisherCampaignPage} />
              <Route path=":id(/:tab)" component={PublisherCampaignPage} />
            </Route>
          </Route>
        </Route>

        <Route path="campaigns">
          <Route path=":id(/influencers/:influencerId)" component={InfluencerCampaignPage} />
        </Route>

        <Route path="messages">
          <Route path=":id" component={AccountContextWrapper} />
        </Route>

        <Route path="influencers">
          <Redirect from="search/v2" to="search" />
          <Route
            path="twitch/search"
            onEnter={checkRoles(Role.WHITELABEL_ADMIN)}
            component={PublisherTwitchSearchPage}
          />
          <Route
            path="youtube/search"
            onEnter={checkRoles(Role.WHITELABEL_ADMIN)}
            component={PublisherYoutubeSearchPage}
          />
          <Route
            path="profile/:id(/:slug)"
            component={InfluencerProfilePage}
            onEnter={checkRoles([Role.INFLUENCER_MANAGER, Role.ADMIN, Role.WHITELABEL_ADMIN])}
          />
        </Route>

        <Route
          path="collections/:id"
          component={PublisherCollectionPage}
          onEnter={checkRoles([Role.ADMIN, Role.WHITELABEL_ADMIN])}
        />

        <Route path="admin" onEnter={checkRoles([Role.ADMIN, Role.WHITELABEL_ADMIN])}>
          <IndexRoute component={AdminDashboardAllocatedBudget} />
          <Route path="collections">
            <IndexRoute component={AdminDashboardCollections} />
            <Route path="combine-youtube" component={CombineYoutubeCollections} />
            <Route path="combine-instagram" component={CombineInstagramCollections} />
            <Route path="add-channels" component={AdminDashboardAddChannelsToCollection} />
            <Route
              path="add-twitch-channels"
              component={AdminDashboardAddTwitchChannelsToCollection}
            />
            <Route
              path="add-instagram-channels"
              component={AdminDashboardAddInstagramChannelsToCollection}
            />
            <Route path=":id/fetch-emails" component={AdminFetchYoutubeContactEmailsPage} />
          </Route>
          <Route path="influencers">
            <IndexRoute component={AdminDashboardInfluencers} />
            {
              // For now this page can only be accessed by admin
              // Influencers can see their own page under
              // dashboard/influencer
            }
            <Route
              path="create"
              component={CreateMockInfluencerPage}
              onEnter={checkRoles([Role.ADMIN, Role.WHITELABEL_ADMIN])}
            />
          </Route>
          <Route path="managers">
            <IndexRoute component={AdminDashboardManagers} />
          </Route>
          <Route path="redirect-links">
            <IndexRoute component={AdminDashboardRedirectLinks} />
          </Route>
          <Route path="api-explorer">
            <IndexRoute component={YoutubeApiExplorer} />
            <Route path="youtube" component={YoutubeApiExplorer} />
            <Route path="facebook" component={FacebookApiExplorer} />
            <Route path="ig-posts" component={InstagramChannelDataFetcher} />
          </Route>
          <Route path="publishers" component={AdminDashboardPublishers} />
          <Route path="campaigns">
            <IndexRoute component={AdminDashboardCampaigns} />
            <Route path="allocated-budget">
              <IndexRoute component={AdminDashboardAllocatedBudget} />
            </Route>
            <Route path="new" component={CreateCampaign} />
            <Route path=":id/edit" component={EditCampaign}>
              <IndexRoute component={EditCampaign} />
            </Route>
            <Route path=":id/report" component={PublisherCampaignReportPage} />
            <Route path=":id/collection/:collectionId" component={PublisherCampaignPage} />
            <Route path=":id(/:tab)" component={PublisherCampaignPage} />
          </Route>
          <Route path="email">
            <IndexRoute component={AdminDashboardEmail} />
          </Route>
          <Route path="teams">
            <IndexRoute component={AdminDashboardTeams} />
            <Route path=":teamId" component={AdminDashboardTeam} />
          </Route>
          <Route path="manager-teams">
            <IndexRoute component={AdminDashboardManagerTeams} />
            <Route path=":teamId" component={AdminManagerTeamPage} />
          </Route>
          <Route path="login-as">
            <Route path=":role/:accountId" component={AdminLoginAs} />
          </Route>
          <Route path="invoices">
            <IndexRedirect to="status" />
            <Route path="status" component={AdminInvoicesPage} />
            <Route path="new-item" component={CreateInvoiceItem} />
          </Route>
          /ayout
          <Route path="payouts">
            <IndexRedirect to="update" />
            <Route path="update" component={AdminPayoutsPage} />
            <Route path="new" component={CreatePayout} />
          </Route>
          <Route path="budgets">
            <IndexRoute component={AdminBudgets} />
          </Route>
        </Route>

        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  );
}

export default function Root() {
  const inMaintenanceMode = getConfig('inMaintenanceMode', false);
  if (inMaintenanceMode) {
    // Maintenance mode -- catch-all route to render "We'll be back"
    return (
      <Router history={browserHistory}>
        <Route path="*" component={Maintenance} />
      </Router>
    );
  }

  // Normal mode
  return (
    <CommonWrappers>
      <Routes />
    </CommonWrappers>
  );
}

function MessagesPageWrapper(props) {
  const ctx = useAccountContext();
  return <MessagesPage {...props} accountContext={ctx} />;
}

const AccountContextWrapper = props => (
  <AccountContextProvider>
    <MessagesPageWrapper {...props} />
  </AccountContextProvider>
);
