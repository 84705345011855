import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// $FlowFixMe
import Select from 'react-select';

import { PrefixIndexStrategy, UnorderedSearchIndex } from 'js-search';
import createFilterOptions from 'react-select-fast-filter-options';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';

import Input from '../../../components/forms/controls/Input';

import './DynamicSelector.scss';

// To prioritise "Minecraft" when typing "mine" instead of "Guide for Minecraft" or something
const indexStrategy = new PrefixIndexStrategy();
// Unordered means order from server, which is by game popularity
const searchIndex = new UnorderedSearchIndex();

const countries = Object.entries(countryCodes).map(i => ({ code: i[0], name: i[1] }));

function CountryOptionRenderer(option) {
  return (
    <div className="Option">
      <label className="Option__label">{option.name}</label>
      <span
        title={option.name}
        className={`countryOptionFlag flag-icon flag-icon-${option.code.toLowerCase()}`}
      />
    </div>
  );
}

class ChannelFilters extends Component {
  constructor(props) {
    super(props);

    this.onSelectWhitelistCountries = this.onSelectWhitelistCountries.bind(this);
    this.onSelectBlacklistCountries = this.onSelectBlacklistCountries.bind(this);
  }

  onSelectWhitelistCountries(country) {
    this.props.onChangeChannelCountriesOrEstimatedCountriesWhitelist(
      this.props.channelCountriesOrEstimatedCountriesWhitelist
        .concat(country.code)
        .filter((v, i, arr) => arr.findIndex(a => a === v) === i)
    );
  }

  onSelectBlacklistCountries(country) {
    this.props.onChangeChannelCountriesOrEstimatedCountriesBlacklist(
      this.props.channelCountriesOrEstimatedCountriesBlacklist
        .concat(country.code)
        .filter((v, i, arr) => arr.findIndex(a => a === v) === i)
    );
  }

  renderWhitelistCountrySelector() {
    let options = (countries || [])
      .map(country => {
        if (this.props.channelCountriesOrEstimatedCountriesWhitelist.indexOf(country.code) !== -1)
          return null;
        return country;
      })
      .filter(Boolean);

    const filterOptions = createFilterOptions({
      options,
      indexStrategy,
      searchIndex,
      labelKey: 'name',
      valueKey: 'code',
      indexes: ['code', 'name'] // This allows typing "us" or "Unit" to return "United States of America"
    });

    return (
      <Select
        className="DynamicSelector"
        name="whitelist-countries"
        clearable={false}
        isLoading={this.props.isFetchingCountries}
        disabled={this.props.disabled}
        placeholder="Select countries the influencers are from"
        options={options}
        onChange={this.onSelectWhitelistCountries}
        filterOptions={filterOptions}
        multi={false}
        optionRenderer={CountryOptionRenderer}
        labelKey="name"
        valueKey="code"
      />
    );
  }

  renderBlacklistCountrySelector() {
    let options = (countries || [])
      .map(country => {
        if (this.props.channelCountriesOrEstimatedCountriesBlacklist.indexOf(country.code) !== -1)
          return null;
        return country;
      })
      .filter(Boolean);

    const filterOptions = createFilterOptions({
      options,
      indexStrategy,
      searchIndex,
      labelKey: 'name',
      valueKey: 'code',
      indexes: ['code', 'name'] // This allows typing "us" or "Unit" to return "United States of America"
    });

    return (
      <Select
        className="DynamicSelector"
        name="blacklist-countries"
        clearable={false}
        isLoading={this.props.isFetchingCountries}
        disabled={this.props.disabled}
        placeholder="Select countries the influencers are not from"
        options={options}
        onChange={this.onSelectBlacklistCountries}
        filterOptions={filterOptions}
        multi={false}
        optionRenderer={CountryOptionRenderer}
        labelKey="name"
        valueKey="code"
      />
    );
  }

  render() {
    return (
      <div className="YoutubeSearchSettings">
        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">
            <div className="YoutubeSearchSettings__group-item">
              <Input
                min={0}
                label={
                  <FormattedMessage id="youtube.search.setting.minSubscribers" tagName="strong" />
                }
                value={this.props.channelMinSubscriberCount}
                disabled={this.props.disabled}
                onChange={this.props.onChangeChannelMinSubscriberCount}
                onBlur={this.props.onBlurChannelMinSubscriberCount}
              />
            </div>
            <div className="YoutubeSearchSettings__group-item">
              <Input
                label={
                  <FormattedMessage id="youtube.search.setting.maxSubscribers" tagName="strong" />
                }
                min={0}
                value={this.props.channelMaxSubscriberCount}
                disabled={this.props.disabled}
                onChange={this.props.onChangeChannelMaxSubscriberCount}
                onBlur={this.props.onBlurChannelMaxSubscriberCount}
              />
            </div>
          </div>
        </div>

        <div className="is-flex-tablet">
          {/* Lame hack to have some spacing between inputs, but since we're about to retire this UI, it's ok*/}
          <div className="YoutubeSearchSettings__group">
            {this.renderWhitelistCountrySelector()}
          </div>
          <div className="YoutubeSearchSettings__group">
            {this.renderBlacklistCountrySelector()}
          </div>
        </div>

        <p className="has-text-centered">
          ℹ Setting any value here to `0` will exclude that value from search
        </p>
      </div>
    );
  }
}

ChannelFilters.propTypes = {
  disabled: PropTypes.bool,
  channelMinSubscriberCount: PropTypes.number.isRequired,
  channelMaxSubscriberCount: PropTypes.number.isRequired,
  channelCountriesOrEstimatedCountriesWhitelist: PropTypes.arrayOf(PropTypes.string).isRequired,
  channelCountriesOrEstimatedCountriesBlacklist: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeChannelMinSubscriberCount: PropTypes.func.isRequired,
  onBlurChannelMinSubscriberCount: PropTypes.func.isRequired,
  onChangeChannelMaxSubscriberCount: PropTypes.func.isRequired,
  onBlurChannelMaxSubscriberCount: PropTypes.func.isRequired,
  onChangeChannelCountriesOrEstimatedCountriesWhitelist: PropTypes.func.isRequired,
  onChangeChannelCountriesOrEstimatedCountriesBlacklist: PropTypes.func.isRequired
};

ChannelFilters.defaultProps = {
  disabled: false
};

ChannelFilters = injectIntl(ChannelFilters);

export default ChannelFilters;
