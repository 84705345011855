// @flow
import React from 'react';
// $FlowFixMe
import { Bar as BarChart } from 'react-chartjs-2';
import { getColorVariables } from '../../config';
import { hexToRgba } from '@sharkpunch/matchmade-common/color';
import { numberFormatter } from '@sharkpunch/matchmade-common/formatters';
import DateRangePicker from '../common/DateRangePicker';
import Spinner from '../common/Spinner';
import moment from 'moment';

const variables = getColorVariables();

type AnalyticsItem = {
  gameId: string,
  timestamp: string,
  viewerCount: number
};

type Props = {
  analytics: AnalyticsItem[],
  isLoading: boolean,
  startDate: any, // todo moment type?
  endDate: any,
  onChangeDate: (moment$Moment, moment$Moment) => void
};

function getAnalyticAttribute(attr, analytics) {
  if (!analytics) return [];
  return analytics.map(row => row[attr]);
}

function generateChartLabels(analytics) {
  return getAnalyticAttribute('timestamp', analytics).map((date, index, array) => {
    date = moment.utc(date);
    return date.format('YYYY-MM-DD HH:mm');
  });
}

function Chart({ analytics = [] }) {
  return (
    <BarChart
      height={100}
      data={{
        labels: generateChartLabels(analytics),
        datasets: [
          {
            type: 'bar',
            label: 'Concurrent viewership',
            data: getAnalyticAttribute('viewerCount', analytics),

            backgroundColor: hexToRgba(variables['mm-navy']),
            borderColor: hexToRgba(variables['mm-navy']),
            pointBackgroundColor: hexToRgba(variables['mm-navy']),
            pointBorderColor: hexToRgba(variables['mm-navy']),
            tension: 0,
            fill: false,

            yAxisID: 'y-ccv'
          }
        ]
      }}
      options={{
        responsive: true,
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              return `CCV: ${numberFormatter.format(tooltipItem.yLabel || 0)}`;
            }
          }
        },
        elements: {
          line: {
            fill: false
          }
        },
        legend: {
          display: true,
          labels: {
            fontSize: 14,
            fontColor: hexToRgba(variables['mm-navy']),
            boxWidth: 60
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: hexToRgba(variables['mm-navy']),
                fontSize: 14,
                padding: 10
              },
              labels: generateChartLabels(analytics)
            }
          ],
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-ccv',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              },
              ticks: {
                beginAtZero: true,
                fontSize: 14,
                fontColor: hexToRgba(variables['mm-navy']),
                padding: 10,
                callback: function (value) {
                  // Skip non-int labels, so we don't create 10 $0 labels, for instance
                  const v = parseInt(value, 10);
                  return isFinite(v) ? numberFormatter.format(v) : null;
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Concurrent viewership',
                fontColor: hexToRgba(variables['mm-navy']),
                fontSize: 16,
                fontStyle: 'bold'
              }
            }
          ]
        }
      }}
    />
  );
}

const CampaignTwitchKpiChart = ({
  analytics = [],
  isLoading = true,
  startDate = moment.utc().subtract(6, 'days'),
  endDate = moment.utc(),
  onChangeDate
}: Props) => {
  if (isLoading) {
    return <Spinner mode="fullWidth" />;
  }

  return (
    <div className="CampaignTwitchKpiChart">
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        isLoading={isLoading}
        onAccept={range => {
          const [startDate, endDate] = range;

          onChangeDate(startDate.utcOffset(0, true), endDate.utcOffset(0, true));
        }}
      />
      <div className="CampaignTwitchKpiChart__chart">
        <Chart analytics={analytics} />
      </div>
    </div>
  );
};

export default CampaignTwitchKpiChart;
