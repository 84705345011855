import PropTypes from 'prop-types';
import React from 'react';

import { useAsync } from 'react-async';
import Spinner from '../../../components/common/Spinner';

import callApi from '../../../helpers/api';

import './PayoutsTab.scss';

async function getPayoutsTask(options) {
  return callApi(`/agreements/${options.agreementId}/payout-task`);
}

const LoadingState = () => (
  <div>
    <Spinner mode="fullWidth" size="medium" />
  </div>
);

const PayoutTaskState = ({ status, amount, url }) => {
  if (status === 'payment_info_pending') {
    return <PayoutTaskPending amount={amount} url={url} />;
  } else {
    return <PayoutTaskConfirmed amount={amount} url={url} />;
  }
};

const PayoutTaskPending = ({ amount, url }) => (
  <div>
    <p>You have a payout pending</p>
    <h2>${amount}</h2>
    <a
      className="button Button is-medium is-primary"
      href={url}
      target="_blank"
      rel="noopener noreferrer">
      Confirm payout details
    </a>
  </div>
);

const PayoutTaskConfirmed = ({ amount, url }) => (
  <div>
    <p>Payout information confirmed</p>
    <h2>${amount}</h2>
    <a href={url} target="_blank" rel="noopener noreferrer">
      Check details
    </a>
  </div>
);

const ErrorState = () => (
  <div>
    <p>Could not load Payout information</p>
  </div>
);

const PayoutsTab = ({ agreementId }) => {
  const { data, isPending } = useAsync(getPayoutsTask, { agreementId });
  return (
    <div className="PayoutsTab PayoutsTab__empty">
      {isPending ? (
        <LoadingState />
      ) : data ? (
        <PayoutTaskState status={data.data.status} amount={data.data.amount} url={data.data.url} />
      ) : (
        <ErrorState />
      )}
    </div>
  );
};

PayoutsTab.propTypes = {
  agreementId: PropTypes.number.isRequired
};

// @lauri 2020-12-09
// somewhere upper in the call tree we do dumb stuff with
// reducers and updating _all of the tree_ with 2s interval
// without React.memo we'd trigger `useAsync` with that interval
export default React.memo(PayoutsTab);
