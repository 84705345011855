// @flow

import React from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import Avatar from '../messages/Avatar';
import DoneStatusIcon from '../common/Icons/DoneStatusIcon';
import PendingStatusIcon from '../common/Icons/PendingStatusIcon';
import Table, { Column, pageLimitOptions } from '../tables/Table';

import './ContactEmailsTable.scss';

export const TaskStatus = {
  FINISHED: 'FINISHED',
  PENDING: 'PENDING',
  NOT_STARTED: 'NOT_STARTED'
};

export type TaskStatusType = $Keys<typeof TaskStatus>;

type Props = {
  data: Array<{
    channelId: string,
    channelAvatarUrl: string,
    channelName: string,
    taskStatus: TaskStatusType,
    emailAddress: string
  }>
};

function ContactEmailsTable(props: Props) {
  return (
    <Table
      className="ContactEmailsTable is-fullwidth"
      data={props.data}
      searchBy={['channelName', 'scheduledDate', 'channelAddress']}
      pagination={props.data.length > pageLimitOptions[1]}>
      <Column
        name="channel"
        component={props => {
          const rowData = props.rowData;

          return (
            <span className="ContactEmailsTable__channel-column">
              <Avatar url={rowData.channelAvatarUrl} size={24} />
              <a
                href={`https://youtube.com/channel/${rowData.channelId}`}
                target="_blank"
                rel="noopener noreferrer">
                {rowData.channelName}
              </a>
            </span>
          );
        }}>
        <FormattedMessage id="admin.mturk.table.header.channel" />
      </Column>
      <Column name="channelId">
        <FormattedMessage id="admin.mturk.table.header.channelId" />
      </Column>
      <Column
        name="average30dViews"
        component={props => <FormattedNumber value={props.data} type="number" />}>
        <FormattedMessage id="admin.mturk.table.header.30dAvgViews" />
      </Column>
      <Column
        name="task"
        component={props => {
          const rowData = props.rowData;

          if (rowData.taskStatus === TaskStatus.FINISHED) {
            return (
              <DoneStatusIcon
                className="has-text-success"
                tooltipI18nString="admin.mturk.status.finished"
                withTooltip
              />
            );
          } else if (rowData.taskStatus === TaskStatus.PENDING) {
            return (
              <PendingStatusIcon
                className="has-text-warning"
                tooltipI18nString="admin.mturk.status.pending"
                withTooltip
              />
            );
          } else {
            return null;
          }
        }}>
        <FormattedMessage id="admin.mturk.table.header.task" />
      </Column>
      <Column
        name="emailAddress"
        component={props => {
          const rowData = props.rowData;

          if (!rowData.emailAddress && rowData.taskStatus === 'FINISHED') {
            return <FormattedMessage id="admin.mturk.noEmail" />;
          }

          return rowData.emailAddress;
        }}>
        <FormattedMessage id="admin.mturk.table.header.emailAddress" />
      </Column>
    </Table>
  );
}

export default ContactEmailsTable;
