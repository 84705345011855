// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import ChannelCountry from '../ChannelCountry';
import FormattedNumber from '../../common/FormattedNumber';

import './InstagramAnalyticsTab.scss';

type Props = {
  country: string | null,
  followerCount: number,
  engagementRate: number,
  postCount: number
};

export default function InstagramAnalyticsTab(props: Props) {
  const { country, followerCount, engagementRate, postCount } = props;
  return (
    <div className="InstagramAnalyticsTab">
      <div className="InstagramAnalyticsTab__stats-title">
        <FormattedMessage id="instagram.stats" />
      </div>
      <div className="InstagramAnalyticsTab__stats-rows">
        <div className="InstagramAnalyticsTab__stats-row-title">
          <FormattedMessage id="instagram.followers" />
        </div>
        <div className="InstagramAnalyticsTab__stats-row-value">
          <FormattedNumber value={followerCount} defaultToZero type="number" />
        </div>

        <div className="InstagramAnalyticsTab__stats-row-title">
          <FormattedMessage id="instagram.posts" />
        </div>
        <div className="InstagramAnalyticsTab__stats-row-value">
          <FormattedNumber value={postCount} defaultToZero type="number" />
        </div>

        <div className="InstagramAnalyticsTab__stats-row-title">
          <FormattedMessage id="instagram.engagement" />
        </div>
        <div className="InstagramAnalyticsTab__stats-row-value">
          <FormattedNumber value={engagementRate / 100} type="percentage" />
        </div>
      </div>
      <div className="InstagramAnalyticsTab__country">
        <ChannelCountry countryCode={country} />
      </div>
    </div>
  );
}
