import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { fetchInfluencers } from '../../actions/influencer';

import InfluencerTable from '../../components/admin/InfluencerTable';

import './AdminDashboardCommon.scss';

import { Helmet } from 'react-helmet';

import { AdminNoInfluencers } from './AdminNoInfluencers';
import Spinner from '../../components/common/Spinner';

// TODO flow this file
function AdminDashboardInfluencers(props) {
  const reducerState = useSelector(state => state.adminDashboardInfluencers);

  const { influencers, isLoadingInfluencers } = reducerState;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchInfluencers.run());
  }, [dispatch]);

  let content = null;

  if (isLoadingInfluencers) {
    content = <Spinner mode="fullWidth" size="large" />;
  } else if (!influencers || !influencers.length) {
    content = <AdminNoInfluencers />;
  } else {
    const tableProps = {
      ...props,
      ...reducerState
    };
    content = <InfluencerTable {...tableProps} />;
  }

  return (
    <div className="AdminDashboardInfluencers container">
      <Helmet title="Influencers" />
      {content}
    </div>
  );
}

export default AdminDashboardInfluencers;
