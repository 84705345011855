/**
 * Generic notification component with a button.
 *
 * Example:
 *
 *
 * ```
 * const actions = [{
 *  action: myAction,
 *  label: 'My label',
 *  type: 'success',
 *  icon: 'plus'
 * }];
 * <ActionableNotification type="warning" icon="meh-o" actions={actions}
 *   <FormattedMessage id="campaign.sections.influencers.addSimilarGames" />
 * </ActionableNotification>
 * ```
 */

// @flow
import * as React from 'react';

import type { Colors } from '../../types/bulma.flow';

import './ActionableNotification.scss';

type Action = {
  action: Function,
  label?: string,
  icon?: string,
  type?: Colors,
  loading?: boolean,
  disabled?: boolean
};

type Props = {
  actions?: Action[],
  children: ?React.Node,
  icon?: string,
  type?: Colors
};

const Actions = ({ items, type: parentType }) => {
  if (!items || !items.length) return null;

  return items.map((item, i) => {
    const { action, label, icon, loading, disabled = false } = item;
    const type = item.type || parentType;

    let buttonClass = 'button level-item ';
    if (loading) buttonClass += 'is-loading';
    // No inverted or type in case of loading, looks meh
    else buttonClass += `is-inverted is-${type}`;

    return (
      <div
        className="ActionableNotification__action level-item is-narrow"
        key={`action-${label || icon || i}`}>
        <button className={buttonClass} onClick={action} disabled={disabled}>
          {icon ? (
            <span className="icon is-small">
              <i className={`fa fa-${icon}`} />
            </span>
          ) : null}
          <span>{label}</span>
        </button>
      </div>
    );
  });
};

// DEPRECATED in favour of @material-ui/lab/Alert
const ActionableNotification = ({ children, actions = [], type = 'primary', icon = '' }: Props) => {
  if (!children) return null;

  return (
    <div className={`ActionableNotification notification is-${type} level`}>
      {icon ? (
        <span className="icon level-item is-narrow">
          <i className={`fa fa-lg fa-${icon}`} />
        </span>
      ) : null}

      <div className="ActionableNotification__content level-item">{children}</div>

      <Actions items={actions} type={type} />
    </div>
  );
};

export default ActionableNotification;
