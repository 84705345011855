import React from 'react';
import ReactDOM from 'react-dom';

import { ModalWithIntlProvider } from '../common/Modal';
import InfluencerEditableProfile from './InfluencerEditableProfile';
import ProfileIcon from '../common/Icons/ProfileIcon';
import Tooltip from '../common/Tooltip';
import usePrevious from '../../hooks/usePrevious';

import './InfluencerProfileModalTrigger.scss';

const PORTAL_NODE_CLASS = 'InfluencerProfilePortal';
function getRootNode() {
  return document.body;
}

function getPortalNode() {
  return document.querySelector(`.${PORTAL_NODE_CLASS}`);
}

function createPortalNode() {
  const node = document.createElement('div');
  node.className = PORTAL_NODE_CLASS;
  return node;
}

function appendPortalNode(node) {
  const rootNode = getRootNode();
  if (!rootNode) {
    return null;
  }

  rootNode.appendChild(node);
}

function deletePortalNode() {
  const portalNode = document.querySelector(`.${PORTAL_NODE_CLASS}`);
  const rootNode = getRootNode();
  if (portalNode) {
    ReactDOM.unmountComponentAtNode(portalNode);

    try {
      rootNode && rootNode.removeChild(portalNode);
    } catch (e) {}
  }
}

type Props = {
  accountId: number,
  disableSettings?: boolean,
  disableYoutubePreferences?: boolean,
  disableAgencyInfo?: boolean,
  tooltipText?: string,
  children?: React.Node | null
};

const InfluencerProfileModalTrigger = ({
  accountId,
  disableSettings,
  disableYoutubePreferences,
  disableAgencyInfo,
  tooltipText,
  children
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const previousAccountId = usePrevious(accountId);
  const previousIsOpen = usePrevious(isOpen);

  // The logic here is to only ever have 1 modal markup in DOM
  React.useEffect(() => {
    const isOpenSame = isOpen === previousIsOpen;
    const isSameAccount = previousAccountId === accountId;

    // If nothing has changed, no need to update the modal instance in DOM
    if (isSameAccount && isOpenSame) {
      return;
    }

    // When we have new account id, make sure that only the currently visible modal is updated
    if (!isSameAccount && !previousIsOpen) {
      return;
    }

    let portalNode = getPortalNode();

    // this is the first time we ever render the modal, create the portal node to host the modal
    if (!portalNode) {
      portalNode = createPortalNode();
      appendPortalNode(portalNode);
    }

    const content = (
      <ModalWithIntlProvider
        className="InfluencerProfileModal"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <InfluencerEditableProfile
          accountId={accountId}
          disableSettings={disableSettings}
          disableYoutubePreferences={disableYoutubePreferences}
          disableAgencyInfo={disableAgencyInfo}
        />
      </ModalWithIntlProvider>
    );

    ReactDOM.render(content, portalNode);

    return function cleanup() {
      deletePortalNode();
    };
  }, [
    accountId,
    isOpen,
    previousAccountId,
    previousIsOpen,
    disableSettings,
    disableYoutubePreferences,
    disableAgencyInfo
  ]);

  const defaultContent = <ProfileIcon />;
  const defaultTooltipText = 'Open influencer profile';

  return (
    <Tooltip tooltip={tooltipText || defaultTooltipText}>
      <button
        key="profile-modal-trigger"
        className="link-button ProfileModalTrigger"
        onClick={() => {
          setIsOpen(true);
        }}>
        {children || defaultContent}
      </button>
    </Tooltip>
  );
};

export default InfluencerProfileModalTrigger;
