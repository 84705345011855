// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import browserHistory from 'react-router/lib/browserHistory';
import classNames from 'classnames';

import { acceptTOS } from '../actions/user';
import {
  checkIfInfluencer,
  checkIfInfluencerManager,
  checkIfPublisher,
  getLandingUrl
} from '../helpers/user';
import FooterActions from '../components/common/FooterActions';
import InfluencerManagerTermsContent from '../components/tos/InfluencerManagerTermsContent';
import InfluencerTermsContent from '../components/tos/InfluencerTermsContent';
import PublisherTermsContent from '../components/tos/PublisherTermsContent';
import TosConfirmationModal from '../components/modals/TosConfirmationModal';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';
import type { DashboardUser } from '../types/account.flow';

import './TermsPage.scss';

type Props = {
  user: DashboardUser,
  acceptTOS: Function,
  isAcceptTosLoading: boolean
};

const TermsPage = ({ user, acceptTOS, isAcceptTosLoading }: Props) => {
  const [isModalOpened, setIsModalOpened] = React.useState(!user.hasAcceptedTos);

  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };

  const acceptTos = () => {
    acceptTOS().then(browserHistory.push(getLandingUrl()));
  };

  const footerActions = !user.hasAcceptedTos && (
    <FooterActions
      showBackButton={false}
      float
      onNext={acceptTos}
      isLoading={isAcceptTosLoading}
      nextButtonText="tos.acceptTerms"
    />
  );

  const className = classNames('TermsPage', {
    'TermsPage--accepted': user.hasAcceptedTos,
    'TermsPage--do-not-show-text': isModalOpened
  });

  return (
    <div className="container">
      <div className={className}>
        <TosConfirmationModal isOpen={isModalOpened} onClick={acceptTos} onClose={toggleModal} />
        {checkIfInfluencer() && <InfluencerTermsContent />}
        {checkIfInfluencerManager() && <InfluencerManagerTermsContent />}
        {checkIfPublisher() && <PublisherTermsContent />}
        {footerActions}
      </div>
    </div>
  );
};

const mapStateToProps = generateStateToProps('layout');
const mapDispatchToProps = generateDispatchToProps({
  acceptTOS
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TermsPage));
