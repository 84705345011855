//$FlowFixMe
import { useAsync } from 'react-async';

import ActionableNotification from '../../components/common/ActionableNotification';
import Button from '../../components/common/Button';
import Form from '../../components/forms/Form';
import Input from '../../components/forms/controls/Input';
import Modal from '../../components/common/Modal';

import { FormattedMessage } from 'react-intl';
import EmailCopyIcon from '../common/EmailCopyIcon';
import Link from 'react-router/lib/Link';
import React, { useState } from 'react';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import get from 'lodash/get';
import moment from 'moment';

import teamShape from '../../shapes/team';

import './TeamsTable.scss';

type Props = {
  teams: teamShape[],
  createTeam: any
};

function CreateTeamForm({ createTeam }) {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({});

  const onSubmitService = useAsync({
    deferFn: createTeam,
    onResolve: resolveData => {
      setMessage({
        type: 'info',
        text: `Successfully created team ${resolveData.data.name} [${resolveData.data.id}], close the modal or add another`
      });
    },
    onReject: e => {
      setMessage({
        type: 'danger',
        text: `Error when creating a team: ${e.message || 'Unknown error'}`
      });
    }
  });

  const onSubmit = formValues => {
    setMessage({});
    if (!formValues?.name) {
      setMessage({ type: 'danger', text: 'Name must not be empty' });
    } else {
      onSubmitService.run(formValues.name);
    }
  };

  return (
    <div>
      <button
        className="button is-primary"
        type="submit"
        onClick={() => {
          setShowModal(!showModal);
        }}>
        New team
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setMessage({});
          setShowModal(false);
        }}
        isBoxed={false}>
        <Form key="form" onSubmit={onSubmit}>
          <header className="modal-card-head">
            <p className="modal-card-title">Create a new team</p>
          </header>
          <section className="modal-card-body">
            {message && (
              <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
            )}

            <Input className="field" name="name" type="text" placeholder="Team name" />

            <Button buttonClassName="is-primary" type="submit" loading={onSubmitService.isPending}>
              <React.Fragment>Create new team</React.Fragment>
            </Button>
          </section>
        </Form>
      </Modal>
    </div>
  );
}

const TeamsTable = ({ teams, createTeam }: Props) => {
  return (
    <Table
      data={teams}
      initialSort="id"
      initialSortDirection="desc"
      className="TeamsTable is-fullwidth"
      pagination={teams.length > pageLimitOptions[1]}
      headerControls={[<CreateTeamForm key="createTeamForm" createTeam={createTeam} />]}
      searchBy={[
        'id',
        'name',
        function (team, searchInput) {
          const searchInputRegex = new RegExp(searchInput, 'gi');

          return (
            team.members.some(member => {
              const email = member.account.contactEmail || member.account.email;
              const displayName = member.account.displayName;

              return searchInputRegex.test(email) || searchInputRegex.test(displayName);
            }) ||
            team.invites.some(invite => {
              return searchInputRegex.test(invite.account.email);
            })
          );
        }
      ]}>
      <Column name="id">
        <FormattedMessage id="admin.teams.table.header.id" />
      </Column>
      <Column
        name="name"
        component={props => (
          <Link to={`/admin/teams/${props.rowData.id}`}>
            <span>{props.rowData.name}</span>
          </Link>
        )}>
        <FormattedMessage id="admin.teams.table.header.name" />
      </Column>
      <Column
        name="members"
        sortBy={(a, b) => {
          a = a || [];
          b = b || [];

          // desc so that we see publisher belonging to the most teams
          if (a.length !== b.length) return a.length - b.length;

          const aName = get(a[0], 'account.displayName', '');
          const bName = get(b[0], 'account.displayName', '');

          // asc to sort A-Z
          return bName.localeCompare(aName);
        }}
        component={props => {
          const team = props.rowData;

          const members = team.members.map((member, index) => {
            const email = member.account.contactEmail || member.account.email;
            return (
              <li key={index}>
                <EmailCopyIcon value={email} /> {member.account.displayName} ({member.role})
              </li>
            );
          });
          return <ul>{members}</ul>;
        }}>
        <FormattedMessage id="admin.teams.table.header.members" />
      </Column>
      <Column
        name="invites.length"
        component={props => {
          const team = props.rowData;

          const invites = team.invites.map((invite, index) => {
            return <li key={index}>{invite.account.email}</li>;
          });
          return <ul>{invites}</ul>;
        }}>
        <FormattedMessage id="admin.teams.table.header.invites" />
      </Column>
      <Column
        name="created"
        component={props => {
          const rowData = props.rowData;

          return <span>{moment.utc(rowData.created).format('MMM Do YYYY')}</span>;
        }}
        sortBy={created => new Date(created).getTime()}>
        <FormattedMessage id="admin.teams.table.header.created" />
      </Column>
      <Column
        name="trialEnded"
        component={props => {
          const rowData = props.rowData;

          return <span>{moment.utc(rowData.trialEnded).format('MMM Do YYYY')}</span>;
        }}
        sortBy={trialEnded => new Date(trialEnded).getTime()}>
        <FormattedMessage id="admin.teams.table.header.trialEnded" />
      </Column>
    </Table>
  );
};

export default TeamsTable;
