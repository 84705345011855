// @flow
import * as React from 'react';

import ReactDOM from 'react-dom';

import { ModalWithIntlProvider } from '../common/Modal';
import CollectionSelector from './CollectionSelector';
import FolderIcon from '../common/Icons/FolderIcon';

import './CollectionSelectorTrigger.scss';

export type Events = {
  onAdd: (collectionId: number) => void,
  onRemove: (collectionId: number) => void,
  onCreate?: (name: string) => void
};

type CollectionType = 'dynamic' | 'static';

export type Props = {
  selected: number[],
  collections: { id: number, name: string, searchId?: ?number }[],
  type?: CollectionType,
  selectedI18nString?: string,
  availableI18nString?: string,
  iconTooltip?: string,
  children?: React.Node | null,
  isLoading?: boolean
};

// Node where we render our modal to.
// WARNING! Don't remove this via cleanup, otherwise
// we might end up in situation where "onClick"
// event is bound to removed modal, and nothing will open
const PORTAL_NODE_CLASS = 'CollectionSelectorPortal';
function getPortalNode() {
  const existingNode = document.querySelector(`.${PORTAL_NODE_CLASS}`);
  if (existingNode) {
    return existingNode;
  }

  const rootNode = document.body;
  if (!rootNode) {
    return null;
  }

  const node = document.createElement('div');
  node.className = PORTAL_NODE_CLASS;
  rootNode.appendChild(node);
  return node;
}

const CollectionSelectorModal = ({
  isOpen,
  onClose,
  children
}: {
  isOpen: boolean,
  onClose: () => void,
  children: any
}) => {
  const portalNode = getPortalNode();
  if (!portalNode) {
    return null;
  }

  const content = (
    <ModalWithIntlProvider className="CollectionSelectorModal" isOpen={isOpen} onClose={onClose}>
      {children}
    </ModalWithIntlProvider>
  );

  return ReactDOM.createPortal(content, portalNode);
};

const CollectionSelectorTrigger = ({
  onAdd,
  onRemove,
  onCreate,
  collections,
  selectedI18nString,
  availableI18nString,
  iconTooltip,
  selected = [],
  isLoading = false,
  children
}: Props & Events) => {
  const [isOpen, setIsOpen] = React.useState(false);

  children = children || (
    <button className="link-button CollectionSelectorTrigger__button">
      <FolderIcon />
    </button>
  );

  return (
    <>
      <div
        key="collection-selector-trigger"
        className="CollectionSelectorTrigger"
        onClick={() => setIsOpen(true)}>
        {children}
      </div>
      {isOpen ? (
        <CollectionSelectorModal isOpen onClose={() => setIsOpen(false)}>
          <CollectionSelector
            selectedI18nString={selectedI18nString}
            availableI18nString={availableI18nString}
            options={collections}
            selected={selected}
            onAdd={onAdd}
            onRemove={onRemove}
            onCreate={onCreate}
            isLoading={isLoading}
          />
        </CollectionSelectorModal>
      ) : null}
    </>
  );
};

export default CollectionSelectorTrigger;
