// @flow

import React from 'react';

// $FlowFixMe
import { useAsync } from 'react-async';

import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';
import callApi from '../../helpers/api';
import { CsvUpload } from '../../helpers/CsvUpload';

import Button, { Color } from '../common/Button';
import ActionableNotification from '../common/ActionableNotification';
import OnOffToggle from '../common/OnOffToggle';
import Spinner from '../common/Spinner';

import Modal from '../common/Modal';
import Table, { Column } from '../tables/Table';

import config from '../../config';

import type { CampaignForPublisher } from '../../types/campaign.flow';

import './PromoCodes.scss';

type PromoCode = {
  code: string,
  campaignId: number,
  campaignAgreementId: number | null
};

async function updatePromoCodesCall(campaignId, codes) {
  try {
    const res = await callApi(`/campaigns/${campaignId}/promo-codes`, {
      method: 'PUT',
      body: codes
    });
    return res.data;
  } catch (error) {
    const requestId = error && error.requestId;

    const errorMessage = [
      error.message || 'Unexpected error',
      error.params || '',
      `Request id: [${requestId || 'unknown'}]`
    ].join('. ');
    throw new Error(errorMessage);
  }
}

async function getPromoCodesCall({ campaignId }) {
  try {
    const res = await callApi(`/campaigns/${campaignId}/promo-codes`, {
      method: 'GET'
    });
    return res.data;
  } catch (error) {
    const requestId = error && error.requestId;

    const errorMessage =
      (error.message || 'Unexpected error') + `. Request id: [${requestId || 'unknown'}]`;
    throw new Error(errorMessage);
  }
}

const getCampaign = async ({ campaignId }): Promise<?CampaignForPublisher> => {
  try {
    const res = await callApi(`/campaigns/${campaignId}`);
    return res.data;
  } catch (error) {
    const requestId = error && error.requestId;

    const errorMessage =
      (error.message || 'Unexpected error') + `. Request id: [${requestId || 'unknown'}]`;
    throw new Error(errorMessage);
  }
};

const togglePromoCodesForCampaign = async (id, shouldHavePromoCode) => {
  try {
    const res = await callApi(`/campaigns/${id}`, {
      method: 'PUT',
      body: { shouldHavePromoCode }
    });
    return res.data;
  } catch (error) {
    const requestId = error && error.requestId;

    const errorMessage =
      (error.message || 'Unexpected error') + `. Request id: [${requestId || 'unknown'}]`;
    throw new Error(errorMessage);
  }
};

function UploadCodes({ upload }) {
  const [codes, setCodes] = React.useState([]);
  const [showReset, setShowReset] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState({});

  const resetFormData = () => {
    setCodes([]);
    setMessage({});
  };

  const onSubmitService = useAsync({
    deferFn: async () => {
      return upload(codes);
    },
    onResolve: resolveData => {
      setShowReset(true);
      setMessage({
        type: 'info',
        text: `Added ${resolveData.length} codes. To add more, select another CSV.`
      });
    },
    onReject: e => {
      setShowReset(true);
      setMessage({
        type: 'danger',
        text: `Error when adding codes: ${e.message || 'Unknown error'} (Request ID: ${
          e.requestId || 'Unknown'
        })`
      });
    }
  });

  const onCsvData = csvData => {
    setMessage({});
    setCodes([]);
    setShowReset(false);
    let processed = [];
    for (const index in csvData) {
      if (!csvData[index].promo_code) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: missing promo_code`
        });
        return;
      }
      const { promo_code } = csvData[index];
      processed.push({
        code: String(promo_code).trim()
      });
    }
    setCodes(uniqBy(processed, 'code'));
  };

  return (
    <>
      <Button
        buttonClassName="is-primary"
        onClick={() => {
          setShowModal(!showModal);
        }}>
        <>Add codes</>
      </Button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          resetFormData();
          setMessage({});
          setShowModal(false);
        }}
        isBoxed={false}>
        <div>
          <header className="modal-card-head">
            <p className="modal-card-title">Add promo codes</p>
          </header>
          <section className="modal-card-body">
            <p className="notification is-info is-light">
              CSV needs to include one column: promo_code.
            </p>

            {message && (
              <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
            )}

            {codes.length === 0 || showReset ? (
              <CsvUpload onData={onCsvData} />
            ) : (
              <>
                <Button
                  buttonClassName="is-primary"
                  loading={onSubmitService.isPending}
                  onClick={onSubmitService.run}>
                  <>Add {codes.length} codes</>
                </Button>
                <Button
                  buttonClassName="is-secondary"
                  disabled={onSubmitService.isPending}
                  onClick={() => {
                    resetFormData();
                  }}>
                  <>Clear selection</>
                </Button>
              </>
            )}

            {codes.length > 0 && (
              <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                <Table className="is-fullwidth is-hoverable" data={codes} searchBy={[]}>
                  <Column name="code">Promo code</Column>
                </Table>
              </div>
            )}
          </section>
        </div>
      </Modal>
    </>
  );
}

const PromoCodesTable = ({ codes }: { codes: PromoCode[] }) => (
  <div style={{ overflow: 'auto' }}>
    <Table
      className="is-fullwidth is-hoverable"
      data={codes.map(c => {
        const campaignAgreementId = c.campaignAgreementId;
        return {
          ...c,
          campaignAgreementId: campaignAgreementId ? (
            <a
              href={`${config('bulkOfferTool.url')}/deals/${campaignAgreementId}`}
              target="_blank"
              rel="noopener noreferrer">
              {campaignAgreementId}
            </a>
          ) : null
        };
      })}
      searchBy={[]}>
      <Column name="code">Code</Column>
      <Column name="campaignAgreementId">Deal ID</Column>
    </Table>
  </div>
);

const CampaignPromoCodes = ({ campaignId }: { campaignId: number }) => {
  const [showPromoCodesTable, setShowPromoCodesTable] = React.useState<boolean>(false);

  const {
    data: campaign,
    setData: setCampaignData,
    isLoading: isLoadingCampagin,
    error: errorLoadingCampaign
  } = useAsync({
    promiseFn: getCampaign,
    campaignId
  });

  const {
    run: runTogglePromoCodes,
    isLoading: isUpdatingCampaign,
    error: errorUpdatingCampaign
  } = useAsync({
    deferFn: async ([shouldHavePromoCode]) => {
      const c = await togglePromoCodesForCampaign(campaignId, shouldHavePromoCode);
      setCampaignData(c);
    }
  });

  const {
    data: codes,
    isLoading: isLoadingPromoCodes,
    error: loadingPromoCodesError,
    setData: setExistingPromoCodes
  } = useAsync({
    promiseFn: getPromoCodesCall,
    campaignId: campaignId
  });

  const [usedCodes, unusedCodes] = partition(codes, c => !!c.campaignAgreementId);

  const isLoading = isLoadingPromoCodes || isLoadingCampagin;
  const error = loadingPromoCodesError || errorLoadingCampaign || errorUpdatingCampaign;

  const campaignHasActiveAgreements =
    campaign &&
    campaign.agreements &&
    campaign.agreements.findIndex(a => a.status === 'settled') !== -1;

  return (
    <div className="box PromoCodes">
      {isLoading ? (
        <Spinner size="medium" mode="inline" centered>
          Loading promo codes...
        </Spinner>
      ) : (
        <>
          <Modal
            isOpen={showPromoCodesTable}
            onClose={() => {
              setShowPromoCodesTable(false);
            }}
            isBoxed={false}>
            <PromoCodesTable codes={codes} />
          </Modal>
          <div className="is-flex is-align-items-center">
            <h2 className="label is-size-4 mr-2 mb-0">Promo codes</h2>
            <div className="field is-horizontal mb-0">
              <div className="field-body">
                <div className="field">
                  <div className="control is-expanded">
                    <div className="checkbox">
                      <OnOffToggle
                        isDisabled={isUpdatingCampaign || campaignHasActiveAgreements}
                        isLoading={isUpdatingCampaign}
                        className="pl-0"
                        isOn={campaign.shouldHavePromoCode}
                        onClick={runTogglePromoCodes}>
                        <span>Enabled</span>
                      </OnOffToggle>
                    </div>
                    {campaignHasActiveAgreements ? (
                      <p className="help">
                        This campaign has active deals, so you can't{' '}
                        {campaign.shouldHavePromoCode ? 'disable' : 'enable'} this anymore.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="is-flex is-flex-grow-1 is-align-items-center is-justify-content-end is-flex-wrap-wrap">
              <div className="is-flex is-align-items-center p-2">
                <div className="stats-elem">
                  <span className="num">{codes.length}</span>
                  <span className="label">All codes</span>
                </div>
                <div className="stats-elem">
                  <span className="num">{usedCodes.length}</span>
                  <span className="label">Used codes</span>
                </div>
                <div className="stats-elem">
                  <span className="num">{unusedCodes.length}</span>
                  <span className="label">Available codes</span>
                </div>
              </div>

              <div className="is-flex is-align-items-center mb-1 p-2">
                <Button
                  className="is-flex-grow-0 mr-2"
                  color={Color.LIGHT}
                  onClick={() => {
                    setShowPromoCodesTable(true);
                  }}>
                  <span>Show existing codes</span>
                </Button>
                <UploadCodes
                  upload={async newCodes => {
                    const uploadedCodes = await updatePromoCodesCall(
                      campaign.id,
                      newCodes.map(r => ({
                        code: r.code,
                        campaignId: campaign.id,
                        campaignAgreementId: null
                      }))
                    );
                    setExistingPromoCodes(uniqBy([...uploadedCodes, ...codes], 'code'));
                    return newCodes;
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {error && <div className="notification is-danger">Error: {error.message}</div>}
    </div>
  );
};

export default CampaignPromoCodes;
