// @flow
import { createApiAction, createSimpleAction } from './helper';
export type FetchInfluencers = () => Promise<any>;

export const fetchInfluencers = createApiAction('FETCH_INFLUENCERS', () => {
  return {
    path: '/influencers'
  };
});

export const mock = createApiAction('MOCK_INFLUENCER', data => {
  const { youtubeChannel, ...influencer } = data;
  return {
    path: `/influencers`,
    options: {
      body: { ...influencer, youtubeChannel },
      method: 'POST'
    }
  };
});

export const resetMock = createSimpleAction('RESET_MOCK_INFLUENCER');

export const importYoutubeInfluencersByChannel = createApiAction(
  'IMPORT_YOUTUBE_INFLUENCERS_BY_CHANNEL_ID',
  ids => {
    return {
      path: '/youtube/channels/v2/import',
      options: {
        body: { id: ids },
        method: 'POST'
      }
    };
  }
);
