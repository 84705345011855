// @flow
import React from 'react';

import { FormattedMessage } from 'react-intl';

import Input from '../../forms/controls/Input';

import './Countries.scss';
import AddIcon from '../../common/Icons/AddIcon';
import Button from '../../common/Button';
import CountrySelection from '../../common/CountrySelection';
import DeleteIcon from '../../common/Icons/DeleteIcon';

type Props = {
  country: any,
  onChange: (data: {}) => void,
  addCountry: () => void,
  deleteCountry: (countryCode: string) => void,
  numberOfCountries?: 4 | 5 | 6 | 7 | 8
};

const Countries = (props: Props) => {
  const {
    country,
    onChange = (data: {}) => {},
    deleteCountry = country => {},
    numberOfCountries = 4,
    addCountry
  } = props;
  const othersValue =
    Math.round(
      (100 - Object.values(country || {}).reduce((prev, next) => prev + +(next || 0), 0)) * 100
    ) / 100;

  const countries = Object.keys(country || {});
  while (countries.length < numberOfCountries) countries.push(''); // we need exactly 4 countries
  return (
    <div className="Countries">
      <h1>Countries</h1>
      <ol>
        {countries.map((countryCode, i) => {
          const value = (country || {})[countryCode] || '';
          return (
            <li className="Countries__entry is-flex" key={`${countryCode}_${i}`}>
              <span className="Countries__label">
                <CountrySelection
                  selected={countryCode}
                  clearable={false}
                  onChange={item => {
                    if (!item) {
                      return;
                    }

                    const { code } = item;
                    // Make sure we don't add new row when we modify existing country selection
                    const data = Object.entries({ ...country, [code]: value })
                      .map((entry, index) => {
                        if (index !== i) return entry;
                        return [code, entry[1]];
                      })
                      .reduce((obj, entry) => {
                        obj[entry[0]] = entry[1];
                        return obj;
                      }, {});

                    onChange(data);
                  }}
                />
              </span>
              <span className="Countries__value">
                <Input
                  type="number"
                  step={1}
                  min={1}
                  max={100}
                  disabled={!countryCode}
                  name={countryCode}
                  value={value}
                  onChange={e => {
                    onChange({ ...country, [countryCode]: e.target.value });
                  }}
                  iconLeft={<i className="fa fa-percent" />}
                />
              </span>
              <span className="Countries__actions">
                <Button
                  onClick={() => deleteCountry(countryCode)}
                  transparent
                  disabled={numberOfCountries <= 4}
                  size="small">
                  <DeleteIcon withTooltip={false} />
                </Button>
                <Button
                  className="Countries__actions__add-button"
                  onClick={addCountry}
                  transparent
                  size="small"
                  disabled={numberOfCountries >= 8}>
                  <AddIcon type="circle" withTooltip={false} />
                </Button>
              </span>
            </li>
          );
        })}
        <li className="Countries__entry is-flex">
          <span className="Countries__label">
            <FormattedMessage id="lists.other" />
          </span>
          <span className="Countries__value">
            <Input
              type="number"
              min={0}
              max={100}
              name="ZZ"
              disabled
              value={othersValue}
              iconLeft={<i className="fa fa-percent" />}
            />
          </span>
        </li>
      </ol>
    </div>
  );
};

export default Countries;
