// @flow
import type { Options as FetchOptions } from '../helpers/api';

// $FlowFixMe
import { createAction } from 'redux-actions';

import api from '../helpers/api';

import get from 'lodash/get';

// This is needed for old or headless browsers that don't support AbortController
// $FlowFixMe
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

let CACHED = {};
// {path1: AbortControllerInstance, path2: AbortControllerInstance...}
let abortableRequests = {};

export function clearCache() {
  CACHED = {};
}

type Dispatcher<T> = (dispatch: Function, getState: Function) => T;

type Options = {
  loadingAction?: string,
  abortOngoingRequest?: boolean,
  success: any,
  failure: any,
  thenFn?: Function,
  catchFn: Function,
  key: string,
  loadingPayload?: any,
  state?: any
};

/**
 * Generate the implementation for an action creator
 * to call an API.
 * @param  {String} path the API path (without /api prefix)
 * @param  {Object} fetchOptions define the options passed directly to fetch
 * @param  {Object} options define the actual options (naming is hard...) to configure the returned function
 * @param  {String} options.loadingAction is the type for the "loading" state of the action
 * @param  {Object} options.loadingPayload is the payload for "loading" state of the action
 * @param  {boolean} options.abortOngoingRequest whether ongoing request should be aborted if we called this again
 *                   before previous request completed
 * @param  {Array} options.state is an array of strings used for checking whether to call the API or not. If the current state tree
 * contains value for at least 1 string in the array, "success" state is triggered with that value
 * @param  {Function} options.success defines which action to perform using the data returned from the API
 * @param  {Function} options.failure defines which action to perform using the error returned from the API
 * @param  {String} options.key is the cache key for the call. This is important for actions that might be trigger
 * @param  {Function} options.thenFn is a function which returns another function to be used in then clause
 * @param  {Function} options.catchFn is a function which returns another function to be used in catch clause
 * multiple times. The default behavior (for now) is to return the first Promise.
 * @todo Add an option to change the cache behavior to clear the current one and perform a new one
 * @return {Function}
 */
export function callApi(path: string, fetchOptions: FetchOptions = {}, options: Options = {}) {
  const loadingAction = options.loadingAction;

  const abortOngoingRequest = options.abortOngoingRequest;
  let signal;
  if (abortOngoingRequest) {
    // If we need to abort request, we don't care about query string
    const pathForRequestToAbort = path.split('?')[0];
    const abortController = abortableRequests[pathForRequestToAbort];
    if (abortController) {
      abortController.abort();
      delete abortableRequests[path];
    }
    const controller = new AbortController();
    signal = controller.signal;
    abortableRequests[pathForRequestToAbort] = controller;
  }

  const success = options.success;
  const failure = options.failure;
  const thenFn =
    options.thenFn ||
    function (dispatch, getState) {
      return function (result) {
        return result;
      };
    };

  const catchFn =
    options.catchFn ||
    function (dispatch, getState) {
      return function (error) {
        return Promise.reject(error);
      };
    };
  const key = options.key;
  const loadingPayload = options.loadingPayload || {};
  const state = options.state || [];

  return (dispatch: Function, getState: Function) => {
    if (key && CACHED[key]) {
      return CACHED[key];
    }

    if (loadingAction) {
      dispatch({
        type: loadingAction,
        payload: loadingPayload
      });
    }

    if (state && state.length) {
      const currentState = getState();
      for (let i = 0; i < state.length; i++) {
        const s = state[i];
        const value = get(currentState, s);
        // what about false? no one caches false!!!
        if (value) {
          // Found it, just trigger success state and return.
          // Include loadingPayload when dispatching success, in case reducer needs it (see createApiAction)
          // Pagination is included in meta as well, but only in cases where it's defined
          const meta = { loadingPayload };
          dispatch(success(value, meta));
          delete CACHED[key];
          return Promise.resolve(value)
            .then(thenFn(dispatch, getState))
            .catch(catchFn(dispatch, getState));
        }
      }
    }

    const promise = api(path, fetchOptions, signal)
      .then(thenFn(dispatch, getState))
      .then(result => {
        // Include loadingPayload when dispatching success, in case reducer needs it (see createApiAction)
        // Pagination is included in meta as well, but only in cases where it's defined
        const meta: {
          loadingPayload: any,
          pagination: any
        } = { loadingPayload, pagination: null };
        const data =
          result && result.hasOwnProperty('data')
            ? result.data
            : // Feels like this should be actually set to null instead of {},
              // but didn't want to change the behaviour from what it was previously.
              {};
        if (result && result.hasOwnProperty('pagination')) meta.pagination = result.pagination;
        if (success) dispatch(success(data, meta));
        delete CACHED[key];
        return data;
      })
      .catch(error => {
        delete CACHED[key];
        if (error.name === 'AbortError') return;
        // Include loadingPayload when dispatching error, in case reducer needs it (see createApiAction)
        // We don't include pagination information in case of errors
        const meta = { loadingPayload };
        if (failure) dispatch(failure(error, meta));
      })
      .catch(catchFn(dispatch, getState));

    if (key) CACHED[key] = promise;
    return promise;
  };
}

type ApiActionCreator = {
  REQUEST: string,
  SUCCESS: string,
  FAILURE: string,

  success: any,
  failure: any,

  run: (...args: any[]) => Dispatcher<Promise<any>>
};

/**
 * Creates an action creator that in turn creates actions
 * which communicates with the API
 * @param  {String}   name the name of the action
 * @param  {Function} fn is the function to construct the API call
 * and various other options based on the provided arguments
 * @return {[type]}        [description]
 */
export function createApiAction(name: string, fn: Function): ApiActionCreator {
  const action = {};
  // 3 different states for each action
  action.REQUEST = `${name.toUpperCase()}_REQUEST`;
  action.SUCCESS = `${name.toUpperCase()}_SUCCESS`;
  action.FAILURE = `${name.toUpperCase()}_FAILURE`;

  // 2 action creators for success and failure state
  // In both of these, we don't do any extra data processing (null)
  // but create extra "meta" object (seconds function argument)
  const success = createAction(action.SUCCESS, null, (data, meta) => meta);
  const failure = createAction(action.FAILURE, null, (data, meta) => meta);

  // then expose them as well
  action.success = success;
  action.failure = failure;

  // Do not use fat arrow here as we need to use `arguments`
  // which is tied to the function context
  action.run = function (...callArgs: any[]) {
    const args = fn.apply(null, callArgs);

    const loadingAction = args.loadingAction !== undefined ? args.loadingAction : action.REQUEST;

    return callApi(args.path, args.options || {}, {
      loadingAction: loadingAction,
      loadingPayload: args.loadingPayload,
      abortOngoingRequest: args.abortOngoingRequest,
      state: args.state || [],
      // stringify callArgs for cache, otherwise if we call API function with object, we will always
      // have only first call in cache
      key: [action.REQUEST].concat(JSON.stringify(callArgs)).join(':'),
      success: success,
      failure: failure,
      thenFn: args.thenFn,
      catchFn: args.catchFn
    });
  };

  return action;
}

type SimpleActionCreator = {
  SUCCESS: string,
  success: Function,
  run: (...args: any[]) => Dispatcher<void>
};

export function createSimpleAction(name: string, fn?: Function): SimpleActionCreator {
  const action = {};
  // 1 state for each action
  action.SUCCESS = `${name.toUpperCase()}_SUCCESS`;

  // 1 action creators for success state
  const success = createAction(action.SUCCESS, fn);

  // then expose it as well
  action.success = success;

  // Do not use fat arrow here as we need to use `arguments`
  // which is tied to the function context
  action.run = function (...args: any[]) {
    return (dispatch: Function, getState: Function) => {
      dispatch(success.apply(null, args));
    };
  };

  return action;
}
