import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ReactTags from 'react-tag-autocomplete';

import { matchSorter } from 'match-sorter';

import { useAsync } from 'react-async';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../Spinner';
import callApi from '../../../helpers/api';
import classNames from 'classnames';

import './AdminDealTagInput.scss';

async function getTagsApiCall({ agreementId }) {
  const r = await callApi(`/agreements/${agreementId}`);
  return r.data.dealTags;
}

async function getTagSuggestionsApiCall({ agreementId }) {
  return callApi(`/admin/deal-tags/suggestions`);
}

async function saveTagsApiCall({ agreementId, tags }) {
  return callApi(`/admin/deal-tags/${agreementId}`, {
    method: 'POST',
    body: { dealTags: tags }
  });
}

function tagToInputFormat(tag) {
  return { id: tag, name: tag };
}

function tagToInputFormatWithCount(tag) {
  return { id: tag, name: tag, count: tag.count };
}

function tagFromInputFormat(inputTag) {
  return inputTag.name;
}

const LoadingState = () => (
  <div className="level level-left">
    <Spinner size="medium" mode="inline" />
    <div className="ml-2"> Loading tags</div>
  </div>
);

const ErrorState = () => (
  <div className="notification is-danger is-light">Unable to load tags for this deal</div>
);

const TagInput = ({ tags, suggestions, onSaveTags }) => {
  const [inputTags, setInputTags] = useState(tags.map(tagToInputFormat));
  const [error, setError] = useState(null);
  const [isDirty, setIsDirty] = useState(null);
  const [isSaved, setIsSaved] = useState(null);

  const saveTagsService = useAsync({
    deferFn: () => {
      return onSaveTags(inputTags.map(tagFromInputFormat));
    },
    onResolve: () => {
      setError(null);
      setIsDirty(false);
      setIsSaved(true);
    },
    onReject: e => setError(e.message || 'An error')
  });

  return (
    <>
      <ReactTags
        tags={inputTags}
        suggestions={suggestions.map(suggestion => tagToInputFormatWithCount(suggestion.tag))}
        allowNew={true}
        suggestionsTransform={(query, suggestions) => {
          const inputtedTags = inputTags.map(t => t.id);
          const unusedSuggestions = suggestions.filter(s => {
            return inputtedTags.indexOf(s.id) === -1;
          });
          return matchSorter(unusedSuggestions, query, {
            keys: ['name'],
            baseSort: (a, b) => (a.count < b.count ? -1 : 1)
          });
        }}
        addOnBlur={true}
        minQueryLength={0}
        onDelete={tagIndex => {
          setInputTags(inputTags.filter((_, i) => i !== tagIndex));
          setIsDirty(true);
        }}
        onAddition={newTag => {
          setInputTags([...inputTags, newTag]);
          setIsDirty(true);
        }}
        onValidate={newTag => {
          // tags should be unique and non-empty
          const inputtedTags = inputTags.map(t => t.name);
          return inputtedTags.indexOf(newTag.name) === -1 && newTag.name.trim() !== '';
        }}
      />
      <div className="level level-left mt-2">
        <button
          disabled={saveTagsService.isLoading}
          className={classNames('button is-primary', {
            'is-loading': saveTagsService.isLoading
          })}
          onClick={saveTagsService.run}>
          Save tags
        </button>
        {isDirty && (
          <div className="ml-2 notification is-info is-light p-2">You have unsaved changes</div>
        )}
        {!isDirty && isSaved && (
          <div className="ml-2 notification is-success is-light p-2">Changes saved</div>
        )}
      </div>
      {error && (
        <Alert severity="error" className="mt-2">
          Error when saving tags: {error}
        </Alert>
      )}
    </>
  );
};

const AdminDealTagInput = ({ agreementId }) => {
  const { data: tags, error: tagsError, isPending: tagsPending } = useAsync(getTagsApiCall, {
    agreementId
  });
  const { data: suggestions, isPending: suggestionsPending } = useAsync(getTagSuggestionsApiCall, {
    agreementId
  });

  const renderContent = () => {
    if (tagsPending || suggestionsPending) {
      return <LoadingState />;
    } else if (tagsError) {
      return <ErrorState />;
    } else {
      return (
        <TagInput
          tags={tags}
          suggestions={suggestions.data}
          onSaveTags={async tags => {
            return saveTagsApiCall({ agreementId, tags });
          }}
        />
      );
    }
  };
  return <div className="AdminDealTagInput">{renderContent()}</div>;
};

AdminDealTagInput.propTypes = {
  agreementId: PropTypes.number.isRequired
};

// @lauri: somewhere upper in the call tree we do dumb stuff with
// reducers and updating _all of the tree_ with 2s interval
// without React.memo we'd trigger `useAsync` with that interval
export default React.memo(AdminDealTagInput);
