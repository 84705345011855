// @flow
import React from 'react';

import Icon from '../Icon';
import InstagramSvg from '../../../images/icons/InstagramIcon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const InstagramIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="InstagramIcon">
      {() => <InstagramSvg />}
    </Icon>
  );
};

export default InstagramIcon;
