import { createApiAction } from './helper';

export const fetchTeams = createApiAction('FETCH_TEAMS', () => {
  return {
    path: `/teams`,
    options: {
      method: 'GET'
    }
  };
});

// TODO this could also update commission rate for team
export const updateTeam = createApiAction('UPDATE_TEAM', (id, name) => {
  return {
    path: `/teams/${id}`,
    options: {
      method: 'PUT',
      body: {
        name
      }
    }
  };
});
