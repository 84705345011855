// @flow
import type { ReducerCreator } from '../../types/action.flow';

import { updateSettings } from '../../actions/account';

export type UpdateSettingsActionAttributes = {
  isUpdatingAccountSettings: boolean,
  user?: Object // TODO: have proper type for user
};
export const handleUserSettingActions: ReducerCreator<
  void,
  UpdateSettingsActionAttributes
> = () => {
  return (state, action) => {
    switch (action.type) {
      case updateSettings.REQUEST:
        return {
          ...state,
          isUpdatingAccountSettings: true
        };
      case updateSettings.SUCCESS:
        const { user } = state;

        if (user) {
          return {
            ...state,
            isUpdatingAccountSettings: false,
            user: {
              ...user,
              settings: action.payload.settings
            }
          };
        }

        return {
          ...state,
          isUpdatingAccountSettings: false
        };
      case updateSettings.FAILURE:
        return {
          ...state,
          isUpdatingAccountSettings: false,
          error: null
        };
      default:
        return state;
    }
  };
};
