// Shield-like clip path. Should
// automatically scale to image
//
// to use, just add
// clip-path: url(#shield)
// to your css element

import React from 'react';

const ShieldClipPath = props => (
  <svg viewBox="0 0 80 100" {...props}>
    <defs>
      <clipPath id="shield" clipPathUnits="objectBoundingBox" transform="scale(.01)">
        <path d="M50 0L10 18v27c0 25 17 49 40 55 23-6 40-30 40-55V18L50 0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ShieldClipPath;
