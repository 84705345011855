import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import injectRoleChecking from '../../hoc/injectRoleChecking';

import { renderValidationError } from '../../helpers/formHelper';
import teamShape from '../../shapes/team';

import Avatar from '../messages/Avatar';
import renderComponentSection from '../../helpers/renderComponentSection';

import Modal from '../common/Modal';
import Table, { Column } from '../tables/Table';

import { FormattedMessage, injectIntl } from 'react-intl';

import TeamPaymentInfoForm from './TeamPaymentInfoForm';

import './TeamManage.scss';

import DeleteIcon from '../common/Icons/DeleteIcon';
import PersonIcon from '../common/Icons/PersonIcon';
import isEqual from 'lodash/isEqual';

class TeamTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberToDelete: null
    };

    this.onCloseConfirmMemberDeleteModal = this.onCloseConfirmMemberDeleteModal.bind(this);
    this.onDeleteTeamMember = this.onDeleteTeamMember.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.team, nextProps.team) ||
      !isEqual(this.state.memberToDelete, nextState.memberToDelete)
    );
  }

  renderCurrentTeamMembers() {
    const team = this.props.team;
    const members = team.members.concat(team.invites);

    // flatten the data here to make it easier to work with in Griddle

    const results = members.map(member => {
      const account = member.account || {};

      return {
        id: member.id,
        verified: member.verified,
        displayName: account.displayName,
        avatarUrl: account.avatarUrl,
        email: account.contactEmail || account.email,
        role: member.role,
        hasAccount: !!account.id,
        accountId: account.id
      };
    });

    return (
      <Table data={results} className="is-fullwidth">
        <Column
          name="displayName"
          component={props => {
            const member = props.rowData;
            const displayName = member.displayName ? (
              <span>{member.displayName}</span>
            ) : (
              <div className="PublisherTeamManage__no-display-name">
                <FormattedMessage id="team.member.displayName.notSet" />
              </div>
            );
            return displayName;
          }}>
          <FormattedMessage id="team.member.displayName"></FormattedMessage>
        </Column>
        <Column name="email">Email</Column>
        <Column
          name="avatarUrl"
          component={props => {
            const member = props.rowData;
            const displayName =
              member.displayName ||
              this.props.intl.formatMessage({
                id: 'team.member.displayName.notSet'
              });
            const avatarUrl = member.avatarUrl;

            return <Avatar alt={displayName} size={32} url={avatarUrl} />;
          }}>
          <FormattedMessage id="team.member.avatar"></FormattedMessage>
        </Column>
        <Column
          name="role"
          component={props => (
            <FormattedMessage id={`team.member.role.${props.rowData.role || 'member'}`} />
          )}>
          <FormattedMessage id="team.member.role"></FormattedMessage>
        </Column>
        <Column
          name="verified"
          component={props =>
            props.rowData.verified ? (
              <FormattedMessage id="team.member.status.joined" />
            ) : (
              <FormattedMessage id="team.member.status.pending" />
            )
          }>
          <FormattedMessage id="team.member.status"></FormattedMessage>
        </Column>
        {this.props.isOwner && (
          <Column
            name="remove"
            sortable={false}
            component={props => {
              const member = props.rowData;
              if (member.role === 'owner') {
                return null;
              }

              return (
                <button
                  className="link-button"
                  disabled={this.props.isLoading}
                  onClick={this.onClickDeleteMember(member)}>
                  <DeleteIcon />
                </button>
              );
            }}></Column>
        )}
        {this.props.isWhitelabelAdmin && (
          <Column
            name="loginAs"
            sortable={false}
            component={props => (
              <Link
                to={`/admin/login-as/${RoleEnum.PUBLISHER}/${props.rowData.accountId}`}
                key="admin-login-as-publisher"
                activeClassName="is-active">
                <PersonIcon />
              </Link>
            )}></Column>
        )}
      </Table>
    );
  }

  onClickDeleteMember(member) {
    return () => {
      this.setState({
        memberToDelete: member
      });
    };
  }

  onCloseConfirmMemberDeleteModal() {
    this.setState({
      memberToDelete: null
    });
  }

  onDeleteTeamMember() {
    const memberToDelete = this.state.memberToDelete;
    if (!memberToDelete) {
      return;
    }

    // an actual member
    if (memberToDelete.hasAccount) {
      this.props.onDeleteTeamMember(this.state.memberToDelete);
    } else {
      // an invite
      this.props.onDeleteTeamInvite(this.state.memberToDelete);
    }
    this.onCloseConfirmMemberDeleteModal();
  }

  renderConfirmMemberDelete() {
    const member = this.state.memberToDelete;
    if (!member) {
      return null;
    }

    return (
      <Modal isOpen={!!member} onClose={this.onCloseConfirmMemberDeleteModal}>
        <p className="subtitle">
          <FormattedMessage
            id="publisher.team.deleteMember.title"
            values={{
              name: member.displayName || member.email,
              strong: (...chunks) => {
                return <strong>{chunks}</strong>;
              }
            }}
          />
        </p>
        <p>
          <FormattedMessage id="publisher.team.deleteMember.warning" />
        </p>
        <p>
          <button className="button is-danger" onClick={this.onDeleteTeamMember}>
            <FormattedMessage id="form.remove" />
          </button>
          &nbsp;
          <button className="button is-text" onClick={this.onCloseConfirmMemberDeleteModal}>
            <FormattedMessage id="form.cancel" />
          </button>
        </p>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmMemberDelete()}
        {this.renderCurrentTeamMembers()}
      </div>
    );
  }
}

class TeamManage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoading && !nextProps.isLoading) {
      this.setState({ email: '' }, () => {
        this.refs.emailInput.focus();
      });
    }
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  renderEmailInput() {
    const error = renderValidationError(this.props.error, 'email');

    return (
      <div key="team-member-email-input" className="control">
        <input
          className={`input ${error ? 'is-danger' : ''}`}
          type="email"
          placeholder="Email"
          value={this.state.email}
          disabled={this.props.isLoading}
          ref="emailInput"
          onChange={this.onChangeEmail}
        />
        {error}
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.state.email) {
      this.refs.emailInput.focus();
      return;
    }
    this.props.onInviteTeamMember(this.state.email);
  }

  renderInviteTeamMemberForm() {
    if (!this.props.isOwner) {
      return null;
    }

    return (
      <div className="columns">
        <div className="column is-6">
          <form onSubmit={this.onSubmit}>
            <div className="field">{this.renderEmailInput()}</div>
            <div key="team-member-email-submit" className="field is-grouped">
              <p className="control">
                <button className="button is-primary" disabled={this.props.isLoading}>
                  <FormattedMessage id="form.send" />
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }

  teamMemberCount() {
    const team = this.props.team;

    return team.members.length + team.invites.length;
  }

  renderTitle() {
    const team = this.props.team;

    return (
      <h2 className="subtitle">
        {team.name}
        &nbsp;
        <small>
          (
          <FormattedMessage
            id="publisher.team.currentMembers"
            values={{ count: this.teamMemberCount() }}
          />
          )
        </small>
      </h2>
    );
  }

  renderInviteTeamMemberBox() {
    if (!this.props.isOwner) {
      return null;
    }

    return (
      <div className="box">
        <h2 className="subtitle">
          <FormattedMessage id="publisher.team.addTeamMember" />
        </h2>
        {this.renderInviteTeamMemberForm()}
      </div>
    );
  }

  render() {
    return (
      <div className="TeamManage">
        <div className="box">
          {this.renderTitle()}
          <TeamTable {...this.props} />
        </div>
        {this.renderInviteTeamMemberBox()}
        <div className="box">
          <h2 className="subtitle">Team payment info</h2>
          <TeamPaymentInfoForm teamId={this.props.team.id} />
        </div>
        <div className="TeamManage__footer">{renderComponentSection(this.props, 'footer')}</div>
      </div>
    );
  }
}

TeamManage.propTypes = {
  team: teamShape.isRequired,
  onInviteTeamMember: PropTypes.func,
  onDeleteTeamMember: PropTypes.func,
  onDeleteTeamInvite: PropTypes.func,
  isLoading: PropTypes.bool,
  sections: PropTypes.shape({
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
  })
};

TeamManage.defaultProps = {
  onInviteTeamMember: email => {},
  onDeleteTeamMember: member => {},
  onDeleteTeamInvite: invite => {}
};

export default injectIntl(injectRoleChecking()(TeamManage));
