// @flow
import type { Demographics } from 'matchmade-types/instagram';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import ChannelCountry from '../ChannelCountry';
import FormattedNumber from '../../common/FormattedNumber';
import PercentageTable from '../../common/PercentageTable';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';

import FemaleIcon from '../../common/Icons/FemaleIcon';
import MaleIcon from '../../common/Icons/MaleIcon';

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import './InstagramDemographicsTab.scss';

const NUMBER_OF_TABLE_ROWS = 4;

type Props = {
  demographics: Demographics,
  country: string | null,
  estimatedCountry: string | null
};

export default function InstagramDemographicsTab(props: Props) {
  const { country, estimatedCountry } = props;

  const demographics = props.demographics || {};

  const hasAgeGroup = !isEmpty(demographics.ageGroup);
  const hasGender = !isEmpty(demographics.gender);
  const hasCountry = !isEmpty(demographics.country);

  if (!hasAgeGroup && !hasGender && !hasCountry) {
    return (
      <div className="InstagramDemographicsTab">
        <ChannelCountry
          countryCode={country || estimatedCountry}
          isEstimated={!country && !!estimatedCountry}
          key="country"
        />
      </div>
    );
  }

  const ageGroupsSortedByAge = sortBy(demographics.ageGroup, 'name');
  const youngAgesData = ageGroupsSortedByAge
    .map(({ name, value }) => ({
      label: name.substring(3),
      value: value / 100
    }))
    .slice(0, NUMBER_OF_TABLE_ROWS);
  const otherAgesPercentage = ageGroupsSortedByAge
    .slice(NUMBER_OF_TABLE_ROWS)
    .reduce((acc, e) => acc + e.value / 100, 0);
  const otherAgesData = {
    label: 'Others',
    value: otherAgesPercentage
  };
  const ageData = youngAgesData.concat(otherAgesData);

  const countriesByUsage =
    (demographics.country && demographics.country.sort((a, b) => b.value - a.value)) || [];
  const mostUsedCountriesData = countriesByUsage
    .filter(e => e.name !== 'ZZ')
    .filter(e => e.value)
    .map(({ name, value }) => ({
      label: countryCodes[name],
      value: value / 100
    }))
    .slice(0, NUMBER_OF_TABLE_ROWS);
  const unknownCountry = countriesByUsage.find(e => e.name === 'ZZ');
  const otherCountriesPercentage = countriesByUsage
    .slice(NUMBER_OF_TABLE_ROWS + 1)
    .reduce((acc, e) => acc + e.value / 100, (unknownCountry && unknownCountry.value / 100) || 0);
  const otherCountriesData = {
    label: 'Others',
    value: otherCountriesPercentage
  };
  const countryData = mostUsedCountriesData.concat(otherCountriesData);

  const womenData = demographics.gender && demographics.gender.find(e => e.name === 'female');
  const menData = demographics.gender && demographics.gender.find(e => e.name === 'male');
  const menPercentage = ((menData && menData.value) || 0) / 100;
  const womenPercentage = ((womenData && womenData.value) || 0) / 100;

  const ageGroupClassName = classNames('center-content bottom-border', {
    'first-two-fifth-width': hasAgeGroup && hasCountry,
    'full-width': hasAgeGroup && !hasCountry
  });
  const ageGroupNode = hasAgeGroup ? (
    <div className={ageGroupClassName}>
      <h2>
        <b>
          <FormattedMessage id="influencer.card.stats.age" />
        </b>
      </h2>
      <PercentageTable data={ageData} />
    </div>
  ) : null;

  const countryClassName = classNames('center-content bottom-border left-border', {
    'second-three-fifth-width': hasAgeGroup && hasCountry,
    'full-width': !hasAgeGroup && hasCountry
  });
  const countryNode = hasCountry ? (
    <div className={countryClassName}>
      <h2>
        <b>
          <FormattedMessage id="influencer.card.stats.country" />
        </b>
      </h2>
      <PercentageTable data={countryData} />
    </div>
  ) : null;

  const genderNode = hasGender ? (
    <React.Fragment>
      <div className="InstagramDemographicsTab__upper-icons first-half-width center-content bottom-border">
        <MaleIcon isActive />
        <FormattedNumber type="percentage" value={menPercentage} />
      </div>
      <div className="InstagramDemographicsTab__upper-icons second-half-width center-content bottom-border left-border">
        <FemaleIcon isActive />
        <FormattedNumber type="percentage" value={womenPercentage} />
      </div>
    </React.Fragment>
  ) : null;

  const className = classNames('InstagramDemographicsTab', {
    'two-rows': (hasAgeGroup || hasCountry) && hasGender
  });

  return (
    <div className={className}>
      {ageGroupNode}
      {countryNode}
      {genderNode}
    </div>
  );
}
