import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { changeTeam, fetchPublishers } from '../../actions/publisher';

import { deleteAccount, verifyAccount } from '../../actions/account';

import { fetchTeams } from '../../actions/team';

import { resetProps } from '../../actions/common';

import PublisherTable from '../../components/admin/PublisherTable';

import './AdminDashboardCommon.scss';

import { Helmet } from 'react-helmet';

import Spinner from '../../components/common/Spinner';

import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import { AdminNoPublishers } from './AdminNoPublishers';
import browserHistory from 'react-router/lib/browserHistory';

class AdminDashboardPublishers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountToVerify: null
    };
  }

  componentWillMount() {
    this.props.fetchPublishers();
    this.props.fetchTeams();
  }

  componentDidMount() {
    // However we leave this page, always reset
    this.props.router.setRouteLeaveHook(this.props.route, this.props.resetProps);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.verifyingAccountId && !nextProps.verifyingAccountId) {
      this.onCloseVerifyAccountModal();
    }

    if (nextProps.verifiedAccount) {
      const teams = nextProps.verifiedAccount.teams || [];
      const team = teams[0];

      if (!team) return;

      browserHistory.push(`/admin/teams/${team.id}`);
    }
  }

  isLoading() {
    return this.props.isLoadingPublishers || this.props.isLoadingTeams;
  }

  onClickVerifyAccount = publisher => {
    this.props.verifyAccount(publisher.id, RoleEnum.PUBLISHER);
  };

  onCloseVerifyAccountModal = () => {
    this.setState({ accountToVerify: null });
  };

  onChangeTeam = (publisherId, teamId, teamName) => {
    this.props.changeTeam(publisherId, teamId, teamName);
  };

  render() {
    const { publishers } = this.props;
    let content = null;
    let rootClasses = ['AdminDashboardPublishers'];

    if (this.isLoading()) {
      content = <Spinner mode="fullWidth" size="large" />;
    } else if (!publishers || !publishers.length) {
      content = <AdminNoPublishers />;
    } else {
      rootClasses.push('container');
      const props = Object.assign(
        {
          onClickVerifyAccount: this.onClickVerifyAccount,
          onChangeTeam: this.onChangeTeam
        },
        this.props
      );
      content = <PublisherTable {...props} />;
    }

    return (
      <div className={rootClasses.join(' ')}>
        <Helmet title="Publishers" />
        {content}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardPublishers');

const mapDispatchToProps = generateDispatchToProps({
  fetchPublishers,
  fetchTeams,
  deleteAccount,
  verifyAccount,
  resetProps,
  changeTeam
});

AdminDashboardPublishers = connect(mapStateToProps, mapDispatchToProps)(AdminDashboardPublishers);

export default AdminDashboardPublishers;
