// @flow
import type { SocialMediaConnectionIn } from '../../types/connection.flow';

import type { ContentPlatform } from '../../types/campaign.flow';
// $FlowFixMe
import { useAsync } from 'react-async';
import callApi from '../../helpers/api';
import useError from '../../hooks/useError';

import CheckIcon from '../common/Icons/CheckIcon';
import OnOffToggle from '../common/OnOffToggle';
import ReportIcon from '../common/Icons/ReportIcon';

import Tooltip from '../common/Tooltip';

import * as React from 'react';
// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import './ContentPlatformToConnect.scss';
import RevokeAccessModal from '../modals/RevokeAccessModal';

export type Scope = {
  name: string,
  description: string,
  isGranted: boolean | null // null means that we don't have it yet
};

function AvailableChannelsToLink({
  connectionIns,
  selected,
  isLoading,
  onSelect
}: {
  connectionIns: Array<SocialMediaConnectionIn>,
  selected: SocialMediaConnectionIn | null,
  isLoading: boolean,
  onSelect: (connectionIn: SocialMediaConnectionIn) => any
}) {
  if (!connectionIns || !connectionIns.length) {
    return null;
  }

  const options = connectionIns.map((connectionIn, index) => {
    const { connection, channel } = connectionIn;
    const isSelected =
      selected &&
      selected.connection.id === connection.id &&
      selected.channel.channelId === channel.channelId;
    return (
      <li key={`${channel.contentPlatform}-${channel.channelId}-${index}`}>
        <OnOffToggle
          isOn={!!isSelected}
          isRadio
          onClick={() => {
            onSelect(connectionIn);
          }}>
          <a href={channel.channelUrl} target="_blank" rel="noopener noreferrer">
            {channel.displayName}
          </a>
        </OnOffToggle>
      </li>
    );
  });

  return (
    <div className="ContentPlatformToConnect__available">
      <FormattedMessage id="auth.connectAccount.selectChannelToConnect" tagName="strong" />
      <ul>{options}</ul>
    </div>
  );
}

function ConnectedChannels({ connectionIns }: { connectionIns: SocialMediaConnectionIn[] }) {
  if (!connectionIns || !connectionIns.length) {
    return null;
  }

  const options = connectionIns.map(({ connection, channel }) => {
    return (
      <li key={`${channel.contentPlatform}-${channel.channelId}`}>
        <a href={channel.channelUrl} target="_blank" rel="noopener noreferrer">
          {channel.displayName}
        </a>
      </li>
    );
  });

  return (
    <div className="ContentPlatformToConnect__connected">
      <FormattedMessage id="auth.connectAccount.connected" tagName="strong" />
      <ul>{options}</ul>
    </div>
  );
}

function ConnectedButton({
  connectionIns,
  isLoading = false
}: {
  connectionIns: SocialMediaConnectionIn[],
  isLoading?: boolean
}) {
  if (!connectionIns || !connectionIns.length || isLoading) {
    return null;
  }

  return (
    <button disabled className="button is-medium is-success">
      <div className="mr-2">
        <CheckIcon />
      </div>
      <FormattedMessage id="auth.connectAccount.connected" />
    </button>
  );
}

export function RevokeButton({ contentPlatform }: { contentPlatform: ContentPlatform }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="button is-medium is-light">
        Revoke
      </button>
      <RevokeAccessModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        contentPlatform={contentPlatform}
      />
    </>
  );
}

export default function ContentPlatformToConnect({
  name,
  allowConnectingMoreThanOneAccount,
  connected,
  available,
  onSetupConnection,
  onConnected = () => {},
  icon,
  scopes,
  requirements,
  isLoading,
  isDisabled,
  connectText
}: {
  name: string,
  allowConnectingMoreThanOneAccount?: boolean,
  connected: Array<SocialMediaConnectionIn>,
  available: Array<SocialMediaConnectionIn>,
  onSetupConnection: () => void,
  onConnected?: (connection: SocialMediaConnectionIn) => any,
  icon: React.Node,
  scopes: Scope[],
  requirements: React.Node[],
  isLoading?: boolean,
  isDisabled?: boolean,
  connectText?: string | null
}) {
  const intl = useIntl();
  const hasDoneAutomaticLinking = React.useRef(false);
  const [
    selectedConnectionIn,
    setSelectedConnectionIn
  ] = React.useState<SocialMediaConnectionIn | null>(null);

  const availableCount = (available || []).length;
  const connectedCount = (connected || []).length;
  const isMissingScopes = scopes.some(({ isGranted }) => isGranted === false);

  const { showError } = useError();

  const {
    run: linkChannelToInfluencerAccount,
    isPending: isLinkingChannel
  }: { run: (input: SocialMediaConnectionIn) => any, isPending: boolean } = useAsync({
    deferFn: args => {
      return callApi(`/connections/social-media`, {
        method: 'POST',
        body: {
          data: args[0]
        }
      }).then(result => {
        onConnected(args[0]);
        return result;
      });
    },
    onReject: res => {
      showError(res);
    }
  });

  if (availableCount >= 1 && !selectedConnectionIn) {
    setSelectedConnectionIn(available[0]);
  }

  // sanity check to make sure that whatever we have in the state is present in the available list
  // otherwise, just fallback to the first available connection
  if (
    selectedConnectionIn &&
    !available.find(({ connection, channel }) => {
      return (
        selectedConnectionIn.connection.id === connection.id &&
        selectedConnectionIn.channel.channelId === channel.channelId
      );
    })
  ) {
    setSelectedConnectionIn(available[0]);
  }

  if (hasDoneAutomaticLinking.current === false) {
    // automatically link if there is only 1 channel. Only applicable if the influencer has not linked
    // anything yet for this particular platform
    if (availableCount === 1 && connectedCount === 0 && !isLinkingChannel) {
      linkChannelToInfluencerAccount(available[0]);
      hasDoneAutomaticLinking.current = true;
    }
  }

  const shouldShowConnectButton =
    (!availableCount && !connectedCount && !allowConnectingMoreThanOneAccount) || isMissingScopes;
  const shouldShowLinkButton = !!availableCount && !connectedCount && !isMissingScopes;
  const shouldShowAvailableChannel = !connected.length && !isMissingScopes;
  const shouldShowARevokeButton = connected && connected.length > 0;

  const btnText = isMissingScopes ? (
    <FormattedMessage id="socialMediaAccount.reconnect" />
  ) : (
    <FormattedMessage id="socialMediaAccount.connect" />
  );

  const connectBtnClassName = classNames('button is-medium', {
    'is-loading': isLoading,
    'is-danger': isMissingScopes,
    'is-primary': !isMissingScopes
  });
  const connectBtn = (
    <button
      disabled={isDisabled || isLoading}
      className={connectBtnClassName}
      onClick={onSetupConnection}>
      {connectText || btnText}
    </button>
  );

  // @Tan 2020-07-21 we use the same "Connect" text for "connect" and "link" actions
  const linkBtnClassName = classNames('button is-medium is-primary', {
    'is-loading': isLoading || isLinkingChannel
  });
  const linkBtn = (
    <button
      disabled={isDisabled || isLoading || isLinkingChannel || !selectedConnectionIn}
      className={linkBtnClassName}
      onClick={() => {
        if (!selectedConnectionIn) {
          return;
        }
        linkChannelToInfluencerAccount(selectedConnectionIn);
      }}>
      <FormattedMessage id="socialMediaAccount.connect" />
    </button>
  );

  return (
    <div className="box mb-5">
      <div className="is-flex is-justify-content-space-between is-align-items-center mb-5">
        <h2 className="subtitle m-0 is-flex is-align-items-cente has-text-dark">
          <span className="ContentPlatformToConnect__icon is-hidden-mobile">{icon}</span> {name}
        </h2>
        <div className="ContentPlatformToConnect__connections is-flex-tablet">
          {shouldShowARevokeButton && (
            <div className="mr-2">
              <RevokeButton contentPlatform={connected[0].channel.contentPlatform} />
            </div>
          )}
          {shouldShowConnectButton ? (
            connectBtn
          ) : (
            <div className="is-hidden-mobile">
              <ConnectedButton connectionIns={connected} isLoading={isLoading} />
            </div>
          )}
          {shouldShowLinkButton && linkBtn}
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third">
          <FormattedMessage id="auth.connectAccount.scope" tagName="strong" />

          <ul className="ContentPlatformToConnect__scopes">
            {(scopes || []).map((scope, index) => {
              const scopeClassName = classNames('ContentPlatformToConnect__scope', {
                'has-text-danger': scope.isGranted === false
              });
              return (
                <li key={index} className={scopeClassName}>
                  <span className="ContentPlatformToConnect__scope-content">
                    {scope.description}
                  </span>
                  {scope.isGranted === true && <CheckIcon />}
                  {scope.isGranted === false && (
                    <Tooltip
                      tooltip={intl.formatMessage({
                        id: 'socialMediaAccount.missingScopeTooltip'
                      })}>
                      <span>
                        <ReportIcon />
                      </span>
                    </Tooltip>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        {requirements.length > 0 && (
          <div className="column is-one-third">
            <FormattedMessage id="auth.connectAccount.requirements" tagName="strong" />

            {requirements.map((requirement, index) => {
              return (
                <li key={index} className="ContentPlatformToConnect__requirement">
                  <span className="ContentPlatformToConnect__requirement-content">
                    {requirement}
                  </span>
                </li>
              );
            })}
          </div>
        )}
        <div className="column is-one-third">
          {shouldShowAvailableChannel && (
            <AvailableChannelsToLink
              isLoading={isLinkingChannel}
              connectionIns={available}
              selected={selectedConnectionIn}
              onSelect={setSelectedConnectionIn}
            />
          )}
          <ConnectedChannels connectionIns={connected} />
        </div>
      </div>
    </div>
  );
}
