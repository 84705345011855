import React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

const CopyIcon = (props: IconProps) => {
  const { isActive } = props;
  const iconStyles = isActive ? 'icon Icon mdCopyIcon activeTabIcon' : 'icon Icon mdCopyIcon';
  return (
    <Icon className={iconStyles} {...props}>
      {() => <i className="material-icons">content_copy</i>}
    </Icon>
  );
};

export default CopyIcon;
