import React from 'react';

const StoreIconApple = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs />
    <g id="Icon-/-Apple" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M17.6365693,17.8960037 C16.9300731,18.9514919 16.1810169,19.9814442 15.0404087,19.9984682 C13.8998004,20.0240042 13.5337843,19.32602 12.23996,19.32602 C10.9376237,19.32602 10.5375596,19.9814442 9.45653529,20.0240042 C8.34146302,20.0665642 7.49877481,18.9004199 6.78376664,17.8704676 C5.32821428,15.7680031 4.21314201,11.8950422 5.71125438,9.29036954 C6.45179856,7.99654522 7.77967088,7.17939302 9.21819923,7.15385702 C10.3077355,7.13683301 11.3461998,7.8944012 12.0186479,7.8944012 C12.6825841,7.8944012 13.9423604,6.98361698 15.2617207,7.11980901 C15.8150009,7.14534502 17.3641852,7.34112106 18.3600895,8.80518542 C18.2834815,8.85625744 16.512985,9.89472169 16.530009,12.0482582 C16.555545,14.6188828 18.7856896,15.4785951 18.8112256,15.4871071 C18.7856896,15.5466911 18.4537215,16.7128354 17.6365693,17.8960037 Z M12.7762161,4.27680031 C13.3975923,3.57030414 14.4275445,3.03404801 15.2787447,3 C15.3894007,3.99590424 14.9893366,5.00032049 14.3934965,5.71532867 C13.8061684,6.43884884 12.8358001,7.00064098 11.8824559,6.92403296 C11.7547759,5.94515272 12.231448,4.92371247 12.7762161,4.27680031 Z"
        id="Shape"
        fill="#2D3767"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default StoreIconApple;
