import React from 'react';

const DemographicsIconFemale = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      d="M16.5 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM15 26v-6h-3l2.59-7.59c.25-.82 1.01-1.41 1.91-1.41.9 0 1.66.59 1.91 1.41L21 20h-3v6h-3z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default DemographicsIconFemale;
