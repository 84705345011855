// @flow

import {
  fetchManagerTeamById,
  fetchManagerTeamChannelInvites,
  fetchManagerTeamChannels,
  fetchManagerTeamMembers,
  removeManagedChannel,
  updateManagerTeam
} from '../../../actions/managerTeam';

import type { Action } from '../../../types/action.flow';

export type ManagerTeamMember = {
  id: number,
  email: string,
  displayName: string,
  avatarUrl: string
};

export type ManagedChannel = ManagerTeamMember & {
  contactEmail: ?string | null,
  channelId: string,
  channelName: string
};

type InviteStatus = 'pending' | 'accepted' | 'rejected';

export type InvitedChannel = ManagedChannel & { status: InviteStatus };

export type AdminManagerTeamPage = {|
  team: { id: number, name: string } | null,
  isLoadingTeam: boolean,
  members: ManagerTeamMember[] | null,
  isLoadingMembers: boolean,
  channels: ManagedChannel[] | null,
  isLoadingChannels: boolean,
  invites: InvitedChannel[] | null,
  isLoadingInvites: boolean,
  isUpdatingTeam: boolean,
  error: { code: number, statusCode: number, params: any } | null
|};

const initialState: AdminManagerTeamPage = Object.freeze({
  team: null,
  isLoadingTeam: false,
  members: null,
  isLoadingMembers: false,
  channels: null,
  isLoadingChannels: false,
  invites: null,
  isLoadingInvites: false,
  isUpdatingTeam: false,
  error: null
});

export default function adminManagerTeamPage(
  state: AdminManagerTeamPage = initialState,
  action: Action
): AdminManagerTeamPage {
  const newState = {
    ...state,
    error: null
  };
  switch (action.type) {
    case fetchManagerTeamById.REQUEST:
      return {
        ...newState,
        isLoadingTeam: true
      };
    case fetchManagerTeamById.SUCCESS:
      return {
        ...newState,
        isLoadingTeam: false,
        team: action.payload
      };
    case fetchManagerTeamById.FAILURE:
      return {
        ...newState,
        isLoadingTeam: false,
        error: action.payload
      };
    case fetchManagerTeamMembers.REQUEST:
      return {
        ...newState,
        isLoadingMembers: true
      };
    case fetchManagerTeamMembers.SUCCESS:
      return {
        ...newState,
        isLoadingMembers: false,
        members: action.payload
      };
    case fetchManagerTeamMembers.FAILURE:
      return {
        ...newState,
        isLoadingMembers: false,
        error: action.payload
      };
    case fetchManagerTeamChannels.REQUEST:
      return {
        ...newState,
        isLoadingChannels: true
      };
    case fetchManagerTeamChannels.SUCCESS:
      return {
        ...newState,
        isLoadingChannels: false,
        channels: action.payload
      };
    case fetchManagerTeamChannels.FAILURE:
      return {
        ...newState,
        isLoadingChannels: false,
        error: action.payload
      };
    case fetchManagerTeamChannelInvites.REQUEST:
      return {
        ...newState,
        isLoadingInvites: true
      };
    case fetchManagerTeamChannelInvites.SUCCESS:
      return {
        ...newState,
        isLoadingInvites: false,
        invites: action.payload
      };
    case fetchManagerTeamChannelInvites.FAILURE:
      return {
        ...newState,
        isLoadingInvites: false,
        error: action.payload
      };
    case updateManagerTeam.REQUEST:
      return {
        ...newState,
        isUpdatingTeam: true,
        // optimistically update the UI
        team: {
          ...state.team,
          ...action.payload
        }
      };
    case updateManagerTeam.SUCCESS:
      return {
        ...newState,
        isUpdatingTeam: false,
        team: action.payload[0]
      };
    case updateManagerTeam.FAILURE:
      return {
        ...newState,
        isUpdatingTeam: false,
        error: action.payload
      };
    case removeManagedChannel.SUCCESS:
      return {
        ...newState,
        channels: (newState.channels || []).filter(c => c.channelId !== action.payload.channelId)
      };
    default:
      return newState;
  }
}
