// @flow
import React from 'react';

import { checkIfWhitelabelAdmin } from '../../../helpers/user';

import Avatar from '../../messages/Avatar';
import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import YoutubeIcon from '../../common/Icons/YoutubeIcon';
import TwitchIcon from '../../common/Icons/TwitchIcon';

type Props = {
  id: number,
  isOutOfNetworkInfluencer: boolean,
  channelUrl: string,
  channelName: string,
  avatarUrl: string,
  isMock: boolean,
  contentPlatform?: string
};

const LinkToInfluencer = (props: Props) => {
  const {
    channelName,
    avatarUrl,
    channelUrl,
    isMock,
    contentPlatform = ContentPlatform.YOUTUBE
  } = props;

  const avatar = (
    <Avatar
      url={avatarUrl}
      size={64}
      shape={isMock && checkIfWhitelabelAdmin() ? 'shield' : null}
    />
  );
  const title = (
    <span className="cardTitle">
      <strong>{channelName}</strong>
      <br />
      {contentPlatform === ContentPlatform.YOUTUBE && <YoutubeIcon />}
      {contentPlatform === ContentPlatform.TWITCH && <TwitchIcon />}
    </span>
  );

  return (
    <a className="influencerLink" href={channelUrl} target="_blank" rel="noopener noreferrer">
      {avatar}
      {title}
    </a>
  );
};

export default LinkToInfluencer;
