// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { mixpanelClient } from '../../helpers/mixpanelClient';
import { useAccountContext } from '../../helpers/account';
import Spinner from '../../components/common/Spinner';
import browserHistory from 'react-router/lib/browserHistory';
import useError from '../../hooks/useError';

// $FlowFixMe
import ReactPixel from 'react-facebook-pixel';

export default function AuthLanding() {
  // this component just basically routes to other places and shows spinner while that happens
  const accountContext = useAccountContext();
  const { showError } = useError();
  const [newLocation, setNewLocation] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    // need to handle these in a effect as otherwise will get
    // Cannot update a component (`ErrorPopUp`) while rendering differnt component `AuthLanding`
    // in case of showError, this should be implelmented in the showError itself
    // and
    // Cannot update during an existing state transition
    // in case of browserHistory.push, which is kinda expected, as the transfomation is an effect
    if (error) {
      showError(error);
    }

    if (newLocation) {
      browserHistory.push(newLocation);
    }
  }, [newLocation, error, showError]);

  if (accountContext.isLoading || newLocation) {
    // newLocation existis --> reactRouter is doing state transformation --> continue returning spinnner
    return <Spinner size="large" mode="overlay" />;
  }

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('error')) {
    if (urlParams.get('error') !== 'access_denied') {
      // No need to sentry if user denies access in authentication flow
      Sentry.captureMessage(
        `Error in authenticate landing: ${urlParams.get('error') || 'unkwown'}`
      );
    }
    setError({
      code: 400,
      message: 'Could not authenticate. Did you remember to give Matchmade access?'
    });
    urlParams.delete('error');
    setNewLocation(`/authenticate/signup${urlParams.toString() ? `?${urlParams.toString()}` : ''}`);
    return <Spinner size="large" mode="overlay" />;
  }

  if (accountContext.data) {
    const account = accountContext.data.account;
    const isNew = accountContext.data.isNew;

    if (!account) {
      setNewLocation(`/authenticate/signup${window.location.search}`);
      return <Spinner size="large" mode="overlay" />;
    }

    if (isNew) {
      mixpanelClient.alias(account.id);
      ReactPixel.track('CompleteRegistration');
    }

    if (!account.role) {
      setNewLocation(`/authenticate/select-role${window.location.search}`);
      return <Spinner size="large" mode="overlay" />;
    }

    // in case of existing role, assuming login
    // in this case we expect the role specific "root" page
    // to handle cases where there is something incorrectly
    // configured and handle those gracefully
    setNewLocation(`/${window.location.search}`);
    return <Spinner size="large" mode="overlay" />;
  }

  if (accountContext.error) {
    Sentry.captureException(accountContext.error);
    setError({ code: 500, message: 'Unexpected error occured. Pleace try again.' });
    setNewLocation(`/authenticate/signup${window.location.search}`);
    return <Spinner size="large" mode="overlay" />;
  }

  // We should never hit here, but well, you'll never know
  Sentry.captureMessage('Something went totally wrong in authenticate/landing');
  setError({ code: 500, message: 'Unexpected error occured. Pleace try again.' });
  setNewLocation(`/authenticate/signup${window.location.search}`);
  return <Spinner size="large" mode="overlay" />;
}
