// @flow
import * as React from 'react';

import classNames from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import isValidURL from '../../../helpers/urlValidator';

import './UrlControlInput.scss';

type Props = {
  value: ?string,

  icon?: React.Element<*>,
  intl: IntlShape,
  onChange: (value: string, isInvalid: boolean) => void,

  isInverted: boolean,
  isDisabled: boolean,

  // this allows doing extra validation outside of this component
  hasInvalidInput: boolean,
  // null / undefined / false / ''
  isEmptyValueAllowed: boolean
};

UrlControlInput.defaultProps = {
  isInverted: false,
  hasInvalidInput: false,
  isDisabled: false,
  isEmptyValueAllowed: false
};

function getErrorMessage(props: Props, hasInvalidInput) {
  if (!hasInvalidInput) {
    return <span>&nbsp;</span>;
  }

  if (!props.value && !props.isEmptyValueAllowed) {
    return <FormattedMessage id="urlControlInput.required" />;
  }

  return <FormattedMessage id="urlControlInput.invalidUrl" />;
}

function UrlControlInput(props: Props) {
  let hasInvalidInput = false;

  if (props.value) {
    hasInvalidInput = !isValidURL(props.value);
  } else {
    hasInvalidInput = !props.isEmptyValueAllowed;
  }

  hasInvalidInput = hasInvalidInput || props.hasInvalidInput;

  const inputClassName = classNames('input', {
    'is-inverted': props.isInverted,
    'is-warning': hasInvalidInput
  });

  const className = classNames('UrlControlInput control', {
    'has-icons-left': props.icon,
    'has-icons-right': hasInvalidInput
  });

  return (
    <div className={className}>
      <input
        className={inputClassName}
        disabled={props.isDisabled}
        placeholder={props.intl.formatMessage({
          id: 'urlControlInput.enterUrl'
        })}
        defaultValue={props.value}
        onChange={e => {
          props.onChange(e.target.value, !isValidURL(e.target.value));
        }}
        onBlur={e => {
          props.onChange(e.target.value, !isValidURL(e.target.value));
        }}
      />
      {props.icon && <span className="icon is-small is-left">{props.icon}</span>}
      {hasInvalidInput && (
        <span className="icon is-small is-right">
          <i className="material-icons UrlControlInput__error">error</i>
        </span>
      )}
      <div className="help is-warning">{getErrorMessage(props, hasInvalidInput)}</div>
    </div>
  );
}

export default injectIntl(UrlControlInput);
