// @flow

import * as React from 'react';

import {
  DateRange,
  DateRangeDelimiter,
  DateRangePicker as DateRangePickerMui
  // $FlowFixMe
} from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
// $FlowFixMe
import { useIntl } from 'react-intl';
import moment from 'moment';

type Props = {
  startDate?: moment,
  endDate?: moment,
  isLoading?: boolean,
  onAccept?: (range: DateRange) => void
};

function DateRangePicker(props: Props) {
  const { startDate = null, endDate = null, isLoading = false, onAccept = null } = props;
  const intl = useIntl();
  const [selectedDate, handleDateChange] = React.useState<DateRange>([startDate, endDate]);

  return (
    <DateRangePickerMui
      startText={intl.formatMessage({
        id: 'daterangepicker.start'
      })}
      endText={intl.formatMessage({
        id: 'daterangepicker.end'
      })}
      value={selectedDate}
      maxDate={moment.utc()}
      disabled={isLoading}
      disableMaskedInput
      onChange={date => handleDateChange(date)}
      onAccept={range => {
        if (onAccept) {
          onAccept(range);
        }
      }}
      renderInput={(startProps, endProps) => (
        <>
          <TextField {...startProps} helperText="" size="small" placeholder="" />
          <DateRangeDelimiter>
            {intl.formatMessage({
              id: 'daterangepicker.to'
            })}
          </DateRangeDelimiter>
          <TextField {...endProps} helperText="" size="small" placeholder="" />
        </>
      )}
    />
  );
}

export default DateRangePicker;
