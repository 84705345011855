// @flow
import * as React from 'react';

type Props = {
  title: React.Element<any> | string,
  children?: any
};

// Group multiple columns under one main title
export default function GroupedColumn(props: Props) {
  const children = React.Children.toArray(props.children).filter(Boolean);
  return (
    <th className="GroupedColumn" colSpan={children.length}>
      {props.title}
    </th>
  );
}
