// @flow
import * as React from 'react';

import WithTimestampUpdate from './common/WithTimestampUpdate';

import './NegotiationMessage.scss';

type Props = {
  created?: string,
  timestamp?: string,
  reverse?: boolean,
  offer: React.Node,
  action: React.Node
};

// This component uses the same style as that of NegotiationMessage
const CommissionMessage = ({
  timestamp,
  created = 'a few seconds ago',
  reverse,
  offer,
  action
}: Props) => {
  if (!action || !offer) return null;

  // timestamp comes from WithTimestampUpdate
  const time = timestamp || created;

  return (
    <div className="NegotiationMessage is-reverse">
      <div className="NegotiationMessage__icon">
        <span className="icon is-medium">
          <i className="fa fa-star" />
        </span>
      </div>
      <div className="NegotiationMessage__content">
        <div className="NegotiationMessage__negotiation">
          {action}
          {offer}
        </div>
        <hr />
        <div className="NegotiationMessage__created">
          <time>{time}</time>
        </div>
      </div>
    </div>
  );
};

// This component uses the same style as that of NegotiationMessage
export default WithTimestampUpdate(CommissionMessage, 'created');
