// @flow
import React from 'react';

import BilibiliSvg from '../../../images/icons/BilibiliIcon';
import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const BilibiliIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="BilibiliIcon">
      {() => <BilibiliSvg />}
    </Icon>
  );
};

export default BilibiliIcon;
