import React from 'react';

import { getColorVariables } from '../../../config';
import { getConstants } from '@sharkpunch/matchmade-common/graphs';
import Input from '../../forms/controls/Input';

import Form from '../../forms/Form';

import './AgeGroups.scss';
import { FormattedMessage } from 'react-intl';

const variables = getColorVariables();
const { AGE_GROUP_LABELS } = getConstants(variables);
const firstFourAgeGroupLabels = Object.keys(AGE_GROUP_LABELS).slice(0, 4);
const ageGroupsBaseObject = Object.keys(AGE_GROUP_LABELS).reduce((baseObj, label, index) => {
  baseObj[label] = 0;
  return baseObj;
}, {});

const AgeGroups = ({ onChange = () => {}, ageGroup = {} }) => {
  const othersValue =
    Math.round(
      (100 - firstFourAgeGroupLabels.reduce((prev, next) => prev + (+ageGroup[next] || 0), 0)) * 100
    ) / 100;
  // We want to always have the same object structure
  const values = { ...ageGroupsBaseObject, ...ageGroup };
  return (
    <Form className="AgeGroups" onChange={onChange} values={values}>
      <h1>Age</h1>
      <div>
        {firstFourAgeGroupLabels.map(label => {
          const value = ageGroup[label] || '';

          return (
            <span className="AgeGroups__entry is-flex" key={label}>
              <span className="AgeGroups__label">{AGE_GROUP_LABELS[label]}</span>
              <span className="AgeGroups__value">
                <Input
                  type="number"
                  step={1}
                  min={1}
                  max={100}
                  name={label}
                  value={value}
                  iconLeft={<i className="fa fa-percent" />}
                />
              </span>
            </span>
          );
        })}

        <span className="AgeGroups__entry is-flex">
          <span className="AgeGroups__label">
            <FormattedMessage id="lists.other" />
          </span>
          <span className="AgeGroups__value">
            <Input
              type="number"
              min={0}
              max={100}
              disabled
              name="age65-" // others
              value={othersValue}
              iconLeft={<i className="fa fa-percent" />}
            />
          </span>
        </span>
      </div>
    </Form>
  );
};

export default AgeGroups;
