import Immutable from 'immutable';

import uniqueId from 'lodash/uniqueId';

import {
  fetchMessages,
  optimisticallyCreateNegotiationMessage,
  sendMessage
} from '../../actions/messages';

import { INFLUENCER_SET_CPI } from '@sharkpunch/matchmade-common/campaignAgreement';

export default {
  // DEPRECATED
  // @Tan 2020-05-04 there is a local version in messagesPage reducer
  // move it to this file if we need to reuse the logic
  fetchMessages(state, action) {
    switch (action.type) {
      case fetchMessages.REQUEST:
        return state.set('isFetchingMessages', true);
      case fetchMessages.SUCCESS:
        const messages = action.payload || [];
        return state.set('isFetchingMessages', false).set('messages', Immutable.fromJS(messages));
      case fetchMessages.FAILURE:
        return state
          .set('isFetchingMessages', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  // DEPRECATED
  // @Tan 2020-05-04 there is a local version in messagesPage reducer
  // move it to this file if we need to reuse the logic
  sendMessage(state, action) {
    switch (action.type) {
      case sendMessage.REQUEST:
        return state.set('isSendingMessage', true).set('sendMessageError', null);
      case sendMessage.SUCCESS:
        const messages = state.get('messages');
        if (!messages) {
          return state;
        }

        return state
          .set('isSendingMessage', false)
          .set('sendMessageError', null)
          .set('messages', messages.unshift(Immutable.fromJS(action.payload)));
      case sendMessage.FAILURE:
        return state
          .set('isSendingMessage', false)
          .set('sendMessageError', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  // DEPRECATED
  // @Tan 2020-05-04 there is a local version in messagesPage reducer
  // move it to this file if we need to reuse the logic

  // Here we hook up to negotiate API call and generate "fake" message
  // so that we can update UI immediately
  optimisticallyCreateNegotiationMessage(state, action) {
    switch (action.type) {
      case optimisticallyCreateNegotiationMessage.SUCCESS:
        const payload = action.payload;
        const {
          influencerCpi = 0,
          publisherCpi = 0,
          influencerMinGuarantee = 0,
          publisherMinGuarantee = 0,
          influencerMaxPayment = 0,
          publisherMaxPayment = 0,
          influencerCpm = 0,
          publisherCpm = 0,
          status = INFLUENCER_SET_CPI,
          promotionType
        } = payload;

        const campaignAgreement = state.get('campaignAgreement');
        const author = campaignAgreement.get('author');

        const cpi = campaignAgreement.get('cpi');
        const minGuarantee = campaignAgreement.get('minGuarantee');

        const newNegotiationMessage = {
          id: uniqueId(),
          content: status,
          author,
          role: publisherCpi || publisherMinGuarantee ? 'publisher' : 'influencer',
          type: 'negotiation',
          created: new Date().toISOString(),
          meta: {
            influencerCpi,
            publisherCpi,
            influencerMinGuarantee,
            publisherMinGuarantee,
            influencerMaxPayment,
            publisherMaxPayment,
            influencerCpm,
            publisherCpm,
            cpi,
            minGuarantee,
            promotionType
          }
        };

        const messages = state.get('messages');

        if (!messages) {
          return state;
        }

        return state.set('messages', messages.unshift(Immutable.fromJS(newNegotiationMessage)));
      default:
        return state;
    }
  }
};
