// @flow
import * as React from 'react';
import { clearAccessToken } from '../../helpers/puppe';
import { generateApiPath } from '../../config';

export default class Logout extends React.PureComponent<{}> {
  componentDidMount() {
    clearAccessToken();
    window.location.href = generateApiPath('/auth/logout');
  }

  render() {
    return '...';
  }
}
