// @flow
import React from 'react';

//$FlowFixMe
import { useAsync } from 'react-async';
//$FlowFixMe
import { useForm } from 'react-hook-form';
// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';
import Modal from '../../components/common/Modal';
import callApi from '../../helpers/api';
import CampaignTagsInput from '../campaign/CampaignTagsInput';

const BudgetCreateModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
  currentBudget
}: {
  isOpen: boolean,
  setIsOpen: (val: boolean) => void,
  onSuccess: () => void,
  currentBudget?: { maximum: number, name: String, campaigns: number[], id: string } | null
}) => {
  const [message, setMessage] = React.useState({});

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      maximum: null,
      campaigns: [],
      currency: 'USD'
    },
    mode: 'onChange'
  });

  React.useEffect(() => {
    setValue('name', (currentBudget && currentBudget.name) || '');
    // API returns cents, make it whole USD
    setValue('maximum', (currentBudget && currentBudget.maximum / 100) || null);
    setValue('campaigns', (currentBudget && currentBudget.campaigns) || []);
  }, [currentBudget]);

  const { run: postBudget, isLoading } = useAsync({
    deferFn: async () => {
      setMessage({});
      const formData = getValues();

      const campaigns = formData.campaigns;
      if (!campaigns.length) {
        throw new Error('At least one campaign is required');
      }
      // don't send campaigns to budgets API
      delete formData.campaigns;

      let newBudget;

      if (currentBudget && currentBudget.id) {
        newBudget = await callApi(`/budgets/${currentBudget.id}`, {
          method: 'PATCH',
          body: { maximum: 100 * formData.maximum, name: formData.name }
        });
      } else {
        newBudget = await callApi('/budgets', {
          method: 'POST',
          body: {
            maximum: 100 * formData.maximum,
            name: formData.name,
            currency: formData.currency
          }
        });
      }

      if (newBudget.data && newBudget.data.id) {
        // link budget to campaigns
        return callApi(`/budgets/${newBudget.data.id}/link-campaigns`, {
          method: 'PUT',
          body: { campaign_ids: campaigns }
        });
      }
    },
    onResolve: onSuccess,
    onReject: e =>
      setMessage({
        type: 'error',
        text: `Error when saving budget: ${e.message || 'Unknown error'}`
      })
  });

  return (
    <Modal
      key="modal"
      isOpen={isOpen}
      onClose={() => {
        reset();
        setMessage({});
        setIsOpen(false);
      }}>
      <form className="CreatePayoutForm" onSubmit={handleSubmit(postBudget)}>
        <section>
          <header>
            <h3>{currentBudget && currentBudget.id ? 'Edit budget' : 'New budget'}</h3>
          </header>
          <div className="field">
            <label htmlFor="name" className="label">
              Budget name
            </label>
            <div className="control">
              <input
                {...register('name', { required: 'Budget name is required' })}
                className="input"
                type="text"
                autoComplete="off"
              />
            </div>
            {errors.name && <p className="help is-danger">{errors.name.message}</p>}
          </div>
          <div className="field">
            <label htmlFor="maximum" className="label">
              Amount (USD)
            </label>
            <div className="control">
              <input
                {...register('maximum', { required: 'Amount is required' })}
                className="input"
                type="number"
              />
            </div>
            {errors.maximum && <p className="help is-danger">{errors.maximum.message}</p>}
          </div>

          <div className="field">
            <label htmlFor="campaigns" className="label">
              Campaigns
            </label>
            <div className="control"></div>
            <CampaignTagsInput
              isDirty={isDirty}
              tags={((currentBudget && currentBudget.campaigns) || []).map(c => ({ id: c })) || []}
              setData={data => {
                setValue(
                  'campaigns',
                  data.map(t => t.id)
                );
              }}
            />
            {errors.campaigns && <p className="help is-danger">{errors.campaigns.message}</p>}
          </div>
          {message.type && message.text && (
            <Alert severity={message.type} className="my-5" variant="filled">
              <AlertTitle>{message.text}</AlertTitle>
            </Alert>
          )}

          <footer className="is-flex is-justify-content-end mt-5">
            <button
              type="button"
              className="button mr-4"
              onClick={() => {
                reset();
                setMessage({});
                setIsOpen(false);
              }}>
              Cancel
            </button>
            <button type="submit" className="button is-primary" disabled={isLoading}>
              {currentBudget && currentBudget.id ? 'Save budget' : 'Create budget'}
            </button>
          </footer>
        </section>
      </form>
    </Modal>
  );
};

export default BudgetCreateModal;
