// @flow

import * as React from 'react';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import type { IntlShape } from 'react-intl';

import './SearchInput.scss';

type State = {
  value: string
};

type Props = {
  value?: string,
  intl: IntlShape,
  onChangeSearchInput: (value: string) => void
};

const WAIT_TIME = 800;

class SearchInput extends React.Component<Props, State> {
  debouncedOnChange: (value: string) => void;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || ''
    };

    this.debouncedOnChange = debounce(this.props.onChangeSearchInput, WAIT_TIME);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  onChange = e => {
    const value = e.target.value;

    this.setState(
      {
        value
      },
      () => {
        this.debouncedOnChange(value);
      }
    );
  };

  render() {
    return (
      <div className="SearchInput">
        <i className="material-icons">search</i>
        <div className="field">
          <div className="control">
            <input
              className="input is-small SearchInput__input-field"
              type="text"
              placeholder={this.props.intl.formatMessage({
                id: 'table.searchPlaceholder'
              })}
              onChange={this.onChange}
              value={this.state.value}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SearchInput);
