import {
  SearchUrl,
  constructSearchURL,
  queryFromURLFriendlyFormat,
  queryToURLFriendlyFormat
} from '../helpers/searchYoutube';
import { createApiAction, createSimpleAction } from './helper';
import browserHistory from 'react-router/lib/browserHistory';

export const fetchGames = createApiAction('FETCH_GAMES_FOR_INFLUENCER_SEARCH', query => {
  const constructedGamesSearchUrl = constructSearchURL({ query });
  return {
    path: '/search/v2/games?' + constructedGamesSearchUrl
  };
});

// Based on game titles
export const fetchSuggestedGames = createApiAction(
  'FETCH_SUGGESTED_GAMES_FOR_INFLUENCER_SEARCH',
  (data: string[] | string) => {
    const baseTitles = Array.isArray(data) ? data : [data];
    return {
      path: `/search/v2/suggest/games?baseTitles=${JSON.stringify(baseTitles)}`
    };
  }
);

// Only changes the URL - This action doesn't change the state at all!
// URL is treated as the single source of truth for the search state.
// State is only modified when PublisherYouTubeSearchPage notices
// that the URL has changed and calls searchInfluencers action.
export const gotoSearchURL = createSimpleAction('GOTO_SEARCH_URL', searchQueryFromUI => {
  const urlFriendlyQuery = queryToURLFriendlyFormat(searchQueryFromUI);

  browserHistory.push({
    pathname: SearchUrl,
    search: '?' + constructSearchURL(urlFriendlyQuery)
  });
});

// Do the actual search
export const searchInfluencers = createApiAction(
  'SEARCH_INFLUENCERS',
  (searchQueryFromURL, DEFAULT_SEARCH_QUERY) => {
    // onlyInNetwork param is not passed as part of query, but instead we use it to determine which API to call
    const query = queryFromURLFriendlyFormat(searchQueryFromURL, DEFAULT_SEARCH_QUERY);
    return {
      path: `/search/v2/youtube`,
      options: {
        method: 'POST',
        body: { query }
      },
      loadingPayload: {
        query
      }
    };
  }
);

export const saveSearchToCollection = createApiAction(
  'SAVE_SEARCH_TO_COLLECTION',
  ({ collectionName, collectionId, searchId, searchQuery, defaultQuery }) => {
    const query = queryFromURLFriendlyFormat(searchQuery, defaultQuery);
    return {
      path: '/search/v2/save-to-collection',
      options: {
        method: 'POST',
        body: {
          searchId,
          query,
          collectionName,
          collectionId
        }
      },
      loadingPayload: { query, searchId, collectionId }
    };
  }
);

export const resetSearch = createSimpleAction('RESET_SEARCH');
