// @flow
import * as React from 'react';
import { AccountContextProvider } from '../helpers/account';
import { ErrorPopUp } from './Layout';
import { Helmet } from 'react-helmet';

import appleTouchIcon from '../images/themes/matchmade/apple-touch-icon.png';
import favicon from '../images/themes/matchmade/favicon-16x16.png';
type Props = {
  children: React.Node
};

const isDev = process.env.NODE_ENV === 'development';
const prefix = isDev ? '[DEV] ' : '';

export default function AuthLayout(props: Props) {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate={`${prefix}Matchmade - %s`}
        defaultTitle={`${prefix}Matchmade`}
        link={[
          {
            rel: 'apple-touch-icon',
            href: appleTouchIcon
          },
          {
            rel: 'shortcut icon',
            href: favicon,
            type: 'image/x-icon'
          }
        ]}
      />
      <ErrorPopUp />
      <AccountContextProvider>{props.children}</AccountContextProvider>
    </React.Fragment>
  );
}
