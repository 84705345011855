// @flow

import MultiValueSelector from '../common/MultiValueSelector';
import React from 'react';
import Tooltip from '../common/Tooltip';
import classNames from 'classnames';

import './CampaignsCollectionSelector.scss';

type Option = {
  name: string,
  id: number,
  team: string
};

const sortByName = (a: Option, b: Option) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};
const mapOptions = (options: Option[]) =>
  options.sort(sortByName).map(option => {
    return {
      key: '' + option.id,
      value: option.name + ' - ' + option.team
    };
  });

type Props = {|
  selected: string[],
  options: { id: number, name: string, team: string }[],
  onAdd(ids: string[]): void,
  onRemove(ids: string[]): void,
  onCopyToClipboard(id: number): void,
  disabled?: boolean
|};

function pickOptionsKeyAndCallFn(fn) {
  return options => {
    fn(options.map(({ key }) => key));
  };
}

const CampaignsCollectionSelector = (props: Props) => {
  const { disabled, options, selected, onAdd, onRemove, onCopyToClipboard } = props;

  const filteredOptions = mapOptions(options);

  return (
    <MultiValueSelector
      disabled={disabled}
      className="CampaignsCollectionSelector"
      options={filteredOptions}
      selected={filteredOptions
        .map(option => {
          if (selected.indexOf(parseInt(option.key, 10)) !== -1) {
            return { ...option };
          }
          return null;
        })
        .filter(Boolean)}
      onAdd={pickOptionsKeyAndCallFn(onAdd)}
      onRemove={pickOptionsKeyAndCallFn(onRemove)}
      onCopyToClipboard={onCopyToClipboard}
      menuRenderer={menuProps =>
        renderMenu({
          ...menuProps,
          copyToClipboard: onCopyToClipboard
        })
      }
    />
  );
};

function renderMenu({
  focusedOption,
  focusOption,
  inputValue,
  instancePrefix,
  onFocus,
  onOptionRef,
  onSelect,
  optionClassName,
  optionComponent,
  optionRenderer,
  options,
  removeValue,
  copyToClipboard,
  selectValue,
  valueArray,
  valueKey,
  labelKey
}) {
  let Option = optionComponent;

  const optionElements = options
    .map((option, i) => {
      if (!option[valueKey]) return null;

      let isSelected = valueArray && valueArray.some(x => x[valueKey] === option[valueKey]);
      let isFocused = option === focusedOption;
      let optionClass = classNames(optionClassName, {
        'Select-option': true,
        'is-selected': isSelected,
        'is-focused': isFocused,
        'is-disabled': option.disabled
      });

      return (
        <Option
          className={optionClass}
          focusOption={focusOption}
          inputValue={inputValue}
          instancePrefix={instancePrefix}
          isDisabled={option.disabled}
          isFocused={isFocused}
          isSelected={isSelected}
          key={`option-${i}-${option[valueKey]}`}
          onFocus={onFocus}
          onSelect={onSelect}
          option={option}
          optionIndex={i}
          ref={ref => {
            onOptionRef(ref, isFocused);
          }}
          removeValue={removeValue}
          selectValue={selectValue}>
          {optionRenderer(option, i, inputValue)}
        </Option>
      );
    })
    .filter(Boolean);

  const selected = valueArray.map(value => {
    return (
      <div key={value[valueKey]} className="CampaignsCollectionSelector__selected-container">
        <Tooltip tooltip={<span>Copy campaign link to clipboard</span>}>
          <span
            className="tag is-rounded MultiValueSelector__copy"
            onClick={() => {
              copyToClipboard(value[valueKey]);
            }}></span>
        </Tooltip>
        <span
          className="tag is-rounded is-info MultiValueSelector__selected"
          onClick={() => {
            removeValue(value);
          }}>
          {value[labelKey]}
        </span>
      </div>
    );
  });

  const selectedValues = selected.length ? (
    <div className="MultiValueSelector__selected-values">{selected}</div>
  ) : null;

  return (
    <div className="MultiValueSelector__menu">
      {selectedValues}
      <div className="MultiValueSelector__available-values">{optionElements}</div>
    </div>
  );
}

export default CampaignsCollectionSelector;
