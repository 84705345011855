// @flow
import * as React from 'react';

type Direction = 'asc' | 'desc';

type Props = {
  name: string,
  index?: number,
  component?: Function,
  extraProps?: Object,
  sortable?: boolean,
  sort?: string,
  sortBy?: (value: any, rowData: Object) => any,
  sortDirection?: Direction,
  sortDirectionCycle?: Direction[],
  rowSpan?: number,
  colSpan?: number,
  grouped?: boolean,
  onClick?: (params: { name: string, sortDirectionCycle: ?(Direction[]) }) => any,
  children?: any
};

// Define one column for the table
class Column extends React.PureComponent<Props, {}> {
  static defaultProps = {
    extraProps: {},
    sortable: true,
    grouped: false,
    sortDirectionCycle: ['asc', 'desc']
  };

  onClick = () => {
    const { sortDirectionCycle, name } = this.props;

    // for now clicking the header will only trigger sorting
    // so, just pass these props
    this.props.onClick &&
      this.props.onClick({
        name,
        sortDirectionCycle
      });
  };

  render() {
    const { index, sortable, grouped, sort, sortDirection, name } = this.props;

    const sortIconClassName = 'Column__sort-icon';

    // need to have this to preserve the column width
    let sortDirectionIcon = <span className="is-invisible">▲</span>,
      onClick = null;

    if (sortable && sort === name) {
      sortDirectionIcon =
        sortDirection === 'asc' ? (
          <span className={sortIconClassName}>▲</span>
        ) : (
          <span className={sortIconClassName}>▼</span>
        );
    }

    if (sortable) onClick = this.onClick;

    const props = {
      key: `column-header-${String(index)}`,
      onClick,
      rowSpan: 0,
      colSpan: 0
    };

    if (this.props.rowSpan) props.rowSpan = this.props.rowSpan;
    if (this.props.colSpan) props.colSpan = this.props.colSpan;

    return (
      <th
        {...props}
        className={`Column ${grouped ? 'Column--grouped' : ''} ${
          sortable ? 'Column--sortable' : ''
        }`}>
        {this.props.children}
        {sortDirectionIcon}
      </th>
    );
  }
}

export default Column;
