// @flow
import * as React from 'react';
import type { IntlShape } from 'react-intl';

import { FormattedMessage, injectIntl } from 'react-intl';
import CentToDollarInput from '../common/CentToDollarInput';
import type { CurrencyOnChange } from '../common/CentToDollarInput';

import './Budget.scss';

type Props = {
  value: number, // cents
  onChange: CurrencyOnChange,
  isDisabled: boolean,
  intl: IntlShape,
  warningMessage?: React.Element<*> | null
};

export class Budget extends React.PureComponent<Props> {
  static defaultProps = {
    warningMessage: null,
    isDisabled: false
  };

  onChange = (value: number | '') => {
    this.props.onChange(value || 0);
  };

  render() {
    return (
      <div className="field is-horizontal Budget">
        <div className="field-label">
          <label className="label">
            <FormattedMessage id="campaignCreation.campaignDetails.budget" />
          </label>
        </div>
        <div className="field-body">
          <div className="field no-margin-bottom Budget__input">
            {this.props.warningMessage}
            <p className="FullDollarControlInput control has-icons-left">
              <CentToDollarInput
                value={this.props.value <= 0 ? '' : this.props.value}
                isDisabled={this.props.isDisabled}
                onChange={this.onChange}
                onEnter={this.props.onChange}
                allowDecimal={false}
                placeholder={this.props.intl.formatMessage({
                  id: 'campaignCreation.campaignDetails.budget.placeholder'
                })}
              />
              <span className="icon is-small is-left">
                <i className="material-icons">attach_money</i>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Budget);
