import { Bar as BarChart } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import React from 'react';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';
import { getColorVariables } from '../../../config';
import { getInstallsByCountryGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 455; // 32.5rem;

const InstallsByCountryGraph = ({ installsPerCountry, intl }) => {
  const variables = getColorVariables();
  const notSpecifiedCountry = intl.formatMessage({
    id: 'campaign.report.stats.notSpecified'
  });
  const labels = installsPerCountry.map(
    ({ country }) => countryCodes[country] || notSpecifiedCountry
  );
  const values = installsPerCountry.map(({ count }) => count);
  const { chart } = getGraphConfig(labels, values, variables);

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default injectIntl(InstallsByCountryGraph);
