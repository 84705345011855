import buildContainerReducer from '../helpers/buildContainerReducer';

export default buildContainerReducer(
  {
    campaign: null,
    isLoadingCampaign: true,
    campaignStatistics: null,
    isFetchingCampaignStatistics: true,
    isFetchingCampaignChannelsReport: true,
    error: null
  },
  {
    error: null
  },
  ['campaign.fetchCampaign', 'campaign.fetchCampaignStatistics']
);
