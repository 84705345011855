// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';
const AnalyticsIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="AnalyticsIcon">
      {() => <i className="material-icons">control_camera</i>}
    </Icon>
  );
};

export default AnalyticsIcon;
