// @flow
import type { SearchParams } from '../types/search.flow';

import { InfluencerSearchTypeEnum } from '../types/search.flow';

export const SearchUrl = '/influencers/twitch/search';

export const DEFAULT_SEARCH_QUERY: SearchParams = Object.freeze({
  influencerType: InfluencerSearchTypeEnum.ALL,

  // Channel filters
  channelMinSubscriberCount: 50000,
  channelMaxSubscriberCount: 0,

  channelCountriesOrEstimatedCountriesWhitelist: [],
  channelCountriesOrEstimatedCountriesBlacklist: [],

  page: 1,
  pageSize: 24,
  sortBy: 'subscriberCount',
  sortDirection: 'desc',
  channelName: ''
});

export const getSearchURL = (query: Object) => {
  return `${SearchUrl}?${constructSearchURL(queryToURLFriendlyFormat(query || {}))}`;
};

export const constructSearchURL = (query: Object) => {
  return Object.keys(query)
    .map(key => {
      const value = query[key];
      if (Array.isArray(value)) {
        return value
          .map(value => encodeURIComponent(key) + '=' + encodeURIComponent(value))
          .join('&');
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    })
    .join('&');
};

export const queryToURLFriendlyFormat = (query: Object) => {
  return Object.keys(query).reduce((queryWithoutEmptyValues, key) => {
    const value = query[key];
    if (value === null || value === undefined || (Array.isArray(value) && !value.length)) {
      return queryWithoutEmptyValues;
    }
    if (Array.isArray(value)) {
      // If UI somehow screwed up and sending duplicate values -- make sure we don't have them.
      queryWithoutEmptyValues[key + '[]'] = value.filter(
        (v, i, arr) => arr.findIndex(a => a === v) === i
      );
    } else {
      queryWithoutEmptyValues[key] = value;
    }
    return queryWithoutEmptyValues;
  }, {});
};

export const queryFromURLFriendlyFormat = (query: Object, defaultSearchQuery: SearchParams) => {
  return Object.keys(query).reduce((filteredQuery, key) => {
    const value = query[key];
    if (key.endsWith('[]')) {
      const keyWithoutParens = key.split('[]')[0];
      if (!filteredQuery[keyWithoutParens]) {
        filteredQuery[keyWithoutParens] = Array.isArray(value) ? value : [value];
      } else {
        filteredQuery[keyWithoutParens] = filteredQuery[keyWithoutParens].concat(value);
      }
      // If UI somehow screwed up and sending duplicate values -- make sure we don't have them.
      filteredQuery[keyWithoutParens] = filteredQuery[keyWithoutParens].filter(
        (v, i, arr) => arr.findIndex(a => a === v) === i
      );
    } else {
      if (typeof defaultSearchQuery[key] === 'number') {
        filteredQuery[key] = Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      } else if (typeof defaultSearchQuery[key] === 'boolean') {
        // If this comes from search query, it will be a string, but in DEFAULT_SEARCH_QUERY it is boolean
        filteredQuery[key] = typeof value === 'boolean' ? !!value : value === 'true';
      } else if (defaultSearchQuery.hasOwnProperty(key)) {
        filteredQuery[key] = value === null ? '' : value;
      }
    }
    // Make sure we add default criteria that might be missing from query
    // This is needed in cases where current search is ['Minecraft'], but we go back to [],
    // which might not be present in the URL, but we still need to overwrite current state with []
    return { ...defaultSearchQuery, ...filteredQuery };
  }, {});
};
