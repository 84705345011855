// @flow
import type { Action } from '../../../types/action.flow';
import type { GetAllResponseData } from 'matchmade-types/api/collections';
import type { GetAllActionAttributes as GetAllTeamsActionAttributes } from '../../common/team';

import runMultipleReducers from '../../helpers/runMultipleReducers';

import {
  changeTeam,
  duplicateAsDynamicCollection,
  duplicateAsStaticCollection,
  getAll,
  removeCollection
} from '../../../actions/collection';

import {
  handleGetAllActions as handleGetAllTeamsActions,
  handleNewTeamCreatedWhenChangingTeam
} from '../../common/team';

export type State = {|
  ...$Exact<GetAllTeamsActionAttributes>,
  collections: ?GetAllResponseData,
  isLoadingCollections: boolean,

  isChangingTeam: boolean,

  error: Object | null
|};

const initialState: State = Object.freeze({
  collections: null,
  isLoadingCollections: false,

  isChangingTeam: false,

  isLoadingTeams: false,
  teams: null,

  error: null
});

function changeCollectionTeam() {
  return (state, action) => {
    switch (action.type) {
      case changeTeam.REQUEST:
        return {
          ...state,
          isChangingTeam: true,
          // optimistically update the collection using the current team list in state if available
          collections: (state.collections || []).map(collection => {
            if (collection.id !== action.payload.collectionId) {
              return collection;
            }

            const newTeam = (state.teams || []).find(({ id }) => id === action.payload.teamId);
            if (!newTeam) {
              return collection;
            }

            return {
              ...collection,
              team: newTeam
            };
          })
        };
      case changeTeam.SUCCESS:
        return {
          ...state,
          isChangingTeam: false,
          collections: (state.collections || []).map(collection => {
            if (collection.id !== action.payload.id) {
              return collection;
            }

            return {
              ...collection,
              team: action.payload.team
            };
          })
        };
      case changeTeam.FAILURE:
        return {
          ...state,
          isChangingTeam: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
}

export default function adminDashboardCollectionsReducer(
  state: State = initialState,
  action: Action
): State {
  const newState = runMultipleReducers([
    handleGetAllTeamsActions(),
    handleNewTeamCreatedWhenChangingTeam(),
    changeCollectionTeam()
  ])(
    {
      ...state,
      error: null
    },
    action
  );

  switch (action.type) {
    case getAll.REQUEST:
      return {
        ...newState,
        isLoadingCollections: true
      };
    case getAll.SUCCESS:
      return {
        ...newState,
        isLoadingCollections: false,
        collections: action.payload
      };
    case getAll.FAILURE:
      return {
        ...newState,
        isLoadingCollections: false,
        error: action.payload
      };
    case removeCollection.SUCCESS:
      return {
        ...newState,
        isLoadingCollections: false,
        collections:
          (newState.collections && newState.collections.filter(c => c.id !== action.payload.id)) ||
          null
      };
    case duplicateAsStaticCollection.SUCCESS:
    case duplicateAsDynamicCollection.SUCCESS:
      return {
        ...newState,
        isLoadingCollections: false,
        collections: (newState.collections && newState.collections.concat(action.payload)) || null
      };
    default:
      return newState;
  }
}
