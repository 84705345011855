// @flow
import * as React from 'react';

import AdvancedSetting from '../common/AdvancedSetting';

import { FormattedMessage } from 'react-intl';
import Tooltip from '../../common/Tooltip';

import './VideoReview.scss';

type Props = {
  value: boolean,
  isDisabled: boolean,
  onChange: (value: boolean) => void
};

const VideoReview = (props: Props) => {
  const disabledTooltip = props.isDisabled ? (
    <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.videoReview.tooltip.disabled" />
  ) : null;

  return (
    <AdvancedSetting
      disabled={props.isDisabled}
      label={
        <Tooltip tooltip={disabledTooltip}>
          <span>
            <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.videoReview" />
          </span>
        </Tooltip>
      }
      tooltip={
        <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.videoReview.tooltip" />
      }>
      <div className="control CampaignDescription__advanced-setting-content VideoReview">
        <label className="radio">
          <input
            onChange={() => {
              props.onChange(true);
            }}
            checked={props.value}
            disabled={props.isDisabled}
            type="radio"
            name="requireVideoReview"
            value="true"
          />
          <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.videoReview.yes" />
        </label>
        <label className="radio">
          <input
            onChange={() => {
              props.onChange(false);
            }}
            checked={!props.value}
            disabled={props.isDisabled}
            type="radio"
            name="requireVideoReview"
            value="false"
          />
          <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.videoReview.no" />
        </label>
      </div>
    </AdvancedSetting>
  );
};

export default VideoReview;
