// @flow
import type { ClearError } from '../../actions/common';
import type { ImportGame, SearchGame } from '../../actions/publisher';
import type {
  DataProps as ProductSelectionData,
  SearchSettings
} from '../../components/campaignCreation/ProductSelection';
import type { RootState } from '../../reducers';
import type {
  SetAttributesForProductSelectionStep,
  SetSearchSettingsInProductSelectionStep
} from '../../actions/campaign';
import type { Step } from '../../components/campaignCreation/CampaignCreationWizard';
import type { StepControl } from '../../components/campaignCreation/CampaignCreationWizard';

import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Platform as PlatformEnum } from '@sharkpunch/matchmade-common/game';
import { connect } from 'react-redux';
import { getUser } from '../../helpers/user';
import isEqual from 'lodash/isEqual';
import type { IntlShape } from 'react-intl';

import { buildStepsFromState } from './buildStepsFromState';
import compose from '../../hoc/compose';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';

import { BASE_CAMPAIGN_CREATION_URL, StepName, StepUrl } from './constants';

import NotificationIcon from '../../components/common/Icons/NotificationIcon';

import CampaignCreationWizard, {
  setupStepControl
} from '../../components/campaignCreation/CampaignCreationWizard';
import ProductSelection from '../../components/campaignCreation/ProductSelection';

import {
  clearCreatedCampaign,
  setAttributesForProductSelectionStep,
  setSearchSettingsInProductSelectionStep
} from '../../actions/campaign';
import { clearError } from '../../actions/common';
import { importGame, searchGame } from '../../actions/publisher';

type Actions = {|
  clearCreatedCampaign: () => void,
  setAttributesForProductSelectionStep: SetAttributesForProductSelectionStep,
  setSearchSettingsInProductSelectionStep: SetSearchSettingsInProductSelectionStep,
  importGame: ImportGame,
  searchGame: SearchGame,
  clearError: ClearError
|};

type DataProps = {|
  data: ProductSelectionData,
  searchSettings: SearchSettings,
  steps: Step[],
  baseUrl: string,

  isImportingGame: boolean,
  importedGame: any,
  createdByUserId?: number,

  isSearchingGame: boolean,
  searchResults: any[] | null
|};

type Props = {|
  ...Actions,
  ...DataProps,

  intl: IntlShape
|};

class ProductSelectionStep extends React.PureComponent<Props, {}> {
  static layoutClassName = 'Layout--create-campaign';
  static disableHeadroom = true;
  static url = StepUrl[StepName.PRODUCT_SELECTION];

  stepControl: StepControl;

  constructor(props: Props) {
    super(props);

    this.stepControl = setupStepControl(this.props.steps, this.props.baseUrl);
  }

  componentDidMount() {
    if (this.props.createdByUserId !== getUser().id) {
      this.props.clearCreatedCampaign();
    }
  }

  componentDidUpdate(prevProps: Props) {
    // when we have imported the game, go to the next step
    if (prevProps.isImportingGame && !this.props.isImportingGame && this.props.importedGame) {
      this.stepControl.goToNextStep();
    }
  }

  shouldImportGame() {
    const {
      data: { selectedGames },
      importedGame
    } = this.props;

    if (!importedGame) {
      return true;
    }

    const importedGames = (importedGame.platforms || [])
      .map(platform => {
        switch (platform) {
          case PlatformEnum.ANDROID:
            return `${platform}:${importedGame.googlePlayId}`;
          case PlatformEnum.IOS:
            return `${platform}:${importedGame.appStoreId}`;
          case PlatformEnum.STEAM:
            return `${platform}:${importedGame.steamAppId}`;
          default:
            return null;
        }
      })
      .filter(Boolean)
      .sort();

    const sortedSelectedGames = selectedGames.map(game => `${game.platform}:${game.id}`).sort();

    if (!importedGames.length || !isEqual(importedGames, sortedSelectedGames)) {
      return true;
    }

    return false;
  }

  onGoToStep = (index: number) => {
    const {
      data: { selectedGames },
      searchSettings
    } = this.props;

    if (!this.shouldImportGame() || index <= this.stepControl.getActiveStepIndex()) {
      return this.stepControl.goToStep(index);
    }

    const urls = selectedGames.map(game => game.url);

    // only import game if we go forward
    this.props.importGame(
      urls,
      selectedGames[0].title,
      searchSettings.isSoftLaunch ? searchSettings.softLaunchCountry : null,
      false
    );
  };

  renderNotification() {
    const { searchResults, data } = this.props;
    const { selectedGames, selectedPlatforms } = data;

    if (!data.campaignType) {
      return {
        icon: <NotificationIcon />,
        content: (
          <FormattedMessage id="campaignCreation.productSelection.notification.missingVertical" />
        )
      };
    }

    // if any of this is true, don't show the notification
    if (
      [
        (searchResults && !searchResults.length) || !selectedGames.length,
        selectedPlatforms.length <= 1
      ].some(Boolean)
    )
      return null;

    if (selectedGames.length === selectedPlatforms.length) return null;
    const selectedGamesPlatforms = selectedGames.map(g => {
      return g.platform;
    });
    const missingPlatform = selectedPlatforms.filter(
      p => selectedGamesPlatforms.indexOf(p) === -1
    )[0];

    return {
      icon: <NotificationIcon />,
      content: (
        <FormattedMessage
          id="campaignCreation.productSelection.notification.missingGameSelection"
          values={{
            platform: this.props.intl.formatMessage({ id: `platform.${missingPlatform}` })
          }}
        />
      )
    };
  }

  render() {
    const {
      data,
      baseUrl,
      steps,
      clearError,
      isSearchingGame,
      isImportingGame,
      searchGame,
      searchResults,
      setAttributesForProductSelectionStep,
      setSearchSettingsInProductSelectionStep,
      searchSettings
    } = this.props;

    const content = (
      <ProductSelection
        isLoading={isSearchingGame || isImportingGame}
        isDisabled={isImportingGame}
        onSearchForGame={(query, country) => {
          searchGame(query, country, false, false);
        }}
        games={searchResults}
        data={data}
        onChange={newData => {
          setAttributesForProductSelectionStep(newData);
        }}
        searchSettings={searchSettings}
        onChangeSearchSettings={data => {
          setSearchSettingsInProductSelectionStep(data);
        }}
      />
    );

    const { selectedGames } = data;
    // prioritize iOS logo because that's what the backend does
    const iosGame = (selectedGames || []).find(({ platform }) => platform === PlatformEnum.IOS);
    const androidGame = (selectedGames || []).find(
      ({ platform }) => platform === PlatformEnum.ANDROID
    );
    const steamGame = (selectedGames || []).find(({ platform }) => platform === PlatformEnum.STEAM);
    const logoUrl = (iosGame || androidGame || steamGame || {}).logoUrl;

    // we are using changeStyleWhenScrolling HOC in CampaignCreationWizard which automatically
    // injects `style` object to props. We haven't had a way to tell flow that yet
    return (
      // $FlowFixMe
      <CampaignCreationWizard
        headerBgImage={logoUrl}
        baseUrl={baseUrl}
        steps={steps}
        notification={this.renderNotification()}
        onGoToStep={this.onGoToStep}
        onClearError={clearError}
        isLoading={isSearchingGame || isImportingGame}>
        {content}
      </CampaignCreationWizard>
    );
  }
}

const mapDispatchToProps = generateDispatchToProps({
  clearCreatedCampaign,
  clearError,
  setSearchSettingsInProductSelectionStep,
  setAttributesForProductSelectionStep,
  importGame,
  searchGame
});

export default compose(
  connect(function (state: RootState, ownProps): DataProps {
    const ownState = state.campaignCreation;

    return {
      data: ownState.productSelection,

      isImportingGame: ownState.isImportingGame,
      importedGame: ownState.importedGame,

      isSearchingGame: ownState.isSearchingGame,
      searchResults: ownState.searchResults,

      searchSettings: ownState.searchSettings,

      steps: buildStepsFromState(ownState, StepName.PRODUCT_SELECTION),
      baseUrl: BASE_CAMPAIGN_CREATION_URL,

      createdByUserId: ownState.createdByUserId
    };
  }, mapDispatchToProps),
  injectIntl
)(ProductSelectionStep);
