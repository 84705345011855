import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import React from 'react';
import clamp from 'lodash/clamp';
import moment from 'moment';

import { PromotionType as PromotionTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import { numberToFormattedValue } from '../../../helpers/percentageFormat';
import FormattedNumber from '../FormattedNumber';
import Tooltip from '../../common/Tooltip';
import type { PromotionType } from 'matchmade-types';

import './LastDealsTab.scss';

type Props = {
  deals: [
    {
      campaignAgreementId: number,
      campaignId: number,
      campaignName: string,
      promotionType: PromotionType,
      cost: number,
      payout: number,
      created: Date,
      views: number,
      installs: number,
      clicks: number
    }
  ]
};
const MAX_NUMBER_OF_DEALS = 3;

const LastDealsTab = ({ deals }: Props) => {
  if (!deals || !deals.length) {
    return (
      <div className="LastDeals no-deals">
        <FormattedMessage id="influencer.card.deals.noDeals" tagName="strong" />
      </div>
    );
  }

  deals = deals.sort((a, b) => (a.created > b.created ? -1 : a.created < b.created ? 1 : 0));

  return (
    <div className="LastDeals">
      <div className="column">
        <div className="LastDeals__title">&nbsp;</div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.promotionType" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.cost" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.payout" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.installs" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.clicks" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.views" tagName="strong" />
        </div>
        <div className="LastDeals__title">
          <Tooltip tooltip={<FormattedMessage id="influencer.card.deals.admin.eCpm.tooltip" />}>
            <div>
              <FormattedMessage id="influencer.card.deals.eCpm" tagName="strong" />
            </div>
          </Tooltip>
        </div>
        <div className="LastDeals__title">
          <Tooltip tooltip={<FormattedMessage id="influencer.card.deals.ctr.tooltip" />}>
            <div>
              <FormattedMessage id="influencer.card.deals.ctr" tagName="strong" />
            </div>
          </Tooltip>
        </div>
        <div className="LastDeals__title">
          <Tooltip tooltip={<FormattedMessage id="influencer.card.deals.cr.tooltip" />}>
            <div>
              <FormattedMessage id="influencer.card.deals.cr" tagName="strong" />
            </div>
          </Tooltip>
        </div>
        <div className="LastDeals__title">
          <Tooltip
            tooltip={<FormattedMessage id="influencer.card.deals.viewsToInstalls.tooltip" />}>
            <div>
              <FormattedMessage id="influencer.card.deals.viewsToInstalls" tagName="strong" />
            </div>
          </Tooltip>
        </div>
        <div className="LastDeals__title">
          <FormattedMessage id="influencer.card.deals.campaign" tagName="strong" />
        </div>
      </div>
      {[...Array(clamp(deals.length, MAX_NUMBER_OF_DEALS)).keys()].map(i => {
        const deal = deals[i];
        const ctr = deal && numberToFormattedValue(deal.clicks / deal.views, true);
        const cr = deal && numberToFormattedValue(deal.installs / deal.clicks, true);
        const vti = deal && numberToFormattedValue(deal.installs / deal.views, true);

        return (
          <div className="column" key={i}>
            {deal && (
              <React.Fragment>
                <h2 className="LastDeals__value">
                  <a
                    href={`/messages/${deal.campaignAgreementId}`}
                    className="listItem"
                    target="_blank"
                    rel="noopener noreferrer">
                    {moment(deal.created).locale('short-format').fromNow()}
                  </a>
                </h2>
                <div className="LastDeals__value">
                  {deal.promotionType === PromotionTypeEnum.INTEGRATION && (
                    <FormattedMessage id="promotionTypes.integration" />
                  )}
                  {deal.promotionType === PromotionTypeEnum.DEDICATED && (
                    <FormattedMessage id="promotionTypes.dedicated" />
                  )}
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber value={deal.cost} type="cost" />
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber value={deal.payout} type="cost" />
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber value={deal.installs} type="number" />
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber value={deal.clicks} type="number" />
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber value={deal.views} type="number" />
                </div>
                <div className="LastDeals__value">
                  <FormattedNumber
                    value={deal.views ? (deal.cost / deal.views) * 1000 : 0}
                    type="cost"
                  />
                </div>
                <div className="LastDeals__value">{ctr === null ? 'N/A' : `${ctr}%`}</div>
                <div className="LastDeals__value">{cr === null ? 'N/A' : `${cr}%`}</div>
                <div className="LastDeals__value">{vti === null ? 'N/A' : `${vti}%`}</div>
                <div className="LastDeals__value LastDeals__campaign">
                  <Tooltip tooltip={deal.campaignName}>
                    <Link to={`/admin/campaigns/${deal.campaignId}`}>{deal.campaignName}</Link>
                  </Tooltip>
                </div>
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LastDealsTab;
