import React, { PureComponent } from 'react';

import moment from 'moment';

const updateInterval = 60 * 100; // default

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function WithTimestampUpdate(WrappedComponent, timestampProp = 'created') {
  class WithTimestampUpdate extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        timestamp: moment(props[timestampProp]).fromNow()
      };
      this.updateTimestamp = this.updateTimestamp.bind(this);
    }

    componentDidMount() {
      this._updateTimestampInterval = setInterval(this.updateTimestamp, updateInterval);
    }

    componentWillUnmount() {
      clearInterval(this._updateTimestampInterval);
    }

    updateTimestamp() {
      const timestamp = moment(this.props[timestampProp]).fromNow();
      if (this.state.timestamp === timestamp) return;
      this.setState({ timestamp });
    }

    render() {
      // Filter out extra props that are specific to this HOC and shouldn't be
      // passed through
      const { ...passThroughProps } = this.props;

      // Pass props to wrapped component
      return <WrappedComponent timestamp={this.state.timestamp} {...passThroughProps} />;
    }
  }
  WithTimestampUpdate.displayName = `WithTimestampUpdate${getDisplayName(WrappedComponent)})`;
  return WithTimestampUpdate;
}
