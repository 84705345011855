import React from 'react';

import { FormattedMessage } from 'react-intl';

import { getColorVariables } from '../../../config';

import CPAIcon from '../../../images/icons/CPAIcon';
import CPIIcon from '../../../images/icons/CPIIcon';
import CPMIcon from '../../../images/icons/CPMIcon';
import ECPIIcon from '../../../images/icons/ECPIIcon';
import EffectiveReachIcon from '../../../images/icons/EffectiveReachIcon';
import EngagementIcon from '../../../images/icons/EngagementIcon';
import InstallsIcon from '../../../images/icons/InstallsIcon';
import OrganicInstallsIcon from '../../../images/icons/OrganicInstallsIcon';
import PromotionTypesIcon from '../../../images/icons/PromotionTypesIcon';

import './Glossary.scss';

const colors = getColorVariables();
const primaryColor = colors['mm-navy'];
const secondaryColor = colors['mm-aqua'];

const Glossary = () => {
  return (
    <div className="Glossary">
      <h2>
        <FormattedMessage id="campaign.report.glossary.title" tagName="strong" />
      </h2>

      <div className="Glossary__table">
        <div className="Glossary__table-item">
          <figure className="image">
            <CPIIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.cpiTitle" tagName="strong" />
          </h3>
          <h4>
            <FormattedMessage id="campaign.report.glossary.cpiMeaning" tagName="strong" />
          </h4>
          <FormattedMessage id="campaign.report.glossary.cpiDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <CPAIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.cpaTitle" tagName="strong" />
          </h3>
          <h4>
            <FormattedMessage id="campaign.report.glossary.cpaMeaning" tagName="strong" />
          </h4>
          <FormattedMessage id="campaign.report.glossary.cpaDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <ECPIIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.ecpiTitle" tagName="strong" />
          </h3>
          <h4>
            <FormattedMessage id="campaign.report.glossary.ecpiMeaning" tagName="strong" />
          </h4>
          <FormattedMessage id="campaign.report.glossary.ecpiDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <CPMIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.cpmTitle" tagName="strong" />
          </h3>
          <h4>
            <FormattedMessage id="campaign.report.glossary.cpmMeaning" tagName="strong" />
          </h4>
          <FormattedMessage id="campaign.report.glossary.cpmDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <InstallsIcon primaryColor={primaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.installTitle" tagName="strong" />
          </h3>
          <FormattedMessage id="campaign.report.glossary.installDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <OrganicInstallsIcon primaryColor={primaryColor} />
          </figure>
          <h3>
            <FormattedMessage
              id="campaign.report.glossary.unattributedInstallTitle"
              tagName="strong"
            />
          </h3>
          <FormattedMessage id="campaign.report.glossary.unattributedInstallDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <EffectiveReachIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.effectiveReachTitle" tagName="strong" />
          </h3>
          <FormattedMessage id="campaign.report.glossary.effectiveReachDescription" />
        </div>
        <div className="Glossary__table-item">
          <figure className="image">
            <EngagementIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.engagement" tagName="strong" />
          </h3>
          <FormattedMessage id="campaign.report.glossary.engagementDescription" />
        </div>
        <div className="Glossary__table-item Glossary__promotion-types">
          <figure className="image">
            <PromotionTypesIcon />
          </figure>
          <h3>
            <FormattedMessage id="campaign.report.glossary.promotionTypes" tagName="strong" />
          </h3>
          <FormattedMessage id="campaign.report.glossary.promotionTypesDescription" tagName="p" />
          <FormattedMessage id="campaign.report.glossary.dedicated" tagName="p" />
          <FormattedMessage id="campaign.report.glossary.integrated" tagName="p" />
        </div>
      </div>
    </div>
  );
};

export default Glossary;
