// @flow
import { createSimpleAction } from './helper';

export type AppError = {
  code: number,
  message: string,
  params?: any
};

export const addError = createSimpleAction('ADD_GLOBAL_ERROR');
export const clearErrors = createSimpleAction('CLEAR_GLOBAL_ERRORS');
