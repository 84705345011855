// @flow
import './Autopilot.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoTooltip from '../../common/InfoTooltip';

type Props = {
  isSelected: boolean,
  onChangeValues: (values: boolean) => void
};

export default function Autopilot(props: Props) {
  const { isSelected = true, onChangeValues } = props;

  return (
    <div className="field is-horizontal Autopilot">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.campaignDescription.autopilot" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.campaignDescription.autopilot.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <button
          className="link-button Autopilot__toggle control"
          onClick={() => onChangeValues(!isSelected)}>
          <label className="radio">
            <FormattedMessage id="campaignCreation.campaignDescription.autopilot.on" tagName="b" />
          </label>
          <i
            className={`Autopilot__toggle-icon material-icons ${
              isSelected ? '' : 'Autopilot__toggle-off'
            }`}>
            {isSelected ? 'toggle_off' : 'toggle_on'}
          </i>
          <label className="radio">
            <FormattedMessage id="campaignCreation.campaignDescription.autopilot.off" tagName="b" />
          </label>
        </button>
      </div>
    </div>
  );
}
