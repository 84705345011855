import { createApiAction } from './helper';

export const fetchCampaignAgreementInfo = createApiAction('FETCH_CAMPAIGN_AGREEMENT_INFO', id => {
  return {
    path: `/agreements/${id}`
  };
});

export const fetchInfluencerFromCampaignAgreement = createApiAction(
  'FETCH_INFLUENCER_FROM_CAMPAIGN_AGREEMENT',
  id => {
    return {
      path: `/agreements/${id}/influencer`
    };
  }
);

export const setAgreementDeadline = createApiAction(
  'SET_AGREEMENT_DEADLINE',
  (agreementId, deadline) => {
    return {
      path: `/agreements/${agreementId}/deadline`,
      options: {
        method: 'PUT',
        body: { deadline }
      },
      loadingPayload: { agreementId, deadline }
    };
  }
);

export const addPublishedContent = createApiAction(
  'ADD_PUBLISHED_CONTENT_TO_AGREEMENT',
  (agreementId, contentType, contentUrl) => {
    return {
      path: `/agreements/${agreementId}/${contentType}/publish`,
      options: {
        method: 'POST',
        body: { contentUrl }
      },
      loadingPayload: { agreementId }
    };
  }
);

export const cancelSettledDeal = createApiAction('CANCEL_SETTLED_DEAL', agreement => {
  return {
    path: `/agreements/${agreement.id}/cancel-settled-deal`,
    options: {
      method: 'DELETE'
    },
    loadingPayload: { agreement }
  };
});
