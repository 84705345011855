export function parseInputToCents(userInput) {
  userInput += ''; // make sure we always have string
  // From dollars to cents
  let cents = parseFloat(userInput.replace(',', '.')) * 100;

  // Rounding is needed to avoid $1.11 turning into 111.00000000000000001 etc.
  cents = Math.round(cents);

  return cents;
}

export function userInputToCents(userInput, maxValue, minValue, defaultValue = 0) {
  let cents = parseInputToCents(userInput);
  if (cents < 0) cents = 0;
  if (maxValue !== undefined) cents = Math.min(cents, maxValue);
  if (minValue !== undefined) cents = Math.max(cents, minValue);
  if (isNaN(cents)) cents = defaultValue;

  return cents;
}

export function centsToFormattedValue(cents, rounded) {
  cents = parseInt(cents, 10);
  if (isNaN(cents)) return null;

  if (rounded) return Math.round(cents / 100) + '';
  return (cents / 100).toFixed(2);
}

export function centsToDollars(cents) {
  const centsInt = parseInt(cents, 10);
  if (isNaN(centsInt)) return null;
  return cents / 100;
}
