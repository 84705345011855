import { PublisherPaymentInfo } from '../types/paymentInfo.flow';
import { createApiAction } from './helper';

export const fetchPublisherPaymentInfo = createApiAction('FETCH_PUBLISHER_PAYMENT_INFO', () => {
  return {
    path: '/me/payment-info-publisher'
  };
});

export const updatePublisherPaymentInfo = createApiAction(
  'UPDATE_PUBLISHER_PAYMENT_INFO',
  (paymentInfo: PublisherPaymentInfo) => {
    return {
      path: '/me/payment-info-publisher',
      options: {
        method: 'PUT',
        body: paymentInfo
      }
    };
  }
);
