// @flow
import React from 'react';

import CurrentDealTable, { CurrentDealTableFooter } from './CurrentDealTable';
import type { StatelessProps as Props } from './types.flow';

import './AdminTab.scss';

const AdminTab = (props: Props) => {
  return (
    <div className="AdminTab">
      <CurrentDealTable
        footer={
          <CurrentDealTableFooter
            showPublishContent={props.showPublishContent}
            agreementId={props.agreementId}
            contentPlatform={props.contentPlatform}
            contentSubmission={props.contentSubmission}
            // Change deadline input props
            deadline={props.deadline}
            isSubmitDeadlineDisabled={props.isSubmitDeadlineDisabled}
            isDeadlineEditable={props.isDeadlineEditable}
            onChangedDeadline={props.onChangedDeadline}
            onSubmitDeadline={props.onSubmitDeadline}
            cpi={props.cpi}
            cpm={props.cpm}
            minGuarantee={props.minGuarantee}
            maxPayment={props.maxPayment}
            commissionRate={props.commissionRate}
          />
        }
      />
    </div>
  );
};

export default AdminTab;
