// @flow
import type { ContentPlatform } from 'matchmade-types';

import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';

import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';
import { SETTLED } from '@sharkpunch/matchmade-common/campaignAgreement';

import InfluencerAvailableCard from '../../influencer/InfluencerAvailableCard';
import InfluencerInvitedCard from '../../influencer/InfluencerInvitedCard';
import InfluencerNegotiationCard from '../../influencer/InfluencerNegotiationCard';
import InfluencerParticipatingCard from '../../influencer/InfluencerParticipatingCard';
import type { CampaignForPublisher } from '../../../types/campaign.flow';

import { combineDifferentContentPlatforms } from '../../../helpers/influencer';
import { getViewsFromEstimates } from '../../../helpers/youtube';

export const Tabs = {
  ALL: 'all',
  NEGOTIATIONS: 'negotiations',
  PARTICIPANTS: 'participants',
  COLLECTION: 'collection',
  NO_DEAL_TAGS: 'noTags'
};

export function getSortingOptions(contentPlatform: ContentPlatform) {
  switch (contentPlatform) {
    case ContentPlatformEnum.YOUTUBE:
      return youtubeSortOptions;
    case ContentPlatformEnum.TWITCH:
      return twitchSortOptions;
    case ContentPlatformEnum.INSTAGRAM:
      return instagramSortOptions;
    default:
      return youtubeSortOptions;
  }
}

export const instagramSortOptions = [
  {
    attribute: 'followerCount',
    label: 'instagram.followers'
  },
  {
    attribute: 'name',
    label: 'instagram.channelName'
  },
  {
    attribute: 'postCount',
    label: 'instagram.posts'
  },
  {
    attribute: 'engagementRate',
    label: 'instagram.engagement'
  }
];

export const twitchSortOptions = [
  {
    attribute: 'last30dStats.streamsAverageCcv',
    label: 'twitch.30dAvgCcv'
  }
];

export const youtubeSortOptions = [
  {
    sort(influencer: any) {
      // pick estimate based on defaultContentPlatform
      const channel = get(influencer, 'youtubeChannels[0]');
      if (!channel) return -1;
      const median =
        channel.estimates &&
        channel.estimates.freeToPlayMobile &&
        channel.estimates.freeToPlayMobile.installs.dedicated.median;
      return !isNaN(parseFloat(median)) ? median : -1;
    },
    attribute: 'estimatedInstalls',
    label: 'influencer.collection.sort.estimatedInstalls'
  },
  {
    sort(influencer: any) {
      const youtubeChannel = influencer.youtubeChannels[0];

      if (!youtubeChannel) {
        return -1;
      }

      return youtubeChannel && getViewsFromEstimates(youtubeChannel.estimates);
    },
    attribute: 'estimatedViews',
    label: 'influencer.collection.sort.estimatedViews'
  },
  {
    // multiple twitch view count with some magic number
    attribute: 'last30dStats.averageViewCount',
    label: 'influencer.collection.sort.last30dAvgViews'
  },
  {
    // Figure out what engagement means on Twitch
    attribute: 'last30dStats.averageEngagementRatio',
    label: 'influencer.collection.sort.last30dAvgEngagement'
  },
  {
    sort(influencer: any) {
      if (influencer.agreement) {
        // hacky way to sort by creation
        // trusting that the id's increase monotonically
        return influencer.agreement.id;
      } else {
        return 0;
      }
    },
    attribute: 'dealdone',
    label: 'influencer.collection.sort.dealDone'
  },
  {
    sort(influencer: any) {
      if (influencer.agreement) {
        return influencer.agreement.deadline;
      } else {
        return 0;
      }
    },
    attribute: 'publishDate',
    label: 'influencer.collection.sort.publishDate'
  },
  {
    sort(influencer: any, sortByValue: any) {
      // agreement && estimatedCost are added to the influencer in the Tabs creating the InfluencerCollection
      if (influencer.agreement && influencer.estimatedCost) {
        const agreement = influencer.agreement;
        const estimatedCost = influencer.estimatedCost;
        const averageViewCount = influencer.youtubeChannels[0].last30dStats.averageViewCount;
        let costToDisplay = 0;
        const isCostOverMaxPayment =
          agreement.maxPayment && costToDisplay && costToDisplay >= agreement.maxPayment;
        if (agreement.cost || isCostOverMaxPayment) {
          costToDisplay = agreement.cost;
        } else {
          costToDisplay = estimatedCost;
        }
        return averageViewCount && (costToDisplay / averageViewCount) * 1000;
      } else {
        return 0;
      }
    },
    attribute: 'ecpm',
    label: 'influencer.collection.sort.eCpm'
  }
];

/**
 * Here we do 2 things:
 * 1: Validate tab is truthy
 * 2: Is one of our static tabs
 */
export function isValidTab(tab?: string) {
  const values = Object.values(Tabs);
  return !!(tab && values.find(t => t === tab));
}

export function isParticipating(influencer: any, campaign: CampaignForPublisher) {
  const { agreements } = campaign;
  const agreement = find(agreements, { influencerId: influencer.id });
  return agreement && agreement.status === SETTLED;
}

export function isNegotiating(influencer: any, campaign: CampaignForPublisher) {
  const { agreements } = campaign;
  const agreement = find(agreements, { influencerId: influencer.id });
  return agreement && agreement.status !== SETTLED;
}

export function isInvited(influencer: any, campaign: CampaignForPublisher) {
  const channels = combineDifferentContentPlatforms(influencer);

  const relevantChannels = channels.filter(({ contentPlatform }) => {
    // only pick the relevant platform to compare
    return campaign.defaultContentPlatform === contentPlatform;
  });

  return !!campaign.outOfNetworkInfluencers.find(i => {
    if (!i.invite) {
      return false;
    }

    return !!relevantChannels.find(({ id, contentPlatform }) => {
      return id === i.invite.channelId && contentPlatform === i.invite.contentPlatform;
    });
  });
}

export function isAvailable(
  influencer: any,
  campaign: CampaignForPublisher,
  influencerNoPartOfCampaign?: any[]
) {
  return influencerNoPartOfCampaign && influencerNoPartOfCampaign.find(i => i.id === influencer.id);
}

export function getParticipatingInfluencers(campaign: CampaignForPublisher) {
  const { influencers } = campaign;
  const influencerGroups: any = partition(influencers, i => {
    return isParticipating(i, campaign);
  });
  return influencerGroups[0];
}

function tagsForInfluencer(campaign: CampaignForPublisher, influencer: any): string[] {
  const agreement = find(campaign.agreements, { influencerId: influencer.id });
  if (!agreement) {
    return [];
  } else {
    return agreement.dealTags || [];
  }
}

export function getParticipatingInfluencersWithTag(campaign: CampaignForPublisher, tag: string) {
  const influencers = getParticipatingInfluencers(campaign);
  return influencers.filter(influencer => {
    return tagsForInfluencer(campaign, influencer).indexOf(tag) > -1;
  });
}

export function getParticipatingInfluencersWithNoTags(campaign: CampaignForPublisher) {
  const influencers = getParticipatingInfluencers(campaign);
  return influencers.filter(influencer => {
    return tagsForInfluencer(campaign, influencer).length === 0;
  });
}

export function getTagsForParticipatingInfluencers(campaign: CampaignForPublisher): string[] {
  const influencers = getParticipatingInfluencers(campaign);
  const allTags = flatMap(influencers, influencer => {
    return tagsForInfluencer(campaign, influencer);
  });
  return uniq(allTags);
}

export function getNegotiatingInfluencers(campaign: CampaignForPublisher) {
  const { influencers, agreements } = campaign;
  const influencerGroups: any = partition(influencers, i => {
    const agreement = find(agreements, { influencerId: i.id });
    return agreement && agreement.status === SETTLED;
  });

  return influencerGroups[1];
}

export function getInvitedInfluencers(campaign: CampaignForPublisher) {
  return campaign.outOfNetworkInfluencers;
}

export function getTabNames(campaign: CampaignForPublisher) {
  const sections = [];

  if (getNegotiatingInfluencers(campaign).length || getInvitedInfluencers(campaign).length) {
    sections.unshift(Tabs.NEGOTIATIONS);
  }

  if (getParticipatingInfluencers(campaign).length) {
    sections.unshift(Tabs.PARTICIPANTS);
  }

  return sections;
}

export function getCardComponentByInfluencer(influencer: any, campaign: CampaignForPublisher) {
  const type = getInfluencerCampaignType(influencer, campaign);
  switch (type) {
    case 'participating':
      return InfluencerParticipatingCard;
    case 'negotiating':
      return InfluencerNegotiationCard;
    case 'invited':
      return InfluencerInvitedCard;
    case 'available':
      return InfluencerAvailableCard;
    default:
      return InfluencerAvailableCard;
  }
}

export function getInfluencerCampaignType(influencer: any, campaign: CampaignForPublisher) {
  const participating = isParticipating(influencer, campaign);
  const negotiating = isNegotiating(influencer, campaign);
  const invited = isInvited(influencer, campaign);
  if (participating) {
    return 'participating';
  } else if (negotiating) {
    return 'negotiating';
  } else if (invited) {
    return 'invited';
  } else {
    return 'available';
  }
}

const TAG_TAB_PREFIX = 'TAG_FILTER_TAB_';

export function isTagFilterTab(tabId: ?string) {
  return (tabId || '').startsWith(TAG_TAB_PREFIX);
}

export function tagToTabId(tag: string) {
  return TAG_TAB_PREFIX + tag;
}

export function tagFromTabId(tabId: string) {
  // TODO: ensure this replaces only from string's beginning
  return tabId.replace(TAG_TAB_PREFIX, '');
}
