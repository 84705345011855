import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import find from 'lodash/find';

import AgeGroupChart from '../../common/Graphs/AgeGroupChart';
import CountryChart from '../../common/Graphs/CountryChart';
import FormattedNumber from '../../common/FormattedNumber';

import AndroidIcon from '../../common/Icons/AndroidIcon';
import AppleIcon from '../../common/Icons/AppleIcon';
import FemaleIcon from '../../common/Icons/FemaleIcon';
import LaptopIcon from '../../common/Icons/LaptopIcon';
import MaleIcon from '../../common/Icons/MaleIcon';

import './ChannelInfo.scss';

const GRAPH_HEIGHT = 140; // 10rem
const computerPlatforms = ['MACINTOSH', 'WINDOWS', 'LINUX'];

const ChannelInfo = ({ channel }) => {
  const intl = useIntl();

  const genders =
    channel.demographics.gender &&
    channel.demographics.gender.reduce((acc, e) => ({ ...acc, [e.name]: e.value }), {});
  const osSplit = channel.demographics.operatingSystem;
  const ios = find(osSplit, { name: 'IOS' }) || 0;
  const android = find(osSplit, { name: 'ANDROID' }) || 0;
  const computers = osSplit.reduce((rate, platform) => {
    if (computerPlatforms.indexOf(platform.name) === -1) return rate;
    return rate + platform.value;
  }, 0);
  return (
    <div className="ChannelInfo">
      <div key="name" className="ChannelInfo__table-row">
        <div className="ChannelInfo__table-left">
          <FormattedMessage id="campaign.report.info.name" tagName="strong" />
        </div>
        <div className="ChannelInfo__table-right">
          <strong className="ChannelInfo__channel-name">{channel.name}</strong>
        </div>
      </div>
      <div key="avatar" className="ChannelInfo__table-row">
        <div className="ChannelInfo__table-left" />
        <div className="ChannelInfo__table-right image is-circle">
          <img referrerPolicy="no-referrer" src={channel.avatarUrl} alt="Channel logo" />
        </div>
      </div>
      <div className="ChannelInfo__table-row">
        <div className="ChannelInfo__table-left">
          <FormattedMessage id="campaign.report.channels.channelUrl" tagName="strong" />
        </div>
        <div className="ChannelInfo__table-right">
          <a
            href={intl.formatMessage(
              { id: 'campaign.report.channels.youtubeUrl' },
              { channelId: channel.id }
            )}
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage
              id="campaign.report.channels.youtubeUrl"
              values={{ channelId: channel.id }}
            />
          </a>
        </div>
      </div>
      {channel.demographics.ageGroup && !!channel.demographics.ageGroup.length && (
        <div className="ChannelInfo__table-row">
          <div className="ChannelInfo__table-left">
            <FormattedMessage id="campaign.report.channels.age" tagName="strong" />
          </div>
          <div className="ChannelInfo__table-right ChannelInfo__graph">
            <AgeGroupChart ageGroup={channel.demographics.ageGroup} height={GRAPH_HEIGHT} />
          </div>
        </div>
      )}
      {channel.demographics.country && !!channel.demographics.country.length && (
        <div className="ChannelInfo__table-row">
          <div className="ChannelInfo__table-left">
            <FormattedMessage id="campaign.report.channels.country" tagName="strong" />
          </div>
          <div className="ChannelInfo__table-right ChannelInfo__graph">
            <CountryChart country={channel.demographics.country} height={GRAPH_HEIGHT} />
          </div>
        </div>
      )}
      {genders && (
        <div className="ChannelInfo__table-row">
          <div className="ChannelInfo__table-left">
            <FormattedMessage id="campaign.report.channels.gender" tagName="strong" />
          </div>
          <div className="ChannelInfo__table-right ChannelInfo__metrics">
            <div className="ChannelInfo__metric">
              <MaleIcon isActive />
              <FormattedNumber type="percentage" value={genders.male / 100} fractionDigits={1} />
            </div>
            <div className="ChannelInfo__metric">
              <FemaleIcon isActive />
              <FormattedNumber type="percentage" value={genders.female / 100} fractionDigits={1} />
            </div>
          </div>
        </div>
      )}
      {osSplit && (
        <div className="ChannelInfo__table-row">
          <div className="ChannelInfo__table-left">
            <FormattedMessage id="campaign.report.channels.platform" tagName="strong" />
          </div>
          <div className="ChannelInfo__table-right ChannelInfo__metrics">
            <div className="ChannelInfo__metric">
              <AppleIcon isActive />
              <FormattedNumber type="percentage" value={ios.value / 100} fractionDigits={1} />
            </div>
            <div className="ChannelInfo__metric">
              <AndroidIcon isActive />
              <FormattedNumber type="percentage" value={android.value / 100} fractionDigits={1} />
            </div>
            <div className="ChannelInfo__metric">
              <LaptopIcon isActive />
              <FormattedNumber type="percentage" value={computers / 100} fractionDigits={1} />
            </div>
          </div>
        </div>
      )}

      <div key="subscriberCount" className="ChannelInfo__table-row">
        <div className="ChannelInfo__table-left">
          <FormattedMessage id="campaign.report.channels.subscriber" tagName="strong" />
        </div>
        <div className="ChannelInfo__table-right">
          <FormattedNumber type="number" value={channel.subscriberCount} />
        </div>
      </div>
      <div key="viewCount" className="ChannelInfo__table-row">
        <div className="ChannelInfo__table-left">
          <FormattedMessage id="campaign.report.channels.totalViews" tagName="strong" />
        </div>
        <div className="ChannelInfo__table-right">
          <FormattedNumber type="number" value={channel.viewCount} />
        </div>
      </div>
    </div>
  );
};

export default ChannelInfo;
