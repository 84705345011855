// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import type { Estimates } from '../../../types/estimates.flow';
import type { EstimatesGameType } from '../../../types/game.flow';

//$FlowFixMe
import { useAsync } from 'react-async';

import { authorizedFetch } from '../../../helpers/api';

import config from '../../../config';

import {
  CampaignType as CampaignTypeEnum,
  ContentPlatform as ContentPlatformEnum
} from '@sharkpunch/matchmade-common/campaign';
import { ContentSubmissionStatusEnum, DealItemStatus } from '../../../constants';
import {
  INVITED_TO_JOIN,
  SETTLED,
  getCpiFromAgreement,
  getCpmFromAgreement,
  getDealType,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement,
  influencerActions,
  publisherActions
} from '@sharkpunch/matchmade-common/campaignAgreement';
import { calculateYoutubePrices } from '@sharkpunch/matchmade-common/money';
import type { CampaignAgreementStatus } from '../../../types/campaignAgreement.flow';

import { VideoTabForCampaignCard } from '../../common/TabComponents/VideoTab';
import { checkIfInfluencerManager } from '../../../helpers/user';
import { getEstimatedInstallsFromEstimates } from '../../../helpers/estimates';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { getViewsFromEstimates } from '../../../helpers/youtube';
import { useCampaignDescriptionModal } from '../../modals/CampaignDescriptionModal';
import { useModifyOfferOverlay } from '../../common/ModifyOfferOverlay';
import Button, { Color } from '../../common/Button';
import CampaignCardHeader from '../common/CampaignCardHeader';
import Card from '../../common/Card';
import CheckForLatestAgreement from '../../campaignAgreement/CheckForLatestAgreement';
import CheckIcon from '../../common/Icons/CheckIcon';
import EditIcon from '../../common/Icons/EditIcon';
import GameInfo from '../../common/TabComponents/GameInfo';
import GamepadIcon from '../../common/Icons/GamepadIcon';
import ImagesIcon from '../../common/Icons/ImagesIcon';
import InfoIcon from '../../common/Icons/InfoIcon';
import LatestOfferTab from '../../common/TabComponents/LatestOfferTab';
import MessagesIcon from '../../common/Icons/MessagesIcon';
import MessagesTab from '../../common/TabComponents/MessagesTab';
import NegotiationIcon from '../../common/Icons/NegotiationIcon';
import PayoutsTab from '../../common/TabComponents/PayoutsTab';
import PublishingWindowModal from '../../modals/PublishingWindowModal';
import ScreenshotsTab from '../../common/TabComponents/ScreenshotsTab';
import SendIcon from '../../common/Icons/SendIcon';
import TabsWithContent from '../../common/TabsWithContent';
import VideoIcon from '../../common/Icons/VideoIcon';
import type { Game } from '../../../types/game.flow';

import { getAvailableDealTypes } from '../../../helpers/campaign';

import '../common/CampaignCard.scss';

let contentSubmissionWindow = null;

const getActionButton = (
  agreement,
  influencerId,
  isLoading,
  disabled,
  onAcceptCampaign,
  showModalToAcceptOffer,
  isNegotiatingCampaign,
  isCharityCampaign,
  canNegotiateOffer,
  hasPublishingWindow,
  setIsPublishingWindowOpen,
  contentSubmission
) => {
  const isOfferFromInfluencer = influencerActions.indexOf(agreement.status) !== -1;
  const isOfferFromPublisher = publisherActions.indexOf(agreement.status) !== -1;

  let acceptMessageId = 'influencer.campaign.accept';
  if (isCharityCampaign) {
    acceptMessageId = 'influencer.campaign.makeOffer.charity';
  }

  switch (true) {
    case isOfferFromPublisher && !canNegotiateOffer: {
      return (
        <Button
          key="accept"
          color={Color.SUCCESS}
          disabled={disabled}
          className="Button__main-action"
          onClick={() => {
            if (hasPublishingWindow) {
              setIsPublishingWindowOpen(true);
            } else {
              showModalToAcceptOffer({ ...agreement, influencerId });
            }
          }}
          loading={isLoading}>
          <FormattedMessage tagName="strong" id={acceptMessageId} />
        </Button>
      );
    }
    case isOfferFromPublisher: {
      return (
        <Button
          key="accept"
          color={Color.PRIMARY}
          disabled={disabled}
          className="Button__main-action"
          onClick={() =>
            isNegotiatingCampaign
              ? onAcceptCampaign({ ...agreement, influencerId })
              : showModalToAcceptOffer({ ...agreement, influencerId })
          }
          loading={isLoading}>
          <FormattedMessage tagName="strong" id={acceptMessageId} />
        </Button>
      );
    }
    case isOfferFromInfluencer: {
      return (
        <Button
          key="all-done"
          className="Button__main-action"
          disabled
          onClick={() => {}}
          loading={isLoading}>
          <FormattedMessage tagName="strong" id="influencer.card.actions.offerSent" />
          <SendIcon />
        </Button>
      );
    }
    case agreement.status === SETTLED: {
      if (
        contentSubmission &&
        [
          ContentSubmissionStatusEnum.PENDING,
          ContentSubmissionStatusEnum.REQUEST_FOR_CHANGES
        ].includes(contentSubmission.status)
      ) {
        const message =
          contentSubmission.status === ContentSubmissionStatusEnum.PENDING
            ? 'Submit draft'
            : 'Re-submit draft';
        const color =
          contentSubmission.status === ContentSubmissionStatusEnum.PENDING
            ? Color.PRIMARY
            : Color.WARNING;
        return (
          <Button
            key="all-done"
            className="Button__main-action"
            color={color}
            onClick={() => {
              if (!contentSubmissionWindow || contentSubmissionWindow.closed) {
                contentSubmissionWindow = window.open(contentSubmission.url);
              } else {
                contentSubmissionWindow.focus();
              }
            }}
            loading={isLoading}>
            <span className="mr-2">{message}</span>
            <SendIcon />
          </Button>
        );
      }

      return (
        <Button
          key="all-done"
          className="Button__main-action"
          disabled
          onClick={() => {}}
          loading={isLoading}>
          <FormattedMessage tagName="strong" id="influencer.card.actions.dealMade" />
          <CheckIcon />
        </Button>
      );
    }
    default:
      return null;
  }
};

// We do this in two steps; first get content submission id by agreement id,
// and then get actual content submission object via proxy API.
//
// While we probably can do the same inside first API, this will unnecessarily
// complicate it; this way we re-use the same APIs other services are using already.
export const getContentSubmission = async ({
  agreementId,
  agreementStatus,
  isAdmin
}: {
  agreementId: number,
  agreementStatus: CampaignAgreementStatus,
  isAdmin?: boolean
}) => {
  // This makes sense only for settled agreements
  if (agreementStatus !== SETTLED) {
    return null;
  }

  const res = await authorizedFetch(`/agreements/${agreementId}/content-submission-task-id`);

  if (!res.ok || res.status > 400) {
    return null;
  }

  const json = await res.json();
  const id = json.data;
  if (!id) {
    return null;
  }

  const getTaskUrl = isAdmin
    ? `/admin/content-submission-tasks/v1/content-submission-task/${id}`
    : `/content-submission-tasks/v1/content-submission-task/${id}`;

  const contentSubmissionTaskRes = await authorizedFetch(getTaskUrl);

  if (!contentSubmissionTaskRes.ok || !contentSubmissionTaskRes.status === 200) {
    console.warn(`Didn't get anything for content submission task [${id}]`);
    return null;
  }

  const { data: task } = await contentSubmissionTaskRes.json();
  return {
    deadline: task.deadline,
    status: task.status,
    url: isAdmin
      ? `${config('bulkOfferTool.url')}/content-submissions/${task.id}`
      : `${config('creatorApp.url')}/content-submission/${task.id}`
  };
};

export type Props = {
  defaultTab?: number,
  influencer: any,
  campaign: any,
  agreement: any,
  onNegotiateCampaign: (agreement: any) => void,
  onAcceptCampaign: (agreement: any) => void,
  onShowDeclineOfferModal: (agreement: any) => void,
  isLoading: boolean,
  isNegotiatingCampaign?: boolean,
  hideCpi: boolean,
  estimates?: Estimates,
  gameType: EstimatesGameType,
  disabled?: boolean,
  game: Game,

  showVideoTab?: boolean,
  showMessagesTab?: boolean
};

type State = {
  fullHeight: boolean,
  seenRefreshWarning: boolean,
  acceptedAgreement?: any,
  offer?: {
    cpi: number,
    minGuarantee: number,
    deadline: string,
    promotionType: string,
    contentPlatform: string,
    freeOfCharge: boolean
  }
};

let CampaignParticipatingCard = (props: Props) => {
  const {
    campaign,
    influencer,
    agreement,
    disabled,
    game,
    estimates,
    gameType,
    hideCpi,
    defaultTab = 2,
    isLoading = false,
    isNegotiatingCampaign = false,
    showMessagesTab = true,
    showVideoTab = true,

    onAcceptCampaign,
    onNegotiateCampaign
  } = props;

  const { data: contentSubmission, isPending: getContentSubmissionPending } = useAsync({
    promiseFn: getContentSubmission,
    agreementId: agreement.id,
    agreementStatus: agreement.status,
    // (Re-)fetch content submission when agreement status changes
    watchFn: (props, prevProps) => props.agreementStatus !== prevProps.agreementStatus
  });

  const [state, setState] = React.useState<State>({
    fullHeight: false,
    seenRefreshWarning: false
  });

  const [isPublishingWindowOpen, setIsPublishingWindowOpen] = React.useState(false);

  const [
    shouldCampaignDescriptionModalSendOffer,
    setShouldCampaignDescriptionModalSendOffer
  ] = React.useState(false);

  const isAgreementInNegotiation = agreement.status && agreement.status !== INVITED_TO_JOIN;

  const dealItem = (agreement.dealItems || []).filter(dealItem => {
    return dealItem.published;
  })[0];

  const campaignVideoId = dealItem && dealItem.contentId;
  const campaignVideoPublished = !!dealItem;

  const cpi = getCpiFromAgreement(agreement);
  const cpm = getCpmFromAgreement(agreement);
  const minGuarantee = getMinGuaranteeFromAgreement(agreement);
  const maxPayment = getMaxPaymentFromAgreement(agreement);
  const infoIconText = 'influencer.campaign.info';
  const isVideoDue = moment(agreement.deadline).isBefore(moment(), 'day');
  const isFixedFee = !cpi && !!minGuarantee;
  const lastMessage = agreement.lastMessage || {};
  const promotionType = agreement.promotionType || campaign.defaultPromotionType;
  const estimatedInstalls = getEstimatedInstallsFromEstimates(estimates, promotionType, gameType);
  const campaignLink = checkIfInfluencerManager()
    ? `/campaigns/${campaign.id}/influencers/${influencer.id}`
    : `/campaigns/${campaign.id}`;

  const showModalToSendOffer = ({
    cpi,
    minGuarantee,
    deadline,
    promotionType,
    contentPlatform,
    freeOfCharge
  }) => {
    setCampaignDescriptionModalVisibility(true);
    setShouldCampaignDescriptionModalSendOffer(true);
    setState({
      ...state,
      offer: {
        cpi: cpi || 0,
        minGuarantee: minGuarantee || 0,
        deadline,
        promotionType,
        contentPlatform,
        freeOfCharge
      }
    });
  };

  const onMakeOffer = ({
    cpi,
    cpm,
    minGuarantee,
    maxPayment,
    deadline,
    promotionType,
    contentPlatform,
    freeOfCharge
  }) => {
    showModifyOfferOverlay(false);
    return onNegotiateCampaign({
      campaignId: campaign.id,
      influencerId: influencer.id,
      influencerCpi: cpi,
      influencerCpm: cpm,
      influencerMinGuarantee: minGuarantee,
      influencerMaxPayment: maxPayment,
      promotionType,
      contentPlatform,
      deadline,
      logId: agreement.logId,
      freeOfCharge
    });
  };

  const onMakeOfferFromModal = () => {
    if (state.acceptedAgreement) {
      return onAcceptCampaign(state.acceptedAgreement);
    }
    if (!state.offer) return;
    const {
      cpi,
      minGuarantee,
      deadline,
      promotionType,
      contentPlatform,
      freeOfCharge
    } = state.offer;
    onMakeOffer({
      cpi,
      cpm: 0,
      minGuarantee,
      maxPayment: null,
      deadline,
      promotionType,
      contentPlatform,
      freeOfCharge
    });
  };

  const makeOfferOrShowModal = isAgreementInNegotiation ? onMakeOffer : showModalToSendOffer;

  const onShowDeclineOfferModal = agreement => () =>
    props.onShowDeclineOfferModal({
      influencerId: influencer.id,
      campaignId: agreement.campaignId,
      logId: agreement.logId
    });

  const [
    campaignDescriptionModal,
    setCampaignDescriptionModalVisibility
  ] = useCampaignDescriptionModal({
    onAccept: onMakeOfferFromModal,

    campaignId: campaign.id,
    description: campaign.description,
    talkingPoints: campaign.talkingPoints,
    promotionTypes: campaign.promotionTypes,
    campaignType: campaign.campaignType,

    isLoading: isLoading || isNegotiatingCampaign,

    shouldSendOffer: shouldCampaignDescriptionModalSendOffer
  });

  const { channel, contentPlatform: channelContentPlatform } =
    getFirstAvailableContentPlatform(
      influencer,
      agreement.contentPlatform || campaign.defaultContentPlatform
    ) || {};
  const dealTypes = getAvailableDealTypes(
    agreement.contentPlatform || campaign.defaultContentPlatform
  );

  const [modifyOfferOverlay, showModifyOfferOverlay] = useModifyOfferOverlay({
    isHorizontal: true,
    cpi,
    cpm,
    minGuarantee,
    maxPayment,
    deadline: agreement.deadline,
    onShowInfoForInfluencer: () => {
      setCampaignDescriptionModalVisibility(true);
      setShouldCampaignDescriptionModalSendOffer(false);
    },

    isLoading,

    onMakeOffer: makeOfferOrShowModal,
    onShowDeclineOfferModal: onShowDeclineOfferModal(agreement),

    promotionType: agreement.promotionType || campaign.defaultPromotionType,
    promotionTypes: campaign.promotionTypes,
    defaultPromotionType: campaign.defaultPromotionType,

    contentPlatform: agreement.contentPlatform || campaign.defaultContentPlatform,
    contentPlatforms: campaign.contentPlatforms,
    defaultContentPlatform: campaign.defaultContentPlatform,

    dealTypes,
    hidePromotionType: channelContentPlatform === ContentPlatformEnum.INSTAGRAM,

    campaignType: campaign.campaignType,
    estimates,
    gameType,
    hideCpi,
    avatarUrl: game.logoUrl,

    checkLatestLog: false,
    campaignId: campaign.id,
    influencerId: influencer.id
  });

  if (!channel) return null;

  const publisher = game.publisherName;
  const actor = influencerActions.indexOf(agreement.status) !== -1 ? channel.name : publisher;

  const estimatedViews =
    channelContentPlatform === ContentPlatformEnum.YOUTUBE
      ? getViewsFromEstimates(channel.estimates)
      : 0;

  const dealType = getDealType({
    cpi,
    cpm,
    freeOfCharge: agreement.freeOfCharge
  });

  const rootClass = classNames('CampaignCard', {
    'CampaignCard--full-height': state.fullHeight
  });

  const showModalToAcceptOffer = acceptedAgreement => {
    setCampaignDescriptionModalVisibility(true);
    setShouldCampaignDescriptionModalSendOffer(true);
    setState({
      ...state,
      acceptedAgreement
    });
  };

  const isSettled = agreement.status === SETTLED;
  const canNegotiateOffer = !(isSettled || agreement.takeItOrLeaveIt);
  const hasBudgetLock = !!agreement.budgetId;
  const hasPublishingWindow =
    agreement.publishingWindow &&
    agreement.publishingWindow.start !== agreement.publishingWindow.end;

  const actions = [
    getActionButton(
      agreement,
      influencer.id,
      isLoading || getContentSubmissionPending,
      disabled,
      onAcceptCampaign,
      showModalToAcceptOffer,
      isAgreementInNegotiation,
      campaign.campaignType === CampaignTypeEnum.CHARITY,
      canNegotiateOffer,
      hasPublishingWindow,
      setIsPublishingWindowOpen,
      contentSubmission
    ),
    canNegotiateOffer ? (
      <Button
        size="large"
        buttonClassName="CampaignParticipatingCard__modify"
        color={Color.PRIMARY}
        key="decline"
        onClick={() => {
          showModifyOfferOverlay(true);
        }}
        disabled={disabled}
        loading={isLoading}>
        <EditIcon />
      </Button>
    ) : !isSettled ? (
      <Button
        buttonClassName="CampaignParticipatingCard__decline"
        color={Color.DANGER}
        key="modify"
        onClick={onShowDeclineOfferModal(agreement)}
        disabled={disabled}
        loading={isLoading}>
        <FormattedMessage id="actions.decline" tagName="strong" />
      </Button>
    ) : null,
    <Button
      size="large"
      color={Color.WHITE}
      loading={isLoading}
      key="campaign-info"
      onClick={() => {
        setCampaignDescriptionModalVisibility(true);
        setShouldCampaignDescriptionModalSendOffer(false);
      }}>
      <InfoIcon tooltipI18nString={infoIconText} withTooltip={!!infoIconText} />
    </Button>
  ].filter(Boolean);

  return [
    <Card key={`card-${campaign.id}`} className={rootClass}>
      <CampaignCardHeader
        logoUrl={game.logoUrl}
        moneyInCents={
          campaignVideoId
            ? agreement.payout
            : calculateYoutubePrices({
                promotionType,
                dealType,
                estimatedViews,
                estimatedInstalls,
                minGuarantee,
                cpi,
                cpm,
                maxPayment
              }).currentPrice
        }
        hasPublishedVideo={!!campaignVideoId}
        isFixedFee={isFixedFee}
        campaignLink={campaignLink}
        campaignName={campaign.name}
        contentPlatform={agreement.contentPlatform}
        campaignAgreementId={agreement.id}
      />
      <TabsWithContent
        horizontal
        defaultTab={defaultTab}
        actionsHidden={state.fullHeight}
        actions={actions}
        data={[
          {
            icon: <GamepadIcon />,
            content: <GameInfo game={game} />,
            onSelect: () => setState({ ...state, fullHeight: false })
          },
          {
            icon: <ImagesIcon />,
            content: <ScreenshotsTab screenshots={game.screenshots} />,
            onSelect: () => setState({ ...state, fullHeight: true })
          },
          {
            icon: <NegotiationIcon />,
            content: (
              <LatestOfferTab
                isTakeItOrLeaveIt={agreement.takeItOrLeaveIt}
                hasBudgetLock={hasBudgetLock}
                agreementDeadline={agreement.deadline}
                agreementStatus={agreement.status}
                promotionType={agreement.promotionType}
                contentPlatform={agreement.contentPlatform}
                cpi={cpi}
                cpm={cpm}
                minGuarantee={minGuarantee}
                maxPayment={maxPayment}
                freeOfCharge={agreement.freeOfCharge || false}
                showCost={false}
                showInfluencerEarnings={true}
                showEffectiveCpm={false}
                showPromotionType={channelContentPlatform !== ContentPlatformEnum.INSTAGRAM}
                publishingWindow={agreement.publishingWindow}
              />
            ),
            onSelect: () => setState({ ...state, fullHeight: false })
          },
          showMessagesTab && {
            icon: <MessagesIcon />,
            content: (
              <MessagesTab
                content={lastMessage.content}
                lastMessageRole={lastMessage.role}
                senderName={lastMessage.senderName}
                senderAvatarUrl={lastMessage.senderAvatarUrl}
                created={lastMessage.created}
                agreementId={agreement.id}
                noMessageAvatar={game.logoUrl}
                chatClosed={agreement.chatClosed}
              />
            ),
            onSelect: () => setState({ ...state, fullHeight: false })
          },
          showVideoTab &&
            agreement.status === SETTLED && {
              icon: <VideoIcon showAlert={!campaignVideoId && isVideoDue} />,
              content: (
                <VideoTabForCampaignCard
                  contentPlatform={agreement.contentPlatform}
                  videoId={campaignVideoId}
                  lastVideoPublishedAt={campaignVideoPublished}
                  trackingCode={agreement.trackingCode}
                  deadline={agreement.deadline}
                  agreementId={agreement.id}
                  isPublic={dealItem && dealItem.status === DealItemStatus.PUBLISHED}
                  contentSubmission={contentSubmission}
                />
              ),
              onSelect: () => setState({ ...state, fullHeight: false })
            },
          agreement.payoutTaskId && {
            icon: <span className="material-icons">monetization_on</span>,
            content: <PayoutsTab agreementId={agreement.id} />,
            onSelect: () => setState({ ...state, fullHeight: false })
          }
        ].filter(Boolean)}
      />
      {!disabled && (
        <CheckForLatestAgreement
          className="ModifyOfferOverlay"
          actor={actor}
          onRefresh={() => setState({ ...state, seenRefreshWarning: true })}
          agreementId={agreement.id}>
          {modifyOfferOverlay}
        </CheckForLatestAgreement>
      )}
    </Card>,
    campaignDescriptionModal,

    hasPublishingWindow && (
      <PublishingWindowModal
        key={`publishing-window-modal-${campaign.id}`}
        isOpen={isPublishingWindowOpen}
        onClose={() => setIsPublishingWindowOpen(false)}
        onConfirm={chosenPublishingDate => {
          setIsPublishingWindowOpen(false);
          if (chosenPublishingDate) {
            agreement.deadline = chosenPublishingDate;
          }
          showModalToAcceptOffer({ ...agreement, influencerId: influencer.id });
        }}
        publishingWindow={agreement.publishingWindow}
      />
    )
  ];
};

export default CampaignParticipatingCard;
