import React from 'react';

const TabsIconLatestMessageWhite = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M14.639 22.56a14.012 14.012 0 0 1-5.904 3.151C8.273 25.841 7 26 7 26c1.25-1.932 2-3.335 2.338-5.145C6.694 19.332 5 16.975 5 14.332 5 9.737 10.147 6 16.5 6S28 9.724 28 14.332c0 4.61-5.147 8.333-11.5 8.333-.629 0-1.258-.04-1.861-.104z"
    />
  </svg>
);

export default TabsIconLatestMessageWhite;
