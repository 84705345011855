// @flow

import * as React from 'react';
import InfoTooltip from '../../common/InfoTooltip';

import './AdvancedSettingsToggle.scss';

type Props = {
  isOpen?: boolean,
  onToggle: () => void,

  label: React.Element<*>,
  intro: React.Element<*>,
  tooltip?: React.Element<*>
};

export default function AdvancedSettingsToggle(props: Props) {
  return (
    <div className="field is-horizontal no-margin-bottom AdvancedSettingsToggle">
      <div className="field-label">
        <label className="label">{props.label}</label>
        {props.tooltip && <InfoTooltip>{props.tooltip}</InfoTooltip>}
      </div>
      <div className="field-body">
        <div className="field AdvancedSettingsToggle__toggle">
          <div
            onClick={props.onToggle}
            className={`AdvancedSettingsToggle${props.isOpen ? '--open' : '--close'}`}>
            <div className="AdvancedSettingsToggle__intro">{props.intro}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
