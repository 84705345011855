// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PromotionType as PromotionTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import type { PromotionType } from 'matchmade-types';

import InfoTooltip from '../../common/InfoTooltip';
import MultiSelectButtonWithDefault from '../common/MultiSelectButtonWithDefault';
import Tooltip from '../../common/Tooltip';
import type { Option } from '../common/MultiSelectButtonWithDefault';

import './PromotionTypes.scss';

type Props = {
  isDisabled: boolean,
  values: PromotionType[],
  defaultValue: PromotionType | null,
  onChangeValues: (values: PromotionType[]) => void,
  onChangeDefaultValue: (value: PromotionType | null) => void
};

const options: Option<PromotionType>[] = [
  PromotionTypeEnum.DEDICATED,
  PromotionTypeEnum.INTEGRATION
].map(opt => {
  return {
    value: opt,
    label: <FormattedMessage id={`campaignCreation.campaignDescription.promotionTypes.${opt}`} />
  };
});

const PromotionTypes = ({
  isDisabled = false,
  values,
  defaultValue,
  onChangeDefaultValue,
  onChangeValues
}: Props) => {
  return (
    <div className="field is-horizontal PromotionTypes">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.campaignDescription.promotionTypes" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.campaignDescription.promotionTypes.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <Tooltip
          tooltip={
            isDisabled ? (
              <FormattedMessage id="campaignCreation.campaignDescription.promotionTypes.disabled.tooltip" />
            ) : null
          }>
          <div className="field is-horizontal">
            <MultiSelectButtonWithDefault
              className="control"
              options={options}
              disabled={isDisabled}
              values={values}
              defaultValue={defaultValue}
              onChangeValues={onChangeValues}
              onChangeDefaultValue={onChangeDefaultValue}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default PromotionTypes;
