import { FormattedMessage } from 'react-intl';
import React from 'react';

import { checkIfWhitelabelAdmin } from '../../helpers/user';
import { generateApiPath } from '../../config';
import Button, { Color } from '../common/Button';
import Modal from '../common/Modal';

import './SessionExpiredConfirmation.scss';

const SessionExpiredConfirmation = props => {
  const { isOpen } = props;
  const redirectUrl = checkIfWhitelabelAdmin()
    ? generateApiPath('/auth/delete-legacy-token')
    : '/auth';
  const backToSession = () => (window.location.href = redirectUrl);

  return (
    <Modal isOpen={isOpen} isClosable={false} className="SessionExpiredConfirmation">
      <header className="modal-card-head">
        <h3>
          <FormattedMessage id="modal.expiredSession.confirmation.header" tagName="strong" />
        </h3>
        <FormattedMessage id="modal.expiredSession.confirmation.body" tagName="div" />
      </header>
      <footer className="modal-card-foot">
        <Button color={Color.PRIMARY} size="large" onClick={backToSession}>
          <FormattedMessage
            id={
              checkIfWhitelabelAdmin()
                ? 'modal.expiredSession.confirmation.admin.continue'
                : 'auth.login'
            }
          />
        </Button>
      </footer>
    </Modal>
  );
};

export default SessionExpiredConfirmation;
