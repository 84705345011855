// @flow
import * as React from 'react';

import type { IntlShape } from 'react-intl';

import UrlControlInput from '../../common/UrlControlInput';
import PlatformSpecificInputs from './PlatformSpecificInputs';
import type { PlatformSpecificTrackingCodes } from './PlatformSpecificInputs';

import { FormattedMessage } from 'react-intl';

import type { AttributionPartnerOption } from './AttributionPartnerOption';
import { AttributionPartnerOptionEnum } from './AttributionPartnerOption';

type Props = {
  showPlatformSpecificSettings: boolean,

  trackingCode: ?string,
  onChangeTrackingCode: (value: string | null) => void,

  platformSpecificTrackingCodes: PlatformSpecificTrackingCodes,
  onChangePlatformSpecificTrackingCodes: (value: PlatformSpecificTrackingCodes | null) => void,

  selectedOption: AttributionPartnerOption,
  onChangeOption: (opt: AttributionPartnerOption) => void,

  isDisabled: boolean,
  isInverted: boolean,

  intl: IntlShape
};

export default function MatchmadeLink(props: Props) {
  return (
    <div className="ServiceWithOneTrackingUrlAndInstruction InstallAttribution__attribution-partner">
      <label className="label">
        <FormattedMessage
          id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadeLink.label`}
        />
      </label>
      <div className="control">
        <label className="radio">
          <input
            disabled={props.isDisabled}
            onChange={() => {
              if (props.isDisabled) return;
              props.onChangeOption(AttributionPartnerOptionEnum.URL);
              props.onChangePlatformSpecificTrackingCodes(null);
            }}
            checked={props.selectedOption === AttributionPartnerOptionEnum.URL}
            type="radio"
            name="option"
          />
          <span>Same link for all users</span>
        </label>
        <label className="radio">
          <input
            disabled={props.isDisabled}
            onChange={() => {
              if (props.isDisabled) return;
              props.onChangeOption(AttributionPartnerOptionEnum.PLATFORM_SPECIFIC);
              props.onChangeTrackingCode(null);
            }}
            checked={props.selectedOption === AttributionPartnerOptionEnum.PLATFORM_SPECIFIC}
            type="radio"
            name="option"
          />
          <span>Different link for users on Android, iOS, and all others</span>
        </label>
      </div>
      {props.selectedOption === AttributionPartnerOptionEnum.URL && (
        <UrlControlInput
          isInverted={props.isInverted}
          value={props.trackingCode}
          onChange={props.onChangeTrackingCode}
        />
      )}
      {props.selectedOption === AttributionPartnerOptionEnum.PLATFORM_SPECIFIC && (
        <PlatformSpecificInputs
          platforms={['android', 'ios', 'default']}
          isInverted={props.isInverted}
          values={props.platformSpecificTrackingCodes}
          isDisabled={props.isDisabled}
          onChange={props.onChangePlatformSpecificTrackingCodes}
        />
      )}
    </div>
  );
}
