// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import FormattedNumber from '../common/FormattedNumber';
import Spinner from '../common/Spinner';

import './CampaignTotalCost.scss';

type Props = {
  cost: ?number,
  title: string | React.Element<*>,
  isLoading: boolean
};

const CampaignTotalCost = (props: Props) => {
  const { cost, title, isLoading } = props;
  if (cost == null || isLoading) {
    return (
      <div className="CampaignTotalCost">
        <Spinner mode="inline" />
      </div>
    );
  }

  const totalCost = cost ? (
    <strong>
      <FormattedNumber type="cost" value={cost} />
    </strong>
  ) : null;
  const totalCostBlock = totalCost ? (
    <div className="CampaignTotalCost__cost">
      <FormattedMessage id="chat.campaignInfo.totalCost" values={{ cost: totalCost }} />
    </div>
  ) : null;

  return (
    <div className="CampaignTotalCost">
      <p className="CampaignTotalCost__title">{title}</p>
      {totalCostBlock}
    </div>
  );
};

export default CampaignTotalCost;
