// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';

import classNames from 'classnames';

import Card from '../../common/Card';
import GavelIcon from '../../common/Icons/GavelIcon';
import TabsWithContent from '../../common/TabsWithContent';

import { useModifyOfferOverlay } from '../../common/ModifyOfferOverlay';

import { getAvailableDealTypes } from '../../../helpers/campaign';

import GameInfo from '../../common/TabComponents/GameInfo';

import { checkIfInfluencerManager, checkIfManagedInfluencer } from '../../../helpers/user';

import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';

import Button, { Color } from '../../common/Button';
import CampaignCardHeader from '../common/CampaignCardHeader';
import GamepadIcon from '../../common/Icons/GamepadIcon';
import ImagesIcon from '../../common/Icons/ImagesIcon';
import InfoIcon from '../../common/Icons/InfoIcon';
import ScreenshotsTab from '../../common/TabComponents/ScreenshotsTab';

import injectCampaignDescriptionModalController from '../../common/Card/injectCampaignDescriptionModalController';

import type { EstimatesGameType } from '../../../types/game.flow';

import {
  CampaignType,
  ContentPlatform as ContentPlatformEnum
} from '@sharkpunch/matchmade-common/campaign';

import '../common/CampaignCard.scss';

type Props = {
  defaultTab?: number,
  influencer: Object,
  user: Object,
  campaign: Object,
  onNegotiateCampaign: Function,
  isLoading: boolean,
  hideCpi: boolean,
  estimatedInstalls?: number,
  estimates: Object,
  gameType: EstimatesGameType,
  disabled: boolean,
  game: Object,

  // passed by injectCampaignDescriptionModalController
  showCampaignDescriptionModal: boolean,
  modalShouldSendOffer: boolean,
  onShowCampaignDescriptionModal: Function,
  onHideCampaignDescriptionModal: Function,
  renderCampaignDescriptionModal: Function
};

function CampaignAvailableCard(props: Props) {
  const {
    campaign,
    disabled,
    game,
    estimates,
    gameType,
    influencer,
    isLoading,
    modalShouldSendOffer,
    onShowCampaignDescriptionModal,
    onHideCampaignDescriptionModal,
    defaultTab = 0,
    hideCpi,
    renderCampaignDescriptionModal,
    onNegotiateCampaign
  } = props;

  const [fullHeight, setFullHeight] = React.useState(false);
  const [offer, setOffer] = React.useState(null);

  function onMakeOffer() {
    if (!offer) return;
    const { cpi, cpm, minGuarantee, maxPayment, deadline, promotionType, contentPlatform } = offer;
    return onNegotiateCampaign({
      campaignId: campaign.id,
      influencerId: influencer.id,
      influencerCpi: cpi,
      influencerCpm: cpm,
      influencerMaxPayment: maxPayment,
      influencerMinGuarantee: minGuarantee,
      promotionType,
      contentPlatform,
      deadline,
      freeOfCharge: campaign.campaignType === CampaignType.CHARITY
    });
  }

  function showModalToSendOffer({
    cpi,
    cpm,
    minGuarantee,
    maxPayment,
    deadline,
    promotionType,
    contentPlatform
  }) {
    showModifyOfferOverlay(false);
    onShowCampaignDescriptionModal();
    setOffer({
      cpi,
      cpm,
      minGuarantee,
      maxPayment,
      deadline,
      promotionType,
      contentPlatform
    });
  }

  let makeOfferMessageId = 'influencer.campaign.makeOffer';
  if (campaign.campaignType === CampaignType.CHARITY) {
    makeOfferMessageId = 'influencer.campaign.makeOffer.charity';
  }

  const { contentPlatform: channelContentPlatform } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};
  const dealTypes = getAvailableDealTypes(campaign.defaultContentPlatform);

  const [modifyOfferOverlay, showModifyOfferOverlay] = useModifyOfferOverlay({
    isHorizontal: true,
    initialSubmitEnabled: true,
    isLoading: isLoading,

    onMakeOffer: showModalToSendOffer,
    onShowInfoForInfluencer: () => {
      onShowCampaignDescriptionModal(false);
    },

    promotionType: campaign.defaultPromotionType,
    promotionTypes: campaign.promotionTypes,
    defaultPromotionType: campaign.defaultPromotionType,

    dealTypes,
    hidePromotionType: channelContentPlatform === ContentPlatformEnum.INSTAGRAM,

    contentPlatform: campaign.defaultContentPlatform,
    contentPlatforms: campaign.contentPlatforms,
    defaultContentPlatform: campaign.defaultContentPlatform,

    campaignType: campaign.campaignType,

    estimates: estimates,

    hideCpi: hideCpi,
    gameType,
    avatarUrl: game.logoUrl,

    checkLatestLog: false,
    campaignId: campaign.id,
    influencerId: influencer.id
  });

  const actions = [
    !checkIfManagedInfluencer() && (
      <Button
        tooltip={null}
        disabled={false}
        onClick={() => showModifyOfferOverlay(true)}
        key="invite"
        color={Color.PRIMARY}
        loading={isLoading}
        className="Button__main-action CampaignAvailableCard__make-offer-button">
        <FormattedMessage tagName="strong" id={makeOfferMessageId} />
        <GavelIcon />
      </Button>
    ),
    <Button
      size="large"
      color={Color.WHITE}
      loading={isLoading}
      key="campaign-info"
      onClick={() => {
        onShowCampaignDescriptionModal(false);
      }}>
      <InfoIcon tooltipI18nString="influencer.campaign.info" withTooltip />
    </Button>
  ].filter(Boolean);

  const rootClass = classNames('CampaignCard', {
    'CampaignCard--full-height': fullHeight
  });

  const onAccept = modalShouldSendOffer ? onMakeOffer : onHideCampaignDescriptionModal;
  const campaignLink = checkIfInfluencerManager()
    ? `/campaigns/${campaign.id}/influencers/${influencer.id}`
    : `/campaigns/${campaign.id}`;

  return [
    <Card key={`card-${campaign.id}`} className={rootClass}>
      <CampaignCardHeader
        logoUrl={game.logoUrl}
        campaignLink={campaignLink}
        campaignName={campaign.name}
        contentPlatform={campaign.defaultContentPlatform}
      />
      <TabsWithContent
        horizontal
        defaultTab={defaultTab}
        actionsHidden={fullHeight}
        actions={actions}
        data={[
          {
            icon: <GamepadIcon />,
            content: <GameInfo game={game} />,
            onSelect: () => setFullHeight(false)
          },
          {
            icon: <ImagesIcon />,
            content: <ScreenshotsTab screenshots={game.screenshots} />,
            onSelect: () => setFullHeight(true)
          }
        ]}
      />
      {!disabled && modifyOfferOverlay}
    </Card>,
    renderCampaignDescriptionModal({
      onAccept
    })
  ];
}

export default injectCampaignDescriptionModalController(CampaignAvailableCard);
