// @flow
import { Helmet } from 'react-helmet';
import React from 'react';

import { useCurrentConnections } from '../hooks/useCurrentConnections';
import ConnectYourAccounts, { DEFAULT_OPTIONS } from '../components/auth2/ConnectYourAccounts';

export default function SocialMediaAccounts() {
  const { data: getCurrentConnectionsRes, isPending, reload } = useCurrentConnections();

  return (
    <div className="container">
      <h1 className="title">Social media accounts</h1>
      <Helmet title="Social media accounts" />

      <div className="container">
        <ConnectYourAccounts
          options={DEFAULT_OPTIONS}
          connectionData={(getCurrentConnectionsRes || {}).data || []}
          isGettingConnectionData={isPending}
          onConnected={() => {
            reload();
          }}
        />
      </div>
    </div>
  );
}
