import React from 'react';

import Icon from '../../../images/icons/StoreIconAndroid';

import './Icons.scss';

const AndroidIcon = ({ isActive }) => {
  const iconStyles = isActive
    ? 'TabIcon mdTabIcon androidIcon activeTabIcon'
    : 'TabIcon mdTabIcon androidIcon';
  return (
    <span className={iconStyles}>
      <Icon width={32} height={32} />
    </span>
  );
};

export default AndroidIcon;
