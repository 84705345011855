// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedNumber from '../FormattedNumber';

import Tooltip from '../../common/Tooltip';
import compose from '../../../hoc/compose';
import injectRoleChecking, { type ExtraProps } from '../../../hoc/injectRoleChecking';

import './AnalyticsTab.scss';

type Props = {
  estimatedInstalls: number,
  averageEngagementRatio: number,
  averageViewCount: number,
  medianViewCount: number,
  estimatedViews: number,
  resonanceScore: number,
  isWhitelabelAdmin: boolean,
  hideCpi: boolean
};

const AnalyticsTab = (props: Props & ExtraProps) => {
  const {
    averageEngagementRatio,
    averageViewCount,
    medianViewCount,
    estimatedViews,
    resonanceScore,
    isWhitelabelAdmin = false
  } = props;

  const roundedResonanceScoreTo5 = Math.round((resonanceScore || 0) / 5) * 5;

  const estimatedBlock = isWhitelabelAdmin ? (
    <>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__row--title">
          <FormattedMessage id="influencer.card.admin.estimates" />
        </div>
      </div>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-name">
            <FormattedMessage id="influencer.card.admin.estimates.views" />
          </div>
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={estimatedViews} type="number" />
          </div>
        </div>
      </div>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-name">
            <Tooltip
              tooltip={
                <div className="AnalyticsTab__stat--tooltip-justify">
                  <FormattedMessage id="influencer.card.mrs.description1" tagName="p" />
                  <FormattedMessage id="influencer.card.mrs.description2" tagName="p" />
                </div>
              }>
              <span>
                <FormattedMessage id="influencer.card.mrs" />
              </span>
            </Tooltip>
          </div>
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={roundedResonanceScoreTo5} type="number" />
          </div>
        </div>
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-name">
            <FormattedMessage id="influencer.card.engagement" />
          </div>
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={averageEngagementRatio} type="percentage" />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__row--title">
          <FormattedMessage id="influencer.card.monthlyViews.est" />
        </div>
      </div>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={estimatedViews} type="number" />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className="AnalyticsTab">
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__row--title">
          <FormattedMessage id="influencer.card.monthlyViews" />
        </div>
      </div>
      <div className="AnalyticsTab__row">
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-name">
            <FormattedMessage id="influencer.card.monthlyViews.avg" />
          </div>
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={averageViewCount} type="number" />
          </div>
        </div>
        <div className="AnalyticsTab__stat has-text-centered">
          <div className="AnalyticsTab__stat-name">
            <FormattedMessage id="influencer.card.monthlyViews.med" />
          </div>
          <div className="AnalyticsTab__stat-value">
            <FormattedNumber value={medianViewCount} type="number" />
          </div>
        </div>
      </div>
      <div className="AnalyticsTab__tags">
        <div className="AnalyticsTab__tags--line" />
      </div>

      {estimatedBlock}
    </div>
  );
};

export default compose(injectRoleChecking())(AnalyticsTab);
