// Same list as in API and in data-access layer
export const SORT_OPTIONS = [
  {
    attribute: 'last30dStats.averageViewCount',
    label: 'influencer.collection.sort.last30dAvgViews'
  },
  {
    attribute: 'last30dStats.averageLikeCount',
    label: 'influencer.collection.sort.last30dAvgLikes'
  },
  {
    attribute: 'last30dStats.averageCommentCount',
    label: 'influencer.collection.sort.last30dAvgComments'
  },
  {
    attribute: 'last30dStats.averageEngagementRatio',
    label: 'influencer.collection.sort.last30dAvgEngagement'
  },
  {
    attribute: 'last30dStats.totalVideoCount',
    label: 'influencer.collection.sort.last30dTotalVideos'
  },
  {
    attribute: 'subscriberCount',
    label: 'influencer.collection.sort.subscribers'
  },
  {
    attribute: 'similarity',
    label: 'influencer.collection.sort.similarity'
  },
  {
    attribute: 'videoCount',
    label: 'influencer.collection.sort.videos'
  },
  {
    attribute: 'viewCount',
    label: 'influencer.collection.sort.views'
  },
  {
    attribute: 'name',
    label: 'influencer.collection.sort.name'
  },
  {
    attribute: 'country',
    label: 'influencer.collection.sort.country'
  }
  /*
    {
      TODO: wrap up backend support
      attribute: 'language',
      label: 'influencer.collection.sort.language'
    }
    */
];
