import React from 'react';

const StoreIconAndroid = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version={1.1}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs />
    <g id="Icon-/-Android" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M13,16.6464466 L11,16.6464466 L11,19.6464466 C11,20.1987314 10.5522847,20.6464466 10,20.6464466 C9.44771525,20.6464466 9,20.1987314 9,19.6464466 L9,16.3788822 C8.40219863,16.0330728 8,15.386729 8,14.6464466 L8,8.64644661 L16,8.64644661 L16,14.6464466 C16,15.386729 15.5978014,16.0330728 15,16.3788822 L15,19.6464466 C15,20.1987314 14.5522847,20.6464466 14,20.6464466 C13.4477153,20.6464466 13,20.1987314 13,19.6464466 L13,16.6464466 Z M6,8.64644661 C6.55228475,8.64644661 7,9.09416186 7,9.64644661 L7,14.6464466 C7,15.1987314 6.55228475,15.6464466 6,15.6464466 C5.44771525,15.6464466 5,15.1987314 5,14.6464466 L5,9.64644661 C5,9.09416186 5.44771525,8.64644661 6,8.64644661 Z M18,8.64644661 C18.5522847,8.64644661 19,9.09416186 19,9.64644661 L19,14.6464466 C19,15.1987314 18.5522847,15.6464466 18,15.6464466 C17.4477153,15.6464466 17,15.1987314 17,14.6464466 L17,9.64644661 C17,9.09416186 17.4477153,8.64644661 18,8.64644661 Z M14.2332368,4.32742334 C15.2990416,5.04597206 16,6.26435053 16,7.64644661 L8,7.64644661 C8,6.2107104 8.75642365,4.9516464 9.89248609,4.24603948 L9.25,3.60355339 C9.11192881,3.4654822 9.11192881,3.24162458 9.25,3.10355339 C9.38807119,2.9654822 9.61192881,2.9654822 9.75,3.10355339 L10.5599492,3.91350257 C11.0067737,3.7410174 11.4923427,3.64644661 12,3.64644661 C12.5638655,3.64644661 13.1004808,3.76311866 13.5870216,3.97363855 L14.4571068,3.10355339 C14.595178,2.9654822 14.8190356,2.9654822 14.9571068,3.10355339 C15.095178,3.24162458 15.095178,3.4654822 14.9571068,3.60355339 L14.2332368,4.32742334 Z M10,5.64644661 L10,6.64644661 L11,6.64644661 L11,5.64644661 L10,5.64644661 Z M13,5.64644661 L13,6.64644661 L14,6.64644661 L14,5.64644661 L13,5.64644661 Z"
        id="Combined-Shape"
        fill="#2D3767"
      />
    </g>
  </svg>
);

export default StoreIconAndroid;
