// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export default function None() {
  return (
    <FormattedMessage
      tagName="p"
      id="campaignCreation.campaignDetails.advancedSetting.installAttribution.none.instruction"
    />
  );
}
