// @flow
// $FlowFixMe
import { BroadcastChannel } from 'broadcast-channel';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import moment from 'moment';

// $FlowFixMe
import Alert from '@material-ui/lab/Alert';

// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';

// $FlowFixMe
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// $FlowFixMe
import { useIntl } from 'react-intl';

import classNames from 'classnames';
import get from 'lodash/get';

import MaintenanceWarning from '../components/maintenance/Warning';
import SessionExpiredConfirmation from '../components/modals/SessionExpiredConfirmation';
import Spinner from '../components/common/Spinner';
import TopNavigationBar from '../components/nav/TopNavigationBar';

import { clearAccessToken } from '../helpers/puppe';
import useError from '../hooks/useError';

import './Layout.scss';
import ShieldClipPath from '../images/clipPaths/ShieldClipPath';

import { getUser } from '../helpers/user';

import appleTouchIcon from '../images/themes/matchmade/apple-touch-icon.png';
import favicon from '../images/themes/matchmade/favicon-16x16.png';

type ErrorPopUpProps = {
  children?: any
};

type LayoutProps = {
  enableMessageBroadcasting?: boolean,
  children?: any,
  location: any
};

export function ErrorPopUp({ children }: ErrorPopUpProps) {
  const { errors } = useSelector(state => state.layout);
  const { clearErrors } = useError();

  const error =
    errors && errors.length ? (
      <Snackbar open onClose={clearErrors} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={clearErrors} elevation={6} variant="filled" severity="error">
          {errors.map((e, index) => {
            let { code, message, params, requestId } = e;

            // It is possible the error is actually an array of error objects. Do ask for details,
            // but it is doubtful anyone knows the answer
            if (Array.isArray(e)) {
              code = e[0].code;
              message = e[0].message;
              params = e[0].params;
              requestId = e[0].requestId;
            }

            const parsedParams = params && Object.values(params);

            // If we fail at validation step, show which params failed, otherwise
            // resort to showing just message
            const content =
              code === 40000 && parsedParams
                ? parsedParams.join(', ')
                : message || 'Something went wrong';
            return (
              <div key={content}>
                {content}
                <span style={{ display: 'block', opacity: 0.7 }} className="mt-2">
                  Error code: {requestId || 'Unknown'}
                </span>
              </div>
            );
          })}
        </Alert>
      </Snackbar>
    ) : null;

  return (
    <React.Fragment>
      {error}
      {children}
    </React.Fragment>
  );
}

export default function Layout({
  enableMessageBroadcasting = true,
  children,
  location
}: LayoutProps) {
  const [isSessionExpired, setIsSessionExpired] = React.useState(false);
  const intl = useIntl();
  const { user = null, isLoading = false } = useSelector(state => state.layout);
  const { showError } = useError();

  const _channel = React.useRef();

  function messageHandler(message) {
    const { user = null, payload = null } = message;
    const currentUser = getUser();

    if (
      user &&
      currentUser &&
      payload &&
      payload.type === 'SIGN_IN' &&
      user.id &&
      currentUser.id &&
      user.id !== currentUser.id
    ) {
      clearAccessToken();
      setIsSessionExpired(true);
    }
  }

  React.useEffect(() => {
    if (!enableMessageBroadcasting) {
      return;
    }

    // We disable message broadcasting for tests, because
    // due to the way BroadcastChannel behaves in node,
    // we have no way to properly receive it's "close" message.
    // The alternative here would be to explicitly unmount
    // component in tests and wait for some arbitrary value,
    // but I'd rather not bother with it at all :/
    //
    // See more info https://github.com/pubkey/broadcast-channel/issues/8
    _channel.current = new BroadcastChannel('user-channel');
    _channel.current.addEventListener('message', messageHandler);

    return () => {
      if (!enableMessageBroadcasting) {
        return;
      }

      _channel.current && _channel.current.removeEventListener('message', messageHandler);
      _channel.current && _channel.current.close();
    };
  }, [enableMessageBroadcasting]);

  const errorFromQuerystring = get(location, 'query.error');
  const errorCodeFromQuerystring = get(location, 'query.status');
  React.useEffect(() => {
    if (!errorFromQuerystring) {
      return;
    }
    const contactEmail = intl.formatMessage({ id: 'contactEmail' });
    showError({
      code: errorCodeFromQuerystring || 500,
      message: intl.formatMessage(
        {
          id: errorFromQuerystring
        },
        {
          contactEmail
          // Would be cool to also make email clickable, but one cannot force
          // good colors to it, and aqua on red makes my eyes bleed
          // contactEmailLink: (
          //   <a href={`mailto:${contactEmail}`} target="_blank" rel="noopener noreferrer">
          //     {contactEmail}
          //   </a>
          // )
        }
      )
    });
  }, [errorFromQuerystring, errorCodeFromQuerystring, showError, intl]);

  function renderBody() {
    if (isLoading) {
      return <Spinner size="large" />;
    } else {
      return children
        ? React.cloneElement(children, {
            user: user,
            key: window.location.pathname
          })
        : null;
    }
  }

  function getChildrenComponentAttribute(attr, defaultValue) {
    return get(children, `type.${attr}`, defaultValue);
  }

  function renderNav(disable) {
    if (getChildrenComponentAttribute('topNavigationBar') === false) {
      return null;
    }

    return <TopNavigationBar user={user} location={location} disableHeadroom={disable} />;
  }

  const isDev = process.env.NODE_ENV === 'development';
  const prefix = isDev ? '[DEV] ' : '';

  function renderHelmet() {
    const brand = intl.formatMessage({ id: 'brand' });
    const defaultTitle = `${prefix}${brand}`;

    return (
      <Helmet
        titleTemplate={`${defaultTitle} - %s`}
        defaultTitle={defaultTitle}
        link={[
          {
            rel: 'apple-touch-icon',
            href: appleTouchIcon
          },
          {
            rel: 'shortcut icon',
            href: favicon,
            type: 'image/x-icon'
          }
        ]}
      />
    );
  }

  const containerId = getChildrenComponentAttribute('containerId', 'Layout');
  const disableHeadroom = getChildrenComponentAttribute('disableHeadroom', false);
  const layoutClassName = getChildrenComponentAttribute('layoutClassName', '');

  return (
    <div id={containerId} className={classNames('Layout', layoutClassName)}>
      <ErrorPopUp />
      <header className="header">
        {renderHelmet()}
        {user && moment().isBefore(moment('2022-06-25')) && (
          <MaintenanceWarning>
            <AlertTitle>Slower chat response time on Friday, June 24</AlertTitle>
            <p>
              Due to national holidays, most of Matchmade staff is out of office on Friday, June 24.
              We apologize in advance for the potential delay in responses and thank you for your
              understanding.
            </p>
          </MaintenanceWarning>
        )}
        {renderNav(disableHeadroom)}
      </header>

      <section className="root">{renderBody()}</section>
      {/* TODO Move clip paths to separate loader, if we ever need more than one */}
      <div className="clip-paths">
        <ShieldClipPath />
        <svg className="blurFilter">
          <filter id="sharpBlur">
            <feGaussianBlur stdDeviation="7" />
            <feColorMatrix type="matrix" values="1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0" />
            <feComposite in2="SourceGraphic" operator="in" />
          </filter>
        </svg>
      </div>
      <SessionExpiredConfirmation isOpen={isSessionExpired} />
    </div>
  );
}
