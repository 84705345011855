// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
import moment from 'moment';

import { PromotionType } from '@sharkpunch/matchmade-common/campaign';
import { SETTLED } from '@sharkpunch/matchmade-common/campaignAgreement';

import { userDateFormat } from '../../../helpers/formats';
import FormattedNumber from '../FormattedNumber';
import InfoTooltip from '../../common/InfoTooltip';

import './LatestOfferTab.scss';

const getDeadline = agreementDeadline => {
  let deadline = moment.utc(agreementDeadline);
  if (!deadline.isValid()) {
    deadline = <FormattedMessage id="influencer.campaign.unknownDeadline" />;
  } else {
    deadline = deadline.format(userDateFormat);
  }
  return deadline;
};

const getPublishingWindowDates = publishingWindow => {
  if (!publishingWindow) return <FormattedMessage id="influencer.campaign.unknownDeadline" />;
  let windowDates;
  let start = moment.utc(publishingWindow.start);
  let end = moment.utc(publishingWindow.end);

  if (!start.isValid() || !end.isValid()) {
    windowDates = <FormattedMessage id="influencer.campaign.unknownDeadline" />;
  } else if (publishingWindow.start === publishingWindow.end) {
    windowDates = start.format(userDateFormat);
  } else {
    windowDates = [start.format(userDateFormat), end.format(userDateFormat)].join(' - ');
  }
  return windowDates;
};

const getCostAndLabel = ({ isFixedPrice, cost, estimatedCost, maxPayment }) => {
  let costToDisplay = 0;
  const isCostOverMaxPayment = maxPayment && costToDisplay && costToDisplay >= maxPayment;
  let label;

  if (cost || isCostOverMaxPayment) {
    label = 'influencer.card.cost';
    costToDisplay = cost;
  } else {
    label = 'influencer.card.estimatedCost';
    costToDisplay = estimatedCost;
  }
  return {
    cost: costToDisplay || 0,
    label
  };
};

const pricesRow = (cpi, cpm, minGuarantee, maxPayment) => {
  const hasCpi = !!cpi;
  const hasCpm = !!cpm;
  const hasMinGuarantee = !!minGuarantee;
  const hasMaxPayment = !!maxPayment;
  const hasSomethingToShow = hasCpi || hasCpm || hasMinGuarantee || hasMaxPayment;
  const isFixedPrice = !hasCpi && !hasCpm && hasMinGuarantee;

  return hasSomethingToShow ? (
    <div className="LatestOfferTab__small-number LatestOfferTab__prices">
      <div className="LatestOfferTab__title">
        {!isFixedPrice && hasMinGuarantee && (
          <FormattedMessage id="influencer.card.minGuarantee" tagName="p" />
        )}
        {isFixedPrice && <FormattedMessage id="influencer.card.fixedFee" tagName="p" />}
        {!isFixedPrice && hasCpi && <FormattedMessage id="influencer.card.cpi" tagName="p" />}
        {!isFixedPrice && hasCpm && <FormattedMessage id="influencer.card.cpm" tagName="p" />}
        {!isFixedPrice && hasMaxPayment && (
          <FormattedMessage id="influencer.card.maxPayment" tagName="p" />
        )}
      </div>
      <div className="LatestOfferTab__value LatestOfferTab__cost-value">
        {!isFixedPrice && hasMinGuarantee && (
          <FormattedNumber tagName="p" value={minGuarantee} type="cost" />
        )}
        {isFixedPrice && <FormattedNumber tagName="p" value={minGuarantee} type="cost" />}
        {!isFixedPrice && hasCpi && <FormattedNumber tagName="p" value={cpi} type="cost" />}
        {!isFixedPrice && hasCpm && <FormattedNumber tagName="p" value={cpm} type="cost" />}
        {!isFixedPrice && hasMaxPayment && (
          <FormattedNumber tagName="p" value={maxPayment} type="cost" />
        )}
      </div>
    </div>
  ) : null;
};

type Props = {
  cost?: number,
  cpi: number,
  cpm: number,
  minGuarantee: number,
  maxPayment: number,
  freeOfCharge: boolean,
  promotionType: string,
  contentPlatform: string,
  agreementDeadline: string,
  agreementStatus: string,
  estimatedCost?: number,
  showCost?: boolean,
  showEffectiveCpm?: boolean,
  showPromotionType?: boolean,
  showInfluencerEarnings?: boolean,
  isVertical?: boolean,
  averageViewCount?: number,
  isTakeItOrLeaveIt?: boolean,
  hasBudgetLock?: boolean,
  publishingWindow?: {
    start: string,
    end: string
  }
};

const LatestOfferTab = ({
  estimatedCost,
  cost,
  cpi,
  cpm,
  minGuarantee,
  maxPayment,
  promotionType,
  contentPlatform,
  agreementDeadline,
  agreementStatus,
  averageViewCount = 0,
  showCost = true,
  showEffectiveCpm = true,
  showPromotionType = true,
  showInfluencerEarnings = false,
  isVertical = false,
  isTakeItOrLeaveIt = false,
  hasBudgetLock = false,
  publishingWindow
}: Props) => {
  const isFixedPrice = !cpi && !cpm;

  let latestOfferTitle;
  let icon;
  let infoTooltip;
  let subtitle;

  switch (true) {
    case agreementStatus === SETTLED:
      latestOfferTitle = <FormattedMessage id="influencer.campaign.offerAccepted" />;
      subtitle = (
        <FormattedMessage
          id={isTakeItOrLeaveIt ? 'offerType.express' : 'offerType.negotiation'}
          tagName="p"
        />
      );
      break;
    case isTakeItOrLeaveIt:
      latestOfferTitle = <FormattedMessage id="influencer.campaign.takeItOrLeaveItDeal" />;
      infoTooltip = (
        <InfoTooltip>
          <FormattedMessage id="takeItOrLeaveIt.description" />
        </InfoTooltip>
      );
      break;
    default:
      latestOfferTitle = <FormattedMessage id="influencer.campaign.offerInNegotiation" />;
  }

  const { cost: costToDisplay, label: costHeaderI18n } = getCostAndLabel({
    isFixedPrice,
    cost,
    maxPayment,
    estimatedCost
  });
  const eCpm = averageViewCount && (costToDisplay / averageViewCount) * 1000;
  const deadline = getDeadline(agreementDeadline);
  const windowDates = getPublishingWindowDates(publishingWindow);
  const hasPublishingWindow = Boolean(
    publishingWindow && publishingWindow.start !== publishingWindow.end
  );

  const bigNumberClass = isVertical
    ? 'LatestOfferTab__big-number LatestOfferTab__vertical'
    : 'LatestOfferTab__big-number';

  return (
    <div className="LatestOfferTab">
      <div className={bigNumberClass}>
        <h2 className="LatestOfferTab__headline">
          {icon}
          <strong>
            {latestOfferTitle}
            {infoTooltip}
          </strong>
        </h2>
        {subtitle}
        {hasBudgetLock && (
          <span class="tag is-warning" style={{ marginTop: '0.5rem' }}>
            Act fast! We only have a limited number of these offers available.
          </span>
        )}
      </div>
      {showCost && (
        <div className={bigNumberClass}>
          <small>
            <FormattedMessage tagName="strong" id={costHeaderI18n} />
          </small>
          <div className="LatestOfferTab__cost">
            <FormattedNumber value={costToDisplay} type="cost" />
          </div>
        </div>
      )}
      {showInfluencerEarnings && isFixedPrice && minGuarantee && (
        <div className={bigNumberClass}>
          <div className="LatestOfferTab__cost">
            <FormattedNumber value={minGuarantee} type="cost" />
          </div>
        </div>
      )}
      {showPromotionType && (
        <div className="LatestOfferTab__small-number">
          <div className="LatestOfferTab__title">
            <FormattedMessage id="campaign.promotionTypes.title" tagName="p" />
          </div>
          <div className="LatestOfferTab__value">
            <FormattedMessage id={`promotionTypes.${promotionType || PromotionType.DEDICATED}`} />
          </div>
        </div>
      )}
      {pricesRow(cpi, cpm, minGuarantee, maxPayment)}
      {isFixedPrice && showEffectiveCpm && (
        <div className="LatestOfferTab__small-number LatestOfferTab__ecpm LatestOfferTab__prices">
          <div className="LatestOfferTab__title">
            <FormattedMessage id="influencer.card.deals.eCpm" tagName="p" />
            <InfoTooltip>
              <FormattedMessage id="influencer.card.deals.eCpm.tooltip" />
            </InfoTooltip>
          </div>
          <div className="LatestOfferTab__value">
            <FormattedNumber tagName="p" value={eCpm} type="cost" />
          </div>
        </div>
      )}
      <div className="LatestOfferTab__small-number LatestOfferTab__publishingDeadline">
        <div className="LatestOfferTab__title">
          <FormattedMessage
            id={
              hasPublishingWindow
                ? 'influencer.campaign.releaseDateRange'
                : 'influencer.campaign.releaseDate'
            }
            tagName="p"
          />
        </div>
        <div className="LatestOfferTab__value">{hasPublishingWindow ? windowDates : deadline}</div>
      </div>
    </div>
  );
};

export default LatestOfferTab;
