// @flow
import * as React from 'react';

import Badge from '@material-ui/core/Badge';

import './Icons.scss';

type Props = {
  count: number
};

const ChatIcon = (props: Props) => {
  return (
    <Badge badgeContent={props.count || null} color="error">
      <i className="material-icons">chat_bubble</i>
    </Badge>
  );
};

export default ChatIcon;
