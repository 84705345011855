// @flow
import * as React from 'react';

import AdvancedSetting from '../common/AdvancedSetting';

import MarkdownTextArea from '../../common/MarkdownTextArea';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

type Props = {
  value: string,
  onChange: (value: string) => void,

  isDisabled: boolean,

  intl: IntlShape
};

TalkingPoints.defaultProps = {
  isDisabled: false
};

function TalkingPoints(props: Props) {
  return (
    <AdvancedSetting
      disabled={props.isDisabled}
      label={
        <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.talkingPoints" />
      }
      tooltip={
        <FormattedMessage id="campaignCreation.campaignDescription.advancedSetting.talkingPoints.tooltip" />
      }>
      <MarkdownTextArea
        textareaComponent={
          <textarea
            className="textarea is-inverted"
            disabled={props.isDisabled}
            defaultValue={props.value}
            placeholder={props.intl.formatMessage({
              id: 'campaignCreation.campaignDescription.advancedSetting.talkingPoints.placeholder'
            })}
            onChange={e => {
              props.onChange(e.target.value);
            }}
          />
        }
        getContent={() => {
          return props.value;
        }}
      />
    </AdvancedSetting>
  );
}

export default injectIntl(TalkingPoints);
