// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { OfferType as OfferTypeEnum } from '../../../constants';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  CampaignType as CampaignTypeEnum,
  ContentPlatform as ContentPlatformEnum
} from '@sharkpunch/matchmade-common/campaign';
import type { CampaignType } from 'matchmade-types';
import type { Estimates } from '../../../types/estimates.flow';

import { checkIfWhitelabelAdmin } from '../../../helpers/user';
import { getAvailableDealTypes } from '../../../helpers/campaign';
import {
  getFirstAvailableContentPlatform,
  getOneChannelPerContentPlatform
} from '../../../helpers/influencer';
import { negotiateCampaign } from '../../../actions/campaign';
import { useAdminTab } from '../InfluencerCard/AdminTab';
import { useModifyOfferOverlay } from '../../common/ModifyOfferOverlay';
import Button, { Color } from '../../common/Button';
import Card from '../../common/Card';
import ChannelHeader from '../../common/ChannelHeader';
import EditCommissionIcon from '../../common/Icons/EditCommissionIcon';
import EditIcon from '../../common/Icons/EditIcon';
import HistoryIcon from '../../common/Icons/HistoryIcon';
import LastDealsTab from '../../common/TabComponents/LastDealsTab';
import LinkToInfluencer from '../InfluencerCard/LinkToInfluencer';
import TabsWithContent from '../../common/TabsWithContent';

import { getAnalyticsTab, getDemographicsTab } from '../InfluencerCard/common';

import type { CampaignForPublisher } from '../../../types/campaign.flow';
import type { EstimatesGameType } from '../../../types/game.flow';

function getSuggestedContentPlatform(contentPlatforms, defaultContentPlatform, influencer) {
  const { youtubeChannel, twitchChannel } = getOneChannelPerContentPlatform(influencer);

  if (
    twitchChannel &&
    (defaultContentPlatform === 'twitch' || contentPlatforms.indexOf('twitch') !== -1)
  ) {
    return 'twitch';
  }

  if (
    youtubeChannel &&
    (defaultContentPlatform === 'youtube' || contentPlatforms.indexOf('youtube') !== -1)
  ) {
    return 'youtube';
  }

  return defaultContentPlatform;
}

function ActionButtons(props: {
  isAdminTab: boolean,
  isLoading: boolean,
  disabled: boolean,
  campaignType: CampaignType,
  isOutOfNetworkInfluencer: boolean,
  channel: any,
  onClickSendOffer: Function,
  onClickEditOffer: Function
}) {
  const {
    isLoading,
    disabled,
    campaignType,
    isOutOfNetworkInfluencer,
    channel,
    onClickSendOffer,
    onClickEditOffer
  } = props;

  if (!channel) return null;

  const commonButtonProps = {
    color: Color.PRIMARY,
    disabled: disabled,
    loading: isLoading
  };

  let makeOfferMessageId = 'influencer.campaign.sendOffer';
  if (campaignType === CampaignTypeEnum.CHARITY) {
    makeOfferMessageId = 'influencer.campaign.sendOffer.charity';
  }

  if (!isOutOfNetworkInfluencer) {
    return [
      <Button
        buttonClassName="InfluencerAvailableCard__make-offer-button"
        onClick={onClickSendOffer}
        key="invite"
        className="Button__main-action"
        {...commonButtonProps}>
        <FormattedMessage tagName="strong" id={makeOfferMessageId} />
      </Button>,
      <Button
        {...commonButtonProps}
        key="modify-offer"
        className="editButton"
        onClick={onClickEditOffer}>
        <EditIcon />
      </Button>
    ];
  }

  return [
    <Button {...commonButtonProps} key="invite" disabled={true} className="Button__main-action">
      <FormattedMessage tagName="strong" id="campaign.influencer.outOfNetworkNoInvite" />
    </Button>
  ];
}

// There probably are more hidden props passed to this component
type Props = {
  influencer: Object,
  channelName: string,
  isLoading: boolean,
  campaign: CampaignForPublisher,
  adminActions: React.Element<*> | React.Element<*>[],
  hideCpi: boolean,
  defaultTab?: number,
  disabled: boolean,
  estimates: Estimates,
  gameType: EstimatesGameType
};

function InfluencerAvailableCard(props: Props) {
  const {
    influencer,
    channelName,
    isLoading,
    campaign,
    adminActions,
    hideCpi,
    defaultTab = 0,
    disabled,
    estimates,
    gameType
  } = props;
  const dispatch = useDispatch();
  const { channel, contentPlatform: channelContentPlatform, url: channelUrl } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};
  const dealTypes = getAvailableDealTypes(campaign.defaultContentPlatform);

  const [modifyOfferOverlay, showModifyOfferOverlay] = useModifyOfferOverlay({
    averageViewCount:
      channelContentPlatform === ContentPlatformEnum.YOUTUBE
        ? channel.last30dStats.averageViewCount
        : 0,
    initialSubmitEnabled: true,

    isLoading,

    promotionType: campaign.defaultPromotionType,
    promotionTypes: campaign.promotionTypes,
    defaultPromotionType: campaign.defaultPromotionType,

    contentPlatform: getSuggestedContentPlatform(
      campaign.contentPlatforms,
      campaign.defaultContentPlatform,
      influencer
    ),
    contentPlatforms: campaign.contentPlatforms,
    defaultContentPlatform: campaign.defaultContentPlatform,

    campaignType: campaign.campaignType,

    onMakeOffer: ({
      cpi,
      cpm,
      minGuarantee,
      maxPayment,
      deadline,
      promotionType,
      contentPlatform,
      offerType
    }) => {
      return dispatch(
        negotiateCampaign.run({
          campaignId: campaign.id,
          influencerId: influencer.id,
          publisherCpi: cpi,
          publisherCpm: cpm,
          publisherMinGuarantee: minGuarantee,
          publisherMaxPayment: maxPayment,
          promotionType,
          contentPlatform,
          // this is only available when we reopen a negotiation that was previously declined or withdrawn
          logId: influencer.agreementLogId,
          deadline,
          pageInfo: campaign,
          freeOfCharge: campaign.campaignType === CampaignTypeEnum.CHARITY,
          takeItOrLeaveIt: offerType === OfferTypeEnum.EXPRESS
        })
      );
    },

    estimates,
    hideCpi,

    gameType,
    avatarUrl: channel && channel.avatarUrl,
    displayName: channelName,

    dealTypes,
    hidePromotionType: channelContentPlatform === ContentPlatformEnum.INSTAGRAM,

    checkLatestLog: true,
    campaignId: campaign.id,
    influencerId: influencer.id
  });

  const [adminTab] = useAdminTab({
    commissionRate: influencer.commissionRate || campaign.commissionRate,
    estimates:
      channelContentPlatform === ContentPlatformEnum.YOUTUBE ? (channel || {}).estimates : {},
    promotionType: campaign.defaultPromotionType,
    agreementStatus: null,
    agreementId: null,
    contentPlatform: channelContentPlatform,
    isDisabled: isLoading
  });

  if (!channel) {
    return null;
  }

  const lastDealsTab = checkIfWhitelabelAdmin() &&
    channelContentPlatform === ContentPlatformEnum.YOUTUBE && {
      icon: <HistoryIcon />,
      isAdminTab: true,
      content: <LastDealsTab deals={influencer.lastDeals} campaignId={campaign.id} />
    };

  return (
    <Card
      className={classNames('InfluencerCard InfluencerAvailableCard', {
        'InfluencerCard--has-modify-offer-overlay': !!modifyOfferOverlay
      })}>
      <ChannelHeader backgroundImage={channel.avatarUrl} isVertical adminActions={adminActions}>
        <LinkToInfluencer
          id={influencer.id}
          isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
          channelUrl={channelUrl}
          avatarUrl={channel.avatarUrl}
          channelName={channelName}
          isMock={channel.mock}
        />
      </ChannelHeader>
      <TabsWithContent
        defaultTab={defaultTab}
        actions={(tabIndex, tab) => {
          return (
            tab && (
              <ActionButtons
                isAdminTab={tab.isAdminTab}
                isLoading={isLoading}
                disabled={disabled}
                campaignType={campaign.campaignType}
                isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
                channel={channel}
                onClickSendOffer={() => {
                  showModifyOfferOverlay(true);
                }}
                onClickEditOffer={() => {
                  showModifyOfferOverlay(true);
                }}
              />
            )
          );
        }}
        data={[
          getAnalyticsTab({
            influencer,
            defaultContentPlatform: campaign.defaultContentPlatform
          }),
          getDemographicsTab(influencer, campaign.defaultContentPlatform),
          channelContentPlatform === ContentPlatformEnum.YOUTUBE &&
            checkIfWhitelabelAdmin() && {
              icon: <EditCommissionIcon />,
              isAdminTab: true,
              content: adminTab
            },
          lastDealsTab
        ].filter(Boolean)}
      />
      {!disabled && !influencer.isOutOfNetworkInfluencer && !!channel && modifyOfferOverlay}
    </Card>
  );
}

export default InfluencerAvailableCard;
