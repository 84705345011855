// @flow

import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import moment from 'moment';
// $FlowFixMe
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

import ContactEmailsTable, { TaskStatus } from '../../components/admin/ContactEmailsTable';
import DoneIcon from '../common/Icons/DoneStatusIcon';
import Spinner from '../../components/common/Spinner';
import useError from '../../hooks/useError';

import type { TaskStatusType } from '../../components/admin/ContactEmailsTable';

import './FetchContactEmails.scss';

type ScheduleFetchingEmailsResponse = {
  data: {
    channelId: string,
    success: boolean
  }[]
};

type GetEmailsResultResponse = {
  data: {
    channelId: string,
    emailAddress?: string,
    status: TaskStatusType,
    groupKey: string,
    created: string,
    updated: string
  }[]
};

type Props = {
  collectionId: number,
  getCollection: ({ dispatch: Function, collectionId: number }) => void,
  scheduleFetchingEmails: ({ collectionId: number }) => Promise<ScheduleFetchingEmailsResponse>,
  getScheduledEmailsResult: ({ collectionId: number }) => Promise<GetEmailsResultResponse>,
  mapResult: Function
};

function FetchContactEmails(props: Props) {
  const getCollection = useAsync({
    promiseFn: props.getCollection,
    dispatch: useDispatch(),
    collectionId: props.collectionId
  });
  const getScheduledEmailsResult = useAsync({
    promiseFn: props.getScheduledEmailsResult,
    collectionId: props.collectionId
  });
  const scheduleFetchingEmails = useAsync({
    deferFn: props.scheduleFetchingEmails,
    onReject: error => showError(error),
    onResolve: a => {
      getScheduledEmailsResult.reload();
    }
  });

  const { showError } = useError();
  const isLoading = getCollection.isLoading || getScheduledEmailsResult.isLoading;
  const collectionChannels = (getCollection.data || {}).influencers || [];
  const scheduledEmailResults = (getScheduledEmailsResult.data || {}).data || [];
  const collectionName = (getCollection.data || {}).name || '';
  const sortedDates = scheduledEmailResults.map(result => result.created).sort();
  const latestScheduledDate = sortedDates ? sortedDates[0] : null;
  const data = collectionChannels.map(props.mapResult).map(channel => {
    const result = scheduledEmailResults.find(task => task.channelId === channel.channelId);

    return {
      ...channel,
      emailAddress: result ? result.emailAddress : '',
      taskStatus: result ? result.status : TaskStatus.NOT_STARTED
    };
  });

  let content = null;

  if (isLoading) {
    content = <Spinner mode="fullWidth" size="large" />;
  } else if (!data || !data.length) {
    content = (
      <div className="hero">
        <div className="hero-body">
          <h2 className="title">
            <FormattedMessage id="error.nodata" />
          </h2>
        </div>
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <FormattedMessage id="admin.mturk.collection" tagName="strong" />
        <h2 className="subtitle">{collectionName}</h2>
        <FormattedMessage id="admin.mturk.description" tagName="p" />
        {!!latestScheduledDate && (
          <button
            className="button is-primary is-large FetchContactEmails__mturk-button"
            disabled={true}>
            <FormattedMessage
              id="admin.mturk.sentRequestWithDate"
              values={{ date: moment(latestScheduledDate).format('MMM Do YYYY HH:mm:ss') }}
            />
            <DoneIcon />
          </button>
        )}
        {!latestScheduledDate && (
          <button
            className="button is-primary is-large FetchContactEmails__mturk-button"
            onClick={() => scheduleFetchingEmails.run(props.collectionId)}
            disabled={scheduleFetchingEmails.isLoading || latestScheduledDate}>
            <FormattedMessage id="admin.mturk.sendRequest" />
          </button>
        )}
        <ContactEmailsTable data={data} />
      </React.Fragment>
    );
  }

  return (
    <div className="FetchContactEmails container">
      <Helmet title="Youtube emails" />
      {content}
    </div>
  );
}

export default FetchContactEmails;
