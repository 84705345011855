import * as React from 'react';
// $FlowFixMe
import Alert from '@material-ui/lab/Alert';

import './Warning.scss';

// Maintenance warning.
// This is a generic component that just renders a Material UI alert, and whatever you pass
// to it. Usually we wrap title with <AlertTitle>, and have text in good ol' <p>
const MaintenanceWarning = ({ children }: { children: React.Node }) => {
  const [showWarning, setShowWarning] = React.useState(true);

  return showWarning ? (
    <div className="MaintenanceWarning">
      <Alert
        variant="standard"
        severity="warning"
        onClose={() => {
          setShowWarning(false);
        }}>
        {children}
      </Alert>
    </div>
  ) : null;
};

export default MaintenanceWarning;
