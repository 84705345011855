// @flow
import * as React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import AttributionInfoTooltip from './AttributionInfoTooltip';
import PlatformSpecificInputs from './PlatformSpecificInputs';

import type { PlatformSpecificTrackingCodes } from './PlatformSpecificInputs';

import UrlControlInput from '../../common/UrlControlInput';

import './AppsFlyer.scss';

import { AttributionPartnerOptionEnum as AttributionPartnerOption } from './AttributionPartnerOption';
import getConfig from '../../../../config';
import type { AttributionPartnerOption as Option } from './AttributionPartnerOption';

type Props = {
  showPlatformSpecificSettings: boolean,

  trackingCode: ?string,
  onChangeTrackingCode: (value: string | null) => void,

  platformSpecificTrackingCodes: PlatformSpecificTrackingCodes,
  onChangePlatformSpecificTrackingCodes: (value: PlatformSpecificTrackingCodes | null) => void,

  selectedOption: Option,
  onChangeOption: (opt: Option) => void,

  isDisabled: boolean,
  isInverted: boolean,

  intl: IntlShape
};

const iconTooltipData = [
  { weSend: 'the campaign name', as: 'c' },
  { weSend: 'YouTube channel name', as: 'af_adset' },
  { weSend: 'YouTube video name', as: 'af_ad' },
  { weSendHighlighted: 'video', as: 'af_ad_type' },
  { weSendHighlighted: 'YouTube', as: 'af_channel' }
];

function renderRadioButtons(props: Props) {
  const buttons = [
    AttributionPartnerOption.NONE,
    AttributionPartnerOption.URL,
    AttributionPartnerOption.PLATFORM_SPECIFIC
  ]
    .map(type => {
      return (
        <label key={type} className="radio" label={type}>
          <input
            disabled={props.isDisabled}
            onChange={() => {
              if (props.isDisabled) return;
              props.onChangeOption(type);
            }}
            checked={props.selectedOption === type}
            type="radio"
            name="option"
          />
          <FormattedMessage
            id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.appsflyer.${type}`}
          />
        </label>
      );
    })
    .filter(Boolean);

  return <div className="control">{buttons}</div>;
}

function renderUrlInput(props: Props) {
  if (props.selectedOption !== AttributionPartnerOption.URL) {
    return null;
  }

  return (
    <UrlControlInput
      isInverted={props.isInverted}
      isDisabled={props.isDisabled}
      value={props.trackingCode}
      onChange={props.onChangeTrackingCode}
    />
  );
}

function renderPlatformSpecificInput(props: Props) {
  if (props.selectedOption !== AttributionPartnerOption.PLATFORM_SPECIFIC) {
    return null;
  }

  return (
    <PlatformSpecificInputs
      platforms={['android', 'ios']}
      isInverted={props.isInverted}
      values={props.platformSpecificTrackingCodes}
      isDisabled={props.isDisabled}
      onChange={props.onChangePlatformSpecificTrackingCodes}
    />
  );
}

function renderAppsflyerInfo() {
  return (
    <div className="AppsFlyer__info-wrapper">
      <div className="AppsFlyer__info-icon">
        <AttributionInfoTooltip list={iconTooltipData} />
      </div>
      <div className="AppsFlyer__info-text">
        <div className="AppsFlyer__info-line">
          <FormattedMessage
            id="publisher.campaign.attribution.appsflyerDescription1"
            values={{
              link: (
                <a href="https://support.appsflyer.com/hc/en-us/articles/214185443-Providing-Permissions-to-Ad-Networks">
                  <FormattedMessage id="publisher.campaign.attribution.appsflyerDocumentation" />
                </a>
              ),
              descriptionTerm: getConfig('appName')
            }}
          />
        </div>
        <div className="AppsFlyer__info-line">
          <FormattedMessage
            tagName="strong"
            id="publisher.campaign.attribution.appsflyerDescription3"
          />
        </div>
        <div className="AppsFlyer__info-line">
          <FormattedMessage id="publisher.campaign.attribution.appsflyerDescription4" />
        </div>
        <div className="AppsFlyer__info-line">
          <FormattedMessage id="publisher.campaign.attribution.appsflyerDescription5" />
        </div>
      </div>
    </div>
  );
}

AppsFlyer.defaultProps = {
  selectedOption: AttributionPartnerOption.NONE,
  showPlatformSpecificSettings: true,
  isDisabled: false
};

function AppsFlyer(props: Props) {
  return (
    <div className="AppsFlyer InstallAttribution__attribution-partner">
      {renderRadioButtons(props)}
      {renderUrlInput(props)}
      {renderPlatformSpecificInput(props)}
      {renderAppsflyerInfo()}
    </div>
  );
}

export default injectIntl(AppsFlyer);
