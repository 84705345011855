// @flow
import type { Action } from '../../types/action.flow';
import type { PublisherForDashboard } from '../../types/publisher.flow';

import { fetchPublisher } from '../../actions/publisher';

type State = {|
  publisher: ?PublisherForDashboard,
  isLoadingPublisher: boolean,
  error: ?Object
|};

const initialState: State = Object.freeze({
  publisher: null,
  isLoadingPublisher: false,
  error: null
});

export default function publisherDashboardReducer(
  state: State = initialState,
  action: Action
): State {
  // always reset the state
  const newState = {
    ...state,
    error: null
  };

  switch (action.type) {
    case fetchPublisher.REQUEST:
      return {
        ...newState,
        isLoadingPublisher: true
      };
    case fetchPublisher.SUCCESS:
      return {
        ...newState,
        publisher: action.payload,
        isLoadingPublisher: false
      };
    case fetchPublisher.FAILURE:
      return {
        ...newState,
        isLoadingPublisher: false,
        error: action.payload
      };
    default:
      return state;
  }
}
