// @flow
import * as React from 'react';
import type { ComponentType } from 'react';

export type WrappedComponent<Props, InjectedProps> = React.ComponentType<
  $Diff<Props, InjectedProps>
>;

// TODO: change this one to return the correct prop type, for now it returns
// the prop type of the original Component
export default function compose<Component: ComponentType<*>>(
  ...functions: Function[]
): (c: ComponentType<*>) => Component {
  return c => {
    let temp = c;
    functions.forEach(fn => {
      temp = fn(temp);
    });
    // TODO (tan): a stupid temporary solution to have proper type for composed components
    return (temp: any);
  };
}
