// @flow
// $FlowFixMe
import * as Sentry from '@sentry/browser';
import {
  JwtParsingError,
  getJwtExpireAt,
  getJwtUserId,
  shouldRefreshAccessToken
} from '@sharkpunch/matchmade-common/jwtToken';
import getConfig from '../config';

const PUPPE_URL = `${getConfig('auth.url')}`;
const ME_API = `${PUPPE_URL}/api/v1/me`;
const REFRESH_API = `${PUPPE_URL}/token/v1/refresh`;

export type User = {
  email: string,
  id: string,
  first_name: string,
  last_name: string,
  picture: string,
  features: string[]
};

type Token = {
  accessToken: string,
  accessTokenExpiresAt: number
};

let token: ?Token;

class LogInRequired extends Error {
  constructor() {
    super('Login required');
    this.name = 'LogInRequired';
  }
}

const getAccessToken = async (): Promise<?Token> => {
  try {
    const result: any = await fetch(REFRESH_API, { credentials: 'include' });
    const data = await result.json();
    return {
      accessToken: data.access_token,
      accessTokenExpiresAt: data.e2eExpiration || getJwtExpireAt(data.access_token)
    };
  } catch (e) {
    if (e && e.response && e.response.status === 401) {
      throw new LogInRequired();
    } else if (e instanceof JwtParsingError) {
      throw new Error(`Failed to refresh access token: ${e}`);
    } else {
      // If the error is neither of the above, we are in weird error-land. Call Sentry!
      Sentry.captureException(
        `Failed to fetch access token for ${
          token && token.accessToken
            ? `user [${getJwtUserId(token.accessToken)}]`
            : 'an unidentified user'
        } : ${e}`
      );
      return null;
    }
  }
};

const refreshAccessToken = async () => {
  if (!token || shouldRefreshAccessToken(token)) {
    token = await getAccessToken();
  }
};

export const getAuthHeader = async () => {
  // Catch error and return null here, so that we can go on sending
  // the legacy cookie token instead of an authorization header
  try {
    await refreshAccessToken();
  } catch (e) {
    return null;
  }
  return token && token.accessToken ? { Authorization: `Bearer ${token.accessToken}` } : null;
};

export const isAuthenticated = (user: User) => {
  return user && user.email && user.id;
};

export const getUser = async (): Promise<User | null> => {
  try {
    const result: any = await fetch(ME_API, { credentials: 'include' });
    return result.data;
  } catch (e) {
    if (e instanceof LogInRequired) {
      return null;
    } else {
      throw e;
    }
  }
};

export const clearAccessToken = async () => {
  token = null;
};
