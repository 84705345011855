// @flow
import type { GamePlatform } from 'matchmade-types';

import { AttributionPartner } from '@sharkpunch/matchmade-common/campaign';
import isValidURL from '../../../../helpers/urlValidator';

export const AttributionPartnerOptionEnum = Object.freeze({
  CODE_OR_URL: 'codeOrUrl',
  PLATFORM_SPECIFIC: 'platformSpecific',
  NONE: 'none',
  URL: 'url'
});

export type AttributionPartnerOption = 'codeOrUrl' | 'platformSpecific' | 'none' | 'url';

export function getSelectedOptionForAttributionPartner(params: {
  attributionPartner: string,
  attributionPartnerTrackingCode?: string | null,
  attributionPartnerPlatformSpecificTrackingCodes?: Object | null
}): AttributionPartnerOption {
  const {
    attributionPartner,
    attributionPartnerTrackingCode,
    attributionPartnerPlatformSpecificTrackingCodes
  } = params;
  let selectedOption;
  switch (attributionPartner) {
    case AttributionPartner.APPSFLYER:
      selectedOption = AttributionPartnerOptionEnum.NONE;

      if (attributionPartnerTrackingCode) {
        selectedOption = AttributionPartnerOptionEnum.URL;
      } else if (
        attributionPartnerPlatformSpecificTrackingCodes &&
        Object.keys(attributionPartnerPlatformSpecificTrackingCodes).length
      ) {
        selectedOption = AttributionPartnerOptionEnum.PLATFORM_SPECIFIC;
      }

      return selectedOption;
    case AttributionPartner.ADJUST:
      selectedOption = AttributionPartnerOptionEnum.CODE_OR_URL;

      if (
        attributionPartnerPlatformSpecificTrackingCodes &&
        Object.keys(attributionPartnerPlatformSpecificTrackingCodes).length
      ) {
        selectedOption = AttributionPartnerOptionEnum.PLATFORM_SPECIFIC;
      }

      return selectedOption;
    // all other services
    default:
      selectedOption = AttributionPartnerOptionEnum.URL;

      if (
        attributionPartnerPlatformSpecificTrackingCodes &&
        Object.keys(attributionPartnerPlatformSpecificTrackingCodes).length
      ) {
        selectedOption = AttributionPartnerOptionEnum.PLATFORM_SPECIFIC;
      }
      return selectedOption;
  }
}

export function isAttributionPartnerSettingValidForSelectedOption(params: {
  attributionPartner: string,
  attributionPartnerTrackingCode?: string | null,
  attributionPartnerPlatformSpecificTrackingCodes?: Object | null,
  attributionPartnerSelectedOption?: AttributionPartnerOption,
  gamePlatforms: GamePlatform[]
}) {
  const {
    attributionPartner,
    attributionPartnerTrackingCode,
    attributionPartnerPlatformSpecificTrackingCodes,
    attributionPartnerSelectedOption,
    gamePlatforms
  } = params;

  if (
    attributionPartner === AttributionPartner.NONE ||
    attributionPartner === AttributionPartner.OTHER
  ) {
    return true;
  }

  let hasValidSettingForSelectedOption = true;
  if (attributionPartnerSelectedOption === AttributionPartnerOptionEnum.URL) {
    hasValidSettingForSelectedOption = isValidURL(attributionPartnerTrackingCode);
  }

  if (
    attributionPartnerSelectedOption === AttributionPartnerOptionEnum.PLATFORM_SPECIFIC &&
    attributionPartner !== AttributionPartner.MATCHMADE_LINK
  ) {
    hasValidSettingForSelectedOption =
      // must check if we have all the platforms filled
      !!attributionPartnerPlatformSpecificTrackingCodes &&
      Object.keys(attributionPartnerPlatformSpecificTrackingCodes).every(platform =>
        gamePlatforms.includes(platform)
      ) &&
      Object.values(attributionPartnerPlatformSpecificTrackingCodes).every(isValidURL);
  }

  return hasValidSettingForSelectedOption;
}

export function resetAttributionPartnerSettingAfterChangingOption<T>(params: {
  ...T,
  attributionPartnerTrackingCode?: ?string,
  attributionPartnerPlatformSpecificTrackingCodes?: ?Object,
  attributionPartnerSelectedOption?: ?AttributionPartnerOption
}): {
  ...T,
  attributionPartnerTrackingCode: ?string,
  attributionPartnerPlatformSpecificTrackingCodes: ?Object
} {
  if (params.attributionPartnerSelectedOption === AttributionPartnerOptionEnum.NONE) {
    return {
      ...params,
      attributionPartnerTrackingCode: null,
      attributionPartnerPlatformSpecificTrackingCodes: null
    };
  } else if (
    params.attributionPartnerSelectedOption === AttributionPartnerOptionEnum.PLATFORM_SPECIFIC
  ) {
    return {
      ...params,
      attributionPartnerTrackingCode: null
    };
  }

  // this covers both URL and CODE_OR_URL option
  return {
    ...params,
    attributionPartnerTrackingCode: params.attributionPartnerTrackingCode,
    attributionPartnerPlatformSpecificTrackingCodes: null
  };
}
