// @flow
import * as React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import PlatformSpecificInputs from './PlatformSpecificInputs';
import type { PlatformSpecificTrackingCodes } from './PlatformSpecificInputs';

import UrlControlInput from '../../common/UrlControlInput';

import { AttributionPartnerOptionEnum as AttributionPartnerOption } from './AttributionPartnerOption';
import type { AttributionPartnerOption as Option } from './AttributionPartnerOption';

export type Props = {
  showPlatformSpecificSettings: boolean,

  trackingCode: ?string,
  onChangeTrackingCode: (value: string | null) => void,

  platformSpecificTrackingCodes: PlatformSpecificTrackingCodes,
  onChangePlatformSpecificTrackingCodes: (value: PlatformSpecificTrackingCodes | null) => void,

  selectedOption: Option,
  onChangeOption: (opt: Option) => void,

  isDisabled: boolean,
  isInverted: boolean,

  intl: IntlShape,
  name: string
};

function renderUrlRadioButtons(props: Props) {
  const label = props.showPlatformSpecificSettings ? (
    ['url', 'platformSpecific'].map(type => {
      return (
        <label key={type} className="radio" label={type}>
          <input
            onChange={() => {
              props.onChangeOption(type);
            }}
            checked={props.selectedOption === type}
            type="radio"
            name="option"
          />
          <FormattedMessage
            id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.appsflyer.${type}`}
          />
        </label>
      );
    })
  ) : (
    <label className="label">
      <FormattedMessage
        id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.${props.name}.url`}
      />
    </label>
  );

  return <div className="control">{label}</div>;
}

function renderUrlInput(props: Props) {
  if (props.selectedOption !== 'url') return null;
  return (
    <UrlControlInput
      isDisabled={props.isDisabled}
      value={props.trackingCode}
      onChange={props.onChangeTrackingCode}
    />
  );
}

function renderPlatformSpecificInput(props: Props) {
  if (props.selectedOption !== AttributionPartnerOption.PLATFORM_SPECIFIC) return null;
  return (
    <PlatformSpecificInputs
      platforms={['android', 'ios']}
      values={props.platformSpecificTrackingCodes}
      isDisabled={props.isDisabled}
      onChange={props.onChangePlatformSpecificTrackingCodes}
    />
  );
}

ServiceWithOneTrackingUrlOrPlatformSpecific.defaultProps = {
  selectedOption: AttributionPartnerOption.URL,
  showPlatformSpecificSettings: true
};

function ServiceWithOneTrackingUrlOrPlatformSpecific(props: Props) {
  return (
    <div className="ServiceWithOneTrackingUrlOrPlatformSpecific InstallAttribution__attribution-partner">
      {renderUrlRadioButtons(props)}
      {renderUrlInput(props)}
      {renderPlatformSpecificInput(props)}
    </div>
  );
}

export default injectIntl(ServiceWithOneTrackingUrlOrPlatformSpecific);
