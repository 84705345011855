import React from 'react';

const FaceError = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        d="M14.75 9.5L14 8.75l.75-.75.75.75.75-.75.75.75-.75.75.75.75-.75.75-.75-.75-.75.75-.75-.75.75-.75zm-7 0L7 8.75 7.75 8l.75.75.75-.75.75.75-.75.75.75.75-.75.75-.75-.75-.75.75-.75-.75.75-.75zM7 14h10v1.5H7V14z"
        fill="#000"
      />
      <path d="M11.5 16.5v-1.75h3v1.75a1.5 1.5 0 0 1-3 0z" stroke="#000" strokeWidth={1.5} />
    </g>
  </svg>
);

export default FaceError;
