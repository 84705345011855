// @flow
// $FlowFixMe
import { Bar as BarChart } from 'react-chartjs-2';
import React from 'react';

import { getColorVariables } from '../../../config';

// TODO @Tan 2020-05-19 move graph config out of the common module
// $FlowFixMe
import { getInstallsAndClicksByChannelGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 455; // 32.5rem;

const InstallsAndClicksByChannelGraph = ({
  installs,
  clicks,
  labels
}: {
  installs: number[],
  clicks: number[],
  labels: string[]
}) => {
  const variables = getColorVariables();
  const { chart } = getGraphConfig(labels, installs, clicks, variables);

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default InstallsAndClicksByChannelGraph;
