// @flow
// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
import Form from '../forms/Form';
import Input from '../forms/controls/Input';
import React, { useState } from 'react';
import classNames from 'classnames';

import DynamicCollectionIcon from '../../images/icons/DynamicCollectionIcon';
import FolderIcon from '../common/Icons/FolderIcon';
import Spinner from '../common/Spinner';
import Tooltip from '../common/Tooltip';

import './CollectionSelector.scss';

type Option = {
  id: number,
  name: string,
  searchId?: ?number
};

const sortByName = (a: Option, b: Option) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};
const mapOptions = (options: Option[], filter: string) => {
  return options
    .filter(o => o.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1)
    .sort(sortByName)
    .map(o => ({
      ...o,
      id: parseInt(o.id, 10)
    }));
};

type Props = {|
  selected: number[],
  options: Option[],
  onAdd: (id: number) => void,
  onRemove?: (id: number) => void,
  onCreate?: (name: string) => void,
  selectedI18nString?: string,
  availableI18nString?: string,
  isLoading?: boolean
|};

const SelectedValue = ({ id, name, onRemove }) => {
  return (
    <div className="CollectionSelector__selected-container">
      <span
        className="tag is-rounded is-info CollectionSelector__selected"
        onClick={() => {
          onRemove && onRemove(id);
        }}>
        {name}
      </span>
    </div>
  );
};

const SelectedValues = ({ selected, options, onRemove }) => {
  if (!selected.length) {
    return null;
  }
  return (
    <div className="CollectionSelector__selected-values">
      {selected.map(id => {
        const option = options.find(o => o.id === id);
        return (
          <SelectedValue
            key={`selected-${id}`}
            id={id}
            name={option && option.name}
            onRemove={onRemove}
          />
        );
      })}
    </div>
  );
};

const AvailableValues = ({ options, selected, onAdd, onRemove }) => {
  return options.map(({ id, name, searchId }) => (
    <AvailableValue
      key={id}
      id={id}
      name={name}
      searchId={searchId}
      selected={selected.includes(id)}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  ));
};

const AvailableValue = ({ selected, onAdd, onRemove, id, name, searchId }) => {
  const className = classNames('value', { selected });
  return (
    <div className="CollectionSelector__available-values">
      <div
        key={`option-${id}`}
        className={className}
        onClick={e => (selected ? onRemove && onRemove(id) : onAdd(id))}>
        {name}
        {!!searchId ? (
          <Tooltip tooltip={<FormattedMessage id={'influencer.collection.dynamic'} />}>
            <DynamicCollectionIcon />
          </Tooltip>
        ) : (
          <FolderIcon tooltipI18nString="influencer.collection.static" withTooltip />
        )}
      </div>
    </div>
  );
};

const CreateCollection = ({ intl, value, onCreate, setValue, isLoading }) => {
  if (!onCreate) {
    return null;
  }

  const btnClassName = classNames('button is-primary', {
    'is-loading': isLoading
  });

  return (
    <Form
      className="CollectionSelector__createCollection"
      onSubmit={({ name }) => {
        if (!name) {
          return;
        }
        onCreate && onCreate(name);
        setValue('');
      }}
      onChange={e => setValue(e.name)}
      key="create-form">
      <Input
        name="name"
        type="text"
        value={value}
        disabled={isLoading}
        placeholder={intl.formatMessage({
          id: 'influencer.collection.createCollection'
        })}
        addonRight={
          <button disabled={!value || isLoading} className={btnClassName}>
            Create
          </button>
        }
      />
    </Form>
  );
};

const SearchCollection = ({ setSearch, intl }) => {
  return (
    <Form
      className="CollectionSelector__search"
      onChange={e => setSearch(e.search)}
      key="search-form">
      <Input
        name="search"
        type="text"
        autoFocus={true}
        placeholder={intl.formatMessage({
          id: 'form.search'
        })}
      />
    </Form>
  );
};

const CollectionSelector = ({
  selected,
  onCreate,
  onRemove,
  onAdd,
  options,
  selectedI18nString,
  availableI18nString,
  isLoading = false
}: Props) => {
  const [search, setSearch] = useState('');
  const [newCollectionName, setNewCollectionName] = useState('');
  const filteredOptions = mapOptions(options, search);
  const intl = useIntl();

  return (
    <div className="CollectionSelector">
      {isLoading && <Spinner mode="overlay" />}
      <SearchCollection intl={intl} setSearch={setSearch} />
      <div className="CollectionSelector__scrollable-content">
        {!!selected.length && (
          <div className="CollectionSelector__group-header">
            <FormattedMessage
              id={selectedI18nString ? selectedI18nString : 'influencer.collection.inCollection'}
              values={{
                count: (selected || []).length
              }}
            />
          </div>
        )}
        <SelectedValues
          selected={selected}
          options={options}
          onRemove={isLoading ? id => {} : onRemove}
        />
        <div className="CollectionSelector__group-header">
          <FormattedMessage
            id={availableI18nString ? availableI18nString : 'influencer.collection.add'}
          />
        </div>
        <AvailableValues
          options={filteredOptions}
          selected={selected}
          onAdd={isLoading ? id => {} : onAdd}
          onRemove={isLoading ? id => {} : onRemove}
        />
      </div>
      <CreateCollection
        value={newCollectionName}
        setValue={setNewCollectionName}
        onCreate={onCreate}
        intl={intl}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CollectionSelector;
