// @flow
import * as React from 'react';

import SingleCountrySelector from '../common/SingleCountrySelector';

import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import './SelectSoftLaunchCountry.scss';

type Props = {
  value: string | null, // alpha-2
  onChange: (value: string) => void, // alpha-2
  disabled: boolean,

  intl: IntlShape
};

function SelectSoftLaunchCountry(props: Props) {
  return (
    <div className="field is-horizontal">
      <div className="field-label" />
      <div className="field-body">
        <div className="field is-horizontal">
          <div className="control SelectSoftLaunchCountry SelectSoftLaunchCountry__country-selection">
            <SingleCountrySelector
              selected={props.value}
              placeholder={props.intl.formatMessage({
                id: 'campaignCreation.productSelection.selectCountry'
              })}
              onChange={props.onChange}
              disabled={props.disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SelectSoftLaunchCountry);
