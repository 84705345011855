import React from 'react';

import AndroidIcon from '../../common/Icons/AndroidIcon';
import AppleIcon from '../../common/Icons/AppleIcon';
import LaptopIcon from '../../common/Icons/LaptopIcon';

import Form from '../../forms/Form';
import Input from '../../forms/controls/Input';

import './Platforms.scss';
const iconToPlatform = {
  IOS: <AppleIcon />,
  ANDROID: <AndroidIcon />,
  WINDOWS: <LaptopIcon />
};

const Platforms = ({ onChange = () => {}, platforms = { IOS: 0, ANDROID: 0, WINDOWS: 0 } }) => {
  return (
    <Form className="Platforms" onChange={onChange} values={platforms}>
      <h1>Platforms</h1>
      <div>
        {Object.keys(iconToPlatform).map(platformKey => {
          const icon = iconToPlatform[platformKey];
          const value = platforms[platformKey] || '';
          return (
            <span className="Platforms__entry is-flex" key={platformKey}>
              <span className="Platforms__label">{icon}</span>
              <span className="Platforms__value">
                <Input
                  type="number"
                  step={1}
                  min={1}
                  max={100}
                  name={platformKey}
                  value={value}
                  iconLeft={<i className="fa fa-percent" />}
                />
              </span>
            </span>
          );
        })}
      </div>
    </Form>
  );
};

export default Platforms;
