import React, { Component } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import BasicChannelInfo from '../components/influencer/mock/BasicChannelInfo';
import Platforms from '../components/influencer/mock/Platforms';

import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';

import { mock, resetMock } from '../actions/influencer';
import { resetProps } from '../actions/common';
import { search } from '../actions/manager';

import AgeGroup from '../components/influencer/mock/AgeGroups';
import Countries from '../components/influencer/mock/Countries';
import Gender from '../components/influencer/mock/Gender';

import mapValues from 'lodash/mapValues';

import Button, { Color } from '../components/common/Button';
import FloatingFooter from '../components/common/FloatingFooter';

import Error from '../components/common/Error';

import './CreateMockInfluencerPage.scss';

function sumValues(prev, next) {
  return prev + +next;
}

function getInfluencerFromSearchResults(searchResults) {
  return (
    (searchResults[0] && searchResults[0].youtubeChannels && searchResults[0].youtubeChannels[0]) ||
    {}
  );
}

class CreateMockInfluencerPage extends Component {
  constructor(props) {
    super(props);

    const channel = getInfluencerFromSearchResults(props.searchResults);

    this.state = {
      channel,
      country: {},
      numberOfCountries: 4
    };
  }

  componentWillUnmount() {
    this.props.resetProps();
  }

  searchForChannel = e => {
    const channelUrl = e.target.value;
    if (!channelUrl) {
      return;
    }

    const { resetMock, search } = this.props;

    resetMock();
    search({ query: channelUrl });
  };

  onChange = statType => {
    return stats => {
      const processedStats = mapValues(stats, value => parseFloat(value) || value);
      this.setState({ [statType]: processedStats });
    };
  };

  updateBasicInfo = channel => {
    this.setState({ channel });
  };

  createInfluencer = () => {
    if (!this.props.searchResults.length) {
      return;
    }
    const searchResultChannel = getInfluencerFromSearchResults(this.props.searchResults);
    // Convert our state to form backend expects
    const { country, ageGroup = {}, gender, platforms, channel } = this.state;

    const { name, contactPersonName, contactEmail, avatarUrl } = channel;
    this.props.mock({
      name,
      contactEmail,
      contactPersonName,
      youtubeChannel: {
        url: `https://youtube.com/channel/${searchResultChannel.id}`,
        avatarUrl,
        demographics: {
          // Calculate "others" value on the fly, since it's tricky to do that
          // on Form level
          country: {
            ...country,
            ZZ: 100 - Object.values(country).reduce(sumValues, 0)
          },
          ageGroup: {
            ...ageGroup,
            'age65-': 100 - Object.values(ageGroup).reduce(sumValues, 0)
          },
          gender,
          operatingSystem: platforms
        }
      }
    });
  };

  addCountry = () => {
    const numberOfCountries = this.state.numberOfCountries + 1;
    this.setState({ numberOfCountries });
  };

  deleteCountry = countryCode => {
    const country = { ...this.state.country };
    delete country[countryCode];
    this.setState({
      country,
      numberOfCountries: this.state.numberOfCountries - 1
    });
  };

  render() {
    const receivedChannel = getInfluencerFromSearchResults(this.props.searchResults);

    const channel = {
      ...this.state.channel,
      ...receivedChannel,
      channelUrl: receivedChannel.id
        ? `https://youtube.com/channel/${receivedChannel.id}`
        : this.state.channel.channelUrl
    };

    const { country = {}, ageGroup = {}, gender = {}, platforms = {} } = this.state;

    const submitDisabled =
      !!this.props.mockInfluencer ||
      !receivedChannel.id ||
      !channel.contactPersonName ||
      !channel.contactEmail;

    return (
      <div className="CreateMockInfluencerPage section container">
        <h1 className="title">
          <FormattedMessage id="mockInfluencerPage.title" />
        </h1>
        <BasicChannelInfo
          onChange={this.updateBasicInfo}
          searchChannel={this.searchForChannel}
          isLoading={this.props.searchingWarehouse}
          channel={channel}
          error={this.props.error}
        />
        <div className="CreateMockInfluencerPage__stats section is-flex-desktop">
          <p className="CreateMockInfluencerPage__stats--demographic-info">
            <FormattedMessage id="mockInfluencerPage.demographicsInfoRequired" />
          </p>
          <Countries
            onChange={this.onChange('country')}
            country={country}
            addCountry={this.addCountry}
            deleteCountry={this.deleteCountry}
            numberOfCountries={this.state.numberOfCountries}
          />
          <AgeGroup onChange={this.onChange('ageGroup')} ageGroup={ageGroup} />
          <Gender onChange={this.onChange('gender')} gender={gender} />
          <Platforms onChange={this.onChange('platforms')} platforms={platforms} />
        </div>
        <Error error={this.props.error} />
        <FloatingFooter>
          <Button
            buttonClassName="is-radiusless"
            size="large"
            color={this.props.mockInfluencer ? Color.LIGHT : Color.PRIMARY}
            onClick={this.createInfluencer}
            loading={this.props.searchingWarehouse || this.props.isImportingMockInfluencer}
            disabled={submitDisabled}>
            <FormattedMessage id={this.props.mockInfluencer ? 'success' : 'form.import'} />
          </Button>
        </FloatingFooter>
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('createMockInfluencerPage');

const mapDispatchToProps = generateDispatchToProps({
  search,
  mock,
  resetMock,
  resetProps
});

CreateMockInfluencerPage = injectIntl(CreateMockInfluencerPage);

CreateMockInfluencerPage = connect(mapStateToProps, mapDispatchToProps)(CreateMockInfluencerPage);

export default CreateMockInfluencerPage;
