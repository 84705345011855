import React from 'react';

const TabsIconLatestOffer = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fill="#2D3767" fillRule="evenodd">
      <path d="M4.536 12.192l8 8-3 3-8-8zm9.167-8.989l8 8-3 3-8-8zm-2.5 7.5l10 10-2 2-10-10z" />
      <path d="M21.203 18.703l5.5 5.5-4 4-5.5-5.5zM13.467 6.09l5.42 5.259-8.046 7.938-5.151-5.313z" />
    </g>
  </svg>
);

export default TabsIconLatestOffer;
