import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import CostGraph from '../graphs/CostGraph';
import EngagementGraph from '../graphs/EngagementGraph';
import FormattedNumber from '../../common/FormattedNumber';
import InstallsAndClicksByPlatformGraph from '../graphs/InstallsAndClicksByPlatformGraph';
import InstallsAndClicksGraph from '../graphs/InstallsAndClicksGraph';
import InstallsByCountryGraph from '../graphs/InstallsByCountryGraph';
import NoData from '../../common/NoData';
import Spinner from '../../common/Spinner';
import Tooltip from '../../common/Tooltip';

import {
  CampaignType as CampaignTypeEnum,
  canTrackInstalls
} from '@sharkpunch/matchmade-common/campaign';

import './Statistics.scss';

const ECPI_RATIO = 2.45; // given by biz team
configureAnchors({ offset: -60 });

class StatisticsTab extends Component {
  componentDidMount() {
    const {
      startDate,
      endDate,
      campaign,
      campaignStatistics,
      fetchCampaignStatistics
    } = this.props;
    const campaignId = campaign.id;
    if (!campaignStatistics) {
      fetchCampaignStatistics(campaignId, startDate, endDate);
    }
  }
  componentWillUpdate(nextProps) {
    const { startDate, endDate, campaign, fetchCampaignStatistics } = nextProps;
    const campaignId = campaign.id;
    const oldStartDate = this.props.startDate;
    const oldEndDate = this.props.endDate;
    if (startDate !== oldStartDate || endDate !== oldEndDate) {
      fetchCampaignStatistics(campaignId, startDate, endDate);
    }
  }
  render() {
    const { isFetchingCampaignStatistics, campaignStatistics, campaign } = this.props;
    const canCampaignTrackInstalls = canTrackInstalls(campaign.attributionPartner);
    if (isFetchingCampaignStatistics) {
      return <Spinner mode="fullWidth" size="large" />;
    }

    if (!campaignStatistics) {
      return <NoData />;
    }
    return (
      <div className="StatisticsTab">
        <ScrollableAnchor id="installs">
          <section className="StatisticsTab__section">
            <div>
              <h2>
                <FormattedMessage id="campaign.report.stats.title" tagName="strong" />
              </h2>
              <h1>
                <a href="#installs">
                  <FormattedMessage id="campaign.report.stats.installs" tagName="strong" />
                </a>
              </h1>
              <div className="StatisticsTab__metrics">
                {canCampaignTrackInstalls && [
                  <div key="total" className="StatisticsTab__metrics-box">
                    <Tooltip
                      tooltip={
                        <FormattedMessage id="campaign.report.glossary.installDescription" />
                      }>
                      <h2>
                        <FormattedMessage
                          id="campaign.report.stats.totalInstalls"
                          tagName="strong"
                        />
                      </h2>
                    </Tooltip>
                    <h1>
                      <FormattedNumber value={campaignStatistics.totalInstallCount} type="number" />
                    </h1>
                  </div>,
                  <div key="unattributed" className="StatisticsTab__metrics-box">
                    <Tooltip
                      tooltip={
                        <FormattedMessage id="campaign.report.glossary.unattributedInstallDescription" />
                      }>
                      <h2>
                        <FormattedMessage
                          id="campaign.report.stats.totalAttributedInstalls"
                          tagName="strong"
                        />
                      </h2>
                    </Tooltip>
                    <h1>
                      <FormattedNumber
                        value={campaignStatistics.attributedInstallCount}
                        type="number"
                      />
                    </h1>
                  </div>,
                  <div key="attributed" className="StatisticsTab__metrics-box">
                    <h2>
                      <FormattedMessage
                        id="campaign.report.stats.totalUnattributedInstalls"
                        tagName="strong"
                      />
                    </h2>
                    <h1>
                      <FormattedNumber
                        value={campaignStatistics.unattributedInstallCount}
                        type="number"
                      />
                    </h1>
                  </div>
                ]}
                <div className="StatisticsTab__metrics-box">
                  <h2>
                    <FormattedMessage id="campaign.report.stats.totalClicks" tagName="strong" />
                  </h2>
                  <h1>
                    <FormattedNumber value={campaignStatistics.totalClickCount} type="number" />
                  </h1>
                </div>
              </div>
            </div>
            <div className="StatisticsTab__graph">
              <InstallsAndClicksGraph
                clicksAndInstallsAnalytics={campaignStatistics.clicksAndInstallsAnalytics}
              />
            </div>
          </section>
        </ScrollableAnchor>
        <ScrollableAnchor id="engagement">
          <section className="StatisticsTab__section">
            <div>
              <h2>
                <FormattedMessage id="campaign.report.stats.title" tagName="strong" />
              </h2>
              <h1>
                <a href="#engagement">
                  <FormattedMessage id="campaign.report.stats.engagement" tagName="strong" />
                </a>
              </h1>
              <div className="StatisticsTab__metrics">
                <div className="StatisticsTab__metrics-box">
                  <h2>
                    <FormattedMessage id="campaign.report.stats.totalViews" tagName="strong" />
                  </h2>
                  <h1>
                    <FormattedNumber value={campaignStatistics.viewCount} type="number" />
                  </h1>
                </div>
                <div className="StatisticsTab__metrics-box">
                  <Tooltip
                    tooltip={
                      <FormattedMessage id="campaign.report.glossary.engagementDescription" />
                    }>
                    <h2>
                      <FormattedMessage
                        id="campaign.report.stats.totalEngagement"
                        tagName="strong"
                      />
                    </h2>
                  </Tooltip>
                  <h1>
                    <FormattedNumber value={campaignStatistics.engagementRatio} type="percentage" />
                  </h1>
                </div>
              </div>
            </div>
            <div className="StatisticsTab__graph">
              <EngagementGraph
                likeCount={campaignStatistics.likeCount}
                dislikeCount={campaignStatistics.dislikeCount}
                commentCount={campaignStatistics.commentCount}
                viewCount={campaignStatistics.viewCount}
              />
            </div>
          </section>
        </ScrollableAnchor>
        <ScrollableAnchor id="demographics">
          <section className="StatisticsTab__section">
            <div>
              <h2>
                <FormattedMessage id="campaign.report.stats.title" tagName="strong" />
              </h2>
              <h1>
                <a href="#demographics">
                  <FormattedMessage
                    id="campaign.report.stats.installsDemographics"
                    tagName="strong"
                  />
                </a>
              </h1>
            </div>
            <h2 className="StatisticsTab__demographics-graph-title">
              <FormattedMessage id="campaign.report.stats.byCountry" tagName="strong" />
            </h2>
            <div className="StatisticsTab__demographics-graph">
              <InstallsByCountryGraph installsPerCountry={campaignStatistics.installsPerCountry} />
            </div>
            <h2 className="StatisticsTab__demographics-graph-title">
              <FormattedMessage id="campaign.report.stats.byPlatform" tagName="strong" />
            </h2>
            <div className="StatisticsTab__demographics-graph">
              <InstallsAndClicksByPlatformGraph
                clicksAndInstallsByPlatform={campaignStatistics.clicksAndInstallsByPlatform}
              />
            </div>
          </section>
        </ScrollableAnchor>
        <ScrollableAnchor id="costs">
          <section className="StatisticsTab__section">
            {campaign.campaignType !== CampaignTypeEnum.CHARITY ? (
              <React.Fragment>
                <div>
                  <h2>
                    <FormattedMessage id="campaign.report.stats.title" tagName="strong" />
                  </h2>
                  <h1>
                    <a href="#costs">
                      <FormattedMessage id="campaign.report.stats.costs" tagName="strong" />
                    </a>
                  </h1>
                  <div className="StatisticsTab__metrics">
                    <div className="StatisticsTab__metrics-box">
                      <h2>
                        <FormattedMessage id="campaign.report.stats.totalCost" tagName="strong" />
                      </h2>
                      <h1>
                        <FormattedNumber value={campaignStatistics.totalCost} type="cost" />
                      </h1>
                    </div>
                    <div className="StatisticsTab__metrics-box">
                      <Tooltip
                        tooltip={<FormattedMessage id="campaign.report.glossary.cpiDescription" />}>
                        <h2>
                          <FormattedMessage id="campaign.report.stats.cpi" tagName="strong" />
                        </h2>
                      </Tooltip>
                      <h1>
                        <FormattedNumber value={campaignStatistics.cpi} type="cost" />
                      </h1>
                    </div>
                    <div className="StatisticsTab__metrics-box">
                      <Tooltip
                        tooltip={
                          <FormattedMessage id="campaign.report.glossary.ecpiDescription" />
                        }>
                        <h2>
                          <FormattedMessage id="campaign.report.stats.ecpi" tagName="strong" />
                        </h2>
                      </Tooltip>
                      <h1>
                        <FormattedNumber value={campaignStatistics.cpi / ECPI_RATIO} type="cost" />
                      </h1>
                    </div>
                    <div className="StatisticsTab__metrics-box">
                      <Tooltip
                        tooltip={<FormattedMessage id="campaign.report.glossary.cpmDescription" />}>
                        <h2>
                          <FormattedMessage id="campaign.report.stats.cpm" tagName="strong" />
                        </h2>
                      </Tooltip>
                      <h1>
                        <FormattedNumber value={campaignStatistics.cpm} type="cost" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="StatisticsTab__graph">
                  <CostGraph costsAnalytics={campaignStatistics.costsAnalytics} />
                </div>
              </React.Fragment>
            ) : null}
          </section>
        </ScrollableAnchor>
      </div>
    );
  }
}

export default StatisticsTab;
