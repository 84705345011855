import { FormattedMessage } from 'react-intl';
import Bullhorn from '../../images/Bullhorn';
import React from 'react';

import './AdminPlaceholderPages.scss';

export const AdminNoCampaigns = () => {
  return (
    <div className="AdminNoCampaigns container">
      <h1 className="title">
        <FormattedMessage id="nav.admin.campaigns" />
      </h1>
      <div className="has-text-centered">
        <Bullhorn className="PlaceholderIcon" />
        <FormattedMessage tagName="div" id="admin.accounts.noCampaigns" />
      </div>
    </div>
  );
};
