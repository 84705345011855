// @flow
import type { Action, ReducerCreator } from '../../types/action.flow';
import type { CampaignForPublisher } from '../../types/campaign.flow';

import { DEFAULT_PROMOTION_TYPE } from '@sharkpunch/matchmade-common/campaign';

import { Platform } from '@sharkpunch/matchmade-common/game';

import {
  getSelectedOptionForAttributionPartner,
  resetAttributionPartnerSettingAfterChangingOption
} from '../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';

import {
  validateAttributionPartnerSelection,
  validateName
} from '../../reducers/containers/campaignCreation/validators';
import runMultipleReducers from '../helpers/runMultipleReducers';

import { clearError } from '../../actions/common';
import { editCampaign, fetchCampaign, updateCampaign } from '../../actions/campaign';
import isEqual from 'lodash/isEqual';
import overEvery from 'lodash/overEvery';

export type State = {|
  // from the api
  // TODO use the correct api response type for this
  originalCampaign: ?CampaignForPublisher,
  isLoadingCampaign: boolean,

  isUpdatingCampaign: boolean,

  // indicate whether the campaign has been changed or not
  isCampaignChanged: boolean,
  // indicate whether we have invalid data in the campaign
  // UI components should handle the actual error
  isCampaignValid: boolean,

  // this is what the UI uses to render
  // TODO use the correct api response type for this
  campaign: ?CampaignForPublisher,

  error: ?Object
|};

const initialState: State = Object.freeze({
  // default to game

  originalCampaign: null,
  isLoadingCampaign: false,

  isUpdatingCampaign: false,

  isCampaignChanged: false,
  isCampaignValid: true,

  campaign: null,

  error: null
});

function getAttributionPartnerSelectedOptionFromCampaign(campaign): any {
  if (!campaign) {
    return {};
  }
  return {
    ...campaign,
    attributionPartnerSelectedOption: getSelectedOptionForAttributionPartner(campaign)
  };
}

type CampaignToBeValidated = $NonMaybeType<$PropertyType<State, 'campaign'>>;
const validateNewCampaignData: (campaign: CampaignToBeValidated) => boolean = overEvery([
  validateName,
  (newCampaign: CampaignToBeValidated) =>
    validateAttributionPartnerSelection({
      ...newCampaign,
      gamePlatforms: newCampaign.game.platforms.filter(p => p !== Platform.HOMEPAGE)
    })
]);

const handleUpdateCampaignActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case updateCampaign.REQUEST:
        return {
          ...state,
          isUpdatingCampaign: true
        };
      case updateCampaign.SUCCESS:
        const updatedCampaign = getAttributionPartnerSelectedOptionFromCampaign(action.payload);
        return {
          ...state,
          campaign: updatedCampaign,
          originalCampaign: updatedCampaign,
          isUpdatingCampaign: false,
          isCampaignChanged: false,
          isCampaignValid: true
        };
      case updateCampaign.FAILURE:
        return {
          ...state,
          error: action.payload,
          isUpdatingCampaign: false
        };
      case editCampaign.SUCCESS:
        if (!state.originalCampaign || !state.campaign) {
          return state;
        }

        const { attribute, value }: { attribute: string, value: any } = action.payload;
        let newCampaign = {
          ...state.campaign,
          [attribute]: value
        };

        switch (attribute) {
          case 'restrictions':
            newCampaign.description = value;
            break;
          case 'contentPlatforms':
            const originalContentPlatform = state.originalCampaign.contentPlatforms[0];
            const newContentPlatform = value[0];

            // switch back to the original value
            if (originalContentPlatform === newContentPlatform) {
              newCampaign.promotionTypes = state.originalCampaign.promotionTypes;
              newCampaign.defaultPromotionType = state.originalCampaign.defaultPromotionType;
            } else {
              newCampaign.promotionTypes = [DEFAULT_PROMOTION_TYPE[newContentPlatform]];
              newCampaign.defaultPromotionType = DEFAULT_PROMOTION_TYPE[newContentPlatform];
            }

            break;
          case 'attributionPartnerSelectedOption':
            newCampaign = resetAttributionPartnerSettingAfterChangingOption({ ...newCampaign });
            break;
          case 'attributionPartner':
            newCampaign = getAttributionPartnerSelectedOptionFromCampaign({ ...newCampaign });
            break;
          default:
            break;
        }

        return {
          ...state,
          campaign: newCampaign,
          isCampaignChanged: !isEqual(state.originalCampaign, newCampaign),
          isCampaignValid: validateNewCampaignData(newCampaign)
        };
      default:
        return state;
    }
  };
};

const handleFetchCampaignActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchCampaign.REQUEST:
        return {
          ...state,
          originalCampaign: null,
          campaign: null,
          isCampaignChanged: false,
          isCampaignValid: true,
          isLoadingCampaign: true
        };
      case fetchCampaign.SUCCESS:
        const campaignFromApi = getAttributionPartnerSelectedOptionFromCampaign(action.payload);

        return {
          ...state,
          originalCampaign: campaignFromApi,
          campaign: campaignFromApi,
          isLoadingCampaign: false
        };
      case fetchCampaign.FAILURE:
        return {
          ...state,
          error: action.payload,
          isLoadingCampaign: false
        };
      default:
        return state;
    }
  };
};

const handleCommonActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case clearError.SUCCESS: {
        return {
          ...state,
          error: null
        };
      }
      default:
        return state;
    }
  };
};

export default function editCampaignReducer(state: State = initialState, action: Action): State {
  const newState: State = runMultipleReducers([
    handleFetchCampaignActions(),
    handleUpdateCampaignActions(),
    handleCommonActions()
  ])(
    {
      ...state,
      error: null
    },
    action
  );

  return newState;
}
