// @flow
import Button, { Color } from './Button';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { FormattedMessage } from 'react-intl';

import Spinner from './Spinner';

import { authorizedFetch } from '../../helpers/api';

const filenameRegex = /filename="([a-zA-Z\-_0-9.]+)"/;

type RequestParams = {
  path: string,
  body?: Object,
  method: 'GET' | 'POST'
};

export const downloadUrl = async ({ path, method, body }: RequestParams) => {
  // This is a simplified version of FileSaver.js[1], except I dropped everything
  // that felt irrelevant to downloading CSV and saving it.
  //
  // Note: the comment above was at a time when this was only used
  // to download CSV files from search tool. Now this is a generic CSV download component,
  // but left the comment above for significance.
  //
  // WARNING! This is not following flux[2] data flow, because this is the only place in our code that needs to download file
  // IFF we need this in more than one place -- we should move these parts out to actions & reducers
  //
  // [1] https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
  // [2] https://github.com/facebook/flux

  let response;
  try {
    response = await authorizedFetch(path, {
      method,
      body
    });
  } catch (e) {
    console.error(`Requesting file for path [${path}] failed`, e);
    Sentry.captureException(e);
    throw new Error(`Failed to download file with the following error: ${e.message}.`);
  }

  const requestId = response.headers.get('request-id');

  if (!response || response.status !== 200) {
    console.error(`Fetch failed while downloading url [${path}]`);
    const errorBody = await response.json();
    const errorMessage =
      (errorBody && errorBody.error && errorBody.error.message) || 'Unknown error';
    throw new Error(
      `Failed to download file with the following error: ${errorMessage}. Error code: ${
        requestId || 'Unknown'
      }`
    );
  }

  const blob = await response.blob();
  if (!blob) {
    console.error('Failed to get Blob from download response:', response);
    throw new Error(`Failed to download file. Error code: ${requestId || 'Unknown'}`);
  }

  // Create blob link
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.rel = 'noopener';

  // Extract filename from 'Content-Disposition' header
  const contentDisposition = response.headers.get('content-disposition');
  const filenameFromContentDisposition =
    contentDisposition && contentDisposition.match(filenameRegex);
  const fileName =
    (filenameFromContentDisposition && filenameFromContentDisposition[1]) || 'report.csv';
  link.setAttribute('download', fileName);

  // Append to html
  document.body && document.body.appendChild(link);

  // Download
  link.click();

  // Remove
  link.parentNode && link.parentNode.removeChild(link);
};

// This component is a generic download button. This way you can do
// downloads from JS. Example use case is having an access token present
// (as opposed to not having it when clicking a new link to download something
// without first getting the access token from Puppe).
export const DownloadUrlButton = (reqParams: RequestParams) => {
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const t = setTimeout(() => setClicked(false), 5000);
    return function cleanup() {
      clearTimeout(t);
    };
  });

  return (
    <>
      <Button
        color={Color.TEXT}
        onClick={() => {
          setClicked(true);
          // Self-executing promise function, since we can't return
          // anything for the onClick
          downloadUrl(reqParams)
            .catch(e => setError(e))
            .finally(() => setClicked(false));
        }}>
        {clicked ? <Spinner size="small" mode="inline" /> : <FormattedMessage id="export.csv" />}
      </Button>
      {error && <p className="help is-danger has-text-centered p-2">{error.message}</p>}
    </>
  );
};
