// @flow
import * as React from 'react';
import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';

import type { CampaignType } from 'matchmade-types';

import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import './SelectVertical.scss';

type Props = {
  value: CampaignType | null,
  onChange: (value: CampaignType) => void,
  isDisabled: boolean
};

const options: { value: CampaignType, disabled: boolean, label: React.Element<*> }[] = [
  {
    value: CampaignTypeEnum.GAME,
    disabled: false,
    label: <FormattedMessage id="campaignCreation.productSelection.vertical.game" />
  },
  {
    value: CampaignTypeEnum.APP,
    disabled: false,
    label: <FormattedMessage id="campaignCreation.productSelection.vertical.app" />
  }
];

SelectVertical.defaultProps = {
  isDisabled: false
};

export default function SelectVertical(props: Props) {
  const verticalElements = options.map(({ value, disabled, label }) => {
    const selected = value && props.value === value;

    const isDisabled = disabled || props.isDisabled;

    const className = classNames('button', {
      SelectVertical__vertical: true,
      'is-primary': selected,
      'SelectVertical__vertical--selected': selected,
      'is-disabled': isDisabled
    });

    const onClick = isDisabled || selected ? null : () => props.onChange(value);

    return (
      <p className="control is-expanded" key={value}>
        <button onClick={onClick} className={className}>
          {label}
        </button>
      </p>
    );
  });

  return (
    <div className="SelectVertical field is-horizontal">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.productSelection.vertical" />
        </label>
      </div>
      <div className="field-body">
        <div className="field is-grouped SelectVertical__verticals">{verticalElements}</div>
      </div>
    </div>
  );
}
