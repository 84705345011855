//@flow
import * as React from 'react';
import CreatePayoutForm from './CreatePayoutForm';

const CreatePayout = () => {
  return (
    <div className="container">
      <h1 className="title is-1">Create Payout</h1>
      <CreatePayoutForm />
    </div>
  );
};

export default CreatePayout;
