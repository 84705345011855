import { Bar as BarChart } from 'react-chartjs-2';
import React from 'react';

import { getColorVariables } from '../../../config';
import { getInstallsConversionByChannelGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 455; // 32.5rem;

const InstallsConversionByChannelGraph = ({
  conversionRates,
  labels
}: {
  conversionRates: number[],
  labels: string[]
}) => {
  const variables = getColorVariables();

  const { chart } = getGraphConfig(labels, conversionRates, variables);

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default InstallsConversionByChannelGraph;
