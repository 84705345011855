import React from 'react';

import Icon from '../Icon';
import TwitchSvg from '../../../images/icons/TwitchIcon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const TwitchIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="TwitchIcon">
      {() => <TwitchSvg />}
    </Icon>
  );
};

export default TwitchIcon;
