// @flow
import * as React from 'react';

import Card from '../../common/Card';
import ChannelHeader from '../../common/ChannelHeader';
import TabsWithContent from '../../common/TabsWithContent';

import compose from '../../../hoc/compose';
import injectRoleChecking from '../../../hoc/injectRoleChecking';

import LinkToInfluencer from '../InfluencerCard/LinkToInfluencer';

import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';

import { getAnalyticsTab, getDemographicsTab } from '../InfluencerCard/common';

type Props = {
  defaultTab: number,
  influencer: {
    id: number,
    isOutOfNetworkInfluencer: boolean,
    youtubeChannels: any[],
    twitchChannels: any[],
    instagramChannels: any[],
    bilibiliChannels: any[],
    country: string | null,
    estimatedCountry: string | null
  },
  adminActions?: React.Element<*> | React.Element<*>[],
  isLoading: boolean
};

class InfluencerFollowedCard extends React.PureComponent<Props> {
  render() {
    const { influencer, defaultTab = 0, adminActions } = this.props;

    const { channel, url: channelUrl, contentPlatform } =
      getFirstAvailableContentPlatform(influencer) || {};

    if (!channel) return null;

    const demographicsTab = getDemographicsTab(this.props.influencer, contentPlatform);
    const analyticsTab = getAnalyticsTab({
      influencer: this.props.influencer,
      defaultContentPlatform: contentPlatform
    });

    return (
      <Card className="InfluencerCard InfluencerFollowedCard">
        <ChannelHeader backgroundImage={channel.avatarUrl} isVertical adminActions={adminActions}>
          <LinkToInfluencer
            id={influencer.id}
            isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
            channelUrl={channelUrl}
            avatarUrl={channel.avatarUrl}
            channelName={channel.name}
            isMock={channel.mock}
            contentPlatform={contentPlatform}
          />
        </ChannelHeader>
        <TabsWithContent defaultTab={defaultTab} data={[analyticsTab, demographicsTab]} />
      </Card>
    );
  }
}

export default compose(injectRoleChecking())(InfluencerFollowedCard);
