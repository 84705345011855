import React from 'react';

const EffectiveReachIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg height="100%" viewBox="0 0 89 57" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g strokeWidth="4" fill="none" fillRule="evenodd">
        <path d="M1 55.25h86M13 47V23M1 35h24" stroke={secondaryColor} strokeLinecap="round" />
        <path d="M75 37a8 8 0 1 1-16 0 8 8 0 0 1 16 0" stroke={primaryColor} />
        <path
          d="M67 28c-7.288 0-13.78 3.67-18 9 4.22 5.33 10.712 9 18 9 7.287 0 13.78-3.67 18-9-4.22-5.33-10.713-9-18-9z"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M75 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0" stroke={primaryColor} />
        <path
          d="M67 2c-7.288 0-13.78 3.67-18 9 4.22 5.33 10.712 9 18 9 7.287 0 13.78-3.67 18-9-4.22-5.33-10.713-9-18-9z"
          stroke={primaryColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default EffectiveReachIcon;
