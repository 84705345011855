// @flow
import type { ApiResponse } from '../types/api/response.flow';
import type { GetCurrentSocialMediaConnectionData } from '../types/api/connections.flow';

import useError from '../hooks/useError';

// $FlowFixMe
import { useAsync } from 'react-async';

import { getCurrentSocialMediaConnections } from '../helpers/connection';

async function getCurrentSocialMediaConnectionsFn() {
  return await getCurrentSocialMediaConnections();
}

export function useCurrentConnections() {
  const { showError } = useError();
  const {
    data,
    isPending,
    reload
  }: {
    data: ApiResponse<GetCurrentSocialMediaConnectionData[]>,
    isPending: boolean,
    reload: Function
  } = useAsync({
    promiseFn: getCurrentSocialMediaConnectionsFn,
    onReject: res => {
      showError(res);
    },
    onResolve: res => {
      // if promiseFn doesn't reject when there's an error (e.g. `fetch` doesn't)
      // then the error returned from API is part of the resolved data
      if (!res.success) {
        showError(res.error);
      }
    }
  });

  return { data, isPending, reload };
}
