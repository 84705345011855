// @flow
import * as React from 'react';
import classNames from 'classnames';

import './ProgressTracker.scss';

export type Props = {
  steps: React.Node,
  clickableSteps: number[],
  activeStep: number, // from 0
  onGoToStep: (index: number) => void,
  minimized: boolean,
  noNumbers: boolean,
  isLoading: boolean
};

ProgressTracker.defaultProps = {
  activeStep: 0,
  minimized: false,
  noNumbers: false,
  onGoToStep() {},
  isLoading: false
};

export default function ProgressTracker(props: Props) {
  const onGoToStep = props.onGoToStep || function (index) {};
  const steps = React.Children.map(props.steps, (step, index) => {
    const isClickable = props.clickableSteps.includes(index) && !props.isLoading;
    const className = classNames('progress-step ProgressTracker__step', {
      'is-active ProgressTracker__step--active':
        props.activeStep != null && props.activeStep === index,
      'ProgressTracker__step--clickable': isClickable
    });
    const onClick = isClickable ? () => onGoToStep(index) : null;
    return (
      <li key={`step-${index + 1}`} className={className}>
        <span
          onClick={onClick}
          className="progress-marker ProgressTracker__step-index is-unselectable">
          {index < props.activeStep ? (
            <i className="material-icons">radio_button_checked</i>
          ) : (
            index + 1
          )}
        </span>
        <span
          className="progress-text ProgressTracker__step-label is-unselectable"
          onClick={onClick}>
          {step}
        </span>
      </li>
    );
  });

  const className = classNames('ProgressTracker', {
    'ProgressTracker--minimized': props.minimized,
    'ProgressTracker--no-numbers': props.noNumbers
  });

  return (
    <div className={className}>
      <ul className="progress-tracker progress-tracker--text progress-tracker--center">{steps}</ul>
    </div>
  );
}
