import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import classNames from 'classnames';

import './TabsWithContent.scss';

class TabsWithContent extends PureComponent {
  constructor(props) {
    super(props);
    this.tabRefs = {};
    this.state = {
      tabIndex: props.defaultTab || 0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultTab !== prevProps.defaultTab) {
      const tabSelect =
        this.props.data[this.props.defaultTab] && this.props.data[this.props.defaultTab].onSelect;
      this.changeTab(this.props.defaultTab, tabSelect)();
    }
  }

  changeTab = (tabIndex, externalCallback) => {
    return () => {
      if (tabIndex < 0 || tabIndex >= this.props.data.length) {
        tabIndex = 0;
      }
      this.setState(
        {
          tabIndex
        },
        externalCallback
      );
    };
  };

  renderTabsComponent = () => {
    // Use % width rather than px, so tab automatically sizes and positions itself
    // if window size is changed
    const backgroundStyle = {
      width: `${100 / this.props.data.length}%`,
      transform: `translate3d(${this.state.tabIndex * 100}%, 0, 0)`
    };

    return (
      <ul className="TabWithContent__nav" role="tablist">
        <div className="TabWithContent__background" style={backgroundStyle} />
        {this.props.data.map((tab, index, data) => {
          const className = classNames('', {
            active: this.state.tabIndex === index,
            'is-admin': tab.isAdminTab
          });

          return (
            <li
              role="presentation"
              className={className}
              key={index}
              ref={i => (this.tabRefs[index] = i)}
              onClick={this.changeTab(index, tab.onSelect)}>
              <button className="link-button">{tab.icon}</button>
            </li>
          );
        })}
      </ul>
    );
  };

  renderActions() {
    const { actions } = this.props;

    return typeof actions === 'function'
      ? actions(this.state.tabIndex, this.props.data[this.state.tabIndex])
      : actions;
  }

  render() {
    const tab = this.props.data[this.state.tabIndex];

    const rootClassName = classNames('TabWithContent', {
      'TabWithContent--horizontal': this.props.horizontal,
      'TabWithContent--minimal': this.props.isMinimal
    });

    const contentClassName = classNames('TabWithContent__content', {
      'is-admin': tab && tab.isAdminTab
    });

    return (
      <div className={rootClassName}>
        <div className="TabWithContent__tabs">{this.renderTabsComponent()}</div>
        <div className={contentClassName}>{tab ? tab.content : ''}</div>
        {!this.props.actionsHidden && (
          <div className="TabWithContent__actions">{this.renderActions()}</div>
        )}
      </div>
    );
  }
}

TabsWithContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      content: PropTypes.element.isRequired,
      onSelect: PropTypes.func
    })
  ).isRequired,
  actions: PropTypes.any,
  defaultTab: PropTypes.number,
  horizontal: PropTypes.bool,
  isMinimal: PropTypes.bool,
  actionsHidden: PropTypes.bool
};

export default TabsWithContent;
