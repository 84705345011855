// @flow
import * as React from 'react';

import CheckIcon from '../common/Icons/CheckIcon';
import CloseIcon from '../common/Icons/CloseIcon';
import EditIcon from '../common/Icons/EditIcon';

import './EditableHeader.scss';

type Props = {
  text: string,
  suffix?: React.Node,
  onEdit: (text: string) => void
};

const EditableHeader = ({ text, suffix, onEdit }: Props) => {
  const _input: { current: null | React.ElementRef<'input'> } = React.createRef();
  const [editable, setEditable] = React.useState(false);
  const [inputText, setInputText] = React.useState('');

  React.useEffect(() => {
    setInputText(text);
  }, [text, editable]);

  React.useEffect(() => {
    if (_input.current) {
      _input.current.focus();
    }
  }, [_input]);

  const onSubmit = () => {
    setEditable(false);
    if (inputText !== text) {
      onEdit(inputText);
    }
  };

  const onToggleEdit = () => {
    setEditable(!editable);
    setInputText(editable && text ? text : '');
  };

  const onKeyUp = (ev: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      onSubmit();
    }
    if (ev.key === 'Escape') {
      onToggleEdit(); // Close
    }
  };

  const onChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    setInputText(ev.target.value);
  };

  return (
    <div className="EditableHeader">
      {!editable && (
        <span className="EditableHeader__container">
          <h2 className="subtitle">
            {text}
            {suffix}
          </h2>
          <button
            className="EditableHeader__button EditableHeader__edit link-button"
            onClick={onToggleEdit}>
            <EditIcon />
          </button>
        </span>
      )}
      {editable && (
        <span className="EditableHeader__container">
          <input
            className="subtitle"
            type="text"
            value={inputText}
            onChange={onChange}
            onKeyUp={onKeyUp}
            autoFocus={true}
            ref={_input}
          />
          <button
            className="EditableHeader__button EditableHeader__close link-button"
            onClick={onToggleEdit}>
            <CloseIcon />
          </button>
          <button
            className="EditableHeader__button EditableHeader__submit link-button"
            onClick={onSubmit}>
            <CheckIcon />
          </button>
        </span>
      )}
    </div>
  );
};

export default EditableHeader;
