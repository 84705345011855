import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import './RefreshAgreementNotification.scss';

const MADE_NEW_OFFER = 'madeNewOffer';
const DECLINED = 'declined';
const WITHDREW = 'withdrew';

const actionToMessage = {
  [MADE_NEW_OFFER]: 'campaign.agreement.hasMadeANewOffer',
  [DECLINED]: 'campaign.agreement.hasDeclinedYourOffer',
  [WITHDREW]: 'campaign.agreement.hasWithdrawnTheirOffer'
};

class RefreshAgreementNotification extends PureComponent {
  render() {
    return (
      <div className={[this.props.className, 'RefreshAgreementNotification is-flex'].join(' ')}>
        <p className="RefreshAgreementNotification__text">
          <FormattedMessage
            id={actionToMessage[this.props.action]}
            values={{ actor: <strong>{this.props.actor}</strong> }}
          />
        </p>
        <p className="RefreshAgreementNotification__button">
          <button className="button is-primary" onClick={this.props.onClick}>
            <FormattedMessage id="campaign.agreement.refreshData" />
          </button>
        </p>
      </div>
    );
  }
}

RefreshAgreementNotification.actions = {
  MADE_NEW_OFFER,
  DECLINED,
  WITHDREW
};

RefreshAgreementNotification.defaultProps = {
  onClick: () => {},
  action: MADE_NEW_OFFER,
  className: ''
};

RefreshAgreementNotification.propTypes = {
  onClick: PropTypes.func,
  actor: PropTypes.string.isRequired,
  action: PropTypes.oneOf([MADE_NEW_OFFER, DECLINED, WITHDREW]),
  className: PropTypes.string
};

export default RefreshAgreementNotification;
