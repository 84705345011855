// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import Link from 'react-router/lib/Link';

import type { ManagerTeamMember } from '../../reducers/containers/admin/adminManagerTeamPage';

import Avatar from '../../components/messages/Avatar';

import PersonIcon from '../../components/common/Icons/PersonIcon';

import Table, { Column } from '../../components/tables/Table';

import compose from '../../hoc/compose';

import hasLoading from '../../hoc/hasLoading';
import hasNoData from '../../hoc/hasNoData';

export const TeamMembers = compose(
  hasLoading(<FormattedMessage id="admin.managerTeam.members.loading" />, {
    size: 'large',
    mode: 'inline',
    centered: true
  }),
  hasNoData('members')
)(({ members }: { members: ManagerTeamMember[] }) => {
  return (
    <Table
      data={members}
      className="is-fullwidth"
      pagination
      pageLimit={12}
      searchBy={['displayName', 'email']}>
      <Column
        name="displayName"
        component={({ data, rowData }) => (
          <div className="AdminManagerTeamPage__name-with-avatar is-flex">
            <Avatar url={rowData.avatarUrl} size={32} shape="rectangle" />
            {data}
          </div>
        )}>
        <FormattedMessage id="team.member.displayName" />
      </Column>
      <Column name="email">
        <FormattedMessage id="table.headers.email" />
      </Column>
      <Column
        name="login"
        component={props => {
          const member = props.rowData;
          return (
            <Link
              to={`/admin/login-as/${RoleEnum.INFLUENCER_MANAGER}/${member.id}`}
              key="admin-login-as-influencer-manager"
              activeClassName="is-active">
              <PersonIcon />
            </Link>
          );
        }}
      />
    </Table>
  );
});
