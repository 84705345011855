import React from 'react';

const Controller = props => (
  <svg width={230} height={190} viewBox="0 0 230 190" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M114.929 66V51.162c0-2.882-.65-6.102-5.624-6.102h-5.642c-3.316 0-6.663-1.903-6.663-5.51 0-3.607 3.1-6.406 6.663-6.406h20.163c4.964 0 7.174-3.832 7.174-7.957V22"
        stroke="#EE322B"
        strokeWidth={6}
      />
      <path fill="#EE322B" d="M128 12h6v6h-6zM128 3h6v6h-6z" />
      <path
        stroke="#EE322B"
        strokeWidth={6}
        fill="#F3DCDC"
        strokeLinejoin="round"
        d="M76 56h92v51H76z"
      />
      <g transform="translate(29 29)" stroke="#EE322B" strokeWidth={6}>
        <path
          d="M.063 110.63L0 110.62l6.794-75.595 50.277 9.552-19.963 71.313C35.395 124.506 27.821 131 18.736 131 8.388 131 0 122.576 0 112.185c0-.524.021-1.042.063-1.555z"
          fill="#F3DCDC"
          strokeLinejoin="round"
        />
        <rect fill="#F3C4C4" x={15.682} y={3.84} width={32.047} height={19.472} rx={8} />
        <ellipse fill="#F3C4C4" cx={31.706} cy={39.118} rx={26.271} ry={26.382} />
        <ellipse fill="#F3C4C4" cx={57.976} cy={70.889} rx={19.024} ry={19.104} />
        <ellipse fill="#F3DCDC" cx={57.976} cy={70.889} rx={8.153} ry={8.188} />
        <path
          d="M21.453 39.365h21.816M70.453 42.365h7.574M91.453 42.365h7.574M32.159 50.42V28.806"
          strokeLinecap="square"
        />
      </g>
      <g transform="matrix(-1 0 0 1 199 29)" stroke="#EE322B" strokeWidth={6}>
        <path
          d="M.063 110.63L0 110.62l6.794-75.595 50.277 9.552-19.963 71.313C35.395 124.506 27.821 131 18.736 131 8.388 131 0 122.576 0 112.185c0-.524.021-1.042.063-1.555z"
          fill="#F3DCDC"
          strokeLinejoin="round"
        />
        <rect fill="#F3C4C4" x={15.682} y={3.84} width={32.047} height={19.472} rx={8} />
        <ellipse fill="#F3C4C4" cx={31.706} cy={39.118} rx={26.271} ry={26.382} />
        <ellipse fill="#F3C4C4" cx={57.976} cy={70.889} rx={19.024} ry={19.104} />
        <ellipse fill="#F3DCDC" cx={57.976} cy={70.889} rx={8.153} ry={8.188} />
      </g>
      <path
        d="M161.563 60v3.563H158a3 3 0 0 0-3 3v4.874a3 3 0 0 0 3 3h3.563V78a3 3 0 0 0 3 3h4.875a3 3 0 0 0 3-3v-3.563H176a3 3 0 0 0 3-3v-4.874a3 3 0 0 0-3-3h-3.563V60a3 3 0 0 0-3-3h-4.875a3 3 0 0 0-3 3z"
        stroke="#EE322B"
        strokeWidth={6}
        fill="#F3DCDC"
      />
    </g>
  </svg>
);

export default Controller;
