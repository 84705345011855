// @flow
import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

//$FlowFixMe
import { useAsync } from 'react-async';
import ActionableNotification from '../../components/common/ActionableNotification';
import Spinner from '../../components/common/Spinner';

import Table, { Column } from '../../components/tables/Table';

import callApi from '../../helpers/api';

import last from 'lodash/last';
import mean from 'lodash/mean';

import './AdminDashboardCommon.scss';

async function queryData(influencerId, path): Promise<{ data: any[] }> {
  const res = await callApi(
    `/connection-api-proxy/v1/imt_account_${influencerId}/default/facebook-graph/${path}`,
    { method: 'GET' }
  );
  if (res.data && res.data.statusCode === 200 && res.data.body) {
    return res.data.body;
  } else {
    console.error('Failed to query IG data:', res);
    throw Error('Error when querying data!');
  }
}

type IGInsight = {
  name: string,
  period: string,
  values: { value: number }[],
  title: string,
  description: string,
  id: string
};

type IGMediaObject = {
  timestamp: string,
  username: string,
  permalink: string,
  insights: { data: IGInsight[] },
  id: string
};

type IGBusinessAccount = {
  instagram_business_account: {
    media: { data: IGMediaObject[] },
    stories: { data: IGMediaObject[] },
    id: string
  }
};

function getStatForIGMedia(media: IGMediaObject, stat: string, period: string): number {
  const insights = media.insights.data.filter(i => {
    return i.name === stat && i.period === period;
  });
  if (insights.length > 0) {
    return insights[0].values[0].value;
  } else {
    return 0;
  }
}

async function fetchIGPostAndStoryData(influencerId): Promise<{ data: IGBusinessAccount[] }> {
  return queryData(
    influencerId,
    '/me/accounts?fields=instagram_business_account{media.limit(5) {timestamp, username, permalink, insights.metric(reach)}, stories{timestamp, username, permalink, insights.metric(reach)}}'
  );
}

async function getPostData(
  influencerId
): Promise<{ influencerId: number, oldestStoryReach: number, latestPostMeanReach: number }> {
  const { data } = await fetchIGPostAndStoryData(influencerId);
  if (data.length > 1) {
    console.warn('Got more than 1 channel to parse! Picking the first one.', data);
  }
  const firstChannel = data[0]; // There might be more than one!
  const stories = firstChannel.instagram_business_account.stories;
  const media = firstChannel.instagram_business_account.media;
  const oldestStory = stories ? last(stories.data) : null;
  const posts = media ? media.data : null;

  return {
    influencerId,
    oldestStoryReach: oldestStory ? getStatForIGMedia(oldestStory, 'reach', 'lifetime') : -1,
    latestPostMeanReach: Array.isArray(posts)
      ? mean(
          posts.map(post => {
            return getStatForIGMedia(post, 'reach', 'lifetime');
          })
        )
      : -1
  };
}

async function getPostDataForMultipleInfluencers([influencerIdString]) {
  const ids = influencerIdString.split(',').map(id => id.trim());
  return await Promise.all(ids.map(id => getPostData(id)));
}

export function InstagramChannelDataFetcher() {
  const [influencerId, setInfluencerId] = useState('');

  const dataFetcher = useAsync({
    deferFn: getPostDataForMultipleInfluencers
  });

  return (
    <div className="AdminDashboardGeneric container">
      <Helmet title="Instagram Channel Data Fetcher" />
      <h1 className="title">Instagram Channel Data Fetcher</h1>
      <div className="section">
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">MM Influencer Ids (comma-separated)</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="e.g. 1234"
                  onChange={e => {
                    setInfluencerId(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-narrow">
            <div className="field">
              <label className="label">&nbsp;</label>
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={() => {
                    dataFetcher.run(influencerId);
                  }}>
                  Fetch data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        {dataFetcher.isLoading ? (
          <Spinner mode="fullWidth" size="large" />
        ) : dataFetcher.error ? (
          <ActionableNotification type="danger">
            Error: {dataFetcher.error.message}
          </ActionableNotification>
        ) : (
          <>
            <Table
              className="is-fullwidth is-hoverable"
              data={dataFetcher.data || []}
              searchBy={['influencerId']}>
              <Column name="influencerId">InfluencerId</Column>
              <Column name="oldestStoryReach">oldestStoryReach</Column>
              <Column name="latestPostMeanReach">latestPostMeanReach</Column>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}
