// @flow
import React from 'react';

import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';

import { getOneChannelPerContentPlatform } from '../../../helpers/influencer';
import { getViewsFromEstimates } from '../../../helpers/youtube';

import AnalyticsIcon from '../../common/Icons/AnalyticsIcon';
import AnalyticsTab from '../../common/TabComponents/AnalyticsTab';
import DemographicsIcon from '../../common/Icons/DemographicsIcon';
import DemographicsTab from '../InfluencerCard/DemographicsTab';
import InstagramAnalyticsTab from '../tabs/InstagramAnalyticsTab';
import InstagramDemographicsTab from '../tabs/InstagramDemographicsTab';
import TwitchAnalyticsTab from '../../common/TabComponents/TwitchAnalyticsTab';

import type { ContentPlatform } from '../../../types/campaign.flow';

export function getAnalyticsTab(props: {|
  influencer: any,
  defaultContentPlatform: ContentPlatform
|}) {
  const { influencer } = props;
  const { youtubeChannel, twitchChannel, instagramChannel } = getOneChannelPerContentPlatform(
    influencer
  );

  const { defaultContentPlatform } = props;

  if (youtubeChannel && defaultContentPlatform === ContentPlatformEnum.YOUTUBE) {
    const estimatedViews = getViewsFromEstimates(youtubeChannel.estimates);
    return {
      icon: <AnalyticsIcon />,
      content: (
        <AnalyticsTab
          averageEngagementRatio={youtubeChannel.last30dStats.averageEngagementRatio}
          averageViewCount={youtubeChannel.last30dStats.averageViewCount}
          medianViewCount={youtubeChannel.last30dStats.medianViewCount}
          estimatedViews={estimatedViews}
          resonanceScore={youtubeChannel.resonanceScore}
        />
      )
    };
  }

  if (twitchChannel && defaultContentPlatform === ContentPlatformEnum.TWITCH) {
    return {
      icon: <AnalyticsIcon />,
      content: (
        <TwitchAnalyticsTab
          streamsAverageCcv={twitchChannel.last30dStats.streamsAverageCcv}
          followerCount={twitchChannel.followerCount}
          isMatureContent={twitchChannel.mature}
          broadcasterType={twitchChannel.broadcasterType}
        />
      )
    };
  }

  if (instagramChannel && defaultContentPlatform === ContentPlatformEnum.INSTAGRAM) {
    return {
      icon: <AnalyticsIcon />,
      content: (
        <InstagramAnalyticsTab
          country={influencer.country}
          followerCount={instagramChannel.followerCount}
          postCount={instagramChannel.postCount}
          engagementRate={instagramChannel.engagementRate}
        />
      )
    };
  }

  // For everything else, including special "bilibi" channel,
  // return analytics tab with what we can populate.
  return {
    icon: <AnalyticsIcon />,
    content: (
      <AnalyticsTab
        averageEngagementRatio={null}
        averageViewCount={null}
        medianViewCount={null}
        estimatedViews={null}
        resonanceScore={null}
      />
    )
  };
}

export function getDemographicsTab(influencer: any, defaultContentPlatform: ContentPlatform) {
  const { youtubeChannel, twitchChannel, instagramChannel } = getOneChannelPerContentPlatform(
    influencer
  );

  if (youtubeChannel && defaultContentPlatform === ContentPlatformEnum.YOUTUBE) {
    return {
      icon: <DemographicsIcon />,
      content: (
        <DemographicsTab demographics={youtubeChannel.demographics} influencer={influencer} />
      )
    };
  }

  if (twitchChannel && defaultContentPlatform === ContentPlatformEnum.TWITCH) {
    return {
      icon: <DemographicsIcon />,
      content: <DemographicsTab demographics={twitchChannel.demographics} influencer={influencer} />
    };
    /*
    Previously, we had a Twitch demographics tab, but it was removed because it wasn't
    working properly. We'll bring it back if we need it again.
    return {
      icon: <GamepadIcon />,
      content: <TwitchDemographicsTab relevancy={twitchChannel.last30dStats.relevancy} />
    };*/
  }

  if (instagramChannel && defaultContentPlatform === ContentPlatformEnum.INSTAGRAM) {
    return {
      icon: <DemographicsIcon />,
      content: (
        <InstagramDemographicsTab
          demographics={instagramChannel.demographics}
          country={influencer.country}
          estimatedCountry={influencer.estimatedCountry}
        />
      )
    };
  }

  // For everything else, including special "bilibi" channel,
  // return demographics tab with what we can populate.
  return {
    icon: <DemographicsIcon />,
    content: <DemographicsTab demographics={{}} influencer={influencer} />
  };
}
