// @flow

export default function isValidURL(url: mixed) {
  if (typeof url === 'string') {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
}
