import { connect, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import { disableCampaign, enableCampaign, fetchCampaigns } from '../../actions/campaign';
import { fetchTeams } from '../../actions/team';

import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import { Helmet } from 'react-helmet';

import CampaignsTable from '../../components/admin/CampaignsTable';
import Spinner from '../../components/common/Spinner';

import './AdminDashboardCampaigns.scss';
import './AdminDashboardCommon.scss';
import { AdminNoCampaigns } from './AdminNoCampaigns';

function AdminDashboardCampaigns(props) {
  const { isLoadingCampaigns, campaigns } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCampaigns.run());
    dispatch(fetchTeams.run());
  }, [dispatch]);

  if (isLoadingCampaigns) {
    return (
      <div className="AdminDashboardCampaigns">
        <Spinner mode="fullWidth" size="large" />
      </div>
    );
  }

  if (!(campaigns && campaigns.length)) {
    return (
      <div className="AdminDashboardCampaigns">
        <AdminNoCampaigns />
      </div>
    );
  }

  return (
    <div className="AdminDashboardCampaigns container">
      <Helmet title="Campaigns" />
      <CampaignsTable {...props} />
    </div>
  );
}

const mapStateToProps = generateStateToProps('adminDashboardCampaigns');

const mapDispatchToProps = generateDispatchToProps({
  enableCampaign,
  disableCampaign
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardCampaigns);
