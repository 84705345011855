// @flow
import * as React from 'react';
import Tooltip from './Tooltip';

import './InfoTooltip.scss';

type Props = {
  children: any
};

export default function InfoTooltip(props: Props) {
  const tooltipContent = <div className="InfoTooltip InfoTooltip__content">{props.children}</div>;
  return (
    <Tooltip tooltip={tooltipContent}>
      <i className="InfoTooltip InfoTooltip__icon material-icons">help</i>
    </Tooltip>
  );
}
