// @flow
import * as React from 'react';

import Big from 'big.js';

type CommonProps = {
  className?: string,
  isDisabled?: boolean
};

type StatelessProps = CommonProps & {
  value: number | string,
  // all values here are floating-point numbers
  onChangingValue: (value: number | string) => void,
  onChangedValue: (value: number | string) => void
};

type StatefulProps = CommonProps & {
  value: number, // floating-point

  // all values here are floating-point numbers
  onChangingValue: (value: number) => void,
  onChangedValue: (value: number) => void
};

export function percentageToFloatingPoint(value: number) {
  return parseFloat(new Big(value || 0).div(100));
}

function cleanupUserInput(value) {
  return value.replace(',', '.') || '';
}

// User typed something like `7.` or `.42` or `4.0`
function looksLikeIncompleteNumber(value: string) {
  return value.startsWith('.') || value.endsWith('.') || value.endsWith('.0');
}

// This component receives a floating-point number and display it as normal percentage (0.5 -> 50%)
// Everything else is in floating-point
function FloatToPercentageInput({
  value,
  className,
  onChangingValue,
  onChangedValue,
  isDisabled
}: StatelessProps) {
  const formattedValue =
    typeof value === 'string' ? value : new Big(value || 0).times(100).toPrecision();
  return (
    <input
      type="text"
      pattern="[0-9]{0,3}[,|\.]?[0-9]{0,6}?"
      className={`input ${className || ''}`}
      disabled={!!isDisabled}
      value={formattedValue}
      onChange={e => {
        if (!e.target.checkValidity()) return;
        const currentValue = cleanupUserInput(e.target.value);

        const newValue = percentageToFloatingPoint(currentValue);
        if (newValue < 0) return;

        if (looksLikeIncompleteNumber(currentValue)) {
          return onChangingValue(currentValue);
        }

        onChangingValue(newValue);
      }}
      onBlur={e => {
        const newValue = percentageToFloatingPoint(cleanupUserInput(e.target.value));
        if (newValue < 0) return;
        onChangedValue(newValue);
      }}
    />
  );
}

type State = {
  tempValue: number | string
};

function stateful(): (
  c: React.ComponentType<StatelessProps>
) => React.ComponentType<StatefulProps> {
  return C => {
    class StatefulFloatToPercentageInput extends React.PureComponent<StatefulProps, State> {
      constructor(props: StatefulProps) {
        super(props);

        this.state = {
          tempValue: props.value
        };
      }

      componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
          this.setState({
            tempValue: this.props.value
          });
        }
      }

      render() {
        const props = {
          className: this.props.className,
          value: this.state.tempValue,
          onChangingValue: value => {
            this.setState(
              {
                tempValue: value
              },
              () => {
                if (!looksLikeIncompleteNumber(value + '')) {
                  this.props.onChangingValue(parseFloat(value) || 0);
                }
              }
            );
          },

          onChangedValue: value => {
            this.setState(
              {
                tempValue: value
              },
              () => {
                this.props.onChangedValue(parseFloat(value) || 0);
              }
            );
          }
        };
        return <C {...props} />;
      }
    }

    return StatefulFloatToPercentageInput;
  };
}

export default stateful()(FloatToPercentageInput);
