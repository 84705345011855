// @flow
import type { GamePlatform } from 'matchmade-types';
import type { GameToImport } from '../../../types/game.flow';
import type { ListSortingSettings } from './index';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Platform } from '@sharkpunch/matchmade-common/game';

import MobileGame from './MobileGame';
import SteamGame from './SteamGame';
import StoreIconAndroid from '../../../images/icons/StoreIconAndroid';
import StoreIconApple from '../../../images/icons/StoreIconApple';
import StoreIconSteam from '../../../images/icons/StoreIconSteam';

import './Games.scss';

type Props = ListSortingSettings & {
  games: GameToImport[] | null,
  isLoading: boolean,
  selectedGames: GameToImport[],
  selectedPlatforms: GamePlatform[],

  onSelectGame: (game: GameToImport) => void
};

function isSelected(game: GameToImport, selectedGames: GameToImport[]) {
  return !!selectedGames.find(({ platform, id }) => {
    return platform === game.platform && id + '' === game.id + '';
  });
}

function isDisabled(platform: GamePlatform, selectedGames: GameToImport[]) {
  return !selectedGames.every(game => {
    if (platform === Platform.STEAM) return game.platform === Platform.STEAM;
    if (platform === Platform.ANDROID || platform === Platform.IOS) {
      return game.platform !== Platform.STEAM;
    }
    return true;
  });
}

function createSortingFunction(selectedGames: GameToImport[]) {
  return (a, b) => {
    const bSelected = isSelected(b, selectedGames) ? 1 : 0;
    const aSelected = isSelected(a, selectedGames) ? 1 : 0;
    return bSelected - aSelected;
  };
}

function getGameCard(Component, selectedGames, onSelect) {
  return game => {
    return (
      <Component
        {...game}
        key={`${game.platform}-${game.id}`}
        isSelected={isSelected(game, selectedGames)}
        isDisabled={isDisabled(game.platform, selectedGames)}
        onSelect={() => onSelect(game)}
      />
    );
  };
}

export default function Games(props: Props) {
  const {
    games,
    selectedGames,
    onSelectGame,
    isLoading,

    showSelectedIosGameFirst,
    showSelectedAndroidGameFirst,
    showSelectedSteamGameFirst
  } = props;
  if (!games && !selectedGames.length) return null;

  const allGames = (games || []).concat(selectedGames).filter((elem, i, arr) => {
    return arr.findIndex(game => game.id === elem.id) === i;
  });

  const sortingFunction = createSortingFunction(selectedGames);

  const selectedAndroidGames =
    !isLoading && props.selectedPlatforms.indexOf(Platform.ANDROID) !== -1;

  const androidGames = selectedAndroidGames
    ? allGames.filter(({ platform }) => platform === Platform.ANDROID)
    : [];
  if (showSelectedAndroidGameFirst) androidGames.sort(sortingFunction);

  const selectedIosGames = !isLoading && props.selectedPlatforms.indexOf(Platform.IOS) !== -1;
  const iosGames = selectedIosGames
    ? allGames.filter(({ platform }) => platform === Platform.IOS)
    : [];
  if (showSelectedIosGameFirst) iosGames.sort(sortingFunction);

  const selectedSteamGames = !isLoading && props.selectedPlatforms.indexOf(Platform.STEAM) !== -1;
  const steamGames = selectedSteamGames
    ? allGames.filter(({ platform }) => platform === Platform.STEAM)
    : [];
  if (showSelectedSteamGameFirst) steamGames.sort(sortingFunction);

  const noResults =
    (selectedAndroidGames && !androidGames.length) ||
    (selectedIosGames && !iosGames.length) ||
    (selectedSteamGames && !steamGames.length);

  return (
    <div className="Games">
      <div className="columns">
        {selectedAndroidGames && (androidGames.length || props.selectedPlatforms.length > 1) ? (
          <div className="Games__android-games is-5 column">
            <div className="Games__store-icon">
              <StoreIconAndroid />
            </div>
            {androidGames.map(getGameCard(MobileGame, selectedGames, onSelectGame))}
          </div>
        ) : null}

        {selectedIosGames && (iosGames.length || props.selectedPlatforms.length > 1) ? (
          <div className="Games__ios-games is-5 column">
            <div className="Games__store-icon">
              <StoreIconApple />
            </div>
            {iosGames.map(getGameCard(MobileGame, selectedGames, onSelectGame))}
          </div>
        ) : null}

        {selectedSteamGames && steamGames.length ? (
          <div className="Games__steam-games is-5 column">
            <div className="Games__store-icon">
              <StoreIconSteam />
            </div>
            {steamGames.map(getGameCard(SteamGame, selectedGames, onSelectGame))}
          </div>
        ) : null}
      </div>

      {noResults && (
        <div className="Games__no-results has-text-centered">
          <i className="material-icons">report</i>
          <FormattedMessage id="campaignCreation.productSelection.noResults" tagName="p" />
        </div>
      )}
    </div>
  );
}
