// @flow
import { getUser } from './user';
import setupMixpanelClient from './mixpanelClient';

export const MixpanelEvent = {
  DOWNLOAD_REPORT_PDF: 'download_report_pdf',
  OPEN_ADVANCED_ANALYTICS: 'open_advanced_analytics',
  ADVERTISER_PRESSED_SEND_OFFER_BUTTON: 'advertiser_pressed_send_offer_button',
  ADVERTISER_PRESSED_INVITE_BUTTON: 'advertiser_pressed_invite_button',
  CLICKED_FILL_INFLUENCER_PAYMENT_INFO_LINK_FROM_BANNER:
    'clicked_fill_influencer_payment_info_link_from_banner',
  CLICKED_FILL_INFLUENCER_PROFILE_LINK_FROM_BANNER:
    'clicked_fill_influencer_profile_link_from_banner',
  USE_SEARCH_TO_COLLECTION_SELECTOR: 'use_search_to_collection_selector',
  USE_SEARCH_SUGGESTION: 'use_search_suggestion',
  USE_SEARCH_FILTERS: 'use_search_filters',
  SEARCH: 'search_ui',
  PAGE_VIEW: 'pageview',
  USE_TABLE_FILTERS: 'use_table_filters',
  SIGN_UP_PLATFORM: 'sign_up_platform'
};

export function sendMixpanelEvent(name: string, params: any) {
  const currentUser = getUser();

  const isAnonymous = !currentUser || !currentUser.token;
  const client = setupMixpanelClient(isAnonymous ? null : currentUser.id);

  const baseParams = isAnonymous
    ? {}
    : {
        admin_account_id: currentUser.token.adminAccountId,
        role: currentUser.token.role
      };
  client.sendEvent(name, {
    ...baseParams,
    ...(params || {})
  });
}

export function trackDownloadReportPdf() {
  sendMixpanelEvent(MixpanelEvent.DOWNLOAD_REPORT_PDF);
}

export function trackOpenAdvancedAnalytics() {
  sendMixpanelEvent(MixpanelEvent.OPEN_ADVANCED_ANALYTICS);
}

export function trackPageview(route: string) {
  sendMixpanelEvent(MixpanelEvent.PAGE_VIEW, { route });
}
