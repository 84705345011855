// @flow
// TODO this whole component here is unstable as it needs to, not sure if upgrading
// to react-select v2 will solve any of these problems...
// - modify the Select.prototype to inject custom behaviour
// - use a combination of css pseudo selector and JS to render things (renderSelectedValue)

import * as React from 'react';
import classNames from 'classnames';

import Select from './Select';

import difference from 'lodash/difference';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import './MultiValueSelector.scss';

type OptionKey = string;

type Option = {
  key: OptionKey,
  value: string | number,
  disabled?: boolean
};

type Props = {
  options: Option[],
  selected: Option[],

  removeSelected?: boolean,

  onAdd: (options: Option[]) => void, // alpha-2
  onRemove: (options: Option[]) => void, // alpha-2
  onClear?: () => void,
  placeholder?: ?string,
  isInverted?: boolean,
  disabled?: boolean,
  // instead of listing the selected countries, show this
  label?: ?React.Element<*>,
  menuRenderer?: Function,
  className?: string
};

const DEFAULT_PROPS = Object.freeze({
  isInverted: false,
  disabled: false,
  removeSelected: false
});

// originally from https://github.com/JedWatson/react-select/blob/v1.3.0/src/utils/defaultMenuRenderer.js
function renderMenu({
  focusedOption,
  focusOption,
  inputValue,
  instancePrefix,
  onFocus,
  onOptionRef,
  onSelect,
  optionClassName,
  optionComponent,
  optionRenderer,
  options,
  removeValue,
  selectValue,
  valueArray,
  valueKey,
  labelKey
}) {
  let Option = optionComponent;

  const optionElements = options
    .map((option, i) => {
      if (!option[valueKey]) return null;

      let isSelected = valueArray && valueArray.some(x => x[valueKey] === option[valueKey]);
      let isFocused = option === focusedOption;
      let optionClass = classNames(optionClassName, {
        'Select-option': true,
        'is-selected': isSelected,
        'is-focused': isFocused,
        'is-disabled': option.disabled
      });

      return (
        <Option
          className={optionClass}
          focusOption={focusOption}
          inputValue={inputValue}
          instancePrefix={instancePrefix}
          isDisabled={option.disabled}
          isFocused={isFocused}
          isSelected={isSelected}
          key={`option-${i}-${option[valueKey]}`}
          onFocus={onFocus}
          onSelect={onSelect}
          option={option}
          optionIndex={i}
          ref={ref => {
            onOptionRef(ref, isFocused);
          }}
          removeValue={removeValue}
          selectValue={selectValue}>
          {optionRenderer(option, i, inputValue)}
        </Option>
      );
    })
    .filter(Boolean);

  const selected = valueArray.map(value => {
    return (
      <span
        key={value[valueKey]}
        className="tag is-rounded is-info MultiValueSelector__selected"
        onClick={() => {
          removeValue(value);
        }}>
        {value[labelKey]}
      </span>
    );
  });

  const selectedValues = selected.length ? (
    <div className="MultiValueSelector__selected-values">{selected}</div>
  ) : null;

  return (
    <div className="MultiValueSelector__menu">
      {selectedValues}
      <div className="MultiValueSelector__available-values">{optionElements}</div>
    </div>
  );
}

function renderSelectedValue(customLabel) {
  return (params: { value: Option, values: Option[] }) => {
    const { value, values } = params;
    const index = findIndex(values, ({ key }) => value.key === key);

    if (index !== 0) {
      return null;
    }

    if (customLabel) {
      return (
        <div className="MultiValueSelector__custom-label responsive-truncate-contents">
          {customLabel}
        </div>
      );
    }

    return (
      <span
        key="value"
        className="MultiValueSelector__selected-option-name responsive-truncate-contents">
        {values.slice(1).reduce((acc, val) => acc + ', ' + val.value, values[0].value)}
      </span>
    );
  };
}

export default function MultiValueSelector(props: Props) {
  const {
    options,
    selected,
    removeSelected,
    onAdd,
    onRemove,
    onClear,
    isInverted,
    disabled,
    placeholder,
    label,
    className,
    menuRenderer
  } = {
    ...DEFAULT_PROPS,
    ...props
  };

  function getOptionsByKeys(keys) {
    return keys
      .map(singleKey => {
        return options.find(({ key }) => {
          return singleKey === key;
        });
      })
      .filter(Boolean);
  }

  function onChange(selectedOptions: Option[]) {
    if ((!selectedOptions || !selectedOptions.length) && onClear) return onClear();

    const prevKeys = selected.map(({ key }) => key);
    const newKeys = selectedOptions.map(({ key }) => key);

    const added = difference(newKeys, prevKeys);
    const removed = difference(prevKeys, newKeys);

    // not sure if we ever have added/removed at the same time
    if (added.length) {
      onAdd(getOptionsByKeys(added));
    }

    if (removed.length) {
      onRemove(getOptionsByKeys(removed));
    }
  }

  const availableOptions = options.filter(({ key }) => {
    const selectedOptionIndex = selected.indexOf(key) !== -1;
    return !selectedOptionIndex || !removeSelected;
  });

  const selectedOptions = selected
    .map(({ key }) => {
      const selected = find(options, {
        key
      });

      if (!selected) {
        console.warn('Unknown option', selected);
        return null;
      }

      // https://github.com/JedWatson/react-select/blob/v1.3.0/src/Select.js#L1074
      // do not scroll to the first selected option when opening the menu
      return {
        ...selected,
        disabled: true
      };
    })
    .filter(Boolean);

  const topLevelClassName = classNames('MultiValueSelector', {
    'MultiValueSelector--inverted': isInverted,
    [className || '']: true
  });

  // when we have all the options selected, still add a dummy option here
  // so that we can open the menu
  if (availableOptions.length === 0) {
    availableOptions.push({
      key: 'dummy-option-key-that-should-not-match-anything-else',
      value: ''
    });
  }

  return (
    <div className={topLevelClassName}>
      <Select
        multi
        joinValues
        disabled={disabled}
        options={availableOptions}
        valueKey="key"
        labelKey="value"
        value={selectedOptions}
        placeholder={placeholder}
        onChange={onChange}
        menuRenderer={menuRenderer || renderMenu}
        valueComponent={renderSelectedValue(label)}
        clearable={false}
        backspaceRemoves={false}
        closeOnSelect={false}
        removeSelected={removeSelected}
        isInverted={isInverted}
      />
    </div>
  );
}
