import React from 'react';

import './index.scss';

const Maintenance = () => (
  <section className="Maintenance hero is-info is-fullheight">
    <div className="hero-body">
      <div className="has-text-centered">
        <p className="has-text-primary">
          <i className="material-icons">self_improvement</i>
        </p>
        <h1>Sorry, we&apos;re down for maintenance</h1>
        <p>Your data will not be affected. We should be back online shortly.</p>
        <p>
          If you have questions, please{' '}
          <a
            className="has-text-primary"
            href="mailto:info@matchmade.tv"
            target="_blank"
            rel="noopener noreferrer">
            drop us a line
          </a>
          .
        </p>
      </div>
    </div>
  </section>
);
export default Maintenance;
