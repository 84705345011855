// @flow
import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import uniqBy from 'lodash/uniqBy';

//$FlowFixMe
import { useAsync } from 'react-async';

import Table, { Column, pageLimitOptions } from '../../components/tables/Table';

import ActionableNotification from '../../components/common/ActionableNotification';
import DeleteIcon from '../../components/common/Icons/DeleteIcon';
import Spinner from '../../components/common/Spinner';

import callApi from '../../helpers/api';
import isValidURL from '../../helpers/urlValidator';

import { CsvUpload } from '../../helpers/CsvUpload';

import useConfirmService from '../../components/common/ConfirmDialog/ConfirmDialog';

import Button from '../../components/common/Button';
import Form from '../../components/forms/Form';
import Input from '../../components/forms/controls/Input';
import Modal from '../../components/common/Modal';

import './AdminDashboardCommon.scss';

type RedirectLink = {|
  id?: number,
  code: string,
  userPlatform: 'default' | 'android' | 'ios',
  redirectUrl: string
|};

type RedirectLinkApiResponse = {
  data: RedirectLink[]
};

function getLinks() {
  return callApi('/admin/redirect-link');
}

function upsertLinkApiCall(linkDataArray: RedirectLink[]): Promise<RedirectLinkApiResponse> {
  return callApi('/admin/redirect-link/bulk', { method: 'POST', body: { links: linkDataArray } });
}

function deleteLinkApiCall(linkId) {
  return callApi('/admin/redirect-link/bulk', { method: 'DELETE', body: { ids: [linkId] } });
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? text.substring(0, maxLength) + '...(truncated in UI)' : text;
}

function isInvalidLink(linkData) {
  return !linkData.code || !linkData.redirectUrl;
}

function codeFromLinkOrCode(codeOrLink) {
  const regexMatch = codeOrLink.match(/(?:http[s]?:\/\/mtchm\.de\/)(?<code>.*)/);
  return ((regexMatch || {}).groups || {}).code || codeOrLink;
}

function formDataToLink(formData) {
  return {
    code: codeFromLinkOrCode((formData.code || '').trim()),
    userPlatform: (formData.userPlatform || '').trim(),
    redirectUrl: (formData.redirectUrl || '').trim()
  };
}

function UpsertRedirectLinksForm({ upsertLink }) {
  const [codeAndUrl, setCodeAndUrl] = useState({});
  const [userPlatform, setUserPlatform] = useState('default');
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({});

  const resetFormData = () => {
    setCodeAndUrl({});
    setUserPlatform('default');
  };

  const onSubmitService = useAsync({
    deferFn: upsertLink,
    onResolve: resolveData => {
      resetFormData();
      const newLink = resolveData.data ? resolveData.data[0] : {};
      setMessage({
        type: 'info',
        text: `Successfully added redirect for https://mtchm.de/${newLink.code}. Add another one, or close the modal.`
      });
    },
    onReject: e => {
      setMessage({
        type: 'danger',
        text: `Error when adding redirect link: ${e.message || 'Unknown error'} (Request ID: ${
          e.requestId || 'Unknown'
        })`
      });
    }
  });

  const onSubmit = formValues => {
    setMessage({});
    const linkData = formDataToLink({ ...formValues, userPlatform });
    if (isInvalidLink(linkData)) {
      setMessage({ type: 'danger', text: 'Invalid link data, please double check' });
    } else {
      onSubmitService.run(linkData);
    }
  };

  return (
    <div>
      <button
        className="button is-primary"
        type="submit"
        onClick={() => {
          setShowModal(!showModal);
        }}>
        Add or Update
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          resetFormData();
          setMessage({});
          setShowModal(false);
        }}
        isBoxed={false}>
        <Form key="form" values={codeAndUrl} onSubmit={onSubmit}>
          <header className="modal-card-head">
            <p className="modal-card-title">Add or update Redirect Link</p>
          </header>
          <section className="modal-card-body">
            <p>
              To update an existing link, simply use the same code and user platform. If they match
              to an existing link it gets updated with the redirect URL, otherwise a new redirect
              will be added.
            </p>

            {message && (
              <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
            )}

            <Input
              className="field"
              name="code"
              type="text"
              placeholder="Matchmade Code or link, e.g. https://mtchm.de/ejp62"
            />
            <div className="field select">
              <select
                name="userPlatform"
                onChange={e => {
                  setUserPlatform(e.target.value);
                }}>
                <option value="default">All users</option>
                <option value="ios">iOS users</option>
                <option value="android">Android users</option>
                <option value="windows">Windows users</option>
                <option value="mac">Mac users</option>
                <option value="linux">Linux users</option>
              </select>
            </div>
            <Input className="field" name="redirectUrl" type="url" placeholder="Redirect URL" />

            <Button buttonClassName="is-primary" type="submit" loading={onSubmitService.isPending}>
              <React.Fragment>Add or update Redirect</React.Fragment>
            </Button>
          </section>
        </Form>
      </Modal>
    </div>
  );
}

function UpsertRedirectLinksFromCSV({ upsertLink }) {
  const [redirects, setRedirects] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({});

  const resetFormData = () => {
    setRedirects([]);
    setMessage({});
  };

  const onSubmitService = useAsync({
    deferFn: () => {
      return upsertLink(redirects);
    },
    onResolve: resolveData => {
      setShowReset(true);
      setMessage({
        type: 'info',
        text: `Added or updated ${resolveData.data.length} redirect links. To add more, select another CSV.`
      });
    },
    onReject: e => {
      setShowReset(true);
      setMessage({
        type: 'danger',
        text: `Error when adding redirect links: ${e.message || 'Unknown error'} (Request ID: ${
          e.requestId || 'Unknown'
        })`
      });
    }
  });

  const validateCSVRow = csvRow => {
    if (!csvRow.code || !csvRow.user_platform || !csvRow.link) {
      throw new Error('Missing code, user_platform or link');
    }
    if (isValidURL(String(csvRow.code))) {
      throw new Error(`Code can not be a link, got ${csvRow.code}`);
    }
    if (
      ['default', 'ios', 'android', 'windows', 'mac', 'linux'].indexOf(csvRow.user_platform) < 0
    ) {
      throw new Error(
        `Invalid user_platform, allowed values are default, ios, android, windows, mac and linux, got ${csvRow.user_platform}`
      );
    }
    if (!isValidURL(csvRow.link)) {
      throw new Error(
        `invalid link, it must start with either http:// or https://, got ${csvRow.link}`
      );
    }
  };

  const onCsvData = csvData => {
    setMessage({});
    setRedirects([]);
    setShowReset(false);
    let processed = [];
    for (const index in csvData) {
      try {
        validateCSVRow(csvData[index]);
      } catch (e) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: ${e.message}`
        });
        return;
      }
      const { code, user_platform, link } = csvData[index];
      processed.push({
        code: String(code).trim(),
        userPlatform: user_platform.trim(),
        redirectUrl: link.trim()
      });
    }
    setRedirects(
      uniqBy(processed, row => {
        return row.code + '_' + row.userPlatform;
      })
    );
  };

  return (
    <>
      <button
        className="button is-secondary"
        type="submit"
        onClick={() => {
          setShowModal(!showModal);
        }}>
        Add or Update from CSV
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          resetFormData();
          setMessage({});
          setShowModal(false);
        }}
        isBoxed={false}>
        <div>
          <header className="modal-card-head">
            <p className="modal-card-title">Add or update Redirect Link from CSV</p>
          </header>
          <section className="modal-card-body">
            <p>
              To update an existing link, simply use the same code and user platform. If they match
              to an existing link it gets updated with the redirect URL, otherwise a new redirect
              will be added.
            </p>
            <p className="notification is-info is-light">
              CSV needs to include columns: code, user_platform and link.
            </p>

            {message && (
              <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
            )}

            {redirects.length === 0 || showReset ? (
              <CsvUpload onData={onCsvData} />
            ) : (
              <>
                <Button
                  buttonClassName="is-primary"
                  loading={onSubmitService.isPending}
                  onClick={onSubmitService.run}>
                  <>Add or update {redirects.length} links</>
                </Button>
                <Button
                  buttonClassName="is-secondary"
                  disabled={onSubmitService.isPending}
                  onClick={() => {
                    resetFormData();
                  }}>
                  <>Clear selection</>
                </Button>
              </>
            )}

            {redirects.length > 0 && (
              <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                <Table className="is-fullwidth is-hoverable" data={redirects} searchBy={[]}>
                  <Column name="code">Code</Column>
                  <Column name="userPlatform">User Platform</Column>
                  <Column name="redirectUrl">Redirect Url</Column>
                </Table>
              </div>
            )}
          </section>
        </div>
      </Modal>
    </>
  );
}

type RedirectLinksTableProps = {
  links: RedirectLink[],
  deleteLink: (link: RedirectLink) => void,
  upsertLink: (link: RedirectLink[]) => Promise<RedirectLinkApiResponse>
};

export function RedirectLinksTable({ links, deleteLink, upsertLink }: RedirectLinksTableProps) {
  return (
    <Table
      className="is-fullwidth is-hoverable"
      data={links}
      headerControls={[
        <UpsertRedirectLinksForm key="redirectLinksForm" upsertLink={upsertLink} />,
        <div style={{ marginLeft: '0.5rem', display: 'inline' }} key="redirectLinksBulkAdd">
          <UpsertRedirectLinksFromCSV upsertLink={upsertLink} />
        </div>
      ]}
      initialSort="id"
      initialSortDirection="desc"
      pagination={links.length > pageLimitOptions[1]}
      searchBy={['code', 'redirectUrl']}>
      <Column name="id">Id</Column>
      <Column name="code">Code</Column>
      <Column name="userPlatform">User Platform</Column>
      <Column
        name="redirectUrl"
        component={props => (
          <a href={props.rowData.redirectUrl} alt={props.rowData.redirectUrl}>
            {!!props.rowData.redirectUrl && truncateText(props.rowData.redirectUrl, 90)}
          </a>
        )}>
        Redirect Url
      </Column>
      <Column
        name="actions"
        sortable={false}
        component={props => (
          <div>
            <button
              className="link-button"
              onClick={() => {
                deleteLink(props.rowData);
              }}>
              <DeleteIcon />
            </button>
          </div>
        )}></Column>
    </Table>
  );
}

function AdminDashboardRedirectLinks() {
  const linksResponse = useAsync(getLinks);
  const confirmService = useConfirmService();
  const [error, setError] = useState('');

  return (
    <div className="AdminDashboardGeneric container">
      <Helmet title="Redirect Links" />
      {linksResponse.isLoading ? (
        <Spinner mode="fullWidth" size="large" />
      ) : error || linksResponse.error ? (
        <ActionableNotification type="danger">
          An error occured: {error || linksResponse.error.message}
        </ActionableNotification>
      ) : (
        <RedirectLinksTable
          links={linksResponse.data.data}
          upsertLink={async linkDatas => {
            return upsertLinkApiCall(linkDatas).then(newLinkResponse => {
              const newLinks = newLinkResponse.data || [];
              const newLinkIds = newLinks.map(newLink => {
                return newLink.id;
              });
              const allLinksWithNew = linksResponse.data.data
                .filter(existingLink => {
                  // if we've just updated the link, make sure we don't end up with that link twice in the list
                  return newLinkIds.indexOf(existingLink.id) < 0;
                })
                .concat(newLinks);
              linksResponse.setData({
                data: allLinksWithNew
              });
              return newLinkResponse;
            });
          }}
          deleteLink={linkData => {
            confirmService
              .confirm({
                description:
                  'Are you sure you want to delete this link? Deleting the link might affect campaign tracking.',
                actionText: 'Delete link',
                actionCategory: 'danger'
              })
              .then(() => {
                // optimistically update the list of links
                linksResponse.setData({
                  data: linksResponse.data.data.filter(existingLink => {
                    return existingLink.id !== linkData.id;
                  })
                });
                deleteLinkApiCall(linkData.id).catch(error => {
                  setError(
                    `Unable to delete link ${linkData.id || 'without id'}. ${error.message}`
                  );
                });
              })
              .catch(() => {
                // no need to do anything on "cancel"
              });
          }}
        />
      )}
      <confirmService.Modal />
    </div>
  );
}

export default AdminDashboardRedirectLinks;
