// @flow
import React from 'react';

import './GameHeader.scss';
import GameCategories from './GameCategories';
import GamePlatform from './GamePlatform';
import ReactMarkdown from 'react-markdown';
import type { Game } from '../../types/game.flow';

import ANALYTICS from '../../helpers/analytics';
import GAOutboundLink from '../common/GAOutboundLink';

import clone from 'lodash/fp/clone';
import compact from 'lodash/fp/compact';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import first from 'lodash/fp/first';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import mean from 'lodash/fp/mean';
import reduce from 'lodash/fp/reduce';
import sortBy from 'lodash/fp/sortBy';
import sum from 'lodash/fp/sum';

import { FormattedMessage } from 'react-intl';

function EmptyLine() {
  return <span>&nbsp;</span>;
}

type Props = {
  stats: Object,
  bottom?: Object,
  game: Game
};

const calculateApptraceAverageRating = flow([
  map('ratings'),
  compact,
  map('overall'),
  filter(rating => {
    return rating.count > 10;
  }),
  compact,
  map('rating'),
  compact,
  mean
]);

const getApptraceRatingCount = flow([
  map(item => parseFloat(get('ratings.overall.count')(item))),
  filter(Boolean),
  sum
]);

const sortByAttributes: string[] = ['top'];

const getApplyzerCountries = flow([compact, map('rank'), sortBy(sortByAttributes), first]);

const getApplyzerRankingFlow = flow([
  filter(d => d.chart === 'ranking' && d.type === 'top'),
  map('rows'),
  flatten,
  map(row => {
    const match = /Top ([0-9]{1,3}) reached/gi.exec(row[0]);
    const top = match && parseInt(match[1], 10);

    return {
      top,
      // for applyzer, they use the same data attribute name 'rank'
      // when they do top [x] in [y] countries. In that context, 'rank'
      // is [y] countries in which the game is in top [x]
      countries: getApplyzerCountries(row.slice(1)) || null
    };
  }),
  reduce((a: any, b: Object) => {
    const currentTop = clone(find(a, { top: b.top }));
    if (currentTop) {
      currentTop.countries =
        currentTop.countries < b.countries ? b.countries : currentTop.countries;
    } else {
      a.push(b);
    }
    return a;
  }, []),
  sortBy(sortByAttributes)
]);

const renderCover = (game, stats) => {
  let className = 'column cover';
  let imageClassName = 'image';
  if (isSteamGame(game)) {
    imageClassName += ' wide';
    if (hasStat(stats, 'center')) {
      className += ' is-2';
    } else {
      className += ' is-4';
    }
  } else {
    imageClassName += ' is-128x128';
    className += ' is-2';
  }

  return (
    <div className={className}>
      <div
        className={imageClassName}
        style={{
          backgroundImage: game.logoUrl ? `url('${game.logoUrl}')` : 'none'
        }}
      />
    </div>
  );
};

const renderIntro = game => {
  let description;
  if (game.description) {
    description = <ReactMarkdown source={game.description.slice(0, 125) + '...'} />;
  }

  let platforms = Object.keys(game.storeUrls).map(platform => {
    const url = game.storeUrls[platform];
    const key = [game.id, platform].join('-');
    return (
      <GAOutboundLink
        key={key}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        eventLabel={ANALYTICS.LABEL.CLICKED_STORE_LINK_IN_GAME_HEADER}>
        <GamePlatform platform={platform} />
      </GAOutboundLink>
    );
  });

  if (!platforms.length) platforms = null;

  const categories = <GameCategories categories={game.categories} />;

  return (
    <div className="column is-4 intro">
      <div>
        <span className="subtitle GameHeader__header-text">
          <strong>{game.title}</strong>
        </span>
      </div>
      <div className="tagline">{description}</div>
      <div className="platforms">{platforms}</div>
      <div className="categories">{categories}</div>
    </div>
  );
};

const isSteamGame = game => {
  // Array of platforms for already imported games, string for similar games and such
  return Array.isArray(game.platforms)
    ? game.platforms.indexOf('steam') !== -1
    : // not sure why we are passing 2 different kinds of data structure here :(
      // $FlowFixMe
      game.platform === 'steam';
};

const getAppTraceRating = game => {
  const apptrace = game.apptrace;
  let value = 'n/a';
  // need to put this instead of null
  // to maintain the alignment
  let key = <EmptyLine />;

  if (apptrace && apptrace.length) {
    value = calculateApptraceAverageRating(apptrace);

    value = parseFloat(value.toFixed(2));
    if (!value) {
      value = 'n/a';
    } else {
      key = <FormattedMessage id="publisher.game.overallRating" />;
    }
  }

  return { key, value };
};

const getSteamReviewCount = game => {
  const steam = game.steam || {};
  let value = 'n/a';
  // need to put this instead of null
  // to maintain the alignment
  let key = <EmptyLine />;

  const reviews = steam.reviews;
  if (reviews) {
    value = reviews.total;
    if (!value) {
      value = 'n/a';
    } else {
      key = <FormattedMessage id="publisher.game.reviews" />;
    }
  }

  return { key, value };
};

const renderStat = (position: string, stat: Object) => {
  const content = React.isValidElement(stat)
    ? stat
    : [
        <div key={`game-header-${position}-stat-value`} className="stat-val">
          {stat.value}
        </div>,
        <div
          key={`game-header-${position}-stat-key`}
          className="stat-key responsive-truncate-contents">
          {stat.key}
        </div>
      ];

  return (
    <div className={`column is-2 ${position}-stat has-text-centered is-hidden-mobile`}>
      {content}
    </div>
  );
};

const renderLeftStat = (game, stats) => {
  let stat = null;
  if (stats && stats.left) {
    stat = stats.left;
  } else if (isSteamGame(game)) {
    stat = getSteamReviewCount(game);
  } else {
    stat = getAppTraceRating(game);
  }

  return renderStat('left', stat);
};

const getAppLyzerRanking = game => {
  const applyzer = game.applyzer;
  let value = 'n/a';
  // need to put this instead of null
  // to maintain the alignment
  let key = <EmptyLine />;

  // if a game is top 1 and 10 ranked in 2 and 5 countries respectively
  // pick top 1 over top 10.
  // If there are multiple categories in one "top", pick the one with highest number
  // of country. For example, a game is top 10 in 10 countries in Racing category, and
  // 20 in Puzzle category, pick 20
  // If there are multiple charts (iphone/ipad). Apply the same concept after
  // applying the "multiple categories" rule
  if (applyzer && applyzer.length) {
    const topRankingCharts = getApplyzerRankingFlow(applyzer);

    for (let i = 0; i < topRankingCharts.length; i++) {
      const chart = topRankingCharts[i];
      if (chart.countries) {
        value = `Top ${chart.top}`;
        key = (
          <FormattedMessage
            id="publisher.game.inCountries"
            values={{ countries: chart.countries }}
          />
        );
        break;
      }
    }
  }

  return { key, value };
};

const renderCenterStat = (game, stats) => {
  const hasCustomCentralStat = hasStat(stats, 'center');
  if (!hasCustomCentralStat && isSteamGame(game)) return null;
  return renderStat('center', hasCustomCentralStat ? stats.center : getAppTraceRatingCount(game));
};

const hasStat = (stats, position) => {
  return stats && stats[position];
};

const getAppTraceRatingCount = game => {
  const apptrace = game.apptrace;
  let value = 'n/a';

  // need to put this instead of null
  // to maintain the alignment
  let key = <EmptyLine />;

  if (apptrace && apptrace.length) {
    value = getApptraceRatingCount(apptrace);

    if (!value) {
      value = 'n/a';
    } else {
      key = <FormattedMessage id="publisher.game.ratings" />;
    }
  }

  return { key, value };
};

const getSteamRating = game => {
  const steam = game.steam || {};
  let value = 'n/a';
  // need to put this instead of null
  // to maintain the alignment
  let key = <EmptyLine />;

  const reviews = steam.reviews;
  if (reviews) {
    value = Math.round((reviews.positive / reviews.total) * 100);
    if (!value) {
      value = 'n/a';
    } else {
      value = `${value}%`;
      key = <FormattedMessage id="publisher.game.positive" />;
    }
  }

  return { key, value };
};

const renderRightStat = (game, stats) => {
  let stat = null;
  if (stats && stats.right) {
    stat = stats.right;
  } else if (isSteamGame(game)) {
    stat = getSteamRating(game);
  } else {
    stat = getAppLyzerRanking(game);
  }

  return renderStat('right', stat);
};

const GameHeader = (props: Props) => {
  const { stats, bottom = null, game } = props;

  return (
    <div className="GameHeader">
      <div className="profile-heading">
        <div className="columns">
          {renderCover(game, stats)}
          {renderIntro(game)}
          <div className="stat-container">
            {hasStat(stats, 'left') ? renderLeftStat(game, stats) : null}
            {hasStat(stats, 'center') ? renderCenterStat(game, stats) : null}
            {hasStat(stats, 'right') ? renderRightStat(game, stats) : null}
          </div>
        </div>
        {bottom}
      </div>
    </div>
  );
};

export default GameHeader;
