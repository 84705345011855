// @flow
import * as React from 'react';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import AttributionInfoTooltip from './AttributionInfoTooltip';
import getConfig from '../../../../config';

import {
  AttributionPartner,
  isAttributionPartnerSettingValid
} from '@sharkpunch/matchmade-common/campaign';
import PlatformSpecificInputs from './PlatformSpecificInputs';

import { AttributionPartnerOptionEnum as AttributionPartnerOption } from './AttributionPartnerOption';
import type { PlatformSpecificTrackingCodes } from './PlatformSpecificInputs';

import type { AttributionPartnerOption as Option } from './AttributionPartnerOption';

import './Adjust.scss';

const iconTooltipData = [
  { weSend: `${getConfig('appName')} campaign name`, as: 'campaign' },
  { weSend: 'YouTube channel name', as: 'adgroup' },
  { weSend: 'YouTube video name', as: 'creative' }
];

type Props = {
  showPlatformSpecificSettings: boolean,

  trackingCode: string | null,
  onChangeTrackingCode: (value: string | null) => void,

  platformSpecificTrackingCodes: PlatformSpecificTrackingCodes,
  onChangePlatformSpecificTrackingCodes: (value: PlatformSpecificTrackingCodes | null) => void,

  selectedOption: Option,
  onChangeOption: (opt: Option) => void,

  isDisabled: boolean,
  isInverted: boolean,

  intl: IntlShape
};

Adjust.defaultProps = {
  selectedOption: AttributionPartnerOption.CODE_OR_URL,
  showPlatformSpecificSettings: true,
  isDisabled: false
};

function renderRadioButtons(props: Props) {
  const buttons = [
    AttributionPartnerOption.CODE_OR_URL,
    AttributionPartnerOption.PLATFORM_SPECIFIC
  ].map(type => {
    return (
      <label key={type} className="radio" label={type}>
        <input
          onChange={() => {
            if (props.isDisabled) return;
            props.onChangeOption(type);
          }}
          checked={props.selectedOption === type}
          type="radio"
          name="option"
          disabled={props.isDisabled}
        />
        <FormattedMessage
          id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.${type}`}
        />
      </label>
    );
  });

  return <div className="control">{buttons}</div>;
}

function renderCodeOrUrlInput(props: Props) {
  if (props.selectedOption !== AttributionPartnerOption.CODE_OR_URL) {
    return null;
  }

  const hasInvalidInput = !isAttributionPartnerSettingValid({
    attributionPartner: AttributionPartner.ADJUST,
    attributionPartnerTrackingCode: props.trackingCode,
    attributionPartnerPlatformSpecificTrackingCodes: null
  });

  const inputClassName = classNames('input', {
    'is-inverted': props.isInverted,
    'is-warning': hasInvalidInput
  });

  const className = classNames('control', {
    'has-icons-right': hasInvalidInput
  });

  return (
    <div className={className}>
      <input
        className={inputClassName}
        disabled={props.isDisabled}
        defaultValue={props.trackingCode}
        placeholder={props.intl.formatMessage({
          id:
            'campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.codeOrUrl.placeholder'
        })}
        onChange={e => {
          if (props.isDisabled) return;
          props.onChangeTrackingCode(e.target.value);
        }}
        onBlur={e => {
          if (props.isDisabled) return;
          props.onChangeTrackingCode(e.target.value);
        }}
      />
      {hasInvalidInput && (
        <span className="icon is-small is-right">
          <i className="material-icons UrlControlInput__error">error</i>
        </span>
      )}
      <div className="help is-warning">
        {hasInvalidInput ? (
          <FormattedMessage id="campaignCreation.campaignDetails.advancedSetting.installAttribution.adjust.urlError" />
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  );
}

function renderPlatformSpecificInput(props: Props) {
  if (props.selectedOption !== AttributionPartnerOption.PLATFORM_SPECIFIC) {
    return null;
  }

  return (
    <PlatformSpecificInputs
      platforms={['android', 'ios']}
      isInverted={props.isInverted}
      values={props.platformSpecificTrackingCodes}
      isDisabled={props.isDisabled}
      onChange={props.onChangePlatformSpecificTrackingCodes}
    />
  );
}

const AdjustInfo = () => (
  <div className="Adjust__info-wrapper">
    <div className="Adjust__info-icon">
      <AttributionInfoTooltip list={iconTooltipData} />
    </div>
    <div className="Adjust__info-text">
      <div className="Adjust__info-line">
        <FormattedMessage
          id="publisher.campaign.attribution.adjustDescription1"
          values={{
            term: (
              <FormattedMessage tagName="strong" id="publisher.campaign.attribution.adjustTerm" />
            )
          }}
        />
      </div>
      <div className="Adjust__info-line">
        <FormattedMessage
          id="publisher.campaign.attribution.adjustDescription2"
          values={{
            link: (
              <a href="https://help.adjust.com/en/article/advanced-tracker-setup#attribution-settings">
                <FormattedMessage id="publisher.campaign.attribution.adjustDocumentation" />
              </a>
            )
          }}
        />
      </div>
    </div>
  </div>
);

function Adjust(props: Props) {
  return (
    <div className="Adjust InstallAttribution__attribution-partner">
      {renderRadioButtons(props)}
      {renderCodeOrUrlInput(props)}
      {renderPlatformSpecificInput(props)}
      <AdjustInfo />
    </div>
  );
}

export default injectIntl(Adjust);
