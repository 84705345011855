// @flow

import CampaignInfo from './tabs/CampaignInfo';
import CampaignReportVideos from './tabs/CampaignReportVideos';
import ChannelsTab from './tabs/Channels';
import DateRangePicker from '../common/DateRangePicker';
import Error from '../common/Error';
import Glossary from './tabs/Glossary';
import Link from 'react-router/lib/Link';
import React from 'react';
import Spinner from '../common/Spinner';
import Statistics from './tabs/Statistics';
import Tabs from '../common/Tabs';
import injectRoleChecking from '../../hoc/injectRoleChecking';
import injectURLQueryParameter from '../../hoc/injectURLQueryParameter';
import moment from 'moment';

import './CampaignReport.scss';

const TABS = ['campaign-info', 'statistics', 'channels', 'videos', 'glossary', 'matchmade'];

const CampaignReport = ({
  intl,
  location,
  isLoadingCampaign,
  campaign,
  campaignStatistics,
  fetchCampaignStatistics,
  isFetchingCampaignStatistics,
  urlParams,
  error,
  isWhitelabelAdmin
}) => {
  const params = new URLSearchParams(location.search);
  const startDateString = params.get('start');
  const endDateString = params.get('end');
  const activeTab = TABS.indexOf(urlParams.tab) !== -1 ? TABS.indexOf(urlParams.tab) : 0;
  const onChangeTab = index => urlParams.updateURLParameters({ tab: TABS[index] });

  const onAccept = range => {
    const [startDate, endDate] = range;
    urlParams.updateURLParameters({
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD')
    });
  };

  if (isLoadingCampaign) {
    return (
      <div className="container CampaignReport">
        <Spinner mode="fullWidth" size="large" />
      </div>
    );
  }

  const backUrl = isWhitelabelAdmin
    ? `/admin/campaigns/${campaign.id}`
    : `/dashboard/publisher/campaigns/${campaign.id}`;

  return (
    <div className="container CampaignReport">
      <Link to={backUrl}>
        &larr; {intl.formatMessage({ id: 'back' }, { title: campaign.game.title })}
      </Link>
      <Error error={error} />
      <div className="CampaignReport__header">
        <DateRangePicker
          startDate={moment(startDateString)}
          endDate={moment(endDateString)}
          onAccept={onAccept}
        />
      </div>
      <Tabs
        activeTab={activeTab}
        tabs={[
          intl.formatMessage({ id: 'campaign.report.tabs.campaignInfo' }),
          intl.formatMessage({ id: 'campaign.report.tabs.statistics' }),
          intl.formatMessage({ id: 'campaign.report.tabs.channels' }),
          intl.formatMessage({ id: 'campaign.report.tabs.videos' }),
          intl.formatMessage({ id: 'campaign.report.tabs.glossary' })
        ].filter(Boolean)}
        panels={[
          <CampaignInfo campaign={campaign} />,
          <Statistics
            fetchCampaignStatistics={fetchCampaignStatistics}
            campaignStatistics={campaignStatistics}
            isFetchingCampaignStatistics={isFetchingCampaignStatistics}
            campaign={campaign}
            startDate={startDateString}
            endDate={endDateString}
          />,
          <ChannelsTab campaignId={campaign.id} contentPlatform={campaign.contentPlatform} />,
          <CampaignReportVideos campaignId={campaign.id} />,
          <Glossary />
        ].filter(Boolean)}
        onChangeTab={onChangeTab}
      />
    </div>
  );
};

const Component = injectURLQueryParameter(CampaignReport, {
  start: '2017-01-01',
  end: '2017-01-01'
});

const ComponentWithRoleChecking = injectRoleChecking()(Component);

export default ComponentWithRoleChecking;
