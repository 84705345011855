// @flow
import type { AccountVerificationAttributes, DeleteAccountAttributes } from '../../common/account';
import type { Action } from '../../../types/action.flow';
import type { FetchInfluencersAttributes } from '../../common/influencer';

import { handleAccountVerificationActions, handleDeleteAccountActions } from '../../common/account';
import { handleFetchInfluencersActions } from '../../common/influencer';
import runMultipleReducers from '../../helpers/runMultipleReducers';

import { deleteAccount, unverifyAccount, verifyAccount } from '../../../actions/account';

type State = {
  ...$Exact<AccountVerificationAttributes>,
  ...$Exact<DeleteAccountAttributes>,
  ...$Exact<FetchInfluencersAttributes>
};

const initialState: State = Object.freeze({
  influencers: null,
  isLoadingInfluencers: true,
  isDeletingAccount: false,
  verifyingAccountId: 0,
  verifiedAccount: null,
  isChangingEmail: false,
  error: null
});

export default function adminDashboardInfluencersReducer(
  state: State = initialState,
  action: Action
): State {
  const newState = runMultipleReducers([
    handleAccountVerificationActions(),
    handleDeleteAccountActions(),
    handleFetchInfluencersActions()
  ])(state, action);

  const { influencers } = state;

  if (!influencers) {
    return newState;
  }

  switch (action.type) {
    case deleteAccount.SUCCESS:
      return {
        ...newState,
        influencers: influencers.filter(({ id }) => {
          return action.payload.id !== id;
        })
      };
    case unverifyAccount.SUCCESS:
      return {
        ...newState,
        influencers: influencers.map(influencer => {
          if (influencer.id !== action.payload.id) {
            return influencer;
          }

          return {
            ...influencer,
            account: {
              ...influencer.account,
              verified: false
            }
          };
        })
      };
    case verifyAccount.SUCCESS:
      return {
        ...newState,
        influencers: influencers.map(influencer => {
          if (influencer.id !== action.payload.id) {
            return influencer;
          }

          return {
            ...influencer,
            account: {
              ...influencer.account,
              verified: true
            }
          };
        })
      };
    default:
      return newState;
  }
}
