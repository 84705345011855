// @flow
import React from 'react';
import classNames from 'classnames';

import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import Icon from './Icon';

const MaterialIcon = (props: { className?: string, children: string }) => {
  const finalClassName = classNames(
    props.className,
    upperFirst(camelCase(props.children)) + 'Icon'
  );
  return (
    <Icon {...props} className={finalClassName}>
      {() => <i className="material-icons">{props.children}</i>}
    </Icon>
  );
};

export default MaterialIcon;
