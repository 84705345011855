import './InfluencerCardOverviewStats.scss';
import FormattedNumber, { formatters } from '../common/FormattedNumber';
import InfluencerCardStat from './InfluencerCardStat';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';

class InfluencerCardOverviewStats extends Component {
  render() {
    const { channel, stats, mode = 'row' } = this.props;

    if (!channel) return null;

    const columns = flatten(
      stats.map(
        (
          { value, defaultValue, type = 'number', label, info, defaultToZero = false },
          index,
          arr
        ) => {
          value = isFunction(value) ? value(channel) : get(channel, value);

          const column = (
            <InfluencerCardStat
              value={<FormattedNumber type={type} value={value} defaultToZero={defaultToZero} />}
              label={label}
              info={info}
            />
          );
          const className = classNames('column has-text-centered', {
            'is-half': mode === 'row',
            'is-full': mode === 'column'
          });
          return [
            <div className={className} key={`column-${index}`}>
              {column}
            </div>,
            this.props.dividers && index % 2 !== 0 && index !== arr.length - 1 ? (
              <hr key={`divider-${index}`} />
            ) : null
          ].filter(Boolean);
        }
      )
    );

    return (
      <div className="InfluencerCardOverviewStats is-multiline is-mobile columns">{columns}</div>
    );
  }
}

InfluencerCardOverviewStats.propTypes = {
  channel: PropTypes.object.isRequired,
  dividers: PropTypes.bool, // Include divider line after 2 columns
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string, // is used for get(channel, value)
        PropTypes.func // is called with channel as the first argument
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      type: PropTypes.oneOf(keys(formatters).concat('raw'))
    })
  ).isRequired
};

InfluencerCardOverviewStats.defaultProps = {
  dividers: false
};

export default InfluencerCardOverviewStats;
