// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentPlatformLabel } from '../ContentPlatform';
import type { ContentPlatform } from '../../../types/campaign.flow';

import './Platform.scss';

type Props = {
  value: ContentPlatform
};

const Platform = ({ value }: Props) => {
  return (
    <div className="field is-horizontal Platform">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaign.offer.platform" />
        </label>
      </div>
      <div className="field-body">
        <div className="field is-horizontal">
          <ContentPlatformLabel platform={value} />
        </div>
      </div>
    </div>
  );
};

export default Platform;
