import PropTypes from 'prop-types';
import React, { Component } from 'react';

import get from 'lodash/get';
import map from 'lodash/map';

import Spinner from '../common/Spinner';

import './InfluencerCardTabs.scss';
class InfluencerCardTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: get(props, 'tabs[0].key', null)
    };
  }

  onSwitchTab(tab) {
    return () => {
      this.setState({ active: tab.key }, () => {
        this.props.onChangeTab(tab);
      });
    };
  }

  shouldUseActiveStateFromTabs() {
    return !!this.props.tabs.filter(({ active }) => active !== undefined).length;
  }

  render() {
    const { active } = this.state;

    const tabs = map(this.props.tabs, tab => {
      let className = '';

      if (this.shouldUseActiveStateFromTabs()) {
        if (tab.active) {
          className = 'is-active';
        }
      } else if (active === tab.key) {
        className = 'is-active';
      }

      const disabled = tab.disabled || this.props.isLoading;

      if (disabled) {
        className += ' is-disabled';
      }

      const icon = tab.icon ? (
        <span className="icon is-small">
          <i className={`fa ${tab.icon}`} />
        </span>
      ) : null;

      const onClick = disabled ? null : this.onSwitchTab(tab);

      return (
        <li className={className.trim()} key={tab.key}>
          {/* Bulma's tabs styles work only for anchor :/ */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={onClick}>
            {icon}
            {tab.label}
          </a>
        </li>
      );
    });

    const tabContent = this.props.isLoading ? (
      <Spinner mode="inline" size="large" />
    ) : (
      this.props.tabs
        .filter(({ key, content, active }) => {
          if (this.shouldUseActiveStateFromTabs()) {
            return active;
          }
          return key === this.state.active;
        })
        .map(({ content }) => content)[0]
    );

    const renderToggles = this.props.tabs.length > 1;

    const defaultTabsClassName = [
      'tabs',
      this.props.style && `is-${this.props.style}`,
      this.props.alignment && `is-${this.props.alignment}`,
      this.props.isFullwidth && 'is-fullwidth'
    ].filter(Boolean);

    const mobileTabsClassName = defaultTabsClassName
      .concat(['is-small', 'is-hidden-desktop'])
      .join(' ');
    const desktopTabsClassName = defaultTabsClassName.concat(['is-hidden-touch']).join(' ');

    return (
      <div className={`InfluencerCardTabs ${this.props.className}`}>
        {renderToggles ? (
          <div className="InfluencerCardTabs__tabs">
            <div className={mobileTabsClassName}>
              <ul>{tabs}</ul>
            </div>
            <div className={desktopTabsClassName}>
              <ul>{tabs}</ul>
            </div>
          </div>
        ) : null}

        <div
          className={`InfluencerCardTabs__tab-content ${
            this.props.isLoading ? 'InfluencerCardTabs--loading' : ''
          }`}>
          {tabContent}
        </div>
      </div>
    );
  }
}

InfluencerCardTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      active: PropTypes.bool,
      content: PropTypes.element
    })
  ),
  onChangeTab: PropTypes.func,
  isLoading: PropTypes.bool,
  isFullwidth: PropTypes.bool,
  alignment: PropTypes.oneOf(['centered', 'right']), // default Bulma styles
  style: PropTypes.oneOf(['toggle', 'boxed'])
};

InfluencerCardTabs.defaultProps = {
  className: '',
  active: 'overview',
  tabs: [],
  onChangeTab: tab => {},
  isLoading: false,
  isFullwidth: false,
  alignment: 'centered',
  style: 'toggle'
};

export default InfluencerCardTabs;
