// @flow
import * as React from 'react';
import * as formatters from '@sharkpunch/matchmade-common/formatters';

import Tooltip from './Tooltip';

type Props = {|
  tagName?: string,
  value: number | null, // can be null or undefined
  type: 'cost' | 'percentage' | 'number',
  defaultToZero?: boolean
|};

const FormattedNumber = (props: Props) => {
  const { tagName = 'span', type, value, defaultToZero = false } = props;
  if (!formatters[type]) {
    console.error(`No formatter for type [${type}]!`);
    return value;
  }
  const element = React.createElement(
    tagName,
    { className: 'FormattedNumber' },
    formatters[type](value, { defaultToZero })
  );
  // Idiotic flow doesn't let me to assign this to a variable, because
  // then inside if clause it cannot divide value :E
  if (value && (type === 'cost' ? value > 1000 * 100 : value > 1000)) {
    const valueToFormat = type === 'cost' && value ? value / 100 : value;
    const formatter = formatters.getFormatter(type);
    const formattedValue = formatter.format(valueToFormat);
    return <Tooltip tooltip={<span>{formattedValue}</span>}>{element}</Tooltip>;
  }
  return element;
};

FormattedNumber.formatters = formatters;

export { formatters };

export default FormattedNumber;
