// @flow
import * as React from 'react';

import Icon from '../Icon';
import classNames from 'classnames';
import type { IconProps } from '../Icon';

import './Icons.scss';
const AccountIcon = (props: IconProps) => {
  const propsClassName = props.className || '';
  const className = classNames('AccountIcon', {
    [propsClassName]: !!propsClassName
  });
  return (
    <Icon {...props} className={className}>
      {() => <i className="material-icons">account_circle</i>}
    </Icon>
  );
};

export default AccountIcon;
