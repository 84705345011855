// @flow
import React from 'react';

import AdminTab from './AdminTab';
// Deal values passed to this component have commission
import type { StatefulProps as Props } from './types.flow';

import './AdminTab.scss';
import { getContentSubmission } from '../../../campaign/CampaignParticipatingCard';
// $FlowFixMe
import { useAsync } from 'react-async';

function StatefulAdminTab(props: Props) {
  const [deadline, setDeadline] = React.useState<string>(props.deadline || '');
  const { onChangedDeadline: externalOnChangedDeadline } = props;

  const { data: contentSubmission } = useAsync({
    promiseFn: getContentSubmission,
    agreementId: props.agreementId,
    agreementStatus: props.agreementStatus,
    isAdmin: true
  });

  React.useEffect(() => {
    deadline && externalOnChangedDeadline && externalOnChangedDeadline(deadline);
  }, [deadline, externalOnChangedDeadline, contentSubmission]);

  const onChangedDeadline = value => {
    setDeadline(value);
  };

  const onSubmitDeadline = value => {
    props.onSubmitDeadline && props.onSubmitDeadline(value);
  };

  const adminTabProps = {
    showPublishContent: props.showPublishContent,
    cpi: props.cpi,
    cpm: props.cpm,
    minGuarantee: props.minGuarantee,
    maxPayment: props.maxPayment,
    commissionRate: props.commissionRate,

    isDeadlineEditable: true,

    agreementId: props.agreementId,
    contentPlatform: props.contentPlatform,

    deadline,
    onChangedDeadline,
    onSubmitDeadline,
    isSubmitDeadlineDisabled: props.isSubmitDeadlineDisabled,

    contentSubmission: contentSubmission
  };

  return <AdminTab {...adminTabProps} />;
}

export function useAdminTab(props: Props) {
  const [deadline, setDeadline] = React.useState(props.deadline);
  const [hasNewDeadline, setHasNewDeadline] = React.useState(false);

  const finalProps = {
    ...props,
    deadline,
    isSubmitDeadlineDisabled: !hasNewDeadline,

    onChangedDeadline: newDeadline => {
      setHasNewDeadline(newDeadline !== props.deadline);
      setDeadline(newDeadline);
    },
    onSubmitDeadline: value => {
      props.onSubmitDeadline && props.onSubmitDeadline(value);
      setHasNewDeadline(false);
    }
  };

  return [<StatefulAdminTab {...finalProps} />, hasNewDeadline];
}

export default StatefulAdminTab;
