// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import AttributionInfoTooltip from './AttributionInfoTooltip';

import type { Props } from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import ServiceWithOneTrackingUrlOrPlatformSpecific from './ServiceWithOneTrackingUrlOrPlatformSpecific';
import getConfig from '../../../../config';

import './AttributionInfo.scss';

const appName = getConfig('appName');
const iconTooltipData = [
  { weSend: 'the campaign name', as: 'campaignname' },
  { weSend: 'YouTube channel id', as: 'site_id' },
  { weSend: 'YouTube channel name', as: 'sub_site_id' },
  { weSendHighlighted: 'video', as: 'site_category' },
  { weSendHighlighted: 'YouTube', as: 'ad_platform' },
  { weSend: 'YouTube video name', as: 'creative_id' }
];

export default function Kochava(props: Props) {
  return (
    <div className="AttributionInfo">
      <ServiceWithOneTrackingUrlOrPlatformSpecific {...props} name="kochava" />
      <div className="AttributionInfo__description">
        <AttributionInfoTooltip list={iconTooltipData} />
        <FormattedMessage
          id="publisher.campaign.attribution.kochavaDescription"
          values={{
            descriptionTerm: appName,
            link: (...chunks) => {
              return (
                <a href="https://support.kochava.com/campaign-management/create-an-install-campaign">
                  {chunks}
                </a>
              );
            },
            p: (...chunks) => {
              return <p>{chunks}</p>;
            }
          }}
        />
      </div>
    </div>
  );
}
