import React from 'react';

const YoutubeIcon = props => (
  <svg viewBox="0 0 34 24" height="100%" {...props}>
    <path
      d="M33.29 3.747C34 6.421 34 12 34 12s0 5.579-.71 8.252a4.276 4.276 0 0 1-3.006 3.031C27.632 24 17 24 17 24s-10.632 0-13.284-.717a4.276 4.276 0 0 1-3.005-3.03C0 17.578 0 12 0 12S0 6.42.71 3.747A4.276 4.276 0 0 1 3.717.717C6.368 0 17 0 17 0s10.632 0 13.284.716a4.276 4.276 0 0 1 3.005 3.031zM22 12l-9-5v10l9-5z"
      fill="#D9252A"
      fillRule="evenodd"
    />
  </svg>
);

export default YoutubeIcon;
