// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';

import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';
import type { ContentPlatform } from 'matchmade-types';

import { ContentPlatformIcon } from '../../common/ContentPlatform';
import Avatar from '../../messages/Avatar';
import FormattedNumber from '../../common/FormattedNumber';

import './CampaignCardHeader.scss';

type Props = {
  logoUrl: string,
  campaignLink: string,
  moneyInCents?: number,
  hasPublishedVideo?: boolean,
  isFixedFee?: boolean,
  campaignName: string,
  contentPlatform: ContentPlatform,
  campaignAgreementId?: number
};

const CampaignCardHeader = ({
  logoUrl,
  campaignLink,
  moneyInCents,
  hasPublishedVideo,
  isFixedFee,
  campaignName,
  // What to show here if campaign targets more than 1 platform
  contentPlatform = ContentPlatformEnum.YOUTUBE,
  campaignAgreementId = null
}: Props) => {
  const darkerBgImage = `linear-gradient(
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    )${logoUrl ? `, url(${logoUrl})` : ''}`;

  const isSteam = logoUrl && logoUrl.indexOf('steamcdn') !== -1;

  let label;
  if (hasPublishedVideo || isFixedFee) {
    label = 'campaign.card.earnings';
  } else if (campaignAgreementId) {
    label = 'campaign.card.estimatedEarnings';
  }

  return (
    <div className="CampaignCardHeader">
      <div className="CampaignCardHeader__channelType">
        <ContentPlatformIcon platform={contentPlatform} />
      </div>
      <div
        className="CampaignCardHeader__background svgBlur"
        style={{
          // We want to make background a bit darker, hence the gradient
          backgroundImage: darkerBgImage
        }}
      />
      <Link to={campaignLink}>
        <Avatar url={logoUrl} size={48} shape={isSteam ? 'rectangle' : null} />
      </Link>
      <div className="CampaignCardHeader__side-text">
        <Link to={campaignLink}>
          <strong className="CampaignCardHeader__title">{campaignName}</strong>
        </Link>
        {moneyInCents && label ? (
          <div>
            <FormattedMessage tagName="strong" id={label} />
            <span className="CampaignCardHeader__estimated-earnings-number">
              <FormattedNumber value={moneyInCents} type="cost" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CampaignCardHeader;
