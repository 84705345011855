// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { MessageType, supportedMessageTypes } from '../../constants';
import ChangedDeadlineMessage from './ChangedDeadlineMessage';
import ChatMessage from './ChatMessage';
import CommissionMessage from './CommissionMessage';
import NegotiationMessage from './NegotiationMessage';
import PublishedContentMessage from './PublishedContentMessage';
import Spinner from '../common/Spinner';

import './MessagesList.scss';

function renderMessage(MessageComponent, message) {
  if (!MessageComponent) {
    return null;
  }

  const everythingExceptChildren = omit(message, 'children');

  return (
    <MessageComponent key={message.id} {...everythingExceptChildren}>
      {message.children}
    </MessageComponent>
  );
}

type Props = {
  loading: boolean,
  messages: Object[]
};

const MessagesList = ({ loading = false, messages = [] }: Props) => {
  if (loading && !messages) {
    return (
      <Spinner mode="fullWidth" size="large">
        <FormattedMessage id="chat.loading" />
      </Spinner>
    );
  }

  // Nothing to show -- so we show nothing
  if (isEmpty(messages)) {
    return null;
  }

  const messageNodes = messages.map(message => {
    if (supportedMessageTypes.indexOf(message.type) === -1) {
      console.warn('Invalid message type, skipping...', message);
      return null;
    }

    let messageComponent = null;
    switch (message.type) {
      case MessageType.MESSAGE:
        messageComponent = ChatMessage;
        break;
      case MessageType.NEGOTIATION:
        messageComponent = NegotiationMessage;
        break;
      case MessageType.PUBLISHED_CONTENT:
        messageComponent = PublishedContentMessage;
        break;
      case MessageType.COMMISSION:
        messageComponent = CommissionMessage;
        break;
      case MessageType.CHANGED_DEADLINE:
        messageComponent = ChangedDeadlineMessage;
        break;
      default:
        break;
    }

    return renderMessage(messageComponent, message);
  });

  return <div className="MessagesList">{messageNodes}</div>;
};

export default MessagesList;
