import Immutable from 'immutable';

import { cancelSettledDeal, fetchCampaignAgreementInfo } from '../../actions/campaignAgreement';

export const handleCancelDealActions: ReducerCreator<
  { onSuccess: Reducer<any> },
  {
    isDecliningCampaign: boolean,
    error: Object | null
  }
> = callbacks => {
  const { onSuccess = (state, action) => state } = callbacks || {};

  return (state, action) => {
    switch (action.type) {
      case cancelSettledDeal.REQUEST:
        return {
          ...state,
          isCancelingDeal: true,
          error: null
        };
      case cancelSettledDeal.SUCCESS:
        return {
          ...onSuccess(state, action),
          isCancelingDeal: false,
          error: null
        };
      case cancelSettledDeal.FAILURE:
        return {
          ...state,
          isCancelingDeal: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

export default {
  // DEPRECATED
  // @Tan 2020-05-04 there is a local version in messagesPage reducer
  // move it to this file if we need to reuse the logic
  fetchCampaignAgreementInfo(state, action) {
    switch (action.type) {
      case fetchCampaignAgreementInfo.REQUEST:
        return state.set('isFetchingCampaignAgreementInfo', true);
      case fetchCampaignAgreementInfo.SUCCESS:
        return state
          .set('isFetchingCampaignAgreementInfo', false)
          .set('campaignAgreement', Immutable.fromJS(action.payload));
      case fetchCampaignAgreementInfo.FAILURE:
        return state
          .set('isFetchingCampaignAgreementInfo', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};
