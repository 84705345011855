// @flow
import * as React from 'react';

// $FlowFixMe
import { useAsync } from 'react-async';

import type { CampaignType, ContentPlatform, DealType, PromotionType } from 'matchmade-types';

import type { OfferType } from '../../../types/campaignAgreement.flow';

import type { CampaignAgreementLog } from '../../../types/campaignAgreementLog.flow';

import {
  CampaignType as CampaignTypeEnum,
  ContentPlatform as ContentPlatformEnum,
  DealType as DealTypeEnum,
  PromotionType as PromotionTypeEnum
} from '@sharkpunch/matchmade-common/campaign';
// $FlowFixMe apparently, there is no typedef for react-intl@4.x :(
import { FormattedMessage, useIntl } from 'react-intl';
import { SUGGESTED_MIN_GUARANTEE_TO_ESTIMATED_COST_RATIO } from '@sharkpunch/matchmade-common/campaignAgreement';
import { calculateYoutubePrices } from '@sharkpunch/matchmade-common/money';
import { getDealType } from '@sharkpunch/matchmade-common/campaignAgreement';
import { getEstimatedInstallsFromEstimates } from '../../../helpers/estimates';
import { getViewsFromEstimates } from '../../../helpers/youtube';
import Button, { Color } from '../Button';
import CloseIcon from '../Icons/CloseIcon';
import DateOffer from './DateOffer';
import DeleteIcon from '../Icons/DeleteIcon';
import GavelIcon from '../Icons/GavelIcon';
import InfluencerStats from './InfluencerStats';
import InfoIcon from '../Icons/InfoIcon';
import MonetaryControlInput from '../MonetaryControlInput';
import Platform from './Platform';
import classNames from 'classnames';

import { checkIfWhitelabelAdmin } from '../../../helpers/user';

import callApi from '../../../helpers/api';

import { OfferType as OfferTypeEnum } from '../../../constants';

import Toggles from './Toggles';

import type { Estimates } from '../../../types/estimates.flow';
import type { EstimatesGameType } from '../../../types/game.flow';

import './ModifyOfferOverlay.scss';

type OnMakeOfferArgs = {
  cpi?: number,
  cpm?: number,
  minGuarantee?: number,
  maxPayment?: number,
  deadline: string,
  promotionType: PromotionType,
  contentPlatform: ContentPlatform,
  freeOfCharge: boolean,
  offerType: string
};

export type Props = {
  cpi?: number,
  cpm?: number,
  promotionType: PromotionType,
  deadline?: string | null,
  isCharityCampaign?: boolean,
  minGuarantee?: number,
  maxPayment?: number,

  avatarUrl: ?string,
  displayName?: string,

  dealType?: DealType,
  dealTypes?: DealType[],

  eCpm?: number,

  estimates: ?Estimates,
  gameType: EstimatesGameType,
  isHorizontal?: boolean,
  isLoading?: boolean,
  initialSubmitEnabled?: boolean,
  averageViewCount?: number,
  defaultPromotionType: PromotionType,
  promotionTypes: PromotionType[],
  contentPlatform: ContentPlatform,
  campaignType: CampaignType,
  onMakeOffer: OnMakeOfferArgs => void,
  onHide?: () => void,
  onShowDeclineOfferModal?: () => void,
  onShowInfoForInfluencer?: () => void,
  // hideCpi is deprecated, and will be removed soon, use dealTypes instead if you want to exclude any deal type
  hideCpi: boolean,
  // control the visibility of the promotion type selector
  // for example, Instagram has only "dedicated"
  hidePromotionType?: boolean,
  takeItOrLeaveIt?: boolean,

  campaignId: number,
  influencerId: number,
  checkLatestLog: boolean
};

const DeleteButtonBlock = ({
  onClick,
  isLoading = false
}: {
  onClick: () => void,
  isLoading?: boolean
}) => {
  return (
    <div className="ModifyOfferOverlay__delete-button">
      <Button transparent onClick={onClick} disabled={isLoading}>
        <DeleteIcon />
      </Button>
    </div>
  );
};

const isNegotiationDeal = (takeItOrLeaveIt?: boolean) =>
  typeof takeItOrLeaveIt === 'boolean' && !takeItOrLeaveIt;

// Currently default is different depending on the content platform,
// but hopefully _soon_ we can default to Express for everything
const getDefaultOfferType = (contentPlatform: ContentPlatform) =>
  contentPlatform === ContentPlatformEnum.INSTAGRAM
    ? OfferTypeEnum.NEGOTIATION
    : OfferTypeEnum.EXPRESS;

const NegotiationControls = ({
  onPromotionTypeChange,
  onChangeDealType,
  onChangeOfferType,
  promotionType,
  promotionTypes,
  contentPlatform,
  dealType,
  isLoading,
  hidePromotionType,
  offerType,
  dealTypes
}: {
  onPromotionTypeChange: (value: PromotionType) => void,
  onChangeDealType: (value: DealType) => void,
  onChangeOfferType: (value: OfferType) => void,

  contentPlatform: ContentPlatform,

  promotionType: PromotionType,
  promotionTypes: PromotionType[],

  dealType: DealType,
  dealTypes: DealType[],

  offerType: OfferType,

  isLoading: boolean,
  hidePromotionType: boolean
}) => {
  const promotionTypesWithIntl = [
    { value: PromotionTypeEnum.DEDICATED, labelIntl: 'campaign.offer.promotion.dedicated' },
    { value: PromotionTypeEnum.INTEGRATION, labelIntl: 'campaign.offer.promotion.integrated' }
  ].filter(p => promotionTypes.includes(p.value));

  const dealTypesWithIntl =
    dealType === DealTypeEnum.FREE_OF_CHARGE
      ? [{ value: DealTypeEnum.FREE_OF_CHARGE, labelIntl: 'campaing.offer.deal.freeOfCharge' }]
      : [
          { value: DealTypeEnum.FIXED_FEE, labelIntl: 'campaign.offer.deal.fixedFee' },
          { value: DealTypeEnum.CPM, labelIntl: 'campaign.offer.deal.cpm' },
          { value: DealTypeEnum.CPI, labelIntl: 'campaign.offer.deal.cpi' }
        ].filter(d => dealTypes.includes(d.value));

  const agreementTypesWithIntl = [
    {
      value: OfferTypeEnum.NEGOTIATION,
      labelIntl: 'offerType.negotiation'
    },
    {
      value: OfferTypeEnum.EXPRESS,
      labelIntl: 'offerType.express'
    }
  ];

  const agreementTypes = checkIfWhitelabelAdmin() ? (
    <Toggles
      value={offerType || OfferTypeEnum.EXPRESS}
      isDisabled={isLoading}
      onChange={onChangeOfferType}
      availableValues={agreementTypesWithIntl}
      labelIntl="campaign.offer.type"
    />
  ) : null;

  return (
    <div className="ModifyOfferOverlay__negotiation-controls">
      <Platform value={contentPlatform} />
      {!hidePromotionType && (
        <Toggles
          value={promotionType}
          isDisabled={isLoading}
          onChange={onPromotionTypeChange}
          availableValues={promotionTypesWithIntl}
          labelIntl="campaign.offer.promotion"
        />
      )}
      <Toggles
        value={dealType}
        isDisabled={isLoading}
        onChange={onChangeDealType}
        availableValues={dealTypesWithIntl}
        labelIntl="campaign.offer.deal"
      />
      {agreementTypes}
    </div>
  );
};

const MoneyInputs = ({
  dealType,
  isLoading,
  cpi,
  cpm,
  minGuarantee,
  maxPayment,
  onChangeCpi,
  onChangeCpm,
  onChangeMinGuarantee,
  onChangeMaxPayment,
  placeholder,
  isError
}: {
  dealType: DealType,
  isLoading: boolean,
  cpi: number,
  cpm: number,
  minGuarantee: number | '',
  maxPayment: number | '',
  onChangeCpi: (value: number | '') => void,
  onChangeCpm: (value: number | '') => void,
  onChangeMinGuarantee: (value: number | '') => void,
  onChangeMaxPayment: (value: number | '') => void,
  placeholder: string,
  isError: boolean
}) => {
  if (dealType === DealTypeEnum.FREE_OF_CHARGE) {
    return <div className="ModifyOfferOverlay__money-inputs is-flex"></div>;
  }
  if (dealType === DealTypeEnum.FIXED_FEE) {
    return (
      <div className="ModifyOfferOverlay__money-inputs is-flex">
        <div className="ModifyOfferOverlay__fixed-fee-input">
          <FormattedMessage id="modifyOfferOverlay.fixedFee" />
          <MonetaryControlInput
            allowDecimal={false}
            isDisabled={isLoading}
            value={minGuarantee}
            onChange={onChangeMinGuarantee}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="ModifyOfferOverlay__money-inputs is-flex">
      <div className="ModifyOfferOverlay__min-guarantee-input">
        <FormattedMessage id="modifyOfferOverlay.minGuarantee" />
        <MonetaryControlInput
          allowDecimal={false}
          isDisabled={isLoading}
          placeholder={placeholder}
          value={minGuarantee}
          onChange={onChangeMinGuarantee}
        />
      </div>
      {dealType === DealTypeEnum.CPM ? (
        <div className="ModifyOfferOverlay__cpm-input">
          <FormattedMessage id="modifyOfferOverlay.cpm" />
          <MonetaryControlInput
            isDisabled={isLoading}
            value={cpm}
            isError={cpm === 0}
            onChange={onChangeCpm}
          />
        </div>
      ) : (
        <div className="ModifyOfferOverlay__cpi-input">
          <FormattedMessage id="modifyOfferOverlay.cpi" />
          <MonetaryControlInput
            isDisabled={isLoading}
            value={cpi}
            isError={cpi === 0}
            onChange={onChangeCpi}
          />
        </div>
      )}
      <div className="ModifyOfferOverlay__max-pay-input">
        <FormattedMessage id="modifyOfferOverlay.maxPayment" />
        <MonetaryControlInput
          allowDecimal={false}
          isDisabled={isLoading}
          isError={isError}
          placeholder={placeholder}
          value={maxPayment}
          onChange={onChangeMaxPayment}
        />
      </div>
    </div>
  );
};

const ActionButtons = ({
  isLoading,
  isDisabled,
  onSubmit,
  onHide = () => {},
  onShowInfoForInfluencer,
  isCharityCampaign
}: {
  isLoading: boolean,
  isDisabled: boolean,
  onSubmit: () => void,
  onHide?: () => void,
  onShowInfoForInfluencer?: () => void,
  isCharityCampaign: boolean
}) => {
  const isInfluencer = !!onShowInfoForInfluencer;
  let makeOfferMessageId = isInfluencer
    ? 'influencer.campaign.makeOffer'
    : 'influencer.campaign.sendOffer';
  if (isCharityCampaign) {
    makeOfferMessageId = isInfluencer
      ? 'influencer.campaign.makeOffer.charity'
      : 'influencer.campaign.sendOffer.charity';
  }

  return (
    <div className="ModifyOfferOverlay__make-offer">
      <Button
        loading={isLoading}
        disabled={isDisabled}
        className="MakeOffer__main-action is-marginless is-fullwidth"
        onClick={onSubmit}
        color={Color.PRIMARY}>
        <FormattedMessage id={makeOfferMessageId} tagName="strong" />
        <GavelIcon />
      </Button>
      <Button onClick={onHide} transparent className="ModifyOfferOverlay__close">
        <CloseIcon />
      </Button>
      {onShowInfoForInfluencer && (
        <Button
          size="large"
          color={Color.PRIMARY}
          key="campaign-info"
          onClick={onShowInfoForInfluencer}>
          <InfoIcon withTooltip tooltipI18nString="influencer.campaign.info" />
        </Button>
      )}
    </div>
  );
};

function getInitialState(props) {
  const cpi = props.cpi;
  const cpm = props.cpm;
  const minGuarantee = isFinite(props.minGuarantee) ? props.minGuarantee : '';
  return {
    cpi,
    cpm,
    // minGuarantee and maxPayment can be empty
    // round cents to dollars
    minGuarantee: (minGuarantee ? Math.round(minGuarantee / 100) * 100 : minGuarantee) || '',
    maxPayment: props.maxPayment || '',
    promotionType: props.promotionType || props.defaultPromotionType,
    dealType: getDealType({
      cpi: props.cpi || 0,
      cpm: props.cpm || 0,
      freeOfCharge: props.campaignType === CampaignTypeEnum.CHARITY
    }),
    offerType: isNegotiationDeal(props.takeItOrLeaveIt)
      ? OfferTypeEnum.NEGOTIATION
      : getDefaultOfferType(props.contentPlatform),
    deadline: props.deadline || null
  };
}

const fetchLatestLog = async (
  {
    campaignId,
    influencerId,
    checkLatestLog
  }: { campaignId: number, influencerId: number, checkLatestLog: boolean },
  { signal }
): Promise<CampaignAgreementLog | null> => {
  if (!checkLatestLog) {
    return null;
  }

  const res = await callApi(`/campaigns/${campaignId}/influencers/${influencerId}/latest-log`, {
    method: 'GET'
  });

  return res.data;
};

const ModifyOfferOverlay = function (props: Props) {
  const initialState = getInitialState(props);

  const isCharityCampaign = props.campaignType === CampaignTypeEnum.CHARITY;

  const [offerType, setOfferType] = React.useState(initialState.offerType);
  const [promotionType, setPromotionType] = React.useState(initialState.promotionType);
  const [dealType, setDealType] = React.useState(initialState.dealType);
  const [cpi, setCpi] = React.useState(initialState.cpi);
  const [cpm, setCpm] = React.useState(initialState.cpm);
  const [minGuarantee, setMinGuarantee] = React.useState(initialState.minGuarantee);
  const [maxPayment, setMaxPayment] = React.useState(initialState.maxPayment);
  const [deadline, setDeadline] = React.useState(initialState.deadline);

  const intl = useIntl();

  const { campaignId, influencerId, checkLatestLog = false } = props;
  const { data: latestLog, error, isPending } = useAsync(fetchLatestLog, {
    campaignId,
    influencerId,
    checkLatestLog
  });

  let canChangeOfferType = !isPending && !error;
  const defaultOfferType = getDefaultOfferType(props.contentPlatform);

  React.useEffect(() => {
    // Reset offer type if we got new log entry
    if (latestLog) {
      setOfferType(
        isNegotiationDeal(latestLog.takeItOrLeaveIt) ? OfferTypeEnum.NEGOTIATION : defaultOfferType
      );
    }
  }, [latestLog, defaultOfferType]);

  function isCurrentDealValuesUnchanged() {
    return (
      promotionType === initialState.promotionType &&
      dealType === initialState.dealType &&
      cpi === initialState.cpi &&
      cpm === initialState.cpm &&
      minGuarantee === initialState.minGuarantee &&
      maxPayment === initialState.maxPayment &&
      deadline === initialState.deadline
    );
  }

  const {
    avatarUrl,
    displayName,
    isHorizontal = false,
    isLoading = false,
    initialSubmitEnabled = false,
    hideCpi,
    hidePromotionType = false,
    averageViewCount,
    promotionTypes,
    defaultPromotionType,
    contentPlatform,
    gameType,
    estimates,
    onMakeOffer,
    onHide,
    onShowDeclineOfferModal,
    onShowInfoForInfluencer
  } = props;

  // backward compatibility, by default ALL deal types are allowed
  const dealTypes = (
    props.dealTypes || [DealTypeEnum.FIXED_FEE, DealTypeEnum.CPM, DealTypeEnum.CPI]
  ).filter(type => {
    if (hideCpi && type === DealTypeEnum.CPI) {
      return false;
    }
    return true;
  });

  const estimatedInstalls = getEstimatedInstallsFromEstimates(estimates, promotionType, gameType);
  const estimatedViews = getViewsFromEstimates(estimates);

  const {
    currentPrice // whatever the user sets
  } = calculateYoutubePrices({
    // This will be either default promotion type or promotion type coming from agreement
    defaultPromotionType: props.promotionType,
    promotionType,
    dealType,
    estimatedViews,
    estimatedInstalls,
    minGuarantee: minGuarantee === '' ? 0 : minGuarantee,
    cpi: cpi === '' ? 0 : cpi,
    cpm: cpm === '' ? 0 : cpm,
    maxPayment: maxPayment === '' ? 0 : maxPayment
  });
  const eCpm = averageViewCount && (currentPrice / averageViewCount) * 1000;

  const maxPaymentTooLow =
    dealType !== DealTypeEnum.FREE_OF_CHARGE &&
    dealType !== DealTypeEnum.FIXED_FEE &&
    !!parseFloat(maxPayment) &&
    !!parseFloat(minGuarantee) &&
    (maxPayment || 0) < (minGuarantee || 0);

  const submitButtonDisabled =
    (!initialSubmitEnabled && isCurrentDealValuesUnchanged()) ||
    !deadline ||
    (dealType !== DealTypeEnum.FREE_OF_CHARGE &&
      (maxPaymentTooLow ||
        (dealType === DealTypeEnum.CPI && isFinite(cpi) && cpi === 0) ||
        (dealType === DealTypeEnum.CPM && isFinite(cpm) && cpm === 0)));

  const submitOffer = () => {
    if (!deadline) {
      return;
    }

    const offer: OnMakeOfferArgs = {
      // We allow these to be empty strings, so make sure we always send number
      minGuarantee: minGuarantee || 0,
      maxPayment: maxPayment || 0,
      deadline,
      promotionType,
      contentPlatform,
      freeOfCharge: dealType === DealTypeEnum.FREE_OF_CHARGE,
      offerType
    };
    if (dealType === DealTypeEnum.FIXED_FEE) {
      delete offer.cpi;
      delete offer.cpm;
      delete offer.maxPayment;
    } else if (dealType === DealTypeEnum.CPI) {
      offer.cpi = cpi || 0;
    } else if (dealType === DealTypeEnum.CPM) {
      offer.cpm = cpm || 0;
    } else if (dealType === DealTypeEnum.FREE_OF_CHARGE) {
      delete offer.cpi;
      delete offer.cpm;
      delete offer.minGuarantee;
      delete offer.maxPayment;
    }
    onMakeOffer(offer);
  };

  return (
    <div
      className={classNames('ModifyOfferOverlay', {
        'ModifyOfferOverlay--horizontal': isHorizontal,
        'ModifyOfferOverlay--instagram': contentPlatform === ContentPlatformEnum.INSTAGRAM
      })}>
      {!isHorizontal && onShowDeclineOfferModal && (
        <DeleteButtonBlock onClick={onShowDeclineOfferModal} isLoading={isLoading} />
      )}
      {isHorizontal ? null : (
        <InfluencerStats
          displayName={displayName}
          avatarUrl={avatarUrl}
          estimatedViews={estimatedViews}
          estimatedInstalls={estimatedInstalls}
          eCpm={dealType === DealTypeEnum.FIXED_FEE ? eCpm : 0}
        />
      )}
      <NegotiationControls
        onPromotionTypeChange={promotionType => setPromotionType(promotionType)}
        onChangeDealType={newDealType => {
          setDealType(newDealType);
          // A bit crazy-looking if-else here, but the basic idea is that
          // we when we use minGuarantee in FIXED_FEE context, we want it to be twice
          // the minGuarantee that we suggest
          if (minGuarantee === '') {
            return;
          }
          if (newDealType !== DealTypeEnum.FIXED_FEE && dealType === DealTypeEnum.FIXED_FEE) {
            setMinGuarantee(minGuarantee * SUGGESTED_MIN_GUARANTEE_TO_ESTIMATED_COST_RATIO);
          } else if (
            dealType !== DealTypeEnum.FIXED_FEE &&
            newDealType === DealTypeEnum.FIXED_FEE
          ) {
            setMinGuarantee(minGuarantee / SUGGESTED_MIN_GUARANTEE_TO_ESTIMATED_COST_RATIO);
          }
        }}
        onChangeOfferType={newOfferType => setOfferType(newOfferType)}
        offerType={offerType}
        canChangeOfferType={canChangeOfferType}
        hidePromotionType={hidePromotionType}
        promotionType={promotionType || defaultPromotionType}
        promotionTypes={promotionTypes}
        dealType={dealType}
        dealTypes={dealTypes}
        contentPlatform={contentPlatform}
        isLoading={isLoading}
      />
      <div className="ModifyOfferOverlay__mid-section">
        <div className="ModifyOfferOverlay__suggestions">
          {dealType === DealTypeEnum.FREE_OF_CHARGE ? null : (
            <React.Fragment>
              <MoneyInputs
                isLoading={isLoading}
                dealType={dealType}
                placeholder={intl.formatMessage({ id: 'optional' })}
                isError={maxPaymentTooLow}
                cpi={cpi || 0}
                cpm={cpm || 0}
                minGuarantee={minGuarantee}
                maxPayment={maxPayment}
                onChangeCpi={value => setCpi(value)}
                onChangeCpm={value => setCpm(value)}
                onChangeMinGuarantee={value => setMinGuarantee(value)}
                onChangeMaxPayment={value => setMaxPayment(value)}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="ModifyOfferOverlay__date-offer">
        <DateOffer
          date={deadline}
          isLoading={isLoading}
          onChange={deadline => setDeadline(deadline)}
        />
        {isHorizontal && onShowDeclineOfferModal && (
          <DeleteButtonBlock onClick={onShowDeclineOfferModal} isLoading={isLoading} />
        )}
      </div>
      <ActionButtons
        isCharityCampaign={isCharityCampaign}
        isLoading={isLoading}
        isDisabled={submitButtonDisabled}
        onHide={onHide}
        onSubmit={submitOffer}
        onShowInfoForInfluencer={onShowInfoForInfluencer}
      />
    </div>
  );
};

export function useModifyOfferOverlay(props: Props): [null | React.Node, (boolean) => void] {
  const [isVisible, setIsVisible] = React.useState(false);

  if (!isVisible) {
    return [null, setIsVisible];
  }

  const finalProps = {
    ...props,
    onHide() {
      props.onHide && props.onHide();
      setIsVisible(false);
    }
  };

  return [<ModifyOfferOverlay {...finalProps} />, setIsVisible];
}

export default ModifyOfferOverlay;
