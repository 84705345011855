import chatReducer, { State as ChatsState, initialState as chatsInitialState } from './chat';
import runMultipleReducers from '../helpers/runMultipleReducers';

type State = {|
  ...ChatsState
|};

export function navigationReducer(
  state: State = {
    ...chatsInitialState
  },
  action: Action
): State {
  return runMultipleReducers([chatReducer])(state, action);
}

export default navigationReducer;
