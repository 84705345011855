import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import './CampaignPerformance.scss';
import Tooltip from '../common/Tooltip';

import { checkIfInfluencerOrManager, checkIfWhitelabelAdmin } from '../../helpers/user';

import * as formatters from '@sharkpunch/matchmade-common/formatters';
import { FormattedMessage } from 'react-intl';

import FormattedNumber from '../common/FormattedNumber';
import InfluencerCardStat from '../influencer/InfluencerCardStat';

class CampaignPerformance extends PureComponent {
  render() {
    const { agreement } = this.props;

    const { views, installs, clicks, payout, cost } = agreement.stats || {};

    const costValue = (checkIfInfluencerOrManager() ? payout : cost) / 100;
    const costOrEarnings = (
      <Tooltip
        tooltip={
          <div>
            {!checkIfInfluencerOrManager() && (
              <p>
                <FormattedMessage
                  id="chat.actionBox.campaignPerformance.cost.amount"
                  values={{ amount: formatters.costFormatter.format(cost / 100) }}
                />
              </p>
            )}
            {(checkIfInfluencerOrManager() || checkIfWhitelabelAdmin()) && (
              <p>
                <FormattedMessage
                  id="chat.actionBox.campaignPerformance.payout.amount"
                  values={{ amount: formatters.costFormatter.format(payout / 100) }}
                />
              </p>
            )}
          </div>
        }>
        <span>
          {formatters.toHumanReadableCost(costValue, {
            defaultToZero: true
          })}
        </span>
      </Tooltip>
    );

    const costLabel = checkIfInfluencerOrManager() ? (
      <FormattedMessage id="chat.actionBox.campaignPerformance.payout" />
    ) : (
      <FormattedMessage id="chat.actionBox.campaignPerformance.cost" />
    );

    return (
      <div className="CampaignPerformance is-flex level">
        <div className="level-item has-text-centered CampaignPerformance__campaign-performance">
          <strong>
            <FormattedMessage id="chat.actionBox.campaignPerformance" />
          </strong>
        </div>

        <InfluencerCardStat
          className="level-item has-text-centered"
          value={<FormattedNumber type="number" value={views} defaultToZero />}
          label={
            <FormattedMessage id="chat.actionBox.campaignPerformance.views" values={{ views }} />
          }
        />

        <InfluencerCardStat
          className="level-item has-text-centered"
          value={<FormattedNumber type="number" value={installs} defaultToZero />}
          label={
            <FormattedMessage
              id="chat.actionBox.campaignPerformance.installs"
              values={{ installs }}
            />
          }
        />

        <InfluencerCardStat
          className="level-item has-text-centered"
          value={costOrEarnings}
          label={costLabel}
        />

        <InfluencerCardStat
          className="level-item has-text-centered"
          value={<FormattedNumber type="percentage" value={installs / clicks} />}
          label={<FormattedMessage id="chat.actionBox.campaignPerformance.conversionRate" />}
        />
      </div>
    );
  }
}

CampaignPerformance.propTypes = {
  agreement: PropTypes.object.isRequired
};

CampaignPerformance.defaultProps = {};

export default CampaignPerformance;
