// @flow
import { INFLUENCER_SET_CPI } from '@sharkpunch/matchmade-common/campaignAgreement';
import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';

import type { Action, ReducerCreator, ReducerReturnType } from '../../types/action.flow';

import {
  acceptCampaign,
  declineCampaign,
  fetchCampaignEstimates,
  negotiateCampaign
} from '../../actions/campaign';
import {
  fetchCampaignAgreementInfo,
  fetchInfluencerFromCampaignAgreement
} from '../../actions/campaignAgreement';
import {
  fetchMessages,
  optimisticallyCreateNegotiationMessage,
  sendMessage
} from '../../actions/messages';

import {
  handleAcceptCampaignActions,
  handleDeclineCampaignActions,
  handleNegotiateCampaignActions,
  handleOutOfDateNegotiationMoves,
  updateInfluencerCampaignsAfterANegotiationMove
} from '../common/negotiation';
import { handleCancelDealActions } from '../common/campaignAgreement';

import runMultipleReducers from '../helpers/runMultipleReducers';

const handleFetchCampaignEstimatesActions: ReducerCreator<
  void,
  {
    isLoadingCampaignEstimates: boolean,
    campaignEstimates: any,
    error: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchCampaignEstimates.REQUEST:
        return {
          ...state,
          isLoadingCampaignEstimates: true,
          campaignEstimates: null
        };
      case fetchCampaignEstimates.SUCCESS:
        return {
          ...state,
          isLoadingCampaignEstimates: false,
          campaignEstimates: action.payload
        };
      case fetchCampaignEstimates.FAILURE:
        return {
          ...state,
          isLoadingCampaignEstimates: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleFetchCampaignAgreementInfoActions: ReducerCreator<
  void,
  {
    isFetchingCampaignAgreementInfo: boolean,
    // TODO @Tan 2020-05-04 define proper type
    campaignAgreement: any,
    error: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchCampaignAgreementInfo.REQUEST:
        return {
          ...state,
          isFetchingCampaignAgreementInfo: true
        };
      case fetchCampaignAgreementInfo.SUCCESS:
        return {
          ...state,
          isFetchingCampaignAgreementInfo: false,
          campaignAgreement: action.payload
        };
      case fetchCampaignAgreementInfo.FAILURE:
        return {
          ...state,
          isFetchingCampaignAgreementInfo: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

// Here we hook up to negotiate API call and generate "fake" message
// so that we can update UI immediately
const handleOptimisticallyCreateNegotiationMessageAction: ReducerCreator<
  void,
  {
    // TODO @Tan 2020-05-04 define proper type
    messages: any[] | null,
    // TODO @Tan 2020-05-04 define proper type
    campaignAgreement: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case optimisticallyCreateNegotiationMessage.SUCCESS:
        const payload = action.payload;
        const {
          influencerCpi = 0,
          publisherCpi = 0,
          influencerMinGuarantee = 0,
          publisherMinGuarantee = 0,
          influencerMaxPayment = 0,
          publisherMaxPayment = 0,
          influencerCpm = 0,
          publisherCpm = 0,
          status = INFLUENCER_SET_CPI,
          promotionType
        } = payload;

        const { campaignAgreement, messages } = state;

        if (!campaignAgreement || !messages) {
          return state;
        }

        const { author, cpi, minGuarantee } = campaignAgreement;

        const newNegotiationMessage = {
          id: uniqueId(),
          content: status,
          author,
          role: publisherCpi || publisherMinGuarantee ? 'publisher' : 'influencer',
          type: 'negotiation',
          created: new Date().toISOString(),
          dealItem: null,
          dealValues: {
            influencerCpi,
            publisherCpi,
            influencerMinGuarantee,
            publisherMinGuarantee,
            influencerMaxPayment,
            publisherMaxPayment,
            influencerCpm,
            publisherCpm,
            cpi,
            minGuarantee,
            promotionType
          }
        };

        return {
          ...state,
          messages: [newNegotiationMessage].concat(messages)
        };
      default:
        return state;
    }
  };
};

const handleSendMessageActions: ReducerCreator<
  void,
  {
    // TODO @Tan 2020-05-04 define proper type
    messages: any[] | null,
    isSendingMessage: boolean,
    // TODO @Tan 2020-05-04 define proper type
    sendMessageError: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case sendMessage.REQUEST:
        return {
          ...state,
          isSendingMessage: true,
          sendMessageError: null
        };
      case sendMessage.SUCCESS:
        const { messages } = state;

        if (!messages) {
          return state;
        }

        return {
          ...state,
          isSendingMessage: false,
          sendMessageError: null,
          messages: [action.payload].concat(messages)
        };
      case sendMessage.FAILURE:
        return {
          ...state,
          isSendingMessage: false,
          sendMessageError: action.payload
        };
      default:
        return state;
    }
  };
};

const handleFetchMessagesActions: ReducerCreator<
  void,
  {
    isFetchingMessages: boolean,
    // TODO @Tan 2020-05-04 define proper type
    messages: any[] | null,
    // TODO @Tan 2020-05-04 define proper type
    error: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchMessages.REQUEST:
        return {
          ...state,
          isFetchingMessages: true
        };
      case fetchMessages.SUCCESS:
        const messages = action.payload || [];
        return {
          ...state,
          isFetchingMessages: false,
          messages
        };
      case fetchMessages.FAILURE:
        return {
          ...state,
          isFetchingMessages: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleFetchInfluencerFromCampaignAgreementActions: ReducerCreator<
  void,
  {
    isLoadingInfluencer: boolean,
    // TODO @Tan 2020-05-04 define proper type
    influencer: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchInfluencerFromCampaignAgreement.REQUEST:
        return {
          ...state,
          isLoadingInfluencer: true
        };
      case fetchInfluencerFromCampaignAgreement.SUCCESS:
        return {
          ...state,
          isLoadingInfluencer: false,
          influencer: action.payload
        };
      case fetchInfluencerFromCampaignAgreement.FAILURE:
        return {
          ...state,
          isLoadingInfluencer: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleSuccessfulNegotiationMoves: ReducerCreator<
  void,
  {
    campaignAgreement: any | null
  }
> = () => {
  return (state, action) => {
    switch (action.type) {
      case negotiateCampaign.SUCCESS:
      case acceptCampaign.SUCCESS:
      case declineCampaign.SUCCESS:
        const { campaignAgreement } = state;

        if (!campaignAgreement) {
          return state;
        }

        return {
          ...state,
          // @Tan 2020-05-04 merge mutates the (first) object, but we still
          // want to create another object here to make it clear that the object has changed (different memory address)
          campaignAgreement: {
            ...merge(campaignAgreement, action.payload)
          }
        };
      default:
        return state;
    }
  };
};

export type State = {|
  ...$Exact<ReducerReturnType<typeof handleAcceptCampaignActions>>,
  ...$Exact<ReducerReturnType<typeof handleDeclineCampaignActions>>,
  ...$Exact<ReducerReturnType<typeof handleNegotiateCampaignActions>>,
  ...$Exact<ReducerReturnType<typeof handleFetchCampaignEstimatesActions>>,
  ...$Exact<ReducerReturnType<typeof handleFetchCampaignAgreementInfoActions>>,
  ...$Exact<ReducerReturnType<typeof handleOptimisticallyCreateNegotiationMessageAction>>,
  ...$Exact<ReducerReturnType<typeof handleSendMessageActions>>,
  ...$Exact<ReducerReturnType<typeof handleFetchMessagesActions>>,
  ...$Exact<ReducerReturnType<typeof handleFetchInfluencerFromCampaignAgreementActions>>,
  ...$Exact<ReducerReturnType<typeof handleSuccessfulNegotiationMoves>>
|};

const initialState: State = Object.freeze({
  error: null,

  isNegotiatingCampaign: false,
  createdCampaignAgreement: null,
  creatingCampaignAgreement: null,

  isAcceptingCampaign: false,
  acceptedCampaignAgreement: null,
  acceptingCampaignAgreement: null,

  isDecliningCampaign: false,
  decliningCampaignAgreement: null,

  isLoadingCampaignEstimates: false,
  campaignEstimates: null,

  isFetchingCampaignAgreementInfo: false,
  campaignAgreement: null,

  isFetchingMessages: true,
  messages: null,

  isSendingMessage: false,
  sendMessageError: null,

  isLoadingInfluencer: false,
  influencer: null
});

const handleStateTransition = runMultipleReducers([
  handleCancelDealActions({
    onSuccess: updateInfluencerCampaignsAfterANegotiationMove
  }),
  handleAcceptCampaignActions({
    onSuccess: updateInfluencerCampaignsAfterANegotiationMove
  }),
  handleDeclineCampaignActions({
    onSuccess: updateInfluencerCampaignsAfterANegotiationMove
  }),
  handleNegotiateCampaignActions({
    onSuccess: updateInfluencerCampaignsAfterANegotiationMove
  }),
  handleFetchCampaignEstimatesActions(),
  handleFetchCampaignAgreementInfoActions(),
  handleOptimisticallyCreateNegotiationMessageAction(),
  handleSendMessageActions(),
  handleFetchMessagesActions(),
  handleFetchInfluencerFromCampaignAgreementActions(),
  handleSuccessfulNegotiationMoves(),
  handleOutOfDateNegotiationMoves({
    getAgreementsFromState(state: State) {
      const { campaignAgreement } = state;

      if (!campaignAgreement) {
        return null;
      }

      return [campaignAgreement];
    },
    setAgreementsInState(state: State, agreements) {
      const { campaignAgreement } = state;
      if (!campaignAgreement) {
        return state;
      }

      if (agreements.length) {
        return state;
      }

      return {
        ...state,
        campaignAgreement: agreements[0]
      };
    }
  })
]);

export default function messagesPageReducer(state: State = initialState, action: Action): State {
  return handleStateTransition(state, action);
}
