import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { Color } from '../common/Button';
import CloseIcon from '../common/Icons/CloseIcon';
import Modal from '../common/Modal';

function getRevokePlatformLink(contentPlatform) {
  switch (contentPlatform?.toLowerCase()) {
    case 'youtube':
      return 'https://myaccount.google.com/permissions?continue=https%3A%2F%2Fmyaccount.google.com%2Fsecurity%3Fpli%3D1';
    case 'instagram':
      return 'https://www.facebook.com/settings?tab=business_tools';
    case 'twitch':
      return 'https://www.twitch.tv/settings/connections';
    default:
      return '';
  }
}

const RevokeAccessModal = ({ isOpen, onClose, contentPlatform }) => {
  if (!contentPlatform) {
    return null;
  }
  const link = getRevokePlatformLink(contentPlatform);

  return (
    <Modal isOpen={isOpen} className="RevokeAccessModal" onClose={onClose} isBoxed={false}>
      <header className="modal-card-head">
        <h3>
          <FormattedMessage
            id={'user.settings.revokeAccessModalText.' + contentPlatform.toLowerCase()}
            tagName="strong"
            defaultMessage="user.settings.revokeAccessModalText.default"
          />
        </h3>
      </header>
      <section className="modal-card-body">
        <p className="tagline">
          <FormattedMessage
            id={'user.settings.revokeAccessModalTextBody.' + contentPlatform.toLowerCase()}
            defaultMessage="user.settings.revokeAccessModalTextBody.default"
          />
        </p>
        <p>
          <FormattedMessage
            id={'user.settings.revokeAccessTooltip.' + contentPlatform.toLowerCase()}
            defaultMessage="user.settings.revokeAccessTooltip.default"
          />
        </p>
      </section>
      <footer className="modal-card-foot">
        <Button
          color={Color.PRIMARY}
          onClick={() => {
            window.open(link, '_blank');
            onClose();
          }}
          className="action_button">
          <FormattedMessage id="user.settings.revokeAccessModalButton" tagName="strong" />
        </Button>
        <Button color={Color.PRIMARY} size="large" onClick={onClose} className="close_button">
          <CloseIcon />
        </Button>
      </footer>
    </Modal>
  );
};

RevokeAccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RevokeAccessModal;
