export function numberToFormattedValue(number, rounded) {
  number = parseFloat(number);

  if (isNaN(number)) {
    return null;
  }

  const percentage = number * 100;

  return rounded ? Math.round(percentage * 100) / 100 : percentage;
}
