// @flow
import * as React from 'react';

import debounce from 'lodash/debounce';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

// we store the name of the not supported attribution partner to trackingCode
// TODO: change the backend to support 'other' (just ignore it in the tracking)
export type Props = {
  trackingCode: ?string,
  onChangeTrackingCode: (value: string) => void,

  isDisabled: boolean,
  isInverted: boolean,

  intl: IntlShape
};

let debouncedOnChangeTrackingCode;

function renderInput(props: Props) {
  if (!debouncedOnChangeTrackingCode) {
    debouncedOnChangeTrackingCode = debounce(props.onChangeTrackingCode, 500);
  }

  const { isDisabled, isInverted } = props;

  return (
    <div className="control">
      <input
        className={`input ${isInverted ? 'is-inverted' : ''}`}
        disabled={isDisabled}
        onChange={e => {
          if (isDisabled) return;
          debouncedOnChangeTrackingCode(e.target.value);
        }}
        onBlur={e => {
          if (isDisabled) return;
          props.onChangeTrackingCode(e.target.value);
        }}
      />
    </div>
  );
}

const Other = function (props: Props) {
  const contactEmail = props.intl.formatMessage({ id: 'contactEmail' });
  return (
    <div className="Other InstallAttribution__attribution-partner">
      <label className="label">
        <FormattedMessage id="campaignCreation.campaignDetails.advancedSetting.installAttribution.other.label" />
      </label>
      {renderInput(props)}
      <FormattedMessage
        tagName="p"
        id="campaignCreation.campaignDetails.advancedSetting.installAttribution.other.instruction"
        values={{
          email: (
            <a href={contactEmail} target="_blank" rel="noopener noreferrer">
              {contactEmail}
            </a>
          )
        }}
      />
    </div>
  );
};

export default injectIntl(Other);
