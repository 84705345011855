import React, { Component } from 'react';

import PropTypes from 'prop-types';

import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { FormattedMessage } from 'react-intl';

import { countryCodes, generateTopCountries } from '@sharkpunch/matchmade-common/countryCodes';

import InfluencerCardStat from './InfluencerCardStat';

import FormattedNumber from '../common/FormattedNumber';

import './InfluencerCardDetailsStats.scss';

const AGE_GROUP_LABELS = {
  'age13-17': '13-17',
  'age18-24': '18-24',
  'age25-34': '25-34',
  'age35-44': '35-44',
  'age45-54': '45-54',
  'age55-64': '55-64',
  'age65-': '65+'
};

// Demographics stuff
class InfluencerCardDetailsStats extends Component {
  renderAgeGroupTable() {
    let ageGroup = get(this.props.channel, 'demographics.ageGroup');

    if (isEmpty(ageGroup)) return null;

    ageGroup = sortBy(ageGroup, entry => {
      return entry.name;
    });

    const labels = ageGroup.map(entry => {
      return AGE_GROUP_LABELS[entry.name] || 'Other';
    });

    const values = ageGroup.map(entry => entry.value);

    return (
      <table className="table is-narrow is-fullwidth">
        <thead>
          <tr>
            <td>
              <FormattedMessage id="influencer.card.stats.age" />
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={i}>
                <td>{label}</td>
                <td>{values[i].toFixed(1)}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderCountryTable() {
    let country = get(this.props.channel, 'demographics.country');

    if (isEmpty(country)) return null;

    const top = generateTopCountries(country, 6, 0);
    const labels = top.map(entry => {
      const c = countryCodes[entry.name.toUpperCase()];
      if (c) return c;
      return entry.name;
    });

    const values = top.map(entry => {
      if (entry.value < 1) return '< 1';
      return entry.value.toFixed(1);
    });

    return (
      <table className="table is-narrow is-fullwidth">
        <thead>
          <tr>
            <td>
              <FormattedMessage id="influencer.card.stats.country" />
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {labels.map((label, i) => {
            return (
              <tr key={i}>
                <td
                  className="responsive-truncate-contents InfluencerCard__country-name"
                  title={label}>
                  {label}
                </td>
                <td>{values[i]}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderGenderPercentage() {
    const demographics = (this.props.channel || {}).demographics || {};
    const gender = demographics.gender || [];

    const male = find(gender, { name: 'male' });
    const female = find(gender, { name: 'female' });

    if (!male && !female) return null;

    const malePercentage = male ? male.value / 100 : null;
    const femalePercentage = female ? female.value / 100 : null;

    return (
      <div className="columns has-text-centered is-mobile">
        <div className="column is-half has-text-centered">
          <InfluencerCardStat
            value={<FormattedNumber type="percentage" value={malePercentage} fractionDigits={1} />}
            label={<FormattedMessage id="influencer.card.stats.male" />}
          />
        </div>

        <div className="column is-half has-text-centered">
          <InfluencerCardStat
            value={
              <FormattedNumber type="percentage" value={femalePercentage} fractionDigits={1} />
            }
            label={<FormattedMessage id="influencer.card.stats.female" />}
          />
        </div>
      </div>
    );
  }

  renderOsPercentage() {
    const demographics = (this.props.channel || {}).demographics || {};
    const osSplit = demographics.operatingSystem;

    // No data
    if (isEmpty(osSplit)) return null;

    const ios = find(osSplit, { name: 'IOS' });
    const android = find(osSplit, { name: 'ANDROID' });
    const computerPlatforms = ['MACINTOSH', 'WINDOWS', 'LINUX'];
    const computers = osSplit.reduce((rate, platform) => {
      if (computerPlatforms.indexOf(platform.name) === -1) return rate;
      return rate + platform.value;
    }, 0);

    const iosPercentage = ios ? ios.value / 100 : null;
    const androidPercentage = android ? android.value / 100 : null;
    const pcPercentage = computers / 100;

    const stats = [
      {
        value: iosPercentage,
        label: <FormattedMessage id="platform.ios" />,
        key: 'ios'
      },
      {
        value: androidPercentage,
        label: <FormattedMessage id="platform.android" />,
        key: 'android'
      },
      {
        value: pcPercentage,
        label: <FormattedMessage id="platform.pc" />,
        key: 'pc'
      }
    ].map(({ value, label, key }) => {
      return (
        <div key={key} className="column is-4">
          <InfluencerCardStat
            value={<FormattedNumber type="percentage" value={value} fractionDigits={1} />}
            label={label}
          />
        </div>
      );
    });

    return <div className="columns has-text-centered is-mobile">{stats}</div>;
  }

  render() {
    return (
      <div className="InfluencerCardDetailsStats">
        <div className="columns has-text-centered is-mobile">
          <div key="age" className="column is-4">
            {this.renderAgeGroupTable()}
          </div>

          <div key="country" className="column is-8">
            {this.renderCountryTable()}
          </div>
        </div>
        {this.renderGenderPercentage()}
        {this.renderOsPercentage()}
      </div>
    );
  }
}

InfluencerCardDetailsStats.propTypes = {
  channel: PropTypes.object.isRequired
};

export default InfluencerCardDetailsStats;
