// @flow
/**
 * HOC to display "No data" component if
 * it has nothing to display.
 * NOTE! You _have_to use this together with hasLoading hoc,
 * or in some other way pass "isLoading" prop, otherwise
 * we won't know whether we've loaded our data already or not
 * (and this hoc behaves differently depending on these).
 *
 * Pass it along with other hocs, and make sure that
 * you set dataProp on the component, so we know
 * what to check for
 *
 * Example:
 *
 * ```
 const TeamMembers = compose(
 isLoading,
 hasNoData('members'),
 injectIntl
 )(({ teamName, membersCount, intl }: { members: TeamMembers[] | null, intl: any }) => (
 <h2 className="subtitle">
    {members}
 </h2>
 ));

 ....
 <TeamMembers isLoading={isTeamLoading} members={......} />
 ```
 */

import * as React from 'react';

import type { ComponentType } from 'react';

import NoData from '../components/common/NoData';

import getDisplayName from './getDisplayName';

function hasNoData(dataProp: any) {
  return function (WrappedComponent: ComponentType<*>) {
    return class extends React.Component<{
      isLoading: boolean
    }> {
      static displayName = getDisplayName('HasNoData', WrappedComponent);

      render() {
        const data = this.props[dataProp];
        if (
          !this.props.isLoading &&
          (!data || (Array.isArray(data) && !data.length) || !Object.keys(data).length)
        ) {
          return <NoData />;
        }

        return <WrappedComponent {...this.props} />;
      }
    };
  };
}

export default hasNoData;
