// @flow
import Immutable from 'immutable';

import { fetchManagers, search } from '../../actions/manager';

export default {
  fetchManagers(state: any, action: any) {
    switch (action.type) {
      case fetchManagers.REQUEST:
        return state.set('isLoadingManagers', true);
      case fetchManagers.SUCCESS:
        return state
          .set('isLoadingManagers', false)
          .set('managers', Immutable.fromJS(action.payload));
      case fetchManagers.FAILURE:
        return state.set('isLoadingManagers', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  search(state: any, action: any) {
    switch (action.type) {
      case search.REQUEST:
        return state.set('searchingWarehouse', true);

      case search.SUCCESS:
        return state
          .set('searchingWarehouse', false)
          .set('searchResults', Immutable.fromJS(action.payload));

      case search.FAILURE:
        return state
          .set('searchingWarehouse', false)
          .set('error', Immutable.fromJS(Immutable.fromJS(action.payload)))
          .set('searchResults', Immutable.fromJS([]));

      default:
        return state;
    }
  }
};
