import React from 'react';

const MTurkEmailIcon = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Icon-/-Mturk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M10.5030329,4 C10.4906884,4.36008463 10.5160458,4.72164457 10.5787706,5.07869327 C10.7610447,6.11625341 11.259106,7.11480631 12.0578645,7.91356476 C12.7421525,8.59785283 13.5569125,9.05286138 14.4122143,9.28489555 L14.652,9.343 L12,11 L4,6 L4,8 L12,13 L16.91,9.931 L22,14.786 L22,18 C22,19.05 21.1799587,19.9177686 20.1486946,19.9944872 L20,20 L4,20 C2.95,20 2.0822314,19.1799587 2.00551277,18.1486946 L2,18 L2.01,6 C2.01,4.95 2.82092975,4.0822314 3.85136551,4.00551277 L4,4 L10.5030329,4 Z M14.3719386,1.30003957 C15.5717798,0.750112384 17.0715812,0.950085908 18.0714488,1.94995353 C19.021323,2.89982777 19.2712899,4.24964905 18.8213495,5.39949682 L18.8213495,5.39949682 L23.3707472,9.94889449 C23.5707207,10.0988746 23.5707207,10.4488283 23.3207538,10.6488018 L23.3207538,10.6488018 L22.170906,11.7986496 C21.9709325,11.9986231 21.6709722,11.9986231 21.4709987,11.7986496 L21.4709987,11.7986496 L16.921601,7.24925191 C15.7717533,7.69919234 14.421932,7.44922544 13.4720578,6.4993512 C12.4721901,5.49948358 12.2222232,3.99968215 12.8221438,2.799841 L12.8221438,2.799841 L15.0218526,4.94955639 L16.521654,3.44975496 Z"
        id="Combined-Shape"
        fill="#E0E0E0"
        fillRule="nonzero"></path>
    </g>
  </svg>
);

export default MTurkEmailIcon;
