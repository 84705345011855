import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { changeTeam, fetchManagers } from '../../actions/manager';
import { deleteAccount, unverifyAccount, verifyAccount } from '../../actions/account';
import { fetchManagerTeams } from '../../actions/managerTeam';
import { resetProps } from '../../actions/common';
import ManagerTable from '../../components/admin/ManagerTable';
import Spinner from '../../components/common/Spinner';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import './AdminDashboardCommon.scss';

class AdminDashboardManagers extends Component {
  componentWillMount() {
    this.props.fetchManagers();
    this.props.fetchTeams();
  }

  componentDidMount() {
    // However we leave this page, always reset
    this.props.router.setRouteLeaveHook(this.props.route, this.props.resetProps);
  }

  isLoading() {
    return this.props.isLoadingManagers;
  }

  render() {
    const { managers } = this.props;
    let content = null;
    let rootClasses = ['AdminDashboardManagers'];

    if (this.isLoading()) {
      content = <Spinner mode="fullWidth" size="large" />;
    } else if (!managers || !managers.length) {
      content = (
        <div className="hero is-medium">
          <div className="hero-body container has-text-centered">
            <h2 className="title">
              <FormattedMessage id="error.nodata" />
            </h2>
          </div>
        </div>
      );
    } else {
      rootClasses.push('container');
      content = <ManagerTable {...this.props} />;
    }

    return (
      <div className={rootClasses.join(' ')}>
        <Helmet title="Managers" />
        {content}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardManagers');

const mapDispatchToProps = generateDispatchToProps({
  fetchManagers,
  fetchTeams: fetchManagerTeams,
  deleteAccount,
  verifyAccount,
  unverifyAccount,
  resetProps,
  changeTeam
});

AdminDashboardManagers = connect(mapStateToProps, mapDispatchToProps)(AdminDashboardManagers);

export default AdminDashboardManagers;
