// @flow
import React, { Component } from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import type { ComponentType } from 'react';

type Props = any;
type State = { [key: string]: string | number };

const injectURLQueryParameter = (
  WrappedComponent: ComponentType<{
    ...Props,
    urlParams: {
      ...State,
      updateURLParameters: Function
    }
  }>,
  defaultParams?: { [key: string]: string | number }
) => {
  return class extends Component<Props, State> {
    constructor(props: Props) {
      super(props);

      const params = new URLSearchParams(document.location.search);

      this.state = {};
      for (const key of Object.keys(defaultParams || {})) {
        this.state[key] = (defaultParams || {})[key];
      }
      for (const [key, value] of params) {
        this.state[key] = value;
      }
    }

    updateURLParameters = (values: any) => {
      const params = new URLSearchParams(document.location.search);
      for (let key in values) {
        params.set(key, values[key]);
      }
      browserHistory.push({
        pathname: document.location.pathname,
        search: '?' + params.toString()
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          urlParams={{ ...this.state, updateURLParameters: this.updateURLParameters }}
        />
      );
    }
  };
};

export default injectURLQueryParameter;
