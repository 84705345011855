// @flow
import type { CampaignType } from 'matchmade-types';

import * as React from 'react';
import { CampaignType as CampaignTypeEnum } from '@sharkpunch/matchmade-common/campaign';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import InfoTooltip from '../../common/InfoTooltip';

import './SelectGame.scss';

type Props = {
  isLoading: boolean,
  isDisabled: boolean,
  isSoftLaunch: boolean,
  searchQuery: string,
  onChangeSearchQuery: (value: string) => void,
  onSearchForGame: (value: string) => void,
  onToggleSoftLaunch: () => void,

  campaignType: CampaignType,

  intl: IntlShape
};

let debouncedOnSearchForGame;

function onSearchForGame(query, props: Props) {
  const searchQuery = query.trim();
  if (!searchQuery) return;
  props.onSearchForGame(searchQuery);
}

function onChangeSearchQuery(props: Props) {
  if (!debouncedOnSearchForGame) {
    debouncedOnSearchForGame = debounce(onSearchForGame, 1000);
  }

  return (e: any) => {
    const value = e.target.value;
    props.onChangeSearchQuery(value);
    debouncedOnSearchForGame(value, props);
  };
}

function onKeyPressSearchInput(props: Props) {
  return (e: any) => {
    if (e.charCode !== 13) return;
    onSearchForGame(e.target.value, props);
  };
}

// This component covers both Game and App despite its name
function SelectGame(props: Props) {
  const className = classNames('control SelectGame SelectGame__game-title', {
    'is-loading': props.isLoading
  });

  const label =
    props.campaignType === CampaignTypeEnum.APP ? (
      <FormattedMessage id="campaignCreation.productSelection.selectApp" />
    ) : (
      <FormattedMessage id="campaignCreation.productSelection.selectGame" />
    );

  const textPlaceholder =
    props.campaignType === CampaignTypeEnum.APP
      ? 'campaignCreation.productSelection.selectApp.placeholder'
      : 'campaignCreation.productSelection.selectGame.placeholder';

  return (
    <div className="field is-horizontal">
      <div className="field-label">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field is-horizontal">
          <div className={className}>
            <input
              className="input"
              type="text"
              value={props.searchQuery}
              disabled={props.isDisabled}
              onChange={onChangeSearchQuery(props)}
              onKeyPress={onKeyPressSearchInput(props)}
              placeholder={props.intl.formatMessage({
                id: textPlaceholder
              })}
            />
          </div>

          <div className="control SelectGame__soft-launch">
            <label className="checkbox">
              <input
                disabled={props.isLoading}
                type="checkbox"
                name="softLaunch"
                onChange={props.onToggleSoftLaunch}
                checked={props.isSoftLaunch}
              />
              <FormattedMessage id="campaignCreation.productSelection.softLaunch" />
              <InfoTooltip>
                <FormattedMessage id="campaignCreation.productSelection.softLaunch.tooltip" />
              </InfoTooltip>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SelectGame);
