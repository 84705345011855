// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
import capitalize from 'lodash/capitalize';

import classNames from 'classnames';

import {
  Tabs as TabsEnum,
  getInvitedInfluencers,
  getNegotiatingInfluencers,
  getParticipatingInfluencers,
  getParticipatingInfluencersWithNoTags,
  getParticipatingInfluencersWithTag,
  getTabNames,
  getTagsForParticipatingInfluencers,
  tagToTabId
} from './helper';
import ListIcon from '../../common/Icons/ListIcon';
import NegotiationIcon from '../../common/Icons/NegotiationIcon';
import TagIcon from '../../common/Icons/TagIcon';
import VideoIcon from '../../common/Icons/VideoIcon';
import type { CampaignForPublisher } from '../../../types/campaign.flow';

import compose from '../../../hoc/compose';
import injectRoleChecking from '../../../hoc/injectRoleChecking';

import './Tabs.scss';

type ClickEventHandler = (event: SyntheticEvent<*>) => void;

type Props = {
  activeTab: string,
  campaign: CampaignForPublisher,
  user: { verified: boolean },
  selectSection: (tabId: string) => ClickEventHandler,
  isWhitelabelAdmin: boolean
};

const AllTab = ({ user, selectSection }) => {
  const title = <FormattedMessage id={`campaign.sections.${TabsEnum.ALL}`} />;
  return (
    /* Bulma's tabs styles work only for anchor :/ */
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      className="label is-truncated"
      id={`campaignTab${capitalize(TabsEnum.ALL)}`}
      onClick={user.verified ? selectSection(TabsEnum.ALL) : null}>
      <ListIcon />
      {title}
    </a>
  );
};

const DealTagTab = ({ selectSection, campaign, dealTag }) => {
  //const settled = getParticipatingInfluencersWithTag(campaign, tag).length;
  const numberOfDeals = getParticipatingInfluencersWithTag(campaign, dealTag).length;
  const notification = numberOfDeals ? (
    <span className="tag is-rounded">{numberOfDeals}</span>
  ) : null;
  return (
    /* Bulma's tabs styles work only for anchor :/ */
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      className="label is-truncated"
      id={`campaignTabDealTag${capitalize(dealTag)}`}
      onClick={selectSection(tagToTabId(dealTag))}>
      <TagIcon />
      {dealTag}
      {notification}
    </a>
  );
};

const NoDealTagsTab = ({ selectSection, campaign }) => {
  const numberOfDeals = getParticipatingInfluencersWithNoTags(campaign).length;
  return (
    /* Bulma's tabs styles work only for anchor :/ */
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      className="label is-truncated"
      id={`campaignTab${capitalize(TabsEnum.NO_DEAL_TAGS)}`}
      onClick={selectSection(TabsEnum.NO_DEAL_TAGS)}>
      <TagIcon />
      No Tags
      <span className="tag is-rounded">{numberOfDeals}</span>
    </a>
  );
};

const ParticipantTab = ({ selectSection, user, campaign }) => {
  const title = <FormattedMessage id={`campaign.sections.${TabsEnum.PARTICIPANTS}`} />;
  const settled = getParticipatingInfluencers(campaign).length;
  const notification = settled ? <span className="tag is-rounded">{settled}</span> : null;
  return (
    /* Bulma's tabs styles work only for anchor :/ */
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      className="label is-truncated"
      id={`campaignTab${capitalize(TabsEnum.PARTICIPANTS)}`}
      onClick={user.verified ? selectSection(TabsEnum.PARTICIPANTS) : null}>
      <VideoIcon />
      {title}
      {notification}
    </a>
  );
};

const NegotiatingTab = ({ selectSection, user, campaign }) => {
  const title = <FormattedMessage id={`campaign.sections.${TabsEnum.NEGOTIATIONS}`} />;
  const negotiating = getNegotiatingInfluencers(campaign).length;
  const invited = getInvitedInfluencers(campaign).length;
  const total = negotiating + invited;
  const notification = total ? <span className="tag is-rounded">{total}</span> : null;
  return (
    /* Bulma's tabs styles work only for anchor :/ */
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      className="label is-truncated"
      id={`campaignTab${capitalize(TabsEnum.NEGOTIATIONS)}`}
      onClick={user.verified ? selectSection(TabsEnum.NEGOTIATIONS) : null}>
      <NegotiationIcon />
      {title}
      {notification}
    </a>
  );
};

const CampaignTab = ({ tab, campaign, user, selectSection }) => {
  let content = null;

  switch (tab) {
    case TabsEnum.ALL:
      content = <AllTab selectSection={selectSection} user={user} />;
      break;
    case TabsEnum.PARTICIPANTS:
      content = <ParticipantTab selectSection={selectSection} user={user} campaign={campaign} />;
      break;
    case TabsEnum.NEGOTIATIONS:
      content = <NegotiatingTab selectSection={selectSection} user={user} campaign={campaign} />;
      break;
    default:
      break;
  }

  return content;
};

const getListItemClassName = ({
  isActive,
  isAdmin = false
}: {
  isActive: boolean,
  isAdmin?: boolean
}) => classNames({ 'is-active': isActive, 'is-admin': isAdmin }) || null;

const Tabs = (props: Props) => {
  const { campaign, user, activeTab, selectSection, isWhitelabelAdmin } = props;

  const dealTags = getTagsForParticipatingInfluencers(campaign);
  const availableTabs = getTabNames(campaign);
  const adminTabs = [TabsEnum.ALL];

  return (
    <div className="TabsContainer">
      <ul className="tabs is-boxed">
        {dealTags.map(t => (
          <li
            key={`campaign.sections.${t}`}
            className={getListItemClassName({ isActive: activeTab === tagToTabId(t) })}>
            <DealTagTab campaign={campaign} dealTag={t} selectSection={selectSection} tab={t} />
          </li>
        ))}
        <li
          key={`campaign.sections.${TabsEnum.NO_DEAL_TAGS}`}
          className={getListItemClassName({ isActive: activeTab === TabsEnum.NO_DEAL_TAGS })}>
          <NoDealTagsTab
            campaign={campaign}
            user={user}
            selectSection={selectSection}
            tab={TabsEnum.NO_DEAL_TAGS}
          />
        </li>
        {availableTabs.map(t => (
          <li
            key={`campaign.sections.${t}`}
            className={getListItemClassName({ isActive: activeTab === t })}>
            <CampaignTab campaign={campaign} user={user} selectSection={selectSection} tab={t} />
          </li>
        ))}
        {isWhitelabelAdmin &&
          adminTabs.map(t => (
            <li
              key={`campaign.sections.${t}`}
              className={getListItemClassName({ isActive: activeTab === t, isAdmin: true })}>
              <CampaignTab campaign={campaign} user={user} selectSection={selectSection} tab={t} />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default compose(injectRoleChecking())(Tabs);
