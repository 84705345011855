// @flow
import * as React from 'react';

import findIndex from 'lodash/findIndex';

import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { Helmet } from 'react-helmet';
import ErrorFooterNotification from './common/ErrorFooterNotification';
import classNames from 'classnames';

import FooterActions from '../common/FooterActions';
import HeaderNotification from './HeaderNotification';
import ProgressTracker from './ProgressTracker';

import browserHistory from 'react-router/lib/browserHistory';

import changeStyleWhenScrolling from './changeStyleWhenScrolling';
import compose from '../../hoc/compose';
import type { ExtraProps as InjectedScrollingProps } from './changeStyleWhenScrolling';
import type { WrappedComponent } from '../../hoc/compose';

import './CampaignCreationWizard.scss';

type Notification = {
  icon: React.Element<*>,
  content: React.Element<*>
};

export type Step = {
  url: string,
  label: React.Element<*>,
  isActive: boolean,
  isValid: boolean
};

export type StepControl = {
  goToStep: (index: number) => void,
  goToNextStep: () => void,
  getActiveStepIndex: () => number,
  getLastStepIndex: () => number,
  goToFirstStep: () => void
};

export function setupStepControl(steps: Step[], baseUrl: string): StepControl {
  function getActiveStepIndex() {
    return findIndex(steps, { isActive: true });
  }

  function goToStep(index: number) {
    let step = steps[index];

    if (!step) {
      console.warn(`No step found at index [${index}]`);
      step = steps[0];
    }

    browserHistory.push({
      pathname: `${baseUrl}${step.url}`
    });
  }

  function goToNextStep() {
    const currentStepIndex = getActiveStepIndex();
    if (currentStepIndex === -1) {
      console.warn('There is no active steps, going to the first step');
      return goToStep(0);
    }

    if (!steps[currentStepIndex + 1]) {
      return goToStep(steps.length - 1);
    }

    goToStep(currentStepIndex + 1);
  }

  function getLastStepIndex() {
    return steps.length - 1;
  }

  function goToFirstStep() {
    goToStep(0);
  }

  return {
    goToStep,
    goToNextStep,
    getActiveStepIndex,
    getLastStepIndex,
    goToFirstStep
  };
}

type RequiredProps = {
  onClearError: () => void,

  steps: Step[],
  baseUrl: string,

  headerBgImage: string | null,
  isLoading: boolean,

  onGoToStep: (index: number, stepControl: StepControl) => void,

  error: ?Object,

  children: any,
  notification: any
};

type InjectedProps = {| ...InjectedScrollingProps, intl: IntlShape |};

type Props = RequiredProps & InjectedProps;

class CampaignCreationWizard extends React.PureComponent<Props, {}> {
  static layoutClassName: string;
  static disableHeadroom: boolean;

  stepControl: StepControl;

  static defaultProps = {
    headerBgImage: null,
    style: {
      title: {
        opacity: 1
      },
      header: {},
      notification: {},
      body: {}
    },
    onScroll() {},
    notification: null,
    isLoading: false,
    error: null
  };

  constructor(props: Props) {
    super(props);

    this.stepControl = setupStepControl(props.steps, props.baseUrl);
  }

  componentDidMount() {
    const { steps } = this.props;

    const activeStepIndex = this.getActiveStepIndex();

    // try to find the lowest step possible that is valid and go there
    // for example, can't go to step 3 if step 2 is not done
    const previousSteps = steps.filter((step, index) => index < activeStepIndex).reverse(); // must have reverse here because we want to check the lowest one first

    for (let i = 0; i < previousSteps.length; i++) {
      const previousStep = previousSteps[i];
      if (!previousStep.isValid) {
        this.stepControl.goToStep(i);
        break;
      }
    }
  }

  navigateDirectlyToStep = (stepIndex: number) => {
    window.scroll(0, 0);
    this.stepControl.goToStep(stepIndex);
  };

  navigateToStep = (nextStep: number) => {
    const { steps } = this.props;
    const activeStepIndex = findIndex(steps, ({ isActive }) => isActive);

    const step = steps[nextStep];
    const currentStep = steps[activeStepIndex];
    if (!step && nextStep < steps.length) return;
    // only do validation if going to the next step or if it's the last step
    if (nextStep > activeStepIndex && currentStep && !currentStep.isValid) return;

    this.navigateDirectlyToStep(nextStep);
  };

  getHeaderHeight() {
    return parseFloat(this.props.style.header.height);
  }

  getStyleWhenHavingNotification(
    notification: any,
    styleName: string,
    heightWhenHavingNotification: string = '6rem'
  ) {
    const style = this.props.style[styleName];
    if (!style) return {};

    const headerHeight = this.getHeaderHeight();
    return {
      ...style,
      height: headerHeight < 6 && notification ? heightWhenHavingNotification : style.height
    };
  }

  getActiveStepIndex = () => {
    const index = findIndex(this.props.steps, ({ isActive }) => isActive);
    return index < 0 ? 0 : index;
  };

  renderHeader(notification: Notification | null) {
    const { steps, style, isLoading } = this.props;

    const activeStepIndex = this.getActiveStepIndex();

    const stepLabels = steps.map(({ label }) => label);
    const validSteps = steps
      .map((step, index) => (step.isValid ? index : -1))
      .filter(index => index >= 0);

    const background = {
      backgroundImage: this.props.headerBgImage
        ? `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("${this.props.headerBgImage}")`
        : null
    };

    const titleClassName = classNames('title', {
      'title--hidden':
        style.title.opacity <= 0 || activeStepIndex >= steps.length - 1 || !!notification
    });

    return (
      <div
        className="CampaignCreationWizard__header"
        style={this.getStyleWhenHavingNotification(notification, 'header')}>
        <div className="CampaignCreationWizard__header-background svgBlur" style={background} />
        <div className="container">
          <div className={titleClassName}>
            <FormattedMessage id="campaignCreation.title" tagName="h1" />
          </div>
          <ProgressTracker
            isLoading={isLoading}
            steps={stepLabels}
            clickableSteps={validSteps}
            activeStep={activeStepIndex}
            minimized={!!notification || !this.props.style.title.opacity}
            onGoToStep={index => {
              if (this.props.onGoToStep) {
                return this.props.onGoToStep(index, this.stepControl);
              }
              this.navigateToStep(index);
            }}
          />
          {notification && (
            <HeaderNotification style={this.props.style.notification} icon={notification.icon}>
              {notification.content}
            </HeaderNotification>
          )}
        </div>
      </div>
    );
  }

  getActiveStep = () => {
    // fallback to the very first step if we can't find any active step
    return this.props.steps[this.getActiveStepIndex()] || this.props.steps[0];
  };

  isActiveStepValid() {
    return this.getActiveStep().isValid;
  }

  renderFooter() {
    if (this.props.error) {
      return <ErrorFooterNotification clearError={this.props.onClearError} />;
    }

    const activeStepIndex = this.getActiveStepIndex();

    const nextButtonText =
      activeStepIndex === this.props.steps.length - 1
        ? 'campaignCreation.step.finish'
        : 'campaignCreation.step.next';

    return (
      <FooterActions
        showBackButton={activeStepIndex > 0}
        disabled={!this.isActiveStepValid()}
        isLoading={this.props.isLoading}
        float
        onNext={() => {
          if (this.props.onGoToStep) {
            return this.props.onGoToStep(activeStepIndex + 1, this.stepControl);
          }
          this.navigateToStep(activeStepIndex + 1);
        }}
        onBack={() => {
          this.navigateToStep(activeStepIndex - 1);
        }}
        nextButtonText={nextButtonText}
      />
    );
  }

  render() {
    const activeStep: Step = this.getActiveStep();

    const className = classNames(
      'CampaignCreationWizard',
      `CampaignCreationWizard__${activeStep.url}`,
      'section',
      {
        'CampaignCreationWizard--has-notification': !!this.props.notification
      }
    );

    return (
      <div className={className}>
        <Helmet title={this.props.intl.formatMessage({ id: 'title.publisher.createCampaign' })} />

        {this.renderHeader(this.props.notification)}

        <div className="CampaignCreationWizard__content">
          <div className="CampaignCreationWizard__body">
            <div className="container section">{this.props.children}</div>
          </div>

          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

const ComposedCampaignCreationWizard: WrappedComponent<Props, InjectedProps> = compose(
  changeStyleWhenScrolling(),
  injectIntl
)(CampaignCreationWizard);

// $FlowFixMe
ComposedCampaignCreationWizard.layoutClassName = 'Layout--create-campaign';
// $FlowFixMe
ComposedCampaignCreationWizard.disableHeadroom = true;

export default ComposedCampaignCreationWizard;
