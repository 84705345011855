import { connect } from 'react-redux';
import React, { Component } from 'react';

import {
  deleteTeamInvite,
  deleteTeamMember,
  fetchTeam,
  inviteTeamMember
} from '../../actions/account';

import { updateTeam } from '../../actions/team';

import Error from '../../components/common/Error';
import NoData from '../../components/common/NoData';
import Spinner from '../../components/common/Spinner';

import TeamForm from '../../components/team/TeamForm';
import TeamManage from '../../components/team/TeamManage';

import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import './AdminDashboardTeam.scss';

class AdminDashboardTeam extends Component {
  constructor(props) {
    super(props);

    this.onInviteTeamMember = this.onInviteTeamMember.bind(this);
    this.onDeleteTeamMember = this.onDeleteTeamMember.bind(this);
    this.onDeleteTeamInvite = this.onDeleteTeamInvite.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const user = this.props.user;
    if (!user) return;
    this.props.fetchTeam(user.id, this.props.params.teamId);
  }

  onInviteTeamMember(email) {
    const teamId = this.props.params.teamId;
    const accountId = this.props.user.id;
    this.props.inviteTeamMember(accountId, teamId, email);
  }

  onDeleteTeamMember(member) {
    const teamId = this.props.params.teamId;
    const accountId = this.props.user.id;
    this.props.deleteTeamMember(accountId, teamId, member.id);
  }

  onDeleteTeamInvite(invite) {
    const teamId = this.props.params.teamId;
    const accountId = this.props.user.id;
    this.props.deleteTeamInvite(accountId, teamId, invite.id);
  }

  onSubmit(team) {
    this.props.updateTeam(team.id, team.name);
  }

  isLoading() {
    return this.props.isFetchingTeam;
  }

  renderTeamManageFooter() {
    if (this.isLoading()) return null;

    return (
      <div className="box">
        <TeamForm
          team={this.props.team}
          onSubmit={this.onSubmit}
          isLoading={this.props.isUpdatingTeam}
        />
      </div>
    );
  }

  render() {
    if (this.isLoading()) return <Spinner size="large" />;

    const { team } = this.props;

    if (!team) return <NoData className="container" />;

    return (
      <div className="container AdminDashboardTeam">
        <Error error={this.props.error} />
        <TeamManage
          team={team}
          isOwner
          onInviteTeamMember={this.onInviteTeamMember}
          onDeleteTeamMember={this.onDeleteTeamMember}
          onDeleteTeamInvite={this.onDeleteTeamInvite}
          isLoading={
            this.props.isInvitingTeamMember ||
            this.props.isDeletingTeamMember ||
            this.props.isDeletingTeamInvite ||
            this.props.isUpdatingTeam
          }
          error={this.props.error}
          sections={{
            footer: this.renderTeamManageFooter()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardTeam');

const mapDispatchToProps = generateDispatchToProps({
  fetchTeam,
  inviteTeamMember,
  deleteTeamMember,
  deleteTeamInvite,
  updateTeam
});

AdminDashboardTeam = connect(mapStateToProps, mapDispatchToProps)(AdminDashboardTeam);

export default AdminDashboardTeam;
