// @flow
import * as React from 'react';
import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';

// $FlowFixMe
import { FormattedMessage } from 'react-intl';
import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import { useDispatch } from 'react-redux';

import config from '../../config';

import Avatar from '../messages/Avatar';
import BilibiliIcon from '../common/Icons/BilibiliIcon';
import CheckIcon from '../common/Icons/CheckIcon';
import DeleteIcon from '../common/Icons/DeleteIcon';
import EditCommissionIcon from '../common/Icons/EditCommissionIcon';
import EmailCopyIcon from '../common/EmailCopyIcon';
import InstagramIcon from '../common/Icons/InstagramIcon';
import Link from 'react-router/lib/Link';
import OnOffToggle from '../common/OnOffToggle';
import PersonIcon from '../common/Icons/PersonIcon';
import ProfileIcon from '../common/Icons/ProfileIcon';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import TwitchIcon from '../common/Icons/TwitchIcon';
import YoutubeIcon from '../common/Icons/YoutubeIcon';
import ListIcon from '../common/Icons/ListIcon';
import moment from 'moment';

import { deleteAccount, unverifyAccount, verifyAccount } from '../../actions/account';

import './InfluencerTable.scss';

function isProcessing(props: { isDeletingAccount: boolean, verifyingAccountId: number | null }) {
  return props.isDeletingAccount || !!props.verifyingAccountId;
}

type Props = {
  influencers: Object[],
  verifyingAccountId: number | null
};

function InfluencerTable(props: Props) {
  const { influencers, verifyingAccountId } = props;
  const [accountToDelete, setAccountToDelete] = React.useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([
    ContentPlatform.YOUTUBE,
    ContentPlatform.TWITCH,
    ContentPlatform.INSTAGRAM
  ]);
  const [includeMock, setIncludeMock] = React.useState(true);
  const dispatch = useDispatch();

  function renderConfirmAccountDeleteModal() {
    if (!accountToDelete) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Delete account?</p>
            <button
              className="delete"
              onClick={() => {
                setAccountToDelete(null);
              }}
            />
          </header>
          <section className="modal-card-body">
            <p>
              Removing this account will remove all data associated with this account. Are you sure
              you want to delete it?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-danger"
              onClick={() => {
                if (!accountToDelete) {
                  return;
                }

                setAccountToDelete(null);
                dispatch(deleteAccount.run(accountToDelete, RoleEnum.INFLUENCER));
              }}>
              Delete
            </button>
            <button
              className="button"
              onClick={() => {
                setAccountToDelete(null);
              }}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }

  function selectPlatform(platform) {
    return () => {
      if (selectedPlatforms.indexOf(platform) === -1) {
        setSelectedPlatforms(selectedPlatforms.concat(platform));
      } else {
        setSelectedPlatforms(selectedPlatforms.filter(v => v !== platform));
      }
    };
  }

  return (
    <div className="InfluencerTable">
      {renderConfirmAccountDeleteModal()}
      <Table
        headerControls={[
          <Link to="/admin/influencers/create" key="create">
            <PersonIcon type="add" tooltipI18nString="mockInfluencerPage.title" />
          </Link>,
          <button
            className="link-button"
            key="youtube"
            onClick={selectPlatform(ContentPlatform.YOUTUBE)}>
            <YoutubeIcon
              inactive={!selectedPlatforms.includes(ContentPlatform.YOUTUBE)}
              withTooltip
              tooltipPosition="bottom"
              tooltipI18nString="admin.influencers.table.includeYoutube"
            />
          </button>,
          <button
            className="link-button"
            key="twitch"
            onClick={selectPlatform(ContentPlatform.TWITCH)}>
            <TwitchIcon
              inactive={!selectedPlatforms.includes(ContentPlatform.TWITCH)}
              withTooltip
              tooltipPosition="bottom"
              tooltipI18nString="admin.influencers.table.includeTwitch"
            />
          </button>,
          <button
            className="link-button"
            key="instagram"
            onClick={selectPlatform(ContentPlatform.INSTAGRAM)}>
            <InstagramIcon
              inactive={!selectedPlatforms.includes(ContentPlatform.INSTAGRAM)}
              withTooltip
              tooltipPosition="bottom"
              tooltipI18nString="admin.influencers.table.includeInstagram"
            />
          </button>,
          <button
            className="link-button"
            key="bilibili"
            onClick={selectPlatform(ContentPlatform.BILIBILI)}>
            <BilibiliIcon
              inactive={!selectedPlatforms.includes(ContentPlatform.BILIBILI)}
              withTooltip
              tooltipPosition="bottom"
              tooltipI18nString="admin.influencers.table.includeBilibili"
            />
          </button>,
          <button
            className="link-button"
            key="mock"
            onClick={() => {
              setIncludeMock(!includeMock);
            }}>
            <EditCommissionIcon
              inactive={!includeMock}
              withTooltip
              tooltipPosition="bottom"
              tooltipI18nString="admin.influencers.table.includeMock"
            />
          </button>
        ]}
        data={influencers.filter(i => {
          const { youtubeChannel, twitchChannel, instagramChannel, bilibiliChannel } = i;
          if (selectedPlatforms.includes(ContentPlatform.YOUTUBE) && youtubeChannel) {
            return !(!includeMock && youtubeChannel.mock);
          }
          if (selectedPlatforms.includes(ContentPlatform.TWITCH) && twitchChannel) {
            return !(!includeMock && twitchChannel.mock);
          }
          if (selectedPlatforms.includes(ContentPlatform.INSTAGRAM) && instagramChannel) {
            return !(!includeMock && instagramChannel.mock);
          }
          if (selectedPlatforms.includes(ContentPlatform.BILIBILI) && bilibiliChannel) {
            return !(!includeMock && bilibiliChannel.mock);
          }
          return false;
        })}
        initialSort="id"
        initialSortDirection="desc"
        className="is-fullwidth is-hoverable"
        pagination={influencers.length > pageLimitOptions[1]}
        searchBy={[
          'id',
          'account.email',
          'account.contactEmail',
          'account.displayName',
          'youtubeChannel.name',
          'twitchChannel.name',
          'instagramChannel.name',
          'managerTeam.name'
        ]}>
        <Column name="id">
          <FormattedMessage id="admin.accounts.table.header.id" />
        </Column>
        <Column
          name="*"
          sortBy={influencer => {
            const { youtubeChannel, twitchChannel, instagramChannel, bilibiliChannel } = influencer;
            // Since this function is passed to _.sortBy,
            // we can't really compare actual fields values
            // here, so here we return arbitrary numbers instead.
            //
            // We sort in "Leo order" (named so after Leo, who suggested it):
            // - Youtube & Twitch
            // - Imported YouTube & Twitch
            // - YouTube
            // - Imported YouTube
            // - Twitch
            // - Imported Twitch
            return [youtubeChannel, twitchChannel, instagramChannel, bilibiliChannel].reduce(
              (total, channel) => {
                if (!channel) {
                  return total;
                }
                if (channel.id) {
                  if (channel.mock) {
                    return total + 1;
                  }
                  return total + 2;
                }
                return total;
              },
              0
            );
          }}
          component={props => {
            const influencer = props.rowData;

            const { youtubeChannel, twitchChannel, instagramChannel, bilibiliChannel } = influencer;

            return (
              <span className="InfluencerTable__channels is-flex">
                {youtubeChannel && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={youtubeChannel.mock ? 'is-mock' : ''}
                    href={`https://www.youtube.com/channel/${youtubeChannel.id}`}>
                    <YoutubeIcon />
                  </a>
                )}
                {twitchChannel && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={twitchChannel.mock ? 'is-mock' : ''}
                    href={`https://www.twitch.tv/${twitchChannel.name}`}>
                    <TwitchIcon />
                  </a>
                )}
                {instagramChannel && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={instagramChannel.mock ? 'is-mock' : ''}
                    href={`https://www.instagram.com/${instagramChannel.username}`}>
                    <InstagramIcon />
                  </a>
                )}
                {bilibiliChannel && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={bilibiliChannel.mock ? 'is-mock' : ''}
                    href={`https://space.bilibili.com/${bilibiliChannel.id}`}>
                    <BilibiliIcon />
                  </a>
                )}
              </span>
            );
          }}>
          <FormattedMessage id="campaignCreation.productSelection.platform" />
        </Column>
        <Column
          name="account.displayName"
          component={props => {
            const rowData = props.rowData;

            return (
              <span className="AdminDashboardInfluencers__display-name">
                <Avatar url={rowData.account.avatarUrl} size={24} />
                <EmailCopyIcon value={rowData.account.contactEmail || rowData.account.email} />
                <Link to={`/admin/influencers/${rowData.id}`}>{rowData.account.displayName}</Link>
              </span>
            );
          }}>
          <FormattedMessage id="admin.accounts.table.header.name" />
        </Column>
        <Column
          name="managerTeam.name"
          component={props => {
            const team = props.rowData.managerTeam;
            if (!team) {
              return null;
            }

            return <Link to={`/admin/manager-teams/${team.id}`}>{team.name}</Link>;
          }}>
          <FormattedMessage id="admin.accounts.table.header.agency" />
        </Column>
        <Column
          name="account.created"
          className="AdminDashboardInfluencers__date-field"
          sortBy={created => new Date(created).getTime()}
          component={props => (
            <span>{moment(props.rowData.account.created).format('MMM Do YYYY')}</span>
          )}>
          <FormattedMessage id="admin.accounts.table.header.created" />
        </Column>
        <Column
          name="account.verified"
          component={props => {
            const {
              id,
              account: { verified }
            } = props.rowData;

            return (
              <OnOffToggle
                isOn={verified}
                onClick={() => {
                  verified
                    ? dispatch(unverifyAccount.run(id, RoleEnum.INFLUENCER))
                    : dispatch(verifyAccount.run(id, RoleEnum.INFLUENCER));
                }}
                isDisabled={isProcessing(props)}
                isLoading={verifyingAccountId === id}
              />
            );
          }}>
          <FormattedMessage id="admin.accounts.table.header.verified" />
        </Column>
        <Column
          name="account.lastLogin"
          className="AdminDashboardInfluencers__date-field"
          sortBy={lastLogin => new Date(lastLogin).getTime()}
          component={props => (
            <span>{moment(props.rowData.account.lastLogin).format('MMM Do YYYY')}</span>
          )}>
          <FormattedMessage id="admin.accounts.table.header.lastLogin" />
        </Column>
        <Column
          name="hasAcceptedTos"
          component={props => {
            const influencer = props.rowData;
            if (!influencer.hasAcceptedTos) {
              return <span>NO</span>;
            }

            return <CheckIcon />;
          }}>
          <FormattedMessage id="admin.accounts.table.header.tosAccepted" />
        </Column>
        <Column name="language">
          <FormattedMessage id="admin.accounts.table.header.language" />
        </Column>
        <Column
          name="actions"
          sortable={false}
          component={props => {
            const influencer = props.rowData;

            return (
              <div className="InfluencerTable__actions">
                <button
                  className="link-button"
                  disabled={isProcessing(props)}
                  onClick={() => {
                    setAccountToDelete(influencer.id);
                  }}>
                  <DeleteIcon />
                </button>
                <Link to={`/influencers/profile/${influencer.id}`} key="profile">
                  <ProfileIcon withTooltip toolTipText="View influencer profile" />
                </Link>
                {influencer.youtubeChannel && (
                  <a
                    href={`${config('bulkOfferTool.url')}/deals/?channel.id=eq.${
                      influencer.youtubeChannel.id
                    }`}
                    key="view-influencer-deals"
                    activeClassName="is-active"
                    target="_blank"
                    rel="noopener noreferrer">
                    <ListIcon withTooltip toolTipText="View influencer deals" />
                  </a>
                )}
              </div>
            );
          }}></Column>
      </Table>
    </div>
  );
}

export default InfluencerTable;
