// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import { InlineTeamManagement } from './InlineTeamManagement';
import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import { useState } from 'react';
import Avatar from '../messages/Avatar';
import CheckIcon from '../common/Icons/CheckIcon';
import DeleteIcon from '../common/Icons/DeleteIcon';
import EmailCopyIcon from '../common/EmailCopyIcon';
import Link from 'react-router/lib/Link';
import OnOffToggle from '../common/OnOffToggle';
import PersonIcon from '../common/Icons/PersonIcon';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import moment from 'moment';

import './ManagerTable.scss';

type Props = {
  managers: any[],
  managerTeams: any[],
  isChangingTeam: boolean,
  deleteAccount: (id: number, role: string) => void,
  verifyAccount: (id: number, role: string) => void,
  unverifyAccount: (id: number, role: string) => void,
  changeTeam: (id: ?number, teamId: ?number, teamName: string) => void,
  verifyingAccountId: number
};

const ManagerTable = ({
  managers,
  deleteAccount,
  verifyAccount,
  unverifyAccount,
  verifyingAccountId,
  isChangingTeam,
  managerTeams: allTeams,
  changeTeam
}: Props) => {
  const [accountToChangeTeam, setAccountToChangeTeam] = useState(null);
  const [accountToDelete, setAccountToDelete] = React.useState(null);

  function renderConfirmAccountDeleteModal() {
    if (!accountToDelete) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Delete account?</p>
            <button
              className="delete"
              onClick={() => {
                setAccountToDelete(null);
              }}
            />
          </header>
          <section className="modal-card-body">
            <p>
              Removing this account will remove all data associated with this account. Are you sure
              you want to delete it?
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-danger"
              onClick={() => {
                if (!accountToDelete) {
                  return;
                }

                setAccountToDelete(null);
                deleteAccount(accountToDelete, RoleEnum.INFLUENCER_MANAGER);
              }}>
              Delete
            </button>
            <button
              className="button"
              onClick={() => {
                setAccountToDelete(null);
              }}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }

  return (
    <div className="ManagerTable">
      {renderConfirmAccountDeleteModal()}
      <Table
        data={managers}
        initialSort="id"
        initialSortDirection="desc"
        className="is-fullwidth is-hoverable"
        pagination={managers.length > pageLimitOptions[1]}
        searchBy={[
          'id',
          'account.email',
          'account.contactEmail',
          'account.displayName',
          'team.name',
          function (manager, searchInput) {
            const searchInputRegex = new RegExp(searchInput, 'gi');
            return (manager.channels || [])
              .concat(manager.invites || [])
              .some(({ channelName }) => searchInputRegex.test(channelName));
          }
        ]}>
        <Column name="id">
          <FormattedMessage id="admin.accounts.table.header.id" />
        </Column>
        <Column
          name="account.displayName"
          component={props => {
            const rowData = props.rowData;

            return (
              <span className="AdminDashboardManagers__display-name">
                <Avatar size={24} url={rowData.account.avatarUrl} alt={rowData.account.email} />
                <EmailCopyIcon value={rowData.account.email} /> {rowData.account.displayName}
              </span>
            );
          }}>
          <FormattedMessage id="admin.accounts.table.header.name" />
        </Column>
        <Column
          name="teamName"
          component={props => {
            const account = props.rowData;
            const isEditing = !!(accountToChangeTeam && accountToChangeTeam === account.id);

            return (
              <InlineTeamManagement
                allTeams={allTeams}
                currentTeam={account.team}
                isEditing={isEditing}
                isLoading={isChangingTeam}
                link={`/admin/manager-teams/${account.team.id}`}
                onChangeTeam={(teamId, teamName) => {
                  changeTeam(accountToChangeTeam, teamId, teamName);
                  setAccountToChangeTeam(null);
                }}
                onCreateTeam={teamName => {
                  changeTeam(accountToChangeTeam, null, teamName);
                  setAccountToChangeTeam(null);
                }}
                onToggleEdit={() => {
                  setAccountToChangeTeam(account.id === accountToChangeTeam ? null : account.id);
                }}
              />
            );
          }}>
          <FormattedMessage id="admin.accounts.table.header.agency" />
        </Column>
        <Column
          name="account.created"
          sortBy={created => new Date(created).getTime()}
          component={props => (
            <span>{moment(props.rowData.account.created).format('MMM Do YYYY')}</span>
          )}>
          <FormattedMessage id="admin.accounts.table.header.created" />
        </Column>
        <Column
          name="account.verified"
          component={props => {
            const {
              id,
              account: { verified }
            } = props.rowData;

            return (
              <OnOffToggle
                isOn={verified}
                onClick={() =>
                  verified
                    ? unverifyAccount(id, RoleEnum.INFLUENCER_MANAGER)
                    : verifyAccount(id, RoleEnum.INFLUENCER_MANAGER)
                }
                isLoading={verifyingAccountId === id}
              />
            );
          }}>
          <FormattedMessage id="admin.accounts.table.header.verified" />
        </Column>
        <Column
          name="lastLogin"
          sortBy={lastLogin => new Date(lastLogin).getTime()}
          component={props => (
            <span>{moment(props.rowData.account.lastLogin).format('MMM Do YYYY')}</span>
          )}>
          <FormattedMessage id="admin.accounts.table.header.lastLogin" />
        </Column>
        <Column
          name="hasAcceptedTos"
          component={props => {
            const { rowData } = props;

            if (!rowData.hasAcceptedTos) {
              return <span>NO</span>;
            }

            return <CheckIcon />;
          }}>
          <FormattedMessage id="admin.accounts.table.header.tosAccepted" />
        </Column>
        <Column
          name="actions"
          sortable={false}
          component={props => {
            const rowData = props.rowData;

            return (
              <div className="ManagerTable__actions">
                <Link
                  to={`/admin/login-as/${RoleEnum.INFLUENCER_MANAGER}/${rowData.id}`}
                  key="admin-login-as-influencer-manager"
                  activeClassName="is-active">
                  <PersonIcon />
                </Link>
                <button
                  className="link-button"
                  onClick={() => {
                    setAccountToDelete(rowData.id);
                  }}>
                  <DeleteIcon />
                </button>
              </div>
            );
          }}></Column>
      </Table>
    </div>
  );
};

export default ManagerTable;
