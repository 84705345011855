// @flow
import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import { DownloadUrlButton } from '../common/DownloadUrlButton';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { StatefulCollectionSelectorTrigger } from '../collection/StatefulCollectionSelectorTrigger';
import {
  changeName,
  linkCollectionToCampaign,
  removeCollectionFromCampaign,
  removeInstagramChannelFromCollection,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection,
  hideChannelFromCollection,
  showChannelFromCollection
} from '../../actions/collection';
import { copyTextToClipboard } from '../../helpers/browser';
import { getFirstAvailableContentPlatform } from '../../helpers/influencer';
import { getSortingOptions } from '../campaign/CampaignTabsSection/helper';
import Button from '../common/Button';
import CampaignsCollectionSelector from './CampaignsCollectionSelector';
import EditableHeader from '../common/EditableHeader';
import FollowedInfluencersTable from '../influencer/tables/FollowedInfluencersTable';
import InfluencerCollection from '../influencer/InfluencerCollection';
import InfluencerFollowedCard from '../influencer/InfluencerFollowedCard';
import InfluencerProfileModalTrigger from '../influencer/InfluencerProfileModalTrigger';
import InfoTooltip from '../common/InfoTooltip';
import NotFound from '../auth/NotFound';
import RemoveIcon from '../common/Icons/RemoveIcon';
import VisibilityIcon from '../common/Icons/VisibilityIcon';

import './CollectionView.scss';

/*
 * @Eder: `CollectionView` isn't updating correctly depending what the user does we might not get campaigns,
 * It's a redux error, so this error is not critical at all
 * So preventing that from happening, fixing `CollectionView` rendering might take longer
 */
function LinkToCampaign({ campaigns = [], collection }) {
  const dispatch = useDispatch();

  // No point in linking an empty collection to a campaign
  if (!collection.influencers || !collection.influencers.length) return null;

  // Include these fields after link/unlink collection to campaign
  const include = ['influencers', 'campaigns'];

  return (
    <div className="CollectionView__relatedCampaigns">
      <div className="CollectionView__relatedCampaignsTitle">
        <FormattedMessage id="influencer.collection.linkToCampaign.label" tagName="strong" />
        <InfoTooltip>
          <FormattedMessage id="influencer.collection.linkToCampaign.tooltip" />
        </InfoTooltip>
      </div>
      <div className="is-flex-tablet">
        <CampaignsCollectionSelector
          options={campaigns}
          selected={collection.campaigns}
          onAdd={([campaignId]) => {
            dispatch(
              linkCollectionToCampaign.run({ collectionId: collection.id, campaignId, include })
            );
          }}
          onRemove={([campaignId]) => {
            dispatch(
              removeCollectionFromCampaign.run({ collectionId: collection.id, campaignId, include })
            );
          }}
          onCopyToClipboard={(campaignId: number) => {
            const link = `${window.location.origin}/dashboard/publisher/campaigns/${campaignId}/collection/${collection.id}`;
            copyTextToClipboard(link);
          }}
        />
      </div>
    </div>
  );
}

function SingleInfluencerCollection(props: {
  onChangeSettings: Function,
  collection: Object,
  name?: string
}) {
  const { onChangeSettings, collection, name = 'singleCollectionView' } = props;
  const dispatch = useDispatch();

  const [updatedVisibility, setUpdatedVisibility] = useState({});

  function removeChannelFromCollection(channelId, contentPlatform, collectionId) {
    switch (contentPlatform) {
      case ContentPlatform.YOUTUBE:
        return dispatch(removeYoutubeChannelFromCollection.run(channelId, collectionId));
      case ContentPlatform.TWITCH:
        return dispatch(removeTwitchChannelFromCollection.run(channelId, collectionId));
      case ContentPlatform.INSTAGRAM:
        return dispatch(removeInstagramChannelFromCollection.run(channelId, collectionId));
      default:
        break;
    }
  }

  function hideChannel(channelId, contentPlatform, collectionId) {
    switch (contentPlatform) {
      case ContentPlatform.YOUTUBE:
        setUpdatedVisibility({ ...updatedVisibility, [channelId]: 'hide' }); // TODO: Make it work with reducers
        return dispatch(hideChannelFromCollection.run(channelId, collectionId));
      default:
        break;
    }
  }

  function showChannel(channelId, contentPlatform, collectionId) {
    switch (contentPlatform) {
      case ContentPlatform.YOUTUBE:
        setUpdatedVisibility({ ...updatedVisibility, [channelId]: 'show' }); // TODO: Make it work with reducers
        return dispatch(showChannelFromCollection.run(channelId, collectionId));
      default:
        break;
    }
  }

  const { influencers = null } = collection;

  if (!influencers || !influencers.length) {
    return null;
  }

  // TODO @Tan 2020-03-27 when we actually have multiple channel types in the same collection
  // the sorting logic/options need to be changed to use common "attributes" among all the types
  // For example: we can potentially sort by subscriberCount for Youtube, followerCount for twitch, and followerCount for Instagram
  // But we can't sort by 30d avg views between Youtube and Instagram because they mean different things
  const sortOptions = (influencers || [])
    .reduce((contentPlatforms, influencer) => {
      const { contentPlatform } = getFirstAvailableContentPlatform(influencer) || {};

      if (!contentPlatform || contentPlatforms.includes(contentPlatform)) {
        return contentPlatforms;
      }

      return contentPlatforms.concat(contentPlatform);
    }, [])
    .reduce((sortOptions, contentPlatform) => {
      return getSortingOptions(contentPlatform);
    }, []);

  return (
    <InfluencerCollection
      name={name}
      influencers={influencers}
      card={InfluencerFollowedCard}
      cardProps={influencerProps => {
        const { channel, contentPlatform } =
          getFirstAvailableContentPlatform(influencerProps) || {};
        const isStaticCollection = !collection.searchId;

        return {
          influencer: influencerProps,
          adminActions: [
            isStaticCollection && (
              <Button
                key="removeChannelButton"
                size="small"
                onClick={() =>
                  removeChannelFromCollection(channel.id, contentPlatform, collection.id)
                }>
                <RemoveIcon type="circle" tooltipI18nString="influencer.collection.removeChannel" />
              </Button>
            ),
            (updatedVisibility[channel.id] === 'hide' ||
              (!updatedVisibility[channel.id] && !isStaticCollection && channel.hidden)) && (
              <Button
                key="showChannelButton"
                size="small"
                onClick={() => showChannel(channel.id, contentPlatform, collection.id)}>
                <VisibilityIcon off />
              </Button>
            ),
            (updatedVisibility[channel.id] === 'show' ||
              (!updatedVisibility[channel.id] && !isStaticCollection && !channel.hidden)) && (
              <Button
                key="hideChannelButton"
                size="small"
                onClick={() => hideChannel(channel.id, contentPlatform, collection.id)}>
                <VisibilityIcon />
              </Button>
            ),
            <StatefulCollectionSelectorTrigger
              type="static"
              key="CollectionSelectorTrigger"
              contentPlatform={contentPlatform}
              channelId={channel.id}
            />,
            influencerProps.id && (
              <InfluencerProfileModalTrigger
                key="InfluencerProfileModalTrigger"
                accountId={influencerProps.id}
              />
            )
          ]
        };
      }}
      cardSortOptions={sortOptions}
      list={FollowedInfluencersTable}
      listProps={props}
      onChangeSettings={onChangeSettings}
      searchBy={[
        'youtubeChannels[0].name',
        'twitchChannels[0].name',
        'instagramChannels[0].name',
        'instagramChannels[0].username'
      ]}
    />
  );
}

// TODO @Tan 2020-03-25 define proper types here
type Props = {
  collection: Object,
  isSearchingCampaigns: boolean,
  // used in SingleInfluencerCollection
  onChangeSettings: Function,
  name?: string,
  // used in LinkToCampaign
  campaigns: Object[],
  publicPage: boolean
};

export default function CollectionView(props: Props) {
  const { collection, campaigns, onChangeSettings, name, publicPage } = props;
  const dispatch = useDispatch();

  if (!collection || !collection.name) {
    return <NotFound />;
  }

  return (
    <div className="CollectionView container">
      <Helmet title={collection.name} />
      <div className="section">
        {publicPage && <h1 className="title">{collection.name}</h1>}
        {!publicPage && (
          <>
            <EditableHeader
              text={collection.name}
              onEdit={newName => {
                dispatch(changeName.run(collection.id, newName));
              }}
            />
            {(collection.teamId === 66 || collection.teamId === 1) && (
              <>
                <span>Public link (for sponsors):&nbsp;&nbsp;</span>
                <a
                  href={`https://app.matchmade.tv/collections/public/${
                    (collection.id + 345) * 57
                  }`}>{`https://app.matchmade.tv/collections/public/${
                  (collection.id + 345) * 57
                }`}</a>
                <br />
                <span>Subscription link (for creators):&nbsp;&nbsp;</span>
                <a
                  href={`https://creators.matchmade.tv/?c=${
                    (collection.id + 274) * 59
                  }`}>{`https://creators.matchmade.tv/?c=${(collection.id + 274) * 59}`}</a>
              </>
            )}
            <br />
            <LinkToCampaign campaigns={campaigns} collection={collection} />
            <div className="CollectionView__relatedCampaignsTitle">
              <p>
                <i>
                  Remember: if the campaign is visible, adding a collection to a campaign will cause
                  the campaign to show up in the platform for all influencers in the collection. It,
                  will also send a (email) notification to all in-network influencers in the
                  collection about the campaign.
                </i>
              </p>
            </div>
          </>
        )}
        {!publicPage && (
          <>
            Export detailed data:
            <DownloadUrlButton
              path={`/collections/${collection.id}/csv?include=influencers`}
              method="GET"
            />
            <br />
            <hr />
            Export public data:
          </>
        )}
        <DownloadUrlButton
          path={`/collections/public/${(collection.id + 345) * 57}/csv?include=influencers`}
          method="GET"
        />
      </div>
      <div className="CollectionView__influencers">
        <SingleInfluencerCollection
          onChangeSettings={onChangeSettings}
          collection={collection}
          name={name}
        />
      </div>
    </div>
  );
}
