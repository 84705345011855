// @flow
import React from 'react';

import FormattedNumber from '../FormattedNumber';

import './PercentageTable.scss';

const PercentageTable = ({
  data
}: {
  data: {
    label: string,
    value: number
  }[]
}) => {
  return (
    <div className="PercentageTable">
      {data.map(({ label, value }, index) => (
        <div key={index} className="tableItem">
          <span className="label">{label}</span>
          <div className="fixedWidthPercentage">
            <FormattedNumber type="percentage" value={value} defaultToZero />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PercentageTable;
