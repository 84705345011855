//@flow
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import type { IntlShape } from 'react-intl';
// $FlowFixMe
import Select from 'react-select';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';

import { PrefixIndexStrategy, UnorderedSearchIndex } from 'js-search';
import FloatToPercentageInput from '../../common/FloatToPercentageInput';
import createFilterOptions from 'react-select-fast-filter-options';

import './DynamicSelector.scss';

// To prioritise "Minecraft" when typing "mine" instead of "Guide for Minecraft" or something
const indexStrategy = new PrefixIndexStrategy();
// Unordered means order from server, which is by game popularity
const searchIndex = new UnorderedSearchIndex();

const countries = Object.entries(countryCodes).map(i => ({ code: i[0], name: i[1] }));

const OptionRenderer = option => (
  <div className="Option">
    <label className="Option__label">{option.label}</label>
  </div>
);

function CountryOptionRenderer(option) {
  return (
    <div className="Option">
      <label className="Option__label">{option.name}</label>
      <span
        title={option.name}
        className={`countryOptionFlag flag-icon flag-icon-${option.code.toLowerCase()}`}
      />
    </div>
  );
}

type Props = {
  disabled: boolean,
  demographicsTargetCountries: string[],
  onChangeDemographicsTargetCountries: (string[]) => void,
  demographicsTargetCountriesMinThreshold: number,
  onChangeDemographicsTargetCountriesMinThreshold: number => void,
  demographicsGenderType: string,
  onChangeDemographicsGenderType: string => void,
  demographicsGenderTypeMinThreshold: number,
  onChangeDemographicsGenderTypeMinThreshold: number => void,
  demographicsOperatingSystems: string[],
  onChangeDemographicsOperatingSystems: (string[]) => void,
  demographicsOperatingSystemMinThreshold: number,
  onChangeDemographicsOperatingSystemMinThreshold: number => void,
  demographicsAgeGroups: string[],
  onChangeDemographicsAgeGroups: (string[]) => void,
  demographicsAgeGroupsMinThreshold: number,
  onChangeDemographicsAgeGroupsMinThreshold: number => void,
  intl: IntlShape
};

class InNetworkChannelFilters extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false
  };

  renderTargetCountrySelector() {
    let options = (countries || [])
      .map(country => {
        if (this.props.demographicsTargetCountries.indexOf(country.code) !== -1) return null;
        return country;
      })
      .filter(Boolean);

    const filterOptions = createFilterOptions({
      options,
      indexStrategy,
      searchIndex,
      labelKey: 'name',
      valueKey: 'code',
      indexes: ['code', 'name'] // This allows typing "us" or "Unit" to return "United States of America"
    });

    return (
      <Select
        className="DynamicSelector"
        name="target-countries"
        clearable={false}
        isLoading={false}
        disabled={this.props.disabled}
        placeholder="Select countries for the channel audience"
        options={options}
        onChange={country => {
          this.props.onChangeDemographicsTargetCountries(
            this.props.demographicsTargetCountries
              .concat(country.code)
              .filter((v, i, arr) => arr.findIndex(a => a === v) === i)
          );
        }}
        filterOptions={filterOptions}
        multi={false}
        optionRenderer={CountryOptionRenderer}
        labelKey="name"
        valueKey="code"
      />
    );
  }

  renderGenderSelector() {
    const options = [
      { value: 'female', label: 'Female' },
      { value: 'male', label: 'Male' }
    ];

    return (
      <Select
        className="DynamicSelector"
        name="gender-groups"
        clearable={false}
        value={this.props.demographicsGenderType}
        disabled={this.props.disabled}
        placeholder="Age groups for the channel audience"
        options={options}
        optionRenderer={OptionRenderer}
        onChange={v => {
          this.props.onChangeDemographicsGenderType(v.value);
        }}
      />
    );
  }

  renderAgeGroupsSelector() {
    const options = [
      { value: 'age13-17', label: 'Age 13 - 17' },
      { value: 'age18-24', label: 'Age 18 - 24' },
      { value: 'age25-34', label: 'Age 25 - 34' },
      { value: 'age55-64', label: 'Age 55 - 64' },
      { value: 'age35-44', label: 'Age 35 - 44' },
      { value: 'age45-54', label: 'Age 45 - 54' },
      { value: 'age65-', label: 'Age 65 -' }
    ];

    return (
      <Select
        className="DynamicSelector"
        name="age-groups"
        clearable={false}
        value={this.props.demographicsAgeGroups}
        disabled={this.props.disabled}
        placeholder="Age groups for the channel audience"
        options={options}
        optionRenderer={OptionRenderer}
        onChange={v => {
          this.props.onChangeDemographicsAgeGroups(
            v.map(x => {
              return x.value;
            })
          );
        }}
        isMulti
        multi={true}
      />
    );
  }

  renderOperatingSystemsSelector() {
    const options = [
      { value: 'ios', label: 'iOS' },
      { value: 'android', label: 'Android' },
      { value: 'windows', label: 'windows' },
      { value: 'macintosh', label: 'Macintosh' },
      { value: 'amazon_fireos', label: 'Amazon_fireos' },
      { value: 'apple_tvos', label: 'Apple TV OS' },
      { value: 'blackberry', label: 'Blackberry' },
      { value: 'chromecast', label: 'Chromecast' },
      { value: 'firefox', label: 'Firefox' },
      { value: 'kaios', label: 'Kaios' },
      { value: 'linux', label: 'Linux' },
      { value: 'nintendo_3ds', label: 'Nintendo 3DS' },
      { value: 'nintendo_switch', label: 'Nintendo Switch' },
      { value: 'other', label: 'Other' },
      { value: 'playstation', label: 'Playstation' },
      { value: 'realmedia', label: 'Realmedia' },
      { value: 'rokuos', label: 'Rokuos' },
      { value: 'smart_tv', label: 'Smart TV' },
      { value: 'symbian', label: 'Symbian' },
      { value: 'tizen', label: 'Tizen' },
      { value: 'webos', label: 'Webos' },
      { value: 'wii', label: 'Wii' },
      { value: 'windows_mobile', label: 'Windows Mobile' },
      { value: 'xbox', label: 'Xbox' }
    ];

    return (
      <Select
        className="DynamicSelector"
        name="os-groups"
        clearable={false}
        value={this.props.demographicsOperatingSystems}
        disabled={this.props.disabled}
        placeholder="Operating systems for the channel audience"
        options={options}
        optionRenderer={OptionRenderer}
        onChange={v => {
          this.props.onChangeDemographicsOperatingSystems(
            v.map(x => {
              return x.value;
            })
          );
        }}
        isMulti
        multi={true}
      />
    );
  }

  render() {
    return (
      <div className="YoutubeSearchSettings">
        <div className="notification is-info">
          Note, that these filters are only applied to those results that are in-network
          influencers. Out-of-network influencers are not affected by these criteria!
        </div>

        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">
            <div className="YoutubeSearchSettings__group-item">
              {this.renderTargetCountrySelector()}
            </div>
            <div className="YoutubeSearchSettings__group-item">
              <FormattedMessage
                id="youtube.search.setting.demographicsTargetCountriesMinThreshold"
                tagName="strong"
              />
              <div className="control has-icons-left">
                <FloatToPercentageInput
                  value={this.props.demographicsTargetCountriesMinThreshold}
                  onChangedValue={this.props.onChangeDemographicsTargetCountriesMinThreshold}
                  onChangingValue={this.props.onChangeDemographicsTargetCountriesMinThreshold}
                  disabled={this.props.disabled}
                />
                <span className="icon is-small is-left">%</span>
              </div>
            </div>
          </div>

          <div className="YoutubeSearchSettings__group">
            <div className="YoutubeSearchSettings__group-item">{this.renderGenderSelector()}</div>
            <div className="YoutubeSearchSettings__group-item">
              <FormattedMessage
                id="youtube.search.setting.demographicsGenderTypeMinThreshold"
                tagName="strong"
              />
              <div className="control has-icons-left">
                <FloatToPercentageInput
                  value={this.props.demographicsGenderTypeMinThreshold}
                  onChangedValue={this.props.onChangeDemographicsGenderTypeMinThreshold}
                  onChangingValue={this.props.onChangeDemographicsGenderTypeMinThreshold}
                  disabled={this.props.disabled}
                />
                <span className="icon is-small is-left">%</span>
              </div>
            </div>
          </div>
        </div>

        <div className="is-flex-tablet">
          <div className="YoutubeSearchSettings__group">
            <div className="YoutubeSearchSettings__group-item">
              {this.renderAgeGroupsSelector()}
            </div>
            <div className="YoutubeSearchSettings__group-item">
              <FormattedMessage
                id="youtube.search.setting.demographicsAgeGroupsMinThreshold"
                tagName="strong"
              />
              <div className="control has-icons-left">
                <FloatToPercentageInput
                  value={this.props.demographicsAgeGroupsMinThreshold}
                  onChangedValue={this.props.onChangeDemographicsAgeGroupsMinThreshold}
                  onChangingValue={this.props.onChangeDemographicsAgeGroupsMinThreshold}
                  disabled={this.props.disabled}
                />
                <span className="icon is-small is-left">%</span>
              </div>
            </div>
          </div>

          <div className="YoutubeSearchSettings__group">
            <div className="YoutubeSearchSettings__group-item">
              {this.renderOperatingSystemsSelector()}
            </div>
            <div className="YoutubeSearchSettings__group-item">
              <FormattedMessage
                id="youtube.search.setting.demographicsOperatingSystemMinThreshold"
                tagName="strong"
              />
              <div className="control has-icons-left">
                <FloatToPercentageInput
                  value={this.props.demographicsOperatingSystemMinThreshold}
                  onChangedValue={this.props.onChangeDemographicsOperatingSystemMinThreshold}
                  onChangingValue={this.props.onChangeDemographicsOperatingSystemMinThreshold}
                  disabled={this.props.disabled}
                />
                <span className="icon is-small is-left">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(InNetworkChannelFilters);
