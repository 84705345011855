// @flow
import React from 'react';

import getConfig from '../../../config';

import { FormattedMessage } from 'react-intl';

const email = getConfig('saleEmail');
const mailTo = `mailto:${email}`;

const UnverifiedConfirmation = () => (
  <div className="CampaignViewForPublisher__verify-first">
    <FormattedMessage
      id="campaign.availableInfluencers.verifyFirst"
      values={{ email: <a href={mailTo}>{email}</a> }}
    />
  </div>
);

export default UnverifiedConfirmation;
