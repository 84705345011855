// @flow
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import callApi from '../../helpers/api';
// $FlowFixMe
import { useAsync } from 'react-async';
import React, { useState } from 'react';
// $FlowFixMe
import { useIntl } from 'react-intl';
// $FlowFixMe
import Select from 'react-select';
import useError from '../../hooks/useError';

import InfluencerCardLayout from '../../components/influencer/InfluencerCardLayout';
import type { Collection } from '../../types/collection.flow';
import type { InstagramChannel } from '../../types/instagram.flow';

import './AdminDashboardCommon.scss';
import './AdminDashboardImportInfluencer.scss';

const formatInfluencers = (
  influencers
): {
  isOutOfNetworkInfluencer: boolean,
  account: null,
  id: null,
  language: null,
  country: null,
  estimatedCountry: null,
  matchingVideos: [],
  youtubeChannels: [],
  twitchChannel: [],
  instagramChannels: InstagramChannel[]
}[] => {
  return influencers
    .filter(influencerFromApi => {
      return !!influencerFromApi.result;
    })
    .map(influencerResultWithData => {
      const { result: instagramInfluencer } = influencerResultWithData;
      return {
        isOutOfNetworkInfluencer: true,
        account: null,
        id: null,
        language: null,
        country: null,
        estimatedCountry: null,
        matchingVideos: [],
        youtubeChannels: [],
        twitchChannel: [],
        instagramChannels: [
          {
            id: instagramInfluencer.id,
            username: instagramInfluencer.username,
            followingCount: instagramInfluencer.followingCount,
            followerCount: instagramInfluencer.followerCount,
            postCount: instagramInfluencer.postCount,
            name: instagramInfluencer.name,
            avatarUrl: instagramInfluencer.avatarUrl,
            biography: instagramInfluencer.biography,
            country: null,
            engagementRate: null
          }
        ]
      };
    });
};

async function fetchInfluencers(input) {
  const path = '/instagram/channels';
  const urlsAsArray = input.split(/\r?\n/);
  const usernames = urlsAsArray.map(url => {
    const regex = /instagram\.com\/([^/]*)/g;
    const matches = regex.exec(url);
    if (!matches || !matches.length) {
      throw new Error(
        'Failed to search creators based on input. Are you sure you input a list of IG creator channel URLs? If the problem persists, please contact the fixer.'
      );
    }
    return matches[1];
  });
  const res = await callApi(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ usernames })
  });
  return res.data;
}

async function fetchCollections() {
  const path = '/collections/static';
  const res = await callApi(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res.data;
}

async function importInfluencers(fetchedInfluencers) {
  const influencersToImport = fetchedInfluencers
    .filter(inf => {
      return !!inf.result;
    })
    .map(inf => {
      return inf.result;
    });
  const path = '/influencers/instagram/import';
  const res = await callApi(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ channels: influencersToImport })
  });
  return res.data;
}

async function addToCollection(importedInfluencers, collectionId) {
  if (!collectionId) {
    return;
  }
  const channelIds = importedInfluencers.map(inf => {
    return inf.id;
  });
  const path = `/collections/${collectionId}/instagram-channels`;
  const res = await callApi(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ channelIds })
  });
  return res.data;
}

const AdminDashboardAddInstagramChannelsToCollection = () => {
  const [input, setInput] = useState('');
  const [selectedCollection, setSelectedCollection] = useState<?Collection>(null);
  const { showError } = useError();
  const intl = useIntl();

  const {
    data: fetchedInfluencers,
    isLoading: influencersLoading,
    run: getInstagramInfluencers
  }: {
    data: { username: string, result: InstagramChannel }[],
    isLoading: boolean,
    run: Function
  } = useAsync({
    deferFn: () => fetchInfluencers(input),
    onReject: showError
  });

  const {
    data: collections
  }: {
    data: Collection[]
  } = useAsync({
    promiseFn: fetchCollections,
    onReject: showError
  });

  const {
    data: importedInfluencers,
    error: importInfluencersError,
    isLoading: importInfluencersLoading,
    run: runImportInfluencers
  }: {
    data: InstagramChannel[],
    error: { name: string, message: string },
    isLoading: boolean,
    run: Function
  } = useAsync({
    deferFn: () => importInfluencers(fetchedInfluencers),
    onReject: showError
  });

  const {
    data: addedToCollection,
    isLoading: addToCollectionLoading,
    run: runAddToCollection
  }: {
    data: { id: number, channels: string[] },
    isLoading: boolean,
    run: Function
  } = useAsync({
    deferFn: () =>
      addToCollection(importedInfluencers, selectedCollection && selectedCollection.id),
    onReject: showError
  });

  function loadingFetches() {
    return influencersLoading || importInfluencersLoading || addToCollectionLoading;
  }

  function renderAddToCollection() {
    return (
      <div className="field is-horizontal AdminDashboardImportInfluencer__add-to-collection-section">
        <div className="field-label" />
        <div className="field-body">
          <div className="field">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-fullwidth">{renderCollectionDropDown()}</div>
              </div>
              <div className="control">
                <button
                  className="button is-primary"
                  disabled={!selectedCollection || !importedInfluencers || loadingFetches()}
                  onClick={runAddToCollection}>
                  <FormattedMessage id="admin.collections.addInstagramChannels.addToCollectionButton" />
                </button>
              </div>
            </div>
            {addedToCollection && (
              <p className={`help is-success`}>Successfully added creators to collection</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderImportedResults() {
    if (!fetchedInfluencers || !fetchedInfluencers.length) {
      return <FormattedMessage id="admin.collections.addInstagramChannels.noChannels" />;
    }

    return formatInfluencers(fetchedInfluencers).map(inf => {
      return (
        <div
          key={`ig-import-${inf.instagramChannels[0].id}`}
          className="AdminDashboardImportInfluencer__ig-card">
          <InfluencerCardLayout
            influencer={inf}
            tabs={[
              {
                key: 'overview',
                label: <FormattedMessage id="influencer.card.tabs.overview" />,
                icon: 'fa-image',
                disabled: false,
                content: <div>This card is a placeholder</div>
              }
            ]}
            onChangeTab={() => {}}
            footer={<div></div>}
            footerHasBorder={false}
            isLoading={false}
          />
        </div>
      );
    });
  }

  const renderCollectionDropDown = () => {
    return (
      <Select
        onChange={option => setSelectedCollection(option)}
        placeholder={intl.formatMessage({
          id: 'admin.collections.addInstagramChannels.selectCollectionPlaceholder'
        })}
        options={collections}
        value={selectedCollection}
        multi={false}
        labelKey="name"
        valueKey="id"
      />
    );
  };

  return (
    <div className="container AdminDashboardImportInfluencer">
      <Helmet title="Add channels to collection" />
      <h1 className="title">
        <FormattedMessage id="admin.collections.addInstagramChannels.title" />
      </h1>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage id="admin.collections.addInstagramChannels.channelsLabel" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <textarea
                value={input}
                onChange={e => setInput(e.target.value)}
                className="textarea"
                placeholder={intl.formatMessage({
                  id: 'admin.collections.addInstagramChannels.channelListPlaceholder'
                })}
                rows="10"
              />
            </div>
            <p className="help">
              <FormattedMessage id="admin.collections.addInstagramChannels.helpInfo" />
            </p>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label" />
        <div className="field-body">
          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!input || input === '' || loadingFetches()}
                onClick={getInstagramInfluencers}>
                <FormattedMessage id="admin.collections.addInstagramChannels.searchButton" />
              </button>
            </div>
            <div className="control">
              <button
                className="button is-primary"
                disabled={
                  !fetchedInfluencers ||
                  (fetchedInfluencers && !fetchedInfluencers.length) ||
                  loadingFetches()
                }
                onClick={runImportInfluencers}>
                <FormattedMessage id="admin.collections.addInstagramChannels.importButton" />
              </button>
            </div>
            {importInfluencersError && (
              <p className={`help is-danger`}>{importInfluencersError.message}</p>
            )}

            {importedInfluencers && !importInfluencersError && (
              <p className={`help is-success`}>Successfully imported creators</p>
            )}
          </div>
        </div>
      </div>
      {renderAddToCollection()}
      {renderImportedResults()}
    </div>
  );
};

export default injectIntl(AdminDashboardAddInstagramChannelsToCollection);
