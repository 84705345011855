//@flow
import * as React from 'react';
import CreateInvoiceItemForm from './CreateInvoiceItemForm';

const CreateInvoiceItem = () => {
  return (
    <div className="container">
      <h1 className="title is-1">Create invoice items</h1>
      <CreateInvoiceItemForm />
    </div>
  );
};

export default CreateInvoiceItem;
