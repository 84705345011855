// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import './MultiSelectButtonWithDefault.scss';

export type Option<T> = {
  value: T,
  label: string | React.Element<*>
};

type Props<T = string> = {
  values: T[],
  options: Option<T>[],
  defaultValue: T | null,

  onChangeValues: (values: T[]) => void,
  onChangeDefaultValue: (value: T | null) => void,

  // set default to null when not selecting more than 1 options
  resetDefaultValue: boolean,

  // must select at least 1 or not
  allowEmpty: boolean,
  disabled?: boolean,

  className: string
};

function onSelect<T>(option: any, selected: boolean, props: Props<T>) {
  return () => {
    const newValues = selected
      ? props.values.filter(opt => {
          if (opt === option) {
            if (props.allowEmpty) return false;
            if (props.values.length > 1) return false;
            return true;
          }

          return true;
        })
      : props.values.concat([option]).reduce((all, current) => {
          const c: T = (current: any);
          if (all.indexOf(current) !== -1) return all;
          return all.concat([c]);
        }, []);

    props.onChangeValues(newValues);
    if (newValues.length <= 1 && props.resetDefaultValue) {
      props.onChangeDefaultValue(null);
    }
  };
}

MultiSelectButtonWithDefault.defaultProps = {
  className: '',
  resetDefaultValue: false,
  allowEmpty: false,
  disabled: false
};

export default function MultiSelectButtonWithDefault<T>(props: Props<T>) {
  const { defaultValue, values, disabled } = props;

  const options = props.options.map(option => {
    const selected = values.find(value => value === option.value);
    const key = String(option.value);

    const defaultSelection =
      props.values.length > 1 ? (
        <button
          className="button is-primary MultiSelectButtonWithDefault__default-button"
          disabled={disabled}
          onClick={() => {
            props.onChangeDefaultValue(option.value);
          }}>
          <label className="radio is-inverted">
            <input
              onChange={e => {
                props.onChangeDefaultValue(option.value);
              }}
              disabled={disabled}
              value={`${key}-default`}
              type="radio"
              name={uniqueId('multi-select-button')}
              checked={defaultValue === option.value}
            />
            <FormattedMessage id="multiSelectButtonWithDefault.default" />
          </label>
        </button>
      ) : null;

    const btnClassName = classNames('button', {
      'is-primary': !!selected,
      MultiSelectButtonWithDefault__button: true
    });
    return (
      <div key={key} className="buttons has-addons">
        <button
          onClick={onSelect(option.value, !!selected, props)}
          className={btnClassName}
          disabled={disabled}>
          {option.label}
        </button>
        {defaultSelection}
      </div>
    );
  });

  return <div className={`MultiSelectButtonWithDefault ${props.className}`}>{options}</div>;
}
