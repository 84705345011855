import url from 'url';

import getConfig from '../config';

export function getAuthUrlWithNextUrl(name, location = {}) {
  const urlObj = url.parse(getConfig(name), true);
  urlObj.query = Object.assign({}, location.query, urlObj.query);
  delete urlObj.search;

  return url.format(urlObj);
}
