// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import InstallAttribution from './InstallAttribution';
import type { Props } from './InstallAttribution';

import InfoTooltip from '../../common/InfoTooltip';

import './InstallAttributionSetting.scss';

function InstallAttributionSetting(props: Props) {
  return (
    <div className="field is-horizontal InstallAttributionSetting">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.campaignDetails.advancedSetting.installAttribution" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.campaignDetails.advancedSetting.installAttribution.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <div className="field is-horizontal">
          <div className="control InstallAttributionSetting__options">
            <InstallAttribution {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstallAttributionSetting;
