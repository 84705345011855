// @flow
import type { ApiResponse } from '../../types/api/response.flow';
import type { GetAgencyInfoData } from '../../types/api/influencers.flow';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Provider, connect } from 'react-redux';
// $FlowFixMe
import { useAsync } from 'react-async';

import callApi from '../../helpers/api';

import { defaultSettings } from '../../constants';
import {
  getInfluencerSettings,
  getInfluencerYoutubePreferences,
  saveInfluencerSettings,
  saveInfluencerYoutubePreferences
} from '../../actions/influencerSettings';
import CheckIcon from '../common/Icons/CheckIcon';
import EditIcon from '../common/Icons/EditIcon';
import InfluencerSettingsForm from './InfluencerSettingsForm';
import InfluencerYoutubePreferencesForm from './InfluencerYoutubePreferencesForm';
import Spinner from '../common/Spinner';
import configureStore from '../../configureStore';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import influencerSettingsReducer, { initialState } from '../../reducers/common/influencerSettings';
import type { ReduxInjectedProps } from '../../reducers/common/influencerSettings';

import './InfluencerEditableProfile.scss';

const { store } = configureStore(influencerSettingsReducer, initialState);

type Props = {
  ...ReduxInjectedProps,
  accountId: number,
  disableYoutubePreferences?: boolean,
  disableSettings?: boolean,
  disableAgencyInfo?: boolean,
  isFetchingAgencyInfo: boolean,
  agencyInfo: {
    id: number,
    name: string
  }
};

const ToggleButton = ({ onToggle, editMode = false }) => {
  return (
    <span className="InfluencerEditableProfile__edit-mode-toggle__button" onClick={onToggle}>
      {!editMode ? (
        <EditIcon className="button is-primary is-rounded" withTooltip={false} />
      ) : (
        <CheckIcon className="button is-primary is-rounded" />
      )}
    </span>
  );
};

function AgencyInfo({ id, name }: { id: number, name: string }) {
  return (
    <div className="columns">
      <div className="column is-full">
        <div className="control">
          <label className="label">
            <FormattedMessage id="influencer.profile.agencyName" />
          </label>
          {/* can't use <Link> here because we are out of the router context */}
          <a rel="noopener noreferrer" target="_blank" href={`/admin/manager-teams/${id}`}>
            {name}
          </a>
        </div>
      </div>
    </div>
  );
}

export function InfluencerEditableProfile({
  accountId,
  settings,
  youtubePreferences,
  saveInfluencerYoutubePreferences,
  saveInfluencerSettings,
  isFetchingYoutubePreferences,
  isFetchingSettings,
  isFetchingAgencyInfo,
  agencyInfo,
  disableSettings,
  disableYoutubePreferences,
  disableAgencyInfo
}: Props) {
  const [editMode, setEditMode] = React.useState(false);
  const [settingsForm, setSettingsState] = React.useState(defaultSettings);
  const [youtubePreferencesForm, setYoutubePreferencesState] = React.useState(null);
  const isLoading = isFetchingYoutubePreferences || isFetchingSettings || isFetchingAgencyInfo;

  React.useEffect(() => {
    setSettingsState(settings || defaultSettings);
  }, [settings]);

  React.useEffect(() => {
    setYoutubePreferencesState(youtubePreferences);
  }, [youtubePreferences]);

  const onToggleEditMode = () => {
    // On submit
    if (editMode) {
      if (!disableSettings) {
        saveInfluencerSettings(accountId, settingsForm);
      }
      if (!disableYoutubePreferences) {
        saveInfluencerYoutubePreferences(accountId, youtubePreferencesForm);
      }
    }
    setEditMode(!editMode);
  };

  const agencyInfoContent = agencyInfo ? <AgencyInfo {...agencyInfo} /> : null;

  return (
    <div className="InfluencerEditableProfile">
      {isLoading && <Spinner size="large" />}
      {!disableAgencyInfo && agencyInfoContent ? (
        <p>
          <h2 className="subtitle">
            <FormattedMessage id="influencer.profile.agencyInfo" />
          </h2>
          {agencyInfoContent}
        </p>
      ) : null}
      {!disableSettings && (
        <p>
          <h2 className="subtitle">
            <FormattedMessage id="influencer.profile.contactInformation" />
          </h2>
          <InfluencerSettingsForm
            settings={settingsForm}
            editMode={editMode}
            onChange={form =>
              setSettingsState({
                ...settings,
                ...form
              })
            }
          />
        </p>
      )}
      {!disableYoutubePreferences && (
        <p>
          <h2 className="subtitle">
            <FormattedMessage id="influencer.profile.modal.youtubePreferences.title" />
          </h2>
          <InfluencerYoutubePreferencesForm
            youtubePreferences={youtubePreferencesForm}
            editMode={editMode}
            onChange={form => {
              setYoutubePreferencesState({
                ...youtubePreferences,
                ...form
              });
            }}
          />
        </p>
      )}
      <ToggleButton onToggle={onToggleEditMode} editMode={editMode} />
    </div>
  );
}

const mapStateToProps = state => state;
const mapDispatchToProps = generateDispatchToProps({
  saveInfluencerSettings,
  getInfluencerSettings,
  saveInfluencerYoutubePreferences,
  getInfluencerYoutubePreferences
});

function callGetAgencyInfoAPI(influencerId) {
  return callApi(`/influencers/${influencerId}/agency`, {
    method: 'GET'
  });
}

let InfluencerEditableProfileWithStore = (props: Props) => {
  const {
    accountId,
    getInfluencerSettings,
    getInfluencerYoutubePreferences,
    disableSettings,
    disableYoutubePreferences,
    disableAgencyInfo
  } = props;

  const {
    isPending: isFetchingAgencyInfo,
    data: agencyInfo,
    run: getAgencyInfo
  }: { isPending: boolean, data: ApiResponse<GetAgencyInfoData>, run: Function } = useAsync({
    deferFn: callGetAgencyInfoAPI
  });

  React.useEffect(() => {
    if (!disableAgencyInfo) {
      getAgencyInfo(accountId);
    }
    if (!disableSettings) {
      getInfluencerSettings(accountId);
    }
    if (!disableYoutubePreferences) {
      getInfluencerYoutubePreferences(accountId);
    }
  }, [
    accountId,
    getInfluencerSettings,
    getInfluencerYoutubePreferences,
    getAgencyInfo,
    disableSettings,
    disableYoutubePreferences,
    disableAgencyInfo
  ]);

  return (
    <InfluencerEditableProfile
      {...props}
      isFetchingAgencyInfo={isFetchingAgencyInfo}
      agencyInfo={agencyInfo ? agencyInfo.data : null}
    />
  );
};

InfluencerEditableProfileWithStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerEditableProfileWithStore);

export default function WithProvider(props: Props) {
  return (
    <Provider store={store}>
      <InfluencerEditableProfileWithStore {...props} />
    </Provider>
  );
}
