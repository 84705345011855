// @flow
import { authorizedFetch } from '../../helpers/api';
import React from 'react';
import browserHistory from 'react-router/lib/browserHistory';

type Props = {|
  params: {
    role: string,
    accountId: number
  }
|};

export const AdminLoginAs = (props: Props) => {
  React.useEffect(() => {
    async function getLoggedInAsCookieAndRedirect() {
      await authorizedFetch(
        `/auth/login-as-user-without-redirect?role=${props.params.role}&accountId=${props.params.accountId}`
      );
      browserHistory.push('/');
      window.location.reload(false);
    }

    getLoggedInAsCookieAndRedirect();
  }, [props.params.accountId, props.params.role]);

  return null;
};
