import React from 'react';

import Input from '../../forms/controls/Input';

import FemaleIcon from '../../common/Icons/FemaleIcon';
import Form from '../../forms/Form';
import MaleIcon from '../../common/Icons/MaleIcon';

import './Gender.scss';

const iconToGender = {
  male: <MaleIcon />,
  female: <FemaleIcon />
};

const Gender = ({ onChange = () => {}, gender = {} }) => {
  return (
    <Form className="Gender" onChange={onChange} values={gender}>
      <h1>Gender</h1>
      <div>
        {Object.keys(iconToGender).map(iconLabel => {
          const icon = iconToGender[iconLabel];
          const value = gender[iconLabel] || '';
          return (
            <span className="Gender__entry is-flex" key={iconLabel}>
              <span className="Gender_label">{icon}</span>
              <span className="Gender__value">
                <Input
                  name={iconLabel}
                  type="number"
                  step={1}
                  min={1}
                  max={100}
                  value={value}
                  onChange={onChange}
                  iconLeft={<i className="fa fa-percent" />}
                />
              </span>
            </span>
          );
        })}
      </div>
    </Form>
  );
};

export default Gender;
