// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Props } from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import ServiceWithOneTrackingUrlOrPlatformSpecific from './ServiceWithOneTrackingUrlOrPlatformSpecific';
import getConfig from '../../../../config';

const appName = getConfig('appName');

export default function Tenjin(props: Props) {
  return (
    <div>
      <ServiceWithOneTrackingUrlOrPlatformSpecific {...props} name="tenjin" />
      <div>
        <FormattedMessage
          id="publisher.campaign.attribution.tenjinDescription"
          values={{
            descriptionTerm: appName,
            channelsLink: (...chunks) => {
              return <a href="https://www.tenjin.io/dashboard/channels">{chunks}</a>;
            },
            campaignsLink: (...chunks) => {
              return <a href="https://www.tenjin.io/dashboard/campaigns">{chunks}</a>;
            },
            p: (...chunks) => {
              return <p>{chunks}</p>;
            }
          }}
        />
      </div>
    </div>
  );
}
