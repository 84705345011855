// @flow
// This is from UUID module, but I don't want to drag all
// of it to our bundle just for one validation function.
// This also doesn't let nil uuid (so all 0) to pass, since it's not valid for our case
//
// https://github.com/uuidjs/uuid/blob/master/src/validate.js

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const validate = (maybeUuid: any) => typeof maybeUuid === 'string' && uuidRegex.test(maybeUuid);

export { validate };
