// @flow

import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import callApi from '../../helpers/api';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import CheckIcon from '../../components/common/Icons/CheckIcon';
import FooterNotification from '../../components/common/FooterNotification';

// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
// $FlowFixMe
import Select from 'react-select';

import './AdminDashboardCombineCollection.scss';
import './AdminDashboardCommon.scss';

import type { Collection, CollectionWithIncludedFields } from '../../types/collection.flow';

//$FlowFixMe
import { useAsync } from 'react-async';
import useError from '../../hooks/useError';

type CollectionSelectorProps = {
  collections: Collection[],
  value: ?Collection,
  onChange: Function,
  onAdd: Function
};
function CollectionSelector(props: CollectionSelectorProps) {
  const { collections, value, onAdd, onChange } = props;
  const intl = useIntl();

  return (
    <>
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className="select is-fullwidth">
            <Select
              onChange={option => onChange(option)}
              placeholder={intl.formatMessage({
                id: 'admin.collections.addChannels.selectCollectionPlaceholder'
              })}
              options={collections}
              value={value}
              multi={false}
              labelKey="name"
              valueKey="id"
            />
          </div>
        </div>
        <div className="control">
          <button className="button is-primary" disabled={!value} onClick={onAdd}>
            +
          </button>
        </div>
      </div>
    </>
  );
}

type TagsProps = {
  collections: CollectionWithIncludedFields[],
  onDeleteCollection: Function
};
const Tags = ({ collections, onDeleteCollection }: TagsProps) => (
  <div className="field is-grouped is-grouped-multiline">
    {(collections || []).map(collection => (
      <div key={collection.id} className="control">
        <div className="tags has-addons">
          <a
            className="tag is-link"
            target="_blank"
            rel="noopener noreferrer"
            href={`/collections/${collection.id}`}>
            {collection.name}
          </a>
          <span
            className="tag is-info is-delete"
            onClick={() => onDeleteCollection(collection)}></span>
        </div>
      </div>
    ))}
  </div>
);

const NotificationSuccess = ({ collection }) => {
  return (
    <FooterNotification type="success">
      <div className="FooterNotification__icon">
        <CheckIcon width="2.85rem" height="2.85rem" />
      </div>
      <div>
        <FormattedMessage
          id="admin.collections.create.successfully"
          values={{
            link: (
              <a href={`/collections/${collection.id}`} target="_blank" rel="noopener noreferrer">
                {collection.name}
              </a>
            ),
            id: collection.id
          }}
        />
      </div>
    </FooterNotification>
  );
};

async function fetchCollections() {
  const path = '/collections/static';
  const res = await callApi(path, {
    method: 'GET'
  });
  return res.data;
}

async function fetchCollectionById(id: number) {
  const path = `/collections/${id}?include=channels`;
  const res = await callApi(path, {
    method: 'GET'
  });
  return res.data;
}

async function createCollection({ name, youtubeChannelIds = [], instagramChannelIds = [] }) {
  const path = `/collections`;
  const res = await callApi(path, {
    method: 'POST',
    body: {
      name,
      youtubeChannelIds,
      instagramChannelIds
    }
  });
  return res.data;
}

type CombineCollectionsProps = {
  title: string,
  getPreviewChannels: (
    includedCollections: CollectionWithIncludedFields[],
    excludedCollections: CollectionWithIncludedFields[]
  ) => string[],
  getCreateChannels: (
    includedCollections: CollectionWithIncludedFields[],
    excludedCollections: CollectionWithIncludedFields[]
  ) => { youtubeChannelIds: string[], instagramChannelIds: string[] }
};
function CombineCollections({
  title,
  getPreviewChannels,
  getCreateChannels
}: CombineCollectionsProps) {
  const { showError } = useError();
  const intl = useIntl();
  const [name, setName] = useState<string>('');
  const [newCollection, setNewCollection] = useState<?Collection>(null);
  const [includedCollections, setIncludedCollections] = useState<CollectionWithIncludedFields[]>(
    []
  );
  const [excludedCollections, setExcludedCollections] = useState<CollectionWithIncludedFields[]>(
    []
  );
  const [
    selectedIncludedCollection,
    setSelectedIncludedCollection
  ] = useState<?CollectionWithIncludedFields>(null);
  const [
    selectedExcludedCollection,
    setSelectedExcludedCollection
  ] = useState<?CollectionWithIncludedFields>(null);

  const resetForm = () => {
    setName('');
    setIncludedCollections([]);
    setExcludedCollections([]);
    setSelectedIncludedCollection(null);
    setSelectedExcludedCollection(null);
  };

  const {
    data: collections
  }: {
    data: Collection[]
  } = useAsync({
    promiseFn: fetchCollections,
    onReject: showError
  });

  const { run: fetchIncludedCollection } = useAsync({
    deferFn: ([id]) => fetchCollectionById(id),
    onResolve: collection => setIncludedCollections([...includedCollections, collection]),
    onReject: error => showError(error)
  });

  const { run: fetchExcludedCollection } = useAsync({
    deferFn: ([id]) => fetchCollectionById(id),
    onResolve: collection => setExcludedCollections([...excludedCollections, collection]),
    onReject: error => showError(error)
  });

  const { run: save, isLoading } = useAsync({
    deferFn: ([id]) => {
      const { youtubeChannelIds, instagramChannelIds } = getCreateChannels(
        includedCollections,
        excludedCollections
      );
      return createCollection({ name, youtubeChannelIds, instagramChannelIds });
    },
    onResolve: collection => {
      setNewCollection(collection);
      resetForm();
      //cleanup notification message after 5 seconds
      setTimeout(() => setNewCollection(null), 5000);
    },
    onReject: error => showError(error)
  });

  const onChangeCollectionName = e => setName(e.target.value);

  const onAddIncludeCollection = () => {
    const collection = selectedIncludedCollection;
    if (collection && includedCollections.findIndex(c => c.id === collection.id) === -1) {
      fetchIncludedCollection(collection.id);
      setSelectedIncludedCollection(null);
    }
  };
  const onAddExcludedCollection = () => {
    const collection = selectedExcludedCollection;
    if (collection && excludedCollections.findIndex(c => c.id === collection.id) === -1) {
      fetchExcludedCollection(collection.id);
      setSelectedExcludedCollection(null);
    }
  };

  const onChangeIncludedCollection = collection => setSelectedIncludedCollection(collection);
  const onChangeExcludedCollection = collection => setSelectedExcludedCollection(collection);

  const onDeleteIncludedCollection = (collection: Collection) => {
    setIncludedCollections([...includedCollections.filter(c => c.id !== collection.id)]);
  };
  const onDeleteExcludedCollection = (collection: Collection) => {
    setExcludedCollections([...excludedCollections.filter(c => c.id !== collection.id)]);
  };

  const channels = getPreviewChannels(includedCollections, excludedCollections);

  return (
    <div className="container AdminDashboardCombineCollection">
      <Helmet title="Create collection" />
      <h1 className="title">{title}</h1>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage id="admin.collections.create.newCollectionName" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                value={name}
                onChange={onChangeCollectionName}
                placeholder={intl.formatMessage({
                  id: 'admin.collections.create.newCollectionName'
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage id="admin.collections.create.includedCollections" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <CollectionSelector
              collections={collections}
              value={selectedIncludedCollection}
              onChange={onChangeIncludedCollection}
              onAdd={onAddIncludeCollection}
            />
          </div>
        </div>
      </div>

      {includedCollections.length > 0 && (
        <div className="field is-horizontal">
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <Tags
                collections={includedCollections}
                onDeleteCollection={onDeleteIncludedCollection}
              />
            </div>
          </div>
        </div>
      )}

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage id="admin.collections.create.excludedCollections" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <CollectionSelector
              collections={collections}
              value={selectedExcludedCollection}
              onChange={onChangeExcludedCollection}
              onAdd={onAddExcludedCollection}
            />
          </div>
        </div>
      </div>

      {excludedCollections.length > 0 && (
        <div className="field is-horizontal">
          <div className="field-label"></div>
          <div className="field-body">
            <div className="field">
              <Tags
                collections={excludedCollections}
                onDeleteCollection={onDeleteExcludedCollection}
              />
            </div>
          </div>
        </div>
      )}

      <div className="field is-horizontal">
        <div className="field-label" />
        <div className="field-body">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-primary"
                disabled={!name || !channels.length || isLoading}
                onClick={save}>
                <FormattedMessage id="admin.collections.create.createCollection" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">
            <FormattedMessage id="admin.collections.create.channelsPreview" />
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <textarea
                value={channels.join('\r\n')}
                className="textarea"
                placeholder={intl.formatMessage({
                  id: 'admin.collections.create.noChannels'
                })}
                disabled="disabled"
                rows="10"
              />
            </div>
          </div>
          <p className="help">{`Contains ${channels.length} unique channels`}</p>
        </div>
      </div>
      {newCollection && <NotificationSuccess collection={newCollection} />}
    </div>
  );
}

export const CombineYoutubeCollections = () => {
  const intl = useIntl();
  const getPreviewChannels = (includedCollections, excludedCollections) =>
    uniq(
      difference(
        includedCollections.flatMap(collection => collection.channels || []),
        excludedCollections.flatMap(collection => collection.channels || [])
      )
    );

  const getCreateChannels = (includedCollections, excludedCollections) => ({
    youtubeChannelIds: getPreviewChannels(includedCollections, excludedCollections),
    instagramChannelIds: []
  });

  return (
    <CombineCollections
      title={intl.formatMessage({ id: 'admin.collections.create.youtubeTitle' })}
      getPreviewChannels={getPreviewChannels}
      getCreateChannels={getCreateChannels}
    />
  );
};

export const CombineInstagramCollections = () => {
  const intl = useIntl();
  const getPreviewChannels = (includedCollections, excludedCollections) =>
    uniq(
      difference(
        includedCollections.flatMap(collection => collection.instagramChannels || []),
        excludedCollections.flatMap(collection => collection.instagramChannels || [])
      )
    );

  const getCreateChannels = (includedCollections, excludedCollections) => ({
    youtubeChannelIds: [],
    instagramChannelIds: getPreviewChannels(includedCollections, excludedCollections)
  });

  return (
    <CombineCollections
      title={intl.formatMessage({ id: 'admin.collections.create.instagramTitle' })}
      getPreviewChannels={getPreviewChannels}
      getCreateChannels={getCreateChannels}
    />
  );
};
