// @flow
import type { ChangeTeam, DuplicateCollection, RemoveCollection } from '../../actions/collection';
import type { State as ReducerState } from '../../reducers/containers/admin/adminDashboardCollections';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  changeTeam,
  duplicateAsDynamicCollection,
  duplicateAsStaticCollection,
  getAll as getAllCollections,
  removeCollection
} from '../../actions/collection';
import { connect, useDispatch } from 'react-redux';
import { fetchTeams as getAllTeams } from '../../actions/team';
import { useEffect } from 'react';

import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import './AdminDashboardCommon.scss';

import { Helmet } from 'react-helmet';

import CollectionsTable from '../../components/admin/CollectionsTable';
import Spinner from '../../components/common/Spinner';

type Actions = {|
  changeTeam: ChangeTeam,
  removeCollection: RemoveCollection,
  duplicateAsStaticCollection: DuplicateCollection,
  duplicateAsDynamicCollection: DuplicateCollection
|};

type Props = {|
  ...ReducerState,
  ...Actions
|};

function AdminDashboardCollections(props: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCollections.run(['channelsCount', 'team']));
    dispatch(getAllTeams.run());
  }, [dispatch]);

  const {
    isLoadingCollections,
    isLoadingTeams,
    collections,
    changeTeam,
    removeCollection,
    duplicateAsStaticCollection,
    duplicateAsDynamicCollection
  } = props;

  let content = null;
  let rootClasses = ['AdminDashboardCollections'];

  if (isLoadingCollections || isLoadingTeams) {
    return (
      <div className="AdminDashboardCampaigns">
        <Spinner mode="fullWidth" size="large" />
      </div>
    );
  }

  if (!collections || !collections.length) {
    content = (
      <div className="hero">
        <div className="hero-body">
          <h2 className="title">
            <FormattedMessage id="error.nodata" />
          </h2>
        </div>
      </div>
    );
  } else {
    rootClasses.push('container');

    content = (
      <CollectionsTable
        {...props}
        onDuplicateAsStaticCollection={duplicateAsStaticCollection}
        onDuplicateAsDynamicCollection={duplicateAsDynamicCollection}
        onDelete={removeCollection}
        onChangeTeam={changeTeam}
      />
    );
  }

  return (
    <div className={rootClasses.join(' ')}>
      <Helmet title="Collections" />
      {content}
    </div>
  );
}

const mapStateToProps = generateStateToProps('adminDashboardCollections');
const mapDispatchToProps = generateDispatchToProps({
  changeTeam,
  removeCollection,
  duplicateAsStaticCollection,
  duplicateAsDynamicCollection
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardCollections);
