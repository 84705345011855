// @flow
import type { ReducerCreator } from '../../types/action.flow';

import Immutable from 'immutable';

import { fetchPublisher, fetchPublishers, importGame, searchGame } from '../../actions/publisher';

export default {
  fetchPublisher(state: any, action: any) {
    switch (action.type) {
      case fetchPublisher.REQUEST:
        return state.set('isLoadingPublisher', true);
      case fetchPublisher.SUCCESS:
        return state
          .set('isLoadingPublisher', false)
          .set('publisher', Immutable.fromJS(action.payload));
      case fetchPublisher.FAILURE:
        return state
          .set('isLoadingPublisher', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchPublishers(state: any, action: any) {
    switch (action.type) {
      case fetchPublishers.REQUEST:
        return state.set('isLoadingPublishers', true);
      case fetchPublishers.SUCCESS:
        return state
          .set('isLoadingPublishers', false)
          .set('publishers', Immutable.fromJS(action.payload));
      case fetchPublishers.FAILURE:
        return state
          .set('isLoadingPublishers', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  // this is deprecated, use handleImportGameActions instead
  importGame(state: any, action: any) {
    switch (action.type) {
      case importGame.REQUEST:
        return state.set('isImportingGame', true).set('importingGameTitle', action.payload.title);
      case importGame.SUCCESS:
        let publisher = state.get('publisher');
        const game = Immutable.fromJS(action.payload);

        if (!publisher) {
          return state
            .set('isImportingGame', false)
            .set('importingGameTitle', null)
            .set('importedGame', game);
        }

        let games = publisher.get('games');

        if (!games) {
          games = Immutable.List.of(game);
        } else {
          if (
            games.findIndex((value, index) => {
              return value.get('id') === game.get('id');
            }) === -1
          ) {
            games = games.push(game);
          }
        }

        publisher = publisher.set('games', games);
        return state
          .set('isImportingGame', false)
          .set('importingGameTitle', null)
          .set('importedGame', game)
          .set('publisher', publisher);
      case importGame.FAILURE:
        return state.set('isImportingGame', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  searchGame(state: any, action: any) {
    switch (action.type) {
      case searchGame.REQUEST:
        return state.set('isSearchingGame', true);
      case searchGame.SUCCESS:
        return state
          .set('isSearchingGame', false)
          .set('searchResults', Immutable.fromJS(action.payload));
      case searchGame.FAILURE:
        return state.set('isSearchingGame', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};

export type ImportGameActionAttributes = {
  importingGameTitle: any,
  isImportingGame: boolean,
  importedGame: any
};
export const handleImportGameActions: ReducerCreator<void, ImportGameActionAttributes> = () => {
  return (state, action) => {
    switch (action.type) {
      case importGame.REQUEST:
        return {
          ...state,
          importingGameTitle: action.payload.title,
          isImportingGame: true
        };
      case importGame.SUCCESS: {
        const game = action.payload;
        return {
          ...state,
          isImportingGame: false,
          importingGameTitle: null,
          importedGame: game
        };
      }
      case importGame.FAILURE:
        return {
          ...state,
          error: action.payload,
          isImportingGame: false
        };

      default:
        return state;
    }
  };
};

export type SearchGameActionAttributes = {
  isSearchingGame: boolean,
  searchResults: any[] | null
};
export const handleSearchGameActions: ReducerCreator<void, SearchGameActionAttributes> = () => {
  return (state, action) => {
    switch (action.type) {
      case searchGame.REQUEST:
        return {
          ...state,
          isSearchingGame: true
        };
      case searchGame.SUCCESS:
        return {
          ...state,
          searchResults: action.payload,
          isSearchingGame: false
        };
      case searchGame.FAILURE:
        return {
          ...state,
          error: action.payload,
          isSearchingGame: false
        };
      default:
        return state;
    }
  };
};
