// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import Avatar from '../../messages/Avatar';
import FormattedNumber from '../FormattedNumber';

import './InfluencerStats.scss';

export type InfluencerStatsProps = {
  avatarUrl: ?string,
  displayName?: string,
  eCpm?: number
};

type Props = InfluencerStatsProps & {
  estimatedViews: number,
  estimatedInstalls: number
};
const InfluencerStats = ({
  avatarUrl,
  displayName,
  estimatedViews,
  estimatedInstalls,
  eCpm
}: Props) => {
  const shouldShowEstimates = !!estimatedViews || !!estimatedInstalls;
  return (
    <div className="InfluencerStats">
      <Avatar url={avatarUrl} size={48} />
      {shouldShowEstimates && (
        <React.Fragment>
          <div className="InfluencerStats__estimates has-text-centered">
            {displayName && <strong>{displayName}</strong>}
            <p>
              <FormattedMessage
                id="influencerStats.estimates.views"
                values={{
                  views: <FormattedNumber type="number" value={estimatedViews} />
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="influencerStats.estimates.installs"
                values={{
                  installs: <FormattedNumber type="number" value={estimatedInstalls} />
                }}
              />
            </p>
            {!!eCpm && (
              <p>
                <FormattedMessage
                  id="influencerStats.estimates.eCpm"
                  values={{
                    ecpm: <FormattedNumber type="cost" value={eCpm} />
                  }}
                />
              </p>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default InfluencerStats;
