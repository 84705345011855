// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import browserHistory from 'react-router/lib/browserHistory';

import { fetchPublisher } from '../actions/publisher';
import PublisherCampaigns from '../components/dashboard/publisher/PublisherCampaigns';
import Spinner from '../components/common/Spinner';
import generateStateToProps from '../helpers/generateStateToProps';
import usePrevious from '../hooks/usePrevious';
import type { CampaignForPublisherDashboard } from '../types/campaign.flow';

import './PublisherDashboard.scss';

type Props = {
  publisher: {
    id: number,
    verified: boolean,
    account: ?{
      id: number,
      email: string
    },
    campaigns: ?(CampaignForPublisherDashboard[])
  },
  isLoadingPublisher: boolean
};

const PublisherDashboard = ({ publisher, isLoadingPublisher }: Props) => {
  const dispatch = useDispatch();
  const prevIsLoadingPublisher = usePrevious(isLoadingPublisher);

  React.useEffect(() => {
    dispatch(fetchPublisher.run());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      prevIsLoadingPublisher &&
      !isLoadingPublisher &&
      (!publisher || !(publisher.campaigns || []).length)
    ) {
      browserHistory.push('/dashboard/publisher/campaigns/create');
    }
  }, [isLoadingPublisher, prevIsLoadingPublisher, publisher]);

  if (isLoadingPublisher) return <Spinner mode="fullWidth" size="large" />;
  if (!publisher || !(publisher.campaigns || []).length) {
    return null;
  }

  return (
    <div className="PublisherDashboard container">
      <section className="section margin-bottom" key="campaigns-and-games">
        <div className="CampaignsAndGames">
          <h2 className="title">
            <FormattedMessage id="publisher.dashboard.myCampaigns" />
          </h2>
          <PublisherCampaigns campaigns={publisher.campaigns || []} />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = generateStateToProps('publisherDashboard');

// https://github.com/facebook/flow/issues/7125#issuecomment-454911546
// we might need to rethink the whole action/actionCreator setup to work with flowtype
// $FlowFixMe
export default connect(mapStateToProps)(PublisherDashboard);
