// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FaceError from '../../../images/icons/FaceError';
import FooterNotification from '../../common/FooterNotification';
import type { IntlShape } from 'react-intl';

type Props = {
  intl: IntlShape,
  clearError: () => void
};

const ErrorFooterNotification = ({ intl, clearError }: Props) => {
  const contactEmail = intl.formatMessage({ id: 'contactEmail' });
  return (
    <FooterNotification onClose={clearError}>
      <div className="FooterNotification__icon">
        <FaceError width="2.85rem" height="2.85rem" />
      </div>
      <div>
        <FormattedMessage tagName="strong" id="genericErrorMessage" />
        <FormattedMessage
          id="contactUs"
          tagName="p"
          values={{
            contactEmail: (
              <a href={`mailto:${contactEmail}`} target="_blank" rel="noopener noreferrer">
                {contactEmail}
              </a>
            )
          }}
        />
      </div>
    </FooterNotification>
  );
};

export default injectIntl(ErrorFooterNotification);
