import isFunction from 'lodash/isFunction';

// component should have props.sections
export default function (props, name) {
  const sections = props.sections || {};
  const section = sections[name];
  if (!section) return null;

  if (isFunction(section)) return section(props);
  return section;
}
