// @flow

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import { StatefulCollectionSelectorTrigger } from '../../collection/StatefulCollectionSelectorTrigger';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { getFirstEstimates } from '../../../helpers/estimates';
import { getGameTypeForEstimates } from '../../../helpers/game';
import { getSortingOptions } from './helper';
import { shouldHideCpi } from '../../../helpers/campaign';
import InfluencerAvailableCard from '../../influencer/InfluencerAvailableCard';
import InfluencerCardPlaceholder from '../../placeholders/InfluencerCardPlaceholder';
import InfluencerCollection from '../../influencer/InfluencerCollection';
import InfluencerProfileModalTrigger from '../../influencer/InfluencerProfileModalTrigger';
import React from 'react';
import SearchInput from '../../common/SearchInput';
import type { CampaignForPublisher } from '../../../types/campaign.flow';

type Props = {
  campaign: CampaignForPublisher,
  influencers: any[],
  isFetching: boolean,
  isNegotiatingCampaign: boolean,
  creatingCampaignAgreement: any,
  onFetchAllInNetworkInfluencers: (campaignId: number, name: string) => void
};

const isNegotiating = ({ influencer, isNegotiatingCampaign, creatingCampaignAgreement }) => {
  return (
    isNegotiatingCampaign &&
    creatingCampaignAgreement &&
    creatingCampaignAgreement.influencerId === influencer.id
  );
};

const getCardProps = ({
  influencer,
  campaign,
  isNegotiatingCampaign,
  creatingCampaignAgreement
}) => {
  const gameType = getGameTypeForEstimates(campaign.game);
  const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);
  const { channel, contentPlatform } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};

  if (!channel) {
    console.warn('Corrupted influencer data', influencer);
    return { campaign };
  }

  const channelName =
    (channel && channel.name) || (influencer.account && influencer.account.displayName) || '';
  const estimates = getFirstEstimates(influencer, channel);

  const adminActions = [
    <StatefulCollectionSelectorTrigger
      type="static"
      key="ApiConnectedCollectionSelectorTrigger"
      contentPlatform={contentPlatform}
      channelId={channel.id}
    />,
    (contentPlatform === ContentPlatform.YOUTUBE ||
      contentPlatform === ContentPlatform.TWITCH ||
      contentPlatform === ContentPlatform.INSTAGRAM) &&
      influencer.id && (
        <InfluencerProfileModalTrigger
          key="InfluencerProfileModalTrigger"
          accountId={influencer.id}
        />
      )
  ];

  const promotionType = campaign.defaultPromotionType;
  // TODO: Twitch! When we have dual campaigns, get contentPlatform from selected one
  const estimatedInstalls =
    (estimates && estimates[gameType] && estimates[gameType].installs[promotionType].median) || 0;
  const isLoading = isNegotiating({ influencer, isNegotiatingCampaign, creatingCampaignAgreement });

  return {
    campaign,
    influencer,
    channelName,
    hideCpi,
    gameType,
    estimates,
    adminActions,
    estimatedInstalls,
    disabled: !campaign.visible || isLoading,
    isLoading: isLoading
  };
};

const AllInNetworkTab = ({
  campaign,
  influencers,
  isFetching,
  isNegotiatingCampaign,
  creatingCampaignAgreement,
  onFetchAllInNetworkInfluencers
}: Props) => {
  influencers = influencers ? influencers : [];
  influencers = influencers.map(influencer => {
    // We do not have an agreement for any creator on the all in network tab as there is no ongoing
    // negotiations or agreements with these creators.
    // We set estimatedCost to 0 because we don't currently offer a cost estimate before a
    // negotiation starts.
    // agreement and estimatedCost are still required here for sorting by eCPM to work on the other Tabs.
    influencer.agreement = {};
    influencer.estimatedCost = 0;
    return influencer;
  });

  return (
    <section className="influencer-cards">
      <SearchInput
        onChangeSearchInput={name => onFetchAllInNetworkInfluencers(campaign.id, name)}
      />
      <InfluencerCollection
        name="allInNetworkInfluencerCollection"
        influencers={influencers || []}
        isLoading={isFetching}
        card={InfluencerAvailableCard}
        cardPlaceholder={InfluencerCardPlaceholder}
        cardProps={influencer =>
          getCardProps({
            influencer,
            campaign,
            isNegotiatingCampaign,
            creatingCampaignAgreement
          })
        }
        cardSortOptions={getSortingOptions(campaign.defaultContentPlatform)}
      />
    </section>
  );
};

export default AllInNetworkTab;
