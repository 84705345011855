// @flow
import React from 'react';
// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import set from 'lodash/set';
// $FlowFixMe
import validate from 'validate.js';

import { renderValidationError } from '../../helpers/formHelper';
import CountrySelection from '../common/CountrySelection';
import Form from '../forms/Form';
import Input from '../forms/controls/Input';
import OnOffToggle from '../common/OnOffToggle';
import validations from './validations';
import type { PublisherPaymentInfo } from '../../types/paymentInfo.flow';

import './PaymentInfoFormForPublisher.scss';

type Props = {
  data: ?PublisherPaymentInfo,
  error: ?Object,
  isLoading?: boolean,
  onSubmit: (data: PublisherPaymentInfo) => void
};

const PaymentInfoFormForPublisher = (props: Props) => {
  const { data, error: apiError, isLoading } = props;
  const intl = useIntl();

  // Pre-loaded data from API
  const companyCountry = data ? data.companyCountry : null;

  // Special states & setters for special controllers (date, country selector...)
  const [error, setError] = React.useState(apiError);
  const [agreed, setAgreed] = React.useState(false);
  const [country, setCountry] = React.useState(companyCountry);

  // This sends API request and returns new data that overwrites the payment info in our redux state
  const onSubmit = values => {
    const payload = {
      ...values,
      companyCountry: country
    };
    const clientErrors = validate(payload, validations.publisherPaymentInfo) || null;
    if (!clientErrors) {
      props.onSubmit(payload);
    } else {
      setError({ params: clientErrors });
    }
  };

  const validateInputOnChange = (name, value) => {
    // Set payload in format: { [field]: value }
    const payload = {};
    set(payload, name, value);
    // Set contraints for specific field: { [field]: (validate.js object) }
    const constraints = {};
    set(constraints, name, get(validations.publisherPaymentInfo, name));
    // Will return errors for input field: { [field]: ['error1', 'error2'] } || null
    const inputErrors = validate(payload, constraints) || {};
    set(inputErrors, name, get(inputErrors, name));
    // Merging existing errors with new error
    const params = { ...(error || {}).params, ...inputErrors };
    setError({ params });
  };

  // Form events
  const onToggleTerms = () => setAgreed(!agreed);
  const onChangeCountry = item => {
    setCountry(item ? item.code : null);
    validateInputOnChange('companyCountry', item ? item.code : null);
  };

  // Effects
  React.useEffect(() => setCountry(companyCountry), [companyCountry]);
  React.useEffect(() => setError(apiError), [apiError]);

  return (
    <div className="PaymentInfoFormForPublisher content">
      <Form values={data} error={error} onSubmit={onSubmit} onChangeInput={validateInputOnChange}>
        <h1 className="title">
          <FormattedMessage id="paymentInfo.publisher.title" />
        </h1>
        <FormattedMessage id="paymentInfo.publisher.description" tagName="p" />
        <FormattedMessage id="paymentInfo.publisher.description2" tagName="p" />
        <p>
          <FormattedMessage id="paymentInfo.faq.description" />
          <a href="https://matchmade.tv/advertiser-faq/" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="paymentInfo.faq.link" />
          </a>
        </p>
        <div className="section">
          <div className="columns">
            <div className="column">
              <Input
                name="companyName"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyName'
                })}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Input
                name="companyStreetAddress"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyStreetAddress'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="companyPostalCode"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyPostalCode'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="companyCity"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyCity'
                })}
              />
            </div>
            <div className="column">
              <label className="label">
                <FormattedMessage id="paymentInfo.publisher.label.companyCountry" />
              </label>
              <CountrySelection
                name="companyCountry"
                selected={country}
                clearable={false}
                onChange={onChangeCountry}
              />
              {renderValidationError(error, 'companyCountry')}
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Input
                name="companyNumber"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyNumber'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="companyVat"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyVat'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="companyEin"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.companyEin'
                })}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="columns">
            <div className="column">
              <Input
                name="contactName"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.contactName'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="contactEmail"
                type="email"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.contactEmail'
                })}
              />
            </div>
            <div className="column">
              <Input
                name="contactPhoneNumber"
                type="text"
                label={intl.formatMessage({
                  id: 'paymentInfo.publisher.label.contactPhoneNumber'
                })}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <Input
            name="invoiceEmail"
            type="email"
            label={intl.formatMessage({
              id: 'paymentInfo.publisher.label.invoiceEmail'
            })}
          />
        </div>

        <FormattedMessage id="paymentInfo.policy.intro" tagName="p" />

        <div className="control" onClick={onToggleTerms}>
          <div className="checkbox">
            <OnOffToggle isOn={agreed} onClick={onToggleTerms} />
            <FormattedMessage id="paymentInfo.policy.aware" />
          </div>
        </div>

        <p>
          <a href="https://matchmade.tv/privacy-policy/" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="paymentInfo.privacyPolicy" />
          </a>
        </p>
        <FormattedMessage id="paymentInfo.policy.consent" tagName="p" />
        <button className="button is-primary" type="submit" disabled={!agreed || isLoading}>
          <FormattedMessage id="form.save" />
        </button>
      </Form>
    </div>
  );
};

export default PaymentInfoFormForPublisher;
