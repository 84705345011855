import { createApiAction } from './helper';

export const fetchManagers = createApiAction('FETCH_MANAGERS', () => {
  return {
    path: '/managers'
  };
});

export const search = createApiAction('SEARCH', params => {
  return {
    path: `/managers/search?query=${params.query}`
  };
});

// teamId can be null if we are creating a new team
export const changeTeam = createApiAction('MANAGER_CHANGE_TEAM', (managerId, teamId, teamName) => {
  return {
    path: `/managers/${managerId}/change-team`,
    options: {
      method: 'POST',
      body: {
        teamId,
        teamName
      }
    },
    loadingPayload: {
      managerId,
      teamId
    }
  };
});
