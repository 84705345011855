// @flow
import * as React from 'react';
import classNames from 'classnames';

import Select from '../../common/Select';

import './SingleValueSelection.scss';

export type Option = {
  value: any,
  label: string
};

type Props = {
  value?: ?Option,
  options: Option[],
  isInverted: boolean,
  isDisabled: boolean,

  onChange: (value: Option) => void
};

SingleValueSelection.defaultProps = {
  isInverted: false,
  isDisabled: false
};
export default function SingleValueSelection(props: Props) {
  const className = classNames('SingleValueSelection', {
    'SingleValueSelection--inverted': props.isInverted
  });

  return (
    <div className={className}>
      <Select
        options={props.options}
        value={props.value}
        clearable={false}
        onChange={props.onChange}
        removeSelected
        isInverted={props.isInverted}
        disabled={props.isDisabled}
      />
    </div>
  );
}
