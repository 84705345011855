// @flow
import { changeTeam, fetchPublishers } from '../../../actions/publisher';
import { deleteAccount, verifyAccount } from '../../../actions/account';

import type { Action } from '../../../types/action.flow';
import type { GetAllActionAttributes } from '../../common/team';
import type { Publisher } from '../../../types/publisher.flow';

import { handleGetAllActions, handleNewTeamCreatedWhenChangingTeam } from '../../common/team';
import runMultipleReducers from '../../helpers/runMultipleReducers';

type State = {|
  ...$Exact<GetAllActionAttributes>,
  publishers: Publisher[] | null,
  isLoadingPublishers: boolean,
  isDeletingAccount: boolean,
  verifyingAccountId: number,
  verifiedAccount: Publisher | null,

  isChangingTeam: boolean,

  error: Object | null
|};

const initialState: State = Object.freeze({
  publishers: null,
  teams: null,
  isLoadingPublishers: true,
  isDeletingAccount: false,
  verifyingAccountId: 0,
  verifiedAccount: null,
  isLoadingTeams: false,

  isChangingTeam: false,

  error: null
});

export default function adminDashboardPublishersReducer(
  state: State = initialState,
  action: Action
): State {
  // always reset the state
  const newState = runMultipleReducers([
    handleNewTeamCreatedWhenChangingTeam(),
    handleGetAllActions()
  ])(
    {
      ...state,
      error: null
    },
    action
  );

  switch (action.type) {
    case deleteAccount.REQUEST:
      return {
        ...newState,
        isDeletingAccount: true
      };
    case deleteAccount.SUCCESS:
      return {
        ...newState,
        isDeletingAccount: false,
        publishers: (state.publishers || []).filter(publisher => {
          return publisher.id !== action.payload.id;
        })
      };
    case deleteAccount.FAILURE:
      return {
        ...newState,
        isDeletingAccount: false,
        error: action.payload
      };
    case verifyAccount.REQUEST:
      return {
        ...newState,
        verifyingAccountId: action.payload.id,
        verifiedAccount: null
      };
    case verifyAccount.SUCCESS:
      return {
        ...newState,
        verifyingAccountId: 0,
        verifiedAccount: action.payload,
        publishers: (state.publishers || []).map(publisher => {
          if (publisher.id === action.payload.id) {
            return {
              ...publisher,
              verified: true
            };
          }
          return publisher;
        })
      };
    case verifyAccount.FAILURE:
      return {
        ...newState,
        verifyingAccountId: 0,
        verifiedAccount: null,
        error: action.payload
      };
    case fetchPublishers.REQUEST:
      return {
        ...newState,
        isLoadingPublishers: true
      };
    case fetchPublishers.SUCCESS:
      return {
        ...newState,
        isLoadingPublishers: false,
        publishers: action.payload
      };
    case fetchPublishers.FAILURE:
      return {
        ...newState,
        isLoadingPublishers: false,
        error: action.payload
      };
    case changeTeam.REQUEST:
      return {
        ...newState,
        isChangingTeam: true,
        publishers: (state.publishers || []).map(publisher => {
          if (publisher.id !== action.payload.publisherId) {
            return publisher;
          }

          const newTeam = (state.teams || []).find(({ id }) => id === action.payload.teamId);
          if (!newTeam) {
            return publisher;
          }

          return {
            ...publisher,
            teams: [newTeam]
          };
        })
      };
    case changeTeam.SUCCESS:
      return {
        ...newState,
        isChangingTeam: false,
        publishers: (state.publishers || []).map(publisher => {
          if (publisher.id !== action.payload.id) {
            return publisher;
          }

          return {
            ...publisher,
            teams: [action.payload.team]
          };
        })
      };
    case changeTeam.FAILURE:
      return {
        ...newState,
        isChangingTeam: false,
        error: action.payload
      };
    default:
      break;
  }

  return newState;
}
