import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './InfluencerCardStat.scss';

class InfluencerCardStat extends Component {
  render() {
    const { value, label, info } = this.props;

    const infoText = info ? <p className="InfluencerCardStat__stat-info">{info}</p> : null;

    const className = ['InfluencerCardStat']
      .concat(this.props.className.split(' '))
      .filter(Boolean)
      .join(' ');

    return (
      <div className={className}>
        <p className="InfluencerCardStat__stat-val">{value}</p>
        <p className="InfluencerCardStat__stat-label">{label}</p>
        {infoText}
      </div>
    );
  }
}

InfluencerCardStat.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  info: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element])
};

InfluencerCardStat.defaultProps = {
  className: ''
};

export default InfluencerCardStat;
