// @flow
import * as React from 'react';

import InfoTooltip from '../../common/InfoTooltip';

import './AdvancedSettingToggle.scss';

type Props = {
  tooltip: React.Element<*>,
  value?: ?React.Element<*>,
  label: React.Element<*>,
  onClick?: () => void
};

export default function AdvancedSettingToggle(props: Props) {
  const { value, tooltip, label } = props;

  return (
    <div
      className="AdvancedSettingToggle"
      onClick={() => {
        props.onClick && props.onClick();
      }}>
      <div className="AdvancedSettingToggle__title">
        {label}

        {value && <div className="AdvancedSettingToggle__current-value">{value}</div>}
      </div>
      <InfoTooltip>{tooltip}</InfoTooltip>
    </div>
  );
}
