import React from 'react';

import { FormattedMessage } from 'react-intl';
import { OutboundLink } from 'react-ga';
import ANALYTICS from '../../helpers/analytics';

import { getAuthUrlWithNextUrl } from '../../helpers/auth';

import './AdminLogin.scss';

import logo from '../../images/themes/matchmade/logo-large.svg';

const AdminLogin = ({ location }) => {
  return (
    <div className="AdminLogin is-vertically-aligned">
      <img src={logo} alt="Matchmade" />
      <OutboundLink
        to={getAuthUrlWithNextUrl('urls.whitelabelAdminAuth', location)}
        eventLabel={ANALYTICS.LABEL.CLICKED_LOGIN}
        className="button is-large is-primary">
        <FormattedMessage id="auth.login" />
      </OutboundLink>
    </div>
  );
};

AdminLogin.topNavigationBar = false;

export default AdminLogin;
