// @flow

import { FormattedMessage } from 'react-intl';
import React from 'react';
import Tooltip from '../Tooltip';

type Props = {
  isActive?: boolean,
  tooltipI18nString?: string,
  type?: 'add' | ''
};

const PersonIcon = (props: Props) => {
  const { isActive = false, tooltipI18nString = 'tooltips.login', type = '' } = props;
  const iconStyles = isActive ? 'icon Icon mdPersonIcon activeTabIcon' : 'icon Icon mdPersonIcon';

  const isAdd = type === 'add';
  const iconName = isAdd ? 'person_add' : 'person';
  const position = isAdd ? 'bottom' : 'top';
  return (
    <Tooltip tooltip={<FormattedMessage id={tooltipI18nString} />} position={position}>
      <span className={iconStyles}>
        <i className="material-icons">{iconName}</i>
      </span>
    </Tooltip>
  );
};

export default PersonIcon;
