// @flow
import * as React from 'react';
import * as url from 'url';
// $FlowFixMe
import { useAsync } from 'react-async';

import {
  ContentPlatform as ContentPlatformEnum,
  ContentPlatformToConnectionType
} from '../../constants';
import { ContentPlatformIcon } from '../common/ContentPlatform';
import { getAvailableChannelsToConnect, getConnectedChannels } from '../../helpers/connection';
import ContentPlatformToConnect from './ContentPlatformToConnect';
import callApi from '../../helpers/api';
import useError from '../../hooks/useError';
import type { ContentPlatform } from '../../types/campaign.flow';
import type { GetCurrentSocialMediaConnectionData } from '../../types/api/connections.flow';
import type { Scope } from './ContentPlatformToConnect';
import type { SocialMediaConnectionIn } from '../../types/connection.flow';

import './ConnectYourAccounts.scss';

type Option = {
  platform: ContentPlatform,
  name: string,
  icon: React.Node,
  requirements: React.Node[],
  scopes: Scope[],
  isDisabled: boolean,
  connectText: string | null // null means use the default text
};

export const DEFAULT_OPTIONS: Option[] = [
  {
    icon: <ContentPlatformIcon platform={ContentPlatformEnum.YOUTUBE} isIcon />,
    name: 'YouTube',
    platform: ContentPlatformEnum.YOUTUBE,
    requirements: [],
    scopes: [
      {
        name: 'https://www.googleapis.com/auth/userinfo.email',
        // View your email address
        description: 'Your email address',
        isGranted: null
      },
      {
        name: 'https://www.googleapis.com/auth/userinfo.profile',
        // See your personal info, including any personal info you've made publicly available
        description: 'Profile information',
        isGranted: null
      },
      {
        name: 'https://www.googleapis.com/auth/youtube.readonly',
        // View your YouTube account
        description: 'View your videos and playlists',
        isGranted: null
      },
      {
        name: 'https://www.googleapis.com/auth/yt-analytics.readonly',
        // View YouTube Analytics reports for your YouTube content.
        // This scope provides access to user activity metrics, like view counts and rating counts.
        description: 'Audience demographics',
        isGranted: null
      }
    ],
    isDisabled: false,
    connectText: null
  },
  {
    icon: <ContentPlatformIcon platform={ContentPlatformEnum.INSTAGRAM} isIcon />,
    name: 'Instagram',
    platform: ContentPlatformEnum.INSTAGRAM,
    requirements: [
      'A Creator account',
      'The account needs to be linked to a Facebook page',
      <a
        href="https://help.instagram.com/2358103564437429"
        target="_blank"
        rel="noopener noreferrer">
        Read more
      </a>
    ],
    scopes: [
      {
        name: 'email',
        description: 'Your email address',
        isGranted: null
      },
      {
        // https://developers.facebook.com/docs/facebook-login/permissions/#reference-instagram_basic
        name: 'instagram_basic',
        description: 'Instagram profile information and posts',
        isGranted: null
      },
      {
        // https://developers.facebook.com/docs/facebook-login/permissions/#reference-instagram_manage_insights
        name: 'instagram_manage_insights',
        description: 'Insights for the Instagram profile',
        isGranted: null
      },
      {
        // https://developers.facebook.com/docs/facebook-login/permissions/#reference-pages_show_list
        name: 'pages_show_list',
        description: 'List of Pages you manage',
        isGranted: null
      },
      {
        // https://developers.facebook.com/docs/facebook-login/permissions#reference-pages_read_engagement
        name: 'pages_read_engagement',
        description: 'Read content posted on the Page',
        isGranted: null
      }
    ],
    isDisabled: false,
    connectText: null
  }
];

type Props = {
  options: Option[],
  connectionData: GetCurrentSocialMediaConnectionData[],
  isGettingConnectionData: boolean,
  onConnected: (data: SocialMediaConnectionIn) => any
};

function getCurrentUrl(excludeQueryString?: string[]): string {
  const urlObj = url.parse(window.location.href, true);
  for (const attr of excludeQueryString || []) {
    delete urlObj.query[attr];
  }

  urlObj.search = null;

  return url.format(urlObj);
}

export function getScopesWithGrantStatus(
  existingConnection: ?GetCurrentSocialMediaConnectionData,
  requiredScopes: Scope[]
): Scope[] {
  return requiredScopes.map(scope => {
    if (!existingConnection) {
      return scope;
    }

    if (
      existingConnection &&
      existingConnection.connection &&
      !existingConnection.connection.isValid
    ) {
      return scope;
    }

    return {
      ...scope,
      isGranted: (existingConnection.connection.tokenScopes || []).includes(scope.name)
    };
  });
}

export default function ConnectYourAccounts({
  options,
  connectionData,
  isGettingConnectionData,
  onConnected
}: Props) {
  const { showError } = useError();
  const [error, setError] = React.useState(null);
  const [isConnectLoading, setIsConnectLoading] = React.useState(false);
  const { run: setupConnection, isPending: isSettingUpConnection } = useAsync({
    deferFn: platform => {
      return callApi(`/connections/social-media/${platform}`, {
        method: 'POST'
      });
    },
    onResolve: res => {
      window.location.assign(`${res.data.authUrl}?next_url=${getCurrentUrl(['error'])}`);
    },
    onReject: res => {
      setError(res);
      setIsConnectLoading(false);
    }
  });

  if (new URLSearchParams(window.location.search).get('error') && !error) {
    setError({ code: 400, message: 'Could not connect account. Please try reconnecting.' });
  }

  React.useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  const available = getAvailableChannelsToConnect(connectionData);
  const connected = getConnectedChannels(connectionData);

  return (
    <section role="list">
      {options.map(option => {
        // pick up existing connection if available
        // there are 2 cases here
        // - user has somehow managed to create an empty connection (they first create the connection then cancel the auth process for example)
        // - user has created a connection with not enough scopes
        // This only picks the 1 connection it finds, so if the user somehow manages to create 2 connections, the other one is ignored
        const existingConnection = connectionData.find(({ connection }) => {
          return connection.connectionType === ContentPlatformToConnectionType[option.platform];
        });

        const scopes = getScopesWithGrantStatus(existingConnection, option.scopes);

        return (
          <div role="listitem" key={option.platform} className="ConnectYourAccounts__platform">
            <ContentPlatformToConnect
              isLoading={isSettingUpConnection || isGettingConnectionData || isConnectLoading}
              isDisabled={option.isDisabled}
              connectText={option.connectText}
              name={option.name}
              icon={option.icon}
              scopes={scopes}
              requirements={option.requirements}
              allowConnectingMoreThanOneAccount={false}
              connected={(connected || []).filter(
                ({ channel }) =>
                  channel.contentPlatform.toLowerCase() === option.platform.toLowerCase()
              )}
              available={(available || []).filter(
                ({ channel }) =>
                  channel.contentPlatform.toLowerCase() === option.platform.toLowerCase()
              )}
              onSetupConnection={() => {
                setIsConnectLoading(true);
                // @Tan 2020-07-02 if we want to allow the influencer to connect multiple times
                // we need to update this logic
                if (!existingConnection) {
                  if (isSettingUpConnection) {
                    return;
                  }
                  return setupConnection(option.platform);
                }

                window.location.assign(
                  `${existingConnection.connection.authUrl}?next_url=${getCurrentUrl(['error'])}`
                );
              }}
              onConnected={onConnected}
            />
          </div>
        );
      })}
    </section>
  );
}
