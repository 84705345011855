import { acceptCampaign, declineCampaign, negotiateCampaign } from '../actions/campaign';
import { acceptTOS, fetchMe } from '../actions/user';
import { addError, clearErrors } from '../actions/error';
import { updateSettings } from '../actions/account';

import get from 'lodash/get';

import { ___reset, defaultRole, setFeatures, setRole, setUser } from '../helpers/user';

const initialState = Object.freeze({
  user: null,
  tosAccepted: false,
  isLoading: false,
  error: null, // deprecated, use `errors` instead, when it's empty, there are no errors
  isAcceptTosLoading: false,
  errors: [] // a mix of any kind of error
});

const concatAndReduceErrorsFromPayload = (state, action) => {
  return [
    (state.errors || []).concat(action.payload).reduce((all, current) => {
      const existing = all.find(
        err => err.code === current.code && err.message === current.message
      );

      if (!existing) {
        return all.concat(current);
      }

      // when we have the same error, the params might be different, let's update it
      existing.params = current.params;
      return all;
    }, [])
  ];
};

function layout(state = initialState, action) {
  switch (action.type) {
    case fetchMe.REQUEST:
      return { ...state, isLoading: true, error: null };

    case fetchMe.SUCCESS: {
      setRole(get(action.payload, 'token.role', defaultRole));
      setFeatures(get(action.payload, 'featureFlags', []));
      setUser(action.payload);

      return { ...state, user: action.payload, isLoading: false, error: null };
    }

    case fetchMe.FAILURE:
      ___reset();
      return { ...state, error: action.payload, isLoading: false };

    case acceptTOS.REQUEST:
      return { ...state, isAcceptTosLoading: true };

    case acceptCampaign.FAILURE:
    case declineCampaign.FAILURE:
    case negotiateCampaign.FAILURE:
      return {
        ...state,
        errors: concatAndReduceErrorsFromPayload(state, action)
      };

    case acceptTOS.SUCCESS:
      setUser({
        ...state.user,
        isAcceptTosLoading: false,
        hasAcceptedTos: true
      });
      return {
        ...state,
        user: {
          ...state.user,
          isAcceptTosLoading: false,
          hasAcceptedTos: true
        }
      };

    case acceptTOS.FAILURE:
      return { ...state, isAcceptTosLoading: false, error: action.payload };

    case updateSettings.SUCCESS:
      setUser({ ...state.user, settings: action.payload.settings });
      return { ...state, user: { ...state.user, settings: action.payload.settings } };

    case addError.SUCCESS:
      return {
        ...state,
        errors: concatAndReduceErrorsFromPayload(state, action)
      };
    case clearErrors.SUCCESS:
      return {
        ...state,
        errors: []
      };

    default:
      return state;
  }
}

export default layout;
