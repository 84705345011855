import React from 'react';

const InstagramIcon = props => {
  const { isGlyph, ...svgProps } = props;
  return (
    <svg viewBox="3 0 38 38" height="100%" xmlnsXlink="http://www.w3.org/1999/xlink" {...svgProps}>
      <defs>
        <path
          d="M32.142 6.578a2.28 2.28 0 1 0 0 4.56 2.28 2.28 0 0 0 0-4.56M22 25.333a6.333 6.333 0 1 1 0-12.666 6.333 6.333 0 0 1 0 12.666m0-16.09c-5.389 0-9.757 4.368-9.757 9.757 0 5.388 4.368 9.757 9.757 9.757 5.389 0 9.757-4.369 9.757-9.757 0-5.389-4.368-9.757-9.757-9.757M22 0c-5.16 0-5.807.022-7.834.114-2.022.093-3.403.414-4.612.883A9.314 9.314 0 0 0 6.19 3.19a9.313 9.313 0 0 0-2.191 3.365c-.47 1.209-.791 2.59-.884 4.612C3.022 13.193 3 13.84 3 19s.022 5.807.114 7.834c.093 2.022.414 3.403.884 4.612a9.313 9.313 0 0 0 2.19 3.365 9.314 9.314 0 0 0 3.366 2.191c1.209.47 2.59.791 4.612.884C16.193 37.978 16.84 38 22 38s5.807-.022 7.834-.114c2.022-.093 3.403-.414 4.612-.884a9.312 9.312 0 0 0 3.365-2.191 9.314 9.314 0 0 0 2.192-3.365c.47-1.209.79-2.59.883-4.612C40.978 24.807 41 24.16 41 19s-.022-5.807-.114-7.834c-.093-2.022-.414-3.403-.883-4.612A9.314 9.314 0 0 0 37.81 3.19 9.312 9.312 0 0 0 34.446.997c-1.209-.47-2.59-.79-4.612-.883C27.807.022 27.16 0 22 0m0 3.423c5.073 0 5.674.02 7.678.111 1.852.085 2.858.394 3.528.654.886.345 1.52.757 2.184 1.422a5.887 5.887 0 0 1 1.422 2.184c.26.67.57 1.676.654 3.528.091 2.004.11 2.605.11 7.678s-.019 5.674-.11 7.678c-.085 1.852-.394 2.858-.654 3.528a5.886 5.886 0 0 1-1.422 2.184 5.888 5.888 0 0 1-2.184 1.422c-.67.26-1.676.57-3.528.654-2.004.091-2.604.11-7.678.11s-5.674-.019-7.678-.11c-1.852-.085-2.858-.394-3.528-.654A5.888 5.888 0 0 1 8.61 32.39a5.886 5.886 0 0 1-1.422-2.184c-.26-.67-.57-1.676-.654-3.528-.091-2.004-.11-2.605-.11-7.678s.019-5.674.11-7.678c.085-1.852.394-2.858.654-3.528A5.886 5.886 0 0 1 8.61 5.61a5.887 5.887 0 0 1 2.184-1.422c.67-.26 1.676-.57 3.528-.654 2.004-.091 2.605-.11 7.678-.11"
          id="a"
        />
        <filter
          x="-39.8%"
          y="-39.8%"
          width="179.6%"
          height="179.6%"
          filterUnits="objectBoundingBox"
          id="c">
          <feGaussianBlur stdDeviation={6.236} in="SourceGraphic" />
        </filter>
        <filter
          x="-44.1%"
          y="-68.2%"
          width="188.2%"
          height="236.4%"
          filterUnits="objectBoundingBox"
          id="d">
          <feGaussianBlur stdDeviation={5} in="SourceGraphic" />
        </filter>
        <filter
          x="-36.2%"
          y="-72.4%"
          width="172.4%"
          height="244.9%"
          filterUnits="objectBoundingBox"
          id="e">
          <feGaussianBlur stdDeviation={7.486} in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#000" xlinkHref="#a" />
        {!isGlyph && (
          <g mask="url(#b)" fillRule="nonzero">
            <g transform="translate(-26 -23)">
              <path fill="#CE2E95" d="M29 23h38v38H29z" />
              <circle
                fill="#F66A34"
                filter="url(#c)"
                transform="rotate(16 41.5 61.5)"
                cx={41.5}
                cy={61.5}
                r={23.5}
              />
              <ellipse fill="#FED474" filter="url(#d)" cx={39} cy={60} rx={17} ry={11} />
              <ellipse
                fill="#5657CD"
                filter="url(#e)"
                transform="rotate(-6 33 19.5)"
                cx={33}
                cy={19.5}
                rx={31}
                ry={15.5}
              />
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default InstagramIcon;
