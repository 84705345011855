// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { DataProps as CampaignDescriptionData } from '../../components/campaignCreation/CampaignDescription';
import type { DataProps as CampaignDetailsData } from '../../components/campaignCreation/CampaignDetails';
import type { ClearError } from '../../actions/common';
import type { CreateCampaign } from '../../actions/campaign';
import type { CreateCampaignActionAtributes } from '../../reducers/common/campaign';
import type { DataProps as ProductSelectionData } from '../../components/campaignCreation/ProductSelection';
import type { RootState } from '../../reducers';
import type { Step, StepControl } from '../../components/campaignCreation/CampaignCreationWizard';
import type { WrappedComponent } from '../../hoc/compose';

import { BASE_CAMPAIGN_CREATION_URL } from './constants';
import { clearCreatedCampaign, createCampaign } from '../../actions/campaign';
import { clearError } from '../../actions/common';
import { getUser } from '../../helpers/user';
import CampaignCreationWizard from '../../components/campaignCreation/CampaignCreationWizard';
import compose from '../../hoc/compose';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';

import browserHistory from 'react-router/lib/browserHistory';

type Actions = {|
  clearCreatedCampaign: () => void,
  clearError: ClearError,
  createCampaign: CreateCampaign
|};

type DataFromReducer = {|
  ...$Exact<CreateCampaignActionAtributes>,
  importedGame: any,
  createdByUserId: ?number,
  newCampaignId: ?number,

  campaignDescription: $Exact<CampaignDescriptionData>,
  campaignDetails: $Exact<CampaignDetailsData>,
  productSelection: $Exact<ProductSelectionData>,

  baseUrl: string,
  error: ?Object
|};

type RequiredProps = {|
  notification: ?{ icon: React.Element<*>, content: React.Element<*> },
  steps: Step[],
  children: any
|};

type InjectedProps = {|
  ...Actions,
  ...DataFromReducer
|};

type Props = {
  ...InjectedProps,
  ...RequiredProps
};

class FinalStep extends React.PureComponent<Props, {}> {
  static defaultProps = {
    baseUrl: BASE_CAMPAIGN_CREATION_URL
  };

  componentDidMount() {
    const currentUser = getUser();
    if (currentUser && this.props.createdByUserId !== currentUser.id) {
      this.props.clearCreatedCampaign();
    }
  }

  componentDidUpdate() {
    if (this.props.newCampaignId) {
      browserHistory.push({
        pathname: `/dashboard/publisher/campaigns/${this.props.newCampaignId}`
      });
    }
  }

  onGoToStep = (index: number, stepControl: StepControl) => {
    const {
      importedGame,
      createCampaign,
      campaignDescription,
      campaignDetails,
      productSelection
    } = this.props;

    if (index < stepControl.getLastStepIndex()) {
      return stepControl.goToStep(index);
    }

    if (!importedGame) {
      return stepControl.goToFirstStep();
    }

    createCampaign(importedGame.id, {
      ...campaignDescription,
      ...campaignDetails,
      campaignType: productSelection.campaignType
    });
  };

  render() {
    const {
      importedGame,
      baseUrl,
      steps,
      isCreatingNewCampaign,
      clearError,
      children,
      notification,
      error
    } = this.props;

    // we are using changeStyleWhenScrolling HOC in CampaignCreationWizard which automatically
    // injects `style` object to props. We haven't had a way to tell flow that yet
    return (
      <CampaignCreationWizard
        notification={notification}
        headerBgImage={importedGame && importedGame.logoUrl}
        baseUrl={baseUrl}
        steps={steps}
        onGoToStep={this.onGoToStep}
        isLoading={isCreatingNewCampaign}
        onClearError={clearError}
        error={error}>
        {children}
      </CampaignCreationWizard>
    );
  }
}

const mapDispatchToProps = generateDispatchToProps({
  clearCreatedCampaign,
  clearError,
  createCampaign
});

const ComposedFinalStep: WrappedComponent<Props, InjectedProps> = compose(
  connect(function (state: RootState): DataFromReducer {
    const ownState = state.campaignCreation;

    return {
      isCreatingNewCampaign: ownState.isCreatingNewCampaign,
      createdCampaign: ownState.createdCampaign,
      newCampaignId: ownState.newCampaignId,

      importedGame: ownState.importedGame,

      campaignDescription: ownState.campaignDescription,
      productSelection: ownState.productSelection,
      campaignDetails: ownState.campaignDetails,

      baseUrl: BASE_CAMPAIGN_CREATION_URL,

      error: ownState.error,
      createdByUserId: ownState.createdByUserId
    };
  }, mapDispatchToProps)
)(FinalStep);

export default ComposedFinalStep;
