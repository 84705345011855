export default {
  CATEGORY: {
    ADDING_A_GAME: 'Adding a game',
    CREATING_A_CAMPAIGN: 'Creating a campaign',
    CREATING_A_TEAM: 'Creating a team',
    CAMPAIGN_NEGOTIATION: 'Campaign negotiation',
    CAMPAIGN_NEGOTIATION_WHILE_CHATTING: 'Campaign negotiation',
    TOS: 'Terms of service',
    INFLUENCER_LANDING_PAGE: 'Influencer landing page',
    CHAT_PAGE: 'Chat page'
  },
  ACTION: {
    ACCEPTED_TOS: 'Accepted terms of service',

    IMPORTED_A_GAME: 'Imported a game',
    SEARCHED_FOR_A_GAME: 'Searched for a game',
    CREATED_A_CAMPAIGN: 'Created a campaign',

    INFLUENCER_PROPOSED_TO_JOIN_AT_SUGGESTED_CPI: 'Influencer proposed to join at suggested CPI',
    INFLUENCER_PROPOSED_TO_JOIN_AT_DIFFERENT_CPI: 'Influencer proposed to join at a different CPI',
    INFLUENCER_CHANGED_CPI: 'Influencer changed CPI',
    INFLUENCER_ACCEPTED_CPI: 'Influencer accepted CPI',
    INFLUENCER_DECLINED_AGREEMENT: 'Influencer declined campaign agreement',
    INFLUENCER_CLICKED_SEARCH_CHANNEL: 'Influencer clicked search channel button',
    INFLUENCER_CLICKED_GAME_LOGO_IN_LANDING_PAGE: 'Influencer clicked game logo in landing page',
    INFLUENCER_SET_DEADLINE: 'Influencer set video release date',

    PUBLISHER_INVITED_OUT_OF_NETWORK_INFLUENCER: 'Publisher invited out of network influencer',
    PUBLISHER_INVITED_INFLUENCER_AT_SUGGESTED_CPI: 'Publisher invited influencer at suggested CPI',
    PUBLISHER_INVITED_INFLUENCER_AT_DIFFERENT_CPI:
      'Publisher invited influencer proposing a different CPI',
    PUBLISHER_CHANGED_CPI: 'Publisher changed CPI',
    PUBLISHER_ACCEPTED_CPI: 'Publisher accepted CPI',
    PUBLISHER_DECLINED_AGREEMENT: 'Publisher declined agreement',

    PUBLISHER_NAVIGATED_TO_CHAT_PAGE: 'Publisher navigated to the chat page',
    INFLUENCER_NAVIGATED_TO_CHAT_PAGE: 'Influencer navigated to the chat page'
  },
  LABEL: {
    CLICKED_LOGIN: 'Clicked login',
    CLICKED_LOGOUT: 'Clicked logout',
    CLICKED_CONTACT_US: 'Clicked contact us',
    CLICKED_SETTINGS: 'Clicked user settings',
    CLICKED_TERMS: 'Clicked terms',
    CLICKED_PAYMENT_INFO: 'Clicked user payment info',

    CLICKED_STORE_LINK_IN_GAME_HEADER: 'Clicked store link in a game header',

    CLICKED_CHANNEL_LINK_IN_CAMPAIGN_DRAFT: 'Clicked channel link in campaign draft',
    CLICKED_FACEBOOK_LINK_IN_CAMPAIGN_DRAFT: 'Clicked facebook link in campaign draft',
    CLICKED_TWITTER_LINK_IN_CAMPAIGN_DRAFT: 'Clicked twitter link in campaign draft',
    CLICKED_INSTAGRAM_LINK_IN_CAMPAIGN_DRAFT: 'Clicked instagram link in campaign draft',
    CLICKED_EMAIL_LINK_IN_CAMPAIGN_DRAFT: 'Clicked email link in campaign draft',
    CLICKED_YOUTUBE_BUSINESS_EMAIL_LINK_IN_CAMPAIGN_DRAFT:
      'Clicked youtube business email link in campaign draft',
    CLICKED_YOUTUBE_CHANNEL_LINK: 'Clicked youtube channel link',
    CLICKED_YOUTUBE_CHANNEL_AVATAR_IN_LANDING_PAGE:
      'Clicked youtube channel avatar in the landing page',
    CLICKED_YOUTUBE_NAME_IN_LANDING_PAGE: 'Clicked youtube channel name in the landing page',
    CLICKED_YOUTUBE_VIDEO_THUMBNAIL_IN_CHAT_PAGE:
      'Clicked youtube video thumbnail in the chat page',

    CLICKED_PROFILE_URL_IN_INFLUENCER_PAGE: 'Clicked profile url in the influencer page',

    INFLUENCER_CLICKED_SIGNUP: 'Influencer clicked signup',
    INFLUENCER_CLICKED_CONNECT_CHANNEL: 'Influencer clicked connect channel',
    INFLUENCER_CLICKED_CONNECT_ACCOUNT: 'Influencer clicked connect account',
    INFLUENCER_CLICKED_STORE_LINK_IN_CAMPAIGN_CARD:
      'Influencer clicked store link in a campaign card',
    INFLUENCER_CLICKED_STORE_LINK_IN_CAMPAIGN_CARD_IN_LANDING_PAGE:
      'Influencer clicked store link in a campaign card in the landing page',
    INFLUENCER_CLICKED_GAME_LOGO_IN_LANDING_PAGE:
      'Influencer clicked game logo in a campaign card in the landing page',

    INFLUENCER_CLICKED_GAME_TITLE_IN_CAMPAIGN_CARD_IN_LANDING_PAGE:
      'Influencer clicked game title in a campaign card in the landing page',
    INFLUENCER_CLICKED_JOIN_BUTTON_IN_CAMPAIGN_CARD_IN_LANDING_PAGE:
      'Influencer clicked join button in a campaign card in the landing page',

    INFLUENCER_CLICKED_OWN_INSTALL_LINK: 'Influencer clicked her own install link',

    INFLUENCER_MANAGER_CLICKED_SIGNUP: 'Influencer manager clicked signup',

    PUBLISHER_CLICKED_CHANNEL_LINK_IN_CAMPAIGN_CARD:
      'Publisher clicked channel link in campaign card',
    PUBLISHER_CLICKED_CHANNEL_LINK_IN_FOLLOWED_INFLUENCER_CARD:
      'Publisher clicked channel in followed influencer card',
    PUBLISHER_CLICKED_MATCHING_VIDEO: 'Publisher clicked matching video',
    PUBLISHER_CLICKED_INSTALL_LINK: 'Publisher clicked install link in her own campaign',

    INFLUENCER_CLICKED_VIDEO_LINK_IN_PUBLISHED_VIDEO_MESSAGE:
      'Influencer clicked the video link in a published video message',
    PUBLISHER_CLICKED_VIDEO_LINK_IN_PUBLISHED_VIDEO_MESSAGE:
      'Publisher clicked the video link in a published video message',

    PUBLISHER_CLICKED_MESSAGES_ICON_TO_GO_TO_CHAT_PAGE:
      'Publisher clicked the messages icon to go to the chat page',
    INFLUENCER_CLICKED_MESSAGES_ICON_TO_GO_TO_CHAT_PAGE:
      'Influencer clicked the messages icon to go to the chat page'
  }
};
