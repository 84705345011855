import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import capitalize from 'lodash/capitalize';

import AndroidIcon from '../../common/Icons/AndroidIcon';
import AppleIcon from '../../common/Icons/AppleIcon';

import GamePlatform from '../../game/GamePlatform';

import { PromotionType, canTrackInstalls } from '@sharkpunch/matchmade-common/campaign';

import './CampaignInfo.scss';

const DESCRIPTION_MAX_LENGTH = 800;

const CampaignInfo = ({ campaign }) => {
  return (
    <div className="CampaignInfoTab">
      <h2>
        <FormattedMessage id="campaign.report.info.title" tagName="strong" />
      </h2>
      <CampaignInfoTable campaign={campaign} />
    </div>
  );
};

const Description = ({ description }) => {
  return (
    <div className="CampaignInfoTab__table-row">
      <div className="CampaignInfoTab__table-left">
        <FormattedMessage id="campaign.report.info.campaignDescription" tagName="strong" />
      </div>
      <div className="CampaignInfoTab__table-right">
        <pre className="CampaignInfoTab__description">{description}</pre>
      </div>
    </div>
  );
};

const CampaignInfoTable = injectIntl(({ campaign, intl }) => {
  const iosAttributionLink =
    campaign.attributionPartnerPlatformSpecificTrackingCodes &&
    campaign.attributionPartnerPlatformSpecificTrackingCodes.ios;
  const androidAttributionLink =
    campaign.attributionPartnerPlatformSpecificTrackingCodes &&
    campaign.attributionPartnerPlatformSpecificTrackingCodes.android;
  const genericAttributionLink = campaign.attributionPartnerTrackingCode;

  const hasDedicatedPromotionType = campaign.promotionTypes.indexOf(PromotionType.DEDICATED) !== -1;
  const isDedicatedDefault =
    campaign.defaultPromotionType === PromotionType.DEDICATED && campaign.promotionTypes.length > 1;
  const hasIntegrationPromotionType =
    campaign.promotionTypes.indexOf(PromotionType.INTEGRATION) !== -1;
  const isIntegrationDefault =
    campaign.defaultPromotionType === PromotionType.INTEGRATION &&
    campaign.promotionTypes.length > 1;
  const description =
    campaign.description.length < DESCRIPTION_MAX_LENGTH
      ? campaign.description
      : campaign.description.substring(0, DESCRIPTION_MAX_LENGTH) + '...';

  const noAttributionWarningBlock = () => {
    return (
      <div className="CampaignInfoTab__warning-block">
        <div>
          <i className="fa fa-lg fa-exclamation-circle ExclamationIcon" />
        </div>
        <p>
          {intl.formatMessage({
            id: 'campaign.report.info.noAttributionWarning'
          })}
        </p>
      </div>
    );
  };

  const campaignAttribution = () => {
    return (
      <div className="CampaignInfoTab__table-right">
        {capitalize(campaign.attributionPartner)}
        {iosAttributionLink && (
          <div className="CampaignInfoTab__attribution-section">
            <div className="CampaignInfoTab__attribution-icon">
              <AppleIcon />
              URL
            </div>
            <a href={androidAttributionLink} target="_blank" rel="noopener noreferrer">
              {iosAttributionLink}
            </a>
          </div>
        )}
        {androidAttributionLink && (
          <div className="CampaignInfoTab__attribution-section">
            <div className="CampaignInfoTab__attribution-icon">
              <AndroidIcon />
              URL
            </div>
            <a href={androidAttributionLink} target="_blank" rel="noopener noreferrer">
              {androidAttributionLink}
            </a>
          </div>
        )}
        {genericAttributionLink && !iosAttributionLink && !androidAttributionLink && (
          <a
            href={genericAttributionLink}
            target="_blank"
            rel="noopener noreferrer"
            className="CampaignInfoTab__attribution-section">
            {genericAttributionLink}
          </a>
        )}
        {!canTrackInstalls(campaign.attributionPartner) && noAttributionWarningBlock()}
      </div>
    );
  };

  /* TODO: TWITCH!
  const hasYouTubeContentPlatform = campaign.contentPlatforms.indexOf(ContentPlatform.YOUTUBE) !== -1;
  const isYouTubeDefault = (campaign.defaultContentPlatform === ContentPlatform.YOUTUBE) &&
    campaign.contentPlatforms.length > 1;
  const hasTwitchContentPlatform = campaign.contentPlatforms.indexOf(ContentPlatform.TWITCH) !== -1;
  const isTwitchDefault = campaign.defaultContentPlatform === ContentPlatform.TWITCH &&
    campaign.contentPlatforms.length > 1;
  */

  return (
    <div className="CampaignInfoTab__table">
      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left" />
        <div className="CampaignInfoTab__table-right image is-circle">
          <img referrerPolicy="no-referrer" src={campaign.game.logoUrl} alt="Game logo" />
        </div>
      </div>

      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.name" tagName="strong" />
        </div>
        <div className="CampaignInfoTab__table-right">
          <strong className="CampaignInfoTab__campaign-name">{campaign.name}</strong>
        </div>
      </div>

      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.platforms" tagName="strong" />
        </div>
        <div className="CampaignInfoTab__table-right">
          <div className="CampaignInfoTab__platforms">
            {campaign.game.platforms.map(platform => (
              <GamePlatform key={platform} platform={platform} />
            ))}
          </div>
        </div>
      </div>

      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.tags" tagName="strong" />
        </div>
        <div className="CampaignInfoTab__table-right">{campaign.game.categories.join(', ')}</div>
      </div>

      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.dealType" tagName="strong" />
        </div>
        <div className="CampaignInfoTab__table-right">
          {hasDedicatedPromotionType && (
            <div className="CampaignInfoTab__promotion-info">
              <div>
                <FormattedMessage key="dedicated" id="campaign.promotionTypes.dedicated" />
                {isDedicatedDefault && (
                  <FormattedMessage key="default" id="campaign.report.info.defaultPromotionType" />
                )}
              </div>
              <FormattedMessage
                key="dedicatedDescription"
                id="campaign.report.info.dedicatedDescription"
              />
            </div>
          )}
          {hasIntegrationPromotionType && (
            <div className="CampaignInfoTab__promotion-info">
              <div>
                <FormattedMessage key="integration" id="campaign.promotionTypes.integration" />
                {isIntegrationDefault && (
                  <FormattedMessage key="default" id="campaign.report.info.defaultPromotionType" />
                )}
              </div>
              <FormattedMessage
                key="integrationDescription"
                id="campaign.report.info.integrationDescription"
              />
            </div>
          )}
        </div>
      </div>

      {/* TODO: TWITCH!
      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.dealType" tagName="strong"/>
        </div>
        <div className="CampaignInfoTab__table-right">
          {
            hasDedicatedPromotionType && (
              <div className="CampaignInfoTab__promotion-info">
                <div>
                  <FormattedMessage key="dedicated" id="campaign.promotionTypes.dedicated" />
                  {isDedicatedDefault && <FormattedMessage key="default" id="campaign.report.info.defaultPromotionType" />}
                </div>
                <FormattedMessage key="dedicatedDescription" id="campaign.report.info.dedicatedDescription" />
              </div>
            )
          }
          {
            hasIntegrationPromotionType && (
              <div className="CampaignInfoTab__promotion-info">
                <div>
                  <FormattedMessage key="integration" id="campaign.promotionTypes.integration"/>
                  {isIntegrationDefault && <FormattedMessage key="default" id="campaign.report.info.defaultPromotionType" />}
                </div>
                <FormattedMessage key="integrationDescription" id="campaign.report.info.integrationDescription" />
              </div>
            )
          }
        </div>
      </div>
      */}

      <div className="CampaignInfoTab__table-row">
        <div className="CampaignInfoTab__table-left">
          <FormattedMessage id="campaign.report.info.attribution" tagName="strong" />
        </div>
        {campaignAttribution()}
      </div>

      <Description description={description} />
    </div>
  );
});

export default CampaignInfo;
