import mapValues from 'lodash/mapValues';

export default function (functions, customProps = function () {}) {
  return function (dispatch, ownProps) {
    return Object.assign(
      {},
      mapValues(functions, (handler, name) => {
        return function (...args) {
          return dispatch(handler.run.apply(handler, args));
        };
      }),
      customProps(dispatch, ownProps)
    );
  };
}
