// @flow
export const ContentType = {
  INSTAGRAM_POST: 'instagram_post',
  INSTAGRAM_STORY: 'instagram_story',
  INSTAGRAM_IGTV: 'instagram_igtv',
  YOUTUBE_VIDEO: 'youtube_video',
  TWITCH_STREAM: 'twitch_stream',
  TWITCH_VIDEO: 'twitch_video',
  BILIBILI_VIDEO: 'bilibili_video'
};

export const ContentPlatform = {
  YOUTUBE: 'youtube',
  TWITCH: 'twitch',
  INSTAGRAM: 'instagram',
  BILIBILI: 'bilibili'
};

export const ConnectionType = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google'
};

export const ContentPlatformToConnectionType = {
  [ContentPlatform.YOUTUBE]: ConnectionType.GOOGLE,
  [ContentPlatform.INSTAGRAM]: ConnectionType.FACEBOOK,
  [ContentPlatform.BILIBILI]: 'not-supported-yet',
  [ContentPlatform.TWITCH]: 'not-supported-yet'
};

export const MessageType = {
  MESSAGE: 'message',
  NEGOTIATION: 'negotiation',
  PUBLISHED_CONTENT: 'published_content',
  COMMISSION: 'commission',
  CHANGED_DEADLINE: 'changed_deadline'
};

export const supportedMessageTypes = Object.values(MessageType);

export const DealType = {
  CPM: 'cpm',
  CPI: 'cpi',
  FIXED_FEE: 'fixedFee',
  FREE_OF_CHARGE: 'freeOfCharge'
};

export const DealItemStatus = {
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  PUBLISHED: 'published',
  DELETED: 'deleted'
};

export const Regex = {
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/i,
  // credit https://stackoverflow.com/a/3726073
  YOUTUBE_VIDEO_ID: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)(?<videoId>[\w_-]*)(&(amp;)?[\w?=]*)?/i,
  INSTAGRAM_POST_ID: /(https?:\/\/)?(www\.)?instagram\.com\/p\/(?<shortcode>[^/?#&]+).*/i
};

export const Role = {
  PUBLISHER: 'publisher',
  ADMIN: 'admin',
  WHITELABEL_ADMIN: 'whitelabel_admin',
  INFLUENCER: 'influencer',
  INFLUENCER_MANAGER: 'influencer_manager'
};

export const defaultSettings = {
  preferredCommunicationValue: '',
  preferredCommunication: 'email',
  contactEmail: ''
};

export const OfferType = {
  NEGOTIATION: 'negotiation',
  EXPRESS: 'express' // a.k.a. "take it or leave it"
};

export const ContentSubmissionStatusEnum = {
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  REQUEST_FOR_CHANGES: 'request_for_changes',
  CANCELED: 'canceled'
};

export const ContentSubmissionStatusLabel = {
  pending: 'Pending',
  submitted: 'Submitted',
  in_review: 'In review',
  approved: 'Approved',
  request_for_changes: 'Changes requested',
  canceled: 'Canceled'
};
