// @flow
import * as React from 'react';

import classNames from 'classnames';

import './Select.scss';
import defaultArrowRenderer from '../../helpers/arrowRenderer';
// $FlowFixMe
import ReactSelect from 'react-select';

// This hacky solution will affect all other places (in a good way) but the
// behaviour of the select component doesn't change

// https://github.com/JedWatson/react-select/blob/v1.3.0/src/Select.js#L215
ReactSelect.prototype.handleTouchOutside = function (event) {
  // When we select an option, we will probably remove it from the list of available
  // options. At that point the wrapper doesn't contain the node anymore
  // So, check for the className of the event target, if it's the option, don't
  // close the menu
  if (
    event.target &&
    typeof event.target.className === 'string' &&
    event.target.className.indexOf('Select-option') !== -1
  ) {
    return;
  }
  // handle touch outside on ios to dismiss menu
  if (this.wrapper && !this.wrapper.contains(event.target)) {
    this.closeMenu();
  }
};

// https://github.com/JedWatson/react-select/blob/v1.3.0/src/Select.js#L401
ReactSelect.prototype.handleInputBlur = function (event) {
  // The check for menu.contains(activeElement) is necessary to prevent IE11's scrollbar from closing the menu in certain contexts.
  if (
    this.menu &&
    (this.menu === document.activeElement || this.menu.contains(document.activeElement))
  ) {
    this.focus();
    return;
  }

  if (this.props.onBlur) {
    this.props.onBlur(event);
  }
  let onBlurredState = {
    isFocused: false,
    // this doesn't work because when we select an option, we lose focus on the input
    // which triggers the blur event and close the menu
    // isOpen: false
    isPseudoFocused: false,
    inputValue: this.state.inputValue
  };
  if (this.props.onBlurResetsInput) {
    onBlurredState.inputValue = this.handleInputValueChange('');
  }
  this.setState(onBlurredState);
};

// TODO define proper type for props
Select.defaultProps = {
  isInverted: false
};
export default function Select(props: any) {
  const className = classNames('CustomSelect', {
    'CustomSelect--inverted': props.isInverted
  });

  const selectProps = {
    arrowRenderer: () => {
      if (props.disabled) return null;
      return defaultArrowRenderer(props);
    },
    ...props,
    // have this so that we don't screw up styles of the same Select component in other places
    className: className
  };

  return <ReactSelect {...selectProps} />;
}
