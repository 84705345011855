// Our custom theme for material UI. The result produced by
// createTheme is used by <MuiThemeProvider> component that
// is wrapping all of the routes at Root.js
//
// NOTE!
// Using commonjs rather than es modules, otherwise we can't
// test some of our components in webapp without some special
// transpiling jest/babel configuration

import { createTheme } from '@material-ui/core/styles';
import { getColorVariables } from '../config';

const variables = getColorVariables();

const muiTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        color: variables['mm-navy']
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        color: variables['mm-navy'],
        '&:hover $notchedOutline': {
          borderColor: variables['mm-aqua']
        }
      }
    }
  },
  palette: {
    primary: { main: variables['mm-navy'] },
    secondary: { main: variables['mm-aqua'] },
    error: { main: variables['mm-red'] },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  typography: {
    // The same as the font family defined for html/body
    fontFamily: [
      '"GT-Walsheim-Medium"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif'
    ].join(',')
  }
});

export default muiTheme;
