import { createApiAction } from './helper';

export const getAllocatedBudget = createApiAction('FETCH_ALLOCATED_BUDGET', () => {
  return {
    path: `/admin/campaigns/allocated-budget`
  };
});

export const getAllocatedBudgetForCampaign = createApiAction('GET_ALLOCATED_BUDGET', campaignId => {
  return {
    path: `/admin/campaigns/${campaignId}/allocated-budget`
  };
});
