// @flow
import * as React from 'react';
import Icon from '../Icon';
import type { IconProps } from '../Icon';

const SendIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="SendIcon">
      {() => <i className="material-icons">send</i>}
    </Icon>
  );
};

export default SendIcon;
