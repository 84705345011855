// @flow
import * as React from 'react';

import './Tabs.scss';

type Props = {
  tabs: string[],
  panels: React.Node[],
  onChangeTab?: number => void,
  activeTab?: number
};
// This is as simple as possible and not-flexible at all
// If you need more, maybe take a look at https://reactcommunity.org/react-tabs/
const Tabs = (props: Props) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(props.activeTab || 0);
  const panel = props.panels[activeTabIndex];
  return (
    <div className="Tabs">
      <div className="tabs is-fullwidth is-boxed">
        <ul>
          {props.tabs.map((tab, index) => {
            const tabTags = tab.split('\n').map(word => <strong key={word}>{word}</strong>);
            // <strong> could be moved out of this component if need be
            return (
              <li key={index} className={index === activeTabIndex ? 'is-active' : null}>
                {/* Bulma's tabs styles work only for anchor :/ */
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onChangeTab && props.onChangeTab(index);
                    setActiveTabIndex(index);
                  }}>
                  {tabTags}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <section className="section tab-panel is-active">{panel}</section>
    </div>
  );
};

export default Tabs;
