import * as React from 'react';

import { ContentPlatform, AttributionPartner } from '@sharkpunch/matchmade-common/campaign';
import { FormattedMessage } from 'react-intl';
import BilibiliIcon from '../common/Icons/BilibiliIcon';
import FormattedNumber from '../common/FormattedNumber';
import InstagramIcon from '../common/Icons/InstagramIcon';
import Link from 'react-router/lib/Link';
import OnOffToggle from '../common/OnOffToggle';
import Table, { Column, pageLimitOptions } from '../tables/Table';
import Tooltip from '../common/Tooltip';
import TwitchIcon from '../common/Icons/TwitchIcon';
import YoutubeIcon from '../common/Icons/YoutubeIcon';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import './CampaignsTable.scss';

function ArchivedIcon() {
  return (
    <Tooltip tooltip={<span>Stats or channel suggestions won't be updated </span>}>
      <span className="tag is-light is-rounded">Archived</span>
    </Tooltip>
  );
}

function CampaignsTable(props) {
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([
    ContentPlatform.YOUTUBE,
    ContentPlatform.TWITCH,
    ContentPlatform.INSTAGRAM,
    ContentPlatform.BILIBILI
  ]);

  function selectPlatform(platform) {
    return () => {
      if (!selectedPlatforms.includes(platform)) {
        setSelectedPlatforms(selectedPlatforms.concat(platform));
      } else {
        setSelectedPlatforms(selectedPlatforms.filter(v => v !== platform));
      }
    };
  }

  const { campaigns, updatingCampaignId, enableCampaign, disableCampaign } = props;

  const filteredCampaigns = campaigns.filter(campaign => {
    return (
      (campaign.contentPlatforms.includes(ContentPlatform.YOUTUBE) &&
        selectedPlatforms.includes(ContentPlatform.YOUTUBE)) ||
      (campaign.contentPlatforms.includes(ContentPlatform.TWITCH) &&
        selectedPlatforms.includes(ContentPlatform.TWITCH)) ||
      (campaign.contentPlatforms.includes(ContentPlatform.INSTAGRAM) &&
        selectedPlatforms.includes(ContentPlatform.INSTAGRAM)) ||
      (campaign.contentPlatforms.includes(ContentPlatform.BILIBILI) &&
        selectedPlatforms.includes(ContentPlatform.BILIBILI))
    );
  });

  const headerControls = [
    <button className="link-button" key="youtube" onClick={selectPlatform(ContentPlatform.YOUTUBE)}>
      <YoutubeIcon
        inactive={!selectedPlatforms.includes(ContentPlatform.YOUTUBE)}
        withTooltip
        tooltipPosition="bottom"
        tooltipI18nString="admin.influencers.table.includeYoutubeCampaign"
      />
    </button>,
    <button className="link-button" key="twitch" onClick={selectPlatform(ContentPlatform.TWITCH)}>
      <TwitchIcon
        inactive={!selectedPlatforms.includes(ContentPlatform.TWITCH)}
        withTooltip
        tooltipPosition="bottom"
        tooltipI18nString="admin.influencers.table.includeTwitchCampaign"
      />
    </button>,
    <button
      className="link-button"
      key="instagram"
      onClick={selectPlatform(ContentPlatform.INSTAGRAM)}>
      <InstagramIcon
        inactive={!selectedPlatforms.includes(ContentPlatform.INSTAGRAM)}
        withTooltip
        tooltipPosition="bottom"
        tooltipI18nString="admin.influencers.table.includeInstagram"
      />
    </button>,
    <button
      className="link-button"
      key="bilibili"
      onClick={selectPlatform(ContentPlatform.BILIBILI)}>
      <BilibiliIcon
        inactive={!selectedPlatforms.includes(ContentPlatform.BILIBILI)}
        withTooltip
        tooltipPosition="bottom"
        tooltipI18nString="admin.influencers.table.includeBilibili"
      />
    </button>,
    <Link to="/admin/campaigns/new" key="new-campaign" className="button is-primary ml-4">
      New campaign
    </Link>
  ];

  return (
    <div className="CampaignsTable">
      <Table
        data={filteredCampaigns}
        headerControls={headerControls}
        initialSort="created"
        initialSortDirection="desc"
        className="is-fullwidth is-hoverable"
        pagination={filteredCampaigns.length > pageLimitOptions[1]}
        searchBy={['id', 'name', 'attributionPartner', 'game.title', 'team.name']}>
        <Column name="id">
          <FormattedMessage id="admin.campaigns.table.header.id" />
        </Column>
        <Column
          name="game.logoUrl"
          sortable={false}
          component={props => {
            const game = props.rowData.game;
            if (!game) {
              return null;
            }
            return (
              <figure className="image is-24x24">
                <img referrerPolicy="no-referrer" src={game.logoUrl} alt={game.title} />
              </figure>
            );
          }}></Column>
        <Column
          name="*"
          sortBy={campaign => {
            const { contentPlatforms } = campaign;

            if (contentPlatforms.includes(ContentPlatform.YOUTUBE)) {
              return 2;
            }
            if (contentPlatforms.includes(ContentPlatform.TWITCH)) {
              return 1;
            }
            return -1;
          }}
          component={props => {
            const { contentPlatforms } = props.rowData;
            const isYoutubeCampaign = contentPlatforms.includes(ContentPlatform.YOUTUBE);
            const isTwitchCampaign = contentPlatforms.includes(ContentPlatform.TWITCH);
            const isInstagram = contentPlatforms.includes(ContentPlatform.INSTAGRAM);
            const isBilibili = contentPlatforms.includes(ContentPlatform.BILIBILI);
            return (
              <span className="CampaignsTable__platform-icons">
                {isYoutubeCampaign && <YoutubeIcon />}
                {isTwitchCampaign && <TwitchIcon />}
                {isInstagram && <InstagramIcon />}
                {isBilibili && <BilibiliIcon />}
              </span>
            );
          }}>
          <FormattedMessage id="campaignCreation.productSelection.platform" />
        </Column>
        <Column
          name="name"
          component={props => (
            <span className="AdminDashboardCampaigns__campaign-name">
              <Link to={`/admin/campaigns/${props.rowData.id}`}>
                {props.rowData.name || 'Hypothetical campaign'}
              </Link>
              {props.rowData.archived ? <ArchivedIcon /> : null}
            </span>
          )}>
          <FormattedMessage id="admin.campaigns.table.header.name" />
        </Column>
        <Column
          name="team.name"
          sortBy={name => name.toLowerCase()}
          component={props => {
            const campaign = props.rowData;
            if (!campaign.team) {
              return null;
            } else {
              return <Link to={`/admin/teams/${campaign.team.id}`}>{campaign.team.name}</Link>;
            }
          }}>
          <FormattedMessage id="admin.campaigns.table.header.team" />
        </Column>
        <Column
          name="created"
          sortBy={created => new Date(created).getTime()}
          component={props => <span>{moment(props.rowData.created).format('MMM Do YYYY')}</span>}>
          <FormattedMessage id="admin.campaigns.table.header.created" />
        </Column>
        <Column name="installCount">
          <FormattedMessage id="admin.campaigns.table.header.installs" />
        </Column>
        <Column
          name="cost"
          component={props => (
            <FormattedNumber type="cost" value={props.rowData.cost} defaultToZero />
          )}>
          <FormattedMessage id="admin.campaigns.table.header.cost" />
        </Column>
        <Column
          name="payout"
          component={props => (
            <FormattedNumber type="cost" value={props.rowData.payout} defaultToZero />
          )}>
          <FormattedMessage id="admin.campaigns.table.header.payout" />
        </Column>
        <Column
          name="attributionPartner"
          sortBy={attributionPartner => attributionPartner || 'z'}
          component={props => (
            <>
              <span>{capitalize(props.rowData.attributionPartner)}</span>{' '}
              {props.rowData.attributionPartner === AttributionPartner.NONE && (
                <Tooltip
                  tooltip={<span>Often campaigns should have some attribution configured</span>}>
                  <span className="tag is-warning is-rounded">Warning</span>
                </Tooltip>
              )}
            </>
          )}>
          <FormattedMessage id="admin.campaigns.table.header.attribution" />
        </Column>
        <Column
          name="visible"
          component={props => {
            const { visible, id } = props.rowData;
            return (
              <OnOffToggle
                onClick={() => {
                  if (visible) {
                    disableCampaign(id);
                  } else {
                    enableCampaign(id);
                  }
                }}
                isOn={visible}
                isLoading={updatingCampaignId === id}
              />
            );
          }}>
          <FormattedMessage id="admin.campaigns.table.header.visibility" />
        </Column>
      </Table>
    </div>
  );
}

export default CampaignsTable;
