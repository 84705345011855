import React from 'react';

import './Icons.scss';

const PrevIcon = ({ isActive }) => {
  const iconStyles = isActive
    ? 'icon TabIcon mdTabIcon PrevIcon activeTabIcon'
    : 'icon TabIcon mdTabIcon PrevIcon';
  return (
    <span className={iconStyles}>
      <i className="material-icons">navigate_before</i>
    </span>
  );
};

export default PrevIcon;
