// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../common/Tooltip';

type Props = {
  list: any[]
};

function generateSingleListItem(textItem: any) {
  const weSend = textItem.weSendHighlighted ? (
    <span className="has-text-primary">{textItem.weSendHighlighted}</span>
  ) : (
    textItem.weSend
  );
  const as = <span className="has-text-primary">{textItem.as}</span>;
  return (
    <li key={textItem.as}>
      <FormattedMessage
        id="publisher.campaign.attribution.listItem"
        values={{
          name: weSend,
          param: as
        }}
      />
    </li>
  );
}

function createTooltipContent(textObject) {
  const liComponents = textObject.map(item => generateSingleListItem(item));

  return <ul>{liComponents}</ul>;
}

export default function AttributionInfoTooltip(props: Props) {
  const tooltipData = createTooltipContent(props.list);

  return (
    <Tooltip tooltip={tooltipData}>
      <i className="material-icons">info</i>
    </Tooltip>
  );
}
