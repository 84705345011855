import PropTypes from 'prop-types';
import React from 'react';

import ExternalLink from '../../images/icons/ExternalLink';
import StoreIconAndroid from '../../images/icons/StoreIconAndroid';
import StoreIconApple from '../../images/icons/StoreIconApple';
import StoreIconSteam from '../../images/icons/StoreIconSteam';

import './GamePlatform.scss';

const ICONS = {
  ios: <StoreIconApple />,
  android: <StoreIconAndroid />,
  steam: <StoreIconSteam />,
  homepage: <ExternalLink />
};

const GamePlatform = ({ platform }) => {
  return <span className="GamePlatform">{ICONS[platform] || ICONS.homepage}</span>;
};

GamePlatform.propTypes = {
  platform: PropTypes.string.isRequired
};

export default GamePlatform;
