import React from 'react';

import './Card.scss';

const Card = ({ className, children, ...props }) => {
  const finalClassName = ['Card', className].filter(Boolean).join(' ');
  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  );
};

export default Card;
