import { Element, scroller } from 'react-scroll';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import map from 'lodash/map';

import { DISPLAY_MODES } from './InfluencerCollection';
import Pagination from '../common/Pagination';
import SortingSelector from '../common/SortingSelector';

import './InfluencerCollection.scss';

class ExternallyManagedInfluencerCollection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayMode: 'card'
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangePageSize = this.onChangePageSize.bind(this);
    this.onChangeSort = this.onChangeSort.bind(this);
    this.onChangeDisplayMode = this.onChangeDisplayMode.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.page !== prevProps.page ||
      this.props.pageSize !== prevProps.pageSize ||
      this.props.sortBy !== prevProps.sortBy ||
      this.props.sortDirection !== prevProps.sortDirection
    ) {
      scroller.scrollTo('topOfInfluencerCollection', {
        duration: 300,
        delay: 100,
        smooth: true
      });
    }
  }

  onChangePage(newPage) {
    this.props.onChangePage(newPage);
  }

  onChangePageSize(pageSize) {
    this.props.onChangePageSize(pageSize);
  }

  onChangeDisplayMode(e) {
    this.setState({
      displayMode: e.target.value
    });
  }

  // TODO: this method tries to handle both SearchInfluencersTable and cards with SortingSelector
  // Would be much easier if the sorting callbacks those methods would made to use similar parameters
  onChangeSort({ sortProp, ascending, name, sortDirectionCycle }) {
    if (ascending === true || ascending === false) {
      // SortingSelector uses boolean for direction
      this.props.onChangeSort(sortProp, ascending ? 'asc' : 'desc');
    } else if (name && Array.isArray(sortDirectionCycle)) {
      // SearchInfluencersTable && Table send the whole cycle
      if (name !== this.props.sortBy) {
        // When we change the sort property, then default to asc
        // or desc based on the column default (names asc, numbers desc etc.)
        this.props.onChangeSort(name, sortDirectionCycle[0]);
      } else {
        let sortDirection = sortDirectionCycle[0];
        if (sortDirection === this.props.sortDirection) {
          sortDirection = sortDirectionCycle[1];
        }
        // We are just changing direction
        this.props.onChangeSort(name, sortDirection);
      }
    }
    /* TODO: persist user level settings
    this.props.onChangeSettings({
      [this.props.name]: {
        sortBy: sortProp,
        sortDirection: ascending ? 'asc' : 'desc'
      }
    });
    */
  }

  renderSortOptions() {
    if (this.state.displayMode !== 'card') return null;

    return (
      <SortingSelector
        name={this.props.name}
        options={this.props.sortOptions.map(({ attribute, label }) => {
          return {
            value: attribute,
            label: this.props.intl.formatMessage({ id: label })
          };
        })}
        settingsPrefix={null /* on purpose, we handle this through onSelect */}
        onSelect={this.onChangeSort}
        ascending={this.props.sortDirection === 'asc'}
        selectedValue={this.props.sortBy}
      />
    );
  }

  renderDisplayModeSelection() {
    // No need for selector if only one type of list
    if (!this.props.card || !this.props.list) return null;

    const options = map(DISPLAY_MODES, (text, value) => {
      return (
        <option key={value} value={value}>
          {this.props.intl.formatMessage({ id: text })}
        </option>
      );
    });

    return (
      <span className="select">
        <select value={this.state.displayMode} onChange={this.onChangeDisplayMode}>
          {options}
        </select>
      </span>
    );
  }

  renderPagination() {
    if (this.props.totalCount === this.props.influencers.length) return null;

    const pageCount = Math.ceil(this.props.totalCount / this.props.pageSize);

    return (
      <Pagination
        pageCount={pageCount}
        currentPage={this.props.page}
        pageLimit={this.props.pageSize}
        pageRange={4}
        onChangePage={this.onChangePage}
        onChangePageLimit={this.onChangePageSize}
      />
    );
  }

  renderCards() {
    const CardComponent = this.props.card;
    const cardProps = this.props.cardProps;
    return (
      <div className="columns is-multiline">
        {this.props.influencers.map(influencer => {
          const key = influencer.youtubeChannels[0]
            ? influencer.youtubeChannels[0].id
            : influencer.twitchChannels[0]
            ? influencer.twitchChannels[0]
            : null;
          return (
            <div className="column is-one-third-desktop" key={key}>
              <CardComponent influencer={influencer} {...cardProps} />
            </div>
          );
        })}
      </div>
    );
  }

  renderList() {
    const ListComponent = this.props.list;
    return (
      <ListComponent
        influencers={this.props.influencers}
        {...this.props.listProps}
        onChangeSort={this.onChangeSort}
        sortOptions={this.props.sortOptions.map(option => option.attribute)}
        initialSort={this.props.sortBy}
        initialSortDirection={this.props.sortDirection}
      />
    );
  }

  renderInfluencers() {
    switch (this.state.displayMode) {
      case 'card':
        return this.renderCards();
      case 'list':
        return this.renderList();
      default:
        return <div>Unsupported mode</div>;
    }
  }

  render() {
    const className = `InfluencerCollection InfluencerCollection--${this.state.displayMode}`;

    if (this.props.totalCount === 0) {
      return (
        <div className={className + ' has-text-centered'}>
          <FormattedMessage
            id="influencer.collection.influencerCount"
            values={{
              count: this.props.totalCount
            }}
          />
        </div>
      );
    }

    const pagination = this.renderPagination();

    return (
      <div className={className}>
        <Element name="topOfInfluencerCollection" />
        <div className="InfluencerCollection__toolbar is-flex-tablet">
          {this.renderDisplayModeSelection()}
          {this.renderSortOptions()}
        </div>

        <div className="InfluencerCollection__results-info is-flex-tablet">
          <div className="InfluencerCollection__total-influencer">
            <FormattedMessage
              id="influencer.collection.influencerCount"
              values={{
                count: this.props.totalCount
              }}
            />
          </div>
          <div className="InfluencerCollection__top-pagination">{pagination}</div>
        </div>

        <div
          className={
            'InfluencerCollection__influencers' +
            (this.props.isLoading ? ' InfluencerCollection__influencers--disabled' : '')
          }>
          {this.renderInfluencers()}
        </div>
        <div className="InfluencerCollection__results-info is-flex-tablet">
          <div className="InfluencerCollection__bottom-pagination">{pagination}</div>
        </div>
      </div>
    );
  }
}

ExternallyManagedInfluencerCollection = injectIntl(ExternallyManagedInfluencerCollection);

ExternallyManagedInfluencerCollection.propTypes = {
  // used to
  // - update user settings (preferred sort options for example)
  name: PropTypes.string,
  // TODO add influencer shape
  influencers: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  card: PropTypes.func,
  cardProps: PropTypes.oneOfType([
    PropTypes.object,
    // use a function here when you want to return different props
    // for each card. It receives the current influencer as the first argument
    PropTypes.func
  ]),
  list: PropTypes.func,
  listProps: PropTypes.object,
  // First option is used as the default sort option if no sortBy given
  // These will be used for both, cards and list
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      // will be passed to get(influencer, `youtubeChannels[0].${key}`)
      attribute: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        // will be passed to FormattedMessage as id
        PropTypes.string,
        PropTypes.element
      ]).isRequired
    })
  ),
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']), // TODO:
  displayMode: PropTypes.oneOf(Object.keys(DISPLAY_MODES)),
  onChangeSettings: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

ExternallyManagedInfluencerCollection.defaultProps = {
  name: 'influencerCollection',
  influencers: [],
  totalCount: 0,
  cardProps: {},
  tableProps: {},
  displayMode: Object.keys(DISPLAY_MODES)[0],
  onChangeSettings: () => {},
  onChangePage: () => {},
  onChangePageSize: () => {},
  onChangeSort: () => {},
  sortDirection: 'desc',
  isLoading: false
};

export default ExternallyManagedInfluencerCollection;
