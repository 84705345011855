// @flow
import * as React from 'react';

import isEqual from 'lodash/isEqual';

import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import StoreIconAndroid from '../../../../images/icons/StoreIconAndroid';
import StoreIconApple from '../../../../images/icons/StoreIconApple';

import UrlControlInput from '../../common/UrlControlInput';

import './PlatformSpecificInputs.scss';

export type PlatformSpecificTrackingCodes = {
  ios?: string,
  android?: string,
  default?: string
};

type Props = {
  values: PlatformSpecificTrackingCodes | null,
  onChange: (values: PlatformSpecificTrackingCodes) => void,
  isDisabled: boolean,
  isInverted?: boolean,
  intl: IntlShape,
  platforms: string[]
};

class PlatformSpecificInputs extends React.Component<Props, {}> {
  static defaultProps = {
    values: null,
    onChange() {},
    isDisabled: false,
    isInverted: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      this.props.isDisabled !== nextProps.isDisabled
    );
  }

  onChange = (platform: string) => {
    return value => {
      this.props.onChange({
        ...(this.props.values || {}),
        [platform]: value
      });
    };
  };

  platforms = [
    {
      name: 'android',
      icon: <StoreIconAndroid />
    },
    {
      name: 'ios',
      icon: <StoreIconApple />
    },
    {
      name: 'default',
      icon: (
        <i
          className="material-icons"
          style={{
            color: '#2d3767',
            fontSize: '20px',
            marginLeft: '-19px'
          }}>
          public
        </i>
      )
    }
  ];

  render() {
    const { values, isDisabled, isInverted } = this.props;

    const platforms = this.platforms
      .filter(({ name }) => {
        return this.props.platforms.indexOf(name) >= 0;
      })
      .map(({ name, icon }) => {
        return (
          <UrlControlInput
            key={name}
            icon={icon}
            value={(values || {})[name]}
            onChange={isDisabled ? () => {} : this.onChange(name)}
            isInverted={isInverted}
            isDisabled={isDisabled}
          />
        );
      });

    return <div className="PlatformSpecificInputs">{platforms}</div>;
  }
}

export default injectIntl(PlatformSpecificInputs);
