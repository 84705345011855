// @flow
export const StepName = {
  CAMPAIGN_DESCRIPTION: 'campaignDescription',
  PRODUCT_SELECTION: 'productSelection',
  CAMPAIGN_DETAILS: 'campaignDetails'
};

export const StepUrl = {
  [StepName.CAMPAIGN_DESCRIPTION]: 'campaign-description',
  [StepName.PRODUCT_SELECTION]: 'product-selection',
  [StepName.CAMPAIGN_DETAILS]: 'campaign-details'
};

export const StepI18nLabel = {
  [StepName.CAMPAIGN_DESCRIPTION]: 'campaignCreation.step.campaignDescription',
  [StepName.PRODUCT_SELECTION]: 'campaignCreation.step.productSelection',
  [StepName.CAMPAIGN_DETAILS]: 'campaignCreation.step.campaignDetails'
};

export const BASE_CAMPAIGN_CREATION_URL = '/dashboard/publisher/campaigns/create/';
