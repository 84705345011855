import React, { Component } from 'react';

import PropTypes from 'prop-types';

import InfluencerCardHeader from './InfluencerCardHeader';
import InfluencerCardTabs from './InfluencerCardTabs';

import './InfluencerCardLayout.scss';
/**
 * The main layout for different types of influencer cards
 */
class InfluencerCardLayout extends Component {
  renderFooter() {
    let { footer, footerHasBorder } = this.props;

    if (!footer) return null;

    if (!Array.isArray(footer)) footer = [footer];

    footer = footer.filter(Boolean).map((element, index) => {
      return React.cloneElement(element, {
        className: (element.props.className || '') + ' card-footer-item',
        key: index
      });
    });

    const className = ['InfluencerCardLayout__footer', 'card-footer'];

    if (!footerHasBorder) className.push('InfluencerCardLayout__footer--no-border');

    return <div className={className.join(' ')}>{footer}</div>;
  }

  render() {
    const { influencer, tabs, topRightCorner } = this.props;

    return (
      <div className="InfluencerCardLayout card">
        <InfluencerCardHeader influencer={influencer} right={topRightCorner} />
        <InfluencerCardTabs
          className="card-content InfluencerCardLayout__content"
          tabs={tabs}
          onChangeTab={this.props.onChangeTab}
          isLoading={this.props.isLoading}
        />
        {this.renderFooter()}
      </div>
    );
  }
}

const shapes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      active: PropTypes.bool,
      content: PropTypes.element
    })
  )
};

InfluencerCardLayout.propTypes = {
  // TODO: add influencer shape
  influencer: PropTypes.object.isRequired,
  tabs: shapes.tabs,
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  topRightCorner: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  onChangeTab: PropTypes.func,
  footerHasBorder: PropTypes.bool,
  isLoading: PropTypes.bool
};

InfluencerCardLayout.defaultProps = {
  tabs: [],
  footerHasBorder: true,
  isLoading: false
};

export { shapes };

export default InfluencerCardLayout;
