// @flow
import * as React from 'react';

import type { IntlShape } from 'react-intl';

import classNames from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';

import range from 'lodash/range';
import uniq from 'lodash/uniq';

import './Pagination.scss';

export const pageLimitOptions = [12, 24, 60];

// Generate visible pages and possible gaps
// This follows the same logic as that of Github pagination
// tl;dr
// - Always show first and last 2 pages
// - Always show at least 5 pages in the middle (total visible pages for selection is 9)
function getPages(pageCount: number, currentPage: number): Array<number | string> {
  const pageRange = 2,
    minVisiblePage = pageRange * 2 + 1;

  let lower = currentPage - pageRange,
    upper = currentPage + pageRange;

  // visible pages
  // left side of range is exclusive so we need to add 1
  let visiblePages = range(lower, upper + 1).filter(page => {
    return page > 0 && page <= pageCount;
  });

  // make sure that we have at least 5 visible pages
  const missingPageCount = minVisiblePage - visiblePages.length;
  if (missingPageCount > 0) {
    const firstVisiblePage = visiblePages[0],
      lastVisiblePage = visiblePages[visiblePages.length - 1];

    if (firstVisiblePage === 1) {
      // append missing pages to the right
      visiblePages = visiblePages.concat(
        // need to +1 here since we need to include one more page
        // because `range` excludes the right end of the range
        range(lastVisiblePage, lastVisiblePage + missingPageCount + 1)
      );
    } else {
      // append missing pages to the left
      // and we don't need +1 here since we don't want to include
      // the first visible page in the missing pages
      visiblePages = range(firstVisiblePage - missingPageCount, firstVisiblePage).concat(
        visiblePages
      );
    }
  }

  lower = visiblePages[0];
  upper = visiblePages[visiblePages.length - 1];

  // first 2 pages are always visible
  let pages: Array<number | string> = [1, 2];
  const lastPageToTheLeft = 2;

  // show ... if the gap between left edge of visible pages and
  // 2nd page is >= 3
  if (lower - 2 >= 3) {
    pages.push('gap-left');
  } else {
    // otherwise, fill it with missing pages
    pages = pages.concat(range(lastPageToTheLeft + 1, lower));
  }

  pages = pages.concat(visiblePages);

  // show ... if the gap between right edge of visible pages
  // and 2nd to last page is >= 3
  const lastPageToTheRight = pageCount - 1;
  if (lastPageToTheRight - upper >= 3) {
    pages.push('gap-right');
  } else {
    // otherwise, fill it with missing pages
    pages = pages.concat(range(upper + 1, pageCount - 1));
  }

  // always show last 2 pages
  pages = pages.concat([pageCount - 1, pageCount]);

  // sanity check to make sure we have pages within the range
  return uniq(
    pages.filter(page => {
      if (typeof page === 'string') {
        return page;
      }
      return page > 0 && page <= pageCount;
    })
  );
}

type Props = {
  currentPage: number,
  pageCount: number,
  pageLimit: number,
  onChangePage?: (page: number) => void,
  onChangePageLimit?: (pageLimit: number) => void,
  intl: IntlShape
};

function Pagination(props: Props) {
  const {
    currentPage = 1,
    pageCount = 1,
    pageLimit = pageLimitOptions[0],
    intl,
    onChangePageLimit = pageLimit => {},
    onChangePage = page => {}
  } = props;

  const onChangePageWithinPageCount = page => {
    return e => {
      e.preventDefault();

      if (typeof page === 'string') {
        return;
      }

      if (page > pageCount) {
        page = pageCount;
      }

      if (page <= 0) {
        page = 1;
      }

      onChangePage(page);
    };
  };

  React.useEffect(() => {
    if (!pageCount || currentPage === pageCount) {
      return;
    }
    if (currentPage > pageCount) {
      onChangePage(1);
    }
  }, [currentPage, pageCount, onChangePage]);

  // No pages to render -- return;
  if (!pageCount) {
    return null;
  }

  const pages = getPages(pageCount, currentPage).map(page => {
    if (page === 'gap-left' || page === 'gap-right') {
      return (
        <li key={`page-${page}`}>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      );
    }

    const className = classNames('pagination-link', {
      'is-current': page === currentPage
    });

    return (
      <li key={`page-${page}`} onClick={onChangePageWithinPageCount(page)}>
        <button className={className}>{page}</button>
      </li>
    );
  });

  const options = pageLimitOptions.map(count => {
    return (
      <option key={`option-${count}`} value={count}>
        {intl.formatMessage(
          {
            id: 'pagination.pageLimit'
          },
          { count }
        )}
      </option>
    );
  });

  return (
    <div className="Pagination">
      <nav className="pagination is-small">
        <button
          className="pagination-previous is-hidden-mobile"
          onClick={onChangePageWithinPageCount(currentPage - 1)}
          disabled={currentPage <= 1}>
          <FormattedMessage id="pagination.prevPage" />
        </button>
        <button
          className="pagination-next is-hidden-mobile"
          onClick={onChangePageWithinPageCount(currentPage + 1)}
          disabled={currentPage >= pageCount}>
          <FormattedMessage id="pagination.nextPage" />
        </button>
        <ul className="pagination-list">{pages}</ul>
        <p className="Pagination__page-limit control is-hidden-mobile">
          <span className="select is-small">
            <select
              onChange={e => {
                onChangePageLimit(parseInt(e.target.value, 10) || pageLimitOptions[0]);
              }}
              value={pageLimit}>
              {options}
            </select>
          </span>
        </p>
      </nav>
    </div>
  );
}

function sliceData(data: any[], currentState: { currentPage: number, pageLimit: number }): any[] {
  const { currentPage, pageLimit } = currentState;

  const begin = (currentPage - 1) * pageLimit,
    end = currentPage * pageLimit;

  return (data || []).slice(begin, end);
}

export {
  sliceData,
  getPages // for testing
};

export default injectIntl(Pagination);
