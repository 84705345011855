import React from 'react';

import { FormattedMessage } from 'react-intl';

import ChannelCountry from '../ChannelCountry';
import FormattedNumber from '../../common/FormattedNumber';
import PercentageTable from '../../common/PercentageTable';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';

import AndroidIcon from '../../common/Icons/AndroidIcon';
import AppleIcon from '../../common/Icons/AppleIcon';
import FemaleIcon from '../../common/Icons/FemaleIcon';
import LaptopIcon from '../../common/Icons/LaptopIcon';
import MaleIcon from '../../common/Icons/MaleIcon';

import './DemographicsTab.scss';

import isEmpty from 'lodash/isEmpty';

const NUMBER_OF_TABLE_ROWS = 4;

// TODO: TWITCH
// change input into youtube.demographics and twitch.????
const DemographicsTab = ({ demographics, influencer }) => {
  if (influencer.isOutOfNetworkInfluencer || isEmpty(demographics)) {
    return (
      <div className="DemographicsTab DemographicsTab__influencerCountry">
        <ChannelCountry
          countryCode={influencer.country || influencer.estimatedCountry}
          isEstimated={!influencer.country && influencer.estimatedCountry}
          key="country"
        />
      </div>
    );
  }

  const ageGroupsSortedByAge =
    (demographics.ageGroup && demographics.ageGroup.sort((a, b) => a.name - b.name)) || [];
  const youngAgesData = ageGroupsSortedByAge
    .map(({ name, value }) => ({
      label: name.substring(3),
      value: value / 100
    }))
    .slice(0, NUMBER_OF_TABLE_ROWS);
  const otherAgesPercentage = ageGroupsSortedByAge
    .slice(NUMBER_OF_TABLE_ROWS)
    .reduce((acc, e) => acc + e.value / 100, 0);
  const otherAgesData = {
    label: 'Others',
    value: otherAgesPercentage
  };
  const ageData = youngAgesData.concat(otherAgesData);

  const countriesByUsage =
    (demographics.country && demographics.country.sort((a, b) => b.value - a.value)) || [];
  const mostUsedCountriesData = countriesByUsage
    .filter(e => e.name !== 'ZZ')
    .filter(e => e.value)
    .map(({ name, value }) => ({
      label: countryCodes[name],
      value: value / 100
    }))
    .slice(0, NUMBER_OF_TABLE_ROWS);
  const unknownCountry = countriesByUsage.find(e => e.name === 'ZZ');
  const otherCountriesPercentage = countriesByUsage
    .slice(NUMBER_OF_TABLE_ROWS + 1)
    .reduce((acc, e) => acc + e.value / 100, (unknownCountry && unknownCountry.value / 100) || 0);
  const otherCountriesData = {
    label: 'Others',
    value: otherCountriesPercentage
  };
  const countryData = mostUsedCountriesData.concat(otherCountriesData);

  const womenData = demographics.gender && demographics.gender.find(e => e.name === 'female');
  const menData = demographics.gender && demographics.gender.find(e => e.name === 'male');

  const androidData =
    demographics.operatingSystem && demographics.operatingSystem.find(e => e.name === 'ANDROID');
  const iosData =
    demographics.operatingSystem && demographics.operatingSystem.find(e => e.name === 'IOS');
  const windowsData =
    demographics.operatingSystem && demographics.operatingSystem.find(e => e.name === 'WINDOWS');
  const linuxData =
    demographics.operatingSystem && demographics.operatingSystem.find(e => e.name === 'LINUX');
  const macosData =
    demographics.operatingSystem && demographics.operatingSystem.find(e => e.name === 'MACINTOSH');

  const androidPercentage = ((androidData && androidData.value) || 0) / 100;
  const iosPercentage = ((iosData && iosData.value) || 0) / 100;
  const computerPercentage =
    (((windowsData && windowsData.value) || 0) +
      ((linuxData && linuxData.value) || 0) +
      ((macosData && macosData.value) || 0)) /
    100;

  // If we happen for some reason to miss computerPercentage --
  // use this as a fallback
  const calculatedComputerPercentage = 1 - androidPercentage - iosPercentage;

  const menPercentage = ((menData && menData.value) || 0) / 100;
  const womenPercentage = ((womenData && womenData.value) || 0) / 100;

  return (
    <div className="DemographicsTab">
      <div className="firstTwoFifthWidth centerContent bottomBorder">
        <h2>
          <b>
            <FormattedMessage id="influencer.card.stats.age" />
          </b>
        </h2>
        <PercentageTable data={ageData} />
      </div>
      <div className="secondThreeFifthWidth centerContent bottomBorder leftBorder">
        <h2>
          <b>
            <FormattedMessage id="influencer.card.stats.country" />
          </b>
        </h2>
        <PercentageTable data={countryData} />
      </div>
      <div className="DemographicsTab__upper-icons firstHalfWidth centerContent bottomBorder">
        <MaleIcon isActive />
        <FormattedNumber type="percentage" value={menPercentage} fractionDigits={1} />
      </div>
      <div className="DemographicsTab__upper-icons secondHalfWidth centerContent bottomBorder leftBorder">
        <FemaleIcon isActive />
        <FormattedNumber type="percentage" value={womenPercentage} fractionDigits={1} />
      </div>
      <div className="DemographicsTab__lower-icons firstThirdWidth centerContent">
        <AndroidIcon isActive />
        <FormattedNumber type="percentage" value={androidPercentage} />
      </div>
      <div className="DemographicsTab__lower-icons secondThirdWidth centerContent leftBorder">
        <AppleIcon isActive />
        <FormattedNumber type="percentage" value={iosPercentage} />
      </div>
      <div className="DemographicsTab__lower-icons thirdThirdWidth centerContent leftBorder">
        <LaptopIcon isActive />
        <FormattedNumber
          type="percentage"
          value={computerPercentage || calculatedComputerPercentage}
          defaultToZero
        />
      </div>
    </div>
  );
};

export default DemographicsTab;
