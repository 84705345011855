// @flow
import Immutable from 'immutable';
import find from 'lodash/find';
import moment from 'moment';
import remove from 'lodash/remove';

import {
  INFLUENCER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_DECLINED,
  PUBLISHER_WITHDREW
} from '@sharkpunch/matchmade-common/campaignAgreement';

import {
  acceptCampaign,
  createCampaign,
  declineCampaign,
  fetchCampaign,
  fetchCampaignAnalytics,
  fetchCampaignEstimates,
  fetchCampaignInfluencerStats,
  fetchCampaignOverallStats,
  fetchCampaignStatistics,
  fetchCampaigns,
  negotiateCampaign,
  updateCampaign
} from '../../actions/campaign';
import type { ReducerCreator } from '../../types/action.flow';

function generateFillerData(startTimestamp, endTimestamp) {
  let result = [];
  const startDate = moment.utc(startTimestamp);
  const endDate = moment.utc(endTimestamp);
  while (startDate.isBefore(endDate, 'hour')) {
    result.push({
      timestamp: startDate.format(),
      viewerCount: 0,
      gameId: ''
    });
    startDate.add(1, 'hour');
  }
  return result;
}

const updateInfluencerAfterNegotiateOrAcceptCall = (state: any, action: any) => {
  // All this dance here is just because we store campaigns
  // as part of influencer object

  let influencer = state.get ? state.get('influencer') : { ...state.influencer };
  if (!influencer) return state;
  influencer = influencer.toJS ? influencer.toJS() : influencer;
  const campaignAgreement = action.payload;
  const campaigns = influencer.campaigns || [];
  let availableCampaigns = influencer.availableCampaigns || [];

  let agreementForNewCampaign = find(availableCampaigns, {
    id: campaignAgreement.campaignId
  });
  const agreementForExistingCampaign = find(campaigns, {
    id: campaignAgreement.campaignId
  });

  if (agreementForNewCampaign) {
    agreementForNewCampaign.agreement = campaignAgreement;
    campaigns.unshift(agreementForNewCampaign);
    remove(availableCampaigns, campaign => {
      return campaignAgreement.campaignId === campaign.id;
    });
  } else if (agreementForExistingCampaign) {
    // This updated existing campaign; if it's decline or withdrawal, we need
    // to move card back to availableCampaigns and delete agreement
    // (since backend filters those out)
    if (
      [INFLUENCER_DECLINED, PUBLISHER_DECLINED, INFLUENCER_WITHDREW, PUBLISHER_WITHDREW].indexOf(
        campaignAgreement.status
      ) !== -1
    ) {
      remove(campaigns, campaign => {
        return campaign.id === campaignAgreement.campaignId;
      });
      agreementForExistingCampaign.agreement = null;
      availableCampaigns.push(agreementForExistingCampaign);
    } else {
      // ...else just update campaign agreement
      agreementForExistingCampaign.agreement = campaignAgreement;
    }
  }

  influencer.campaigns = campaigns;
  influencer.availableCampaigns = availableCampaigns;

  return state.set
    ? state.set('influencer', Immutable.fromJS(influencer))
    : {
        ...state,
        influencer
      };
};

export default {
  fetchCampaign(state: any, action: any) {
    switch (action.type) {
      case fetchCampaign.REQUEST:
        return state.set('isLoadingCampaign', true).set('campaign', null);
      case fetchCampaign.SUCCESS:
        return state
          .set('isLoadingCampaign', false)
          .set('campaign', Immutable.fromJS(action.payload));
      case fetchCampaign.FAILURE:
        return state.set('isLoadingCampaign', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  // DEPRECATED
  // @Tan 2020-05-04 there is a local version in messagesPage reducer
  // move it to this file if we need to reuse the logic
  fetchCampaignEstimates(state: any, action: any) {
    switch (action.type) {
      case fetchCampaignEstimates.REQUEST:
        return state.set('isLoadingCampaignEstimates', true).set('campaignEstimates', null);
      case fetchCampaignEstimates.SUCCESS:
        return state
          .set('isLoadingCampaignEstimates', false)
          .set('campaignEstimates', Immutable.fromJS(action.payload));
      case fetchCampaignEstimates.FAILURE:
        return state
          .set('isLoadingCampaignEstimates', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchCampaigns(state: any, action: any) {
    switch (action.type) {
      case fetchCampaigns.REQUEST:
        return state.set('isLoadingCampaigns', true).set('campaigns', null);
      case fetchCampaigns.SUCCESS:
        return state
          .set('isLoadingCampaigns', false)
          .set('campaigns', Immutable.fromJS(action.payload));
      case fetchCampaigns.FAILURE:
        return state
          .set('isLoadingCampaigns', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  // TODO refactor creating/created to negotiating/negotiated
  // to match with the action name
  negotiateCampaign(state: any, action: any) {
    switch (action.type) {
      case negotiateCampaign.REQUEST:
        return state
          .set('isNegotiatingCampaign', true)
          .set('error', null)
          .set('creatingCampaignAgreement', Immutable.fromJS(action.payload));
      case negotiateCampaign.SUCCESS:
        return updateInfluencerAfterNegotiateOrAcceptCall(state, action)
          .set('isNegotiatingCampaign', false)
          .set('creatingCampaignAgreement', null)
          .set('createdCampaignAgreement', Immutable.fromJS(action.payload));
      case negotiateCampaign.FAILURE:
        return state
          .set('isNegotiatingCampaign', false)
          .set('creatingCampaignAgreement', null)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  acceptCampaign(state: any, action: any) {
    switch (action.type) {
      case acceptCampaign.REQUEST:
        return state
          .set('isAcceptingCampaign', true)
          .set('error', null)
          .set('acceptingCampaignAgreement', Immutable.fromJS(action.payload));
      case acceptCampaign.SUCCESS:
        return updateInfluencerAfterNegotiateOrAcceptCall(state, action)
          .set('isAcceptingCampaign', false)
          .set('acceptingCampaignAgreement', null)
          .set('acceptedCampaignAgreement', Immutable.fromJS(action.payload));
      case acceptCampaign.FAILURE:
        return state
          .set('isAcceptingCampaign', false)
          .set('acceptingCampaignAgreement', null)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  declineCampaign(state: any, action: any) {
    switch (action.type) {
      case declineCampaign.REQUEST:
        return state
          .set('isDecliningCampaign', true)
          .set('error', null)
          .set('decliningCampaignAgreement', Immutable.fromJS(action.payload));
      case declineCampaign.SUCCESS:
        return updateInfluencerAfterNegotiateOrAcceptCall(state, action)
          .set('isDecliningCampaign', false)
          .set('decliningCampaignAgreement', null);
      case declineCampaign.FAILURE:
        return state
          .set('isDecliningCampaign', false)
          .set('decliningCampaignAgreement', null)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchCampaignAnalytics(state: any, action: any) {
    switch (action.type) {
      case fetchCampaignAnalytics.REQUEST:
        return state.set('isFetchingCampaignAnalytics', true);
      case fetchCampaignAnalytics.SUCCESS:
        const hasPoints = action.payload && action.payload.points;
        if (!hasPoints) {
          return state
            .set('isFetchingCampaignAnalytics', false)
            .set('campaignAnalytics', Immutable.fromJS(action.payload));
        }
        const { startDate, endDate } = action.meta.loadingPayload;

        const data = action.payload.points;
        // We need to generate some placeholder data for graph
        // We do it here, since backend provides only ES entries
        // that matched our query, but we need to display graph
        // from startDate to endDate
        const dataStartTimestamp = (data[0] || {}).timestamp || endDate;
        const dataEndTimestamp = (data[data.length - 1] || {}).timestamp || endDate;

        const startDateFillerData = generateFillerData(startDate, dataStartTimestamp);
        const endDateFillerData = generateFillerData(dataEndTimestamp, endDate);

        return state.set('isFetchingCampaignAnalytics', false).set(
          'campaignAnalytics',
          Immutable.fromJS({
            points: startDateFillerData.concat(data).concat(endDateFillerData),
            aggs: action.payload.aggs
          })
        );
      case fetchCampaignAnalytics.FAILURE:
        return state
          .set('isFetchingCampaignAnalytics', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchCampaignOverallStats(state: any, action: any) {
    switch (action.type) {
      case fetchCampaignOverallStats.REQUEST:
        return state.set('isFetchingCampaignOverallStats', true);
      case fetchCampaignOverallStats.SUCCESS:
        return state
          .set('isFetchingCampaignOverallStats', false)
          .set('campaignOverallStats', Immutable.fromJS(action.payload));
      case fetchCampaignOverallStats.FAILURE:
        return state
          .set('isFetchingCampaignOverallStats', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchCampaignStatistics(state: any, action: any) {
    switch (action.type) {
      case fetchCampaignStatistics.REQUEST:
        return state.set('isFetchingCampaignStatistics', true);
      case fetchCampaignStatistics.SUCCESS:
        return state
          .set('isFetchingCampaignStatistics', false)
          .set('campaignStatistics', Immutable.fromJS(action.payload));
      case fetchCampaignStatistics.FAILURE:
        return state
          .set('isFetchingCampaignStatistics', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  fetchCampaignInfluencerStats(state: any, action: any) {
    switch (action.type) {
      case fetchCampaignInfluencerStats.REQUEST:
        return state.set('isFetchingCampaignInfluencerStats', true);
      case fetchCampaignInfluencerStats.SUCCESS:
        return state
          .set('isFetchingCampaignInfluencerStats', false)
          .set('campaignInfluencerStats', Immutable.fromJS(action.payload));
      case fetchCampaignInfluencerStats.FAILURE:
        return state
          .set('isFetchingCampaignInfluencerStats', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  updateCampaign(state: any, action: any) {
    switch (action.type) {
      case updateCampaign.REQUEST: {
        const id = action.payload.id;
        let campaigns = state.get('campaigns');

        if (campaigns) {
          const index = campaigns.findIndex((v, k) => v.get('id') === id);
          campaigns = campaigns.update(index, v => v.set('updating', true));
          state = state.set('campaigns', campaigns);
        }
        return state.set('isUpdatingCampaign', true);
      }
      case updateCampaign.SUCCESS: {
        const updatedCampaign = Immutable.fromJS(
          Object.assign({ updating: false }, action.payload)
        );
        const id = updatedCampaign.get('id');
        let campaigns = state.get('campaigns');

        if (campaigns) {
          campaigns = Immutable.fromJS(campaigns);

          let index = campaigns.findIndex((v, k) => v.get('id') === id);
          campaigns = campaigns.update(index, v => v.merge(updatedCampaign));

          state = state.set('campaigns', campaigns);
        }

        return state.set('isUpdatingCampaign', false).set('updatedCampaign', updatedCampaign);
      }
      case updateCampaign.FAILURE:
        return state
          .set('isUpdatingCampaign', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  createCampaign(state: any, action: any) {
    switch (action.type) {
      case createCampaign.REQUEST:
        return state.set('isCreatingNewCampaign', true);
      case createCampaign.SUCCESS:
        const campaign = Immutable.fromJS(action.payload);

        let campaigns = state.get('campaigns');
        if (campaigns) {
          campaigns = Immutable.fromJS(campaigns);
          if (
            campaigns.findIndex((value, index) => {
              return value.get('id') === campaign.get('id');
            }) === -1
          ) {
            campaigns = campaigns.push(campaign);
            state = state.set('campaigns', campaigns);
          }
        }

        return state.set('isCreatingNewCampaign', false).set('createdCampaign', campaign);
      case createCampaign.FAILURE:
        return state
          .set('isCreatingNewCampaign', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};

export type CreateCampaignActionAtributes = {
  isCreatingNewCampaign: boolean,
  error: Object | null,
  createdCampaign: any // TODO put proper type here
};
export const handleCreateCampaignActions: ReducerCreator<
  void,
  CreateCampaignActionAtributes
> = () => {
  return (state, action) => {
    switch (action.type) {
      case createCampaign.REQUEST:
        return {
          ...state,
          isCreatingNewCampaign: true
        };
      case createCampaign.SUCCESS:
        return {
          ...state,
          isCreatingNewCampaign: false,
          createdCampaign: action.payload
        };
      case createCampaign.FAILURE:
        return {
          ...state,
          isCreatingNewCampaign: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};
