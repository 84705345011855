// @flow
import React from 'react';

import Icon from '../Icon';
import MTurkEmailSvg from '../../../images/icons/MTurkEmailIcon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const MTurkEmailIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="MTurkEmailIcon">
      {() => <MTurkEmailSvg />}
    </Icon>
  );
};

export default MTurkEmailIcon;
