// @flow
import React from 'react';

import Icon from '../Icon';
import YoutubeSvg from '../../../images/icons/YoutubeIcon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const YoutubeIcon = (props: IconProps) => {
  return (
    <Icon {...props} className="YoutubeIcon">
      {() => <YoutubeSvg />}
    </Icon>
  );
};

export default YoutubeIcon;
