// @flow

import React from 'react';

import { FormattedMessage } from 'react-intl';

import Tooltip from '../common/Tooltip';
import compose from '../../hoc/compose';
import injectRoleChecking, { type ExtraProps } from '../../hoc/injectRoleChecking';

import './CampaignInfoFooter.scss';

const CampaignInfoFooter = (props: ExtraProps) => {
  const text = props.isPublisherOrWhitelabelAdmin
    ? 'chat.campaignInfo.footerTextForPublisher'
    : 'chat.campaignInfo.footerTextForInfluencer';

  const tooltipContent = (
    <div className="CampaignInfoFooter__tooltip">
      <FormattedMessage id={text} tagName="p" />
    </div>
  );

  return (
    <div className="CampaignInfoFooter">
      <div className="CampaignInfoFooter__text">
        <Tooltip tooltip={tooltipContent}>
          <strong>
            <FormattedMessage id="chat.campaignInfo.footerTitle" />
          </strong>
        </Tooltip>
      </div>
    </div>
  );
};

export default compose(injectRoleChecking())(CampaignInfoFooter);
