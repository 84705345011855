import get from 'lodash/get';

export default function (key) {
  return function (state, ownProps) {
    const subState = get(state, key, {});
    // TODO: Remove this once we get rid of `Immutable.js` in reducers
    if (subState.toJS) return subState.toJS();
    return subState;
  };
}
