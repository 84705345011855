// @flow
import { authorizedFetch } from '../helpers/api';
import type { ApiResponse } from '../types/api/response.flow';
import type { GetCurrentSocialMediaConnectionData } from '../types/api/connections.flow';
import type { SocialMediaConnectionIn } from '../types/connection.flow';

export function getAvailableChannelsToConnect(
  connectionData: GetCurrentSocialMediaConnectionData[]
): SocialMediaConnectionIn[] {
  let available: SocialMediaConnectionIn[] = [];
  for (const entry of connectionData) {
    if (!entry.availableChannels.length) {
      continue;
    }

    available = available.concat(
      entry.availableChannels.map(({ channel }) => {
        return {
          connection: entry.connection,
          channel
        };
      })
    );
  }
  return available;
}

export function getConnectedChannels(connectionData: GetCurrentSocialMediaConnectionData[]) {
  let connected: SocialMediaConnectionIn[] = [];
  for (const entry of connectionData) {
    if (!entry.connectedChannels.length) {
      continue;
    }

    connected = connected.concat(
      entry.connectedChannels.map(({ channel }) => {
        return {
          connection: entry.connection,
          channel
        };
      })
    );
  }
  return connected;
}

export async function getCurrentSocialMediaConnections(): Promise<
  ApiResponse<GetCurrentSocialMediaConnectionData>
> {
  const res = await authorizedFetch('/connections/social-media');
  const connectionData = await res.json();
  if (res.status >= 400 && connectionData && connectionData.error) {
    connectionData.error.requestId = res.headers.get('request-id') || null;
  }
  return connectionData;
}
