// @flow
import type { Action } from '../../types/action.flow';
import type { PublisherPaymentInfo } from '../../types/paymentInfo.flow';

import { fetchPublisherPaymentInfo, updatePublisherPaymentInfo } from '../../actions/paymentInfo';

export const handleGetPublisherPaymentInfo = (state: State, action: Action) => {
  switch (action.type) {
    case fetchPublisherPaymentInfo.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case fetchPublisherPaymentInfo.SUCCESS:
      return {
        ...state,
        isLoading: false,
        publisherPaymentInfo: action.payload
      };

    case fetchPublisherPaymentInfo.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export const handleUpdatePublisherPaymentInfo = (state: State, action: Action) => {
  switch (action.type) {
    case updatePublisherPaymentInfo.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case updatePublisherPaymentInfo.SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false
      };

    case updatePublisherPaymentInfo.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

type SharedState = {
  isLoading: boolean,
  error: ?Object,
  success: boolean
};

export type PublisherPaymentInfoState = SharedState & {
  publisherPaymentInfo: ?PublisherPaymentInfo
};

export type State = SharedState & {
  publisherPaymentInfo: ?PublisherPaymentInfo
};

const sharedState: SharedState = {
  isLoading: false,
  error: null,
  success: false
};

export const publisherPaymentInfoInitialstate: PublisherPaymentInfoState = {
  ...sharedState,
  publisherPaymentInfo: null
};

export const initialState: State = Object.freeze({
  ...sharedState,
  publisherPaymentInfo: null
});

export default function (state: State = initialState, action: Action): State {
  // always reset the state
  let newState = {
    ...state,
    error: null,
    success: false
  };

  newState = handleGetPublisherPaymentInfo(newState, action);
  newState = handleUpdatePublisherPaymentInfo(newState, action);

  return newState;
}
