// @flow
import * as React from 'react';
import type { CurrencyOnChange } from '../campaignCreation/common/CentToDollarInput';

import CentToDollarInput from '../campaignCreation/common/CentToDollarInput';
import classNames from 'classnames';

import './MonetaryControlInput.scss';

type Props = {
  allowDecimal?: boolean,
  isInverted?: boolean,
  isDisabled?: boolean,
  isError?: boolean,
  placeholder?: string,
  size?: 'small' | null, // null is the default size

  value: number | '', // cents
  onChange: CurrencyOnChange
};

export default function MonetaryControlInput(props: Props) {
  const {
    size = null,
    isInverted = false,
    isDisabled = false,
    isError = false,
    placeholder = '',
    onChange,
    value,
    allowDecimal = true
  } = props;
  const pClassName = classNames('MonetaryControlInput control has-icons-left', {
    'is-small': size === 'small'
  });

  const inputClassName = classNames('', {
    'is-inverted': isInverted,
    'is-warning': isError
  });

  return (
    <p className={pClassName}>
      <CentToDollarInput
        isDisabled={isDisabled}
        className={inputClassName}
        allowDecimal={allowDecimal}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <span className="icon is-left">
        <i className="material-icons">attach_money</i>
      </span>
    </p>
  );
}
