import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import mapValues from 'lodash/mapValues';

function recursivelyConvertImmutableToJS(obj) {
  return mapValues(obj, value => {
    if (!value) return value;

    if (value.toJS) return value.toJS();

    if (Array.isArray(value)) {
      return value.map(recursivelyConvertImmutableToJS);
    } else if (typeof value === 'object') {
      return recursivelyConvertImmutableToJS(value);
    }

    return value;
  });
}

// TODO: smartly merge error here so that substates don't overwrite
// each other errors
export default function pickState(state, ...keys) {
  const subStates = flow([
    map(key => get(key)(state)),
    compact,
    map(subState => {
      if (subState.toJS) return subState.toJS();
      // TODO: because we are mixing Immutable and normal JS objects,
      // sometimes the state itself is a JS object but it contains Immutable objects
      // This will eventually go away when we get rid of Immutable
      return recursivelyConvertImmutableToJS(subState);
    })
  ])(keys);

  return Object.assign.apply(Object, [{}].concat(subStates));
}
