// @flow
import * as React from 'react';

import type { ContentPlatform } from 'matchmade-types';

import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import InfoTooltip from '../../common/InfoTooltip';
import Tooltip from '../../common/Tooltip';

import InstagramIcon from '../../../images/icons/InstagramIcon';

import HorizontalLogoTwitch from '../../../images/icons/HorizontalLogoTwitch';
import HorizontalLogoYoutube from '../../../images/icons/HorizontalLogoYoutube';

import './ContentPlatforms.scss';

type Props = {
  values: ContentPlatform[],
  onChange: (values: ContentPlatform[]) => void,
  isDisabled: boolean
};

ContentPlatforms.defaultProps = {
  isDisabled: false
};

export default function ContentPlatforms(props: Props) {
  function getOptions(): { value: ContentPlatform, disabled: boolean, label: React.Element<*> }[] {
    return [
      {
        value: 'youtube',
        disabled: false,
        label: <HorizontalLogoYoutube />
      },
      {
        value: 'twitch',
        disabled: false,
        label: <HorizontalLogoTwitch />
      },
      {
        value: 'instagram',
        disabled: false,
        label: <InstagramIcon />
      }
    ];
  }

  const platformElements = getOptions().map(({ value, disabled, label }) => {
    const selected = props.values.indexOf(value) !== -1;

    const isDisabled = disabled || props.isDisabled;

    const className = classNames('button', {
      ContentPlatforms__platform: true,
      'is-primary': selected,
      'ContentPlatforms__platform--selected': selected,
      'is-disabled': isDisabled
    });

    // For now we support only one platform per campaign
    const onClick = isDisabled || selected ? null : () => props.onChange([value]);

    return (
      <p className="control is-expanded" key={value}>
        <Tooltip
          key={value}
          tooltip={
            disabled ? (
              <FormattedMessage id="campaignCreation.campaignDescription.platform.notSupported" />
            ) : null
          }>
          <button onClick={onClick} className={className}>
            {label}
          </button>
        </Tooltip>
      </p>
    );
  });

  return (
    <div className="ContentPlatforms field is-horizontal">
      <div className="field-label">
        <label className="label">
          <FormattedMessage id="campaignCreation.campaignDescription.platform" />
        </label>
        <InfoTooltip>
          <FormattedMessage id="campaignCreation.campaignDescription.platform.tooltip" />
        </InfoTooltip>
      </div>
      <div className="field-body">
        <div className="field is-grouped ContentPlatforms__platforms">{platformElements}</div>
      </div>
    </div>
  );
}
