// @flow

import * as React from 'react';

import type { AttributionPartnerOption } from './attributionPartners/AttributionPartnerOption';
import type { GamePlatform, PlatformSpecificTrackingCodes } from 'matchmade-types';

import SingleValueSelection from '../common/SingleValueSelection';

import { services } from '../../../helpers/attributionPartners';

import Adjust from './attributionPartners/Adjust';
import AppsFlyer from './attributionPartners/AppsFlyer';
import BuffPanel from './attributionPartners/BuffPanel';
import Kochava from './attributionPartners/Kochava';
import MatchmadeLink from './attributionPartners/MatchmadeLink';
import MatchmadePixel from './attributionPartners/MatchmadePixel';
import None from './attributionPartners/None';
import Other from './attributionPartners/Other';
import Singular from './attributionPartners/Singular';
import Tenjin from './attributionPartners/Tenjin';
import Tune from './attributionPartners/Tune';

import './InstallAttribution.scss';

export type Props = {
  platforms: GamePlatform[],

  attributionPartner: string,
  trackingCode: string | null,
  platformSpecificTrackingCodes: PlatformSpecificTrackingCodes | null,
  selectedOption: AttributionPartnerOption,

  isDisabled?: boolean,
  isInverted?: boolean,

  onChangeAttributionPartner: (value: string) => void,
  onChangeTrackingCode: (value: string) => void,
  onChangePlatformSpecificTrackingCodes: (value: PlatformSpecificTrackingCodes) => void,
  onChangeOption: (opt: AttributionPartnerOption) => void
};

const serviceComponents = {
  adjust: Adjust,
  appsflyer: AppsFlyer,
  buffpanel: BuffPanel,
  kochava: Kochava,
  tenjin: Tenjin,
  tune: Tune,
  singular: Singular,
  'matchmade-link': MatchmadeLink,
  'matchmade-pixel': MatchmadePixel,
  other: Other,
  none: None
};

const options = services.map(({ value, label }) => {
  return { value, label };
});

export function renderPartnerSelection(props: Props) {
  const selected = options.find(({ value }) => props.attributionPartner === value);

  return (
    <div className="InstallAttribution__partner-selector">
      <SingleValueSelection
        isInverted={props.isInverted}
        isDisabled={props.isDisabled}
        options={options}
        value={selected}
        onChange={e => props.onChangeAttributionPartner(e && e.value)}
      />
    </div>
  );
}

export function renderSelectedPartnerSettings(props: Props) {
  const Service = serviceComponents[props.attributionPartner];

  if (!Service) {
    return null;
  }

  const serviceProps = {
    trackingCode: props.trackingCode,
    platformSpecificTrackingCodes: props.platformSpecificTrackingCodes || {},
    selectedOption: props.selectedOption,

    onChangeTrackingCode: props.onChangeTrackingCode,
    onChangePlatformSpecificTrackingCodes: props.onChangePlatformSpecificTrackingCodes,
    onChangeOption: props.onChangeOption,

    showPlatformSpecificSettings: props.platforms.length > 1,

    isDisabled: props.isDisabled,
    isInverted: props.isInverted
  };

  return <Service {...serviceProps} />;
}

class InstallAttribution extends React.PureComponent<Props> {
  static defaultProps = {
    isDisabled: false,
    isInverted: false
  };

  render() {
    return (
      <div className="field InstallAttribution">
        {renderPartnerSelection(this.props)}
        {renderSelectedPartnerSettings(this.props)}
      </div>
    );
  }
}

export default InstallAttribution;
