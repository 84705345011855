// @flow

import * as React from 'react';
import classNames from 'classnames';

import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';
import Select from '../../common/Select';

import type { Country } from '../../../types/campaign.flow';

import isEqual from 'lodash/isEqual';

const countryOptions = Object.entries(countryCodes)
  .map(([code, name]) => {
    return { code, name };
  })
  .sort((a: any, b: any) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

type Props = {
  selected: string | null, // alpha-2
  excluded: string[], // alpha-2
  removeSelected: boolean,
  onChange: (country: string) => void, // alpha2
  placeholder?: ?string,
  isInverted: boolean,
  disabled: boolean
};

export default class SingleCountrySelector extends React.Component<Props, {}> {
  static defaultProps = {
    excluded: [],
    isInverted: false,
    disabled: false,
    removeSelected: false
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.selected !== nextProps.selected ||
      !isEqual(this.props.excluded, nextProps.excluded)
    );
  }

  onChange = (country: Country) => {
    this.props.onChange(country.code);
  };

  render() {
    const availableCountries = countryOptions.filter(country => {
      if (!country.code) return false;

      if (this.props.excluded.indexOf(country.code) !== -1) return false;

      const selected = this.props.selected === country.code;
      if (!selected || !this.props.removeSelected) return true;
      return false;
    });

    const selected = countryOptions.find(({ code }) => this.props.selected === code);

    const className = classNames('SingleCountrySelector', {
      'SingleCountrySelector--inverted': this.props.isInverted
    });

    return (
      <div className={className}>
        <Select
          disabled={this.props.disabled}
          options={availableCountries}
          valueKey="code"
          labelKey="name"
          value={selected}
          placeholder={this.props.placeholder || 'Select country'}
          onChange={this.onChange}
          clearable={false}
          backspaceRemoves={false}
          removeSelected={this.props.removeSelected}
          isInverted={this.props.isInverted}
        />
      </div>
    );
  }
}
