// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const TagIcon = (props: IconProps) => {
  return <Icon {...props}>{() => <i className="material-icons">tag</i>}</Icon>;
};

export default TagIcon;
