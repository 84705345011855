import Immutable from 'immutable';

import account from '../common/account';
import campaign from '../common/campaign';
import campaignAgreement from '../common/campaignAgreement';
import collection from '../common/collection';
import email from '../common/email';
import influencer from '../common/influencer';
import manager from '../common/manager';
import messages from '../common/messages';
import publisher from '../common/publisher';
import team from '../common/team';

import { resetProps } from '../../actions/common';

import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

const SHARED_REDUCERS = {
  account,
  campaign,
  campaignAgreement,
  email,
  influencer,
  publisher,
  manager,
  team,
  collection,
  messages
};

export default function (
  initialState = {},
  cleanUpState = {},
  sharedReducers = [],
  containerReducer
) {
  containerReducer =
    containerReducer ||
    function (state, action) {
      return state;
    };

  const InitialState = Immutable.Record(initialState);

  return function (state = new InitialState(), action) {
    forEach(cleanUpState, (value, key) => {
      state = state.set(key, value);
    });

    for (let i in sharedReducers) {
      if (sharedReducers.hasOwnProperty(i)) {
        const sharedReducer = get(SHARED_REDUCERS, sharedReducers[i]);

        if (!sharedReducer) throw new Error(`Shared reducer ${sharedReducers[i]} is not defined`);

        if (sharedReducer && isFunction(sharedReducer)) {
          state = sharedReducer(state, action);
        }
      }
    }

    switch (action.type) {
      case resetProps.SUCCESS:
        state = new InitialState();
        break;
      default:
        break;
    }

    return containerReducer(state, action);
  };
}
