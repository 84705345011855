// @flow
import * as React from 'react';

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import type { ContentPlatform as ContentPlatformType } from 'matchmade-types';
import type { IntlShape } from 'react-intl';

// $FlowFixMe
import { Bar as BarChart } from 'react-chartjs-2';
import { toHumanReadableNumber } from '@sharkpunch/matchmade-common/formatters';

import { checkIfInfluencerOrManager, checkIfWhitelabelAdmin } from '../../helpers/user';
import BarChartIcon from '../common/Icons/BarChartIcon';
import ButtonWithLabel from '../common/ButtonWithLabel';
import DateRangePicker from '../common/DateRangePicker';
import FormattedNumber from '../common/FormattedNumber';
import OnOffToggle from '../common/OnOffToggle';
import Spinner from '../common/Spinner';
import Tooltip from '../common/Tooltip';

import * as formatters from '@sharkpunch/matchmade-common/formatters';

import moment from 'moment';

import { FormattedMessage, injectIntl } from 'react-intl';

import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { hexToRgba } from '@sharkpunch/matchmade-common/color';

import { getColorVariables } from '../../config';

import isEqual from 'lodash/isEqual';

// $FlowFixMe
import { useAsync } from 'react-async';
import ActionableNotification from '../../components/common/ActionableNotification';
import { CsvUpload } from '../../helpers/CsvUpload';
import callApi from '../../helpers/api';
import config from '../../config';
import classNames from 'classnames';
import Table, { Column } from '../../components/tables/Table';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';

import './CampaignKpiChart.scss';

type AnalyticsData = {
  clicks: number,
  cost: number,
  date: string,
  installs: number,
  payout: number,
  views: number
};

type Props = {
  campaignId: string,
  analytics: AnalyticsData[],
  isLoading: boolean,
  // TODO change start/end date to string and construct moment objects internally
  startDate: moment$Moment,
  endDate: moment$Moment,
  // TODO change start/end date to string and let the receiver construct the object
  onChangeDate: (startDate: moment$Moment, endDate: moment$Moment) => void,
  messagePrefix?: string,
  contentPlatform: ContentPlatformType,
  intl: IntlShape
};

const variables = getColorVariables();

function sumAnalyticValueByKey(
  analytics: AnalyticsData[],
  key: 'clicks' | 'cost' | 'installs' | 'payout' | 'views'
): number {
  return (analytics || [])
    .map(row => {
      return row[key];
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);
}

// TODO if this is slow, use _.memoize
function cumulateAnalytics(analytics: AnalyticsData[]) {
  return (analytics || []).reduce((everything, current, index) => {
    const prev = everything[index - 1];
    if (prev) {
      return everything.concat([
        {
          ...current,
          installs: current.installs + prev.installs,
          views: current.views + prev.views,
          clicks: current.clicks + prev.clicks,
          cost: current.cost + prev.cost,
          payout: current.payout + prev.payout
        }
      ]);
    }

    return everything.concat([current]);
  }, []);
}

function SummaryCards(props: {
  analytics: $PropertyType<Props, 'analytics'>,
  isLoading: $PropertyType<Props, 'isLoading'>,
  messagePrefix?: $PropertyType<Props, 'messagePrefix'>,
  startDate: $PropertyType<Props, 'startDate'>,
  endDate: $PropertyType<Props, 'endDate'>,
  onAccept: Function,
  contentPlatform: ContentPlatformType,
  campaignId: string
}) {
  const { analytics, isLoading, onAccept, messagePrefix = '', contentPlatform } = props;
  const startDate = props.startDate.format('YYYY-MM-DD');
  const endDate = props.endDate.format('YYYY-MM-DD');
  const analyticsLink = `${window.location.pathname}/report?start=${startDate}&end=${endDate}`;

  if (isLoading) {
    // need to have those empty cards to not blow the layout
    return (
      <div className="CampaignKpiChart__summary">
        <div className="card CampaignKpiChart--invisible-card" />
        <div className="card CampaignKpiChart--invisible-card" />
        <div className="card CampaignKpiChart--invisible-card" />
        <div className="card CampaignKpiChart--invisible-card" />
      </div>
    );
  }

  const installs = sumAnalyticValueByKey(analytics, 'installs');

  // WITH commission for publishers/admins, WITHOUT commission for influencers
  const cost = sumAnalyticValueByKey(analytics, 'cost') / 100;

  // always WITHOUT commission, currently only shown for admins
  const payout = sumAnalyticValueByKey(analytics, 'payout') / 100;

  const clicks = sumAnalyticValueByKey(analytics, 'clicks') || 1; // prevent Infinity

  let conversionRate = installs / clicks;
  if (isNaN(conversionRate)) conversionRate = 0;

  let center = <strong>{formatters.costFormatter.format(cost)}</strong>;

  if (cost > 0 && checkIfWhitelabelAdmin()) {
    center = (
      <Tooltip
        tooltip={
          <div>
            <div>
              {formatters.costFormatter.format(cost)}
              &nbsp;
              <FormattedMessage id="campaign.kpi.chart.cost.summary" />
            </div>
            <div>
              {formatters.costFormatter.format(payout)}
              &nbsp;
              <FormattedMessage id="campaign.kpi.chart.payout.summary" />
            </div>
          </div>
        }>
        {center}
      </Tooltip>
    );
  }

  return (
    <div className="CampaignKpiChart__summary">
      <div className="CampaignKpiChart__daterangepicker">
        <DateRangePicker
          startDate={moment(startDate).utc()}
          endDate={moment(endDate).utc()}
          isLoading={isLoading}
          onAccept={onAccept}
        />
        {!checkIfInfluencerOrManager() && contentPlatform !== ContentPlatform.INSTAGRAM && (
          <div className="is-flex is-align-items-center is-justify-content-space-between is-flex-grow-1">
            <ButtonWithLabel
              to={analyticsLink}
              icon={<BarChartIcon className="button is-primary is-rounded" />}>
              <FormattedMessage id="campaign.report" tagName="strong" />
            </ButtonWithLabel>
            <UploadManuallyUploadedDailyCampaignEvents
              upload={updateManuallyUploadedDailyCampaignEventsCall}
              campaignId={props.campaignId}
            />
          </div>
        )}
      </div>
      <div className="CampaignKpiChart__summary-metrics level has-text-centered">
        <div className="level-item CampaignKpiChart__summary-text">
          <strong>
            <FormattedNumber type="number" value={installs} defaultToZero />
          </strong>
          <FormattedMessage
            id={`${messagePrefix}campaign.kpi.chart.install.summary`}
            values={{ installs: installs }}
          />
        </div>
        <div className="level-item CampaignKpiChart__summary-text">
          {center}
          <FormattedMessage id={`${messagePrefix}campaign.kpi.chart.cost.summary`} />
        </div>
        <div className="level-item CampaignKpiChart__summary-text">
          <strong>
            <FormattedNumber type="percentage" value={conversionRate} />
          </strong>
          <FormattedMessage id={`${messagePrefix}campaign.kpi.chart.conversion.summary`} />
        </div>
      </div>
    </div>
  );
}

type GroupingOptionType = 'daily' | 'hourly' | 'monthly';
const GroupingOption = {
  DAILY: 'daily',
  HOURLY: 'hourly',
  MONTHLY: 'monthly'
};

const ChartModeSelector = injectIntl(
  (props: {
    isCumulative: boolean,
    onToggleCumulative: (value: boolean) => void,

    groupingOptions: GroupingOptionType[],
    selectedGroupingOption: GroupingOptionType,
    onChangeGroupingOption: (value: GroupingOptionType) => void,

    intl: IntlShape
  }) => {
    const {
      isCumulative,
      onToggleCumulative,
      groupingOptions,
      selectedGroupingOption,
      onChangeGroupingOption,
      intl
    } = props;

    const groupingOptionSelector =
      groupingOptions.length > 1 ? (
        <div className="select">
          <select
            value={selectedGroupingOption}
            onChange={e => {
              onChangeGroupingOption(e.target.value);
            }}>
            {groupingOptions.map(value => {
              return (
                <option key={value} value={value}>
                  {intl.formatMessage({ id: `influencer.campaign.kpi.chart.${value}` })}
                </option>
              );
            })}
          </select>
        </div>
      ) : null;

    return (
      <div className="CampaignKpiChart__chart-selection">
        <div className="CampaignKpiChart__cumulative-checkbox">
          {groupingOptionSelector}
          <OnOffToggle
            isOn={isCumulative}
            onClick={() => {
              onToggleCumulative(!isCumulative);
            }}>
            <span>Cumulative</span>
          </OnOffToggle>
        </div>
      </div>
    );
  }
);

const Chart = injectIntl(function Chart(props: {
  analytics: $PropertyType<Props, 'analytics'>,
  isLoading: $PropertyType<Props, 'isLoading'>,
  messagePrefix?: $PropertyType<Props, 'messagePrefix'>,
  intl: IntlShape,
  chartModeSelector: React.Element<*> | null
}) {
  const { analytics, isLoading, intl, messagePrefix = '', chartModeSelector } = props;

  if (isLoading) return <Spinner mode="fullWidth" />;

  function generateChartLabels() {
    return (analytics || []).map(row => row['date']);
  }

  const chart = (
    <BarChart
      height={100}
      data={{
        labels: generateChartLabels(),
        datasets: [
          {
            type: 'line',
            label: intl.formatMessage({
              id: `${messagePrefix}campaign.kpi.chart.cost.label`
            }),
            data: map(analytics, 'cost'),

            backgroundColor: hexToRgba(variables['mm-navy']),
            borderColor: hexToRgba(variables['mm-navy']),
            pointBackgroundColor: hexToRgba(variables['mm-navy']),
            pointBorderColor: hexToRgba(variables['mm-navy']),
            tension: 0,
            fill: false,

            yAxisID: 'y-cost'
          },
          {
            type: 'bar',
            label: intl.formatMessage({
              id: `${messagePrefix}campaign.kpi.chart.view.label`
            }),
            data: map(analytics, 'views'),

            backgroundColor: hexToRgba(variables['mm-aqua']),
            borderColor: hexToRgba(variables['mm-aqua']),
            hoverBackgroundColor: hexToRgba(variables['mm-aqua']),
            hoverBorderColor: hexToRgba(variables['mm-aqua']),
            fill: false,

            yAxisID: 'y-views'
          },
          {
            type: 'bar',
            label: intl.formatMessage({
              id: `${messagePrefix}campaign.kpi.chart.install.label`
            }),
            data: map(analytics, 'installs'),

            backgroundColor: hexToRgba(variables['mm-light-aqua']),
            borderColor: hexToRgba(variables['mm-light-aqua']),
            hoverBackgroundColor: hexToRgba(variables['mm-light-aqua']),
            hoverBorderColor: hexToRgba(variables['mm-light-aqua']),
            fill: false,

            yAxisID: 'y-installs'
          }
        ]
      }}
      options={{
        responsive: true,
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              if (tooltipItem.datasetIndex === 0) {
                return `${intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.cost.label`
                })}: ${FormattedNumber.formatters.cost(tooltipItem.yLabel, {
                  defaultToZero: true
                })}`;
              } else if (tooltipItem.datasetIndex === 1) {
                return `${intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.view.label`
                })}: ${tooltipItem.yLabel}`;
              } else if (tooltipItem.datasetIndex === 2) {
                return `${intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.install.label`
                })}: ${tooltipItem.yLabel}`;
              }
            }
          }
        },
        elements: {
          line: {
            fill: false
          }
        },
        legend: {
          display: true,
          labels: {
            fontSize: 14,
            boxWidth: 60
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              ticks: {
                fontSize: 12,
                fontColor: hexToRgba(variables['mm-navy']),
                padding: 0
              },
              labels: generateChartLabels()
            }
          ],
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-cost',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              },
              ticks: {
                beginAtZero: true,
                fontSize: 12,
                fontColor: hexToRgba(variables['mm-navy']),
                padding: 0,
                callback: function (value) {
                  // Skip non-int labels, so we don't create 10 $0 labels, for instance
                  const v = parseInt(value, 10);
                  return v ? FormattedNumber.formatters.cost(v) : null;
                }
              },
              scaleLabel: {
                display: true,
                labelString: intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.cost.label`
                }),
                fontColor: hexToRgba(variables['mm-navy']),
                fontSize: 14,
                fontStyle: 'bold'
              }
            },
            {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-views',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              },
              ticks: {
                beginAtZero: true,
                fontSize: 12,
                fontColor: hexToRgba(variables['mm-navy']),
                padding: 0,
                callback: function (value, index, values) {
                  // Skip non-int labels, so we don't create 10 0-labels
                  const v = parseInt(value, 10);
                  return v === value ? toHumanReadableNumber(value, { defaultToZero: true }) : null;
                }
              },
              scaleLabel: {
                display: true,
                labelString: intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.view.label`
                }),
                fontColor: hexToRgba(variables['mm-aqua']),
                fontSize: 14,
                fontStyle: 'bold'
              }
            },
            {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-installs',
              gridLines: {
                display: false
              },
              labels: {
                show: true
              },
              ticks: {
                beginAtZero: true,
                fontSize: 12,
                fontColor: hexToRgba(variables['mm-navy']),
                padding: 0,
                callback: function (value, index, values) {
                  // Skip non-int labels, so we don't create 10 0-labels
                  const v = parseInt(value, 10);
                  return v === value ? toHumanReadableNumber(value, { defaultToZero: true }) : null;
                }
              },
              scaleLabel: {
                display: true,
                labelString: intl.formatMessage({
                  id: `${messagePrefix}campaign.kpi.chart.install.label`
                }),
                fontColor: hexToRgba(variables['mm-light-aqua']),
                fontSize: 14,
                fontStyle: 'bold'
              }
            }
          ]
        }
      }}
    />
  );

  return (
    <div className="CampaignKpiChart__chart">
      {chartModeSelector}
      {chart}
    </div>
  );
});

const DAY_DIFF_TO_GROUP_BY_HOUR = 3;
const DAY_DIFF_TO_GROUP_BY_MONTH = 60;

function getDefaultGroupingOption(startDate: moment$Moment, endDate: moment$Moment) {
  const dayDiff = endDate.diff(startDate, 'day');

  if (dayDiff <= DAY_DIFF_TO_GROUP_BY_HOUR) {
    return GroupingOption.HOURLY;
  }

  if (dayDiff >= DAY_DIFF_TO_GROUP_BY_MONTH) {
    return GroupingOption.MONTHLY;
  }

  return GroupingOption.DAILY;
}

function getAvailableGroupingOptions(startDate: moment$Moment, endDate: moment$Moment) {
  const dayDiff = endDate.diff(startDate, 'day');

  return [
    dayDiff <= DAY_DIFF_TO_GROUP_BY_HOUR && dayDiff > 0 ? GroupingOption.HOURLY : null,
    GroupingOption.DAILY,
    dayDiff >= DAY_DIFF_TO_GROUP_BY_MONTH ? GroupingOption.MONTHLY : null
  ].filter(Boolean);
}

function groupAnalyticsData(
  analytics: AnalyticsData[],
  groupingOption: GroupingOptionType
): AnalyticsData[] {
  const groups = groupBy(analytics || [], ({ date }) => {
    const momentDate = moment.utc(date);

    switch (groupingOption) {
      case GroupingOption.HOURLY:
        return momentDate.format('YYYY-MM-DDTHH:00:00.000') + 'Z';
      case GroupingOption.DAILY:
        return momentDate.format('YYYY-MM-DDT00:00:00.000') + 'Z';
      case GroupingOption.MONTHLY:
        return momentDate.format('YYYY-MM-01T00:00:00.000') + 'Z';
      default:
        return momentDate.format('YYYY-MM-DDT00:00:00.000') + 'Z';
    }
  });

  return Object.entries(groups).reduce((all, [key, values]: [string, any]) => {
    const analytics: AnalyticsData[] = values;

    return all.concat(
      analytics.reduce(
        (finalValue, currentValue) => {
          return {
            ...finalValue,
            clicks: finalValue.clicks + currentValue.clicks,
            cost: finalValue.cost + currentValue.cost,
            installs: finalValue.installs + currentValue.installs,
            payout: finalValue.payout + currentValue.payout,
            views: finalValue.views + currentValue.views
          };
        },
        {
          date: key,
          clicks: 0,
          cost: 0,
          installs: 0,
          payout: 0,
          views: 0
        }
      )
    );
  }, []);
}

function formatDateBasedOnGroupingOption(
  entry: AnalyticsData,
  groupingOption: GroupingOptionType
): AnalyticsData {
  const date = moment.utc(entry.date);
  switch (groupingOption) {
    case GroupingOption.HOURLY:
      return {
        ...entry,
        date: date.hours() === 0 ? date.format('YYYY-MM-DD') : date.format('HH:mm')
      };

    case GroupingOption.DAILY:
      return {
        ...entry,
        date: date.format('YYYY-MM-DD')
      };

    case GroupingOption.MONTHLY:
      return {
        ...entry,
        date: date.format('MMM YYYY')
      };

    default:
      return entry;
  }
}

function CampaignKpiChart(props: Props) {
  const {
    analytics = [],
    isLoading = true,
    startDate = moment.utc().subtract(6, 'days'),
    endDate = moment.utc(),
    onChangeDate = function (startDate, endDate) {},
    contentPlatform,
    messagePrefix = ''
  } = props;

  const [isCumulative, setIsCumulative] = React.useState(false);
  const [groupingOption, setGroupingOption] = React.useState(
    getDefaultGroupingOption(startDate, endDate)
  );

  function getAnalyticsValuesBasedOnGroupingOption() {
    const groupedAnalytics = groupAnalyticsData(analytics || [], groupingOption).map(entry => {
      return formatDateBasedOnGroupingOption(entry, groupingOption);
    });

    return isCumulative ? cumulateAnalytics(groupedAnalytics) : groupedAnalytics;
  }

  const propsWithDefaultValues = {
    campaignId: props.campaignId,
    analytics,
    isLoading,
    startDate,
    endDate,
    onChangeDate,
    messagePrefix,
    contentPlatform,
    onAccept: range => {
      const [startDate, endDate] = range;
      setGroupingOption(getDefaultGroupingOption(startDate, endDate));
      onChangeDate(startDate.utcOffset(0, true), endDate.utcOffset(0, true));
    }
  };

  return (
    <div className="CampaignKpiChart">
      <SummaryCards {...propsWithDefaultValues} />
      <Chart
        isLoading={isLoading}
        messagePrefix={messagePrefix}
        analytics={getAnalyticsValuesBasedOnGroupingOption()}
        chartModeSelector={
          <ChartModeSelector
            isCumulative={isCumulative}
            onToggleCumulative={setIsCumulative}
            selectedGroupingOption={groupingOption}
            onChangeGroupingOption={setGroupingOption}
            groupingOptions={getAvailableGroupingOptions(startDate, endDate)}
          />
        }
      />
    </div>
  );
}

async function getDealsForCampaign({ campaign_id }: { campaign_id: number }) {
  let allDeals = [];

  // To make sure this doesn't explode in our face when we have
  // thousands of items that match the query,
  // fetch them page by page.
  let pageEnd = false;
  const pageSize = 50;
  for (let offset = 0; !pageEnd; offset += pageSize) {
    const response = await callApi('/admin/deals', {
      query: { 'deal.campaign_id': `eq.${campaign_id}`, limit: pageSize, offset }
    });
    const deals = response.data;
    allDeals = deals && deals.length ? allDeals.concat(deals) : allDeals;
    pageEnd = deals && deals.length ? deals.length < pageSize : true;
  }

  return allDeals;
}

async function getManuallyUploadedDailyCampaignEventsForDealIds({
  dealIds
}: {
  dealIds: string[] | null
}) {
  if (!dealIds) return [];
  const res = await callApi('/admin/manually-uploaded-daily-campaign-events', {
    query: { deal_id: dealIds }
  });
  return res.data;
}
type ManuallyUploadedDailyCampaignEvent = {
  deal_id: number,
  date: Date,
  events_count: number,
  event_type: string
};
function UploadManuallyUploadedDailyCampaignEvents({
  campaignId,
  upload
}: {
  campaignId: string,
  upload: (
    manuallyUploadedDailyCampaignEvents: ManuallyUploadedDailyCampaignEvent[]
  ) => Promise<void>
}) {
  const [
    manuallyUploadedDailyCampaignEvents,
    setManuallyUploadedDailyCampaignEvents
  ] = React.useState([]);
  const [showReset, setShowReset] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState({});

  const resetFormData = () => {
    setManuallyUploadedDailyCampaignEvents([]);
    setMessage({});
  };

  const { isLoading: isLoadingDeals, data: deals, error: loadingDealsError } = useAsync({
    promiseFn: getDealsForCampaign,
    campaign_id: campaignId,
    onReject: e => {
      setShowReset(true);
      setMessage({
        type: 'danger',
        text: `Error loading deals for campaign: ${e.message || 'Unknown error'}`
      });
    }
  });

  const dealIds = !loadingDealsError && !isLoadingDeals ? deals.map(d => Number(d.deal.id)) : null;

  const {
    isLoading: isLoadingManuallyUploadedDailyCampaignEvents,
    data: existingManuallyUploadedDailyCampaignEvents
  } = useAsync({
    promiseFn: getManuallyUploadedDailyCampaignEventsForDealIds,
    onReject: e => {
      setShowReset(true);
      setMessage({
        type: 'danger',
        text: `Error loading events: ${e.message || 'Unknown error'}`
      });
    },
    dealIds,
    watchFn: (props, prevProps) =>
      props.dealIds && props.dealIds.length && !isEqual(props.dealIds, prevProps.dealIds)
  });

  const { isLoading: isUploadingManuallyUploadedDailyCampaignEvents, run: runUpload } = useAsync({
    deferFn: async () => {
      return upload(manuallyUploadedDailyCampaignEvents);
    },
    onResolve: resolveData => {
      setShowReset(true);
      setMessage({
        type: 'info',
        text: `Added ${resolveData.length} events. To add more, select another CSV.`
      });
    },
    onReject: e => {
      setShowReset(true);
      setMessage({
        type: 'danger',
        text: `Error when adding events: ${e.message || 'Unknown error'}`
      });
    }
  });

  const isLoading =
    isLoadingDeals ||
    isLoadingManuallyUploadedDailyCampaignEvents ||
    isUploadingManuallyUploadedDailyCampaignEvents;

  const onCsvData = csvData => {
    setMessage({});
    setManuallyUploadedDailyCampaignEvents([]);
    setShowReset(false);

    let processed: ManuallyUploadedDailyCampaignEvent[] = [];
    for (const index in csvData) {
      // Could've used Object.hasOwn, but we're way too behind
      // the latest Flowtype
      if (!Object.hasOwnProperty.call(csvData[index], 'deal_id')) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: missing deal_id`
        });
        return;
      }
      if (!Object.hasOwnProperty.call(csvData[index], 'date')) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: missing date`
        });
        return;
      }
      if (!Object.hasOwnProperty.call(csvData[index], 'events_count')) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: missing events_count`
        });
        return;
      }
      if (!Object.hasOwnProperty.call(csvData[index], 'event_type')) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${parseInt(index, 10) + 1}: missing event_type`
        });
        return;
      }

      const { deal_id, date, events_count, event_type } = csvData[index];

      const belongsToThisCampaign = dealIds ? dealIds.includes(deal_id) : false;
      if (!belongsToThisCampaign) {
        setMessage({
          type: 'danger',
          text: `Invalid data on CSV row number ${
            parseInt(index, 10) + 1
          }: deal ${deal_id} doesn't belong to this campaign`
        });
        return;
      }

      processed.push({
        deal_id: Number(deal_id),
        date: new Date(date),
        events_count: Number(events_count),
        event_type
      });
    }
    setManuallyUploadedDailyCampaignEvents(processed);
  };

  return (
    <>
      <button
        className="button is-primary"
        onClick={() => {
          setShowModal(!showModal);
        }}>
        Add or update daily campaign events
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          resetFormData();
          setMessage({});
          setShowModal(false);
        }}
        isBoxed={false}>
        <div>
          <header className="modal-card-head">
            <p className="modal-card-title">Add or update daily campaign events</p>
          </header>
          <section className="modal-card-body">
            <p className="notification is-info is-light">
              CSV should be of format <code>deal_id,date,events_count,event_type</code>
            </p>

            {message && (
              <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
            )}

            {manuallyUploadedDailyCampaignEvents.length === 0 || showReset ? (
              <CsvUpload onData={onCsvData} />
            ) : (
              <>
                <Button buttonClassName="is-primary" loading={isLoading} onClick={runUpload}>
                  <span>
                    Add or update {manuallyUploadedDailyCampaignEvents.length} daily campaign events
                  </span>
                </Button>
                <Button
                  buttonClassName="is-secondary"
                  disabled={isLoading}
                  onClick={() => {
                    resetFormData();
                  }}>
                  <span>Clear selection</span>
                </Button>
              </>
            )}

            {manuallyUploadedDailyCampaignEvents.length > 0 && (
              <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                <Table
                  className="is-fullwidth is-hoverable"
                  data={manuallyUploadedDailyCampaignEvents.map(c => {
                    const existingManuallyUploadedDailyCampaignEvent = existingManuallyUploadedDailyCampaignEvents.find(
                      ec =>
                        c.date.toISOString().split('T')[0] === ec.date.split('T')[0] &&
                        c.event_type === ec.event_type &&
                        c.deal_id === ec.deal_id
                    );

                    const countsDifference = existingManuallyUploadedDailyCampaignEvent
                      ? c.events_count - existingManuallyUploadedDailyCampaignEvent.events_count
                      : 0;

                    return {
                      ...c,
                      deal_id: (
                        <a
                          href={`${config('bulkOfferTool.url')}/deals/${c.deal_id}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {c.deal_id}
                        </a>
                      ),
                      events_count: (
                        <>
                          {c.events_count}
                          {countsDifference ? (
                            <span
                              className={classNames('has-text-weight-bold', {
                                'has-text-success': countsDifference > 0,
                                'has-text-danger': countsDifference < 0
                              })}>
                              {' '}
                              {countsDifference > 0 ? '+' : ''}
                              {countsDifference}
                            </span>
                          ) : null}
                        </>
                      ),
                      date: c.date.toISOString().split('T')[0],
                      action: existingManuallyUploadedDailyCampaignEvent ? (
                        <span className="icon-text">
                          <span className="icon">
                            <i className="material-icons is-unselectable" alt="update">
                              sync
                            </i>{' '}
                          </span>
                          <span>Update</span>
                        </span>
                      ) : (
                        <span className="icon-text">
                          <span className="icon">
                            <i className="material-icons is-unselectable" alt="add">
                              add_circle_outline
                            </i>
                          </span>
                          <span>Add</span>
                        </span>
                      )
                    };
                  })}
                  searchBy={[]}>
                  <Column name="deal_id">Deal id</Column>
                  <Column name="date">Date</Column>
                  <Column name="events_count">Events count</Column>
                  <Column name="event_type">Event type</Column>
                  <Column name="action">Action</Column>
                </Table>
              </div>
            )}
          </section>
        </div>
      </Modal>
    </>
  );
}

async function updateManuallyUploadedDailyCampaignEventsCall(manuallyUploadedDailyCampaignEvents) {
  try {
    const res = await callApi(`/admin/manually-uploaded-daily-campaign-events`, {
      method: 'PUT',
      body: manuallyUploadedDailyCampaignEvents
    });
    return res.data;
  } catch (error) {
    const requestId = error && error.requestId;

    const errorMessage = [
      error.message || 'Unexpected error',
      error.params ? JSON.stringify(error.params) : '',
      `Request id: [${requestId || 'unknown'}]`
    ].join('. ');
    throw new Error(errorMessage);
  }
}

export default injectIntl(CampaignKpiChart);
