// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Props } from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import ServiceWithOneTrackingUrlOrPlatformSpecific from './ServiceWithOneTrackingUrlOrPlatformSpecific';

// This is now under Branch
export default function Tune(props: Props) {
  return (
    <div>
      <ServiceWithOneTrackingUrlOrPlatformSpecific {...props} name="tune" />
      <FormattedMessage
        id="publisher.campaign.attribution.tuneDescription"
        values={{
          link: (...chunks) => {
            return (
              <a href="https://docs.branch.io/deep-linked-ads/matchmade-mobile-tracking/">
                {chunks}
              </a>
            );
          }
        }}
      />
    </div>
  );
}
