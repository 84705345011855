// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
// $FlowFixMe
import Select from 'react-select';
import capitalize from 'lodash/capitalize';

import Form from '../forms/Form';
import Input from '../forms/controls/Input';
import type {
  InfluencerSettings,
  PreferredCommunication
} from '../../types/influencerSettings.flow';

import { defaultSettings } from '../../constants';

import './InfluencerSettingsForm.scss';

const communicationTypes: PreferredCommunication[] = [
  'email',
  'skype',
  'phone',
  'whatsapp',
  'other'
];

type Props = {
  settings: InfluencerSettings,
  onChange?: (form: InfluencerSettings) => void,
  editMode?: boolean
};

export default function InfluencerSettingsForm({ onChange, settings, editMode = false }: Props) {
  const newSettings = settings || defaultSettings;
  const [form, setForm] = React.useState(newSettings);

  React.useEffect(() => {
    setForm(newSettings);
  }, [newSettings]);

  return (
    <Form onChange={onChange} values={form} className="InfluencerSettingsForm">
      <div className="columns">
        <div className="column is-full">
          <div className="control">
            <label className="label">
              <FormattedMessage id="influencer.profile.contactInformation.contactEmail" />
              {editMode && <span>*</span>}
            </label>
            {editMode ? <Input name="contactEmail" type="text" /> : form.contactEmail}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="control">
            <label className="label">
              <FormattedMessage id="influencer.profile.contactInformation.backupChannel" />
              {editMode && <span>*</span>}
            </label>
            {editMode ? (
              <Select
                name="preferredCommunication"
                className="preferredCommunication"
                value={form.preferredCommunication}
                clearable={false}
                isSearchable={false}
                options={communicationTypes.map(value => ({ label: capitalize(value), value }))}
                onChange={option => {
                  onChange && onChange({ ...form, preferredCommunication: option.value });
                }}
                closeOnSelect={true}
              />
            ) : (
              capitalize(form.preferredCommunication)
            )}
          </div>
        </div>
        <div className="column">
          <div className="control">
            <label className="label">
              <FormattedMessage
                id={`influencer.profile.contactInformation.${form.preferredCommunication || ''}`}
              />
              {editMode && <span>*</span>}
            </label>
            {editMode ? (
              <Input name="preferredCommunicationValue" type="text" />
            ) : (
              form.preferredCommunicationValue
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
