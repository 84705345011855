// @flow
import * as React from 'react';

import './FooterNotification.scss';

type Props = {
  children: any,
  type?: 'success' | 'warning' | 'danger',
  onClose?: () => void
};

const FooterNotification = ({ children, onClose, type }: Props) => {
  return (
    <div className={`FooterNotification notification is-${type || 'warning'}`}>
      <div className="FooterNotification__children container">
        {children}
        {onClose && <button className="delete" onClick={onClose} />}
      </div>
    </div>
  );
};

export default FooterNotification;
