import React from 'react';

const Headset = props => (
  <svg width={230} height={190} viewBox="0 0 230 190" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(48 95)" fill="#EE322B" stroke="#EE322B" strokeWidth={6}>
        <rect
          transform="rotate(7 119.816 12.816)"
          x={111.316}
          y={4.316}
          width={17}
          height={17}
          rx={3}
        />
        <rect
          transform="scale(-1 1) rotate(7 0 -196.72)"
          x={4.316}
          y={4.316}
          width={17}
          height={17}
          rx={3}
        />
      </g>
      <path
        d="M178.5 100.5c-1.443 8.05-2.943 17.537-4.5 28.459-2.5 17.541-11.371 25.481-27.676 26.01-.432.013-3.873.024-10.324.031"
        stroke="#EE322B"
        strokeWidth={6}
        strokeLinecap="round"
      />
      <g fill="#F3DCDC" stroke="#EE322B" strokeWidth={6}>
        <path d="M148.803 84.275l10.403 1.277a8 8 0 0 1 6.965 8.916l-3.412 27.79a8 8 0 0 1-8.915 6.966l-10.404-1.277a8 8 0 0 1-6.965-8.915l3.412-27.792a8 8 0 0 1 8.916-6.965zM78.844 84.275L68.44 85.552a8 8 0 0 0-6.965 8.916l3.412 27.79a8 8 0 0 0 8.916 6.966l10.403-1.277a8 8 0 0 0 6.965-8.915L87.76 91.24a8 8 0 0 0-8.915-6.965z" />
      </g>
      <g stroke="#EE322B" strokeWidth={6}>
        <path
          d="M113.614 8c31.311 0 67 23.557 61.5 64.057 0 3-5.033 40.067-5.906 47.17-.872 7.105-7.32 11.09-13.098 10.38l-6.947-.853 5.362-43.672 8.933 1.097c1.124-7.226 1.896-13.512 2.315-18.859 2-25.5-23.16-48.763-52.16-48.763h.452c-29 0-54.16 23.263-52.16 48.763.42 5.347 1.192 11.633 2.316 18.859l8.933-1.097 5.362 43.672-6.947.853c-5.778.71-12.226-3.275-13.098-10.38-.873-7.103-5.906-44.17-5.906-47.17C47.065 31.557 82.754 8 114.065 8"
          fill="#F3DCDC"
        />
        <path
          d="M174.381 52.026c1.384 6.112 1.715 12.802.733 20.031 0 3-5.033 40.067-5.906 47.17-.872 7.105-7.32 11.09-13.098 10.38l-6.947-.853 5.362-43.672 8.933 1.097c1.124-7.226 1.896-13.512 2.315-18.859a37.064 37.064 0 0 0-.924-11.55l9.532-3.744zM62.858 55.653a37.078 37.078 0 0 0-.952 11.667c.42 5.347 1.19 11.633 2.315 18.859l8.933-1.097 5.362 43.672-6.947.853c-5.778.71-12.226-3.275-13.098-10.38-.873-7.103-5.906-44.17-5.906-47.17-.988-7.276-.647-14.005.76-20.15l9.533 3.746z"
          fill="#F3C4C4"
        />
      </g>
      <rect
        stroke="#EE322B"
        strokeWidth={6}
        fill="#F3DCDC"
        x={123}
        y={151}
        width={23}
        height={11}
        rx={3}
      />
      <path
        d="M79 127c.333 5.373.833 9.04 1.5 11 1 2.94 3.779 2.94 5.5 2.94h2.337c2.859 0 6.663 1.903 6.663 5.51a73872358352018300 73872358352018300 0 0 0-35 14.363V166"
        stroke="#EE322B"
        strokeWidth={6}
      />
      <path fill="#EE322B" d="M57 180h6v6h-6zM57 170h6v6h-6z" />
    </g>
  </svg>
);

export default Headset;
