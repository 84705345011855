// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';

// $FlowFixMe$
import Linkify from 'linkifyjs/react';

import Button, { Color } from '../common/Button';
import Modal from '../common/Modal';

import CloseIcon from '../common/Icons/CloseIcon';

import {
  CampaignType as CampaignTypeEnum,
  PromotionType as PromotionTypeEnum
} from '@sharkpunch/matchmade-common/campaign';

import type { CampaignType, PromotionType } from 'matchmade-types';

import './CampaignDescriptionModal.scss';

type Props = {
  description: string,
  talkingPoints: string,
  promotionTypes: PromotionType[],
  isOpen: boolean,
  isSendingOffer?: boolean,
  onAccept?: () => void,
  onClose: (e: any) => void,
  isLoading?: boolean,
  campaignType: CampaignType
};

const CampaignDescriptionModal = ({
  description,
  talkingPoints,
  promotionTypes,
  isOpen,
  isSendingOffer = false,
  onAccept,
  onClose,
  isLoading = false,
  campaignType
}: Props) => {
  if (!isOpen) {
    return null;
  }

  const hasDedicated = promotionTypes.indexOf(PromotionTypeEnum.DEDICATED) !== -1;
  const hasIntegrated = promotionTypes.indexOf(PromotionTypeEnum.INTEGRATION) !== -1;
  const isCharityCampaign = campaignType === CampaignTypeEnum.CHARITY;

  const closeAndProceed = () => {
    onAccept ? onAccept() : onClose();
  };
  const onCloseIfNotLoading = e => {
    if (isLoading) {
      return;
    }

    onClose(e);
  };

  const closeButton = isSendingOffer ? (
    <Button
      loading={isLoading}
      color={Color.PRIMARY}
      size="large"
      onClick={onCloseIfNotLoading}
      className="CampaignDescriptionModal__close_button">
      <CloseIcon />
    </Button>
  ) : null;

  const dedicatedStyles = hasDedicated
    ? 'CampaignDescriptionModal__button'
    : 'CampaignDescriptionModal__button CampaignDescriptionModal__button--disabled';
  const integratedStyles = hasIntegrated
    ? 'CampaignDescriptionModal__button'
    : 'CampaignDescriptionModal__button CampaignDescriptionModal__button--disabled';
  return (
    <Modal
      className="CampaignDescriptionModal"
      isBoxed={false}
      onClose={onCloseIfNotLoading}
      isOpen={isOpen}
      isClosable={false}>
      <header className="modal-card-head">
        <h3>
          <FormattedMessage id="influencer.campaign.campaignInfo" tagName="strong" />
        </h3>
      </header>
      <section className="modal-card-body">
        <div className="CampaignDescriptionModal__buttons_title">
          <FormattedMessage id="influencer.campaign.availablePromotionTypes" tagName="strong" />
        </div>
        <div className="CampaignDescriptionModal__buttons">
          <div className={integratedStyles}>
            <FormattedMessage id="campaign.promotionTypes.integration" tagName="strong" />
            <FormattedMessage id="influencer.campaign.integrationExplanation" tagName="p" />
          </div>
          <div className="CampaignDescriptionModal__button-separator" />
          <div className={dedicatedStyles}>
            <FormattedMessage id="campaign.promotionTypes.dedicated" tagName="strong" />
            <FormattedMessage id="influencer.campaign.dedicatedExplanation" tagName="p" />
          </div>
        </div>
        {description && (
          <div className="CampaignDescriptionModal__section">
            <div className="CampaignDescriptionModal__section_title">
              <FormattedMessage id="campaign.description.requirements.title" tagName="strong" />
            </div>
            <div className="CampaignDescriptionModal__section_content">
              <Linkify
                options={{
                  attributes: {
                    rel: 'noopener noreferrer'
                  }
                }}>
                {description}
              </Linkify>
            </div>
          </div>
        )}
        {talkingPoints ? (
          <div className="CampaignDescriptionModal__section">
            <div className="CampaignDescriptionModal__section_title">
              <FormattedMessage id="campaign.description.talkingPoints.title" tagName="strong" />
            </div>
            <div className="CampaignDescriptionModal__section_content">
              <Linkify
                options={{
                  attributes: {
                    rel: 'noopener noreferrer'
                  }
                }}>
                {talkingPoints}
              </Linkify>
            </div>
          </div>
        ) : null}
        {isCharityCampaign ? (
          <div className="CampaignDescriptionModal__section">
            <div className="CampaignDescriptionModal__section_title">
              <FormattedMessage id="campaign.description.charity.title" tagName="strong" />
            </div>
            <div className="CampaignDescriptionModal__section_content">
              <FormattedMessage id="campaign.description.charity.subtitle" />
            </div>
          </div>
        ) : null}
      </section>
      <footer className="modal-card-foot">
        <Button
          color={Color.PRIMARY}
          loading={isLoading}
          onClick={closeAndProceed}
          className="CampaignDescriptionModal__action_button">
          {isSendingOffer ? (
            <FormattedMessage id="influencer.campaign.acceptAndProceed" tagName="strong" />
          ) : (
            <FormattedMessage id="influencer.campaign.readThis" tagName="strong" />
          )}
        </Button>
        {closeButton}
      </footer>
    </Modal>
  );
};

export function useCampaignDescriptionModal(props: {
  isLoading?: boolean,

  onAccept?: () => void, // default to just close the modal

  campaignId: number,
  description: string,
  talkingPoints: string,
  promotionTypes: PromotionType[],
  campaignType: CampaignType,

  shouldSendOffer?: boolean
}) {
  const [showCampaignDescriptionModal, setShowCampaignDescriptionModal] = React.useState(false);

  function renderCampaignDescriptionModal() {
    if (!showCampaignDescriptionModal) return null;

    const { onAccept, shouldSendOffer = false, ...rest } = props;

    const finalProps = {
      ...rest,
      className: 'CampaignCard__modal',
      isOpen: showCampaignDescriptionModal,
      isSendingOffer: shouldSendOffer,
      onClose: onHideCampaignDescriptionModal,

      onAccept: () => {
        shouldSendOffer && onAccept && onAccept();
        onHideCampaignDescriptionModal();
      },

      key: `modal-${props.campaignId}`
    };

    return <CampaignDescriptionModal {...finalProps} />;
  }

  function onHideCampaignDescriptionModal() {
    setShowCampaignDescriptionModal(false);
  }

  return [
    renderCampaignDescriptionModal(),
    (visible: boolean) => {
      setShowCampaignDescriptionModal(visible);
    }
  ];
}

export default CampaignDescriptionModal;
