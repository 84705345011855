// @flow
import React from 'react';
import type { ContentPlatform } from 'matchmade-types';
import type { DealItem } from '../../types/dealItem.flow';

import { checkIfWhitelabelAdmin } from '../../helpers/user';
import FormattedNumber from '../common/FormattedNumber';
import Table, { Column } from '../tables/Table';

import { FormattedMessage } from 'react-intl';
import Spinner from '../common/Spinner';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

import { getFirstAvailableContentPlatform } from '../../helpers/influencer';

import {
  ContentPlatform as ContentPlatformEnum,
  DEFAULT_CONTENT_PLATFORM
} from '@sharkpunch/matchmade-common/campaign';
import { DealItemStatus } from '../../constants';

import './CampaignKpiTable.scss';

const MAX_PUBLISHED_IN_MONTHS = 6;
function isLatestVideoTooOld(publishedAt) {
  return (
    publishedAt && moment.utc().diff(moment.utc(publishedAt), 'months') >= MAX_PUBLISHED_IN_MONTHS
  );
}

type RowData = {
  id: number,
  contentPlatform: ContentPlatform,
  channelName: string,
  installs: number,
  cost: number,
  payout: number,
  clicks: number,
  cpi: number,
  minGuarantee: number,
  conversionRate: number,
  dealItems: DealItem[]
};

type Props = {
  analytics: Array<RowData>,
  isLoading: boolean,
  showSpinner: boolean,
  contentPlatform: ContentPlatform
};

function getDefaultSortProp(contentPlatform: ContentPlatform) {
  switch (contentPlatform) {
    case ContentPlatformEnum.YOUTUBE:
      return 'installs';
    case ContentPlatformEnum.TWITCH:
      return 'averageCcv';
    case ContentPlatformEnum.INSTAGRAM:
      return 'installs';
    default:
      return 'installs';
  }
}

function getLatestPublishedItem(rowData: RowData): ?DealItem {
  return sortBy(
    (rowData.dealItems || []).filter(({ status }) => status === DealItemStatus.PUBLISHED),
    'published'
  ).reverse()[0];
}

const CampaignKpiTable = (props: Props) => {
  const {
    analytics: analyticsProps = [],
    isLoading = true,
    showSpinner = true,
    contentPlatform = DEFAULT_CONTENT_PLATFORM
  } = props;
  if (isLoading || !analyticsProps.length) {
    if (showSpinner)
      return (
        <Spinner mode="fullWidth">
          <FormattedMessage id="campaign.kpi.table.loading" />
        </Spinner>
      );
  }
  const isYoutube = contentPlatform === ContentPlatformEnum.YOUTUBE;
  const isTwitch = contentPlatform === ContentPlatformEnum.TWITCH;
  const isInstagram = contentPlatform === ContentPlatformEnum.INSTAGRAM;

  const analytics = sortBy(analyticsProps, getDefaultSortProp(contentPlatform)).reverse();

  const rows = analytics.slice(0).map(row => {
    // prevent Infinity
    row.conversionRate = row.installs / (row.clicks || 1) || 0;
    return row;
  });

  return (
    <div className="CampaignKpiTable">
      <Table
        data={rows}
        className="is-fullwidth"
        initialSort="installs"
        initialSortDirection="desc"
        searchBy={[]}>
        {checkIfWhitelabelAdmin() && (
          <Column name="agreementId">
            <FormattedMessage id="campaign.kpi.table.header.agreementId" />
          </Column>
        )}
        <Column
          name="channelName"
          component={props => {
            const influencer = props.rowData;

            // for total row
            // TODO what happened to the total row??? don't see it in the UI anymore
            if (!influencer.id) {
              return null;
            }

            const isVideoTooOld = isLatestVideoTooOld(influencer.latestVideo);

            const { url } = getFirstAvailableContentPlatform(influencer) || {};

            return (
              <a
                href={url}
                className={isVideoTooOld ? 'CampaignKpiTable--has-old-videos' : ''}
                rel="noopener noreferrer">
                {influencer.channelName}
              </a>
            );
          }}>
          <FormattedMessage id="campaign.kpi.table.header.channel" />
        </Column>
        {checkIfWhitelabelAdmin() && !isInstagram && (
          <Column
            name="dealItems"
            component={props => {
              const firstPublishedItem = getLatestPublishedItem(props.rowData);

              if (!firstPublishedItem) {
                return <div>N/A</div>;
              }

              const isVideoTooOld = isLatestVideoTooOld(firstPublishedItem.published);
              const text = `${moment.utc(firstPublishedItem.published).format('DD MMM YYYY')}`;

              return (
                <a
                  href={firstPublishedItem.contentUrl}
                  className={isVideoTooOld ? 'CampaignKpiTable--has-old-videos' : ''}
                  target="_blank"
                  rel="noopener noreferrer">
                  {text}
                </a>
              );
            }}
            sortBy={(dealItems, rowData) => {
              const firstPublishedItem = getLatestPublishedItem(rowData);

              return firstPublishedItem && firstPublishedItem.published;
            }}>
            Content
          </Column>
        )}
        {!isInstagram && (
          <Column
            name="promotionType"
            component={props => (
              <FormattedMessage
                id={`promotionTypes.${props.rowData.promotionType}`}
                defaultMessage="Unknown"
              />
            )}>
            <FormattedMessage id="campaign.kpi.table.header.promotionType" />
          </Column>
        )}
        {(isYoutube || isInstagram) && (
          <Column
            name="installs"
            component={props => <FormattedNumber value={props.rowData.installs} type="number" />}>
            <FormattedMessage id="campaign.kpi.table.header.install" />
          </Column>
        )}
        {isTwitch && (
          <React.Fragment>
            <Column
              name="averageCcv"
              component={props => (
                <FormattedNumber type="number" value={props.rowData.averageCcv} />
              )}>
              <FormattedMessage id="campaign.kpi.table.header.avgCcv" />
            </Column>
            <Column
              name="peakCcv"
              component={props => <FormattedNumber type="number" value={props.rowData.peakCcv} />}>
              <FormattedMessage id="campaign.kpi.table.header.peakCcv" />
            </Column>
          </React.Fragment>
        )}
        <Column
          name="clicks"
          component={props => <FormattedNumber value={props.rowData.clicks} type="number" />}>
          <FormattedMessage id="campaign.kpi.table.header.click" />
        </Column>
        {!isInstagram && (
          <Column
            name="views"
            component={props => <FormattedNumber value={props.rowData.views} type="number" />}>
            <FormattedMessage id="campaign.kpi.table.header.view" />
          </Column>
        )}
        {isYoutube && (
          <Column
            name="conversionRate"
            component={props => {
              const influencer = props.rowData;

              if (!influencer.clicks) {
                return <span>N/A</span>;
              }

              return <FormattedNumber value={props.data} type="percentage" defaultToZero />;
            }}>
            <FormattedMessage id="campaign.kpi.table.header.conversionRate" />
          </Column>
        )}
        <Column
          name="cost"
          component={props => (
            <FormattedNumber value={props.rowData.cost} type="cost" defaultToZero />
          )}>
          <FormattedMessage id="campaign.kpi.table.header.cost" />
        </Column>
        {checkIfWhitelabelAdmin() && (
          <React.Fragment>
            <Column
              name="payout"
              component={props => (
                <FormattedNumber value={props.rowData.payout} type="cost" defaultToZero />
              )}>
              <FormattedMessage id="admin.campaigns.table.header.payout" />
            </Column>
            <Column
              name="commissionRate"
              component={props => (
                <FormattedNumber
                  value={props.rowData.commissionRate}
                  type="percentage"
                  defaultToZero
                />
              )}>
              <FormattedMessage id="admin.campaigns.table.header.commission" />
            </Column>
          </React.Fragment>
        )}
      </Table>
    </div>
  );
};

export default CampaignKpiTable;
