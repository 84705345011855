import React from 'react';

const TwitchIcon = props => (
  <svg viewBox="0 0 28 29" height="100%" {...props}>
    <path
      d="M11 16h3V8h-3v8zm7 0h3V8h-3v8zm7 .94L20.5 21H14l-4.094 4v-4H4V3h21v13.94zM0 4.979V25h7v4h3.938L15 25h5l8-7.5V0H2L0 4.978z"
      fill="#6441A5"
      fillRule="evenodd"
    />
  </svg>
);

export default TwitchIcon;
