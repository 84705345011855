import { FormattedMessage } from 'react-intl';
import React from 'react';
import Slider from 'react-slick';

import Button, { Color } from '../Button';

import NextIcon from '../Icons/NextIcon';
import PrevIcon from '../Icons/PrevIcon';

import './ScreenshotsTab.scss';

const MAX_SCREENSHOTS = 10;

function Arrow(props) {
  const { className, style, onClick, children } = props;
  return (
    <Button
      color={Color.PRIMARY}
      className={className}
      style={{ ...style }}
      round
      onClick={onClick}>
      {children}
    </Button>
  );
}

const settings = {
  dots: true,
  infinite: true,
  centerMode: true,
  swipeToSlide: true,
  centerPadding: '0',
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <Arrow>
      <NextIcon />
    </Arrow>
  ),
  prevArrow: (
    <Arrow>
      <PrevIcon />
    </Arrow>
  )
};

const ScreenshotsTab = ({ screenshots = [] }) => {
  const screenshotsSlice = screenshots.slice(0, MAX_SCREENSHOTS);
  const slider = screenshotsSlice.length ? (
    <Slider {...settings}>
      {screenshotsSlice.map(screenshot => {
        return (
          <div className="ScreenshotsTab__image-wrapper" key={screenshot}>
            <img referrerPolicy="no-referrer" src={screenshot} alt={screenshot} />
          </div>
        );
      })}
    </Slider>
  ) : (
    <span className="ScreenshotsTab__no-screenshots">
      <FormattedMessage id="campaign.card.noScreenshots" />
    </span>
  );
  return <div className="ScreenshotsTab">{slider}</div>;
};

export default ScreenshotsTab;
