// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { ContentPlatform, PromotionType } from 'matchmade-types';

import { deleteCampaignInvite } from '../../../actions/campaign';
import { getFirstAvailableContentPlatformOrThrow } from '../../../helpers/influencer';
import Button, { Color } from '../../common/Button';
import Card from '../../common/Card';
import ChannelHeader from '../../common/ChannelHeader';
import DeleteIcon from '../../common/Icons/DeleteIcon';
import LinkToInfluencer from '../InfluencerCard/LinkToInfluencer';
import TabsWithContent from '../../common/TabsWithContent';
import WaitIcon from '../../common/Icons/WaitIcon';

import { getAnalyticsTab, getDemographicsTab } from '../InfluencerCard/common';

type Props = {
  defaultTab?: number,
  inviteId: number,
  estimatedInstalls?: number,
  estimatedCost?: number,
  influencer: Object,
  isLoading?: boolean,
  disabled?: boolean,
  hideCpi: boolean,
  promotionType: PromotionType,
  defaultContentPlatform: ContentPlatform,
  channelName: string,
  adminActions?: React.Element<*> | React.Element<*>[]
};

const InfluencerInvitedCard = ({
  defaultTab = 1,
  influencer,
  inviteId,
  disabled,
  isLoading,
  channelName,
  adminActions,
  hideCpi,
  estimatedInstalls,
  promotionType,
  defaultContentPlatform
}: Props) => {
  const dispatch = useDispatch();
  const {
    channel,
    contentPlatform: channelContentPlatform,
    url: channelUrl
  } = getFirstAvailableContentPlatformOrThrow(influencer, defaultContentPlatform);

  return (
    <Card className="InfluencerCard">
      <ChannelHeader
        backgroundImage={channel.avatarUrl}
        isVertical
        channelType={channelContentPlatform}
        adminActions={adminActions}>
        <LinkToInfluencer
          id={influencer.id}
          isOutOfNetworkInfluencer={influencer.isOutOfNetworkInfluencer}
          channelUrl={channelUrl}
          avatarUrl={channel.avatarUrl}
          channelName={channelName}
          isMock={channel.mock}
        />
      </ChannelHeader>
      <TabsWithContent
        defaultTab={defaultTab}
        actions={[
          <Button
            size="large"
            color={Color.PRIMARY}
            loading={isLoading}
            disabled={disabled}
            key="decline"
            onClick={() => {
              dispatch(deleteCampaignInvite.run(inviteId));
            }}>
            <DeleteIcon tooltipI18nString="publisher.campaign.cancelInvite" />
          </Button>,
          <Button className="Button__main-action" disabled key="waiting" onClick={() => {}}>
            <FormattedMessage tagName="strong" id="influencer.card.actions.wait" />
            <WaitIcon />
          </Button>
        ]}
        data={[
          getAnalyticsTab({
            influencer,
            defaultContentPlatform: defaultContentPlatform
          }),
          getDemographicsTab(influencer, defaultContentPlatform)
        ]}
      />
    </Card>
  );
};

export default InfluencerInvitedCard;
