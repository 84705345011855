// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';
const DeleteIcon = (props: IconProps) => {
  return (
    <Icon tooltipI18nString="tooltips.delete" withTooltip className="DeleteIcon" {...props}>
      {() => <i className="material-icons">delete</i>}
    </Icon>
  );
};

export default DeleteIcon;
