// @flow

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import React from 'react';
import moment from 'moment';

import {
  acceptCampaign,
  declineCampaign,
  fetchAllInNetworkInfluencers,
  fetchCampaign,
  fetchCampaignAnalytics,
  fetchCampaignInfluencerStats,
  fetchCampaignOverallStats,
  updateCampaign
} from '../actions/campaign';
import { checkIfWhitelabelAdmin } from '../helpers/user';
import {
  getAll as fetchAllCollections,
  linkCollectionToCampaign,
  removeCollectionFromCampaign
} from '../actions/collection';
import { getAllocatedBudgetForCampaign } from '../actions/admin';
import { resetProps } from '../actions/common';
import { updateSettings } from '../actions/account';
import CampaignKpiChart from '../components/campaign/CampaignKpiChart';
import CampaignKpiTable from '../components/campaign/CampaignKpiTable';
import CampaignTwitchKpiChart from '../components/campaign/CampaignTwitchKpiChart';
import CampaignViewForPublisher from '../components/campaign/CampaignViewForPublisher';
import Spinner from '../components/common/Spinner';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';
import usePrevious from '../hooks/usePrevious';
import type { CampaignBudget, CampaignForPublisher } from '../types/campaign.flow';
import type { CollectionWithPaginatedInfluencers } from '../types/collection.flow';

import './PublisherCampaignPage.scss';

const KpiTable = ({ analytics, isLoading, showSpinner, contentPlatform }) => {
  if (!analytics || !analytics.length) {
    return null;
  }

  return (
    <CampaignKpiTable
      analytics={analytics}
      isLoading={isLoading}
      showSpinner={showSpinner}
      contentPlatform={contentPlatform}
    />
  );
};

const KpiChart = ({
  startDate,
  endDate,
  contentPlatform,
  campaignAnalytics,
  campaignCreatedAt,
  campaignId,
  onChangeDate
}) => {
  const analytics =
    contentPlatform === ContentPlatform.TWITCH
      ? campaignAnalytics && campaignAnalytics.points
      : campaignAnalytics;
  const isLoading = false; // We show big overlay on top of both chart and table

  const props = {
    campaignId,
    startDate,
    endDate,
    analytics,
    isLoading,
    onChangeDate,
    contentPlatform
  };

  // Twitch uses a different chart than ALL other platforms (youtube, instagram)
  return contentPlatform === ContentPlatform.TWITCH ? (
    <CampaignTwitchKpiChart {...props} />
  ) : (
    <CampaignKpiChart {...props} />
  );
};

const KpiChartAndTable = ({
  startDate,
  endDate,
  campaignAnalytics,
  campaignInfluencerStats,
  isFetchingCampaignAnalytics,
  isFetchingCampaignInfluencerStats,
  contentPlatform,
  campaignCreatedAt,
  onChangeDate,
  campaignId
}) => {
  const spinner = isFetchingCampaignAnalytics ? (
    <Spinner size="large" mode="overlay">
      <FormattedMessage id="campaign.kpi.chart.loading" />
    </Spinner>
  ) : null;

  return (
    <div className="PublisherCampaignPage__stats">
      {spinner}
      <div className="PublisherCampaignPage__kpi-chart">
        <KpiChart
          startDate={startDate}
          endDate={endDate}
          contentPlatform={contentPlatform}
          campaignAnalytics={campaignAnalytics}
          campaignCreatedAt={campaignCreatedAt}
          campaignId={campaignId}
          onChangeDate={onChangeDate}
        />
      </div>
      <div className="PublisherCampaignPage__kpi-table">
        <KpiTable
          analytics={campaignInfluencerStats}
          isLoading={!!isFetchingCampaignInfluencerStats}
          showSpinner={!isFetchingCampaignAnalytics}
          contentPlatform={contentPlatform}
        />
      </div>
    </div>
  );
};

type Props = {
  campaign: ?CampaignForPublisher,
  campaignInfluencerStats: any,
  campaignAnalytics: any,
  isFetchingCampaignOverallStats?: boolean,
  isFetchingCampaignInfluencerStats?: boolean,
  isFetchingCampaignAnalytics?: boolean,
  isLoadingCampaign?: boolean,
  isFetchingCurrentCollection?: boolean,
  currentCollection: ?CollectionWithPaginatedInfluencers,
  router: { setRouteLeaveHook: Function },
  route: string,
  user: Object,
  params: { id: string, tab: string, collectionId: string },
  error: any,
  resetProps: Function,
  updateSettings: Function,
  fetchAllInNetworkInfluencers: Function,
  declineCampaign: Function,
  acceptCampaign: Function,
  updateCampaign: Function,
  isLoadingBudget: boolean,
  budget: ?CampaignBudget
};

let PublisherCampaignPage = (props: Props) => {
  const { campaign, budget, params } = props;
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    startDate: moment.utc().subtract(6, 'days'),
    endDate: moment.utc()
  });
  const previousError = usePrevious(props.error);
  const isFetching =
    props.isLoadingCampaign || props.isFetchingCampaignOverallStats || props.isLoadingBudget;
  const campaignId = params.id;

  const onChangeSettings = (settings: Object) => {
    return props.updateSettings(props.user.id, settings);
  };

  const onChangeCampaignAnalyticsDate = (startDate: moment$Moment, endDate: moment$Moment) => {
    let dimension = 'day';
    if (endDate.diff(startDate, 'days') <= 2) dimension = 'hour';
    const newStartDate = startDate.utc().startOf('date');
    const newEndDate = endDate.utc().endOf('date');

    dispatch(
      fetchCampaignAnalytics.run({
        campaignId,
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString(),
        dimension
      })
    );
    dispatch(
      fetchCampaignInfluencerStats.run(
        campaignId,
        newStartDate.toISOString(),
        newEndDate.toISOString()
      )
    );
    setState({ startDate: newStartDate, endDate: newEndDate });
  };
  const onChangeCampaignAnalyticsDateCallback = React.useCallback(onChangeCampaignAnalyticsDate, [
    state.startDate,
    state.endDate,
    campaignId,
    dispatch
  ]);

  React.useEffect(() => {
    dispatch(fetchCampaign.run(campaignId));
    dispatch(fetchCampaignOverallStats.run(campaignId));
    dispatch(fetchAllCollections.run());
    if (checkIfWhitelabelAdmin()) {
      dispatch(getAllocatedBudgetForCampaign.run(campaignId));
    }
  }, [dispatch, campaignId]);

  React.useEffect(() => {
    onChangeCampaignAnalyticsDateCallback(state.startDate, state.endDate);
  }, [onChangeCampaignAnalyticsDateCallback, state.endDate, state.startDate]);

  React.useEffect(() => {
    props.router.setRouteLeaveHook(props.route, props.resetProps);
  }, [props.resetProps, props.route, props.router]);

  React.useEffect(() => {
    if (
      !previousError &&
      props.error &&
      props.error.message === 'Attribution partner setup required'
    ) {
      window.scrollTo(0, 0);
    }
  }, [previousError, props.error]);

  if (isFetching) {
    return (
      <div className="container">
        <Spinner mode="cover" size="large" />
      </div>
    );
  }

  if (!campaign) {
    return (
      <div className="container">
        <h2>
          <FormattedMessage id="error.nodata" />
        </h2>
      </div>
    );
  }

  return (
    <div className="container PublisherCampaignPage">
      <CampaignViewForPublisher
        {...props}
        campaign={campaign}
        budget={budget}
        isFetchingCurrentCollection={props.isFetchingCurrentCollection}
        currentCollection={props.currentCollection}
        activeTab={params.tab || params.collectionId}
        sections={{
          afterGameHeader: [
            <section className="section box PublisherCampaignPage__kpi" key="campaign-kpi-chart">
              <h2 className="PublisherCampaignPage__header-text subtitle">
                <FormattedMessage tagName="strong" id="publisher.campaign.kpi.chart.title" />
              </h2>
              <KpiChartAndTable
                campaignId={campaignId}
                startDate={state.startDate}
                endDate={state.endDate}
                campaignInfluencerStats={props.campaignInfluencerStats}
                campaignAnalytics={props.campaignAnalytics}
                isFetchingCampaignAnalytics={props.isFetchingCampaignAnalytics}
                isFetchingCampaignInfluencerStats={props.isFetchingCampaignInfluencerStats}
                contentPlatform={campaign && campaign.defaultContentPlatform}
                campaignCreatedAt={(campaign || {}).created}
                onChangeDate={onChangeCampaignAnalyticsDate}
              />
            </section>
          ]
        }}
        fetchAllInNetworkInfluencers={props.fetchAllInNetworkInfluencers}
        onAcceptCampaign={props.acceptCampaign}
        onDeclineCampaign={props.declineCampaign}
        onChangeSettings={onChangeSettings}
        onUpdateCampaign={props.updateCampaign}
      />
    </div>
  );
};

const mapStateToProps = generateStateToProps('publisherCampaignPage');

const mapDispatchToProps = generateDispatchToProps({
  acceptCampaign,
  declineCampaign,
  fetchAllInNetworkInfluencers,
  resetProps,
  updateSettings,
  updateCampaign,
  linkCollectionToCampaign,
  removeCollectionFromCampaign
});

// $FlowFixMe
PublisherCampaignPage = connect(mapStateToProps, mapDispatchToProps)(PublisherCampaignPage);

export default PublisherCampaignPage;
