// @flow
import * as React from 'react';
import TermsNavigation from './TermsNavigation';

import './TermsContent.scss';

const navItems = [
  { id: 'definitions', value: 'Definitions' },
  { id: 'obligations', value: 'Obligations of the Influencer' },
  { id: 'useOfPlatform', value: 'Use of Matchmade Platform' },
  { id: 'processingData', value: 'Processing of Data' },
  { id: 'content', value: 'Content' },
  { id: 'intellectualProperty', value: 'Intellectual Property Rights' },
  { id: 'confidentiality', value: 'Confidentiality' },
  { id: 'fees', value: 'Fees and Payment' },
  { id: 'liability', value: 'Limitation of liability' },
  { id: 'terminations', value: 'Term and termination' },
  { id: 'referenceUse', value: 'Reference Use' },
  { id: 'severance', value: 'Severance' },
  { id: 'noEmployment', value: 'No Employment' },
  { id: 'assignment', value: 'Assignment' },
  { id: 'applicableLaw', value: 'Applicable law and dispute resolution' }
];

const InfluencerTermsContent = () => {
  return (
    <div className="columns TermsContent">
      <div className="column TermsContent__navigation-column">
        <TermsNavigation items={navItems} />
      </div>
      <div className="column TermsContent__content-column">
        <div className="TermsContent__content content">
          <h1>Matchmade terms of service for influencers</h1>
          <p>
            These Terms of Service govern the publishing of Content in connection with an
            Advertiser’s Campaign and any use of the Matchmade Platform by an Influencer under the
            Agreement, as well as any other services provided by Matchmade under the Agreement.
          </p>
          <p>
            By accepting these Terms of Service as part of the sign-up procedure or login to the
            Matchmade Platform or sign-up to a Campaign, or when accepting a deal or reviewing or
            participating in a Campaign, (e.g. by clicking “I agree” or a similar box in Matchmade
            Platform), or by accessing or using the Matchmade Platform in any manner as an
            Influencer, or by signing the Campaign Order Form, a binding contract is formed between
            Matchmade and Influencer.
          </p>
          <p>
            If you register as a company, association or any other legal entity, you represent and
            warrant that you are authorized to bind the entity into these Terms of Service. Persons
            under 16 years of age are not allowed to register or use the Matchmade Platform or
            conclude this Agreement without the required parental or guardian approvals.
          </p>
          <h2 id="definitions">Definitions</h2>
          <p>
            As used in the Agreement, the following capitalized terms shall have the meaning set out
            below.
          </p>
          <p>
            “Advertiser” shall mean a company looking to promote products or services, or an agency
            or representative of such company, by working with Influencers.
          </p>
          <p>
            “Influencer” shall mean an influencer, e.g. a content creator, who is advertising
            product(s), service(s) or brand(s) for the benefit of, and/or under an assignment from,
            the Advertiser under this Agreement.
          </p>
          <p>
            “Matchmade” shall mean Shark Punch Oy (business ID: 2618428-2), address Urho Kekkosen
            katu 5 B, 00100 Helsinki, Finland.
          </p>
          <p>
            “Matchmade Platform” shall means Matchmade’s online service for connecting Influencers
            with Advertisers, including the online platform on which the Influencer and the
            Advertiser can conclude and administer agreements regarding Campaigns.
          </p>
          <p>“Party” shall mean Influencer or Matchmade (jointly the “Parties”).</p>
          <p>
            “Agreement” shall mean these Matchmade Terms of Service and any Orders and any
            appendices attached thereto.
          </p>
          <p>
            “Campaign” shall mean any marketing or other public campaign which Advertiser purchases
            from Influencer by using the Matchmade Platform.
          </p>
          <p>
            “Campaign Fee(s)” means the remuneration Matchmade pays to Influencer for Influencer’s
            participation in a certain Campaign.
          </p>
          <p>
            “Content” shall mean photos, videos, audiovisual material and any other form or type of
            deliverable, material or content uploaded by Influencer to the relevant and agreed
            channel under this Agreement, or otherwise provided by Influencer to Advertiser or
            Matchmade under this Agreement.
          </p>
          <p>
            “Individual Influencer” shall mean Influencer that has registered to Matchmade platform
            as an individual, ie. files the revenue generated under this Agreement as personal
            income to the relevant authorities, receives the payment for Campaign Fee(s) directly to
            a personal account or to a service that is in the individual’s personal use, and has not
            engaged in an agreement with a third party to perform Campaign(s) under this Agreement.
          </p>
          <p>
            “Influencer Payment” shall mean payment option(s) available on Matchmade Platform
            indicated to be for the use of Individual Influencer(s).
          </p>
          <p>
            “Company influencer” shall mean Influencer that has registered to Matchmade platform as
            a business entity, ie. files the revenue generated under this Agreement as business
            income to the relevant authorities, receives the payment for Campaign Fee(s) directly to
            a business account or to a service that is in business use, or has engaged in an
            agreement with a third party to perform Campaign(s) under this Agreement.
          </p>
          <p>
            “Influencer Invoicing” shall mean payment option(s) available on Matchmade Platform
            indicated to be for the use of Company Influencer(s).
          </p>
          <p>
            “Intellectual Property Rights” shall mean copyrights and related rights (including
            database and catalogue rights and photography rights), patents, utility models, design
            rights, trademarks, tradenames, trade secrets, know-how and any other form of registered
            or unregistered intellectual property rights.
          </p>
          <p>
            “Performance Campaign” shall mean a Campaign in which the Campaign Fee is calculated
            based on the performance of a campaign.
          </p>
          <p>
            “Qualified Action” shall mean an individual person who (i) accesses the application
            distribution service (e.g. Google Play Store or, Apple App Store or similar) through the
            partner’s Campaign tracking link; and (ii.a.) launches the promoted application directly
            as a result of following the partner’s Campaign tracking link while connected to the
            Internet; or (ii.b.) installs and launches the promoted application directly as a result
            of following the partner’s Campaign tracking link while connected to the Internet; (iii)
            is not a robot, script, or otherwise computer generated; (iv) is not determined by
            Matchmade or the Advertiser later to be fraudulent, unqualified or a duplicate.
            Qualified installs will be attributed to Influencer&#39;s Content for a time period
            defined in in Campaign brief or otherwise in connection to Campaign, or if not otherwise
            defined, 30 days following Content&#39;s publishing date.
          </p>
          <p>
            “Cost Per Action Campaign” shall mean a Performance Campaign where performance is
            defined by the number of Qualified Action(s) and further a Campaign Fee per Qualified
            Action is defined.{' '}
          </p>
          <p>
            “Qualified View” shall mean a view that is not a robot, script, or otherwise computer
            generated and has been tracked by Matchmade during a time period defined in Campaign
            brief or otherwise in connection to Campaign, or if not otherwise defined, 30 days
            following Content’s publishing date.
          </p>
          <p>
            “Cost Per View Campaign” shall mean a Performance Campaign where performance is defined
            by the number of qualified views and further a Campaign Fee per qualified view is
            defined.
          </p>
          <h2 id="obligations">Obligations of the Influencer</h2>
          <p>
            Influencer may participate in Campaigns by following the instructions set out on the
            Matchmade Platform. Participation in a Campaign requires both Influencer and the
            relevant Advertiser to expressly agree to Influencer’s participation in a Campaign.
          </p>
          <p>
            When registering to the Matchmade Platform, Influencer shall provide true, accurate and
            complete information as prompted by the registration process, and update such
            information when required. Influencer shall provide Matchmade with all information
            required for e.g. tax reporting purposes.
          </p>
          <p>
            Advertiser and/or Matchmade may issue different kind of guidelines pertaining to the
            Campaign and the Content Influencer submits to the Campaign. Influencer needs to follow
            and comply with any such guidelines provided to Influencer by Matchmade or Advertiser.
          </p>
          <p>
            In case Influencer fails to comply with any agreed time schedules as regards submission
            for review or publication of the Content, Matchmade shall have the right to terminate
            this Agreement and Influencer shall not have the right to receive any Campaign Fees for
            the Campaign. Alternatively, in case of a delay by Influencer in submitting the Content
            for review or publishing the Content in accordance with the agreed time schedules,
            Matchmade may, in its sole discretion, deduct 10 % of the Campaign Fees payable to
            Influencer per each day of delay. The deduction for delay shall be calculated on the
            total Campaign Fees agreed to be paid to Influencer for the relevant Content which is
            delayed.
          </p>
          <p>
            Influencer shall keep the Content available in Influencer’s channels for at least 180
            days from the first publication of the Content. However, Influencer acknowledges that
            there might be certain specific requirements for the Content as well as for the use of
            the Content in Influencer’s channels, for example, pertaining to the availability of the
            Content. Such requirements shall be available to Influencer prior to the commencement of
            the relevant Campaign. Influencer agrees to comply with said requirements.
          </p>
          <p>
            Advertiser and/or Matchmade shall always have the right to approve the Content prior to
            the publication of the Content. In case Advertiser or Matchmade perceives the Content to
            be unfit for the Campaign, for whatever reason, Influencer commits to take all
            reasonable measures, including the retake of the Content, to remedy the situation and to
            modify the Content.
          </p>
          <p>
            In case the Content or Influencer’s actions relating to the Campaign, or otherwise, are
            contradictory to the terms of this Agreement or the guidelines, or in case Influencer
            has otherwise breached the terms of this Agreement, or in case Influencer’s channels
            contain any pornographic, racist, defamatory, libelous or otherwise immoral, vulgar or
            obscene content or any content that depicts unlawful or violent, hateful or threatening
            or otherwise inappropriate acts, Influencer shall not be entitled to receive the
            Campaign Fee or any other remuneration. In such case, Influencer shall return all
            products Influencer has received from Matchmade or the Advertiser in connection with the
            Campaign. In addition, Influencer, shall as per requested by the Advertiser or
            Matchmade, delete the Content. Influencer acknowledges and understands that in case of
            breach of this Agreement, Influencer may be ordered to cover the damages incurred by
            Matchmade or the Advertiser, including indirect damages, such as damages to goodwill.
          </p>
          <p>
            Influencer agrees to include in connection with the relevant Content created by an
            Influencer, in the relevant description and in the customary place for such information
            specific to that channel (e.g. in YouTube in the description of the Content and in
            Twitch in the chat): (i) the links via which the promoted product can be purchased or
            downloaded, such as a link to websites and/or mobile stores; and (ii) the words
            referring to paid promotion, partnership, sponsorship or advertising or other similar
            words, as specified by the Advertiser. and; (iii) the tracking link. Influencer shall
            also announce the material connection between Advertiser (or the company the Advertiser
            represents) and Influencer at the beginning of the sponsored video Content or at the
            beginning of the sponsored content included in the video Content.
          </p>
          <p>
            Influencer and the Content shall comply with all Advertiser’s guidelines (as specified
            in the Campaign description on the Matchmade Platform or as otherwise communicated to
            Influencer), YouTube and other service and channel terms and conditions, and all
            applicable laws and regulations, including without limitation guidelines and laws
            relevant to promotional content. Influencer shall clearly disclose any material
            connection between Influencer and Advertiser.
          </p>
          <p>
            Influencer agrees to follow FTC and other guidelines and laws relevant to promotional
            content. Failure to abide by the FTC endorsement disclosure guidelines can result in
            immediate termination of Influencer’s account.
          </p>
          <p>
            In case Matchmade has provided any tracking links or other links, all Content must
            include the correct tracking link(s) or other link(s) provided by Matchmade. Such
            link(s) shall be placed at the beginning of the Content description, or in any other
            place, as required or instructed.
          </p>
          <p>
            Influencer shall provide a link for unlisted Content to Advertiser to review. Advertiser
            will have final approval of any Content provided by Influencer in connection with the
            Campaign. Influencer hereby agrees to offer the relevant Advertiser the right to request
            one round of revisions to the Content to ensure that the talking points and guidelines
            are met. Upon approval by Advertiser, Influencer shall publish the video as agreed upon
            between Advertiser and Influencer.
          </p>
          <h2 id="useOfPlatform">Use of Matchmade Platform</h2>
          <p>
            Subject to the terms and conditions of this Agreement, Influencer shall have the right
            to use the Matchmade Platform during the term of this Agreement.
          </p>
          <p>
            Matchmade is constantly developing the Matchmade Platform and may change or remove
            different parts of the Matchmade Platform, including features available on the Matchmade
            Platform.
          </p>
          <p>
            Influencer acknowledges that the Matchmade Platform may at any time be interrupted or
            permanently discontinued. The Matchmade Platform may also be temporarily suspended.
          </p>
          <p>
            Except as specifically provided under this Agreement, the Matchmade Platform is provided
            "as is" and with the functionalities available at each time without warranty of any
            kind, either express or implied, including but not limited to the warranties of
            merchantability and fitness for a particular purpose.
          </p>
          <p>
            Influencer shall use the Matchmade Platform in compliance with the Agreement. Misuse of
            the Matchmade Platform by Influencer may lead to termination of the Agreement or
            suspension or denial of access to the Matchmade Platform.
          </p>
          <p>
            Influencer agrees not to use the Matchmade Platform for any unlawful or illegal actions.
            Influencer shall not use the Matchmade Platform for data storage or for making backup
            copies.
          </p>
          <p>
            The Matchmade Platform credentials are personal. Influencer shall ensure that any user
            account credentials and equivalent information required to access the user account of
            the Influencer are kept confidential and used in a secure manner not accessible by third
            parties.
          </p>
          <p>
            Where it is suspected that any unauthorized person has become aware of Influencer’s user
            account credentials or has access to the user account of the Influencer, Influencer
            shall immediately inform Matchmade thereof. Influencer shall be responsible for any use
            of the Matchmade Platform and any activity under the user account of Influencer.
          </p>
          <p>
            Influencer shall be responsible for obtaining and maintaining any devices or equipment
            and connections needed for access to and use of the Matchmade Platform as well as for
            the provision of the Content to Matchmade or any Advertiser.
          </p>
          <p>
            Any and all linked third party services are provided by the relevant third parties and
            covered by their terms of service or other agreement or license.
          </p>
          <p>
            Matchmade uses YouTube API Services to access video performance data and audience
            information so that Matchmade can find relevant Campaigns for Influencers. By agreeing
            to this Agreement, and when and to the extent Influencer creates any Content for YouTube
            under this Agreement, Influencer agrees to be bound by the{' '}
            <a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a> and{' '}
            <a href="http://www.google.com/policies/privacy">Google Privacy Policy</a>, as updated
            from time to time.
          </p>
          <p>Influencer is not permitted and not entitled to do any of the following:</p>
          <ul>
            <li>
              circumvent or attempt to circumvent any usage control or anti-copy features of the
              Matchmade Platform;
            </li>
            <li>probe, scan or test the vulnerability of the Matchmade Platform; </li>
            <li>
              use the Matchmade Platform or the Content in any manner that could damage, disable,
              overburden or impair the Matchmade Platform;
            </li>
            <li>
              use any data mining, robots, scraping, or similar data gathering or extraction
              methods;
            </li>
            <li>
              use, copy, sell, resell, rent, transfer, license or otherwise provide anybody with the
              Matchmade Platform or the Content available through the Matchmade Platform, except as
              provided herein;
            </li>
            <li>
              reverse engineer or decompile the Matchmade Platform or access the source code
              thereof, except as permitted by law;
            </li>
            <li>
              use the Matchmade Platform for transmitting any unauthorized advertising, promotional
              materials, junk mail, spam, chain letters, contests, pyramid schemes, or any other
              form of solicitation or mass messaging;
            </li>
            <li>use the Matchmade Platform in violation of applicable law;</li>
            <li>
              use the Matchmade Platform in ways that violate Intellectual Property Rights or
              privacy of third parties or users; and/or
            </li>
            <li>
              use the Matchmade Platform to transmit any material that contains adware, malware,
              spyware, software viruses, worms or any other computer code designed to interrupt,
              destroy, or limit the functionality of computer software or equipment.
            </li>
          </ul>
          <h2 id="processingData">Processing of Data</h2>
          <p>
            Matchmade may collect and process data, including personal data, in relation to
            Influencer’s use of the Service and the Service, such as contact information, payment
            details, photographs, and identification data.
          </p>
          <p>
            Matchmade processes personal data in accordance with its{' '}
            <a href=" https://matchmade.tv/privacy-policy/">Privacy Policy</a> in force from time to
            time.
          </p>
          <p>
            Influencer acknowledges that Matchmade may collect tracking data, technical data and
            other usage data relating to the Content.
          </p>
          <p>
            Matchmade offers Campaigns to Influencers based on the content Influencer produces, and
            the kind of audience Influencer has. Campaign participation requires that the Advertiser
            gets to know Influencer, e.g. Influencer’s channel (YouTube, Twitch, Instagram, etc.)
            and Influencer’s audience. Matchmade shall have the right to disclose the foregoing
            information to the relevant Advertiser.
          </p>
          <h2 id="content">Content</h2>
          <p>
            By uploading Content to the relevant and agreed channel under this Agreement, or by
            otherwise providing Content to Matchmade, or any Advertiser under this Agreement,
            Influencer acknowledges and confirms that the Content will be made available to the
            public on and through the Matchmade Platform for personal and commercial use of third
            parties subject to this Agreement.
          </p>
          <p>
            Influencer warrants, that Influencer shall not upload, post or transmit any Content
            that:
          </p>
          <p>
            infringes any third party’s copyrights or other Intellectual Property Rights,
            contractual rights or any other rights of any person or an entity; contains any
            pornographic, racist, defamatory, libelous or otherwise immoral, vulgar or obscene
            content; depicts unlawful or violent, hateful or threatening or otherwise inappropriate
            acts; offends, defames, harasses or otherwise damages Matchmade, any Advertiser or any
            third party; infringes the privacy of any person; violates any law, statute, or
            regulation.
          </p>
          <p>
            Matchmade shall have the right to request Influencer to remove any Content, if Matchmade
            believes the Content to be defective, of poor quality, or in violation of this Agreement
            or if the Content does not comply with the Campaign Guidelines or any other agreed
            specifications. Influencer commits to remove such Content without undue delay after
            receiving the request.
          </p>
          <p>
            Influencer represents and warrants that: (i) the Content is Influencer’s, or Influencer
            has the right to use it and grant Matchmade the rights and license as provided in this
            Agreement; (ii) Matchmade will not need to obtain licenses from any third party or pay a
            compensation or royalties to any third party with respect to the Content; and (iii) the
            Content does not infringe any third party rights (including in particular copyrights,
            neighboring rights, Intellectual Property Rights, name rights, right of personality,
            rights of privacy, data rights or other property rights); and (iv) the Content complies
            with this Agreement and all applicable laws.
          </p>
          <p>
            When Influencer uploads any Content to the Matchmade Platform or otherwise provides any
            Content to Matchmade or any Advertiser under this Agreement, Influencer authorizes
            Matchmade under Influencer’s copyrights to enforce any violations of the sublicense
            Matchmade grants in the Content to others. In other words, Matchmade is entitled to take
            appropriate measures to pursue the rights granted to Matchmade hereunder. The Influencer
            shall support Matchmade in the court or non-court assertion of the acquired rights, in
            particular by providing information, providing the necessary original documents and
            other documents, making or having made the necessary assignment of rights to Matchmade,
            as well as preparing any further declarations or documents which should be required or
            useful for the realization of the license granted by Influencer to Matchmade.
          </p>
          <h2 id="fees">Fees and Payment</h2>
          <p>
            Matchmade will pay Influencer the agreed Campaign Fees. Influencer shall be eligible to
            receive payment once the Campaign Fees have been paid by the Advertiser to Matchmade
            according to payment schedule effective at the time. Matchmade shall invoice the
            Advertiser within 30 days after the Influencer has published the Content.
          </p>
          <p>
            Influencer shall be obliged to receive payment for Campaign Fee(s) using the options and
            follow the instructions set forth for the different options available on Matchmade
            Platform. Influencer acknowledges that Influencer will be paid only after the relevant
            Advertiser, to whose Campaign Influencer has participated, has paid the relevant
            installments to Matchmade. Influencer shall release Matchmade from any claims relating
            to Campaign Fees, if Matchmade has not received the funds from the relevant Advertiser.
            Matchmade reserves the right to change, shorten or extend the payment schedule at any
            time.
          </p>
          <p>
            Influencer acknowledges that the provision of correct payment details and adherence to
            Matchmade’s instructions (such as but not limited to instructions regarding Influencer
            Payment and Influencer Invoicing) for receiving payment is Influencer’s sole
            responsibility. Influencer further acknowledges that failure to provide correct payment
            details or follow Matchmade’s instructions may result in a delay in receiving payment,
            for which Matchmade bears no responsibility. Matchmade shall ask for correction from
            Influencer, and re-attempt payment of Campaign Fee(s) within 30 days of receiving such
            correction from Influencer. In case the Influencer fails to provide correct payment
            details or other information requested by Matchmade for the duration of 180 after a
            notice by Matchmade, the Campaign Fee(s) will be deemed as forfeited. Further,
            Influencer agrees that Matchmade will not provide additional documentation for
            fulfilling tax and other governmental and statutory obligations, other than what is made
            readily available on Matchmade Platform, for the work (eg. created Content) Influencer
            has performed or for the Campaign Fee(s) Influencer has received under this Agreement.
          </p>
          <p>
            Influencer agrees that Matchmade will not provide additional documentation for
            fulfilling tax and other governmental and statutory obligations, other than what is made
            readily available on Matchmade Platform, for the work (eg. created Content) Influencer
            has performed or for the Campaign Fee(s) Influencer has received under this Agreement.{' '}
          </p>
          <p>
            Influencer may have participated in a Performance Campaign, either a Cost per Action
            Campaign or a Cost per View campaign, wherein Qualified Actions and Qualified Views
            define the Campaign Fee as described on the Matchmade Platform. If any previously paid
            Qualified Actions or Qualified Views are later determined to not meet the specified
            criteria, these will be deducted from Influencer’s account prior to the payment of any
            further Campaign Fee(s).
          </p>
          <p>
            Influencer shall be responsible for all tax and other governmental and statutory
            obligations pertaining to the Campaign Fees Influencer receives under this Agreement.
            However, Matchmade shall have the right, but not the obligation, to declare to the tax
            authorities the Campaign Fees paid to the Influencer by Matchmade.
          </p>
          <p>
            Influencer acknowledges that any fraudulent acts, (such as but not limited to obtaining
            fraudulent installs or fraudulent views for the Content) are grounds for immediate
            termination of Influencer’s account and participation in Campaigns. If Influencer’s
            account is terminated on the basis of fraud, Influencer will not receive any of the
            unpaid installments of the Campaign Fee(s) on Influencer’s account.
          </p>
          <p>
            For the avoidance of doubt, Matchmade shall only pay Influencer the Campaign Fees which
            the Advertiser has paid to Matchmade. In case the Advertiser refuses to pay the Campaign
            Fee due to Influencer’s breach of Matchmade Representation Agreement or any fraudulent
            or inappropriate conduct, Advertiser’s payment issues or defaults (bankruptcy etc.) or
            any other reason, Matchmade shall have no obligation to pay the Influencer the Campaign
            Fee or any other remuneration or sums. Matchmade shall not have the obligation to pay
            Manager or the Influencer in case the Influencer has not followed the video guidelines
            or any other reasonable instructions issued by the Advertiser or Matchmade.
          </p>
          <h2 id="intellectualProperty">Intellectual Property Rights</h2>
          <p>
            When Influencer uploads any Content to the relevant and agreed channel(s) under this
            Agreement, or otherwise provides any Content to Matchmade or any Advertiser under this
            Agreement, Influencer thereby grants Advertiser (or the company Advertiser represents) a
            worldwide, restricted and irrevocable license and right to use the link to the Content,
            and refer to the Influencer’s name, tag-name likeness, photograph, statements and/or
            voice, in whole or in part in all forms of advertising, publicity, promotion and trade,
            in any and all internet and other digital media, including, without limitation, on
            Advertiser’s and third party websites and in Advertiser’s or any third party’s social
            media channels.
          </p>
          <p>
            Except for the foregoing restricted right and license granted to Advertiser, and in case
            not otherwise explicitly agreed, Influencer shall retain the title to, and all
            Intellectual Property Rights relating to the Content Influencer has created under this
            Agreement.
          </p>
          <p>
            Nothing in this Agreement shall be construed to grant Influencer any other license or
            right to use Matchmade’s copyrights, drawings, know-how, technology, data, trade
            secrets, domain names, or Trademarks used by Matchmade in relation to Matchmade Platform
            or otherwise (<strong>“Matchmade IPR”</strong>). All Matchmade IPR shall at all times
            remain the sole property of Matchmade.
          </p>
          <h2 id="confidentiality">Confidentiality</h2>
          <p>
            Neither Party shall disclose to third parties any material or information received from
            the other Party and marked as confidential or which should be understood to be
            confidential, and shall not use such material or information for any other purposes than
            those stated in this Agreement. For clarity, Influencer’s confidentiality obligations
            set out in this Section shall also cover any confidential information of any Advertiser,
            received by Influencer during the co-operation and actions under or within the scope of
            this Agreement.
          </p>
          <p>
            The Party shall return all records or copies of the confidential information of the
            other Party at the request of the other Party and at the latest upon the expiry or
            termination of the Agreement. This shall not apply to confidential information or copies
            thereof which must be stored by the receiving Party in accordance with the applicable
            law.
          </p>
          <p>
            The obligation of confidentiality is applied during the term of this Agreement and for
            five (5) years after the termination of this Agreement.
          </p>
          <p>
            The confidentiality obligation shall, however, not be applied to material and
            information, (a) which is generally available or otherwise public; or (b) which the
            Party has lawfully received from a third party without any obligation of
            confidentiality; or (c) which was lawfully in the possession of the receiving Party
            prior to receipt of the same from the other Party without any obligation of
            confidentiality related thereto; or (d) which a Party has independently developed
            without using material or information received from the other Party as verified by the
            written records of the receiving Party; or (e) which a Party is obligated to disclose
            due to applicable mandatory laws, public authority regulations or court orders. In case
            of disclosure due to (e), the Party much promptly inform the other Party of such
            disclosure.
          </p>
          <h2 id="liability">Limitation of liability</h2>
          <p>
            Matchmade will not be liable for any indirect, incidental, consequential, special or
            exemplary damages, including but not limited to, loss of profits or loss of business
            opportunity, even if such damages are foreseeable and whether or not Matchmade has been
            advised of the possibility thereof. This limitation of liability shall not apply in
            cases of intentional misconduct or gross negligence and breaches of sections 5
            (Confidentiality) and 6 (Intellectual Property Rights).
          </p>
          <p>
            Matchmade's total aggregate liability under or in connection with this Agreement shall
            be limited to the aggregate Campaign Fees paid or payable by Matchmade to Influencer
            during a period of six (6) months preceding the occurrence for which damages are
            claimed.
          </p>
          <p>
            The Parties do not restrict their liability for any matter in respect of which, by
            mandatory law, it is not permitted to restrict its liability.
          </p>
          <h2 id="terminations">Term and termination</h2>
          <p>
            This Agreement shall remain in force until expiry or termination in accordance with the
            terms set out herein. This Agreement may be terminated by either Party with a notice to
            the other Party. The termination period shall be one (1) month.
          </p>
          <p>
            This Agreement shall terminate automatically when the Influencer no longer has an active
            account on the Matchmade Platform.{' '}
          </p>
          <p>
            Either Party may, by written notice to the other, terminate this Agreement if the other
            Party materially breaches any provision of this Agreement and does not cure the breach
            within 14 days after receiving written notice thereof from the non-breaching Party.
          </p>
          <p>
            The termination of this Agreement will not release either Party from any liability
            arising under this Agreement prior to the termination. The termination of this
            Agreement, for any reason, shall not affect the rights granted by Influencer herein
            pertaining to the Content Influencer has uploaded to the relevant and agreed channel
            under this Agreement, or otherwise provided to Matchmade or Advertiser under this
            Agreement prior to the termination of the Agreement. Upon termination or expiry of this
            Agreement, all rights and licenses granted to Influencer shall terminate immediately.
          </p>
          <p>
            Any provisions of this Agreement intended to survive the expiry or termination of this
            Agreement shall do so, including sections regarding Confidentiality, Intellectual
            Property Rights, Term and Termination, Limitation of Liability and Governing Law and
            Dispute Resolution.
          </p>
          <h2 id="referenceUse">Reference Use</h2>
          <p>
            The Parties may use the other Party’s name and logo in their marketing and other
            activities.
          </p>
          <p>
            Influencer agrees that Matchmade and the relevant Advertiser may use Influencer’s artist
            name, likeness, voice and biography to promote Matchmade or the relevant Advertiser.
          </p>
          <h2 id="severance">Severance</h2>
          <p>
            If any provision of this Agreement is declared by any judicial or other competent
            authority to be void, illegal or otherwise unenforceable, the remaining provisions of
            this Agreement shall remain in full force and effect.
          </p>
          <h2 id="noEmployment">No Employment</h2>
          <p>
            The Parties shall be deemed independent contractors and under no circumstances shall the
            Influencer be deemed an employee or agent of Matchmade. Influencer shall be solely
            responsible for its tax obligations and other statutory and governmental obligations. No
            agency, partnership, or employment is created as a result of this Agreement.
          </p>
          <h2 id="assignment">Assignment</h2>
          <p>
            Matchmade shall be entitled to assign all or any of its rights or obligations hereunder
            in whole or part to an affiliate or to an acquirer or transferee of its business assets
            relating to the Agreement without Influencer’s prior consent.{' '}
          </p>
          <p>
            Influencer shall not be entitled to assign this Agreement or any of its rights or
            obligations hereunder in whole or part without the prior written consent of Matchmade.
          </p>
          <h2 id="applicableLaw">Applicable law and dispute resolution</h2>
          <p>
            This Agreement shall be governed by and construed in accordance with the laws of
            Finland, except for its provisions on choice of law.
          </p>
          <p>
            Any dispute, controversy or claim arising out of or relating to this Agreement, or the
            breach, termination or validity thereof, shall be subject to amicable negotiations
            between Influencer and Matchmade.
          </p>
          <p>
            Should the negotiations not lead to a settlement between the Parties within 90 days, any
            dispute, controversy or claim arising out of or relating to this Agreement, or the
            breach, termination or validity thereof, shall be finally settled by arbitration in
            accordance with the Arbitration Rules of the Finland Chamber of Commerce. The number of
            arbitrators shall be one. The seat of arbitration shall be Helsinki, Finland. The
            language of the arbitration shall be English. The arbitral proceedings and award shall
            be confidential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfluencerTermsContent;
