import React from 'react';

const ImagesIcon = ({ isActive }) => {
  const iconStyles = isActive ? 'icon Icon mdImagesIcon activeTabIcon' : 'icon Icon mdImagesIcon';
  return (
    <span className={iconStyles}>
      <i className="material-icons">burst_mode</i>
    </span>
  );
};

export default ImagesIcon;
