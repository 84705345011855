import buildContainerReducer from '../../helpers/buildContainerReducer';

export default buildContainerReducer(
  {
    emailTemplates: null,
    isLoadingEmailTemplates: false,
    isLoadingEmailTemplatePreview: false,
    emailTemplatePreview: null,
    isSendingEmailTemplate: false,
    error: null
  },
  {
    error: null
  },
  ['email.fetchEmailTemplates', 'email.previewEmailTemplate', 'email.sendEmailTemplate']
);
