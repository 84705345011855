// @flow

import { getAllocatedBudget } from '../../../actions/admin';
import type { Action } from '../../../types/action.flow';

type State = {
  campaigns: any[],
  isLoading: boolean
};

const initialState: State = Object.freeze({
  campaigns: [],
  isLoading: false
});

export default function adminDashboardAllocatedBudgetReducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case getAllocatedBudget.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case getAllocatedBudget.SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        isLoading: false
      };
    case getAllocatedBudget.FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
