export const InfluencerSearchTypeEnum = {
  ALL: 'all',
  IN_NETWORK: 'in-network',
  OUT_OF_NETWORK: 'out-of-network'
};

export type InfluencerSearchType = $Values<typeof InfluencerSearchTypeEnum>;

export type SearchParams = {
  playedGames?: string[],
  notPlayedGames?: string[],
  tags?: string[],
  channelIds?: string[] | null,
  channelId?: string,
  channelValues?: { id: string, score: number }[] | null,
  channelName?: string,
  channelMinSubscriberCount?: number,
  channelMin30dAvgEngagementRatio?: number,
  channelMin30dAvgViewCount?: number,
  channelMaxSubscriberCount?: number,
  channelMax30dAvgEngagementRatio?: number,
  channelMax30dAvgViewCount?: number,
  channelCountriesOrEstimatedCountriesWhitelist?: string[],
  channelCountriesOrEstimatedCountriesBlacklist?: string[],
  sortBy?: string,
  sortDirection?: 'ASC' | 'DESC',
  page?: number,
  pageSize?: number,
  influencerType: InfluencerSearchType
};

export type SuggestedGame = {
  title: string,
  channelCount: number
};

export type SuggestedTag = {
  tag: string,
  channelCount: number
};
