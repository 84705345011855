import { Iterable } from 'immutable';
import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';

import { persistStore } from 'redux-persist';

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  const createLogger = require('redux-logger').createLogger;
  const loggerMiddleware = createLogger({
    collapsed: true,
    stateTransformer: state => {
      let newState = {};

      for (let i of Object.keys(state)) {
        if (Iterable.isIterable(state[i])) {
          newState[i] = state[i].toJS();
        } else {
          newState[i] = state[i];
        }
      }
      return newState;
    }
  });
  middlewares.push(loggerMiddleware);
}

middlewares.push(promiseMiddleware);

export default function configureStore(reducer, preloadedState) {
  const store = createStore(
    reducer || rootReducer,
    preloadedState,
    applyMiddleware(...middlewares)
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
