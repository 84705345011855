// @flow
import * as React from 'react';
import Icon from '../Icon';

import type { IconProps } from '../Icon';

import './Icons.scss';

const BarChartIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      {() => (
        <i className="material-icons" style={{ transform: 'scale(-1, 1)' }}>
          bar_chart
        </i>
      )}
    </Icon>
  );
};

export default BarChartIcon;
