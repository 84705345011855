// @flow
import * as React from 'react';
import get from 'lodash/get';
import set from 'lodash/set';

import Input from './controls/Input';

type Props = {
  children: React.Node,
  values?: Object,
  isLoading?: boolean,
  error?: any,
  className?: string,
  onSubmit?: (values: Object) => void,
  onChange?: (values: Object) => void,
  onChangeInput?: (name: string, value: string) => void
};

const Form = ({
  children,
  values,
  isLoading,
  error,
  className = '',
  onSubmit = () => {},
  onChange = () => {},
  onChangeInput = () => {}
}: Props) => {
  const [state, setState] = React.useState(values || {});

  React.useEffect(() => {
    setState(values);
  }, [values]);

  const submit = e => {
    e.preventDefault();
    onSubmit(state);
  };

  const changeInput = name => {
    return e => {
      const newState = { ...state };
      set(newState, name, e.target.value);
      onChange(newState);
      onChangeInput(name, e.target.value);
      setState(newState);
    };
  };

  function processChildren(children) {
    if (!children) return children;
    return React.Children.map(children, child => {
      if (!child) return null;
      let grandChildren = (child.props || {}).children;
      if (React.Children.count(grandChildren)) {
        grandChildren = processChildren(grandChildren);
      }

      if (!grandChildren) grandChildren = null;

      if (!child.type) {
        // this is probably a text node
        return child;
      }

      if (child.type === Input) {
        const value =
          child.props.value === undefined ? get(state, child.props.name) : child.props.value;

        return React.cloneElement(child, {
          onChange: changeInput(child.props.name),
          // make sure that we don't have null/undefined as the value here
          value: value === undefined || value === null ? '' : value,
          children: grandChildren,
          disabled: child.props.disabled || isLoading,
          error
        });
      }

      return React.cloneElement(child, {
        children: grandChildren
      });
    });
  }

  return (
    <form className={`Form ${className}`} onSubmit={submit}>
      {processChildren(children)}
    </form>
  );
};

export default Form;

export { Input };
