import Immutable from 'immutable';

import { fetchEmailTemplates, previewEmailTemplate, sendEmailTemplate } from '../../actions/email';

export default {
  fetchEmailTemplates(state, action) {
    switch (action.type) {
      case fetchEmailTemplates.REQUEST:
        return state.set('isLoadingEmailTemplates', true);
      case fetchEmailTemplates.SUCCESS:
        return state
          .set('isLoadingEmailTemplates', false)
          .set('emailTemplates', Immutable.fromJS(action.payload));
      case fetchEmailTemplates.FAILURE:
        return state
          .set('isLoadingEmailTemplates', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  previewEmailTemplate(state, action) {
    switch (action.type) {
      case previewEmailTemplate.REQUEST:
        return state.set('isLoadingEmailTemplatePreview', true);
      case previewEmailTemplate.SUCCESS:
        return state
          .set('isLoadingEmailTemplatePreview', false)
          .set('emailTemplatePreview', Immutable.fromJS(action.payload))
          .set('error', null);
      case previewEmailTemplate.FAILURE:
        return state
          .set('isLoadingEmailTemplatePreview', false)
          .set('emailTemplatePreview', null)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  sendEmailTemplate(state, action) {
    switch (action.type) {
      case sendEmailTemplate.REQUEST:
        return state.set('isSendingEmailTemplate', true);
      case sendEmailTemplate.SUCCESS:
        return state.set('isSendingEmailTemplate', false).set('error', null);
      case sendEmailTemplate.FAILURE:
        return state
          .set('isSendingEmailTemplate', false)
          .set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};
