import React, { Component } from 'react';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import partial from 'lodash/partial';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import Pagination, { sliceData } from '../common/Pagination';

import ProTipIcon from '../common/Icons/ProTipIcon';
import SortingSelector from '../common/SortingSelector';
import TwitchIcon from '../common/Icons/TwitchIcon';
import YoutubeIcon from '../common/Icons/YoutubeIcon';

import injectSearchInputController from '../../hoc/injectSearchInputController';

import { getFirstAvailableContentPlatform } from '../../helpers/influencer';
import { getUser } from '../../helpers/user';

import { Element, scroller } from 'react-scroll';

import PropTypes from 'prop-types';

import './InfluencerCollection.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Mousetrap from 'mousetrap';

// first one is the default one
export const DISPLAY_MODES = {
  card: 'influencer.collection.display.cards',
  list: 'influencer.collection.display.list'
};

// settings that are stored in db
export const ACCOUNT_SETTINGS = ['displayMode', 'sortBy', 'sortDirection'];

export function firstAvailableModeWithout(without) {
  return (
    Object.keys(DISPLAY_MODES).filter(name => {
      return name !== without;
    })[0] || null
  );
}

// It checks if we have card component for card display mode.
// This is to make sure that the component picks correct "default" display mode
// For example, don't pick `card` if there is no `card` component
export function pickDefaultModeFromProps(props) {
  let displayMode = props.displayMode;

  Object.keys(DISPLAY_MODES).forEach(mode => {
    if (!props[mode] && props.displayMode === mode) {
      displayMode = firstAvailableModeWithout(mode);
    }
  });

  return displayMode;
}
// This component renders a list of cards or a table
// using the provided influencers
class InfluencerCollection extends Component {
  constructor(props) {
    super(props);

    let initialSortOption = props.cardSortOptions ? first(props.cardSortOptions).attribute : null;
    let initialSortDirection = 'desc';

    // our collections might have different sorting options, fallback to the default one
    // if the saved settings don't meet the sorting options of the current collection
    if (props.cardInitialSortAttribute && props.cardSortOptions) {
      if (
        find(props.cardSortOptions || [], {
          attribute: props.cardInitialSortAttribute
        })
      ) {
        initialSortOption = props.cardInitialSortAttribute;
        initialSortDirection = props.cardInitialSortDirection || initialSortDirection;
      }
    }

    this.state = {
      displayMode: pickDefaultModeFromProps(props),
      sortBy: initialSortOption,
      sortDirection: initialSortDirection,
      currentPage: 1,
      pageLimit: 24,
      contentPlatforms: this.hasInfluencersFromMultiplePlatforms()
        ? ['youtube', 'twitch']
        : [this.pickDefaultContentPlatform()]
    };
  }

  componentDidMount() {
    if (this.props.defaultTab) {
      return;
    }
    [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(index => {
      Mousetrap.bind(`${index}`, () => {
        this.setState({
          defaultTab: index - 1
        });
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (this.props.displayMode !== nextProps.displayMode) {
      newState.displayMode = nextProps.displayMode;
    }

    this.setState(newState);
  }

  onChangeSelect(attr) {
    return e => {
      const settings = {
        [attr]: e.target.value
      };

      this.setState(settings, () => {
        if (ACCOUNT_SETTINGS.indexOf(attr) === -1) return;
        const setting = this.props.name || 'influencerCollection';

        this.props.onChangeSettings({
          [setting]: settings
        });
      });
    };
  }

  isContentPlatformSelected = type => {
    return this.state.contentPlatforms.includes(type);
  };

  renderSortOptions() {
    if (this.state.displayMode !== 'card') {
      return null;
    }

    const { cardSortOptions } = this.props;

    if (!cardSortOptions) {
      return null;
    }

    const { sortBy, sortDirection } = this.state;

    const sortOptions = map(cardSortOptions, ({ attribute, label }) => {
      label = typeof label === 'string' ? this.props.intl.formatMessage({ id: label }) : label;

      return {
        value: attribute,
        label
      };
    });

    return (
      <SortingSelector
        options={sortOptions}
        settingsPrefix={this.props.name}
        onSelect={this.onSort}
        ascending={sortDirection === 'asc'}
        selectedValue={sortBy}
      />
    );
  }

  renderDisplayModeSelection() {
    const { card, list } = this.props;

    if (!card || !list) {
      return null;
    }

    const options = map(DISPLAY_MODES, (text, value) => {
      return (
        <option key={value} value={value}>
          {this.props.intl.formatMessage({ id: text })}
        </option>
      );
    });

    return (
      <span className="select">
        <select value={this.state.displayMode} onChange={this.onChangeSelect('displayMode')}>
          {options}
        </select>
      </span>
    );
  }

  hasInfluencersFromMultiplePlatforms() {
    const { influencers } = this.props;
    if (!influencers) {
      return false;
    }

    const hasYoutubers =
      influencers.youtubeChannels && influencers.some(i => i.youtubeChannels.length > 0);
    const hasTwitchers =
      influencers.twitchChannels && influencers.some(i => i.twitchChannels.length > 0);

    return hasYoutubers && hasTwitchers;
  }

  pickDefaultContentPlatform() {
    const { influencers } = this.props;
    if (!influencers) {
      return false;
    }

    const hasYoutubers =
      influencers.youtubeChannels && influencers.some(i => i.youtubeChannels.length > 0);
    const hasTwitchers =
      influencers.twitchChannels && influencers.some(i => i.twitchChannels.length > 0);

    if (hasYoutubers && !hasTwitchers) {
      return 'youtube';
    }

    if (!hasYoutubers && hasTwitchers) {
      return 'twitch';
    }

    return 'both';
  }

  renderContentPlatformSelection() {
    if (!this.hasInfluencersFromMultiplePlatforms()) {
      return null;
    }

    return (
      <div className="ContentPlatformSelector is-flex-tablet">
        <label className="label">Filter by content platform</label>
        <div className="field has-addons">
          <div className="control">
            <button
              style={{
                opacity: this.isContentPlatformSelected('youtube') ? 1.0 : 0.5
              }}
              className="button is-transparent is-shadowless"
              onClick={() => {
                if (
                  this.isContentPlatformSelected('youtube') &&
                  this.isContentPlatformSelected('twitch')
                ) {
                  this.setState({ contentPlatforms: ['twitch'] });
                } else if (this.isContentPlatformSelected('youtube')) {
                  this.setState({ contentPlatforms: ['twitch'] });
                } else {
                  this.setState({ contentPlatforms: ['youtube', 'twitch'] });
                }
              }}>
              <YoutubeIcon title="YouTube" />
            </button>
            <button
              style={{
                opacity: this.isContentPlatformSelected('twitch') ? 1.0 : 0.5
              }}
              className="button is-transparent is-shadowless"
              onClick={() => {
                if (
                  this.isContentPlatformSelected('youtube') &&
                  this.isContentPlatformSelected('twitch')
                ) {
                  this.setState({ contentPlatforms: ['youtube'] });
                } else if (this.isContentPlatformSelected('twitch')) {
                  this.setState({ contentPlatforms: ['youtube'] });
                } else {
                  this.setState({ contentPlatforms: ['youtube', 'twitch'] });
                }
              }}>
              <TwitchIcon title="Twitch" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  onChangePage = currentPage => {
    this.setState({ currentPage }, this.onChangePagination);
  };

  onChangePageLimit = pageLimit => {
    this.setState({ pageLimit }, this.onChangePagination);
  };

  onSort = ({ sortProp, ascending }) => {
    this.setState(
      {
        sortBy: sortProp,
        sortDirection: ascending ? 'asc' : 'desc'
      },
      this.onChangePagination
    );
  };

  onChangePagination = () => {
    const { onChangePagination, isLoading } = this.props;

    if (onChangePagination && !isLoading) {
      onChangePagination({
        currentPage: this.state.currentPage,
        pageSize: this.state.pageLimit,
        sortBy: this.state.sortBy,
        sortDirection: this.state.sortDirection,
        filterKeyword: this.props.searchInput
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      scroller.scrollTo('topOfInfluencerCollection', {
        duration: 300,
        delay: 100,
        smooth: true
      });
    }
  }

  renderPagination(count) {
    if (!this.props.pagination && !this.props.isLoading) {
      return null;
    }

    const pageCount = Math.ceil((count || 1) / this.state.pageLimit);

    return (
      <Pagination
        pageCount={pageCount}
        currentPage={this.state.currentPage}
        pageLimit={this.state.pageLimit}
        pageRange={4}
        onChangePage={this.onChangePage}
        onChangePageLimit={this.onChangePageLimit}
      />
    );
  }

  renderInfluencerCount(count) {
    return (
      <FormattedMessage
        id="influencer.collection.influencerCount"
        values={{
          count: count
        }}
      />
    );
  }

  renderToolbar() {
    // when we don't have pagination, we show sort options in place of pagination
    let sortOptions = this.props.pagination ? this.renderSortOptions() : null;
    const keyboardShortcutTip = (
      <div className="InfluencerCollection__keyboard-tip">
        <ProTipIcon tooltipI18nString="influencer.cards.keyboardTip" withTooltip />
      </div>
    );

    return (
      <div className="InfluencerCollection__toolbar is-flex-tablet">
        {keyboardShortcutTip}
        {this.renderContentPlatformSelection()}
        {sortOptions}
        {this.renderDisplayModeSelection()}
      </div>
    );
  }

  processInfluencersBeforeRendering(influencers, sortByValue) {
    influencers = this.props.processInfluencersBeforeRendering(influencers, sortByValue);

    if (!this.props.pagination) {
      return influencers;
    }

    return sliceData(influencers, this.state);
  }

  filterInfluencersByContentPlatform(influencers) {
    if (this.hasInfluencersFromMultiplePlatforms() && this.state.contentPlatforms.length === 1) {
      const onlyContentPlatform = this.state.contentPlatforms[0];

      return partition(influencers, influencer => {
        if (onlyContentPlatform === 'twitch') {
          return influencer.twitchChannels.length > 0;
        }
        if (onlyContentPlatform === 'youtube') {
          return influencer.youtubeChannels.length > 0;
        }
        return false;
      })[0];
    }
    return influencers;
  }

  renderCards(influencers) {
    const { sortDirection, pageLimit } = this.state;
    const sortByValue = this.state.sortBy;
    const {
      cardSortOptions,
      card,
      getCard,
      isLoading,
      cardPlaceholder: CardPlaceholder
    } = this.props;

    if (isLoading && CardPlaceholder) {
      return (
        <div className="columns is-multiline">
          {Array(6)
            .fill()
            .map((value, index) => {
              return (
                <div
                  key={`placeholder-${index}`}
                  className="column is-one-third-desktop is-half-tablet">
                  <CardPlaceholder />
                </div>
              );
            })}
        </div>
      );
    }

    const sortOption = find(cardSortOptions, { attribute: sortByValue });
    const sortedInfluencers = sortByValue
      ? sortBy(influencers, influencer => {
          // for whatever reason if we can't find the sort option
          // just return 1 here so that all cards will be treated as equal
          if (!sortOption) return 1;
          if (typeof sortOption.sort === 'function') {
            return sortOption.sort(influencer, sortByValue);
          }
          // Figure out how to handle cases where one content platform has some attributes
          // but the other one doesn't, like YT subscriberCount vs Twitch followerCount
          return (
            get(influencer, `youtubeChannels[0].${sortByValue}`) ||
            get(influencer, `twitchChannels[0].${sortByValue}`) ||
            get(influencer, `instagramChannels[0].${sortByValue}`) ||
            get(influencer, `bilibiliChannels[0].${sortByValue}`) ||
            0
          );
        })
      : influencers;

    if (sortByValue && sortDirection === 'desc') {
      sortedInfluencers.reverse();
    }

    // this basically inlines `this.processInfluencersBeforeRendering`, because
    // `this.props.processInfluencersBeforeRendering` might change the order
    // of already-sorted influencers, and we need to keep track of the
    // `positionOnList` of each influencer for, well, tracking purposes.
    const processedInfluencers = this.props.processInfluencersBeforeRendering(
      sortedInfluencers,
      sortByValue
    );
    const influencersToRender = this.props.pagination
      ? sliceData(processedInfluencers, this.state)
      : processedInfluencers;

    const cards = influencersToRender.map((influencer, index) => {
      let cardProps = this.props.cardProps;
      const positionOnList = findIndex(processedInfluencers, partial(isEqual, influencer));
      const totalListSize = processedInfluencers.length;

      // we want to track the first position as 1 instead of 0. non-existing index is still -1
      const asBaseOneIndex = i => {
        return i < 0 ? -1 : i + 1;
      };

      if (typeof cardProps === 'function')
        cardProps = cardProps(influencer, {
          positionOnPage: asBaseOneIndex(index),
          positionOnList: asBaseOneIndex(positionOnList),
          totalListSize: totalListSize,
          sortDirection: sortDirection,
          sortBy: sortByValue,
          pageLimit
        });

      const props = Object.assign({}, cardProps, {
        influencer,
        pageInfo: {
          positionOnPage: asBaseOneIndex(index),
          positionOnList: asBaseOneIndex(positionOnList),
          totalListSize: totalListSize,
          sortDirection: sortDirection,
          sortBy: sortByValue,
          pageLimit
        }
      });

      const { channel, contentPlatform } = getFirstAvailableContentPlatform(influencer) || {};

      // TODO tan 27.12.2019 we should probably unify this having 1 prop overwrites another
      // is confusing. Maybe just have `cardComponent` as either a component or a function
      const CardComponent = getCard ? getCard(influencer) : card;
      const cardWrapperClassName =
        this.props.cardWrapperClassName || 'column is-one-third-desktop is-half-tablet';

      // MM-1047: passing the selected tab to CardComponent like this: defaultTab={this.props.defaultTab || this.state.defaultTab}
      // and in the case of this.props.defaultTab being 0 and this.state.defaultTab undefined will result in defaultTab
      // being set to undefined because 0 equals false
      props.defaultTab =
        this.props.defaultTab !== undefined ? this.props.defaultTab : this.state.defaultTab;

      return (
        <div
          className={cardWrapperClassName}
          key={`${channel && channel.id}-${contentPlatform}-${influencer.id}`}>
          <CardComponent {...props} />
        </div>
      );
    });

    return <div className="columns is-multiline">{cards}</div>;
  }

  renderList(influencers) {
    const ListComponent = this.props.list;
    const props = Object.assign(
      {
        influencers: this.processInfluencersBeforeRendering(influencers)
      },
      this.props.listProps
    );

    return <ListComponent {...props} />;
  }

  renderInfluencers(influencers) {
    const { displayMode } = this.state;

    if (displayMode === 'card') {
      return this.renderCards(influencers);
    } else if (displayMode === 'list') {
      return this.renderList(influencers);
    }

    return <div>Unsupported mode</div>;
  }

  renderSearchInput = () => {
    const { searchBy } = this.props;

    if (!searchBy || !searchBy.length) {
      return null;
    }

    return this.props.renderSearchInput();
  };

  render() {
    if (!this.props.influencers) {
      return null;
    }

    const influencersMatchedSearchInput = this.props.getDataMatchedSearchInput(
      this.props.influencers,
      this.props.searchBy
    );
    const influencersByContentPlatform = this.filterInfluencersByContentPlatform(
      influencersMatchedSearchInput
    );

    const className = `InfluencerCollection InfluencerCollection--${this.state.displayMode}`;

    if (!this.props.influencers.length && !this.props.isLoading) {
      return (
        <div className={className + ' has-text-centered'}>{this.renderInfluencerCount(0)}</div>
      );
    }
    const totalCount = influencersByContentPlatform.length;
    const topPagination = this.props.pagination
      ? this.renderPagination(totalCount)
      : this.renderSortOptions();

    let bottomPagination = this.renderPagination(totalCount);
    if (bottomPagination) {
      bottomPagination = (
        <div className="InfluencerCollection__bottom-pagination">{bottomPagination}</div>
      );
    }

    return (
      <div className={className}>
        <Element name="topOfInfluencerCollection" />
        {this.renderToolbar()}

        <div className="InfluencerCollection__results-info is-flex-tablet">
          <div className="InfluencerCollection__total-influencer">
            {this.props.isLoading ? (
              <FormattedMessage id="influencer.collection.loading" />
            ) : (
              this.renderInfluencerCount(totalCount)
            )}
            {this.renderSearchInput()}
          </div>
          <div className="InfluencerCollection__top-pagination">{topPagination}</div>
        </div>

        <div className="InfluencerCollection__influencers">
          {this.renderInfluencers(influencersByContentPlatform)}
        </div>

        <div className="InfluencerCollection__results-info is-flex-tablet">{bottomPagination}</div>
      </div>
    );
  }
}

function injectUserSettings(WrappedComponent) {
  class InjectUserSettings extends Component {
    static displayName = `InjectUserSettings(${
      Component.displayName || Component.name || 'Component'
    })`;

    render() {
      // although we have this.props.name to distinguish between different influencer collections
      // we want them to share the same saved settings
      // change it to this.props.name || 'influencerCollection' if we want to share per-collection settings
      const name = 'influencerCollection';
      const settings = get(getUser(), `settings.${name}`, {});

      const extraProps = {
        name,
        cardInitialSortAttribute: settings.sortProp,
        cardInitialSortDirection: settings.ascending ? 'asc' : 'desc' // desc is the default in InfluencerCollection
      };

      if (settings.displayMode) {
        extraProps.displayMode = settings.displayMode;
      }

      return <WrappedComponent {...Object.assign({}, this.props, extraProps)} />;
    }
  }

  return InjectUserSettings;
}

InfluencerCollection = injectIntl(InfluencerCollection);
InfluencerCollection = injectUserSettings(InfluencerCollection);
InfluencerCollection = injectSearchInputController()(InfluencerCollection);

InfluencerCollection.propTypes = {
  // used to
  // - update user settings (prefered sort options for example)
  name: PropTypes.string,
  // TODO add influencer shape
  influencers: PropTypes.arrayOf(PropTypes.object),
  getCard: PropTypes.func,
  card: PropTypes.func,
  // this is rendered in place of the real card when being loaded
  cardPlaceholder: PropTypes.func,
  cardProps: PropTypes.oneOfType([
    // when cardProps is an object, it includes a `pageInfo = {index, sortDirection, sortBy, pageLimit}`
    PropTypes.object,
    // use a function here when you want to return different props
    // for each card.
    // signature: (influencer, pageInfo) same pageInfo as above
    PropTypes.func
  ]),
  cardWrapperClassName: PropTypes.string,
  list: PropTypes.func,
  listProps: PropTypes.object,
  displayMode: PropTypes.oneOf(Object.keys(DISPLAY_MODES)),
  // first option is used as the default sort option if no sortBy given
  cardSortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      // will be passed to get(influencer, `youtubeChannels[0].${key}`)
      attribute: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        // will be passed to FormattedMessage as id
        PropTypes.string,
        PropTypes.element
      ]).isRequired
    })
  ),
  cardInitialSortAttribute: PropTypes.string,
  cardInitialSortDirection: PropTypes.oneOf(['asc', 'desc']),
  onChangeSettings: PropTypes.func,
  // This function is called after sorting influencers based on
  // current criteria (for card mode only) and before apply pagination logic
  // Use case for this function is when we need to move in-network influencers
  // to the top of the list
  processInfluencersBeforeRendering: PropTypes.func,
  pagination: PropTypes.bool,
  isLoading: PropTypes.bool
};

InfluencerCollection.defaultProps = {
  name: 'influencerCollection',
  card: () => null,
  cardProps: {},
  tableProps: {},
  displayMode: Object.keys(DISPLAY_MODES)[0],
  onChangeSettings: () => {},
  processInfluencersBeforeRendering: influencers => {
    return influencers;
  },
  pagination: true
};

export default InfluencerCollection;
