import { Async } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { useAsync } from 'react-async';
import React, { useState } from 'react';
import callApi from '../../helpers/api';

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import ActionableNotification from '../common/ActionableNotification';

import './CreateInvoiceItemForm.scss';

const sanitizeFields = data => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === '' || key.startsWith('__')) {
      delete data[key];
    }
  });
};

async function getTeamOptions() {
  const path = '/teams';
  const res = await callApi(path, {
    method: 'GET'
  });
  const teamOptions = res.data.map(team => {
    return { value: team.id, label: `[${team.id}] ${team.name}` };
  });
  return { options: teamOptions, complete: true };
}

async function getCampaignOptions() {
  const path = '/admin/dashboard/campaigns';
  const res = await callApi(path, {
    method: 'GET'
  });
  const campaignOptions = res.data
    .filter(campaign => !campaign.archived)
    .filter(
      campaign =>
        campaign.contentPlatforms.length === 1 &&
        campaign.contentPlatforms[0] === ContentPlatform.YOUTUBE
    )
    .sort((a, b) => (a.created > b.created ? -1 : a.created < b.created ? 1 : 0))
    .slice(0, 500)
    .map(campaign => {
      return {
        value: campaign.id,
        label: `[${campaign.id}] ${campaign.name}`,
        team_id: campaign.team.id,
        name: campaign.name
      };
    });
  return { options: campaignOptions, complete: true };
}

const CreateInvoiceItemForm = () => {
  const [message, setMessage] = useState({});
  const [metadataFields, setMetadataFields] = useState([{ key: null, value: null }]);

  const { register, getValues, setValue, control, handleSubmit, reset, formState } = useForm({
    shouldUnregister: true,
    defaultValues: {
      amount: null,
      campaign: null,
      campaign_id: null,
      customer_id: null,
      campaign_name: null,
      category: null,
      description: null,
      invoicing_date: null,
      invoice_item_id: null
    },
    mode: 'onBlur'
  });

  const { isValid, errors } = formState;

  const { run: createInvoiceItems, isLoading: isSavingInvoiceItem } = useAsync({
    deferFn: async () => {
      setMessage({});
      const data = getValues();
      sanitizeFields(data);

      for (const item of metadataFields) {
        if (item.key !== null && item.key !== '' && item.value !== null && item.value !== '') {
          data[item.key] = item.value;
        }
      }
      return callApi('/admin/invoice-items', {
        method: 'POST',
        body: data
      });
    },
    onResolve: ({ data }) => {
      const invoiceId = data ? data.id : 'no_id';
      setMessage({
        type: 'success',
        text: `Invoice item ${invoiceId} succesfully created`
      });
      reset();
    },
    onReject: e =>
      setMessage({
        type: 'danger',
        text: `Error when saving invoice item: ${e.message || 'Unknown error'}`
      })
  });

  async function onTeamSelect(teamOption) {
    if (teamOption) {
      setValue('customer_id', teamOption.value);
    } else {
      setValue('customer_id', null);
    }
  }

  async function onCampaignSelect(campaignOption) {
    if (campaignOption) {
      setValue('__campaign', campaignOption.value);
      setValue('campaign_name', campaignOption.name);
      setValue('campaign_id', campaignOption.value);
      setValue('customer_id', campaignOption.team_id);
    } else {
      setValue('campaign', null);
      setValue('campaign_name', null);
      setValue('campaign_id', null);
      setValue('customer_id', null);
    }
  }

  const onMetadataAdd = () => {
    const values = [...metadataFields];
    values.push({ key: null, value: null });
    setMetadataFields(values);
  };

  const onMetadataRemove = idx => {
    const items = [...metadataFields];
    items.splice(idx, 1);
    setMetadataFields(items);
  };

  const onMetadataChange = (idx, event, attribute) => {
    const items = [...metadataFields];
    items[idx][attribute] = event.target.value;
    setMetadataFields(items);
  };

  return (
    <form className="CreateInvoiceItemForm" onSubmit={handleSubmit(createInvoiceItems)}>
      <div className="section mb-5">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="campaign" className="label">
                Select campaign
              </label>
              <div className="control">
                <Controller
                  name="__campaign"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Async
                      {...field}
                      placeholder="Select campaign if applicable..."
                      loadOptions={getCampaignOptions}
                      onChange={onCampaignSelect}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Invoice item details</h2>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="customer_id" className="label">
                Customer (required)
              </label>
              <div className="control">
                <Controller
                  name="customer_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Async
                      {...field}
                      loadOptions={getTeamOptions}
                      onChange={onTeamSelect}
                      className="Option"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="category" className="label">
                Category (required)
              </label>
              <div className="control">
                <select
                  {...register('category', { required: 'Category is required' })}
                  className="input">
                  <option value="Big deal">Big deal</option>
                  <option value="Buyout">Buyout</option>
                  <option value="deal">deal</option>
                  <option value="credit">credit</option>
                  <option value="cost adjustment">cost adjustment</option>
                  <option value="prepayment">prepayment</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="campaign_name" className="label">
                Campaign name (optional)
              </label>
              <div className="control">
                <input
                  {...register('campaign_name')}
                  className="input"
                  type="text"
                  placeholder="Name for the campaign"
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="campaign_id" className="label">
                Campaign ID (optional)
              </label>
              <div className="control">
                <input
                  {...register('campaign_id')}
                  className="input"
                  type="text"
                  placeholder="<campaign id> or custom for external campaigns"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="deal_id" className="label">
                Deal ID (optional)
              </label>
              <div className="control">
                <input
                  {...register('deal_id')}
                  className="input"
                  type="number"
                  placeholder="<id> for a deal, if applicable"
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="payout_id" className="label">
                Payout ID (optional)
              </label>
              <div className="control">
                <input
                  {...register('payout_id', {
                    pattern: /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/
                  })}
                  className="input"
                  type="text"
                  placeholder="<uuid> of a related payout, if applicable (e.g 123e4567-e89b-12d3-a456-426614174000)"
                />
              </div>
              {errors.payout_id && <p className="help is-danger">Payout id must be a uuid</p>}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="campaign_name" className="label">
                Amount (required)
              </label>
              <div className="control">
                <input
                  {...register('amount', { required: 'Amount is required' })}
                  className="input"
                  pattern="^-?\d{1,18}(\.\d{2})?$"
                  placeholder="Enter amount in USD (negative for discounts)"
                />
                <p className="help is-info">Amount format example: -10, -5.12, 6.12, 10</p>
              </div>
              {errors.amount && <p className="help is-danger">{errors.amount.message}</p>}
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="description" className="label">
                Invoicing date (required)
              </label>
              <div className="control">
                <input
                  {...register('invoicing_date', { required: 'Invoicing date is required' })}
                  className="input"
                  type="date"
                  placeholder="Date for the invoice item"
                />
              </div>
              {errors.invoicing_date && (
                <p className="help is-danger">{errors.invoicing_date.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="description" className="label">
                Description (required)
              </label>
              <div className="control">
                <textarea
                  {...register('description', { required: 'Must provide a description' })}
                  className="textarea"
                  placeholder="Detailed description for the invoice item"
                />
              </div>
              {errors.description && <p className="help is-danger">{errors.description.message}</p>}
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="invoice_item_id" className="label">
                Invoice Item ID (optional)
              </label>
              <div className="control">
                <input
                  {...register('invoice_item_id', {
                    pattern: /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/
                  })}
                  className="input"
                  type="text"
                  placeholder="<uuid> of a related invoice_item_id, if applicable (e.g 123e4567-e89b-12d3-a456-426614174000)"
                />
              </div>
              {errors.invoice_item_id && (
                <p className="help is-danger">Invoice Item ID must be a uuid</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Custom metadata</h2>
        <div className="columns">
          <div className="column is-half">
            {metadataFields.map((item, idx) => {
              return (
                <div className="field metadata__field" key={`metadata-${idx}`}>
                  <input
                    className="input"
                    type="text"
                    value={item.key || ''}
                    placeholder="key"
                    onChange={event => onMetadataChange(idx, event, 'key')}
                  />
                  <input
                    className="input"
                    type="text"
                    value={item.value || ''}
                    placeholder="value"
                    onChange={event => onMetadataChange(idx, event, 'value')}
                  />
                  <button
                    type="button"
                    className="button"
                    disabled={metadataFields.length <= 1}
                    onClick={() => onMetadataRemove(idx)}>
                    Remove
                  </button>
                </div>
              );
            })}
            <div className="field">
              <button type="button" className="button" onClick={() => onMetadataAdd()}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="field mt-5">
        <div className="control">
          <button
            className={`button is-primary is-medium ${isSavingInvoiceItem && 'is-loading'}`}
            disabled={!isValid}
            type="submit">
            Save
          </button>
        </div>
        {message && (
          <div className="field">
            <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
          </div>
        )}
      </div>
    </form>
  );
};

export default CreateInvoiceItemForm;
