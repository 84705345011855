import React from 'react';

const EyeIcon = props => (
  <svg width="100%" viewBox="0 0 128 65" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M92.4 32c0 15.074-12.559 27.294-28.05 27.294-15.491 0-28.05-12.22-28.05-27.294S48.859 4.706 64.35 4.706C79.841 4.706 92.4 16.926 92.4 32"
        fill="#2F3968"
      />
      <path d="M63.5 18.353v20.47" stroke="#55CDD7" strokeWidth="11.9" />
      <path fill="#55CDD7" d="M49.9 35.412l13.6 15.353 13.6-15.353z" />
      <path
        d="M63.5 3C39.41 3 17.95 14.332 4 32c13.95 17.668 35.41 29 59.5 29 24.089 0 45.55-11.332 59.5-29C109.05 14.332 87.589 3 63.5 3z"
        stroke="#2F3968"
        strokeWidth="5.1"
      />
    </g>
  </svg>
);

export default EyeIcon;
