import React from 'react';

const CPIIcon = props => {
  const { primaryColor, secondaryColor, ...restProps } = props;
  return (
    <svg viewBox="0 0 94 52" height="100%" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fillRule="nonzero" strokeWidth="4" fill="none" strokeLinecap="round">
        <path stroke={primaryColor} d="M50 37v10h42V37" />
        <path stroke={primaryColor} d="M80 30l-9 9-9-9M75 8a6 6 0 0 1-6 6H59a6 6 0 1 1 0-12h4" />
        <path d="M67 8a6 6 0 0 1 6-6h10a6 6 0 1 1 0 12h-4M71 38V22" stroke={primaryColor} />
        <path
          d="M19 15c0-4.647-3.5-8-8-8s-8.5 2.853-8.5 7.5 3 7.5 8.5 9.486c5.5 1.985 9 4.514 9 9.104 0 4.59-4.03 8.455-9 8.455s-9-3.786-9-8.455"
          stroke={primaryColor}
          strokeLinejoin="round"
        />
        <path d="M11 7V3M11 45v-3" stroke={primaryColor} />
        <path d="M27 50L39 2" stroke={secondaryColor} />
      </g>
    </svg>
  );
};

export default CPIIcon;
