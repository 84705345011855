// @flow
import * as React from 'react';
import classNames from 'classnames';

import './OnOffToggle.scss';

type Props = {
  name?: string,
  onClick: (isOn: boolean) => void,
  isLoading?: boolean,
  isDisabled?: boolean,
  isOn?: boolean,
  isRadio?: boolean,
  className?: string,
  children?: React.Node
};

const OnOffToggle = (props: Props) => {
  const {
    name,
    isLoading = false,
    isDisabled = false,
    isOn = false,
    onClick,
    isRadio = false,
    className = '',
    children
  } = props;
  const finalClassName = classNames(className, 'OnOffToggle button', {
    'is-loading': isLoading
  });

  const iconClass = `${
    isOn && !isLoading
      ? isRadio
        ? 'radio_button_checked'
        : 'check_box'
      : isRadio
      ? 'radio_button_unchecked'
      : 'check_box_outline_blank'
  }`;

  return (
    <button
      name={name}
      className={finalClassName}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        if (isDisabled || isLoading) return;

        onClick(!isOn);
      }}
      disabled={isDisabled}>
      <i className="material-icons">{iconClass}</i>
      {children}
    </button>
  );
};

export default OnOffToggle;
