// @flow
import {
  Tabs as TabsEnum,
  getInvitedInfluencers,
  getNegotiatingInfluencers,
  getParticipatingInfluencers,
  getSortingOptions,
  isTagFilterTab,
  isValidTab,
  tagFromTabId
} from './helper';
import React, { Component } from 'react';
import Tabs from './Tabs';
import debounce from 'lodash/debounce';
import type { CampaignForPublisher } from '../../../types/campaign.flow';
import type { IntlShape } from 'react-intl';

import AllInNetworkTab from './AllInNetworkTab';
import NegotiationTab from './NegotiationTab';
import ParticipantTab, { DealTagTab, NoDealTagsTab } from './ParticipantTab';
import UnverifiedConfirmation from './UnverifiedConfirmation';

// TODO: Passing only props related to this component, right now is getting all props from PublisherCampaignPage
// Not all props are reflected here, just displaying the ones we use
type Props = {
  campaign: CampaignForPublisher,
  user: { verified: boolean },
  allInNetworkInfluencers: any[], // Influencer[]
  activeTab: string,
  error: any,
  intl: IntlShape,
  isNegotiatingCampaign: boolean,
  creatingCampaignAgreement: any, // Agreement
  isAcceptingCampaign: boolean,
  acceptingCampaignAgreement: any, // Agreement
  acceptedCampaignAgreement: any, // Agreement
  onAcceptCampaign: () => void,
  declineCampaign: () => void,
  isDecliningCampaign: boolean,
  decliningCampaignAgreement: any, // Agreement
  fetchAllInNetworkInfluencers: (campaignId: number, query?: string) => void,
  onChangeSettings: () => void,
  isFetchingAllInNetworkInfluencers: boolean,
  isCancelingDeal: boolean,
  onShowDeclineOfferModal: any
};

type State = {
  selectedTab: string,
  currentPage: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  filterKeyword: string
};

function getNegotiatingTab(campaign) {
  return (
    (getNegotiatingInfluencers(campaign).length || getInvitedInfluencers(campaign).length) &&
    TabsEnum.NEGOTIATIONS
  );
}

function getParticipatingTab(campaign) {
  return getParticipatingInfluencers(campaign).length && TabsEnum.PARTICIPANTS;
}

class CampaignTabsSection extends Component<Props, State> {
  debouncedFetchAllInNetworkInfluencers: (campaignId: number, query?: string) => void;

  constructor(props: Props) {
    super(props);

    const { campaign, fetchAllInNetworkInfluencers } = props;
    const sortingOptions = getSortingOptions(campaign.defaultContentPlatform);

    this.state = {
      selectedTab: getNegotiatingTab(campaign) || getParticipatingTab(campaign),
      currentPage: 1,
      pageSize: 24,
      sortBy:
        sortingOptions && sortingOptions.length ? sortingOptions[0].attribute : 'estimatedViews',
      sortDirection: 'desc',
      filterKeyword: ''
    };

    this.debouncedFetchAllInNetworkInfluencers = debounce(fetchAllInNetworkInfluencers, 300, {
      leading: true
    });
  }

  componentDidMount() {
    const { campaign, activeTab } = this.props;
    // In case we don't have activeTab we need to load data by default in case we need it
    const selectedTab = activeTab || this.state.selectedTab;

    if (isValidTab(selectedTab)) {
      if (selectedTab === TabsEnum.ALL) {
        this.debouncedFetchAllInNetworkInfluencers(campaign.id);
      }
    }
  }

  renderActiveSection() {
    if (!this.props.user.verified) {
      return <UnverifiedConfirmation />;
    }

    return (
      <div className="CampaignViewForPublisher__tab-content">{this.getSelectedTabContent()}</div>
    );
  }

  getSelectedTabContent() {
    const { selectedTab } = this.state;

    if (selectedTab === TabsEnum.ALL) {
      const {
        allInNetworkInfluencers,
        campaign,
        isFetchingAllInNetworkInfluencers,
        isNegotiatingCampaign,
        creatingCampaignAgreement
      } = this.props;

      return (
        <AllInNetworkTab
          campaign={campaign}
          influencers={allInNetworkInfluencers}
          isFetching={isFetchingAllInNetworkInfluencers}
          isNegotiatingCampaign={isNegotiatingCampaign}
          creatingCampaignAgreement={creatingCampaignAgreement}
          onFetchAllInNetworkInfluencers={this.debouncedFetchAllInNetworkInfluencers}
        />
      );
    }

    if (selectedTab === TabsEnum.NEGOTIATIONS) {
      const {
        campaign,
        error,
        isAcceptingCampaign,
        acceptingCampaignAgreement,
        isNegotiatingCampaign,
        creatingCampaignAgreement,
        isDecliningCampaign,
        decliningCampaignAgreement,
        onChangeSettings,
        onShowDeclineOfferModal
      } = this.props;

      return (
        <NegotiationTab
          campaign={campaign}
          error={error}
          isAcceptingCampaign={isAcceptingCampaign}
          acceptingCampaignAgreement={acceptingCampaignAgreement}
          isNegotiatingCampaign={isNegotiatingCampaign}
          creatingCampaignAgreement={creatingCampaignAgreement}
          isDecliningCampaign={isDecliningCampaign}
          decliningCampaignAgreement={decliningCampaignAgreement}
          onChangeSettings={onChangeSettings}
          onShowDeclineOfferModal={onShowDeclineOfferModal}
        />
      );
    }

    if (selectedTab === TabsEnum.PARTICIPANTS) {
      const { campaign, onChangeSettings, isCancelingDeal } = this.props;

      return (
        <ParticipantTab
          campaign={campaign}
          onChangeSettings={onChangeSettings}
          isLoading={isCancelingDeal}
        />
      );
    }

    if (isTagFilterTab(selectedTab)) {
      const { campaign, onChangeSettings, isCancelingDeal } = this.props;
      const dealTag = tagFromTabId(selectedTab);
      return (
        <DealTagTab
          campaign={campaign}
          onChangeSettings={onChangeSettings}
          isLoading={isCancelingDeal}
          dealTag={dealTag}
        />
      );
    }

    if (selectedTab === TabsEnum.NO_DEAL_TAGS) {
      const { campaign, onChangeSettings, isCancelingDeal } = this.props;

      return (
        <NoDealTagsTab
          campaign={campaign}
          onChangeSettings={onChangeSettings}
          isLoading={isCancelingDeal}
        />
      );
    }

    return null;
  }

  render() {
    const { campaign, user } = this.props;

    return (
      <div id="campaign-tabs">
        <Tabs
          activeTab={this.state.selectedTab}
          campaign={campaign}
          user={user}
          selectSection={this.onSelectTab}
        />
        {this.renderActiveSection()}
      </div>
    );
  }

  onSelectTab = (tabId: string) => {
    return (event: SyntheticEvent<*>) => {
      event.preventDefault();
      event.stopPropagation();
      this.selectTab(tabId);
    };
  };

  selectTab = (tabId: string) => {
    const { campaign, allInNetworkInfluencers } = this.props;

    if (tabId === TabsEnum.ALL && !(allInNetworkInfluencers || []).length) {
      // Let's fetch all in network influencers after the tab is clicked
      this.debouncedFetchAllInNetworkInfluencers(campaign.id);
    }

    this.setState({ selectedTab: tabId });
  };
}

export default CampaignTabsSection;
