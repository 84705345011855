// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';

import './DeclineReasonMessage.scss';

const otherKey = 'other';
const i18DeclineMessages = {
  influencer_content_inappropriate: 'campaign.agreement.decline.influencerContentInappropriate',
  influencer_audience_inappropriate: 'campaign.agreement.decline.influencerAudienceInappropriate',
  price_high: 'campaign.agreement.decline.priceHigh',
  channel_small: 'campaign.agreement.decline.channelSmall',
  channel_big: 'campaign.agreement.decline.channelBig',
  publisher_content_inappropriate: 'campaign.agreement.decline.publisherContentInappropriate',
  price_low: 'campaign.agreement.decline.priceLow',
  publisher_deal_inappropriate: 'campaign.agreement.decline.publisherDealInappropriate',
  campaign_not_available: 'campaign.agreement.decline.campaignNotAvailable'
};

type Props = {
  declineReason: { other: string, predefined: string[] } | null
};

export const DeclineReasonMessage = ({ declineReason }: Props) => {
  if (declineReason === null) {
    // In old declined messages, declineReason is null, so we check before continuing
    return null;
  }
  const declineKeys = declineReason.predefined || [];
  const reasons = declineKeys.map((key, index) => {
    return <FormattedMessage key="negotiationMessageOfferDecline" id={i18DeclineMessages[key]} />;
  });
  reasons.push(<span>{declineReason[otherKey]}</span>);
  return (
    <div className="DeclineReasonMessage">
      <span className="DeclineReasonMessage__label">
        <FormattedMessage
          key="negotiationMessageOfferDecline"
          id={`campaign.agreement.decline.reason`}
        />
        :
      </span>
      {reasons.reduce((prev, curr) => [prev, ', ', curr])}
    </div>
  );
};

export default DeclineReasonMessage;
