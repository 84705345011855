// @flow
import * as React from 'react';
import classNames from 'classnames';

import './ToggleContainer.scss';

type Props = {
  children: React.Node,
  title: string,
  className?: string
};

const ToggleContainer = ({ children, title, className }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const body = !isOpen ? null : children;

  return (
    <div className={classNames('ToggleContainer', className)}>
      <div className="field is-horizontal no-margin-bottom ToggleContainer__header">
        <div className="field-body">
          <div className="field ToggleContainer__header-toggle">
            <div
              onClick={onToggle}
              className={classNames({
                'ToggleContainer__header--open': isOpen,
                'ToggleContainer__header--close': !isOpen
              })}>
              <h2 className="ToggleContainer__header--title">
                <span>{title}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      {body}
    </div>
  );
};

export default ToggleContainer;
