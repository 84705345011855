// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DiscordIcon from '../../images/icons/Discord';
// $FlowFixMe$
import Headroom from 'react-headroom';
import Link from 'react-router/lib/Link';
import isEqual from 'lodash/isEqual';

import { Role } from '@sharkpunch/matchmade-common/user';
import type { Role as RoleType, Token } from 'matchmade-types';

import { getChats } from '../../actions/chat';
import ChatIcon from '../common/Icons/ChatIcon';
import ChatList from '../chat/ChatList';
import compose from '../../hoc/compose';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';
import getConfig, { generateApiPath } from '../../config';
import injectPageVisibilityChecking from '../../hoc/injectPageVisibilityChecking';
import injectRoleChecking from '../../hoc/injectRoleChecking';
import userAgent from '../../helpers/userAgent';
import type { ExtraProps as RoleCheckingProps } from '../../hoc/injectRoleChecking';

import logo from '../../images/themes/matchmade/logo-small.svg';

import './TopNavigationBar.scss';

const Avatar = ({ avatarUrl, email }: { avatarUrl: ?string, email: string }) => {
  if (!avatarUrl) return null;
  return <img referrerPolicy="no-referrer" src={avatarUrl} alt={email} />;
};

const UserName = ({ displayName, email }: { displayName: ?string, email: string }) => {
  return <span className="TopNavigationBar__name">{displayName || email}</span>;
};

const InfluencerLinks = ({ renderInvitesLink }: { renderInvitesLink: boolean }) => {
  return (
    <React.Fragment>
      <Link className="navbar-item" activeClassName="is-active" to="/dashboard/influencer">
        <FormattedMessage id="nav.myDashboard" />
      </Link>
      <Link
        className="navbar-item"
        activeClassName="is-active"
        to="/settings/social-media-accounts?utm_source=top_nav">
        <FormattedMessage id="nav.socialMediaAccounts" />
      </Link>
      {renderInvitesLink ? (
        <Link
          activeClassName="is-active"
          className="navbar-item has-text-primary"
          to="/dashboard/influencer/invites"
          key="invites">
          <FormattedMessage id="nav.invites" />
          <span className="icon">
            <i className="fa fa-bell" />
          </span>
        </Link>
      ) : null}
      <Link
        className="navbar-item"
        activeClassName="is-active"
        to={{
          pathname: 'https://matchmade.helpscoutdocs.com/collection/6-creators'
        }}
        target="_blank">
        <FormattedMessage id="nav.faqInHeader" />
      </Link>
    </React.Fragment>
  );
};

type ManagedInfluencer = {
  influencerId: number,
  channelName: string
};
const InfluencerManagerLinks = ({
  influencers,
  hasInvites
}: {
  influencers: ?(ManagedInfluencer[]),
  hasInvites: boolean
}) => {
  const invitesLink = hasInvites ? (
    <Link
      className="navbar-item"
      activeClassName="is-active"
      to="/dashboard/influencerManager/invites"
      key="invites">
      <FormattedMessage id="nav.invites" />
    </Link>
  ) : null;

  const inviteInfluencersLink = (
    <Link
      className="navbar-item"
      activeClassName="is-active"
      to="/dashboard/influencerManager/import">
      <FormattedMessage id="nav.influencerManager.inviteInfluencers" />
    </Link>
  );

  let influencersLink = hasInvites ? (
    <Link
      className="navbar-item"
      activeClassName="is-active"
      to="/dashboard/influencerManager/influencers">
      <FormattedMessage id="nav.influencerManager.influencers" />
    </Link>
  ) : null;

  if (influencers && influencers.length) {
    influencersLink = (
      <Link
        className="navbar-item"
        activeClassName="is-active"
        to="/dashboard/influencerManager/influencers"
        key="influencers">
        <FormattedMessage id="nav.influencerManager.influencers" />
      </Link>
    );
  }

  return (
    <React.Fragment>
      {influencersLink}
      {invitesLink}
      {inviteInfluencersLink}
    </React.Fragment>
  );
};

const PublisherLinks = ({ teamId, userEmail }: { teamId?: number, userEmail?: string }) => {
  return (
    <React.Fragment>
      <Link className="navbar-item" activeClassName="is-active" to="/dashboard/publisher">
        <FormattedMessage id="nav.myDashboard" />
      </Link>
      {teamId ? (
        <Link
          key="my-team"
          className="navbar-item"
          activeClassName="is-active"
          to={`/dashboard/publisher/teams/${teamId}`}>
          <FormattedMessage id="nav.myTeam" />
        </Link>
      ) : null}
    </React.Fragment>
  );
};

const AdminLinks = ({ userEmail }: { userEmail?: string }) => {
  return (
    <React.Fragment>
      {/* Search */}
      <div className="navbar-item has-dropdown is-hoverable" key="search">
        <div className="navbar-link">
          <FormattedMessage id="nav.search" />
        </div>
        <div className="navbar-dropdown">
          <Link
            to="/influencers/youtube/search"
            key="influencers/youtube"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.search.youtube" />
          </Link>
          <Link
            to="/influencers/twitch/search"
            key="influencers/twitch"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.search.twitch" />
          </Link>
        </div>
      </div>
      {/* User dashboards */}
      <div className="navbar-item has-dropdown is-hoverable" key="users">
        <div className="navbar-link">
          <FormattedMessage id="nav.admin.users" />
        </div>

        <div className="navbar-dropdown">
          <Link
            to="/admin/influencers"
            key="influencers"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.influencers" />
          </Link>
          <Link
            to="/admin/managers"
            key="influencer-managers"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.managers" />
          </Link>
          <Link
            to="/admin/publishers"
            key="publishers"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.publishers" />
          </Link>
        </div>
      </div>
      {/* Teams */}
      <div className="navbar-item has-dropdown is-hoverable" key="teams">
        <div className="navbar-link">
          <FormattedMessage id="nav.admin.teams" />
        </div>

        <div className="navbar-dropdown">
          <Link to="/admin/teams" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.publishers" />
          </Link>
          <Link to="/admin/manager-teams" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.managers" />
          </Link>
        </div>
      </div>
      {/* Collections */}
      <div className="navbar-item has-dropdown is-hoverable">
        <Link to="/admin/collections" className="navbar-link" activeClassName="is-active">
          <FormattedMessage id="nav.collections" />
        </Link>
        <div className="navbar-dropdown">
          <Link
            to="/admin/collections/combine-youtube"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.collections.combineYoutubeCollection" />
          </Link>
          <Link
            to="/admin/collections/combine-instagram"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.collections.combineInstagramCollection" />
          </Link>
          <Link
            to="/admin/collections/add-channels"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.collections.addChannels" />
          </Link>
          <Link
            to="/admin/collections/add-twitch-channels"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.collections.addTwitchChannels" />
          </Link>
          <Link
            to="/admin/collections/add-instagram-channels"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.collections.addInstagramChannels" />
          </Link>
        </div>
      </div>
      {/* Campaigns */}
      <div className="navbar-item has-dropdown is-hoverable CampaignLink" key="campaignsDropdown">
        <Link
          className="navbar-link"
          activeClassName="is-active"
          to="/admin/campaigns"
          key="campaigns">
          <FormattedMessage id="nav.admin.campaigns" />
        </Link>
        <div className="navbar-dropdown">
          <Link
            to="/admin/campaigns/new"
            key="new-campaign"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.newCampaign" />
          </Link>
          <Link
            to="/admin/campaigns/allocated-budget"
            key="allocated-budget"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.allocatedBudget" />
          </Link>
        </div>
      </div>
      {/* Email */}
      <div className="navbar-item has-dropdown is-hoverable EmailLink" key="emailDropdown">
        <Link to="/admin/email" key="email" className="navbar-link" activeClassName="is-active">
          <FormattedMessage id="nav.admin.email" />
        </Link>
        <div className="navbar-dropdown">
          <a
            href="https://notifications.matchmade.tv/preview"
            key="emailv2"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-item">
            <FormattedMessage id="nav.admin.email.v2" />
          </a>
        </div>
      </div>
      <Link
        to="/admin/redirect-links"
        key="redirect-links"
        className="navbar-item"
        activeClassName="is-active">
        <FormattedMessage id="nav.admin.redirectLinks" />
      </Link>
      <div className="navbar-item has-dropdown is-hoverable" key="api-explorer-dropdown">
        <div className="navbar-link">
          <FormattedMessage id="nav.admin.apiExplorer" />
        </div>
        <div className="navbar-dropdown">
          <Link
            to="/admin/api-explorer/youtube"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.apiExplorer.youtube" />
          </Link>

          <Link
            to="/admin/api-explorer/facebook"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.apiExplorer.facebook" />
          </Link>

          <Link
            to="/admin/api-explorer/ig-posts"
            className="navbar-item"
            activeClassName="is-active">
            <FormattedMessage id="nav.admin.apiExplorer.IGPostData" />
          </Link>
        </div>
      </div>
      <div className="navbar-item has-dropdown is-hoverable" key="finance-dropdown">
        <div className="navbar-link">
          <FormattedMessage id="nav.admin.finance" />
        </div>
        <div className="navbar-dropdown">
          <Link to="/admin/payouts/new" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.newPayout" />
          </Link>
          <Link to="/admin/invoices/new-item" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.newInvoiceItem" />
          </Link>
          <Link to="/admin/payouts/update" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.payoutUpdate" />
          </Link>
          <Link to="/admin/invoices/status" className="navbar-item" activeClassName="is-active">
            <FormattedMessage id="nav.admin.invoiceStatusUpdate" />
          </Link>
        </div>
      </div>
      <Link to="/admin/budgets" key="budgets" className="navbar-item" activeClassName="is-active">
        <FormattedMessage id="nav.admin.budgets" />
      </Link>
    </React.Fragment>
  );
};

type Props = {|
  ...RoleCheckingProps,
  isPageVisible: boolean,
  role: RoleType,
  location: {
    pathname: string
  },
  user: {
    token: Token,
    contactEmail?: string,
    displayName: string,
    avatarUrl: ?string,
    email: string,
    hasAcceptedTos: boolean,
    hasInvites: boolean,
    verified: boolean,
    influencers: ?(ManagedInfluencer[]),
    invites: ?({}[]),
    managers: ?({ managerName: string }[]),
    teams: ?({ id: number, name: string }[])
  },
  disableHeadroom?: boolean,
  chats: any[],
  isChatsLoading: boolean,
  getChats: () => void
|};

type State = {
  menuOpened: boolean,
  chatOpened: boolean
};

class TopNavigationBar extends React.Component<Props, State> {
  static defaultProps = {
    chats: []
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      menuOpened: false,
      chatOpened: false
    };
  }

  _fetchChatsInterval = null;
  chatIconDesktop = null;
  chatIconMobile = null;

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const simpleProps = [
      'disableHeadroom',
      'isAnonymous',
      'isInfluencer',
      'isManagedInfluencer',
      'isInfluencerManager',
      'isInfluencerOrManager',
      'isPageVisible',
      'isPublisher',
      'isPublisherOrWhitelabelAdmin',
      'isWhitelabelAdmin'
    ];

    if (!isEqual(this.state, nextState)) {
      return true;
    }

    return (
      !isEqual(nextProps.chats, this.props.chats) ||
      simpleProps.some(attr => {
        return this.props[attr] !== nextProps[attr];
      }) ||
      !isEqual(nextProps.user, this.props.user)
    );
  }

  componentDidMount() {
    this.runFetchingChats();
    document.addEventListener('click', this.onChatClose, false);
  }

  componentWillUnmount() {
    this.stopFetchingChats();

    document.removeEventListener('click', this.onChatClose, false);
  }

  componentDidUpdate(prevProps: Props) {
    const { isPageVisible } = this.props;

    if (prevProps.isPageVisible !== isPageVisible) {
      isPageVisible ? this.runFetchingChats() : this.stopFetchingChats();
    }
  }

  runFetchingChats = () => {
    const { getChats } = this.props;

    if (!this.isChatAvailable()) {
      return;
    }

    getChats();

    if (!this._fetchChatsInterval) {
      this._fetchChatsInterval = setInterval(getChats, 10000);
    }
  };

  stopFetchingChats = () => {
    if (this._fetchChatsInterval) {
      clearInterval(this._fetchChatsInterval);
      this._fetchChatsInterval = null;
    }
  };

  renderManagedAccountNotification() {
    const { isManagedInfluencer } = this.props;
    if (!isManagedInfluencer) {
      return null;
    }

    const { teams } = this.props.user;
    if (!teams || !teams.length) {
      return null;
    }

    return (
      <div className="notification has-text-centered is-info is-paddingless is-marginless">
        <FormattedMessage
          id="influencer.managedAccount"
          values={{ teamName: <strong>{teams[0].name}</strong> }}
        />
      </div>
    );
  }

  renderActingOnBehalfNotification() {
    // List of paths where we want to display "you're acting on behalf" notification for managers
    const influencersPaths = [
      /\/influencers\/([0-9]+)/,
      /\/campaigns\/[0-9]+\/influencers\/([0-9]+)/
    ];
    // Render similar to "managed account" notification, but for Managers.
    const { user, location } = this.props,
      { pathname } = location;
    if (!user || !this.props.isInfluencerManager) {
      return null;
    }

    let influencerId,
      notification = null;
    influencersPaths.forEach(p => {
      if (influencerId || notification) {
        return;
      }

      const match = pathname.match(p);
      influencerId = parseInt(match && match[1], 10);
      if (!influencerId) {
        return;
      }

      const influencers = this.props.user.influencers;
      // We assume that influencer has only one manager
      if (!influencers || !influencers.length) {
        return null;
      }

      const influencer = influencers.find(i => i.influencerId === influencerId);
      if (!influencer || !influencer.channelName) {
        return null;
      }

      notification = (
        <div className="notification has-text-centered is-info is-paddingless is-marginless">
          <FormattedMessage
            id="influencerManager.actingOnBehalf"
            values={{ channelName: <strong>{influencer.channelName}</strong> }}
          />
        </div>
      );
    });
    return notification;
  }

  onChatClose = (e: any) => {
    if (this.chatIconDesktop && this.chatIconDesktop.contains(e.target)) {
      return;
    }

    if (this.chatIconMobile && this.chatIconMobile.contains(e.target)) {
      return;
    }

    this.setState({ chatOpened: false });
  };

  onChatToggle = () => {
    this.setState(prevState => ({ chatOpened: !prevState.chatOpened }));
  };

  isChatAvailable = () => {
    const { isManagedInfluencer, user = {} } = this.props;
    const tosAccepted = user && user.hasAcceptedTos;

    return tosAccepted && !isManagedInfluencer;
  };

  render() {
    const {
      isWhitelabelAdmin,
      isPublisher,
      isAnonymous,
      isInfluencer,
      isInfluencerManager,
      role,
      user = {}
    } = this.props;

    if (isAnonymous) {
      return null;
    }
    const tosAccepted = user && user.hasAcceptedTos;

    const influencerProfile = isInfluencer && user.token.accountId && (
      <Link className="navbar-item" to="/my-profile">
        <FormattedMessage id="nav.myProfile" />
      </Link>
    );

    const paymentInfo = isPublisher && (
      <Link className="navbar-item" to="/payment-info">
        <FormattedMessage id="nav.paymentInfo" />
      </Link>
    );

    const logout = (
      <a className="navbar-item" id="logout-button" href={generateApiPath('/auth/logout')}>
        <FormattedMessage id="nav.logout" />
      </a>
    );

    const tos = !isWhitelabelAdmin && (
      <Link className="navbar-item" to={'/tos'}>
        <FormattedMessage id="nav.tos" />
      </Link>
    );

    let faqUrl = null;
    if (isInfluencer) {
      faqUrl = 'https://matchmade.helpscoutdocs.com/collection/6-creators';
    } else if (isInfluencerManager) {
      faqUrl = 'https://matchmade.helpscoutdocs.com/collection/17-managers-and-agencies';
    } else if (isPublisher) {
      faqUrl = 'https://matchmade.helpscoutdocs.com/';
    } else {
      faqUrl = 'https://matchmade.helpscoutdocs.com/';
    }
    const faq = (
      <a className="navbar-item" target="_blank" rel="noopener noreferrer" href={faqUrl}>
        <FormattedMessage id="nav.faq" />
      </a>
    );

    const join = (
      <>
        <hr className="navbar-divider is-block"></hr>
        <div className="navbar-item">
          <p className="menu-label">
            <FormattedMessage id="nav.joinCommunity" />
          </p>
        </div>
        <a
          className="navbar-item is-flex"
          href="https://discord.gg/gehTNTnNn5"
          target="_blank"
          rel="noopener noreferrer">
          <DiscordIcon />
          <FormattedMessage id="nav.joinCommunity.discord" />
        </a>
      </>
    );

    const userMenu = (
      <div className="navbar-dropdown is-hidden-touch is-right">
        {influencerProfile}
        {paymentInfo}
        {tos}
        {faq}
        {logout}
        {join}
      </div>
    );

    const mobileUserMenu = [influencerProfile, paymentInfo, logout];

    const logoLink = getConfig(`features.landingUrls.${role}`, '/404');

    let links;
    switch (role) {
      case Role.PUBLISHER:
        const team = (user.teams && user.teams[0]) || {};
        links = <PublisherLinks teamId={team.id} userEmail={user.email} />;
        break;
      case Role.INFLUENCER_MANAGER:
        links = user.verified ? (
          <InfluencerManagerLinks influencers={user.influencers} hasInvites={user.hasInvites} />
        ) : (
          []
        );
        break;
      case Role.WHITELABEL_ADMIN:
      case Role.ADMIN:
        links = <AdminLinks userEmail={user.email} />;
        break;
      case Role.INFLUENCER: {
        links = <InfluencerLinks renderInvitesLink={user.hasInvites} />;
        break;
      }
      default:
        links = [];
    }

    const isAdmin = !!user.token.adminAccountId;
    const backToAdmin = isAdmin ? (
      <a
        className="navbar-item"
        href={generateApiPath('/auth/delete-legacy-token')}
        key="backToAdmin">
        <FormattedMessage id="nav.backToAdmin" />
      </a>
    ) : null;

    const chats = this.props.chats || [];
    const count = chats.filter(chat => chat.isNew).length;
    const shouldRenderChatNotification = this.isChatAvailable();

    const chatComponent = shouldRenderChatNotification ? (
      <ChatList
        chats={chats}
        isLoading={this.props.isChatsLoading}
        onChatClick={this.onChatToggle}
      />
    ) : null;

    return (
      <Headroom className="TopNavigationBar" disable={this.props.disableHeadroom}>
        {this.renderManagedAccountNotification()}
        <nav className="navbar container" id="top">
          <div className="navbar-brand">
            <Link className="navbar-item TopNavigationBar__logo" to={tosAccepted ? logoLink : ''}>
              <figure className="image">
                <img src={logo} alt="Matchmade" />
              </figure>
            </Link>
            {shouldRenderChatNotification && userAgent.isMobile() && (
              <div className="chat-burger">
                <button
                  className="navbar-link is-arrowless"
                  ref={node => (this.chatIconMobile = node)}
                  onClick={this.onChatToggle}>
                  <ChatIcon count={count} />
                </button>
              </div>
            )}

            <div
              className={`navbar-burger ${this.state.menuOpened ? ' is-active' : ''}`}
              onClick={() => this.setState({ menuOpened: !this.state.menuOpened })}>
              <span />
              <span />
              <span />
            </div>
          </div>

          {shouldRenderChatNotification && userAgent.isMobile() && (
            <div className={`navbar-menu ${this.state.chatOpened ? ' is-active' : ''}`}>
              {chatComponent}
            </div>
          )}
          <div className={`navbar-menu ${this.state.menuOpened ? ' is-active' : ''}`}>
            <div className="navbar-start">
              {tosAccepted && links} {backToAdmin}
            </div>
            <div className="navbar-end">
              <div className="is-hidden-desktop">{this.state.menuOpened && mobileUserMenu}</div>
              {shouldRenderChatNotification && !userAgent.isMobile() && (
                <div
                  className={`navbar-item has-dropdown ${
                    this.state.chatOpened ? ' is-active' : ''
                  }`}>
                  <button
                    className="navbar-link is-arrowless"
                    ref={node => (this.chatIconDesktop = node)}
                    onClick={this.onChatToggle}>
                    <ChatIcon count={count} />
                  </button>
                  <div className="navbar-dropdown chat-dropdown is-right">
                    <div className="navbar-item chat-item">{chatComponent}</div>
                  </div>
                </div>
              )}

              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">
                  <div className="level is-mobile">
                    <div className="level-left">
                      <div className="level-item is-hidden-desktop TopNavigationBar__avatar">
                        <Avatar avatarUrl={user.avatarUrl} email={user.email} />
                      </div>
                      <div className="level-item">
                        <UserName
                          displayName={user.displayName}
                          email={user.contactEmail || user.email}
                        />
                      </div>
                    </div>
                    <div className="level-right is-hidden-touch TopNavigationBar__avatar">
                      <div className="level-item">
                        <Avatar avatarUrl={user.avatarUrl} email={user.email} />
                      </div>
                    </div>
                  </div>
                </div>
                {userMenu}
                <div className="is-hidden-desktop">{this.state.menuOpened && join}</div>
              </div>
            </div>
          </div>
        </nav>
        {this.renderActingOnBehalfNotification()}
      </Headroom>
    );
  }
}

const mapStateToProps = generateStateToProps('navigation');
const mapDispatchToProps = generateDispatchToProps({
  getChats
});

TopNavigationBar = connect(mapStateToProps, mapDispatchToProps)(TopNavigationBar);

export default compose(injectPageVisibilityChecking, injectRoleChecking())(TopNavigationBar);
