import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ReactYoutube from 'react-youtube';

import FormattedNumber from '../common/FormattedNumber';

import { FormattedMessage, injectIntl } from 'react-intl';

import './InfluencerCardVideo.scss';
class InfluencerCardVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playingVideo: false
    };

    this.onClickVideoThumbnail = this.onClickVideoThumbnail.bind(this);
  }

  onClickVideoThumbnail() {
    this.setState({ playingVideo: true });
  }

  renderVideoStatsTable() {
    const { video, channel } = this.props;

    const { likeCount = 0, commentCount = 0, viewCount = 0 } = video;
    const last30dStats = channel.last30dStats || {};

    // just trust the server here and don't check for negative numbers
    const averageViewCount = last30dStats.averageViewCount || 0;
    const averageCommentCount = last30dStats.averageCommentCount || 0;
    const averageLikeCount = last30dStats.averageLikeCount || 0;
    const averageEngagementRatio =
      averageViewCount > 0 ? (averageLikeCount + averageCommentCount) / averageViewCount : 0;

    return (
      <table className="table is-narrow is-fullwidth">
        <thead>
          <tr>
            <th />
            <th>
              <span
                title={this.props.intl.formatMessage({
                  id: 'influencer.searchV1Card.video.views'
                })}
                className="icon is-small">
                <i className="fa fa-eye" />
              </span>
            </th>
            <th>
              <span
                title={this.props.intl.formatMessage({
                  id: 'influencer.searchV1Card.video.comments'
                })}
                className="icon is-small">
                <i className="fa fa-comment-o" />
              </span>
            </th>
            <th>
              <span
                title={this.props.intl.formatMessage({
                  id: 'influencer.searchV1Card.video.likes'
                })}
                className="icon is-small">
                <i className="fa fa-thumbs-o-up" />
              </span>
            </th>
            <th>
              <span
                title={this.props.intl.formatMessage({
                  id: 'influencer.searchV1Card.video.engagement'
                })}
                className="icon is-small">
                <i className="fa fa-percent" />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="has-text-left">
              {this.props.intl.formatMessage({
                id: 'influencer.searchV1Card.video'
              })}
            </td>
            <td>
              <FormattedNumber type="number" value={video.viewCount} />
            </td>
            <td>
              <FormattedNumber type="number" value={video.commentCount} />
            </td>
            <td>
              <FormattedNumber type="number" value={video.likeCount} />
            </td>
            <td>
              <FormattedNumber type="percentage" value={(likeCount + commentCount) / viewCount} />
            </td>
          </tr>
          <tr>
            <td className="has-text-left">
              {this.props.intl.formatMessage({
                id: 'influencer.collection.display.table.last30dAvg'
              })}
            </td>
            <td>
              <FormattedNumber type="number" value={averageViewCount} />
            </td>
            <td>
              <FormattedNumber type="number" value={averageCommentCount} />
            </td>
            <td>
              <FormattedNumber type="number" value={averageLikeCount} />
            </td>
            <td>
              <FormattedNumber type="percentage" value={averageEngagementRatio} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderVideoCountLast30d() {
    const { channel } = this.props;

    return (
      <small>
        <FormattedMessage
          id="influencer.searchV1Card.videos.30d"
          values={{ count: channel.last30dStats.totalVideoCount }}
        />
      </small>
    );
  }

  render() {
    const { channel, video } = this.props;

    if (!channel && !video) return null;

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    const imageOrVideo = this.state.playingVideo ? (
      <ReactYoutube videoId={video.id} opts={opts} />
    ) : (
      <div
        className="InfluencerCardVideo__video-thumbnail"
        onClick={this.onClickVideoThumbnail}
        style={{ backgroundImage: `url(${video.thumbnailUrl})` }}>
        <i className="fa fa-play" />{' '}
        <span className="InfluencerCardVideo__video-title">{video.title}</span>
      </div>
    );

    return (
      <div className="InfluencerCardVideo">
        <p className="has-text-centered">Recent example video:</p>
        <div className="card-image">{imageOrVideo}</div>
        <div className="InfluencerCardVideo__video-stats">{this.renderVideoStatsTable()}</div>
        <div className="InfluencerCardVideo__video-last-30d">{this.renderVideoCountLast30d()}</div>
      </div>
    );
  }
}

InfluencerCardVideo = injectIntl(InfluencerCardVideo);

InfluencerCardVideo.propTypes = {
  channel: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired
};

export default InfluencerCardVideo;
