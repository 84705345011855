import { countryCodes } from '@sharkpunch/matchmade-common/countryCodes';
import { useForm } from 'react-hook-form';

//$FlowFixMe
import { useAsync } from 'react-async';

import React, { useState } from 'react';

import ActionableNotification from '../../components/common/ActionableNotification';
import Spinner from '../../components/common/Spinner';

import callApi from '../../helpers/api';

const getPaymentInfo = async ({ teamId }) => {
  return callApi(`/admin/publisher-teams/${teamId}/payment-info`);
};

const InnerTeamPaymentInfoForm = props => {
  const [message, setMessage] = useState({});
  const {
    register,
    getValues,
    formState: { isValid, errors }
  } = useForm({
    defaultValues: props.existingPaymentInfo || {},
    mode: 'onChange'
  });

  const submitService = useAsync({
    deferFn: async () => {
      setMessage({});
      if (isValid) {
        await props.update(getValues());
      } else {
        throw new Error('Form data not valid, have you filled all required form fields?');
      }
    },
    onResolve: () => {
      setMessage({
        type: 'success',
        text: `Successfully saved payment info`
      });
    },
    onReject: e => {
      setMessage({
        type: 'danger',
        text: `Error when saving payment info: ${e.message || 'Unknown error'}`
      });
    }
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        submitService.run();
      }}>
      <div className="field">
        <label className="label">Company name</label>
        <div className="control">
          <input {...register('companyName')} className="input" type="text" placeholder="ACME Oy" />
        </div>
        {errors.companyName?.type === 'required' && (
          <p class="help is-danger">Company name is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Country</label>
        <div className="control">
          <div className="select">
            <select {...register('companyCountry')}>
              {Object.entries(countryCodes).map(([code, label]) => {
                return (
                  <option value={code} key={code}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">City</label>
        <div className="control">
          <input {...register('companyCity', { required: true })} className="input" type="text" />
        </div>
        {errors.companyCity?.type === 'required' && (
          <p class="help is-danger">Company city is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Postal code</label>
        <div className="control">
          <input
            {...register('companyPostalCode', { required: true })}
            className="input"
            type="text"
          />
        </div>
        {errors.companyPostalCode?.type === 'required' && (
          <p class="help is-danger">Company postal code is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Street address</label>
        <div className="control">
          <input
            {...register('companyStreetAddress', { required: true })}
            className="input"
            type="text"
          />
        </div>
        {errors.companyStreetAddress?.type === 'required' && (
          <p class="help is-danger">Company street address is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Company number</label>
        <div className="control">
          <input {...register('companyNumber')} className="input" type="text" />
        </div>
      </div>
      <div className="field">
        <label className="label">EIN</label>
        <div className="control">
          <input {...register('companyEin')} className="input" type="text" />
        </div>
      </div>
      <div className="field">
        <label className="label">VAT code</label>
        <div className="control">
          <input {...register('companyVat')} className="input" type="text" />
        </div>
      </div>
      <div className="field">
        <label className="label">Contact name</label>
        <div className="control">
          <input {...register('contactName', { required: true })} className="input" type="text" />
        </div>
        {errors.contactName?.type === 'required' && (
          <p class="help is-danger">Contact name is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Contact phone number</label>
        <div className="control">
          <input {...register('contactPhoneNumber')} className="input" type="text" />
        </div>
      </div>
      <div className="field">
        <label className="label">Contact email</label>
        <div className="control">
          <input {...register('contactEmail', { required: true })} className="input" type="text" />
        </div>
        {errors.contactEmail?.type === 'required' && (
          <p class="help is-danger">Contact email is required</p>
        )}
      </div>
      <div className="field">
        <label className="label">Invoice email</label>
        <div className="control">
          <input {...register('invoiceEmail', { required: true })} className="input" type="text" />
        </div>
        {errors.invoiceEmail?.type === 'required' && (
          <p class="help is-danger">Invoice email is required</p>
        )}
      </div>
      <div className="field">
        <div className="control">
          <button
            className={`button is-primary ${submitService.isLoading && 'is-loading'}`}
            type="submit">
            Save
          </button>
        </div>
      </div>
      {message && (
        <div className="field">
          <ActionableNotification type={message.type}>{message.text}</ActionableNotification>
        </div>
      )}
    </form>
  );
};

const TeamPaymentInfoForm = props => {
  const paymentInfo = useAsync({ promiseFn: getPaymentInfo, teamId: props.teamId });

  if (paymentInfo.isLoading) {
    return <Spinner mode="fullWidth" size="large" />;
  } else if (paymentInfo.error) {
    return (
      <ActionableNotification type="danger">{paymentInfo.error.message}</ActionableNotification>
    );
  } else {
    return (
      <InnerTeamPaymentInfoForm
        existingPaymentInfo={paymentInfo.data.data}
        update={async data => {
          return await callApi(`/admin/publisher-teams/${props.teamId}/payment-info`, {
            method: 'POST',
            body: data
          });
        }}
      />
    );
  }
};

export default TeamPaymentInfoForm;
