// @flow

import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import React from 'react';

import Papa from 'papaparse';
import flatten from 'flat';

import saveFile from '../../helpers/saveFile';

import './DataExporter.scss';

function exporterFactory(filename, data) {
  return function () {
    const flattenedData = (data || []).map(flatten);
    const keys = uniq(flatMap(flattenedData, Object.keys));
    const csvData = Papa.unparse({ data: flattenedData, fields: keys });
    saveFile(filename, csvData, 'text/csv');
  };
}

const DataExporter = (props: { data: Array<any> }) => {
  return (
    <div className="DataExporter">
      <button className="link-button" onClick={exporterFactory('data.csv', props.data)}>
        Export all
      </button>
    </div>
  );
};

export default DataExporter;
