// @flow
import {
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection,
  changeName,
  linkCollectionToCampaign,
  removeCollectionFromCampaign,
  removeInstagramChannelFromCollection,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection
} from '../../actions/collection';
import { getCampaignsByName } from '../../actions/campaignSearch';
import { updateSettings } from '../../actions/account';
import collectionReducer from '../common/collection';
import type { Action } from '../../types/action.flow';
import type { CollectionWithIncludedFields } from '../../types/collection.flow';

type State = {|
  isFetchingCollection: boolean,
  isSearchingCampaigns: boolean,
  campaigns: { id: number, name: string, team: string }[],
  collection: CollectionWithIncludedFields | null,
  isCreatingCollection: boolean,
  isAddingChannelToCollection: boolean,
  isRemovingChannelFromCollection: boolean,
  isUpdatingAccountSettings: boolean,
  user?: Object,
  error: Object | null
|};

const initialState: State = Object.freeze({
  isFetchingCollection: true,
  isSearchingCampaigns: true,
  collection: null,
  campaigns: [],
  isCreatingCollection: false,
  isAddingChannelToCollection: false,
  isRemovingChannelFromCollection: false,
  isUpdatingAccountSettings: false,
  error: null
});

export default function publisherCollectionPageReducer(
  state: State = initialState,
  action: Action
): State {
  state = collectionReducer.getCollection(state, action);
  state = collectionReducer.createCollection(state, action);

  switch (action.type) {
    case addYoutubeChannelToCollection.REQUEST:
    case addTwitchChannelToCollection.REQUEST:
      return {
        ...state,
        isAddingChannelToCollection: true
      };
    case addTwitchChannelToCollection.SUCCESS:
      return {
        ...state,
        isAddingChannelToCollection: false
      };
    case addYoutubeChannelToCollection.FAILURE:
    case addTwitchChannelToCollection.FAILURE:
      return {
        ...state,
        isAddingChannelToCollection: false,
        error: action.payload
      };
    case removeYoutubeChannelFromCollection.REQUEST:
    case removeTwitchChannelFromCollection.REQUEST:
    case removeInstagramChannelFromCollection.REQUEST:
      return {
        ...state,
        isRemovingChannelFromCollection: true
      };
    case removeYoutubeChannelFromCollection.SUCCESS:
      return {
        ...state,
        collection:
          (state.collection &&
            '' + state.collection.id === action.payload.id && {
              ...state.collection,
              influencers: state.collection.influencers.filter(influencer => {
                return (
                  influencer.youtubeChannels &&
                  action.payload.channels.includes(influencer.youtubeChannels[0].id)
                );
              }),
              channels: action.payload.channels
            }) ||
          state.collection,
        isRemovingChannelFromCollection: false
      };
    case removeTwitchChannelFromCollection.SUCCESS:
      return {
        ...state,
        collection:
          (state.collection &&
            '' + state.collection.id === action.payload.id && {
              ...state.collection,
              influencers: state.collection.influencers.filter(influencer => {
                return (
                  (influencer.twitchChannels || []).length &&
                  action.payload.channels.includes(influencer.twitchChannels[0].id)
                );
              }),
              twitchChannels: action.payload.twitchChannels
            }) ||
          state.collection,
        isRemovingChannelFromCollection: false
      };
    case removeInstagramChannelFromCollection.SUCCESS:
      return {
        ...state,
        collection:
          (state.collection &&
            '' + state.collection.id === action.payload.id && {
              ...state.collection,
              influencers: state.collection.influencers.filter(influencer => {
                return (
                  (influencer.instagramChannels || []).length &&
                  action.payload.channels.includes(influencer.instagramChannels[0].id)
                );
              }),
              instagramChannels: action.payload.instagramChannels
            }) ||
          state.collection,
        isRemovingChannelFromCollection: false
      };
    case removeYoutubeChannelFromCollection.FAILURE:
    case removeTwitchChannelFromCollection.FAILURE:
    case removeInstagramChannelFromCollection.FAILURE:
      return {
        ...state,
        isRemovingChannelFromCollection: false,
        error: action.payload
      };
    case updateSettings.REQUEST:
      return {
        ...state
      };
    case updateSettings.SUCCESS:
      const user = state['user'];

      if (user) {
        state = {
          ...state,
          user: {
            ...user,
            settings: action.payload.settings
          }
        };
      }

      return {
        ...state
      };
    case updateSettings.FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case getCampaignsByName.REQUEST:
      return {
        ...state,
        isSearchingCampaigns: true
      };
    case getCampaignsByName.SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        isSearchingCampaigns: false
      };
    case getCampaignsByName.FAILURE:
      return {
        ...state,
        isSearchingCampaigns: false,
        error: action.payload
      };
    case linkCollectionToCampaign.SUCCESS:
    case removeCollectionFromCampaign.SUCCESS:
      return {
        ...state,
        collection: action.payload
      };
    case changeName.SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          name: action.payload.name
        }
      };
    case changeName.FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
