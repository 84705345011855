import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import ActionableNotification from './ActionableNotification';

// these errors need to be handled differently
// For now, just ignore them and let the form handle
// TODO have a common module for all these error codes
const IGNORED = [
  40000 // fail to validate input
];

// DEPRECATED, use useError to render a global error
class Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error
    };

    this.onClear = this.onClear.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ error: nextProps.error });
  }

  onClear() {
    this.setState({ error: null });
  }

  render() {
    const error = this.state.error;
    if (!error) {
      return null;
    }

    if (IGNORED.indexOf(error.code) !== -1) {
      return null;
    }

    if (this.props.autoCloseAfter) {
      setTimeout(() => {
        this.onClear();
      }, this.props.autoCloseAfter);
    }

    const i18n = error.i18n || {};

    const actions = [
      {
        action: this.onClear,
        label: <FormattedMessage id="form.close" />,
        icon: 'times'
      }
    ];

    return (
      <ActionableNotification type="danger" icon="exclamation-circle" actions={actions}>
        <FormattedMessage id={i18n.id || error.message} values={i18n.values || {}} />
      </ActionableNotification>
    );
  }
}

Error.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.number,
    message: PropTypes.string,
    params: PropTypes.object
  }),
  autoCloseAfter: PropTypes.number // ms
};

Error.defaultProps = {
  autoCloseAfter: 0
};

export default Error;
