// @flow
import * as React from 'react';

import './TermsNavigation.scss';

type Props = {
  items: { id: string, value: string }[]
};

export default class TermsNavigation extends React.PureComponent<Props> {
  render() {
    return (
      <div className="TermsNavigation">
        <ul className="TermsNavigation--scroller">
          {this.props.items &&
            this.props.items.map(i => (
              <li key={`TermsNavigation-${i.id}`}>
                <button className="link-button" onClick={() => this.onClickNavItem(i.id)}>
                  {i.value}
                </button>
              </li>
            ))}
        </ul>
      </div>
    );
  }

  onClickNavItem(id: string) {
    const item = document.querySelector('#' + id);
    if (item) {
      item.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
