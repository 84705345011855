// @flow
import * as React from 'react';
import AdvancedSettingsToggle from './AdvancedSettingsToggle';

import './AdvancedSettings.scss';

type State = {
  isOpen: boolean
};

type Props = {
  label: React.Element<*>,
  intro: React.Element<*>,
  tooltip?: React.Element<*>,
  isOpen?: boolean,
  children: any
};

function stateful(C: React.ComponentType<StatelessProps>): React.ComponentType<Props> {
  class StatefulAdvancedSettings extends React.Component<Props, State> {
    static defaultProps = {
      isOpen: false
    };

    constructor(props) {
      super(props);

      this.state = {
        isOpen: !!props.isOpen
      };
    }

    onToggle = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
      const props = {
        ...this.props,
        isOpen: this.state.isOpen,
        onToggle: this.onToggle
      };
      return <C {...props} />;
    }
  }
  return StatefulAdvancedSettings;
}

type StatelessProps = Props & {
  isOpen?: boolean,
  onToggle: () => void
};
// the whole advanced settings block
function AdvancedSettings(props: StatelessProps) {
  const body = !props.isOpen ? null : (
    <div className="field is-horizontal">
      <div className="field-label" />
      <div className="field-body AdvancedSettings__settings">{props.children}</div>
    </div>
  );

  return (
    <div className="AdvancedSettings">
      <AdvancedSettingsToggle {...props} />
      {body}
    </div>
  );
}

export default stateful(AdvancedSettings);
