import React, { Component } from 'react';

import compact from 'lodash/fp/compact';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import ChannelCountry from '../ChannelCountry';
import InfluencerContactInfo from '../InfluencerContactInfo';

import FormattedNumber from '../../common/FormattedNumber';

import ANALYTICS from '../../../helpers/analytics';
import GAOutboundLink from '../../common/GAOutboundLink';

import { getBiggestCountryGroup, getInfluencerByChannelId } from '../../../helpers/influencer';

import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';

import Table, { Column, GroupedColumn } from '../../tables/Table';

import './FollowedInfluencersTable.scss';
import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import YoutubeIcon from '../../common/Icons/YoutubeIcon';
import TwitchIcon from '../../common/Icons/TwitchIcon';

const getChannels = flow([
  map(({ youtubeChannels, twitchChannels }) => [
    ...(youtubeChannels || []).map(channel => ({ ...channel, platform: 'youtube' })),
    ...(twitchChannels || []).map(channel => ({ ...channel, platform: 'twitch' }))
  ]),
  flatten,
  compact
]);

class ChannelName extends Component {
  render() {
    const { name, id, platform } = this.props.rowData;

    let url = `https://www.youtube.com/channel/${id}`;

    if (platform === ContentPlatform.TWITCH) {
      url = `https://twitch.tv/${name}`;
    }

    // TODO: use internal link to influencer profile page
    // if the channel belongs to in-network influencer
    return (
      <div key={`collection-channel-${id}-name`}>
        <div className="FollowedInfluencersTable__channel-name">
          <GAOutboundLink
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            eventLabel={ANALYTICS.LABEL.PUBLISHER_CLICKED_CHANNEL_LINK_IN_FOLLOWED_INFLUENCER_CARD}
            className="FollowedInfluencersTable__channel-link responsive-truncate-contents"
            title={name}>
            {name}
          </GAOutboundLink>
        </div>
        <div className="FollowedInfluencersTable__contact-info responsive-truncate-contents">
          <InfluencerContactInfo youtubeChannel={this.props.rowData} />
        </div>
      </div>
    );
  }
}

class PlatformLogo extends React.PureComponent {
  render() {
    const { platform } = this.props.rowData;

    if (!platform) {
      return null;
    }

    return (
      <span className="PlatformLogo">
        {platform === ContentPlatform.YOUTUBE && <YoutubeIcon />}
        {platform === ContentPlatform.TWITCH && <TwitchIcon />}
      </span>
    );
  }
}

class FollowedInfluencersTable extends React.PureComponent {
  render() {
    const channels = getChannels(this.props.influencers);

    return (
      <Table
        data={channels}
        initialSort={this.props.initialSort}
        initialSortDirection={this.props.initialSortDirection}
        className="FollowedInfluencersTable is-fullwidth is-bordered">
        <Column name="name" sortDirectionCycle={['asc', 'desc']} component={ChannelName}>
          <FormattedMessage id="influencer.collection.display.table.name" />
        </Column>
        <Column name="platform" sortDirectionCycle={['asc', 'desc']} component={PlatformLogo}>
          <FormattedMessage id="influencer.collection.display.table.platform" />
        </Column>
        <Column
          name="videoCount"
          component={({ rowData }) => {
            const videoCount = rowData.videoCount;

            return <FormattedNumber type="number" value={videoCount} />;
          }}>
          <FormattedMessage id="influencer.collection.display.table.videos" />
        </Column>
        <Column
          name="subscriberCount"
          component={({ rowData }) => {
            const subscriberCount = rowData.subscriberCount;

            return <FormattedNumber type="number" value={subscriberCount} />;
          }}>
          <FormattedMessage id="influencer.collection.display.table.subscribers" />
        </Column>
        <Column
          name="viewCount"
          component={({ rowData }) => {
            const viewCount = rowData.viewCount;

            return <FormattedNumber type="number" value={viewCount} />;
          }}>
          <FormattedMessage id="influencer.collection.display.table.views" />
        </Column>

        <GroupedColumn
          title={<FormattedMessage id="influencer.collection.display.table.last30dAvg" />}>
          <Column
            name="last30dStats.averageViewCount"
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageViewCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.viewsInPastMonth" />
          </Column>
          <Column
            name="last30dStats.totalLikeCount"
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageLikeCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dLikes" />
          </Column>
          <Column
            name="last30dStats.totalCommentCount"
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageCommentCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dComments" />
          </Column>
          <Column
            name="last30dStats.averageEngagementRatio"
            component={({ rowData }) => {
              const value = rowData.last30dStats.averageEngagementRatio;

              return <FormattedNumber type="percentage" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.engagement" />
          </Column>
          <Column
            name="last30dStats.totalVideoCount"
            component={({ rowData }) => {
              const value = rowData.last30dStats.totalVideoCount;

              return <FormattedNumber type="number" value={value} />;
            }}>
            <FormattedMessage id="influencer.collection.display.table.30dVideos" />
          </Column>
        </GroupedColumn>

        <Column
          name="country"
          component={({ rowData }) => {
            const influencer = getInfluencerByChannelId(this.props.influencers, rowData.id);

            const { name: countryCode, isEstimated = false } =
              getBiggestCountryGroup(influencer) || {};

            return (
              <ChannelCountry
                countryCode={countryCode}
                isEstimated={isEstimated}
                onlyIcon
                textCentered={false}
              />
            );
          }}
          sortBy={(value, channel) => {
            const influencer = getInfluencerByChannelId(this.props.influencers, channel.id);
            const countryGroup = getBiggestCountryGroup(influencer);
            if (!countryGroup) return null;
            return countryGroup.name;
          }}>
          <FormattedMessage id="influencer.collection.display.table.country" />
        </Column>
      </Table>
    );
  }
}

FollowedInfluencersTable = injectIntl(FollowedInfluencersTable);

FollowedInfluencersTable.propTypes = Object.assign({
  // TODO add influencer shape
  influencers: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialSort: PropTypes.string,
  initialSortDirection: PropTypes.string
});

FollowedInfluencersTable.defaultProps = {
  initialSort: 'subscriberCount',
  initialSortDirection: 'desc'
};

export default FollowedInfluencersTable;
