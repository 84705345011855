// @flow
import React from 'react';

import './Avatar.scss';
import AccountIcon from '../common/Icons/AccountIcon';

const customSizes = [77, 88];
const bulmaSizes = [16, 24, 32, 48, 64, 96, 128];

type Shape = 'rectangle' | 'shield';

type Props = {
  url: ?string,
  size?: 16 | 24 | 32 | 48 | 64 | 77 | 88 | 96 | 128,
  shape?: ?Shape,
  alt?: string,
  isAdmin?: boolean
};

const Avatar = (props: Props) => {
  const { url, size = 96, shape, alt = 'avatar', isAdmin = false } = props;
  const sizeClass = `is-${size}x${size}`;

  let avatarStyle;
  switch (shape) {
    case 'rectangle':
      avatarStyle = 'Avatar Avatar--rectangle';
      break;
    case 'shield':
      avatarStyle = 'Avatar Avatar--shield';
      break;
    default:
      avatarStyle = 'Avatar';
  }

  // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/referrerPolicy
  // "no-referrer" meaning that the Referer: HTTP header will not be sent.
  // Avoids some YT and Bilibili and whatever avatar URL breakages
  // https://stackoverflow.com/a/53542897
  // Note on React: When using this in React, I found that it only works reliably when the referrerPolicy attribute is spelt with the capital P and appears before the src attribute.
  return (
    <div className={`${avatarStyle} ${sizeClass}`}>
      {url ? (
        <figure className={`image ${sizeClass}`}>
          <img referrerPolicy="no-referrer" src={url} alt={alt} />
        </figure>
      ) : (
        <AccountIcon className={sizeClass} />
      )}
      {isAdmin && <i className="Avatar__admin-icon material-icons">verified_user</i>}
    </div>
  );
};

export default Avatar;

export const allowedSizes: number[] = bulmaSizes.concat(customSizes); // for tests
