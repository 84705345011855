// @flow

// Refactoring in progress, all the things here will be
// eventually moved into separate reducers

import type { State as CampaignCreationState } from './containers/campaignCreation';
import type { State as EditCampaignState } from './containers/editCampaign';

import { useSelector } from 'react-redux';
// $FlowFixMe
import { combineReducers } from 'redux';
// $FlowFixMe
import { persistReducer } from 'redux-persist';
// $FlowFixMe
import storage from 'redux-persist/lib/storage';

// one common reducer containing the user info
import layout from './layout';

// each container has its own reducer which is a combination of common reducers
// and the container specific reducer
import adminDashboardAllocatedBudget from './containers/admin/adminDashboardAllocatedBudget';
import adminDashboardCampaignEdit from './containers/admin/adminDashboardCampaignEdit';
import adminDashboardCampaigns from './containers/admin/adminDashboardCampaigns';
import adminDashboardCollections from './containers/admin/adminDashboardCollections';
import adminDashboardEmail from './containers/admin/adminDashboardEmail';
import adminDashboardImportInfluencer from './containers/admin/adminDashboardImportInfluencer';
import adminDashboardInfluencers from './containers/admin/adminDashboardInfluencers';
import adminDashboardManagerTeams from './containers/admin/adminDashboardManagerTeams';
import adminDashboardManagers from './containers/admin/adminDashboardManagers';
import adminDashboardPublishers from './containers/admin/adminDashboardPublishers';
import adminDashboardTeam from './containers/admin/adminDashboardTeam';
import adminDashboardTeams from './containers/admin/adminDashboardTeams';
import adminManagerTeamPage from './containers/admin/adminManagerTeamPage';
import campaignCreation from './containers/campaignCreation';
import chat from './common/chat';
import createMockInfluencerPage from './containers/createMockInfluencerPage';
import editCampaign from './containers/editCampaign';
import influencerCampaignPage from './containers/influencerCampaignPage';
import influencerSettings from './common/influencerSettings';
import messagesPage from './containers/messagesPage';
import navigation from './common/navigation';
import paymentInfo from './common/paymentInfo';
import publisherCampaignPage from './containers/publisherCampaignPage';
import publisherCampaignReportPage from './containers/publisherCampaignReportPage';
import publisherCollectionPage from './containers/publisherCollectionPage';
import publicCollectionPage from './containers/publicCollectionPage';
import publisherDashboard from './containers/publisherDashboard';
import publisherTeamManagePage from './containers/publisherTeamManagePage';
import publisherYoutubeSearchPage from './containers/publisherYoutubeSearchPage';

import {
  userCollectionSelectorTriggerReducer,
  REDUCER_NAME as userCollectionSelectorTriggerReducerName
} from '../components/collection/StatefulCollectionSelectorTrigger';

import { SubStateName } from './constants';

export type RootState = {
  // This misses quite a bunch of types,
  // because we added Flow much later in development
  // state, and no one had time to fill these.
  // Sorry :(
  campaignCreation: CampaignCreationState,
  editCampaign: EditCampaignState
};

export function useReducerState(name: string) {
  return useSelector((state: RootState) => state[name]);
}

const rootReducer = combineReducers({
  adminDashboardAllocatedBudget,
  adminDashboardInfluencers,
  adminDashboardManagers,
  adminDashboardPublishers,
  adminDashboardCampaigns,
  adminDashboardCampaignEdit,
  adminDashboardEmail,
  adminDashboardTeams,
  adminDashboardTeam,
  adminDashboardImportInfluencer,
  adminDashboardManagerTeams,
  adminManagerTeamPage,
  adminDashboardCollections,
  layout,
  chat,
  paymentInfo,
  publisherCampaignPage,
  influencerSettings,
  navigation,
  influencerCampaignPage,
  publisherCampaignReportPage,
  publisherTeamManagePage,
  publisherYoutubeSearchPage,
  publisherCollectionPage,
  publicCollectionPage,
  messagesPage,
  createMockInfluencerPage,
  [SubStateName.CAMPAIGN_CREATION]: persistReducer(
    {
      key: 'campaignCreation',
      storage,
      whitelist: [
        'searchSettings',
        'importedGame',
        'searchResults',
        'campaignDescription',
        'productSelection',
        'campaignDetails',
        'createdByUserId'
      ]
    },
    campaignCreation
  ),
  editCampaign,
  publisherDashboard,

  // self-contained component reducers
  [userCollectionSelectorTriggerReducerName]: userCollectionSelectorTriggerReducer
});

export default rootReducer;
