import React, { Component } from 'react';

import { getContactInfo } from '../../helpers/influencer';
import channelShape from '../../shapes/youtubeChannel';

import './InfluencerContactInfo.scss';

import { FormattedMessage } from 'react-intl';

class InfluencerContactInfo extends Component {
  renderSocialNetworkLink(service, icon) {
    const contactInfo = this.getContactInfo() || {};
    const url = contactInfo[service];

    if (!url) return null;

    icon = icon || `fa-${service}`;
    const info = getContactInfo(contactInfo, service);

    return (
      <a
        className="responsive-truncate-contents InfluencerContactInfo__social"
        key={service}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        title={info}>
        <span className="icon is-small">
          <i className={`fa ${icon}`} />
        </span>
        <span>{info}</span>
      </a>
    );
  }

  getContactInfo() {
    return {
      ...(this.props.youtubeChannel || {}).contactInfo,
      ...(this.props.twitchChannel || {}).contactInfo
    };
  }

  render() {
    const contactInfo = this.getContactInfo();
    if (!contactInfo) return null;

    const { email, hasYouTubeBusinessEmail } = contactInfo;

    const linkClassName = 'responsive-truncate-contents InfluencerContactInfo__social';

    const fb = this.renderSocialNetworkLink('facebook');
    const tw = this.renderSocialNetworkLink('twitter');
    const insta = this.renderSocialNetworkLink('instagram');
    // TODO: TWITCH!
    // For Twitchers, render YT links and vice versa

    const emailAddress = email ? (
      <a
        className={linkClassName}
        key="email"
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
        title={email}>
        <span className="icon is-small">
          <i className="fa fa-envelope-o" />
        </span>
        <span>{email}</span>
      </a>
    ) : null;

    const url = this.props.youtubeChannel
      ? `https://www.youtube.com/channel/${this.props.youtubeChannel.id}/about`
      : `https://www.twitch.tv/${this.props.twitchChannel.name}`;

    const aboutPage =
      hasYouTubeBusinessEmail && !email ? (
        <a
          className={linkClassName}
          key="about"
          href={`${url}`}
          target="_blank"
          rel="noopener noreferrer">
          <span className="icon is-small">
            <i className="fa fa-envelope-o" />
          </span>
          <FormattedMessage id="influencer.collection.viewEmailAddress" />
        </a>
      ) : null;

    return (
      <div className="InfluencerContactInfo">
        {fb} {tw} {insta} {emailAddress} {aboutPage}
      </div>
    );
  }
}

InfluencerContactInfo.propTypes = {
  youtubeChannel: channelShape,
  twitchChannel: channelShape
};

export default InfluencerContactInfo;
