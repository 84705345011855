// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import './FooterActions.scss';

type Props = {
  showBackButton: boolean,
  disabled: boolean,
  isLoading: boolean,
  float: boolean,
  onNext: () => void,
  onBack: () => void,
  nextButtonText: string
};

FooterActions.defaultProps = {
  disabled: false,
  isLoading: false,
  float: false,
  showBackButton: true,
  onBack() {},
  nextButtonText: 'campaignCreation.step.next'
};

export default function FooterActions(props: Props) {
  const nextButton = (
    <button
      disabled={props.disabled || props.isLoading}
      onClick={props.onNext}
      className={`button is-primary FooterActions__next-button ${
        props.isLoading ? 'is-loading' : ''
      }`}>
      <FormattedMessage id={props.nextButtonText} />
    </button>
  );

  const backButton = props.showBackButton ? (
    <button
      disabled={props.isLoading}
      onClick={props.onBack}
      className="button is-text FooterActions__back-button">
      <i className="material-icons">chevron_left</i>
      <FormattedMessage id="campaignCreation.step.back" />
    </button>
  ) : (
    <div />
  );

  return (
    <div className={`FooterActions ${props.float ? 'FooterActions--floating' : ''}`}>
      <div className="container">
        {backButton}
        {nextButton}
      </div>
    </div>
  );
}
