// @flow
import { FormattedMessage } from 'react-intl';
import Mousetrap from 'mousetrap';
import React from 'react';
import find from 'lodash/find';

import { CampaignType, ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import { calculateYoutubePrices } from '@sharkpunch/matchmade-common/money';
import {
  getCpiFromAgreement,
  getCpmFromAgreement,
  getDealType,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement
} from '@sharkpunch/matchmade-common/campaignAgreement';

import { StatefulCollectionSelectorTrigger } from '../../collection/StatefulCollectionSelectorTrigger';
import { getEstimatedInstallsFromEstimates } from '../../../helpers/estimates';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { getGameTypeForEstimates } from '../../../helpers/game';
import { getInvitedInfluencers, getNegotiatingInfluencers, getSortingOptions } from './helper';
import { getViewsFromEstimates } from '../../../helpers/youtube';
import { shouldHideCpi } from '../../../helpers/campaign';
import InfluencerCollection from '../../influencer/InfluencerCollection';
import InfluencerInvitedCard from '../../influencer/InfluencerInvitedCard';
import InfluencerNegotiationCard from '../../influencer/InfluencerNegotiationCard';
import InfluencerProfileModalTrigger from '../../influencer/InfluencerProfileModalTrigger';
import type { CampaignForPublisher } from '../../../types/campaign.flow';

const searchBy = [
  'youtubeChannels[0].name',
  'twitchChannels[0].name',
  'instagramChannels[0].name',
  'instagramChannels[0].username'
];

const isNegotiating = (influencer: any, isNegotiatingCampaign, creatingCampaignAgreement) => {
  return (
    isNegotiatingCampaign &&
    creatingCampaignAgreement &&
    creatingCampaignAgreement.influencerId === influencer.id
  );
};

const isAccepting = (influencer: any, isAcceptingCampaign, acceptingCampaignAgreement) => {
  return (
    isAcceptingCampaign &&
    acceptingCampaignAgreement &&
    acceptingCampaignAgreement.influencerId === influencer.id
  );
};

const isDeclining = (influencer: any, isDecliningCampaign, decliningCampaignAgreement) => {
  return (
    isDecliningCampaign &&
    decliningCampaignAgreement &&
    decliningCampaignAgreement.influencerId === influencer.id
  );
};

const getNegotiationCardProps = ({
  influencer,
  campaign,
  error,
  isAcceptingCampaign,
  acceptingCampaignAgreement,
  isNegotiatingCampaign,
  creatingCampaignAgreement,
  isDecliningCampaign,
  decliningCampaignAgreement,
  onShowDeclineOfferModal
}) => {
  const gameType = getGameTypeForEstimates(campaign.game);
  const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);
  const { channel } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};

  // for invites, we don't have agreement...
  // so... for normal cases, use the agreement
  // for invites, construct a fake "agreement"
  // with the estimates
  const agreement: any = find(campaign.agreements, { influencerId: influencer.id }) || {};

  if (!channel) {
    console.warn('Corrupted influencer data', influencer);
    return { campaign, agreement, estimatedCost: 0 };
  }

  const channelName =
    (channel && channel.name) || (influencer.account && influencer.account.displayName) || '';
  const invite = influencer.invite;

  const promotionType = agreement.promotionType || campaign.defaultPromotionType;
  const contentPlatform =
    agreement.contentPlatform ||
    (invite && invite.contentPlatform) ||
    campaign.defaultContentPlatform;
  const youtubeChannel = influencer.youtubeChannels[0];
  const estimates = youtubeChannel && youtubeChannel.estimates;
  const estimatedInstalls = getEstimatedInstallsFromEstimates(estimates, promotionType, gameType);
  const adminActions = [
    <StatefulCollectionSelectorTrigger
      type="static"
      key="ApiConnectedCollectionSelectorTrigger"
      contentPlatform={contentPlatform}
      channelId={channel.id}
    />,
    (contentPlatform === ContentPlatform.YOUTUBE ||
      contentPlatform === ContentPlatform.TWITCH ||
      contentPlatform === ContentPlatform.INSTAGRAM) &&
      influencer.id && (
        <InfluencerProfileModalTrigger
          key="InfluencerProfileModalTrigger"
          accountId={influencer.id}
        />
      )
  ];
  const cpi = getCpiFromAgreement(agreement);
  const cpm = getCpmFromAgreement(agreement);
  const minGuarantee = getMinGuaranteeFromAgreement(agreement);
  const maxPayment = getMaxPaymentFromAgreement(agreement);

  const estimatedViews = youtubeChannel && getViewsFromEstimates(youtubeChannel.estimates);

  const estimatedCost = calculateYoutubePrices({
    promotionType,
    dealType: getDealType({
      cpi,
      cpm,
      freeOfCharge: agreement.freeOfCharge || campaign.campaignType === CampaignType.CHARITY
    }),
    estimatedViews,
    estimatedInstalls,
    minGuarantee,
    cpi,
    cpm,
    maxPayment
  }).currentPrice;

  const isLoading =
    isNegotiating(influencer, isNegotiatingCampaign, creatingCampaignAgreement) ||
    isAccepting(influencer, isAcceptingCampaign, acceptingCampaignAgreement) ||
    isDeclining(influencer, isDecliningCampaign, decliningCampaignAgreement);

  return {
    campaign,
    influencer,
    agreement,
    gameType,
    promotionType,
    contentPlatform,
    channelName,
    disabled: !campaign.visible || isLoading,
    isLoading: isLoading,
    agreementDeadline: agreement.deadline,
    agreementStatus: agreement.status,
    estimates,
    cost: agreement.cost,
    estimatedCost,
    estimatedInstalls,
    showMessagesTab: true,
    showCommissionTab: true,
    defaultTab: 1,
    hideCpi,
    adminActions,
    error,
    onShowDeclineOfferModal
  };
};

const getInviteCardProps = ({
  influencer,
  campaign,
  isAcceptingCampaign,
  acceptingCampaignAgreement,
  isNegotiatingCampaign,
  creatingCampaignAgreement,
  isDecliningCampaign,
  decliningCampaignAgreement
}) => {
  const gameType = getGameTypeForEstimates(campaign.game);
  const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);
  const { channel } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};

  // for invites, we don't have agreement...
  // so... for normal cases, use the agreement
  // for invites, construct a fake "agreement"
  // with the estimates
  const agreement: any = find(campaign.agreements, { influencerId: influencer.id }) || {};

  if (!channel) {
    console.warn('Corrupted influencer data', influencer);
    return { campaign, agreement, estimatedCost: 0 };
  }

  const channelName =
    (channel && channel.name) || (influencer.account && influencer.account.displayName) || '';
  const invite = influencer.invite;
  const promotionType = agreement.promotionType || campaign.defaultPromotionType;
  // TODO: Twitch! When we have dual campaigns, get contentPlatform from selected one
  const contentPlatform =
    agreement.contentPlatform ||
    (invite && invite.contentPlatform) ||
    campaign.defaultContentPlatform;
  const youtubeChannel = influencer.youtubeChannels[0];
  const estimates = youtubeChannel && youtubeChannel.estimates;
  const estimatedInstalls = getEstimatedInstallsFromEstimates(estimates, promotionType, gameType);
  const adminActions = [
    <StatefulCollectionSelectorTrigger
      type="static"
      key="ApiConnectedCollectionSelectorTrigger"
      contentPlatform={contentPlatform}
      channelId={channel.id}
    />,
    (contentPlatform === ContentPlatform.YOUTUBE ||
      contentPlatform === ContentPlatform.TWITCH ||
      contentPlatform === ContentPlatform.INSTAGRAM) &&
      influencer.id && (
        <InfluencerProfileModalTrigger
          key="InfluencerProfileModalTrigger"
          accountId={influencer.id}
        />
      )
  ];

  const isLoading =
    isNegotiating(influencer, isNegotiatingCampaign, creatingCampaignAgreement) ||
    isAccepting(influencer, isAcceptingCampaign, acceptingCampaignAgreement) ||
    isDeclining(influencer, isDecliningCampaign, decliningCampaignAgreement);

  return {
    influencer,
    channelName,
    inviteId: invite ? invite.id : null,
    hideCpi,
    disabled: !campaign.visible || isLoading,
    isLoading,
    estimatedInstalls,
    promotionType,
    defaultContentPlatform: campaign.defaultContentPlatform,
    adminActions,
    defaultTab: 1,
    estimatedCost: 0
  };
};

type Props = {
  campaign: CampaignForPublisher,
  isAcceptingCampaign: boolean,
  acceptingCampaignAgreement: any,
  isNegotiatingCampaign: boolean,
  creatingCampaignAgreement: any,
  isDecliningCampaign: boolean,
  decliningCampaignAgreement: any,
  error: any,
  onChangeSettings: Function,
  onShowDeclineOfferModal: Function
};

const NegotiationTab = ({
  campaign,
  isAcceptingCampaign,
  acceptingCampaignAgreement,
  isNegotiatingCampaign,
  creatingCampaignAgreement,
  isDecliningCampaign,
  decliningCampaignAgreement,
  error,
  onChangeSettings,
  onShowDeclineOfferModal
}: Props) => {
  const [defaultTab, setDefaultTab] = React.useState<number>(1);

  React.useEffect(() => {
    [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(index => {
      Mousetrap.bind(`${index}`, () => {
        setDefaultTab(index - 1);
      });
    });
  }, []);

  let invitedInfluencers = getInvitedInfluencers(campaign);
  let negotiatingInfluencers = getNegotiatingInfluencers(campaign);

  if (
    (!invitedInfluencers || !invitedInfluencers.length) &&
    (!negotiatingInfluencers || !negotiatingInfluencers.length)
  ) {
    return null;
  }

  invitedInfluencers = invitedInfluencers.map(invitedInfluencer => {
    invitedInfluencer.agreement =
      campaign.agreements.find(a => a.influencerId === invitedInfluencer.id) || {};

    const { estimatedCost } = getInviteCardProps({
      influencer: invitedInfluencer,
      campaign,
      error,
      isAcceptingCampaign,
      acceptingCampaignAgreement,
      isNegotiatingCampaign,
      creatingCampaignAgreement,
      isDecliningCampaign,
      decliningCampaignAgreement,
      onShowDeclineOfferModal
    });
    invitedInfluencer.estimatedCost = estimatedCost;
    return invitedInfluencer;
  });

  negotiatingInfluencers = negotiatingInfluencers.map(negotiatingInfluencer => {
    negotiatingInfluencer.agreement =
      campaign.agreements.find(a => a.influencerId === negotiatingInfluencer.id) || {};
    const { estimatedCost } = getNegotiationCardProps({
      influencer: negotiatingInfluencer,
      campaign,
      error,
      isAcceptingCampaign,
      acceptingCampaignAgreement,
      isNegotiatingCampaign,
      creatingCampaignAgreement,
      isDecliningCampaign,
      decliningCampaignAgreement,
      onShowDeclineOfferModal
    });
    negotiatingInfluencer.estimatedCost = estimatedCost;
    return negotiatingInfluencer;
  });

  return (
    <section className="influencer-cards">
      <InfluencerCollection
        defaultTab={defaultTab}
        name="negotiatingInfluencerCollection"
        onChangeSettings={onChangeSettings}
        influencers={negotiatingInfluencers || []}
        card={InfluencerNegotiationCard}
        cardProps={influencer =>
          getNegotiationCardProps({
            influencer,
            campaign,
            error,
            isAcceptingCampaign,
            acceptingCampaignAgreement,
            isNegotiatingCampaign,
            creatingCampaignAgreement,
            isDecliningCampaign,
            decliningCampaignAgreement,
            onShowDeclineOfferModal
          })
        }
        cardSortOptions={getSortingOptions(campaign.defaultContentPlatform)}
        searchBy={searchBy}
      />
      {(invitedInfluencers || []).length > 0 && (
        <>
          <h2 className="subtitle">
            <FormattedMessage id="campaign.influencer.invited" />
          </h2>
          <InfluencerCollection
            defaultTab={defaultTab}
            name="invitedInfluencerCollection"
            onChangeSettings={onChangeSettings}
            influencers={invitedInfluencers || []}
            card={InfluencerInvitedCard}
            cardProps={influencer =>
              getInviteCardProps({
                influencer,
                campaign,
                isAcceptingCampaign,
                acceptingCampaignAgreement,
                isNegotiatingCampaign,
                creatingCampaignAgreement,
                isDecliningCampaign,
                decliningCampaignAgreement
              })
            }
            cardSortOptions={getSortingOptions(campaign.defaultContentPlatform)}
            searchBy={searchBy}
          />
        </>
      )}
    </section>
  );
};

export default NegotiationTab;
