import { connect, useDispatch } from 'react-redux';
import React from 'react';

import { getCollectionPublic } from '../actions/collection';
import CollectionView from '../components/collection/CollectionView';
import Spinner from '../components/common/Spinner';
import generateStateToProps from '../helpers/generateStateToProps';

let PublicCollectionPage = ({ params, user, router, route, ...props }) => {
  const isFetching = props.isFetchingCollection || !props.collection;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCollectionPublic.run(params.cryptedId));
  }, [dispatch, params.cryptedId]);

  if (isFetching) {
    return (
      <div className="container">
        <Spinner mode="cover" size="large" />
      </div>
    );
  }

  return (
    <div className="PublicCollectionPage">
      <CollectionView
        name="PublicCollectionPage"
        collection={props.collection}
        publicPage={true}
        onChangeSettings={() => {}}
      />
    </div>
  );
};

const mapStateToProps = generateStateToProps('publicCollectionPage');

PublicCollectionPage = connect(mapStateToProps)(PublicCollectionPage);

PublicCollectionPage.containerClassName = 'no-padding-top';

export default PublicCollectionPage;
