// flow
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

import './NotFound.scss';

export default function NotFound() {
  const intl = useIntl();
  const contactEmail = intl.formatMessage({ id: 'contactEmail' });
  const emailLink = (
    <a className="NotFound__email-link" href={`mailto:${contactEmail}`}>
      {contactEmail}
    </a>
  );
  return (
    <div className="NotFound hero is-fullheight is-warning page-not-found">
      <div className="hero-body">
        <div className="container">
          <span className="not-found-icon">
            <i className="fa fa-meh-o" aria-hidden="true" />
          </span>
          <h1 className="title has-text-centered">
            <FormattedMessage id="error.pageNotFound" />
          </h1>
        </div>
      </div>
      <div className="hero-foot has-text-centered">
        <FormattedMessage
          id="error.pageNotFoundContact"
          values={{
            email: emailLink
          }}
        />
      </div>
    </div>
  );
}
