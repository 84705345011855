// @flow
import * as React from 'react';

import Icon from '../Icon';
import type { IconProps } from '../Icon';

import './Icons.scss';

const NotificationIcon = (props: IconProps) => {
  return <Icon {...props}>{() => <i className="material-icons">notifications_active</i>}</Icon>;
};

export default NotificationIcon;
