import { createApiAction, createSimpleAction } from './helper';

import uniqueId from 'lodash/uniqueId';

export const fetchMessages = createApiAction('FETCH_MESSAGES', id => {
  return {
    path: `/agreements/${id}/messages`
  };
});

export const sendMessage = createApiAction('SEND_MESSAGE', (agreementId, content) => {
  const messageClientId = uniqueId();
  return {
    path: `/agreements/${agreementId}/messages`,
    options: {
      method: 'POST',
      body: { content, type: 'message' }
    },
    loadingPayload: { messageClientId, content }
  };
});

export const optimisticallyCreateNegotiationMessage = createSimpleAction(
  'OPTIMISTICALLY_CREATE_NEGOTIATION_MESSAGE'
);
