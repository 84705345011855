import React from 'react';

import getDisplayName from './getDisplayName';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export type State = {
  isPageVisible: boolean
};

const injectPageVisibilityChecking = WrappedComponent => {
  return class extends React.PureComponent<{}, State> {
    static displayName = getDisplayName('injectPageVisibilityChecking', WrappedComponent);

    constructor(props) {
      super(props);

      this.state = {
        isPageVisible: true
      };
    }

    componentDidMount() {
      // If the browser doesn't support addEventListener or the Page Visibility API
      if (typeof document.addEventListener !== 'undefined' && hidden !== undefined) {
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      }
    }

    componentWillUnmount() {
      document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    }

    handleVisibilityChange = () => {
      this.setState({ isPageVisible: !document[hidden] });
    };

    render() {
      const props = {
        ...this.props,
        isPageVisible: this.state.isPageVisible
      };

      return <WrappedComponent {...props} />;
    }
  };
};

export default injectPageVisibilityChecking;
