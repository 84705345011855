// @flow
import * as React from 'react';
import classNames from 'classnames';

import './AdvancedSetting.scss';
import AdvancedSettingToggle from '../common/AdvancedSettingToggle';

type State = {
  isOpen: boolean
};

type CommonProps = {
  label: React.Element<*>,
  tooltip: React.Element<*>,
  currentValue?: ?React.Element<*>,
  children: any,

  className?: string,
  disabled: boolean,

  isOpen?: boolean
};

type StatelessProps = CommonProps & {
  onToggle: () => void
};

type StatefulProps = CommonProps;

function stateful(C: React.ComponentType<StatelessProps>): React.ComponentType<StatefulProps> {
  class StatefulAdvancedSetting extends React.PureComponent<StatefulProps, State> {
    static defaultProps = {
      isOpen: false,
      className: ''
    };

    constructor(props) {
      super(props);

      this.state = {
        isOpen: !!props.isOpen
      };
    }

    onToggle = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
      const props = {
        ...this.props,
        isOpen: this.state.isOpen,
        onToggle: this.onToggle
      };
      return <C {...props} />;
    }
  }
  return StatefulAdvancedSetting;
}

// One advanced setting section including the toggle
function AdvancedSetting(props: StatelessProps) {
  const trigger = (
    <AdvancedSettingToggle
      label={props.label}
      tooltip={props.tooltip}
      value={props.isOpen ? null : props.currentValue}
      onClick={props.onToggle}
    />
  );

  const className = classNames(`field AdvancedSetting`, {
    [props.className || '']: true,
    'AdvancedSetting--disabled': props.disabled
  });

  const content =
    !props.disabled && props.isOpen ? (
      <div className="control AdvancedSetting__content">{props.children}</div>
    ) : null;

  return (
    <div className={className}>
      {trigger}
      {content}
    </div>
  );
}

AdvancedSetting.defaultProps = {
  isOpen: false,
  disabled: false,
  className: ''
};

export default stateful(AdvancedSetting);
