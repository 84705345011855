// @flow
import * as React from 'react';
import ChannelHeader from '../common/ChannelHeader';

import './InfluencerCardPlaceholder.scss';

export default function InfluencerCardPlaceholder() {
  return (
    <div className="InfluencerCardPlaceholder">
      <ChannelHeader backgroundImage={'data:null'} isVertical>
        <span className="influencerLink">
          <div className="Avatar is-64x64">
            <figure className="image is-64x64"></figure>
          </div>
          <span className="cardTitle"></span>
        </span>
      </ChannelHeader>
      <div className="InfluencerCardPlaceholder__body"></div>
      <div className="InfluencerCardPlaceholder__footer"></div>
    </div>
  );
}
