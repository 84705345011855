import React from 'react';

import ActionableNotification from '../../components/common/ActionableNotification';

//$FlowFixMe
import { useAsync } from 'react-async';

import callApi from '../../helpers/api';

import Spinner from '../../components/common/Spinner';
import TeamsTable from '../../components/admin/TeamsTable';

import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';

function getTeams() {
  return callApi('/teams');
}

const AdminDashboardTeams = () => {
  const teamsResponse = useAsync(getTeams);

  let content;
  if (teamsResponse.isLoading) {
    content = <Spinner mode="fullWidth" size="large" />;
  } else if (teamsResponse.error) {
    content = (
      <ActionableNotification type="danger">
        An error occured: {teamsResponse.error.message || 'Unknown error'}
      </ActionableNotification>
    );
  } else if (teamsResponse.data && teamsResponse.data.length === 0) {
    content = (
      <h2 className="title">
        <FormattedMessage id="error.nodata" />
      </h2>
    );
  } else {
    content = (
      <TeamsTable
        teams={teamsResponse.data.data}
        createTeam={async ([teamName]: string[]) => {
          const createdTeam = await callApi('/admin/publisher-teams', {
            method: 'POST',
            body: { name: teamName }
          });
          teamsResponse.setData({
            data: [{ invites: [], members: [], ...createdTeam.data }, ...teamsResponse.data.data]
          });
          return createdTeam;
        }}
      />
    );
  }

  return (
    <div className="AdminDashboardTeams container">
      <Helmet title="Teams" />
      {content}
    </div>
  );
};

export default AdminDashboardTeams;
