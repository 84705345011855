// @flow
import { changeTeam, fetchManagers } from '../../../actions/manager';
import { deleteAccount, unverifyAccount, verifyAccount } from '../../../actions/account';
import { fetchManagerTeams } from '../../../actions/managerTeam';
import { handleDeleteAccountActions } from '../../common/account';
import runMultipleReducers from '../../helpers/runMultipleReducers';
import type { Action } from '../../../types/action.flow';
import type { DeleteAccountAttributes } from '../../common/account';

type State = {|
  ...$Exact<DeleteAccountAttributes>,
  managers: any[],
  managerTeams: any[],
  isLoadingManagers: boolean,
  isChangingTeam: boolean,
  error: Object | null
|};

const initialState: State = Object.freeze({
  managers: [],
  managerTeams: [],
  isLoadingManagers: false,
  isChangingTeam: false,
  isDeletingAccount: false,
  error: null
});

export default function adminDashboardManagersReducer(
  state: State = initialState,
  action: Action
): State {
  const newState = runMultipleReducers([handleDeleteAccountActions()])(state, action);

  switch (action.type) {
    case fetchManagers.REQUEST:
      return {
        ...newState,
        isLoadingManagers: true
      };
    case fetchManagers.SUCCESS:
      return {
        ...newState,
        isLoadingManagers: false,
        managers: action.payload
      };
    case fetchManagers.FAILURE:
      return {
        ...newState,
        isLoadingManagers: false,
        error: action.payload
      };
    case deleteAccount.SUCCESS:
      if (!newState.managers) break;
      return {
        ...newState,
        managers: newState.managers.filter(manager => {
          return manager.id !== action.payload.id;
        })
      };
    case unverifyAccount.SUCCESS:
      if (!newState.managers) break;
      return {
        ...newState,
        managers: newState.managers.map(manager => {
          if (manager.id === action.payload.id) {
            return { ...manager, account: { ...manager.account, verified: false } };
          }
          return manager;
        })
      };
    case verifyAccount.SUCCESS:
      if (!newState.managers) break;
      return {
        ...newState,
        managers: newState.managers.map(manager => {
          if (manager.id === action.payload.id) {
            return { ...manager, account: { ...manager.account, verified: true } };
          }
          return manager;
        })
      };
    case fetchManagerTeams.SUCCESS:
      return {
        ...newState,
        managerTeams: action.payload
      };
    case fetchManagerTeams.FAILURE:
      return {
        ...newState,
        error: action.payload,
        managerTeams: []
      };
    case changeTeam.REQUEST:
      return {
        ...newState,
        isChangingTeam: true,
        managers: (newState.managers || []).map(manager => {
          if (manager.id !== action.payload.managerId) {
            return manager;
          }

          const newTeam = (newState.managerTeams || []).find(
            ({ id }) => id === action.payload.teamId
          );
          if (!newTeam) {
            return manager;
          }

          return {
            ...manager,
            team: newTeam
          };
        })
      };
    case changeTeam.SUCCESS:
      return {
        ...newState,
        isChangingTeam: false,
        managers: (newState.managers || []).map(manager => {
          if (manager.id !== action.payload.id) {
            return manager;
          }

          return {
            ...manager,
            team: action.payload.team
          };
        })
      };
    case changeTeam.FAILURE:
      return {
        ...newState,
        isChangingTeam: false,
        error: action.payload
      };
    default:
      break;
  }

  return newState;
}
