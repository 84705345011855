//@flow

import React from 'react';

import config from '../../config';

import type { AttributionPartnerOption } from '../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';
import type { GamePlatform, PlatformSpecificTrackingCodes } from 'matchmade-types';
import type { IntlShape } from 'react-intl';

import InstallAttribution from '../campaignCreation/CampaignDetails/InstallAttribution';

import './CampaignAttributionSetup.scss';
import { injectIntl } from 'react-intl';
import InfoTooltip from '../common/InfoTooltip';

const trackingUrl = config('tracking.url');

function generateOnChange(attribute: string, onChange: (attribute: string, data: any) => void) {
  return data => {
    onChange(attribute, data);
  };
}

type Props = {
  campaignId: number,
  platforms: GamePlatform[],
  attributionPartner: string,
  attributionPartnerTrackingCode: string,
  attributionPartnerPlatformSpecificTrackingCodes: PlatformSpecificTrackingCodes | null,
  attributionPartnerSelectedOption: AttributionPartnerOption,
  onChange: () => void,
  onSave: () => void,
  intl: IntlShape
};

class CampaignAttributionSetup extends React.PureComponent<Props> {
  renderAttributorSelector() {
    const {
      attributionPartner,
      attributionPartnerTrackingCode,
      attributionPartnerPlatformSpecificTrackingCodes,
      attributionPartnerSelectedOption,
      platforms,
      onChange,
      onSave,
      campaignId
    } = this.props;

    return (
      <div className="CreateCampaignFields">
        <InstallAttribution
          isInverted
          attributionPartner={attributionPartner}
          trackingCode={attributionPartnerTrackingCode}
          platformSpecificTrackingCodes={attributionPartnerPlatformSpecificTrackingCodes}
          selectedOption={attributionPartnerSelectedOption}
          platforms={platforms}
          onChangeAttributionPartner={generateOnChange('attributionPartner', onChange)}
          onChangeTrackingCode={generateOnChange('attributionPartnerTrackingCode', onChange)}
          onChangePlatformSpecificTrackingCodes={generateOnChange(
            'attributionPartnerPlatformSpecificTrackingCodes',
            onChange
          )}
          onChangeOption={generateOnChange('attributionPartnerSelectedOption', onChange)}
        />
        {this.props.attributionPartner && (
          <div className="CampaignAttributionSetup__save-button-div">
            <div className="CampaignAttributionSetup__test-links">
              Attribution test links:
              <ul>
                {[
                  `${trackingUrl}/test/${campaignId}/ios`,
                  `${trackingUrl}/test/${campaignId}/android`,
                  `${trackingUrl}/test/${campaignId}`
                ].map(url => (
                  <li key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <button
              className="button is-primary is-large CampaignAttributionSetup__button"
              onClick={onSave}>
              {this.props.intl.formatMessage({
                id: 'publisher.campaign.attribution.save'
              })}
            </button>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="box CampaignAttributionSetup CampaignAttributionSetup__pad-for-publisher-view">
        <h2 className="CampaignAttributionSetup__attribution-title">
          <strong>
            {this.props.campaignId && !this.props.attributionPartner ? (
              this.props.intl.formatMessage({
                id: 'publisher.campaign.attribution.noPartnerHeader'
              })
            ) : (
              <div className="CampaignAttributionSetup__attribution-title-tooltip">
                {this.props.intl.formatMessage({
                  id: 'publisher.campaign.attribution.hasPartnerHeader'
                })}
                <InfoTooltip>
                  {this.props.intl.formatMessage({
                    id:
                      'campaignCreation.campaignDetails.advancedSetting.installAttribution.tooltip'
                  })}
                </InfoTooltip>
              </div>
            )}
          </strong>
        </h2>
        {this.renderAttributorSelector()}
      </div>
    );
  }
}

export default injectIntl(CampaignAttributionSetup);
