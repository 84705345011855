// It would be cool to add flow this file, but seems like
// we do something inherently wrong with types, since this reveals
// a mega-big can of worms 😞
import {
  getInfluencerSettings,
  getInfluencerYoutubePreferences,
  saveInfluencerSettings,
  saveInfluencerYoutubePreferences
} from '../../actions/influencerSettings';
import runMultipleReducers from '../helpers/runMultipleReducers';
import type {
  GetInfluencerSettings,
  GetInfluencerYoutubePreferences,
  SaveInfluencerSettings,
  SaveInfluencerYoutubePreferences
} from '../../actions/influencerSettings';
import type {
  InfluencerSettings,
  InfluencerYoutubePreferences
} from '../../types/influencerSettings.flow';

import type { Action, ReducerCreator } from '../../types/action.flow';

export type State = {|
  settings: InfluencerSettings | null,
  youtubePreferences: InfluencerYoutubePreferences | null,
  isFetchingSettings: boolean,
  isFetchingYoutubePreferences: boolean,
  settingsError: Object | null,
  youtubePreferencesError: Object | null
|};

export type ReduxInjectedProps = {|
  saveInfluencerSettings: SaveInfluencerSettings,
  getInfluencerSettings: GetInfluencerSettings,
  saveInfluencerYoutubePreferences: SaveInfluencerYoutubePreferences,
  getInfluencerYoutubePreferences: GetInfluencerYoutubePreferences,
  ...State
|};

export const initialState: State = {
  settings: null,
  youtubePreferences: null,
  isFetchingSettings: false,
  isFetchingYoutubePreferences: false,
  settingsError: null,
  youtubePreferencesError: null,
  settingsSaved: false,
  youtubePreferencesSaved: false
};

const handleInfluencerSettingsActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case getInfluencerSettings.REQUEST:
        return {
          ...state,
          isFetchingSettings: true,
          settingsError: null
        };
      case getInfluencerSettings.SUCCESS:
        return {
          ...state,
          isFetchingSettings: false,
          settings: action.payload,
          settingsError: null
        };
      case getInfluencerSettings.FAILURE:
        return {
          ...state,
          isFetchingSettings: false,
          settingsError: action.payload
        };

      case saveInfluencerSettings.REQUEST:
        return {
          ...state,
          settingsSaved: false,
          settingsError: null
        };
      case saveInfluencerSettings.SUCCESS:
        return {
          ...state,
          settingsSaved: true,
          settings: action.payload,
          settingsError: null
        };
      case saveInfluencerSettings.FAILURE:
        return {
          ...state,
          settingsSaved: false,
          settingsError: action.payload
        };
      default:
        return state;
    }
  };
};

const handleInfluencerYoutubePreferencesActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case getInfluencerYoutubePreferences.REQUEST:
        return {
          ...state,
          isFetchingYoutubePreferences: true,
          youtubePreferencesError: null
        };
      case getInfluencerYoutubePreferences.SUCCESS:
        return {
          ...state,
          isFetchingYoutubePreferences: false,
          youtubePreferences: action.payload,
          youtubePreferencesError: null
        };
      case getInfluencerYoutubePreferences.FAILURE:
        return {
          ...state,
          isFetchingYoutubePreferences: false,
          youtubePreferencesError: action.payload
        };
      case saveInfluencerYoutubePreferences.REQUEST:
        return {
          ...state,
          youtubePreferencesSaved: false,
          youtubePreferencesError: null
        };
      case saveInfluencerYoutubePreferences.SUCCESS:
        return {
          ...state,
          youtubePreferencesSaved: true,
          youtubePreferences: action.payload,
          youtubePreferencesError: null
        };
      case saveInfluencerYoutubePreferences.FAILURE:
        return {
          ...state,
          youtubePreferencesSaved: false,
          youtubePreferencesError: action.payload
        };
      default:
        return state;
    }
  };
};

export function influencerSettingsReducer(state: State = initialState, action: Action): State {
  return runMultipleReducers([
    handleInfluencerYoutubePreferencesActions(),
    handleInfluencerSettingsActions()
  ])(state, action);
}

export default influencerSettingsReducer;
