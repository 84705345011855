// @flow
import type { ReducerCreator } from '../../types/action.flow';
import type { Team } from 'matchmade-types/schema';

import Immutable from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';

import { changeTeam as changeCollectionTeam } from '../../actions/collection';
import { changeTeam as changePublisherTeam } from '../../actions/publisher';
import { fetchTeams, updateTeam } from '../../actions/team';

// These are deprecated, don't use them anymore, use the new handle* functions instead
export default {
  fetchTeams(state: any, action: any) {
    switch (action.type) {
      case fetchTeams.REQUEST:
        return state.set('isFetchingTeams', true);
      case fetchTeams.SUCCESS:
        return state.set('isFetchingTeams', false).set('teams', Immutable.fromJS(action.payload));
      case fetchTeams.FAILURE:
        return state.set('isFetchingTeams', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  },

  updateTeam(state: any, action: any) {
    switch (action.type) {
      case updateTeam.REQUEST:
        return state.set('isUpdatingTeam', true);
      case updateTeam.SUCCESS:
        return state.set('isUpdatingTeam', false);
      case updateTeam.FAILURE:
        return state.set('isUpdatingTeam', false).set('error', Immutable.fromJS(action.payload));
      default:
        return state;
    }
  }
};

export type GetAllActionAttributes = {
  teams: Team[] | null,
  isLoadingTeams: boolean,
  error: Object | null
};
export const handleGetAllActions: ReducerCreator<void, GetAllActionAttributes> = () => {
  return (state, action) => {
    switch (action.type) {
      case fetchTeams.REQUEST:
        return {
          ...state,
          isLoadingTeams: true
        };
      case fetchTeams.SUCCESS:
        return {
          ...state,
          isLoadingTeams: false,
          teams: action.payload
        };
      case fetchTeams.FAILURE:
        return {
          ...state,
          isLoadingTeams: false,
          error: action.payload
        };

      default:
        return state;
    }
  };
};

export const handleNewTeamCreatedWhenChangingTeam: ReducerCreator<
  { teamsPropertyInState: string },
  { teams: Team[] | null }
> = (params = {}) => {
  const { teamsPropertyInState = 'teams' } = params;

  return (state, action) => {
    const teams = get(state, teamsPropertyInState);

    // this assumes that ALL changeTeam APIs have `team` in their response
    switch (action.type) {
      case changePublisherTeam.SUCCESS:
      case changeCollectionTeam.SUCCESS:
        if (!teams) {
          console.warn('Missing teams in the current state branch');
          return state;
        }

        const newState = cloneDeep(state);
        set(
          newState,
          teamsPropertyInState,
          uniqBy(teams.concat(action.payload.team), 'id').filter(Boolean)
        );
        return newState;
      default:
        return state;
    }
  };
};
