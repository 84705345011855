import { connect, useDispatch } from 'react-redux';
import React from 'react';

import { getCampaignsByName } from '../actions/campaignSearch';
import { getCollection } from '../actions/collection';
import { resetSearch } from '../actions/influencerYoutubeSearch';
import { updateSettings } from '../actions/account';
import CollectionView from '../components/collection/CollectionView';
import Spinner from '../components/common/Spinner';
import generateStateToProps from '../helpers/generateStateToProps';

let PublisherCollectionPage = ({ params, user, router, route, ...props }) => {
  const isFetching = props.isFetchingCollection || !props.collection;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCollection.run(params.id, ['influencers', 'campaigns']));
  }, [dispatch, params.id]);

  // However we leave this page, always reset
  React.useEffect(() => {
    router.setRouteLeaveHook(route, () => {
      dispatch(resetSearch.run());
    });
  }, [dispatch, route, router]);

  React.useEffect(() => {
    // This _used to be used_ (I think) in a Async select, so we would
    // call this API as users type, and this way make select list smaller.
    // However NOW (31-01-2020) we call this once to get list of ALL campaigns,
    // which we then filter on the client side; because
    // CampaignsCollectionSelector uses MultiValueSelector that uses Select :-X
    dispatch(getCampaignsByName.run());
  }, [dispatch]);

  const onChangeSettings = settings => {
    return dispatch(updateSettings.run(user.id, settings));
  };

  if (isFetching) {
    return (
      <div className="container">
        <Spinner mode="cover" size="large" />
      </div>
    );
  }

  return (
    <div className="PublisherCollectionPage">
      <CollectionView
        name="publisherCollectionPage"
        campaigns={props.campaigns}
        collection={props.collection}
        publicPage={false}
        onChangeSettings={onChangeSettings}
      />
    </div>
  );
};

const mapStateToProps = generateStateToProps('publisherCollectionPage');

PublisherCollectionPage = connect(mapStateToProps)(PublisherCollectionPage);

PublisherCollectionPage.containerClassName = 'no-padding-top';

export default PublisherCollectionPage;
