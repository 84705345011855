// @flow

import React from 'react';

import FetchContactEmails from '../../components/admin/FetchContactEmails';

import { getCollection as getCollectionAction } from '../../actions/collection';
import { getOneChannelPerContentPlatform } from '../../helpers/influencer';
import callApi from '../../helpers/api';

type Props = {
  params: {
    id: number
  }
};

type Channel = {
  id: string,
  name: string,
  avatarUrl: string,
  last30dStats: {
    averageViewCount: number
  }
};

type InfluencerWithYoutubeChannel = {
  youtubeChannels: Channel[],
  twitchChannels: Channel[],
  instagramChannels: Channel[],
  bilibiliChannels: Channel[]
};

const getCollection = ({ dispatch, collectionId }) =>
  dispatch(getCollectionAction.run(collectionId, ['influencers']));

const scheduleFetchingEmails = ([collectionId]) =>
  callApi(`/ludwig/youtube_channel_emails/collection/${collectionId}`, {
    method: 'POST'
  });

const getScheduledEmailsResult = ({ collectionId }) =>
  callApi(`/ludwig/youtube_channel_emails/collection/${collectionId}`, {
    method: 'GET'
  });

const mapResult = (item: InfluencerWithYoutubeChannel) => {
  const { youtubeChannel } = getOneChannelPerContentPlatform(item);

  if (!youtubeChannel) {
    return null;
  }

  return {
    channelId: youtubeChannel.id,
    channelName: youtubeChannel.name,
    channelAvatarUrl: youtubeChannel.avatarUrl,
    average30dViews: (youtubeChannel.last30dStats || {}).averageViewCount || 0
  };
};

function AdminFetchYoutubeContactEmailsPage(props: Props) {
  const collectionId = props.params.id;

  return (
    <FetchContactEmails
      collectionId={collectionId}
      getCollection={getCollection}
      getScheduledEmailsResult={getScheduledEmailsResult}
      scheduleFetchingEmails={scheduleFetchingEmails}
      mapResult={mapResult}
    />
  );
}

export default AdminFetchYoutubeContactEmailsPage;
