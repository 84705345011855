// @flow
/**
 * Common icon class that provides some handy props
 * to define modifier classes, and tooltips
 *
 * Example:
 * const YoutubeIcon = (props: IconProps) => {
 * return (
 *     <Icon {...props} className="YoutubeIcon">
 *       {() => <YoutubeSvg />}
 *     </Icon>
 *   );
 * };
 */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from './Tooltip';
import classNames from 'classnames';

import './Icons/Icons.scss';

export type IconProps = {
  isActive?: boolean,
  inactive?: boolean,
  className?: string,
  tooltip?: React.Element<*> | React.Element<*>[] | null,
  tooltipI18nString?: string,
  toolTipText?: string,
  withTooltip?: boolean,
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
};

type Props = IconProps & {
  children: () => React.Element<*>
};

const Icon = (props: Props) => {
  const {
    isActive = false,
    className = '',
    tooltip = null,
    tooltipI18nString = '',
    toolTipText = '',
    withTooltip = false,
    tooltipPosition = 'top',
    inactive = false,
    children
  } = props;

  if (withTooltip && !(tooltipI18nString || tooltip || toolTipText)) {
    throw new Error('Cannot show tooltip if there is no i18n id');
  }

  // TODO figure out if anything actually uses TabIcon class
  const iconStyles = classNames('Icon TabIcon', {
    [className]: !!className,
    activeTabIcon: isActive,
    'Icon--inactive': inactive
  });

  const renderedIcon = <span className={iconStyles}>{children()}</span>;

  if (!withTooltip) return renderedIcon;
  return (
    <Tooltip
      position={tooltipPosition}
      tooltip={
        tooltip ||
        (toolTipText && <span>{toolTipText}</span>) || <FormattedMessage id={tooltipI18nString} />
      }>
      {renderedIcon}
    </Tooltip>
  );
};

export default Icon;
