import get from 'lodash/get';
import url from 'url';

// Some react components require color variables as json object.
// This used to be `variables.json`, but we don't need it anymore.
const COLOR_VARIABLES = {
  'mm-aqua': '#55cdd7',
  'mm-light-aqua': '#aae6eb',
  'mm-navy': '#2d3767',
  'mm-yellow': '#ffbe00',
  'mm-red': '#fa506e',
  'mm-green': '#64d282',
  'mm-black': '#171715',
  'mm-grey-dark': '#636362',
  'mm-grey-light': '#bebfbf',
  'mm-purple': '#aa6ebe',
  'mm-orange': '#ff9600',
  'mm-darkest-grey': '#353535'
};
export function getColorVariables() {
  return COLOR_VARIABLES;
}

function buildConfig() {
  const webappConfig = window.webappConfig;
  const urls = {
    influencerAuthYouTube: `${webappConfig.backend.url}/auth/google/influencer?prompt=consent`,
    influencerManagerAuth: `${webappConfig.auth.url}/token/v1/login/google?next_url=${webappConfig.frontend.url}/authenticate/landing?role=influencer_manager`,
    whitelabelAdminAuth: `${webappConfig.auth.url}/token/v1/login/google?next_url=${webappConfig.frontend.url}/authenticate/v2/landing`,
    login: `${webappConfig.backend.url}/auth/google/login?prompt=select_account`,
    creatorAuth: webappConfig.creatorApp.url
  };

  return {
    ...webappConfig,
    urls,
    // Boolean flag to switch on Maintenance mode.
    // When set to true, all routes will render "We'll be back" page.
    // See more in ../src/components/Root.js
    inMaintenanceMode: false
  };
}
export default (path, defaultValue = null) => {
  const config = buildConfig();
  return get(config, path, defaultValue);
};

export function generateApiPath(path, query = {}) {
  if (!path.startsWith('/')) {
    throw new Error(`Path [${path}] must start from /`);
  }
  const config = buildConfig();
  const host = get(config, 'backend.url');
  const root = get(config, 'backend.apiRoot');

  return `${host}${root}${path}${url.format({ query })}`;
}

export function getTrackingUrl(code) {
  const config = buildConfig();
  return `${config.tracking.url}/${code}`;
}

export function generateAuthUrl(path, nextUrl) {
  const { frontend, auth } = buildConfig();

  if (!path.startsWith('/')) {
    throw new Error(`Path [${path}] must start from /`);
  }

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('error');

  nextUrl =
    nextUrl ||
    `${frontend.url}/authenticate/landing${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
  const authUrl = `${auth.url}${path}`;
  const urlObj = url.parse(authUrl, true);
  urlObj.query.next_url = nextUrl;

  return url.format(urlObj);
}
