// @flow
import type { CollectionId } from 'matchmade-types/schema';
import type {
  ChangeTeamRequestData,
  ChangeTeamResponseData,
  LinkCollectionRequestData,
  LinkCollectionResponseData
} from 'matchmade-types/api/collections';

import { createApiAction } from './helper';
import type { Collection, CollectionWithIncludedFields } from '../types/collection.flow';

export type CreateCollection = ({
  youtubeChannelIds?: string[],
  twitchChannelIds?: string[],
  instagramChannelIds?: string[],
  name: string
}) => Promise<any>;
export const createCollection = createApiAction(
  'CREATE_COLLECTION',
  ({ name, youtubeChannelIds, twitchChannelIds, instagramChannelIds }) => {
    return {
      path: '/collections',
      options: {
        method: 'POST',
        body: {
          name,
          youtubeChannelIds,
          twitchChannelIds,
          instagramChannelIds
        }
      }
    };
  }
);

export type DuplicateCollection = (params: {
  id: CollectionId,
  name: string
}) => Promise<Collection>;
export const duplicateAsStaticCollection = createApiAction(
  'DUPLICATE_AS_STATIC_COLLECTION',
  ({ id, name }) => {
    return {
      path: `/collections/${id}/duplicate-as-static`,
      options: {
        method: 'POST',
        body: {
          name
        }
      }
    };
  }
);

export const duplicateAsDynamicCollection = createApiAction(
  'DUPLICATE_AS_DYNAMIC_COLLECTION',
  ({ id, name }) => {
    return {
      path: `/collections/${id}/duplicate-as-dynamic`,
      options: {
        method: 'POST',
        body: {
          name
        }
      }
    };
  }
);

export type RemoveCollection = (id: CollectionId) => Promise<Collection>;
export const removeCollection = createApiAction('DELETE_COLLECTION', id => {
  return {
    path: `/collections/${id}`,
    options: {
      method: 'DELETE'
    }
  };
});

export type GetCollection = (id: CollectionId) => Promise<CollectionWithIncludedFields>;
export const getCollection = createApiAction(
  'FETCH_COLLECTION',
  (id: string, include?: string[] = []) => {
    return {
      path: `/collections/${id}${include ? `?include=${include.join(',')}` : ''}`
    };
  }
);

export type GetCollectionPublic = (
  cryptedId: CollectionId
) => Promise<CollectionWithIncludedFields>;
export const getCollectionPublic = createApiAction(
  'FETCH_COLLECTION_PUBLIC',
  (cryptedId: string, include?: string[] = []) => {
    return {
      path: `/collections/public/${cryptedId}${include ? `?include=${include.join(',')}` : ''}`
    };
  }
);

export type AddYoutubeChannelToCollection = (
  // Tan - 23.12.2019 - this should be just `string[]` but since we are calling it
  // in a lot of places, for now, do this kind of mixed param
  channelIds: string | string[],
  collectionId: CollectionId
) => Promise<{ channels: string[], id: CollectionId }>;
export const addYoutubeChannelToCollection = createApiAction(
  'ADD_YOUTUBE_CHANNEL_TO_COLLECTION',
  (channelIds, collectionId) => {
    const channelIdsAsArray = Array.isArray(channelIds) ? channelIds : [channelIds];
    return {
      path: `/collections/${collectionId}/youtube-channels`,
      options: {
        method: 'POST',
        body: { channelIds: channelIdsAsArray }
      },
      loadingPayload: {
        channelIds: channelIdsAsArray,
        id: collectionId
      }
    };
  }
);

export type RemoveYoutubeChannelFromCollection = (
  channelId: string,
  collectionId: CollectionId
) => Promise<{ channels: string[], id: CollectionId }>;
export const removeYoutubeChannelFromCollection = createApiAction(
  'REMOVE_YOUTUBE_CHANNEL_FROM_COLLECTION',
  (channelId, collectionId) => {
    return {
      path: `/collections/${collectionId}/youtube-channels/${channelId}`,
      options: {
        method: 'DELETE',
        body: { channelId }
      },
      loadingPayload: {
        channelId,
        id: collectionId
      }
    };
  }
);

export const addInstagramChannelToCollection = createApiAction(
  'ADD_INSTAGRAM_CHANNEL_TO_COLLECTION',
  (channelIds: string[], collectionId: number) => {
    return {
      path: `/collections/${collectionId}/instagram-channels`,
      options: {
        method: 'POST',
        body: { channelIds }
      },
      loadingPayload: {
        channelIds,
        id: collectionId
      }
    };
  }
);

export const removeInstagramChannelFromCollection = createApiAction(
  'REMOVE_INSTAGRAM_CHANNEL_FROM_COLLECTION',
  (channelId: string, collectionId: number) => {
    return {
      path: `/collections/${collectionId}/instagram-channels/${channelId}`,
      options: {
        method: 'DELETE',
        body: { channelId }
      },
      loadingPayload: {
        channelId,
        id: collectionId
      }
    };
  }
);

export type AddTwitchChannelToCollection = (
  channelIds: string[],
  collectionId: CollectionId
) => Promise<{ channels: string[], id: CollectionId }>;
export const addTwitchChannelToCollection = createApiAction(
  'ADD_TWITCH_CHANNEL_TO_COLLECTION',
  (channelIds, collectionId) => {
    return {
      path: `/collections/${collectionId}/twitch-channels`,
      options: {
        method: 'POST',
        body: { channelIds }
      },
      loadingPayload: {
        channelIds,
        id: collectionId
      }
    };
  }
);

export type RemoveTwitchChannelFromCollection = (
  channelId: string,
  collectionId: CollectionId
) => Promise<{ channels: string[], id: CollectionId }>;
export const removeTwitchChannelFromCollection = createApiAction(
  'REMOVE_TWITCH_CHANNEL_FROM_COLLECTION',
  (channelId, collectionId) => {
    return {
      path: `/collections/${collectionId}/twitch-channels/${channelId}`,
      options: {
        method: 'DELETE',
        body: { channelId }
      },
      loadingPayload: {
        channelId,
        id: collectionId
      }
    };
  }
);

export type LinkCollectionToCampaign = (
  params: LinkCollectionRequestData
) => Promise<LinkCollectionResponseData>;
export const linkCollectionToCampaign = createApiAction(
  'LINK_COLLECTION_TO_CAMPAIGN',
  ({ collectionId, campaignId, include = [] }) => {
    const queryParams = include.length ? `?include=${include.join(',')}` : '';
    return {
      path: `/collections/${collectionId}/campaign/${campaignId}${queryParams}`,
      options: {
        method: 'POST'
      }
    };
  }
);

export type RemoveCollectionFromCampaign = (
  params: LinkCollectionRequestData
) => Promise<LinkCollectionResponseData>;
export const removeCollectionFromCampaign = createApiAction(
  'REMOVE_COLLECTION_FROM_CAMPAIGN',
  ({ collectionId, campaignId, include = [] }) => {
    const queryParams = include.length ? `?include=${include.join(',')}` : '';
    return {
      path: `/collections/${collectionId}/campaign/${campaignId}${queryParams}`,
      options: {
        method: 'DELETE'
      }
    };
  }
);

export const getAll = createApiAction('GET_ALL_COLLECTIONS', (include?: string[]) => {
  return {
    path: `/collections${include ? `?include=${include.join(',')}` : ''}`,
    options: {
      method: 'GET'
    }
  };
});

export const getDynamicCollections = createApiAction(
  'GET_DYNAMIC_COLLECTIONS',
  (include?: string[]) => {
    return {
      path: `/collections/dynamic${include ? `&include=${include.join(',')}` : ''}`,
      options: {
        method: 'GET'
      }
    };
  }
);

export const getStaticCollections = createApiAction(
  'GET_STATIC_COLLECTIONS',
  (include?: string[]) => {
    return {
      path: `/collections/static${include ? `&include=${include.join(',')}` : ''}`,
      options: {
        method: 'GET'
      }
    };
  }
);

export type ChangeTeam = (params: ChangeTeamRequestData) => Promise<ChangeTeamResponseData>;
export const changeTeam = createApiAction(
  'CHANGE_COLLECTION_TEAM',
  (params: ChangeTeamRequestData) => {
    return {
      path: `/collections/${params.collectionId}/change-team`,
      options: {
        method: 'POST',
        body: {
          teamId: params.teamId,
          teamName: params.teamName
        }
      },
      loadingPayload: {
        collectionId: params.collectionId,
        teamId: params.teamId
      }
    };
  }
);

export const changeName = createApiAction('CHANGE_COLLECTION_NAME', (collectionId, name) => {
  return {
    path: `/collections/${collectionId}/change-name`,
    options: {
      method: 'POST',
      body: { name }
    },
    loadingPayload: {
      collectionId,
      name
    }
  };
});

export const hideChannelFromCollection = createApiAction(
  'HIDE_CHANNEL_FROM_COLLECTION',
  (channelIds, collectionId) => {
    return {
      path: `/collections/${collectionId}/channel/${channelIds}/hide`,
      options: {
        method: 'PUT',
        body: {}
      },
      loadingPayload: {
        channelIds: channelIds,
        id: collectionId
      }
    };
  }
);

export const showChannelFromCollection = createApiAction(
  'SHOW_CHANNEL_FROM_COLLECTION',
  (channelIds, collectionId) => {
    return {
      path: `/collections/${collectionId}/channel/${channelIds}/show`,
      options: {
        method: 'PUT',
        body: {}
      },
      loadingPayload: {
        channelIds: channelIds,
        id: collectionId
      }
    };
  }
);
