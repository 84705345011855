// @flow
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
// $FlowFixMe
import { useIntl } from 'react-intl';
import React from 'react';

import { checkIfInfluencer, checkIfPublisher } from '../helpers/user';
import { fetchPublisherPaymentInfo, updatePublisherPaymentInfo } from '../actions/paymentInfo';
import CheckIcon from '../components/common/Icons/CheckIcon';
import FooterNotification from '../components/common/FooterNotification';
import PaymentInfoFormForPublisher from '../components/paymentInfo/PaymentInfoFormForPublisher';
import compose from '../hoc/compose';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';
import type { State as PaymentInfoState } from '../reducers/common/paymentInfo';
import type { PublisherPaymentInfo } from '../types/paymentInfo.flow';

import './PaymentInfo.scss';

const NotificationSuccess = () => {
  return (
    <FooterNotification type="success">
      <div className="FooterNotification__icon">
        <CheckIcon width="2.85rem" height="2.85rem" />
      </div>
      <div>
        <FormattedMessage id="paymentInfo.success" />
      </div>
    </FooterNotification>
  );
};

type Props = {
  ...PaymentInfoState,
  fetchPublisherPaymentInfo: () => void,
  updatePublisherPaymentInfo: (publisherPaymentInfo: PublisherPaymentInfo) => void
};

const PaymentInfoPage = (props: Props) => {
  const { publisherPaymentInfo, updatePublisherPaymentInfo, isLoading, success, error } = props;
  const intl = useIntl();

  const dispatch = useDispatch();

  React.useEffect(() => {
    checkIfPublisher() && dispatch(fetchPublisherPaymentInfo.run());
  }, [dispatch]);

  return (
    <div className="PaymentInfo container">
      {checkIfPublisher() && (
        <PaymentInfoFormForPublisher
          data={publisherPaymentInfo}
          error={error}
          isLoading={isLoading}
          onSubmit={updatePublisherPaymentInfo}
        />
      )}
      {checkIfInfluencer() && (
        <div>
          {intl.formatMessage({
            id: 'paymentInfo.freyja'
          })}
        </div>
      )}
      {success && <NotificationSuccess />}
    </div>
  );
};

const mapStateToProps = generateStateToProps('paymentInfo');
const mapDispatchToProps = generateDispatchToProps({
  updatePublisherPaymentInfo
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentInfoPage);
