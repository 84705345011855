import { FormattedMessage } from 'react-intl';
import Headset from '../../images/Headset';
import React from 'react';

import './AdminPlaceholderPages.scss';

export const AdminNoInfluencers = () => {
  return (
    <div className="AdminNoInfluencers container">
      <h1 className="title">
        <FormattedMessage id="nav.admin.influencers" />
      </h1>
      <div className="has-text-centered">
        <Headset className="PlaceholderIcon" />
        <FormattedMessage tagName="div" id="admin.accounts.noInfluencers" />
      </div>
    </div>
  );
};
