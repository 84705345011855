// @flow
// $FlowFixMe
import { DatePicker } from '@material-ui/pickers';
// $FlowFixMe
import { apiDateFormat } from '../../../helpers/formats';
// $FlowFixMe
import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import classNames from 'classnames';
import getConfig from '../../../config';

import './DateOffer.scss';

const dateRangeMaximum = getConfig('maxVideoPublishingMonthRange', 12);

type Props = {
  onChange: (newDate: string) => void,
  date: string | null,
  isLoading?: boolean,
  addOnAfterInput?: any,
  disablePast?: boolean
};

const DateOffer = ({ onChange, date, isLoading, addOnAfterInput, disablePast = true }: Props) => {
  const today = new Date();
  const maxDate = today.setMonth(today.getMonth() + dateRangeMaximum);
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  return (
    <div className="control DateOffer">
      <small>{intl.formatMessage({ id: 'datePicker.publishingDate' })}</small>
      <DatePicker
        value={date}
        disabled={isLoading}
        maxDate={maxDate}
        variant="outlined"
        open={isForcePickerOpen}
        autoOk
        disablePast={disablePast}
        disableMaskedInput
        readOnly
        onChange={newDate => onChange(newDate.format(apiDateFormat))}
        onClose={() => setIsOpen(false)}
        renderInput={props => {
          const { disabled, error, inputRef, inputProps, onChange = () => {} } = props;

          const extraProps = inputProps || {};
          const value = extraProps.value || props.value;

          const className = classNames('input', {
            'is-warning': error || !value
          });

          extraProps.placeholder = intl.formatMessage({
            id: 'modifyOfferOverlay.publishingDatePlaceholder'
          });

          return (
            <input
              ref={inputRef}
              type="text"
              className={className}
              value={value}
              disabled={disabled}
              onChange={onChange}
              onClick={() => setIsOpen(isOpen => !isOpen)}
              onTouchEnd={() => setIsOpen(isOpen => !isOpen)}
              {...extraProps}
            />
          );
        }}
      />
    </div>
  );
};

export default DateOffer;
