// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';

import Button from '../../common/Button';
import CollectionSelector from '../../collection/CollectionSelector';
import DynamicCollectionIcon from '../../../images/icons/DynamicCollectionIcon';
import Modal from '../../common/Modal';
import type { Collection } from '../../../types/collection.flow';

import './SaveSearchToCollectionTrigger.scss';

type Props = {
  collections: Collection[],
  isLoading: boolean,
  collectionCreated: ?Collection,
  onSubmit: (collectionName: string) => void,
  onAddToCollection: (collectionId: number) => void
};

const SaveSearchToCollectionTrigger = ({
  onSubmit,
  onAddToCollection,
  isLoading,
  collections,
  collectionCreated
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const collectionCreatedId = collectionCreated ? collectionCreated.id : 0;

  React.useEffect(() => {
    if (
      !isLoading &&
      collectionCreatedId &&
      selected &&
      selected.indexOf(collectionCreatedId) === -1
    ) {
      setSelected([...selected, collectionCreatedId]);
    }
  }, [collectionCreatedId, isLoading, selected]);

  const createCollection = name => {
    onSubmit(name);
  };

  const addToCollection = collectionId => {
    onAddToCollection(collectionId);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelected([]);
  };

  return [
    <Button
      key="create-collection-button"
      className="SaveSearchToCollectionTrigger__button"
      size="large"
      loading={isLoading}
      onClick={() => setIsOpen(true)}>
      <DynamicCollectionIcon />
      <FormattedMessage id="search.saveToCollection.button" />
    </Button>,
    <Modal
      key="create-collection-modal"
      className="SaveSearchToCollectionTriggerModal"
      isOpen={isOpen}
      onClose={closeModal}>
      <CollectionSelector
        selectedI18nString="search.selectedCollections"
        availableI18nString="search.saveSearchToCollection"
        options={collections.map(c => ({ id: c.id, name: c.name, searchId: c.searchId }))}
        selected={selected}
        onAdd={addToCollection}
        onCreate={createCollection}
        isLoading={isLoading}
      />
    </Modal>
  ];
};

export default SaveSearchToCollectionTrigger;
