// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { generateApiPath } from '../config';
import { getAuthHeader } from './puppe';
// $FlowFixMe
import { useAsync } from 'react-async';
import isNil from 'lodash/isNil';

import type { Header } from './api';
import type { Role } from 'matchmade-types';

export type MatchmadeAccount = {
  id: number,
  displayName: string,
  email: string,
  avatarUrl: string,
  settings: any,
  role: ?Role
};

const validateAndReturnAccount = (response: Object): MatchmadeAccount => {
  const { id, displayName, email, avatarUrl, settings, role } = response;
  if (isNil(id) || isNil(displayName) || isNil(email) || isNil(avatarUrl) || isNil(settings)) {
    throw new Error(`Incomplete account response from API: ${JSON.stringify(response)}`);
  }
  return { id, displayName, email, avatarUrl, settings, role: role || null };
};

export class NotLoggedInError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotLoggedInError';
  }
}

export class FailedToGetAccountError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FailedToGetAccount';
  }
}

async function postAccount(abortSignal: ?AbortSignal): Promise<?MatchmadeAccount> {
  const authHeaders = await getAuthHeader();
  if (!authHeaders) {
    throw new NotLoggedInError('Not logged in');
  }
  const options: {
    credentials: 'include',
    headers: Header,
    signal: ?AbortSignal
  } & RequestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authHeaders['Authorization']
    },
    signal: abortSignal
  };
  let status: number;
  let json: any;
  try {
    const result = await fetch(generateApiPath('/accounts'), options);
    status = result.status;
    json = await result.json();
    if (status === 201 && json && json.data && json.data) {
      return validateAndReturnAccount(json.data);
    } else {
      throw new Error(`Account creation returned an unexpected response: ${JSON.stringify(json)}`);
    }
  } catch (error) {
    throw new Error('Failed to create account: ' + error.message);
  }
}

async function getAccountOrThrow(abortSignal: ?AbortSignal): Promise<?MatchmadeAccount> {
  const authHeaders = await getAuthHeader();
  if (!authHeaders) {
    throw new NotLoggedInError('Not logged in');
  }
  const options: {
    credentials: 'include',
    headers: Header,
    signal: ?AbortSignal
  } & RequestOptions = {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authHeaders['Authorization']
    },
    signal: abortSignal
  };

  let status: number;
  let json: any;
  try {
    const result = await fetch(generateApiPath('/accounts'), options);
    status = result.status;
    json = await result.json();
  } catch (error) {
    throw new Error(error.toString());
  }
  if (status === 200 && json.success && json.data) {
    return validateAndReturnAccount(json.data);
  }
  if (status === 404) {
    return null;
  }
  throw new FailedToGetAccountError('Failed to get account');
}

export async function getOrCreateAccount(params: {
  signal: ?AbortSignal
}): Promise<{ account: ?MatchmadeAccount, isNew: boolean }> {
  const { signal } = params || {};
  let account = null;
  let isNew = false;
  try {
    account = await getAccountOrThrow(signal);
    if (!account) {
      account = await postAccount(signal);
      isNew = true;
    }
  } catch (e) {
    if (e instanceof NotLoggedInError) {
      account = null;
    } else {
      throw e;
    }
  }
  return {
    account,
    isNew
  };
}

type AccountContextType = {
  isLoading: boolean,
  error: any,
  data: {
    account: ?MatchmadeAccount,
    isNew: boolean
  } | null,
  reload: () => void
};

export const AccountContext = React.createContext<AccountContextType>({
  isLoading: true,
  error: null,
  data: null,
  reload: () => {}
});

// These two idioticly small helper are here to help use the account context correctly
export const useAccountContext = () => {
  const context = React.useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccountContext must be used within a AccountContext');
  }
  return context;
};

export const AccountContextProvider = (props: { children: React.Node }) => {
  // on prupose passing the whole useAsync return to the context
  // then it's up for the child components to decide what to do
  // is it showing loading indicator instantly or trying to load
  // some data in parallel or what not we don't care here
  const accountContext = useAsync(getOrCreateAccount);
  if (accountContext.data && accountContext.data.account) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: accountContext.data.account.email,
        id: accountContext.data.account.id
      });
    });
  }
  return <AccountContext.Provider value={accountContext}>{props.children}</AccountContext.Provider>;
};
