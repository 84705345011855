// @flow
import React from 'react';

//$FlowFixMe
import Modal from '../../components/common/Modal';
import callApi from '../../helpers/api';
import moment from 'moment';
import Spinner from '../common/Spinner';

import { chunk } from 'lodash';
import FormattedNumber from '../common/FormattedNumber';
//$FlowFixMe
import { useAsync } from 'react-async';

import Table, { Column, pageLimitOptions } from '../../components/tables/Table';

const getCampaign = async id => {
  try {
    const res = await callApi(`/campaigns/${id}`);
    return res.data;
  } catch {
    throw new Error('Failed to fetch campaign');
  }
};

const getCampaigns = async campaignIds => {
  let campaigns = [];
  const batches = chunk(campaignIds, 5);

  for (const batch of batches) {
    const res = await Promise.all(batch.map(async campaign => getCampaign(campaign)));
    campaigns = campaigns.concat(res);
  }
  return campaigns;
};

const CampaignListModal = ({
  isOpen,
  setIsOpen,
  campaignIds
}: {
  isOpen: boolean,
  setIsOpen: (val: boolean) => void,
  campaignIds: number[]
}) => {
  const { data: campaigns, run, isLoading, isSettled } = useAsync({
    deferFn: () => getCampaigns(campaignIds)
  });

  React.useEffect(() => {
    if (isOpen && !isLoading && !isSettled) {
      run();
    }
  }, [isOpen, run, isLoading, isSettled]);

  return (
    <Modal key="modal" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <section>
        <header>
          <h3>Campaigns included in budget</h3>
        </header>
        {isLoading && <Spinner size="large" />}
        {campaigns && (
          <Table
            className="is-fullwidth is-hoverable"
            data={campaigns}
            initialSort="id"
            initialSortDirection="desc"
            pagination={campaigns.length > pageLimitOptions[1]}
            searchBy={['name']}>
            <Column
              name="name"
              component={props => {
                return <a href={`/admin/campaigns/${props.rowData.id}`}>{props.data}</a>;
              }}>
              Campaign name
            </Column>
            <Column name="id">Campaign id</Column>
            <Column
              name="budget"
              component={props => {
                return <FormattedNumber type="cost" value={props.data} defaultToZero />;
              }}>
              Budget
            </Column>
            <Column
              name="created"
              sortBy={created => new Date(created).getTime()}
              component={props => {
                const date = props.rowData.created;
                return <span>{moment(date).format('MMM Do YYYY')}</span>;
              }}>
              Created
            </Column>
          </Table>
        )}
        <footer className="is-flex is-justify-content-end mt-5">
          <button type="button" className="button mr-4" onClick={() => setIsOpen(false)}>
            Close
          </button>
        </footer>
      </section>
    </Modal>
  );
};

export default CampaignListModal;
