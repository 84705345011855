import React from 'react';

import { countryCodes, generateTopCountries } from '@sharkpunch/matchmade-common/countryCodes';
import { injectIntl } from 'react-intl';

import { numberFormatter } from '@sharkpunch/matchmade-common/formatters';

import userAgent from '../../../helpers/userAgent';

import { Pie as PieChart } from 'react-chartjs-2';

import { getColorVariables } from '../../../config';
import { getCountryChartConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const variables = getColorVariables();

const CountryChart = ({ country, height, intl }) => {
  const top = generateTopCountries(country, 4, 1);
  const labels = top.map(entry => {
    const c = countryCodes[entry.name.toUpperCase()];
    if (c) return c;
    return entry.name;
  });

  const values = top.map(e => numberFormatter.format(e.value));

  const title = intl.formatMessage({
    id: 'influencer.dashboard.chart.title.country'
  });

  const config = getGraphConfig(labels, values, title, variables, userAgent.isMobile());

  return <PieChart height={height || (userAgent.isMobile() ? 250 : 150)} {...config} />;
};

export default injectIntl(CountryChart);
