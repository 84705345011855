// @flow
import * as React from 'react';
import classNames from 'classnames';

import injectRoleChecking from '../../../hoc/injectRoleChecking';
import type { ExtraProps } from '../../../hoc/injectRoleChecking';

import './ChannelHeader.scss';

type Props = {
  ...ExtraProps,
  children: React.Element<*> | React.Element<*>[],
  adminActions?: React.Element<*> | React.Element<*>[],
  backgroundImage?: string,
  isVertical?: boolean,
  isMinimal?: boolean,
  // This header is reused for CampaignCards where
  // a more appropriate property name would be contentPlatform etc.
  channelTypePosition?: 'left' | 'right'
};

const ChannelHeader = (props: Props) => {
  const {
    children,
    backgroundImage,
    isVertical,
    isMinimal,
    channelTypePosition = 'left',
    adminActions,
    isWhitelabelAdmin = false
  } = props;
  const rootClass = classNames('ChannelHeader', {
    'ChannelHeader--vertical': isVertical,
    'ChannelHeader--minimal': isMinimal
  });

  const darkerBgImage = `linear-gradient(
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    )${backgroundImage ? `, url(${backgroundImage})` : ''}`;

  return (
    <div className={rootClass}>
      <div
        className={`ChannelHeader__channelActionIcon ChannelHeader__channelActionIcon--${channelTypePosition}`}>
        {isWhitelabelAdmin && adminActions}
      </div>
      <div
        className="ChannelHeader__background svgBlur"
        style={{
          // We want to make background a bit darker, hence the gradient
          backgroundImage: darkerBgImage
        }}
      />
      {children}
    </div>
  );
};

export default injectRoleChecking()(ChannelHeader);
