import buildContainerReducer from '../helpers/buildContainerReducer';

export default buildContainerReducer(
  {
    searchResults: [],
    searchingWarehouse: false,
    error: null,
    isImportingMockInfluencer: false,
    mockInfluencer: null
  },
  {
    error: null
  },
  ['manager.search', 'influencer.mock', 'influencer.resetMock']
);
