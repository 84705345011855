// @flow
import type { Action, Reducer, ReducerCreator } from '../../types/action.flow';

import find from 'lodash/find';
import remove from 'lodash/remove';

import { OUT_OF_DATE_NEGOTIATION } from '@sharkpunch/matchmade-common/errorCodes';

import {
  INFLUENCER_DECLINED,
  INFLUENCER_WITHDREW,
  PUBLISHER_DECLINED,
  PUBLISHER_WITHDREW
} from '@sharkpunch/matchmade-common/campaignAgreement';

import { acceptCampaign, declineCampaign, negotiateCampaign } from '../../actions/campaign';

// This function updates an agreement List by using the payload from an error
// It replaces the old out-of-date agreement in the list with the latest data
// Examples
// - Update the out-of-date agreement when doing negotiation in publisher dashboard
// - Update the out-of-date agreement when doing negotiation in influencer dashboard
// TODO: have proper type for agreements
export function handleOutOfDateNegotiationMoves<T: Object>(params: {
  getAgreementsFromState: (state: T) => any[] | null,
  setAgreementsInState: (state: T, agreements: any[]) => T
}): Reducer<T> {
  const { getAgreementsFromState, setAgreementsInState } = params;

  return (state, action) => {
    switch (action.type) {
      case negotiateCampaign.FAILURE:
      case acceptCampaign.FAILURE:
      case declineCampaign.FAILURE:
        if ((action.payload || {}).code !== OUT_OF_DATE_NEGOTIATION) return state;
        const agreements = getAgreementsFromState(state);

        if (!agreements) {
          return state;
        }

        const updatedAgreement = action.payload.params;

        return setAgreementsInState(
          state,
          agreements.map(agreement => {
            if (agreement.id !== updatedAgreement.id) {
              return agreement;
            }

            return {
              ...agreement,
              ...updatedAgreement
            };
          })
        );
      default:
        return state;
    }
  };
}

// this function is used to process the `influencer` data after a negotiation move is done from the influencer side
// for example update their dashboard. This is specific for `influencer` or `influencer_manager` where the data structure
// is different from the `publisher`'s side
export function updateInfluencerCampaignsAfterANegotiationMove(state: any, action: Action) {
  // All this dance here is just because we store campaigns
  // as part of influencer object
  const { influencer } = state;
  if (!influencer) return state;
  const campaignAgreement = action.payload;
  const campaigns = [...(influencer.campaigns || [])];
  let availableCampaigns = [...(influencer.availableCampaigns || [])];

  let agreementForNewCampaign = find(availableCampaigns, {
    id: campaignAgreement.campaignId
  });
  const agreementForExistingCampaign = find(campaigns, {
    id: campaignAgreement.campaignId
  });

  if (agreementForNewCampaign) {
    agreementForNewCampaign.agreement = campaignAgreement;
    campaigns.unshift(agreementForNewCampaign);
    remove(availableCampaigns, campaign => {
      return campaignAgreement.campaignId === campaign.id;
    });
  } else if (agreementForExistingCampaign) {
    // This updated existing campaign; if it's decline or withdrawal, we need
    // to move card back to availableCampaigns and delete agreement
    // (since backend filters those out)
    if (
      [INFLUENCER_DECLINED, PUBLISHER_DECLINED, INFLUENCER_WITHDREW, PUBLISHER_WITHDREW].indexOf(
        campaignAgreement.status
      ) !== -1
    ) {
      remove(campaigns, campaign => {
        return campaign.id === campaignAgreement.campaignId;
      });
      agreementForExistingCampaign.agreement = null;
    } else {
      // ...else just update campaign agreement
      agreementForExistingCampaign.agreement = campaignAgreement;
    }
  }

  influencer.campaigns = campaigns;
  influencer.availableCampaigns = availableCampaigns;

  return {
    ...state,
    influencer
  };
}

export const handleAcceptCampaignActions: ReducerCreator<
  { onSuccess: Reducer<any> },
  {
    isAcceptingCampaign: boolean,
    acceptingCampaignAgreement: any, // TODO put proper type here
    acceptedCampaignAgreement: any // TODO put proper type here
  }
> = callbacks => {
  const { onSuccess = (state, action) => state } = callbacks || {};

  return (state, action) => {
    switch (action.type) {
      case acceptCampaign.REQUEST:
        return {
          ...state,
          isAcceptingCampaign: true,
          acceptingCampaignAgreement: action.payload
        };
      case acceptCampaign.SUCCESS:
        return {
          ...onSuccess(state, action),
          isAcceptingCampaign: false,
          acceptingCampaignAgreement: null,
          acceptedCampaignAgreement: action.payload
        };
      case acceptCampaign.FAILURE:
        return {
          ...state,
          isAcceptingCampaign: false,
          acceptingCampaignAgreement: null
        };
      default:
        return state;
    }
  };
};

export const handleDeclineCampaignActions: ReducerCreator<
  { onSuccess: Reducer<any> },
  {
    isDecliningCampaign: boolean,
    decliningCampaignAgreement: any // TODO put proper type here
  }
> = callbacks => {
  const { onSuccess = (state, action) => state } = callbacks || {};

  return (state, action) => {
    switch (action.type) {
      case declineCampaign.REQUEST:
        return {
          ...state,
          isDecliningCampaign: true,
          decliningCampaignAgreement: action.payload
        };
      case declineCampaign.SUCCESS:
        return {
          ...onSuccess(state, action),
          isDecliningCampaign: false,
          decliningCampaignAgreement: null
        };
      case declineCampaign.FAILURE:
        return {
          ...state,
          isDecliningCampaign: false,
          decliningCampaignAgreement: null
        };
      default:
        return state;
    }
  };
};

export const handleNegotiateCampaignActions: ReducerCreator<
  { onSuccess: Reducer<any> },
  {
    isNegotiatingCampaign: boolean,
    creatingCampaignAgreement: any,
    createdCampaignAgreement: any // TODO put proper type here
  }
> = callbacks => {
  const { onSuccess = (state, action) => state } = callbacks || {};

  return (state, action) => {
    switch (action.type) {
      case negotiateCampaign.REQUEST:
        return {
          ...state,
          isNegotiatingCampaign: true,
          creatingCampaignAgreement: action.payload
        };
      case negotiateCampaign.SUCCESS:
        return {
          ...onSuccess(state, action),
          isNegotiatingCampaign: false,
          creatingCampaignAgreement: null,
          createdCampaignAgreement: action.payload
        };
      case negotiateCampaign.FAILURE:
        return {
          ...state,
          isNegotiatingCampaign: false,
          creatingCampaignAgreement: null
        };
      default:
        return state;
    }
  };
};
