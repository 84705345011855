// @flow
import type { Role } from 'matchmade-types';

import { createApiAction } from './helper';

import clone from 'lodash/clone';

export type DeleteAccount = (id: number, accountType: Role) => Promise<any>;
export const deleteAccount = createApiAction('DELETE_ACCOUNT', (id, accountType) => {
  return {
    path: `/accounts/${id}`,
    options: {
      method: 'DELETE',
      body: {
        accountType
      }
    }
  };
});

export type VerifyAccount = (id: number, accountType: Role) => Promise<any>;
export const verifyAccount = createApiAction('VERIFY_ACCOUNT', (id, accountType) => {
  return {
    path: `/accounts/${id}/verify`,
    options: {
      method: 'POST',
      body: {
        accountType
      }
    },
    loadingPayload: { id }
  };
});

export type UnverifyAccount = (id: number, accountType: Role) => Promise<any>;
export const unverifyAccount = createApiAction(
  'UNVERIFY_ACCOUNT',
  (id, accountType, team, games) => {
    team = clone(team);

    if (team) {
      const teamId = parseInt(team.id, 10);
      if (!teamId) delete team.id;
    }

    return {
      path: `/accounts/${id}/unverify`,
      options: {
        method: 'POST',
        body: {
          accountType: accountType,
          team,
          games
        }
      },
      loadingPayload: { id }
    };
  }
);

export const fetchTeam = createApiAction('FETCH_TEAM', (accountId, teamId) => {
  return {
    path: `/accounts/${accountId}/teams/${teamId}`,
    options: {
      method: 'GET'
    }
  };
});

export const inviteTeamMember = createApiAction(
  'INVITE_TEAM_MEMBER',
  (accountId, teamId, email) => {
    return {
      path: `/accounts/${accountId}/teams/${teamId}/members`,
      options: {
        method: 'POST',
        body: {
          email
        }
      }
    };
  }
);

export const deleteTeamMember = createApiAction(
  'DELETE_TEAM_MEMBER',
  (accountId, teamId, teamMemberId) => {
    return {
      path: `/accounts/${accountId}/teams/${teamId}/members/${teamMemberId}`,
      options: {
        method: 'DELETE'
      }
    };
  }
);

export const deleteTeamInvite = createApiAction(
  'DELETE_TEAM_INVITE',
  (accountId, teamId, teamInviteId) => {
    return {
      path: `/accounts/${accountId}/teams/${teamId}/invites/${teamInviteId}`,
      options: {
        method: 'DELETE'
      }
    };
  }
);

export const updateSettings = createApiAction('UPDATE_ACCOUNT_SETTINGS', (id, settings = {}) => {
  return {
    path: `/accounts/${id}/settings`,
    options: {
      method: 'PUT',
      body: {
        settings
      }
    }
  };
});
