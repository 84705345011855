import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import find from 'lodash/find';
import isString from 'lodash/isString';

import getConfig from '../../config';

import { AttributionPartner, CampaignType } from '@sharkpunch/matchmade-common/campaign';
import { SETTLED } from '@sharkpunch/matchmade-common/campaignAgreement';

import ButtonWithLabel from '../common/ButtonWithLabel';
import EditIcon from '../common/Icons/EditIcon';

import { AttributionPartnerOptionEnum as AttributionPartnerOption } from '../../components/campaignCreation/CampaignDetails/attributionPartners/AttributionPartnerOption';
import { canDoCPI, getService } from '../../helpers/attributionPartners';
import { checkIfWhitelabelAdmin } from '../../helpers/user';
import { getGameTypeForEstimates } from '../../helpers/game';
import GameHeader from '../game/GameHeader';
import Tooltip from '../common/Tooltip';

import { FormattedMessage, injectIntl } from 'react-intl';

import CampaignAllocatedBudget from './CampaignAllocatedBudget';
import CampaignAttributionSetup from './CampaignAttributionSetup';
import CampaignBudgetLock from './CampaignBudgetLock';
import CampaignTabsSection from './CampaignTabsSection';
import CampaignPromoCodes from './PromoCodes';

import * as formatters from '@sharkpunch/matchmade-common/formatters';
import FormattedNumber from '../common/FormattedNumber';

import './CampaignViewForPublisher.scss';

import DeclineOfferModal from '../modals/DeclineOfferModal';

import ContentSubmissionTaskTemplate from './ContentSubmissionTaskTemplate';

const defaultAttributionPartner = getService(AttributionPartner.NONE);

const whitelabelPixelUrl = getConfig('tracking.url');

function generateGameHeaderStats(campaign, campaignOverallStats = {}, summaryText) {
  // TODO this should happen in the backend, so that here we can just render
  const { cost, installs, clicks, payout, commissionRate } = campaignOverallStats;
  const views = Object.keys(campaign.viewCount).reduce(
    (total, key) => total + campaign.viewCount[key],
    0
  );
  const gameType = getGameTypeForEstimates(campaign.game);

  // WITH commission for publishers/admins, WITHOUT commission for influencers
  const conversion = installs / clicks || 0;

  const joinedInfluencers = campaign.influencers.filter(influencer => {
    const agreement = find(campaign.agreements, {
      influencerId: influencer.id
    });
    return agreement && agreement.status === SETTLED;
  });

  // Normally all cost calculations happen on the server but since this is an estimate, it doesn't matter.
  const {
    installs: estimatedInstalls,
    views: estimatedViews,
    costs: estimatedCost
  } = joinedInfluencers.reduce(
    (total, influencer) => {
      const agreement = find(campaign.agreements, {
        influencerId: influencer.id
      });
      if (!agreement) return total;

      const { contentPlatform } = agreement;
      const promotionType = agreement.promotionType || campaign.defaultPromotionType;
      const estimates = agreement.estimates && agreement.estimates[gameType];
      const estimatedInstalls =
        (estimates && estimates.installs && estimates.installs[promotionType].median) || 0;
      const estimatedViews =
        (estimates && estimates.views && estimates.views[contentPlatform]) || 0;

      return {
        installs: total.installs + estimatedInstalls,
        views: total.views + estimatedViews,
        cost: total.cost + estimatedInstalls * agreement.cpi + agreement.minGuarantee
      };
    },
    { installs: 0, views: 0, cost: 0 }
  );

  let left = (
    <React.Fragment>
      <div>
        <div className="stat-val">
          <FormattedNumber type="number" value={installs} defaultToZero />
        </div>
        <div className="stat-key">
          <FormattedMessage id="campaign.kpi.chart.install.summary" values={{ installs }} />
          <div className="CampaignViewForPublisher__estimated-installs">
            (<FormattedMessage id="publisher.campaign.est" />
            <FormattedNumber type="number" value={estimatedInstalls} defaultToZero />)
          </div>
        </div>
      </div>
      <div>
        <div className="stat-val">
          <FormattedNumber type="number" value={views} defaultToZero />
        </div>
        <div className="stat-key">
          <FormattedMessage id="campaign.kpi.chart.view.summary" values={{ views }} />
          <div className="CampaignViewForPublisher__estimated-installs">
            (<FormattedMessage id="publisher.campaign.est" />
            <FormattedNumber type="number" value={estimatedViews} defaultToZero />)
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  // always WITHOUT commission, currently only shown for admins
  const summaryTitle =
    cost > 0 && checkIfWhitelabelAdmin() ? (
      <Tooltip
        tooltip={
          <div>
            <p>
              {formatters.costFormatter.format(cost / 100)}
              &nbsp;
              <FormattedMessage id="campaign.kpi.chart.cost.summary" />
            </p>
            <p>
              {formatters.costFormatter.format(payout / 100)}
              &nbsp;
              <FormattedMessage id="campaign.kpi.chart.payout.summary" />
            </p>
            <p className="has-text-centered">
              <FormattedNumber type="percentage" value={commissionRate} defaultToZero />
            </p>
          </div>
        }>
        {summaryText}
      </Tooltip>
    ) : (
      summaryText
    );

  let right = (
    <React.Fragment>
      {campaign.campaignType !== CampaignType.CHARITY ? (
        <React.Fragment>
          <div>
            <div className="stat-val">
              <FormattedNumber type="cost" value={cost} defaultToZero />
            </div>
            <div className="stat-key">
              {summaryTitle}
              <div className="CampaignViewForPublisher__estimated-cost">
                (<FormattedMessage id="publisher.campaign.est" />
                <FormattedNumber type="cost" value={estimatedCost} defaultToZero />)
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      <div>
        <div className="stat-val">
          <FormattedNumber type="percentage" value={conversion} />
        </div>
        <div className="stat-key">
          <FormattedMessage id="campaign.kpi.chart.conversion.summary" />
        </div>
      </div>
    </React.Fragment>
  );

  return {
    left,
    right
  };
}

function CampaignBrief(props) {
  const { talkingPoints, requirements } = props;

  const boxes = [
    talkingPoints ? (
      <section key="description" className="section box heading-block">
        <h2 className="subtitle">
          <FormattedMessage
            id="campaignCreation.campaignDescription.advancedSetting.talkingPoints"
            tagName="span"
          />
        </h2>

        <pre>{talkingPoints}</pre>
      </section>
    ) : null,
    requirements ? (
      <section key="requirements" className="section box heading-block">
        <h2 className="subtitle">
          <FormattedMessage
            id="campaignCreation.campaignDescription.advancedSetting.restrictions"
            tagName="span"
          />
        </h2>

        <pre>{requirements}</pre>
      </section>
    ) : (
      requirements
    )
  ].filter(Boolean);

  if (!boxes.length) {
    return null;
  }

  return <div className="CampaignBrief">{boxes}</div>;
}

class CampaignViewForPublisher extends Component {
  constructor(props) {
    super(props);

    const { campaign } = this.props;

    /*
     *  Default attribution partner to the campaign default, because the API expects
     *  attribution partner when saving the prompt for attribution, otherwise changing
     *  a URL or code without changing existing partner will result in validation error
     */
    const attributionPartner = (
      getService(campaign.attributionPartner) || defaultAttributionPartner
    ).value;

    this.state = {
      renderVideo: '',
      showAttributionSetupInstructions: false,
      isDeclineOfferModalOpen: false,
      declineOfferInfluencerId: null,
      declineOfferCampaignId: null,
      declineOfferLogId: null,
      attributionPartnerTrackingCode: campaign ? campaign.attributionPartnerTrackingCode : null,
      attributionPartnerPlatformSpecificTrackingCodes: campaign
        ? campaign.attributionPartnerPlatformSpecificTrackingCodes
        : null,
      attributionPartner,
      attributionPartnerSelectedOption: campaign.attributionPartnerSelectedOption
    };
  }

  componentDidMount() {
    const el = document.querySelectorAll('#campaign-allocated-budget, #campaign-tabs');
    if (el[0]) {
      el[0].scrollIntoView();
    }
  }

  toggleInstructions = () => {
    this.setState({
      showAttributionSetupInstructions: !this.state.showAttributionSetupInstructions
    });
  };

  renderVideo = videoId => {
    this.setState({ renderVideo: videoId });
  };

  onClickSave = async e => {
    e.stopPropagation();
    e.preventDefault();

    /*
     *  Add attribution data to campaign on save.
     *  If this.state.attributionPartner is an object, add attributionPartnerPlatformSpecificTrackingCodes.
     *  If it is a string, add attributionPartnerTrackingCode.
     *  If it is empty, don't add anything (not adding any attribution data on edit).
     */
    const { campaign, onUpdateCampaign } = this.props;
    const {
      attributionPartner,
      attributionPartnerTrackingCode,
      attributionPartnerPlatformSpecificTrackingCodes,
      attributionPartnerSelectedOption
    } = this.state;
    delete campaign.attributionPartner;
    delete campaign.attributionPartnerTrackingCode;
    delete campaign.attributionPartnerPlatformSpecificTrackingCodes;
    if (isString(attributionPartner)) {
      campaign.attributionPartner = attributionPartner;
    }
    switch (attributionPartnerSelectedOption) {
      case AttributionPartnerOption.CODE_OR_URL:
      case AttributionPartnerOption.URL:
        campaign.attributionPartnerTrackingCode = attributionPartnerTrackingCode;
        break;
      case AttributionPartnerOption.PLATFORM_SPECIFIC:
        campaign.attributionPartnerPlatformSpecificTrackingCodes = attributionPartnerPlatformSpecificTrackingCodes;
        break;
      default:
        break;
    }

    await onUpdateCampaign(campaign);
  };

  renderSection(name) {
    const sections = this.props.sections || {};
    return sections[name] || null;
  }

  toggleDeclineOfferModal = params => {
    this.setState(prevState => ({
      isDeclineOfferModalOpen: !prevState.isDeclineOfferModalOpen,
      declineOfferInfluencerId: params ? params.influencerId : null,
      declineOfferChannelId: params ? params.channelId : null,
      declineOfferCampaignId: params ? params.campaignId : null,
      declineOfferLogId: params ? params.logId : null
    }));
  };

  onDeclineCampaign = async ({ reason }) => {
    const {
      declineOfferInfluencerId,
      declineOfferChannelId,
      declineOfferCampaignId,
      declineOfferLogId
    } = this.state;
    const { declineCampaign } = this.props;

    await declineCampaign({
      campaignId: declineOfferCampaignId,
      influencerId: declineOfferInfluencerId,
      channelId: declineOfferChannelId,
      logId: declineOfferLogId,
      reason
    });
  };

  onChange = (attribute: string, value: any) => {
    this.setState({ [attribute]: value });
  };

  render() {
    const { campaign, budget, campaignInfluencerStats, isDecliningCampaign } = this.props;
    const { isDeclineOfferModalOpen } = this.state;
    const shouldNotHideAutopilotParts =
      !campaign.isAutopilot || (campaign.isAutopilot && checkIfWhitelabelAdmin());
    const {
      attributionPartner,
      attributionPartnerTrackingCode,
      attributionPartnerPlatformSpecificTrackingCodes,
      attributionPartnerSelectedOption
    } = this.state;
    let error = null;
    if (this.props.error && this.props.error.message) {
      error = <div className="notification is-danger">{this.props.error.message}</div>;
    }

    let gameHeaderStats = null;
    if (campaign.defaultContentPlatform === 'youtube') {
      gameHeaderStats = generateGameHeaderStats(
        campaign,
        this.props.campaignOverallStats,
        <span>{this.props.intl.formatMessage({ id: 'campaign.kpi.chart.cost.summary' })}</span>
      );
    } else if (campaign.defaultContentPlatform === 'twitch') {
      if (!this.props.campaignAnalytics || !this.props.campaignAnalytics.aggs) {
        return null;
      }
      const cost = campaignInfluencerStats
        ? campaignInfluencerStats.reduce((acc, cur) => cur.cost + acc, 0)
        : 0;

      gameHeaderStats = {
        left: {
          key: 'Average CCV',
          value: <FormattedNumber type="number" value={this.props.campaignAnalytics.aggs.avg} />
        },
        center: {
          key: 'Peak CCV',
          value: <FormattedNumber type="number" value={this.props.campaignAnalytics.aggs.max} />
        }
      };
      if (!campaign.campaignType !== CampaignType.CHARITY) {
        gameHeaderStats.right = {
          key: 'Cost',
          value: <FormattedNumber type="cost" value={cost} defaultToZero />
        };
      }
    }

    const editCampaignLink = checkIfWhitelabelAdmin()
      ? `/admin/campaigns/${campaign.id}/edit`
      : `/dashboard/publisher/campaigns/${campaign.id}/edit`;

    return (
      <div className="CampaignViewForPublisher">
        <div className="is-flex CampaignViewForPublisher__heading-container">
          <h1 className="title CampaignViewForPublisher__header-text">
            <FormattedMessage id="campaign.name.placeholder" values={{ title: campaign.name }} />
          </h1>
          <div className="is-flex CampaignViewForPublisher__header-buttons">
            <ButtonWithLabel
              to={editCampaignLink}
              icon={<EditIcon className="button is-primary is-rounded" withTooltip={false} />}>
              <FormattedMessage id="campaign.edit" tagName="strong" />
            </ButtonWithLabel>
          </div>
        </div>
        {error}
        {!canDoCPI(campaign.attributionPartner) && (
          <CampaignAttributionSetup
            onChange={this.onChange}
            onSave={this.onClickSave}
            attributionPartner={attributionPartner}
            attributionPartnerTrackingCode={attributionPartnerTrackingCode}
            attributionPartnerPlatformSpecificTrackingCodes={
              attributionPartnerPlatformSpecificTrackingCodes
            }
            attributionPartnerSelectedOption={attributionPartnerSelectedOption}
            platforms={(campaign && campaign.game && campaign.game.platforms) || []}
            campaignId={campaign.id}
          />
        )}
        {campaign.attributionPartner === AttributionPartner.MATCHMADE_PIXEL && (
          <div className="box CampaignViewForPublisher__matchmade-tracking">
            <FormattedMessage id="publisher.campaign.attribution.pixelDescription" />
            <pre>
              {this.props.intl.formatMessage(
                {
                  id:
                    'campaignCreation.campaignDetails.advancedSetting.installAttribution.matchmadePixel.link'
                },
                { trackingUrl: whitelabelPixelUrl, campaignId: campaign.id }
              )}
            </pre>
          </div>
        )}
        <Helmet title={campaign.name} />
        <section className="section box heading-block">
          <GameHeader game={campaign.game} stats={gameHeaderStats} />
        </section>
        {this.renderSection('afterGameHeader')}
        {checkIfWhitelabelAdmin() && (
          <div className="columns is-desktop">
            <div className="column is-desktop">
              <CampaignAllocatedBudget
                totalBudget={budget ? budget.totalBudget : 0}
                allocatedBudget={budget ? budget.allocatedBudget : 0}
                unallocatedBudget={budget ? budget.unallocatedBudget : 0}
                budgetInNegotiation={budget ? budget.budgetInNegotiation : 0}
                spentBudget={budget ? budget.spentBudget : 0}
                numOfPendingConditionalOffers={budget ? budget.numOfPendingConditionalOffers : 0}
              />
            </div>
          </div>
        )}
        {checkIfWhitelabelAdmin() && <CampaignPromoCodes campaignId={campaign.id} />}
        {checkIfWhitelabelAdmin() && <CampaignBudgetLock campaignId={campaign.id} />}
        {checkIfWhitelabelAdmin() && (
          <ContentSubmissionTaskTemplate
            contentSubmissionTaskTemplateId={campaign.contentSubmissionTaskTemplateId}
          />
        )}
        {shouldNotHideAutopilotParts ? (
          <CampaignTabsSection
            {...this.props}
            onShowDeclineOfferModal={this.toggleDeclineOfferModal}
          />
        ) : null}
        <DeclineOfferModal
          onClose={this.toggleDeclineOfferModal}
          onDeclineCampaign={this.onDeclineCampaign}
          isOpen={isDeclineOfferModalOpen}
          isLoading={isDecliningCampaign}
        />
        <CampaignBrief talkingPoints={campaign.talkingPoints} requirements={campaign.description} />
      </div>
    );
  }
}

CampaignViewForPublisher.propTypes = {
  campaign: PropTypes.object.isRequired,
  agreements: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.object,
  activeTab: PropTypes.string,
  // props for campaign negotiation

  isNegotiatingCampaign: PropTypes.bool,
  creatingCampaignAgreement: PropTypes.object,
  createdCampaignAgreement: PropTypes.object,

  acceptCampaign: PropTypes.func,
  isAcceptingCampaign: PropTypes.bool,
  acceptingCampaignAgreement: PropTypes.object,
  acceptedCampaignAgreement: PropTypes.object,

  declineCampaign: PropTypes.func,
  isDecliningCampaign: PropTypes.bool,
  decliningCampaignAgreement: PropTypes.object,

  isDeletingCampaignInvite: PropTypes.bool,
  deletingCampaignInvite: PropTypes.object,
  deletedCampaignInvite: PropTypes.object,

  campaignOverallStats: PropTypes.object,
  sections: PropTypes.object,
  fetchAllInNetworkInfluencers: PropTypes.func,
  onChangeSettings: PropTypes.func,
  onUpdateCampaign: PropTypes.func,
  linkCollectionToCampaign: PropTypes.func,
  removeCollectionFromCampaign: PropTypes.func,
  user: PropTypes.object,
  location: PropTypes.object
};

CampaignViewForPublisher.defaultProps = {
  fetchAllInNetworkInfluencers: (influencerId, options) => {},
  onChangeSettings: settings => {},
  isFetchingAllInNetworkInfluencersForCampaign: false
};

export default injectIntl(CampaignViewForPublisher);
