// most of this is copied from publisherTeamManage reducer
import buildContainerReducer from '../../helpers/buildContainerReducer';

import { deleteTeamInvite, deleteTeamMember, inviteTeamMember } from '../../../actions/account';

import { updateTeam } from '../../../actions/team';

import Immutable from 'immutable';

function addEntryToListAndRemoveDuplicated(list, newEntry) {
  return list
    .filter(entry => {
      return entry.get('id') !== newEntry.get('id');
    })
    .unshift(newEntry);
}

export default buildContainerReducer(
  {
    isFetchingTeam: true,
    isInvitingTeamMember: false,
    isDeletingTeamMember: false,
    isDeletingTeamInvite: false,
    isUpdatingTeam: false,
    team: null,
    error: null
  },
  {
    error: null
  },
  ['account.fetchTeam', 'account.inviteTeamMember', 'account.deleteTeamMember', 'team.updateTeam'],
  (state, action) => {
    let team = state.get('team');

    switch (action.type) {
      case inviteTeamMember.SUCCESS:
        const newMembers = Immutable.fromJS(action.payload);
        if (team) {
          let members = team.get('members') || Immutable.List([]);
          let invites = team.get('invites') || Immutable.List([]);
          newMembers.forEach(newMember => {
            // if this is a new account add it to invites list
            // otherwise add it to members
            // However, since people can add the same email, we need
            // to remove duplicated members/invites
            if (newMember.get('account').get('id')) {
              members = addEntryToListAndRemoveDuplicated(members, newMember);
            } else {
              invites = addEntryToListAndRemoveDuplicated(invites, newMember);
            }
          });
          team = team.set('members', members).set('invites', invites);

          state = state.set('team', team);
        }
        break;
      case deleteTeamMember.SUCCESS:
        if (!action.payload) break;
        const deletedMember = Immutable.fromJS(action.payload);
        if (!team) break;

        let members = team.get('members') || Immutable.List([]);
        team = team.set(
          'members',
          members.filter(member => {
            return member.get('id') !== deletedMember.get('id');
          })
        );
        state = state.set('team', team);
        break;
      case deleteTeamInvite.SUCCESS:
        if (!action.payload) break;
        const deletedInvite = Immutable.fromJS(action.payload);
        if (!team) break;

        let invites = team.get('invites') || Immutable.List([]);
        team = team.set(
          'invites',
          invites.filter(invite => {
            return invite.get('id') !== deletedInvite.get('id');
          })
        );
        state = state.set('team', team);
        break;
      case updateTeam.SUCCESS:
        if (!team) break;
        const newTeam = Immutable.fromJS(action.payload);

        team = team.merge(newTeam);

        state = state.set('team', team);
        break;
      default:
        break;
    }

    return state;
  }
);
