// @flow
// $FlowFixMe
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { toHumanReadableNumber } from '@sharkpunch/matchmade-common/formatters';
import { useDispatch } from 'react-redux';
import Link from 'react-router/lib/Link';
import React from 'react';
import classNames from 'classnames';
import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { DEFAULT_SEARCH_QUERY } from '../../../helpers/searchYoutube';
import { DownloadUrlButton } from '../../common/DownloadUrlButton';
import { MixpanelEvent, sendMixpanelEvent } from '../../../helpers/mixpanelEvents';
import { SORT_OPTIONS } from '../../../helpers/influencerSearchOptions';
import {
  fetchSuggestedGames,
  gotoSearchURL,
  saveSearchToCollection,
  searchInfluencers
} from '../../../actions/influencerYoutubeSearch';
import Button, { Color } from '../../common/Button';
import ChannelFilters from './ChannelFilters';
import ExternallyManagedInfluencerCollection from '../../influencer/ExternallyManagedInfluencerCollection';
import GamesAndChannelName from './GamesAndChannelName';
import InNetworkChannelFilters from './InNetworkChannelFilters';
import SaveIcon from '../../common/Icons/SaveIcon';
import SaveSearchToCollectionTrigger from './SaveSearchToCollectionTrigger';
import SearchInfluencerCard from '../../influencer/cards/SearchInfluencerCard';
import SearchInfluencersTable from '../../influencer/tables/SearchInfluencersTable';
import Spinner from '../../common/Spinner';
import Tabs from '../../common/Tabs';
import ToggleContainer from '../../common/ToggleContainer';
import usePrevious from '../../../hooks/usePrevious';
import type { Collection, CollectionWithIncludedFields } from '../../../types/collection.flow';
import type { SearchParams, SuggestedGame, SuggestedTag } from '../../../types/search.flow';

import './YoutubeSearch.scss';

const SuggestionTag = ({ value, onClick, isDisabled }) => {
  const buttonClassName = classNames('tag is-rounded is-medium is-unselectable clickable', {
    'is-disabled': isDisabled
  });
  return (
    <button className={buttonClassName} key={value} onClick={onClick}>
      <strong>{value}</strong>
    </button>
  );
};

const SearchCriteriaTag = ({ value, onClick, isDisabled, isUnchangeable = false }) => {
  const spanClassName = classNames('tag is-rounded is-medium is-unselectable', {
    'is-disabled': isDisabled
  });
  const buttonClassName = classNames('delete is-small', {
    'is-disabled': isDisabled
  });
  return (
    <span className={spanClassName} key={value}>
      <strong>{value}</strong>
      {isUnchangeable ? null : (
        <button className={buttonClassName} disabled={isDisabled} onClick={onClick} />
      )}
    </span>
  );
};

const LinkToPreviousPage = ({ link }) => {
  const section = link.indexOf('admin/campaigns') > -1 ? 'campaign' : 'collections';
  return (
    <div className="YoutubeSearch__link-to-previous-page">
      <Link to={link}>
        &larr; <FormattedMessage id="search.linkToPreviousPage" values={{ section }} />
      </Link>
    </div>
  );
};

const SearchTitle = ({ collection, linkToPreviousPage }) => {
  const intl = useIntl();
  const searchTitle = (
    <h1 className="title is-1">
      <FormattedMessage id="search.youtube.title" />
    </h1>
  );
  const collectionSearchTitle = (
    <h2 className="subtitle">
      <FormattedMessage id="search.collection.title" />
    </h2>
  );
  return (
    <React.Fragment>
      <Helmet title={intl.formatMessage({ id: 'search.title' })} />
      {!!collection && linkToPreviousPage && <LinkToPreviousPage link={linkToPreviousPage} />}
      {collection ? collectionSearchTitle : searchTitle}
      {!!collection && <h1 className="title is-1">{collection.name}</h1>}
    </React.Fragment>
  );
};

type Props = {
  collection: ?CollectionWithIncludedFields,
  linkToPreviousPage?: string,
  isSearchingInfluencers: boolean,
  query: SearchParams,
  isSavingSearch: boolean,
  collections: Collection[],
  isLoadingCollections: boolean,
  updatedCollection: ?Collection,
  hasSearchedOnce: boolean,
  influencers: Object[], // TODO @Tan 2020-04-17 replace Object with specific type
  totalCount: number,
  isFetchingCountries: boolean,
  errorSearchingInfluencers: ?Object,
  isFetchingSuggestedGames: boolean,
  isFetchingSuggestedTags: boolean,
  suggestedGames: SuggestedGame[],
  suggestedTags: SuggestedTag[]
};

type State = SearchParams & {
  // Edit collection params
  collectionId?: number,
  linkToPreviousPage?: string
};

let YoutubeSearch = (props: Props) => {
  const {
    query,
    collection,
    linkToPreviousPage,
    isSearchingInfluencers,
    isSavingSearch,
    collections,
    isLoadingCollections,
    updatedCollection,
    hasSearchedOnce,
    influencers,
    totalCount,
    isFetchingCountries,
    errorSearchingInfluencers,
    isFetchingSuggestedGames,
    isFetchingSuggestedTags,
    suggestedGames,
    suggestedTags
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  // State is always updated immediately when search input/parameters
  // are changed etc. by the user. User could change minSubscriberCount using the slider
  // or add new played games.
  // Parent component(s) are notified of the state changes when the user presses "Search",
  // through a change in the current URL. Parent component(s) then pass the search query
  // back to this component. In this scenario props.query is equal to state
  // and nothing happens.
  // The only scenario where props.query differs from state and overwrites
  // state is when user presses back or forward or when user opens a search directly
  // from a URL. In this scenario we need to overwrite state with whatever is in props.query.
  const [state, setState] = React.useState<State>(query);
  const previousQuery = usePrevious(query);
  const collectionId = collection && collection.id;

  React.useEffect(() => {
    if (!isEqual(previousQuery, query)) {
      setState(query);
    }
  }, [previousQuery, query]);

  // We fetch new suggestions after selecting/removing played games
  React.useEffect(() => {
    if (state.playedGames.length) {
      dispatch(fetchSuggestedGames.run(state.playedGames));
    }
  }, [state.playedGames, dispatch]);

  const updateUrlBasedOnSearchParams = (page, pageSize, sortBy, sortDirection) => {
    dispatch(
      gotoSearchURL.run({
        ...state,
        // We use this when editing search for collections
        collectionId,
        linkToPreviousPage,
        page,
        pageSize,
        sortBy,
        sortDirection
      })
    );

    setState({
      ...state,
      page,
      pageSize,
      sortBy,
      sortDirection
    });
  };

  const onSelectTags = tags => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { tags });
    setState({ ...state, tags });
  };

  const onExcludeTags = tags => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { excludedTags: tags });
    setState({ ...state, excludedTags: tags });
  };

  const onCategoriesAny = categoriesAny => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { categoriesAny });
    setState({ ...state, categoriesAny });
  };

  const onCategoriesAll = categoriesAll => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { categoriesAll });
    setState({ ...state, categoriesAll });
  };

  const onSelectPlayedGames = playedGames => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { playedGames });
    setState({ ...state, playedGames });
  };

  const onSelectNotPlayedGames = notPlayedGames => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { notPlayedGames });
    setState({ ...state, notPlayedGames });
  };

  const onChangeMatchingContentMinVideoCount = e => {
    setState({ ...state, matchingContentMinVideoCount: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurMatchingContentMinVideoCount = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      matchingContentMinVideoCount: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelCountriesOrEstimatedCountriesWhitelist = channelCountriesOrEstimatedCountriesWhitelist => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelCountriesOrEstimatedCountriesWhitelist
    });
    setState({
      ...state,
      // If adding to whitelist, clear blacklist at the same time, can't have both
      channelCountriesOrEstimatedCountriesBlacklist: [],
      channelCountriesOrEstimatedCountriesWhitelist
    });
  };

  const onChangeChannelCountriesOrEstimatedCountriesBlacklist = channelCountriesOrEstimatedCountriesBlacklist => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelCountriesOrEstimatedCountriesBlacklist
    });
    setState({
      ...state,
      // If adding to blacklist, clear whitelist at the same time, can't have both
      channelCountriesOrEstimatedCountriesWhitelist: [],
      channelCountriesOrEstimatedCountriesBlacklist
    });
  };

  const onChangeChannelMinSubscriberCount = e => {
    setState({ ...state, channelMinSubscriberCount: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMinSubscriberCount = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMinSubscriberCount: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelMin30dAvgViewCount = e => {
    setState({ ...state, channelMin30dAvgViewCount: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMin30dAvgViewCount = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMin30dAvgViewCount: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelMin30dAvgEngagementRatio = e => {
    setState({ ...state, channelMin30dAvgEngagementRatio: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMin30dAvgEngagementRatio = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMin30dAvgEngagementRatio: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelMaxSubscriberCount = e => {
    setState({ ...state, channelMaxSubscriberCount: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMaxSubscriberCount = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMaxSubscriberCount: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelMax30dAvgViewCount = e => {
    setState({ ...state, channelMax30dAvgViewCount: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMax30dAvgViewCount = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMax30dAvgViewCount: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelMax30dAvgEngagementRatio = e => {
    setState({ ...state, channelMax30dAvgEngagementRatio: parseInt(e.target.value, 10) || 0 });
  };

  const onBlurChannelMax30dAvgEngagementRatio = e => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      channelMax30dAvgEngagementRatio: parseInt(e.target.value, 10) || 0
    });
  };

  const onChangeChannelName = channelName => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { channelName });
    setState({ ...state, channelName });
  };

  const onChangeDemographicsTargetCountries = demographicsTargetCountries => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { demographicsTargetCountries });
    setState({ ...state, demographicsTargetCountries });
  };
  const onChangeDemographicsTargetCountriesMinThreshold = value => {
    const demographicsTargetCountriesMinThreshold = parseFloat(value) || 0;
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      demographicsTargetCountriesMinThreshold
    });
    setState({ ...state, demographicsTargetCountriesMinThreshold });
  };
  const onChangeDemographicsGenderType = demographicsGenderType => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { demographicsGenderType });
    setState({ ...state, demographicsGenderType });
  };
  const onChangeDemographicsGenderTypeMinThreshold = value => {
    const demographicsGenderTypeMinThreshold = parseFloat(value) || 0;
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      demographicsGenderTypeMinThreshold
    });
    setState({ ...state, demographicsGenderTypeMinThreshold });
  };
  const onChangeDemographicsOperatingSystems = demographicsOperatingSystems => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { demographicsOperatingSystems });
    setState({ ...state, demographicsOperatingSystems });
  };
  const onChangeDemographicsOperatingSystemMinThreshold = value => {
    const demographicsOperatingSystemMinThreshold = parseFloat(value) || 0;
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      demographicsOperatingSystemMinThreshold
    });
    setState({ ...state, demographicsOperatingSystemMinThreshold });
  };
  const onChangeDemographicsAgeGroups = demographicsAgeGroups => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { demographicsAgeGroups });
    setState({ ...state, demographicsAgeGroups });
  };
  const onChangeDemographicsAgeGroupsMinThreshold = value => {
    const demographicsAgeGroupsMinThreshold = parseFloat(value) || 0;
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, {
      demographicsAgeGroupsMinThreshold
    });
    setState({ ...state, demographicsAgeGroupsMinThreshold });
  };

  const onChangePage = page => {
    updateUrlBasedOnSearchParams(page, state.pageSize, state.sortBy, state.sortDirection);
  };

  const onChangePageSize = pageSize => {
    // Always go to first page if page size changes
    updateUrlBasedOnSearchParams(1, pageSize, state.sortBy, state.sortDirection);
  };

  const onChangeSort = (sortBy, sortDirection) => {
    // Always go to first page if sorting changes
    updateUrlBasedOnSearchParams(1, state.pageSize, sortBy, sortDirection);
  };

  const onSearchInfluencers = () => {
    // Always go to first page if performing a new search
    updateUrlBasedOnSearchParams(1, state.pageSize, state.sortBy, state.sortDirection);
  };

  const onSelectInfluencerType = influencerType => {
    sendMixpanelEvent(MixpanelEvent.USE_SEARCH_FILTERS, { influencerType });
    setState({ ...state, influencerType });
  };

  const renderSearchCriteria = (searchParamName, prefix, format = null) => {
    const defaultValue = DEFAULT_SEARCH_QUERY[searchParamName];
    const value = state[searchParamName] || 0;
    const onClickRemove = () => {
      const resetObject = {};
      resetObject[searchParamName] = defaultValue;
      setState({ ...state, ...resetObject });
    };
    const isUnchangeable = value === defaultValue;
    const prefixedValue = prefix + ': ' + (format ? format(value) : value);

    return (
      <SearchCriteriaTag
        key={prefixedValue}
        value={prefixedValue}
        onClick={onClickRemove}
        isDisabled={isSearchingInfluencers}
        isUnchangeable={isUnchangeable}
      />
    );
  };

  const renderOptionalSearchCriteria = (searchParamName, prefix) => {
    const value = state[searchParamName];
    const defaultValue = DEFAULT_SEARCH_QUERY[searchParamName];
    if (value === defaultValue) {
      return null;
    }
    return renderSearchCriteria(searchParamName, prefix);
  };

  const renderSearchCriteriaFromArrayElement = (searchParamArrayName, elementValue, prefix) => {
    const onClickRemove = () => {
      const resetObject = {};
      resetObject[searchParamArrayName] = without(state[searchParamArrayName], elementValue);
      setState({ ...state, ...resetObject });
    };
    const prefixedValue = prefix + ': ' + elementValue;
    return (
      <SearchCriteriaTag
        key={prefixedValue}
        value={prefixedValue}
        onClick={onClickRemove}
        isDisabled={isSearchingInfluencers}
      />
    );
  };

  const renderSearchCriteriaFromArray = (searchParamArrayName, prefix) => {
    return (state[searchParamArrayName] || []).map(v =>
      renderSearchCriteriaFromArrayElement(searchParamArrayName, v, prefix)
    );
  };

  const renderSuggestedTags = () => {
    return (suggestedTags || []).map(v => {
      const onClick = () => {
        sendMixpanelEvent(MixpanelEvent.USE_SEARCH_SUGGESTION, {
          suggestion_type: 'add_suggested_tag',
          suggestion_value: v.tag,
          suggestion_target_field: 'tags'
        });
        onSelectTags(uniq(state.tags.concat(v.tag)));
      };
      const prefixedValue = 'Used tag: ' + v.tag;
      return (
        <SuggestionTag
          key={prefixedValue}
          value={prefixedValue}
          onClick={onClick}
          isDisabled={isFetchingSuggestedTags}
        />
      );
    });
  };

  const renderSuggestedGames = () => {
    return (suggestedGames || []).map(v => {
      const onclick = () => {
        sendMixpanelEvent(MixpanelEvent.USE_SEARCH_SUGGESTION, {
          suggestion_type: 'add_suggested_game',
          suggestion_value: v.title,
          suggestion_target_field: 'played_games'
        });
        onSelectPlayedGames(uniq(state.playedGames.concat(v.title)));
      };
      const prefixedValue = 'Has played: ' + v.title;
      return (
        <SuggestionTag
          key={prefixedValue}
          value={prefixedValue}
          onClick={onclick}
          isDisabled={isFetchingSuggestedGames}
        />
      );
    });
  };

  const renderInNetworkSearchCriteria = () => {
    const inNetworkTags = concat(
      renderSearchCriteriaFromArray('demographicsTargetCountries', 'Audience From'),
      renderSearchCriteria(
        'demographicsTargetCountriesMinThreshold',
        'Audience min. limit',
        v => v * 100 + '%'
      ),
      renderSearchCriteria('demographicsGenderType', 'Audience gender'),
      renderSearchCriteria(
        'demographicsGenderTypeMinThreshold',
        'Audience gender min. limit',
        v => v * 100 + '%'
      ),
      renderSearchCriteriaFromArray('demographicsOperatingSystems', 'Audience OS'),
      renderSearchCriteria(
        'demographicsOperatingSystemMinThreshold',
        'Audience OS min. limit',
        v => v * 100 + '%'
      ),
      renderSearchCriteriaFromArray('demographicsAgeGroups', 'Audience Age'),
      renderSearchCriteria(
        'demographicsAgeGroupsMinThreshold',
        'Audience age groups min. limit',
        v => v * 100 + '%'
      )
    );

    if (!inNetworkTags.length) return;

    return (
      <div>
        <h2 className="subtitle" style={{ marginTop: '2rem' }}>
          In-network search criteria
        </h2>
        {inNetworkTags}
      </div>
    );
  };

  const renderSearchCriteriaSection = () => {
    const tags = concat(
      // Cannot call `concat` with `renderOptionalSearchCriteria(...)` bound to `base` because  `React.Element` [1] is incompatible with  read-only array type [2]
      // Strange flow type error, fixed manually as it's very unclear where the issue is
      // $FlowFixMe
      renderOptionalSearchCriteria('channelName', 'Channel name'),
      renderSearchCriteriaFromArray('playedGames', 'Has played'),
      renderSearchCriteriaFromArray('notPlayedGames', 'Has not played'),
      renderSearchCriteriaFromArray('tags', 'Used tags'),
      renderSearchCriteriaFromArray('excludedTags', 'Excluded tags'),
      renderSearchCriteriaFromArray('categoriesAny', 'Topic categories (any)'),
      renderSearchCriteriaFromArray('categoriesAll', 'Topic categories (all)'),
      renderSearchCriteria(
        'matchingContentMinVideoCount',
        'Min. matching videos',
        toHumanReadableNumber
      ),
      renderSearchCriteria(
        'channelMin30dAvgViewCount',
        'Min. channel views',
        toHumanReadableNumber
      ),
      renderSearchCriteria(
        'channelMin30dAvgEngagementRatio',
        'Min. channel eng. ratio',
        v => v + '%'
      ),
      renderSearchCriteria('channelMinSubscriberCount', 'Min. channel subs', toHumanReadableNumber),
      renderSearchCriteria(
        'channelMax30dAvgViewCount',
        'Max. channel views',
        toHumanReadableNumber
      ),
      renderSearchCriteria(
        'channelMax30dAvgEngagementRatio',
        'Max. channel eng. ratio',
        v => v + '%'
      ),
      renderSearchCriteria('channelMaxSubscriberCount', 'Max. channel subs', toHumanReadableNumber),
      renderSearchCriteriaFromArray(
        'channelCountriesOrEstimatedCountriesWhitelist',
        'Channel From'
      ),
      renderSearchCriteriaFromArray('channelCountriesOrEstimatedCountriesBlacklist', 'Not from')
    );

    if (!tags.length) {
      return;
    }

    return (
      <section className="section">
        <h2 className="subtitle">Search criteria</h2>
        {tags}
      </section>
    );
  };

  const renderSuggestedTagsAndGames = () => {
    const tags = concat(renderSuggestedTags(), renderSuggestedGames());
    const isLoading = isFetchingSuggestedTags || isFetchingSuggestedGames;

    if (!isLoading && !tags.length) {
      return null;
    }

    return (
      <section className="section">
        <h2 className="subtitle">Suggestions</h2>
        {tags}
        {isLoading && <Spinner size="medium" mode="inline" centered />}
      </section>
    );
  };

  const renderActions = () => {
    const searchButton = (
      <Button
        className="YoutubeSearch__actions-search"
        size="large"
        color={collectionId ? '' : Color.PRIMARY}
        loading={isSearchingInfluencers}
        disabled={isSearchingInfluencers}
        onClick={onSearchInfluencers}>
        <FormattedMessage id="form.search" />
      </Button>
    );
    const editSearchCollection = (
      <Button
        className="YoutubeSearch__actions-edit-search"
        size="large"
        color={Color.PRIMARY}
        loading={isSavingSearch}
        disabled={isSavingSearch}
        onClick={() => {
          dispatch(
            saveSearchToCollection.run({
              collectionId,
              searchQuery: state,
              defaultQuery: DEFAULT_SEARCH_QUERY
            })
          );
        }}>
        <SaveIcon />
        <FormattedMessage id="search.editCollection.button" />
      </Button>
    );
    const saveSearchToCollectionTrigger = (
      <SaveSearchToCollectionTrigger
        collections={collections || []}
        onSubmit={collectionName =>
          dispatch(
            saveSearchToCollection.run({
              collectionName,
              searchQuery: state,
              defaultQuery: DEFAULT_SEARCH_QUERY
            })
          )
        }
        onAddToCollection={collectionId =>
          dispatch(
            saveSearchToCollection.run({
              collectionId,
              searchQuery: state,
              defaultQuery: DEFAULT_SEARCH_QUERY
            })
          )
        }
        isLoading={isSavingSearch || isLoadingCollections}
        collectionCreated={updatedCollection}
      />
    );

    return (
      <div className="YoutubeSearch__actions">
        {searchButton}
        {!collectionId && saveSearchToCollectionTrigger}
        {!!collectionId && editSearchCollection}
      </div>
    );
  };

  const renderSearchResults = () => {
    if (!hasSearchedOnce) return null;

    // TODO: read get(getUser()...) for display props

    return (
      <section className="section">
        <DownloadUrlButton
          body={{ query: { ...DEFAULT_SEARCH_QUERY, ...query } }}
          path="/search/v2/csv"
          method="POST"
        />
        <ExternallyManagedInfluencerCollection
          influencers={influencers}
          card={SearchInfluencerCard}
          list={SearchInfluencersTable}
          sortOptions={SORT_OPTIONS}
          sortBy={state.sortBy}
          sortDirection={state.sortDirection}
          page={state.page}
          pageSize={state.pageSize}
          totalCount={totalCount}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          onChangeSort={onChangeSort}
          isLoading={isSearchingInfluencers}
        />
      </section>
    );
  };

  const renderInNetworkSection = () => {
    return (
      <ToggleContainer className="section" title="In-network filters">
        <InNetworkChannelFilters
          disabled={isSearchingInfluencers}
          demographicsTargetCountries={state.demographicsTargetCountries}
          onChangeDemographicsTargetCountries={onChangeDemographicsTargetCountries}
          demographicsTargetCountriesMinThreshold={state.demographicsTargetCountriesMinThreshold}
          onChangeDemographicsTargetCountriesMinThreshold={
            onChangeDemographicsTargetCountriesMinThreshold
          }
          demographicsGenderType={state.demographicsGenderType}
          onChangeDemographicsGenderType={onChangeDemographicsGenderType}
          demographicsGenderTypeMinThreshold={state.demographicsGenderTypeMinThreshold}
          onChangeDemographicsGenderTypeMinThreshold={onChangeDemographicsGenderTypeMinThreshold}
          demographicsOperatingSystems={state.demographicsOperatingSystems}
          onChangeDemographicsOperatingSystems={onChangeDemographicsOperatingSystems}
          demographicsOperatingSystemMinThreshold={state.demographicsOperatingSystemMinThreshold}
          onChangeDemographicsOperatingSystemMinThreshold={
            onChangeDemographicsOperatingSystemMinThreshold
          }
          demographicsAgeGroups={state.demographicsAgeGroups}
          onChangeDemographicsAgeGroups={onChangeDemographicsAgeGroups}
          demographicsAgeGroupsMinThreshold={state.demographicsAgeGroupsMinThreshold}
          onChangeDemographicsAgeGroupsMinThreshold={onChangeDemographicsAgeGroupsMinThreshold}
        />
        {renderInNetworkSearchCriteria()}
      </ToggleContainer>
    );
  };

  return (
    <div className="YoutubeSearch">
      <SearchTitle collection={collection} linkToPreviousPage={linkToPreviousPage} />
      <Tabs
        tabs={['Games and tags', 'Channel filters']}
        panels={[
          <GamesAndChannelName
            disabled={isSearchingInfluencers}
            channelName={state.channelName}
            playedGames={state.playedGames}
            matchingContentMinVideoCount={state.matchingContentMinVideoCount}
            onChangeChannelName={onChangeChannelName}
            notPlayedGames={state.notPlayedGames}
            influencerType={state.influencerType}
            onSelectPlayedGames={onSelectPlayedGames}
            onSelectNotPlayedGames={onSelectNotPlayedGames}
            onSelectTags={onSelectTags}
            onExcludeTags={onExcludeTags}
            onCategoriesAny={onCategoriesAny}
            onCategoriesAll={onCategoriesAll}
            onChangeMatchingContentMinVideoCount={onChangeMatchingContentMinVideoCount}
            onBlurMatchingContentMinVideoCount={onBlurMatchingContentMinVideoCount}
            onSelectInfluencerType={onSelectInfluencerType}
            tags={state.tags}
            excludedTags={state.excludedTags}
            categoriesAny={state.categoriesAny}
            categoriesAll={state.categoriesAll}
          />,
          <ChannelFilters
            disabled={isSearchingInfluencers}
            isFetchingCountries={isFetchingCountries}
            channelCountriesOrEstimatedCountriesWhitelist={
              state.channelCountriesOrEstimatedCountriesWhitelist
            }
            channelCountriesOrEstimatedCountriesBlacklist={
              state.channelCountriesOrEstimatedCountriesBlacklist
            }
            channelMinSubscriberCount={state.channelMinSubscriberCount}
            channelMin30dAvgViewCount={state.channelMin30dAvgViewCount}
            channelMin30dAvgEngagementRatio={state.channelMin30dAvgEngagementRatio}
            onChangeChannelMinSubscriberCount={onChangeChannelMinSubscriberCount}
            onBlurChannelMinSubscriberCount={onBlurChannelMinSubscriberCount}
            onChangeChannelMin30dAvgViewCount={onChangeChannelMin30dAvgViewCount}
            onBlurChannelMin30dAvgViewCount={onBlurChannelMin30dAvgViewCount}
            onChangeChannelMin30dAvgEngagementRatio={onChangeChannelMin30dAvgEngagementRatio}
            onBlurChannelMin30dAvgEngagementRatio={onBlurChannelMin30dAvgEngagementRatio}
            channelMaxSubscriberCount={state.channelMaxSubscriberCount}
            channelMax30dAvgViewCount={state.channelMax30dAvgViewCount}
            channelMax30dAvgEngagementRatio={state.channelMax30dAvgEngagementRatio}
            onChangeChannelMaxSubscriberCount={onChangeChannelMaxSubscriberCount}
            onBlurChannelMaxSubscriberCount={onBlurChannelMaxSubscriberCount}
            onChangeChannelMax30dAvgViewCount={onChangeChannelMax30dAvgViewCount}
            onBlurChannelMax30dAvgViewCount={onBlurChannelMax30dAvgViewCount}
            onChangeChannelMax30dAvgEngagementRatio={onChangeChannelMax30dAvgEngagementRatio}
            onBlurChannelMax30dAvgEngagementRatio={onBlurChannelMax30dAvgEngagementRatio}
            onChangeChannelCountriesOrEstimatedCountriesWhitelist={
              onChangeChannelCountriesOrEstimatedCountriesWhitelist
            }
            onChangeChannelCountriesOrEstimatedCountriesBlacklist={
              onChangeChannelCountriesOrEstimatedCountriesBlacklist
            }
          />
        ]}
      />
      {renderSearchCriteriaSection()}
      {renderSuggestedTagsAndGames()}
      {renderInNetworkSection()}
      {renderActions()}
      {errorSearchingInfluencers && (
        <div className="YoutubeSearch__error">
          {intl.formatMessage({ id: 'youtube.search.error.timeout' })}
          <button
            type="submit"
            onClick={() => {
              dispatch(searchInfluencers.run(query, DEFAULT_SEARCH_QUERY));
            }}>
            {intl.formatMessage({ id: 'youtube.search.error.retry' })}
          </button>
        </div>
      )}
      {renderSearchResults()}
    </div>
  );
};

export default YoutubeSearch;
