// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import get from 'lodash/get';
import type { IntlShape } from 'react-intl';
// $FlowFixMe
import Select from 'react-select';

import {
  addYoutubeChannelToCollection,
  createCollection,
  getStaticCollections,
  removeYoutubeChannelFromCollection
} from '../../actions/collection';
import { importYoutubeInfluencersByChannel } from '../../actions/influencer';
import InfluencerCollection from '../../components/influencer/InfluencerCollection';
import SearchInfluencerCard from '../../components/influencer/cards/SearchInfluencerCard';
import Spinner from '../../components/common/Spinner';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';
import type { Collection } from '../../types/collection.flow';

import './AdminDashboardCommon.scss';
import './AdminDashboardImportInfluencer.scss';

type Props = {
  isImportingChannels: boolean,
  isAddingChannelsToCollection: boolean,
  influencers: any,
  staticCollections: Collection[],
  createCollection: ({
    youtubeChannelIds?: string[],
    twitchChannelIds?: string[],
    name: string
  }) => void,
  addYoutubeChannelToCollection: (channelIds: string[], collectionId: number) => void,
  removeYoutubeChannelFromCollection: (channelId: string, collectionId: number) => void,
  importYoutubeInfluencersByChannel: (channelIds: Array<string>) => void,
  fetchCollections: () => void,
  intl: IntlShape
};

type State = {
  channelIds: string,
  selectedCollection: ?{
    name: string,
    id: number
  },
  submitted: boolean
};

class AdminDashboardAddChannelsToCollection extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { channelIds: '', selectedCollection: null, submitted: false };
  }

  componentDidMount() {
    this.props.fetchCollections();
  }

  onImportClick = () => {
    const { importYoutubeInfluencersByChannel } = this.props;
    const { channelIds } = this.state;
    const ids = channelIds
      .split(/[ ,\n]+/)
      .filter(Boolean)
      .filter((item, index, arr) => arr.indexOf(item) === index);

    if (ids && ids.length) {
      importYoutubeInfluencersByChannel(ids);
      this.setState({
        submitted: true
      });
    }
  };
  onAddToCollectionClick = () => {
    const { influencers, addYoutubeChannelToCollection } = this.props;
    const { selectedCollection } = this.state;

    if (influencers.length && selectedCollection) {
      const channelIds = influencers
        .map(influencer => get(influencer, 'youtubeChannels[0].id'))
        .filter(Boolean);

      addYoutubeChannelToCollection(channelIds, selectedCollection.id);
    }
  };

  onChannelsChange = e => {
    this.setState({ channelIds: e.target.value, submitted: false });
  };

  onSelectCollection = option => {
    this.setState({ selectedCollection: option });
  };

  isLoading() {
    const { isAddingChannelsToCollection, isImportingChannels } = this.props;
    return isAddingChannelsToCollection || isImportingChannels;
  }
  renderAddToCollection() {
    const { influencers } = this.props;
    const { selectedCollection } = this.state;

    return (
      <div className="field is-horizontal AdminDashboardImportInfluencer__add-to-collection-section">
        <div className="field-label" />
        <div className="field-body">
          <div className="field">
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className="select is-fullwidth">{this.renderCollectionDropDown()}</div>
              </div>
              <div className="control">
                <button
                  className="button is-primary"
                  disabled={this.isLoading() || !influencers.length || !selectedCollection}
                  onClick={this.onAddToCollectionClick}>
                  <FormattedMessage id="admin.collections.addChannels.addToCollectionButton" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderImportedResults() {
    const {
      isImportingChannels,
      influencers,
      createCollection,
      addYoutubeChannelToCollection,
      removeYoutubeChannelFromCollection,
      staticCollections
    } = this.props;
    const { submitted } = this.state;

    const cardProps = {
      collections: staticCollections,
      onCreateCollection: createCollection,
      onAddChannelToCollection: addYoutubeChannelToCollection,
      onRemoveChannelFromCollection: removeYoutubeChannelFromCollection
    };

    if (!submitted) {
      return null;
    }

    if (isImportingChannels) {
      return <Spinner size="large" mode="fullWidth" />;
    }

    if (!influencers.length) {
      return <FormattedMessage id="admin.collections.addChannels.noChannels" />;
    }

    return (
      <InfluencerCollection
        name="managerImportInfluencerCollection"
        influencers={influencers}
        card={SearchInfluencerCard}
        cardProps={cardProps}
      />
    );
  }

  renderCollectionDropDown = () => {
    const { staticCollections, intl } = this.props;
    const { selectedCollection } = this.state;

    return (
      <Select
        onChange={this.onSelectCollection}
        placeholder={intl.formatMessage({
          id: 'admin.collections.addChannels.selectCollectionPlaceholder'
        })}
        options={staticCollections}
        value={selectedCollection}
        multi={false}
        labelKey="name"
        valueKey="id"
      />
    );
  };

  render() {
    const { channelIds } = this.state;

    return (
      <div className="container AdminDashboardImportInfluencer">
        <Helmet title="Add channels to collection" />
        <h1 className="title">
          <FormattedMessage id="admin.collections.addChannels.title" />
        </h1>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
              <FormattedMessage id="admin.collections.addChannels.channelsLabel" />
            </label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <textarea
                  value={channelIds}
                  onChange={this.onChannelsChange}
                  className="textarea"
                  placeholder={this.props.intl.formatMessage({
                    id: 'admin.collections.addChannels.channelListPlaceholder'
                  })}
                  rows="10"
                />
              </div>
              <p className="help">
                <FormattedMessage id="admin.collections.addChannels.helpInfo" />
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label" />
          <div className="field-body">
            <div className="field">
              <div className="control">
                <button
                  className="button is-primary"
                  disabled={this.isLoading() || !channelIds}
                  onClick={this.onImportClick}>
                  <FormattedMessage id="admin.collections.addChannels.importButton" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.renderAddToCollection()}
        {this.renderImportedResults()}
      </div>
    );
  }
}

const mapStateToProps = generateStateToProps('adminDashboardImportInfluencer');
const mapDispatchToProps = generateDispatchToProps({
  importYoutubeInfluencersByChannel,
  addYoutubeChannelToCollection,
  createCollection,
  removeYoutubeChannelFromCollection,
  fetchCollections: getStaticCollections
});

AdminDashboardAddChannelsToCollection = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardAddChannelsToCollection);

export default injectIntl(AdminDashboardAddChannelsToCollection);
