// @flow
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Tooltip from '../Tooltip';

type Props = {
  isActive?: boolean,
  type?: 'box' | 'circle',
  tooltipI18nString?: string,
  withTooltip?: boolean
};

const RemoveIcon = (props: Props) => {
  const { isActive, type, tooltipI18nString = 'tooltips.remove', withTooltip = true } = props;
  const iconStyles = isActive ? 'icon Icon mdRemoveIcon activeTabIcon' : 'icon Icon mdRemoveIcon';
  let iconName;
  switch (type) {
    case 'box':
      iconName = 'remove_box';
      break;
    case 'circle':
      iconName = 'remove_circle';
      break;
    case 'circle_outline':
      iconName = 'remove_circle_outline';
      break;
    default:
      iconName = 'remove';
  }

  const icon = (
    <span className={iconStyles}>
      <i className="material-icons">{iconName}</i>
    </span>
  );

  if (!withTooltip) return icon;
  return <Tooltip tooltip={<FormattedMessage id={tooltipI18nString} />}>{icon}</Tooltip>;
};

export default RemoveIcon;
