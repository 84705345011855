import React from 'react';

const TabsIconVideo = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <path
      fill="#2D3767"
      d="M25 7H7c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L27 9a2 2 0 0 0-2-2zm0 14H7V9h18v12zm-5-6l-7 4v-8l7 4z"
    />
  </svg>
);

export default TabsIconVideo;
