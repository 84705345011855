// @flow
import type { Props as BaseProps } from './CollectionSelectorTrigger';
import type { ContentPlatform } from 'matchmade-types';

import * as React from 'react';
import { ContentPlatform as ContentPlatformEnum } from '@sharkpunch/matchmade-common/campaign';
import { useDispatch } from 'react-redux';
import CollectionSelectorTrigger from './CollectionSelectorTrigger';

import {
  addInstagramChannelToCollection,
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection,
  createCollection,
  removeInstagramChannelFromCollection,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection
} from '../../actions/collection';

export type Props = {
  ...BaseProps,

  channelId: string,
  contentPlatform: ContentPlatform
};

// Use this component if/when you want something that triggers actions but doesn't handle those actions
export function AutoDispatchCollectionSelectorTrigger(props: Props) {
  const {
    channelId,
    contentPlatform,

    children,
    ...rest
  } = props;

  const dispatch = useDispatch();

  function onAdd(collectionId) {
    contentPlatform === ContentPlatformEnum.YOUTUBE &&
      channelId &&
      dispatch(addYoutubeChannelToCollection.run([channelId], collectionId));

    contentPlatform === ContentPlatformEnum.TWITCH &&
      channelId &&
      dispatch(addTwitchChannelToCollection.run([channelId], collectionId));

    contentPlatform === ContentPlatformEnum.INSTAGRAM &&
      channelId &&
      dispatch(addInstagramChannelToCollection.run([channelId], collectionId));
  }

  function onRemove(collectionId) {
    contentPlatform === ContentPlatformEnum.YOUTUBE &&
      channelId &&
      dispatch(removeYoutubeChannelFromCollection.run(channelId, collectionId));

    contentPlatform === ContentPlatformEnum.TWITCH &&
      channelId &&
      dispatch(removeTwitchChannelFromCollection.run(channelId, collectionId));

    contentPlatform === ContentPlatformEnum.INSTAGRAM &&
      channelId &&
      dispatch(removeInstagramChannelFromCollection.run(channelId, collectionId));
  }

  function onCreate(name) {
    const youtubeChannelIds = contentPlatform === ContentPlatformEnum.YOUTUBE ? [channelId] : [];
    const twitchChannelIds = contentPlatform === ContentPlatformEnum.TWITCH ? [channelId] : [];
    const instagramChannelIds =
      contentPlatform === ContentPlatformEnum.INSTAGRAM ? [channelId] : [];
    dispatch(
      createCollection.run({ youtubeChannelIds, twitchChannelIds, instagramChannelIds, name })
    );
  }

  const collectionTriggerProps = {
    ...rest,
    onAdd,
    onRemove,
    onCreate
  };

  return (
    <CollectionSelectorTrigger {...collectionTriggerProps}>{children}</CollectionSelectorTrigger>
  );
}
