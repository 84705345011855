import { FormattedMessage } from 'react-intl';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import StarsRating from '../StarsRating';

import GameCategories from '../../game/GameCategories';
import GamePlatform from '../../game/GamePlatform';

import './GameInfo.scss';

const MAX_TAG_NUMBER = 4;

const isSteamGame = game => {
  return Array.isArray(game.platforms)
    ? game.platforms.indexOf('steam') !== -1
    : game.platform === 'steam';
};

const isHomePage = game =>
  game.platforms && game.platforms.length === 1 && game.platforms.indexOf('homepage') !== -1;

const getAppTraceRating = game => {
  const avgRating = game.avgRating;
  let value = '';
  let key = null;

  if (avgRating) {
    value = parseFloat(avgRating.toFixed(2));
    if (!value) {
      value = '';
    } else {
      key = <FormattedMessage id="publisher.campaign.overallRating" tagName="strong" />;
    }
  }
  return { key, value };
};
const getSteamRating = game => {
  const steam = game.steam || {};
  let value = '';
  let key = null;

  const reviews = steam.reviews;
  if (reviews) {
    value = Math.round((reviews.positive / reviews.total) * 100);
    if (!value) {
      value = '';
    } else {
      value = `${value}%`;
      key = <FormattedMessage id="publisher.campaign.positive" tagName="strong" />;
    }
  }

  return { key, value };
};

const getRatingComponent = game => {
  const { key: avgRatingLabel, value: rating } = isSteamGame(game)
    ? getSteamRating(game)
    : getAppTraceRating(game);
  if (isSteamGame(game)) {
    return getSteamRating(game).value ? (
      <div className="GameInfo__ratings">
        <div key="value" className="positivePercentage">
          {rating}
        </div>
        <div key="label" className="ratingLabel">
          {avgRatingLabel}
        </div>
      </div>
    ) : null;
  }
  if (isHomePage(game)) {
    return null;
  }
  return getAppTraceRating(game).value ? (
    <div className="GameInfo__ratings">
      <div key="label" className="ratingLabel">
        {avgRatingLabel}
      </div>
      <div key="value" className="ratingValue">
        <StarsRating value={rating} />
      </div>
    </div>
  ) : null;
};

const GameInfo = ({ game }) => {
  const limitedCategories = game.categories.slice(0, MAX_TAG_NUMBER);
  const gameDescription = game.description ? game.description.slice(0, 120) + '...' : '';

  return (
    <div className="GameInfo">
      <div className="GameInfo__description-wrapper">
        <div className="GameInfo__description">
          <p className="GameInfo__description-header">
            <strong>{game.title}</strong>
          </p>
          <div className="GameInfo__description-text">
            <ReactMarkdown source={gameDescription} />
          </div>
        </div>
        <div className="GameInfo__tags">
          <GameCategories categories={limitedCategories} />
        </div>
      </div>
      <div className="GameInfo__metrics">
        <div className="GameInfo__by">
          <FormattedMessage
            id="campaign.card.info.by"
            values={{ publisherName: game.publisherName }}
            tagName="strong"
          />
        </div>
        <div className="is-flex-mobile">
          <div className="GameInfo__platforms">
            {game.platforms.map(platform => (
              <a
                key={platform}
                href={game.storeUrls[platform]}
                target="_blank"
                rel="noopener noreferrer">
                <GamePlatform platform={platform} />
              </a>
            ))}
          </div>
          {getRatingComponent(game)}
        </div>
      </div>
    </div>
  );
};

export default GameInfo;
