// @flow
import * as React from 'react';

import classNames from 'classnames';

import type { IntlShape } from 'react-intl';
import type { Team } from 'matchmade-types/schema';

import Link from 'react-router/lib/Link';

import Spinner from '../common/Spinner';

import { injectIntl } from 'react-intl';

// $FlowFixMe
import Select from 'react-select';

export type Props = {
  currentTeam: ?Team,
  allTeams: Team[],
  link?: string,
  onChange: (team: Team) => void,
  isLoading: boolean,
  isEditing: boolean,

  intl: IntlShape
};

class CreatableTeamSelector extends React.PureComponent<Props> {
  promptTextCreator = (label: string) => {
    return this.props.intl.formatMessage(
      {
        id: 'admin.accounts.verify.createTeam'
      },
      {
        name: label
      }
    );
  };

  renderArrow = ({ isOpen }) => {
    if (this.props.isLoading) {
      return <Spinner size="small" mode="inline" />;
    }
    return <i className="material-icons arrow-icon">{`arrow_drop_${isOpen ? 'up' : 'down'}`}</i>;
  };

  render() {
    let content = null;
    if (!this.props.isEditing) {
      const team = this.props.currentTeam;
      if (!team) {
        return null;
      }
      const link = this.props.link || (team.id ? `/admin/teams/${team.id}` : '/admin/teams');
      content = <Link to={link}>{team.name}</Link>;
    } else {
      content = (
        <Select.Creatable
          value={this.props.currentTeam}
          options={this.props.allTeams.filter(({ id }) => {
            return !!id;
          })}
          onChange={this.props.onChange}
          valueKey="id"
          labelKey="name"
          clearable={false}
          multi={false}
          promptTextCreator={this.promptTextCreator}
          arrowRenderer={this.renderArrow}
          disabled={this.props.isLoading}
        />
      );
    }

    const className = classNames('CreatableTeamSelector', {
      'CreatableTeamSelector--editing': this.props.isEditing
    });

    return <div className={className}>{content}</div>;
  }
}

export default injectIntl(CreatableTeamSelector);
