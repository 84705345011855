import CreateCampaignForm from './CreateCampaignForm';

const CreateCampaign = props => {
  return (
    <div className="container">
      <h1 className="title is-1">New campaign</h1>
      <CreateCampaignForm />
    </div>
  );
};

export default CreateCampaign;
