// @flow

import { fetchManagerTeams } from '../../../actions/managerTeam';
import type { Action } from '../../../types/action.flow';

type State = {|
  managerTeams: any[] | null,
  isLoadingManagerTeams: boolean,
  error: Object | null
|};

const initialState: State = Object.freeze({
  managerTeams: null,
  isLoadingManagerTeams: true,
  error: null
});

export default function adminDashboardManagerTeamsReducer(
  state: State = initialState,
  action: Action
): State {
  const newState = {
    ...state,
    error: null
  };

  switch (action.type) {
    case fetchManagerTeams.REQUEST:
      return {
        ...newState,
        isLoadingManagerTeams: true
      };
    case fetchManagerTeams.SUCCESS:
      return {
        ...newState,
        isLoadingManagerTeams: false,
        managerTeams: action.payload
      };
    case fetchManagerTeams.FAILURE:
      return {
        ...newState,
        isLoadingManagerTeams: false,
        error: action.payload
      };
    default:
      return newState;
  }
}
