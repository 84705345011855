// @flow
import * as React from 'react';

import FormattedNumber from '../FormattedNumber';

import { FormattedMessage } from 'react-intl';

import './AnalyticsTab.scss';
import Tooltip from '../Tooltip';

type BroadcasterType = 'partner' | 'affiliate';

type Props = {
  followerCount: number,
  streamsAverageCcv: number,
  isMatureContent?: boolean,
  broadcasterType?: BroadcasterType
};

const TwitchAnalyticsTab = (props: Props) => {
  const { followerCount, streamsAverageCcv, isMatureContent = false, broadcasterType } = props;
  return (
    <div className="TwitchAnalyticsTab">
      <div className="TwitchAnalyticsTab__tags">
        {isMatureContent ? (
          <div className="TwitchAnalyticsTab__tags--item">
            <div className="TwitchAnalyticsTab__tags--item-content">
              <FormattedMessage id="twitch.broadcasterType.mature" />
            </div>
          </div>
        ) : null}
        {broadcasterType ? (
          <div className="TwitchAnalyticsTab__tags--item">
            <div className="TwitchAnalyticsTab__tags--item-content">
              <FormattedMessage id={`twitch.broadcasterType.${broadcasterType}`} />
            </div>
          </div>
        ) : null}
        <div className="TwitchAnalyticsTab__tags--line" />
      </div>
      <div className="TwitchAnalyticsTab__row">
        <div className="TwitchAnalyticsTab__row--title">
          <FormattedMessage id="influencer.card.stats" />
        </div>
      </div>
      <div className="TwitchAnalyticsTab__row">
        <div className="TwitchAnalyticsTab__stat">
          <div className="has-text-centered">
            <div className="TwitchAnalyticsTab__stat-name">
              <FormattedMessage id="influencer.card.followers" />
            </div>
            <div className="TwitchAnalyticsTab__stat-value">
              <div className="TwitchAnalyticsTab__stat-value">
                <FormattedNumber value={followerCount} type="number" />
              </div>
            </div>
          </div>
        </div>
        <div className="TwitchAnalyticsTab__stat">
          <div className="has-text-centered">
            <Tooltip tooltip={<FormattedMessage id="twitch.30dAvgCcv" />}>
              <div className="TwitchAnalyticsTab__stat-name">
                <FormattedMessage id="twitch.30dAvgCcv.short" />
              </div>
            </Tooltip>
            <div className="TwitchAnalyticsTab__stat-value">
              <FormattedNumber value={streamsAverageCcv} type="number" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitchAnalyticsTab;
