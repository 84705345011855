// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Role as RoleEnum } from '@sharkpunch/matchmade-common/user';
import Link from 'react-router/lib/Link';

import Avatar from '../messages/Avatar';
import Button, { Color } from '../common/Button';
import DeleteIcon from '../common/Icons/DeleteIcon';
import Modal from '../common/Modal';
import PersonIcon from '../common/Icons/PersonIcon';
import Table, { Column } from '../tables/Table';
import compose from '../../hoc/compose';
import hasLoading from '../../hoc/hasLoading';
import hasNoData from '../../hoc/hasNoData';
import type {
  InvitedChannel,
  ManagedChannel
} from '../../reducers/containers/admin/adminManagerTeamPage';

const DeleteChannelModal = ({ isOpen, onClose, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      className="AdminManagerTeamPage__modal"
      isBoxed={false}
      onClose={onClose}>
      <header className="modal-card-head">
        <FormattedMessage id="areYouSure" tagName="h3" />
      </header>
      <footer className="modal-card-foot">
        <Button color={Color.DANGER} onClick={() => onDelete()} className="action_button">
          <FormattedMessage id="form.delete" tagName="strong" />
        </Button>
        <Button onClick={onClose} className="action_button">
          <FormattedMessage id="form.cancel" tagName="strong" />
        </Button>
      </footer>
    </Modal>
  );
};

export const ManagedChannels = compose(
  hasLoading(<FormattedMessage id="admin.managerTeam.channels.loading" />, {
    size: 'large',
    mode: 'inline',
    centered: true
  }),
  hasNoData('channels')
)(
  ({
    channels,
    onRemoveManagedChannel
  }: {
    channels: ?(ManagedChannel[]),
    onRemoveManagedChannel: (channelId: ?string) => void
  }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [currentChannel, setCurrentChannel] = React.useState(null);

    const openDeleteModal = channelId => {
      setShowDeleteModal(true);
      setCurrentChannel(channelId);
    };

    const onDelete = () => {
      onRemoveManagedChannel(currentChannel);
      setShowDeleteModal(false);
    };

    return (
      <React.Fragment>
        <h2 className="subtitle">
          <FormattedMessage
            id="admin.managerTeam.channels.title"
            values={{
              channelsCount: channels ? channels.length : 0
            }}
          />
        </h2>
        {/* $FlowFixMe$ */}
        <Table
          data={channels || []}
          className="is-fullwidth"
          pagination
          pageLimit={12}
          searchBy={['channelName', 'displayName', 'contactEmail']}>
          <Column
            name="displayName"
            component={({ data, rowData }) => (
              <div className="AdminManagerTeamPage__name-with-avatar is-flex">
                <Avatar url={rowData.avatarUrl} size={32} shape="rectangle" />
                {data}
              </div>
            )}>
            <FormattedMessage id="team.member.displayName" />
          </Column>
          <Column name="contactEmail">
            <FormattedMessage id="table.headers.email" />
          </Column>
          <Column
            name="login"
            component={props => {
              const member = props.rowData;
              return [
                <Link key="login-as" to={`/admin/login-as/${RoleEnum.INFLUENCER}/${member.id}`}>
                  <PersonIcon />
                </Link>,
                <button
                  key="delete-channel"
                  className="link-button"
                  onClick={() => openDeleteModal(member.channelId)}>
                  <DeleteIcon tooltipI18nString="admin.managerTeams.table.removeManagedChannel" />
                </button>
              ];
            }}
          />
        </Table>
        <DeleteChannelModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={onDelete}
        />
      </React.Fragment>
    );
  }
);

export const InvitedChannels = compose(
  hasLoading(<FormattedMessage id="admin.managerTeam.invites.loading" />, {
    size: 'large',
    mode: 'inline',
    centered: true
  }),
  hasNoData('invites')
)(({ invites }: { invites: ?(InvitedChannel[]) }) => {
  return (
    <React.Fragment>
      <h2 className="subtitle">
        <FormattedMessage
          id="admin.managerTeam.invites.title"
          values={{
            channelsCount: invites ? invites.length : 0
          }}
        />
      </h2>
      {/* $FlowFixMe$ */}
      <Table
        data={invites || []}
        className="is-fullwidth"
        pagination
        pageLimit={12}
        searchBy={['channelName', 'displayName', 'contactEmail']}>
        <Column
          name="displayName"
          component={({ data, rowData }) => (
            <div className="AdminManagerTeamPage__name-with-avatar is-flex">
              <Avatar url={rowData.avatarUrl} size={32} shape="rectangle" />
              {data}
            </div>
          )}>
          <FormattedMessage id="team.member.displayName" />
        </Column>
        <Column name="contactEmail">
          <FormattedMessage id="table.headers.email" />
        </Column>
        <Column
          name="login"
          component={props => {
            const member = props.rowData;
            return (
              <Link to={`/admin/login-as/${RoleEnum.INFLUENCER}/${member.id}`}>
                <PersonIcon />
              </Link>
            );
          }}
        />
      </Table>
    </React.Fragment>
  );
});
