// @flow
import { generateApiPath } from '../config';
import isObject from 'lodash/isObject';

import * as Sentry from '@sentry/browser';

import {
  ADMIN_WITH_PUPPE_TOKEN_AND_EXPIRED_LEGACY_TOKEN,
  NO_ACCOUNT_MATCH_FOR_PUPPE_ID,
  NO_ROLE_FOUND_OR_MULTIPLE_ROLES_FOUND,
  PUPPE_ME_RESPONSE
} from '@sharkpunch/matchmade-common/errorCodes';
import {
  INFLUENCER,
  INFLUENCER_MANAGER,
  PUBLISHER,
  WHITELABEL_ADMIN
} from '@sharkpunch/matchmade-common/user';
import { getAuthHeader } from './puppe';
import { getUser } from './user';
import browserHistory from 'react-router/lib/browserHistory';
import url from 'url';

const separatelyHandledErrorCodes = [
  PUPPE_ME_RESPONSE,
  NO_ACCOUNT_MATCH_FOR_PUPPE_ID,
  NO_ROLE_FOUND_OR_MULTIPLE_ROLES_FOUND,
  ADMIN_WITH_PUPPE_TOKEN_AND_EXPIRED_LEGACY_TOKEN
];

function getCurrentPathname() {
  return window.location.pathname;
}

export type Header = { [string]: string };
export type Options = {
  headers?: Header,
  // default to GET
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH',
  body?: Object,
  query?: Object
};

export async function authorizedFetch(
  path: string,
  opts?: Options,
  abortSignal: ?AbortSignal
): Promise<Response> {
  const passedOptions = opts || {};
  const options: {
    credentials: 'include',
    headers: Header,
    query?: Object,
    signal: ?AbortSignal
  } & RequestOptions = {
    ...passedOptions,
    headers: passedOptions.headers || {},
    credentials: 'include',
    signal: abortSignal
  };

  if (!options.headers['Accept']) {
    options.headers['Accept'] = 'application/json';
  }

  if (!options.headers['Content-Type']) {
    options.headers['Content-Type'] = 'application/json';
  }

  const user = getUser();

  const supportedPuppeRoles = [PUBLISHER, INFLUENCER, INFLUENCER_MANAGER, WHITELABEL_ADMIN].filter(
    Boolean
  );
  if (!user || (user.token && supportedPuppeRoles.indexOf(user.token.role) !== -1)) {
    const authHeaders = await getAuthHeader();
    if (authHeaders) {
      options.headers['Authorization'] = authHeaders.Authorization;
    }
  }

  let method = (options.method || 'GET').toLowerCase();
  if (method !== 'GET' && options.body && isObject(options.body)) {
    options.body = JSON.stringify(options.body);
  }

  if (options.query && isObject(options.query)) {
    path += url.format({ query: options.query });
  }

  path = generateApiPath(path);
  return await fetch(path, options);
}

const callApi = async function (
  path: string,
  opts?: Options,
  abortSignal: ?AbortSignal
): Promise<{
  success: boolean,
  data: any,
  error: any | null,
  pagination?: {
    page?: number,
    pageSize?: number,
    totalCount?: number
  }
}> {
  const res = await authorizedFetch(path, opts, abortSignal);

  if (res.status === 204) {
    return { success: true, data: null, error: null };
  }
  const json = await res.json();
  if (res.status < 400) {
    const result = {};
    result.data = json.data || null;

    if (res.headers.has('X-Page')) {
      const page = parseInt(res.headers.get('X-Page'), 10);
      if (!isNaN(page)) {
        if (!result.pagination) result.pagination = {};
        result.pagination.page = page;
      }
    }
    if (res.headers.has('X-Page-Size')) {
      const pageSize = parseInt(res.headers.get('X-Page-Size'), 10);
      if (!isNaN(pageSize)) {
        if (!result.pagination) result.pagination = {};
        result.pagination.pageSize = pageSize;
      }
    }
    if (res.headers.has('X-Total-Count')) {
      const totalCount = parseInt(res.headers.get('X-Total-Count'), 10);
      if (!isNaN(totalCount)) {
        if (!result.pagination) result.pagination = {};
        result.pagination.totalCount = totalCount;
      }
    }
    return result;
  }

  const error = json.error;
  error.requestId = res.headers.get('Request-Id') || null;
  const errorCode = res.status;

  switch (true) {
    // Our API always returns 401 if there is no auth,
    // BUT some external library might return 403... So check for both.
    case errorCode === 401 || errorCode === 403: {
      const currentPath = getCurrentPathname().split('/').slice(1);

      if (error && error.errorCode && separatelyHandledErrorCodes.includes(error.errorCode)) {
        handleAuthorizationError(error, currentPath, browserHistory);

        return { data: null, error: error, success: false };
      }
      let authPath = '/auth';
      if (currentPath[0] === 'admin') {
        authPath = '/auth/admin';
      }

      let next = (getCurrentPathname() + document.location.search).trim();
      if (['/', '/auth', '/auth/admin'].indexOf(getCurrentPathname()) === -1) {
        authPath += `?next=${encodeURIComponent(next)}`;
      }

      browserHistory.push(authPath);

      return { data: null, error: error, success: false };
    }
    case errorCode === 404:
      return handleNotFoundError(error);
    default:
      return handleAllOtherErrors(error);
  }
};

function handleAuthorizationError(error, currentPath, browserHistory) {
  if (
    currentPath[0] === 'admin' ||
    (error && error.errorCode === ADMIN_WITH_PUPPE_TOKEN_AND_EXPIRED_LEGACY_TOKEN)
  ) {
    browserHistory.push('/auth/admin');
  } else if (error && error.i18n) {
    browserHistory.push(`/auth/failed?error=${error.i18n}`);
  }
}

function handleNotFoundError(error) {
  browserHistory.push('/404');

  throw Object.assign(
    {
      code: 404,
      message: 'Not Found Error',
      params: null
    },
    error
  );
}

function handleAllOtherErrors(error) {
  const errorObject = Object.assign(
    {
      code: 500,
      message: 'Internal Server Error',
      params: null,
      requestId: error.requestId
    },
    error
  );
  if (errorObject.code >= 500) {
    Sentry.captureException(new Error(errorObject.message));
  }
  throw errorObject;
}

export default callApi;
