import { DatePicker } from '@material-ui/pickers';
import { useAsync } from 'react-async';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import callApi from '../../helpers/api';

// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';

import './CreatePayoutForm.scss';

const removeEmptyFields = data => {
  Object.keys(data).forEach(key => {
    if (data[key] === null || data[key] === '') {
      delete data[key];
    }
  });
};

const CreatePayoutForm = () => {
  const [message, setMessage] = useState({});
  const [publishDateValue, setPublishDateValue] = useState(null);
  const [metadataFields, setMetadataFields] = useState([{ key: null, value: null }]);

  const { register, getValues, handleSubmit, reset, formState } = useForm({
    shouldUnregister: true,
    defaultValues: {
      display_info: null,
      content_id: null,
      amount: null,
      content_publish_date: null
    },
    mode: 'onBlur'
  });

  const { isValid, errors } = formState;

  const { run: createPayout, isLoading: isSavingPayout } = useAsync({
    deferFn: async () => {
      setMessage({});
      const data = getValues();
      //YYYY-MM-DD
      data.content_publish_date = publishDateValue?.toISOString().substring(0, 10) || null;
      removeEmptyFields(data);
      for (const item of metadataFields) {
        if (item.key !== null && item.key !== '' && item.value !== null && item.value !== '') {
          data[item.key] = item.value;
        }
      }
      return callApi('/admin/payout-tasks', {
        method: 'POST',
        body: data
      });
    },
    onResolve: response => {
      if (!response || (response.error && !response.success)) {
        setMessage({
          type: 'danger',
          text: `Error when saving payout: ${response.error.message || 'Unknown error'}`
        });
        return;
      }

      setMessage({
        type: 'success',
        text: `Successfully saved payout`,
        id: response.data.task.id,
        url: response.data.url
      });
      reset();
    },
    onReject: e =>
      setMessage({
        type: 'error',
        text: `Error when saving payout: ${e.message || 'Unknown error'}`
      })
  });

  const metadataAdd = () => {
    const values = [...metadataFields];
    values.push({ key: null, value: null });
    setMetadataFields(values);
  };

  const metadataRemove = idx => {
    const items = [...metadataFields];
    items.splice(idx, 1);
    setMetadataFields(items);
  };

  const metadataChange = (idx, event, attribute) => {
    const items = [...metadataFields];
    items[idx][attribute] = event.target.value;
    setMetadataFields(items);
  };

  return (
    <form className="CreatePayoutForm" onSubmit={handleSubmit(createPayout)}>
      <div className="section mb-5">
        <h2 className="subtitle">Mandatory information</h2>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="display_info" className="label">
                Display name
              </label>
              <div className="control">
                <input
                  {...register('display_info', { required: 'Display info is required' })}
                  className="input"
                  type="text"
                  placeholder="Campaign name, deal name, etc..."
                />
              </div>
              {errors.display_info && (
                <p className="help is-danger">{errors.display_info.message}</p>
              )}
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="content_id" className="label">
                Content ID
              </label>
              <div className="control">
                <input
                  {...register('content_id', { required: 'Content ID is required' })}
                  className="input"
                  type="text"
                  placeholder="This is an id of content this payout is for, for instance a YouTube video"
                />
              </div>
              {errors.content_id && <p className="help is-danger">{errors.content_id.message}</p>}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="amount" className="label">
                Creator payout amount (USD)
              </label>
              <div className="control">
                <input
                  {...register('amount', { required: 'Amount is required' })}
                  className="input"
                  type="number"
                  step="0.01"
                />
              </div>
              {errors.amount && <p className="help is-danger">{errors.amount.message}</p>}
            </div>
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Additional information</h2>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="channel_id" className="label">
                Channel ID
              </label>
              <div className="control">
                <input
                  {...register('channel_id')}
                  className="input"
                  type="text"
                  placeholder="Like UCj5LKyzWpYXMEBalNX_MN2g for YouTube"
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="channel_name" className="label">
                Channel name
              </label>
              <div className="control">
                <input
                  {...register('channel_name')}
                  className="input"
                  type="text"
                  placeholder="Matchmade"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="category" className="label">
                Category
              </label>
              <div className="control">
                <select {...register('category')} className="input">
                  <option value="big_deal">Big deal</option>
                  <option value="buyout">Buyout</option>
                </select>
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="customer_id" className="label">
                Customer / Team ID
              </label>
              <div className="control">
                <input
                  {...register('customer_id')}
                  className="input"
                  type="text"
                  placeholder="imt_12345"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="deal_type" className="label">
                Deal type
              </label>
              <div className="control">
                <input
                  {...register('deal_type')}
                  className="input"
                  type="text"
                  placeholder="fixed free, CPM, ..."
                />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              <label htmlFor="content_publish_date" className="label">
                Content publish date
              </label>
              <div className="control">
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  clearable={true}
                  value={publishDateValue}
                  onChange={newValue => setPublishDateValue(newValue)}
                  renderInput={props => <TextField {...props} />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="content_link" className="label">
                Content link
              </label>
              <div className="control">
                <input
                  {...register('content_link')}
                  className="input"
                  type="text"
                  placeholder="YouTube url, Instagram url or such, if applicable"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label htmlFor="description" className="label">
                Description
              </label>
              <div className="control">
                <textarea
                  {...register('description')}
                  className="textarea"
                  placeholder="More secific description of the payout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section mb-5">
        <h2 className="subtitle">Custom metadata</h2>
        <div className="columns">
          <div className="column is-half">
            {metadataFields.map((item, idx) => {
              return (
                <div className="field metadata__field" key={`metadata-${idx}`}>
                  <input
                    className="input"
                    type="text"
                    value={item.key || ''}
                    placeholder="key"
                    onChange={event => metadataChange(idx, event, 'key')}
                  />
                  <input
                    className="input"
                    type="text"
                    value={item.value || ''}
                    placeholder="value"
                    onChange={event => metadataChange(idx, event, 'value')}
                  />
                  <button
                    type="button"
                    className="button"
                    disabled={metadataFields.length <= 1}
                    onClick={() => metadataRemove(idx)}>
                    Remove
                  </button>
                </div>
              );
            })}
            <div className="field">
              <button type="button" className="button" onClick={() => metadataAdd()}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="field mt-5">
        <div className="control">
          <button
            className={`button is-primary is-medium ${isSavingPayout && 'is-loading'}`}
            disabled={!isValid}
            type="submit">
            Save
          </button>
        </div>
        {message.type && (
          <Alert severity={message.type} className="my-5" variant="filled">
            <AlertTitle>{message.text}</AlertTitle>
            {message.id && <p>{`ID: ${message.id}\n`}</p>}
            {message.url && (
              <p>
                URL:{' '}
                <a href={message.url} target="_blank" rel="noopener noreferrer">
                  {message.url}
                </a>
              </p>
            )}
          </Alert>
        )}
      </div>
    </form>
  );
};

export default CreatePayoutForm;
