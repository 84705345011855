// @flow
import { AdminNoCampaigns } from './AdminNoCampaigns';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { getAllocatedBudget } from '../../actions/admin';
import AllocatedBudgetTable from '../../components/admin/AllocatedBudgetTable';
import React, { useEffect } from 'react';
import Spinner from '../../components/common/Spinner';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';
import generateStateToProps from '../../helpers/generateStateToProps';

import './AdminDashboardAllocatedBudget.scss';

type Props = {
  campaigns: any[],
  isLoading: boolean
};

function AdminDashboardAllocatedBudget(props: Props) {
  const { campaigns, isLoading } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllocatedBudget.run());
  }, [dispatch]);

  let content = null;

  if (isLoading) {
    content = <Spinner mode="fullWidth" size="large" />;
  } else if (!(campaigns && campaigns.length)) {
    content = <AdminNoCampaigns />;
  } else {
    content = <AllocatedBudgetTable campaigns={campaigns} />;
  }

  return (
    <div className="AdminDashboardAllocatedBudget container">
      <Helmet title="Allocated budget" />
      {content}
    </div>
  );
}

const mapStateToProps = generateStateToProps('adminDashboardAllocatedBudget');

const mapDispatchToProps = generateDispatchToProps({
  getAllocatedBudget
});

//$FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardAllocatedBudget);
