// @flow
// $FlowFixMe
import { createAction } from 'redux-actions';

import api from '../helpers/api';

import compact from 'lodash/fp/compact';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import values from 'lodash/fp/values';

import { createApiAction } from './helper';

export const fetchPublisher = createApiAction('FETCH_PUBLISHER', (id, force) => {
  let state = [];
  // if we have publisher stored in `privatePublisherDashboard.publisher`
  // use it instead of loading a new one
  if (!id && !force) state = ['privatePublisherDashboard.publisher'];

  // however, if we are getting public publisher data
  // don't care about the state
  return {
    path: id ? `/publishers/${id}` : '/publishers/dashboard',
    state: state
  };
});

export const fetchPublishers = createApiAction('FETCH_PUBLISHERS', () => {
  return {
    path: '/admin/dashboard/publishers'
  };
});

export type ImportGame = (
  urls: string[],
  title: string,
  country: ?string,
  dryRun?: boolean
) => Promise<any>;
export const importGame = createApiAction(
  'PUBLISHER_IMPORT_GAME',
  (url, title, country, dryRun) => {
    return {
      path: '/games/import',
      options: {
        method: 'POST',
        body: {
          url,
          country,
          dryRun
        }
      },
      loadingPayload: {
        title: title
      }
    };
  }
);

// Can't use createApiAction for searching game because it needs
// custom logic when receiving the response
// But we still need to maintain the format for API action
const PUBLISHER_SEARCH_GAME_REQUEST = 'PUBLISHER_SEARCH_GAME_REQUEST';
const PUBLISHER_SEARCH_GAME_SUCCESS = 'PUBLISHER_SEARCH_GAME_SUCCESS';
const PUBLISHER_SEARCH_GAME_FAILURE = 'PUBLISHER_SEARCH_GAME_FAILURE';
const publisherSearchGameSuccess = createAction(PUBLISHER_SEARCH_GAME_SUCCESS);
const publisherSearchGameFailure = createAction(PUBLISHER_SEARCH_GAME_FAILURE);

let abortController;

export type SearchGame = (
  term: string,
  country: ?string,
  tryToImportGame?: boolean,
  sortByName?: boolean
) => Promise<any>;
export const searchGame = {
  REQUEST: PUBLISHER_SEARCH_GAME_REQUEST,
  SUCCESS: PUBLISHER_SEARCH_GAME_SUCCESS,
  FAILURE: PUBLISHER_SEARCH_GAME_FAILURE,
  success: publisherSearchGameSuccess,
  failure: publisherSearchGameFailure,
  run: function (
    term: string,
    country: string,
    tryToImportGame?: boolean = true,
    sortByName?: boolean = true
  ) {
    return async (dispatch: Function, getState: Function) => {
      dispatch({
        type: PUBLISHER_SEARCH_GAME_REQUEST
      });

      if (!abortController) {
        abortController = new AbortController();
      } else {
        abortController.abort();
        abortController = new AbortController();
      }
      try {
        const { data } = await api(
          '/games/search',
          {
            query: {
              term,
              ...(country ? { country } : {}),
              sortByName
            }
          },
          abortController.signal
        );
        if (!data || !data.length) {
          // No results
          dispatch(publisherSearchGameSuccess([]));
          return data;
        }
        const groups = flow([groupBy('platform'), values, compact])(data);

        const titles = flow([
          flatten,
          map(group => {
            const g = group || {};
            return g.normalizedTitle || g.title;
          }),
          compact,
          uniq
        ])(groups);

        if (
          tryToImportGame &&
          titles.length === 1 &&
          groups.every(group => {
            return group.length === 1 || group.length === 0;
          })
        ) {
          const urls = flow([
            flatten,
            map(group => {
              return group.url;
            }),
            compact
          ])(groups);

          dispatch(importGame.run(urls));
        } else {
          dispatch(publisherSearchGameSuccess(data));
        }
        return data;
      } catch (error) {
        if (error.name === 'AbortError') return;
        dispatch(publisherSearchGameFailure(error));
      }
    };
  }
};

// teamId can be null if we are creating a new team
export const changeTeam = createApiAction(
  'PUBLISHER_CHANGE_TEAM',
  (publisherId, teamId, teamName) => {
    return {
      path: `/publishers/${publisherId}/change-team`,
      options: {
        method: 'POST',
        body: {
          teamId,
          teamName
        }
      },
      loadingPayload: {
        publisherId,
        teamId
      }
    };
  }
);
