// @flow
import * as React from 'react';
import Link from 'react-router/lib/Link';

import './ButtonWithLabel.scss';

type Props = {
  children: React.Element<*>,
  icon: React.Element<*>,
  to: string
};

const ButtonWithLabel = ({ children, icon, to }: Props) => {
  return (
    <Link className="ButtonWithLabel" to={to}>
      {icon}
      {children}
    </Link>
  );
};

export default ButtonWithLabel;
