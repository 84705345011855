export const MOBILE = 'freeToPlayMobile';
export const STEAM = 'steam';
export function getGameTypeForEstimates(game) {
  const platforms = game && game.platforms;
  if (!platforms || !platforms.length) return null;
  // This could be problematic, for Fortnite, since it's
  // available everywhere and the platforms could be in
  // random order in the array!
  switch (platforms[0]) {
    case 'ios':
      return MOBILE;
    case 'android':
      return MOBILE;
    case 'steam':
      return STEAM;
    // Games that are not released yet
    // usually have some sort of landing page we redirect to
    // No estimates for them either, so assume "Steam"
    case 'homepage':
      return STEAM;
    default:
      return null;
  }
}
