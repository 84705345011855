import './SearchInfluencerCard.scss';
import { FormattedMessage } from 'react-intl';
import { StatefulCollectionSelectorTrigger } from '../../collection/StatefulCollectionSelectorTrigger';
import { getBiggestCountryGroup } from '../../../helpers/influencer';
import { hasDemographics } from '../../../helpers/influencer';
import ChannelCountry from '../ChannelCountry';
import EyeIcon from '../../../images/icons/EyeIcon';
import InfluencerCardDetailsStats from '../InfluencerCardDetailsStats';
import InfluencerCardLayout from '../InfluencerCardLayout';
import InfluencerCardOverviewStats from '../InfluencerCardOverviewStats';
import InfluencerCardVideo from '../InfluencerCardVideo';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from '../../common/Tooltip';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { ContentPlatform, PromotionType } from '@sharkpunch/matchmade-common/campaign';

class SearchInfluencerCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'overview'
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(this.props.influencer, nextProps.influencer) ||
      this.props.isCreatingCollection !== nextProps.isCreatingCollection ||
      !isEqual(this.state, nextState)
    ) {
      return true;
    }
    return false;
  }

  onChangeTab = ({ key }) => {
    this.setState({ activeTab: key });
  };

  renderFooter() {
    const channel = this.getChannel();
    const platform = this.getPlatform();
    return (
      <StatefulCollectionSelectorTrigger
        type="static"
        contentPlatform={platform}
        channelId={platform === ContentPlatform.TWITCH ? channel.name : channel.id}
        renderContent={selectedCount => {
          return (
            <button className="button is-link is-fullwidth">
              {selectedCount ? (
                <FormattedMessage
                  id="influencer.collection.inCollection"
                  values={{
                    count: selectedCount
                  }}
                />
              ) : (
                <FormattedMessage id="influencer.collection.add" />
              )}
            </button>
          );
        }}
      />
    );
  }

  renderChannelStats() {
    const channel = this.getChannel();
    const gameType = 'freeToPlayMobile';
    const promotionType = PromotionType.DEDICATED;
    const estimates = channel && channel.estimates;
    const estimatedInstalls =
      (estimates && estimates[gameType] && estimates[gameType].installs[promotionType].median) || 0;

    const stats = [
      {
        value() {
          return estimatedInstalls;
        },
        label: <FormattedMessage id="influencer.card.estimatedInstalls" />,
        type: 'number'
      },
      {
        value: 'last30dStats.averageViewCount',
        type: 'number',
        label: <FormattedMessage id="influencer.card.avgViewsInPastMonth" />,
        info: (
          <span>
            Estimate: {get(estimates || {}, 'views.youtube30d.low', 'N/A')} -{' '}
            {get(estimates || {}, 'views.youtube30d.high', 'N/A')}
          </span>
        )
      },
      {
        value: 'last30dStats.averageEngagementRatio',
        type: 'percentage',
        label: <FormattedMessage id="influencer.card.engagement" />
      }
    ];
    this.props.influencer.youtubeChannels.forEach(({ resonanceScore }) => {
      stats.push({
        value() {
          return !resonanceScore ? 0 : Math.round((resonanceScore || 0) / 5) * 5;
        },
        type: 'number',
        label: (
          <React.Fragment>
            <FormattedMessage id="influencer.card.resonanceScore" />
            <Tooltip
              tooltip={
                <div>
                  <FormattedMessage id="influencer.card.mrs.description1" tagName="p" />
                  <FormattedMessage id="influencer.card.mrs.description2" tagName="p" />
                </div>
              }>
              <span className="iconHolder">
                <EyeIcon />
              </span>
            </Tooltip>
          </React.Fragment>
        )
      });
    });

    return <InfluencerCardOverviewStats channel={channel} stats={stats} mode="column" />;
  }

  renderTwitchChannelStats() {
    const channel = this.getChannel();

    const stats = [
      {
        value() {
          return channel.subscriberCount;
        },
        label: <FormattedMessage id="influencer.card.subscriberCount" />,
        type: 'number'
      }
    ];

    return <InfluencerCardOverviewStats channel={channel} stats={stats} mode="column" />;
  }

  renderOverviewTab() {
    const { influencer } = this.props;

    const { name: countryCode, value: percentage, isEstimated = false } =
      getBiggestCountryGroup(influencer) || {};

    return (
      <div className="SearchInfluencerCard__overview">
        {this.renderChannelStats()}
        <ChannelCountry
          countryCode={countryCode}
          percentage={percentage}
          isEstimated={isEstimated}
        />
      </div>
    );
  }

  renderTwitchOverviewTab() {
    const { influencer } = this.props;

    const { name: countryCode, value: percentage, isEstimated = false } =
      getBiggestCountryGroup(influencer) || {};

    return (
      <div className="SearchInfluencerCard__overview">
        {this.renderTwitchChannelStats()}
        <ChannelCountry
          countryCode={countryCode}
          percentage={percentage}
          isEstimated={isEstimated}
        />
      </div>
    );
  }

  renderDetailsTab() {
    if (!hasDemographics(this.getChannel())) return null;
    return (
      <div className="FollowedInfluencerCard__details">
        <InfluencerCardDetailsStats channel={this.getChannel()} />
      </div>
    );
  }

  renderVideoTab() {
    const { influencer } = this.props;

    const video = (influencer.matchingVideos || [])[0];
    if (!video) return null;

    return <InfluencerCardVideo channel={this.getChannel()} video={video} />;
  }

  getTabs() {
    const { influencer } = this.props;
    const { activeTab } = this.state;

    const matchingVideos = influencer.matchingVideos || [];
    return (this.getPlatform() === 'youtube'
      ? [
          {
            key: 'overview',
            label: <FormattedMessage id="influencer.card.tabs.overview" />,
            icon: 'fa-image',
            disabled: false,
            content: this.renderOverviewTab()
          },
          {
            key: 'details',
            label: <FormattedMessage id="influencer.card.tabs.details" />,
            icon: 'fa-pie-chart',
            disabled: !hasDemographics(this.getChannel()),
            content: this.renderDetailsTab()
          },
          {
            key: 'video',
            label: <FormattedMessage id="influencer.card.tabs.video" />,
            icon: 'fa-video-camera',
            disabled: !matchingVideos.length,
            content: this.renderVideoTab()
          }
        ]
      : [
          {
            key: 'overview',
            label: <FormattedMessage id="influencer.card.tabs.overview" />,
            icon: 'fa-image',
            disabled: false,
            content: this.renderTwitchOverviewTab()
          }
        ]
    ).map(tab => {
      tab.active = activeTab === tab.key;
      return tab;
    });
  }

  getChannel() {
    return this.props.influencer.youtubeChannels[0] || this.props.influencer.twitchChannels[0];
  }

  getPlatform() {
    return this.props.influencer.youtubeChannels[0]
      ? ContentPlatform.YOUTUBE
      : ContentPlatform.TWITCH;
  }

  hasLast30dStats() {
    // when we don't have last30dStats (null/undefined), assuming that something will eventually
    // load it and show a spinner instead
    if (this.getPlatform() === ContentPlatform.YOUTUBE) {
      return !!(this.getChannel() || {}).last30dStats;
    }
    return true;
  }

  render() {
    return (
      <div className="SearchInfluencerCard">
        <InfluencerCardLayout
          influencer={this.props.influencer}
          platform={this.getPlatform()}
          tabs={this.getTabs()}
          onChangeTab={this.onChangeTab}
          footer={this.renderFooter()}
          footerHasBorder={false}
          isLoading={!this.hasLast30dStats()}
        />
      </div>
    );
  }
}

SearchInfluencerCard.propTypes = {
  // TODO: add influencer shape
  influencer: PropTypes.object.isRequired
};

export default SearchInfluencerCard;
