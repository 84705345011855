import { Role } from '@sharkpunch/matchmade-common/user';
import React, { Component } from 'react';

import InfluencerManagerTermsContent from '../components/tos/InfluencerManagerTermsContent';
import InfluencerTermsContent from '../components/tos/InfluencerTermsContent';
import PublisherTermsContent from '../components/tos/PublisherTermsContent';

import { Helmet } from 'react-helmet';

import { injectIntl } from 'react-intl';

import './PublicTermsPage.scss';

type Props = {
  route: { path: string },
  intl: PropTypes.object.isRequired
};

class PublicTermsPage extends Component<Props> {
  render() {
    const { route } = this.props;

    return (
      <div className="container">
        <Helmet title={this.props.intl.formatMessage({ id: 'tos.title' })} />

        <div className="PublicTermsPage">
          {route.path === Role.INFLUENCER && <InfluencerTermsContent />}
          {route.path === Role.INFLUENCER_MANAGER && <InfluencerManagerTermsContent />}
          {route.path === Role.PUBLISHER && <PublisherTermsContent />}
          {route.path === 'advertiser' && <PublisherTermsContent />}
        </div>
      </div>
    );
  }
}

export default injectIntl(PublicTermsPage);
