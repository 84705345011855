import { Bar as BarChart } from 'react-chartjs-2';
import React from 'react';
import moment from 'moment';

import { getColorVariables } from '../../../config';
import { getCostsGraphConfig as getGraphConfig } from '@sharkpunch/matchmade-common/graphs';

const FULL_WIDTH_GRAPH_HEIGHT = 588; // 42rem;

const CostGraph = ({ costsAnalytics }) => {
  const variables = getColorVariables();
  const labels = costsAnalytics.map(({ date }) => moment(date).format('Do MMM YYYY'));
  const costByDay = costsAnalytics.map(({ cost }) => cost / 100);
  const { chart } = getGraphConfig(labels, costByDay, variables);

  return <BarChart height={FULL_WIDTH_GRAPH_HEIGHT} {...chart} />;
};

export default CostGraph;
