// @flow
import type { Action, ReducerCreator } from '../../types/action.flow';
import type { Props as ActionableProps } from './AutoDispatchCollectionSelectorTrigger';
import type { CollectionWithIncludedFields } from '../../types/collection.flow';

import * as React from 'react';
import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import { useDispatch, useSelector } from 'react-redux';

import { AutoDispatchCollectionSelectorTrigger } from './AutoDispatchCollectionSelectorTrigger';
import {
  addInstagramChannelToCollection,
  addTwitchChannelToCollection,
  addYoutubeChannelToCollection,
  createCollection,
  removeInstagramChannelFromCollection,
  removeTwitchChannelFromCollection,
  removeYoutubeChannelFromCollection
} from '../../actions/collection';
import { createApiAction } from '../../actions/helper';
import runMultipleReducers from '../../reducers/helpers/runMultipleReducers';

// We create a different action here that will be used here only, to prevent updating other reducers
const getCollections = createApiAction('GET_COLLECTIONS_FROM_TRIGGER', ({ include, type }) => {
  let baseUrl = '/collections';
  if (type) {
    baseUrl += `/${type}`;
  }
  return {
    path: `${baseUrl}${include ? `?include=${include.join(',')}` : ''}`,
    options: {
      method: 'GET'
    }
  };
});

type State = {|
  isGettingCollections: boolean,
  isCreatingCollection: boolean,
  userCollections: CollectionWithIncludedFields[] | null, // null means we haven't get the collections yet
  error: Object | null
|};

export const REDUCER_NAME = 'userCollectionSelectorTrigger';

const handleGetAllActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    switch (action.type) {
      case getCollections.REQUEST:
        return {
          ...state,
          isGettingCollections: true,
          error: null
        };
      case getCollections.SUCCESS:
        return {
          ...state,
          isGettingCollections: false,
          userCollections: action.payload,
          error: null
        };
      case getCollections.FAILURE:
        return {
          ...state,
          isGettingCollections: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleAddChannelsActions: ReducerCreator<
  { attribute: 'channels' | 'twitchChannels' | 'instagramChannels', apiAction: any },
  State
> = params => {
  const { attribute = 'channels', apiAction } = params || {};

  if (!apiAction) {
    throw new Error('Must provide the api action');
  }

  return (state, action) => {
    const { userCollections } = state;

    switch (action.type) {
      case apiAction.REQUEST:
        if (!userCollections) return state;

        return {
          ...state,
          userCollections: userCollections.map(collection => {
            if (collection.id !== action.payload.id) {
              return collection;
            }

            const channels = (collection[attribute] || []).concat(action.payload.channelIds);

            collection[attribute] = channels;
            return collection;
          })
        };
      case apiAction.SUCCESS:
        if (!userCollections) return state;

        return {
          ...state,
          userCollections: userCollections.map(collection => {
            if (collection.id !== action.payload.id) {
              return collection;
            }

            const channels = (collection[attribute] || []).concat(action.payload.channelIds);

            collection[attribute] = channels;
            return collection;
          })
        };

      // TODO revert back to the old channel list
      case apiAction.FAILURE:
        if (!userCollections) return state;

        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleRemoveChannelsActions: ReducerCreator<
  { attribute: 'channels' | 'twitchChannels' | 'instagramChannels', apiAction: any },
  State
> = params => {
  const { attribute = 'channels', apiAction } = params || {};

  if (!apiAction) {
    throw new Error('Must provide the api action');
  }

  return (state, action) => {
    const { userCollections } = state;

    switch (action.type) {
      case apiAction.REQUEST:
        if (!userCollections) return state;

        return {
          ...state,
          userCollections: userCollections.map(collection => {
            if (collection.id !== action.payload.id) {
              return collection;
            }

            const channels = (collection[attribute] || []).filter(channelId => {
              return channelId !== action.payload.channelId;
            });

            collection[attribute] = channels;
            return collection;
          })
        };
      case apiAction.SUCCESS:
        if (!userCollections) return state;

        return {
          ...state,
          userCollections: userCollections.map(collection => {
            if (collection.id !== action.payload.id) {
              return collection;
            }

            const channels = (collection[attribute] || []).filter(channelId => {
              return channelId !== action.payload.channelId;
            });

            collection[attribute] = channels;
            return collection;
          })
        };

      // TODO revert back to the old channel list
      case apiAction.FAILURE:
        if (!userCollections) return state;

        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

const handleCreateCollectionActions: ReducerCreator<void, State> = () => {
  return (state, action) => {
    const { userCollections } = state;

    if (!userCollections) {
      return state;
    }

    switch (action.type) {
      case createCollection.REQUEST:
        return {
          ...state,
          isCreatingCollection: true,
          error: null
        };
      case createCollection.SUCCESS:
        return {
          ...state,
          isCreatingCollection: false,
          userCollections: [action.payload].concat(state.userCollections).filter(Boolean),
          error: null
        };
      case createCollection.FAILURE:
        return {
          ...state,
          isCreatingCollection: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
};

export function userCollectionSelectorTriggerReducer(
  state: State = {
    isGettingCollections: false,
    isCreatingCollection: false,
    userCollections: null,
    error: null
  },
  action: Action
): State {
  return runMultipleReducers([
    handleGetAllActions(),
    handleAddChannelsActions({
      attribute: 'channels',
      apiAction: addYoutubeChannelToCollection
    }),
    handleAddChannelsActions({
      attribute: 'twitchChannels',
      apiAction: addTwitchChannelToCollection
    }),
    handleAddChannelsActions({
      attribute: 'instagramChannels',
      apiAction: addInstagramChannelToCollection
    }),
    handleRemoveChannelsActions({
      attribute: 'channels',
      apiAction: removeYoutubeChannelFromCollection
    }),
    handleRemoveChannelsActions({
      attribute: 'twitchChannels',
      apiAction: removeTwitchChannelFromCollection
    }),
    handleRemoveChannelsActions({
      attribute: 'instagramChannels',
      apiAction: removeInstagramChannelFromCollection
    }),
    handleCreateCollectionActions()
  ])(state, action);
}

type Props = {
  ...$Diff<
    ActionableProps,
    { selected: number[], collections: { id: number, name: string, searchId?: ?number }[] }
  >,

  renderContent?: (selectedCount: number) => React.Node | React.Node[]
};

// Use this component if/when you want something that triggers actions and handles those actions
// Note that this component will get the current user's collections
export function StatefulCollectionSelectorTrigger(props: Props) {
  const reducerState = useSelector(state => state[REDUCER_NAME]);
  const { children, renderContent, type, ...rest } = props;

  const { isGettingCollections, userCollections, isCreatingCollection } = reducerState;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCollections.run({ include: ['channels'], type }));
  }, [dispatch, type]);

  const selected = (userCollections || [])
    .map(collection => {
      switch (props.contentPlatform) {
        case ContentPlatform.YOUTUBE:
          return (collection.channels || []).includes(props.channelId) && collection.id;
        case ContentPlatform.TWITCH:
          return (collection.twitchChannels || []).includes(props.channelId) && collection.id;
        case ContentPlatform.INSTAGRAM:
          return (collection.instagramChannels || []).includes(props.channelId) && collection.id;
        default:
          return null;
      }
    })
    .filter(Boolean);

  const collectionTriggerProps = {
    ...rest,
    isLoading: !userCollections || isGettingCollections || isCreatingCollection,
    collections: (userCollections || []).map(collection => {
      return {
        id: collection.id,
        name: collection.name,
        searchId: collection.searchId
      };
    }),
    selected
  };

  const content = renderContent ? renderContent(selected.length) : children;

  return (
    <AutoDispatchCollectionSelectorTrigger {...collectionTriggerProps}>
      {content}
    </AutoDispatchCollectionSelectorTrigger>
  );
}
