// @flow
import * as React from 'react';

// $FlowFixMe
import PortalToolTip from 'react-portal-tooltip';

import { IntlProvider } from '../../locales';

import partition from 'lodash/partition';
import uniqueId from 'lodash/uniqueId';

import { getColorVariables } from '../../config';

export type ExtraProps = {};

const variables = getColorVariables();
const backgroundColor = variables['mm-darkest-grey'];

function getPathFromEvent(e) {
  const path = [];
  let currentElem = e.target;
  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document);
  if (path.indexOf(window) === -1) path.push(window);
  return path;
}

const tooltipGroups = {};

type Props = {
  position?: 'top' | 'right' | 'bottom' | 'left',
  styleVariant?: 'white' | 'warning',
  showTrigger: string,
  hideTrigger: string,
  group?: string,
  tooltip: React.Node | null,
  toggle: boolean,
  active: boolean,
  hideOnClickOutside: boolean,
  onShown: () => void,
  onHidden: () => void,
  children: any
};

type State = {
  active: boolean
};

class Tooltip extends React.Component<Props, State> {
  __tooltipId: string;
  __tooltipGroup: string;

  static defaultProps = {
    position: 'top',
    showTrigger: 'onMouseEnter',
    hideTrigger: 'onMouseLeave',
    onShown: () => {},
    onHidden: () => {},
    toggle: false,
    active: false,
    hideOnClickOutside: true
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      active: props.active
    };

    const children = React.Children.toArray(props.children);
    this.__tooltipId =
      ((children[0] && children[0].props) || {}).tooltipId || uniqueId('tooltip-trigger-');
    this.__tooltipGroup = props.group || 'main';

    tooltipGroups[this.__tooltipGroup] = tooltipGroups[this.__tooltipGroup] || [];
    tooltipGroups[this.__tooltipGroup].push(this);
  }

  componentWillUnmount() {
    tooltipGroups[this.__tooltipGroup] = tooltipGroups[this.__tooltipGroup].filter(tooltip => {
      return tooltip !== this;
    });
    document.removeEventListener('click', this.onClickOutside);
  }

  onClickOutside = (e: any) => {
    if (!this.props.hideOnClickOutside) return;
    const path = getPathFromEvent(e);
    const tooltip = path.filter(p => {
      // Sometimes we tie tooltip to svg
      // need to do this ugly special thing
      // because in some cases, we might have a button in the tooltip
      // that can change tooltip state (re-render)
      // And that button is usually removed from the DOM which makes this
      // method think that the click comes from outside of the tooltip
      if (typeof p.className !== 'string') return false;

      const className = p.className.split(' ');

      return (
        className.indexOf('ToolTipPortal') !== -1 || className.indexOf('tooltip-trigger') !== -1
      );
    });

    if (!tooltip.length) {
      this.hideTooltip();
    }
  };

  showTooltip = () => {
    const active = this.props.toggle ? !this.state.active : true;

    const tooltips = partition(tooltipGroups[this.__tooltipGroup] || [], tooltip => {
      return tooltip === this;
    });

    tooltips[1].forEach(tooltip => {
      tooltip.setState({ active: false });
    });

    // we need setTimeout here to push this tooltip state change to the end
    // after all other tooltips have changed their state. The reason for this
    // awkward setTimeout is that there is only one "Card" instance created
    // to host tooltip content for each group, once a tooltip is marked as
    // inactive, the Card instance is also changed to inactive. So, without
    // this setTimeout, we would run into a situation
    // where this supposed-to-be-active tooltip is hidden right after it's shown
    // due to other tooltips hiding the Card
    setTimeout(() => {
      tooltips[0].forEach(tooltip => {
        tooltip.setState({ active }, () => {
          tooltip.props.onShown();
          document.addEventListener('click', tooltip.onClickOutside);
        });
      });
    }, 0);
  };

  hideTooltip = () => {
    this.setState(
      {
        active: false
      },
      () => {
        this.props.onHidden();
        document.removeEventListener('click', this.onClickOutside);
      }
    );
  };

  render() {
    const id = this.__tooltipId;
    // Stupid -- one cannot simply define optional prop with default value
    const { styleVariant = 'default' } = this.props;
    const children = React.Children.toArray(this.props.children);
    const computedId = children[0].id || id;

    const tooltipProps = {
      parent: `#${id}`,
      position: this.props.position,
      arrow: 'center',
      tooltipTimeout: 0,
      style: styles[styleVariant],
      active: this.props.tooltip && (this.props.active || this.state.active)
    };

    const tooltip = (
      <PortalToolTip {...tooltipProps} key="tooltip-element">
        <IntlProvider>{this.props.tooltip}</IntlProvider>
      </PortalToolTip>
    );

    // the assumption is that there should be only 1 top element
    const trigger = {
      id: computedId,
      key: 'tooltip-trigger'
    };
    trigger[this.props.showTrigger] = this.showTooltip;
    if (!this.props.toggle) trigger[this.props.hideTrigger] = this.hideTooltip;

    /**
     * Tooltip will be actually mounted to the body, so it doesn't matter
     * where do we render it.
     * This clone all child elements (for the sake of versatility)
     * and attach listeners to it, which works
     * with simple nodes like `<span>`. The downside of this was that
     * passing any component with custom method would not work, unless that
     * component happens to spread props to it's top element, or set
     * id, onMouseEnter and such. Hence you need to either wrap it with
     * standard node, or mount listener functions from props to root
     * of your custom component
     */
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, c =>
          React.cloneElement(c, { ...trigger, ...c.props })
        )}
        {tooltip}
      </React.Fragment>
    );
  }
}

const styles = {
  warning: {
    style: {
      transition: 'none !important',
      textAlign: 'center',
      whiteSpace: 'no-wrap',
      borderRadius: 0,
      padding: '.5rem',
      background: variables['mm-yellow'],
      borderColor: 'transparent',
      maxWidth: '30rem'
    },
    arrowStyle: {
      transition: 'none !important',
      color: variables['mm-yellow'],
      bottom: '-11px',
      borderColor: 'transparent'
    }
  },
  white: {
    style: {
      transition: 'none !important',
      borderRadius: '0.35rem',
      background: '#ffffff',
      color: variables['mm-navy'],
      padding: '.75rem',
      maxWidth: '20rem'
    },
    arrowStyle: {
      color: '#ffffff',
      borderColor: 'rgba(0,0,0,.2)',
      transition: 'none !important'
    }
  },
  default: {
    style: {
      transition: 'none !important',
      borderRadius: '0.35rem',
      background: backgroundColor,
      color: '#fff',
      padding: '.75rem',
      border: 'none',
      maxWidth: '30rem',
      zIndex: 10000
    },
    arrowStyle: {
      color: backgroundColor,
      transition: 'none !important'
    }
  }
};

export default Tooltip;
