import React from 'react';

import { CampaignType, ContentPlatform } from '@sharkpunch/matchmade-common/campaign';

import InfluencerCollection from '../../influencer/InfluencerCollection';
import InfluencerParticipatingCard from '../../influencer/InfluencerParticipatingCard';

import { StatefulCollectionSelectorTrigger } from '../../collection/StatefulCollectionSelectorTrigger';
import InfluencerProfileModalTrigger from '../../influencer/InfluencerProfileModalTrigger';

import { getEstimatedInstallsFromEstimates } from '../../../helpers/estimates';
import { getFirstAvailableContentPlatform } from '../../../helpers/influencer';
import { getGameTypeForEstimates } from '../../../helpers/game';
import {
  getParticipatingInfluencers,
  getParticipatingInfluencersWithNoTags,
  getParticipatingInfluencersWithTag,
  getSortingOptions
} from './helper';
import { getViewsFromEstimates } from '../../../helpers/youtube';
import { shouldHideCpi } from '../../../helpers/campaign';

import { calculateYoutubePrices } from '@sharkpunch/matchmade-common/money';
import {
  getCpiFromAgreement,
  getCpmFromAgreement,
  getDealType,
  getMaxPaymentFromAgreement,
  getMinGuaranteeFromAgreement
} from '@sharkpunch/matchmade-common/campaignAgreement';

import find from 'lodash/find';

import type { CampaignForPublisher } from '../../../types/campaign.flow';

type Props = {
  campaign: CampaignForPublisher,
  onChangeSettings: () => void
};

const getCardProps = ({ influencer, campaign }) => {
  const gameType = getGameTypeForEstimates(campaign.game);
  const hideCpi = shouldHideCpi(campaign.attributionPartner, gameType);
  const { channel } =
    getFirstAvailableContentPlatform(influencer, campaign.defaultContentPlatform) || {};

  // for invites, we don't have agreement...
  // so... for normal cases, use the agreement
  // for invites, construct a fake "agreement"
  // with the estimates
  const agreement: any = find(campaign.agreements, { influencerId: influencer.id }) || {};

  if (!channel) {
    console.warn('Corrupted influencer data', influencer);
    return { campaign, agreement };
  }

  const channelName =
    (channel && channel.name) || (influencer.account && influencer.account.displayName) || '';
  const invite = influencer.invite;
  const promotionType = agreement.promotionType || campaign.defaultPromotionType;

  const contentPlatform =
    agreement.contentPlatform ||
    (invite && invite.contentPlatform) ||
    campaign.defaultContentPlatform;
  const youtubeChannel = influencer.youtubeChannels[0];
  const estimates = youtubeChannel && youtubeChannel.estimates;
  const estimatedInstalls = getEstimatedInstallsFromEstimates(estimates, promotionType, gameType);
  const adminActions = [
    <StatefulCollectionSelectorTrigger
      type="static"
      key="ApiConnectedCollectionSelectorTrigger"
      contentPlatform={contentPlatform}
      channelId={channel.id}
    />,
    (contentPlatform === ContentPlatform.YOUTUBE ||
      contentPlatform === ContentPlatform.TWITCH ||
      contentPlatform === ContentPlatform.INSTAGRAM) &&
      influencer.id && (
        <InfluencerProfileModalTrigger
          key="InfluencerProfileModalTrigger"
          accountId={influencer.id}
        />
      )
  ];

  const cpi = getCpiFromAgreement(agreement);
  const cpm = getCpmFromAgreement(agreement);
  const minGuarantee = getMinGuaranteeFromAgreement(agreement);
  const maxPayment = getMaxPaymentFromAgreement(agreement);

  const estimatedViews = youtubeChannel && getViewsFromEstimates(youtubeChannel.estimates);

  const estimatedCost = calculateYoutubePrices({
    promotionType,
    dealType: getDealType({
      cpi,
      cpm,
      freeOfCharge: agreement.freeOfCharge || campaign.campaignType === CampaignType.CHARITY
    }),
    estimatedViews,
    estimatedInstalls,
    minGuarantee,
    cpi,
    cpm,
    maxPayment
  }).currentPrice;

  return {
    influencer,
    agreement,
    promotionType,
    contentPlatform,
    channelName,
    agreementDeadline: agreement.deadline,
    agreementStatus: agreement.status,
    cost: agreement.cost,
    estimatedCost,
    estimatedInstalls,
    showMessagesTab: true,
    hideCpi,
    adminActions
  };
};

const BaseParticipantTab = ({
  campaign,
  onChangeSettings,
  isLoading,
  defaultTab,
  influencers
}: Props) => {
  if (!influencers || !influencers.length) {
    return (
      <section className="influencer-cards">
        <div class="notification is-info">No influencers</div>
      </section>
    );
  }

  influencers = influencers.map(influencer => {
    influencer.agreement = campaign.agreements.find(a => a.influencerId === influencer.id) || {};
    const { estimatedCost } = getCardProps({
      influencer: influencer,
      campaign: campaign
    });
    influencer.estimatedCost = estimatedCost;
    return influencer;
  });

  return (
    <section className="influencer-cards">
      <InfluencerCollection
        defaultTab={defaultTab}
        name="participatingInfluencerCollection"
        onChangeSettings={onChangeSettings}
        influencers={influencers}
        card={InfluencerParticipatingCard}
        cardProps={influencer => getCardProps({ influencer, campaign, isLoading })}
        cardSortOptions={getSortingOptions(campaign.defaultContentPlatform)}
        searchBy={[
          'youtubeChannels[0].name',
          'twitchChannels[0].name',
          'instagramChannels[0].name',
          'instagramChannels[0].username'
        ]}
      />
    </section>
  );
};

const ParticipantTab = ({ campaign, onChangeSettings, isLoading, defaultTab }: Props) => {
  const influencers = getParticipatingInfluencers(campaign);
  return BaseParticipantTab({ campaign, onChangeSettings, isLoading, defaultTab, influencers });
};

export const DealTagTab = ({
  campaign,
  onChangeSettings,
  isLoading,
  defaultTab,
  dealTag
}: Props) => {
  const influencers = getParticipatingInfluencersWithTag(campaign, dealTag);
  return BaseParticipantTab({ campaign, onChangeSettings, isLoading, defaultTab, influencers });
};

export const NoDealTagsTab = ({ campaign, onChangeSettings, isLoading, defaultTab }: Props) => {
  const influencers = getParticipatingInfluencersWithNoTags(campaign);
  return BaseParticipantTab({ campaign, onChangeSettings, isLoading, defaultTab, influencers });
};

export default ParticipantTab;
