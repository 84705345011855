// @flow
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import React from 'react';

import useError from '../hooks/useError';

import { defaultSettings } from '../constants';
import {
  getInfluencerSettings,
  getInfluencerYoutubePreferences,
  saveInfluencerSettings,
  saveInfluencerYoutubePreferences
} from '../actions/influencerSettings';
import InfluencerSettingsForm from '../components/influencer/InfluencerSettingsForm';
import InfluencerYoutubePreferencesForm from '../components/influencer/InfluencerYoutubePreferencesForm';
import InfoTooltip from '../components/common/InfoTooltip';
import compose from '../hoc/compose';
import generateDispatchToProps from '../helpers/generateDispatchToProps';
import generateStateToProps from '../helpers/generateStateToProps';
import type { ReduxInjectedProps } from '../reducers/common/influencerSettings';

import './InfluencerProfilePage.scss';

const InfluencerContactInformation = ({ settings, onChangeSettings }) => {
  return (
    <div className="InfluencerContactInformation">
      <h2 className="subtitle">
        <FormattedMessage id="influencer.profile.contactInformation" />
      </h2>
      <div className="box">
        <InfluencerSettingsForm
          editMode
          settings={settings}
          onChange={form =>
            onChangeSettings({
              ...settings,
              ...form
            })
          }
        />
      </div>
    </div>
  );
};

const InfluencerYoutubePreferences = ({
  youtubePreferences,
  onChangeYoutubePreferences,
  estimatedDedicatedPrice = 0,
  estimatedIntegratedPrice = 0
}) => {
  return (
    <div className="InfluencerYoutubePreferences">
      <h2 className="subtitle is-flex">
        <FormattedMessage id="influencer.profile.youtubePreferences.title" />
        <div className="ml-2 pt-1">
          <InfoTooltip>
            <FormattedMessage id="influencer.profile.youtubePreferences.tooltip" />
          </InfoTooltip>
        </div>
      </h2>
      <div className="box">
        <InfluencerYoutubePreferencesForm
          editMode
          youtubePreferences={youtubePreferences}
          onChange={form => {
            onChangeYoutubePreferences({
              ...youtubePreferences,
              ...form
            });
          }}
        />
      </div>
    </div>
  );
};

type Props = {
  ...ReduxInjectedProps,
  user: { token: { accountId: number } },
  routeParams: { id: string }
};

const InfluencerProfilePage = ({
  youtubePreferences,
  isFetchingYoutubePreferences,
  youtubePreferencesSaved,
  settings,
  isFetchingSettings,
  settingsSaved,
  getInfluencerSettings,
  getInfluencerYoutubePreferences,
  saveInfluencerSettings,
  saveInfluencerYoutubePreferences,
  user: { token },
  routeParams: { id },
  settingsError,
  youtubePreferencesError
}: Props) => {
  const accountId = id || token.accountId;
  const isLoading = isFetchingSettings || isFetchingYoutubePreferences;
  const [settingsForm, setSettingsState] = React.useState(defaultSettings);
  const [youtubePreferencesForm, setYoutubePreferencesState] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const { showError } = useError();

  const onSubmit = async () => {
    setIsSaving(true);
    await saveInfluencerSettings(accountId, settingsForm);
    await saveInfluencerYoutubePreferences(accountId, youtubePreferencesForm);
    setIsSaving(false);
  };

  React.useEffect(() => {
    getInfluencerSettings(accountId);
    getInfluencerYoutubePreferences(accountId);
  }, [getInfluencerSettings, getInfluencerYoutubePreferences, accountId]);

  React.useEffect(() => {
    setSettingsState(settings || defaultSettings);
  }, [settings]);

  React.useEffect(() => {
    setYoutubePreferencesState(youtubePreferences);
  }, [youtubePreferences]);

  React.useEffect(() => {
    if (settingsError) {
      showError(settingsError);
    }
  }, [settingsError, showError]);

  React.useEffect(() => {
    if (youtubePreferencesError) {
      showError(youtubePreferencesError);
    }
  }, [showError, youtubePreferencesError]);

  return (
    <div className="InfluencerProfilePage container">
      <div className="padding-container">
        <h1 className="title">
          <FormattedMessage id="influencer.profile.title" />
        </h1>
        <p className="InfluencerProfilePage__description">
          <FormattedMessage id="influencer.profile.description" />
        </p>

        <InfluencerContactInformation settings={settingsForm} onChangeSettings={setSettingsState} />

        <InfluencerYoutubePreferences
          youtubePreferences={youtubePreferencesForm}
          onChangeYoutubePreferences={setYoutubePreferencesState}
        />

        <div className="InfluencerProfilePage__actions">
          <button
            onClick={onSubmit}
            disabled={!(youtubePreferencesForm && youtubePreferencesForm.preferredPromotionType)}
            className={`button is-primary ${isLoading || isSaving ? 'is-loading' : ''}`}>
            Save
          </button>
          {settingsSaved && youtubePreferencesSaved && (
            <p className="has-text-success mt-3">Profile info has been successfully saved</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(
    generateStateToProps('influencerSettings'),
    generateDispatchToProps({
      getInfluencerSettings,
      getInfluencerYoutubePreferences,
      saveInfluencerSettings,
      saveInfluencerYoutubePreferences
    })
  )
)(InfluencerProfilePage);
