// @flow
import moment from 'moment';

import type { SearchSettings } from '../components/campaignCreation/ProductSelection';

import { createApiAction, createSimpleAction } from './helper';

import { fetchPublisher } from './publisher';
import { fetchCampaignAgreementInfo } from './campaignAgreement';

import { apiDateFormat } from '../helpers/formats';

import {
  AttributionPartner,
  CampaignGoal,
  ContentPlatform,
  PromotionType
} from '@sharkpunch/matchmade-common/campaign';

export const fetchCampaignEstimates = createApiAction('FETCH_CAMPAIGN_ESTIMATES', id => {
  return {
    path: `/campaigns/${id}/estimates`
  };
});

export const changeCreatedCampaign = createSimpleAction('CHANGE_CREATED_CAMPAIGN');
export const clearCreatedCampaign = createSimpleAction('CLEAR_CREATED_CAMPAIGN');

// TODO use proper type for campaign. We are sending too many unneccessary things to the API
export type CreateCampaign = (gameId: number, campaign: any) => Promise<any>;
export const createCampaign = createApiAction('CREATE_CAMPAIGN', (gameId, campaign) => {
  return {
    path: '/campaigns',
    options: {
      method: 'POST',
      body: {
        ...campaign,
        description: campaign.description || campaign.restrictions,

        // Always make sure that we have the default value
        goal: campaign.goal || CampaignGoal.INSTALLS,
        attributionPartner: campaign.attributionPartner || AttributionPartner.NONE,
        contentPlatforms: campaign.contentPlatforms || [ContentPlatform.YOUTUBE],
        defaultContentPlatform:
          campaign.defaultContentPlatform ||
          (campaign.contentPlatforms || [])[0] ||
          ContentPlatform.YOUTUBE,

        promotionTypes: campaign.promotionTypes || [PromotionType.DEDICATED],
        defaultPromotionType:
          campaign.defaultPromotionType ||
          (campaign.promotionTypes || [])[0] ||
          PromotionType.DEDICATED,

        gameId
      }
    },
    thenFn: (dispatch, getState) => {
      return async function (result) {
        // refresh current publisher data
        await dispatch(fetchPublisher.run(null, true));
        return result;
      };
    }
  };
});

export type FetchCampaign = (id: number, influencerId?: number) => Promise<any>;
export const fetchCampaign = createApiAction('FETCH_CAMPAIGN', (id, influencerId) => {
  let action = {
    path: `/campaigns/${id}`,
    options: {}
  };

  if (influencerId) {
    action.options = {
      method: 'GET',
      query: { influencerId }
    };
  }

  return action;
});

export const fetchCampaigns = createApiAction('FETCH_CAMPAIGNS', () => {
  return {
    path: `/admin/dashboard/campaigns`
  };
});

export const enableCampaign = createApiAction('ENABLE_CAMPAIGN', id => {
  return {
    path: `/campaigns/${id}/show`,
    options: {
      method: 'POST'
    },
    loadingPayload: { id }
  };
});

export const disableCampaign = createApiAction('DISABLE_CAMPAIGN', id => {
  return {
    path: `/campaigns/${id}/hide`,
    options: {
      method: 'POST'
    },
    loadingPayload: { id }
  };
});

// TODO determine the type
export type UpdateCampaign = (campaign: any) => Promise<any>;
export const updateCampaign = createApiAction('UPDATE_CAMPAIGN', campaign => {
  // make sure that we don't send everything to the API to avoid
  // Request Entity Too Large error
  const { influencers, game, gamePage, outOfNetworkInfluencers, ...campaignValues } = campaign;
  return {
    path: `/campaigns/${campaign.id}`,
    options: {
      method: 'PUT',
      body: campaignValues
    },
    loadingPayload: { id: campaign.id }
  };
});

export const deleteCampaignInvite = createApiAction('DELETE_CAMPAIGN_INVITE', inviteId => {
  return {
    path: `/campaigns/invites/${inviteId}`,
    options: {
      method: 'DELETE'
    },
    loadingPayload: { id: inviteId }
  };
});

// Do not use null for missing CPI because it will overwrite the previous value. Use undefined instead
export const negotiateCampaign = createApiAction('NEGOTIATE_CAMPAIGN', payload => {
  const {
    campaignId,
    influencerId,
    influencerCpi,
    publisherCpi,
    influencerCpm,
    publisherCpm,
    influencerMinGuarantee,
    publisherMinGuarantee,
    influencerMaxPayment,
    publisherMaxPayment,
    deadline,
    promotionType,
    contentPlatform,
    logId = null,
    freeOfCharge,
    takeItOrLeaveIt
  } = payload;
  return {
    path: `/campaigns/${campaignId}/negotiate`,
    options: {
      method: 'POST',
      body: {
        campaignId,
        influencerId,
        influencerCpi,
        publisherCpi,
        influencerCpm,
        publisherCpm,
        influencerMinGuarantee,
        publisherMinGuarantee,
        influencerMaxPayment,
        publisherMaxPayment,
        promotionType,
        contentPlatform,
        deadline,
        logId,
        freeOfCharge,
        takeItOrLeaveIt
      }
    },
    loadingPayload: payload
  };
});

const swapOfferInResponseWithLegacyCampaignAgreement = (dispatch, getState) => {
  return async function (result) {
    const offer = result && result.data && result.data.offer;
    if (!offer) {
      // there is no `offer` field in the result -- return as is,
      // this is probably some "succeeded with failure" result :|
      return result;
    }
    // Re-fetch old style agreement by ID, and replace
    // result's data with it, so the rest of the reducer
    // logic works as before.
    // It's not _exactly_ the same as agreement return by old
    // /campaigns/:id/accept|decline endpoints, but it serves the
    // purpose as well.
    const agreement = await dispatch(fetchCampaignAgreementInfo.run(offer.id, true));
    result.data = agreement;
    return result;
  };
};

export const acceptCampaign = createApiAction(
  'ACCEPT_CAMPAIGN',
  ({ campaignId, influencerId, id, deadline, logId = null, channelId = null }) => {
    // Have to add default deadline for agreement, because some old agreements might not have deadline at all.
    deadline = deadline || moment().add(2, 'weeks').format(apiDateFormat);

    return {
      path: `/offers/${id}/accept`,
      options: {
        method: 'PUT',
        body: {
          deadline,
          hash: logId
        }
      },
      loadingPayload: { campaignId, influencerId },
      thenFn: swapOfferInResponseWithLegacyCampaignAgreement
    };
  }
);

export const declineCampaign = createApiAction(
  'DECLINE_CAMPAIGN',
  ({ campaignId, influencerId, id, channelId = null, logId = null, reason = null }) => {
    return {
      path: `/offers/${id}/decline`,
      options: {
        method: 'PUT',
        body: { hash: logId, reason }
      },
      loadingPayload: { campaignId, influencerId },
      thenFn: swapOfferInResponseWithLegacyCampaignAgreement
    };
  }
);

export const fetchAllInNetworkInfluencers = createApiAction(
  'FETCH_ALL_IN_NETWORK_INFLUENCERS',
  (campaignId, query) => {
    return {
      path: `/campaigns/${campaignId}/all-in-network`,
      options: {
        method: 'GET',
        query: query && { q: query }
      },
      // use undefined here to use the default loading action
      loadingAction: undefined,
      abortOngoingRequest: true
    };
  }
);

export const fetchCampaignAnalytics = createApiAction(
  'FETCH_CAMPAIGN_ANALYTICS',
  ({ campaignId, influencerId, startDate, endDate, dimension }) => {
    return {
      path: `/campaigns/${campaignId}/analytics`,
      options: {
        query: {
          startDate,
          influencerId,
          endDate,
          dimension
        }
      },
      loadingPayload: { startDate, endDate }
    };
  }
);

export const fetchCampaignOverallStats = createApiAction(
  'FETCH_CAMPAIGN_OVERALL_STATS',
  (campaignId, influencerId) => {
    let action = {
      path: `/campaigns/${campaignId}/overall-stats`,
      options: {}
    };

    if (influencerId) {
      action.options = {
        method: 'GET',
        query: { influencerId }
      };
    }

    return action;
  }
);

export const fetchCampaignStatistics = createApiAction(
  'FETCH_CAMPAIGN_STATISTICS',
  (campaignId, startDate, endDate) => {
    return {
      path: `/campaigns/${campaignId}/statistics`,
      options: {
        query: { startDate, endDate }
      }
    };
  }
);

export const fetchCampaignInfluencerStats = createApiAction(
  'FETCH_CAMPAIGN_INFLUENCER_STATS',
  (campaignId, startDate, endDate) => {
    return {
      path: `/campaigns/${campaignId}/influencer-stats`,
      options: {
        query: { startDate, endDate }
      }
    };
  }
);

export type EditCampaign = (data: Object) => void;
export const editCampaign = createSimpleAction('EDIT_CAMPAIGN');

// for campaign creation steps
export type SetAttributeForCampaignDescriptionStep = ({ attribute: string, value: any }) => void;
export const setAttributeForCampaignDescriptionStep = createSimpleAction(
  'SET_ATTRIBUTE_FOR_CAMPAIGN_DESCRIPTION_STEP'
);

export type SetAttributesForProductSelectionStep = (attributes: Object) => void;
export const setAttributesForProductSelectionStep = createSimpleAction(
  'SET_ATTRIBUTES_FOR_PRODUCT_SELECTION_STEP'
);

export type SetAttributeForCampaignDetailsStep = ({ attribute: string, value: any }) => void;
export const setAttributeForCampaignDetailsStep = createSimpleAction(
  'SET_ATTRIBUTE_FOR_CAMPAIGN_DETAILS_STEP'
);

export type SetSearchSettingsInProductSelectionStep = (settings: SearchSettings) => void;
export const setSearchSettingsInProductSelectionStep = createSimpleAction(
  'CHANGE_SEARCH_SETTINGS_IN_PRODUCT_SELECTION_STEP'
);
