// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentPlatform } from '@sharkpunch/matchmade-common/campaign';
import type { ContentPlatform as ContentPlatformType, PromotionType } from 'matchmade-types';

import AdvancedSettings from '../common/AdvancedSettings';
import Autopilot from './Autopilot';
import ContentPlatforms from './ContentPlatforms';
import Name from './Name';
import PromotionTypes from './PromotionTypes';
import Restrictions from './Restrictions';
import TalkingPoints from './TalkingPoints';
import VideoReview from './VideoReview';

import './index.scss';

export type DataProps = {
  name: string,
  contentPlatforms: ContentPlatformType[],
  promotionTypes: PromotionType[],
  defaultPromotionType: PromotionType | null,
  talkingPoints: string,
  videoReview: boolean,
  restrictions: string,
  previousRestrictions?: ?string,
  isAutopilot: boolean
};

type EditableAttribute =
  | 'name'
  | 'contentPlatforms'
  | 'promotionTypes'
  | 'defaultPromotionType'
  | 'talkingPoints'
  | 'videoReview'
  | 'isAutopilot'
  | 'restrictions';

type OnChange = (attribute: EditableAttribute, value: any) => void;

type Props = DataProps & {
  onChange: OnChange,
  isDisabled: boolean,
  isContentPlatformDisabled: boolean
};

function generateOnChangeHandler(attribute: EditableAttribute, onChange: OnChange) {
  return value => {
    onChange(attribute, value);
  };
}

function renderName(props: Props) {
  return (
    <Name
      value={props.name}
      onChange={generateOnChangeHandler('name', props.onChange)}
      isDisabled={props.isDisabled}
    />
  );
}

function renderContentPlatforms(props: Props) {
  return (
    <ContentPlatforms
      values={props.contentPlatforms}
      onChange={generateOnChangeHandler('contentPlatforms', props.onChange)}
      isDisabled={props.isDisabled || props.isContentPlatformDisabled}
    />
  );
}

function renderPromotionTypes(props: Props) {
  return (
    <PromotionTypes
      isDisabled={props.contentPlatforms.indexOf(ContentPlatform.TWITCH) !== -1 || props.isDisabled}
      values={props.promotionTypes}
      defaultValue={props.defaultPromotionType}
      onChangeValues={generateOnChangeHandler('promotionTypes', props.onChange)}
      onChangeDefaultValue={generateOnChangeHandler('defaultPromotionType', props.onChange)}
    />
  );
}

function renderAutopilot(props: Props) {
  return (
    <Autopilot
      key="autopilot"
      isSelected={props.isAutopilot}
      onChangeValues={generateOnChangeHandler('isAutopilot', props.onChange)}
    />
  );
}

function renderAdvancedSettingTalkingPoints(props: Props) {
  return (
    <TalkingPoints
      key="talkingPoints"
      value={props.talkingPoints}
      onChange={generateOnChangeHandler('talkingPoints', props.onChange)}
      isDisabled={props.isDisabled}
    />
  );
}

function renderAdvancedSettingVideoReview(props: Props) {
  const { videoReview } = props;
  const { contentPlatforms } = props;

  let disabled = false;
  if (
    !contentPlatforms.length ||
    (contentPlatforms.length === 1 && contentPlatforms[0] === ContentPlatform.TWITCH)
  ) {
    disabled = true;
  }

  return (
    <VideoReview
      value={videoReview}
      onChange={generateOnChangeHandler('videoReview', props.onChange)}
      isDisabled={disabled || props.isDisabled}
    />
  );
}

function renderAdvancedSettingRestrictions(props: Props) {
  return (
    <Restrictions
      key="restrictions"
      value={props.restrictions}
      onChange={generateOnChangeHandler('restrictions', props.onChange)}
      isDisabled={props.isDisabled}
    />
  );
}

function renderAdvancedSettings(props: Props) {
  return (
    <AdvancedSettings
      label={<FormattedMessage id="campaignCreation.advancedSettings" />}
      intro={<FormattedMessage id="campaignCreation.campaignDescription.advancedSettings.intro" />}
      tooltip={
        <FormattedMessage id="campaignCreation.campaignDescription.advancedSettings.tooltip" />
      }>
      {renderAdvancedSettingVideoReview(props)}
      {renderAdvancedSettingTalkingPoints(props)}
      {renderAdvancedSettingRestrictions(props)}
    </AdvancedSettings>
  );
}

function CampaignDescription(props: Props) {
  return (
    <div className="CampaignDescription CreateCampaignFields">
      {renderName(props)}
      {renderContentPlatforms(props)}
      {renderPromotionTypes(props)}
      {renderAutopilot(props)}

      <hr />

      {renderAdvancedSettings(props)}
    </div>
  );
}

CampaignDescription.defaultProps = {
  isDisabled: false,
  isContentPlatformDisabled: false
};

export default CampaignDescription;
