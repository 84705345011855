/**
 * Generic notification banner.
 *
 * Example:
 *
 * ```
  <NotificationBanner type="warning">
    <NotificationAlert ...>
      Item 1
    </NotificationAlert>
    <NotificationAlert ...>
      Item 2
    </NotificationAlert>
  </NotificationBanner>
 * ```
 */

// @flow
import * as React from 'react';

import classNames from 'classnames';

import type { Colors } from '../../types/bulma.flow';

import './NotificationBanner.scss';
import { sendMixpanelEvent } from '../../helpers/mixpanelEvents';
// $FlowFixMe
import Alert from '@material-ui/lab/Alert';
// $FlowFixMe
import AlertTitle from '@material-ui/lab/AlertTitle';
import browserHistory from 'react-router/lib/browserHistory';

type AlertProps = {
  children: React.Node,
  link: string,
  mixpanelEvent?: string,
  title: React.Node,
  actionText: React.Node,
  severity?: 'error' | 'warning' | 'info' | 'success',
  openInNewTab?: boolean,
  buttonColor?: string
};

type Props = {
  children: React.Node,
  type?: Colors
};

export const NotificationAlert = ({
  children,
  link,
  mixpanelEvent,
  title,
  actionText,
  severity,
  buttonColor,
  openInNewTab
}: AlertProps) => {
  return (
    <Alert
      severity={severity || 'info'}
      classes={{
        root: 'container'
      }}
      action={
        <button
          type="button"
          className={`button is-${buttonColor || 'info'}`}
          onClick={() => {
            if (mixpanelEvent) {
              sendMixpanelEvent(mixpanelEvent);
            }
            if (openInNewTab) {
              window.open(link, '_blank');
            } else {
              browserHistory.push(link);
            }
          }}>
          {actionText}
        </button>
      }>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {children}
    </Alert>
  );
};

const NotificationBanner = ({ children, type = '' }: Props) => {
  if (!children) return null;

  return (
    <div
      className={classNames('NotificationBanner is-mobile', {
        [`is-${type}`]: !!type,
        'is-default': !type
      })}>
      {children}
    </div>
  );
};

export default NotificationBanner;
