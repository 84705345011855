import React from 'react';

const WaitIcon = ({ isActive }) => {
  const iconStyles = isActive ? 'icon Icon mdWaitIcon activeTabIcon' : 'icon Icon mdWaitIcon';
  return (
    <span className={iconStyles}>
      <i className="material-icons">timer</i>
    </span>
  );
};

export default WaitIcon;
