// @flow
import type { DataProps as CampaignDescriptionData } from '../../components/campaignCreation/CampaignDescription';
import type { ClearError } from '../../actions/common';
import type { IntlShape } from 'react-intl';
import type { RootState } from '../../reducers';
import type { Step } from '../../components/campaignCreation/CampaignCreationWizard';

import * as React from 'react';
import { DEFAULT_PROMOTION_TYPE } from '@sharkpunch/matchmade-common/campaign';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { buildStepsFromState } from './buildStepsFromState';
import { getUser } from '../../helpers/user';
import compose from '../../hoc/compose';
import generateDispatchToProps from '../../helpers/generateDispatchToProps';

import { BASE_CAMPAIGN_CREATION_URL, StepName, StepUrl } from './constants';

import CampaignCreationWizard from '../../components/campaignCreation/CampaignCreationWizard';
import CampaignDescription from '../../components/campaignCreation/CampaignDescription';

import {
  clearCreatedCampaign,
  setAttributeForCampaignDescriptionStep
} from '../../actions/campaign';
import { clearError } from '../../actions/common';

import type { SetAttributeForCampaignDescriptionStep } from '../../actions/campaign';

type Actions = {|
  clearCreatedCampaign: () => void,
  setAttributeForCampaignDescriptionStep: SetAttributeForCampaignDescriptionStep,
  clearError: ClearError
|};

type DataProps = {|
  data: CampaignDescriptionData,
  importedGame: any,

  createdByUserId?: number,

  steps: Step[],
  baseUrl: string
|};

type Props = {|
  ...Actions,
  ...DataProps,

  intl: IntlShape
|};

class CampaignDescriptionStep extends React.PureComponent<Props, {}> {
  static layoutClassName = 'Layout--create-campaign';
  static disableHeadroom = true;
  static url = StepUrl[StepName.CAMPAIGN_DESCRIPTION];

  componentDidMount() {
    if (this.props.createdByUserId !== getUser().id) {
      this.props.clearCreatedCampaign();
    }
  }

  onChange = (attribute: string, value: any) => {
    this.props.setAttributeForCampaignDescriptionStep({ attribute, value });
  };

  render() {
    const { data, steps, intl, clearError, baseUrl, importedGame } = this.props;

    const content = (
      <CampaignDescription
        {...data}
        onChange={(attribute, value) => {
          const newData = { ...data };
          this.onChange(attribute, value);

          if (attribute === 'contentPlatforms') {
            // Special logic here, since changing content platform
            // should also use correct restrictions placeholder
            // So...
            const newContentPlatform = value[0];
            // get both the previous contentPlatform and defaultRequirements
            // to do comparison
            const currentContentPlatform = newData.contentPlatforms[0];
            const currentDefaultRestrictions = intl.formatMessage({
              id: `campaign.description.default.${currentContentPlatform}`
            });
            const newDefaultRestrictions = intl.formatMessage({
              id: `campaign.description.default.${newContentPlatform}`
            });

            const currentRestrictions = newData.restrictions;

            const restrictions = data.previousRestrictions || newDefaultRestrictions;
            this.onChange(
              'previousRestrictions',
              currentRestrictions || currentDefaultRestrictions
            );
            this.onChange('restrictions', restrictions);

            // reset the promotion type to its default value when switching content platform
            this.onChange('promotionTypes', [DEFAULT_PROMOTION_TYPE[newContentPlatform]]);
            this.onChange('defaultPromotionType', DEFAULT_PROMOTION_TYPE[newContentPlatform]);
          }
        }}
      />
    );

    // we are using changeStyleWhenScrolling HOC in CampaignCreationWizard which automatically
    // injects `style` object to props. We haven't had a way to tell flow that yet
    return (
      // $FlowFixMe
      <CampaignCreationWizard
        headerBgImage={importedGame && importedGame.logoUrl}
        baseUrl={baseUrl}
        steps={steps}
        onClearError={clearError}>
        {content}
      </CampaignCreationWizard>
    );
  }
}

const mapDispatchToProps = generateDispatchToProps({
  clearCreatedCampaign,
  clearError,
  setAttributeForCampaignDescriptionStep
});

export default compose(
  connect(function (state: RootState, ownProps): DataProps {
    const ownState = state.campaignCreation;

    return {
      data: ownState.campaignDescription,
      importedGame: ownState.importedGame,
      steps: buildStepsFromState(ownState, StepName.CAMPAIGN_DESCRIPTION),
      baseUrl: BASE_CAMPAIGN_CREATION_URL,
      createdByUserId: ownState.createdByUserId
    };
  }, mapDispatchToProps),
  injectIntl
)(CampaignDescriptionStep);
