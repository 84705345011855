// @flow
import orderBy from 'lodash/orderBy';

import { DEFAULT_SEARCH_QUERY } from '../../helpers/searchYoutube';
import { MixpanelEvent, sendMixpanelEvent } from '../../helpers/mixpanelEvents';
import {
  fetchSuggestedGames,
  resetSearch,
  saveSearchToCollection,
  searchInfluencers
} from '../../actions/influencerYoutubeSearch';
import { getDynamicCollections } from '../../actions/collection';
import { handleUpdateSettingsActions } from '../common/account';
import collectionReducer from '../common/collection';
import runMultipleReducers from '../helpers/runMultipleReducers';
import type { Action } from '../../types/action.flow';
import type { Collection, CollectionWithIncludedFields } from '../../types/collection.flow';
import type { SearchParams, SuggestedGame, SuggestedTag } from '../../types/search.flow';

function handleSearchInfluencersActions() {
  return (state, action) => {
    switch (action.type) {
      case searchInfluencers.REQUEST:
        // Sending mixpanel event on click, no matter the result
        sendMixpanelEvent(MixpanelEvent.SEARCH, action.payload.query);

        return {
          ...state,
          isSearchingInfluencers: true,
          query: action.payload.query,
          errorSearchingInfluencers: null,
          isSavingSearch: false,
          updatedCollection: null,
          errorSavingSearch: null
        };
      // We leave previous influencer result here on purpose to avoid UI jumps etc.
      case searchInfluencers.SUCCESS:
        return {
          ...state,
          isSearchingInfluencers: false,
          influencers: action.payload,
          // page and pageSize are kinda redundant to set here again
          // but it could be that server side validation or the query changes
          // these values so we reset them to whatever server side sent
          query: {
            ...state.query,
            // $FlowFixMe
            page: action.meta.pagination.page,
            // $FlowFixMe
            pageSize: action.meta.pagination.pageSize
          },
          // $FlowFixMe
          totalCount: action.meta.pagination.totalCount,
          // Idea is to not display search result box if you haven't searched
          // BUT display 0 results in the result box after subsequent, even on possibly empty results
          hasSearchedOnce: true,
          errorSearchingInfluencers: null
        };
      case searchInfluencers.FAILURE:
        return {
          ...state,
          isSearchingInfluencers: false,
          influencers: [],
          // Idea is to not display search result box if you haven't searched
          // BUT display 0 results in the result box after subsequent, even on possibly empty results
          hasSearchedOnce: true,
          errorSearchingInfluencers: action.payload
        };
      default:
        return state;
    }
  };
}

function handleSaveSearchToCollectionActions() {
  return (state, action) => {
    switch (action.type) {
      case saveSearchToCollection.REQUEST:
        sendMixpanelEvent(MixpanelEvent.USE_SEARCH_TO_COLLECTION_SELECTOR, {
          searchId: action.payload.searchId,
          collectionId: action.payload.collectionId
        });
        return {
          ...state,
          isSavingSearch: true,
          updatedCollection: null,
          errorSavingSearch: null
        };
      case saveSearchToCollection.SUCCESS:
        if (state.collections.filter(c => c.id === action.payload.id).length > 0) {
          // If update collection
          return {
            ...state,
            isSavingSearch: false,
            updatedCollection: action.payload,
            errorSavingSearch: null
          };
        }
        // Else it's a new collection so we add this to the list
        return {
          ...state,
          isSavingSearch: false,
          updatedCollection: action.payload,
          collections: [...state.collections, action.payload],
          errorSavingSearch: null
        };
      case saveSearchToCollection.FAILURE:
        return {
          ...state,
          isSavingSearch: false,
          updatedCollection: null,
          errorSavingSearch: action.payload
        };
      default:
        return state;
    }
  };
}

function handleFetchCollectionsActions() {
  return (state, action) => {
    switch (action.type) {
      case getDynamicCollections.REQUEST:
        return {
          ...state,
          isLoadingCollections: true,
          collections: null
        };
      case getDynamicCollections.SUCCESS:
        return {
          ...state,
          isLoadingCollections: false,
          collections: action.payload
        };
      case getDynamicCollections.FAILURE:
        return {
          ...state,
          isLoadingCollections: false,
          collections: null
        };
      default:
        return state;
    }
  };
}

function handleFetchSuggestedGamesActions() {
  return (state, action) => {
    switch (action.type) {
      case fetchSuggestedGames.REQUEST:
        return {
          ...state,
          isFetchingSuggestedGames: true,
          suggestedGames: []
        };
      case fetchSuggestedGames.SUCCESS:
        return {
          ...state,
          isFetchingSuggestedGames: false,
          // We get more than 10, but we only need the first 10
          suggestedGames: orderBy<SuggestedGame>(
            action.payload || [],
            'channelCount',
            'desc'
          ).slice(0, 10)
        };
      case fetchSuggestedGames.FAILURE:
        return {
          ...state,
          isFetchingSuggestedGames: false,
          suggestedGames: []
        };
      default:
        return state;
    }
  };
}

function handleResetSearch() {
  return (state, action) => {
    if (action.type !== resetSearch.SUCCESS) {
      return state;
    }

    return {
      ...state,
      collection: null,
      isSearchingInfluencers: false,
      influencers: [],
      query: DEFAULT_SEARCH_QUERY,
      totalCount: 0,
      hasSearchedOnce: false,
      isSavingSearch: false,
      updatedCollection: null,
      errorSavingSearch: null
    };
  };
}

export type State = {
  influencers: Object[],
  // In normal scenarios this will always match the state of this component
  // Special cases:
  // - User opens a URL with search encoded in it.
  //   => query object will contain state that this component needs to be updated to
  // - User pressed back or forward
  //   => same as above
  query: SearchParams,
  isSearchingInfluencers: boolean,
  errorSearchingInfluencers: Object,
  collections: Collection[],
  collection: ?CollectionWithIncludedFields,
  isSavingSearch: boolean,
  updatedCollection: ?Collection,
  hasSearchedOnce: boolean,
  errorSavingSearch: Object,
  isLoadingCollections: boolean,
  totalCount: number,
  suggestedGames: SuggestedGame[],
  suggestedTags: SuggestedTag[],
  isFetchingSuggestedGames: boolean,
  isFetchingSuggestedTags: boolean,
  isFetchingCountries: boolean,
  isFetchingCollection: boolean
};

export const initialState: State = {
  isFetchingGames: false,
  isFetchingCountries: false,
  isFetchingSuggestedGames: false,
  isFetchingSuggestedTags: false,
  isSearchingInfluencers: false,
  isSearchingInfluencersForRelatedSearchDropdown: false,
  hasSearchedOnce: false,
  isLoadingCollections: false,
  isSavingSearch: false,
  isUpdatingAccountSettings: false,
  isRemovingChannelFromCollection: false,
  isAddingChannelToCollection: false,
  isFetchingCollections: false,
  isFetchingCollection: false,

  // This array is a possibly only a single page of a larger result set.
  // If the length of this array is shorter than 'influencerTotalCount',
  // then this is only a subset of the full result set.
  influencers: [],
  influencersForRelatedSearchDropdown: [],
  suggestedTags: [],
  suggestedGames: [],
  totalCount: 0,
  query: { ...DEFAULT_SEARCH_QUERY },
  collections: [],
  collection: null,
  updatedCollection: null,
  errorSavingSearch: null,
  error: null,
  errorSearchingInfluencers: null
};

function publisherYoutubePage2Reducer(state: State = initialState, action: Action): State {
  return runMultipleReducers([
    handleUpdateSettingsActions(),
    handleSearchInfluencersActions(),
    handleFetchCollectionsActions(),
    handleSaveSearchToCollectionActions(),
    handleFetchSuggestedGamesActions(),
    handleResetSearch(),
    (state, action) => collectionReducer.getCollection(state, action),
    (state, action) => collectionReducer.addYoutubeChannelToCollection(state, action),
    (state, action) => collectionReducer.createCollection(state, action),
    (state, action) => collectionReducer.removeYoutubeChannelFromCollection(state, action)
  ])(state, action);
}

export default publisherYoutubePage2Reducer;
