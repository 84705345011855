import { createApiAction } from './helper';

export const authLanding = createApiAction('AUTH_LANDING', () => {
  // NOTE! THIS IS ONLY FOR PUBLISHERS!
  return {
    path: '/auth/login-landing/publisher',
    options: {
      method: 'PUT'
    }
  };
});

export const fetchMe = createApiAction('FETCH_USER', () => {
  return {
    path: '/me'
  };
});

export const acceptTOS = createApiAction('ACCEPT_TOS', () => {
  return {
    path: '/me/accept-tos',
    options: {
      method: 'POST'
    }
  };
});
