// @flow
import type { ContentPlatform } from '../types/campaign.flow';

import { ContentPlatform as ContentPlatformEnum } from '../constants';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';

const regexes = {
  facebook: {
    re: /facebook.com\/(?:(?:profile\.php\?id=)|(?:pages\/)|(?:groups\/))?([a-zA-Z0-9.]+)/,
    match: 1
  },
  twitter: {
    re: /twitter.com\/(#!\/)?[@]?(.+)/,
    match: 2
  },
  instagram: {
    re: /instagram.com\/([_a-zA-Z0-9.]+)/,
    match: 1
  },
  bilibili: {
    re: /space.bilibili.com\/([_a-zA-Z0-9.]+)/,
    match: 1
  }
};

export function getContactInfo(contactInfo: any, service: string) {
  const contact = contactInfo[service];
  if (!contact) return '';

  const regex = regexes[service];
  if (!regex) return '';

  const matches = contact.match(regex.re);
  if (!matches) return '';

  return matches[regex.match];
}

export function getBiggestCountryGroup(influencer: any) {
  const { isOutOfNetworkInfluencer, youtubeChannels = [], twitchChannels = [] } = influencer || {};
  const channel = youtubeChannels[0] || twitchChannels[0];

  if (!channel) return null;

  let biggestCountryGroup = null;

  const demographics = channel.demographics || {};
  // only get country from demographics only if it's an in-network channel
  if (!isEmpty(demographics) && !isOutOfNetworkInfluencer) {
    biggestCountryGroup = maxBy(demographics.country || [], 'value') || null;
  }

  // then try to get it from either country or estimatedCountry
  if (!biggestCountryGroup && (channel.country || channel.estimatedCountry)) {
    // Just guess that the biggest audience country group is the country of the channel itself
    biggestCountryGroup = {
      name: channel.country || channel.estimatedCountry, // Prefer country influencer set in profile rather the one we guessed
      value: null,
      isEstimated: !channel.country && channel.estimatedCountry
    };
  }

  return {
    ...biggestCountryGroup,
    isEstimated: false
  };
}

// for now, check for ageGroup, gender, os and country
export function hasDemographics(channel: any) {
  const demographics = channel.demographics;
  if (!demographics) return false;

  return ['ageGroup', 'country', 'gender', 'operatingSystem'].every(attr => {
    return !isEmpty(demographics[attr]);
  });
}

export function getChannel(influencer: any) {
  const youtubeChannel = get(influencer, 'youtubeChannels[0]');
  if (youtubeChannel) {
    return youtubeChannel;
  }
  return get(influencer, 'twitchChannels[0]');
}

export function getOneChannelPerContentPlatform<A, B, C, D>(influencer: {
  youtubeChannels: A[],
  twitchChannels: B[],
  instagramChannels: C[],
  bilibiliChannels: D[]
}): {
  youtubeChannel: A | null,
  twitchChannel: B | null,
  instagramChannel: C | null,
  bilibiliChannel: D | null
} {
  const youtubeChannel = influencer.youtubeChannels && influencer.youtubeChannels[0];
  const twitchChannel = influencer.twitchChannels && influencer.twitchChannels[0];
  const instagramChannel = influencer.instagramChannels && influencer.instagramChannels[0];
  const bilibiliChannel = influencer.bilibiliChannels && influencer.bilibiliChannels[0];

  return {
    youtubeChannel,
    twitchChannel,
    instagramChannel,
    bilibiliChannel
  };
}

function constructChannelEntryForContentPlatform(contentPlatform: ContentPlatform) {
  return (channel: any) => {
    let url = '';
    switch (contentPlatform) {
      case ContentPlatformEnum.YOUTUBE:
        url = `https://www.youtube.com/channel/${channel.id}`;
        break;
      case ContentPlatformEnum.TWITCH:
        url = `https://twitch.tv/${channel.name}`;
        break;
      case ContentPlatformEnum.INSTAGRAM:
        url = `https://www.instagram.com/${channel.username}`;
        break;
      case ContentPlatformEnum.BILIBILI:
        url = `https://space.bilibili.com/${channel.id}`;
        break;
      default:
        break;
    }

    return {
      channel,
      url,
      contentPlatform
    };
  };
}

export function getFirstAvailableContentPlatform<A, B, C, D>(
  influencer: {
    youtubeChannels: A[],
    twitchChannels: B[],
    instagramChannels: C[],
    bilibiliChannels: D[]
  },
  targetContentPlatform?: ContentPlatform
): {
  contentPlatform: ContentPlatform,
  channel: A | B | C | D,
  url: string
} | null {
  const { youtubeChannels, twitchChannels, instagramChannels, bilibiliChannels } = influencer;

  // pick channel in this order by default
  const channels = [
    ...(youtubeChannels || []).map(
      constructChannelEntryForContentPlatform(ContentPlatformEnum.YOUTUBE)
    ),
    ...(twitchChannels || []).map(
      constructChannelEntryForContentPlatform(ContentPlatformEnum.TWITCH)
    ),
    ...(instagramChannels || []).map(
      constructChannelEntryForContentPlatform(ContentPlatformEnum.INSTAGRAM)
    ),
    ...(bilibiliChannels || []).map(
      constructChannelEntryForContentPlatform(ContentPlatformEnum.BILIBILI)
    )
  ]
    .filter(influencerChannel => {
      return influencerChannel && influencerChannel.channel;
    })
    .filter(({ contentPlatform }) => {
      // if targetContentPlatform is not provided, just go with the current list
      if (!targetContentPlatform) {
        return true;
      }

      return targetContentPlatform === contentPlatform;
    });

  return channels[0] || null;
}

export function getFirstAvailableContentPlatformOrThrow<A, B, C, D>(
  influencer: {
    youtubeChannels: A[],
    twitchChannels: B[],
    instagramChannels: C[],
    bilibiliChannels: D[]
  },
  targetContentPlatform?: ContentPlatform
): {
  contentPlatform: ContentPlatform,
  channel: A | B | C | D,
  url: string
} {
  const result = getFirstAvailableContentPlatform(influencer, targetContentPlatform);

  if (!result) {
    throw new Error('Channel not found');
  }

  return result;
}

export function getInfluencerByChannelId(influencers: any[], channelId: string) {
  return (
    influencers.find(influencer => {
      const youtubeChannelMatch = (influencer.youtubeChannels || []).some(
        ({ id }) => id === channelId
      );
      const twitchChannelMatch = (influencer.twitchChannels || []).some(
        ({ id }) => id === channelId
      );
      return youtubeChannelMatch || twitchChannelMatch;
    }) || null
  );
}

// Combine ALL channels from different content platforms into 1 list
// to make it easier to check if an influencer has a particular channel/contentPlatform pair or not
export function combineDifferentContentPlatforms(influencer: any) {
  function pickChannelsByContentPlatform(contentPlatform) {
    return channel => {
      return {
        id: channel.id,
        data: channel,
        contentPlatform
      };
    };
  }

  return [
    ...(influencer.youtubeChannels || []).map(
      pickChannelsByContentPlatform(ContentPlatformEnum.YOUTUBE)
    ),
    ...(influencer.twitchChannels || []).map(
      pickChannelsByContentPlatform(ContentPlatformEnum.TWITCH)
    ),
    ...(influencer.instagramChannels || []).map(
      pickChannelsByContentPlatform(ContentPlatformEnum.INSTAGRAM)
    )
  ];
}
