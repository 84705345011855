// @flow
import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

//$FlowFixMe
import { useAsync } from 'react-async';
import ActionableNotification from '../../components/common/ActionableNotification';
import DataExporter from '../../components/common/DataExporter';
import Spinner from '../../components/common/Spinner';

import callApi from '../../helpers/api';

import './AdminDashboardCommon.scss';

async function queryData([influencerId, api, path]) {
  const res = await callApi(
    `/connection-api-proxy/v1/imt_account_${influencerId}/default/${api}/${path}`,
    { method: 'GET' }
  );
  if (res.data && res.data.body) {
    return res.data.body;
  } else {
    return {
      error: 'Something went wrong',
      full_response: res
    };
  }
}

const canExportAsCsv = data =>
  data && Array.isArray(data.rows) && Array.isArray(data.columnHeaders);

function convertDataForCsvExport(data) {
  if (!data.rows || !data.columnHeaders) {
    console.warn('Invalid data input, can export only Reports API queries:', data);
    return [];
  }
  return data.rows.map(row =>
    data.columnHeaders.reduce((acc, header, i) => {
      acc[header.name] = row[i];
      return acc;
    }, {})
  );
}

type API = 'facebook-graph' | 'google-apis' | 'youtube-analytics' | 'twitch-helix';

function ApiExlorer({ title, api }: { title: string, api: API }) {
  const [influencerId, setInfluencerId] = useState('');
  const [path, setPath] = useState('');

  const dataFetcher = useAsync({
    deferFn: queryData
  });

  return (
    <div className="AdminDashboardGeneric container">
      <Helmet title="Redirect Links" />
      <h1 className="title">{title}</h1>
      <div className="section">
        <div className="columns">
          <div className="column is-narrow">
            <div className="field">
              <label className="label">MM Influencer Id</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="e.g. 1234"
                  onChange={e => {
                    setInfluencerId(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">API query path</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="API path"
                  onChange={e => {
                    setPath(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-narrow">
            <div className="field">
              <label className="label">&nbsp;</label>
              <div className="control">
                <button
                  className="button is-primary"
                  type="submit"
                  onClick={() => {
                    dataFetcher.run(influencerId, api, path);
                  }}>
                  Fetch data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        {dataFetcher.isLoading ? (
          <Spinner mode="fullWidth" size="large" />
        ) : dataFetcher.error ? (
          <ActionableNotification type="danger">
            Error: {dataFetcher.error.message}
          </ActionableNotification>
        ) : (
          <>
            {canExportAsCsv(dataFetcher.data) ? (
              <div className="container">
                <DataExporter data={convertDataForCsvExport(dataFetcher.data)} />
              </div>
            ) : dataFetcher.data ? (
              <small className="is-warning">Cannot export this API response to CSV :(</small>
            ) : null}
            <textarea
              className="textarea"
              readOnly={true}
              value={JSON.stringify(dataFetcher.data, null, 4)}></textarea>
          </>
        )}
      </div>
    </div>
  );
}

function YoutubeApiExplorer() {
  return <ApiExlorer title="YouTube API explorer" api="youtube-analytics" />;
}

function FacebookApiExplorer() {
  return <ApiExlorer title="Facebook API explorer" api="facebook-graph" />;
}

export { YoutubeApiExplorer, FacebookApiExplorer };
