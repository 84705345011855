// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import AttributionInfoTooltip from './AttributionInfoTooltip';
import type { Props } from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import ServiceWithOneTrackingUrlOrPlatformSpecific from './ServiceWithOneTrackingUrlOrPlatformSpecific';

import './AttributionInfo.scss';

export default function Singular(props: Props) {
  const iconTooltipData = [
    { weSend: 'the campaign id', as: 'pcid (Campaign ID)' },
    { weSend: 'the campaign name', as: 'pcn (Campaign name)' },
    { weSend: 'YouTube channel name', as: 'site (Site / Publisher name)' },
    { weSend: 'YouTube video name', as: 'pcrn (Creative name)' }
  ];

  return (
    <div className="AttributionInfo">
      <ServiceWithOneTrackingUrlOrPlatformSpecific {...props} name="singular" />
      <div className="AttributionInfo__description">
        <AttributionInfoTooltip list={iconTooltipData} />
        <FormattedMessage
          id="publisher.campaign.attribution.singularDescription"
          values={{
            initialSetupLink: (...chunks) => {
              return (
                <a href="https://support.singular.net/hc/en-us/articles/360053018851">{chunks}</a>
              );
            },
            link: (...chunks) => {
              return (
                <a href="https://support.singular.net/hc/en-us/articles/360050910891-How-to-Create-Deep-Links-Guide-for-Marketing-Business-Teams">
                  {chunks}
                </a>
              );
            },
            p: (...chunks) => {
              return <p>{chunks}</p>;
            }
          }}
        />
      </div>
    </div>
  );
}
