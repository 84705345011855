// @flow
import './HeaderNotification.scss';
import * as React from 'react';

type Props = {
  icon: React.Element<*> | null,
  children: any,

  style?: Object
};

export default function HeaderNotification(props: Props) {
  return (
    <div style={props.style} className="HeaderNotification">
      {props.icon && <div className="HeaderNotification__icon">{props.icon}</div>}
      <div className="HeaderNotification__content">{props.children}</div>
    </div>
  );
}
