// @flow
/**
 * HOC to display spinner whenever component is in loading state.
 *
 * Pass it along with other hocs, and make sure that
 * you set isLoading prop on the component; loading text is
 * optional, but nice to have if you have more than one of
 * these components on the page.
 *
 * Example:
 *
 * ```
  const TeamName = compose(
    hasLoading,
    injectIntl
 )(({ teamName, membersCount, intl }: { teamName: string | null, membersCount: number, intl: any }) => (
    <h2 className="subtitle">
    <FormattedMessage
    id="admin.managerTeam.members.title"
    values={{
        teamName,
        membersCount
      }}
    />
  </h2>
 ));

 ....
 <TeamName isLoading={isTeamLoading} members={......} />
 ```
 */
import * as React from 'react';

import type { ComponentType } from 'react';

import { FormattedMessage } from 'react-intl';
import type { SpinnerConfig } from '../components/common/Spinner';

import Spinner from '../components/common/Spinner';
import getDisplayName from './getDisplayName';

type LoadingTextType = string | React$Element<typeof FormattedMessage>;

function hasLoading(
  loadingText: ?LoadingTextType = null,
  spinnerConfig: SpinnerConfig = {
    centered: true,
    mode: 'inline'
  }
) {
  return function (WrappedComponent: ComponentType<*>) {
    return class extends React.Component<{
      isLoading: boolean
    }> {
      static displayName = getDisplayName('HasLoading', WrappedComponent);

      render() {
        if (this.props.isLoading) {
          return <Spinner {...spinnerConfig}>{loadingText}</Spinner>;
        }

        return <WrappedComponent {...this.props} />;
      }
    };
  };
}
export default hasLoading;
