// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import type { IntlShape } from 'react-intl';

import UrlControlInput from '../../common/UrlControlInput';

export type Props = {
  trackingCode: ?string,
  onChangeTrackingCode: (value: string) => void,

  intl: IntlShape,
  name: string,
  isInverted: boolean,

  children: any
};

export default function ServiceWithOneTrackingUrlAndInstruction(props: Props) {
  return (
    <div className="ServiceWithOneTrackingUrlAndInstruction InstallAttribution__attribution-partner">
      <label className="label">
        <FormattedMessage
          id={`campaignCreation.campaignDetails.advancedSetting.installAttribution.${props.name}.label`}
        />
      </label>
      <UrlControlInput
        isInverted={props.isInverted}
        value={props.trackingCode}
        onChange={props.onChangeTrackingCode}
      />
      {props.children}
    </div>
  );
}
