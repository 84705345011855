import isEmpty from 'lodash/isEmpty';

import get from 'lodash/get';

export function getEstimatesFromInfluencer(influencer) {
  return (
    (influencer.youtubeChannels &&
      influencer.youtubeChannels[0] &&
      influencer.youtubeChannels[0].estimates) ||
    (influencer.twitchChannels &&
      influencer.twitchChannels[0] &&
      influencer.twitchChannels[0].estimates)
  );
}

export function getFirstEstimates(...args) {
  for (const arg of args) {
    if (arg && !isEmpty(arg.estimates)) {
      return arg.estimates;
    }
  }
  return null;
}

export function getEstimatedInstallsFromEstimates(estimates, promotionType, gameType) {
  return get(estimates, `${gameType}.installs.${promotionType}.median`, 0);
}
